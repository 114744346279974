import { format, getDaysInMonth, isSameMonth, isSameYear } from "date-fns";
import es from "date-fns/locale/es";
import { capitalize } from "lodash";

const monthOptions = (minMonth, maxMonth) => {
  const options = [];
  for (let i = minMonth; i <= maxMonth; i++) {
    options.push({
      label: capitalize(format(new Date(2023, i, 1), "MMMM", { locale: es })),
      value: i,
    });
  }
  return options;
};

interface Option {
  label: string;
  value: number;
}

export const getDaysOptions = (
  currentDate: Date,
  minDate: Date,
  maxDate: Date
): Option[] => {
  let minDay = 1;
  let maxDay = getDaysInMonth(currentDate);
  if (isSameMonth(currentDate, minDate)) minDay = minDate.getDate();
  if (isSameMonth(currentDate, maxDate)) maxDay = maxDate.getDate();
  const options = [];
  for (let i = minDay; i <= maxDay; i++) {
    options.push({ label: i.toString(), value: i });
  }
  return options;
};

export const getMonthsOptions = (
  currentDate: Date,
  minDate: Date,
  maxDate: Date
): Option[] => {
  let minMonth = 0;
  let maxMonth = 11;
  if (isSameYear(currentDate, minDate)) minMonth = minDate.getMonth();
  if (isSameYear(currentDate, maxDate)) maxMonth = maxDate.getMonth();
  return monthOptions(minMonth, maxMonth);
};

export const getYearsOptions = (minDate: Date, maxDate: Date): Option[] => {
  const minYear = minDate.getFullYear();
  const maxYear = maxDate.getFullYear();
  const options = [];
  for (let i = minYear; i <= maxYear; i++) {
    options.push({ label: i.toString(), value: i });
  }
  return options;
};
