import { MouseEvent, ReactElement } from "react";

import Card from "@material-ui/core/Card";
import { Grid } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import usePropertyDesktopStyles from "./PropertyDesktopStyles";
import PropertyContent from "./components/PropertyContent";
import { Option, IProperty } from "../../utils/types";
import PropertyImage from "../PropertyImage";

interface Props {
  labelButton?: string;
  handleClick?: () => void;
  property: IProperty;
  optionList?: Option[];
  onHandleOpenProperty?: () => void;
  statusLabel: string;
  chipClassName?: string;
  children?: ReactElement;
}

const PropertyDesktop = ({
  labelButton,
  handleClick,
  property,
  optionList = [],
  onHandleOpenProperty,
  statusLabel,
  chipClassName = "",
  children,
}: Props) => {
  const classes = usePropertyDesktopStyles({
    onClickFlag: !!onHandleOpenProperty,
  });

  const onHandleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleClick();
  };

  return (
    <Card
      className={classes.root}
      elevation={2}
      onClick={onHandleOpenProperty}
      aria-label="property-card"
    >
      <Grid container spacing={2}>
        <Grid item className={classes.imageContainer}>
          <PropertyImage
            photo={property.photoUrl}
            propertyId={property.id}
            statusLabel={statusLabel}
            chipClassName={chipClassName}
          />
        </Grid>
        <Grid item container xs>
          <PropertyContent property={property} optionList={optionList} />
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            className={classes.descriptionContainer}
          >
            <Grid item container xs={12} md={6} direction="column">
              {children}
            </Grid>
            {handleClick && (
              <Grid
                container
                item
                xs={12}
                md={6}
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <Grid item>
                  <DesignSystemButton
                    label={labelButton}
                    variant="primary"
                    size="small"
                    onClick={onHandleClick}
                    buttonClassName={classes.button}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PropertyDesktop;
