import { Trans, useTranslation } from "react-i18next";

import Button from "@houm-com/ui/Button";
import { IoWarningOutline } from "@houm-com/ui-icons";

import CallToActionDrawer from "apps/ui/CustomDrawer/CallToActionDrawer";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import ContentDrawer from "apps/ui/CustomDrawer/ContentDrawer";

import { classes } from "./KhipuOnboardingStyles";

interface Props {
  onboardingLink: string;
}

const KhipuOnboarding = ({ onboardingLink }: Props) => {
  const { t } = useTranslation("payments");
  const { setCurrentStep } = useCustomDrawer();

  const onHandleClick = () => window.open(onboardingLink, "_blank");

  return (
    <>
      <ContentDrawer>
        <h1 className={classes.title}>Información importante</h1>
        <div className={classes.khipuOnboarding}>
          <div className={classes.khipuOnboardingContainer}>
            <p className={classes.subtitle}>Validaremos tu método de pago</p>
            <div className={classes.khipuOnboardingText}>
              <Trans
                i18nKey={t("KHIPU_INFORMATION")}
                components={{
                  1: <p />,
                  2: <span className={classes.strongInfo} />,
                }}
              />
            </div>
          </div>
          <div className={classes.container}>
            <div className={classes.infoContainerIcon}>
              <IoWarningOutline className={classes.infoIcon} />
            </div>
            <div className={classes.infoContainer}>
              <h1 className={classes.infoTitle}>IMPORTANTE</h1>
              <p className={classes.infoText}>
                Si tu fecha límite de pago es hoy contaremos esta como fecha de
                intención de pago y no se te multará por atraso.
              </p>
            </div>
          </div>
        </div>
      </ContentDrawer>
      <CallToActionDrawer>
        <Button
          variant="tertiary"
          size="md"
          onClick={() => setCurrentStep("paymentPlatforms")}
        >
          Atrás
        </Button>
        <Button
          variant="primary"
          size="md"
          type="button"
          onClick={onHandleClick}
        >
          Siguiente
        </Button>
      </CallToActionDrawer>
    </>
  );
};

export default KhipuOnboarding;
