import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@houm-com/ui/Button";

import { IDetails } from "apps/landlord/hooks/usePropertyDetails/utils/types";
import DrawerHeader from "apps/ui/CustomDrawer/DrawerHeader";
import Drawer from "apps/ui/CustomDrawer/Drawer";

import CardContent from "../CardContent";
import InfoContent from "../InfoContent";
import Form from "./components/Form";
import Label from "../Label";
import Title from "../Title";
import Card from "../Card";

interface Props {
  details: IDetails;
  isEditable: boolean;
}

const getValue = (value: string | number, extra?: string) => {
  if (value === 0 || value === null || value === "") return "Sin especificar";
  return `${String(value)}${extra ?? ""}`;
};

const Details = ({ details, isEditable }: Props) => {
  const [open, setOpen] = useState(false);

  const onHandleDrawer = () => setOpen((prev) => !prev);
  const { t } = useTranslation("landlord.property.settingUp");

  return (
    <>
      <Card>
        <CardContent>
          <Title title="Detalles importantes" />
          <InfoContent>
            <Label
              label={t("LABEL_BUILT_YEAR")}
              value={getValue(details.yearBuilt)}
            />
            <Label
              label={t("LABEL_BUILT_AREA")}
              value={getValue(details.builtArea, "m²")}
            />
            <Label
              label={t("LABEL_TOTAL_SURFACE")}
              value={getValue(details.totalArea, "m²")}
            />
            <Label
              label={t("LABEL_BEDROOMS")}
              value={getValue(details.rooms)}
            />
            <Label
              label={t("LABEL_BATHROOMS")}
              value={getValue(details.bathrooms)}
            />
            <Label
              label={t("LABEL_PARKINGS")}
              value={
                typeof details.parkingQuantity === "number"
                  ? String(details.parkingQuantity)
                  : "Sin especificar"
              }
            />
            {!!details.parkingQuantity && details.parkingQuantity > 0 && (
              <Label
                label={t("LABEL_PARKING_SLOTS")}
                value={getValue(details.parkingNumbers)}
              />
            )}
            <Label
              label={t("LABEL_WAREHOUSE")}
              value={details.warehouse ? "Si" : "No"}
            />
            {details.warehouse && (
              <Label
                label={t("LABEL_WAREHOUSE_SLOT")}
                value={getValue(details.warehouseNumber)}
              />
            )}
          </InfoContent>
        </CardContent>
        {isEditable && (
          <Button onClick={onHandleDrawer} size="md" variant="primary">
            Editar
          </Button>
        )}
      </Card>
      <Drawer onHandleClose={onHandleDrawer} open={open}>
        <DrawerHeader onHandleClose={onHandleDrawer} />
        <Form data={details} onHandleDrawer={onHandleDrawer} />
      </Drawer>
    </>
  );
};

export default Details;
