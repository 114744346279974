import Typography from "@material-ui/core/Typography";

import usePropertyAddressStyles from "./PropertyAddressStyles";

interface Props {
  property: {
    address: string;
    city: string;
  };
}

const PropertyAddress = ({ property }: Props) => {
  const classes = usePropertyAddressStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.address}>{property.address}</Typography>
      <Typography variant="body2" className={classes.region}>
        {property.city}
      </Typography>
    </div>
  );
};

export default PropertyAddress;
