import { useState } from "react";
import { useMutation } from "react-query";

import { useGetToken } from "hooks";

import { useHoumSnackBar } from "libs/houm-snackbar";
import useContract from "./useContract";
import { encodeRepairRequest } from "../utils/parsers";
import { uploadRepairPhotos } from "./utils/utils";
import { IRequestForm, IVisitHour } from "./utils/types";
import tenantRepairsService from "../services/tenantRepairsService";

const INITIAL_VISIT_HOURS: IVisitHour[] = Array.from({ length: 7 }, () => ({
  hours: [0, 0],
  checked: true,
}));

interface IUseRepairRequestAction {
  onSuccessHandler: () => void;
  onErrorHandler: () => void;
}

const useRepairRequesAction = ({
  onSuccessHandler,
  onErrorHandler,
}: IUseRepairRequestAction) => {
  const { data: contractData } = useContract();
  const { getToken } = useGetToken();
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const [requestForm, setRequestForm] = useState<IRequestForm>({});
  const [visitHours, setVisitHours] =
    useState<IVisitHour[]>(INITIAL_VISIT_HOURS);

  const repairRequestMutation = useMutation(
    async () => {
      const authToken = await getToken();
      const fileNames = await uploadRepairPhotos(authToken, requestForm.photos);
      if (!fileNames) throw new Error("No pudimos subir las fotos");
      return tenantRepairsService.createRepairRequest(
        authToken,
        contractData?.propertyId,
        encodeRepairRequest({ ...requestForm, visitHours }, fileNames)
      );
    },
    {
      onSuccess: () => {
        onSuccessHandler();
      },
      onError: () => {
        enqueueHoumSnackBar({
          head: "Ocurrió un problema con tu solicitud",
          body: "No pudimos crear tu solicitud de reparación, por favor intenta de nuevo.",
          type: "error",
        });
        onErrorHandler();
      },
    }
  );

  return {
    requestForm,
    setRequestForm,
    visitHours,
    setVisitHours,
    submitData: repairRequestMutation.mutate,
    isLoading: repairRequestMutation.isLoading,
  };
};

export default useRepairRequesAction;
