import { createTheme } from "@material-ui/core/styles";
import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "lg";

export const materialTheme = (theme) =>
  createTheme({
    ...theme,
    // @ts-ignore
    overrides: {
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          overflow: "inherit !important",
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          fontFamily: "Nunito",
          color: theme.palette.grey[500],
          textTransform: "uppercase",
          width: "100%",
          padding: theme.spacing(0, 3),
          [theme.breakpoints.up(defaultBreakpoint)]: {
            width: "60%",
          },
        },
        dayLabel: {
          fontFamily: "Nunito",
          textTransform: "uppercase",
        },
        transitionContainer: {
          "& p": {
            fontWeight: 700,
            fontFamily: "Nunito",
          },
        },
        daysHeader: {
          paddingBottom: theme.spacing(0.625),
          borderBottom: `0.3px solid ${theme.palette.grey[500]}`,
          display: "flex",
          justifyContent: "center",
          [theme.breakpoints.up(defaultBreakpoint)]: {
            justifyContent: "space-around",
          },
        },
      },
      MuiPickersCalendar: {
        week: {
          marginBottom: theme.spacing(1.25),
          paddingBottom: theme.spacing(0.125),
          borderBottom: `0.3px dashed ${theme.palette.grey[500]}`,
          display: "flex",
          justifyContent: "center",
          [theme.breakpoints.up(defaultBreakpoint)]: {
            justifyContent: "space-around",
          },
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          width: 300,
          overflowX: "clip",
          [theme.breakpoints.up(defaultBreakpoint)]: {
            minWidth: 422,
            minHeight: "auto",
          },
        },
      },
      MuiPickersDay: {
        day: {
          color: theme.palette.grey[900],
          "& p": {
            fontSize: theme.typography.pxToRem(18),
            fontFamily: "Nunito",
          },
        },
        daySelected: {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.secondary.main,
          border: `2px solid ${theme.palette.primary.main}`,
          "&:hover": {
            border: `2px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.secondary.main,
          },
        },
        current: {
          color: theme.palette.grey[900],
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
  });

export const useBadgeStyles = makeStyles((theme: Theme) => ({
  customBadge: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));
