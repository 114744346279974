import { useQuery, UseQueryResult } from "react-query";

import propertyContractServiceV2 from "apps/landlord/services/propertyContractServiceV2";
import { useGetToken } from "hooks";

import useActiveContract from "../useActiveContract";
import parseEndHoumContractRequest from "./utils/parsers";
import { IEndHoumContract } from "./utils/types";

const useEndHoumContractStatus = () => {
  const { data: dataActiveContract, isLoading: isLoadingActiveContract } =
    useActiveContract();
  const { getToken } = useGetToken();
  const query: UseQueryResult<IEndHoumContract, Error> = useQuery<
    IEndHoumContract,
    Error
  >(
    ["end_houm_contract_status_request", dataActiveContract?.id],
    async () => {
      const authToken = await getToken();
      const response = await propertyContractServiceV2.getEndContractHoum(
        dataActiveContract?.id,
        authToken
      );
      return parseEndHoumContractRequest(response.data);
    },
    { enabled: !!dataActiveContract?.id, retry: false }
  );

  return {
    ...query,
    endContractHoumStatus: query?.data,
    activeContract: dataActiveContract || null,
    isLoading: isLoadingActiveContract || query?.isLoading,
    showEndHoumContractRequest:
      dataActiveContract &&
      dataActiveContract.lastSubscription.status === "LEASED",
  };
};

export default useEndHoumContractStatus;
