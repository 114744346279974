import { Locale } from "models/Countries";
import { HoumOwner } from "models/Property";
import { CurrencyCodeType } from "./Currency";

export type MoneyType = "Pesos" | "U.F.";
export type RentStatusType =
  | "incomplete"
  | "photo_scheduled"
  | "photo_cancelled"
  | "photo_done"
  | "complete"
  | "published"
  | "reserved"
  | "republished"
  | "rented"
  | "unpublished"
  | "lost"
  | "archived"
  | "out_of_coverage"
  | "unpublished_by_import";

export type SalesStatusType =
  | "incomplete"
  | "photo_scheduled"
  | "photo_cancelled"
  | "photo_done"
  | "complete"
  | "published"
  | "sold"
  | "unpublished"
  | "lost"
  | "archived"
  | "out_of_coverage"
  | "unpublished_by_import";

export interface IPropertyDetails {
  currencyCode: CurrencyCodeType;
  moneyType: MoneyType;
  salesMoneyType: MoneyType;
  status: RentStatusType;
  price: number;
  salesPrice: number;
  firstHandoverDate: string;
  totalArea: number;
  builtArea: number;
  bathrooms: number;
  rooms: number;
  parkingSlots: number;
  laundryRoom: boolean;
  allowsPets: boolean;
  furnished: boolean;
  balcony: boolean;
  age: number;
  latitude: number;
  longitude: number;
  commonExpenses: number;
}

export interface IAmenities {
  hasAllDayVigilance: boolean;
  hasBbqArea: boolean;
  hasCinema: boolean;
  hasConcierge: boolean;
  hasElevator: boolean;
  hasGamesRoom: boolean;
  hasGourmetSpace: boolean;
  hasGym: boolean;
  hasLaundry: boolean;
  hasPartyRoom: boolean;
  hasPatio: boolean;
  hasPetArea: boolean;
  hasPlayground: boolean;
  hasPlayroom: boolean;
  hasRoofGarden: boolean;
  hasSauna: boolean;
  hasSteamRoom: boolean;
  hasSwimmingPool: boolean;
}

export interface ISalesDetails {
  status: SalesStatusType;
  currencyCode: CurrencyCodeType;
  price: number;
}

export enum PropertyTypeEnum {
  DEPARTAMENT = "departmento",
  HOUSE = "casa",
}

export type PropertyType =
  | PropertyTypeEnum.DEPARTAMENT
  | PropertyTypeEnum.HOUSE;

export interface Photos {
  id?: string;
  created_at?: string;
  updated_at?: string;
  filename?: string;
  featured?: boolean;
  order?: number;
  property?: number;
  url?: string;
}
export interface IProperty {
  uid: string;
  id: number;
  address: string;
  country: Locale;
  region: string;
  commune: string;
  forRental: boolean;
  forSale: boolean;
  apartmentNumber: string;
  propertyDetails: IPropertyDetails;
  amenities: IAmenities;
  salesDetails: ISalesDetails;
  streetNumber: string | number;
  type: PropertyType;
  photos: Photos[];
  userRole?: string;
  user?: {
    name: string;
    lastName: string;
    secondLastName: string;
    id: number;
    email: string;
  };
  managementExecutive: HoumOwner;
  stratus?: number;
}

export enum PropertyBusinessType {
  RENT = "rental",
  SALE = "sale",
}
