import { ReactNode } from "react";

import { CountryUpperCase } from "models/Countries";

import HelpTooltip from "apps/lead/components/HelpTooltip";

export type WorkerType = "dependent" | "independent" | "pensioner";

const renderToolTip = (value: string) => (
  <HelpTooltip
    disableFocus={true}
    disableHover={true}
    disableTouch={true}
    openClick={true}
    valueText={value}
  />
);

const renderIdentityCard = {
  id: "identityCard",
  text: "Carnet de identidad por ambos lados",
};

const textByWorkerType = (
  brokerage: boolean,
  workerType: WorkerType,
  country: CountryUpperCase
): { id: string; text: string; tooltip?: ReactNode }[] => {
  const values = {
    Chile: {
      dependent: [
        renderIdentityCard,
        {
          id: "quotes",
          tooltip: renderToolTip(
            "Puedes encontrarlo en la página web tu AFP www.afpcapital.cl www.cuprum.cl www.afphabitat.cl www.afpmodelo.cl www.planvital.cl www.provida.cl"
          ),
          text: "Certificado de las últimas 12 cotizaciones",
        },
        {
          id: "salarySettlements",
          text: "Últimas 3 liquidaciones de sueldo",
        },
        {
          id: "contract",
          tooltip: renderToolTip(
            "Documento que acredite el tipo de contrato y su fecha de inicio"
          ),
          text: "Certificado de antigüedad laboral o copia del contrato de trabajo",
        },
      ],
      independent: [
        renderIdentityCard,
        {
          id: "f22",
          tooltip: renderToolTip("Puedes encontrarlo en www.sii.cl"),
          text: "Formulario 22 de los últimos dos años",
        },
      ],
      pensioner: [
        renderIdentityCard,
        {
          id: "pensionSettlements",
          text: "Últimas 3 liquidaciones de pensión",
        },
      ],
    },
  };
  if (brokerage) {
    const valuePerCountry = values[country];
    return valuePerCountry[workerType];
  } else {
    return [
      renderIdentityCard,
      {
        id: "contract",
        text: "Contrato de arriendo.",
      },
      {
        id: "rentalPayments",
        text: "Últimos 3 comprobantes de pago del arriendo.",
      },
    ];
  }
};

export default textByWorkerType;
