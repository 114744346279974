import { makeStyles, Theme } from "@material-ui/core";

const useLayoutStyles = makeStyles<Theme>((theme) => ({
  container: {
    marginTop: theme.spacing(12.25),
    marginBottom: theme.spacing(12.5),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: 245,
    height: "auto",
  },
}));

export default useLayoutStyles;
