import { makeStyles, Theme } from "@material-ui/core/";

const useInfoRowStyles = makeStyles<Theme>((theme) => ({
  infoTextTitle: {
    color: theme.palette.grey["A400"],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
  infoTextValue: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    fontWeight: 700,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
  infoRowContainer: {
    height: "100%",
    backgroundColor: "#F7F9F9",
    borderRadius: 8,
    padding: theme.spacing(1),
    minHeight: 68,
    gap: theme.spacing(1),
    display: "grid",
    alignContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
}));

export default useInfoRowStyles;
