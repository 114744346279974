import { Box, Grid, Typography } from "@material-ui/core";

import { ApplicationCodebtor } from "models/Evaluation";
import { getName, isMainApplicant } from "apps/landlord/pages/Applicant/utils";
import useEvaluationFormTitleStyles from "./EvaluationFormTitleStyles";

interface EvaluationFormTitleProps {
  applicants: ApplicationCodebtor[];
}

const EvaluationFormTitle = ({ applicants }: EvaluationFormTitleProps) => {
  const classes = useEvaluationFormTitleStyles();
  return (
    <Grid item xs={12}>
      {applicants?.map((applicant) => (
        <Typography key={applicant.id} className={classes.text}>
          <Box className={classes.boldText}>{`${getName(applicant)} | `}</Box>
          {isMainApplicant(applicant) ? "Inquilino" : `Codeudor`}
        </Typography>
      ))}
    </Grid>
  );
};

export default EvaluationFormTitle;
