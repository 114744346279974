import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: 0,
    marginBottom: "0px !important",
  },
  label: {
    margin: 0,
    marginBottom: 0,
    alignSelf: "flex-start",
    fontWeight: "bold",
  },
  switch: {
    left: -10,
  },
  helperText: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
  },
}));

export default useStyles;
