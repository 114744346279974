import { useForm } from "react-hook-form";

import { Grid, Typography } from "@material-ui/core";

import { stepSubmit } from "context/loiFormContext/loiFormActions";
import { useLoiForm } from "context/loiFormContext/loiFormContext";
import { State } from "context/loiFormContext/loiFormTypes";
import fields from "context/loiFormContext/loiFormFields";
import CallToAction from "./components/CustomCallToAction";
import FilteredInput from "./components/FilteredInput";
import LoiFormStepHeader from "./components/LoiFormStepHeader";

const stepTitles = {
  landlordDocs: "Documentos personales",
  propertyDocs: "Documentos de la propiedad",
  brokerageContract: "Contrato de corretaje",
};

const stepFields = {
  landlordDocs: fields.landlordDocs,
  propertyDocs: fields.propertyDocs,
  brokerageContract: fields.brokerageContract,
};

export default function SummaryLoiStep() {
  const { state, dispatch } = useLoiForm();
  const methods = useForm();

  const getIdentificationLabel = (state: State, identificationType: string) =>
    state.documentTypes.find(
      (doc) => doc.value.toString() === identificationType
    )?.label;

  const onSubmit = () => {
    stepSubmit(dispatch, state.step, state.availableSteps);
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <LoiFormStepHeader title="Resumen" />

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <LoiFormStepHeader subtitle="Datos personales" />

          <Typography>
            <b> Nacionalidad: </b> {state.landlordData.nationality}
          </Typography>

          <Typography>
            <b> Identificación: </b>
            {getIdentificationLabel(
              state,
              state.landlordData.identificationType
            )}
          </Typography>

          <Typography>
            <b> N° identificación: </b>{" "}
            {state.landlordData.identificationNumber}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <LoiFormStepHeader subtitle="Propiedad a comprar" />

          <Typography>
            <b> Dirección: </b> {state.propertyData.address}
          </Typography>

          <Typography>
            <b> Número interior: </b> {state.propertyData.id}
          </Typography>

          <Typography>
            <b> Ciudad: </b> {state.propertyData.commune}
          </Typography>
        </Grid>

        {state.availableSteps.includes("landlordDocs") && (
          <Grid item xs={12}>
            <LoiFormStepHeader subtitle={stepTitles.landlordDocs} />

            <FilteredInput
              fieldInfo={stepFields.landlordDocs.identificationDoc1()}
              showDivider={false}
            >
              {state.landlordDocs.identificationDoc1?.name ?? "No Ingresado"}
            </FilteredInput>

            <FilteredInput
              fieldInfo={stepFields.landlordDocs.identificationDoc2()}
              showDivider={false}
            >
              {state.landlordDocs.identificationDoc2?.name ?? "No Ingresado"}
            </FilteredInput>

            <FilteredInput
              fieldInfo={stepFields.landlordDocs.appraisalCertificate()}
              showDivider={false}
            >
              {state.landlordDocs.appraisalCertificate?.name ?? "No Ingresado"}
            </FilteredInput>

            <FilteredInput
              fieldInfo={stepFields.landlordDocs.propertyDeed()}
              showDivider={false}
            >
              {state.landlordDocs.propertyDeed?.name ?? "No Ingresado"}
            </FilteredInput>

            <FilteredInput
              fieldInfo={stepFields.landlordDocs.curp()}
              showDivider={false}
            >
              {state.landlordDocs.curp?.name ?? "No Ingresado"}
            </FilteredInput>

            <FilteredInput
              fieldInfo={stepFields.landlordDocs.rfc()}
              showDivider={false}
            >
              {state.landlordDocs.rfc?.name ?? "No Ingresado"}
            </FilteredInput>

            <FilteredInput
              fieldInfo={stepFields.landlordDocs.birthCertificate()}
              showDivider={false}
            >
              {state.landlordDocs.birthCertificate?.name ?? "No Ingresado"}
            </FilteredInput>

            <FilteredInput
              fieldInfo={stepFields.landlordDocs.marriageCertificate()}
              showDivider={false}
            >
              {state.landlordDocs.marriageCertificate?.name ?? "No Ingresado"}
            </FilteredInput>
          </Grid>
        )}

        {state.availableSteps.includes("propertyDocs") && (
          <Grid item xs={12}>
            <LoiFormStepHeader subtitle={stepTitles.propertyDocs} />

            <FilteredInput
              fieldInfo={stepFields.propertyDocs.propertyDeed()}
              showDivider={false}
            >
              {state.propertyDocs.propertyDeed?.name ?? "No Ingresado"}
            </FilteredInput>

            <FilteredInput
              fieldInfo={stepFields.propertyDocs.propertyBill()}
              showDivider={false}
            >
              {state.propertyDocs.propertyBill?.name ?? "No Ingresado"}
            </FilteredInput>

            <FilteredInput
              fieldInfo={stepFields.propertyDocs.waterBill()}
              showDivider={false}
            >
              {state.propertyDocs.waterBill?.name ?? "No Ingresado"}
            </FilteredInput>

            <FilteredInput
              fieldInfo={stepFields.propertyDocs.traditionCertificate()}
              showDivider={false}
            >
              {state.propertyDocs.traditionCertificate?.name ?? "No Ingresado"}
            </FilteredInput>
          </Grid>
        )}

        {state.availableSteps.includes("brokerageContract") && (
          <Grid item xs={12}>
            <LoiFormStepHeader subtitle={stepTitles.brokerageContract} />

            <FilteredInput
              fieldInfo={stepFields.brokerageContract.signedContract()}
              showDivider={false}
            >
              {state.brokerageContract.signedContract?.name ?? "No Ingresado"}
            </FilteredInput>
          </Grid>
        )}
      </Grid>

      <CallToAction />
    </form>
  );
}
