import { makeStyles, Theme } from "@material-ui/core";

const LEFT_SIDE_HEIGHT = 366;
const CREATE_PROPERTY_BUTTON_HEIGHT = 40;

const usePropertiesStyles = makeStyles<Theme>((theme) => ({
  buttonContainer: {
    textAlign: "center",
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(0),
      textAlign: "end",
    },
  },
  propertiesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(5),
  },
  rootContainer: {
    [theme.breakpoints.up("sm")]: {
      minHeight: LEFT_SIDE_HEIGHT,
    },
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      height: `calc(${LEFT_SIDE_HEIGHT}px - ${CREATE_PROPERTY_BUTTON_HEIGHT}px)`,
    },
  },
}));

export default usePropertiesStyles;
