import { format } from "date-fns";

import {
  InspectionReportFeature,
  InspectionReportFeaturesNames,
  InspectionReportItem,
  InspectionReportItemsNames,
  InspectionReportShowResponse,
  InspectionReportSpace,
  InspectionReportSpacesType,
} from "apps/landlord/services/propertyService/type";
import {
  GroupedInspectionReportSpace,
  InspectionReport,
} from "models/InspectionReport";
import { getObjectLabel } from "utils";

type GeneralSpaceTranslation = {
  label: string;
  value: InspectionReportSpacesType;
};

export const inspectionReportGeneralSpaceTranslation: GeneralSpaceTranslation[] =
  [
    { label: "Living", value: "living_room" },
    { label: "Comedor", value: "dining_room" },
    { label: "Habitación", value: "room" },
    { label: "Baño", value: "bathroom" },
    { label: "Cocina", value: "kitchen" },
    { label: "Logia", value: "laundry_room" },
    { label: "Terraza", value: "terrace" },
    { label: "Otro", value: "other" },
  ];

const groupSpaces = (
  type: InspectionReportSpacesType,
  spaces: InspectionReportSpace[]
) => spaces.filter((space) => space.type === type) ?? null;

const setSpaceNames = (
  spacesArr: InspectionReportSpace[]
): InspectionReportSpace[] => {
  if (spacesArr.length === 1) {
    return spacesArr.map((space) => ({
      ...space,
      name: getObjectLabel(
        inspectionReportGeneralSpaceTranslation,
        space?.type
      ),
    }));
  }
  return spacesArr.map((space, index) => ({
    ...space,
    name: `${getObjectLabel(
      inspectionReportGeneralSpaceTranslation,
      space?.type
    )} ${index + 1}`,
  }));
};

const parseSpaces = (spaces: InspectionReportSpace[]) => {
  const livingRooms = setSpaceNames(groupSpaces("living_room", spaces));
  const diningRooms = setSpaceNames(groupSpaces("dining_room", spaces));
  const rooms = setSpaceNames(groupSpaces("room", spaces));
  const bathrooms = setSpaceNames(groupSpaces("bathroom", spaces));
  const kitchens = setSpaceNames(groupSpaces("kitchen", spaces));
  const laundryRooms = setSpaceNames(groupSpaces("laundry_room", spaces));
  const terraces = setSpaceNames(groupSpaces("terrace", spaces));
  const others = setSpaceNames(groupSpaces("other", spaces));
  return [
    ...livingRooms,
    ...diningRooms,
    ...rooms,
    ...bathrooms,
    ...kitchens,
    ...laundryRooms,
    ...terraces,
    ...others,
  ];
};

const groupByFeatureNature = (
  space: InspectionReportSpace
): GroupedInspectionReportSpace => {
  const spaceEntries = Object.entries(space);

  const spaceFeaturesEntries: [string, InspectionReportFeature][] =
    spaceEntries.filter(
      (spaceEntry) =>
        spaceEntry[1]?.quantity === undefined &&
        spaceEntry[1]?.qualification !== undefined
    );

  const spaceItemsEntries: [string, InspectionReportItem][] =
    spaceEntries.filter((spaceEntry) => spaceEntry[1]?.quantity !== undefined);

  const spaceFeatures = spaceFeaturesEntries.map((featureEntry) => ({
    name: featureEntry[0] as InspectionReportFeaturesNames,
    ...featureEntry[1],
  }));

  const spaceItems = spaceItemsEntries.map((itemEntry) => ({
    name: itemEntry[0] as InspectionReportItemsNames,
    ...itemEntry[1],
  }));

  return {
    id: space?.id,
    name: space?.name,
    photo: space?.photo_link,
    type: space?.type,
    qualification: space?.overall_qualification,
    observation: space?.overall_observation,
    features: spaceFeatures,
    items: spaceItems,
  };
};

const parsePropertyInspectionReport = (
  result: InspectionReportShowResponse
): InspectionReport => ({
  id: result.id,
  createdAt: format(new Date(result?.created_at), "dd/MM/yyyy"),
  overallObservation: result?.general_observation,
  is_habitable: result?.is_habitable,
  appraiserInfo: {
    id: result?.appraiser?.id,
    name: result?.appraiser?.name ?? "Sin información",
    email: result?.appraiser?.email,
  },
  generalSpacesSummary: parseSpaces(result?.spaces).map((space) => ({
    id: space?.id,
    type: space?.type,
    name: space?.name,
    overallQualification: space?.overall_qualification,
    overallObservation: space?.overall_observation,
    photo: space?.photo_link,
  })),
  spaces: parseSpaces(result?.spaces).map((space) =>
    groupByFeatureNature(space)
  ),
});

export default parsePropertyInspectionReport;
