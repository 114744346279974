import { SnackbarPayload } from "libs/houm-snackbar/types";

export const GET_AVAILABILITY_ERROR: SnackbarPayload = {
  body: "Ha ocurrido un error al obtener la disponibilidad de agendamiento de fotos, por favor intenta nuevamente",
  type: "error",
};

export const SCHEDULE_PHOTO_VISIT_ERROR: SnackbarPayload = {
  body: "Ha ocurrido un error al agendar la visita, por favor intenta nuevamente",
  type: "error",
};

export const SCHEDULE_PHOTO_VISIT_SUCCESS: SnackbarPayload = {
  body: "Visita agendada correctamente",
  type: "success",
};
