import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

const useTermsAndConditionsStyles = makeStyles((theme: Theme) => ({
  titleGeneral: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: theme.palette.grey[900],
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: "2rem",
    },
  },
  terms: {
    textAlign: "justify",
    whiteSpace: "pre-line",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
  },
  button: {
    borderRadius: 100,
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      width: "fit-content",
    },
  },
}));

export default useTermsAndConditionsStyles;
