import { useCallback } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Container } from "@material-ui/core";

import ControlledCheckedFile from "apps/lead/components/ControlledForm/ControlledCheckedFile";
import { useEvaluation } from "apps/lead/context/evaluationContext/EvaluationContext";
import { setActualStep } from "apps/lead/context/evaluationContext/EvaluationActions";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper/FormWrapper";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import TermsAndConditions from "apps/lead/components/TermsAndConditions";
import MailSentImage from "assets/images/adminTenant/mail-sent.svg";
import { useFormNotifications } from "hooks";

import { resumeFormSchema, requiredText } from "./validationSchema";
import useResumeFormStyles from "./ResumeFormStyles";
import ResumeApplicants from "../ResumeApplicants";

interface TyCSchema {
  checkTyC: boolean;
}

interface Props {
  openDialog: () => void;
  createEvaluation: () => void;
  isLoading: boolean;
}

const ResumeForm = ({ openDialog, createEvaluation, isLoading }: Props) => {
  const classes = useResumeFormStyles();
  const { state, dispatch } = useEvaluation();
  const { activeStep, codebtorAnswer } = state;

  const methods = useForm<TyCSchema>({
    resolver: yupResolver(resumeFormSchema),
    reValidateMode: "onBlur",
  });

  const { errors } = methods.formState;
  useFormNotifications<TyCSchema>({ errors, requiredText });

  const onSubmit: SubmitHandler<TyCSchema> = () => {
    if (!codebtorAnswer.firstCodebtorAnswer) openDialog();
    else createEvaluation();
  };

  const handleBackClick = useCallback(() => {
    if (!codebtorAnswer.firstCodebtorAnswer) setActualStep(2, dispatch);
    else if (codebtorAnswer.secondCodebtorAnswer)
      setActualStep(activeStep - 1, dispatch);
    else if (
      !codebtorAnswer.secondCodebtorAnswer &&
      codebtorAnswer.firstCodebtorAnswer
    )
      setActualStep(5, dispatch);
  }, [codebtorAnswer]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper>
          <Container className={classes.container}>
            <img
              src={MailSentImage}
              alt="mail-sent"
              className={classes.image}
            />
          </Container>
          <ResumeApplicants />
          <ControlledCheckedFile
            id="checkTyC"
            label={<TermsAndConditions country="Chile" />}
            defaultChecked={false}
          />
        </FormWrapper>
        <CallToAction
          onHandleBackClick={handleBackClick}
          isLoading={isLoading}
          label="Finalizar"
        />
      </form>
    </FormProvider>
  );
};

export default ResumeForm;
