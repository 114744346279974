import { Box, Typography } from "@material-ui/core";

import { IRequestForm } from "apps/tenant/hooks/utils/types";
import useRepairSelectionStyles from "./RepairSelectionStyles";
import RepairForm from "./RepairForm";

interface Props {
  handleNext: () => void;
  setRepairForm: (value: IRequestForm) => void;
  repairForm: IRequestForm;
}

const RepairSelection = ({ handleNext, setRepairForm, repairForm }: Props) => {
  const classes = useRepairSelectionStyles();
  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>
        Cuéntanos, ¿qué reparación necesitas?
      </Typography>
      <Typography className={classes.description}>
        Selecciona el tipo de reparación que necesitas, compártenos fotos y
        cuéntanos mas detalle sobre tu reparación.
      </Typography>
      <Typography className={classes.description}>
        No lo olvides esto nos será de gran ayuda para atenderte mejor
      </Typography>
      <RepairForm
        handleNext={handleNext}
        setRequestForm={setRepairForm}
        requestForm={repairForm}
      />
    </Box>
  );
};

export default RepairSelection;
