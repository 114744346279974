import { makeStyles, Theme } from "@material-ui/core";

const useHeaderStyles = makeStyles((theme: Theme) => ({
  servicesHeaderRoot: {
    marginBottom: theme.spacing(2),
  },
  servicesTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(1.5),
    },
  },
  message: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(2.5),
    },
  },
  noInfo: {
    margin: theme.spacing(2, 0),
  },
}));

export default useHeaderStyles;
