import { CountryCode } from "libphonenumber-js";
import { useTranslation } from "react-i18next";

import MobileHeader from "../MobileHeader/MobileHeader";
import PhoneForm from "../../../PhoneForm/PhoneForm";

interface Props {
  country: CountryCode;
  phone: string;
}

const PhoneMobile = ({ country, phone }: Props) => {
  const { t } = useTranslation("account.profile");

  return (
    <>
      <MobileHeader title="Teléfono" subtitle={t("phone_text")} />
      <PhoneForm country={country} phone={phone} />
    </>
  );
};

export default PhoneMobile;
