import moment from "moment";
import { AutoProfilePropertyParsed, FormattedAddress } from "models";
import { AutoProfileProperty } from "apps/landlord/pages/CreateProperty/context/proprietaryFormTypes";
import { PROPERTY_TYPES } from "apps/landlord/pages/CreateProperty/context/constants";

const parseData = (
  data: AutoProfileProperty,
  formattedAddres: FormattedAddress,
  userId: number
): AutoProfilePropertyParsed => ({
  user: userId,
  latitude: formattedAddres.lat,
  longitude: formattedAddres.lng,
  type: data.propertyType,
  moreInfo: data.moreInfo,
  region: formattedAddres.region,
  country: formattedAddres.country,
  typeTranslated: PROPERTY_TYPES[data.propertyType].label,
  ...(data.propertyNumber && {
    house_or_apartment_number: data.propertyNumber,
  }),
  address: data.address.formatted_address,
  for_sale: ["both", "sale"].includes(data.service),
  for_rental: ["both", "rent"].includes(data.service),
  first_available_handover_date: moment(data.date).format("YYYY-MM-DD"),
});

export default parseData;
