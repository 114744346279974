import { useEffect } from "react";

import Spinner from "@houm-com/ui/Spinner";

import { classes } from "apps/lead/components/AddressSuggester/components/SearchDropDown/SearchDropDownStyles";
import useAddressSearch from "apps/lead/components/AddressSuggester/hooks/useAddressSearch";
import { AddressSearch } from "apps/lead/components/AddressSuggester/utils/types";
import { Locale } from "models/Countries";

interface Props {
  countryCode: Locale;
  handleSearch: (address: AddressSearch) => void;
  handleCheck?: (value: boolean) => void;
  queryAddressSuggestions: string;
  defaultAddress: string;
  selectedAddress?: AddressSearch;
}

const SearchDropDown = ({
  countryCode,
  handleSearch,
  handleCheck,
  queryAddressSuggestions,
  defaultAddress,
  selectedAddress,
}: Props) => {
  const {
    data: suggestions = [],
    isLoading,
    isSuccess,
  } = useAddressSearch({
    addressQuery: queryAddressSuggestions,
    countryCode,
  });

  useEffect(() => {
    if (isLoading) handleCheck?.(false);
  }, [handleCheck, isLoading]);

  const handleSelect = (item: AddressSearch) => handleSearch(item);

  return (
    <div className={classes.searchDropDownRoot}>
      <div className={classes.searchDropDownContainer}>
        {!selectedAddress && (
          <div className={classes.searchDropDownSuggestionsContainer}>
            {suggestions.map((option) => (
              <div
                key={option.reference_id}
                className={classes.searchDropDownSuggestion}
                tabIndex={0}
                role="button"
                onClick={() => handleSelect(option)}
              >
                {option.full_address}
              </div>
            ))}
          </div>
        )}
        {isLoading && (
          <div className={classes.searchDropDownDisclaimerContainer}>
            <Spinner size="sm" variant="primary" />
            <span className={classes.searchDropDownDisclaimerLoadingText}>
              Buscando dirección
            </span>
          </div>
        )}
        {isSuccess && suggestions?.length === 0 && (
          <div className={classes.searchDropDownDisclaimerContainer}>
            <span className={classes.searchDropDownDisclaimerText}>
              No encontramos la dirección que buscas
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchDropDown;
