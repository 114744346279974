import { UseQueryResult, useQuery } from "react-query";

import tenantContractService from "apps/tenant/services/tenantContractService/tenantContractService";
import { useGetToken } from "hooks";

import { SimplifiedContract } from "./types";
import { parseTenantContractHistory } from "./parsers";

const useContractHistory = () => {
  const { getToken } = useGetToken();
  const {
    data,
    isLoading,
    isError,
  }: UseQueryResult<SimplifiedContract[], Error> = useQuery<
    SimplifiedContract[],
    Error
  >("tenantContracts", async () => {
    const authToken = await getToken();
    const response = await tenantContractService.getAllContracts(authToken);
    return parseTenantContractHistory(response.data);
  });
  return { data, isLoading, isError };
};

export default useContractHistory;
