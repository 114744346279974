import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  itemRoot: {
    display: "grid",
    gridTemplateColumns: "80px 1fr",
    alignItems: "center",
    padding: theme.spacing(1, 0),
  },
  infoContainer: {
    alignSelf: "end",
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  text: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    padding: theme.spacing(0, 1),
  },
  boldText: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    padding: theme.spacing(0, 1),
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: 60,
    borderRadius: 8,
    overflow: "hidden",
  },
  image: {
    width: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    marginTop: theme.spacing(1),
  },
}));
