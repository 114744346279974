import { IScheduleFeedback } from "apps/landlord/services/schedulerService/types";

const parseScheduleFeedback = (scheduleData: IScheduleFeedback) => ({
  id: scheduleData.schedule?.id,
  comment: scheduleData?.feedback,
  date: scheduleData.schedule?.end_date,
  houmer: scheduleData.schedule?.appraiser?.name,
});

export default parseScheduleFeedback;
