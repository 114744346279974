import Divider from "@material-ui/core/Divider";

import { ControlledSelection } from "Components/Admin/UIComponents/ControlledForm";
import { useClosureForm } from "context/closureFormContext/closureFormContext";

import { Props, filter, OptionTypes } from "./utils";
import useStyles from "../ClosureFormStyles";

interface SelectionProps extends Props {
  defaultValue: string | number | boolean | null;
  options: OptionTypes[];
  selectionProps?: Partial<React.ComponentProps<typeof ControlledSelection>>;
}

export const FilteredSelection: React.FC<SelectionProps> = ({
  id,
  fieldInfo,
  defaultValue,
  options,
  selectionProps,
}) => {
  const { state } = useClosureForm();
  if (filter(fieldInfo, state.isAdmin, state.propertyData.country)) return null;
  const classes = useStyles();
  return (
    <>
      <p className={classes.inputLabel}>
        {fieldInfo.label(state.propertyData.country)}
      </p>
      <ControlledSelection
        id={id}
        options={options}
        defaultValue={defaultValue}
        {...selectionProps}
      />
      <Divider className={classes.divider} />
    </>
  );
};

export default FilteredSelection;
