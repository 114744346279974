/* eslint-disable max-lines */
import { useHistory } from "react-router-dom";

import { IconButton, Tooltip } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";

import { accountTypes, renderAddress } from "utils";
import { PropertyData } from "models/Property";

import BankAccountsTable from "../BankAccountsTable";
import BankAccountCard from "../BankAccountCard";

interface Props {
  properties: Partial<PropertyData[]>;
}

const BankAccountList = ({ properties }: Props) => {
  const history = useHistory();

  const capitalizeBankName = (bankName) =>
    bankName
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");

  const redirectAction = (propertyUid: string) =>
    history.push(`/landlord/bank_accounts/${propertyUid}`);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      filterable: false,
    },
    {
      field: "address",
      headerName: "DIRECCIÓN",
      sortable: false,
      filterable: false,
      width: 250,
      renderCell: (rowContent) => renderAddress(rowContent.row),
    },
    {
      field: "typeAccount",
      flex: 1,
      headerName: "TIPO DE CUENTA",
      sortable: false,
      filterable: false,
      renderCell: (rowContent) =>
        rowContent.row.account
          ? accountTypes[rowContent.row.country].map(
              (type) =>
                type.code === rowContent.row.account.account_type && type.name
            )
          : "-",
    },
    {
      field: "counterNumber",
      flex: 1,
      headerName: "N° DE CUENTA",
      sortable: false,
      filterable: false,
      renderCell: (rowContent) =>
        rowContent.row.account ? rowContent.row.account.account_number : "-",
    },
    {
      field: "bankName",
      flex: 1,
      headerName: "BANCO",
      sortable: false,
      filterable: false,
      renderCell: (rowContent) =>
        rowContent.row.account
          ? capitalizeBankName(rowContent.row.account.bank_name)
          : "-",
    },
    {
      field: "actions",
      flex: 1,
      headerName: "VER",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (rowContent) => (
        <IconButton aria-label="Eliminar">
          <Tooltip title={rowContent.row.account ? "Editar" : "Añadir"}>
            {rowContent.row.account ? (
              <EditIcon
                color="primary"
                onClick={() => redirectAction(rowContent.row.uid)}
              />
            ) : (
              <AddCircleIcon
                color="primary"
                onClick={() => redirectAction(rowContent.row.uid)}
              />
            )}
          </Tooltip>
        </IconButton>
      ),
    },
  ];

  return (
    <BankAccountsTable
      columns={columns}
      data={properties}
      loading={false}
      type="bankAccount"
      mobileCard={<BankAccountCard onClick={redirectAction} />}
    />
  );
};

export default BankAccountList;
