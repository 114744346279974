import { useHistory } from "react-router-dom";

import Button from "@houm-com/ui/Button";

import CallToActionDrawer from "apps/ui/CustomDrawer/CallToActionDrawer";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji";

import useWelcomeStepStyles from "./WelcomeStepStyles";
import { STEPS } from "../../types";
import CREATE_PROPERTY_GUIDELINES from "./constants";
import WelcomeGuideline from "./WelcomeGuideline";

const WelcomeStep = () => {
  const classes = useWelcomeStepStyles();
  const history = useHistory();
  const { setCurrentStep } = useCustomDrawer();

  return (
    <>
      <section className="flex flex-col gap-4 p-4 grow md:p-8">
        <section className={classes.informationStepContentText}>
          <p className={classes.informationStepTitle}>
            ¡Publica tu propiedad en 4 pasos!
          </p>
          <p className={classes.informationStepContentText}>
            Simple, fácil y seguro con la mayor corredora digital del país.
            Conoce aquí cómo hacerlo <Emoji emojiIcon="👇" ariaLabel="aquí" />
          </p>
        </section>

        <section className={classes.informationStepContentText}>
          {CREATE_PROPERTY_GUIDELINES.map(WelcomeGuideline)}
        </section>

        <section className={classes.informationStepContentText}>
          <p className={classes.informationStepContentText}>
            ¡Luego podrás ver cómo van las visitas a tu propiedad, editar precio
            y mucho más directamente en tu portal!
          </p>
        </section>
      </section>
      <CallToActionDrawer>
        <Button
          variant="tertiary"
          size="md"
          onClick={() => history.push("/landlord")}
        >
          Atrás
        </Button>
        <Button
          onClick={() => setCurrentStep(STEPS.PropertyDetails)}
          variant="primary"
          size="md"
          type="button"
        >
          Siguiente
        </Button>
      </CallToActionDrawer>
    </>
  );
};

export default WelcomeStep;
