import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useLoiForm } from "context/loiFormContext/loiFormContext";
import useStyles from "./CustomCallToActionStyles";

export default function CustomCallToAction() {
  const { state } = useLoiForm();

  const classes = useStyles();

  return (
    <div className={classes.ctaRoot}>
      <div className={classes.ctaContent}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.ctaButton}
        >
          {state.submitting ? (
            <CircularProgress size={14} color="secondary" />
          ) : (
            state.navText
          )}
        </Button>
      </div>
    </div>
  );
}
