import { makeStyles, Theme } from "@material-ui/core";

const useApplicantStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginBottom: 0,
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(4),
    },
  },
  appBar: {
    display: "flex",
    alignItems: "flex-end",
  },
}));

export default useApplicantStyles;
