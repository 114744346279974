import Header from "Components/Layout/GlobalHeader";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import DogsImage from "assets/images/adminTenant/dogs-error.jpg";
import useMobileViewStyles from "./MobileViewStyles";
import ErrorText from "../ErrorText";

interface Props {
  onHandleClick: () => void;
}

const MobileView = ({ onHandleClick }: Props) => {
  const classes = useMobileViewStyles();

  return (
    <>
      <Header />
      <div className={classes.textContainer}>
        <ErrorText />
      </div>
      <img src={DogsImage} alt="error" className={classes.image} />
      <div className={classes.buttonContainer}>
        <DesignSystemButton
          label="Recargar la página"
          type="button"
          size="small"
          variant="primary"
          onClick={onHandleClick}
        />
      </div>
    </>
  );
};

export default MobileView;
