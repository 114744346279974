import { format } from "date-fns";

import { Locale } from "models/Countries";
import { Application } from "models/Evaluation";
import { numberWithCommas } from "utils";

const applicationInfo = (application: Application, country: Locale) => {
  const salaryField = country === "mx" ? "Salario total" : "Renta total";
  return {
    "Inicio contrato": format(
      new Date(application.contract_init_date),
      "dd/MM/yyyy"
    ),
    Vivirán: application.contract_habitants_description,
    Evaluados: application.quantity_applicants.toString(),
    Mascotas: application.has_pets ? "Sí" : "No",
    "Envio perfil": format(new Date(application.created_at), "dd/MM/yyyy"),
    [salaryField]: `$${numberWithCommas(application.total_salary)}`,
  };
};

export default applicationInfo;
