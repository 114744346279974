import { classes } from "apps/lead/pages/EvaluatorForm/components/Finish/FinishStyles";

const Finish = () => (
  <div className={classes.finishRoot}>
    <div className={classes.finishInformationRoot}>
      <div className={classes.finishInformationContainer}>
        <p className={classes.finishTitle}>¡Listo! Información completada</p>
        <div className={classes.finishTextContainer}>
          <p>
            Toda tu información está completada y lista para ser evaluada. Te
            contactaremos en la mayor brevedad posible.
          </p>
        </div>
      </div>
    </div>
    <div className={classes.finishImageContainer}>
      <img
        src="https://houm-production-public.s3.us-east-1.amazonaws.com/cms-content/success_advisor_data_0a6408e0d5.svg"
        alt="success-advisor-data"
        aria-label="success-advisor-data"
        className={classes.finishImage}
      />
    </div>
  </div>
);

export default Finish;
