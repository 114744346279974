import { useEffect, useState } from "react";

interface UseIntersectionObserverArgs {
  ref: React.MutableRefObject<HTMLDivElement>;
  options?: IntersectionObserverInit;
}

type UseIntersectionObserver = (args: UseIntersectionObserverArgs) => {
  isVisible: boolean;
};

const useIntsersectionObserver: UseIntersectionObserver = ({
  ref,
  options = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  },
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    }, options);
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [options, ref]);

  return {
    isVisible,
  };
};

export default useIntsersectionObserver;
