import { useTranslation } from "react-i18next";

import { Box, Typography } from "@material-ui/core";

import useEndHoumContractStatus from "apps/landlord/hooks/useEndHoumContractStatus";
import usePropertyLandlordRequests from "apps/landlord/hooks/propertyLandlordRequests";
import Spinner from "Components/Shared/partials/Spinner";
import { useProperty } from "apps/landlord/hooks";

import usePropertyLandlordRequestStyles from "./PropertyLandlordRequestStyles";
import RequestEndHoumContract from "./components/RequestEndHoumContract";
import RequestCard from "./components/RequestCard";

const PropertyLandlordRequests = () => {
  const { t } = useTranslation(["landlord.property.requests"]);
  const classes = usePropertyLandlordRequestStyles();
  const { propertyData, propertyLoading } = useProperty();
  const { isLoadingData, hasRequestsCards, requestsCardsCollection } =
    usePropertyLandlordRequests({
      propertyId: propertyData?.id,
    });
  const { isFetching, showEndHoumContractRequest, endContractHoumStatus } =
    useEndHoumContractStatus();

  if (isFetching || isLoadingData || propertyLoading) return <Spinner />;

  return (
    <Box className={classes.cardsContainer}>
      {!hasRequestsCards && !showEndHoumContractRequest && (
        <Box>
          <Typography className={classes.subtitle}>
            {t("PROPERTY_WITHOUT_REQUESTS")}
          </Typography>
        </Box>
      )}
      {requestsCardsCollection.map(
        ({ icon: CardIcon, title, description, onClick, ctaText }) => (
          <RequestCard
            ctaText={ctaText}
            description={description}
            onClick={onClick}
            title={title}
            CardIcon={CardIcon}
            key={title}
          />
        )
      )}
      {showEndHoumContractRequest && (
        <RequestEndHoumContract
          country={propertyData?.country}
          statusRequest={endContractHoumStatus?.status}
        />
      )}
    </Box>
  );
};

export default PropertyLandlordRequests;
