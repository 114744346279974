import TabPanel from "Components/Admin/UIComponents/DesignSystem/DesignSystemTab/TabPanel";
import { useTab } from "context/tabContext/tabContext";

import ApplicationsList from "./components/ApplicationsList";

const EvaluationsTabs = () => {
  const { state: tabState } = useTab();
  const { currentTabValue } = tabState;

  return (
    <TabPanel value={currentTabValue} index="evaluation">
      <ApplicationsList aria-labelledby="evaluation" />
    </TabPanel>
  );
};

export default EvaluationsTabs;
