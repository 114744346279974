import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useHistory, useParams } from "react-router-dom";

import useUser from "apps/account/hooks/useUser";
import { useNavbar } from "apps/ui/Navbar/context/navbarContext";
import Spinner from "Components/Shared/partials/Spinner";
import { countryCode } from "utils";

import ContentWrapper from "../../layout/components/ContentWrapper";
import useFavoritesStyles from "./FavoritesStyles";
import FavoritesHeader from "./components/FavoritesHeader";
import useGetFavorites from "./hooks/useGetFavorites";
import FavoritesList from "./components/FavoritesList";
import MarketplaceLink from "./components/MarketplaceLink";
import BackButton from "apps/tenant/components/BackButton";
import { FavoriteMode } from "./utils/types";

const Favorites = () => {
  const classes = useFavoritesStyles();
  const { setBackUrl } = useNavbar();
  const history = useHistory();

  const { user, isLoading: isUserLoading } = useUser();
  const country = countryCode(user?.country);

  const mode = useParams<{ mode: FavoriteMode }>()?.mode || "rent";

  useEffect(() => {
    if (isMobile) setBackUrl("default");
  }, [setBackUrl]);

  const {
    isLoading: isFavoritesLoading,
    data,
    refetch,
  } = useGetFavorites(mode);
  const hasFavorites = data?.length > 0 || isFavoritesLoading;

  if (isUserLoading) return <Spinner />;

  return (
    <ContentWrapper>
      {!isMobile && (
        <BackButton onClickHandler={() => history.push("/tenant")} />
      )}
      <FavoritesHeader countryCode={country} hasFavorites={hasFavorites} />
      <FavoritesList
        countryCode={country}
        propertiesList={data}
        refetchFavorites={refetch}
        isLoading={isFavoritesLoading}
        mode={mode}
      />
      {isMobile && hasFavorites && (
        <div className={classes.ctaContainer}>
          <MarketplaceLink countryCode={country} />
        </div>
      )}
    </ContentWrapper>
  );
};
export default Favorites;
