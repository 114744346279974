import { IBank, IUserBankAccount } from "apps/tenant/models/BankAccount";
import { IBankAccountResponse } from "apps/tenant/services/bankAccountsService/types";

export const parseUserBankAccounts = (
  raw: IBankAccountResponse[],
  banks: IBank[]
): IUserBankAccount[] =>
  raw.map((bankAccount) => ({
    document: bankAccount.document,
    fullName: bankAccount.holder,
    email: bankAccount.email,
    personId: bankAccount.person_id,
    identifier: bankAccount.identifier,
    bankName: bankAccount.bank_name,
    bankCode: bankAccount.bank_code,
    bankId: banks.find((bank) => bank.code === bankAccount.bank_code).bankId,
    accountNumber: bankAccount.account_number,
    accountType: bankAccount.account_type,
    createdAt: new Date(bankAccount.created_at),
    clabe: bankAccount.clabe_number,
  }));

export default parseUserBankAccounts;
