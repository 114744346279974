import { Link } from "react-router-dom";

import { Box } from "@material-ui/core";

import logo from "assets/images/houmLogo.svg";

import AuthDropdownMenu from "./components/AuthDropdownMenu";
import useDeskNavbarStyles from "./DeskNavbarStyles";
import MainMenu from "../MainMenu";
import BoldMenu from "../BoldMenu";

export default function DeskNavbar() {
  const classes = useDeskNavbarStyles();

  return (
    <Box className={classes.navbarDesktopWrapper}>
      <Box className={classes.navbarContentDesktop}>
        <Box className={classes.leftContainer}>
          <Link to="/" title="Ir a Houm">
            <img src={logo} alt="Houm" className={classes.logo} />
          </Link>
          <Box className={classes.navbarMenu}>
            <MainMenu />
          </Box>
        </Box>

        <Box className={classes.rightContainer}>
          <BoldMenu />
          <AuthDropdownMenu />
        </Box>
      </Box>
    </Box>
  );
}
