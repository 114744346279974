import { isMobile } from "react-device-detect";
import { Route, useRouteMatch } from "react-router-dom";

import { NEW_LANDLORD_LOI_FORM } from "env";

import Negotiation from "Components/Admin/landlordAdmin/pages/Negotiations";
import ClosureForm from "Components/Admin/landlordAdmin/pages/ClosureForm";
import NewLoiForm from "Components/Admin/landlordAdmin/pages/NewLoiForm";
import LoiForm from "Components/Admin/landlordAdmin/pages/LoiForm";

import UnfinishedSettingUpBanner from "../pages/PropertyUnfinishedSettingUp";
import PropertyLandlordRequests from "../pages/PropertyLandlordRequests";
import DrawerPropertyDetails from "../pages/DrawerPropertyDetails";
import AllInspectionReports from "../pages/AllInspectionReports";
import SchedulePhotoVisit from "../pages/SchedulePhotoVisit";
import RepairsRequestPage from "../pages/RepairsRequestPage";
import DrawerBankAccount from "../pages/DrawerBankAccount";
import BasicServices from "../pages/BasicServices";
import BasicServicesForm from "../pages/BasicServicesForm";
import InspectionReport from "../pages/InspectionReport";
import PropertyPayments from "../pages/PropertyPayments";
import PropertyDetailsV2 from "../pages/PropertyDetailsV2";
import PropertyDetails from "../pages/PropertyDetails";
import EndHoumContract from "../pages/EndHoumContract";
import PropertyCreate from "../pages/CreateProperty";
import EndOfContract from "../pages/EndOfContract";
import PaymentDetail from "../pages/PaymentDetail";
import SetPrice from "../pages/SetPrice";
import BankAccounts from "../pages/BankAccounts";
import ReferPartner from "../pages/ReferPartner";
import SubscriptionPlans from "../pages/plans";
import Applicant from "../pages/Applicant";
import PropertyPrice from "../pages/Price";
import Documents from "../pages/Documents";
import Schedule from "../pages/Schedule";
import Property from "../pages/Property";
import Summary from "../pages/Summary";

import { DrawerProvider } from "../hooks/useCustomDrawer/context";

const LandlordPrivateRoutes = () => {
  const match = useRouteMatch();

  return (
    <>
      <Route
        exact
        path={[
          `${match.path}/new-properties/:id`,
          `${match.path}/properties/:id`,
          `${match.path}/new-properties/:id/schedule-photo`,
          `${match.path}/properties/:id/schedule-photo`,
          `${match.path}/properties/:id/edit-details`,
          `${match.path}/properties/:id/bank-account`,
          `${match.path}/new-properties/:id/basic-services-setup`,
          `${match.path}/properties/:id/basic-services-setup`,
          `${match.path}/properties/:id/set-price`,
        ]}
      >
        <Property />
        <UnfinishedSettingUpBanner />
      </Route>
      <Route
        exact
        path={[
          `${match.path}/properties/:id/set-price`,
          `${match.path}/properties/:id/details/set-price`,
          `${match.path}/properties/:id/property-price/set-price`,
        ]}
      >
        <DrawerProvider>
          <SetPrice />
        </DrawerProvider>
      </Route>
      <Route exact path={`${match.path}/properties/:id/edit-details`}>
        <DrawerProvider>
          <DrawerPropertyDetails />
        </DrawerProvider>
      </Route>
      <Route exact path={`${match.path}/properties/:id/bank-account`}>
        <DrawerProvider>
          <DrawerBankAccount />
        </DrawerProvider>
      </Route>
      <Route exact path={`${match.path}/properties/:id/basic-services`}>
        <BasicServices />
      </Route>
      <Route
        exact
        path={[
          `${match.path}/new-properties/:id/basic-services-setup`,
          `${match.path}/properties/:id/basic-services-setup`,
        ]}
      >
        <BasicServicesForm />
      </Route>
      <Route
        exact
        path={[
          `${match.path}/new-properties/:id/plans`,
          `${match.path}/properties/:id/plans`,
        ]}
      >
        <SubscriptionPlans />
      </Route>
      <Route
        exact
        path={[
          `${match.path}/new-properties/:id/documents`,
          `${match.path}/properties/:id/documents`,
        ]}
      >
        <Documents />
        <UnfinishedSettingUpBanner />
      </Route>
      <Route
        exact
        path={[
          `${match.path}/new-properties/:id/details`,
          `${match.path}/properties/:id/details`,
          `${match.path}/properties/:id/details/set-price`,
        ]}
      >
        <PropertyDetails />
        <UnfinishedSettingUpBanner />
      </Route>
      <Route exact path={`${match.path}/properties/:id/property-details`}>
        <PropertyDetailsV2 />
      </Route>
      <Route
        exact
        path={[
          match.path,
          `${match.path}/summary`,
          `${match.path}/create-property`,
        ]}
      >
        <Summary />
      </Route>
      <Route
        exact
        path={[
          `${match.path}/bank_accounts`,
          `${match.path}/bank_accounts/:id`,
        ]}
      >
        <BankAccounts />
      </Route>
      <Route exact path={`${match.path}/bank_accounts/:id`}>
        <DrawerProvider>
          <DrawerBankAccount parentUrl="/landlord/bank_accounts" />
        </DrawerProvider>
      </Route>
      <Route exact path={`${match.path}/refer-partner`}>
        <ReferPartner />
      </Route>
      <Route
        exact
        path={[
          `${match.path}/new-properties/:countryCode/applicant/:id`,
          `${match.path}/properties/:countryCode/applicant/:id`,
        ]}
      >
        <Applicant />
        <UnfinishedSettingUpBanner />
      </Route>
      <Route exact path={`${match.path}/closureForm/:id`}>
        <ClosureForm />
      </Route>
      <Route exact path={`${match.path}/negotiations/:id`}>
        <Negotiation />
      </Route>
      <Route exact path={`${match.path}/create-property`}>
        <DrawerProvider>
          <PropertyCreate />
        </DrawerProvider>
      </Route>
      <Route exact path={`${match.path}/properties/:id/schedule-photo`}>
        <DrawerProvider>
          <SchedulePhotoVisit />
        </DrawerProvider>
      </Route>
      <Route exact path={`${match.path}/properties/:id/payments`}>
        <PropertyPayments />
        <UnfinishedSettingUpBanner />
      </Route>
      <Route
        exact
        path={`${match.path}/properties/:id/payment_detail/:movementId`}
      >
        <PaymentDetail />
      </Route>
      <Route
        exact
        path={[
          `${match.path}/properties/:id/property-price`,
          `${match.path}/properties/:id/property-price/set-price`,
        ]}
      >
        <PropertyPrice />
        <UnfinishedSettingUpBanner />
      </Route>
      <Route
        exact
        path={[
          `${match.path}/new-properties/:id/schedules`,
          `${match.path}/properties/:id/schedules`,
        ]}
      >
        <Schedule />
        <UnfinishedSettingUpBanner />
      </Route>
      <Route
        exact={isMobile}
        path={[
          `${match.path}/new-properties/:id/property-requests`,
          `${match.path}/properties/:id/property-requests`,
        ]}
      >
        <PropertyLandlordRequests />
        <UnfinishedSettingUpBanner />
      </Route>
      <Route
        exact
        path={[
          `${match.path}/new-properties/:id/property-requests/end-contract`,
          `${match.path}/properties/:id/property-requests/end-contract`,
        ]}
      >
        <EndOfContract />
      </Route>
      <Route
        exact
        path={[
          `${match.path}/new-properties/:id/property-requests/repairs-request`,
          `${match.path}/properties/:id/property-requests/repairs-request`,
        ]}
      >
        <RepairsRequestPage />
      </Route>
      <Route
        exact
        path={[
          `${match.path}/new-properties/:id/property-requests/end-houm-contract`,
          `${match.path}/properties/:id/property-requests/end-houm-contract`,
        ]}
      >
        <EndHoumContract />
      </Route>
      <Route exact path={`${match.path}/properties/:id/inspections-report`}>
        <AllInspectionReports />
        <UnfinishedSettingUpBanner />
      </Route>
      <Route
        exact
        path={`${match.path}/properties/:id/inspections-report/:reportId`}
      >
        <InspectionReport />
      </Route>
      <Route exact path={`${match.path}/loiForm/:id`}>
        {NEW_LANDLORD_LOI_FORM ? <NewLoiForm /> : <LoiForm />}
      </Route>
    </>
  );
};

export default LandlordPrivateRoutes;
