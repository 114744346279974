import { ChangeEvent } from "react";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import {
  useAntTabsStyles,
  useNegotiatonsTabsStyles,
  useTabStyles,
} from "./NegotiationsTabsStyles";

type NegotiationTabsProps = {
  tabsNames: string[];
  handleChangeTab: (event: ChangeEvent<{}>, newValue: number) => void;
  value: number;
};

export default function NegotiationTabs({
  tabsNames,
  handleChangeTab,
  value,
}: NegotiationTabsProps) {
  const classesNegotiation = useNegotiatonsTabsStyles();
  const classesTab = useTabStyles();
  const classesAntTab = useAntTabsStyles();

  return (
    <div className={classesNegotiation.root}>
      <Tabs
        value={value}
        onChange={handleChangeTab}
        aria-label="negotiations tabs"
        classes={classesAntTab}
      >
        {tabsNames.map((tabName) => (
          <Tab
            disableRipple
            key={tabName}
            label={tabName}
            classes={classesTab}
          />
        ))}
      </Tabs>
    </div>
  );
}
