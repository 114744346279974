import { SnackbarPayload } from "libs/houm-snackbar/types";

export const SUCCESS_SUBMIT_MESSAGE: SnackbarPayload = {
  body: "¡Felicidades, tu propiedad ha sido creada, ahora vamos por las fotos!",
  type: "success",
};

export const FAILED_SUBMIT_MESSAGE: SnackbarPayload = {
  body: "Lo sentimos, tuvimos un problema creando tu propiedad, por favor inténtalo de nuevo.",
  type: "error",
};

export const FAILED_VALIDATION_MESSAGE: SnackbarPayload = {
  body: "Lo sentimos, tuvimos un problema validando tu propiedad, por favor inténtalo de nuevo.",
  type: "error",
};
