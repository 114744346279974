import clx from "classnames";

import { Chip, Grid } from "@material-ui/core";
import { PersonStatus } from "apps/lead/models/Application";

import useStatusTagStyles from "./StatusTagStyles";

interface Props {
  status: PersonStatus;
}

const StatusTag = ({ status }: Props) => {
  const classes = useStatusTagStyles();
  return (
    <Grid item xs={6} container justifyContent="center">
      <Chip
        label={status}
        className={clx(classes.chip, {
          [classes.chipRejected]:
            status === "Desistido" || status === "Rechazado",
          [classes.chipApproved]:
            status === "Aprobado comercialmente" ||
            status === "Perfil aprobado",
          [classes.chipProcess]:
            status === "Por comenzar" ||
            status === "Listo para evaluar" ||
            status === "Nuevos documentos",
          [classes.chipInformation]: status === "Documentación pendiente",
        })}
      />
    </Grid>
  );
};

export default StatusTag;
