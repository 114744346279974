import { SyntheticEvent, useCallback, useState } from "react";

type AlertType = "success" | "info" | "warning" | "error";

export const useAlertMessage = (closeAction: () => void) => {
  const [responseMessage, setResponseMessage] = useState({
    open: false,
    type: "success" as AlertType,
    message: {
      type: "success" as AlertType,
      text: "",
    },
  });

  const handleOpenAlert = useCallback(
    // eslint-disable-next-line default-param-last
    (openFlag: boolean, type: AlertType = "success", text: string) => {
      setResponseMessage({
        open: openFlag,
        type,
        message: {
          type,
          text,
        },
      });
    },
    [responseMessage.open]
  );

  const handleCloseAlert = useCallback(
    (event?: SyntheticEvent, res?: string) => {
      if (res === "clickaway") {
        return;
      }
      setResponseMessage({
        open: false,
        type: "success",
        message: {
          type: "success",
          text: "",
        },
      });
      if (closeAction) closeAction();
    },
    []
  );

  return {
    responseMessage,
    handleOpenAlert,
    handleCloseAlert,
  };
};

export default useAlertMessage;
