import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { FieldErrors, FieldValues } from "react-hook-form";
import { isObject } from "lodash";

interface Options<FormFields> {
  errors: FieldErrors<FormFields>;
  requiredText: string;
  requiredErrorMessage?: string;
}

/**
 *
 *
 * @param obj error validation object
 * @param keyToSearch
 * @param accumulated
 * @returns array of error messages
 */

const deepFind = (obj, keyToSearch = "message", accumulated = []): string[] => {
  if (isObject(obj)) {
    Object.entries(obj).forEach(([key, val]) => {
      if (isObject(val)) deepFind(val, keyToSearch, accumulated);
      if (key === keyToSearch) {
        accumulated.push(val);
      }
    });
  }
  return accumulated;
};

function useFormNotifications<FormFields extends FieldValues>({
  errors,
  requiredText,
  requiredErrorMessage = "Faltan campos obligatorios",
}: Options<FormFields>) {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const deepMessages = Object.values(errors).filter(
      (error) => !error.message
    );

    const deepMessagesInArray = deepFind(deepMessages);

    deepMessagesInArray
      .filter((message) => message !== requiredText)
      .forEach((message) => {
        enqueueSnackbar(message, { variant: "error" });
      });

    Object.values(errors)
      .filter((error) => error.message)
      .filter((error) => error.message !== requiredText)
      .forEach((error) =>
        enqueueSnackbar(error?.message, { variant: "error" })
      );
    Object.values(errors)
      .filter((error) => error.message === requiredText)
      .slice(0, 1)
      .forEach(() =>
        enqueueSnackbar(requiredErrorMessage, { variant: "error" })
      );
  }, [errors]);
}

export default useFormNotifications;
