import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

interface IPropStyle {
  height: "vh" | "%";
}

const useStyles = makeStyles<Theme, IPropStyle>((theme) => ({
  mainContainer: {
    background: "white",
    height: (props) => `100${props.height}`,
  },
  button: {
    bottom: 0,
    borderRadius: 0,
    height: "55px",
    fontSize: "1rem",
    left: 0,
    position: "fixed",
    textTransform: "none",
    width: "100%",
    maxWidth: "none",
    justifyContent: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      borderRadius: "10px",
      marginTop: theme.spacing(4.25),
      position: "relative",
      width: "80%",
    },
  },
  logo: {
    height: "33px",
    width: "69px",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      height: "56px",
      width: "120px",
    },
  },
  logoContainer: {
    background: "white",
    boxShadow: "4px 4px 30px rgba(0, 43, 41, 0.15)",
    height: "56px",
    left: 0,
    position: "fixed",
    top: 0,
    width: "100vw",
    zIndex: 1200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(10, 12),
    },
  },
  logoContainerDesktop: {
    padding: theme.spacing(5, 12),
  },
  container: {
    background: "white",
    padding: theme.spacing(10, 5, 11, 5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(5, 12, 10, 12),
    },
  },
  infoContainer: {
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginRight: theme.spacing(10),
    },
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    margin: "auto",
  },
  imageContainer: {
    display: "flex",
    height: "100%",
    alignContent: "center",
    justifyContent: "center",
  },
  label: {
    fontSize: "0.875rem",
    lineHeight: "1rem",
    fontWeight: 600,
    color: theme.palette.grey[200],
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginBottom: theme.spacing(3),
    },
  },
}));

export default useStyles;
