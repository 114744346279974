import { useHistory } from "react-router-dom";
import Button from "@houm-com/ui/Button";
import LightButton from "@houm-com/ui/LightButton";
import { IoArrowForward as ArrowIcon } from "@houm-com/ui-icons";

import { Box } from "@material-ui/core";

import { useMarket } from "apps/tenant/context/MarketContext";

import useVisitsLinksStyles from "./VisitsLinksStyles";
import { getMarketplaceLink } from "./utils";

const VisitsLinks = () => {
  const classes = useVisitsLinksStyles();
  const history = useHistory();
  const { market } = useMarket();

  return (
    <Box className={classes.visitsLinksRoot}>
      <Button
        size="md"
        variant="primary"
        onClick={() => history.push("/tenant/favorites")}
      >
        Propiedades favoritas <ArrowIcon />
      </Button>
      <LightButton
        size="md"
        variant="primary"
        icon={<ArrowIcon />}
        position="end"
        onClick={() => window.open(getMarketplaceLink(market), "_blank")}
      >
        Buscar más propiedades
      </LightButton>
    </Box>
  );
};

export default VisitsLinks;
