import clx from "classnames";

import CardMedia from "@material-ui/core/CardMedia";
import { Chip, Container } from "@material-ui/core";

import PhotoPlaceholder from "assets/images/vacant_image.png";
import usePropertyImageStyles from "./PropertyImageStyles";

interface Props {
  photo: string | null;
  propertyId: number;
  statusLabel: string;
  chipClassName?: string;
}

const PropertyImage = ({
  photo,
  propertyId,
  statusLabel,
  chipClassName = "",
}: Props) => {
  const classes = usePropertyImageStyles();
  return (
    <Container className={classes.cardMediaContainer}>
      <CardMedia
        image={photo ?? PhotoPlaceholder}
        title="property"
        className={classes.media}
        role="img"
      />
      <Container className={classes.chipContainer}>
        <Chip
          label={`ID ${propertyId}`}
          size="small"
          className={clx(classes.chip, classes.chipId)}
        />
        <Chip
          label={statusLabel}
          size="small"
          className={clx(classes.chip, chipClassName)}
          role="status"
        />
      </Container>
    </Container>
  );
};

export default PropertyImage;
