import { useEffect } from "react";
import { isMobile } from "react-device-detect";

import { Drawer } from "@material-ui/core";

import { useNavbar } from "apps/ui/Navbar/context/navbarContext";

import ScheduleForm from "./components/ScheduleForm";
import useExitVisitScheduleStyles from "./ExitVisitScheduleStyles";

const ExitVisitSchedule = () => {
  const classes = useExitVisitScheduleStyles();
  const { setBackUrl } = useNavbar();

  useEffect(() => {
    if (isMobile) setBackUrl("/tenant/help");
  }, [setBackUrl]);

  if (isMobile) return <ScheduleForm />;

  return (
    <Drawer
      anchor="right"
      open={true}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <ScheduleForm />
    </Drawer>
  );
};

export default ExitVisitSchedule;
