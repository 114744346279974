import { CountryUpperCase } from "models/Countries";
import { COUNTRY_ADDRESS } from "./constants";

export interface FieldProp {
  label: (country: string) => string;
  admin: boolean;
  countries: CountryUpperCase[];
}

const property = {
  address: (): FieldProp => ({
    label: (country) => COUNTRY_ADDRESS[country],
    admin: false,
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  addressNumber: (): FieldProp => ({
    label: (country) => "Número",
    admin: false,
    countries: ["Chile"],
  }),
  outerNumber: (): FieldProp => ({
    label: (country) => "Número exterior",
    admin: false,
    countries: ["Mexico"],
  }),
  innerNumber: (): FieldProp => ({
    label: (country) => "Número interior",
    admin: false,
    countries: ["Mexico"],
  }),
  neighborhood: (): FieldProp => ({
    label: (country) => "Colonia",
    admin: false,
    countries: ["Mexico"],
  }),
  commune: (): FieldProp => ({
    label: (country) =>
      country === "Mexico" ? "Alcaldía/Municipio" : "Comuna",
    admin: false,
    countries: ["Mexico", "Chile"],
  }),
  region: (): FieldProp => ({
    label: (country) => "Estado",
    admin: false,
    countries: ["Mexico"],
  }),
  country: (): FieldProp => ({
    label: (country) => "País",
    admin: true,
    countries: ["Colombia"],
  }),
  postalCode: (): FieldProp => ({
    label: (country) => "Código postal",
    admin: false,
    countries: ["Mexico"],
  }),
  rentValue: (): FieldProp => ({
    label: (country) => {
      switch (country) {
        case "Chile":
          return "Valor del arriendo";
        default:
          return "Valor del cánon";
      }
    },
    admin: true,
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  availableDate: (): FieldProp => ({
    label: (country) => "Fecha a partir de la que está disponible",
    admin: false,
    countries: ["Mexico"],
  }),
  isFlat: (): FieldProp => ({
    label: (country) => "Tipo de inmueble",
    admin: false,
    countries: ["Mexico"],
  }),
  isHorizontal: (): FieldProp => ({
    label: (country) => "¿Hace parte a propiedad horizontal?",
    admin: true,
    countries: ["Colombia"],
  }),
  departmentNumber: (): FieldProp => ({
    label: (country) => "Número departamento",
    admin: false,
    countries: ["Chile"],
  }),
  isFurnished: (): FieldProp => ({
    label: (country) => "Propiedad amoblada",
    admin: false,
    countries: ["Colombia"],
  }),
  parkingNumbers: (): FieldProp => ({
    label: (country) => "Número de estacionamiento(s)",
    admin: false,
    countries: ["Colombia", "Chile"],
  }),
  isUrban: (): FieldProp => ({
    label: (country) => "Inmueble urbano/rural",
    admin: true,
    countries: ["Colombia", "Mexico"],
  }),
  inCondo: (): FieldProp => ({
    label: (country) => "Está en un condominio",
    admin: false,
    countries: ["Mexico"],
  }),
  publicDeed: (): FieldProp => ({
    label: (country) => "Tiene escritura pública",
    admin: false,
    countries: ["Mexico"],
  }),
  publicDeedNumber: (): FieldProp => ({
    label: (country) => "Número de escritura pública",
    admin: false,
    countries: ["Mexico"],
  }),
  publicDeedDate: (): FieldProp => ({
    label: (country) => "Fecha de escritura pública",
    admin: false,
    countries: ["Mexico"],
  }),
  notary: (): FieldProp => ({
    label: (country) => "Nombre notario",
    admin: false,
    countries: ["Mexico"],
  }),
  notaryNumber: (): FieldProp => ({
    label: (country) => "Número notario",
    admin: false,
    countries: ["Mexico"],
  }),
  propertyRegistrationNumber: (): FieldProp => ({
    label: (country) => {
      switch (country) {
        case "Mexico":
          return "Folio real";
        case "Colombia":
        default:
          return "Número de matrícula inmobiliraio";
      }
    },
    admin: false,
    countries: ["Colombia", "Mexico"],
  }),
  predialNumber: (): FieldProp => ({
    label: (country) => "Número de cuenta predial",
    admin: false,
    countries: ["Mexico"],
  }),
  hasCommonExpenses: (): FieldProp => ({
    label: (country) => "¿Incluye cuota de administración?",
    admin: false,
    countries: ["Mexico"],
  }),
  commonExpenses: (): FieldProp => ({
    label: (country) => {
      switch (country) {
        case "Chile":
          return "Gastos comunes";
        default:
          return "Valor cuota de administración";
      }
    },
    admin: false,
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  mortgage: (): FieldProp => ({
    label: (country) => "Especificación de inmueble (hipoteca, embargo, etc.)",
    admin: true,
    countries: ["Colombia"],
  }),
  domainRestriction: (): FieldProp => ({
    label: (country) => "¿Tiene alguna restricción de dominio?",
    admin: true,
    countries: ["Colombia"],
  }),
  paymentCondition: (): FieldProp => ({
    label: (country) => "Condición especial de pago",
    admin: true,
    countries: ["Colombia"],
  }),
  chargeCondition: (): FieldProp => ({
    label: (country) => "Condición especial de cobro",
    admin: true,
    countries: ["Colombia"],
  }),
  policy: (): FieldProp => ({
    label: (country) => "Póliza",
    admin: true,
    countries: ["Colombia"],
  }),
  warranty: (): FieldProp => ({
    label: (country) => "Valor depósito de seguridad",
    admin: false,
    countries: ["Mexico"],
  }),
  warehouseNumber: (): FieldProp => ({
    label: (country) => {
      switch (country) {
        case "Chile":
          return "Número de bodega(s)";
        case "Colombia":
          return "Número de depósito";
        default:
          return "";
      }
    },
    admin: false,
    countries: ["Chile", "Colombia"],
  }),
  maxPeopleQuantity: (): FieldProp => ({
    label: (country) => "Número personas que pueden habitar",
    admin: false,
    countries: ["Chile"],
  }),
};

const landlord = {
  name: (): FieldProp => ({
    label: (country) => "Nombre y apellido",
    admin: false,
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  userType: (): FieldProp => ({
    label: (country) => "Tipo de usuario",
    admin: true,
    countries: ["Colombia", "Mexico"],
  }),
  rfc: (): FieldProp => ({
    label: (country) => "RFC",
    admin: false,
    countries: ["Mexico"],
  }),
  document: (): FieldProp => ({
    label: (country) => (country === "Chile" ? "Rut" : "Número de documento"),
    admin: false,
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  documentType: (): FieldProp => ({
    label: (country) => "Tipo de documento",
    admin: false,
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  phoneNumber: (): FieldProp => ({
    label: (country) => "Teléfono",
    admin: false,
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  email: (): FieldProp => ({
    label: (country) => "Correo electrónico",
    admin: false,
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  address: (): FieldProp => ({
    label: (country) => "Dirección actual",
    admin: false,
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  city: (): FieldProp => ({
    label: (country) => "Ciudad",
    admin: false,
    countries: ["Colombia"],
  }),
  country: (): FieldProp => ({
    label: (country) => "País",
    admin: true,
    countries: ["Colombia"],
  }),
  civilStatus: (): FieldProp => ({
    label: (country) => "Estado civil",
    admin: false,
    countries: ["Mexico"],
  }),
  maritalRegime: (): FieldProp => ({
    label: (country) => "Régimen matrimonial",
    admin: false,
    countries: ["Mexico"],
  }),
  ownWarehouse: (): FieldProp => ({
    label: (country) => "Común o privado",
    admin: false,
    countries: ["Colombia"],
  }),
  isIvaResponsible: (): FieldProp => ({
    label: (country) => "Régimen tributario",
    admin: false,
    countries: ["Colombia"],
  }),
  specialCondition: (): FieldProp => ({
    label: (country) => "Observaciones de mandato",
    admin: true,
    countries: ["Colombia"],
  }),
  rutPhoto: (): FieldProp => ({
    label: (country) => "Foto del documento de identificación",
    admin: false,
    countries: ["Colombia"],
  }),
  isNew: (): FieldProp => ({
    label: (country) => "Propiedad nueva",
    admin: false,
    countries: ["Colombia"],
  }),
  landlordBrokerageCommission: (): FieldProp => ({
    label: (country) => "Comisión de corretaje propietario",
    admin: true,
    countries: ["Mexico"],
  }),
  tenantBrokerageCommission: (): FieldProp => ({
    label: (country) => "Comisión de corretaje arrendatario",
    admin: true,
    countries: ["Mexico"],
  }),
  occupation: (): FieldProp => ({
    label: (country) => "Ocupación",
    admin: false,
    countries: ["Mexico"],
  }),
};

const thirdParty = {
  name: (): FieldProp => ({
    label: (country) => "Nombre",
    admin: false,
    countries: ["Colombia"],
  }),
  document: (): FieldProp => ({
    label: (country) => "Número de documento",
    admin: false,
    countries: ["Colombia"],
  }),
  documentType: (): FieldProp => ({
    label: (country) => "Tipo de documento",
    admin: false,
    countries: ["Colombia"],
  }),
  email: (): FieldProp => ({
    label: (country) => "Correo electrónico",
    admin: false,
    countries: ["Colombia"],
  }),
  phone: (): FieldProp => ({
    label: (country) => "Teléfono",
    admin: false,
    countries: ["Colombia"],
  }),
  address: (): FieldProp => ({
    label: (country) => "Dirección",
    admin: false,
    countries: ["Colombia"],
  }),
  country: (): FieldProp => ({
    label: (country) => "País",
    admin: true,
    countries: ["Colombia"],
  }),
};

const publicServices = {
  waterService: (): FieldProp => ({
    label: (country) => "Proveedor de agua",
    admin: false,
    countries: ["Colombia"],
  }),
  waterClient: (): FieldProp => ({
    label: (country) => "Número de proveedor de agua",
    admin: false,
    countries: ["Colombia"],
  }),
  energyService: (): FieldProp => ({
    label: (country) => "Proveedor de energía",
    admin: false,
    countries: ["Colombia"],
  }),
  energyClient: (): FieldProp => ({
    label: (country) => "Número de proveedor de energía",
    admin: false,
    countries: ["Colombia"],
  }),
  gasService: (): FieldProp => ({
    label: (country) => "Proveedor de gas",
    admin: false,
    countries: ["Colombia"],
  }),
  gasClient: (): FieldProp => ({
    label: (country) => "Número de proveedor de gas",
    admin: false,
    countries: ["Colombia"],
  }),
  cleaningClient: (): FieldProp => ({
    label: (country) => "Proveedor de limpieza",
    admin: false,
    countries: ["Colombia"],
  }),
  includedServices: (): FieldProp => ({
    label: (country) => "Servicios públicos incluídos",
    admin: true,
    countries: ["Colombia"],
  }),
  additionalServices: (): FieldProp => ({
    label: (country) => "Servicios, cosas o usos conexos adicionales",
    admin: true,
    countries: ["Colombia"],
  }),
};

const adminstration = {
  hasHoumCleaning: (): FieldProp => ({
    label: (country) => "Limpieza gestionada por Houm",
    admin: false,
    countries: ["Colombia", "Mexico"],
  }),
  cleaningAmount: (): FieldProp => ({
    label: (country) => "Valor de servicio de limpieza Houm",
    admin: true,
    countries: ["Mexico"],
  }),
};

const files = {
  ccFile: (): FieldProp => ({
    label: (country) => "Copia de la CC",
    admin: false,
    countries: ["Colombia"],
  }),
  predialFile: (): FieldProp => ({
    label: (country) => "Copia del último predial del inmueble",
    admin: false,
    countries: ["Colombia"],
  }),
  energyReceipt: (): FieldProp => ({
    label: (country) => "Copia del último recibo de energía",
    admin: false,
    countries: ["Colombia"],
  }),
  gasReceipt: (): FieldProp => ({
    label: (country) => "Copia del último recibo de gas",
    admin: false,
    countries: ["Colombia"],
  }),
  waterReceipt: (): FieldProp => ({
    label: (country) => "Copia del último recibo de agua",
    admin: false,
    countries: ["Colombia"],
  }),
  cleaningReceipt: (): FieldProp => ({
    label: (country) => "Copia del último recibo de aseo",
    admin: false,
    countries: ["Colombia"],
  }),
  administrationReceipt: (): FieldProp => ({
    label: (country) => "Copia del último recibo de administración",
    admin: false,
    countries: ["Colombia"],
  }),
  traditionLibertyFile: (): FieldProp => ({
    label: (country) => "Copia del certificado de Tradición y Libertad",
    admin: false,
    countries: ["Colombia"],
  }),
  publicWriting: (): FieldProp => ({
    label: (country) => "Escritura pública",
    admin: false,
    countries: ["Mexico"],
  }),
  predialVoucher: (): FieldProp => ({
    label: (country) => "Comprobante predial",
    admin: false,
    countries: ["Mexico"],
  }),
};

export default {
  property,
  landlord,
  thirdParty,
  publicServices,
  adminstration,
  files,
};
