import { Box } from "@material-ui/core";

import { useNavbar } from "../../context/navbarContext";
import { produceKey } from "../../utils/utils";

import NavbarItem from "../NavbarItem";
import useMainMenuStyles from "./MainMenuStyles";
import { getMainMenuContent } from "./utils/utils";

const MainMenu = () => {
  const classes = useMainMenuStyles();
  const { country } = useNavbar();

  return (
    <Box className={classes.container}>
      {getMainMenuContent(country).map((item) => (
        <NavbarItem key={produceKey(item)} {...item} />
      ))}
    </Box>
  );
};
export default MainMenu;
