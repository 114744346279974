import { RepairmentType } from "apps/tenant/services/tenantRepairsService/types";

const REPAIRMENT_TYPE_LABEL: Record<RepairmentType, string> = {
  plumbing: "Plomería / Gasfitería",
  locksmith: "Cerrajería",
  heating: "Calefacción",
  structural: "Estructurales",
  cosmetic_repairs: "Arreglos estéticos",
  electrical: "Eléctrico",
  electronic: "Electrodoméstico",
  cleaning: "Limpieza",
};

export default REPAIRMENT_TYPE_LABEL;
