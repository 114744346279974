import { makeStyles, Theme } from "@material-ui/core";

const useApplicationsRecordStyles = makeStyles<Theme>((theme) => ({
  historialContainer: {
    display: "flex",
    margin: theme.spacing(3, "auto"),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(5, "auto"),
    },
  },
  historialCardsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

export default useApplicationsRecordStyles;
