import { Locale } from "models/Countries";

export const getMarketplaceLink = (market: Locale) => {
  switch (market) {
    case "cl":
      return "https://houm.com/cl/arriendo-departamentos-region-metropolitana";
    case "co":
      return "https://houm.com/co/arriendo-apartamentos-bogota";
    case "mx":
      return "https://houm.com/mx/renta-departamentos-ciudad-de-mexico";
    default:
      return "https://houm.com/";
  }
};

export default getMarketplaceLink;
