import { ParseMissingSettingUpData } from "./types";

const parseMissingSettingUpData: ParseMissingSettingUpData = (missingData) => {
  const pendingRentalPrice = missingData.price_rental && missingData.for_rental;
  const pendingSalePrice = missingData.price_sale && missingData.for_sale;
  const pendingSettingUpPrice = pendingRentalPrice || pendingSalePrice;

  const pendingRentalPhotoSchedule =
    !missingData.scheduled_photos_rent && missingData.for_rental;
  const pendingSalePhotoSchedule =
    !missingData.scheduled_photos_sale && missingData.for_sale;
  const pendingSchedulePhotos =
    pendingRentalPhotoSchedule || pendingSalePhotoSchedule;

  return {
    missingBasicServicesAccount: missingData.basic_services,
    pendingPhotoSchedule: pendingSchedulePhotos,
    pendingPhotosButScheduled: missingData.photos && !pendingSchedulePhotos,
    pendingBasicCharacteristics: missingData.basic_characteristics,
    pendingSettingUpPrice,
  };
};

export default parseMissingSettingUpData;
