import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { Box } from "@material-ui/core";

import { IoCheckmarkOutline } from "@houm-com/ui-icons";

import { IAuthMenuItem } from "../../../../utils/types";
import useAuthMenuItemStyles from "./AuthMenuItemStyles";
import NavbarLabel from "../../../NavbarLabel";

const AuthMenuItem = ({
  isLogout,
  label,
  link,
  onClick,
  nested,
  selected,
}: IAuthMenuItem) => {
  const classes = useAuthMenuItemStyles({ nested, selected });
  const history = useHistory();

  const handleClick = () => {
    if (onClick) onClick();
    else history.push(link);
  };

  if (isMobile && isLogout) return null;

  return (
    <Box className={classes.authMenuItemRoot} onClick={handleClick}>
      {nested && <IoCheckmarkOutline size={19} className={classes.check} />}
      <NavbarLabel label={label} />
    </Box>
  );
};

export default AuthMenuItem;
