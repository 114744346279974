import {
  IoCardOutline as CardIcon,
  IoHomeOutline as HouseIcon,
} from "@houm-com/ui-icons";

export const DESKTOP_HEADER_SPACING = 14; // 122px / 8px
export const DESKTOP_PADDING = 4; // 32px / 8px
export const DESKTOP_HEADER_HEIGHT = 70;
export const MOBILE_HEADER_SPACING = 9.5; // 76px / 8px
export const MOBILE_PADDING = 2; // 16px / 8px
export const MOBILE_HEADER_HEIGHT = 52;
export const MOBILE_BOTTOM_NAVBAR_HEIGHT = 8; // 64px

export const DESKTOP_NAVBAR_BANNER_HEIGHT = 56; // 56px
export const MOBILE_NAVBAR_BANNER_HEIGHT = 80; // 76px

export const menuItems = [
  {
    title: "Propiedades",
    icon: <HouseIcon size={20} />,
    path: "/landlord/summary",
    isActiveRoutes: ["/landlord/summary", "/propietary", "/landlord"],
    handleClick: () => {},
  },
  {
    title: "Cuentas bancarias",
    icon: <CardIcon size={20} />,
    path: "/landlord/bank_accounts",
    isActiveRoutes: ["/propietary/bank_accounts", "/landlord/bank_accounts"],
    handleClick: () => {},
  },
];
