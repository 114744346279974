import TenantLayout from "apps/tenant/layout/TenantLayout";
import ExitVisitSchedule from "./ExitVisitSchedule";

export default function () {
  return (
    <TenantLayout>
      <ExitVisitSchedule />
    </TenantLayout>
  );
}
