import { makeStyles, Theme } from "@material-ui/core";

const useCardApplicantStyles = makeStyles<Theme>((theme) => ({
  card: {
    minHeight: 76,
    border: `1px solid ${theme.palette.grey[75]}`,
    borderRadius: 16,
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 6.5),
    "&:hover": {
      cursor: "pointer",
      border: "1px solid #4C6977",
      boxShadow: "3px 4px 25px rgba(38, 50, 56, 0.15)",
    },
  },
  cardSelected: {
    border: "1px solid #4C6977",
    boxShadow: "3px 4px 25px rgba(38, 50, 56, 0.15)",
  },
  status: {
    textAlign: "center",
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
  },
  textBold: {
    fontWeight: 700,
  },
}));

export default useCardApplicantStyles;
