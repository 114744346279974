import { classes } from "./PaymentHeaderStyles";

interface Props {
  title: string;
}

const PaymentHeader = ({ title }: Props) => (
  <div className={classes.header}>
    <h1 className={classes.title}>{title}</h1>
  </div>
);

export default PaymentHeader;
