import { makeStyles, Theme } from "@material-ui/core";

const useExitVisitScheduleStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
  },
}));

export default useExitVisitScheduleStyles;
