import { ApplicationModel } from "apps/lead/models/Application";
import { Application } from "apps/lead/services/ApplicationsService/types";
import { EvaluationByApplicantIds } from "apps/lead/services/EvaluationService/types";

const frontendApplicationParser = (
  applicationData: {
    data: Application;
    evaluationData: EvaluationByApplicantIds[];
  }[]
): ApplicationModel[] =>
  applicationData.map((application) => ({
    id: application?.data.id,
    rut: application?.data.rut,
    phone: application?.data.phone,
    email: application?.data.email,
    createdAt: application?.data.created_at,
    name: application?.data.name,
    lastName: application?.data.last_name,
    secondLastName: application?.data.second_last_name,
    document: application?.data.document,
    documentType: application?.data.document_type,
    property: {
      uid: application?.data.property?.uid,
      id: application?.data.property?.id,
      address: application?.data.property?.address,
      comuna: application?.data.property?.comuna,
      region: application?.data.property?.region,
      type: application?.data.property?.type,
      city: application?.data.property?.city,
      country: application?.data.property?.country,
      numeroDepto: application?.data.property?.numero_depto,
      streetNumber: application?.data.property?.street_number,
      photoUrl: application?.data.property?.photo_url,
      propertyDetails: application.data?.property.property_details.map(
        (detail) => ({
          valor: detail?.valor,
          typeMoney: detail?.type_money,
          promotionalPrice: detail?.promotional_price,
        })
      ),
    },
    houmer: {
      name: application?.data.houmer ? application?.data.houmer?.name : null,
    },
    user: {
      name: application?.data.user?.name,
      rut: application?.data.user?.rut,
      phone: application?.data.user?.phone,
      email: application?.data.user?.email,
      document: application?.data.user?.document,
      lastName: application?.data.user?.last_name,
      secondLastName: application?.data.user?.second_last_name,
      documentType: application?.data.user?.document_type?.id,
    },
    executive: application?.data.owner
      ? `${application?.data.owner?.name} ${application?.data.owner?.last_name}`
      : null,
    evaluationData: application.evaluationData.length
      ? {
          persons: application.evaluationData[0].persons.map((person) => ({
            documents: person.documents.map((document) => ({
              contentType: document.content_type,
              filename: document.filename,
              id: document.id,
              personId: document.person_id,
            })),
            id: person.id,
            role: person.role,
            status: person.status,
            userId: person.user_id,
            userMainApplicantId: person.user_main_applicant_id,
            name: person.user?.name,
            lastName: person.user?.last_name,
            email: person.user?.email,
            document: person.user?.document,
            documentType: person.user?.document_type,
            phone: person.user?.phone,
            workerType: person?.work_type,
            country: person?.user.country,
          })),
          status: application.evaluationData[0].status,
          relatedApplicantId:
            application.evaluationData[0].related_applicant_id,
          id: application.evaluationData[0].id,
        }
      : null,
  }));

export default frontendApplicationParser;
