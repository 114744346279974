import {
  CloseContractType,
  ContractResponse,
} from "apps/landlord/services/propertyContractService/types";
import { add, format, parse } from "date-fns";
import { LOCALE_BASED_LEAVING_MOTIVES_OPTIONS } from "./constants";

interface EncodeEndOfContractDataArgs {
  endOfContractDate: Date;
  endOfContractTarget: ContractResponse;
  observations?: string;
  reason?: number;
  hasLimitDateCondition?: boolean;
}

export const encodeEndOfContractData = ({
  endOfContractDate,
  endOfContractTarget,
  observations,
  reason,
  hasLimitDateCondition = true,
}: EncodeEndOfContractDataArgs) => {
  const initialDate = parse(
    endOfContractTarget.init_date,
    "yyyy-MM-dd",
    new Date()
  );
  const oneYearFromContract = add(initialDate, {
    years: 1,
  });
  const closeContractType =
    endOfContractDate > oneYearFromContract
      ? CloseContractType.NO_RENOVATION
      : CloseContractType.EARLY_FINISH;
  if (
    closeContractType === CloseContractType.EARLY_FINISH &&
    hasLimitDateCondition
  ) {
    throw Error(CloseContractType.EARLY_FINISH);
  }
  const parsedPayload = {
    type: closeContractType,
    contractId: endOfContractTarget?.id,
    estimatedDate: format(endOfContractDate, "yyyy-MM-dd"),
    comment: LOCALE_BASED_LEAVING_MOTIVES_OPTIONS[
      endOfContractTarget.country
    ].find((option) => option.value === reason).label,
    observations,
  };
  return parsedPayload;
};

export interface EoCLeavingDateForm {
  date?: Date;
  motive?: number;
  observations?: string;
}
