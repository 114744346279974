import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

const useErrorTextStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: 1.3,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(32),
      lineHeight: 1.4,
    },
  },
  subtitle: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.4,
    },
  },
}));

export default useErrorTextStyles;
