import { Collapse, Typography } from "@material-ui/core";

import { IoChevronDownOutline as ArrowDown } from "@houm-com/ui-icons";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import useAuthDropdownMenuStyles from "./AuthDropdownMenuStyles";
import useAuthDropdownMenu from "./hooks/useAuthDropdownMenu";
import AuthMenu from "../../../AuthMenu/AuthMenu";

const AuthDropdownMenu = () => {
  const {
    buttonRef,
    handleClick,
    loading,
    loginWithRedirect,
    open,
    user,
    userName,
  } = useAuthDropdownMenu();
  const classes = useAuthDropdownMenuStyles({ open });

  if (loading) return null;

  if (!user) {
    return (
      <DesignSystemButton
        label="Iniciar sesión"
        href=""
        variant="primary"
        size="small"
        onClick={() => loginWithRedirect()}
      />
    );
  }

  return (
    <div
      className={classes.authDropdownMenuRoot}
      onClick={handleClick}
      ref={buttonRef}
    >
      <Typography className={classes.label}>{userName}</Typography>
      <ArrowDown className={classes.arrow} />
      <Collapse in={open} className={classes.collapse}>
        <AuthMenu />
      </Collapse>
    </div>
  );
};

export default AuthDropdownMenu;
