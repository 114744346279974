import { useQuery, UseQueryResult } from "react-query";

import { countryToCode } from "apps/account/pages/AccountCreate/components/FormCreateAccount/formUtils";
import applicationsService from "apps/lead/services/ApplicationsService";
import evaluationService from "apps/lead/services/EvaluationService";
import { ApplicationModel } from "apps/lead/models/Application";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

import frontendApplicationParser from "../utils/parsers";

const useApplicationList = () => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    data = [],
    error,
  }: UseQueryResult<ApplicationModel[], Error> = useQuery<
    ApplicationModel[],
    Error
  >(
    ["applicationsList"],
    async () => {
      const authToken = await getToken();
      const response = await applicationsService.getApplications(authToken);
      const applicationsData = response.data;
      const applicationsWithEval = await Promise.all(
        applicationsData.map(async (application) => {
          const res = await evaluationService.getEvaluationsByApplicantId(
            countryToCode(application.property.country) as Locale,
            [application.id],
            authToken
          );
          const evaluationData = res.data;
          return {
            data: application,
            evaluationData,
          };
        })
      );
      return frontendApplicationParser(applicationsWithEval);
    },
    {
      refetchOnMount: true,
    }
  );

  return { isLoading, data, error };
};

export default useApplicationList;
