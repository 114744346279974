import { Box, Typography } from "@material-ui/core";

import {
  inspectionReportItemsNameTranslation,
  inspectionReportFeaturesNameTranslation,
} from "apps/landlord/pages/InspectionReport/utils/types";
import TableRowImagePreviewer from "apps/landlord/components/TableRowImagePreviewer";
import { InspectionReportElement } from "services/propertyService/types";
import { getObjectLabel } from "utils";

import useStyles from "./SpaceElementCollapseStyles";

interface Props {
  title: string;
  items: InspectionReportElement[];
}

const SpaceElementCollapse = ({ title, items = [] }: Props) => {
  const classes = useStyles();

  const inspectionReportNameTranslations = [
    ...inspectionReportItemsNameTranslation,
    ...inspectionReportFeaturesNameTranslation,
  ];

  return (
    <>
      <Typography className={classes.title}>{title}</Typography>
      {items.map((item) => (
        <Box key={item.name} className={classes.itemRoot}>
          <Box className={classes.imageContainer}>
            <TableRowImagePreviewer
              rowName={getObjectLabel(
                inspectionReportNameTranslations,
                item.name
              )}
              imagesArr={item?.photo_links?.map((photo) => photo.link)}
            />
          </Box>
          <Box className={classes.infoContainer}>
            <Typography className={classes.boldText}>
              {getObjectLabel(inspectionReportNameTranslations, item.name)}
            </Typography>
            <Typography className={classes.text}>
              {item?.observation ?? "Sin observaciones"}
            </Typography>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default SpaceElementCollapse;
