import { useParams } from "react-router-dom";

import { useFeatureManager } from "@houm-com/feature-manager";

import LandingPaymentsWrapperHoc from "apps/payments/components/LandingPaymentsWrapperHoc";
import usePropertyAddress from "apps/payments/hooks/usePropertyAddress";
import LoadingScreen from "Components/Admin/UIComponents/LoadingScreen";
import PendingPayment from "apps/payments/components/PendingPayment";
import SuccessPayment from "apps/payments/components/SuccessPayment";
import useMovementData from "apps/payments/hooks/useMovementData";
import ErrorPayment from "apps/payments/components/ErrorPayment";
import { Locale } from "models/Countries";

import Reservation from "./components/Reservation";

const LandingPayment = () => {
  const { countryCode } = useParams<{
    countryCode: Locale;
  }>();
  const { status } = useFeatureManager("landing-payments", {
    marketCode: countryCode,
  });
  const { data: movement, isFetching, isError } = useMovementData();
  const { data: propertyAddress, isLoading } = usePropertyAddress({
    propertyId: movement?.propertyId,
  });

  if (isLoading || isFetching || status === "loading") return <LoadingScreen />;

  if (isError) return <ErrorPayment />;

  if (movement.status === "PAID")
    return (
      <SuccessPayment
        country={countryCode}
        movementData={movement}
        address={propertyAddress.address}
      />
    );

  if (movement.status === "PENDING")
    return (
      <PendingPayment
        country={countryCode}
        movementData={movement}
        address={propertyAddress.address}
      />
    );

  if (movement.concept === "RESERVATION")
    return (
      <Reservation
        country={countryCode}
        movement={movement}
        address={propertyAddress?.address}
      />
    );

  return (
    <LandingPaymentsWrapperHoc
      movement={movement}
      address={propertyAddress?.address}
    />
  );
};

export default LandingPayment;
