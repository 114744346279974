import { Box, Typography } from "@material-ui/core";

import useInspectionReportCommentsStyles from "../InspectionReportCommentsStyles";

interface InspectionReportCommentItemProps {
  createdAt: string;
  comment: string;
  photos: [
    {
      photo_filename: string;
      photo_link: string;
    }
  ];
}

const InspectionReportCommentItem = ({
  createdAt,
  comment,
  photos,
}: InspectionReportCommentItemProps) => {
  const classes = useInspectionReportCommentsStyles();
  return (
    <Box className={classes.commentContainer}>
      <Typography className={classes.commentDate}>{createdAt}</Typography>
      <Typography className={classes.commentText}>{comment}</Typography>
      <Typography className={classes.commentPhotosContainerLabel}>
        Fotos
      </Typography>
      <Box className={classes.commentPhotosContainer}>
        <Box className={classes.commentPhotosContainerScrollWrapper}>
          {photos.map(({ photo_filename, photo_link }) => (
            <img
              key={photo_filename}
              src={photo_link}
              alt={photo_filename}
              className={classes.commentPhotoThumbnail}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default InspectionReportCommentItem;
