import { Grid, Typography } from "@material-ui/core";
import clx from "classnames";
import usePlanCardTitleStyles from "./PlanCardTitleStyles";

interface Props {
  isHorizontal?: boolean;
  isMainPlan?: boolean;
  title?: string;
  subtitle?: string;
  isCustom?: boolean;
}

export default function PlanCardTitle({
  isHorizontal,
  isMainPlan,
  title,
  subtitle,
  isCustom,
}: Props) {
  const classes = usePlanCardTitleStyles({ isHorizontal, isMainPlan });
  return (
    <Grid item className={classes.cardHeader}>
      <Typography
        className={clx(
          classes.cardTitle,
          isMainPlan && classes.cardTitlePremium
        )}
      >
        {isCustom ? "Plan personalizado" : title}
      </Typography>
      {!isCustom && (
        <Typography className={classes.cardSubtitle}>{subtitle}</Typography>
      )}
    </Grid>
  );
}
