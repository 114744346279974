import { Drawer } from "@material-ui/core";

import DrawerHeader from "apps/ui/CustomDrawer/DrawerHeader";
import DrawerHeaderStepper from "apps/ui/CustomDrawer/DrawerHeaderStepper";
import useProperty from "apps/landlord/hooks/useProperty";
import useBasicServicesFormStyles from "./BasicServicesFormStyles";
import useBasicServicesFormView from "./hooks/useBasicServicesFormView";
import CommonExpensesStep from "./components/CommonExpensesStep";
import BasicServicesStep from "./components/BasicServicesStep";
import { STEPS } from "./context/types";

const BasicServicesForm = () => {
  const classes = useBasicServicesFormStyles();
  const { propertyData } = useProperty();
  const { open, returnToPropertyDetail, currentStep, setCurrentStep } =
    useBasicServicesFormView();

  const ComponentsByStep = {
    [STEPS.BasicServices]: BasicServicesStep,
    [STEPS.CommonExpenses]: CommonExpensesStep,
  };

  const CurrentStep = ComponentsByStep[currentStep];
  const isCL = propertyData?.country === "cl";

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={returnToPropertyDetail}
      classes={{
        paper: classes.drawerPaper,
        root: classes.drawerRoot,
      }}
    >
      <DrawerHeader onHandleClose={returnToPropertyDetail}>
        {isCL && (
          <DrawerHeaderStepper
            steps={[
              {
                isActive: currentStep === STEPS.CommonExpenses,
                onClick: () => setCurrentStep(STEPS.CommonExpenses),
              },
              {
                isActive: currentStep === STEPS.BasicServices,
                onClick: () => setCurrentStep(STEPS.BasicServices),
              },
            ]}
          />
        )}
      </DrawerHeader>
      <CurrentStep />
    </Drawer>
  );
};

export default BasicServicesForm;
