import Swal from "sweetalert2";

export const renderSwalAlertOnError = (
  error: string,
  onAction: () => void,
  history
) =>
  Swal.fire({
    title: "Error",
    text: `${error}`,
    type: "error",
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    confirmButtonText: "Intentar nuevamente",
  }).then((result) => {
    if (result.value) {
      onAction();
    } else {
      history.push("/tenant");
    }
  });

export default renderSwalAlertOnError;
