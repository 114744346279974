import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { useUser } from "context/userContext";

import useAuthMobileMenuStyles from "./AuthMobileMenuStyles";
import ItemsMenu from "../AuthItemsMenu";

const AuthMobileMenu = () => {
  const { user, loginWithRedirect } = useUser();
  const classes = useAuthMobileMenuStyles();
  /** Role check */

  if (!user) {
    return (
      <DesignSystemButton
        label="Iniciar sesión"
        href=""
        variant="primary"
        size="small"
        buttonClassName={classes.button}
        onClick={() => loginWithRedirect()}
      />
    );
  }

  return <ItemsMenu />;
};

export default AuthMobileMenu;
