import { Box, Typography } from "@material-ui/core";

import CustomTextField from "Components/Admin/UIComponents/CustomTextField";
import CustomRadioButton from "apps/tenant/components/Forms/CustomRadioButton";

import { DEBT_TYPE_OPTIONS, REGULARIZATION_TYPE_OPTIONS } from "./constants";
import useServicesRequestFormStyles from "./ServicesRequestFormStyles";
import { IServicesRequest } from "../../../hooks/types";
import PhotosSection from "./PhotosSection";

interface Props {
  servicesRequest: IServicesRequest;
  setServicesRequest: (value: IServicesRequest) => void;
}

const ServicesRequestForm = ({
  servicesRequest,
  setServicesRequest,
}: Props) => {
  const { debtType, regularizationType, description } = servicesRequest;
  const classes = useServicesRequestFormStyles();
  return (
    <Box className={classes.form}>
      <CustomRadioButton
        label="¿Ya pagaste las cuentas?"
        value={regularizationType}
        onChange={(e) =>
          setServicesRequest({
            ...servicesRequest,
            regularizationType: e.target
              .value as IServicesRequest["regularizationType"],
          })
        }
        id="repairType"
        options={REGULARIZATION_TYPE_OPTIONS}
        required
      />
      <CustomRadioButton
        label="¿A qué corresponden las boletas?"
        value={debtType}
        onChange={(e) =>
          setServicesRequest({
            ...servicesRequest,
            debtType: e.target.value as IServicesRequest["debtType"],
          })
        }
        id="repairType"
        options={DEBT_TYPE_OPTIONS}
        required
      />
      {debtType && (
        <PhotosSection
          servicesRequest={servicesRequest}
          setServicesRequest={setServicesRequest}
        />
      )}
      <Box>
        <Typography className={classes.label}>Descripción</Typography>
        <CustomTextField
          className={classes.textField}
          minRows={3}
          multiline
          wrapperPadding={false}
          required
          onChange={(e) =>
            setServicesRequest({
              ...servicesRequest,
              description: e.target.value as string,
            })
          }
          value={description}
          placeholder="Escribe aquí tu texto"
          maxLength={1024}
        />
      </Box>
    </Box>
  );
};

export default ServicesRequestForm;
