import { useEffect, useState } from "react";

import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import moment, { Moment } from "moment";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import Badge from "@material-ui/core/Badge";

import { useLandlordProperty } from "context/landlordPropertyContext/landlordPropertyContext";
import { materialTheme, useBadgeStyles } from "./CustomCalendarStyles";

interface Props {
  setAllSchedulesDay: (schedules: any) => void;
}

export default function CustomCalendar({ setAllSchedulesDay }: Props) {
  const classes = useBadgeStyles();
  const { state } = useLandlordProperty();
  const { propertyData } = state;
  const { schedules } = propertyData;
  const schedulesActualYear = schedules.filter(
    (schedule) =>
      new Date(schedule.begin_date).getFullYear() === new Date().getFullYear()
  );
  const [selectedDate, handleDateChange] = useState<Moment | null>();

  const getSchedulesFiltered = (date: Date) => {
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();
    const filterSchedules = schedulesActualYear
      .filter(
        (sc) =>
          new Date(sc.begin_date).getMonth() === currentMonth &&
          new Date(sc.begin_date).getFullYear() === currentYear
      )
      .map((sc) => new Date(sc.begin_date).getDate());
    return filterSchedules;
  };

  const getCurrentMonthSchedules = () => {
    const currentDate = new Date();
    return getSchedulesFiltered(currentDate);
  };

  const [schedulesDay, setSchedulesDay] = useState(getCurrentMonthSchedules());

  const handleMonthChange = (e) => {
    const filteredSchedules = getSchedulesFiltered(e);
    setSchedulesDay(filteredSchedules);
  };

  useEffect(() => {
    setAllSchedulesDay(
      orderDates(
        schedulesActualYear.filter(
          (sc) => new Date(sc.begin_date).getDate() === new Date().getDate()
        )
      )
    );
  }, [schedules]);

  const orderDates = (scheduleData) =>
    scheduleData.sort((a, b) => {
      a = new Date(a.begin_date).getHours();
      b = new Date(b.begin_date).getHours();
      return a < b ? -1 : a > b ? 1 : 0;
    });

  const getAllSchedulesday = (e) => {
    const schedulesFilter = schedulesActualYear.filter(
      (sc) =>
        moment(new Date(sc.begin_date)).format("YYYY-MM-DD") ===
        moment(new Date(e)).format("YYYY-MM-DD")
    );
    const schedulesOrdered = orderDates(schedulesFilter);
    setAllSchedulesDay(schedulesOrdered);
  };

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <ThemeProvider theme={materialTheme}>
          <DatePicker
            label=""
            value={selectedDate}
            onChange={(e) => {
              if (schedulesDay.includes(moment(e).date())) {
                getAllSchedulesday(e);
                handleDateChange(moment(e));
              } else {
                handleMonthChange(new Date());
              }
            }}
            variant="static"
            disablePast={true}
            readOnly={true}
            disableToolbar
            onMonthChange={(e) => handleMonthChange(e)}
            renderDay={(day, _, isInCurrentMonth, dayComponent) => {
              const isSelected =
                isInCurrentMonth && schedulesDay.includes(moment(day).date());
              return (
                <Badge
                  classes={{ badge: classes.customBadge }}
                  variant={isSelected ? "dot" : undefined}
                >
                  {dayComponent}
                </Badge>
              );
            }}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
}
