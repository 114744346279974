import { makeStyles, Theme } from "@material-ui/core";

const useTermsAndConditionsStyles = makeStyles<Theme>((theme) => ({
  link: {
    textDecoration: "underline",
    fontWeight: 700,
    color: theme.palette.primary.main,
    pointerEvents: "auto",
  },
}));

export default useTermsAndConditionsStyles;
