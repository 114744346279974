import { useState } from "react";

import { Box, Button, Typography } from "@material-ui/core";

import CustomTextField from "Components/Admin/UIComponents/CustomTextField";
import CustomFileDropzone from "apps/components/CustomFileDropzone";
import useInspectionReportCommentsStyles from "../InspectionReportCommentsStyles";
import { InspectionReportCommentFormData } from "../hooks/types";

interface CreateNewCommentProps {
  onSubmit: (comment: InspectionReportCommentFormData) => void;
  onClose: () => void;
}

const CreateNewComment = ({ onSubmit, onClose }: CreateNewCommentProps) => {
  const classes = useInspectionReportCommentsStyles();
  const [comment, setComment] = useState("");
  const [files, setFiles] = useState<File[]>([]);

  const handleSubmit = () => {
    onSubmit({
      comment,
      photos: files,
    });
    onClose();
  };

  return (
    <Box className={classes.addCommentBox}>
      <Typography className={classes.highlightedBoxTitle}>
        Tus comentarios sobre el estado de la propiedad
      </Typography>
      <Typography className={classes.highlightedBoxText}>
        Recuerda que esta información es importante como respaldo para el
        momento en que decidas salir de esta propiedad.
      </Typography>
      <CustomTextField
        className={classes.addCommentTextField}
        minRows={6}
        multiline
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        wrapperPadding={false}
        required
        placeholder="Escribe aquí tu texto"
        maxLength={1024}
      />
      <CustomFileDropzone
        id="files"
        onChange={(f) => setFiles(f as File[])}
        label="Fotos *"
        description="Agrega las fotos que nos ayuden a tener más claridad de lo que sucede."
        maxFiles={10}
      />
      <Box className={classes.addCommentBoxActions}>
        <Button onClick={onClose} className={classes.addCommentButton}>
          <Typography className={classes.addCommentButtonText}>
            Cancelar
          </Typography>
        </Button>
        <Button onClick={handleSubmit} className={classes.downloadReportButton}>
          <Typography className={classes.downloadReportButtonText}>
            Guardar
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default CreateNewComment;
