import { makeStyles, Theme } from "@material-ui/core";

const useHeaderStyles = makeStyles((theme: Theme) => ({
  headerRoot: {
    marginBottom: theme.spacing(3),
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between ",
  },
  username: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
  },
  closeIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 100,
    "& svg": {
      width: 25,
      height: 25,
    },
  },
}));

export default useHeaderStyles;
