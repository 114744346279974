export default {
  loadingSkeletonHead: "flex items-center justify-between",
  loadingSkeletonTitle: "w-[40%]",
  loadingSkeletonTag: "w-[20%]",
  loadingSkeletonSubtitle: "text-information-100 animate-pulse",
  loadingSkeletonContent: "mt-4",
  loadingSkeletonItem: "bg-secondary-20 h-[20px] my-1 rounded animate-pulse",
  loadingSkeletonItemFull: "w-[100%]",
  loadingSkeletonItemHalf: "w-[50%]",
  loadingSkeletonItemFourth: "w-[25%]",
  loadingSkeletonItemEighth: "w-[15%]",
};
