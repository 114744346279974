import { makeStyles, Theme } from "@material-ui/core";

const usePaymentSummaryStyles = makeStyles<Theme>((theme) => ({
  summaryContainer: {
    display: "grid",
    gap: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      gap: theme.spacing(2.5),
    },
  },
  title: {
    color: theme.palette.grey["A400"],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
  paymentTotalValue: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
  },
  disclaimer: {
    color: theme.palette.grey["A400"],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.57,
    },
  },
  detailContainer: {
    display: "grid",
    gap: theme.spacing(1.75),
    [theme.breakpoints.up("sm")]: {
      gap: theme.spacing(2),
    },
  },
  paymentContainer: {
    display: "grid",
    gap: theme.spacing(2.5),
    [theme.breakpoints.up("sm")]: {
      gap: theme.spacing(3),
    },
  },
}));

export default usePaymentSummaryStyles;
