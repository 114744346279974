import { ReactNode } from "react";
import { Link } from "react-router-dom";
import clx from "classnames";

import { Box, Typography } from "@material-ui/core";

import { collectionLink } from "apps/landlord/pages/Property/utils/utils";
import { Locale } from "models/Countries";

import useDisclaimerRentalInfoStyles from "./DisclaimerRentalInfoStyles";

interface Props {
  children: ReactNode;
  country: Locale;
  className?: string;
  link?: string;
}

const DisclaimerRentalInfo = ({
  children,
  className,
  country,
  link = null,
}: Props) => {
  const classes = useDisclaimerRentalInfoStyles();

  return (
    <Box className={clx(className, classes.disclaimerContainer)}>
      <Typography className={classes.text}>
        {children}
        {link && (
          <>
            {" "}
            <Link
              to={{
                pathname: link ?? collectionLink[country],
              }}
              target="_blank"
              className={classes.link}
            >
              clic aquí
            </Link>
          </>
        )}
        .
      </Typography>
    </Box>
  );
};

export default DisclaimerRentalInfo;
