import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  open: boolean;
}

const useNavbarItemStyles = makeStyles<Theme, Props>((theme) => ({
  navbarItemRoot: {
    margin: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      display: "inline-flex",
      position: "relative",
      marginLeft: theme.spacing(1),
    },
  },
  button: {
    padding: theme.spacing(1, 0),
    textTransform: "none",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0.5, 2),
      borderRadius: 800,
      border: "1px solid transparent",
      alignItems: "center",
      whiteSpace: "nowrap",
      cursor: "pointer",
      "&:hover": {
        border: `1px solid ${theme.palette.grey[50]}`,
        backgroundColor: "#F7F9F9",
      },
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
  },
  arrow: {
    marginLeft: theme.spacing(0.5),
    transform: ({ open }) => (open ? "rotate(180deg)" : "rotate(0)"),
    transitionDuration: "0.5s",
  },
  arrowMobile: {
    height: 20,
    width: 20,
  },
}));

export default useNavbarItemStyles;
