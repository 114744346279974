import { Theme, makeStyles } from "@material-ui/core";

const useBasicServicesFormStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    width: "100vw",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      maxWidth: 480,
      flexGrow: 1,
    },
  },
  drawerRoot: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default useBasicServicesFormStyles;
