import { ReactNode } from "react";
import clx from "classnames";

import { Box, BoxProps } from "@material-ui/core";

import useCardInfoStyles from "./CardInfoStyles";

interface Props extends BoxProps {
  children: ReactNode;
  cardClassName?: string;
}

const CardInfo = ({ children, cardClassName, ...props }: Props) => {
  const classes = useCardInfoStyles();

  return (
    <Box {...props} className={clx(cardClassName, classes.card)}>
      {children}
    </Box>
  );
};

export default CardInfo;
