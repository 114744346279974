import { useParams } from "react-router-dom";

import { Grid } from "@material-ui/core";

import { ApplicationCodebtor } from "models/Evaluation";
import { Locale } from "models/Countries";
import useApplicantsStyles from "./ApplicantsDetailsStyles";
import ApplicantDetails from "./components/ApplicantDetails";

interface ApplicantsDetailsProps {
  applicants: ApplicationCodebtor[];
}

const ApplicantsDetails = ({ applicants }: ApplicantsDetailsProps) => {
  const classes = useApplicantsStyles();
  const { countryCode } = useParams<{ countryCode: Locale }>();

  return (
    <Grid container className={classes.applicantsDetails}>
      {applicants.map((applicant) => (
        <ApplicantDetails
          key={applicant.id}
          applicant={applicant}
          countryCode={countryCode}
        />
      ))}
    </Grid>
  );
};

export default ApplicantsDetails;
