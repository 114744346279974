import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { validationSchema } from "apps/landlord/components/FormPropertyDetails/utils/validationSchema";
import { PropertyDetailsFormProps } from "apps/landlord/components/FormPropertyDetails/utils/types";
import { parseDefaultValues } from "apps/landlord/components/FormPropertyDetails/utils/parsers";
import { IDetails } from "apps/landlord/hooks/usePropertyDetails/utils/types";
import FormPropertyDetails from "apps/landlord/components/FormPropertyDetails";

import useEditDetails from "../../../../hooks/useEditDetails";

interface Props {
  data: IDetails;
  onHandleDrawer: () => void;
}

const Form = ({ data, onHandleDrawer }: Props) => {
  const { mutate, isLoading } = useEditDetails(onHandleDrawer);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
    defaultValues: parseDefaultValues(data),
  });

  const onSubmit: SubmitHandler<PropertyDetailsFormProps> = (e) => {
    if (isLoading) return;
    mutate(e);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormPropertyDetails
          onHandleCloseModal={onHandleDrawer}
          isLoading={isLoading}
        />
      </form>
    </FormProvider>
  );
};

export default Form;
