import { format } from "date-fns";
import { round } from "lodash";

import {
  IProperty,
  IPropertyDetails,
  ISalesDetails,
  PropertyType,
} from "apps/landlord/models/Property";
import { countryCode } from "utils";
import { PropertyData } from "models/Property";

const parseProperty = (data: PropertyData): IProperty => {
  const dpto = data.numero_depto ? `Dpto ${data.numero_depto}, ` : "";
  const propertyDetails = data.property_details[0];
  const amenities = data?.association_amenities;
  return {
    id: data.id,
    uid: data.uid,
    address: `${dpto}${data.address} ${data.street_number ?? ""}`,
    country: countryCode(data.country),
    stratus: data?.stratus,
    photos: data.photos,
    apartmentNumber: data.numero_depto,
    streetNumber: data.street_number,
    region: data.region,
    commune: data.comuna,
    forSale: data.for_sale,
    forRental: data.for_rental,
    userRole: data.user?.role,
    user: {
      name: data.user?.name,
      lastName: data.user?.last_name,
      secondLastName: data.user?.second_last_name,
      email: data.user?.email,
      id: data.user?.id,
    },
    type: data.type as PropertyType,
    managementExecutive: data.houm_owner,
    propertyDetails: {
      currencyCode: propertyDetails.currency_code,
      moneyType: propertyDetails.tipo_moneda,
      salesMoneyType: propertyDetails.tipo_moneda_venta,
      status: propertyDetails.status,
      price: propertyDetails.valor,
      salesPrice: propertyDetails.valor_venta,
      firstHandoverDate: propertyDetails.first_available_handover_date,
      totalArea: round(Number(propertyDetails.m_terreno)),
      builtArea: round(Number(propertyDetails.m_construidos)),
      bathrooms: propertyDetails.banos,
      rooms: propertyDetails.dormitorios,
      parkingSlots: propertyDetails.estacionamientos,
      laundryRoom: propertyDetails.has_laundry_room,
      allowsPets: propertyDetails.mascotas,
      furnished: !!propertyDetails.amoblado,
      balcony: propertyDetails.has_balcony,
      age: data.year_built
        ? Number(format(new Date(), "yyyy")) - data.year_built
        : data.antiquity,
      latitude: propertyDetails.latitud,
      longitude: propertyDetails.longitud,
      commonExpenses: propertyDetails?.gc,
    } as IPropertyDetails,
    amenities: {
      hasAllDayVigilance: amenities?.has_all_day_vigilance,
      hasBbqArea: amenities?.has_bbq_area,
      hasCinema: amenities?.has_cinema,
      hasConcierge: amenities?.has_concierge,
      hasElevator: amenities?.has_elevator,
      hasGamesRoom: amenities?.has_games_room,
      hasGourmetSpace: amenities?.has_gourmet_space,
      hasGym: amenities?.has_gym,
      hasLaundry: amenities?.has_laundry,
      hasPartyRoom: amenities?.has_party_room,
      hasPatio: amenities?.has_patio,
      hasPetArea: amenities?.has_pet_area,
      hasPlayground: amenities?.has_playground,
      hasPlayroom: amenities?.has_playroom,
      hasRoofGarden: amenities?.has_roof_garden,
      hasSauna: amenities?.has_sauna,
      hasSteamRoom: amenities?.has_steam_room,
      hasSwimmingPool: amenities?.has_swimming_pool,
    },
    salesDetails: {
      status: data.sales_details.status,
      currencyCode: data.sales_details.currency_code,
      price: data.sales_details.price,
    } as ISalesDetails,
  };
};

export default parseProperty;
