import { useHistory, useParams } from "react-router-dom";

import { AppBar, Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";

import useNavbarHeight from "apps/landlord/hooks/useNavbarHeight";
import Spinner from "Components/Shared/partials/Spinner";
import { useProperty } from "apps/landlord/hooks";

import PaymentBreakdown from "./components/PaymentBreakdown";
import usePaymentDetailsStyles from "./PaymentDetailStyles";
import BankAccountInfo from "./components/BankAccountInfo";
import usePaymentDetails from "./hooks/usePaymentDetails";
import PaymentHeader from "./components/PaymentHeader";
import Receipts from "./components/Receipts";

const PaymentDetail = () => {
  const navbarHeight = useNavbarHeight();
  const history = useHistory();
  const { id: propertyUid } = useParams<{ id: string }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = usePaymentDetailsStyles({ navbarHeight });
  const { data, isLoading } = usePaymentDetails();
  const { propertyLoading, propertyData } = useProperty();

  if (isLoading || propertyLoading)
    return (
      <Box className={classes.loadingContainer}>
        <Spinner />
      </Box>
    );

  if (data?.status !== "PAID") {
    history.push(`/landlord/properties/${propertyUid}`);
    return null;
  }

  return (
    <>
      <Box className={classes.root}>
        <PaymentHeader
          paymentDate={data?.paymentDate}
          paymentOption={data?.paymentOption}
          country={propertyData?.country}
          propertyLoading={propertyLoading}
        />
        <Box className={classes.content}>
          <Grid
            className={classes.gridContainer}
            container
            spacing={isMobile ? 0 : 5}
          >
            <PaymentBreakdown data={data} />
            <BankAccountInfo bankAccountInfo={data.bankAccountInfo} />
          </Grid>
        </Box>
      </Box>
      {isMobile && propertyData.country === "cl" && (
        <AppBar position="absolute" className={classes.appBar} component="div">
          <Receipts country="cl" />
        </AppBar>
      )}
    </>
  );
};

export default PaymentDetail;
