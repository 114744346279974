import { Box, Checkbox, Grid, Typography } from "@material-ui/core";

import { IoWarningOutline as WarningIcon } from "@houm-com/ui-icons";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import {
  replyOffer,
  setCurrentStep,
} from "context/negotiationsContext/negotiationsActions";
import { useNegotiations } from "context/negotiationsContext/negotiationsContext";
import { useGetToken } from "hooks";
import { useState } from "react";
import Swal from "sweetalert2";
import ResumeOfferCard from "./ResumeOfferCard";
import useResumeOfferStyles from "./ResumeOfferStyles";

const ResumeOffer = () => {
  const { state, dispatch } = useNegotiations();
  const { responseToOffer, countryCode, currentOffer } = state;
  const { getToken } = useGetToken();
  const classes = useResumeOfferStyles();
  const [checked, setChecked] = useState(false);

  const onClick = () => {
    dispatch(setCurrentStep("termsAndConditions"));
  };

  const disabled =
    !checked && countryCode === "mx" && responseToOffer.response === "Aceptada";
  const showTerms =
    countryCode === "mx" && responseToOffer.response === "Aceptada";
  const showWarning = responseToOffer.response === "Rechazada";

  const handleSubmit = () => {
    if (disabled) {
      Swal.fire({
        type: "error",
        text: "Debe aceptar los términos y condiciones",
      });
      return;
    }
    getToken().then((authToken) => {
      replyOffer(
        countryCode,
        currentOffer.id,
        responseToOffer,
        dispatch,
        authToken
      );
    });
  };

  return (
    <Grid container>
      <Typography className={classes.titleGeneral}>¡Último paso!</Typography>
      <Grid container item justifyContent="center">
        <Grid item xs={12}>
          <ResumeOfferCard />
          {showTerms && (
            <Box className={classes.warning}>
              <Checkbox
                color="primary"
                checked={checked}
                className={classes.checkbox}
                onChange={(e) => {
                  setChecked(e.target.checked);
                }}
              />
              <Typography className={classes.terms}>
                {`Al enviar esta oferta, estarás aceptando nuestros `}
                <Typography className={classes.termsDetails} onClick={onClick}>
                  términos y condiciones.
                </Typography>
              </Typography>
            </Box>
          )}
          {showWarning && (
            <Box className={classes.warning}>
              <WarningIcon color="#900000" className={classes.warningIcon} />
              <Typography className={classes.warningReject}>
                <Typography className={classes.warningRejectRemember}>
                  ¡Recuerda!
                </Typography>
                {` Al enviar la respuesta rechazarás la oferta del
              comprador. Esta opción no podrá ser modificada después de ser
              enviada.`}
              </Typography>
            </Box>
          )}
          <Box className={classes.container}>
            <DesignSystemButton
              disabled={disabled}
              variant="primary"
              onClick={handleSubmit}
              label="Enviar respuesta"
              size="medium"
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResumeOffer;
