import { useState } from "react";

import { IProperty } from "apps/landlord/models/Property";

import useProperties from "./useProperties";

const useSearch = () => {
  const [suggestions, setSuggestions] = useState<Partial<IProperty>[]>([]);
  const [valueSearch, setValueSearch] = useState("");

  const saveSuggestion = (data: Partial<IProperty>[]) => setSuggestions(data);
  const { isLoading, isError } = useProperties(
    "searchProperty",
    valueSearch,
    { page_size: 150, rental_status_not: "archived" },
    saveSuggestion
  );

  const makeSearch = (value: string) => {
    setValueSearch(value);
  };

  const resetSuggestion = () => {
    setSuggestions([]);
  };

  return {
    suggestions,
    makeSearch,
    resetSuggestion,
    isLoading,
    isError,
  };
};

export default useSearch;
