import Spinner from "Components/Shared/partials/Spinner";
import usePayUMethods from "./hooks/usePayUMethods";
import PaymentMethods from "../PaymentMethods";

interface Props {
  movementId: string;
  textValue?: string;
}

const PayUDetails = ({ movementId, textValue = "Valor renta" }: Props) => {
  const { isLoading, data } = usePayUMethods(movementId);

  if (isLoading) return <Spinner />;

  return (
    <PaymentMethods
      country="mx"
      methods={data}
      paymentCompany="PayU"
      currencyCode="MXN"
      textValue={textValue}
      paymentMethodIcon="https://houm-staging-public.s3.us-east-1.amazonaws.com/cms-content/logo_Pay_U_1230761174.svg"
    />
  );
};

export default PayUDetails;
