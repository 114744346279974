import { Theme, makeStyles } from "@material-ui/core";

interface Props {
  error: boolean;
  wrapperPadding?: boolean;
  disabled?: boolean;
}

export default makeStyles<Theme, Props>((theme: Theme) => ({
  root: {
    maxWidth: 130,
    cursor: "pointer",
  },
  basicInput: {
    caretColor: "transparent",
    cursor: ({ disabled }) => (disabled ? "auto" : "pointer"),
  },
  inputContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: theme.spacing(2),
    width: "100%",
  },
  wrapper: {
    alignItems: "flex-start",
    display: "flex",
    margin: 0,
    padding: ({ wrapperPadding }) => (wrapperPadding ? theme.spacing(0.6) : 0),
    width: "100%",
  },
  countryWrapper: {
    alignItems: "flex-start",
    display: "flex",
    margin: 0,
    padding: ({ wrapperPadding }) => (wrapperPadding ? theme.spacing(0.6) : 0),
  },
  label: {
    lineHeight: "1.36em",
    fontWeight: 700,
    fontSize: "1rem",
    marginBottom: -theme.spacing(0.25),
  },
  underline: {
    "&&&:before": {
      borderColor: theme.palette.grey["200"],
    },
    "&&:after": {
      borderColor: ({ error }) =>
        error ? theme.palette.primary.main : "#607D8B",
    },
    "&:-webkit-outer-spin-button": {
      WebkitAppearance: null,
      margin: 0,
    },
    "&:-webkit-inner-spin-button": {
      WebkitAppearance: null,
      margin: 0,
    },
  },
}));
