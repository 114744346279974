import { Theme, makeStyles } from "@material-ui/core";

const useUnfinishedSettingUpBannerStyles = makeStyles((theme: Theme) => ({
  bannerText: {
    color: "#FFFFFF",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
  bannerAnimatedDot: {
    display: "flex",
    flexShrink: 0,
    content: "''",
    margin: 4,
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "#FFFFFF",
    animation: "$grow 1s ease-in-out infinite",
  },
  bannerLinkIcon: {
    transform: "rotate(-45deg)",
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
  "@keyframes grow": {
    "0%": {
      transform: "scale(0.5)",
      opacity: 0,
    },
    "80%": {
      transform: "scale(1)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(0.5)",
      opacity: 0,
    },
  },
}));

export default useUnfinishedSettingUpBannerStyles;
