import { makeStyles, Theme } from "@material-ui/core";

export const useItemMenuStyles = makeStyles<Theme>((theme) => ({
  menuLink: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "1.5em",
    color: theme.palette.grey[900],
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export default useItemMenuStyles;
