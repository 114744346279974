import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  shadow?: boolean;
  expanded?: boolean;
}

const useDesignSystemTabsStyles = makeStyles<Theme, Props>((theme) => ({
  appBar: {
    boxShadow: ({ shadow }) => (shadow ? "auto" : "none"),
    marginBottom: theme.spacing(2),
  },
  selected: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: 700,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    height: 4,
    borderRadius: 8,
  },
  tabRoot: {
    fontSize: theme.typography.pxToRem(16),
    textTransform: "unset",
    color: "black",
    minWidth: "auto",
    paddingInline: 0,
    marginLeft: theme.spacing(3),
    paddingBottom: 0,
    paddingTop: 0,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: theme.spacing(2),
    },
  },
}));

export default useDesignSystemTabsStyles;
