import { Alert } from "apps/landlord/pages/Applicant/context/ApplicantContext.types";
import { Application } from "models/Evaluation";

export const DECISIONS_OPTIONS = [
  {
    label: "Apruebo",
    value: "approved",
  },
  {
    label: "Seguir buscando",
    value: "reevaluation",
  },
];

export interface IData {
  decision: string;
  comment?: string;
}

export interface FormData {
  status: string;
  message?: string;
}

export const errorEvaluatingProfile: Alert = {
  status: "error",
  title: "No se pudo evaluar al perfíl",
  message:
    "Por favor intente nuevamente más tarde o contáctese con su ejecutivo",
};

export const errorUpdatingContract: Alert = {
  status: "error",
  title: "No se pudo actualizar contracto",
  message:
    "Por favor intente nuevamente más tarde o contáctese con su ejecutivo",
};

export const successEvaluatingProfile: Alert = {
  status: "success",
  title: "Proceso completado",
  message:
    "El posultante fue correctamente evaluado, porfavor continuar con el llenado del contrato",
};

export const getLastMainApplicantId = (lastApplication: Application) =>
  lastApplication?.applicants.find(
    (codebtor) => codebtor.role === "main_tenant"
  )?.id;
