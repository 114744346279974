import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";

import { useFormNotifications } from "hooks";
import {
  ControlledSelection,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import {
  setLandlordData,
  stepSubmit,
} from "context/loiFormContext/loiFormActions";
import { useLoiForm } from "context/loiFormContext/loiFormContext";
import fields from "context/loiFormContext/loiFormFields";
import { LandlordData } from "context/loiFormContext/loiFormTypes";
import {
  MARITAL_REGIMES,
  CIVIL_STATUSES,
} from "context/loiFormContext/constants";
import FilteredInput from "./components/FilteredInput";
import CallToAction from "./components/CustomCallToAction";
import { landlordDataValidationSchema, requiredText } from "./validationSchema";
import { handleDocumentTypeChange } from "./utils";
import LoiFormStepHeader from "./components/LoiFormStepHeader";

export default function LandlordDataStep() {
  const { state, dispatch } = useLoiForm();
  const [documentRegex, setDocumentRegex] = useState<RegExp>();
  const stepState = state.landlordData;
  const stepFields = fields.landlord;

  const methods = useForm<LandlordData>({
    resolver: yupResolver(landlordDataValidationSchema(documentRegex)),
    reValidateMode: "onBlur",
  });

  const type = methods.watch("identificationType");

  useEffect(() => {
    handleDocumentTypeChange(state.documentTypes, type, setDocumentRegex);
  }, [type]);

  const { errors } = methods.formState;
  useFormNotifications<LandlordData>({ errors, requiredText });

  const onSubmit: SubmitHandler<LandlordData> = (data) => {
    dispatch(setLandlordData({ ...data }));
    stepSubmit(dispatch, state.step, state.availableSteps);
  };

  return (
    <FormProvider {...methods}>
      <LoiFormStepHeader
        title="Persona natural"
        description="Asegúrate de que los datos sean correctos, ya que se utilizarán para 
        la redacción del contrato y del mandato. Todos son obligatorios."
        subtitle="Datos personales"
      />

      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FilteredInput fieldInfo={stepFields.name()}>
          <ControlledTextField
            id="name"
            label={stepFields.name().label(state.country)}
            textFieldProps={{ placeholder: "Ingresar" }}
            defaultValue={stepState.name}
          />
        </FilteredInput>

        <FilteredInput fieldInfo={stepFields.lastName()}>
          <ControlledTextField
            id="lastName"
            label={stepFields.lastName().label(state.country)}
            textFieldProps={{ placeholder: "Ingresar" }}
            defaultValue={stepState.lastName}
          />
        </FilteredInput>

        <FilteredInput fieldInfo={stepFields.secondLastName()}>
          <ControlledTextField
            id="secondLastName"
            label={stepFields.secondLastName().label(state.country)}
            textFieldProps={{ placeholder: "Ingresar" }}
            defaultValue={stepState.secondLastName}
          />
        </FilteredInput>

        <FilteredInput fieldInfo={stepFields.occupation()}>
          <ControlledTextField
            id="occupation"
            label={stepFields.occupation().label(state.country)}
            textFieldProps={{ placeholder: "Ingresar" }}
            defaultValue={stepState.occupation}
          />
        </FilteredInput>

        <FilteredInput fieldInfo={stepFields.identificationType()}>
          <ControlledSelection
            id="identificationType"
            label={stepFields.identificationType().label(state.country)}
            defaultValue={stepState.identificationType}
            options={state.documentTypes}
            compressed={true}
            simple={true}
          />
        </FilteredInput>

        <FilteredInput fieldInfo={stepFields.identificationNumber()}>
          <ControlledTextField
            id="identificationNumber"
            label={stepFields.identificationNumber().label(state.country)}
            textFieldProps={{ placeholder: "Ingresar" }}
            defaultValue={stepState.identificationNumber}
          />
        </FilteredInput>

        <FilteredInput fieldInfo={stepFields.nationality()}>
          <ControlledTextField
            id="nationality"
            label={stepFields.nationality().label(state.country)}
            textFieldProps={{ placeholder: "Ingresar" }}
            defaultValue={stepState.nationality}
          />
        </FilteredInput>

        <FilteredInput fieldInfo={stepFields.rfc()}>
          <ControlledTextField
            id="rfc"
            label={stepFields.rfc().label(state.country)}
            textFieldProps={{ placeholder: "Ingresar" }}
            defaultValue={stepState.rfc}
          />
        </FilteredInput>

        <FilteredInput fieldInfo={stepFields.civilStatus()}>
          <ControlledSelection
            id="civilStatus"
            label={stepFields.civilStatus().label(state.country)}
            defaultValue={stepState.civilStatus}
            options={CIVIL_STATUSES}
            compressed={true}
            simple={true}
          />
        </FilteredInput>

        <FilteredInput fieldInfo={stepFields.maritalRegime()}>
          <ControlledSelection
            id="maritalRegime"
            label={stepFields.maritalRegime().label(state.country)}
            defaultValue={stepState.maritalRegime}
            options={MARITAL_REGIMES}
            compressed={true}
            simple={true}
          />
        </FilteredInput>

        <CallToAction />
      </form>
    </FormProvider>
  );
}
