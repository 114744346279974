import { IVisitsSchedules } from "apps/tenant/components/Forms/DayHoursPicker/utils";
import tenantContractService from "apps/tenant/services/tenantContractService";
import { encodeVisitSchedules } from "apps/tenant/utils/parsers";

const visitSchedulesHandler = async (
  data: IVisitsSchedules[],
  getToken: () => Promise<string>
) => {
  const authToken = await getToken();
  return tenantContractService.setVisitsSchedules(
    authToken,
    encodeVisitSchedules(data)
  );
};

export default visitSchedulesHandler;
