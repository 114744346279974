import currency from "@houm-com/formats/currency";
import { Trans, useTranslation } from "react-i18next";
import { capitalize } from "lodash";
import clx from "classnames";
import { format } from "date-fns";
import { es } from "date-fns/locale";

import { Grid, Typography } from "@material-ui/core";

import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";

import useStyles from "./PaymentBreakdownStyles";
import RenderDetails from "../RenderDetails";

const PaymentBreakdown = () => {
  const { t } = useTranslation("rent-payment");
  const { state } = useRentPayment();
  const {
    price,
    currency: currencyCode,
    originalPrice,
    charges,
    discounts,
    fines,
    readjustment,
    readjustmentDetail,
    discountsDetail,
    chargesDetail,
    date,
  } = state.paymentData;
  const classes = useStyles();

  const paymentMonth = capitalize(
    format(date, "MMMM", {
      locale: es,
    })
  );

  const renderPrice = (color: string, price: number) => {
    const hasPrice = price && price !== 0;

    const priceParsed = currency(price, currencyCode);
    const priceIndicator = color === "red" ? "+" : "-";
    const renderPrice =
      !hasPrice || color === "black"
        ? priceParsed
        : `${priceIndicator} ${priceParsed}`;

    return (
      <Grid
        item
        xs={6}
        className={clx(classes.detailNumber, {
          [classes.red]: hasPrice && color === "red",
          [classes.green]: hasPrice && color === "green",
        })}
      >
        {renderPrice}
      </Grid>
    );
  };

  return (
    <div className={classes.detailsContainer}>
      <Typography className={classes.sectionTitle}>
        <Trans
          i18nKey={t("RENT_MONTH")}
          components={{ 1: <strong /> }}
          values={{ month: paymentMonth }}
        />
      </Typography>
      <Grid
        container
        spacing={1}
        classes={{ root: classes.breakdownContainer }}
      >
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item xs={6} className={classes.detailLabel}>
            {t("RENT_VALUE_LABEL")}
          </Grid>
          {renderPrice("black", Number(originalPrice || price))}
        </Grid>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item xs={6} className={classes.detailLabel}>
            {t("RENT_CHARGES_LABEL")}
          </Grid>
          {renderPrice("red", Number(charges ?? 0))}
        </Grid>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item xs={6} className={classes.detailLabel}>
            {t("RENT_DISCOUNTS_LABEL")}
          </Grid>
          {renderPrice("green", discounts ?? 0)}
        </Grid>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item xs={6} className={classes.detailLabel}>
            {t("RENT_PENALTIES_LABEL")}
          </Grid>
          {renderPrice("red", Number(fines ?? 0))}
        </Grid>
        {readjustment !== 0 && (
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item xs={6} className={classes.detailLabel}>
              {t("RENT_READJUSTMENT_LABEL")}
            </Grid>
            {renderPrice("red", readjustment ?? 0)}
          </Grid>
        )}
      </Grid>
      {discountsDetail?.length < 0 && (
        <RenderDetails title="Detalle descuentos" data={discountsDetail} />
      )}
      {chargesDetail?.length < 0 && (
        <RenderDetails
          title={t("RENT_CHARGES_DETAILS_LABEL")}
          data={chargesDetail}
        />
      )}
      {readjustmentDetail?.length < 0 && (
        <RenderDetails
          title={t("RENT_READJUSTMENT_DETAILS_LABEL")}
          data={readjustmentDetail}
        />
      )}
    </div>
  );
};

export default PaymentBreakdown;
