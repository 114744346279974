import { makeStyles, Theme } from "@material-ui/core";

const useSingleDigitInputStyles = makeStyles((theme: Theme) => ({
  codeInputRoot: {
    display: "flex",
    "& > :nth-child(4)": {
      marginRight: theme.spacing(3),
    },
    "& > :last-child": {
      marginRight: 0,
    },
  },
  digitInputRoot: {
    marginRight: theme.spacing(1),
    width: "fit-content",
  },
  digitInput: {
    backgroundColor: "transparent",
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 700,
    textAlign: "center",
    "&:focus::placeholder": {
      color: "transparent",
    },
  },
  hidden: {
    display: "none",
  },
}));

export default useSingleDigitInputStyles;
