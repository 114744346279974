import { makeStyles, Theme } from "@material-ui/core";

import {
  NAVBAR_HEADER_HEIGHT_DESKTOP,
  NAVBAR_HEADER_HEIGHT_MOBILE,
} from "../../utils/constants";

const defaultBreakpoint = "sm";

const useStyles = makeStyles((theme: Theme) => ({
  navbarRoot: {
    position: "fixed",
    zIndex: theme.zIndex.appBar,
    top: 0,
    left: 0,
    height: NAVBAR_HEADER_HEIGHT_MOBILE,
    width: "100%",
    borderBottom: "2px solid rgb(233,233,233)",
    backgroundColor: "white",
    filter: "drop-shadow(0px 4px 4px rgba(96, 108, 129, 0.1))",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      height: NAVBAR_HEADER_HEIGHT_DESKTOP,
      padding: theme.spacing(0, 10),
      justifyContent: "flex-start",
    },
  },
  arrow: {
    position: "absolute",
    left: 15,
  },
  logo: {
    height: 32.5,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      height: 40,
    },
  },
}));

export default useStyles;
