import { useForm, FormProvider } from "react-hook-form";

import {
  setPublicServices,
  setReady,
} from "context/closureFormContext/closureFormActions";
import { useClosureForm } from "context/closureFormContext/closureFormContext";
import fields from "context/closureFormContext/closureFormFields";
import { PublicServicesProps } from "context/closureFormContext/closureFormTypes";

import CallToAction from "./CustomCallToAction";
import { FilteredSelection, FilteredTextField } from "./FilteredComponents";

export default function PublicServicesStep() {
  const { state, dispatch } = useClosureForm();
  const stepState = state.publicServices;
  const stepFields = fields.publicServices;

  const methods = useForm<PublicServicesProps>({
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    dispatch(
      setPublicServices({
        ...stepState,
        ...data,
        availableServices: stepState.availableServices,
      })
    );
    dispatch(setReady("publicServices"));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FilteredTextField
          id="includedServices"
          fieldInfo={stepFields.includedServices()}
          defaultValue={stepState.includedServices}
        />

        <FilteredTextField
          id="additionalServices"
          fieldInfo={stepFields.additionalServices()}
          defaultValue={stepState.additionalServices}
        />

        <FilteredSelection
          id="waterService"
          fieldInfo={stepFields.waterService()}
          defaultValue={stepState.waterService}
          options={stepState.availableServices.water}
          selectionProps={{
            compressed: true,
            simple: true,
          }}
        />
        <FilteredTextField
          id="waterClient"
          fieldInfo={stepFields.waterClient()}
          defaultValue={stepState.waterClient}
        />

        <FilteredSelection
          id="energyService"
          fieldInfo={stepFields.energyService()}
          defaultValue={stepState.energyService}
          options={stepState.availableServices.energy}
          selectionProps={{
            compressed: true,
            simple: true,
          }}
        />
        <FilteredTextField
          id="energyClient"
          fieldInfo={stepFields.energyClient()}
          defaultValue={stepState.energyClient}
        />

        <FilteredSelection
          id="gasService"
          fieldInfo={stepFields.gasService()}
          defaultValue={stepState.gasService}
          options={stepState.availableServices.gas}
          selectionProps={{
            compressed: true,
            simple: true,
          }}
        />
        <FilteredTextField
          id="gasClient"
          fieldInfo={stepFields.gasClient()}
          defaultValue={stepState.gasClient}
        />

        <FilteredTextField
          id="cleaningClient"
          fieldInfo={stepFields.cleaningClient()}
          defaultValue={stepState.cleaningClient}
        />

        <CallToAction />
      </form>
    </FormProvider>
  );
}
