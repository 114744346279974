import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FieldInput from "@houm-com/ui/FieldInput";

import { Box } from "@material-ui/core";

import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import { setStep } from "apps/payments/context/rentPaymentContext/rentPaymentActions";
import Layout from "apps/payments/layout/RentPaymentLayout";
import { useHoumSnackBar } from "libs/houm-snackbar";
import { useGetQueryParam } from "hooks/useQuery";

import useRentPaymentDataByUserId from "../../hooks/useRentPaymentDataByUserId";
import { Subtitle, Title } from "../../../../components/typography";
import { FormProps as MailFormProps } from "./utils/types";
import validationSchema from "./utils/validationSchema";
import useMailFormStyles from "./MailFormStyles";

const MailForm = () => {
  const { t } = useTranslation("rent-payment");
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const email = useGetQueryParam("email");
  const classes = useMailFormStyles();
  const { dispatch } = useRentPayment();
  const { mutate: mutateByUserId, isLoading: isLoadingByUserId } =
    useRentPaymentDataByUserId();

  useEffect(() => {
    enqueueHoumSnackBar({
      head: t("DEFAULT_EMAIL_HEAD_ERROR"),
      body: t("DEFAULT_EMAIL_BODY_ERROR"),
      type: "error",
    });
  }, [enqueueHoumSnackBar, t]);

  const methods = useForm<MailFormProps>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
    defaultValues: {
      email: email ?? "",
    },
  });

  const onSubmit: SubmitHandler<MailFormProps> = async (data) => {
    mutateByUserId(data);
  };

  const handleBack = () => dispatch(setStep("landing"));

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Layout
          handleBack={handleBack}
          buttonNextText="Ingresar"
          buttonProps={{
            type: "submit",
          }}
          loading={isLoadingByUserId}
        >
          <Box className={classes.emailContainer}>
            <Title>{t("LANDING_EMAIL_TITLE")}</Title>
            <Subtitle>
              <Trans
                i18nKey={t("LANDING_EMAIL_SUBTITLE")}
                components={{ 1: <strong /> }}
              />
            </Subtitle>
            <FieldInput
              id="email"
              label="Correo electrónico"
              required
              errorMessage={methods.formState.errors.email?.message}
              form="novalidateform"
              {...methods.register("email")}
              placeholder="Ingresar"
            />
          </Box>
        </Layout>
      </form>
    </FormProvider>
  );
};

export default MailForm;
