import { useState } from "react";

import { Grid } from "@material-ui/core";

import CircularStepper from "Components/Admin/UIComponents/Stepper/CircularStepper";
import logo from "assets/images/houmLogo.svg";

import SuccessFormCreateAccount from "../SuccessFormCreateAccount";
import { SuccessForm } from "../FormCreateAccount/formUtils";
import FormCreateAccount from "../FormCreateAccount";
import useCreateViewStyles from "./CreateViewStyles";

const CreateView = () => {
  const classes = useCreateViewStyles();
  const [successForm, setSuccessForm] = useState<SuccessForm>({
    success: false,
  });

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} className={classes.formContent}>
        <div className={classes.stepperPosition}>
          <CircularStepper
            stepTextHidden
            step={!successForm.success ? 0 : 1}
            totalSteps={2}
            variant="primary"
          />
        </div>
        <img alt="houm-logo" src={logo} className={classes.logo} />
        {!successForm.success ? (
          <FormCreateAccount setSuccessForm={setSuccessForm} />
        ) : (
          <SuccessFormCreateAccount successForm={successForm} />
        )}
      </Grid>
    </Grid>
  );
};

export default CreateView;
