import { makeStyles, Theme } from "@material-ui/core";

const useVisitsLinksStyles = makeStyles((theme: Theme) => ({
  visitsLinksRoot: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100vw",
    padding: theme.spacing(2),
    backgroundColor: "#EFF2F3",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "inherit",
      position: "relative",
      width: "auto",
      padding: 0,
      flexDirection: "row-reverse",
    },
  },
}));

export default useVisitsLinksStyles;
