import { State, Action, ActionType } from "./loiFormTypes";

export const initialState: State = {
  loading: true,
  availableSteps: ["landlordData", "landlordDocs", "summaryLoi"],
  step: "landlordData",
  readyToSubmit: false,
  submitting: false,
  navText: "Continuar",
  country: "Chile",
  documentTypes: [],
  ids: {
    propertyUid: "",
    propertyId: 0,
    negotiationId: "",
    userId: 0,
  },
  propertyData: {
    uid: "",
    id: 0,
    address: "",
    commune: "",
    country: "",
  },
  landlordData: {
    name: "",
    lastName: "",
    secondLastName: "",
    identificationType: "",
    identificationNumber: "",
    civilStatus: "unmarried",
    maritalRegime: "notApply",
    occupation: "",
    rfc: "",
    nationality: "",
    phoneNumber: "",
  },
  landlordDocs: {
    identificationDoc1: null,
    identificationDoc2: null,
    appraisalCertificate: null,
    propertyDeed: null,
    curp: null,
    rfc: null,
    birthCertificate: null,
    marriageCertificate: null,
  },
  landlordBankData: {
    clabe: "",
  },
  propertyDocs: {
    propertyDeed: null,
    propertyBill: null,
    waterBill: null,
    traditionCertificate: null,
  },
  brokerageContract: {
    signedContract: null,
  },
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_IDS:
      return { ...state, ids: action.ids };
    case ActionType.SET_LOADING:
      return { ...state, loading: action.loading };
    case ActionType.SET_AVAILABLE_STEPS:
      return { ...state, availableSteps: action.steps };
    case ActionType.SET_STEP:
      return { ...state, step: action.step };
    case ActionType.NEXT_STEP:
      return {
        ...state,
        step: state.availableSteps[
          state.availableSteps.indexOf(state.step) + 1
        ],
      };
    case ActionType.PREV_STEP:
      return {
        ...state,
        step: state.availableSteps[
          state.availableSteps.indexOf(state.step) - 1
        ],
      };
    case ActionType.SET_NAV_TEXT:
      return { ...state, navText: action.navText };
    case ActionType.SET_DOC_TYPES:
      return { ...state, documentTypes: action.documentTypes };
    case ActionType.SET_COUNTRY:
      return { ...state, country: action.country };
    case ActionType.SET_LANDLORD_DATA:
      return {
        ...state,
        landlordData: { ...state.landlordData, ...action.landlordData },
      };
    case ActionType.SET_LANDLORD_DOCS:
      return {
        ...state,
        landlordDocs: { ...state.landlordDocs, ...action.landlordDocs },
      };
    case ActionType.SET_LANDLORD_BANK_DATA:
      return {
        ...state,
        landlordBankData: {
          ...state.landlordBankData,
          ...action.landlordBankData,
        },
      };
    case ActionType.SET_PROPERTY_DATA:
      return {
        ...state,
        propertyData: { ...state.propertyData, ...action.propertyData },
      };
    case ActionType.SET_PROPERTY_DOCS:
      return {
        ...state,
        propertyDocs: { ...state.propertyDocs, ...action.propertyDocs },
      };
    case ActionType.SET_BROKERAGE_CONTRACT:
      return {
        ...state,
        brokerageContract: {
          ...state.brokerageContract,
          ...action.brokerageContract,
        },
      };
    case ActionType.SET_READY:
      return { ...state, readyToSubmit: action.ready };
    case ActionType.SET_SUBMITTING:
      return { ...state, submitting: action.submitting };
    default:
      return state;
  }
};

export default apiReducer;
