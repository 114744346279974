import { Grid } from "@material-ui/core";

import { IMarketPlaceStats } from "apps/landlord/pages/Price/hooks/utils/types";

import InfoRow from "../InfoRow";

interface Props {
  marketplaceStats: IMarketPlaceStats;
}

const MarketplaceStats = ({ marketplaceStats }: Props) => (
  <>
    <Grid item xs={4} md={3}>
      <InfoRow title="Vistas en la web" value={marketplaceStats.pageViews} />
    </Grid>
    <Grid item xs={4} md={3}>
      <InfoRow title="Visitas agendadas" value={marketplaceStats.allVisits} />
    </Grid>
    <Grid item xs={4} md={3}>
      <InfoRow title="Visitas realizadas" value={marketplaceStats.doneVisits} />
    </Grid>
  </>
);

export default MarketplaceStats;
