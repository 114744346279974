import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  nested: boolean;
  selected: boolean;
}

const useAuthMenuItemStyles = makeStyles<Theme, Props>((theme) => ({
  authMenuItemRoot: {
    width: "100%",
    padding: ({ nested }) => theme.spacing(1, nested ? 2 : 1),
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
    "& p": {
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      padding: theme.spacing(1, 2.5),
    },
  },
  check: {
    width: 20,
    opacity: ({ selected }) => (selected ? 1 : 0),
    marginRight: theme.spacing(1),
  },
}));

export default useAuthMenuItemStyles;
