import { InspectionReportQualifications } from "services/propertyService/types";

const statusRatingStars: {
  [status in InspectionReportQualifications]: number;
} = {
  excellent: 5,
  good: 4,
  acceptable: 3,
  bad: 2,
  does_not_have: 1,
  unanswered: 0,
};

export default statusRatingStars;
