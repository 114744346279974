import currency from "@houm-com/formats/currency";

import { IProperty } from "apps/landlord/models/Property";

export default (property: IProperty, type: "rent" | "sale") => {
  if (type === "rent")
    return currency(
      property?.propertyDetails?.price ?? 0,
      property?.propertyDetails.currencyCode,
      {
        minimumFractionDigits: 0,
      }
    );

  return currency(
    property?.salesDetails?.price ?? 0,
    property?.salesDetails.currencyCode,
    {
      minimumFractionDigits: 0,
    }
  );
};
