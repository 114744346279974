import { useState } from "react";

import { Box } from "@material-ui/core";

import useNavbarHeight from "apps/landlord/hooks/useNavbarHeight";
import { IProperty } from "apps/landlord/models/Property";
import Spinner from "Components/Shared/partials/Spinner";

import PublishPropertyReminder from "./components/PublishPropertyReminder";
import usePropertiesFlag from "./hooks/usePropertiesFlag";
import SummaryHeader from "./components/SummaryHeader";
import AccountInfo from "./components/AccountInfo";
import Properties from "./components/Properties";
import useSummaryStyles from "./SummaryStyles";

const Summary = () => {
  const navbarHeight = useNavbarHeight();
  const classes = useSummaryStyles({ navbarHeight });
  const [property, setProperty] = useState<Partial<IProperty> | null>(null);
  const choseProperty = (e: Partial<IProperty>) => setProperty(e);
  const { propertiesFlag, rentedFlag, isLoading } = usePropertiesFlag();

  if (isLoading)
    return (
      <Box className={classes.loadingContainer}>
        <Spinner />
      </Box>
    );

  if (!propertiesFlag) return <PublishPropertyReminder />;

  return (
    <Box className={classes.container}>
      <SummaryHeader onChoseProperty={choseProperty} />
      <Box className={classes.summaryRoot}>
        <Box className={classes.leftContainer}>
          <AccountInfo property={property} rentedFlag={rentedFlag} />
        </Box>
        <Box className={classes.rightContainer}>
          <Properties property={property} />
        </Box>
      </Box>
    </Box>
  );
};

export default Summary;
