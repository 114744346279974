import { useState } from "react";
import currency from "@houm-com/formats/currency";

import { Box, Typography } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { Summary } from "apps/landlord/pages/Summary/utils/types";

import usePaymentSummaryDetailsStyles from "./PaymentSummaryDetailsStyles";
import Breakdown from "../Breakdown";

interface Props {
  metrics: Summary;
}

const PaymentSummaryDetails = ({ metrics }: Props) => {
  const classes = usePaymentSummaryDetailsStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box>
      <Box className={classes.moreDetailsContainer} onClick={handleClick}>
        <Typography>{open ? "Ocultar detalle" : "Mostrar detalle"}</Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </Box>
      {open && (
        <Box className={classes.paymentDetailContainer}>
          <Breakdown
            label="Arriendos recaudados"
            value={`+${currency(
              metrics.depositedPayments.collection,
              metrics.currency
            )}`}
          />
          <Breakdown
            label="Abonos recibidos"
            value={`+${currency(
              metrics.totalAdjustment.additionalValue,
              metrics.currency
            )}`}
          />
          <Breakdown
            label="Descuentos aplicados"
            value={`-${currency(
              metrics.totalAdjustment.discountValue -
                metrics.totalManagement.value,
              metrics.currency
            )}`}
          />
          <Breakdown
            label="Administración Houm"
            value={`-${currency(
              metrics.totalManagement.value,
              metrics.currency
            )}`}
          />
        </Box>
      )}
    </Box>
  );
};

export default PaymentSummaryDetails;
