import { makeStyles, Theme } from "@material-ui/core";

const useBankAccountCardStyles = makeStyles((theme: Theme) => ({
  bankAccountCardRoot: {
    padding: theme.spacing(2),
    backgroundColor: "white",
    borderRadius: 8,
    [theme.breakpoints.up("sm")]: {
      maxWidth: 343,
    },
  },
  infoLabel: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey["A400"],
  },
  infoContent: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[900],
  },
  editAccountButton: {
    margin: theme.spacing(2, 0, 3),
    padding: theme.spacing(0.5, 2),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.6,
    "&:hover": {
      "& path": {
        stroke: theme.palette.primary.main,
      },
    },
  },
  editIcon: {
    "& path": {
      stroke: "white",
    },
  },
}));

export default useBankAccountCardStyles;
