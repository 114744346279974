import {
  EvaluationApplicant,
  PropertyApplicationModel,
} from "apps/lead/models/Application";

const usePropertyDetails = (
  property: PropertyApplicationModel,
  houmerName: string | null,
  evaluationData?: EvaluationApplicant
) => {
  const propertyPrice =
    property.propertyDetails[0]?.promotionalPrice > 0
      ? property.propertyDetails[0]?.promotionalPrice
      : property.propertyDetails[0]?.valor;
  const currency = property.propertyDetails[0]?.typeMoney;
  const houmer = houmerName ?? "No tiene";

  const orderEvaluationDataByMainTenant = () => ({
    ...evaluationData,
    persons: [
      ...evaluationData.persons.filter((person) => person.role === "main"),
      ...evaluationData.persons.filter((person) => person.role === "codebtor"),
    ],
  });

  return {
    propertyPrice,
    currency,
    houmer,
    evaluation: evaluationData ? orderEvaluationDataByMainTenant() : null,
  };
};

export default usePropertyDetails;
