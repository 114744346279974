import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

import { Typography, Grid } from "@material-ui/core";

import { IMainPropertyPropertyAnalysis } from "apps/landlord/pages/Price/hooks/useMainProperty/types";
import CardMenuItem from "apps/landlord/components/CardMenuItem";
import { countryCode } from "utils";

import MarketplaceStats from "./components/MarketplaceStats";
import { AnalysisTypeProperty } from "../../utils/types";
import CallToAction from "./components/CallToAction";
import InfoRow from "./components/InfoRow";
import useStyles from "./RevBoxStyles";
import {
  getCurrentPrice,
  getRecommendedPrice,
  isButtonAvailable,
} from "./utils/utils";

interface Props {
  mainProperty: IMainPropertyPropertyAnalysis;
  type: AnalysisTypeProperty;
  handleChangeType: (e: AnalysisTypeProperty) => void;
  isForRentalAndSale: boolean;
}

const RevBox = ({
  mainProperty,
  type,
  handleChangeType,
  isForRentalAndSale,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const locale = countryCode(mainProperty?.country);

  const handleRedirect = () =>
    history.push(
      `/landlord/properties/${mainProperty.uid}/property-price/set-price`
    );

  const currentPrice = getCurrentPrice(mainProperty, locale, type);
  const recommendedPrice = getRecommendedPrice(mainProperty, locale, type);

  const typeLabel = type === "rent" ? "arriendo" : "venta";

  return (
    <CardMenuItem
      title="Desempeño de tu publicación"
      subtitle={`Podrás ver información útil para establecer el precio de ${typeLabel}, estadísticas de tu publicación y comentarios que dejen después de visitar tu propiedad.`}
      buttonLabel={`Editar precio de ${typeLabel}`}
      isButtonAvailable={isButtonAvailable(
        type,
        mainProperty.propertyDetails[0].status
      )}
      onViewInfoClick={handleRedirect}
      cardClassName={classes.card}
      infoContainerClassName={classes.infoContainer}
      buttonVariant="primary"
    >
      <Typography className={classes.text}>
        Esta información es de las últimas 2 semanas
      </Typography>
      <CallToAction
        handleChangeType={handleChangeType}
        isForRentalAndSale={isForRentalAndSale}
        type={type}
      />
      <Grid
        item
        container
        className={classes.statsContainer}
        spacing={isMobile ? 2 : 0}
      >
        <Grid item xs={12} md={3}>
          <InfoRow
            title={type === "rent" ? "Arriendo mensual" : "Precio venta"}
            value={currentPrice?.primaryPrice}
            secondaryValue={currentPrice?.secondaryPrice}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InfoRow
            title={
              type === "rent" ? "Arriendo recomendado" : "Precio recomendado"
            }
            value={recommendedPrice?.primaryPrice}
            secondaryValue={recommendedPrice?.secondaryPrice}
          />
        </Grid>
        <MarketplaceStats marketplaceStats={mainProperty.marketplaceStats} />
      </Grid>
    </CardMenuItem>
  );
};

export default RevBox;
