import { ReactNode } from "react";
import { Carousel, CarouselProps } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Box } from "@material-ui/core";

import useNavbarHeight from "apps/landlord/hooks/useNavbarHeight";
import { Photos } from "models/Property";

import DefaultCard from "./components/DefaultCard";
import useStyles from "./PhotoCarouselStyles";

interface Props {
  photos?: Photos[];
  children?: ReactNode;
  carouselProps?: CarouselProps;
}

const PhotoCarousel = ({
  photos = [],
  children = null,
  carouselProps,
}: Props) => {
  const navbarHeight = useNavbarHeight();
  const classes = useStyles({ navbarHeight });
  return (
    <Box className={classes.carouselRoot}>
      {photos?.length > 0 ? (
        <Carousel
          autoPlay
          infiniteLoop
          showIndicators={photos.length > 1 && photos.length < 8}
          selectedItem={0}
          showArrows={false}
          centerMode={false}
          showThumbs={false}
          showStatus={false}
          dynamicHeight={true}
          centerSlidePercentage={90}
          className={classes.carousel}
          {...carouselProps}
        >
          {photos.map((item, idx) => (
            <img
              key={item.id}
              src={item.url}
              alt={`Foto propiedad ${idx}`}
              className={classes.carouselImage}
            />
          ))}
        </Carousel>
      ) : (
        <DefaultCard />
      )}
      {children}
    </Box>
  );
};

export default PhotoCarousel;
