import ScheduleDates from "./components/ScheduleDates";
import ScheduledMetrics from "./components/ScheduledMetrics";
import ScheduleFeedback from "./components/ScheduleFeedback";

const ScheduledVisit = () => (
  <>
    <ScheduledMetrics />
    <ScheduleDates />
    <ScheduleFeedback />
  </>
);

export default ScheduledVisit;
