import { Grid, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CallToAction from "Components/Admin/UIComponents/atoms/CallToAction";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import { useLoiForm } from "../context/LoiFormContext";
import useFinishedProcessStyles from "./FinishedProcessStyles";

const FinishedProcess = () => {
  const finishedProcessClasses = useFinishedProcessStyles();
  const {
    state: { loiOwners },
    handlers: { goToPropertyDetail },
  } = useLoiForm();
  const mainOwnerData = loiOwners.find((owner) => owner.is_main_user);

  return (
    <Grid container className={finishedProcessClasses.rootGrid}>
      <Grid item className={finishedProcessClasses.iconContainer}>
        <CheckCircleIcon className={finishedProcessClasses.iconCheck} />
      </Grid>
      <Grid item>
        <Typography className={finishedProcessClasses.title}>
          Estás cada vez más cerca de cerrar esta venta
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={finishedProcessClasses.description}>
          Hemos enviado tu información para generar el borrador de promesa de
          compra.
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={finishedProcessClasses.description}>
          {`Te mandaremos un correo a ${mainOwnerData.email} en caso de necesitar más información o documentos.`}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={finishedProcessClasses.description}>
          Cualquier duda no dudes en ponerte en contacto con nosotros. Estaremos
          encantados de ayudarte.
        </Typography>
      </Grid>
      <CallToAction>
        <DesignSystemButton
          label="Ir a gestión de propiedades"
          onClick={goToPropertyDetail}
          size="large"
          variant="primary"
          fullWidth
        />
      </CallToAction>
    </Grid>
  );
};

export default FinishedProcess;
