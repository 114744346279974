import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import { getCookie, setCookie } from "../../utils/utilCookie";

const COOKE_EXPIRATION_DAYS = 3;

const getFlagOnURLParams = () => {
  const location = useLocation();
  const { ff } = queryString.parse(location.search, { arrayFormat: "comma" });
  return ff;
};

const getFlagOnCookie = () => {
  const ff = getCookie("ff");
  if (ff) {
    return ff.split(",");
  }
  return false;
};

const find = (target: string[], resource: string[] | string) =>
  target.every((flag) => resource.includes(flag));

// ff: 'flag1,flag2,flag3'
export const useURLParamsFlag = (...flags: string[]) => {
  const ffURLParams = getFlagOnURLParams();
  if (ffURLParams) {
    return find(flags, ffURLParams);
  }
  return false;
};

export const useCachedFlag = (...flags: string[]) => {
  const [isFlag, setIsFlag] = useState(false);
  const ffURLParams = getFlagOnURLParams();

  useEffect(() => {
    const ffCookie = getFlagOnCookie();
    if (ffCookie) {
      setIsFlag(find(flags, ffCookie));
    }
  }, []);

  useEffect(() => {
    if (ffURLParams) {
      const finded = find(flags, ffURLParams);
      if (finded) {
        setCookie("ff", flags.join(","), COOKE_EXPIRATION_DAYS);
        setIsFlag(true);
      }
    }
  }, [ffURLParams]);

  return isFlag;
};
