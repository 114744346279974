import { getPhotoFileName } from "apps/tenant/hooks/utils/utils";
import servicesRequestsService from "apps/tenant/services/servicesRequestsService/servicesRequestsService";
import { IServicesRequestBody } from "apps/tenant/services/servicesRequestsService/types";
import { uploadToS3 } from "utils/fileStreaming";

import { IServicesRequest } from "./types";

export const uploadServicesRequestPhotos = async (
  token: string,
  photos: File[]
): Promise<string[]> => {
  try {
    const fileNames = photos.map((file) => getPhotoFileName(file.name));
    const presignedUrls = await servicesRequestsService.getPresignedUrl(token, {
      file_names: fileNames,
    });
    Object.entries(presignedUrls.data).forEach(
      async ([fileName, presignedUrl], index) => {
        await uploadToS3({
          fileContents: photos[index],
          presignedUrl: { ...presignedUrl, filePath: fileName },
        });
      }
    );
    return fileNames;
  } catch (error) {
    return null;
  }
};

export const encodeServicesRequest = (
  data: IServicesRequest,
  fileNames: string[]
): IServicesRequestBody => ({
  debt_type: data.debtType,
  description: data.description,
  regularization_type: data.regularizationType,
  photos: fileNames.map((filename) => ({ photo_filename: filename })),
});

export const getPhotos = (servicesRequest: IServicesRequest) => [
  ...(servicesRequest.ggccPhotos ?? []),
  ...(servicesRequest.ssbbPhotos ?? []),
];
