import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRouteMatch } from "react-router-dom";

import Button from "@houm-com/ui/Button";

import CallToActionDrawer from "apps/ui/CustomDrawer/CallToActionDrawer/CallToActionDrawer";
import usePropertyDetails from "apps/landlord/hooks/usePropertyDetails";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import DrawerHeader from "apps/ui/CustomDrawer/DrawerHeader";
import Spinner from "Components/Shared/partials/Spinner";
import { useProperty } from "apps/landlord/hooks";
import Drawer from "apps/ui/CustomDrawer/Drawer";
import { parseCountryCurrencyCode } from "utils";

import validationSchema from "./PriceForm/validationSchema";
import INITIAL_FORM_VALUES from "./hooks/constants";
import useSetPriceStyles from "./SetPriceStyles";
import { PriceFormData } from "./hooks/types";
import useSetPrice from "./hooks/useSetPrice";
import PriceForm from "./PriceForm/PriceForm";

const SetPrice = () => {
  const match = useRouteMatch();
  const classes = useSetPriceStyles();
  const { propertyData, propertyLoading } = useProperty();
  const { detailsData, detailsLoading } = usePropertyDetails(propertyData?.id);
  const { open, returnToMainPage, saveParentUrl } = useCustomDrawer();
  const isForSale = propertyData?.forSale;
  const isForRental = propertyData?.forRental;
  const isForBoth = isForSale && isForRental;

  const initialBusinessType = useMemo(() => {
    if (propertyData?.forSale) return "SALE";
    if (propertyData?.forRental) return "RENT";
    return null;
  }, [propertyData]);

  const methods = useForm<PriceFormData>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: INITIAL_FORM_VALUES,
  });

  const {
    formState: { isValid },
    setValue,
    watch,
  } = methods;

  const businessType = watch("businessType");

  const { mutate } = useSetPrice({
    propertyData,
    propertyDetails: detailsData,
    businessType,
  });

  useEffect(() => {
    const helpURL = match.url.replace("/set-price", "");
    if (propertyData) {
      saveParentUrl(helpURL);
      setValue("businessType", isForBoth ? "RENT" : initialBusinessType);
      const parsedCurrency = parseCountryCurrencyCode(propertyData?.country);
      setValue("currency", parsedCurrency);
    }
  }, [isForBoth, initialBusinessType, propertyData, saveParentUrl, setValue]);

  const displaySpinner = useMemo(
    () => detailsLoading || propertyLoading,
    [detailsLoading, propertyLoading]
  );

  const isReserved = propertyData?.propertyDetails?.status === "reserved";

  return (
    <Drawer onHandleClose={returnToMainPage} open={open}>
      <DrawerHeader onHandleClose={returnToMainPage} />
      {displaySpinner && (
        <section className={classes.fullSizeSpinner}>
          <Spinner />
        </section>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit((formData) => mutate(formData))}>
          <PriceForm propertyData={propertyData} />
          <CallToActionDrawer>
            <Button variant="tertiary" size="md" onClick={returnToMainPage}>
              Atrás
            </Button>
            <Button
              disabled={!isValid || isReserved}
              variant="primary"
              size="md"
              type="submit"
            >
              Siguiente
            </Button>
          </CallToActionDrawer>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default SetPrice;
