import { Box, Typography } from "@material-ui/core";

import { SignedContracts } from "apps/landlord/services/propertyContractService/types";

import useMenuDocumentsStyles from "./MenuDocumentsStyles";
import { getDocumentsList } from "../../utils/utils";
import MenuItem from "../MenuItem";

interface Props {
  documents: SignedContracts;
}

const MenuDocuments = ({ documents }: Props) => {
  const classes = useMenuDocumentsStyles();
  const list = getDocumentsList(documents);

  if (list.length === 0)
    return (
      <Typography className={classes.noDocuments}>
        No hay documentos disponibles
      </Typography>
    );

  return (
    <Box className={classes.menuContainer}>
      {list.map((item) => (
        <MenuItem document={item} key={item.id} />
      ))}
    </Box>
  );
};

export default MenuDocuments;
