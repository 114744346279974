import { isMobile } from "react-device-detect";

import { Box } from "@material-ui/core";

import { useNavbar } from "../../context/navbarContext";
import BoldMenu from "../BoldMenu";
import AuthMenuItem from "./components/AuthMenuItem";
import { getAuthMenuContent, generateKey } from "./utils/utils";

const AuthMenu = () => {
  const { usingAs } = useNavbar();

  return (
    <Box>
      {getAuthMenuContent(usingAs).map((item) => (
        <AuthMenuItem
          key={generateKey(item.label)}
          label={item.label}
          link={item.link}
          isLogout={item.isLogout}
          nested={item.nested}
          selected={item.selected}
        />
      ))}
      {isMobile && <BoldMenu />}
    </Box>
  );
};

export default AuthMenu;
