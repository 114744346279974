import { makeStyles, Theme } from "@material-ui/core";

const useHeaderStyles = makeStyles((theme: Theme) => ({
  closeIconWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closeIcon: {
    margin: theme.spacing(1),
    width: 15,
    height: 15,
    cursor: "pointer",
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
    fontWeight: 700,
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export default useHeaderStyles;
