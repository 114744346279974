import { FC, SVGProps } from "react";

const PetsIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.705 9.43214C19.578 9.38221 19.4428 9.35676 19.3064 9.35714H19.2921C18.5718 9.36785 17.7639 10.0425 17.34 11.0629C16.8318 12.2836 17.0657 13.5325 17.8664 13.8536C17.9932 13.9035 18.1283 13.929 18.2646 13.9286C18.9885 13.9286 19.8075 13.25 20.235 12.2229C20.7396 11.0021 20.5021 9.75321 19.705 9.43214Z"
      stroke="#263238"
      strokeWidth={1.6}
      strokeMiterlimit={10}
    />
    <path
      d="M15.0571 14.1957C14.0642 12.5482 13.6357 11.9286 12.4999 11.9286C11.3642 11.9286 10.9321 12.5521 9.93923 14.1957C9.08923 15.6014 7.37136 15.7186 6.94279 16.9125C6.85585 17.1312 6.81218 17.3647 6.81422 17.6C6.81422 18.5711 7.55708 19.3572 8.47137 19.3572C9.60708 19.3572 11.1535 18.4504 12.5035 18.4504C13.8535 18.4504 15.3928 19.3572 16.5285 19.3572C17.4428 19.3572 18.1821 18.5714 18.1821 17.6C18.1829 17.3645 18.138 17.131 18.05 16.9125C17.6214 15.7143 15.9071 15.6014 15.0571 14.1957Z"
      stroke="#263238"
      strokeWidth={1.6}
      strokeMiterlimit={10}
    />
    <path
      d="M10.2324 10.3571C10.2802 10.3572 10.328 10.3536 10.3753 10.3464C11.2042 10.2261 11.7217 9.0775 11.5338 7.78036C11.357 6.5575 10.6149 5.64285 9.83882 5.64285C9.791 5.64281 9.74324 5.6464 9.69596 5.65357C8.86703 5.77392 8.34953 6.9225 8.53739 8.21964C8.71418 9.43893 9.45632 10.3571 10.2324 10.3571Z"
      stroke="#263238"
      strokeWidth={1.6}
      strokeMiterlimit={10}
    />
    <path
      d="M16.4612 8.21964C16.6491 6.9225 16.1316 5.77392 15.3027 5.65357C15.2554 5.6464 15.2076 5.64281 15.1598 5.64285C14.3837 5.64285 13.643 6.5575 13.4659 7.78036C13.278 9.0775 13.7955 10.2261 14.6245 10.3464C14.6717 10.3536 14.7195 10.3572 14.7673 10.3571C15.5434 10.3571 16.2855 9.43893 16.4612 8.21964Z"
      stroke="#263238"
      strokeWidth={1.6}
      strokeMiterlimit={10}
    />
    <path
      d="M7.13458 13.8536C7.93423 13.5321 8.1678 12.2821 7.6603 11.0629C7.23315 10.0357 6.41494 9.35714 5.69208 9.35714C5.5558 9.35676 5.42068 9.38221 5.29386 9.43214C4.49422 9.75357 4.26065 11.0036 4.76815 12.2229C5.19529 13.25 6.01351 13.9286 6.73637 13.9286C6.87265 13.929 7.00777 13.9035 7.13458 13.8536Z"
      stroke="#263238"
      strokeWidth={1.6}
      strokeMiterlimit={10}
    />
  </svg>
);
export default PetsIcon;
