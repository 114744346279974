import { useUser } from "context/userContext";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const PUBLIC_ROUTES = [
  "/account/create",
  "/pagar",
  "/pagar/cl",
  "/pagar/co",
  "/pagar/mx",
  "/reservation",
  "/contract",
  "/lease",
  "/start-evaluation",
  "/landlord/terms-and-conditions",
];

const AUTH_CALLBACK_PATH_KEY = "auth:callback:path";

export const testPublicRoute = (url: string) =>
  PUBLIC_ROUTES.some((route) => url.startsWith(route));

const ForceLoginControl = ({
  children,
}: {
  children: React.ReactElement | null;
}) => {
  const { user, loginWithRedirect } = useUser();
  const location = useLocation();
  const history = useHistory();

  if (testPublicRoute(location.pathname)) {
    return children;
  }

  useEffect(() => {
    if (user) {
      const authCallbackPath = window.localStorage.getItem(
        AUTH_CALLBACK_PATH_KEY
      );
      if (authCallbackPath) {
        window.localStorage.removeItem(AUTH_CALLBACK_PATH_KEY);
        history.push(authCallbackPath);
      }
    }
    return () => {};
  }, [user, history]);

  useEffect(() => {
    if (!user) {
      loginWithRedirect();
      window.localStorage.setItem(AUTH_CALLBACK_PATH_KEY, location.pathname);
    }
    return () => {};
  }, [user, location, loginWithRedirect]);

  if (user) {
    return children;
  }
  return null;
};

export default ForceLoginControl;
