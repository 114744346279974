import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";

import bankAccountService from "apps/landlord/services/bankAccountService";
import { useHoumSnackBar } from "libs/houm-snackbar";
import { localeToCountry } from "utils/countryCode";
import userService from "services/userService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

import { BankAccount } from "./utils/types";
import parseBank from "./utils/parsers";

const useBankAccountByPropertyId = (country: Locale, propertyId: number) => {
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const { getToken } = useGetToken();
  const user = userService.me();

  const {
    isLoading,
    error,
    data,
    refetch,
  }: UseQueryResult<BankAccount, AxiosError> = useQuery<
    BankAccount,
    AxiosError
  >(
    [`PROPERTY_BANK_ACCOUNT_${propertyId}`, user.id, propertyId],
    async () => {
      const authToken = await getToken();
      const response = await bankAccountService.getByUserAndProperty(
        country,
        user.id,
        propertyId,
        authToken
      );
      return parseBank(response?.data, localeToCountry[country]);
    },
    {
      enabled: !!user && !!propertyId && !!country,
      retry: 0,
      onError: (e) =>
        e.response?.status !== 404 &&
        enqueueHoumSnackBar({
          body: "Ha ocurrido un error",
          type: "error",
        }),
    }
  );

  return {
    bankLoading: isLoading,
    bankError: error,
    bankData: data,
    refetch,
  };
};

export default useBankAccountByPropertyId;
