import { Box, Typography } from "@material-ui/core";

import useProfileCardStyles from "./ProfileCardStyles";

interface Props {
  children: React.ReactNode;
  text?: string;
}

const ProfileCard = ({ children, text }: Props) => {
  const classes = useProfileCardStyles();

  return (
    <Box className={classes.profileCardRoot}>
      {text && <Typography className={classes.text}>{text}</Typography>}
      {children}
    </Box>
  );
};

export default ProfileCard;
