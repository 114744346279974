import { useQuery } from "react-query";

import { useGetToken } from "hooks";
import inspectionReportService from "services/inspectionReportService";
import { parseReportListItem } from "./parsers";

const useInspectionReports = (propertyId: number) => {
  const { getToken } = useGetToken();

  const handleRequestInspectionReports = async () => {
    const authToken = await getToken();
    const { getInspectionsReport } = inspectionReportService(authToken);
    const {
      data: { results: inspectionReportList },
    } = await getInspectionsReport({
      propertyId,
    });
    const parsedReportList = inspectionReportList?.map(parseReportListItem);
    return parsedReportList;
  };

  const {
    isLoading,
    data: inspectionReportList,
    isError,
  } = useQuery(
    ["inspection_report", propertyId],
    handleRequestInspectionReports,
    { enabled: !!propertyId }
  );

  const getInspectionReportById = (inspectionReportId: number) =>
    inspectionReportList?.find(
      (inspectionReport) => inspectionReport?.id === inspectionReportId
    );

  return {
    isLoading,
    isError,
    inspectionReportList,
    getInspectionReportById,
  };
};

export default useInspectionReports;
