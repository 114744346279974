import { ReactElement } from "react";

import { isBrowser } from "react-device-detect";

import PropertyDesktop from "./components/PropertyDesktop";
import PropertyMobile from "./components/PropertyMobile";
import { Option, IProperty } from "./utils/types";

interface Props {
  labelButton?: string;
  onHandleClick: () => void;
  property: IProperty;
  optionList?: Option[];
  onHandleOpenProperty?: () => void;
  statusLabel: string;
  chipClassName?: string;
  children?: ReactElement;
}

const PropertyCard = ({
  labelButton,
  onHandleClick,
  property,
  optionList = [],
  onHandleOpenProperty,
  statusLabel,
  chipClassName = "",
  children,
}: Props) =>
  isBrowser ? (
    <PropertyDesktop
      labelButton={labelButton}
      handleClick={onHandleClick}
      property={property}
      optionList={optionList}
      onHandleOpenProperty={onHandleOpenProperty}
      statusLabel={statusLabel}
      chipClassName={chipClassName}
    >
      {children}
    </PropertyDesktop>
  ) : (
    <PropertyMobile
      labelButton={labelButton}
      handleClick={onHandleClick}
      property={property}
      optionList={optionList}
      onHandleOpenProperty={onHandleOpenProperty}
      statusLabel={statusLabel}
      chipClassName={chipClassName}
    >
      {children}
    </PropertyMobile>
  );

export default PropertyCard;
