export default {
  title: "!text-h24",
  updateBillsLink: "cursor-pointer underline text-secondary-cta",
  info: "flex p-2 my-4 border rounded items-center w-fit text-information-100 bg-information-10",
  infoIcon: "mr-2",
  cardsContainer: "grid grid-cols-1 md:grid-cols-2 gap-4 my-4 p-2",
  footerInfo: "md:flex md:items-center",
  footerInfoButton:
    "ml-1 text-primary-cta flex items-center cursor-pointer hover:underline",
  footerInfoIcon: "ml-1 rotate-45",
};
