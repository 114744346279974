import { ReactNode } from "react";

import { SnackbarType } from "Components/Admin/UIComponents/DesignSystem/DesignSystemSnackbar/utils";
import { ContractModel } from "models/Contract";
import { Locale } from "models/Countries";
import { Application } from "models/Evaluation";

export interface IParams {
  countryCode: Locale;
  id: string;
}

export interface ApplicantContextProviderProps {
  children: ReactNode;
}

export enum Views {
  MAIN,
  MOBILE_APPLICANT_DETAIL,
}

export const INITIAL_DATA = {
  currentView: Views.MAIN,
  EvaluationModalApplication: null,
  applications: [],
  historialApplications: null,
  countryCode: null,
  lastApplication: null,
  contract: null,
  selectedApplication: null,
  isLoading: true,
  submitResponse: { status: null, title: "", message: "" },
  handleBack: () => {},
  handleSnackbarClose: () => {},
  handleSubmitResponse: () => {},
  handleCurrentView: () => {},
  handleEvaluationModalApplication: () => {},
  handleSelectedApplication: () => {},
};

export interface Alert {
  status: SnackbarType;
  title: string;
  message: string;
}

export interface ApplicantContextData {
  currentView: Views;
  countryCode: Locale;
  EvaluationModalApplication: Application | null;
  lastApplication: Application | null;
  historialApplications: Application[];
  contract: ContractModel | null;
  selectedApplication: Application | null;
  isLoading: boolean;
  submitResponse: Alert;
  handleBack: () => void;
  handleSnackbarClose: () => void;
  handleSubmitResponse: (alert: Alert) => void;
  handleCurrentView: (view: Views) => void;
  handleEvaluationModalApplication: (application: Application) => void;
  handleSelectedApplication: (application: Application) => void;
}
