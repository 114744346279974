import { ReactNode } from "react";

import { Box, Grid, Typography } from "@material-ui/core";

import useDetailsElementStyles from "./DetailsElementStyles";

interface Props {
  icon: ReactNode;
  text: string;
  amount: number | string | boolean;
  plural?: boolean;
}

export default function DetailElement({
  icon,
  text,
  amount,
  plural = true,
}: Props) {
  const classes = useDetailsElementStyles();
  return (
    <Grid sm={3} xs={6} className={classes.informationElement} item>
      <Box className={classes.informationIcon}>{icon}</Box>
      <Typography className={classes.informationText}>
        {amount > 0 && `${amount} `}
        {plural ? text : text?.slice(0, text.length - 1)}
      </Typography>
    </Grid>
  );
}
