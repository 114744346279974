import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { IoAdd as CrossIcon } from "@houm-com/ui-icons";

import { Avatar, Box, Typography } from "@material-ui/core";

import { IVisit } from "apps/tenant/models/Visit";
import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji";
import DSModal from "Components/Admin/UIComponents/DesignSystem/DSModal";

import { getVisitTime, getVisitDate } from "../VisitCard/utils";
import useVisitDetailsStyles from "./VisitDetailsStyles";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  visit: IVisit;
}

const VisitDetails = ({ open, setOpen, visit }: Props) => {
  const classes = useVisitDetailsStyles();
  const { t } = useTranslation("tenant.visits");

  return (
    <DSModal open={open} paperClassName={classes.visitDetailsPaper}>
      <Box className={classes.visitDetailsContent}>
        <CrossIcon
          size={32}
          onClick={() => setOpen(false)}
          className={classes.crossIcon}
        />
        <Emoji
          className={classes.calendar}
          emojiIcon="🗓️"
          ariaLabel="calendar icon"
        />
        <Typography className={classes.date}>
          {getVisitDate(visit.date)}
        </Typography>
        <Typography
          className={clsx(classes.info, {
            [classes.unavailableDate]: visit.status !== "ok",
          })}
        >
          {getVisitTime(visit.date, "ok", visit.cancelReason)}
        </Typography>
        <Typography
          className={clsx(classes.info, {
            [classes.unavailableDate]: visit.status !== "ok",
          })}
        >
          {visit.fullLocation}
        </Typography>
        {visit.status !== "ok" && (
          <Typography className={classes.unavailable}>
            {t("unavailable")}
          </Typography>
        )}
        <Box className={classes.houmerSection}>
          <Avatar
            src={visit.houmerPictureUrl}
            className={classes.houmerAvatar}
          />
          <Box>
            <strong>Tu Houmer</strong>
            <Typography>{visit.houmerName}</Typography>
          </Box>
        </Box>
      </Box>
    </DSModal>
  );
};

export default VisitDetails;
