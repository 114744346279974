import { Grid, GridSize, Typography } from "@material-ui/core";
import clx from "classnames";

import usePropertyDetailsSelectorStyles from "./PropertyDetailsSelectorStyles";
import { PropertyDetailsOptions } from "../../constants";

interface Props {
  options: { label: string; value: PropertyDetailsOptions }[];
  selected: PropertyDetailsOptions;
  setSelected: (selected: PropertyDetailsOptions) => void;
  gridSize: GridSize;
}

interface SelectorOptionsProps {
  isSelected?: boolean;
  option: { label: string; value: PropertyDetailsOptions };
  onClick: (selected: PropertyDetailsOptions) => void;
  gridSize: GridSize;
}

const SelectorOption = ({
  option,
  isSelected = false,
  onClick,
  gridSize,
}: SelectorOptionsProps) => {
  const classes = usePropertyDetailsSelectorStyles();
  return (
    <Grid
      item
      className={clx(classes.selectorItem, {
        [classes.selectorItem_selected]: isSelected,
        [classes.selectorItem_unselected]: !isSelected,
      })}
      onClick={() => onClick(option.value)}
      xs={gridSize}
    >
      <Typography
        className={clx(classes.selectorText, {
          [classes.selectorText_selected]: isSelected,
          [classes.selectorText_unselected]: !isSelected,
        })}
      >
        {option.label}
      </Typography>
    </Grid>
  );
};

export default function PropertyDetailsSelector({
  options,
  selected,
  setSelected,
  gridSize,
}: Props) {
  const classes = usePropertyDetailsSelectorStyles();
  return (
    <Grid container className={classes.selectorRoot} xs={12} md={5}>
      {options.map((option) => (
        <SelectorOption
          option={option}
          isSelected={option.value === selected}
          onClick={() => setSelected(option.value)}
          gridSize={gridSize}
          key={option.value}
        />
      ))}
    </Grid>
  );
}
