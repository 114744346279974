import { useHistory } from "react-router-dom";

import { LandlordPropertyProvider } from "context/landlordPropertyContext/landlordPropertyContext";
import SimpleLayout from "apps/landlord/Layout/SimpleLayout";

import Property from "./Property";

const PropertyIndex = () => {
  const history = useHistory();

  return (
    <LandlordPropertyProvider>
      <SimpleLayout onHandleBack={() => history.push("/landlord/summary")}>
        <Property />
      </SimpleLayout>
    </LandlordPropertyProvider>
  );
};

export default PropertyIndex;
