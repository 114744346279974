import {
  State,
  Action,
  SET_ACCESS_KEYS,
  SET_ACCESS_TOKEN_KEY,
  SET_REFRESH_TOKEN_KEY,
  SET_LOADING,
  SET_AGENT_DIDS,
  SET_IS_PHONE_OPEN,
  SET_TOKY_CLIENT,
  SET_TOKY_AUDIO_DEVICES,
  SET_TOKY_SESSION,
  SET_TOKY_SESSION_STATUS,
  SET_PHONE_DISPLAY_STATUS,
  SET_DIALED_PHONE_NUMBER,
  PhoneDisplayStatus,
  TokySessionStatus,
  SET_ON_CLOSE_CALLBACK_FN,
  SET_DID_CALL_CONNECTED,
  SET_TOKY_CALL_ID,
  SET_CALLBACKS,
  SET_READY_TO_CALL,
} from "./tokyTypes";
import { Country } from "models/PhoneInput";
import { getCountryPhone } from "utils/verifyPhoneNumber";

const defaultCountry: Country = getCountryPhone("cl");

export const initialState: State = {
  readyToCall: false,
  accessToken: null,
  refreshToken: null,
  loading: false,
  agentDids: [],
  isPhoneOpen: false,
  phoneDisplayStatus: PhoneDisplayStatus.CLOSED,
  TokyClient: null,
  audioDevices: {
    input: null,
    output: null,
    currentInput: null,
    currentOutput: null,
  },
  tokySession: null,
  dialiedPhoneNumber: { country: defaultCountry, phone: "" },
  tokySessionStatus: TokySessionStatus.OFF,
  onCloseCallbackFn: null,
  didCallConnected: false,
  tokyCallId: null,
  callbacks: {},
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_ACCESS_KEYS: {
      return {
        ...state,
        accessToken: action.tokens.accessToken,
        refreshToken: action.tokens.refreshToken,
        loading: false,
      };
    }
    case SET_ACCESS_TOKEN_KEY: {
      return { ...state, accessToken: action.accessToken };
    }
    case SET_REFRESH_TOKEN_KEY: {
      return { ...state, refreshToken: action.refreshToken };
    }
    case SET_LOADING: {
      return { ...state, loading: action.loading };
    }
    case SET_READY_TO_CALL: {
      return { ...state, readyToCall: action.readyToCall };
    }
    case SET_IS_PHONE_OPEN: {
      return { ...state, isPhoneOpen: action.isPhoneOpen };
    }
    case SET_PHONE_DISPLAY_STATUS: {
      return { ...state, phoneDisplayStatus: action.phoneDisplayStatus };
    }
    case SET_AGENT_DIDS: {
      return { ...state, agentDids: action.agentDids, loading: false };
    }
    case SET_TOKY_CLIENT: {
      return { ...state, TokyClient: action.TokyClient };
    }
    case SET_TOKY_AUDIO_DEVICES: {
      return { ...state, audioDevices: action.audioDevices };
    }
    case SET_TOKY_SESSION: {
      return { ...state, tokySession: action.tokySession };
    }
    case SET_TOKY_SESSION_STATUS: {
      return { ...state, tokySessionStatus: action.tokySessionStatus };
    }
    case SET_DIALED_PHONE_NUMBER: {
      return { ...state, dialiedPhoneNumber: action.dialiedPhoneNumber };
    }
    case SET_ON_CLOSE_CALLBACK_FN: {
      return { ...state, onCloseCallbackFn: action.onCloseCallbackFn };
    }
    case SET_CALLBACKS: {
      return { ...state, callbacks: action.callbacks };
    }
    case SET_DID_CALL_CONNECTED: {
      return { ...state, didCallConnected: action.didCallConnected };
    }
    case SET_TOKY_CALL_ID: {
      return { ...state, tokyCallId: action.tokyCallId };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
