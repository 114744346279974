import { makeStyles, Theme } from "@material-ui/core";

const useCalendarViewStyles = makeStyles((theme: Theme) => ({
  calendarViewRoot: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default useCalendarViewStyles;
