import {
  Action,
  SET_ACTIVE_STEP,
  SET_STEPS,
  SET_USER_INFO,
  SET_APPLICANT,
  SET_PAGE,
  SET_COUNTRY,
  UserApplicant,
  EvaluatorPages,
} from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { Locale } from "models/Countries";

export const setSteps = (steps: number): Action => ({
  type: SET_STEPS,
  steps,
});

export const setActiveStep = (activeStep: number): Action => ({
  type: SET_ACTIVE_STEP,
  activeStep,
});

export const setUserInformation = (user: Partial<UserApplicant>): Action => ({
  type: SET_USER_INFO,
  userInfo: user,
});

export const setApplicant = (user: Partial<UserApplicant>): Action => ({
  type: SET_APPLICANT,
  applicant: user,
});

export const setPage = (page: EvaluatorPages): Action => ({
  type: SET_PAGE,
  page,
});

export const setCountry = (country: Locale): Action => ({
  type: SET_COUNTRY,
  country,
});
