import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { IoCreateOutline } from "@houm-com/ui-icons";

import { Box, Typography } from "@material-ui/core";

import { IUserBankAccount } from "apps/tenant/models/BankAccount";
import { getAccountType } from "apps/tenant/utils/utils";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { Locale } from "models/Countries";

import useBankAccountCardStyles from "./BankAccountCardStyles";

interface Props {
  accountInfo: IUserBankAccount;
  country: Locale;
}

const BankACcountCard = ({ accountInfo, country }: Props) => {
  const classes = useBankAccountCardStyles();
  const { t } = useTranslation("tenant.rentPayment");
  const history = useHistory();
  const {
    fullName,
    bankName,
    document,
    accountNumber,
    accountType,
    email,
    clabe,
  } = accountInfo;

  return (
    <>
      <Box className={classes.bankAccountCardRoot}>
        <Typography className={classes.infoLabel}>
          Nombre: <span className={classes.infoContent}>{fullName}</span>
        </Typography>

        <Typography className={classes.infoLabel}>
          Banco: <span className={classes.infoContent}>{bankName}</span>
        </Typography>

        <Typography className={classes.infoLabel}>
          {t("document")}:{" "}
          <span className={classes.infoContent}>{document}</span>
        </Typography>

        <Typography className={classes.infoLabel}>
          Número de cuenta:{" "}
          <span className={classes.infoContent}>{accountNumber}</span>
        </Typography>
        {country !== "mx" && (
          <Typography className={classes.infoLabel}>
            Tipo de cuenta:{" "}
            <span className={classes.infoContent}>
              {getAccountType({ accountType, country })}
            </span>
          </Typography>
        )}

        <Typography className={classes.infoLabel}>
          Email: <span className={classes.infoContent}>{email}</span>
        </Typography>
        {country === "mx" && (
          <Typography className={classes.infoLabel}>
            CLABE: <span className={classes.infoContent}>{clabe}</span>
          </Typography>
        )}
      </Box>

      <DesignSystemButton
        label="Editar"
        size="small"
        variant="primary"
        buttonClassName={classes.editAccountButton}
        onClick={() =>
          history.push(`tenant/bank-account-form/${accountInfo.identifier}`)
        }
        Icon={<IoCreateOutline size={20} />}
        iconPosition="end"
        iconClassName={classes.editIcon}
      />
    </>
  );
};

export default BankACcountCard;
