import { makeStyles, Theme } from "@material-ui/core";

const useSentLinkStyles = makeStyles((theme: Theme) => ({
  sentLinkRoot: {
    marginBottom: theme.spacing(3),
    borderRadius: 8,
    padding: theme.spacing(1),
    backgroundColor: "#EAF1FB",
    fontSize: theme.typography.pxToRem(14),
  },
  titleContainer: {
    marginBottom: theme.spacing(1),
    display: "flex",
  },
  title: {
    marginLeft: theme.spacing(1),
    fontWeight: 700,
  },
  link: {
    fontWeight: 700,
    color: "#2C73DB",
    textDecoration: "underline",
  },
}));

export default useSentLinkStyles;
