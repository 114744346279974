import { makeStyles, Theme } from "@material-ui/core";

const usePhoneVerificationStyles = makeStyles((theme: Theme) => ({
  phoneVerificationRoot: {
    padding: theme.spacing(0, 2),
    paddingTop: theme.spacing(21),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(20),
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    textAlign: "center",
  },
  codeInputSection: {
    margin: theme.spacing(4, 0, 5),
    maxWidth: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  explanationText: {
    color: theme.palette.grey["A400"],
    fontSize: theme.typography.pxToRem(12),
    textAlign: "center",
    "& strong": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

export default usePhoneVerificationStyles;
