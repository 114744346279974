import { ReactElement } from "react";

import { Box } from "@material-ui/core";

import HoumLogo from "assets/images/logo.png";

import useDefaultPhotoStyles from "./DefaultPhotoStyles";

interface Props {
  children: ReactElement;
}

const DefaultPhoto = ({ children }: Props) => {
  const classes = useDefaultPhotoStyles();
  return (
    <Box className={classes.noPhotoContainer}>
      <img src={HoumLogo} alt="houm-logo" className={classes.houmLogo} />
      {children}
    </Box>
  );
};

export default DefaultPhoto;
