import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

import { alert } from "@houm-com/ui/Alert";

import propertyContractServiceV2 from "apps/landlord/services/propertyContractServiceV2";
import { useGetToken } from "hooks";

const useEndHoumContract = ({
  contractId,
  onCloseDrawer,
}: {
  contractId: number;
  onCloseDrawer?: () => void;
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["errors.end-houm-contract"]);
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    async () => {
      const authToken = await getToken();
      const response = await propertyContractServiceV2.endContractHoum(
        contractId,
        authToken
      );
      return response?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("end_houm_contract_status_request");
        onCloseDrawer?.();
        alert.success({
          message: "Solicitud enviada con éxito",
          disclaimer: "Se ha creado una solicitud de fin de contrato con Houm",
        });
      },
      onError: (e: { message: string; status: number }) => {
        alert.error({
          message: "Ups... Ha ocurrido un error",
          disclaimer: e?.message
            ? t(e.message)
            : "No se pudo crear la solicitud de fin de contrato con Houm",
          onClose: () => onCloseDrawer?.(),
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useEndHoumContract;
