import clx from "classnames";

import { Button } from "@material-ui/core";

import { useUserNavbarLinkStyles } from "./useNavbarLinkStyles";

interface NavbarLinkProps {
  className?: string;
  label: string;
  link: string;
  newTab?: boolean;
  isOnPaper?: boolean;
  ButtonProps?: any; // TODO Use ButtonProps type. Tried to do it but it conflicts when destructuring on Button component props.
  onClick?: Function;
}

const NavbarLink = ({
  className,
  label,
  link,
  newTab,
  isOnPaper = false,
  ButtonProps,
  onClick,
}: NavbarLinkProps) => {
  const classes = useUserNavbarLinkStyles();

  return (
    <div className={clx(className, classes["navbar-item"])}>
      <Button
        {...ButtonProps}
        className={clx(ButtonProps?.className, {
          [classes["navbar-link-button"]]: !isOnPaper,
          [classes["navbar-link-button-paper"]]: isOnPaper,
        })}
        component={newTab && "a"}
        href={link}
        target={newTab ? "_blank" : false}
        onClick={onClick}
      >
        {label}
      </Button>
    </div>
  );
};

export default NavbarLink;
