import { useHistory } from "react-router-dom";
import currency from "@houm-com/formats/currency";

import { IoArrowForwardOutline as ArrowRight } from "@houm-com/ui-icons";
import { Box, Typography } from "@material-ui/core";

import useUser from "apps/tenant/hooks/useUser";
import { CurrencyCodeType } from "apps/tenant/models/Currency";
import { MovementStatus } from "apps/tenant/models/Movement";
import useCurrentMovement from "apps/tenant/hooks/useCurrentMovement";
import Spinner from "Components/Shared/partials/Spinner";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { Locale } from "models/Countries";

import usePaymentSectionStyles from "./PaymentSectionStyles";
import { handleRentPayment } from "../../utils/utils";

interface Props {
  country: Locale;
  currencyCode: CurrencyCodeType;
  status: MovementStatus;
  totalAmount: number;
}

const PaymentSection = ({
  country,
  currencyCode,
  status,
  totalAmount,
}: Props) => {
  const classes = usePaymentSectionStyles();
  const { data: movement, isLoading: movementsLoading } = useCurrentMovement();
  const { user, isLoading } = useUser();
  const history = useHistory();

  if (movementsLoading) return <Spinner size={18} />;

  if (status === "paid")
    return (
      <Box>
        <Typography
          className={classes.voucherLink}
          onClick={() =>
            history.push(
              `tenant/voucher-details/${movement.id}?origin=dashboard`
            )
          }
        >
          Ver detalle de pago
          <ArrowRight className={classes.arrow} />
        </Typography>
      </Box>
    );

  return (
    <DesignSystemButton
      label={`Pagar ${currency(totalAmount, currencyCode)}`}
      size="small"
      variant="primary"
      fullWidth={false}
      buttonClassName={classes.payButton}
      isLoading={isLoading}
      onClick={() => handleRentPayment(country, user.email, user.rut)}
    />
  );
};

export default PaymentSection;
