import "@houm-com/ui/main.css";

import { LicenseInfo } from "@material-ui/x-grid";
import { ThemeProvider } from "@material-ui/core";

import "@fullcalendar/daygrid/main.css";
import theme from "styles/globals";
import "./theme.css";
import "./main.css";

import { XGRID_LICENSE } from "env";

LicenseInfo.setLicenseKey(XGRID_LICENSE);

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
