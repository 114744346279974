import { makeStyles, Theme } from "@material-ui/core";

const useControlledAutocompleteStyles = makeStyles((theme: Theme) => ({
  tag: {
    border: `1px solid ${theme.palette.secondary.light}`,
    backgroundColor: "white",
  },
}));

export default useControlledAutocompleteStyles;
