import { FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import FieldSelect from "@houm-com/ui/FieldSelect";
import FieldInput from "@houm-com/ui/FieldInput";
import Button from "@houm-com/ui/Button";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import useCompanies from "apps/landlord/hooks/propertyBills/useCompanies";
import { CompanyType } from "apps/landlord/services/propertiesDuesService/type";
import useChangeBasicServices from "apps/landlord/hooks/propertyBills/useChangeBasicServices";
import CommonExpensesForm from "apps/landlord/pages/PropertyDetails/components/PropertyDetailsContent/components/CommonExpensesForm";
import { snakeToCamel } from "apps/landlord/utils/basicServices";

import { validationSchemaBill } from "../../utils/validationSchema";
import { companyOptionsKeys } from "../../utils/utils";
import classes from "./BillFormStyles";

type BillFormProps = {
  afterSubmit: () => void;
  companyType: CompanyType;
};

type FormProps = {
  companyName: number;
  externalId: number;
};

const BillForm = ({ afterSubmit, companyType }: BillFormProps) => {
  const { isLoading, [companyOptionsKeys[companyType]]: companyOptions } =
    useCompanies();
  const { submitting: basicServicesSubmitting, mutate: changeBasicServices } =
    useChangeBasicServices();
  const methods = useForm<FormProps>({
    resolver: yupResolver(validationSchemaBill),
  });

  const onSubmit = (data: FormProps) => {
    const company = snakeToCamel(companyType);
    if (companyType !== "common_expenses") {
      changeBasicServices({
        [`${company}ClientNumber`]: data.externalId,
        [`${company}CompanyName`]: data.companyName,
      });
    }
    afterSubmit();
  };

  if (companyType === "common_expenses") {
    return (
      <CommonExpensesForm
        onAfterSubmit={afterSubmit}
        className={classes.commonExpensesFormContainer}
      />
    );
  }

  return (
    <Loading loading={isLoading}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className={classes.formContainer}
        >
          <FieldSelect
            id="companyName"
            name="companyName"
            label="Compañia"
            placeholder="Seleccione una compañia"
            options={companyOptions}
            className={classes.formInput}
            errorMessage={methods.formState.errors.companyName?.message}
            {...methods.register("companyName")}
          />
          <FieldInput
            id="externalId"
            name="externalId"
            label="Número de cliente"
            placeholder="000000000"
            type="number"
            className={classes.formInput}
            errorMessage={methods.formState.errors.externalId?.message}
            {...methods.register("externalId")}
          />
          <Button
            type="submit"
            variant="primary"
            size="lg"
            className={classes.formButton}
            isLoading={basicServicesSubmitting}
          >
            Guardar
          </Button>
        </form>
      </FormProvider>
    </Loading>
  );
};

export default BillForm;
