import moment from "moment-timezone";

import { CountryUpperCase, Timezones } from "models/Countries";

interface Availability {
  [x: string]: string[];
}

const getToday = (timezone: Timezones | null): moment.Moment => {
  if (timezone) {
    return moment.utc().tz(timezone);
  }
  return moment();
};

export const notTodayTZ = (item: string[], timezone?: Timezones): boolean => {
  const today = getToday(timezone);
  if (item.length) {
    return !moment(item[item.length - 1]).isSame(today, "day");
  }
  return false;
};

export const notToday = (item) => {
  const today = moment();
  if (item.length) {
    return !moment(item[item.length - 1]).isSame(today, "date");
  }
  return false;
};

export const notTomorrowIfAlreadyPastMidday = (item) => {
  const today = moment();
  if (today.hour() >= 12 && item.length) {
    return !moment(item[item.length - 1]).isSame(today.add(1, "days"), "date");
  }
  return true;
};

export const notTomorrowIfAlreadyPastSixPM = (item) => {
  const today = moment();
  if (today.hour() >= 18 && item.length) {
    return !moment(item[item.length - 1]).isSame(today.add(1, "days"), "date");
  }
  return true;
};

export const notTomorrowIfAlreadyPastMidDayTZ = (
  item: string[],
  timezone: Timezones
): boolean => {
  const today = getToday(timezone);
  if (today.hour() >= 12 && item.length) {
    return !moment(item[item.length - 1]).isSame(today.add(1, "days"), "date");
  }
  return true;
};

export const filterLandlordHomecheckerHours = (
  item: string[],
  timezone: Timezones
): boolean =>
  notTomorrowIfAlreadyPastMidDayTZ(item, timezone) &&
  notTodayTZ(item, timezone);

export const parseScheduleAvailability = (
  availability: Availability,
  timezone: string,
  handleFilter?: (item: string[], timezone: Timezones) => boolean
) => {
  let parsedAvailability: Record<string, string[]> = {};
  Object.values(availability || {})
    .reduce((prev, current) => [...prev, ...current], [])
    .forEach((day) => {
      const timezoneDay = moment.utc(day).tz(timezone);

      const parsedDay = timezoneDay.format("yyyy-MM-DD");
      if (parsedDay in parsedAvailability) {
        parsedAvailability[parsedDay].push(timezoneDay.format());
      } else {
        parsedAvailability[parsedDay] = [timezoneDay.format()];
      }
    });
  parsedAvailability = Object.fromEntries(
    Object.entries(parsedAvailability || {}).filter(
      handleFilter
        ? ([_, value]) => handleFilter(value, timezone as Timezones)
        : ([_, value]) =>
            notTomorrowIfAlreadyPastSixPM(value) && notToday(value)
    )
  );

  return parsedAvailability;
};

export const getTimezone = (country: CountryUpperCase): Timezones => {
  const countryToZone = {
    Chile: "America/Santiago",
    México: "America/Mexico_City",
    Mexico: "America/Mexico_City",
    Colombia: "America/Bogota",
  };
  return (countryToZone[country] as Timezones) || "America/Santiago";
};

export const getTimezoneWithCommune = (
  country: CountryUpperCase,
  commune: string
): Timezones => {
  if (commune === "Tijuana") return "America/Tijuana";
  return getTimezone(country);
};
