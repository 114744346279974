import clsx from "clsx";

import { Typography } from "@material-ui/core";

import { MovementStatus } from "apps/tenant/models/Movement";

import { getStatusText } from "./utils";
import usePaymentStatusStyles from "./PaymentStatusStyles";

interface Props {
  status: MovementStatus;
  floating?: boolean;
  className?: string;
}

const PaymentStatus = ({ status, floating = false, className }: Props) => {
  const classes = usePaymentStatusStyles({ floating });

  return (
    <Typography
      className={clsx(
        classes.paymentStatusRoot,
        {
          [classes.paidTag]: status === "paid",
          [classes.pendingTag]: status === "pending",
          [classes.overdueTag]: status === "overdue",
          [classes.reviewTag]: status === "review",
        },
        className
      )}
    >
      {getStatusText(status)}
    </Typography>
  );
};

export default PaymentStatus;
