import Swal from "sweetalert2";
import { AxiosError } from "axios";
import { GetNegotiation } from "services/negotiationService/models";
import { LoiAddressData, LoiPersonalData } from "./LoiFormContext.types";
import {
  LoiIdentificationType,
  OwnerPayload,
} from "services/letterOfIntentService/letterOfIntentService.types";

export const parseOwnerDataToService = (
  personalData: LoiPersonalData,
  addressData: LoiAddressData
): OwnerPayload => ({
  name: personalData.name,
  last_name: personalData.lastName,
  second_last_name: personalData.secondLastName,
  occupation: personalData.occupation,
  identifications: [
    {
      number: personalData.identificationNumber,
      type_id: String(personalData.identificationType),
    },
  ],
  email: personalData.email,
  phone: personalData.phone,
  rfc: personalData.rfc,
  civil_status: personalData.civilStatus,
  city: addressData.city,
  commune: addressData.commune,
  address: addressData.address,
  outer_number: addressData.outerNumber,
  inner_number: addressData.innerNumber,
});

export const parseItentificationTypes = (
  documentType: LoiIdentificationType
) => ({
  value: documentType?.id,
  label: documentType?.name,
  validationRegex: documentType?.validation_regex,
});

export const sortNegotiationsByDate = (
  negA: GetNegotiation,
  negB: GetNegotiation
) => new Date(negA.created_at).getTime() - new Date(negB.created_at).getTime();

const FAILED_GET_NEGOTIATIONS =
  "No se ha podido obtener la lista de negociaciones para esta propiedad";
const FAILED_GET_PROPERTY_DETAILS =
  "No se ha podido obtener los detalles de la propiedad";
const FAILED_GET_LOI_OWNERS =
  "No se ha podido obtener la lista de propietarios en esta negociación";
const FAILED_SUBMIT_OWNER =
  "No se ha podido guardar la información de propietario, por favor intente nuevamente y revise los datos ingresados";
const FAILED_UPLOAD_OWNER_DOCS =
  "No se ha podido subir los documentos del propietario, por favor intente nuevamente";
const FAILED_SUBMIT_LOI_TO_REVIEW =
  "Hubo un error al enviar la carta de intención. Por favor, inténtalo de nuevo.";

const throwSwalDialog = (errDescription: string) => {
  Swal.fire({
    title: "¡Ha ocurrido algo!",
    showConfirmButton: true,
    text: errDescription,
    type: "error",
    confirmButtonText: "Aceptar",
  });
};

export const handleGetNegotiationsError = () =>
  throwSwalDialog(FAILED_GET_NEGOTIATIONS);

export const handleGetPropertyDetailsError = () =>
  throwSwalDialog(FAILED_GET_PROPERTY_DETAILS);

export const handleGetLoiOwnersError = () =>
  throwSwalDialog(FAILED_GET_LOI_OWNERS);

export const handleSubmitOwnerError = (err: AxiosError) => {
  const resErrMessage = err?.response?.data?.description || FAILED_SUBMIT_OWNER;
  return throwSwalDialog(resErrMessage);
};

export const handleUploadOwnerDocsError = () =>
  throwSwalDialog(FAILED_UPLOAD_OWNER_DOCS);

export const handleSubmitLoiToReviewError = () =>
  throwSwalDialog(FAILED_SUBMIT_LOI_TO_REVIEW);
