import { es as localeEs } from "date-fns/locale";
import { format } from "date-fns";

import { Grid, Typography } from "@material-ui/core";

import CallToAction from "Components/Admin/UIComponents/atoms/CallToAction";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { CIVIL_STATUSES } from "context/loiFormContext/constants";
import { useLoiForm } from "../context/LoiFormContext";
import useStepsStyles from "./StepsStyles";

const Resume = () => {
  const classes = useStepsStyles();
  const {
    state: { country, personalData, addressData },
    handlers: { updateOrCreateOwner },
  } = useLoiForm();

  const today = format(new Date(), "d 'de' MMMM yyyy", { locale: localeEs });
  const {
    name,
    lastName,
    secondLastName,
    civilStatus,
    occupation,
    identificationNumber,
  } = personalData;
  const { commune, address, outerNumber, innerNumber, city } = addressData;

  const ownerFullName = `${personalData.name} ${personalData.lastName || ""} ${
    personalData.secondLastName || ""
  }`;

  const serializedCivilStatus = CIVIL_STATUSES.find(
    (status) => status.value === civilStatus
  )?.label;

  return (
    <Grid container className={classes.rootGrid}>
      <Grid item>
        <Typography className={classes.ownerNameLead}>
          propietario: {ownerFullName}
        </Typography>
        <Typography className={classes.title}>
          ¡Último paso! Revisa tu información
        </Typography>
        <Typography className={classes.subtitle}>
          Es importante que verifiques que todos estén correctos. Esta es una
          pre-visualización de como se verán reflejados tus datos en la promesa
          de compra.
        </Typography>
      </Grid>
      {country === "Chile" && (
        <Grid item className={classes.highlightBox}>
          Siendo {today}, Comparecen: por una parte don{" "}
          <b>
            {name} {lastName} {secondLastName}
          </b>
          , chileno, {serializedCivilStatus}, {occupation}, cédula nacional de
          identidad nacional número <b>{identificationNumber}</b>, domiciliado
          en{" "}
          <b>
            {address} número {outerNumber} {innerNumber}, comuna de {commune},{" "}
            {city}
          </b>
          , en adelante también como "el Promitente Vendedor
        </Grid>
      )}
      <CallToAction>
        <DesignSystemButton
          label="Enviar datos"
          onClick={updateOrCreateOwner}
          size="large"
          variant="primary"
          fullWidth
        />
      </CallToAction>
    </Grid>
  );
};

export default Resume;
