import { cn } from "@houm-com/ui/utils";

import { classes } from "./SubtitleStyles";

interface Props {
  className?: string;
}

const Subtitle: React.FC<Props> = ({ children, className }) => (
  <p className={cn(classes.subtitleRoot, className)}>{children}</p>
);

export default Subtitle;
