import { makeStyles, Theme } from "@material-ui/core";

const useSetUserCountryStyles = makeStyles((theme: Theme) => ({
  modalRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    maxWidth: "532px",
    padding: theme.spacing(4),
    borderRadius: "1rem",
  },
  countriesSelector: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(3),
  },
  countryFlagButton: {
    height: "32px",
    width: "32px",
    borderRadius: "100%",
    border: "1px solid #E5E5E5",
  },
  countryOption: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "88px",
    width: "88px",
    border: "1px solid #E5E5E5",
    justifyContent: "center",
    borderRadius: theme.spacing(1),
    gap: theme.spacing(1),
    "-webkit-appearance": "none",
    "-moz-appearance": "none",
    textTransform: "none",
    background: "transparent",
    transition: "all .2s ease",
    cursor: "pointer",
    boxShadow: "none",
    [theme.breakpoints.up("xs")]: {
      boxShadow: "3px 4px 25px rgba(38, 50, 56, 0.15)",
    },
    "&:hover": {
      border: "1px solid #E5E5E5",
      backgroundColor: "#f7f7f7",
    },
    '&[data-selected="true"]': {
      border: "1px solid #A0B1B9",
      backgroundColor: "#f1f1f1",
      boxShadow: "none",
    },
  },
  countryLabel: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(24),
    marginBottom: "0",
  },
  dialogTitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    color: theme.palette.grey[900],
    textAlign: "center",
  },
  dialogDisclaimer: {
    maxWidth: "440px",
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(24),
    textAlign: "center",
  },
  nextButton: {
    margin: theme.spacing(2, 0),
  },
  errorMessage: {
    color: "#F44336",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(16),
    textAlign: "center",
  },
}));

export default useSetUserCountryStyles;
