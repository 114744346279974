import { useState } from "react";
import { Grid, Typography } from "@material-ui/core";

import useNegotiationDetailsStyles from "./NegotiationDetailsStyles";
import Photos from "../../Property/Partials/Photos";
import { parsePrice, renderAddress } from "utils";
import { addressProperty } from "context/negotiationsContext/utils";
import NegotiationTabs from "./NegotiationsTabs";
import OfferCard from "./OfferCard";
import { useNegotiations } from "context/negotiationsContext/negotiationsContext";

const NegotiationDetails = () => {
  const { state } = useNegotiations();
  const { property, currentNegotiation } = state;
  const classes = useNegotiationDetailsStyles();
  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (_, newValue: number) => {
    setValueTab(newValue);
  };

  const tabsNames = ["Pendientes", "Respondidas"];
  const statusOffer = [["Abierta"], ["Aceptada", "Rechazada"]];
  const unparseProperty = addressProperty(property);
  const propertyPrice = () =>
    parsePrice(property.salesPrice, property.salesCurrency);

  return (
    <Grid container>
      <Typography className={classes.titleGeneral}>
        Detalle de la negociación
      </Typography>
      <Grid container item justifyContent="center">
        <Grid item xs={12} sm={3} className={classes.photos}>
          <Photos photos={property.photos} />
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={9}
          className={classes.headers}
          alignContent="flex-start"
        >
          <Typography className={classes.title}>Tu propiedad</Typography>
          <Grid item xs={12} className={classes.epigraph}>
            Dirección:
            <span className={classes.value}>
              {renderAddress(unparseProperty)}
            </span>
          </Grid>
          <Grid item xs={12} className={classes.epigraph}>
            Región:
            <span className={classes.value}>{property.region}</span>
          </Grid>
          <Grid item xs={12} className={classes.epigraph}>
            Precio Publicado:
            <span className={classes.value}>{propertyPrice()}</span>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <NegotiationTabs
            tabsNames={tabsNames}
            handleChangeTab={handleChangeTab}
            value={valueTab}
          />
          {currentNegotiation.offers
            .filter((offer) => statusOffer[valueTab].includes(offer.status))
            .map((offer) => (
              <OfferCard key={offer.id} offer={offer} />
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NegotiationDetails;
