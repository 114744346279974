import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

const useNegotiationsListStyles = makeStyles((theme: Theme) => ({
  titleGeneral: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 700,
    color: theme.palette.grey[900],
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(33),
    },
  },
}));

export default useNegotiationsListStyles;
