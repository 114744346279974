import { useState } from "react";
import { useMutation } from "react-query";

import { useHoumSnackBar } from "libs/houm-snackbar";
import { useGetToken } from "hooks";

import useContract from "apps/tenant/hooks/useContract";
import servicesRequestsService from "apps/tenant/services/servicesRequestsService";

import { IServicesRequest } from "./types";
import {
  uploadServicesRequestPhotos,
  encodeServicesRequest,
  getPhotos,
} from "./utils";

interface Props {
  onSuccessHandler?: () => void;
  onErrorHandler?: () => void;
}

const useServicesRequestAction = ({
  onSuccessHandler,
  onErrorHandler,
}: Props) => {
  const [servicesRequest, setServicesRequest] = useState<IServicesRequest>({});
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const { data: contractData, isLoading: isContractLoading } = useContract();
  const { getToken } = useGetToken();

  const servicesRequestMutation = useMutation(
    async () => {
      const authToken = await getToken();
      const photos = getPhotos(servicesRequest);
      const fileNames = await uploadServicesRequestPhotos(authToken, photos);
      if (!fileNames) throw new Error("No pudimos subir las fotos");
      return servicesRequestsService.createServicesRequest(
        authToken,
        contractData?.propertyId,
        encodeServicesRequest(servicesRequest, fileNames)
      );
    },
    {
      onSuccess: () => {
        enqueueHoumSnackBar({
          head: "Hemos creado tu solicitud de regularización de pagos exitosamente",
          body: "",
          type: "success",
        });
        onSuccessHandler();
      },
      onError: () => {
        enqueueHoumSnackBar({
          head: "No pudimos crear tu solicitud de regularización de pagos, por favor intenta de nuevo.",
          body: "",
          type: "error",
        });
        onErrorHandler();
      },
    }
  );

  return {
    servicesRequest,
    setServicesRequest,
    submitData: servicesRequestMutation.mutate,
    isLoading: servicesRequestMutation.isLoading || isContractLoading,
  };
};

export default useServicesRequestAction;
