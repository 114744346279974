interface Props {
  label: string;
  value?: string;
}
const Label = ({ label, value }: Props) => (
  <p className="text-p16">
    {label}
    {value && <span className="text-black-80">: {value}</span>}
  </p>
);

export default Label;
