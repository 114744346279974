import { Box, IconButton, Typography } from "@material-ui/core/";
import { ExpandMore, ExpandLess } from "@material-ui/icons";

import RatingStatus from "apps/landlord/components/RatingStatus";
import TableRowImagePreviewer from "apps/landlord/components/TableRowImagePreviewer";
import { SpaceSummary } from "models/InspectionReport";
import useStyles from "./SpaceMainInfoStyles";

interface Props {
  handleOpen: () => void;
  isOpen: boolean;
  space?: SpaceSummary;
  showExpandIcon: boolean;
}

const SpaceMainInfo = ({
  handleOpen,
  isOpen,
  space,
  showExpandIcon,
}: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.spaceMainInfoRoot} onClick={handleOpen}>
      <Box className={classes.imageContainer}>
        <TableRowImagePreviewer
          rowName={space?.name}
          imagesArr={[space?.photo]}
        />
      </Box>
      <Box className={classes.spaceInfoMain}>
        <Typography className={classes.spaceInfoStateTitle}>
          {space?.name}
        </Typography>
        <Box className={classes.spaceInfoState}>
          <RatingStatus qualification={space?.overallQualification} />
        </Box>
      </Box>
      {showExpandIcon && (
        <Box>
          <IconButton className={classes.iconButton} aria-label="view-photos">
            {isOpen ? (
              <ExpandLess className={classes.icon} />
            ) : (
              <ExpandMore className={classes.icon} />
            )}
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default SpaceMainInfo;
