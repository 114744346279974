import { useQuery, UseQueryResult } from "react-query";
import { alert } from "@houm-com/ui/Alert";

import propertyService from "apps/payments/services/propertyService";
import { parseAddress } from "utils/parseUserSegmentationData";

interface Props {
  propertyId: number;
}

const usePropertyAddress = ({ propertyId }: Props) => {
  const query: UseQueryResult<Partial<{ address: string }>, Error> = useQuery(
    ["property_by_id_not_auth", propertyId],
    async () => {
      const response = await propertyService.getByIDNoAuth(propertyId);
      return {
        address: parseAddress(response.data),
      };
    },
    {
      enabled: !!propertyId,
      onError: () =>
        alert.error({
          message: "Error al obtener detalles de la propiedad",
        }),
    }
  );
  return {
    ...query,
  };
};

export default usePropertyAddress;
