/* eslint-disable camelcase */

// eslint-disable-next-line no-unused-vars
export type Dispatch = (action: Action) => void;

export const SET_CURRENT_TAB = "SET_CURRENT_TAB";

export interface State {
  currentTabValue: string;
}

interface setCurrentTabValue {
  type: typeof SET_CURRENT_TAB;
  currentTabValue: string;
}

export interface ITabOption {
  label: string;
  value: string;
  isDisabled?: boolean;
}

export type Action = setCurrentTabValue;
