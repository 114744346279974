import * as yup from "yup";

export const REQUIRED_TEXT = "Obligatorio";
export const INVALID_FORMAT_TEXT = "Formato incorrecto";

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const rfcRegExp =
  /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{3})$/;

const rfcValidation = yup
  .string()
  .nullable()
  .matches(rfcRegExp, INVALID_FORMAT_TEXT);

const documentValidation = (regex: RegExp) =>
  yup.string().matches(regex, INVALID_FORMAT_TEXT);

export const addressDataValidators = yup.object({
  city: yup.string().required(REQUIRED_TEXT),
  commune: yup.string().required(REQUIRED_TEXT),
  address: yup.string().required(REQUIRED_TEXT),
  outerNumber: yup.string().required(REQUIRED_TEXT),
  innerNumber: yup.string().required(REQUIRED_TEXT),
});

export const personalDataValidators = (documentRegex: RegExp) =>
  yup.object({
    name: yup.string().required(REQUIRED_TEXT),
    lastName: yup.string().required(REQUIRED_TEXT),
    secondLastName: yup.string().required(REQUIRED_TEXT),
    email: yup.string().email(INVALID_FORMAT_TEXT).required(REQUIRED_TEXT),
    phone: yup.string().matches(phoneRegExp, INVALID_FORMAT_TEXT),
    occupation: yup.string(),
    identificationType: yup.string().matches(/[^0]+/, REQUIRED_TEXT),
    identificationNumber:
      documentValidation(documentRegex).required(REQUIRED_TEXT),
    nationality: yup.string(),
    civilStatus: yup.string(),
    rfc: rfcValidation,
  });

export const bankDataValidators = yup.object({
  clabe: yup.string(),
});

export const landlordDocsValidationSchema = (country) =>
  yup.object({
    identificationDoc1:
      country !== "Mexico"
        ? yup.mixed().test("required", REQUIRED_TEXT, Boolean)
        : null,
    identificationDoc2:
      country !== "Mexico"
        ? yup.mixed().test("required", REQUIRED_TEXT, Boolean)
        : null,
    rfc:
      country === "Mexico"
        ? yup.mixed().test("required", REQUIRED_TEXT, Boolean)
        : null,
  });
