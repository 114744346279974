import * as yup from "yup";

export const requiredText = "Obligatorio";

export const reasonValidationSchema = yup.object({
  reason: yup.string().required(requiredText),
});

export const lastAmountValidationSchema = yup.object({
  lastAmount: yup
    .number()
    .min(0, "Los valores deben ser mayores a 0")
    .typeError("Sólo se permiten números")
    .required(requiredText),
  currency: yup.string().nullable().required(requiredText),
});
