import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import Spinner from "Components/Shared/partials/Spinner";
import { useUser } from "context/userContext";
import { useHoumSnackBar } from "libs/houm-snackbar";

import useEmailVerification from "./hooks/useEmailVerification";

const EmailVerification = () => {
  const { code } = useParams<{ code: string }>();
  const history = useHistory();
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const { user } = useUser();
  const { mutate, error, success } = useEmailVerification();

  useEffect(() => {
    if (code && user) {
      mutate({ code, previousEmail: user?.email });
    }
  }, [code, user, mutate]);

  if (error) {
    enqueueHoumSnackBar({
      head: "",
      body: "Ha ocurrido un error, por favor intenta de nuevo",
      type: "error",
    });
  }
  if (success) {
    enqueueHoumSnackBar({
      head: "",
      body: "Tu correo electrónico ha sido verificado",
      type: "success",
    });
    history.push("/logout");
  }
  return <Spinner />;
};

export default EmailVerification;
