import { AxiosResponse } from "axios";
import { Locale } from "models/Countries";

export enum CloseContractType {
  EARLY_FINISH = "EARLY_FINISH",
  NO_RENOVATION = "NO_RENOVATION",
}

export interface ContractResponse {
  active_subscription_id: number;
  country: Locale;
  id: number;
  init_date: string;
  end_date: string;
  end_notice_period: number;
  property_id: number;
}

export interface SignedContracts {
  contract?: string;
  mandate?: string;
}

export interface CloseContractStatusResponse {
  begin_at: string;
  comment: string;
  contract_id: string;
  estimated_date: string;
  requested_by_user: string;
  status: string;
  type: CloseContractType;
  need_photos: boolean;
}

export interface CloseContractResponse {
  estimatedDate: Date;
  hasBegin: boolean;
}

export interface CloseContractArgs {
  type: CloseContractType;
  contractId: number;
  estimatedDate: string;
  comment: string;
}

export interface CloseContractRequest {
  date?: Date;
  motive?: number;
}

export interface GetPropertyContractArgs {
  propertyId: number;
}

export interface GetCloseContractProcessStatusArgs {
  contractId: number;
}

export interface ContractByIdArgs {
  contractId: number;
}

export type GetContracts = (
  args: ContractByIdArgs
) => Promise<AxiosResponse<ContractResponse[]>>;
export type GetActiveContract = (
  args: GetPropertyContractArgs
) => Promise<AxiosResponse<ContractResponse>>;
export type GetPropertyContract = (
  args: GetPropertyContractArgs
) => Promise<AxiosResponse<ContractResponse>>;
export type ClosePropertyContract = (
  args: CloseContractArgs
) => Promise<AxiosResponse<CloseContractResponse>>;
export type GetCloseContractProcessStatus = (
  args: GetCloseContractProcessStatusArgs
) => Promise<AxiosResponse<CloseContractStatusResponse>>;
export type GetSignedContractsDocuments = (
  args: ContractByIdArgs
) => Promise<AxiosResponse<SignedContracts>>;

export interface IPropertyContractService {
  getContracts: GetContracts;
  getActiveContract: GetActiveContract;
  getPropertyContract: GetPropertyContract;
  closePropertyContract: ClosePropertyContract;
  getCloseContractProcessStatus: GetCloseContractProcessStatus;
  getSignedContractsDocuments: GetSignedContractsDocuments;
}

export type PropertyContractService = ({
  token,
}: {
  token: string;
}) => IPropertyContractService;
