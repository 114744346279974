import { CountryUpperCase } from "models/Countries";

export interface FieldProp {
  label: (country: string) => string;
  countries: CountryUpperCase[];
}

export const personalDataFields = {
  name: (): FieldProp => ({
    label: (country) => "Nombre",
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  lastName: (): FieldProp => ({
    label: (country) => "Apellido paterno",
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  secondLastName: (): FieldProp => ({
    label: (country) => "Apellido materno",
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  email: (): FieldProp => ({
    label: (country) => "Correo electrónico",
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  phone: (): FieldProp => ({
    label: (country) => "Teléfono",
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  occupation: (): FieldProp => ({
    label: (country) => "Profesión",
    countries: ["Chile", "Mexico"],
  }),
  identificationType: (): FieldProp => ({
    label: (country) => "Tipo de identificación",
    countries: ["Colombia"],
  }),
  identificationNumber: (): FieldProp => ({
    label: (country) =>
      ({
        Colombia: "Número de identificación",
        Mexico: "Número de identificación",
        Chile: "RUT",
      }[country]),
    countries: ["Chile", "Colombia", "Mexico"],
  }),
  civilStatus: (): FieldProp => ({
    label: (country) => "Estado civil",
    countries: ["Mexico", "Chile"],
  }),
  rfc: (): FieldProp => ({
    label: (country) => "RFC",
    countries: ["Mexico"],
  }),
};

export const addressDataFields = {
  city: (): FieldProp => ({
    label: (country) => (country === "Chile" ? "Region" : "Ciudad"),
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  commune: (): FieldProp => ({
    label: (country) =>
      ({
        Chile: "Comuna",
        Mexico: "Colonia/Delegación",
      }[country]),
    countries: ["Mexico", "Chile"],
  }),
  address: (): FieldProp => ({
    label: (country) => "Dirección de la calle",
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  outerNumber: (): FieldProp => ({
    label: (country) => "Numero",
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  innerNumber: (): FieldProp => ({
    label: (country) =>
      ({
        Chile: "Depto, oficina o número interior",
        Mexico: "Depto, oficina o número interior",
        Colombia: "Apartamento, oficina o número interior",
      }[country]),
    countries: ["Colombia", "Mexico", "Chile"],
  }),
};

export const landlordDocsFields = {
  identificationDoc1: (): FieldProp => ({
    label: (country) =>
      ({
        Chile: "Carnet de identidad - Parte delantera",
        Colombia: "Cédula de ciudadanía - Parte frontal",
      }[country]),
    countries: ["Colombia", "Chile"],
  }),
  identificationDoc2: (): FieldProp => ({
    label: (country) =>
      ({
        Chile: "Carnet de identidad - Parte trasera",
        Colombia: "Cédula de ciudadanía - Parte trasera",
      }[country]),
    countries: ["Colombia", "Chile"],
  }),
  ine: (): FieldProp => ({
    label: (country) => "INE",
    countries: ["Mexico"],
  }),
  rfc: (): FieldProp => ({
    label: (country) => "RFC",
    countries: ["Mexico"],
  }),
  phyisicalCurrentSituation: (): FieldProp => ({
    label: (country) => "Comprobante de situación física actual",
    countries: ["Mexico"],
  }),
  addressCertificate: (): FieldProp => ({
    label: (country) => "Comprobante de domicilio actualizado",
    countries: ["Mexico"],
  }),
  marriageCertificate: (): FieldProp => ({
    label: (country) => "Acta de matrimonio (opcional)",
    countries: ["Mexico"],
  }),
  passport: (): FieldProp => ({
    label: (country) => "Pasaporte",
    countries: ["Mexico"],
  }),
  birthCertificate: (): FieldProp => ({
    label: (country) => "Acta de nacimiento (opcional)",
    countries: ["Mexico"],
  }),
};
