import { Typography } from "@material-ui/core";

const Title = () => (
  <Typography component="span">
    Te estaremos informando sobre tu evaluación y podrás revisar su estado en{" "}
    <strong>Mi Houm</strong>. Recuerda que{" "}
    <strong>los codeudores agregados</strong> recibirán un correo electrónico
    con la evaluación ingresada.
  </Typography>
);

export default Title;
