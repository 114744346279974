import { UseQueryResult, useQuery } from "react-query";

import { IVisit } from "apps/tenant/models/Visit";
import visitsService from "apps/tenant/services/visitsService/visitsService";
import { useGetToken } from "hooks";
import { parseVisitData } from "./parsers";

interface TError {
  response: {
    status: number;
  };
}

const useGetVisits = (email) => {
  const { getToken } = useGetToken();
  const {
    isLoading,
    data = [],
    error,
    isError,
  }: UseQueryResult<IVisit[], TError> = useQuery<IVisit[], TError>(
    ["appointmentsList", email],
    async () => {
      const authToken = await getToken();
      const response = await visitsService.getVisits(authToken, email);
      return parseVisitData(response?.data.visits);
    },
    {
      enabled: !!email,
      onError: (e: TError) => {
        if (e.response?.status === 404) {
          return [];
        }
        return e;
      },
      retry: false,
    }
  );

  return {
    isLoading,
    data,
    error,
    isError: isError && error.response.status !== 404,
  };
};

export default useGetVisits;
