import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

const useOfferCardStyles = makeStyles((theme: Theme) => ({
  offerCard: {
    width: "100%",
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
    backgroundColor: "white",
    boxShadow: "4px 4px 30px rgba(0, 43, 41, 0.15)",
    borderRadius: 10,
  },
  offerDetails: {
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    paddingBottom: theme.spacing(2),
  },
  epigraph: {
    marginBottom: theme.spacing(1),
    fontSize: "0.75rem",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: "1rem",
      lineHeight: 1.5,
      marginBottom: theme.spacing(2),
    },
  },
  value: {
    marginLeft: theme.spacing(1),
    fontWeight: "bold",
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: "1rem",
    fontWeight: "bold",
    lineHeight: 1.5,
    color: theme.palette.grey[500],
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginBottom: theme.spacing(3),
      fontSize: "1.25rem",
    },
  },
  responseTittle: {
    margin: theme.spacing(2, 0),
    fontSize: "1rem",
    fontWeight: "bold",
    lineHeight: 1.5,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      margin: theme.spacing(3, 0),
      fontSize: "1.25rem",
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      justifyContent: "flex-start",
      gap: theme.spacing(2),
    },
  },
  status: {
    borderRadius: 40,
    fontSize: "1rem",
    fontWeight: "bold",
    background: theme.palette.grey[5],
    padding: theme.spacing(1, 2),
    textAlign: "center",
    width: "fit-content",
  },
  accepted: {
    color: theme.palette.success.main,
    border: "1px solid #00982D",
  },
  rejected: {
    color: theme.palette.primary.main,
  },
  offerStatus: {
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    paddingBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      justifyContent: "flex-start",
    },
  },
}));

export default useOfferCardStyles;
