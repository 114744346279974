import { Box } from "@material-ui/core";

import { IoHelpOutline as PersonalInfoIcon } from "@houm-com/ui-icons";

import useHelpWidgetTriggerStyles from "./HelpWidgetTriggerStyles";

const HelpWidgetTrigger = ({ onClick }) => {
  const classes = useHelpWidgetTriggerStyles();
  return (
    <div className="top-10">
      <Box
        data-testid="help-widget-trigger"
        onClick={onClick}
        className={classes.root}
      >
        <PersonalInfoIcon className="w-[22px] h-[24px]" />
      </Box>
    </div>
  );
};

export default HelpWidgetTrigger;
