import { Locale } from "models/Countries";

export const textsByCountry: {
  [countryCode in Locale]: {
    landing: {
      title: string;
      text: string;
      returnPolicy?: string;
      returnPolicyTextLink?: string;
      otherPolicies?: string;
      cleaningPolicy?: string;
      repairPolicy?: string;
      policiesAndConsiderations?: string;
      returnPolicyLink?: string;
      cleaningPolicyLink?: string;
      repairPolicyLink?: string;
    };
    success?: {
      title: string;
      text: string;
    };
    pending?: {
      text: string;
    };
    error?: {
      text: string;
    };
    rentText: string;
  };
} = {
  mx: {
    landing: {
      title: "Reserva tu inmueble con Houm",
      text: "Nos alegra que hayas encontrado la propiedad de tus sueños con Houm. Al pagar tu reserva estás un paso más cerca de cambiarte",
      policiesAndConsiderations: "Reglas de devolución de reserva - México",
      returnPolicyLink: "https://help.houm.com/mx/articles/311",
      returnPolicyTextLink: "Política de devolución de Reserva",
    },
    success: {
      title: "Has reservado tu próximo hogar",
      text: "Ahora uno de nuestros ejecutivos se pondrá en contacto contigo para brindarte una atención personalizada en los siguientes pasos.",
    },
    pending: {
      text: "Luego uno de nuestros ejecutivos se pondrá en contacto contigo para brindarte una atención personalizada en los siguientes pasos.",
    },
    error: {
      text: "No te desanimes, puedes volver a intentar pagar tu reserva en unos minutos. El hogar de tus sueños está esperando por ti.",
    },
    rentText: "Renta mensual",
  },
  cl: {
    landing: {
      title: "Reserva tu nuevo hogar con Houm",
      text: "Nos alegra que hayas encontrado la propiedad de tus sueños con nosotros. Al pagar tu reserva, estás un paso más cerca de tu nuevo hogar.",
      returnPolicy:
        "¿Cuáles son las implicancias de pagar tu reserva? La respuesta la encuentras en el siguiente link.",
      returnPolicyTextLink: "Política de Devolución de Reserva",
      otherPolicies:
        "Si quieres saber sobre nuestras políticas de limpieza y arreglos, lee los siguientes links.",
      cleaningPolicy: "Política de Limpieza",
      repairPolicy: "Política de Arreglos",
      policiesAndConsiderations: "Algunas consideraciones",
      returnPolicyLink:
        "https://help.houm.com/cl/articles/51/politica-de-devolucion-de-reserva",
      cleaningPolicyLink:
        "https://ayuda.houm.com/hc/es-419/articles/4552757215892-Pol%C3%ADtica-de-Limpieza-Houm-",
      repairPolicyLink:
        "https://ayuda.houm.com/hc/es-419/articles/360047147891--C%C3%B3mo-funciona-el-servicio-de-Houm-A-qui%C3%A9n-le-corresponde-los-arreglos-en-la-propiedad-",
    },
    success: {
      title: "Has reservado tu nuevo hogar",
      text: "Si aún no lo has hecho, debes subir tu documentación al link enviado a tu correo para continuar con el proceso.",
    },
    pending: {
      text: "Una vez confirmado, debes subir tu documentación al link enviado a tu correo para continuar con el proceso.",
    },
    error: {
      text: "No te desanimes, puedes volver a intentarlo en unos minutos. El hogar de tus sueños está esperando por ti.",
    },
    rentText: "Arriendo mensual",
  },
  co: {
    landing: {
      title: "Reserva tu nuevo hogar con Houm",
      text: "Nos alegra que hayas encontrado la propiedad de tus sueños con nosotros. Al pagar tu reserva, estás un paso más cerca de tu nuevo hogar.",
      policiesAndConsiderations: "Reglas de devolución de reserva - Colombia",
    },
    success: {
      title: "Has reservado tu próximo hogar",
      text: "Ahora uno de nuestros ejecutivos se pondrá en contacto contigo para brindarte una atención personalizada en los siguientes pasos.",
    },
    pending: {
      text: "Luego uno de nuestros ejecutivos se pondrá en contacto contigo para brindarte una atención personalizada en los siguientes pasos.",
    },
    error: {
      text: "No te desanimes, puedes volver a intentar pagar tu reserva en unos minutos. El hogar de tus sueños está esperando por ti.",
    },
    rentText: "Arriendo mensual",
  },
};
