import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  IPropertyAmenities,
  IAmenities,
} from "apps/landlord/components/FormAmenities/utils/types";
import { validationSchema } from "apps/landlord/components/FormAmenities/utils/validationSchema";
import useEditAmenities from "apps/landlord/pages/PropertyDetailsV2/hooks/useEditAmenities";
import { parseDefaultValues } from "apps/landlord/components/FormAmenities/utils/parsers";
import { AmenitiesFormProps } from "apps/landlord/pages/PropertyDetailsV2/utils/types";
import FormAmenities from "apps/landlord/components/FormAmenities";

interface Props {
  amenities: IAmenities;
  propertyAmenities: IPropertyAmenities;
  onHandleDrawer: () => void;
}

const Form = ({ amenities, propertyAmenities, onHandleDrawer }: Props) => {
  const { mutate, isLoading } = useEditAmenities(onHandleDrawer);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
    defaultValues: parseDefaultValues(propertyAmenities, amenities),
  });

  const onSubmit: SubmitHandler<AmenitiesFormProps> = (e) => {
    if (isLoading) return;
    mutate(e);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormAmenities isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default Form;
