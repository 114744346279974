import Divider from "@material-ui/core/Divider";

import ControlledDropdown from "Components/Admin/UIComponents/ControlledForm/ControlledAutocomplete";

import { useClosureForm } from "context/closureFormContext/closureFormContext";

import { Props, filter, OptionTypes } from "./utils";
import useStyles from "../ClosureFormStyles";

interface AutocompleteProps extends Props {
  defaultValue?: OptionTypes;
  options: OptionTypes[];
  autocompleteProps?: Partial<React.ComponentProps<typeof ControlledDropdown>>;
}

const FilteredAutocomplete: React.FC<AutocompleteProps> = ({
  id,
  fieldInfo,
  defaultValue,
  options,
  autocompleteProps,
}) => {
  const { state } = useClosureForm();
  if (filter(fieldInfo, state.isAdmin, state.propertyData.country)) return null;
  const classes = useStyles();
  return (
    <>
      <ControlledDropdown
        id={id}
        label={fieldInfo.label(state.propertyData.country)}
        options={options}
        defaultValue={defaultValue}
        {...autocompleteProps}
      />
      <Divider className={classes.divider} />
    </>
  );
};

export default FilteredAutocomplete;
