import { makeStyles, Theme } from "@material-ui/core/";

const useCustomRadioButtonStyles = makeStyles<Theme>((theme) => ({
  label: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    transform: "none",
    lineHeight: 1.5,
    color: "inherit !important",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  requiredLabel: {
    "&::after": {
      content: '"*"',
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(0.5),
    },
  },
  option: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0),
    display: "flex",
    alignItems: "flex-start",
  },
  labelContainer: {
    lineHeight: 1.5,
    marginTop: theme.spacing(0.5),
    display: "flex",
    flexDirection: "column",
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  description: {
    color: theme.palette.grey["A400"],
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  checkContainer: {
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(-0.5),
    marginRight: theme.spacing(0.5),
  },
}));

export default useCustomRadioButtonStyles;
