import { useHistory, useLocation } from "react-router-dom";

import { Grid, useMediaQuery, useTheme } from "@material-ui/core";

import { getIsTenantDefaulter } from "apps/landlord/utils/getIsTenantDefaulter";
import CardMenuItem from "apps/landlord/components/CardMenuItem";

import { Locale } from "models/Countries";

import { ICurrentMonthBreakdownParsed } from "apps/landlord/hooks/useCurrentSummaryBreakdown/types";
import { actualDay, actualMonth, getSubtitle } from "./utils/utils";
import useRentalPaymentsInfoStyles from "./RentalPaymentsInfoStyles";
import BankAccountInfo from "./components/BankAccountInfo";
import PaymentDisclaimer from "../PaymentDisclaimer";
import InfoRow from "./components/InfoRow";

interface Props {
  country: Locale;
  isPlanGuaranteed: boolean;
  summaryData: ICurrentMonthBreakdownParsed;
}

export default function RentalPaymentsInfo({
  country,
  isPlanGuaranteed,
  summaryData,
}: Props) {
  const classes = useRentalPaymentsInfoStyles();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();

  const showDisclaimerFlag =
    summaryData.paymentTenantStatus === "UNPAID" && Number(actualDay) > 5;

  const isTenantDefaulter = getIsTenantDefaulter(
    summaryData.paymentTenantStatus,
    summaryData.tenantDebtDays
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const PaymentDisclaimerInfo = () => (
    <PaymentDisclaimer
      country={country}
      isPlanGuaranteed={isPlanGuaranteed}
      tenantDebtDays={summaryData.tenantDebtDays}
      landlordPaid={summaryData?.paymentLandlordStatus === "PAID"}
      lesseeMonthSinceDebt={summaryData?.lesseeMonthSinceDebt}
    />
  );

  return (
    <CardMenuItem
      title={`Arriendo de ${actualMonth}`}
      subtitle={getSubtitle(
        summaryData?.paymentLandlordStatus,
        summaryData?.paymentLandlordDate
      )}
      infoContainerClassName={classes.infoColumnRental}
      isButtonAvailable={summaryData?.paymentLandlordStatus === "PAID"}
      buttonLabel="Ver detalle de mi pago"
      onViewInfoClick={() => {
        const url = pathname.endsWith("/") ? pathname.slice(0, -1) : pathname;
        history.push(`${url}/payment_detail/${summaryData.landlordPaymentId}`);
      }}
    >
      <Grid
        item
        container
        className={classes.infoContainer}
        spacing={!isDesktop ? 2 : 0}
      >
        <Grid item xs={6} md={3}>
          <InfoRow
            title="Arriendo mensual"
            value={summaryData?.lease}
            isDefaulter={isTenantDefaulter}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <InfoRow title="Te depositamos" value={summaryData?.payment} />
        </Grid>
        {!isDesktop && showDisclaimerFlag && PaymentDisclaimerInfo()}
        <Grid item xs={12} md={6}>
          <BankAccountInfo bankData={summaryData.bankAccountData} />
        </Grid>
      </Grid>
      {isDesktop && showDisclaimerFlag && PaymentDisclaimerInfo()}
    </CardMenuItem>
  );
}
