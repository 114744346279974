import { makeStyles, Theme } from "@material-ui/core";

const useScheduleFormStyles = makeStyles((theme: Theme) => ({
  scheduleForm: {
    height: "100%",
    backgroundColor: "#FBFCFE",
    padding: theme.spacing(4, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5),
    },
  },
  closeIconWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closeIcon: {
    width: 15,
    height: 15,
    cursor: "pointer",
  },
  title: { marginBottom: theme.spacing(1), fontWeight: 700 },
  info: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(2),
  },
  missingTime: {
    position: "relative",
    top: 8,
    color: "#AE0400",
    fontSize: theme.typography.pxToRem(12),
  },
  nextButton: {
    margin: theme.spacing(4, 0),
  },
}));

export default useScheduleFormStyles;
