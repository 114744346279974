import { BACKEND_URL } from "env";
import { createHttp } from "services/helper";
import { PropertyContractService as IPropertyContractService } from "./types";

const PropertyContractService: IPropertyContractService = ({ token }) => {
  const httpClient = createHttp(token, BACKEND_URL);

  const getPropertyContract = ({ propertyId }) =>
    httpClient.get(`/contracts/landlord_contract/${propertyId}`);

  const getActiveContract = ({ propertyId }) =>
    httpClient.get(`/v2/landlord/contract_by_property/${propertyId}`);

  const closePropertyContract = ({
    type,
    contractId,
    estimatedDate,
    comment,
  }) =>
    httpClient.put(`/v2/contracts/landlord/${contractId}/exit_process/begin`, {
      type,
      estimated_date: estimatedDate,
      comment,
    });

  const getContracts = () => httpClient.get("/v2/contracts/");

  const getCloseContractProcessStatus = ({ contractId }) =>
    httpClient.get(`/v2/contracts/${contractId}/exit_process`);

  const getSignedContractsDocuments = ({ contractId }) =>
    createHttp(token, BACKEND_URL).get(
      `/v2/contracts/${contractId}/signed_contracts/`
    );

  return {
    getContracts,
    getActiveContract,
    getPropertyContract,
    closePropertyContract,
    getCloseContractProcessStatus,
    getSignedContractsDocuments,
  };
};

export default PropertyContractService;
