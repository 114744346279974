import { FormProvider } from "react-hook-form";

import { Grid } from "@material-ui/core";

import ControlledList from "Components/Admin/UIComponents/ControlledForm/ControlledList";
import ControlledTextField from "Components/Admin/UIComponents/ControlledForm/ControlledTextField";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { useApplicant } from "apps/landlord/pages/Applicant/context/ApplicantContext";
import useApplicantEvaluationFormStyles from "./ApplicantEvaluationFormStyles";
import useEvaluateProfile from "./useEvaluateProfile";
import { DECISIONS_OPTIONS, getLastMainApplicantId } from "./utils";
import KeepSearchingModal from "./components/KeepSearchingModal";
import EvaluationFormTitle from "./components/EvaluationFormTitle";

const ApplicantEvaluationForm = () => {
  const classes = useApplicantEvaluationFormStyles();

  const { EvaluationModalApplication: application, contract } = useApplicant();

  const mainApplicantId = getLastMainApplicantId(application);

  const {
    isLoading,
    decision,
    formMethods,
    handleFormSubmit,
    openKeepSearchingModal,
  } = useEvaluateProfile(mainApplicantId);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleFormSubmit} className={classes.formContent}>
          <Grid item container justifyContent="center">
            <EvaluationFormTitle applicants={application?.applicants} />
            <ControlledList
              label="Selecciona tu decisión"
              id="decision"
              options={DECISIONS_OPTIONS}
              disableUnderline
              classes={{
                select: classes.select,
                container: classes.container,
              }}
              selectClasses={{ root: classes.selectRoot }}
              iconClassName={classes.selectIcon}
            />
            {decision === "reevaluation" && (
              <ControlledTextField
                label="¿Tienes algún comentario?"
                id="comment"
                textFieldProps={{
                  placeholder: "Ingresar",
                  multiline: true,
                  minRows: 1,
                  InputProps: {
                    className: classes.textArea,
                  },
                }}
              />
            )}
            <Grid container justifyContent="flex-end" alignItems="center">
              <DesignSystemButton
                variant="primary"
                size="medium"
                type="submit"
                label="Enviar"
                isLoading={isLoading}
                buttonClassName={classes.button}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <KeepSearchingModal
        openKeepSearchingModal={openKeepSearchingModal}
        propertyUid={contract.property.uid}
      />
    </>
  );
};

export default ApplicantEvaluationForm;
