import { IBank } from "apps/tenant/models/BankAccount";
import { IBankAccountData } from "apps/tenant/services/bankAccountsService/types";
import { BaseOption } from "apps/ui/formComponents/Select/Select";
import { Locale } from "models/Countries";

import { BankAccountFormData, BankAccountFormDataMX } from "./types";

export const parseBankAccountMX = (
  formData: BankAccountFormDataMX
): IBankAccountData => ({
  account_number: formData.accountNumber,
  account_type: "BANK",
  bank_id: formData.bankId,
  document: formData.document,
  email: formData.email,
  holder: formData.name,
  clabe_number: formData.clabe,
  city: "",
});

export const parseBankAccount = (
  formData: BankAccountFormData | BankAccountFormDataMX,
  country: Locale
): IBankAccountData => {
  if (country === "mx")
    return parseBankAccountMX(formData as BankAccountFormDataMX);
  return {
    account_number: formData.accountNumber,
    account_type: formData.accountType,
    bank_id: formData.bankId,
    document: formData.document,
    email: formData.email,
    holder: formData.name,
  } as IBankAccountData;
};

export const parseBankOptions = (banks: IBank[]): BaseOption[] =>
  banks.map((bank) => ({
    label: bank.name,
    value: bank.bankId,
  }));
