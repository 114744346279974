import { makeStyles, Theme } from "@material-ui/core";

const useServicesRequestStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    marginBottom: theme.spacing(7),
  },
  drawerHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  iconContainer: {
    float: "right",
    width: 40,
    height: 40,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
}));

export default useServicesRequestStyles;
