import { makeStyles, Theme } from "@material-ui/core";

const useDesktopSubmenuStyles = makeStyles((theme: Theme) => ({
  collapse: {
    backgroundColor: "white",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("sm")]: {
      top: 45,
      left: 0,
      minWidth: 208,
      padding: theme.spacing(1, 0),
      borderRadius: 8,
      boxShadow: "3px 4px 15px 0px rgba(38, 50, 56, 0.15)",
      position: "absolute",
      overflow: "hidden",
    },
  },
}));

export default useDesktopSubmenuStyles;
