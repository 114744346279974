import { Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import useCompleteEvaluationDialogStyles from "./CompleteEvaluationDialogStyles";

interface Props {
  keepAsInvite: () => void;
  login: () => void;
}

const CompleteEvaluationDialog = ({ keepAsInvite, login }: Props) => {
  const classes = useCompleteEvaluationDialogStyles();

  return (
    <Grid container>
      <Grid item className={classes.textContainer}>
        <Typography component="span" className={classes.text}>
          Recuerda que para arrendar, la suma de las rentas del arrendatario y
          los codeudores, debe ser al menos <strong>3 veces el precio</strong>{" "}
          del arriendo.
        </Typography>
      </Grid>
      <Grid item container alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Typography
            component="span"
            className={classes.textButton}
            onClick={keepAsInvite}
          >
            Seguir como invitado
          </Typography>
        </Grid>
        <Grid item>
          <DesignSystemButton
            label="Iniciar Sesión"
            size="small"
            variant="primary"
            type="button"
            onClick={login}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompleteEvaluationDialog;
