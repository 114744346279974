import { createTheme } from "@material-ui/core/styles";

const defaultBreakpoint = "lg";

const designSystemCalendarTheme = (theme) =>
  createTheme({
    ...theme,
    overrides: {
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          minHeight: 340,
          overflow: "inherit !important",
        },
      },
      MuiPickersCalendarHeader: {
        iconButton: {
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        switchHeader: {
          height: 30,
          fontFamily: "Nunito",
          color: theme.palette.grey[500],
          backgroundColor: theme.palette.grey[50],
          textTransform: "capitalize",
          width: "100%",
        },
        dayLabel: {
          color: theme.palette.grey[900],
          fontSize: "14px !important",
          fontFamily: "Nunito",
          textTransform: "capitalize",
        },
        transitionContainer: {
          "& p": {
            fontWeight: 700,
            fontFamily: "Nunito",
          },
        },
        daysHeader: {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(2),
          borderBottom: `1px solid #DFE5E8`,
          display: "flex",
          justifyContent: "space-around",
        },
      },
      MuiPickersCalendar: {
        week: {
          marginBottom: theme.spacing(1),
          paddingBottom: theme.spacing(0),
          display: "flex",
          justifyContent: "space-around",
        },
      },
      MuiPickersBasePicker: {
        container: {},
        pickerView: {
          width: "100%",
          maxWidth: "unset",
          minHeight: 343,
          justifyContent: "start",
          [theme.breakpoints.up(defaultBreakpoint)]: {
            minWidth: 422,
            minHeight: "auto",
          },
        },
      },
      MuiPickersDay: {
        day: {
          color: theme.palette.grey[900],
          "& p": {
            fontWeight: 400,
            fontSize: theme.typography.pxToRem(14),
            fontFamily: "Nunito",
          },
        },
        daySelected: {
          color: "#212121",
          backgroundColor: "#d9d9d9",
          border: `1px solid ${theme.palette.grey[900]}`,
          "& p": {
            fontWeight: 700,
            color: "#212121",
          },
          "&:hover": {
            color: "#607D8B",
            backgroundColor: "#BFCBD1",
          },
        },
        current: {
          color: theme.palette.grey[900],
          backgroundColor: theme.palette.grey[50],
        },
      },
    },
  });

export default designSystemCalendarTheme;
