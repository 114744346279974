import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    width: 302,
    paddingTop: theme.spacing(2.25, 0, 0),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      paddingTop: theme.spacing(4.5),
      width: 600,
    },
  },
  modalContainer: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 250,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      maxWidth: 430,
    },
  },
  logo: {
    marginBottom: theme.spacing(3),
    width: 32,
    height: 30,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      width: 56,
      height: 48,
    },
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.25,
    fontWeight: 700,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginBottom: theme.spacing(1),
      fontSize: theme.typography.pxToRem(32),
    },
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.25,
    fontWeight: 400,
    textAlign: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginBottom: theme.spacing(5),
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.4,
    },
  },
}));

export default useStyles;
