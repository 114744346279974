import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

const useCustomDialogStyles = makeStyles<Theme>((theme) => ({
  paper: {
    overflowY: "unset",
    borderRadius: 16,
    boxShadow: "3px 4px 20px rgba(38, 50, 56, 0.15)",
    padding: theme.spacing(4, 4, 2, 4),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(16),
      margin: 0,
    },
  },
  customizedButton: {
    position: "absolute",
    backgroundColor: "#D4D6D7",
    color: theme.palette.grey[900],
    width: 40,
    height: 40,
  },
  icon: {
    width: 20,
    height: 20,
  },
  content: {
    padding: 0,
  },
  title: {
    padding: 0,
    marginBottom: theme.spacing(2.5),
  },
}));

export default useCustomDialogStyles;
