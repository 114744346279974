import classNames from "classnames";

import Button from "@houm-com/ui/Button";

import Grid from "@material-ui/core/Grid";
import { Theme, useMediaQuery } from "@material-ui/core";
import useTheme from "@material-ui/styles/useTheme";

import HoumLogo from "assets/images/houmLogo.png";
import useStyles from "./LayoutStyles";

interface Props {
  title: string;
  label: string;
  image: string;
  handleClick?: () => void;
  buttonLabel?: string;
  mainContainerHeight?: "vh" | "%";
  disabled?: boolean;
}

const Layout: React.FC<Props> = ({
  children,
  handleClick,
  image,
  label,
  title,
  buttonLabel = "Clic",
  mainContainerHeight = "vh",
  disabled = false,
}) => {
  const classes = useStyles({ height: mainContainerHeight });
  const theme: Theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const displayButton = (showInDesktop: boolean): React.ReactNode | null => {
    if (handleClick && showInDesktop === isDesktop) {
      return (
        <Button
          variant="primary"
          size="lg"
          className={classes.button}
          onClick={handleClick}
          disabled={disabled}
        >
          {buttonLabel}
        </Button>
      );
    }
    return null;
  };

  return (
    <div className={classes.mainContainer}>
      <div
        className={classNames({
          [classes.logoContainer]: !isDesktop,
          [classes.logoContainerDesktop]: isDesktop,
        })}
      >
        <img className={classes.logo} src={HoumLogo} alt="Houm" />
      </div>
      <div className={classes.container}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <div className={classes.infoContainer}>
              <h4 className={classes.label}>{label.toUpperCase()}</h4>
              <h1 className="text-p18 mb-6 md:text-h32 !font-bold">{title}</h1>
              {children}
              {displayButton(true)}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.imageContainer}>
              <img className={classes.image} src={image} alt="payment" />
            </div>
          </Grid>
        </Grid>
      </div>
      {displayButton(false)}
    </div>
  );
};

export default Layout;
