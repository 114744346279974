import { makeStyles, Theme } from "@material-ui/core/";

const useSelectPlanDialogStyles = makeStyles<Theme>((theme) => ({
  title: {
    textAlign: "center",
    width: "100%",
    fontSize: theme.typography.pxToRem(32),
  },
  subtitle: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(18),
  },
  titleContainer: {
    maxWidth: 457,
    flexDirection: "column",
    alignItems: "center",
  },
  modalContainer: {
    padding: theme.spacing(4, 4),
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4, 12),
      minWidth: 652,
    },
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    gap: 16,
  },
}));

export default useSelectPlanDialogStyles;
