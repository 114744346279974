import { isMobile } from "react-device-detect";

import { IoChevronDownOutline as ArrowDown } from "@houm-com/ui-icons";
import { Box, Typography } from "@material-ui/core";

import { MovementStatus } from "apps/tenant/models/Movement";

import PaymentStatus from "../../../PaymentStatus/PaymentStatus";
import useGeneralInfoStyles from "./GeneralInfoStyles";

interface Props {
  dueDate: string;
  hasBreakdown: boolean;
  showBreakdown: boolean;
  status: MovementStatus;
  totalAmount: string;
  toggleBreakdown: () => void;
}

const GeneralInfo = ({
  dueDate,
  hasBreakdown,
  showBreakdown,
  status,
  totalAmount,
  toggleBreakdown,
}: Props) => {
  const classes = useGeneralInfoStyles({ showBreakdown });

  return (
    <Box className={classes.generalInfoRoot}>
      <Box className={classes.paymentStatus}>
        <Typography className={classes.rent}>Arriendo</Typography>
        <PaymentStatus status={status} floating />
      </Box>
      <Typography className={classes.totalAmount}>{totalAmount}</Typography>
      <Typography className={classes.limitDate}>
        Fecha límite de pago {dueDate}
      </Typography>
      {isMobile && hasBreakdown && (
        <Typography className={classes.seeBreakdown} onClick={toggleBreakdown}>
          {`${showBreakdown ? "Ocultar" : "Ver"} detalle `}
          <ArrowDown className={classes.arrow} />
        </Typography>
      )}
    </Box>
  );
};

export default GeneralInfo;
