import { FormProvider, SubmitHandler } from "react-hook-form";

import { fullAddressSchema } from "apps/lead/pages/EvaluatorForm/components/AddressForm/utils/validationSchema";
import { classes } from "apps/lead/pages/EvaluatorForm/components/AddressForm/AddressFormStyles";
import { IAddressForm } from "apps/lead/pages/EvaluatorForm/components/AddressForm/utils/types";
import AddressSuggester from "apps/lead/components/AddressSuggester/AddressSuggester";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper/FormWrapper";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import useReactHookForm from "hooks/useReactHookForm";
import {
  setActiveStep,
  setUserInformation,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";

const AddressForm = () => {
  const {
    state: { user: userInformation, activeStep, country },
    dispatch,
  } = useEvaluation();
  const { methods, errors } = useReactHookForm<IAddressForm>({
    formSchema: fullAddressSchema,
    formOptions: {
      defaultValues: {
        isAddressSelected: !!userInformation.extraInformation?.fullAddress,
        fullAddress: userInformation.extraInformation?.fullAddress,
      },
    },
  });

  const onSubmit: SubmitHandler<IAddressForm> = (data) => {
    dispatch(
      setUserInformation({
        ...userInformation,
        extraInformation: {
          ...userInformation.extraInformation,
          fullAddress: data.fullAddress,
        },
      })
    );
    dispatch(setActiveStep(activeStep + 1));
  };

  return (
    <FormProvider {...methods}>
      <Header
        title="Dirección de residencia actual"
        stepTitle="Dirección actual"
      />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper classNameFormWrapper={classes.addressFormWrapper}>
          <AddressSuggester
            countryCode={country}
            textFieldProps={{
              id: "fullAddress",
              required: true,
              label: "Dirección",
              errorMessage: errors.fullAddress?.message,
              form: "novalidateform",
              placeholder: "Escribe aquí dónde vives actualmente",
              ...methods.register("fullAddress"),
            }}
            defaultValue={userInformation.extraInformation?.fullAddress}
            onChange={(e) => methods.setValue("fullAddress", e)}
            onCheck={(e) => methods.setValue("isAddressSelected", e)}
          />
        </FormWrapper>
        <CallToAction label="Continuar" />
      </form>
    </FormProvider>
  );
};

export default AddressForm;
