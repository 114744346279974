import { XGrid, XGridProps } from "@material-ui/x-grid";
import GetAppIcon from "@material-ui/icons/GetApp";

import CustomLoadingOverlay from "./components/CustomLoadingOverlay";
import useStyles from "./CustomDataGridStyles";

const CustomDataGrid = (props: XGridProps) => {
  const {
    rowsPerPageOptions = [5, 10, 20, 50, 100],
    paginationMode = "server",
    filterMode = "server",
    sortingMode = "server",
    components,
    localeText,
  } = props;
  const classes = useStyles();
  return (
    <XGrid
      className={classes.table}
      {...props}
      pagination
      rowsPerPageOptions={rowsPerPageOptions}
      paginationMode={paginationMode}
      filterMode={filterMode}
      sortingMode={sortingMode}
      autoHeight
      disableSelectionOnClick
      disableMultipleColumnsSorting
      components={{
        ExportIcon: GetAppIcon,
        LoadingOverlay: CustomLoadingOverlay,
        ...components,
      }}
      localeText={{
        toolbarExport: "Descargar",
        toolbarExportCSV: "Descargar CSV",
        ...localeText,
      }}
    />
  );
};

export default CustomDataGrid;
