import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";
import { useHoumSnackBar } from "libs/houm-snackbar";

import requestsService from "../services/requestsService";
import useContract from "./useContract";
import { parseRepairsRequests } from "./utils/parsers";
import { IRepairsRequest } from "./utils/types";

const useRepairsRequests = () => {
  const { getToken } = useGetToken();
  const { data: contractData, isLoading: isContractLoading } = useContract();
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const { data, isLoading, isError }: UseQueryResult<IRepairsRequest[], Error> =
    useQuery<IRepairsRequest[], Error>(
      "tenantRequests",
      async () => {
        const authToken = await getToken();
        const response = await requestsService.getRepairsRequests(
          authToken,
          contractData.propertyId
        );
        return parseRepairsRequests(response.data);
      },
      {
        onError: () => {
          enqueueHoumSnackBar({
            body: "Ha ocurrido un error obteniendo las solicitudes de reparación de tu propiedad",
            type: "error",
          });
        },
        enabled: !!contractData?.propertyId,
      }
    );

  return { data, isLoading: isLoading || isContractLoading, isError };
};

export default useRepairsRequests;
