import { makeStyles, Theme } from "@material-ui/core/";

const defaultBreakpoint = "sm";

const useRentedPropertyInfoStyles = makeStyles<Theme>((theme) => ({
  rentedInfoCardRoot: {
    width: "100%",
    padding: theme.spacing(3),
    boxShadow: "3px 4px 15px rgba(38, 50, 56, 0.15)",
    backgroundColor: "white",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      display: "contents",
      backgroundColor: "transparent",
      margin: theme.spacing(0),
      padding: theme.spacing(0),
      boxShadow: "none",
      clipPath: "none",
    },
  },
}));

export default useRentedPropertyInfoStyles;
