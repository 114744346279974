interface Props {
  address: string;
  bedrooms: number;
  flatNumber: string;
  typeProperty: string;
  bathrooms: number;
  total_area: number;
  built_area: number;
  parking_spots: number;
  is_furnished: boolean;
  latitud: number;
  longitud: number;
  regionRev: string;
  country: string;
  stratus: number;
  streetNumber: number;
  locality: string;
  property_age: number;
  municipality: string;
  commune: string;
}

export function parseProperty(propertyData: Props) {
  return {
    bedrooms: propertyData.bedrooms,
    property_type:
      propertyData.typeProperty === "departamento" ? "apartment" : "house",
    bathrooms: propertyData.bathrooms,
    total_area: propertyData.total_area,
    built_area: propertyData.built_area,
    parking_spots: propertyData.parking_spots,
    is_furnished: !!propertyData.is_furnished,
    latitude: propertyData.latitud,
    longitude: propertyData.longitud,
    region: propertyData.regionRev,
    country: propertyData.country,
    stratus: propertyData.stratus,
    locality: propertyData.locality,
    property_age: propertyData.property_age,
    municipality: propertyData.municipality,
  };
}

export function addressProperty(propertyData: Props) {
  return {
    address: propertyData.address,
    street_number: propertyData.streetNumber,
    numero_depto: propertyData.flatNumber,
    comuna: propertyData.commune,
    type: propertyData.typeProperty,
  };
}
