const COUNTRY_BASED_TRANSLATIONS = {
  Chile: {
    missingBankAccount: {
      title: "Cuenta bancaria",
      description: "Registra la cuenta donde desees recibir tu próximos pagos",
    },
    missingBasicServicesAccount: {
      title: "Servicios básicos y gastos comunes",
      description:
        "Deberás seleccionar la empresa y agregar los datos que le correspondan",
    },
    pendingPhotoSchedule: {
      title: "Agenda tus fotos gratuitas",
      description:
        "Define el día y horario en que iremos a tomar fotos de tu propiedad",
    },
    pendingSettingUpPrice: {
      title: "Precio de la publicación",
      description:
        "Encuentra recomendaciones basadas en propiedad como la tuya y agrega el precio de tu publicación",
    },
  },
  Colombia: {
    missingBankAccount: {
      title: "Cuenta bancaria",
      description: "Registra la cuenta donde desees recibir tu próximos pagos",
    },
    missingBasicServicesAccount: {
      title: "Servicios básicos y mantenimiento",
      description:
        "Deberás seleccionar la empresa y agregar los datos que le correspondan",
    },
    pendingPhotoSchedule: {
      title: "Agenda tus fotos gratuitas",
      description:
        "Define el día y horario en que iremos a tomar fotos de tu propiedad",
    },
    pendingSettingUpPrice: {
      title: "Precio de la publicación",
      description:
        "Encuentra recomendaciones basadas en propiedad como la tuya y agrega el precio de tu publicación",
    },
  },
  Mexico: {
    missingBankAccount: {
      title: "Cuenta bancaria",
      description: "Registra la cuenta donde desees recibir tu próximos pagos",
    },
    missingBasicServicesAccount: {
      title: "Servicios básicos y administración",
      description:
        "Deberás seleccionar la empresa y agregar los datos que le correspondan",
    },
    pendingPhotoSchedule: {
      title: "Agenda tus fotos gratuitas",
      description:
        "Define el día y horario en que iremos a tomar fotos de tu inmueble",
    },
    pendingSettingUpPrice: {
      title: "Precio de la publicación",
      description:
        "Encuentra recomendaciones basadas en inmuebles como el tuyo y agrega el precio de tu publicación",
    },
  },
};

export default COUNTRY_BASED_TRANSLATIONS;
