import { Country } from "../../types";
import Flag from "../Flag";
import useCountryOptionsStyles from "./CountryOptionsStyles";

interface Props {
  countries: Country[];
  open: boolean;
  setOpen: (open: boolean) => void;
  setSelected: (country: Country) => void;
}

const CountryOptions = ({ countries, open, setOpen, setSelected }: Props) => {
  const classes = useCountryOptionsStyles();

  const handleClick = (country: Country) => {
    setSelected(country);
    setOpen(false);
  };

  if (!open || !countries) return null;

  return (
    <div className={classes.countryOptionsRoot}>
      {countries.map((country) => (
        <div
          data-testid={`country-option-${country.code}`}
          className={classes.countryOption}
          onClick={() => handleClick(country)}
          key={country.code}
          data-value={country.callingCode}
        >
          <Flag country={country} size={12} className={classes.flag} />
          <span className={classes.text}>{country.name}</span>
          <span className={classes.text}>({country.callingCode})</span>
        </div>
      ))}
    </div>
  );
};

export default CountryOptions;
