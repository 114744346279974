import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

const useNegotiationDetailsStyles = makeStyles((theme: Theme) => ({
  titleGeneral: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: theme.palette.grey[900],
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: "2rem",
    },
  },
  photos: {
    height: 170,
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginBottom: 0,
    },
  },
  headers: {
    padding: theme.spacing(0, 0, 5, 0),
  },
  epigraph: {
    marginBottom: theme.spacing(1),
    fontSize: "0.75rem",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: "1rem",
      lineHeight: 1.5,
      marginBottom: theme.spacing(2),
    },
  },
  value: {
    marginLeft: theme.spacing(1),
    fontWeight: "bold",
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: "1rem",
    fontWeight: "bold",
    lineHeight: 1.5,
    color: theme.palette.grey[500],
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginBottom: theme.spacing(3),
      fontSize: "1.25rem",
    },
  },
}));

export default useNegotiationDetailsStyles;
