import { Typography } from "@material-ui/core";

import { PaymentDetail } from "apps/payments/context/rentPaymentContext/rentPaymentTypes";

import useRenderDetailsStyles from "./RenderDetailsStyles";

interface Props {
  title: string;
  data: PaymentDetail[];
}

const RenderDetails = ({ title, data }: Props) => {
  const classes = useRenderDetailsStyles();
  if (data.length === 0) {
    return null;
  }
  return (
    <div className={classes.detailsContainer}>
      <Typography className={classes.sectionSubtitle}>{title}</Typography>
      {data.map((detail) => (
        <Typography className={classes.detailLabel} key={detail.id}>
          {detail.description}
        </Typography>
      ))}
    </div>
  );
};

export default RenderDetails;
