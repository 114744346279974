import { SnackbarProvider } from "notistack";
import { Route, useRouteMatch } from "react-router-dom";

import AccountLayout from "../layout/AccountLayout";
import Appointments from "../pages/Appointments";
import EmailVerification from "../pages/EmailVerification";
import AccountFavorites from "../pages/Favorites";
import PhoneVerification from "../pages/PhoneVerification";
import Profile from "../pages/Profile";

const AccountPrivateRoutes = () => {
  const match = useRouteMatch();
  return (
    <SnackbarProvider>
      <AccountLayout>
        <Route
          exact
          path={[
            match.path,
            `${match.path}/profile`,
            `${match.path}/profile/:submenu`,
          ]}
        >
          <Profile />
        </Route>
        <Route exact path={`${match.path}/email_verification/:code`}>
          <EmailVerification />
        </Route>
        <Route exact path={`${match.path}/appointments`}>
          <Appointments />
        </Route>
        <Route path={`${match.path}/favorites`}>
          <AccountFavorites />
        </Route>
        <Route path={`${match.path}/phone_verification/:phone`}>
          <PhoneVerification />
        </Route>
      </AccountLayout>
    </SnackbarProvider>
  );
};

export default AccountPrivateRoutes;
