import { useUser } from "context/userContext";
import { ReactNode, createContext, useContext } from "react";
import { AUTH0_ROLES, USER_ROLES } from "env";

const UserRoleContext = createContext({ isTenant: false, isLandlord: false });

const isUserTenant = (roles) => roles?.includes(USER_ROLES.TENANT);

const isUserLandlord = (roles) => roles?.includes(USER_ROLES.LANDLORD);

const UserRoleProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();
  const roles = user?.[AUTH0_ROLES];
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const initialState = {
    isTenant: isUserTenant(roles),
    isLandlord: isUserLandlord(roles),
  };
  return (
    <UserRoleContext.Provider value={initialState}>
      {children}
    </UserRoleContext.Provider>
  );
};

const useUserRole = () => {
  const context = useContext(UserRoleContext);
  if (context === undefined) {
    throw new Error("useRoleContext must be used within a UserRoleProvider");
  }
  return context;
};

export { UserRoleProvider, useUserRole };
