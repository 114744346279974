import { makeStyles, Theme } from "@material-ui/core";

const useInfoStepStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    backgroundColor: "#F3F7FD",
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  content: {
    marginBottom: theme.spacing(3),
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.spacing(4),
    },
    "& > br": {
      display: "block",
      content: "' '",
      marginBottom: theme.spacing(1),
    },
  },
}));

export default useInfoStepStyles;
