import { makeStyles, Theme } from "@material-ui/core";

const usePaymentSectionStyles = makeStyles((theme: Theme) => ({
  payButton: {
    height: "fit-content",
    width: "fit-content",
    padding: theme.spacing(0.5, 2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  voucherLink: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    cursor: "pointer",
  },
  arrow: {
    marginLeft: theme.spacing(1),
  },
}));

export default usePaymentSectionStyles;
