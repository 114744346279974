import clx from "classnames";

import { IoPersonAddOutline } from "@houm-com/ui-icons";
import { Typography } from "@material-ui/core";

import { EvaluationApplicant } from "apps/lead/models/Application";

import usePropertyEvaluationDetailsStyles from "./PropertyEvaluationDetailsStyles";

interface Props {
  evaluationData: EvaluationApplicant;
}

const PropertyEvaluationDetails = ({ evaluationData }: Props) => {
  const classes = usePropertyEvaluationDetailsStyles();
  return (
    <>
      <div className={classes.descriptionContainer}>
        <div className={classes.iconContainer}>
          <IoPersonAddOutline color="#46788C" />
        </div>
        <Typography className={classes.descriptionText}>
          <b>Postulantes: </b>
          {evaluationData?.persons.length}
        </Typography>
      </div>
      <div className={classes.personContainer}>
        {evaluationData.persons.map((person) => (
          <Typography
            key={person.id}
            component="div"
            className={classes.personDescription}
          >
            <b>{`${person.name} ${person.lastName} `}</b>
            <span
              className={clx(classes.statusDefault, {
                [classes.statusPending]:
                  person.status === "Documentación pendiente",
                [classes.statusRejected]: person.status === "Rechazado",
              })}
            >
              {person.status}
            </span>
          </Typography>
        ))}
      </div>
    </>
  );
};

export default PropertyEvaluationDetails;
