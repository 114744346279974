import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  phoneHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 50,
    backgroundColor: theme.palette.grey[900],
    padding: theme.spacing(0, 2),
  },
  phoneHeaderText: {
    color: "white",
  },
  headerIcon: {
    color: "white",
  },
}));
