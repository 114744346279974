import { makeStyles, Theme } from "@material-ui/core";

const useWelcomeStyles = makeStyles((theme: Theme) => ({
  rootGrid: {
    minHeight: "70vh",
  },
  text: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
  textTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(28),
    marginBottom: theme.spacing(3),
    lineHeight: theme.typography.pxToRem(36),
  },
}));

export default useWelcomeStyles;
