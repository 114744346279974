import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useMailFormStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
    fontWeight: 700,
    textAlign: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1.33,
    },
  },
  emailContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      gap: theme.spacing(3),
    },
  },
}));

export default useMailFormStyles;
