import { v4 as uuidv4 } from "uuid";
import { compose, flatMap, flatten, prop } from "lodash/fp";

import { format } from "date-fns";
import {
  InspectionReportCommentFormData,
  ParseInspectionReportComments,
} from "./types";

export const parseInspectionReportComments: ParseInspectionReportComments = (
  inspectionReportComment
) => ({
  id: inspectionReportComment.id,
  createdAt: format(new Date(inspectionReportComment.created_at), "dd/MM/yyyy"),
  comment: inspectionReportComment.comment,
  photos: inspectionReportComment.photos,
});

export const encodeInspectionReportComment = ({
  inspectionReportComment,
  filenames,
}: {
  inspectionReportComment: InspectionReportCommentFormData;
  filenames: string[];
}) => ({
  ...inspectionReportComment,
  photos: filenames.map((filename) => ({ photo_filename: filename })),
});

export const getPhotoFileName = ({ name }: { name: string }) => {
  const fileExtension = name.split(".").pop();
  const uuid = uuidv4();
  const fileName = `inspection_report_comment_${uuid}.${fileExtension}`;
  return fileName;
};

export const managePresignedURL = () => {};

export const parsePagesToFlatList = compose(flatMap(prop("results")), flatten);

export const sanitizePageParam = (pageParam: string) => {
  if (!pageParam) return undefined;
  return pageParam.replace("http", "https");
};
