import { Typography } from "@material-ui/core";
import { ILeavingForm } from "apps/tenant/utils/types";
import { Locale } from "models/Countries";
import LeavingDateForm from "./components/LeavingDateForm";
import useLeavingDateStepStyles from "./LeavingDateStepStyles";
import { LOCAL_BASED_DESCRIPTION, LOCAL_BASED_TITLE } from "./constants";

type Props = {
  onSubmitHadler: (data: ILeavingForm) => void;
  leavingData: ILeavingForm;
  country: Locale;
};

const LeavingDateStep = ({ onSubmitHadler, country, leavingData }: Props) => {
  const classes = useLeavingDateStepStyles();
  return (
    <>
      <Typography className={classes.title}>
        {LOCAL_BASED_TITLE[country]}
      </Typography>
      <Typography className={classes.description}>
        {LOCAL_BASED_DESCRIPTION[country]}
      </Typography>
      <LeavingDateForm
        submitHandler={onSubmitHadler}
        data={leavingData}
        country={country}
      />
    </>
  );
};

export default LeavingDateStep;
