const DEBT_TYPE_OPTIONS = [
  {
    label: "Agua, luz y/o gas",
    value: "ssbb",
  },
  {
    label: "Gastos Comunes",
    value: "ggcc",
  },
  {
    label: "Ambos",
    value: "ssbb_ggcc",
  },
];

const REGULARIZATION_TYPE_OPTIONS = [
  {
    label: "Ya pagué y necesito un reembolso",
    value: "refund",
  },
  {
    label: "No he pagado",
    value: "payment",
  },
];

export { DEBT_TYPE_OPTIONS, REGULARIZATION_TYPE_OPTIONS };
