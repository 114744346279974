import { FavoriteProperty } from "apps/account/models/FavoriteProperty";

import useImageContainerStyles from "./ImageContainerStyles";
import { Typography } from "@material-ui/core";
import FavoriteButton from "../FavoriteButton";
import AmenitiesRow from "../AmenitiesRow";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";
import { FavoriteMode } from "apps/account/pages/Favorites/utils/types";

interface Props {
  handleFavoriteToggle?: () => void;
  isFavorite?: boolean;
  mode: FavoriteMode;
  property: FavoriteProperty;
}

const ImageContainer = ({
  handleFavoriteToggle,
  isFavorite = false,
  mode,
  property,
}: Props) => {
  const classes = useImageContainerStyles();
  const { t } = useTranslation("shared");

  const handleFavoriteClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleFavoriteToggle?.();
  };

  return (
    <div className={classes.imageContainer}>
      <img
        src={property.photos?.[0]?.url}
        alt="Imagen de la propiedad"
        className={classes.image}
      />
      <div className={classes.upperOverlayRow}>
        <Typography className={classes.typeLabel}>
          {startCase(t(mode))}
        </Typography>
        <FavoriteButton
          isFavorite={isFavorite}
          handleFavoriteToggle={handleFavoriteClick}
        />
      </div>
      <AmenitiesRow characteristics={property.characteristics} />
    </div>
  );
};

export default ImageContainer;
