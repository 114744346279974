import { useHistory } from "react-router-dom";

import { Box } from "@material-ui/core";

import PropertyLayout from "apps/landlord/Layout/PropertyLayout";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import Spinner from "Components/Shared/partials/Spinner";
import { countryCode } from "utils";

import { useProperty } from "apps/landlord/hooks";
import useSwitchAnalysisType from "./hooks/useSwitchAnalysisType";
import useExternalProperties from "./hooks/useExternalProperties";
import useSimilarProperties from "./hooks/useSimilarProperties";
import SimilarProperties from "./components/SimilarProperties";
import ScheduleFeedback from "./components/ScheduleFeedback";
import useMainProperty from "./hooks/useMainProperty";
import SimilarPropsMap from "./components/Map";
import useStyles from "./PropertyPriceStyles";
import RevBox from "./components/Rev";

const PropertyPrice = () => {
  const classes = useStyles();
  const history = useHistory();
  const { propertyData } = useProperty();
  const {
    data: dataMainProperty,
    isLoading,
    isSuccess,
  } = useMainProperty(Number(propertyData?.id));
  const { handleChangeType, isForRentalAndSale, type } =
    useSwitchAnalysisType(dataMainProperty);
  const { data: similarProperties, isLoading: isLoadingSimilarProperties } =
    useSimilarProperties(Number(propertyData?.id), type);
  const { data: externalProperties, isLoading: isLoadingExternal } =
    useExternalProperties(Number(propertyData?.id), type);

  const onHandleBack = () =>
    dataMainProperty &&
    history.push(`/landlord/properties/${dataMainProperty?.uid}`);

  return (
    <PropertyLayout onHandleBack={onHandleBack} titlePage="">
      <div className={classes.boxesContainer} id="toPrint">
        {isLoading && <Spinner />}
        {isSuccess && (
          <Box className={classes.revContent}>
            <RevBox
              mainProperty={dataMainProperty}
              type={type}
              handleChangeType={handleChangeType}
              isForRentalAndSale={isForRentalAndSale}
            />
            <Loading loading={isLoadingSimilarProperties}>
              <SimilarProperties
                similarProperties={similarProperties}
                locale={countryCode(dataMainProperty?.country)}
                type={type}
              />
            </Loading>
            <Loading loading={isLoadingExternal}>
              <SimilarPropsMap
                mainProperty={dataMainProperty}
                similarProperties={similarProperties}
                externalProperties={externalProperties}
                type={type}
              />
            </Loading>
            <ScheduleFeedback schedules={dataMainProperty.schedules} />
          </Box>
        )}
      </div>
    </PropertyLayout>
  );
};

export default PropertyPrice;
