import { isMobile } from "react-device-detect";
import { useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Box } from "@material-ui/core";

import Spinner from "Components/Shared/partials/Spinner";
import BackButton from "apps/tenant/components/BackButton";
import useProperty from "apps/tenant/hooks/useProperty";
import { useNavbar } from "apps/ui/Navbar/context/navbarContext";
import useInspectionReports from "hooks/inspectionReports/useInspectionReports";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import CreateNewComment from "./components/CreateNewComment";
import ReportCommentsList from "./components/ReportCommentsList";
import useInspectionReportComments from "./hooks/useInspectionReportComments";
import useInspectionReportCommentsStyles from "./InspectionReportCommentsStyles";
import RequestTriggerCard from "./components/RequestsTriggerCard";
import InspectionReportCommentsHeader from "./components/InspectionReportcommentsHeader";

const InspectionReportComments = () => {
  const classes = useInspectionReportCommentsStyles();
  const match = useRouteMatch();
  const { reportId: inspectionReportId, propertyId } = match.params as {
    reportId: string;
    propertyId: string;
  };
  const { getInspectionReportById } = useInspectionReports(Number(propertyId));
  const { propertyData } = useProperty(propertyId);
  const inspectionReport = getInspectionReportById(Number(inspectionReportId));
  const {
    hasMoreInspectionReportComments,
    getMoreInspectionReportComments,
    inspectionReportComments,
    submitNewInspectionReportComment,
    isLoadingInspectionReportComments,
    isLoadingMoreInspectionReportComments,
  } = useInspectionReportComments({
    propertyId,
    inspectionReportId,
  });
  const [isCreatingComment, setIsCreatingComment] = useState(false);
  const observerRef = useRef<HTMLDivElement>(null);
  const { isVisible } = useIntersectionObserver({ ref: observerRef });

  const history = useHistory();
  const { setBackUrl } = useNavbar();

  const backUrl = match.url.replace(`/${inspectionReportId}`, "");

  useEffect(() => {
    if (isVisible && hasMoreInspectionReportComments) {
      getMoreInspectionReportComments();
    }
  }, [
    isVisible,
    getMoreInspectionReportComments,
    hasMoreInspectionReportComments,
  ]);

  useEffect(() => {
    setBackUrl(backUrl);
  }, [backUrl, match.url, setBackUrl]);

  return (
    <Box className={classes.container}>
      {!isMobile && <BackButton onClickHandler={() => history.push(backUrl)} />}
      {inspectionReport && propertyData && (
        <InspectionReportCommentsHeader
          inspectionReport={inspectionReport}
          propertyData={propertyData}
        />
      )}
      {isLoadingInspectionReportComments && <Spinner />}
      {!isLoadingInspectionReportComments && isCreatingComment && (
        <CreateNewComment
          onSubmit={submitNewInspectionReportComment}
          onClose={() => setIsCreatingComment(false)}
        />
      )}
      {!isLoadingInspectionReportComments && !isCreatingComment && (
        <ReportCommentsList
          canCreateNewComments={inspectionReport?.commentsAllowed}
          reports={inspectionReportComments}
          onCreateNewComment={() => setIsCreatingComment(true)}
          isLoadingMoreItems={isLoadingMoreInspectionReportComments}
        />
      )}
      {!isLoadingInspectionReportComments &&
        hasMoreInspectionReportComments && <div ref={observerRef} />}
      <RequestTriggerCard />
    </Box>
  );
};

export default InspectionReportComments;
