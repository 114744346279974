import clsx from "clsx";

import { Dialog, IconButton, DialogProps } from "@material-ui/core";
import { GridCloseIcon } from "@material-ui/x-grid";

import useDSModalStyles from "./DSModalStyles";

interface DSModalProps extends DialogProps {
  paperClassName?: string;
  showCloseButton?: boolean;
}

const DSModal = ({
  open,
  onClose,
  paperClassName,
  showCloseButton = false,
  children,
  ...props
}: DSModalProps) => {
  const classes = useDSModalStyles();
  return (
    <Dialog
      open={open}
      PaperProps={{
        classes: {
          root: clsx(classes.paper, paperClassName),
        },
      }}
      {...props}
    >
      {showCloseButton && (
        <div className={classes.closeButtonContainer}>
          <IconButton
            className={classes.closeButton}
            onClick={() => onClose({}, "backdropClick")}
          >
            <GridCloseIcon />
          </IconButton>
        </div>
      )}
      {children}
    </Dialog>
  );
};
export default DSModal;
