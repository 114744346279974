import { useParams } from "react-router-dom";

import { useFeatureManager } from "@houm-com/feature-manager";

import { UseMovementDataProps } from "apps/payments/hooks/useMovementData/useMovementData";
import LandingInfo from "apps/payments/pages/LandingPayment/components/LandingInfo";
import Layout from "Components/Admin/UIComponents/paymentLayout/Layout";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import NotebookPerson from "assets/images/notebookPerson.png";
import { Locale } from "models/Countries";

import PaymentGateway from "../PaymentGateway";

interface Props {
  movement: UseMovementDataProps;
  address: string;
}

const LandingPaymentsWrapperHoc = ({ movement, address }: Props) => {
  const { onHandleDrawer, open, setCurrentStep } = useCustomDrawer();
  const { countryCode } = useParams<{
    countryCode: Locale;
  }>();
  const { data } = useFeatureManager("landing-payments", {
    marketCode: countryCode,
  });

  const onHandlePayment = async () => {
    setCurrentStep("paymentPlatforms");
    onHandleDrawer();
  };

  return (
    <>
      <Layout
        title={data?.content[movement.concept.toLowerCase()].layoutTitle}
        label="BIENVENIDO"
        image={NotebookPerson}
        handleClick={onHandlePayment}
        buttonLabel={
          data?.content[movement.concept.toLowerCase()].layoutButtonLabel
        }
        mainContainerHeight="%"
      >
        <LandingInfo
          movement={movement}
          address={address}
          country={countryCode}
        />
      </Layout>
      <PaymentGateway open={open} onHandleDrawer={onHandleDrawer} />
    </>
  );
};

export default LandingPaymentsWrapperHoc;
