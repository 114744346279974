import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const CardContent = ({ children }: Props) => (
  <div className="gap-3 flex flex-col">{children}</div>
);

export default CardContent;
