import { useQuery, UseQueryResult } from "react-query";

import propertyService from "apps/landlord/services/propertyService/propertyService";
import { useGetToken } from "hooks";

import parseSimilarProperty from "./parsers";
import { ISimilarProperty } from "./types";

const useSimilarProperties = (propertyId: number, type: "rent" | "sale") => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    data = [],
    isError,
    isSuccess,
  }: UseQueryResult<ISimilarProperty[], Error> = useQuery<
    ISimilarProperty[],
    Error
  >(
    ["similar_properties_by_id_rev", propertyId, type],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.getLandlordSimilarProperties(
        propertyId,
        type,
        authToken
      );
      return response.data.map(parseSimilarProperty);
    },
    {
      enabled: !!propertyId && !!type,
    }
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useSimilarProperties;
