import { AxiosResponse } from "axios";

import { createHttp } from "services/helper";
import { IRepairRequestBody } from "./types";
import { IPhotosBody, IPresignedUrlResponse } from "../types";

export default {
  createRepairRequest(
    token: string,
    propertyId: number,
    data: IRepairRequestBody
  ): Promise<AxiosResponse> {
    return createHttp(token).post(
      `/properties/v2/${propertyId}/maintenance/request`,
      data
    );
  },

  getPresignedUrl(
    token: string,
    data: IPhotosBody
  ): Promise<AxiosResponse<IPresignedUrlResponse>> {
    return createHttp(token).post(
      "/properties/v2/maintenance/upload-url",
      data
    );
  },
};
