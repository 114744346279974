import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  disabled?: boolean;
}

const usePhoneInputStyles = makeStyles<Theme, Props>((theme) => ({
  hidden: {
    display: "none",
  },
  phoneInputRoot: {
    margin: theme.spacing(2, 0),
    marginRight: ({ disabled }) => (disabled ? 0 : theme.spacing(0.5)),
    position: "relative",
    width: "fit-content",
    borderRadius: 4,
    padding: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    backgroundColor: ({ disabled }) =>
      disabled ? "inherit" : theme.palette.grey[50],
    cursor: ({ disabled }) => (disabled ? "default" : "pointer"),
  },
  flag: {
    width: 12,
    height: 12,
  },
  code: {
    margin: theme.spacing(0, 0.5),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
  },
  arrow: {
    display: ({ disabled }) => (disabled ? "none" : "block"),
    width: 12,
    height: 12,
  },
}));

export default usePhoneInputStyles;
