import { makeStyles, Theme } from "@material-ui/core";

const useSummaryReportDownloaderStyles = makeStyles<Theme>((theme) => ({
  summaryReportContainer: {
    alignItems: "center",
    gap: theme.spacing(2),
    justifyContent: "space-between",
  },
  summaryReportField: {
    flex: "auto",
    borderRadius: 4,
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    fontWeight: 700,
    [theme.breakpoints.up("sm")]: {
      width: "70%",
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  summaryReportFieldPlaceholder: {
    fontWeight: 400,
    color: "#BFCBD1",
  },
  summaryReportSelectOption: {
    textTransform: "capitalize",
  },
  summaryReportSelectField: {
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.grey[50],
    textTransform: "capitalize",
    borderRadius: 4,
    "&:focus": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  summaryReportDownloadButton: {
    textTransform: "capitalize",
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    padding: theme.spacing(0.1, 0.2),
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.grey[5],
    },
    "&:active": {
      boxShadow: "4px 4px 30px rgba(0,0,0,0.25)",
    },
    "&:disabled": {
      borderBottom: "1px solid #BFCBD1",
    },
  },
  summaryReportDownloadButtonIcon: {
    marginLeft: theme.spacing(0.5),
  },
}));

export default useSummaryReportDownloaderStyles;
