import { makeStyles, Theme } from "@material-ui/core";

const useDayWrapperStyles = makeStyles((theme: Theme) => ({
  disabled: {
    width: 40,
    height: 36,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: theme.typography.pxToRem(14),
    color: "rgba(0, 0, 0, 0.38)",
    pointerEvents: "none",
  },
}));

export default useDayWrapperStyles;
