import { useHistory, useParams } from "react-router-dom";

import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import DrawerHeader from "apps/ui/CustomDrawer/DrawerHeader";
import Spinner from "Components/Shared/partials/Spinner";
import { useHoumSnackBar } from "libs/houm-snackbar";
import Drawer from "apps/ui/CustomDrawer/Drawer";

import usePropertyDetails from "apps/landlord/hooks/usePropertyDetails/index";
import { useEffect } from "react";
import Form from "./components/Form";

const DrawerPropertyDetails = () => {
  const history = useHistory();
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const { id: propertyId } = useParams<{ id: string }>();
  const { open, returnToMainPage, saveParentUrl } = useCustomDrawer();
  const { isLoading, data, isError } = usePropertyDetails(propertyId);

  useEffect(() => {
    const PROPERTY_URL = `/landlord/properties/${propertyId}`;
    saveParentUrl(PROPERTY_URL);
  }, [propertyId, saveParentUrl]);

  if (isError) {
    enqueueHoumSnackBar({
      body: "Ha ocurrido un error",
      type: "error",
    });
    history.push(`/landlord/properties/${propertyId}`);
  }

  return (
    <Drawer onHandleClose={returnToMainPage} open={open}>
      <DrawerHeader onHandleClose={returnToMainPage} />
      {isLoading && <Spinner />}
      {!isLoading && <Form data={data} />}
    </Drawer>
  );
};

export default DrawerPropertyDetails;
