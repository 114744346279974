import { makeStyles, Theme } from "@material-ui/core";

const usePlanTypeTagStyles = makeStyles<Theme>((theme) => ({
  container: {
    minHeight: 24,
    maxWidth: 208,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(0.25, 1),
    backgroundColor: "rgba(236, 242, 244, 0.3)",
    gap: 8,
  },
  planText: {
    color: "white",
    fontWeight: 700,
    fontStyle: "italic",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
  },
}));

export default usePlanTypeTagStyles;
