import { makeStyles, Theme } from "@material-ui/core";

const useCustomLoadingOverlayStyles = makeStyles((theme: Theme) => ({
  loading: {
    position: "absolute",
    top: theme.spacing(3),
  },
}));

export default useCustomLoadingOverlayStyles;
