import { Box, Typography } from "@material-ui/core";

import useInfoRowStyles from "./InfoRowStyles";

interface Props {
  title?: string;
  value?: any;
  secondaryTitle?: string;
  secondaryValue?: string;
}

export default function InfoRow({
  title = null,
  value = null,
  secondaryTitle = null,
  secondaryValue = null,
}: Props) {
  const classes = useInfoRowStyles();

  return (
    <Box className={classes.infoRowContainer}>
      {title && (
        <Box>
          <Typography className={classes.infoTextTitle}>{title}</Typography>
          <Typography className={classes.infoTextValue}>{value}</Typography>
        </Box>
      )}
      {secondaryTitle && (
        <Box>
          <Typography className={classes.infoTextTitle}>
            {secondaryTitle}
          </Typography>
          <Typography className={classes.infoTextValue}>
            {secondaryValue}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
