import { makeStyles, Theme } from "@material-ui/core";

const useStatusTagStyles = makeStyles<Theme>((theme) => ({
  chip: {
    height: 20,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
  },
  chipRejected: {
    color: "#900000",
    background: "#EFCDCC",
  },
  chipApproved: {
    color: "#439F03",
    background: "#DAEECC",
  },
  chipProcess: {
    color: "#2663BC",
    background: "#D5E3F8",
  },
  chipInformation: {
    color: "#A07800",
    background: "#FAF3CC",
  },
}));

export default useStatusTagStyles;
