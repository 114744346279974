import { makeStyles, Theme } from "@material-ui/core";

const useButtonFullFileStyles = makeStyles<Theme>((theme) => ({
  containerText: {
    display: "flex",
    alignItems: "center",
  },
  containerFile: {
    border: `1px solid ${theme.palette.grey[75]}`,
    borderRadius: 8,
    width: 112,
    height: 43,
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    textTransform: "none",
    marginTop: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 6,
    position: "relative",
    pointerEvents: "none",
  },
  fileName: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[900],
    textDecoration: "underline",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  iconContainer: {
    position: "absolute",
    top: -8,
    right: -9,
  },
  deleteFileUploaded: {
    color: "rgba(176, 190, 197, 0.8)",
    cursor: "pointer",
    fontSize: theme.typography.pxToRem(20),
    "&:hover": {
      color: theme.palette.primary.main,
    },
    pointerEvents: "auto",
  },
}));

export default useButtonFullFileStyles;
