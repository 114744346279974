import { MouseEvent, useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { IoCloseOutline } from "@houm-com/ui-icons";

import useHorizontalBannerStyles from "./HorizontalBannerStyles";

interface HorizontalBannerProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const HorizontalBanner = ({ children, onClick }: HorizontalBannerProps) => {
  const classes = useHorizontalBannerStyles();
  const [modalRoot, setModalRoot] = useState(null);

  useEffect(() => {
    const newModalRoot = document.getElementById("horizontal-banner-root");
    setModalRoot(newModalRoot);
  }, []);

  if (!modalRoot) return null;

  const onCloseBanner = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setModalRoot(null);
  };

  return ReactDOM.createPortal(
    <div
      data-testid="horizontal-banner"
      className={classes.root}
      onClick={onClick}
    >
      <div className="flex justify-center items-center gap-2 max-w-[80%]">
        {children}
      </div>
      <button
        onClick={onCloseBanner}
        className="absolute right-0 mr-8 rounded-full"
      >
        <IoCloseOutline data-testid="close-icon" size="24" />
      </button>
    </div>,
    modalRoot
  );
};

export default HorizontalBanner;
