import { useMutation, useQueryClient } from "react-query";

import { useHoumSnackBar } from "libs/houm-snackbar";

import { useGetToken } from "hooks";
import { CECompanyForm } from "apps/landlord/models/ServicesCompanies";
import propertiesDuesService from "apps/landlord/services/propertiesDuesService";
import { useProperty } from "apps/landlord/hooks";
import useBillsInfo from "./useBillInfo";
import { parseBillsInfoPayload } from "./utils";

const useChangeCommonExpenses = () => {
  const queryClient = useQueryClient();
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const { propertyData } = useProperty();
  const { id } = propertyData || {};
  const { data: billsInfoData, isLoading: billsInfoLoading } = useBillsInfo([
    "common_expenses",
  ]);
  const hasCommonExpenses = billsInfoData?.common_expenses?.company;
  const { getToken } = useGetToken();
  const {
    mutate,
    isLoading: submitting,
    isSuccess,
  } = useMutation(
    async (formData: CECompanyForm) => {
      const authToken = await getToken();
      const billData = parseBillsInfoPayload({
        formData,
        billsInfo: billsInfoData?.common_expenses,
        propertyId: id,
      });
      const method = hasCommonExpenses ? "patchBill" : "postBill";
      return propertiesDuesService[method](id, authToken, billData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("billsInfo");
        queryClient.invalidateQueries("propertyBillsDetails");
        enqueueHoumSnackBar({
          body: "¡Gastos comunes guardados exitosamente!",
          type: "success",
        });
      },
      onError: () => {
        enqueueHoumSnackBar({
          body: "Ha ocurrido un error actualizando la información",
          type: "error",
        });
      },
    }
  );
  return { mutate, submitting: submitting || billsInfoLoading, isSuccess };
};

export default useChangeCommonExpenses;
