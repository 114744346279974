import { compareAsc, parse } from "date-fns";

import { ContractResponse } from "apps/tenant/services/tenantContractService/types";
import { ITenantContract } from "apps/tenant/models/Contract";

// to be deleted when BE updates the endpoint
export const parseSingleTenantContract = (
  data: ContractResponse
): ITenantContract => ({
  country: data.country,
  id: data.id,
  initDate: parse(data.init_date, "yyyy-MM-dd", new Date()),
  endDate: parse(data.end_date, "yyyy-MM-dd", new Date()),
  propertyId: data.property_id,
  subscriptionId: data.active_subscription_id,
  endNoticePeriod: data.end_notice_period,
});

export const parseMultipleTenantContracts = (
  data: ContractResponse[]
): ITenantContract => {
  if (data.length === 0) throw new Error("NO_CONTRACT_FOUND");
  return parseSingleTenantContract(
    data.reduce((selected, current) =>
      compareAsc(new Date(selected.init_date), new Date(current.init_date)) ===
      1
        ? selected
        : current
    )
  );
};

export const parseTenantContract = (
  data: ContractResponse | ContractResponse[]
): ITenantContract => {
  if (Array.isArray(data)) return parseMultipleTenantContracts(data);
  return parseSingleTenantContract(data);
};

export default parseTenantContract;
