import { BrowserView } from "react-device-detect";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton, Grid } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import useDecisionCallToActionStyles from "./DecisionCallToActionStyles";

interface Props {
  label: string;
  secondaryLabel: string;
  onHandleBackClick: () => void;
  onHandleNextClick: () => void;
  onHandleNextSecondaryClick: () => void;
}

const DecisionCallToAction = ({
  label,
  secondaryLabel,
  onHandleBackClick,
  onHandleNextClick,
  onHandleNextSecondaryClick,
}: Props) => {
  const classes = useDecisionCallToActionStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={6} lg={4} className={classes.ctaContent}>
        <Grid item container justifyContent="flex-end">
          <Grid item xs={12} className={classes.buttonContainer}>
            <DesignSystemButton
              label={label}
              size="small"
              variant="primary"
              type="button"
              onClick={() => onHandleNextClick()}
              buttonClassName={classes.button}
            />
            <DesignSystemButton
              label={secondaryLabel}
              size="small"
              variant="tertiaryB"
              type="button"
              onClick={() => onHandleNextSecondaryClick()}
              buttonClassName={classes.secondaryButton}
            />
          </Grid>
        </Grid>
        <BrowserView>
          <Grid item>
            <IconButton
              onClick={() => onHandleBackClick()}
              className={classes.buttonBack}
            >
              <ArrowBackIcon className={classes.icon} />
            </IconButton>
          </Grid>
        </BrowserView>
      </Grid>
    </Grid>
  );
};

export default DecisionCallToAction;
