import { makeStyles, Theme } from "@material-ui/core";

const useSideMenuStyles = makeStyles((theme: Theme) => ({
  sideMenuRoot: {
    height: "fit-content",
    width: "fit-content",
    marginRight: theme.spacing(3),
    padding: theme.spacing(2),
    borderRadius: 8,
    backgroundColor: "white",
  },
  sideMenuItem: {
    margin: theme.spacing(0.25, 0),
    padding: theme.spacing(0.5, 1),
    cursor: "pointer",
  },
  selectedSideMenuItem: {
    padding: theme.spacing(0.5, 2),
    borderRadius: 2,
    backgroundColor: "#233C46",
    color: "white",
    fontWeight: 700,
  },
}));

export default useSideMenuStyles;
