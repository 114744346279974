import { useTranslation } from "react-i18next";
import LightButton from "@houm-com/ui/LightButton";
import { IoArrowForward as ArrowIcon } from "@houm-com/ui-icons";

import { Box, Typography } from "@material-ui/core";

import { getMarketplaceLink } from "apps/tenant/pages/Visits/components/VisitsLinks/utils";
import { Locale } from "models/Countries";

import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji";
import useNoVisitsStyles from "./NoVisitsStyles";

interface Props {
  market: Locale;
}

const NoVisits = ({ market }: Props) => {
  const classes = useNoVisitsStyles();
  const { t } = useTranslation("tenant.dashboard");

  return (
    <Box className={classes.noVisitsRoot}>
      <Emoji
        className={classes.calendar}
        emojiIcon="📆"
        ariaLabel="calendar icon"
      />
      <Typography className={classes.message}>
        {t("no-visits-message")}
      </Typography>
      <LightButton
        className={classes.search}
        variant="primary"
        size="sm"
        icon={<ArrowIcon className={classes.arrow} />}
        onClick={() => window.open(getMarketplaceLink(market), "_blank")}
      >
        Buscar propiedades
      </LightButton>
    </Box>
  );
};

export default NoVisits;
