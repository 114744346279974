import { FC, SVGProps } from "react";

const SurfaceIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.9213 8.94154L12.5974 7.26543L14.2735 8.94154M12.5974 7.45718V10.9491M14.2735 16.0878L12.5974 17.7639L10.9213 16.0878M12.5974 17.5721V14.0803M16.0917 10.7873L17.766 12.4653L16.0881 14.1396M17.5743 12.4651L14.0824 12.4613M8.94183 14.1317L7.26755 12.4538L8.94549 10.7795M7.4593 12.454L10.9512 12.4578M4.89506 20.5H20.1049C20.3231 20.5 20.5 20.3231 20.5 20.1049V4.89506C20.5 4.67688 20.3231 4.5 20.1049 4.5H4.89506C4.67688 4.5 4.5 4.67687 4.5 4.89506V20.1049C4.5 20.3231 4.67687 20.5 4.89506 20.5Z"
      stroke="#263238"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SurfaceIcon;
