import { Box, Typography } from "@material-ui/core";

import useScheduleFeedback from "../../hooks/useScheduleFeedback";
import useScheduleFeedbackStyles from "./ScheduleFeedbackStyles";
import { ISchedules } from "../../hooks/utils/types";
import Feedback from "../Feedback";

interface Props {
  schedules: ISchedules[];
}

const ScheduleFeedback = ({ schedules }: Props) => {
  const classes = useScheduleFeedbackStyles();
  const { feedbacks } = useScheduleFeedback(schedules);

  return (
    <Box className={classes.feedbackContainer}>
      <Box className={classes.textContainer}>
        <Typography className={classes.title}>Comentarios</Typography>
        <Typography className={classes.subtitle}>
          Aquí podras ver los comentarios que dejen las personas que visitan tu
          propiedad
        </Typography>
      </Box>
      <Feedback schedules={feedbacks} />
    </Box>
  );
};

export default ScheduleFeedback;
