import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";

import { useFormNotifications } from "hooks";
import { ControlledFileUpload } from "Components/Admin/UIComponents/ControlledForm";
import {
  setPropertyDocs,
  stepSubmit,
} from "context/loiFormContext/loiFormActions";
import { useLoiForm } from "context/loiFormContext/loiFormContext";
import fields from "context/loiFormContext/loiFormFields";
import { PropertyDocs } from "context/loiFormContext/loiFormTypes";
import { ALLOWED_MIME_TYPES } from "context/loiFormContext/constants";
import CallToAction from "./components/CustomCallToAction";
import FilteredInput from "./components/FilteredInput";
import { propertyDocsValidationSchema, requiredText } from "./validationSchema";
import LoiFormStepHeader from "./components/LoiFormStepHeader";

export default function PropertyDocsStep() {
  const { state, dispatch } = useLoiForm();
  const stepFields = fields.propertyDocs;

  const methods = useForm<PropertyDocs>({
    resolver: yupResolver(propertyDocsValidationSchema),
    reValidateMode: "onBlur",
  });

  const { errors } = methods.formState;
  useFormNotifications<PropertyDocs>({ errors, requiredText });

  const onSubmit: SubmitHandler<PropertyDocs> = (data) => {
    dispatch(setPropertyDocs({ ...data }));
    stepSubmit(dispatch, state.step, state.availableSteps);
  };

  return (
    <FormProvider {...methods}>
      <LoiFormStepHeader
        title="Documentos"
        description="Carga los documentos e ingresa la información necesaria para hacer la venta."
        subtitle="Documentos"
      />

      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FilteredInput fieldInfo={stepFields.propertyDeed()}>
          <ControlledFileUpload
            id="propertyDeed"
            label={stepFields.propertyDeed().label(state.country)}
            allowedMimeTypes={ALLOWED_MIME_TYPES}
            helperText=""
          />
        </FilteredInput>

        <FilteredInput fieldInfo={stepFields.propertyBill()}>
          <ControlledFileUpload
            id="propertyBill"
            label={stepFields.propertyBill().label(state.country)}
            allowedMimeTypes={ALLOWED_MIME_TYPES}
            helperText="La del año actual"
          />
        </FilteredInput>

        <FilteredInput fieldInfo={stepFields.waterBill()}>
          <ControlledFileUpload
            id="waterBill"
            label={stepFields.waterBill().label(state.country)}
            allowedMimeTypes={ALLOWED_MIME_TYPES}
            helperText="La del año actual"
          />
        </FilteredInput>

        <FilteredInput fieldInfo={stepFields.traditionCertificate()}>
          <ControlledFileUpload
            id="traditionCertificate"
            label={stepFields.traditionCertificate().label(state.country)}
            allowedMimeTypes={ALLOWED_MIME_TYPES}
            helperText="Este documento se puede obtener desde..."
          />
        </FilteredInput>

        <CallToAction />
      </form>
    </FormProvider>
  );
}
