import { ReactNode, createContext, useContext } from "react";
import { useAuth0, Auth0ContextInterface, User } from "@auth0/auth0-react";

const UserContext = createContext<Auth0ContextInterface<User> | undefined>(
  undefined
);

export { User };

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const auth0 = useAuth0();

  return <UserContext.Provider value={auth0}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
