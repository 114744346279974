import { LandlordPropertyProvider } from "context/landlordPropertyContext/landlordPropertyContext";
import LandlordProperty from "./LandlordProperty";

interface Props {
  pageToRender?: string;
}

export default function ({ pageToRender }: Props) {
  return (
    <LandlordPropertyProvider>
      <LandlordProperty pageToRender={pageToRender} />
    </LandlordPropertyProvider>
  );
}
