import { makeStyles, Theme } from "@material-ui/core";

const useNoVisitsCardStyles = makeStyles((theme: Theme) => ({
  noVisitsCardRoot: {
    padding: theme.spacing(3, 2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#ffffff",
  },
  icon: {
    fontSize: theme.typography.pxToRem(32),
  },
  title: {
    margin: theme.spacing(1, 0),
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(26),
    fontWeight: 700,
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
}));

export default useNoVisitsCardStyles;
