import { useCallback } from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core";

import { useEvaluation } from "apps/lead/context/evaluationContext/EvaluationContext";
import {
  handleChangeAnswerDecision,
  setActualStep,
} from "apps/lead/context/evaluationContext/EvaluationActions";

import DecisionCallToAction from "./components/DecisionCallToAction";
import Layout from "./components/DecisionLayout";
import Subtitle from "./components/Subtitle";

const AddFirstCodebtorDecision = () => {
  const theme = useTheme();
  const { state, dispatch } = useEvaluation();
  const { activeStep, steps, codebtorAnswer } = state;
  const desktopView = useMediaQuery(theme.breakpoints.up("md"));

  const handleBack = useCallback(() => {
    setActualStep(activeStep - 1, dispatch);
  }, [steps, activeStep]);

  const handleFinalStep = useCallback(() => {
    handleChangeAnswerDecision(
      {
        ...codebtorAnswer,
        firstCodebtorAnswer: false,
      },
      dispatch
    );
    setActualStep(steps - 1, dispatch);
  }, [steps]);

  const handleNextStep = useCallback(() => {
    handleChangeAnswerDecision(
      {
        ...codebtorAnswer,
        firstCodebtorAnswer: true,
      },
      dispatch
    );
    setActualStep(activeStep + 1, dispatch);
  }, [steps, activeStep]);

  return (
    <>
      <Layout title="Importante" subtitle={<Subtitle />} />
      <DecisionCallToAction
        label="Agregar codeudor"
        secondaryLabel={desktopView ? "Sin codeudor" : "Postular sin codeudor"}
        onHandleBackClick={() => handleBack()}
        onHandleNextClick={() => handleNextStep()}
        onHandleNextSecondaryClick={() => handleFinalStep()}
      />
    </>
  );
};

export default AddFirstCodebtorDecision;
