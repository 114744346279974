import { makeStyles, Theme } from "@material-ui/core/";

const useBasicServicesSelectStyles = makeStyles<Theme>((theme) => ({
  formSectionRequiredField: {
    color: theme.palette.primary.main,
  },
  formSectionLabel: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    lineHeight: 1.4,
    marginBottom: theme.spacing(0.5),
    color: theme.palette.grey[900],
  },
  formSectionTextField: {
    width: "100%",
    borderRadius: 4,
  },
  formSectionSelect: {
    backgroundColor: theme.palette.grey[50],
    paddingLeft: theme.spacing(1),
    borderRadius: 4,
    "&:focus": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  errorMessage: {
    fontSize: theme.typography.pxToRem(10),
    color: "red",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}));

export default useBasicServicesSelectStyles;
