/* eslint-disable react/require-default-props */
import * as React from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { Controller, useFormContext, FieldError } from "react-hook-form";

import { BasicOption } from "models/Option";

import CustomTextField from "Components/Admin/UIComponents/CustomTextField";
import useControlledAutocompleteStyles from "./ControlledAutocompleteStyles";

interface Props {
  id: string;
  label: string;
  labelClassName?: string;
  options: BasicOption[];
  textFieldProps?: Partial<React.ComponentProps<typeof CustomTextField>>;
  inputTagsNode?: (value: BasicOption[]) => React.ReactNode;
  controllerProps?: React.ComponentProps<typeof Controller>;
  required?: boolean;
  errorMessage?: string;
  onChangeAction?: (val) => void;
  defaultValue?: BasicOption | Array<BasicOption>;
  multiple?: boolean;
  emptyOption?: boolean;
}

interface LabelError extends FieldError {
  value: { message: string };
}

const ControlledAutocomplete = ({
  id,
  label,
  options,
  textFieldProps,
  controllerProps,
  onChangeAction,
  inputTagsNode = null,
  labelClassName = "",
  defaultValue = [],
  multiple = false,
  emptyOption = true,
}: Props) => {
  const { control } = useFormContext();
  const classes = useControlledAutocompleteStyles();
  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({
        field: { value = { value: "", label: "" }, onChange },
        fieldState: { error, invalid },
      }) => {
        const optionMessage = error as LabelError;
        return (
          <Autocomplete
            fullWidth
            id={id}
            multiple={multiple}
            defaultValue={defaultValue}
            disabled={textFieldProps?.disabled}
            noOptionsText="Buscar por nombre"
            onChange={(e, newValue) => {
              onChange(newValue);
              if (onChangeAction) {
                onChangeAction(newValue);
              }
            }}
            classes={{
              tag: classes.tag,
            }}
            value={value}
            options={[
              ...(emptyOption ? [{ value: "", label: "" }] : []),
              ...options,
            ]}
            getOptionLabel={(o) => o.label}
            renderTags={inputTagsNode}
            getOptionSelected={(option, v) => option.value === v.value}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                error={invalid}
                label={label}
                id={id}
                labelClassName={labelClassName}
                helperText={error?.message || optionMessage?.value?.message}
                {...textFieldProps}
                inputProps={{
                  ...params.inputProps,
                  ...textFieldProps?.inputProps,
                }}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline:
                    textFieldProps?.InputProps?.disableUnderline,
                  classes: {
                    ...textFieldProps?.InputProps?.classes,
                  },
                }}
              />
            )}
          />
        );
      }}
      {...controllerProps}
    />
  );
};

export default ControlledAutocomplete;
