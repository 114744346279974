import { makeStyles, Theme } from "@material-ui/core";

const usePropertyLandlordRequestStyles = makeStyles<Theme>((theme) => ({
  root: {
    backgroundColor: "white",
  },
  container: {
    padding: theme.spacing(4, 2, 5, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5, 5, 6, 5),
    },
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(1),
      fontSize: theme.typography.pxToRem(28),
    },
  },
  cardsContainer: {
    display: "grid",
    gridTemplateRows: "1fr 1fr",
    gridRowGap: theme.spacing(3),
    gridTemplateColumns: "1fr",
    gridColumnGap: theme.spacing(7),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr",
      marginTop: theme.spacing(4),
      gridRowGap: theme.spacing(2),
    },
  },
  card: {
    backgroundColor: "#EAF1FB",
    width: "fit-content",
    borderRadius: 8,
    padding: theme.spacing(3, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 3),
    },
  },
  cardTitleContainer: {
    display: "flex",
    gap: theme.spacing(1),
    "& svg": {
      color: theme.palette.grey[900],
    },
    marginBottom: theme.spacing(1),
  },
  cardTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  cardDescription: {
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  cardCta: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(4),
    },
  },
}));

export default usePropertyLandlordRequestStyles;
