import { TenantData } from "apps/payments/context/rentPaymentContext/rentPaymentTypes";
import { Locale } from "models/Countries";

const getDefaultDocumentType = (country: Locale, documentType: number) => {
  if (documentType) return documentType;
  if (country === "cl") return 1;
  return null;
};

export const getDefaultData = (country: Locale, tenantData: TenantData) => ({
  documentType: getDefaultDocumentType(country, tenantData?.documentType),
  document: tenantData?.document ?? "",
});
