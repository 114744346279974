import { Box } from "@material-ui/core";

import { IVisit } from "apps/tenant/models/Visit";
import { filterVisits } from "apps/tenant/pages/Visits/utils/utils";

import VisitsCalendar from "../../../VisitsCalendar";
import VisitsList from "../../../VisitsList";
import useCalendarViewStyles from "./CalendarViewStyles";

interface Props {
  selectedDate: Date;
  setSelectedDate: (e: Date) => void;
  visits: IVisit[];
}

const CalendarView = ({ selectedDate, setSelectedDate, visits }: Props) => {
  const classes = useCalendarViewStyles();
  const filteredVisits = filterVisits(visits, selectedDate);

  return (
    <Box className={classes.calendarViewRoot}>
      <VisitsCalendar
        value={selectedDate}
        visits={visits}
        setSelectedDate={setSelectedDate}
      />
      <VisitsList visits={filteredVisits} />
    </Box>
  );
};

export default CalendarView;
