import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useApplicationCardStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    lineHeight: 1.57,
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    display: "block",
  },
  cardContainer: {
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[75]}`,
    width: "100%",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      paddingBottom: 0,
      borderBottom: "none",
      paddingRight: theme.spacing(4),
      borderRight: `1px solid ${theme.palette.grey[75]}`,
      width: "fit-content",
    },
  },
  infoContainer: {
    marginLeft: theme.spacing(1),
  },
}));

export default useApplicationCardStyles;
