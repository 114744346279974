import { isMobile } from "react-device-detect";
import { Box } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

import SelectionStep from "./components/SelectionStep";
import useServicesRequestStyles from "./ServicesRequestStyles";

interface Props {
  handleBack?: () => void;
}

const ServicesRequest = ({ handleBack }: Props) => {
  const classes = useServicesRequestStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.drawerHeader}>
        {!isMobile && (
          <Box className={classes.iconContainer} onClick={handleBack}>
            <Close />
          </Box>
        )}
      </Box>
      <SelectionStep handleBack={handleBack} />
    </Box>
  );
};

export default ServicesRequest;
