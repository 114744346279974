import { makeStyles, Theme } from "@material-ui/core";

const usePropertyPriceStyles = makeStyles<Theme>((theme) => ({
  text: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
}));

export default usePropertyPriceStyles;
