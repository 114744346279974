export const classes = {
  container: "flex flex-col gap-6",
  selectableCard: "items-start !p-4 !min-h-fit !h-auto",
  cardContainer: "w-full",
  infoContainer: "flex flex-row gap-4",
  iconContainer: "h-6 flex justify-center items-center",
  textContainer: "flex flex-col items-start",
  title: "text-p16 font-bold font-['Inter'] text-left",
  subtitle: "text-p16 font-normal !text-black-100 text-left !max-w-[300px]",
  checkIconContainer: "h-6 w-6",
  infoTag: "mt-[-32px]",
};
