import { useState } from "react";

import { Box } from "@material-ui/core";

import { ENABLE_PUBLISH_PROPERTY } from "env";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import usePropertyVisibility from "apps/landlord/hooks/propertyVisibilityStatus/usePropertyVisibility";
import { PropertyBusinessType } from "apps/landlord/models/Property";
import useUnpublishPropertyStyles from "./PropertyStatusManagerButtonStyles";
import PropertyStatusManagerDialog from "./components/PropertyStatusManagerDialog";

export default function PropertyStatusManagerButton() {
  const classes = useUnpublishPropertyStyles();
  const [open, setOpen] = useState(false);
  const [unpublishType, setUnpublishType] =
    useState<PropertyBusinessType | null>(null);

  const {
    isNotPublished,
    isPublishedInOneMode,
    isPublishedInBothModes,
    isPublishedInRentalMode,
    refetchPropertyVisibility,
  } = usePropertyVisibility();

  const toggleUnpublishDialog = (mode: PropertyBusinessType) => {
    setUnpublishType(mode);
    setOpen(true);
  };

  const shouldShowRepublish = ENABLE_PUBLISH_PROPERTY && isNotPublished;

  const handleClose = () => {
    setOpen(false);
    refetchPropertyVisibility();
  };

  return (
    <>
      <Box className={classes.container}>
        {isPublishedInBothModes && (
          <Box className={classes.multiOptionsAvailable}>
            <DesignSystemButton
              label="Despublicar para arriendo"
              size="small"
              variant="secondaryB"
              onClick={() => toggleUnpublishDialog(PropertyBusinessType.RENT)}
            />
            <DesignSystemButton
              label="Despublicar para venta"
              size="small"
              variant="secondaryB"
              onClick={() => toggleUnpublishDialog(PropertyBusinessType.SALE)}
            />
          </Box>
        )}
        {isPublishedInOneMode && (
          <DesignSystemButton
            label="Despublicar propiedad"
            size="small"
            variant="secondaryB"
            onClick={() =>
              toggleUnpublishDialog(
                isPublishedInRentalMode
                  ? PropertyBusinessType.RENT
                  : PropertyBusinessType.SALE
              )
            }
          />
        )}
        {shouldShowRepublish && (
          <Box className={classes.multiOptionsAvailable}>
            <DesignSystemButton
              label="Publicar para arriendo"
              size="small"
              variant="primaryB"
            />
            <DesignSystemButton
              label="Publicar para venta"
              size="small"
              variant="primaryB"
            />
          </Box>
        )}
      </Box>
      <PropertyStatusManagerDialog
        mode={unpublishType}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}
