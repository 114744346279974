import useProperty from "../useProperty";

/**
 * Property Visibility Hook
 * @description This hook is used to check if a property is
 * visible to users through marketplace
 */
const usePropertyVisibility = () => {
  const { propertyData, refetchProperty: refetchPropertyVisibility } =
    useProperty({
      refetchOnMount: true,
    });

  const {
    propertyDetails: { status },
  } = propertyData;
  const isPublished = status === "published" || status === "republished";
  const isPublishedInBothModes =
    isPublished && propertyData.forRental && propertyData.forSale;
  const isPublishedInRentalMode =
    isPublished && propertyData.forRental && !propertyData.forSale;
  const isPublishedInSaleMode =
    isPublished && !propertyData.forRental && propertyData.forSale;
  const isPublishedInOneMode =
    (isPublished && isPublishedInRentalMode) || isPublishedInSaleMode;
  const isNotPublished = !isPublished;

  return {
    refetchPropertyVisibility,
    isPublishedInBothModes,
    isPublishedInRentalMode,
    isPublishedInSaleMode,
    isPublishedInOneMode,
    isNotPublished,
  };
};

export default usePropertyVisibility;
