import { AxiosResponse } from "axios";

import { createHttp } from "services/helper";
import {
  IPhotosBody,
  IPresignedUrlResponse,
  IRepairRequestBody,
} from "./types";

export default {
  createRepairRequest(
    token: string,
    propertyId: number,
    data: IRepairRequestBody
  ): Promise<AxiosResponse> {
    return createHttp(token).post(
      `/properties/v2/${propertyId}/maintenance/request`,
      {
        ...data,
        availabilities: [],
      }
    );
  },

  getPresignedUrl(
    token: string,
    data: IPhotosBody
  ): Promise<AxiosResponse<IPresignedUrlResponse>> {
    return createHttp(token).post(
      "/properties/v2/maintenance/upload-url ",
      data
    );
  },
};
