import { makeStyles, Theme } from "@material-ui/core";

const useHelpTooltipInfoStyles = makeStyles<Theme>((theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 4px 15px rgba(0, 43, 41, 0.15)`,
    color: "black",
    borderRadius: 10,
    fontSize: theme.typography.pxToRem(14),
    padding: theme.spacing(2.5),
    lineHeight: 2,
  },
}));

export default useHelpTooltipInfoStyles;
