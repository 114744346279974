import { Box, Typography } from "@material-ui/core";

import {
  setCurrentNegotiation,
  setCurrentStep,
} from "context/negotiationsContext/negotiationsActions";
import { useNegotiations } from "context/negotiationsContext/negotiationsContext";
import {
  Negotiation,
  Property,
} from "context/negotiationsContext/negotiationsTypes";
import { formatPrice } from "context/negotiationsContext/utils";
import { parsePrice } from "utils";
import useNegotiatonsCardStyles from "./NegotiationCardStyles";

interface Props {
  index: number;
  property: Property;
  negotiation: Negotiation;
}

const NegotiationCard = ({ index, property, negotiation }: Props) => {
  const { dispatch } = useNegotiations();
  const {
    activeOfferAmount,
    activeOfferPaymentMethod,
    activeOfferCurrency,
    activeOfferStatus,
  } = negotiation;
  const classes = useNegotiatonsCardStyles();

  const onClick = () => {
    dispatch(setCurrentStep("negotiationDetails"));
    dispatch(setCurrentNegotiation(negotiation));
  };

  const propertyPrice = () =>
    parsePrice(property.salesPrice, property.salesCurrency);

  return (
    <Box className={classes.negotiationCard} onClick={onClick}>
      <Box className={classes.amountContainer}>
        <Typography>
          {formatPrice(activeOfferAmount, activeOfferCurrency)}
        </Typography>
      </Box>
      <Box className={classes.detailsContainer}>
        <Box className={classes.buyerContainer}>
          <Typography className={classes.buyerNumber}>
            Comprador #{index + 1}
          </Typography>
          {activeOfferStatus === "Abierta" && (
            <Box className={classes.newOffer}>Nueva Oferta</Box>
          )}
        </Box>
        <Box className={classes.offer}>
          <Box className={classes.payment}>
            <Typography className={classes.paymentMethod}>
              {activeOfferPaymentMethod}
            </Typography>
            <Typography className={classes.paymentMethodText}>
              Forma de pago
            </Typography>
          </Box>
          <Box className={classes.priceContainer}>
            <Typography className={classes.priceText}>
              Precio publicado
            </Typography>
            <Typography className={classes.price}>{propertyPrice()}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NegotiationCard;
