/* eslint-disable max-lines */
import { useHistory } from "react-router-dom";

import { Box, Grid, Typography } from "@material-ui/core";

import { IoArrowForward } from "@houm-com/ui-icons";
import LightButton from "@houm-com/ui/LightButton";

import usePropertyPublicationLinks from "apps/landlord/hooks/propertyPublicationLinks";
import { getIsGuaranteedPlan } from "apps/landlord/utils/getIsGuaranteedPlan";
import BackNavigation from "apps/landlord/components/BackNavigation";
import PlanTypeTag from "apps/landlord/components/PlanTypeTag";
import { PaymentOptionType } from "apps/landlord/models/Plan";
import { Photos } from "models/Property";

import PhotoCarousel from "../PhotoCarousel";
import usePhotoStyles from "./PhotoStyles";

interface Props {
  photos: Photos[] | null;
  address: string;
  subAddress: string;
  propertyId: number;
  paymentOptionType: PaymentOptionType;
}

const Photo = ({
  photos,
  address,
  subAddress,
  propertyId,
  paymentOptionType,
}: Props) => {
  const classes = usePhotoStyles();
  const history = useHistory();
  const {
    goToSalePublication,
    goToRentalPublication,
    salePublicationLink,
    rentalPublicationLink,
  } = usePropertyPublicationLinks();

  return (
    <PhotoCarousel photos={photos}>
      <Grid container className={classes.photoContainer}>
        <BackNavigation
          className={classes.backButton}
          iconClassName={classes.backIcon}
          onClick={() => history.push("/landlord")}
          size="md"
        />
        <Box className={classes.photoInfoContainer}>
          {paymentOptionType && (
            <Grid item xs={12}>
              <PlanTypeTag
                isGuaranteed={getIsGuaranteedPlan(paymentOptionType)}
              />
            </Grid>
          )}
          <Grid item container>
            <Grid item xs={12}>
              <Typography component="h1" className={classes.addressStreet}>
                {address}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.address}>{subAddress}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.propertyId}>
                {`ID ${propertyId}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            {salePublicationLink && (
              <Grid item xs={12}>
                <LightButton
                  size="md"
                  variant="primary"
                  className="text-white"
                  onClick={goToSalePublication}
                  icon={<IoArrowForward />}
                  position="end"
                >
                  Ver en Houm.com
                </LightButton>
              </Grid>
            )}
            {rentalPublicationLink && (
              <Grid item xs={12}>
                <LightButton
                  size="md"
                  variant="primary"
                  className="text-white"
                  onClick={goToRentalPublication}
                  icon={<IoArrowForward />}
                  position="end"
                >
                  Ver en Houm.com
                </LightButton>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    </PhotoCarousel>
  );
};

export default Photo;
