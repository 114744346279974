import { Box, Typography } from "@material-ui/core";

import useProfileHeaderStyles from "./ProfileHeaderStyles";
import BackNavigation from "apps/landlord/components/BackNavigation/BackNavigation";
import { useHistory } from "react-router-dom";

const ProfileHeader = () => {
  const classes = useProfileHeaderStyles();
  const history = useHistory();

  return (
    <Box className={classes.profileHeaderRoot}>
      <BackNavigation
        className={classes.back}
        onClick={() => history.push("/")}
      />
      <Typography variant="h1" className={classes.title}>
        Mi perfil
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        Encuentra tu información personal, de contacto y edítala desde aquí.
      </Typography>
    </Box>
  );
};

export default ProfileHeader;
