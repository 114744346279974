import { useTranslation } from "react-i18next";

import EmailForm from "../../../EmailForm";
import MobileHeader from "../MobileHeader/MobileHeader";

interface Props {
  email: string;
}

const EmailMobile = ({ email }: Props) => {
  const { t } = useTranslation("account.profile");
  return (
    <>
      <MobileHeader title="Correo electrónico" subtitle={t("email_text")} />
      <EmailForm email={email} />
    </>
  );
};

export default EmailMobile;
