import { FormProvider, SubmitHandler } from "react-hook-form";

import Spinner from "@houm-com/ui/Spinner";

import { userFormSchema } from "apps/lead/pages/EvaluatorForm/components/ApplicantForm/utils/validationSchema";
import { getParseApplicant } from "apps/lead/pages/EvaluatorForm/components/ApplicantForm/utils/parsers";
import { getDefaultValues } from "apps/lead/pages/EvaluatorForm/components/ApplicantForm/utils/utils";
import { classes } from "apps/lead/pages/EvaluatorForm/components/ApplicantForm/ApplicantFormStyles";
import { IApplicantForm } from "apps/lead/pages/EvaluatorForm/components/ApplicantForm/utils/types";
import {
  setPage,
  setUserInformation,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper/FormWrapper";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { EvaluatorPages } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import ApplicantFields from "apps/lead/components/EvaluatorForm/ApplicantFields";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import useDocumentTypeListPublic from "hooks/useDocumentTypeListPublic";
import useReactHookForm from "hooks/useReactHookForm";

const ApplicantForm = () => {
  const {
    state: { applicants, user: userInformation, country },
    dispatch,
  } = useEvaluation();
  const userList = applicants.filter(
    (applicant) => applicant.user?.email !== userInformation?.user?.email
  );
  const emailList = userList.map((applicant) => applicant.user.email);

  const { data, isSuccess, isError, isLoading } =
    useDocumentTypeListPublic(country);
  const { methods } = useReactHookForm<IApplicantForm>({
    formSchema: userFormSchema(data, emailList),
    formOptions: {
      defaultValues: getDefaultValues(userInformation?.user, country),
    },
  });

  const onSubmit: SubmitHandler<IApplicantForm> = (data) => {
    const parsedData = getParseApplicant(data);
    dispatch(
      setUserInformation({
        ...userInformation,
        user: parsedData,
      })
    );
    dispatch(setPage(EvaluatorPages.SELFIE_DISCLAIMER));
  };

  return (
    <FormProvider {...methods}>
      <Header title="Información básica" />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper>
          {isError && (
            <p className={classes.applicantFormErrorText}>
              Ocurrió un error intentando cargar formulario
            </p>
          )}
          {isLoading && (
            <Spinner
              variant="primary"
              size="xl"
              classNameContainer={classes.applicantFormErrorText}
            />
          )}
          {isSuccess && (
            <ApplicantFields
              country={country}
              documentList={data}
              // emailDisabled={userInformation.extraInformation.role === "main"}
            />
          )}
        </FormWrapper>
        <CallToAction />
      </form>
    </FormProvider>
  );
};

export default ApplicantForm;
