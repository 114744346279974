import { IEmploymentForm } from "apps/lead/pages/EvaluatorForm/components/EmployerForm/utils/types";
import { UserApplicant } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

export const parseEmploymentData = (
  userInformation: Partial<UserApplicant>,
  data: IEmploymentForm
) => ({
  ...userInformation,
  employment: {
    ...userInformation.employment,
    employer: data.employer,
    employerPhone: data.phone.parsedPhone,
    occupation: data.occupation,
  },
});
