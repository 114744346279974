import { Grid } from "@material-ui/core";

import usePropertyContentStyles from "./PropertyContentStyles";
import { Option, IProperty } from "../../../../utils/types";
import PropertyAddress from "../../../PropertyAddress";
import getAddress from "../../../../utils/utils";
import OptionList from "../../../OptionList";

interface Props {
  property: IProperty;
  optionList?: Option[];
}

const PropertyContent = ({ property, optionList = [] }: Props) => {
  const classes = usePropertyContentStyles();
  const showOptionListFlag = optionList.length > 0;

  return (
    <Grid item xs={12} container justifyContent="space-between">
      <Grid item container xs direction="column">
        <PropertyAddress property={getAddress(property)} />
      </Grid>
      {showOptionListFlag && (
        <Grid
          container
          item
          xs
          justifyContent="flex-end"
          className={classes.optionsButton}
        >
          <OptionList options={optionList} />
        </Grid>
      )}
    </Grid>
  );
};

export default PropertyContent;
