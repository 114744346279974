import { PropertyDetails } from "models";

enum ColombiaLocality {
  Antioquia = "Antioquia",
  Bogota = "Bogotá",
}

enum ColombiaAdminAreaLevel1 {
  Cundinamarca = "Cundinamarca",
}

enum MexicoAdminAreaLevel1 {
  Jalisco = "Jalisco",
}

enum AddressComponent {
  Country = "country",
  StreetNumber = "street_number",
  Route = "route",
  Intersection = "intersection",
  Neighborhood = "neighborhood",
  Sublocality = "sublocality",
  Locality = "locality",
  AdminArea3 = "administrative_area_level_3",
  AdminArea2 = "administrative_area_level_2",
  AdminArea1 = "administrative_area_level_1",
}

/** Small domain based utils */
const pickThirdPartOfListedString = (listedString) => {
  const thirdPart = listedString.split(",")[2];
  const sanitizedThirdPart = thirdPart?.replace(/[0-9]/g, "").trim();
  return sanitizedThirdPart;
};

export const formatAddress = (sgs) => {
  // sgs documentation
  // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes

  const addressProp = (prop) =>
    sgs.address_components.find((item) => item.types.includes(prop));

  const googleCountry: countries = addressProp(
    AddressComponent.Country
  ).long_name;
  const sanitizedCountry = googleCountry?.replace("é", "e");
  const googleStreetNumber = addressProp(AddressComponent.StreetNumber);
  const googleRoute = addressProp(AddressComponent.Route);
  const googleIntersection = addressProp(AddressComponent.Intersection);
  const googleNeighborhood = addressProp(AddressComponent.Neighborhood);
  const googleSublocality = addressProp(AddressComponent.Sublocality);
  const googleLocality = addressProp(AddressComponent.Locality);
  const googleAdminArea3 = addressProp(AddressComponent.AdminArea3);
  const googleAdminArea2 = addressProp(AddressComponent.AdminArea2);
  const googleAdminArea1 = addressProp(AddressComponent.AdminArea1);

  const googleFormattedAddress = sgs.formatted_address;

  /** Chile comune and region */
  const chileanCommune = googleAdminArea3 || googleLocality;

  /** Colombia comune and region */
  const COLOMBIAN_COMMUNE_BY_ADMIN_AREA_LEVEL_2 = {
    [ColombiaLocality.Antioquia]: googleLocality,
  };

  const COLOMBIAN_COMMUNE_FALLBACK = googleSublocality || googleLocality;

  const colombianCommune =
    COLOMBIAN_COMMUNE_BY_ADMIN_AREA_LEVEL_2[googleAdminArea2?.long_name] ||
    COLOMBIAN_COMMUNE_FALLBACK;

  const isOnCundinamarca =
    googleAdminArea1?.long_name === ColombiaAdminAreaLevel1.Cundinamarca;
  const isOnBogota = googleLocality?.long_name === ColombiaLocality.Bogota;

  const colombianRegion =
    isOnCundinamarca && isOnBogota ? googleLocality : googleAdminArea1;

  /** Mexico comune and region */
  const mexicanCommune =
    googleAdminArea1?.long_name !== MexicoAdminAreaLevel1.Jalisco
      ? { long_name: pickThirdPartOfListedString(googleFormattedAddress) }
      : googleLocality;

  /** Commune and region by country */
  const LOCATION_VALUES_BY_COUNTRY = {
    [countries.CL]: {
      commune: chileanCommune,
      region: googleAdminArea1,
      neighborhood: googleNeighborhood,
    },
    [countries.CO]: {
      commune: colombianCommune,
      region: colombianRegion,
      neighborhood: googleNeighborhood,
    },
    [countries.MX]: {
      commune: mexicanCommune,
      region: googleAdminArea1,
      neighborhood: googleSublocality,
    },
  };

  const rawAddress = {
    city: googleAdminArea2,
    country: googleCountry,
    street: googleRoute || googleIntersection,
    streetNumber: googleStreetNumber,
    region: LOCATION_VALUES_BY_COUNTRY[sanitizedCountry].region,
    commune: LOCATION_VALUES_BY_COUNTRY[sanitizedCountry].commune,
    neighborhood: LOCATION_VALUES_BY_COUNTRY[sanitizedCountry].neighborhood,
  };

  // Check if lat / lng are callable, if yes, calls method to get position
  const lat =
    typeof sgs?.geometry?.location?.lat === "function"
      ? sgs.geometry.location.lat()
      : sgs?.geometry?.location?.lat;
  const lng =
    typeof sgs?.geometry?.location?.lng === "function"
      ? sgs.geometry.location.lng()
      : sgs?.geometry?.location?.lng;

  const parsedAddress = {
    prevSearch: sgs.formatted_address,
    neighborhood: rawAddress.neighborhood?.long_name || "",
    city: rawAddress.city?.long_name || "",
    street: rawAddress.street?.long_name || "",
    streetNumber: rawAddress.streetNumber?.long_name || "",
    commune: rawAddress.commune?.long_name || "",
    region: rawAddress.region?.long_name || "",
    country: rawAddress.country,
    lat,
    lng,
    latitude: lat,
    longitude: lng,
  };

  return parsedAddress;
};

export enum countries {
  CO = "Colombia",
  CL = "Chile",
  MX = "Mexico",
}

export const propertyFurnishedOptions: {
  label: string;
  value: PropertyDetails["furnished"];
}[] = [
  { label: "Amoblado", value: "fully" },
  { label: "Semi-amoblado", value: "partially" },
  { label: "No amoblado", value: "non" },
];
