import { FormProvider, SubmitHandler } from "react-hook-form";

import FieldWrapper from "@houm-com/ui/FieldWrapper";
import InputFile from "@houm-com/ui/InputFileBase";

import { retiredFilesSchema } from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/components/co/RetiredForm/utils/validationSchema";
import { IRetiredFilesForm } from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/components/co/RetiredForm/utils/types";
import { allowedMimeTypes } from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/utils/constants";
import { resetIncomeData } from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/utils/utils";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { UserApplicant } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import useReactHookForm from "hooks/useReactHookForm";

interface Props {
  onFinishSubmit?: (data: Partial<UserApplicant>) => void;
}

const RetiredForm = ({ onFinishSubmit }: Props) => {
  const {
    state: { user: userInformation },
  } = useEvaluation();
  const { methods, errors } = useReactHookForm<IRetiredFilesForm>({
    formSchema: retiredFilesSchema,
  });
  const { setValue, getValues } = methods;

  const onSubmit: SubmitHandler<IRetiredFilesForm> = (data) => {
    onFinishSubmit?.({
      ...userInformation,
      incomeValidationFiles: {
        ...resetIncomeData(),
        pensionSalarySettlements: data.pensionSalarySettlements,
        bankStatements: data.bankStatements,
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <FieldWrapper
          id="pensionSalarySettlements"
          label="Últimas 3 liquidaciones de pensión"
          required
          errorMessage={
            errors.pensionSalarySettlements?.message as unknown as string
          }
        >
          <div className="flex flex-col gap-4">
            {[...Array(3).keys()].map((id) => (
              <InputFile
                key={id}
                id={`pensionSalarySettlements${id + 1}`}
                allowedMimeTypes={allowedMimeTypes}
                classNameInputButton="!w-full"
                splitLength={40}
                form="novalidateform"
                file={
                  userInformation.incomeValidationFiles
                    ?.pensionSalarySettlements?.[
                    `pensionSalarySettlements${id + 1}`
                  ]
                }
                onLoadFile={(e) => {
                  const salaryObject = {
                    ...getValues("pensionSalarySettlements"),
                    [`pensionSalarySettlements${id + 1}`]: e,
                  };
                  setValue("pensionSalarySettlements", salaryObject);
                }}
              />
            ))}
          </div>
        </FieldWrapper>
        <FieldWrapper
          id="bankStatements"
          label="Últimos 3 extractos bancarios"
          required
          errorMessage={errors.bankStatements?.message as unknown as string}
        >
          <div className="flex flex-col gap-4">
            {[...Array(3).keys()].map((id) => (
              <InputFile
                key={id}
                id={`bankStatements${id + 1}`}
                allowedMimeTypes={allowedMimeTypes}
                classNameInputButton="!w-full"
                splitLength={40}
                form="novalidateform"
                file={
                  userInformation.incomeValidationFiles?.bankStatements?.[
                    `bankStatements${id + 1}`
                  ]
                }
                onLoadFile={(e) => {
                  const bankObject = {
                    ...getValues("bankStatements"),
                    [`bankStatements${id + 1}`]: e,
                  };
                  setValue("bankStatements", bankObject);
                }}
              />
            ))}
          </div>
        </FieldWrapper>
        <CallToAction label="Continuar" />
      </form>
    </FormProvider>
  );
};

export default RetiredForm;
