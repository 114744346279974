import { ReactNode } from "react";

import Tag from "./components/Tag";
import TagType from "./types";
import getColors from "./utils";

interface DSTagProps {
  type: TagType;
  text: string;
  tagClassName?: {
    wrapperClassName?: string;
    textClassName?: string;
  };
  bordered?: boolean;
  icon?: ReactNode;
}

const DSTag = ({ type, text, tagClassName, bordered, icon }: DSTagProps) => {
  const colors = getColors(type);
  return (
    <Tag
      colors={colors}
      text={text}
      className={tagClassName}
      bordered={bordered}
      icon={icon}
    />
  );
};

export default DSTag;
