import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Box, Typography } from "@material-ui/core";

import useContract from "apps/tenant/hooks/useContract";
import TenantLayout from "apps/tenant/layout/TenantLayout";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji/Emoji";
import Spinner from "Components/Shared/partials/Spinner";

import useExitVisitSummaryStyles from "./ExitVisitSummaryStyles";
import { formatScheduledDate, formatContractInitDate } from "./utils/utils";
import FormattedTranslation from "./components/FormattedTranslation";

const ExitVisitSummary = () => {
  const classes = useExitVisitSummaryStyles();
  const history = useHistory();
  const { t } = useTranslation("tenant.exit-schedule.summary");
  const { isLoading: contractLoading, data } = useContract();

  if (contractLoading) return <Spinner />;

  return (
    <TenantLayout>
      <Box className={classes.summaryRoot}>
        <Typography className={classes.title}>
          Agendaste el horario de tu salida con éxito
        </Typography>
        <FormattedTranslation
          text={t("subtitle")}
          values={{
            scheduled_date: formatScheduledDate({
              date: new Date("2023-04-21T10:00:00"),
            }),
          }}
        />
        <Emoji emojiIcon="💌" ariaLabel="love letter" />

        <Typography className={classes.subtitle}>
          {`1. ${t("first-section-title")} `}
          <Emoji emojiIcon="📝" ariaLabel="pen and paper" />
        </Typography>
        <FormattedTranslation text={t("first-section-content")} />

        <Typography className={classes.subtitle}>
          {`2. ${t("second-section-title")} `}
          <Emoji emojiIcon="📆" ariaLabel="calendar" />
        </Typography>
        <FormattedTranslation
          text={t("second-section-content")}
          values={{
            contract_init_date: formatContractInitDate({
              date: data.initDate,
            }),
          }}
        />

        <Typography className={classes.subtitle}>
          {`3. ${t("third-section-title")} `}
          <Emoji emojiIcon="🚪" ariaLabel="door" />
        </Typography>
        <FormattedTranslation text={t("third-section-content")} />

        <Typography className={classes.subtitle}>
          {`4. ${t("fourth-section-title")} `}
          <Emoji emojiIcon="🏡" ariaLabel="house with tree" />
        </Typography>
        <FormattedTranslation text={t("fourth-section-content")} />

        <Typography className={classes.subtitle}>
          {`5. ${t("fifth-section-title")} `}
          <Emoji emojiIcon="📦" ariaLabel="cardbox" />
        </Typography>
        <FormattedTranslation text={t("fifth-section-content")} />

        <Typography className={classes.subtitle}>
          {`6. ${t("sixth-section-title")} `}
          <Emoji emojiIcon="🔑" ariaLabel="key" />
        </Typography>
        <FormattedTranslation text={t("sixth-section-content")} />

        <Typography className={classes.subtitle}>
          {`7. ${t("seventh-section-title")} `}
          <Emoji emojiIcon="💵" ariaLabel="money" />
        </Typography>
        <FormattedTranslation text={t("seventh-section-content")} />

        <DesignSystemButton
          onClick={() => history.push("/tenant/help")}
          buttonClassName={classes.exitButton}
          label="Salir"
          size="small"
          variant="primary"
        />
      </Box>
    </TenantLayout>
  );
};

export default ExitVisitSummary;
