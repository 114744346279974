import { ReactNode } from "react";
import { isMobile } from "react-device-detect";

import { Grid, Typography } from "@material-ui/core";
import useDetailsBooleanElementStyles from "./DetailsBooleanElementStyles";

interface Props {
  icon?: ReactNode;
  text: string;
  typographyComponent?: "li" | "p";
}

export default function DetailBooleanElement({
  icon,
  text,
  typographyComponent = "p",
}: Props) {
  const classes = useDetailsBooleanElementStyles();
  const mobileText = text.split("/")[0];
  return (
    <Grid sm={3} xs={6} className={classes.informationElement} item>
      {icon ?? icon}
      <Typography
        component={typographyComponent}
        className={classes.informationText}
      >
        {isMobile ? mobileText : text}
      </Typography>
    </Grid>
  );
}
