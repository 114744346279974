import { Grid } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import DogsImage from "assets/images/adminTenant/dogs-error.jpg";
import Header from "Components/Layout/GlobalHeader";
import useStyles from "./BrowserViewStyles";
import ErrorText from "../ErrorText";

interface Props {
  onHandleClick: () => void;
}

const BrowserView = ({ onHandleClick }: Props) => {
  const classes = useStyles();

  return (
    <>
      <Header />
      <Grid
        container
        className={classes.contentRoot}
        justifyContent="space-between"
      >
        <Grid
          item
          container
          xs={6}
          justifyContent="center"
          className={classes.contentRightContainer}
        >
          <Grid item className={classes.textContainer}>
            <ErrorText />
            <div className={classes.buttonContainer}>
              <DesignSystemButton
                label="Recargar la página"
                type="button"
                size="small"
                variant="primary"
                onClick={onHandleClick}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <img src={DogsImage} alt="error" className={classes.image} />
        </Grid>
      </Grid>
    </>
  );
};

export default BrowserView;
