import DefaultTenantLayout from "apps/lead/layout/DefaultTenantLayout";
import UploadFilesForm from "./UploadFilesForm";

export default function () {
  return (
    <DefaultTenantLayout>
      <UploadFilesForm />
    </DefaultTenantLayout>
  );
}
