import { useState } from "react";
import { isDesktop } from "react-device-detect";
import { useHistory, useRouteMatch } from "react-router-dom";

import usePropertyEndOfContract from "apps/landlord/hooks/propertyEndOfContract";
import { EoCLeavingDateForm } from "apps/landlord/hooks/propertyEndOfContract/utils";
import { useProperty } from "apps/landlord/hooks";

import EndOfContractUI from "./components/EndOfContractUI/EndOfContractUI";

const EndOfContractController = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { propertyData } = useProperty();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [leavingData, setLeavingData] = useState<EoCLeavingDateForm>(
    {} as EoCLeavingDateForm
  );
  const {
    isLoadingEndOfContractTarget,
    isLoadingEndOfContractStatus,
    isLoadingSubmitEndOfContract,
    isSuccessSubmitEndOfContract,
    submitEndOfContract,
    endOfContractStatus,
    endOfContractTarget,
  } = usePropertyEndOfContract({
    propertyId: propertyData?.id,
  });
  const helpURL = match.url.replace("/end-contract", "");
  const goToPropertyHelp = () => history.push(helpURL);
  const endOfContractInProcess = endOfContractStatus?.status === "IN_PROCESS";
  const isLoading =
    isLoadingEndOfContractTarget || isLoadingEndOfContractStatus;
  const goToNextStep = () => setCurrentIndex(currentIndex + 1);
  const goToPrevStep = () => {
    if (currentIndex === 0) {
      goToPropertyHelp();
      return;
    }
    setCurrentIndex(currentIndex - 1);
  };

  const handleSubmitDate = (data: EoCLeavingDateForm) => {
    setLeavingData(data);
    if (!isDesktop) {
      setOpenConfirmation(true);
      return;
    }
    goToNextStep();
  };

  const handleNextFirstStep = endOfContractInProcess
    ? goToPropertyHelp
    : goToNextStep;

  const handleSubmitEoC = (formData) => {
    submitEndOfContract(formData, {
      onSuccess: () => {
        setOpenConfirmation(false);
        goToPropertyHelp();
      },
    });
  };

  return (
    <EndOfContractUI
      leavingData={leavingData}
      isLoading={isLoading}
      openConfirmation={openConfirmation}
      goToNextStep={goToNextStep}
      goToPrevStep={goToPrevStep}
      goToPropertyHelp={goToPropertyHelp}
      handleSubmitEoC={handleSubmitEoC}
      setOpenConfirmation={setOpenConfirmation}
      handleNextFirstStep={handleNextFirstStep}
      endOfContractStatus={endOfContractStatus}
      endOfContractTarget={endOfContractTarget}
      endOfContractInProcess={endOfContractInProcess}
      handleSubmitDate={handleSubmitDate}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
      isLoadingSubmitEndOfContract={isLoadingSubmitEndOfContract}
      isSuccessSubmitEndOfContract={isSuccessSubmitEndOfContract}
    />
  );
};

export default EndOfContractController;
