import { makeStyles, Theme } from "@material-ui/core";

const useBottomNavbarStyles = makeStyles((theme: Theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    backgroundColor: "#fff",
  },
  root: {
    width: "100%",
    height: 63,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.15)",
    "& .MuiBottomNavigationAction-root.Mui-selected": {
      borderTop: `2px solid ${theme.palette.primary.main}`,
      paddingTop: 0,
      "& svg": {
        "& path": {
          stroke: theme.palette.primary.main,
        },
      },
    },
    "& .MuiBottomNavigationAction-label.Mui-selected": {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 700,
    },
    "& .MuiBottomNavigationAction-label": {
      fontSize: theme.typography.pxToRem(10),
    },
  },
  rootAction: {
    borderTop: "2px solid transparent",
    color: theme.palette.grey["A400"],
    padding: 0,
    "& svg": {
      "& path": {
        stroke: theme.palette.grey["A400"],
      },
      width: "auto",
    },
  },
  selected: {
    color: theme.palette.primary.main,
  },
}));

export default useBottomNavbarStyles;
