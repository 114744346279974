import { makeStyles, Theme } from "@material-ui/core";

const useMobileCardApplicantDetailsStyles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    display: "block",
  },
}));

export default useMobileCardApplicantDetailsStyles;
