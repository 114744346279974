import { makeStyles, Theme } from "@material-ui/core";

const useBankAccountInfoStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "100%",
    backgroundColor: "#F7F9F9",
    padding: theme.spacing(2),
    borderRadius: 8,
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    color: "#7D8488",
    lineHeight: 1.57,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
  value: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
  valueBankNumber: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
  valueMobile: {
    fontSize: theme.typography.pxToRem(14),
    color: "#7D8488",
    lineHeight: 1.57,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
  mobileContainerInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  linkArrow: {
    cursor: "pointer",
    display: "flex",
    width: 150,
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      width: 175,
    },
  },
  linkText: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    padding: theme.spacing(0),
    lineHeight: 1.57,
    fontWeight: 700,
    marginRight: theme.spacing(1.75),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
  arrow: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
}));

export default useBankAccountInfoStyles;
