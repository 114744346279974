import ErrorIcon from "@material-ui/icons/ErrorOutline";
import SuccessIcon from "@material-ui/icons/CheckCircleOutline";
import WarningIcon from "@material-ui/icons/ReportProblemOutlined";

import { SnackbarType } from "../utils";

interface SnackbarIconProps {
  className: string;
  type: SnackbarType;
}

const SnackbarIcon = ({ className, type }: SnackbarIconProps) => {
  switch (type) {
    case "success":
      return <SuccessIcon className={className} />;
    case "error":
      return <ErrorIcon className={className} />;
    case "warning":
      return <WarningIcon className={className} />;
    default:
      return null;
  }
};

export default SnackbarIcon;
