import { Box, Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { setCurrentStep } from "context/negotiationsContext/negotiationsActions";
import { useNegotiations } from "context/negotiationsContext/negotiationsContext";
import { termsAndConditionsText } from "context/negotiationsContext/constants";
import useTermsAndConditionsStyles from "./TermsAndConditionsStyles";

const TermsAndConditions = () => {
  const { state, dispatch } = useNegotiations();
  const { countryCode } = state;
  const classes = useTermsAndConditionsStyles();

  const onClick = () => {
    dispatch(setCurrentStep("resumeOffer"));
  };

  return (
    <Grid container>
      <Typography className={classes.titleGeneral}>
        Términos y condiciones
      </Typography>
      <Grid container item justifyContent="center">
        <Grid item xs={12}>
          <Typography className={classes.terms} paragraph={true}>
            {termsAndConditionsText[countryCode]}
          </Typography>
          <Box className={classes.container}>
            <DesignSystemButton
              variant="primary"
              onClick={onClick}
              label="Volver"
              size="medium"
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TermsAndConditions;
