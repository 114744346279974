import { format } from "date-fns";
import { es } from "date-fns/locale";

interface FormatDateProps {
  date: Date;
}

export const formatScheduledDate = ({ date }: FormatDateProps) =>
  format(date, "d 'de' MMMM 'a las' h:mm bbb", { locale: es });

export const formatContractInitDate = ({ date }: FormatDateProps) =>
  format(date, "d 'de' MMMM 'del' y", { locale: es });
