import { ChangeEvent, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { format, validate } from "rut.js";

import useTermsData from "./queries/useTermsData";
import useAcceptTerms from "./queries/useAcceptTerms";
import { BASIC_TERMS_LINK, PREMIUM_TERMS_LINK } from "./constants";

const useTermsAndConditions = () => {
  const { token } = useParams<{ token: string }>();
  const [document, setDocument] = useState("");
  const [inputError, setInputError] = useState("");

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDocument(format(e.target.value));
  };

  const {
    submitData,
    isLoading: isAcceptLoading,
    isSuccess,
  } = useAcceptTerms({
    token,
    document,
  });
  const {
    data: termsData,
    isLoading: isDataLoading,
    isAccepted,
  } = useTermsData({ token });

  const termsLink = useMemo(() => {
    if (!termsData?.category) return undefined;
    return termsData?.category === "basic"
      ? BASIC_TERMS_LINK
      : PREMIUM_TERMS_LINK;
  }, [termsData?.category]);

  const onSubmit = () => {
    if (!validate(document)) {
      setInputError("Debes ingresar un RUT válido");
      return;
    }
    setInputError("");
    submitData();
  };

  return {
    isAccepted: isAccepted || isSuccess,
    termsLink,
    document,
    inputError,
    onInputChange,
    onSubmit,
    isAcceptLoading,
    isDataLoading,
  };
};

export default useTermsAndConditions;
