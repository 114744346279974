import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const Card = ({ children }: Props) => (
  <div
    data-testid="card"
    className="bg-white rounded-lg p-6 gap-6 flex flex-col justify-between"
  >
    {children}
  </div>
);

export default Card;
