import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useApplicantEvaluationStyles = makeStyles((theme: Theme) => ({
  applicantEvaluationContainer: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    alignItems: "center",
    margin: theme.spacing(2, "auto"),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      margin: theme.spacing(5, "auto"),
    },
  },
  buttonContainer: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: 0,
      position: "static",
      justifyContent: "center",
    },
  },
  button: {
    "&:hover": {
      backgroundColor: "#FFC9C2",
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up(defaultBreakpoint)]: {
      width: "fit-content",
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(1),
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
    fontWeight: 700,
    textAlign: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      width: "100%",
    },
  },
  contentContainer: {
    margin: theme.spacing(2, "auto", 3),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      margin: theme.spacing(5, "auto", 4),
    },
  },
}));

export default useApplicantEvaluationStyles;
