import { makeStyles, Theme } from "@material-ui/core/";

const usePropertyDetailsTitleBannerStyles = makeStyles<Theme>((theme) => ({
  detailsImgContainer: {
    position: "relative",
    width: "100%",
    height: 136,
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "4px 4px 0px 0px",
    },
  },
  detailsImg: {
    position: "absolute",
    width: "100%",
    height: "auto",
    transform: "translate(0, -50%)",
  },
  detailsImgGradient: {
    width: "100%",
    height: "100%",
    position: "absolute",
    background:
      "linear-gradient(89.96deg, rgba(38, 50, 56, 0.7) 27.1%, rgba(0, 0, 0, 0) 82.82%)",
  },
  detailsBackButton: {
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    color: "white",
    borderRadius: "100px",
    position: "absolute",
    height: 32,
    width: 32,
    top: 16,
    left: 32,
    transition: "background 0.15s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(200, 200, 200, 0.4)",
    },
  },
  detailsBackButtonIcon: {
    width: 16,
    margin: theme.spacing(0.5, 0, 0, 1),
  },
  detailsTitleContainer: {
    position: "absolute",
    bottom: 16,
    left: 32,
  },
  detailsTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    color: "white",
    lineHeight: 1.4,
  },
  detailsSubtitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    color: theme.palette.grey[75],
    lineHeight: 1.5,
  },
  WarningBannerContainer: {
    display: "flex",
    flexDirectioin: "row",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#DF9B99",
    padding: theme.spacing(1),
    paddingLeft: 0,
    [theme.breakpoints.up("sm")]: {
      borderRadius: "0px 0px 4px 4px",
    },
  },
  WarningBannerText: {
    color: "#900000",
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 700,
    lineHeight: 1.8,
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(12),
      margin: "auto 0",
    },
  },
  WarningBannerIcon: {
    color: "#900000",
    margin: theme.spacing(0, 0.5, 0, 2),
    width: 20,
    height: 20,
  },
}));

export default usePropertyDetailsTitleBannerStyles;
