import { UseQueryResult, useQuery } from "react-query";

import { IBank } from "apps/tenant/models/BankAccount";
import bankAccountService from "apps/tenant/services/bankAccountsService";
import { useGetToken } from "hooks";
import { Locale } from "models/Countries";

import { parseBankAccounts } from "./parsers";

const useGetBanks = ({ market }: { market: Locale }) => {
  const { getToken } = useGetToken();

  const { data, isLoading, isError }: UseQueryResult<IBank[], Error> = useQuery<
    IBank[],
    Error
  >(
    ["banksList", market],
    async () => {
      const authToken = await getToken();
      const response = await bankAccountService.getBanks(authToken, market);
      return parseBankAccounts(response.data);
    },
    {
      enabled: !!market,
    }
  );

  return {
    data,
    isLoading,
    isError,
  };
};

export default useGetBanks;
