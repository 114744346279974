import { makeStyles } from "@material-ui/core";

const useHelpWidgetTriggerStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: 54,
    right: 24,
    zIndex: 9999,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.typography.body1.color,
    width: 40,
    height: 40,
    borderRadius: "50%",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "all 0.1s ease-in-out",
    "&:hover": {
      filter: "brightness(1.4)",
      transform: "translateY(-1px)",
    },
    [theme.breakpoints.up("sm")]: {
      bottom: 24,
    },
  },
}));

export default useHelpWidgetTriggerStyles;
