import { Locale } from "models/Countries";

interface ICardText {
  title: string;
  description: string;
  ctaText: string;
}

export const LOCAL_BASED_CONTRACT_END_CARD_TEXTS: Record<Locale, ICardText> = {
  cl: {
    title: "Finaliza tu contrato de arriendo",
    description:
      "Para dar término a tu contrato de arriendo, contesta las siguientes preguntas y así podremos coordinar la salida de la propiedad.",
    ctaText: "Finalizar contrato",
  },
  co: {
    title: "Finaliza tu contrato de arriendo",
    description:
      "Para dar término a tu contrato de arriendo, contesta las siguientes preguntas y así podremos coordinar la salida de la propiedad.",
    ctaText: "Finalizar contrato",
  },
  mx: {
    title: "Finaliza tu contrato de renta",
    description:
      "Para dar término a tu contrato de renta, contesta las siguientes preguntas y así podremos coordinar la salida del inmueble.",
    ctaText: "Finalizar contrato",
  },
};

export const LOCAL_BASED_REPAIRS_CARD_TEXTS: Record<Locale, ICardText> = {
  cl: {
    title: "Reparaciones",
    description:
      "Si tienes algún problema con plomería, calefacción, limpieza, estructurales o cualquier otro problema que te impida disfrutar de tu hogar. Pide el arreglo aquí.",
    ctaText: "Solicitar reparación",
  },
  co: {
    title: "Reparaciones",
    description:
      "Si tienes algún problema con plomería, calefacción, limpieza, estructurales o cualquier otro problema que te impida disfrutar de tu hogar. Pide el arreglo aquí.",
    ctaText: "Solicitar reparación",
  },
  mx: {
    title: "Reparaciones",
    description:
      "Si tienes algún problema con plomería, aires acondicionados, limpieza, estructurales o cualquier otro problema que te impida disfrutar de tu hogar. Pide el arreglo aquí.",
    ctaText: "Solicitar reparación",
  },
};

export const LOCAL_BASED_KUSTOMER_FORMS: Record<Locale, string> = {
  cl: "https://houm.kustomer.help/es/contact/otras-solicitudes-aa-B1ZBYkhSh",
  co: "https://houm.kustomer.help/es/contact/otras-solicitudes-aa-B1ZBYkhSh",
  mx: "https://houm.kustomer.help/es/contact/otras-solicitudes-aa-B1ZBYkhSh",
};
