import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export default {
  initialize: (dsn: string) => {
    Sentry.init({
      dsn,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.03,
    });
  },
};
