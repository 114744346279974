import clsx from "clsx";
import { Button } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import useBackButtonStyles from "./BackButtonStyles";

interface Props {
  className?: string;
  onClickHandler: () => void;
}

const BackButton = ({ className, onClickHandler }: Props) => {
  const classes = useBackButtonStyles();
  return (
    <div className={classes.container}>
      <Button
        onClick={onClickHandler}
        className={clsx(classes.button, className)}
        startIcon={<ArrowBack className={classes.arrowIcon} />}
      >
        Volver
      </Button>
    </div>
  );
};

export default BackButton;
