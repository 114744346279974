import { useState } from "react";
import { Grid, Typography } from "@material-ui/core";

import useRejectFormStyles from "./RejectFormStyles";
import RejectFormReasonStep from "./RejectedFormReasonStep";
import RejectFormLastAmountStep from "./RejectedFormLastAmountStep";
import { setCurrentStep } from "context/negotiationsContext/negotiationsActions";
import { useNegotiations } from "context/negotiationsContext/negotiationsContext";
import { priceReason } from "context/negotiationsContext/constants";

const RejectForm = () => {
  const { dispatch } = useNegotiations();
  const classes = useRejectFormStyles();
  const [actualStep, setActualStep] = useState(0);

  const handleStep = (reason) => {
    if (priceReason !== reason) {
      dispatch(setCurrentStep("resumeOffer"));
    }
    setActualStep(1);
  };

  const Steps = [
    <RejectFormReasonStep handleStep={handleStep} />,
    <RejectFormLastAmountStep />,
  ];

  return (
    <Grid container>
      <Typography className={classes.titleGeneral}>
        Estás rechazando esta oferta
      </Typography>
      <Grid container item justifyContent="center">
        <Grid item xs={12}>
          {Steps[actualStep]}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RejectForm;
