interface Props {
  requestId: number;
}

const EndContractPending = ({ requestId }: Props) => (
  <div className="flex flex-col gap-2">
    <h1 className="!text-h28">Ya existe una solicitud creada</h1>
    <div className="flex flex-col gap-4">
      <p className="text-p16">
        Tu solicitud de fin de contrato número #{requestId} con Houm está en
        proceso.
      </p>
      <p className="text-p16 text-information-100">
        Un ejecutivo se pondrá en contacto contigo para indicarte los siguientes
        pasos.
      </p>
    </div>
  </div>
);

export default EndContractPending;
