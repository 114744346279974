import { ChangeEvent } from "react";

import { Button, Typography } from "@material-ui/core";

import { IoAttachOutline } from "@houm-com/ui-icons";

import useButtonEmptyFileStyles from "./ButtonEmptyFileStyles";

interface Props {
  allowedMimeTypes: string[];
  handleUpdateFile: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ButtonEmptyFile = ({ allowedMimeTypes, handleUpdateFile }: Props) => {
  const classes = useButtonEmptyFileStyles();

  return (
    <Button component="label" className={classes.uploadButton}>
      <div className={classes.containerText}>
        <IoAttachOutline color="#46788C" className={classes.icon} />
        <Typography className={classes.fileName}>Subir archivo</Typography>
      </div>
      <input
        type="file"
        className={classes.inputFileHiden}
        onChange={(e) => handleUpdateFile(e)}
        accept={allowedMimeTypes.join()}
        aria-label="file-selector"
      />
    </Button>
  );
};

export default ButtonEmptyFile;
