import { useEffect } from "react";
import clx from "classnames";

import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { setCurrentTabValue } from "context/tabContext/tabActions";
import { ITabOption } from "context/tabContext/tabTypes";
import { useTab } from "context/tabContext/tabContext";

import useDesignSystemTabsStyles from "./DesignSystemTabsStyles";

interface IProps {
  options: ITabOption[];
  defaultValue?: string;
  shadow?: boolean;
  expanded?: boolean;
  appBarClassName?: string;
}

const DesignSystemTabs = ({
  options,
  defaultValue,
  shadow = false,
  expanded = false,
  appBarClassName,
}: IProps) => {
  const classes = useDesignSystemTabsStyles({ shadow, expanded });
  const {
    state: { currentTabValue },
    dispatch,
  } = useTab();

  const handleSetTabOnMount = () => {
    if (defaultValue) return dispatch(setCurrentTabValue(defaultValue));
    dispatch(setCurrentTabValue(options[0].value));
  };

  useEffect(handleSetTabOnMount, []);

  if (!currentTabValue) return <CircularProgress />;

  return (
    <AppBar
      data-testid="container"
      position="static"
      color="transparent"
      className={clx(classes.appBar, appBarClassName)}
    >
      <Tabs
        classes={{
          indicator: classes.indicator,
        }}
        value={currentTabValue}
        aria-label="Seleccionar"
        onChange={(e, val) => dispatch(setCurrentTabValue(val))}
      >
        {options.map((option) => (
          <Tab
            id={`tab-${option.value}`}
            key={option.value}
            classes={{
              selected: classes.selected,
              root: classes.tabRoot,
            }}
            value={option.value}
            label={option.label}
            disabled={option.isDisabled}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};
export default DesignSystemTabs;
