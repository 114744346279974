/* eslint-disable camelcase */
export const SET_LANDLORD_VIP_FILTER = "SET_LANDLORD_VIP_FILTER";
export const SET_LANDLORD_VIP = "SET_LANDLORD_VIP";
export const CHANGE_COUNTRY = "CHANGE_COUNTRY";
export const SET_LOADING = "SET_LOADING";

// eslint-disable-next-line no-unused-vars
export type Dispatch = (action: Action) => void;

export interface State {
  loading: boolean;
  landlordVipList: LandlordVipList[];
  numberOfLandlordVip: number | null;
  country: Country;
  filters: LandlordVipFilter;
}

interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}

interface setLandlordVipFilter {
  type: typeof SET_LANDLORD_VIP_FILTER;
  filters: LandlordVipFilter;
}

export type Country = "chile" | "colombia" | "mexico";

interface changeCountry {
  type: typeof CHANGE_COUNTRY;
  country: Country;
}

interface setLandlordVipList {
  type: typeof SET_LANDLORD_VIP;
  payload: { landlordVipList: LandlordVipList[]; count: number };
}

export interface LandlordVipList {
  id: string;
  activeManagedProperties: number;
  executiveName: string;
  executiveEmail: string;
  landlordEmail: string;
  landlordName: string;
  landlordPhone: string;
}

export interface LandlordVipFilter {
  executiveEmail: string | null;
  size: number;
  page: number;
}

export type Action =
  | setLoading
  | setLandlordVipList
  | changeCountry
  | setLandlordVipFilter;
