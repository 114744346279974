import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useInspectionReportLayoutStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2.6, 2),
    marginBottom: theme.spacing(14),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(6, 0),
      marginBottom: theme.spacing(0),
    },
  },
  childrenContainer: {
    padding: 0,
    marginTop: theme.spacing(2.6),
  },
}));

export default useInspectionReportLayoutStyles;
