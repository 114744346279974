import { useFormContext } from "react-hook-form";
import { Box, Typography } from "@material-ui/core";
import FieldInput from "@houm-com/ui/FieldInput";

import { IClientInfo } from "apps/landlord/services/propertiesDuesService/type";
import useKastorFieldsStyles from "./KastorFieldsStyles";

interface Props {
  clientInfo: IClientInfo;
}

export default function KastorFields({ clientInfo }: Props) {
  const classes = useKastorFieldsStyles();
  const {
    formState: { errors },
    register,
  } = useFormContext();
  return (
    <>
      <FieldInput
        id="kastorClientNumber"
        label="Número de cliente Kastor"
        defaultValue={clientInfo?.externalId}
        errorMessage={errors.kastorClientNumber?.message}
        {...register("kastorClientNumber")}
      />
      <Box className={classes.kastorFieldsHelper}>
        <Typography className={classes.kastorFieldsHelperText}>
          Este número lo podrás encontrar en la boleta, tiene 10 dígitos
        </Typography>
      </Box>
    </>
  );
}
