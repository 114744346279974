import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  group: {
    border: `1px solid ${theme.palette.grey[75]}`,
    borderRadius: 100,
    marginTop: theme.spacing(3),
  },
  title: {
    fontWeight: 700,
  },
  button: {
    border: "none",
    textTransform: "unset",
    borderRadius: "100px !important",
  },
  buttonSelected: {
    backgroundColor: `${theme.palette.grey[75]} !important`,
  },
}));
