import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "@houm-com/ui/Button";

import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import CallToActionDrawer from "apps/ui/CustomDrawer/CallToActionDrawer";
import { setMethodSelected } from "apps/payments/context/rentPaymentContext/rentPaymentActions";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import ContentDrawer from "apps/ui/CustomDrawer/ContentDrawer";
import { PaymentMethods } from "apps/payments/models/Payments";

import { getDefaultByCountry, getOtrosPagosLink } from "./utils/utils";
import validationSchema from "./utils/validationSchema";
import PaymentMethodsCO from "../PaymentMethodsCO";
import PaymentMethodsMX from "../PaymentMethodsMX";
import PaymentMethodsCL from "../PaymentMethodsCL";
import { classes } from "./PaymentPlatformsStyles";
import { FormProps } from "./utils/types";
import Header from "./components/Header";

const PaymentPlatforms = () => {
  const { onHandleDrawer, setCurrentStep } = useCustomDrawer();
  const { dispatch, state } = useRentPayment();
  const methods = useForm<FormProps>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
    defaultValues: {
      paymentMethod:
        (state.methodSelected as PaymentMethods) ??
        getDefaultByCountry(state.country),
    },
  });

  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    dispatch(setMethodSelected(data.paymentMethod));
    if (data.paymentMethod === "otrosPagos")
      return window.open(
        getOtrosPagosLink(
          state.paymentData.concept === "RESERVATION"
            ? state.paymentData.movementId
            : state.paymentData.quotationId,
          state.paymentData.concept
        ),
        "_blank"
      );
    setCurrentStep("paymentSelected");
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ContentDrawer contentClassName={classes.drawerPaymentPlatforms}>
          <Header />
          <div className={classes.platformsContainer}>
            {state.country === "mx" && <PaymentMethodsMX />}
            {state.country === "co" && <PaymentMethodsCO />}
            {state.country === "cl" && <PaymentMethodsCL />}
          </div>
        </ContentDrawer>
        <CallToActionDrawer>
          <Button
            variant="tertiary"
            size="md"
            type="button"
            onClick={onHandleDrawer}
          >
            Atrás
          </Button>
          <Button variant="primary" size="md" type="submit">
            Siguiente
          </Button>
        </CallToActionDrawer>
      </form>
    </FormProvider>
  );
};

export default PaymentPlatforms;
