import { Box, SvgIcon, Typography } from "@material-ui/core";
import { format } from "date-fns";
import clx from "classnames";

import MoneyIcon from "assets/icons/Tenant/MoneyIcon";
import ServicesRequest from "apps/tenant/models/Requests";

import useServicesRequestCardStyles from "./ServicesRequestCardStyles";

interface Props {
  servicesRequest: ServicesRequest;
}

const RequestCard = ({ servicesRequest }: Props) => {
  const classes = useServicesRequestCardStyles();
  return (
    <Box className={classes.card}>
      <Box className={classes.iconContainer}>
        <SvgIcon className={classes.icon}>
          <MoneyIcon />
        </SvgIcon>
      </Box>
      <Box className={classes.textContainer}>
        <Typography className={classes.text}>
          <strong>Regularización de pagos</strong>
        </Typography>
        <Typography className={clx(classes.cardText, classes.text)}>
          Creado: {format(servicesRequest.createdAt, "dd/MM/yyyy")}
        </Typography>
        <Typography className={clx(classes.observation, classes.text)}>
          {servicesRequest.description}
        </Typography>
      </Box>
    </Box>
  );
};

export default RequestCard;
