import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { es } from "date-fns/locale";

import currency from "@houm-com/formats/currency";

import { Box, Typography } from "@material-ui/core";

import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import { setStep } from "apps/payments/context/rentPaymentContext/rentPaymentActions";
import PaymentGateway from "apps/payments/components/PaymentGateway";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import Layout from "apps/payments/layout/RentPaymentLayout";
import { useGetQueryParam } from "hooks/useQuery";

import PaymentBreakdown from "./components/paymentBreakdown";
import usePaymentDetailsStyles from "./PaymentDetailsStyles";
import { Title } from "../../../../components/typography";
import Disclaimer from "./components/Disclaimer";

const PaymentDetails = () => {
  const { onHandleDrawer, open, setCurrentStep } = useCustomDrawer();
  const { t } = useTranslation("rent-payment");
  const email = useGetQueryParam("email");
  const classes = usePaymentDetailsStyles();
  const { state, dispatch } = useRentPayment();

  return (
    <>
      <Layout
        buttonNextText="Ir a pagar"
        buttonProps={{
          onClick: () => {
            setCurrentStep("paymentPlatforms");
            onHandleDrawer();
          },
          type: "button",
        }}
        handleBack={() => dispatch(setStep(email ? "mailForm" : "landing"))}
        loading={false}
      >
        <Title>{t("RENT_DETAILS_TITLE")}</Title>
        <div className={classes.paymentContainer}>
          <div className={classes.paymentBox}>
            <Typography component="b" className={classes.payment}>
              {currency(
                Number(state.paymentData.price),
                state.paymentData.currency
              )}
            </Typography>
          </div>
          <Box className={classes.limitDateContainer}>
            <Typography className={classes.limitDateText}>
              {t("LIMIT_PAYMENT_DATE_LABEL")}
            </Typography>
            <Typography className={classes.date}>
              {format(state.paymentData.date, "d 'de' MMMM", { locale: es })}
            </Typography>
          </Box>
          <PaymentBreakdown />
          {state.country === "cl" && <Disclaimer />}
        </div>
      </Layout>
      <PaymentGateway open={open} onHandleDrawer={onHandleDrawer} />
    </>
  );
};

export default PaymentDetails;
