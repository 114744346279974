import { makeStyles, Theme } from "@material-ui/core/styles";

const defaultBreakpoint = "md";

const useCallToActionStyles = makeStyles((theme: Theme) => ({
  spinner: {
    marginTop: theme.spacing(5),
  },
  root: {
    margin: theme.spacing(0, 0),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      margin: "auto",
      width: "50vw",
      marginTop: theme.spacing(0, 10),
    },
  },
  form: {
    padding: theme.spacing(4, 5, 14, 5),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(7, 5, 14, 5),
    },
  },
}));

export default useCallToActionStyles;
