import { makeStyles, Theme } from "@material-ui/core";

const useMobileSubmenuHeaderStyles = makeStyles((theme: Theme) => ({
  mobileSubmenuHeaderRoot: {
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(16),
  },
}));

export default useMobileSubmenuHeaderStyles;
