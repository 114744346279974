import { format } from "date-fns";
import currency from "@houm-com/formats/currency";

import { IMovement } from "apps/landlord/models/Movement";

export interface ParsedMovement {
  amount: string;
  date: string;
  id: string;
}

const parseMovements = ({
  movements,
}: {
  movements: IMovement[];
}): ParsedMovement[] =>
  movements.map((movement) => ({
    amount: currency(movement.totalPrice, movement.currency),
    date: movement?.date
      ? format(movement.date, "dd/MM/yyyy")
      : "No se pudo obtener fecha",
    id: movement.id,
  }));

export default parseMovements;
