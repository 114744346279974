import { useGetToken } from "hooks";
import { useMutation } from "react-query";

import { State } from "apps/lead/context/evaluationContext/EvaluationTypes";
import evaluationService from "apps/lead/services/EvaluationService";
import { countryCode } from "utils";

import { backendCreateEvaluationParser } from "../utils/parsers";

export const useCreateEvaluation = () => {
  const { getToken } = useGetToken();

  return useMutation(async (state: State) => {
    const parsedData = backendCreateEvaluationParser(state);
    const authToken = await getToken();
    const response = await evaluationService.createEvaluation(
      parsedData,
      countryCode(state.property.country),
      authToken
    );
    return response?.data;
  });
};

export default useCreateEvaluation;
