import { ContractsResponse } from "apps/tenant/services/tenantContractService/types";
import { SimplifiedContract } from "./types";

export const parseTenantContractHistory = (
  data: ContractsResponse[]
): SimplifiedContract[] =>
  data
    .map((contract) => ({
      id: contract.id,
      initDate: new Date(contract.init_date),
      propertyId: contract.property_id,
      status: contract.status,
      country: contract.country,
    }))
    .sort((contract) => contract.initDate.getTime());

export default parseTenantContractHistory;
