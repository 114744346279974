import { makeStyles, Theme } from "@material-ui/core";

const useFavoriteButtonStyles = makeStyles((theme: Theme) => ({
  button: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    cursor: "pointer",
  },
  filledIcon: {
    color: theme.palette.primary.main,
  },
  outlinedIcon: {
    color: theme.palette.grey[900],
  },
}));

export default useFavoriteButtonStyles;
