import { ReactNode } from "react";
import useStyles from "./DefaultTenantLayoutStyles";

interface Props {
  children: ReactNode;
}

const DefaultTenantLayout = ({ children }: Props) => {
  const classes = useStyles();

  return <div className={classes.contentRoot}>{children}</div>;
};

export default DefaultTenantLayout;
