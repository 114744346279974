import { makeStyles, Theme } from "@material-ui/core";

const useScheduleFeedbackStyles = makeStyles<Theme>((theme) => ({
  feedbackContainer: {
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "column",
  },
  textContainer: {
    display: "flex",
    gap: theme.spacing(1),
    flexDirection: "column",
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.44,
    marginBottom: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.4,
    },
  },
}));

export default useScheduleFeedbackStyles;
