import { useState } from "react";

import { useFeatureManager } from "@houm-com/feature-manager";

import { UseMovementDataProps } from "apps/payments/hooks/useMovementData/useMovementData";
import RefundDeclaration from "apps/lead/pages/Payments/components/RefundDeclaration";
import Layout from "Components/Admin/UIComponents/paymentLayout/Layout";
import RefundInfo from "apps/lead/pages/Payments/components/RefundInfo";
import PaymentGateway from "apps/payments/components/PaymentGateway";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import NotebookPerson from "assets/images/notebookPerson.png";
import { Locale } from "models/Countries";

import useRefundDeclaration from "../../hooks/useRefundDeclaration";
import LandingInfo from "../LandingInfo";

interface Props {
  country: Locale;
  movement: UseMovementDataProps;
  address: string;
}

const Reservation = ({ country, movement, address }: Props) => {
  const { onHandleDrawer, open, setCurrentStep } = useCustomDrawer();
  const { mutate, isLoading } = useRefundDeclaration();
  const [declaration, setDeclaration] = useState(country !== "cl");
  const onChangeDeclaration = () => setDeclaration((prev) => !prev);
  const { data } = useFeatureManager("landing-payments", {
    marketCode: country,
  });

  const onHandlePayment = async () => {
    setCurrentStep("paymentPlatforms");
    if (country === "cl")
      mutate({
        countryCode: country,
        movementId: movement.movementId,
        refundDeclaration: declaration,
      });
    onHandleDrawer();
  };

  return (
    <>
      <Layout
        title={data?.content[movement.concept.toLowerCase()].layoutTitle}
        label="BIENVENIDO"
        image={NotebookPerson}
        handleClick={onHandlePayment}
        buttonLabel={
          data?.content[movement.concept.toLowerCase()].layoutButtonLabel
        }
        mainContainerHeight="%"
        disabled={!declaration || isLoading}
      >
        <LandingInfo movement={movement} address={address} country={country} />
        {country !== "co" && (
          <RefundDeclaration
            value={declaration}
            onChange={onChangeDeclaration}
            countryCode={country}
          />
        )}
      </Layout>
      <RefundInfo countryCode={country} />
      <PaymentGateway open={open} onHandleDrawer={onHandleDrawer} />
    </>
  );
};

export default Reservation;
