import moment from "moment-timezone";
import "moment/locale/es";

import { Box, Typography } from "@material-ui/core";

import Button from "@houm-com/ui/Button";

import TimeSelection from "apps/tenant/pages/ExitVisitSchedule/components/TimeSelection/TimeSelection";
import DesignSystemCalendar from "Components/Admin/UIComponents/Calendar/DesignSystemCalendar";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import DrawerSpinner from "apps/ui/CustomDrawer/DrawerSpinner";
import ContentDrawer from "apps/ui/CustomDrawer/ContentDrawer";
import CallToActionDrawer from "apps/ui/CustomDrawer/CallToActionDrawer";

import useScheduleStepStyles from "./ScheduleStepStyles";
import useScheduleStep from "../../hooks/useScheduleStep";

const ScheduleStep = () => {
  const classes = useScheduleStepStyles();
  const {
    availability,
    handleChangeDate,
    selectedDate,
    setSelectedTime,
    selectedTime,
    availableHours,
    isLoading,
    handleSubmitSchedulePhotoVisit,
  } = useScheduleStep();
  const { returnToMainPage } = useCustomDrawer();

  const shouldDisableDate = (date: any) =>
    availability &&
    !Object.keys(availability?.parsedAvailability).find((day) =>
      moment(day).isSame(date, "date")
    );

  const isAbleToContinue =
    !selectedTime ||
    !selectedDate ||
    !availableHours ||
    !availableHours.length ||
    isLoading;

  return (
    <>
      {isLoading && <DrawerSpinner />}
      <ContentDrawer contentClassName={classes.shedulePhotoContent}>
        <Box className={classes.shedulePhotoContentText}>
          <Typography className={classes.schedulePhotoTitle}>
            Agenda tus fotos gratuitas
          </Typography>
          <Typography className={classes.schedulePhotoDescription}>
            Nuestras fotos profesionales ayudarán a destacar tu propiedad
          </Typography>
          <Typography className={classes.schedulePhotoDescription}>
            Define el día y horario que más se te acomode para que podamos ir a
            tomar tus fotos
          </Typography>
        </Box>
        <DesignSystemCalendar
          shouldDisableDate={shouldDisableDate}
          onChange={handleChangeDate}
          value={selectedDate}
        />
        <TimeSelection
          availableHours={availableHours}
          scheduleLoading={false}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
        />
      </ContentDrawer>
      <CallToActionDrawer>
        <Button onClick={returnToMainPage} variant="tertiary" size="md">
          Volver
        </Button>
        <Button
          onClick={() =>
            handleSubmitSchedulePhotoVisit({
              selectedTime,
              availableHours,
            })
          }
          variant="primary"
          size="md"
          disabled={isAbleToContinue}
        >
          Siguiente
        </Button>
      </CallToActionDrawer>
    </>
  );
};

export default ScheduleStep;
