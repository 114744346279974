import { AxiosResponse } from "axios";

import { Paginate } from "models/ResponseWithPaginate";
import { createHttp } from "services/helper";

import { IScheduleFeedback } from "./types";

export default {
  getScheduleFeedback(
    scheduleId: number,
    token: string
  ): Promise<AxiosResponse<Paginate<IScheduleFeedback>>> {
    return createHttp(token).get(
      `/schedules/v2/visit-feedback/?schedule_id=${scheduleId}`
    );
  },
};
