import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import usePropertyDetails from "apps/landlord/hooks/usePropertyDetails/index";
import PropertyLayout from "apps/landlord/Layout/PropertyLayout";
import Spinner from "Components/Shared/partials/Spinner";

import usePropertyDetailsStyles from "./PropertyDetailsStyles";
import Amenities from "./components/Amenities";
import Details from "./components/Details";

export default function PropertyDetails() {
  const { t } = useTranslation("landlord.property.details");
  const { id: propertyId } = useParams<{ id: string }>();
  const history = useHistory();
  const classes = usePropertyDetailsStyles();
  const { isLoading, data, isError } = usePropertyDetails(propertyId);

  const handleBack = () => history.push(`/landlord/properties/${propertyId}`);

  if (isError) handleBack();

  const isEditable =
    data?.rentalStatus !== "published" &&
    data?.rentalStatus !== "rented" &&
    data?.saleStatus !== "published" &&
    data?.saleStatus !== "rented";

  return (
    <PropertyLayout
      titlePage={t("PAGE_PROPERTY_DETAILS_TITLE")}
      subtitlePage={t("PAGE_PROPERTY_DETAILS_SUBTITLE")}
      onHandleBack={handleBack}
    >
      {isLoading && <Spinner />}
      {!isLoading && (
        <div className={classes.content}>
          <Details details={data.details} isEditable={isEditable} />
          <Amenities
            amenities={data.amenities}
            propertyAmenities={data.propertyAmenities}
            isEditable={isEditable}
          />
        </div>
      )}
    </PropertyLayout>
  );
}
