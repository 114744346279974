import { Grid } from "@material-ui/core";

import Loading from "Components/Admin/UIComponents/molecules/Loading";

import useRentPaymentsStyles from "./RentPaymentsStyles";
import PaymentsList from "./components/PaymentsList";
import NoMovements from "./components/NoMovements";
import useRentPayment from "./hooks";

const RentPayments = () => {
  const classes = useRentPaymentsStyles();

  const { movementsLoading, movementsData, movementsError } = useRentPayment();

  if (!movementsData || movementsError) return <NoMovements />;

  return (
    <Grid
      container
      className={classes.rentPaymentsRoot}
      justifyContent="space-between"
    >
      <Loading loading={movementsLoading}>
        <Grid item xs={12} sm={6}>
          <PaymentsList movements={movementsData} />
        </Grid>
      </Loading>
    </Grid>
  );
};

export default RentPayments;
