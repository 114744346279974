import { Box, Grid, Typography } from "@material-ui/core";

import { IoBedOutline as Bedrooms } from "@houm-com/ui-icons";

import BathroomsIcon from "assets/icons/Landlord/BathroomsIcon";
import SurfaceIcon from "assets/icons/Landlord/SurfaceIcon";

import useStyles from "./PropertyDetailsStyles";

interface Props {
  rooms: number | null;
  bathrooms: number | null;
  surface: number | null;
}

const PropertyDetails = ({ rooms, bathrooms, surface }: Props) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.propertyDetailsContainer}>
      {rooms && (
        <Box className={classes.iconAndDetail}>
          <Bedrooms className="w-5 h-5" />
          <Typography className={classes.propertyDetailsText}>
            {rooms}
          </Typography>
        </Box>
      )}
      {bathrooms && (
        <Box className={classes.iconAndDetail}>
          <BathroomsIcon className="w-5 h-5" />
          <Typography className={classes.propertyDetailsText}>
            {bathrooms}
          </Typography>
        </Box>
      )}
      {surface && (
        <Box className={classes.iconAndDetail}>
          <SurfaceIcon className="w-5 h-5" />
          <Typography className={classes.propertyDetailsText}>
            {`${surface}mts²`}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default PropertyDetails;
