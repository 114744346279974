import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import { Fintoc } from "@fintoc/fintoc-js";

import { alert } from "@houm-com/ui/Alert";

import { FintocWidgetResponse } from "apps/payments/services/paymentService/types";
import paymentService from "apps/payments/services/paymentService";
import { Locale } from "models/Countries";

interface Props {
  movementId: string;
  country: Locale;
  onHandleDrawer?: () => void;
  onHandleSuccess?: () => void;
}

declare global {
  interface Window {
    Fintoc: Fintoc;
  }
}

const useWidgetFintoc = ({
  movementId,
  country,
  onHandleDrawer,
  onHandleSuccess,
}: Props) => {
  const queryClient = useQueryClient();
  const query: UseQueryResult<FintocWidgetResponse, Error> = useQuery(
    ["fintoc-token", country, movementId],
    async () => {
      const { data } = await paymentService.getFintoc(country, movementId);
      return data;
    },
    {
      enabled: false,
      retry: false,
      onSuccess: (options) => {
        const widget = window.Fintoc.create({
          ...options,
          onExit: () => onHandleDrawer?.(),
          onSuccess: () => {
            queryClient.invalidateQueries("movements");
            onHandleSuccess?.();
          },
        });
        onHandleDrawer?.();
        widget.open();
      },
      onError: () => {
        alert.error({
          message: "¡Ups! Algo salió mal",
          disclaimer: "Por favor intentar con un nuevo método de pago",
        });
      },
    }
  );

  return {
    ...query,
  };
};

export default useWidgetFintoc;
