const REPAIRS_TYPES_OPTIONS = [
  {
    label: "Plomería / Gasfitería:",
    value: "plumbing",
    description: "Instalación y/o mantenimiento de tuberías (baño y cocina)",
  },
  {
    label: "Cerrajería:",
    value: "locksmith",
    description: "Instalación y mantenimiento de chapas",
  },
  {
    label: "Calefacción:",
    value: "heating",
    description: "Problemas con la calefacción",
  },
  {
    label: "Estructurales:",
    value: "structural",
    description: "Daños en pisos, techos y paredes",
  },
  {
    label: "Arreglos estéticos:",
    value: "cosmetic_repairs",
    description: "Pinturas y arreglos menores",
  },
  {
    label: "Eléctrico:",
    value: "electrical",
    description: "Daños y mantenimiento en el cableado o en tablero eléctrico",
  },
  {
    label: "Electrodoméstico:",
    value: "electronic",
    description: "Daños en cocina, encimera, calefont, etc",
  },
  {
    label: "Limpieza:",
    value: "cleaning",
    description: "Aplica al momento de la entrega",
  },
];

export default REPAIRS_TYPES_OPTIONS;
