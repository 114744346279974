import { useHistory } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { IoArrowForward as ArrowRight } from "@houm-com/ui-icons";

import useMainProperty from "apps/landlord/pages/Price/hooks/useMainProperty";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import CardMenuItem from "apps/landlord/components/CardMenuItem";
import { IProperty } from "apps/landlord/models/Property";

import IconButton from "@houm-com/ui/IconButton";
import useSupplyPropertyInfoStyles from "./SupplyPropertyInfoStyles";
import MarketPlaceStats from "./components/MarketPlaceStats";
import getPropertyPrice from "./utils/propertyPrice";
import InfoRow from "./components/InfoRow";

interface Props {
  property: IProperty;
}

const SupplyPropertyInfo = ({ property = null }: Props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useSupplyPropertyInfoStyles();
  const history = useHistory();
  const handleRedirect = () =>
    history.push(`/landlord/properties/${property.uid}/property-price`);

  const { data: propertyData, isLoading } = useMainProperty(property?.id);

  if (isLoading) {
    return <Loading loading />;
  }

  return (
    <Box className={classes.supplyContainerRoot}>
      <CardMenuItem
        title="Desempeño de tu publicación"
        subtitle="Ajusta el precio de tu propiedad o ve un resumen de estadísticas relacionadas a tu publicación de las últimas 2 semanas."
        buttonLabel="Ver más"
        isButtonAvailable
        onViewInfoClick={handleRedirect}
        infoContainerClassName={classes.infoContainer}
        buttonVariant="tertiary"
      >
        <Grid
          item
          container
          className={classes.statsContainer}
          spacing={!isDesktop ? 2 : 0}
        >
          <Grid item xs={12} md={3}>
            <InfoRow
              title={
                property.forRental &&
                property.propertyDetails.status !== "rented" &&
                "Arriendo mensual"
              }
              value={
                property.forRental &&
                property.propertyDetails.status !== "rented" &&
                getPropertyPrice(property, "rent")
              }
              secondaryTitle={property.forSale && "Precio venta"}
              secondaryValue={
                property.forSale && getPropertyPrice(property, "sale")
              }
            />
          </Grid>
          <MarketPlaceStats stats={propertyData?.marketplaceStats} />
        </Grid>
        <Box className={classes.bankTextContainer}>
          <Box className={classes.textContainer}>
            <Typography className={classes.bankText}>
              <b>¿Dónde recibiré mi pago?</b>
            </Typography>
            <Typography className={classes.bankText}>
              Es importante que agregues esta información, ya que aquí es donde
              recibirás el pago de tu arriendo.
            </Typography>
          </Box>
          <IconButton
            variant="primary"
            size="lg"
            onClick={() =>
              history.push(`/landlord/properties/${property.uid}/bank-account`)
            }
            position="end"
            icon={<ArrowRight color="white" />}
          >
            Agregar cuenta bancaria
          </IconButton>
        </Box>
      </CardMenuItem>
    </Box>
  );
};

export default SupplyPropertyInfo;
