import { makeStyles, Theme } from "@material-ui/core";

import sharedFormStyles from "../sharedStyles";

interface IInputStylesProps {
  disabled?: boolean;
  error?: boolean;
}

const useInputStyles = makeStyles<Theme, IInputStylesProps>((theme) => ({
  inputRoot: {
    position: "relative",
    margin: theme.spacing(2, 0),
  },
  ...sharedFormStyles(theme),
  inputBase: {
    width: "100%",
    padding: theme.spacing(0.5, 1),
    border: ({ error }) =>
      error ? `1px solid ${theme.palette.error.main}` : "none",
    borderRadius: 4,
    backgroundColor: ({ disabled }) => (disabled ? "transparent" : "#EFF2F3"),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
  },
  subLabel: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: theme.spacing(0.5),
  },
  eyeIcon: {
    position: "absolute",
    top: 30,
    right: 15,
    cursor: "pointer",
  },
}));

export default useInputStyles;
