/* eslint-disable import/no-cycle */
import TokySDK from "toky-phone-js-sdk";
import tokyService from "services/tokyService";
import {
  Action,
  Dispatch,
  TokenAccessKeys,
  SET_ACCESS_KEYS,
  SET_LOADING,
  SET_IS_PHONE_OPEN,
  SET_AGENT_DIDS,
  IAgentDid,
  IAudioDevices,
  SET_TOKY_CLIENT,
  SET_TOKY_AUDIO_DEVICES,
  SET_TOKY_SESSION,
  TokySessionStatus,
  SET_TOKY_SESSION_STATUS,
  PhoneDisplayStatus,
  SET_PHONE_DISPLAY_STATUS,
  SET_DIALED_PHONE_NUMBER,
  SET_ON_CLOSE_CALLBACK_FN,
  SET_DID_CALL_CONNECTED,
  SET_TOKY_CALL_ID,
  SET_CALLBACKS,
  SET_READY_TO_CALL,
  ICallbacks,
} from "./tokyTypes";

import { PhoneInput } from "models/PhoneInput";
import {
  parseGetAccessKeysResponse,
  parseGetDidsForAgentResponse,
  setupTokyClientEventListeners,
} from "./tokyUtils";

export const setAccessKeys = (tokens: TokenAccessKeys): Action => ({
  type: SET_ACCESS_KEYS,
  tokens,
});

export const setReadyToCall = (readyToCall: boolean): Action => ({
  type: SET_READY_TO_CALL,
  readyToCall,
});

export const setAgentDids = (agentDids: IAgentDid[]): Action => ({
  type: SET_AGENT_DIDS,
  agentDids,
});

export const setTokyClient = (TokyClient: any): Action => ({
  type: SET_TOKY_CLIENT,
  TokyClient,
});

export const setTokyAudioDevices = (audioDevices: IAudioDevices): Action => ({
  type: SET_TOKY_AUDIO_DEVICES,
  audioDevices,
});

export const setTokySession = (tokySession): Action => ({
  type: SET_TOKY_SESSION,
  tokySession,
});

export const setTokySessionStatus = (
  tokySessionStatus: TokySessionStatus
): Action => ({
  type: SET_TOKY_SESSION_STATUS,
  tokySessionStatus,
});

const setLoading = (loading: boolean): Action => ({
  type: SET_LOADING,
  loading,
});

export const setIsPhoneOpen = (isPhoneOpen: boolean): Action => ({
  type: SET_IS_PHONE_OPEN,
  isPhoneOpen,
});

export const setPhoneDisplayStatus = (
  phoneDisplayStatus: PhoneDisplayStatus
): Action => ({
  type: SET_PHONE_DISPLAY_STATUS,
  phoneDisplayStatus,
});

export const setDialedPhoneNumber = (
  dialiedPhoneNumber: PhoneInput
): Action => ({
  type: SET_DIALED_PHONE_NUMBER,
  dialiedPhoneNumber,
});

export const setOnCloseCallbackFn = (
  onCloseCallbackFn: (args?: any) => void | Promise<void>
): Action => ({
  type: SET_ON_CLOSE_CALLBACK_FN,
  onCloseCallbackFn,
});

export const setCallbacks = (callbacks: ICallbacks): Action => ({
  type: SET_CALLBACKS,
  callbacks,
});

export const setDidCallConnected = (didCallConnected: boolean): Action => ({
  type: SET_DID_CALL_CONNECTED,
  didCallConnected,
});

export const setTokyCallId = (tokyCallId: string): Action => ({
  type: SET_TOKY_CALL_ID,
  tokyCallId,
});

export const getAccessKeys = async (agentId: string, dispatch: Dispatch) => {
  dispatch(setLoading(true));

  try {
    const response = await tokyService.getAccessTokens(agentId);
    if (!response) throw new Error("Empty response");

    const tokens = parseGetAccessKeysResponse(response);
    dispatch(setAccessKeys(tokens));
    return tokens;
  } catch (e) {
    dispatch(setLoading(false));
    throw e;
  }
};

export const refreshTokens = async (
  refreshToken: string,
  dispatch: Dispatch
) => {
  dispatch(setLoading(true));

  try {
    const response = await tokyService.refreshTokens(refreshToken);
    if (!response) throw new Error("Empty response");

    const tokens = parseGetAccessKeysResponse(response);
    dispatch(setAccessKeys(tokens));
  } catch (e) {
    dispatch(setLoading(false));
    throw e;
  }
};

export const getDidsForAgent = async (
  agentId: string,
  accessToken: string,
  dispatch: Dispatch
) => {
  dispatch(setLoading(true));

  try {
    const response = await tokyService.getDidsForAgent(agentId, accessToken);
    if (!response) throw new Error("Empty response");

    const agentDids = parseGetDidsForAgentResponse(response);
    dispatch(setAgentDids(agentDids));
  } catch (e) {
    dispatch(setLoading(false));
    throw e;
  }
};

export const loadAudioDevices = (dispatch: Dispatch) => {
  const { TokyMedia, MediaStatus } = TokySDK;

  const setDeviceOptions = () => {
    const audioDevices: IAudioDevices = {
      input: TokyMedia.inputs,
      output: TokyMedia.outputs,
      currentInput: TokyMedia.selectedInputDevice,
      currentOutput: TokyMedia.selectedOutputDevice,
    };
    dispatch(setTokyAudioDevices(audioDevices));
  };

  TokyMedia.on(MediaStatus.READY, () => {
    setDeviceOptions();
  });

  TokyMedia.on(MediaStatus.UPDATED, () => {
    setDeviceOptions();
  });
};

export const initTokyClient = async (
  agentId: string,
  accessToken: string,
  dispatch: Dispatch,
  callbacks: ICallbacks = {}
) => {
  dispatch(setReadyToCall(false));
  const { TokyClient } = TokySDK;

  const Client = new TokyClient({
    accessToken,
    account: {
      user: agentId,
      type: "agent",
      acceptInboundCalls: false,
    },
    transportLib: "sip.js",
  });

  await tryReconnect(Client);
  dispatch(setTokyClient(Client));
  loadAudioDevices(dispatch);
  setupTokyClientEventListeners(Client, dispatch, callbacks);
  return Client;
};

const tryReconnect = async (Client, attemps = 6) => {
  if (!Client.isRegistered) {
    await Client.init();
  }
  setTimeout(() => {
    if (!Client.isRegistered && attemps > 0) {
      return tryReconnect(Client, attemps - 1);
    }
  }, 3000);
};

export const callByToky = (phoneNumber: PhoneInput, dispatch: Dispatch) => {
  dispatch(setIsPhoneOpen(true));
  dispatch(setPhoneDisplayStatus(PhoneDisplayStatus.OPEN));
  dispatch(setDialedPhoneNumber(phoneNumber));
};

export const closePhone = (dispatch: Dispatch) => {
  dispatch(setIsPhoneOpen(false));
  dispatch(setPhoneDisplayStatus(PhoneDisplayStatus.CLOSED));
};
