import { SnackbarProvider } from "notistack";

import { TabProvider } from "context/tabContext/tabContext";

import { ApplicantContextProvider } from "./context/ApplicantContext";
import Applicant from "./Applicant";

const ApplicantIndex = () => (
  <SnackbarProvider>
    <TabProvider>
      <ApplicantContextProvider>
        <Applicant />
      </ApplicantContextProvider>
    </TabProvider>
  </SnackbarProvider>
);

export default ApplicantIndex;
