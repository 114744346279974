import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useHistory, useLocation } from "react-router-dom";

import useContractEnd from "./useContractEnd";

export enum STEPS {
  Initial = 0,
  LeavingDate = 1,
  LeavingConfirmation = 2,
  Confirmation = 3,
}

const useContractEndSteps = (closeDrawer: () => void) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { contractEndData } = useContractEnd();
  const history = useHistory();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(STEPS.Initial);

  useEffect(() => {
    if (contractEndData?.hasBegun) setCurrentStep(STEPS.Confirmation);
  }, [contractEndData]);

  const handleNextStep = async () => {
    switch (currentStep) {
      case STEPS.Initial:
        setCurrentStep(STEPS.LeavingDate);
        return null;
      case STEPS.LeavingDate:
        if (isMobile) {
          if (showConfirmationModal) {
            setCurrentStep(STEPS.Confirmation);
            setShowConfirmationModal(false);
            return null;
          }
          setShowConfirmationModal(true);
          return null;
        }
        setCurrentStep(STEPS.LeavingConfirmation);
        return null;
      case STEPS.LeavingConfirmation:
        setCurrentStep(STEPS.Confirmation);
        return null;
      case STEPS.Confirmation:
        history.push("/tenant/help");
        return null;
      default:
        return null;
    }
  };

  const handleBack = () => {
    if (currentStep === STEPS.LeavingConfirmation) {
      setCurrentStep(currentStep - 1);
      return null;
    } else if (showConfirmationModal) {
      setShowConfirmationModal(false);
      return null;
    }
    if (location.pathname === "/tenant/contract-end") {
      history.push("/tenant/help");
    } else {
      closeDrawer();
    }
  };

  const handleError = () => {
    setCurrentStep(STEPS.LeavingDate);
    setShowConfirmationModal(false);
  };

  return {
    currentStep,
    handleNextStep,
    handleBack,
    showConfirmationModal,
    contractEndData,
    handleError,
  };
};

export default useContractEndSteps;
