import { makeStyles, Theme } from "@material-ui/core";

const useNoInspectionCardStyles = makeStyles<Theme>((theme) => ({
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    backgroundColor: "#F3F7F9",
    borderRadius: theme.spacing(2),
    width: "100%",
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  text: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
}));

export default useNoInspectionCardStyles;
