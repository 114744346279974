import * as yup from "yup";

export const requiredText = "Obligatorio";

const rfcValidation = yup
  .string()
  .nullable()
  .matches(
    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{3})$/,
    "Formato incorrecto"
  );

const documentValidation = (regex: RegExp) =>
  yup.string().matches(regex, "Formato incorrecto");

export const landlordDataValidationSchema = (documentRegex: RegExp) =>
  yup.object({
    name: yup.string().required(requiredText),
    lastName: yup.string().required(requiredText),
    secondLastName: yup.string().required(requiredText),
    occupation: yup.string(),
    identificationType: yup.string().matches(/[^0]+/, requiredText),
    identificationNumber:
      documentValidation(documentRegex).required(requiredText),
    nationality: yup.string(),
    civilStatus: yup.string(),
    maritalRegime: yup.string(),
    rfc: rfcValidation,
  });

export const landlordDocsValidationSchema = yup.object({});
export const landlordBankDataValidationSchema = yup.object({
  clabe: yup.string(),
});
export const propertyDocsValidationSchema = yup.object({});
export const brokerageContractValidationSchema = yup.object({});
