import { makeStyles, Theme } from "@material-ui/core";

const usePriceInfoStyles = makeStyles<Theme>((theme) => {
  const price = {
    fontWeight: 700,
    color: theme.palette.grey[900],
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(3),
    width: "100%",
  };
  const flexCenter = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return {
    price: {
      ...flexCenter,
      ...price,
      textAlign: "center",
      backgroundColor: "#EFF2F3",
    },
    noBgPrice: {
      ...flexCenter,
      ...price,
      textAlign: "center",
      width: "fit-content",
    },
    bold: {
      fontWeight: 700,
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    divisor: {
      margin: theme.spacing(0, 1),
    },
    default: {
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    greenPrice: {
      color: "#26810A",
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  };
});

export default usePriceInfoStyles;
