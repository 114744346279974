import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme, { mapHeight: number }>((theme) => ({
  label: {
    width: "100%",
    fontWeight: "bold",
    alignItems: "left",
    fontSize: "1rem",
    marginBottom: "0px",
  },
  container: {
    height: (props) => props.mapHeight,
    marginBottom: theme.spacing(3),
  },
}));
