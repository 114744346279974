import { isDesktop } from "react-device-detect";

import { Typography } from "@material-ui/core";

import { Locale } from "models/Countries";

import useStyles from "./FavoritesHeaderStyles";
import FavoritesTypeSelector from "../TypeSelector";
import MarketplaceLink from "../MarketplaceLink";

interface Props {
  countryCode: Locale;
  hasFavorites: boolean;
}
const FavoritesHeader = ({ countryCode, hasFavorites }: Props) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.headerText}>
          <Typography component="h1" className={classes.title}>
            Favoritas
          </Typography>
          {/* cms localize */}
          <Typography component="h2" className={classes.subtitle}>
            Encuentra aquí las propiedades que te han gustado
          </Typography>
        </div>
        {isDesktop && hasFavorites && (
          <MarketplaceLink countryCode={countryCode} />
        )}
      </div>
      {hasFavorites && (
        <div className={classes.selectorContainer}>
          <FavoritesTypeSelector />
        </div>
      )}
    </div>
  );
};

export default FavoritesHeader;
