import { Typography } from "@material-ui/core";
import LightButton from "@houm-com/ui/LightButton";
import { IoArrowForward as ArrowIcon } from "@houm-com/ui-icons";
import currency from "@houm-com/formats/currency";

import useDetailsContainerStyles from "./DetailsContainerStyles";
import { FavoriteProperty } from "apps/account/models/FavoriteProperty";

interface Props {
  property: FavoriteProperty;
  toScheduleUrl: string;
}

const DetailsContainer = ({ property, toScheduleUrl }: Props) => {
  const classes = useDetailsContainerStyles();
  const priceText = currency(property.price.value, property.price.currencyCode);

  return (
    <div className={classes.detailsContainer}>
      <Typography className={classes.priceText}>{priceText}</Typography>
      <Typography className={classes.ggccText}>
        {property.isGGCCincluded ? "Gastos incluidos" : "Gastos no incluidos"}
      </Typography>
      <Typography className={classes.addressText}>
        {property.address}
      </Typography>
      <Typography className={classes.regionText}>
        {property.commune}, {property.region}
      </Typography>
      <div className={classes.buttonContainer}>
        <LightButton
          variant="primary"
          size="md"
          className={classes.ctaButton}
          icon={<ArrowIcon />}
          position="end"
          onClick={() => window.open(toScheduleUrl, "_blank")}
        >
          Agendar visita
        </LightButton>
      </div>
    </div>
  );
};

export default DetailsContainer;
