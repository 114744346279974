import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";

import BrowserView from "./components/BrowserView";
import MobileView from "./components/MobileView";

const Fallback = () => {
  const history = useHistory();

  const reloadPage = () => history.go(0);

  return isMobile ? (
    <MobileView onHandleClick={reloadPage} />
  ) : (
    <BrowserView onHandleClick={reloadPage} />
  );
};

export default Fallback;
