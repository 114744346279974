export function strNormalize(str: string): string {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s/g, "-")
    .toLowerCase();
}

export function getCountryPhone(str: string) {
  if (!str) return;
  const chileValues = {
    callingCode: "56",
    countryCode: "CL",
    countryName: "Chile",
  };
  const colombiaValues = {
    callingCode: "57",
    countryCode: "CO",
    countryName: "Colombia",
  };
  const mexicoValues = {
    callingCode: "52",
    countryCode: "MX",
    countryName: "México",
  };
  const values = {
    cl: chileValues,
    chile: chileValues,
    co: colombiaValues,
    colombia: colombiaValues,
    mx: mexicoValues,
    mexico: mexicoValues,
    méxico: mexicoValues,
  };
  return values[str.toLocaleLowerCase()];
}
