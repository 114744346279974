import { format } from "date-fns";
import { round } from "lodash";

import { InspectionReportQualification } from "services/inspectionReportService/types";
import statusRatingStars from "apps/landlord/utils/inspectionStatusRating";
import { Inspection } from "hooks/inspectionReports/types";

export function getDate(inspectionReport: Inspection) {
  return format(new Date(inspectionReport.createdAt), "dd/MM/yyyy");
}

export function getRating(inspectionReport: Inspection) {
  const filteredRatings = inspectionReport.spaceQualifications.filter(
    (summary) =>
      summary.qualification !== InspectionReportQualification.DOES_NOT_HAVE &&
      summary.qualification !== InspectionReportQualification.UNANSWERED
  );
  if (!filteredRatings.length) return 0;
  const rating =
    filteredRatings.reduce(
      (summaryA, summaryB) =>
        summaryA + statusRatingStars[summaryB.qualification],
      0
    ) / filteredRatings.length;
  return round(rating, 1);
}
