import { Box, Typography } from "@material-ui/core";

import PeopleSearch from "assets/images/adminLandlord/PeopleSearch.svg";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import useEmptyEvaluationStyles from "./EmptyEvaluationStyles";

const EmptyEvaluation = () => {
  const classes = useEmptyEvaluationStyles();
  return (
    <Box className={classes.noApplicants}>
      <img
        src={PeopleSearch}
        alt="people-search"
        className={classes.personSearchIcon}
      />
      <Typography className={classes.text}>
        ¡Muy pronto encontraremos al mejor
      </Typography>
      <Typography className={classes.text}>
        postulante para tu hogar!
      </Typography>
      <DesignSystemButton
        variant="primary"
        size="medium"
        label="Seguimos buscando"
        id="evaluate-button"
        disabled={true}
        buttonClassName={classes.button}
      />
    </Box>
  );
};

export default EmptyEvaluation;
