import { FC, SVGProps } from "react";

const LaundryIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.1731 7.34372H9.80263H10.4322H11.0481M18.0365 8.92146H6.87794M7.56105 5H17.4391C17.8432 5 18.1708 5.3276 18.1708 5.73171V19.2683C18.1708 19.6724 17.8432 20 17.4391 20H7.56105C7.15694 20 6.82935 19.6724 6.82935 19.2683V5.73171C6.82935 5.3276 7.15694 5 7.56105 5ZM15.2668 14.375C15.2668 15.9283 14.0076 17.1875 12.4543 17.1875C10.901 17.1875 9.64177 15.9283 9.64177 14.375C9.64177 12.8217 10.901 11.5625 12.4543 11.5625C14.0076 11.5625 15.2668 12.8217 15.2668 14.375Z"
      stroke="#263238"
      strokeWidth={1.6}
      strokeLinecap="round"
    />
  </svg>
);
export default LaundryIcon;
