import { FormProvider } from "react-hook-form";

import { setSecondCodebtor } from "apps/lead/context/evaluationContext/EvaluationActions";
import WorkerTypeSelection from "apps/lead/components/EvaluatorForm/WorkerTypeSelection";
import { useEvaluation } from "apps/lead/context/evaluationContext/EvaluationContext";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper/FormWrapper";
import DisclaimerFiles from "apps/lead/components/EvaluatorForm/DisclaimerFiles";
import FileSelection from "apps/lead/components/EvaluatorForm/FileSelection";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";

import useUploadFiles from "./hooks/useUploadFiles";

const SecondCodebtorUploadFiles = () => {
  const { state } = useEvaluation();
  const {
    secondCodebtor: { workerType, files },
    activeStep,
    property: { country },
    steps,
  } = state;

  const {
    methods,
    onSubmit,
    handleBackClick,
    handleChange,
    uploadFile,
    removeFile,
    requirements,
  } = useUploadFiles(
    state.firstCodebtor,
    workerType,
    country,
    setSecondCodebtor
  );

  return (
    <FormProvider {...methods}>
      <Header
        title="Documentación codeudor 2"
        subtitle="Adjunta los siguientes documentos señalados"
        activeStep={activeStep}
        steps={steps}
      />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper>
          <WorkerTypeSelection
            onHandleChange={handleChange}
            workerType={workerType}
          />
          {requirements.map((req) => (
            <FileSelection
              files={files}
              stateFiles={files[req.id] || []}
              label={req.text}
              fileType={req.id}
              key={req.id}
              helpTooltip={req?.tooltip}
              uploadFile={uploadFile}
              removeFile={removeFile}
            />
          ))}
          <DisclaimerFiles />
        </FormWrapper>
        <CallToAction label="Adjuntar" onHandleBackClick={handleBackClick} />
      </form>
    </FormProvider>
  );
};

export default SecondCodebtorUploadFiles;
