import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useLandlordProperty } from "context/landlordPropertyContext/landlordPropertyContext";
import { CurrentPage } from "context/landlordPropertyContext/landlordPropertyTypes";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import {
  setCurrentPage,
  getBankList,
  getPropertyList,
  setPropertyType,
  getPropertySuggestedPrice,
  getAvailableHours,
  getStatistics,
  fetchData,
} from "context/landlordPropertyContext/landlordPropertyActions";
import PropertyLayout from "apps/landlord/Layout/PropertyLayout";
import { useGetToken, useQuery } from "hooks";

import ScheduledVisitIndex from "./ScheduledVisit/index";
import useStyles from "./LandlordPropertyStyles";
import { parseProperty } from "./utils";

interface Props {
  pageToRender?: string;
}

export default function LandlordProperty({ pageToRender = "" }: Props) {
  const { getToken } = useGetToken();
  const history = useHistory();
  const { state, dispatch } = useLandlordProperty();
  const { propertyData, loading, propertyType } = state;
  const { id } = useParams<{ id: string }>();
  const query = useQuery();

  // set currentPage
  useEffect(() => {
    const page = query.get("page") as CurrentPage;
    if (page) {
      dispatch(setCurrentPage(page));
    }
  }, []);

  useEffect(() => {
    setPropertyType(query.get("type"), dispatch);
    getToken().then((authToken) => {
      fetchData(id, authToken, dispatch);
      getBankList(authToken, dispatch);
      getStatistics(id, authToken, dispatch);
    });
  }, []);

  useEffect(() => {
    getToken().then((authToken) => {
      const rentorData = parseProperty(propertyData);
      if (propertyData.country && propertyType) {
        getPropertyList(
          propertyData.country,
          propertyData.latitud,
          propertyData.longitud,
          propertyData.typeProperty,
          propertyType,
          50,
          [],
          authToken,
          dispatch
        );
        getPropertySuggestedPrice(rentorData, dispatch);
        getAvailableHours(propertyData.id, authToken, dispatch);
      }
    });
  }, [propertyData, propertyType]);

  const classes = useStyles();

  const handleBack = () => history.goBack();

  return (
    <PropertyLayout onHandleBack={handleBack} titlePage="">
      {pageToRender === "schedule" && (
        <Loading loading={loading} className={classes.loading}>
          <ScheduledVisitIndex />
        </Loading>
      )}
    </PropertyLayout>
  );
}
