import { SimilarExternalProperty } from "apps/landlord/services/propertyService/type";

const parseExternalProperty = (property: SimilarExternalProperty) => ({
  link: property.link,
  portal: property.portal,
  price: property.price,
  bedrooms: property.bedrooms,
  bathrooms: property.bathrooms,
  builtSurface: property.built_surface,
  totalSurface: property.total_surface,
  parkingSpots: property.parking_spots,
  level1Area: property.level_1_area,
  level2Area: property.level_2_area,
  latitude: property.latitude,
  longitude: property.longitude,
  scrapedAt: property.scraped_at,
  publicationDate: property.publication_date,
  priceClf: property?.price_clf,
});

export default parseExternalProperty;
