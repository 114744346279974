import { makeStyles, Theme } from "@material-ui/core";
import {
  NAVBAR_HEADER_HEIGHT_DESKTOP,
  NAVBAR_HEADER_HEIGHT_MOBILE,
  BOTTOM_NAVIGATION_DESKTOP,
  BOTTOM_NAVIGATION_MOBILE,
} from "../../utils/constants";

const useStyles = makeStyles<Theme>((theme) => ({
  rentPaymentLayoutRoot: {
    marginBottom: BOTTOM_NAVIGATION_MOBILE,
    marginTop: NAVBAR_HEADER_HEIGHT_MOBILE,
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    minHeight: `calc(100vh - ${NAVBAR_HEADER_HEIGHT_MOBILE}px - ${BOTTOM_NAVIGATION_MOBILE}px)`,
    [theme.breakpoints.up("sm")]: {
      marginBottom: BOTTOM_NAVIGATION_DESKTOP,
      marginTop: NAVBAR_HEADER_HEIGHT_DESKTOP,
      alignItems: "center",
      minHeight: `calc(100vh - ${NAVBAR_HEADER_HEIGHT_DESKTOP}px - ${BOTTOM_NAVIGATION_DESKTOP}px)`,
      padding: theme.spacing(3),
      gap: theme.spacing(3),
    },
  },
}));

export default useStyles;
