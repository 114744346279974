import { makeStyles, Theme } from "@material-ui/core/styles";

const useFileUploadStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    right: 0,
    bottom: 0,
    padding: theme.spacing(2),
    zIndex: theme.zIndex.snackbar,
    height: 110,
    width: "100%",
    border: "1px solid #eceff1",
    boxShadow: "4px 4px 30px rgba(0, 43, 41, 0.15)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
}));

export default useFileUploadStyles;
