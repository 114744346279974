import { makeStyles, Theme } from "@material-ui/core";

const useErrorCardStyles = makeStyles<Theme>((theme) => ({
  infoColumnRental: {
    marginTop: theme.spacing(1),
    borderRadius: 8,
    backgroundColor: "white",
    padding: theme.spacing(0),
    gap: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  iconFlip: {
    transform: "scaleX(-1) !important",
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.57,
    },
  },
}));

export default useErrorCardStyles;
