import { useQueries } from "react-query";

import schedulerService from "apps/landlord/services/schedulerService";
import { useGetToken } from "hooks";

import parseScheduleFeedback from "./parsers";
import { ISchedules } from "../utils/types";

export const useScheduleFeedback = (schedules: ISchedules[]) => {
  const { getToken } = useGetToken();

  const schedulesData = useQueries(
    schedules.map((schedule) => ({
      queryKey: ["schedules", schedule.id],
      queryFn: async () => {
        const authToken = await getToken();
        const response = await schedulerService.getScheduleFeedback(
          schedule.id,
          authToken
        );
        const data = response.data.results;
        return data.length > 0 ? parseScheduleFeedback(data[0]) : null;
      },
      enabled: !!schedules,
    }))
  );

  const getFilteredSchedules = () =>
    schedulesData.filter(
      (schedule) => schedule.data && !!schedule.data?.comment
    );

  return {
    feedbacks: getFilteredSchedules(),
  };
};

export default useScheduleFeedback;
