/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import { ReactNode, createContext, useReducer, useContext } from "react";

import apiReducer, { initialState } from "./EvaluationReducer";
import { State, Dispatch } from "./EvaluationTypes";

interface ApiProviderProps {
  children: ReactNode;
  initialState?: State;
}

const ApiContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

const ApiProvider = ({
  children,
  initialState: givenInitialState,
}: ApiProviderProps) => {
  const [state, dispatch] = useReducer(
    apiReducer,
    givenInitialState || initialState
  );

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { state, dispatch };
  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

const useApi = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error("useApi must be used within a ApiProvider");
  }
  return context;
};

export { ApiProvider as EvaluationProvider, useApi as useEvaluation };
