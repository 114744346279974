import { makeStyles, Theme } from "@material-ui/core/";

const useCompanySelectorStyles = makeStyles<Theme>((theme) => ({
  ceFormSectionSelect: {
    backgroundColor: theme.palette.grey[50],
    paddingLeft: theme.spacing(1),
    borderRadius: 4,
    "&:focus": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  ceFormSectionLabel: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    lineHeight: 1.4,
    marginBottom: theme.spacing(0.5),
    color: theme.palette.grey[900],
  },
  ceFormSectionRequiredField: {
    color: theme.palette.primary.main,
  },
  ceFormSectionTextField: {
    marginBottom: theme.spacing(2),
    width: "100%",
    borderRadius: 4,
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default useCompanySelectorStyles;
