import { useHistory } from "react-router-dom";

import AdsBanner from "../AdsBanner/AdsBanner";

import referPartnerAdBg from "assets/images/adminLandlord/refer-bg.png";

const ReferPartner = ({ isMobile = false }) => {
  const history = useHistory();
  const handleOnClick = () => history.push("/propietary/refer-partner");

  return (
    <AdsBanner
      isMobile={isMobile}
      bgImage={referPartnerAdBg}
      onClick={handleOnClick}
      hasImgGradient
    >
      <AdsBanner.HighlightedText>Comparte Houm</AdsBanner.HighlightedText>
      <AdsBanner.Text>
        &nbsp;y <wbr />
        aumenta tus ganancias
      </AdsBanner.Text>
      <AdsBanner.Button
        isMobile={isMobile}
        label="Invitar amigos"
        onClick={handleOnClick}
      />
    </AdsBanner>
  );
};

export default ReferPartner;
