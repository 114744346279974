import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  listContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(2),
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: theme.spacing(5),
      justifyItems: "center",
    },
  },
  item: {},
}));
