import classNames from "classnames";
import { isMobile } from "react-device-detect";

import { Box, Card, Typography } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";

import { useApplicant } from "apps/landlord/pages/Applicant/context/ApplicantContext";
import { Views } from "apps/landlord/pages/Applicant/context/ApplicantContext.types";
import { Application } from "models/Evaluation";
import DSTag from "Components/Admin/UIComponents/DesignSystem/DSTag/DSTag";
import {
  getCreatedAt,
  getNameMainApplicant,
  getTotalSalary,
} from "../../utils";
import useHistorialCardStyles from "./HistorialCardStyles";
import { getStatusText, getStatusType } from "./utils";

interface HistorialCardProps {
  application: Application;
  selectedApplication: Application;
  handleSelectedApplication: (application: Application) => void;
}

const HistorialCard = ({
  application,
  selectedApplication,
  handleSelectedApplication,
}: HistorialCardProps) => {
  const { handleCurrentView } = useApplicant();

  const isSelected = application?.id === selectedApplication?.id;
  const classes = useHistorialCardStyles({ isSelected });

  const HandleMobileHistorialSelect = () => {
    handleSelectedApplication(application);
    handleCurrentView(Views.MOBILE_APPLICANT_DETAIL);
  };

  return (
    <Card
      className={classes.applicantHistorialCard}
      onClick={() => {
        handleSelectedApplication(application);
      }}
    >
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>
          {getNameMainApplicant(application.applicants)}
        </Typography>
        <Box>
          <DSTag
            type={getStatusType(application.status)}
            text={getStatusText(application.status)}
          />
        </Box>
      </Box>
      <Typography className={classes.text}>
        Fecha: {getCreatedAt(application.created_at)}
      </Typography>
      <Typography className={classes.text}>
        {`Renta Total: `}
        <span className={classes.boldText}>
          {getTotalSalary(application.total_salary)}
        </span>
      </Typography>
      {isMobile && isSelected && (
        <Box display="flex" justifyContent="space-between">
          <Typography
            className={classNames(classes.text, classes.reevaluate)}
            onClick={HandleMobileHistorialSelect}
          >
            ¿Quieres re-evaluar al postulante?
          </Typography>
          <ChevronRight onClick={HandleMobileHistorialSelect} />
        </Box>
      )}
    </Card>
  );
};

export default HistorialCard;
