export const classes = {
  searchDropDownRoot: "w-full relative mt-1",
  searchDropDownContainer: "absolute z-20 w-full bg-[#F4F0FA] rounded-b-md",
  searchDropDownSuggestionsContainer:
    "text-p14 max-h-40 overflow-y-auto w-full",
  searchDropDownSuggestion: "space-x-1 px-4 py-2 hover:bg-black-20",
  searchDropDownDisclaimerContainer: "w-full py-2 px-4 flex",
  searchDropDownDisclaimerText: "relative text-black-80 text-p14",
  searchDropDownDisclaimerLoadingText: "relative ml-4 text-black-80 text-p14",
};
