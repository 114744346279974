import Layout from "Components/Admin/UIComponents/paymentLayout/Layout";
import NotebookPerson from "assets/images/notebookPerson.png";

const ErrorPayment = () => (
  <Layout
    title="Hubo un problema al obtener la orden de pago"
    label="Error"
    image={NotebookPerson}
  >
    <div className="flex flex-col gap-6">
      <p className="text-p16 md:text-p18"></p>
    </div>
  </Layout>
);

export default ErrorPayment;
