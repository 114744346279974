import { makeStyles, Theme } from "@material-ui/core";

const useSuggestionStyles = makeStyles<Theme>((theme) => ({
  suggestionContainerComponent: {
    backgroundColor: theme.palette.grey[5],
    cursor: "pointer",
    padding: theme.spacing(1),
    borderRadius: 8,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#FEF1F0",
    },
  },
  suggestionName: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[900],
    margin: 0,
  },
  title: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
  },
}));

export default useSuggestionStyles;
