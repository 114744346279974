import { IoWarningOutline as WarningIcon } from "@houm-com/ui-icons";
import { Box, Typography } from "@material-ui/core";

import useHabitableDisclaimerStyles from "./HabitableDisclaimerStyles";

const HabitableDisclaimer = () => {
  const classes = useHabitableDisclaimerStyles();
  return (
    <Box className={classes.container}>
      <WarningIcon color="#900000" className={classes.icon} />
      <Typography component="strong" className={classes.text}>
        Esta propiedad necesita arreglos importantes para ser publicada
      </Typography>
    </Box>
  );
};

export default HabitableDisclaimer;
