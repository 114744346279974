import { makeStyles, Theme } from "@material-ui/core";

const useMobileApplicationCardStyles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    display: "block",
  },
  infoContainer: {
    marginLeft: theme.spacing(1),
  },
}));

export default useMobileApplicationCardStyles;
