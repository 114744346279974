import { useQuery, UseQueryResult } from "react-query";

import propertyService from "apps/landlord/services/propertyService";
import { ISchedules } from "models/Property";
import { useGetToken } from "hooks";

const usePhotoScheduled = (propertyUid: string) => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    data,
    isError,
    isSuccess,
  }: UseQueryResult<ISchedules, Error> = useQuery<ISchedules, Error>(
    ["photos_scheduled", propertyUid],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.getScheduledPhotos(
        propertyUid,
        authToken
      );
      const filterScheduled = response.data.filter(
        (schedule) =>
          schedule.schedule_type.name === "Photo" &&
          schedule.status !== "Cancelled"
      );
      if (filterScheduled.length === 0) return null;
      const mostRecentSchedule = filterScheduled.reduce((acc, cur) =>
        cur.date > acc.date ? cur : acc
      );
      return mostRecentSchedule;
    },
    {
      enabled: !!propertyUid,
    }
  );
  return {
    isLoading,
    data,
    isError,
    isSuccess,
  };
};

export default usePhotoScheduled;
