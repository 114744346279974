import { cn } from "@houm-com/ui/utils";

import { classes } from "./TitleStyles";

interface Props {
  className?: string;
}

const Title: React.FC<Props> = ({ children, className }) => (
  <h1 className={cn(classes.titleRoot, className)}>{children}</h1>
);

export default Title;
