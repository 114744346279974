import { useMutation } from "react-query";

import evaluationService from "apps/lead/services/EvaluationService";
import { countryCode } from "utils";
import { useGetToken } from "hooks";

import { UploadFilesDataType } from "./utils/types";

export const useUploadFiles = () => {
  const { getToken } = useGetToken();
  return useMutation(async (data: UploadFilesDataType) => {
    const authToken = await getToken();
    const response = await evaluationService.loadDocumentFiles(
      data.files,
      countryCode(data.country),
      data.evaluationId,
      data.userId,
      authToken
    );
    return response?.data;
  });
};

export default useUploadFiles;
