import { makeStyles, Theme } from "@material-ui/core";

const useScheduleStepStyles = makeStyles<Theme>((theme) => ({
  shedulePhotoContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    justifyContent: "flex-start",
    minHeight: "unset",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 5),
    },
  },
  shedulePhotoContentText: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  schedulePhotoTitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(28),
  },
  schedulePhotoDescription: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(22),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
}));

export default useScheduleStepStyles;
