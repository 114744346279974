import { ReactNode } from "react";

import { Divider, Typography } from "@material-ui/core";

import { useLoiForm } from "context/loiFormContext/loiFormContext";
import { filterFieldByCountry } from "../utils";
import { FieldProp } from "context/loiFormContext/loiFormFields";

interface FilteredInputProps {
  fieldInfo: FieldProp;
  children: ReactNode;
  showDivider?: boolean;
}

const FilteredInput: React.FC<FilteredInputProps> = ({
  fieldInfo,
  children,
  showDivider = true,
}) => {
  const { state } = useLoiForm();

  if (filterFieldByCountry(fieldInfo, state.country)) return null;

  if (showDivider)
    return (
      <>
        {children}
        <Divider />
      </>
    );

  return (
    <Typography>
      <b>{fieldInfo.label(state.country)}</b>: {children}
    </Typography>
  );
};

export default FilteredInput;
