import { makeStyles, Theme } from "@material-ui/core";

const callButtonGreenBackgroundColor = "#2ecc71";

export default makeStyles<Theme, { showcallStatusMessage?: boolean }>(
  (theme) => ({
    formContainer: {
      padding: theme.spacing(0.5, 1),
    },
    selectorLabel: {
      color: "#92a5bb",
      fontSize: theme.typography.pxToRem(12),
      position: "relative",
      transform: "none",
      width: "100%",
      textAlign: "center",
      marginTop: theme.spacing(1),
    },
    underline: {
      "&&&:before": {
        borderColor: theme.palette.grey["200"],
      },
      "&&:after": {
        borderColor: theme.palette.grey["200"],
      },
      "&:-webkit-outer-spin-button": {
        WebkitAppearance: null,
        margin: 0,
      },
      "&:-webkit-inner-spin-button": {
        WebkitAppearance: null,
        margin: 0,
      },
    },
    selectorContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: theme.spacing(0, 1),
      marginTop: 0,

      "& .MuiInputBase-root": {
        width: "100%",
        borderRadius: "100rem",
        border: `1px solid ${theme.palette.grey[900]}`,
        "& .MuiSelect-selectMenu": {
          padding: theme.spacing(1, 1),
        },
      },
    },
    phoneDialNumbersContainer: {
      padding: theme.spacing(2),
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gridTemplateRows: "repeat(4, 1fr)",
      gap: theme.spacing(1),
    },
    phoneDialNumber: {
      border: `1px solid ${theme.palette.primary.main}`,
      transition: "0.3s",
    },
    phoneDialLastNumber: {
      gridColumn: "2/3",
    },
    callButtonContainer: {
      width: "100%",
      padding: theme.spacing(0, 2),
    },
    callButton: {
      width: "100%",
      color: "white",
      fontWeight: 700,
      transition: "0.3s",
      "&:hover": {
        filter: "brightness(1.05)",
      },
    },
    greenBg: {
      backgroundColor: callButtonGreenBackgroundColor,
      "&:hover": {
        backgroundColor: callButtonGreenBackgroundColor,
        filter: "brightness(1.05)",
      },
    },
    redBg: {
      backgroundColor: "red",
      "&:hover": {
        backgroundColor: "red",
      },
    },
    callStatusMessage: {
      width: "100%",
      textAlign: "center",
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.primary.light,
      marginBottom: 10,
      visibility: (props) =>
        props.showcallStatusMessage ? "visible" : "hidden",
    },
  })
);
