import { format } from "date-fns";
import es from "date-fns/locale/es";

export const getHourOptions = (targetDate, availability) => {
  if (!availability) return [];
  const dateString = format(targetDate, "yyyy-MM-dd");
  const availableHours = availability?.parsedAvailability[dateString];

  return availableHours?.map((hour) => ({
    id: new Date(hour).getTime(),
    label: format(new Date(hour), "HH:mm") as `${string}:${string}`,
    fullDate: hour,
  }));
};

export const getReadableDate = (dateString: string) => {
  if (!dateString) return "";
  return format(new Date(dateString), "EEEE, dd 'de' MMMM 'a las' HH:mm", {
    locale: es,
  });
};
