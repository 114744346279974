import { FormProvider, SubmitHandler } from "react-hook-form";

import FieldInputFile from "@houm-com/ui/FieldInputFile";

import { dependentFilesSchema } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/DependentForm/utils/validationSchema";
import { IDependentFilesForm } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/DependentForm/utils/types";
import { allowedMimeTypes } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/utils/constants";
import { parserFileList } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/utils/parsers";
import { resetEmploymentData } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/utils/utils";
import {
  setActiveStep,
  setUserInformation,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper";
import useReactHookForm from "hooks/useReactHookForm";

const DependentForm = () => {
  const {
    state: { user: userInformation, activeStep },
    dispatch,
  } = useEvaluation();

  const { methods, errors } = useReactHookForm<IDependentFilesForm>({
    formSchema: dependentFilesSchema,
    formOptions: {
      defaultValues: {
        laborCertificate: userInformation.employmentFiles?.laborCertificate,
      },
    },
  });

  const onSubmit: SubmitHandler<IDependentFilesForm> = (data) => {
    const parsedData = parserFileList<IDependentFilesForm>(data);
    dispatch(
      setUserInformation({
        ...userInformation,
        employmentFiles: {
          ...resetEmploymentData(),
          laborCertificate: parsedData.laborCertificate[0],
        },
      })
    );
    dispatch(setActiveStep(activeStep + 1));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper classNameFormWrapper="flex flex-col">
          <FieldInputFile
            id="laborCertificate"
            label="Contrato o certificado laboral, en donde se especifique el tipo de contrato"
            allowedMimeTypes={allowedMimeTypes}
            classNameInputButton="!w-full"
            splitLength={40}
            required
            form="novalidateform"
            file={userInformation.employmentFiles?.laborCertificate}
            errorMessage={errors.laborCertificate?.message}
            {...methods.register("laborCertificate")}
          />
        </FormWrapper>
        <CallToAction label="Continuar" />
      </form>
    </FormProvider>
  );
};

export default DependentForm;
