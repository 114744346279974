import { FC, SVGProps } from "react";

const CleaningIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.7367 8.90138L14.2505 3.60958C14.2553 3.55805 14.2375 3.50693 14.1991 3.47227C13.7027 3.02453 12.5105 2.4808 11.2043 3.47011C11.1577 3.50545 11.133 3.56332 11.1384 3.62159L11.6511 8.90138M7.13228 6.30904L5.83218 5.83154M3.83008 16.9109L4.34629 14.846C4.34974 14.8322 4.35148 14.8181 4.35148 14.8039V11.0017M4.35148 11.0017V4.01154C4.35148 3.93179 4.40576 3.86227 4.48313 3.84293L6.91633 3.23463C7.02602 3.2072 7.13228 3.29017 7.13228 3.40324V11.1524C7.13228 11.2569 7.04066 11.3378 6.93693 11.3248L4.35148 11.0017ZM7.13228 7.69945L5.83218 8.74225M9.91309 17.0847H15.4747C15.8586 17.0847 16.1699 16.7734 16.1699 16.3895V12.2183C16.1699 10.2985 14.6136 8.74225 12.6939 8.74225C10.7741 8.74225 9.21789 10.2985 9.21789 12.2183V16.3895C9.21789 16.7734 9.52914 17.0847 9.91309 17.0847Z"
      stroke="#263238"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
  </svg>
);
export default CleaningIcon;
