import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

export const useNegotiatonsTabsStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export const useTabStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: "none",
    fontWeight: "bold",
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(18),
    marginRight: theme.spacing(4),
    [theme.breakpoints.down(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
    },
    "&:hover": {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    "&$selected": {
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
    "&:focus": {
      color: theme.palette.primary.main,
    },
  },
  selected: {},
}));

export const useAntTabsStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: "24px 24px 0px 0px",
    backgroundColor: "white",
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}));
