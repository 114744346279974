import { Locale } from "models/Countries";

const DEFAULT_LOCATIONS = {
  co: {
    lat: 4.693901299999999,
    lng: -74.0853727,
  },

  cl: {
    lat: -33.4487,
    lng: -70.6419,
  },
  mx: {
    lat: 19.419444,
    lng: -99.145556,
  },
};

export const getDefaultLocation = (locale: Locale) => DEFAULT_LOCATIONS[locale];
