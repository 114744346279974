import { Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { setActualStep } from "apps/lead/context/evaluationContext/EvaluationActions";
import { useEvaluation } from "apps/lead/context/evaluationContext/EvaluationContext";

import useWarningNoCodebtorStyles from "./WarningNoCodebtorStyles";

interface Props {
  createEvaluation: () => void;
  isLoading: boolean;
}

const WarningNoCodebtor = ({ createEvaluation, isLoading }: Props) => {
  const { dispatch } = useEvaluation();
  const classes = useWarningNoCodebtorStyles();
  const CODEBTOR_FORM_STEP = 4;

  const handleBackCodebtor = () => {
    setActualStep(CODEBTOR_FORM_STEP, dispatch);
  };

  const handleCreateEvaluation = () => {
    createEvaluation();
  };

  return (
    <Grid container>
      <Grid item className={classes.textContainer}>
        <Typography component="span" className={classes.text}>
          Recuerda que para arrendar, la suma de las rentas del arrendatario y
          los codeudores, debe ser al menos <strong>3 veces el precio</strong>{" "}
          del arriendo.
        </Typography>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="flex-end"
        className={classes.buttonContainer}
      >
        <Typography
          component="span"
          className={classes.textButton}
          onClick={handleBackCodebtor}
          aria-label="Agregar codeudor"
        >
          Agregar codeudor
        </Typography>
        <DesignSystemButton
          label="Finalizar"
          size="small"
          variant="primary"
          type="button"
          onClick={handleCreateEvaluation}
          ariaLabel="Finalizar evaluación"
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
};

export default WarningNoCodebtor;
