import { useQuery } from "react-query";

import { addressSearchResultsSchema } from "apps/lead/components/AddressSuggester/utils/types";
import addressService from "apps/lead/services/AddressService";
import { Locale } from "models/Countries";

const useAddressSearch = ({
  addressQuery,
  countryCode,
}: {
  addressQuery: string;
  countryCode: Locale;
}) => {
  const response = useQuery(
    ["locations/address/search", countryCode, { addressQuery }],
    async () => {
      const { data } = await addressService.searchAddress({
        countryCode,
        addressQuery,
      });
      const parsedData = addressSearchResultsSchema.parse(data);
      return parsedData.results;
    },
    {
      enabled: !!addressQuery,
    }
  );

  return response;
};

export default useAddressSearch;
