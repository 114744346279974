import { Box } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import useButtonsSectionStyles from "./ButtonsSectionStyles";

interface Props {
  disabled?: boolean;
  edit: boolean;
  setEdit: (edit: boolean) => void;
  submitting?: boolean;
}

const ButtonsSection = ({
  disabled,
  edit,
  setEdit,
  submitting = false,
}: Props) => {
  const classes = useButtonsSectionStyles({ edit });

  return (
    <Box className={classes.buttonsSectionRoot}>
      {edit && (
        <>
          <DesignSystemButton
            label="Cancelar"
            size="small"
            variant="tertiaryB"
            buttonClassName={classes.button}
            onClick={() => setEdit(false)}
          />
          <DesignSystemButton
            label="Actualizar"
            size="small"
            variant="primary"
            buttonClassName={classes.button}
            type="submit"
            isLoading={submitting}
            disabled={disabled}
          />
        </>
      )}
      {!edit && (
        <DesignSystemButton
          label="Editar"
          size="small"
          variant="primary"
          buttonClassName={classes.button}
          onClick={() => setEdit(true)}
        />
      )}
    </Box>
  );
};

export default ButtonsSection;
