import { mixed } from "yup";

export const requiredText = "Obligatorio";

type Files = {
  [key: string]: FileList;
};

const REQUIRE_FILE_LENGTH = 3;

export const checkFilesLength = () =>
  mixed()
    .nullable()
    .test("fileLength", requiredText, (value: Files) => {
      if (!value) return false;
      const fileLength = Object.values(value).filter((file) => file.length > 0);
      return fileLength.length === REQUIRE_FILE_LENGTH;
    });
