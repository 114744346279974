import { makeStyles, Theme } from "@material-ui/core/styles";

const useCallToActionStyles = makeStyles((theme: Theme) => ({
  stepName: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: "1.5em",
    color: theme.palette.common.black,
  },
  stepTitle: {
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "1.5em",
    color: theme.palette.common.black,
  },
  backButton: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    border: "none",
  },
}));

export default useCallToActionStyles;
