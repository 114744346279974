import { makeStyles, Theme } from "@material-ui/core";

const useCardStyles = makeStyles<Theme>((theme) => ({
  card: {
    padding: theme.spacing(2),
    backgroundColor: "white",
    gap: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
      borderRadius: 8,
    },
  },
  cardTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    lineHeight: 1.44,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.4,
    },
  },
}));

export default useCardStyles;
