import { Typography } from "@material-ui/core";
import { add } from "date-fns";

import { formatDate } from "apps/tenant/utils/utils";

const getCLConfirmationMessage = (
  contractDate: Date,
  leavingDate: Date,
  endNoticePeriod
) => {
  const oneYearFromContract = add(contractDate, { years: 1 });
  const noticeDaysFromToday = add(new Date(), { days: endNoticePeriod ?? 30 });
  const formattedLeavingDate = formatDate(leavingDate);
  const formattedContractDate = formatDate(contractDate);
  if (leavingDate >= oneYearFromContract) {
    //Contract year is over
    if (leavingDate > noticeDaysFromToday) {
      //Over notice period
      return (
        <Typography component="p">
          Al continuar empezaremos tu proceso de salida para el{" "}
          <strong>{formattedLeavingDate}</strong> ¿Quieres continuar con este
          proceso?
        </Typography>
      );
    }
    return (
      <>
        <Typography component="p">
          Hemos detectado que estás solicitando tu salida con menos de{" "}
          {endNoticePeriod} días de anticipación para el día{" "}
          <strong>{formattedLeavingDate}</strong>.
        </Typography>
        <Typography component="p">
          El no cumplir con el período de previo aviso generará una multa
          equivalente a este periodo. Por ejemplo, si debes notificar a Houm con
          30 días de anticipación y solicitas la salida con 20 días aún así
          deberás pagar cómo <strong>multa</strong> los 10 días{" "}
          <strong>restantes</strong> para cumplir el periodo de aviso.
        </Typography>
        <Typography component="p">
          ¿Deseas continuar con la salida en esta fecha?
        </Typography>
      </>
    );
  }
  //Contract year is not over
  return leavingDate > noticeDaysFromToday ? (
    <>
      <Typography component="p">
        Hemos detectado que tu salida se debe a un término anticipado, tu inicio
        de contrato fue el <strong>{formattedContractDate}</strong> y estás
        solicitando tu salida para el día{" "}
        <strong>{formattedLeavingDate}</strong>
      </Typography>
      <Typography component="p">
        Al continuar se aplicará una multa equivalente a la no devolución de
        garantía. ¿Quieres continuar?
      </Typography>
    </>
  ) : (
    <>
      <Typography component="p">
        Hemos detectado que estas solicitando tu salida con menos de{" "}
        {endNoticePeriod} días de anticipación para el día{" "}
        <strong>{formattedLeavingDate}</strong> y además esto se debe a un
        término anticipado, ya que tu contrato inició el{" "}
        <strong>{formattedContractDate}</strong>
      </Typography>
      <Typography component="p">
        El no cumplir el año de contrato está sujeto a la{" "}
        <strong>no devolución de la garantía</strong>.
      </Typography>
      <Typography component="p">
        El no cumplir con el período de previo aviso generará una{" "}
        <strong>multa</strong> equivalente a este periodo. Por ejemplo, si debes
        notificar a Houm con 30 días de anticipación y solicitas la salida con
        20 días aún así deberás pagar cómo multa los 10 días restantes para
        cumplir el periodo de aviso.
      </Typography>
      <Typography component="p">
        ¿Deseas continuar con la salida en esta fecha?
      </Typography>
    </>
  );
};

export default getCLConfirmationMessage;
