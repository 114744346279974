import { useQuery, UseQueryResult } from "react-query";

import { useProperty } from "apps/landlord/hooks";
import propertiesDuesService from "apps/landlord/services/propertiesDuesService";
import { parseBillDetails } from "apps/landlord/services/propertiesDuesService/parsers";
import {
  CompanyType,
  IBillDetails,
} from "apps/landlord/services/propertiesDuesService/type";
import { useGetToken } from "hooks";
import { Locale } from "models/Countries";

const useBillsDetails = (country: Locale, companyTypes?: CompanyType[]) => {
  const { getToken } = useGetToken();

  const { propertyData, propertyLoading, propertyError } = useProperty();

  const {
    isFetching,
    isLoading: billsLoading,
    error: billsError,
    data = [],
  }: UseQueryResult<IBillDetails[], Error> = useQuery<IBillDetails[], Error>(
    ["propertyBillsDetails", propertyData, country],
    async () => {
      const authToken = await getToken();
      const response = await propertiesDuesService.getBillsDetails(
        propertyData.id,
        authToken
      );
      return parseBillDetails(response.data.bill_details, companyTypes);
    },
    {
      enabled: country !== "mx" && !!propertyData?.id,
    }
  );

  return {
    isFetching,
    isLoading: propertyLoading || billsLoading,
    error: propertyError || billsError,
    data,
    inProcess: [],
    done: [],
  };
};

export default useBillsDetails;
