import { FormControlLabel, Switch } from "@material-ui/core";
import useCustomSliderStyles from "./CustomSliderStyles";

type Props = {
  label?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
};

const CustomSlider = ({ label, value, onChange, disabled }: Props) => {
  const classes = useCustomSliderStyles();
  return (
    <FormControlLabel
      classes={{ label: classes.label }}
      control={
        <Switch
          disabled={disabled}
          className={classes.switch}
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
      }
      label={label}
    />
  );
};

export default CustomSlider;
