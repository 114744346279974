import { makeStyles, Theme } from "@material-ui/core";

const useRerntRequirementItemStyles = makeStyles((theme: Theme) => ({
  rentRequirementsItem: {
    marginTop: theme.spacing(1),
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1),
    },
  },
  iconWrapper: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0.5),
      marginRight: theme.spacing(2),
      borderRadius: 2,
      backgroundColor: "#ECF2F4",
    },
    "& svg": {
      color: "#46788C",
      width: 18,
      height: 18,
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default useRerntRequirementItemStyles;
