import { FormProvider, SubmitHandler } from "react-hook-form";

import FieldRadioButton from "@houm-com/ui/FieldRadioButton";

import { workerTypeSchema } from "apps/lead/pages/EvaluatorForm/components/WorkerTypeForm/utils/validationSchema";
import { workerTypeOptions } from "apps/lead/pages/EvaluatorForm/components/WorkerTypeForm/utils/constants";
import { IWorkerTypeForm } from "apps/lead/pages/EvaluatorForm/components/WorkerTypeForm/utils/types";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper/FormWrapper";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import useReactHookForm from "hooks/useReactHookForm";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import {
  setActiveStep,
  setUserInformation,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";

const WorkerTypeForm = () => {
  const {
    state: { user: userInformation, activeStep },
    dispatch,
  } = useEvaluation();
  const { methods, errors } = useReactHookForm<IWorkerTypeForm>({
    formSchema: workerTypeSchema,
    formOptions: {
      defaultValues: {
        workerType: userInformation.employment?.workerType,
      },
    },
  });

  const onSubmit: SubmitHandler<IWorkerTypeForm> = (data) => {
    dispatch(
      setUserInformation({
        ...userInformation,
        employment: {
          ...userInformation.employment,
          workerType: data.workerType,
        },
      })
    );
    dispatch(setActiveStep(activeStep + 1));
  };

  return (
    <FormProvider {...methods}>
      <Header
        title="Cuéntanos un poco más de ti"
        stepTitle="Información Laboral"
      />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper>
          <FieldRadioButton
            label="Situación laboral"
            id="workerType"
            options={workerTypeOptions}
            required
            form="novalidateform"
            errorMessage={errors.workerType?.message}
            {...methods.register("workerType")}
          />
        </FormWrapper>
        <CallToAction label="Continuar" />
      </form>
    </FormProvider>
  );
};

export default WorkerTypeForm;
