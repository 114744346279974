import { IUserBankAccount } from "apps/tenant/models/BankAccount";

export const getCurrentBankAccount = (
  bankAccounts: IUserBankAccount[],
  propertyId: string
) => {
  if (!bankAccounts || !bankAccounts.length) return undefined;
  return bankAccounts.find((account) => account.identifier === propertyId);
};

export default getCurrentBankAccount;
