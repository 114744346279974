import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  navbarHeight: number;
}

const useBankAccountsStyles = makeStyles<Theme, Props>((theme) => ({
  bankAccountsRoot: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(5),
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      padding: theme.spacing(5),
    },
  },
  leftContainer: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      top: ({ navbarHeight }) => `calc(${navbarHeight}px + 40px)`,
      gap: theme.spacing(2),
      height: `100%`,
      flexDirection: "column",
      position: "sticky",
      width: "30%",
    },
  },
  rightContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 2, 2, 2),
    [theme.breakpoints.up("sm")]: {
      width: "70%",
      padding: theme.spacing(0),
    },
  },
  spinnerContainer: {
    height: "auto",
  },
}));

export default useBankAccountsStyles;
