import { IoChevronForward, IoDocumentsOutline } from "@houm-com/ui-icons";
import { Typography } from "@material-ui/core";

import { IDocumentList } from "../../utils/types";
import useMenuItemStyles from "./MenuItemStyles";

interface Props {
  document: IDocumentList;
}

const MenuItem = ({ document }: Props) => {
  const classes = useMenuItemStyles();

  const onClickDocument = (link: string) => window.open(link, "_blank");

  return (
    <div
      className={classes.menuItem}
      role="button"
      tabIndex={0}
      onClick={() => onClickDocument(document.link)}
      onKeyDown={(event) => {
        if (event.key === "Enter" || event.key === " ") {
          onClickDocument(document.link);
        }
      }}
    >
      <div className={classes.container}>
        <IoDocumentsOutline className="w-6 h-[18px]" />
        <Typography className={classes.text}>{document.text}</Typography>
      </div>
      <IoChevronForward />
    </div>
  );
};

export default MenuItem;
