import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Box,
  Divider,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

import { useNegotiations } from "context/negotiationsContext/negotiationsContext";
import {
  setCurrentStep,
  setResponseToOffer,
} from "context/negotiationsContext/negotiationsActions";
import { ResponseToOffer } from "context/negotiationsContext/negotiationsTypes";
import useRejectFormStyles from "./RejectFormStyles";
import {
  ControlledSelection,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import { CURRENCIES } from "context/negotiationsContext/constants";
import { lastAmountValidationSchema } from "./validationSchema";
import CallToAction from "Components/Admin/UIComponents/atoms/CallToAction";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { useTheme } from "@material-ui/styles";

const RejectFormLastAmountStep = () => {
  const { state, dispatch } = useNegotiations();
  const { property } = state;
  const classes = useRejectFormStyles();
  const theme: Theme = useTheme();
  const isDesktop: boolean = useMediaQuery(theme.breakpoints.up("md"));

  const methods = useForm<Partial<ResponseToOffer>>({
    reValidateMode: "onBlur",
    resolver: yupResolver(lastAmountValidationSchema),
  });

  const onSubmit: SubmitHandler<ResponseToOffer> = (data) => {
    dispatch(setResponseToOffer(data));
    dispatch(setCurrentStep("resumeOffer"));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Typography className={classes.text}>
          ¿No estás de acuerdo con la oferta? Cuéntale a tu comprador el nuevo
          precio que podrías aceptar.
        </Typography>
        <ControlledTextField
          label="Último precio por la propiedad"
          id="lastAmount"
          textFieldProps={{ placeholder: "Ingresar un monto" }}
        />
        <Divider className={classes.divider} />
        <ControlledSelection
          id="currency"
          label="Tipo de moneda"
          options={CURRENCIES[property.country]}
          buttonClassName={classes.selectButton}
          classes={{ label: classes.label }}
        />
        <Divider className={classes.divider} />
        {isDesktop ? (
          <CallToAction>
            <Box className={classes.ctaContent}>
              <DesignSystemButton
                variant="primary"
                label="Continuar"
                type="submit"
                size="medium"
              />
            </Box>
          </CallToAction>
        ) : (
          <Box className={classes.ctaContent}>
            <DesignSystemButton
              variant="primary"
              label="Continuar"
              type="submit"
              size="medium"
            />
          </Box>
        )}
      </form>
    </FormProvider>
  );
};

export default RejectFormLastAmountStep;
