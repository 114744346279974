import * as yup from "yup";

export const requiredText = "Campo obligatorio";

type DocumentTypes = {
  value: number;
  identifier: string;
  label: string;
  regex: string;
};

const validationSchema = (documentTypeList: DocumentTypes[]) =>
  yup.object({
    documentType: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required(requiredText),
    document: yup
      .string()
      .required(requiredText)
      .test(
        "validationDocument",
        null,
        (value, { parent: { documentType } }) => {
          if (!documentType) return true;
          if (!value) return false;
          const docType = documentTypeList.find(
            (doc) => doc.value === documentType
          );
          const regex = new RegExp(docType.regex);
          if (regex.test(value.toUpperCase())) return true;
          return new yup.ValidationError(
            `${docType.label} no válido`,
            null,
            "document",
            "typeError"
          );
        }
      ),
  });

export default validationSchema;
