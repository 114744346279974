import { Box, Typography } from "@material-ui/core";

import { ContractResponse } from "apps/landlord/services/propertyContractService/types";
import {
  LOCALE_BASED_HELP_LINKS,
  LOCALE_BASED_UNSAFE_DATE_WARNING,
} from "./constants";

import useLeavingDateStepStyles from "./LeavingDateStyles";

interface WarningUnsafeDateProps {
  activeContract: ContractResponse;
}

const WarningUnsafeDate = ({ activeContract }: WarningUnsafeDateProps) => {
  const classes = useLeavingDateStepStyles();
  return (
    <Box className={classes.unsafeDateWarningBlock}>
      <Typography className={classes.unsafeDateWarningText}>
        {LOCALE_BASED_UNSAFE_DATE_WARNING[activeContract.country]}{" "}
        <a
          className={classes.unsafeDateWarningLink}
          href={LOCALE_BASED_HELP_LINKS[activeContract.country]}
        >
          aquí.
        </a>
      </Typography>
    </Box>
  );
};

export default WarningUnsafeDate;
