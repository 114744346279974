import { AxiosResponse } from "axios";
import qs from "query-string";

import { createHttp } from "services/helper";
import { Locale } from "models/Countries";
import { EVALUATIONS_URL } from "env";

import {
  CodebtorCreatedResponse,
  CreateCodebtorData,
  CreateEvaluationData,
  EvaluationByApplicantIds,
  EvaluationCreatedResponse,
} from "./types";

export default {
  getEvaluationsByApplicantId(
    country: Locale,
    ids: string[],
    token: string
  ): Promise<AxiosResponse<EvaluationByApplicantIds[]>> {
    return createHttp(token, EVALUATIONS_URL).get(
      `/${country}/v2/tenant/evaluation`,
      {
        params: {
          applicant_ids: ids,
        },
        paramsSerializer: (params) => qs.stringify(params),
      }
    );
  },

  createEvaluation(
    data: CreateEvaluationData,
    country: Locale,
    token: string
  ): Promise<AxiosResponse<EvaluationCreatedResponse>> {
    return createHttp(token, EVALUATIONS_URL).post(
      `/${country}/v2/tenant/evaluation`,
      data
    );
  },
  createCodebtor(
    data: CreateCodebtorData,
    country: Locale,
    evaluationId: number,
    token: string
  ): Promise<AxiosResponse<CodebtorCreatedResponse>> {
    return createHttp(token, EVALUATIONS_URL).post(
      `/${country}/v2/tenant/evaluation/${evaluationId}/codebtor`,
      data
    );
  },
  loadDocumentFiles(
    dataFiles: FormData,
    country: Locale,
    evaluationId: number,
    userId: number,
    token: string
  ) {
    return createHttp(token, EVALUATIONS_URL).post(
      `/${country}/v2/tenant/evaluation/${evaluationId}/person/${userId}/document`,
      dataFiles
    );
  },
};
