import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const usePhotoStyles = makeStyles<Theme>((theme) => ({
  photoContainer: {
    top: 0,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    alignContent: "flex-start",
    position: "absolute",
    padding: theme.spacing(4, 2),
    height: "100%",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
    gap: theme.spacing(2),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      gap: theme.spacing(0),
    },
  },
  photoInfoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(0),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(0, 3),
    },
  },
  addressStreet: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
    color: "white",
  },
  address: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    color: "white",
  },
  propertyId: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    color: "white",
  },
  backButton: {
    margin: 0,
    marginBottom: theme.spacing(5),
    color: "white",
    fontWeight: 700,
    "&:hover": {
      "& path": {
        color: theme.palette.primary.main,
      },
    },
  },
  backIcon: {
    fontSize: 16,
    color: "white",
  },
  link: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    color: "#EFF2F3",
    transition: "filter 0.3s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      filter: "brightness(0.8)",
    },
  },
}));

export default usePhotoStyles;
