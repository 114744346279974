import { CountryUpperCase } from "models/Countries";

export interface FieldProp {
  label: (country: string) => string;
  countries: CountryUpperCase[];
}

const landlord = {
  name: (): FieldProp => ({
    label: (country) => "Nombre",
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  lastName: (): FieldProp => ({
    label: (country) => "Apellido paterno",
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  secondLastName: (): FieldProp => ({
    label: (country) => "Apellido materno",
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  occupation: (): FieldProp => ({
    label: (country) => "Profesión",
    countries: ["Chile", "Mexico"],
  }),
  identificationType: (): FieldProp => ({
    label: (country) => "Tipo de identificación",
    countries: ["Chile", "Colombia", "Mexico"],
  }),
  identificationNumber: (): FieldProp => ({
    label: (country) => "Número de identificación",
    countries: ["Chile", "Colombia", "Mexico"],
  }),
  nationality: (): FieldProp => ({
    label: (country) => "Nacionalidad",
    countries: ["Colombia", "Chile", "Mexico"],
  }),
  civilStatus: (): FieldProp => ({
    label: (country) => "Estado civil",
    countries: ["Mexico", "Chile"],
  }),
  maritalRegime: (): FieldProp => ({
    label: (country) => "Régimen matrimonial",
    countries: ["Mexico", "Chile"],
  }),
  rfc: (): FieldProp => ({
    label: (country) => "RFC",
    countries: ["Mexico"],
  }),
};

const landlordDocs = {
  identificationDoc1: (): FieldProp => ({
    label: (country) =>
      country === "Colombia"
        ? "Cédula de ciudadanía frontal"
        : "Documento de identificación",
    countries: ["Colombia", "Mexico", "Chile"],
  }),
  identificationDoc2: (): FieldProp => ({
    label: (country) => "Cédula de ciudadanía trasero",
    countries: ["Colombia"],
  }),
  appraisalCertificate: (): FieldProp => ({
    label: (country) => "Certificado de avaluo detallado",
    countries: ["Chile"],
  }),
  propertyDeed: (): FieldProp => ({
    label: (country) => "Escritura de la propiedad",
    countries: ["Chile"],
  }),
  curp: (): FieldProp => ({
    label: (country) => "CURP",
    countries: ["Mexico"],
  }),
  rfc: (): FieldProp => ({
    label: (country) => "RFC",
    countries: ["Mexico"],
  }),
  birthCertificate: (): FieldProp => ({
    label: (country) => "Acta de nacimiento",
    countries: ["Mexico"],
  }),
  marriageCertificate: (): FieldProp => ({
    label: (country) => "Acta de matrimonio",
    countries: ["Colombia", "Mexico"],
  }),
};

const landlordBankData = {
  clabe: (): FieldProp => ({
    label: (country) => "CLABE",
    countries: ["Mexico"],
  }),
};

const propertyDocs = {
  propertyDeed: (): FieldProp => ({
    label: (country) => "Escritura",
    countries: ["Mexico"],
  }),
  propertyBill: (): FieldProp => ({
    label: (country) => "Boleta predial",
    countries: ["Mexico"],
  }),
  waterBill: (): FieldProp => ({
    label: (country) => "Boleta de agua (opcional)",
    countries: ["Mexico"],
  }),
  traditionCertificate: (): FieldProp => ({
    label: (country) => "Certificado de tradición y libertad",
    countries: ["Colombia"],
  }),
};

const brokerageContract = {
  signedContract: (): FieldProp => ({
    label: (country) => "Contrato de corretaje firmado",
    countries: ["Colombia"],
  }),
};

export default {
  landlord,
  landlordDocs,
  landlordBankData,
  propertyDocs,
  brokerageContract,
};
