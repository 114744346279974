import { DocumentOptionProps } from "context/closureFormContext/closureFormTypes";

export const handleDocumentTypeChange =
  (documentTypes: DocumentOptionProps[], setRegex) => (type: number) => {
    if (type) {
      const documentRegex = new RegExp(
        documentTypes.find((doc) => doc.value === type)?.validationRegex
      );
      setRegex(documentRegex);
    }
  };
