import { makeStyles, Theme } from "@material-ui/core/";

const defaultBreakpoint = "sm";

const usePropertyPagesMenuStyles = makeStyles<Theme>((theme) => ({
  menuButtonsContainer: {
    display: "grid",
    gap: theme.spacing(2),
    gridTemplateColumns: "repeat(4, 1fr)",
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      display: "flex",
      gap: theme.spacing(3),
      marginBottom: theme.spacing(0),
    },
  },
  menuTitle: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    lineHeight: 1.44,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.4,
    },
  },
}));

export default usePropertyPagesMenuStyles;
