import { Box, Typography } from "@material-ui/core";

import useNoInspectionCardStyles from "./NoInspectionCardStyles";

const NoInspectionCard = () => {
  const classes = useNoInspectionCardStyles();

  return (
    <Box className={classes.cardContainer}>
      <Typography className={classes.title}>
        Sin actas de inspección
        <span role="img" aria-label="paper">
          📄
        </span>
      </Typography>
      <Box>
        <Typography className={classes.text}>
          Aún no se han realizado ninguna inspección a tu propiedad.{" "}
        </Typography>
        <Typography className={classes.text}>
          Aquí podrás ver las actas de estas revisiones una vez realizadas.
        </Typography>
      </Box>
    </Box>
  );
};

export default NoInspectionCard;
