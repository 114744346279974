import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  paper: {
    boxShadow: "3px 4px 20px rgba(38, 50, 56, 0.15)",
    borderRadius: 8,
  },
  rootMenuItem: {
    margin: theme.spacing(1),
    borderRadius: 2,
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  icon: {
    marginLeft: -4,
  },
}));
