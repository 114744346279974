import { formatAddress } from "utils/createProperty";
import {
  Action,
  ActionType,
  AutoProfileProperty,
  Dispatch,
  InfoModal,
  PropertyData,
  Steps,
  User,
} from "./proprietaryFormTypes";

export const setPropertyData = (propertyData: PropertyData): Action => ({
  type: ActionType.SET_PROPERTY_DATA,
  propertyData,
});

export const updatePropertyData = (
  propertyData: Partial<PropertyData>
): Action => ({
  type: ActionType.UPDATE_PROPERTY_DATA,
  propertyData,
});

export const setStep = (step: Steps): Action => ({
  type: ActionType.SET_STEP,
  step,
});

export const setInfoModal = (infoModal: InfoModal): Action => ({
  type: ActionType.SET_INFO_MODAL,
  infoModal,
});

export const closeInfoModal = (): Action => ({
  type: ActionType.CLOSE_INFO_MODAL,
});

export const setIsOpenHelpModal = (open: boolean): Action => ({
  type: ActionType.SET_IS_OPEN_HELP_MODAL,
  open,
});

export const setUser = (user: User): Action => ({
  type: ActionType.SET_USER,
  user,
});

export const goToConfirmationStep = (
  data: AutoProfileProperty,
  dispatch: Dispatch
) => {
  dispatch(
    setPropertyData({
      formattedAddress: formatAddress(data.address),
      additionalInfo: data.moreInfo,
      propertyNumber: data.propertyNumber,
      deliveryDate: data.date,
      district: data.district?.value,
      propertyType: data.propertyType,
      service: data.service,
    })
  );
  dispatch(setStep(Steps.CONFIRMATION_STEP));
};
