import { ChangeEvent } from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import clx from "classnames";

import useCustomSelectStyles from "./CustomSelectStyles";

type Props = {
  label?: string;
  fullWidth?: boolean;
  value?: string | number;
  id: string;
  options: { value: string | number; label: string }[];
  borderRadius?: boolean;
  hasDropdownIcon?: boolean;
  onChange?: (
    event: ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => void;
};

const CustomSelect = ({
  label: selectLabel,
  fullWidth = false,
  value: selectValue,
  borderRadius = false,
  hasDropdownIcon = true,
  id,
  options,
  onChange,
}: Props) => {
  const classes = useCustomSelectStyles();
  return (
    <FormControl fullWidth={fullWidth} className={classes.container}>
      {selectLabel && (
        <InputLabel
          classes={{
            root: classes.label,
          }}
          id={id}
        >
          {selectLabel}
        </InputLabel>
      )}
      <Select
        classes={{
          select: clx(classes.select, { [classes.borderRadius]: borderRadius }),
        }}
        id={id}
        value={selectValue}
        onChange={onChange}
        disableUnderline
        IconComponent={hasDropdownIcon ? KeyboardArrowDown : () => null}
      >
        {options.map(({ value, label }) => (
          <MenuItem
            classes={{ root: classes.optionLabel }}
            value={value}
            key={label}
          >
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
