import { createContext, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import last from "lodash/fp/last";

import { countryCode as getCountryCode } from "utils";
import { useGetToken } from "hooks";

import { OptionTypes } from "Components/Admin/UIComponents/ControlledForm/ControlledList";
import { CountryUpperCase } from "models/Countries";
import { PropertyData } from "models/Property";
import LoiServices from "services/letterOfIntentService";
import {
  handleGetLoiOwnersError,
  handleGetNegotiationsError,
  handleGetPropertyDetailsError,
  handleSubmitLoiToReviewError,
  handleSubmitOwnerError,
  handleUploadOwnerDocsError,
  parseItentificationTypes,
  parseOwnerDataToService,
  sortNegotiationsByDate,
} from "./LoiFormContext.utils";
import {
  INITIAL_LOI_CONTEXT_DATA,
  LoiAddressData,
  LoiBankData,
  LoiContextState,
  LoiDocuments,
  LoiPersonalData,
  Owner,
} from "./LoiFormContext.types";
import { GetNegotiation } from "services/negotiationService/models";

const LoiContext = createContext<LoiContextState>(INITIAL_LOI_CONTEXT_DATA);

interface LoiContextProviderProps {
  children: React.ReactNode;
}

const LoiFormProvider = ({ children }: LoiContextProviderProps) => {
  /** Form steps */
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();

  /** Loi form data */
  const [personalData, setPersonalData] = useState<LoiPersonalData>(
    INITIAL_LOI_CONTEXT_DATA.state.personalData
  );
  const [addressData, setAddressData] = useState<LoiAddressData>(
    INITIAL_LOI_CONTEXT_DATA.state.addressData
  );
  const [bankData, setBankData] = useState<LoiBankData>(
    INITIAL_LOI_CONTEXT_DATA.state.bankData
  );
  const [documents, setDocuments] = useState<LoiDocuments>(
    INITIAL_LOI_CONTEXT_DATA.state.documents
  );
  const [documentTypes, setDocumentTypes] = useState<OptionTypes[]>([]);
  const [finishedProcess, setFinishedProcess] = useState(false);
  const [addingSecondaryOwner, setAddingSecondaryOwner] = useState(false);
  const [completedFirstForm, setCompletedFirstForm] = useState(false);
  const [mainOwner, setMainOwner] = useState<Owner>();

  /** Hooks and services */
  const [authToken, setAuthToken] = useState("");
  const [propertyDetails, setPropertyDetails] = useState<PropertyData>();
  const [negotiations, setNegotiations] = useState<GetNegotiation[]>([]);
  const [loiNegotiation, setLoiNegotiation] = useState<GetNegotiation>(null);
  const [loiOwners, setLoiOwners] = useState<Owner[]>([]);
  const { getToken } = useGetToken();
  const {
    getLoiOwners,
    getNegotiations,
    updateMainOwner,
    createNewOwner,
    sendLoiToReview,
    uploadOwnerDocuments,
    getPropertyDetails,
    getDocumentTypes,
  } = LoiServices(authToken);
  const { id: propertyId } = useParams<{ id: string }>();
  const country: CountryUpperCase = propertyDetails?.country || "Chile";
  const countryCode = getCountryCode(country);

  /** get auth token on firstload */
  useEffect(() => {
    getToken().then((token) => {
      setAuthToken(token);
    });
  }, []);

  /** get negotiations list post authtoken assignation */
  useEffect(() => {
    if (authToken) {
      getNegotiations(countryCode, propertyId)
        .then((res) => {
          const sortedNegotiations = res.data.sort(sortNegotiationsByDate);
          setNegotiations(sortedNegotiations);
        })
        .catch(handleGetNegotiationsError);
    }
  }, [authToken]);

  useEffect(() => {
    if (authToken) {
      getPropertyDetails(propertyId)
        .then((res) => {
          setPropertyDetails(res.data);
        })
        .catch(handleGetPropertyDetailsError);
    }
  }, [authToken]);

  /** pick active LOI form negotiation */
  useEffect(() => {
    if (negotiations.length) {
      const finishedNegotiations = negotiations.filter(
        (negotiation) => negotiation.last_bid_status === "Aceptada"
      );
      const lastAcceptedNegotiation = last(finishedNegotiations);
      setLoiNegotiation(lastAcceptedNegotiation);
    }
  }, [negotiations]);

  /** get owners list from target negotiation */
  useEffect(() => {
    if (loiNegotiation) {
      getLoiOwners(loiNegotiation.id, countryCode)
        .then((res) => {
          setLoiOwners(res.data);
        })
        .catch(handleGetLoiOwnersError);
    }
  }, [loiNegotiation]);

  /** set main owner data */
  useEffect(() => {
    if (loiOwners.length) {
      const mainOwner = loiOwners.find((owner) => owner.is_main_user);
      setMainOwner(mainOwner);
    }
  }, [loiOwners]);

  useEffect(() => {
    if (mainOwner) {
      setCompletedFirstForm(mainOwner.manually_reviewed);
    }
  }, [mainOwner]);

  /** get document types based on property specs */
  useEffect(() => {
    if (loiNegotiation) {
      getDocumentTypes(countryCode).then((res) => {
        const documentTypesCollection = res.data || [];
        const formattedDocumentTypes = documentTypesCollection.map(
          parseItentificationTypes
        );
        setDocumentTypes(formattedDocumentTypes);
      });
    }
  }, [loiNegotiation]);

  const updateOrCreateOwner = () => {
    const submitter = addingSecondaryOwner ? createNewOwner : updateMainOwner;
    const ownerData = parseOwnerDataToService(personalData, addressData);

    submitter(loiNegotiation.id, countryCode, ownerData).then((res) => {
      setCompletedFirstForm(true);
      setActiveStep(0);
      handleDocumentUploadPostSubmit(res.data);
    }, handleSubmitOwnerError);

    const handleDocumentUploadPostSubmit = (owner) => {
      const formattedDocuments = new FormData();
      Object.entries(documents).forEach(([key, value]) => {
        if (value) {
          formattedDocuments.append("files", value, `${key}-${value.name}`);
        }
      });
      if (addingSecondaryOwner) {
        formattedDocuments.append("user_id", owner.id);
      }
      uploadOwnerDocuments(
        loiNegotiation.id,
        countryCode,
        formattedDocuments
      ).catch(handleUploadOwnerDocsError);
    };
  };

  const submitLoiToReview = () => {
    sendLoiToReview(loiNegotiation.id, countryCode)
      .then((res) => {
        setFinishedProcess(true);
      })
      .catch(handleSubmitLoiToReviewError);
  };

  const resetFormData = () => {
    setPersonalData(INITIAL_LOI_CONTEXT_DATA.state.personalData);
    setAddressData(INITIAL_LOI_CONTEXT_DATA.state.addressData);
    setBankData(INITIAL_LOI_CONTEXT_DATA.state.bankData);
    setDocuments(INITIAL_LOI_CONTEXT_DATA.state.documents);
  };

  const refreshLoiOwners = () => {
    getLoiOwners(loiNegotiation.id, countryCode).then((res) => {
      setLoiOwners(res.data);
    });
  };

  const goToPropertyDetail = () =>
    history.push(`/propietary/properties/${propertyId}?type=forSale`);

  //TODO: turn this flag on if services allows to manage multiple owners
  const canAddMoreOwners = false;

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const payload = {
    state: {
      loiOwners,
      country,
      activeStep,
      personalData,
      addressData,
      bankData,
      documents,
      canAddMoreOwners,
      documentTypes,
      finishedProcess,
      completedFirstForm,
      mainOwner,
    },
    actions: {
      setPersonalData,
      setAddressData,
      setBankData,
      setDocuments,
      setActiveStep,
      setAddingSecondaryOwner,
    },
    handlers: {
      submitLoiToReview,
      updateOrCreateOwner,
      resetFormData,
      refreshLoiOwners,
      goToPropertyDetail,
    },
  };

  return <LoiContext.Provider value={payload}>{children}</LoiContext.Provider>;
};

const useLoiForm = () => useContext(LoiContext);

export { useLoiForm };

export default LoiFormProvider;
