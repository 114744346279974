import { makeStyles, Theme } from "@material-ui/core";

const usePaymentDataStyles = makeStyles((theme: Theme) => ({
  paymentDataRoot: {
    width: "100%",
    padding: theme.spacing(2),
    background: "white",
    borderRadius: "0px 0px 16px 16px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 420,
      transform: "none",
      borderRadius: 8,
      background: theme.palette.grey[50],
    },
  },
}));

export default usePaymentDataStyles;
