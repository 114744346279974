import { ReactNode, FC } from "react";

import { Controller, useFormContext } from "react-hook-form";

import { TextFieldProps } from "@material-ui/core";

import PhoneInput from "Components/Admin/UIComponents/CountryInput/PhoneInput";
import { Country } from "models/PhoneInput";

interface Props {
  phoneLabel?: string | ReactNode;
  numberInputProps?: TextFieldProps;
  countryLabel?: string;
  countryInputProps?: TextFieldProps;
  id: string;
  defaultValue: { country: Country; phone: string };
  labelClassName?: string;
  fieldContainerLabel?: string;
  fieldContainerLabelClassName?: string;
  classNameInputContainer?: string;
  countryWrapperClassName?: string;
  numberWrapperClassName?: string;
}

const ControlledPhoneInput: FC<Props> = ({
  id,
  defaultValue,
  phoneLabel,
  numberInputProps,
  countryLabel,
  countryInputProps,
  labelClassName,
  fieldContainerLabel,
  fieldContainerLabelClassName,
  classNameInputContainer,
  countryWrapperClassName,
  numberWrapperClassName,
}: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({
        field: { value, onChange },
        fieldState: { error, invalid },
      }) => (
        <PhoneInput
          numberInputProps={{
            error: invalid,
            fullWidth: true,
            ...numberInputProps,
          }}
          countryInputLabel={countryLabel}
          countryInputProps={countryInputProps}
          error={invalid}
          errorMessage={error}
          onChange={onChange}
          value={value || defaultValue}
          numberInputLabel={phoneLabel}
          labelClassName={labelClassName}
          fieldContainerLabel={fieldContainerLabel}
          fieldContainerLabelClassName={fieldContainerLabelClassName}
          classNameInputContainer={classNameInputContainer}
          countryWrapperClassName={countryWrapperClassName}
          numberWrapperClassName={numberWrapperClassName}
        />
      )}
    />
  );
};
export default ControlledPhoneInput;
