import { makeStyles, Theme } from "@material-ui/core";

const useSubmitSectionStyles = makeStyles((theme: Theme) => ({
  submitSectionRoot: {
    display: "block",
    "& button": {
      marginRight: theme.spacing(2),
      padding: theme.spacing(0, 2),
      fontSize: theme.typography.pxToRem(12),
    },
  },
}));

export default useSubmitSectionStyles;
