import {
  Action,
  Applicant,
  SET_MAIN_APPLICANT,
  SET_FIRST_CODEBTOR,
  SET_SECOND_CODEBTOR,
  SET_ACTIVE_STEP,
  SET_STEPS,
  Dispatch,
  CodebtorAnswer,
  SET_CODEBTOR_ANSWER,
} from "./EvaluationTypes";

export const setSteps = (steps: number): Action => ({
  type: SET_STEPS,
  steps,
});

export const setMainApplicant = (
  mainApplicant: Partial<Applicant>
): Action => ({
  type: SET_MAIN_APPLICANT,
  mainApplicant,
});

export const setFirstCodebtor = (
  firstCodebtor: Partial<Applicant>
): Action => ({
  type: SET_FIRST_CODEBTOR,
  firstCodebtor,
});

export const setSecondCodebtor = (
  secondCodebtor: Partial<Applicant>
): Action => ({
  type: SET_SECOND_CODEBTOR,
  secondCodebtor,
});

export const setActiveStep = (activeStep: number): Action => ({
  type: SET_ACTIVE_STEP,
  activeStep,
});

const setCodebtorAnswer = (codebtorAnswer: CodebtorAnswer): Action => ({
  type: SET_CODEBTOR_ANSWER,
  codebtorAnswer,
});

export const setActualStep = (step: number, dispatch: Dispatch) => {
  dispatch(setActiveStep(step));
};

export const setStepsLength = (stepNumber: number, dispatch: Dispatch) => {
  dispatch(setSteps(stepNumber));
};

export const handleChangeAnswerDecision = (
  codebtorDecision: CodebtorAnswer,
  dispatch: Dispatch
) => {
  dispatch(setCodebtorAnswer(codebtorDecision));
};
