import { PriceFormData } from "./types";

const INITIAL_FORM_VALUES: PriceFormData = {
  businessType: null,
  price: "",
  currency: "CLP",
  includesCommonExpenses: "",
};

export default INITIAL_FORM_VALUES;
