import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

import {
  DatePicker,
  DatePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

import designSystemCalendarTheme from "./DesignSystemCalendarStyles";
/**
 * This component just overrides the default styles of the calendar
 * and returns the native Material-UI DatePicker component
 */

interface DSCalendarProps extends Omit<DatePickerProps, "onChange"> {
  onChange: (e: Date) => void;
}
const DesignSystemCalendar = (datePickerProps: DSCalendarProps) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
    <ThemeProvider theme={designSystemCalendarTheme}>
      <DatePicker
        label=""
        variant="static"
        disablePast={true}
        readOnly={true}
        disableToolbar
        {...datePickerProps}
        onChange={(e) => {
          const date = e as unknown as Date;
          datePickerProps.onChange(date);
        }}
      />
    </ThemeProvider>
  </MuiPickersUtilsProvider>
);

export default DesignSystemCalendar;
