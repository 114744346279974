import { Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  IoConstructOutline as RepairmentIcon,
  IoArrowForwardOutline as ArrowRight,
} from "@houm-com/ui-icons";

import ExitCard from "apps/tenant/components/ExitCard";
import RequestCard from "apps/tenant/components/RepairRequestCard";
import ServicesRequestCard from "apps/tenant/components/ServicesRequestCard";
import useRepairsRequests from "apps/tenant/hooks/useRepairsRequests";
import useServicesRequests from "apps/tenant/hooks/useServicesRequests";
import useContractEnd from "apps/tenant/hooks/useContractEnd";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import Spinner from "Components/Shared/partials/Spinner";

import useRequestsCarrouselStyles from "./RequestsCarrouselStyles";

const RequestsCarrousel = () => {
  const classes = useRequestsCarrouselStyles();
  const { data: repairsData, isLoading: isRepairsLoading } =
    useRepairsRequests();
  const { data: servicesRequestsData, isLoading: isServicesRequestsLoading } =
    useServicesRequests();
  const { contractEndData, isLoading: isContractEndLoading } = useContractEnd();
  const history = useHistory();
  const repairsRequests = repairsData?.slice(0, 2);
  const servicesRequests = servicesRequestsData?.slice(0, 2);
  const contractEnd = contractEndData;

  const requestList = repairsRequests?.length
    ? repairsRequests.map((request) => (
        <RequestCard key={request.id} repairRequest={request} />
      ))
    : servicesRequests?.map((request) => (
        <ServicesRequestCard servicesRequest={request} />
      ));
  const requestsCards = contractEnd.hasBegun ? (
    <ExitCard contractEnd={contractEnd} />
  ) : (
    requestList
  );

  const hasRequests =
    repairsRequests?.length ||
    servicesRequests?.length ||
    contractEnd?.hasBegun;

  if (isRepairsLoading || isServicesRequestsLoading || isContractEndLoading)
    return <Spinner />;

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography className={classes.title}>Solicitudes</Typography>
        {hasRequests && (
          <Box onClick={() => history.push("/tenant/requests")}>
            <Typography className={classes.seeMore}>
              Ver todas
              <ArrowRight />
            </Typography>
          </Box>
        )}
      </Box>
      {!hasRequests ? (
        <Box className={classes.emptyRequestsContainer}>
          <Box className={classes.iconContainer}>
            <RepairmentIcon color="#46788C" />
          </Box>
          <Typography className={classes.emptyRequestsText}>
            Aquí podrás ver las solicitudes más recientes que hayas realizado.
          </Typography>
        </Box>
      ) : (
        <>
          <Typography className={classes.description}>
            Estos son las últimos solicitudes que has realizado
          </Typography>
          <Box className={classes.cards}>{requestsCards}</Box>
        </>
      )}
      <DesignSystemButton
        label="Crear solicitud"
        size="small"
        variant="primary"
        onClick={() => history.push("/tenant/help")}
      />
    </Box>
  );
};

export default RequestsCarrousel;
