import { makeStyles, Theme } from "@material-ui/core";

const useResumeFormStyles = makeStyles<Theme>((theme) => ({
  container: {
    height: "100%",
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    minWidth: 245,
    height: "auto",
  },
}));

export default useResumeFormStyles;
