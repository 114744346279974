import {
  State,
  Action,
  SET_STEPS,
  SET_LOADING,
  SET_PROPERTY,
  SET_ACTIVE_STEP,
  SET_MAIN_APPLICANT,
  SET_FIRST_CODEBTOR,
  SET_SECOND_CODEBTOR,
  SET_CODEBTOR_ANSWER,
  defaultApplicantValue,
  SET_EXECUTIVE,
  SET_RELATED_APPLICANT,
} from "./EvaluationTypes";

export const initialState: State = {
  loading: true,
  mainApplicant: defaultApplicantValue,
  firstCodebtor: defaultApplicantValue,
  secondCodebtor: defaultApplicantValue,
  activeStep: 0,
  steps: 0,
  codebtorAnswer: {
    firstCodebtorAnswer: false,
    secondCodebtorAnswer: false,
  },
  property: null,
  executive: null,
  relatedApplicantId: null,
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_LOADING: {
      return { ...state, loading: action.loading };
    }
    case SET_PROPERTY: {
      return { ...state, property: action.property };
    }
    case SET_MAIN_APPLICANT: {
      return { ...state, mainApplicant: action.mainApplicant };
    }
    case SET_FIRST_CODEBTOR: {
      return { ...state, firstCodebtor: action.firstCodebtor };
    }
    case SET_SECOND_CODEBTOR: {
      return { ...state, secondCodebtor: action.secondCodebtor };
    }
    case SET_ACTIVE_STEP: {
      return { ...state, activeStep: action.activeStep };
    }
    case SET_STEPS: {
      return { ...state, steps: action.steps };
    }
    case SET_CODEBTOR_ANSWER: {
      return { ...state, codebtorAnswer: action.codebtorAnswer };
    }
    case SET_EXECUTIVE: {
      return { ...state, executive: action.executive };
    }
    case SET_RELATED_APPLICANT: {
      return { ...state, relatedApplicantId: action.relatedApplicantId };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
