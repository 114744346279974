import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    height: "100%",
    backgroundColor: theme.palette.grey[5],
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
    borderRadius: theme.spacing(1),
    width: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  stat: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0),
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
}));

export default useStyles;
