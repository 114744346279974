import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@material-ui/core";

import InspectionReportCard from "apps/tenant/components/InspectionReportCard";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import useInspectionReports from "hooks/inspectionReports/useInspectionReports";
import { Inspection } from "hooks/inspectionReports/types";

import useAllInspectionReportsStyles from "./AllInspectionReportsStyles";

export default function AllInspectionReports() {
  const { t } = useTranslation(["tenant.inspection"]);
  const { propertyId } = useParams<{ propertyId: string }>();
  const { inspectionReportList, isLoading } = useInspectionReports(
    Number(propertyId)
  );
  const classes = useAllInspectionReportsStyles();

  if (isLoading) return <Loading loading />;

  return (
    <Grid container>
      <Grid item>
        <Typography className={classes.reportsTitle}>{t("title")}</Typography>
        <Typography className={classes.reportsSubtitle}>
          {t("subtitle")}
        </Typography>
      </Grid>
      <Grid item container className={classes.reportsContainer}>
        {inspectionReportList?.map((report: Inspection) => (
          <InspectionReportCard key={report.id} inspectionReport={report} />
        ))}
      </Grid>
    </Grid>
  );
}
