import { SnackbarPayload } from "libs/houm-snackbar/types";

export const FAILED_TO_FETCH_INSPECTION_REPORT_COMMENTS: SnackbarPayload = {
  body: "Ha ocurrido un error obteniendo los comentarios del acta de tu propiedad",
  type: "error",
};

export const FAILED_TO_SUBMIT_INSPECTION_REPORT_COMMENT: SnackbarPayload = {
  body: "Ha ocurrido un error enviando tu comentario",
  type: "error",
};

export const SUCCESS_SUBMIT_INSPECTION_REPORT_COMMENT: SnackbarPayload = {
  body: "Tu comentario ha sido enviado",
  type: "success",
};
