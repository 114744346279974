export const LOCALE_BASED_HELP_CENTER_LINKS = {
  Chile: "https://help.houm.com/cl",
  Mexico: "https://help.houm.com/mx",
  Colombia: "https://help.houm.com/co",
};

export const LOCALE_BASED_HELP_LANDLORD_FORM_LINKS = {
  Chile:
    "https://houm-chile.kustomer.help/contact/contacto-propietario-SyfKrmO3j",
  Mexico:
    "https://houm-chile.kustomer.help/contact/contacto-propietario-SyfKrmO3j",
  Colombia:
    "https://houm-chile.kustomer.help/contact/contacto-propietario-SyfKrmO3j",
};

export const LOCALE_BASED_HELP_TENANT_FORM_LINKS = {
  Chile:
    "https://houm-chile.kustomer.help/contact/contacto-arrendatario-B1Cn2ZTo",
  Mexico:
    "https://houm-chile.kustomer.help/contact/contacto-arrendatario-B1Cn2ZTo",
  Colombia:
    "https://houm-chile.kustomer.help/contact/contacto-arrendatario-B1Cn2ZTo",
};

export const LOCALE_BASED_HELP_CHAT_LINKS = {
  Chile: "https://s.houm.com/5W6lZ",
  Mexico: "https://s.houm.com/7AjnG",
  Colombia: "https://s.houm.com/0912G",
};
