import clx from "classnames";

import { Box, Typography } from "@material-ui/core";

import HoumSecondaryPinIcon from "assets/icons/Landlord/HoumSecondaryPinIcon";
import HoumPrimaryPinIcon from "assets/icons/Landlord/HoumPrimaryPinIcon";

import usePlanTypeTagStyles from "./PlanTypeTagStyles";

interface Props {
  isGuaranteed: boolean;
  labelClassname?: string;
  containerClassname?: string;
}

const PlanTypeTag = ({
  isGuaranteed,
  labelClassname,
  containerClassname,
}: Props) => {
  const classes = usePlanTypeTagStyles();
  const text = isGuaranteed
    ? "Plan con arriendo garantizado"
    : "Plan sin arriendo garantizado";

  return (
    <Box className={clx(containerClassname, classes.container)}>
      {isGuaranteed ? (
        <HoumPrimaryPinIcon aria-label="guaranteed-pin" />
      ) : (
        <HoumSecondaryPinIcon aria-label="not-guaranteed-pin" />
      )}
      <Typography className={clx(labelClassname, classes.planText)}>
        {text}
      </Typography>
    </Box>
  );
};

export default PlanTypeTag;
