import {
  InspectionReport,
  InspectionReportTableRow,
} from "models/InspectionReport";

const parseInspectionReportForDeskTable = (
  data: InspectionReport
): InspectionReportTableRow[] =>
  data?.generalSpacesSummary.map((generalSpace) => {
    const spaceElements = data?.spaces.find(
      (space) => space.type === generalSpace.type
    );
    if (!spaceElements) return generalSpace;
    const notEmptySpaceItems = spaceElements?.items.filter(
      (item) => item.observation
    );
    const notEmptySpaceFeatures = spaceElements?.features.filter(
      (feature) => feature.observation
    );
    return {
      ...generalSpace,
      subitems: [...notEmptySpaceItems, ...notEmptySpaceFeatures],
    };
  });

export default parseInspectionReportForDeskTable;
