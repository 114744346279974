import { useTheme } from "@material-ui/core";

import TagType from "./types";

const getColors = (type: TagType) => {
  const theme = useTheme();
  switch (type) {
    case TagType.SECONDARY:
      return {
        color: theme.palette.grey[900],
        backgroundColor: "#EFF2F3",
      };
    case TagType.SUCCESS:
      return {
        color: "#439F03",
        backgroundColor: "#DAEECC",
      };
    case TagType.ERROR:
      return {
        color: "#900000",
        backgroundColor: "#EFCDCC",
      };
    case TagType.WARNING:
      return {
        color: "#A07800",
        backgroundColor: "#FAF3CC",
      };
    case TagType.INFO:
      return {
        color: "#2663BC",
        backgroundColor: "#D5E3F8",
      };
    case TagType.GREY:
      return {
        color: "#DFE5E8",
        backgroundColor: "#4C6977",
      };
    case TagType.LIGHT_GREY:
      return {
        color: "#4C6977",
        backgroundColor: "#DFE5E8",
      };
    case TagType.PRIMARY:
    default:
      return {
        color: theme.palette.primary.main,
        backgroundColor: "#F9E8E5",
      };
  }
};

export default getColors;
