/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, ReactNode, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";

export interface IDrawerContext {
  returnToMainPage: () => void;
  saveParentUrl: (url: string) => void;
  open: boolean;
  onHandleDrawer: () => void;
  currentStep: string | number;
  setCurrentStep: (step: string | number) => void;
}

export const DrawerContext = createContext<IDrawerContext>(null);

interface Props {
  children: ReactNode;
  isOpen?: boolean;
}

export const DrawerProvider = ({ children, isOpen = true }: Props) => {
  const history = useHistory();
  const [open, setOpen] = useState(isOpen);
  const [parentURL, setParentURL] = useState(null);
  const [currentStep, setCurrentStep] =
    useState<IDrawerContext["currentStep"]>();

  const returnToMainPage = useCallback(() => {
    if (!isMobile) {
      setOpen(false);
      if (parentURL) return setTimeout(() => history.push(parentURL), 300);
    }
    if (parentURL) return history.push(parentURL);
  }, [history, parentURL]);

  const saveParentUrl = useCallback((url) => setParentURL(url), []);
  const onHandleDrawer = useCallback(() => setOpen((prev) => !prev), []);

  return (
    <DrawerContext.Provider
      value={{
        returnToMainPage,
        setCurrentStep,
        saveParentUrl,
        currentStep,
        open,
        onHandleDrawer,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
