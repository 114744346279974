import { makeStyles, Theme } from "@material-ui/core/";

const DATE_MARGIN_SPACING = 1.25;
const COMMENTS_REMINDER_GAP = 10;

const useInspectionReportCardStyles = makeStyles<Theme>((theme) => ({
  inspectionReportCardContainer: {
    position: "relative",
    height: 140,
    width: "100%",
    borderRadius: 10,
    transition: "filter 0.5s ease",
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 182,
      height: 198,
    },
    cursor: "pointer",
  },
  inspectionReportCardContainerLoading: {
    filter: "contrast(0.8) brightness(1.2)",
  },
  inspectionReportQualification: {
    backgroundColor: "#FFFBE7",
    position: "absolute",
    borderRadius: 100,
    padding: theme.spacing(0),
    top: 12,
    right: 12,
  },
  inspectionReportQualificationText: {
    padding: theme.spacing(0.5, 0.5),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 800,
    marginLeft: theme.spacing(0.25),
  },
  inspectionReportCommentsReminder: {
    position: "absolute",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.5),
    textAlign: "center",
    top: COMMENTS_REMINDER_GAP,
    left: COMMENTS_REMINDER_GAP,
    width: `calc(100% - ${COMMENTS_REMINDER_GAP * 2}px)`,
    backgroundColor: "#F1DA67",
  },
  inspectionReportCommentsReminderText: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    color: "#503C00",
  },
  inspectionReportCommentsReminderIcon: {
    marginTop: theme.spacing(-0.25),
    marginRight: theme.spacing(1),
  },
  starIcon: {
    marginRight: theme.spacing(0.25),
  },
  inspectionReportImage: {
    borderRadius: 10,
    width: "100%",
    position: "absolute",
    height: 140,
    objectFit: "cover",
    objectPosition: "center center",
    [theme.breakpoints.up("sm")]: {
      objectFit: "fill",
      height: "100%",
      width: "auto",
      minWidth: 182,
    },
  },
  inspectionReportGradient: {
    borderRadius: 10,
    width: "100%",
    height: "100%",
    position: "absolute",
    background:
      "linear-gradient(to bottom, rgba(255,255,255, 0) 50%, rgba(0,0,0, 0.6) 100%)",
  },
  inspectionReportDate: {
    color: "white",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    margin: theme.spacing("auto", 0),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  inspectionReportIcon: {
    width: 12,
    margin: theme.spacing(0, 0, 0.25, 0.75),
    "&:svg": {},
  },
  inspectionReportButton: {
    borderRadius: 100,
    backgroundColor: theme.palette.primary.main,
    width: 24,
    height: 24,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  inspectionReportDateContainer: {
    width: `calc(100% - ${2 * DATE_MARGIN_SPACING * 8}px)`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 0,
    color: "white",
    fontWeight: 700,
    margin: theme.spacing(DATE_MARGIN_SPACING),
  },
}));

export default useInspectionReportCardStyles;
