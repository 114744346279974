import { useQuery, UseQueryResult } from "react-query";

import khipuService from "services/khipuService";
import { KhipuData } from "../../utils/types";

const useKhipuUrls = (quotationId: number, document: string) => {
  const { data, isLoading, isError }: UseQueryResult<KhipuData, Error> =
    useQuery<KhipuData, Error>(
      "khipu_urls",
      async () => {
        const response = await khipuService.getKhipuUrls(
          String(quotationId),
          document
        );
        const parsedData = {
          paymentUrl: response.data.payment_url,
          appUrl: response.data.app_url,
          bankAccounts: response.data.bank_accounts.map((bankAccount) => ({
            bankName: bankAccount.bank_name,
            accountNumber: bankAccount.account_number,
          })),
        };
        return parsedData;
      },
      {
        enabled: !!document && !!quotationId,
      }
    );
  return {
    data,
    isLoading,
    isError,
  };
};

export default useKhipuUrls;
