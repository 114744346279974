import { useGetToken } from "hooks";
import { useQuery, UseQueryResult } from "react-query";
import tenantContractService from "../services/tenantContractService";
import { parseTenantContractEnd } from "../utils/parsers";
import { ITenantContractEnd } from "../utils/types";
import useContract from "./useContract";

const useContractEnd = () => {
  const { getToken } = useGetToken();
  const { data: contractData, isLoading } = useContract();

  const {
    data: contractEndData,
    isLoading: isContractEndLoading,
    isError,
    refetch,
  }: UseQueryResult<ITenantContractEnd, Error> = useQuery<
    ITenantContractEnd,
    Error
  >(
    "tenantContractInfo",
    async () => {
      const authToken = await getToken();
      const response = await tenantContractService.getContractEndInfo(
        authToken,
        contractData.id
      );
      return parseTenantContractEnd(response.data);
    },
    { enabled: !!contractData?.id }
  );

  return {
    isLoading: isLoading || isContractEndLoading,
    isError,
    contractEndData,
    refetchContractEnd: refetch,
  };
};

export default useContractEnd;
