import { useQuery, UseQueryResult } from "react-query";

import propertyContractServiceV2 from "apps/landlord/services/propertyContractServiceV2";
import { useProperty } from "apps/landlord/hooks";
import { useGetToken } from "hooks";

import { IActiveContract } from "./types";
import parsedActiveContract from "./parsers";

const useActiveContract = () => {
  const { propertyData, propertyLoading } = useProperty();

  const { getToken } = useGetToken();
  const query: UseQueryResult<IActiveContract, Error> = useQuery<
    IActiveContract,
    Error
  >(
    ["active_contract", propertyData?.id],
    async () => {
      const authToken = await getToken();
      const response = await propertyContractServiceV2.getActiveContract(
        propertyData?.id,
        authToken
      );
      return parsedActiveContract(response.data);
    },
    { enabled: !!propertyData }
  );

  return {
    ...query,
    isLoading: query.isLoading || propertyLoading,
  };
};

export default useActiveContract;
