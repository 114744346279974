import { ReactNode } from "react";

import { Files, WorkerType } from "apps/lead/models/Application";

import WorkerTypeSelection from "../WorkerTypeSelection";
import FormWrapper from "../FormWrapper/FormWrapper";
import DisclaimerFiles from "../DisclaimerFiles";
import FileSelection from "../FileSelection";

interface Props {
  requirements: {
    id: string;
    text: string;
    tooltip?: ReactNode;
  }[];
  files: {
    identityCard: Files[];
    contract?: Files[];
    salarySettlements?: Files[];
    quotes?: Files[];
    f22?: Files[];
    pensionSettlements?: Files[];
  };
  uploadFile: (file: Files, stateFiles: Files[], fileType: string) => void;
  removeFile: (file: string, stateFiles: Files[], fileType: string) => void;
  workerType: WorkerType;
  handleChange: (type: WorkerType) => void;
}

const FilesUploadForm = ({
  requirements,
  files,
  uploadFile,
  removeFile,
  workerType,
  handleChange,
}: Props) => (
  <FormWrapper>
    <WorkerTypeSelection
      onHandleChange={handleChange}
      workerType={workerType}
    />
    {requirements.map((req) => (
      <FileSelection
        files={files}
        stateFiles={files[req.id] || []}
        label={req.text}
        fileType={req.id}
        key={req.id}
        helpTooltip={req?.tooltip}
        uploadFile={uploadFile}
        removeFile={removeFile}
      />
    ))}
    <DisclaimerFiles />
  </FormWrapper>
);

export default FilesUploadForm;
