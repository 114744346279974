import { makeStyles, Theme } from "@material-ui/core";
import {
  DESK_NAVBAR_HEIGHT,
  DESKTOP_CONTENT_MAX_WIDTH,
} from "apps/tenant/constants";

const useDashboardStyles = makeStyles<Theme>((theme) => ({
  spinner: {
    height: "100vh",
  },
  colorBox: {
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "#F7F9F9",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: -1,
      height: "100%",
      width: "100%",
    },
  },
  dashboardContent: {
    flexDirection: "row",
    position: "relative",
    minHeight: "100%",
    width: "100%",
    backgroundColor: theme.palette.grey[50],
    [theme.breakpoints.up("sm")]: {
      maxWidth: DESKTOP_CONTENT_MAX_WIDTH,
      margin: theme.spacing(0, "auto"),
      height: "100%",
      display: "flex",
      flexDirection: "row",
      position: "relative",
    },
  },
  leftContainer: {
    marginBottom: theme.spacing(5),
    padding: theme.spacing(3),
    border: "1px solid white",
    borderRadius: "0px 0px 16px 16px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    boxShadow: "3px 4px 15px rgba(38, 50, 56, 0.15)",
    [theme.breakpoints.up("sm")]: {
      position: "sticky",
      top: DESK_NAVBAR_HEIGHT,
      marginBottom: 0,
      borderRadius: 0,
      height: `calc(100vh - ${DESK_NAVBAR_HEIGHT}px)`,
      width: "40%",
      padding: theme.spacing(15.5, 5),
      clipPath: "inset(0px -30px 0px 0px)",
    },
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.4,
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
      fontSize: theme.typography.pxToRem(28),
      lineHeight: 1,
    },
  },
  welcome: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(22),
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 2, 4),
    backgroundColor: theme.palette.grey[50],
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5, 3),
      width: "60%",
    },
  },
  visitsFavoritesWrapper: {
    gap: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
}));

export default useDashboardStyles;
