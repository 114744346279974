import { Box, Typography } from "@material-ui/core";

import useInfoRowStyles from "./InfoRowStyles";

interface Props {
  title: string;
  value: string | number;
  secondaryValue?: string;
}

export default function InfoRow({
  title,
  value,
  secondaryValue = null,
}: Props) {
  const classes = useInfoRowStyles();

  return (
    <Box className={classes.infoRowContainer}>
      <Typography className={classes.infoTextTitle}>{title}</Typography>
      <Typography className={classes.infoTextValue}>{value}</Typography>
      {secondaryValue && (
        <Typography className={classes.infoSecondaryValue}>
          ({secondaryValue})
        </Typography>
      )}
    </Box>
  );
}
