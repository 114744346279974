import { useFeatureManager } from "@houm-com/feature-manager";

import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import { setMethodSelected } from "apps/payments/context/rentPaymentContext/rentPaymentActions";
import Spinner from "Components/Shared/partials/Spinner";

import PaymentMethodCard from "../PaymentMethodCard";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";

const PaymentMethodsCO = () => {
  const { dispatch } = useRentPayment();
  const { setCurrentStep } = useCustomDrawer();
  const { status, data } = useFeatureManager("place_to_pay");
  const isPlaceToPayFlag = status === "success" && data?.enabled;

  if (status === "loading") return <Spinner />;

  const paymentMethods = [...(isPlaceToPayFlag ? [data.content] : [])];

  if (paymentMethods.length === 1) {
    dispatch(setMethodSelected(paymentMethods[0].id));
    setCurrentStep("paymentSelected");
  }

  return (
    <>
      {paymentMethods.map((item) => (
        <PaymentMethodCard key={item.id} data={item} />
      ))}
    </>
  );
};

export default PaymentMethodsCO;
