import { useEffect, useMemo, useState } from "react";
import chunk from "lodash/chunk";

import { NavigateNext, NavigateBefore } from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import useTheme from "@material-ui/styles/useTheme";
import { Theme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { useLandlordProperty } from "context/landlordPropertyContext/landlordPropertyContext";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import schedulerService from "services/schedulerService";
import useStyles from "./ScheduleFeedbackStyles";
import FeedbackCard from "./FeedbackCard";
import { useGetToken } from "hooks";

/**
 * Number of media items shown in carousel
 */
const desktopSize = 3;
const tabletSize = 2;
const mobileSize = 1;

interface CardsProps {
  id: number;
  comment: string;
  date: string;
  houmer: string;
}

const ScheduleFeedback = () => {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const { getToken } = useGetToken();
  const { state } = useLandlordProperty();
  const { propertyData } = state;
  const { schedules } = propertyData;
  const [cards, setCards] = useState<CardsProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const isDesktop: boolean = useMediaQuery(theme.breakpoints.up("lg"));
  const isTablet: boolean = useMediaQuery(
    theme.breakpoints.between("sm", "md")
  );
  const cardsShown: number = isDesktop
    ? desktopSize
    : isTablet
    ? tabletSize
    : mobileSize;

  useEffect(() => {
    Promise.all<CardsProps>(
      schedules.map((item) =>
        getToken().then(async (authToken) => {
          const fetchedFeedback = await schedulerService.getFeedback(
            item.id,
            authToken
          );
          if (!fetchedFeedback) return;
          if (fetchedFeedback.data?.results[0])
            return {
              id: item.id,
              comment: fetchedFeedback.data.results[0]?.feedback,
              date: fetchedFeedback.data.results[0].schedule?.end_date,
              houmer: fetchedFeedback.data.results[0].schedule?.appraiser.name,
            };
        })
      )
    ).then((e) => {
      const filtered = e.filter((item) => item && item["comment"] !== "");
      setCards(filtered);
      setLoading(false);
    });
  }, [schedules]);

  const cardsList = useMemo(
    () => chunk(cards, cardsShown),
    [cards, cardsShown]
  );

  return (
    <Grid container item xs={12} className={classes.componentContainer}>
      <Grid container item>
        <Grid item container xs={8}>
          <p className={classes.title}>Comentarios</p>
        </Grid>
        <Grid item container xs={12}>
          <p className={classes.subtitle}>
            Entérate de los comentarios que han hecho tus visitas
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justifyContent="space-between"
        className={classes.gridContainer}
        spacing={2}
      >
        <Grid item xs={12}>
          <Loading loading={loading}>
            {cards.length === 0 ? (
              <Typography className={classes.noFeedback}>
                Aún no hay comentarios de las visitas del houmer para esta
                propiedad.
              </Typography>
            ) : (
              <Carousel
                infiniteLoop={true}
                showArrows={isDesktop}
                centerMode={false}
                showIndicators={false}
                className={classes.carousel}
                showThumbs={false}
                showStatus={false}
                dynamicHeight={true}
                centerSlidePercentage={90}
                selectedItem={currentPage}
                onClickItem={setCurrentPage}
                onChange={setCurrentPage}
                renderArrowNext={(onClickHandler) => (
                  <button
                    onClick={onClickHandler}
                    className={`${classes.carouselNavButton} ${classes.carouselNextNavButton}`}
                  >
                    <NavigateNext className={classes.icon} />
                  </button>
                )}
                renderArrowPrev={(onClickHandler) => (
                  <button
                    onClick={onClickHandler}
                    className={`${classes.carouselNavButton} ${classes.carouselPrevNavButton}`}
                  >
                    <NavigateBefore className={classes.icon} />
                  </button>
                )}
              >
                {cardsList.map((items, i) => (
                  <Grid
                    className={classes.contentCard_wrap}
                    container
                    key={`media-tile-${i}`}
                    spacing={1}
                    justifyContent="center"
                  >
                    {items.length > 0 &&
                      items.map((feedback) => (
                        <FeedbackCard feedback={feedback} key={feedback.id} />
                      ))}
                  </Grid>
                ))}
              </Carousel>
            )}
          </Loading>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScheduleFeedback;
