import { CountryUpperCase } from "models/Countries";

export interface DocumentTypeProps {
  id: number;
  regex: RegExp;
  type: string;
}

export interface DocumentsProps {
  document: string;
  documentType: DocumentTypeProps;
}

export interface PersonProps {
  address?: string;
  document?: string;
  documentType?: DocumentTypeProps | null;
  email?: string;
  isLegalRepresentative?: boolean;
  name?: string;
  occupation?: string;
  phone?: string;
  ready: boolean;
  rfc?: string;
  role?: string;
}

export interface State {
  contractId: string | null;
  country: CountryUpperCase | "";
  documentTypes: DocumentTypeProps[];
  fetchingPerson: boolean;
  isNewPerson: boolean;
  person: PersonProps;
  propertyId: number;
  redirectionUrl: string;
  searchParams: DocumentsProps | null;
  title: string;
}

export const SET_CONTRACT_ID = "SET_CONTRACT_ID";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_DOCUMENT_TYPES = "SET_DOCUMENT_TYPES";
export const SET_FETCHING_PERSON = "SET_FETCHING_PERSON";
export const SET_IS_NEW_PERSON = "SET_IS_NEW_PERSON";
export const SET_PERSON = "SET_PERSON";
export const SET_PROPERTY_ID = "SET_PROPERTY_ID";
export const SET_REDIRECTION_URL = "SET_REDIRECTION_URL";
export const SET_SEARCH_PARAMS = "SET_SEARCH_PARAMS";
export const SET_TITLE = "SET_TITLE";

interface setContractId {
  type: typeof SET_CONTRACT_ID;
  contractId: string;
}

interface setCountry {
  type: typeof SET_COUNTRY;
  country: CountryUpperCase;
}

interface setDocumentTypes {
  type: typeof SET_DOCUMENT_TYPES;
  documentTypes: DocumentTypeProps[];
}
interface setFetchingPerson {
  type: typeof SET_FETCHING_PERSON;
  fetchingPerson: boolean;
}

interface setIsNewPerson {
  type: typeof SET_IS_NEW_PERSON;
  isNewPerson: boolean;
}

interface setPerson {
  type: typeof SET_PERSON;
  person: PersonProps;
}

interface setPropertyId {
  type: typeof SET_PROPERTY_ID;
  propertyId: number;
}

interface setRedirectionUrl {
  type: typeof SET_REDIRECTION_URL;
  redirectionUrl: string;
}

interface setSearchParams {
  type: typeof SET_SEARCH_PARAMS;
  searchParams: DocumentsProps;
}

interface setTitle {
  type: typeof SET_TITLE;
  title: string;
}

export type Action =
  | setContractId
  | setCountry
  | setDocumentTypes
  | setFetchingPerson
  | setIsNewPerson
  | setPerson
  | setPropertyId
  | setRedirectionUrl
  | setSearchParams
  | setTitle;

export type Dispatch = (action: Action) => void;
