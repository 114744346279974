import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import {
  setCountry,
  setStep,
} from "apps/payments/context/rentPaymentContext/rentPaymentActions";
import Spinner from "Components/Shared/partials/Spinner";
import { useGetQueryParam } from "hooks/useQuery";
import { Locale } from "models/Countries";

import useRentPaymentData from "./hooks/useRentPaymentData";
import PaymentDetails from "./components/PaymentDetails";
import MailForm from "./components/MailForm";
import Landing from "./components/Landing";

const RentPayment = () => {
  const rut = useGetQueryParam("rut");
  const email = useGetQueryParam("email");
  const { mutate, isLoading } = useRentPaymentData();
  const { state, dispatch } = useRentPayment();
  const { locale } = useParams<{ locale: Locale }>();
  const country = locale ?? "cl";

  useEffect(() => {
    dispatch(setCountry(country));
    if (email) dispatch(setStep("mailForm"));
  }, [country, dispatch, email]);

  useEffect(() => {
    if (rut)
      mutate({
        document: rut,
        documentType: 1,
      });
  }, [mutate, rut]);

  const currentStep = () => {
    switch (state.step) {
      case "paymentDetail":
      case "paymentMethod":
      case "paymentSelected":
        return <PaymentDetails />;
      case "mailForm":
        return <MailForm />;
      case "landing":
      default:
        return <Landing />;
    }
  };

  if (isLoading) return <Spinner />;

  return currentStep();
};

export default RentPayment;
