import { ComponentProps } from "react";
import { useHistory } from "react-router-dom";

import Button from "@houm-com/ui/Button";
import LightButton from "@houm-com/ui/LightButton";

import { IoChevronBack } from "@houm-com/ui-icons";

import { Hidden } from "@material-ui/core";

import CallToAction from "Components/Admin/UIComponents/atoms/CallToAction";
import useStyles from "./BottomBarStyles";

interface Props {
  buttonNextText: string;
  buttonProps?: Partial<ComponentProps<typeof Button>>;
  handleBack?: () => void;
  loading?: boolean;
}

const BottomBar = ({
  buttonNextText,
  buttonProps,
  handleBack,
  loading,
}: Props) => {
  const classes = useStyles({ disabled: buttonProps?.disabled });
  const history = useHistory();

  return (
    <CallToAction className={classes.bottomBarRoot}>
      <Hidden smDown>
        <LightButton
          size="md"
          variant="secondary"
          icon={<IoChevronBack />}
          position="start"
          onClick={handleBack || history.goBack}
        >
          Atrás
        </LightButton>
      </Hidden>
      <Button
        size="md"
        variant="primary"
        isLoading={loading}
        className={classes.button}
        {...buttonProps}
      >
        {buttonNextText}
      </Button>
    </CallToAction>
  );
};

export default BottomBar;
