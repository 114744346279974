import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import propertyService from "apps/landlord/services/propertyService";
import { useGetToken } from "hooks";

import parsePropertyDetails from "./utils/parsers";
import { IPropertyDetails } from "./utils/types";

const usePropertyDetails = (
  propertyId: string,
  options: Omit<
    UseQueryOptions<IPropertyDetails, Error, IPropertyDetails, QueryKey>,
    "queryKey" | "queryFn"
  > = {}
) => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    error,
    data,
    isError,
    refetch,
  }: UseQueryResult<IPropertyDetails, Error> = useQuery<
    IPropertyDetails,
    Error
  >(
    ["property_details", propertyId],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.get(propertyId, authToken);
      return parsePropertyDetails(response.data);
    },
    {
      enabled: !!propertyId,
      ...options,
    }
  );

  return {
    isLoading,
    error,
    isError,
    data,
    refetch,
  };
};

export default usePropertyDetails;
