import { Typography } from "@material-ui/core";

import { Locale } from "models/Countries";
import { ApplicationCodebtor } from "models/Evaluation";
import applicantInfo from "../../utils";
import useDesktopCardApplicantDetailsStyles from "./DesktopCardApplicationDetailsStyles";

interface DesktopCardApplicantDetailsProps {
  applicant: ApplicationCodebtor;
  countryCode: Locale;
}

const DesktopCardApplicantDetails = ({
  applicant,
  countryCode,
}: DesktopCardApplicantDetailsProps) => {
  const classes = useDesktopCardApplicantDetailsStyles();
  const applicantInfoList = Object.entries(
    applicantInfo(applicant, countryCode)
  );

  return (
    <>
      {applicantInfoList.map(([key, value]) => (
        <Typography key={key} className={classes.text}>
          {`${key}: ${value}`}
        </Typography>
      ))}
    </>
  );
};

export default DesktopCardApplicantDetails;
