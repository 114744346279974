import { ComponentProps, ReactNode } from "react";
import clx from "classnames";

import CloseIcon from "@material-ui/icons/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  PaperProps,
} from "@material-ui/core";

import useCustomDialogStyles from "./CustomDialogStyles";

function PaperComponent(props: PaperProps) {
  return <Paper {...props} />;
}

interface CustomDialogProp {
  children?: ReactNode;
  customizedButtonClassName?: string;
  dialogProps?: Partial<ComponentProps<typeof Dialog>>;
  onClose: () => void;
  open: boolean;
  title?: string | ReactNode;
}

const CustomDialog = ({
  children,
  customizedButtonClassName = "",
  dialogProps = {},
  onClose,
  open,
  title,
}: CustomDialogProp) => {
  const classes = useCustomDialogStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      classes={{ paper: classes.paper }}
      {...dialogProps}
    >
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent className={classes.content}>{children}</DialogContent>
      <DialogActions>
        <IconButton
          autoFocus
          onClick={onClose}
          color="primary"
          className={clx(classes.customizedButton, customizedButtonClassName)}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
