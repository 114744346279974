import { PaymentBreakdown } from "apps/tenant/models/Movement";
import { Locale } from "models/Countries";
import BreakdownItem from "../components/BreakdownItem";

export const renderBreakdown = (
  breakdown: PaymentBreakdown[],
  sign: "-" | "+"
) =>
  breakdown?.map((item) => (
    <BreakdownItem
      key={item.identifier}
      value={item.value}
      label={item.description}
      sign={sign}
    />
  ));

export const handleRentPayment = (
  country: Locale,
  email: string,
  rut?: string
) => {
  if (country === "cl")
    if (!rut) return window.open(`${window.location.origin}/pagar/${country}`);
    else
      return window.open(
        `${window.location.origin}/pagar/${country}?rut=${rut}`
      );
  else
    return window.open(
      `${window.location.origin}/pagar/${country}?email=${email}`
    );
};
