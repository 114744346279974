import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import propertyService from "apps/landlord/services/propertyService";
import { useHoumSnackBar } from "libs/houm-snackbar";
import { useGetToken } from "hooks";

import parsePropertyDetailsService from "./utils/parsers";
import { AmenitiesFormProps } from "../../utils/types";

const useEditAmenities = (returnToPropertyDetail: () => void) => {
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const queryClient = useQueryClient();
  const { id: propertyId } = useParams<{ id: string }>();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (formData: AmenitiesFormProps) => {
      const authToken = await getToken();
      const parsedData = parsePropertyDetailsService(
        formData.amenities,
        formData.propertyAmenities
      );
      const response = await propertyService.editPropertyDetails(
        propertyId,
        parsedData,
        authToken
      );
      return response.data;
    },
    {
      onError: () =>
        enqueueHoumSnackBar({
          body: "Ha ocurrido un error al editar las amenidades de la propiedad",
          type: "error",
        }),
      onSuccess: () => {
        queryClient.invalidateQueries("property_details");
        enqueueHoumSnackBar({
          body: "Las amenidades se han editado correctamente",
          type: "success",
        });
        returnToPropertyDetail();
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useEditAmenities;
