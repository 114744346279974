import { SnackbarProvider } from "notistack";
import { Route } from "react-router-dom";

import { EvaluatorProvider } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import EvaluatorForm from "apps/lead/pages/EvaluatorForm";
import Landing from "apps/payments/pages/LandingPayment";

import StartEvaluationLanding from "../pages/StartEvaluationLanding";

const TenantPublicRoutes = () => (
  <>
    <Route
      exact
      path={[
        "/tenant/:movementType/:countryCode/pay/:id",
        "/:movementType/:countryCode/pay/:id",
      ]}
    >
      <Landing />
    </Route>
    <Route exact path={["/tenant/start-evaluation/:applicantUid"]}>
      <StartEvaluationLanding />
    </Route>
    <SnackbarProvider>
      <Route exact path={["/lease/evaluation-form/:token"]}>
        <EvaluatorProvider>
          <EvaluatorForm />
        </EvaluatorProvider>
      </Route>
    </SnackbarProvider>
  </>
);

export default TenantPublicRoutes;
