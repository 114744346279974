import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

const useResumeOfferCardStyles = makeStyles((theme: Theme) => ({
  resumeOfferCard: {
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: "white",
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[75]}`,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(3),
    },
  },
  offerStatus: {
    display: "flex",
    justifyContent: "flex-start",
  },
  resumeTittle: {
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(1),
    fontSize: "0.875rem",
    fontWeight: 700,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: "1.25rem",
      lineHeight: 1.5,
      marginBottom: theme.spacing(2),
    },
  },
  offerDetails: {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      paddingTop: theme.spacing(2),
    },
  },
  epigraph: {
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.5,
      marginBottom: theme.spacing(1),
    },
  },
  value: {
    marginLeft: theme.spacing(0.5),
    fontWeight: 700,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginLeft: theme.spacing(1),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: "1rem",
    fontWeight: 700,
    lineHeight: 1.5,
    color: theme.palette.grey[500],
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginBottom: theme.spacing(3),
      fontSize: "1.25rem",
    },
  },
  responseTittle: {
    margin: theme.spacing(2, 0),
    fontSize: "1rem",
    fontWeight: 700,
    lineHeight: 1.5,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      margin: theme.spacing(3, 0),
      fontSize: "1.25rem",
    },
  },
  status: {
    display: "flex",
    borderRadius: 40,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    lineHeight: 1.75,
    background: theme.palette.grey[5],
    padding: theme.spacing(0, 2),
    textAlign: "center",
    width: "fit-content",
    alignItems: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: "1rem",
    },
  },
  accepted: {
    color: theme.palette.success.main,
    background: "#DAEECC",
  },
  rejected: {
    color: "#900000",
    background: "#EFCDCC",
  },
  rejectDetails: {
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginBottom: theme.spacing(2),
    },
  },
  icon: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: "1.125rem",
    },
  },
}));

export default useResumeOfferCardStyles;
