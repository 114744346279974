import { makeStyles, Theme } from "@material-ui/core";

const useMoreOptionsStyles = makeStyles((theme: Theme) => ({
  moreOptionsRoot: {
    margin: theme.spacing(0),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
  },
  grid: {
    display: "flex",
    flexWrap: "nowrap",
    gap: theme.spacing(2),
  },
}));

export default useMoreOptionsStyles;
