import Typography from "@material-ui/core/Typography";

import { IoPersonOutline, IoHomeOutline } from "@houm-com/ui-icons";
import { parsePrice, countryCurrency } from "apps/lead/utils/parsePrice";
import {
  EvaluationApplicant,
  PropertyApplicationModel,
} from "apps/lead/models/Application";

import usePropertyDetailsStyles from "./PropertyDetailsStyles";
import PropertyEvaluationDetails from "./PropertyEvaluationDetails";
import usePropertyDetails from "../../hooks/usePropertyDetails";

interface Props {
  property: PropertyApplicationModel;
  houmerName: string;
  evaluationData?: EvaluationApplicant;
}

const PropertyDetails = ({ property, houmerName, evaluationData }: Props) => {
  const classes = usePropertyDetailsStyles();
  const { propertyPrice, currency, houmer, evaluation } = usePropertyDetails(
    property,
    houmerName,
    evaluationData
  );

  return (
    <div className={classes.container}>
      <div className={classes.descriptionContainer}>
        <div className={classes.iconContainer}>
          <IoHomeOutline />
        </div>
        <Typography className={classes.descriptionText}>
          <b>Valor de arriendo: </b>
          {parsePrice(
            propertyPrice,
            countryCurrency(property.country, currency),
            property.country
          )}
        </Typography>
      </div>
      <div className={classes.descriptionContainer}>
        <div className={classes.iconContainer}>
          <IoPersonOutline />
        </div>
        <Typography className={classes.descriptionText}>
          <b>Houmer: </b>
          {houmer}
        </Typography>
      </div>
      {evaluation && <PropertyEvaluationDetails evaluationData={evaluation} />}
    </div>
  );
};

export default PropertyDetails;
