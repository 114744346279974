import { Size } from "models/designSystem";

export const getCicularProgressSize = (size: Size): number => {
  switch (size) {
    case "large":
      return 36;
    case "medium":
      return 32;
    case "small":
    default:
      return 28;
  }
};
