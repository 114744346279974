import { compareAsc } from "date-fns";
import { useEffect } from "react";

import useContract from "apps/tenant/hooks/useContract";
import useContractEnd from "apps/tenant/hooks/useContractEnd";
import useContractHistory from "apps/tenant/hooks/useContractHistory";
import useGetVisits from "apps/tenant/hooks/useGetVisits";
import { useHoumSnackBar } from "libs/houm-snackbar";

interface Props {
  email: string;
}

const useDashboard = ({ email }: Props) => {
  const { data: contract, isLoading: contractLoading } = useContract();
  const { isLoading: contractEndLoading } = useContractEnd();
  const { data: contractHistory, isLoading: contractHistoryLoading } =
    useContractHistory();
  const {
    data: visitsData,
    isLoading: visitsLoading,
    isError: isVisitsError,
  } = useGetVisits(email);
  const { enqueueHoumSnackBar } = useHoumSnackBar();

  const nextVisits = visitsData?.filter(
    (visit) => compareAsc(new Date(visit.date), new Date()) === 1
  );

  const showVisits = !contract || nextVisits?.length > 0;

  useEffect(() => {
    if (showVisits && isVisitsError) {
      enqueueHoumSnackBar({
        body: "Ha ocurrido un error cargando las visitas",
        type: "error",
      });
    }
  }, [enqueueHoumSnackBar, showVisits, isVisitsError]);

  return {
    isLoading:
      contractLoading ||
      contractEndLoading ||
      contractHistoryLoading ||
      visitsLoading,
    showBankAccounts: !!contractHistory?.length,
    showMoreOptions: !!contract,
    showRequestsCarrousel: !!contract,
    showPaymentData: !!contract,
    showRentRequirements: !contract,
    showFavorites: !contract,
    showWelcome: !contract,
    showVisits: showVisits && !isVisitsError,
    visits: visitsData,
    contract,
  };
};

export default useDashboard;
