import axios, { AxiosResponse } from "axios";

import { CreateLandlordEvaluationResponse } from "models/Evaluation";
import { Locale } from "models/Countries";
import { EVALUATIONS_URL } from "env";

import { getToken } from "./helper";

const evaluations = (token: string, country = "cl", version: string = "v2") =>
  axios.create({
    baseURL: `${EVALUATIONS_URL}/${country}/${version}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    },
  });

export default {
  getLandlordEvaluations: async (uid: string, country: Locale, token: string) =>
    evaluations(token, country).get(
      `/proprietary/evaluation?property_uid=${uid}&applicant_evaluation_status[]=Perfil aprobado&applicant_evaluation_status[]=Aprobado comercialmente`
    ),

  evaluateApplicantProfile: async (
    mainApplicantId: number,
    country: Locale,
    data,
    token: string
  ) =>
    evaluations(token, country).put(
      `/proprietary/evaluation/${mainApplicantId}/evaluate`,
      data
    ),

  createLandlordEvaluation: async (
    country: Locale,
    data,
    token: string
  ): Promise<AxiosResponse<CreateLandlordEvaluationResponse>> =>
    evaluations(token, country).post(`/proprietary/evaluation`, data),
};
