import { State, Action, SET_CURRENT_TAB } from "./tabTypes";

export const initialState: State = {
  currentTabValue: "",
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_CURRENT_TAB: {
      return { ...state, currentTabValue: action.currentTabValue };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
