import { format } from "date-fns";
import { round } from "lodash";

import { IProperty as IPropertyResponse } from "apps/landlord/services/propertyService/type";
import { SummaryBreakdownModelResponse } from "apps/landlord/services/paymentService/types";
import { IProperty } from "apps/landlord/models/Property";
import parsePrice from "apps/landlord/utils/parsePrice";
import { Locale } from "models/Countries";
import { countryCode } from "utils";

export const parseSearchProperty = (
  properties: IPropertyResponse[]
): Partial<IProperty>[] =>
  properties.map((property) => ({
    id: property.id,
    uid: property.uid,
    address: property.address,
    commune: property.comuna,
    country: countryCode(property.country),
    forRental: property.for_rental,
    forSale: property.for_sale,
    apartmentNumber: property.numero_depto,
    photos: property.photos.map((photo) => ({
      id: photo.id,
      createdAt: photo.created_at,
      updatedAt: photo.updated_at,
      filename: photo.filename,
      featured: photo.featured,
      order: photo.order,
      property: property.id,
      url: photo.url,
    })),
    region: property.region,
    propertyDetails: {
      currencyCode: property.property_details[0]?.currency_code,
      moneyType: property.property_details[0]?.tipo_moneda,
      salesMoneyType: property.property_details[0]?.tipo_moneda_venta,
      status: property.property_details[0]?.status,
      price: property.property_details[0]?.valor,
      salesPrice: property.property_details[0]?.valor_venta,
      firstHandoverDate:
        property.property_details[0]?.first_available_handover_date,
      totalArea: round(Number(property.property_details[0]?.m_terreno)),
      builtArea: round(Number(property.property_details[0]?.m_construidos)),
      bathrooms: property.property_details[0]?.banos,
      rooms: property.property_details[0]?.dormitorios,
      parkingSlots: property.property_details[0]?.estacionamientos,
      laundryRoom: property.property_details[0]?.has_laundry_room,
      allowsPets: property.property_details[0]?.mascotas,
      furnished: !!property.property_details[0]?.amoblado,
      balcony: property.property_details[0]?.has_balcony,
      age: property.year_built
        ? Number(format(new Date(), "yyyy")) - property.year_built
        : property.antiquity,
      latitude: property.property_details[0]?.latitud,
      longitude: property.property_details[0]?.longitud,
      commonExpenses: property.property_details[0]?.gc,
    },
    salesDetails: {
      status: property.sales_details?.status,
      currencyCode: property.sales_details?.currency_code,
      price: property.sales_details?.price,
    },
    streetNumber: property.street_number,
    type: property?.type,
  }));

export const parseSummaryFullbreakdown = (
  data: SummaryBreakdownModelResponse[],
  country: Locale
) =>
  data.map((summary) => ({
    id: summary.property_id,
    propertyId: summary.property_id,
    lease: parsePrice(summary.lease, summary.currency, country, true),
    adjustments: parsePrice(
      summary.adjustments,
      summary.currency,
      country,
      true
    ),
    agreedDate: summary.agreed_date,
    currency: summary.currency,
    depositDate: summary.deposit_date,
    depositStatus: summary.deposit_status,
    lesseePaymentDate: summary.lessee_payment_date,
    management: parsePrice(summary.management, summary.currency, country, true),
    paidByLessee: summary.lessee_payment_status === "PAID",
    payment: parsePrice(summary.payment, summary.currency, country, true),
  }));
