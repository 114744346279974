import { makeStyles, Theme } from "@material-ui/core/";

const usePageButtonStyles = makeStyles<Theme>((theme) => ({
  pageButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 4.5,
    width: 64,
    height: 64,
    [theme.breakpoints.up("sm")]: {
      width: 80,
      height: 80,
    },
  },
  pageButtonContainer: {
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  pageTitle: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: "1.25em",
    marginTop: theme.spacing(0.75),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
  pageTitle_disabled: {
    color: `${theme.palette.grey[900]}50`,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: "1.25em",
    marginTop: theme.spacing(0.75),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
  buttonRoot: {
    width: "100%",
    height: "100%",
  },
}));

export default usePageButtonStyles;
