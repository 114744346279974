import { format } from "date-fns";

import currency from "@houm-com/formats/currency";

import { CurrencyCodeType } from "apps/landlord/models/Currency";
import { Locale } from "models/Countries";

export const getCurrencyPrice = (
  price: number,
  currencyCode: CurrencyCodeType
) =>
  currency(price, currencyCode, {
    minimumFractionDigits: 0,
  });

export const getCCbyLocale = (locale: Locale): CurrencyCodeType =>
  (({
    co: "COP",
    cl: "CLP",
    mx: "MXN",
  }[locale] || "CLP") as CurrencyCodeType);

export const getPublishedDate = (publicationDate: string) =>
  publicationDate ? format(new Date(publicationDate), "dd-MM-yyyy") : undefined;
