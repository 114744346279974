import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import { ReactNode } from "react";
import LeavingConfirmationStep from "./components/LeavingConfirmationStep";
import useContractEndStyles from "./ContractEndStyles";

type Props = {
  handleBack: () => void;
  handleNext: () => void;
  confirmationMessage: ReactNode;
  isOpen: boolean;
};

const ConfirmationDialog = ({
  handleBack,
  handleNext,
  isOpen,
  confirmationMessage,
}: Props) => {
  const classes = useContractEndStyles();
  return (
    <CustomDialog
      onClose={handleBack}
      open={isOpen}
      classes={{ modalContainer: classes.modal }}
    >
      <LeavingConfirmationStep
        handleNext={handleNext}
        handleBack={handleBack}
        confirmationMessage={confirmationMessage}
      />
    </CustomDialog>
  );
};

export default ConfirmationDialog;
