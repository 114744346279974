import DependentForm from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/components/co/DependentForm";
import IndependentForm from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/components/co/IndependentForm";
import RetiredForm from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles/components/co/RetiredForm";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import {
  EvaluatorPages,
  UserApplicant,
} from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper";
import {
  setActiveStep,
  setApplicant,
  setUserInformation,
  setPage,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";

const IncomeValidationFiles = () => {
  const {
    state: { user },
    dispatch,
  } = useEvaluation();

  const onFinishSubmit = (data: Partial<UserApplicant>) => {
    const userInfo = {
      ...data,
      isCompleted: true,
    };
    dispatch(setUserInformation(userInfo));
    dispatch(setApplicant(userInfo));
    dispatch(setUserInformation(undefined));
    dispatch(setActiveStep(0));
    dispatch(setPage(EvaluatorPages.RESUME));
  };

  return (
    <>
      <Header
        title="Validación de ingresos"
        subtitle="Estos documentos pueden estar en formato PDF o como imagen (JPEG, JPG o PNG)"
        stepTitle="Validación laboral e ingresos"
      />
      <FormWrapper classNameFormWrapper="flex flex-col">
        {user?.employment?.workerType === "independent" && (
          <IndependentForm onFinishSubmit={onFinishSubmit} />
        )}
        {user?.employment?.workerType === "dependent" && (
          <DependentForm onFinishSubmit={onFinishSubmit} />
        )}
        {user?.employment?.workerType === "retired" && (
          <RetiredForm onFinishSubmit={onFinishSubmit} />
        )}
      </FormWrapper>
    </>
  );
};

export default IncomeValidationFiles;
