import { Box, Typography } from "@material-ui/core";

import { IoArrowForward } from "@houm-com/ui-icons";

import useHelpWidgetDesktopTooltipStyles from "./HelpWidgetDesktopTooltipStyles";

const HelpWidgetDesktopTooltip = ({
  goToHelpCenter,
  goToHelpForm,
  goToHelpChat,
  onClose,
}) => {
  const classes = useHelpWidgetDesktopTooltipStyles();
  return (
    <Box
      data-testid="wrapper"
      onClick={onClose}
      className={classes.desktopTooltip}
    >
      <Typography className={classes.destopTooltipTitle}>
        Resuelve tus dudas aquí
      </Typography>
      <Box
        data-testid="btn__help-center"
        onClick={goToHelpCenter}
        className={classes.desktopTooltipLinkItem}
      >
        <Typography className={classes.link}>Centro de ayuda</Typography>
        <IoArrowForward size="18" className={classes.linkIcon} />
      </Box>
      <Box
        data-testid="btn__help-form"
        onClick={goToHelpForm}
        className={classes.desktopTooltipLinkItem}
      >
        <Typography className={classes.link}>Formulario</Typography>
        <IoArrowForward size="18" className={classes.linkIcon} />
      </Box>
      <Box
        data-testid="btn__help-chat"
        onClick={goToHelpChat}
        className={classes.desktopTooltipLinkItem}
      >
        <Typography className={classes.link}>Chat</Typography>
        <IoArrowForward size="18" className={classes.linkIcon} />
      </Box>
    </Box>
  );
};

export default HelpWidgetDesktopTooltip;
