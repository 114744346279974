import { makeStyles, Theme } from "@material-ui/core";

// -------------------------------------------------------------

const MOBILE_MAX_HEIGHT = 180;

// -------------------------------------------------------------

interface Props {
  navbarHeight: number;
}

export default makeStyles<Theme, Props>((theme) => ({
  carouselRoot: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      height: "100%",
    },
  },
  carousel: {
    width: "100%",
    height: "100%",
    "& .control-dots": {
      "& .dot": {
        boxShadow: "none",
        opacity: 1,
        backgroundColor: "#DFE5E8",
      },
      "& .dot.selected": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  carouselImage: {
    objectFit: "cover",
    height: MOBILE_MAX_HEIGHT,
    filter: "brightness(40%)",
    [theme.breakpoints.up("sm")]: {
      height: ({ navbarHeight }) => `calc(100vh - ${navbarHeight}px)`,
    },
  },
}));
