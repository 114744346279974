import { Route, useRouteMatch } from "react-router-dom";
import TermsAndConditions from "../pages/TermsAndConditions";

const LandlordPublicRoutes = () => {
  const match = useRouteMatch();
  return (
    <Route exact path={`${match.path}/terms-and-conditions/:token`}>
      <TermsAndConditions />
    </Route>
  );
};
export default LandlordPublicRoutes;
