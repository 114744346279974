import { useMutation, useQueryClient } from "react-query";

import { useHoumSnackBar } from "libs/houm-snackbar";

import { useGetToken } from "hooks";
import { BSCompanyForm } from "apps/landlord/models/ServicesCompanies";
import propertiesDuesService from "apps/landlord/services/propertiesDuesService";
import { useProperty } from "apps/landlord/hooks";
import {
  Bill,
  CompanyType,
} from "apps/landlord/services/propertiesDuesService/type";
import useBillsInfo from "./useBillInfo";

const useChangeBasicServices = () => {
  const queryClient = useQueryClient();
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const { propertyData } = useProperty();
  const { id } = propertyData || {};
  const { data: billsInfoData, isLoading: billsInfoLoading } = useBillsInfo();
  const waterData = billsInfoData?.water;
  const energyData = billsInfoData?.energy;
  const gasData = billsInfoData?.gas;
  const fetchedBills = {
    water: waterData,
    energy: energyData,
    gas: gasData,
  };
  const { getToken } = useGetToken();
  const {
    mutate,
    isLoading: submitting,
    isSuccess,
  } = useMutation(
    async (formData: Partial<BSCompanyForm>) => {
      const authToken = await getToken();
      const formDataBills: Bill[] = [];
      ["water", "energy", "gas"].forEach((companyType) => {
        const companyInfoAddedToForm =
          formData[`${companyType}CompanyName`] ||
          formData[`${companyType}ClientNumber`];
        const infoInFormIsNew =
          formData[`${companyType}ClientNumber`] !==
          fetchedBills[companyType]?.clientInfo?.externalId;

        if (companyInfoAddedToForm && infoInFormIsNew) {
          formDataBills.push({
            company_type: companyType as CompanyType,
            company: formData[`${companyType}CompanyName`],
            property_id: id,
            client_info: {
              external_id: formData[`${companyType}ClientNumber`],
            },
            is_active: true,
          });
        }
      });
      const dataToPost = formDataBills.map((formBill) =>
        fetchedBills[formBill.company_type]
          ? propertiesDuesService.patchBill(id, authToken, {
              company: fetchedBills[formBill.company_type].company,
              client_info: { external_id: formBill.client_info.external_id },
              property_id: id,
            })
          : propertiesDuesService.postBill(id, authToken, formBill)
      );

      await Promise.all(dataToPost);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("billsInfo");
        queryClient.invalidateQueries("propertyBillsDetails");
        enqueueHoumSnackBar({
          body: "¡Servicios básicos guardados exitosamente!",
          type: "success",
        });
      },
      onError: (error) => {
        enqueueHoumSnackBar({
          body: `Ha ocurrido un error actualizando la información. ${error}`,
          type: "error",
        });
      },
    }
  );
  return { mutate, submitting: submitting || billsInfoLoading, isSuccess };
};

export default useChangeBasicServices;
