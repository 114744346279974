export const CURRENCIES = {
  Chile: [
    { value: "CLP", label: "CLP" },
    { value: "UF", label: "UF" },
  ],
  Mexico: [
    { value: "MXN", label: "MXN" },
    { value: "USD", label: "USD" },
  ],
  Colombia: [{ value: "COP", label: "COP" }],
};

export const rejectedReasons = [
  { value: "Propiedad ya se vendió", label: "Propiedad ya se vendió" },
  {
    value: "Ya no se quiere vender la propiedad",
    label: "Ya no se quiere vender la propiedad",
  },
  {
    value: "Valor de la oferta no se ajusta a lo requerido",
    label: "Valor de la oferta no se ajusta a lo requerido",
  },
  {
    value: "Busco solo ofertas con pago al contado",
    label: "Busco solo ofertas con pago al contado",
  },
];

export const priceReason = "Valor de la oferta no se ajusta a lo requerido";

export const termsAndConditionsText = {
  mx: `1. PLAZO PARA CELEBRAR EL CONTRATO: En caso de aceptarse la presente Carta de Intención de Compra, las partes se obligan a celebrar el Contrato de Promesa de Venta dentro de los 5 días naturales siguientes a la aceptación.

  Durante el plazo señalado en el párrafo anterior, el Propietario se obliga a dejar de promocionar el Inmueble y a no venderlo, ni rentarlo a persona distinta al Oferente que haya presentado la Carta de Intención de compra a través de Houm Tech México, S.A. de C.V.
  
  2. VIGENCIA: La presente Carta de Intención de Compra tendrá una vigencia de 3 días naturales contados a partir de su fecha de presentación. En caso de no recibir la resolución del Propietario dentro de dicho plazo, el Oferente quedará liberado de la misma y podrá continuar con otras negociaciones. 
  
  3. DOCUMENTACIÓN: En caso de aceptarse la presente Carta de Intención de Compra, las partes se obligan a compartir toda la documentación necesaria para celebrar el contrato, dentro de los 2 días naturales siguientes a la aceptación (en lo sucesivo la ¨Documentación*).
  
  Las partes acuerdan compartir la documentación que a continuación se enlistan. Lo anterior sin perjuicio de la revisión de la documentación, se desprenda que se requiera documentación adicional para la elaboración del Contrato o para dictaminar la procedencia de la operación.
  
  Documentos e Información del Inmueble:
  
  1) Copia de título de propiedad del Inmueble con constancia de Inscripción en el Registro Público de la Propiedad. 
  2) Boleta predial del último bimestre del Inmueble 
  3) Boleta de agua del último bimestre del Inmueble. 
  
  Documentos e Información del Propietario:
  
  1) Copia de la credencial para votar o Pasaporte vigentes.
  2) Copia de acta de matrimonio (en el caso de haber adquirido el inmueble casado por sociedad conyugal se requerirá de la misma documentación del cónyuge).
  3) Constancia de Situacion Fiscal
  4) Clave Única de Registro de Población CURP
  5) Comprobante de domicilio con vigencia mayor a 3 meses
  6) Carátula del estado de cuenta bancario en donde se realizará el pago del precio, el cual deberá estar a nombre del Propietario.
  7) Correo electrónico y domicilio para notificaciones relacionadas al Contrato.
  
  Documentos e Información del Oferente:
  
  1) Copia de la credencial para votar o Pasaporte vigentes.
  2) Constancia de Situación Fiscal
  3) Clave Unica de Registro de Población CURP
  4) Comprobante de domicilio no mayor a 3 meses 
  5) Correo electrónico y domicilio para notificaciones relacionadas al Contrato.
  6) Autorización de crédito hipotecario (en su caso).`,
  cl: "No se encuentra disponible",
  co: "No se encuentra disponible",
};
