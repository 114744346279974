import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

export default makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(0, 13),
    },
  },
  loading: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginTop: theme.spacing(5),
    },
  },
  navigation: {
    display: "flex",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    color: theme.palette.primary.main,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
      cursor: "pointer",
    },
  },
  photos: {
    height: 170,
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginBottom: 0,
    },
  },
  headers: {
    padding: theme.spacing(0, 0, 5, 0),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(0, 0, 0, 5.5),
    },
  },
  nextSteps: {
    padding: theme.spacing(0, 0, 4.5, 0),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(4.5, 0, 0, 0),
    },
  },
  propertyManagement: {
    padding: theme.spacing(0, 0, 4.5, 0),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(4.5, 0, 0, 5.5),
    },
  },
  sectionTitle: {
    marginTop: 0,
    marginBottom: theme.spacing(3),
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: "bold",
    color: theme.palette.grey[900],
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginTop: 0,
    },
  },
}));
