import { Box } from "@material-ui/core";
import { numberWithCommas } from "utils";

import useBreakdownItemStyles from "./BreakdownItemStyles";

interface Props {
  label: string;
  sign: "-" | "+";
  value: number;
}

const BreakdownItem = ({ label, sign, value }: Props) => {
  const classes = useBreakdownItemStyles();

  return (
    <Box className={classes.breakdownItemRoot}>
      <Box className={classes.breakdownText}>{label}</Box>
      <Box className={classes.breakdownText}>
        {sign}${numberWithCommas(value)}
      </Box>
    </Box>
  );
};

export default BreakdownItem;
