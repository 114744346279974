import { AxiosResponse } from "axios";
import { SERVICES_URL } from "env";

import { KhipuResponse } from "models/Payments";

import { createNoAuthHttp } from "./helper";

const http = createNoAuthHttp(
  SERVICES_URL,
  "application/x-www-form-urlencoded"
);

const redirectionURL = (document: string) =>
  `https://me.houm.com/tenant/rentPayment/cl/khipu/${document}/1`;

export default {
  getKhipuUrls(
    quotationId: string,
    document: string
  ): Promise<AxiosResponse<KhipuResponse>> {
    return http.get("payments/cl/v3/thirdparties/khipu/payment", {
      params: {
        quotation_id: quotationId,
        return_url: redirectionURL(document),
      },
    });
  },
  getKhipuOnboarding(
    quotationId: string,
    document: string
  ): Promise<AxiosResponse<KhipuResponse>> {
    return http.get("payments/cl/v3/thirdparties/khipu/onboarding", {
      params: {
        quotation_id: quotationId,
        return_url: redirectionURL(document),
      },
    });
  },
};
