import { makeStyles, Theme } from "@material-ui/core";

const useDisclaimerRentalInfoStyles = makeStyles<Theme>((theme) => ({
  disclaimerContainer: {
    display: "flex",
    flexDirectioin: "row",
    justifyContent: "center",
    width: "100%",
    padding: theme.spacing(1),
    borderRadius: 4,
  },
  disclaimerText: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
  },
  text: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.57,
    },
  },
  link: {
    textDecoration: "underline",
    fontWeight: 700,
    color: "#2C73DB",
    "&:hover": {
      color: "#2C73DB",
      textDecoration: "underline !important",
    },
  },
}));

export default useDisclaimerRentalInfoStyles;
