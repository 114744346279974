import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  noImgsText: {
    color: theme.palette.grey[200],
  },
  tableImgContainerBtn: {
    display: "flex",
    justifyContent: "flex-start",
    transition: "0.3s",
    "&:hover": {
      transform: "scale(1.05)",
    },
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: 60,
    borderRadius: 8,
    overflow: "hidden",
  },
  image: {
    width: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  tableImgContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: 50,
    borderRadius: 8,
    overflow: "hidden",
  },
  tableImg: {
    width: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  carouselImgContainer: {
    display: "flex",
    maxHeight: "50vh",
  },
  carouselImg: {
    display: "flex",
    width: "100%",
    objectFit: "contain",
  },
}));
