import PlanInfo from "./models";

const PREMIUM_PLAN_PLUS = 310;
const PREMIUM_PLAN = 311;
const BASIC_PLAN = 313;
const ONLY_BROKERAGE_PLAN = 46;

export const plansList: PlanInfo[] = [
  {
    id: BASIC_PLAN,
    name: "Básico",
    description: "Arrendamiento con administración",
    benefits: [
      "Beneficios de solo corretaje, más:",
      "Cobranza y seguimiento de los pagos del arriendo",
      "Coordinación con el arrendatario",
      "Gestión de arreglos a la propiedad y coordinación con proveedores",
      "Plataforma de seguimiento de propiedades",
    ],
    brokerageCommissionText: "50% del arriendo + IVA sólo el primer mes",
    administrationCommissionText: "7% del arriendo + IVA",
  },
  {
    id: PREMIUM_PLAN_PLUS,
    name: "Premium +",
    description: "Administración con pago garantizado",
    benefits: [
      "Los beneficios del plan premium, más:",
      "Seguro de 12 meses ante no pago de arriendo",
      "3 meses de cobertura ante daños y deudas en GGCC y SSBB",
    ],
    brokerageCommissionText: "50% del arriendo + IVA sólo el primer mes",
    administrationCommissionText: "11,5% del arriendo (IVA incluido)",
    isMainPlan: true,
  },
  {
    id: PREMIUM_PLAN,
    name: "Premium",
    description: "Administración con pago garantizado",
    benefits: [
      "Los beneficios del plan básico, más:",
      "Pago fijo los 5 de cada mes a tu cuenta",
      "Seguro de 6 meses contra no pago del arriendo",
      "1 mes de cobertura ante daños y deudas en GGCC y SSBB (garantía)",
    ],
    brokerageCommissionText: "50% del arriendo + IVA sólo el primer mes",
    administrationCommissionText: "8,7% del arriendo (IVA incluido)",
  },
];

export const brokerPlan = {
  id: ONLY_BROKERAGE_PLAN,
  name: "Solo corretaje",
  description: "Promoción y arrendamiento",
  benefits: [
    "Sesión de fotos y video",
    "Publicación en principales portales",
    "Mostrar propiedad",
    "Análisis de crédito rigurosos",
    "Firma contrato online",
  ],
  brokerageCommissionText: "50% del 1° arriendo + IVA",
  administrationCommissionText: "",
  operationalCost: "1.4 UF + IVA",
};

export const readjustOptions = [
  { value: "biannual", label: "Semestral" },
  { value: "annual", label: "Anual" },
  { value: "no_readjust", label: "Sin reajuste" },
];
