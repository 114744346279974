import { Box } from "@material-ui/core";

import { VisitStatus } from "apps/tenant/models/Visit";

import useStatusBarStyles from "./StatusBarStyles";

interface Props {
  status?: VisitStatus;
}

const StatusBar = ({ status = "ok" }: Props) => {
  const classes = useStatusBarStyles({ status });

  return <Box className={classes.statusBarRoot} />;
};

export default StatusBar;
