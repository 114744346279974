import { makeStyles, Theme } from "@material-ui/core";

const useCalendarDayStyles = makeStyles((theme: Theme) => ({
  calendarDayRoot: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dot: {
    position: "absolute",
    bottom: -6,
    [theme.breakpoints.up("sm")]: {
      bottom: -10,
    },
  },
  pastVisit: {
    color: "#BFCBD1",
  },
  upcomingVisit: {
    color: theme.palette.primary.main,
  },
}));

export default useCalendarDayStyles;
