export const LOCALE_BASED_NEW_EOC_INTRO = {
  cl: "Indicarnos la fecha en la que deseas que el arrendatario entregue tu propiedad. No lo olvides, según lo estipulado en tu contrato, la salida se debe solicitar con al menos 30 días de anticipación ya que debemos de notificar al arrendatario.",
  mx: "Indicarnos la fecha en la que deseas que el inquilino entregue tu inmueble. No lo olvides, según lo estipulado en tu contrato, la salida se debe solicitar con al menos 90 días de anticipación ya que debemos de notificar al inquilino",
  co: "Indícanos la fecha en la que deseas que el arrendatario entregue tu propiedad. No lo olvides, según lo estipulado en tu contrato, la salida se debe solicitar con al menos 90 días de anticipación ya que debemos de notificar al arrendatario.",
};

export const LOCALE_BASED_NEW_EOC_CONCERNS = {
  cl: "",
  mx: "",
  co: "En caso de no cumplir con el plazo mínimo de aviso o la duración del arriendo pactada en el contrato, se generará una multa equivalente a 3 cánones de arriendo.",
};

export const LOCALE_BASED_ONGOING_EOC_INTRO = {
  cl: "Te enviamos la confirmación del proceso de salida de tu arrendatario a tu correo registrado.",
  mx: "Te enviamos la confirmación del proceso de salida de tu inquilino a tu correo registrado.",
  co: "Te enviamos la confirmación del proceso de salida de tu arrendatario a tu correo registrado.",
};

export const LOCALE_BASED_ONGOING_EOC_WIP = {
  cl: "Ya estamos trabajando en coordinar todo para poder recibir tu propiedad en la fecha solicitada.",
  mx: "Ya estamos trabajando en coordinar todo para poder recibir tu inmueble en la fecha solicitada.",
  co: "Ya estamos trabajando en coordinar todo para poder recibir tu propiedad en la fecha solicitada.",
};

export const LOCALE_BASED_ONGOING_EOC_LEAVING_DATE_REMINDER = {
  cl: "realizaremos la entrega y recolección de las llaves de la propiedad con tu arrendatario. Te contactaremos unos días antes para coordinar el horario.",
  mx: "realizaremos la entrega y recolección de las llaves del inmueble con tu inquilino. Te contactaremos unos días antes para coordinar el horario.",
  co: "realizaremos la entrega y recolección de las llaves de la propiedad con tu arrendatario. Te contactaremos unos días antes para coordinar el horario.",
};
