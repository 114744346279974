import { makeStyles, Theme } from "@material-ui/core";

const useControlledCheckedFileStyles = makeStyles<Theme>((theme) => ({
  box: {
    pointerEvents: "auto",
  },
  buttonsList: {
    marginTop: theme.spacing(1),
  },
  backgroundColorOnWholeIcon: {
    pointerEvents: "auto",
    "&.Mui-disabled": {
      "&.Mui-checked": {
        color: theme.palette.primary.main,
      },
    },
    paddingTop: 0,
  },
  formControlLabelContainer: {
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
  },
}));

export default useControlledCheckedFileStyles;
