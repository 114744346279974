import { useAuth0 } from "@auth0/auth0-react";

import useAuthLogoutButtonStyles from "./AuthLogoutButtonStyles";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

const AuthLogoutButton = () => {
  const { logout } = useAuth0();
  const classes = useAuthLogoutButtonStyles();
  return (
    <DesignSystemButton
      label="Cerrar sesión"
      onClick={() => logout()}
      variant="tertiaryB"
      size="small"
      buttonClassName={classes.button}
    />
  );
};

export default AuthLogoutButton;
