import { BasicServicesFormContextProvider } from "./context/BasicServicesFormContext";
import BasicServicesForm from "./BasicServicesForm";

const BasicServicesFormView = () => (
  <BasicServicesFormContextProvider>
    <BasicServicesForm />
  </BasicServicesFormContextProvider>
);

export default BasicServicesFormView;
