import { FavoriteProperty } from "apps/account/models/FavoriteProperty";
import Spinner from "Components/Shared/partials/Spinner";
import { Locale } from "models/Countries";

import useDeleteFavorite from "../../hooks/useDeleteFavorite";
import NoResults from "../NoResults";
import PropertyCard from "../PropertyCard";
import useStyles from "./FavoritesListStyles";

interface Props {
  countryCode: Locale;
  propertiesList: FavoriteProperty[];
  refetchFavorites: () => void;
  isLoading: boolean;
  mode: "rent" | "sale";
}
const FavoritesList = ({
  countryCode,
  propertiesList,
  refetchFavorites,
  isLoading,
  mode,
}: Props) => {
  const classes = useStyles();
  const { mutate } = useDeleteFavorite({ onSuccessHandler: refetchFavorites });

  const hasFavorites = propertiesList?.length > 0;

  if (isLoading) return <Spinner />;

  if (!hasFavorites)
    return (
      <div className={classes.listContainer}>
        <NoResults countryCode={countryCode} />;
      </div>
    );

  return (
    <div className={classes.listContainer}>
      {propertiesList.map((item, index) => (
        <div key={index} className={classes.item}>
          <PropertyCard
            property={item}
            locale={countryCode}
            mode={mode}
            handleFavoriteToggle={() => mutate(item.id)}
            isFavorite
          />
        </div>
      ))}
    </div>
  );
};

export default FavoritesList;
