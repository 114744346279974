import { useFormContext } from "react-hook-form";
import Collapse from "@material-ui/core/Collapse";

import { useClosureForm } from "context/closureFormContext/closureFormContext";
import fields from "context/closureFormContext/closureFormFields";
import { BooleanString as BS } from "context/closureFormContext/constants";

import { FilteredTextField } from "../FilteredComponents";

const PublicDeedFields = () => {
  const { state } = useClosureForm();
  const { watch } = useFormContext();

  const stepState = state.propertyData;
  const stepFields = fields.property;

  return (
    <Collapse in={watch("publicDeed") === BS.YES}>
      <FilteredTextField
        id="publicDeedNumber"
        fieldInfo={stepFields.publicDeedNumber()}
        defaultValue={stepState.publicDeedNumber}
      />

      <FilteredTextField
        id="publicDeedDate"
        fieldInfo={stepFields.publicDeedDate()}
        defaultValue={stepState.publicDeedDate}
        textFieldProps={{
          textFieldProps: { type: "date" },
        }}
      />
    </Collapse>
  );
};

export default PublicDeedFields;
