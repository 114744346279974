import { Box } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

import { isMobile } from "react-device-detect";
import useStepComponent from "./useStepComponent";
import useContractEndStyles from "./ContractEndStyles";

type Props = {
  handleClose?: () => void;
};

const ContractEnd = ({ handleClose }: Props) => {
  const classes = useContractEndStyles();

  const { currentStepComponent, dialogComponent } =
    useStepComponent(handleClose);

  return (
    <>
      {!isMobile && (
        <Box className={classes.drawerHeader}>
          <Box className={classes.iconContainer} onClick={handleClose}>
            <Close />
          </Box>
        </Box>
      )}
      {currentStepComponent}
      {dialogComponent}
    </>
  );
};

export default ContractEnd;
