import { makeStyles, Theme } from "@material-ui/core/";

interface Props {
  navbarHeight: number;
}

const usePropertyLayoutStyles = makeStyles<Theme, Props>((theme) => ({
  pageContainer: {
    maxWidth: 1280,
    padding: theme.spacing(4, 2, 2, 2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 5),
      gap: theme.spacing(2),
    },
  },
}));

export default usePropertyLayoutStyles;
