import { makeStyles, Theme } from "@material-ui/core";

const useButtonLinkStyles = makeStyles<Theme>((theme) => ({
  button: {
    textTransform: "none",
    gap: 12,
    padding: theme.spacing(0, 1),
  },
  buttonLabel: {
    display: "flex",
    alignItems: "center",
    gap: 12,
  },
  linkText: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    padding: theme.spacing(0),
    lineHeight: 1.57,
    fontWeight: 700,
  },
  arrowIcon: {
    // "& path": {
    //   stroke: theme.palette.primary.main,
    // },
    color: theme.palette.primary.main,
  },
}));

export default useButtonLinkStyles;
