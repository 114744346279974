import { makeStyles, Theme } from "@material-ui/core";

const useWelcomeStepStyles = makeStyles<Theme>((theme) => ({
  fullScreenFormContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4, 3, 15),
    },
  },
  informationStepContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    flexGrow: 1,
    justifyContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 5),
    },
  },
  informationStepContentText: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: 1.5,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  informationStepTitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(28),
  },

  informationStepGuidelineBox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: theme.spacing(2),
  },
  informationStepGuidelineIcon: {
    flexGrow: 0,
    display: "flex",
    backgroundColor: "#ECF2F4",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1),
    color: "#46788C",
  },
  informationSetpGuidelineDetails: {
    flexGrow: 1,
  },
  informationStepGuidelineHead: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    lineHeight: 1.5,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  informationStepGuidelineDescription: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: 1.5,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export default useWelcomeStepStyles;
