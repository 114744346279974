import { CountryCode } from "libphonenumber-js";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Box } from "@material-ui/core";

import Input from "apps/ui/formComponents/Input";
import PhoneInput from "apps/ui/formComponents/PhoneInput";

import ButtonsSection from "../ButtonsSection";
import ProfileCard from "../ProfileCard";
import useChangePhone from "./hooks/useChangePhone";
import usePhoneFormStyles from "./PhoneFormStyles";
import { useFeatureManager } from "@houm-com/feature-manager";

interface Props {
  country: CountryCode;
  phone: string;
}

const PhoneForm = ({ country, phone }: Props) => {
  const [edit, setEdit] = useState(false);
  const classes = usePhoneFormStyles({ disabled: !edit });
  const { t } = useTranslation("account.profile");
  const { register, handleSubmit, setValue } = useForm();
  const { mutate, submitting } = useChangePhone();
  const { data } = useFeatureManager("ENABLE_PHONE_CHANGE", {
    marketCode: country.toLowerCase(),
  });

  const hasEnabledPhoneChange = Boolean(data?.enabled);

  const onSubmit = (data: { phone: string; country: string }) => {
    mutate({ phone: `${data.country}${data.phone}` });
  };

  return (
    <ProfileCard text={!isMobile && t("phone_text")}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.inputsWrapper}>
          <PhoneInput
            id="country"
            setValue={setValue}
            {...register("country")}
            defaultValue={country.toLowerCase()}
            disabled={!edit}
            classNames={{ root: classes.inputRoot }}
          />
          <Input
            id="phone"
            {...register("phone")}
            defaultValue={phone}
            disabled={!edit}
            classNames={{
              root: classes.inputRoot,
              input: classes.input,
            }}
          />
        </Box>
        {hasEnabledPhoneChange && (
          <ButtonsSection
            edit={edit}
            setEdit={setEdit}
            submitting={submitting}
          />
        )}
      </form>
    </ProfileCard>
  );
};

export default PhoneForm;
