import { makeStyles, Theme } from "@material-ui/core/";
import { DESKTOP_HEADER_HEIGHT } from "apps/lead/constants/layout";

const useStyles = makeStyles<Theme>((theme) => ({
  contentRoot: {
    width: "100%",
    marginTop: theme.spacing(DESKTOP_HEADER_HEIGHT / 8),
  },
  contentRightContainer: {
    padding: theme.spacing(0, 6.75),
  },
  textContainer: {
    padding: 0,
    margin: 0,
    marginTop: theme.spacing(11),
    maxWidth: 506,
  },
  buttonContainer: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "end",
  },
  image: {
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    objectFit: "cover",
    objectPosition: "center center",
    height: `calc(100vh - ${DESKTOP_HEADER_HEIGHT}px)`,
  },
}));

export default useStyles;
