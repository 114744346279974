import { useHistory } from "react-router-dom";
import { isDesktop } from "react-device-detect";

import { Grid, Typography } from "@material-ui/core";

import { getIsGuaranteedPlan } from "apps/landlord/utils/getIsGuaranteedPlan";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import BackNavigation from "apps/landlord/components/BackNavigation";
import PlanTypeTag from "apps/landlord/components/PlanTypeTag";
import { PaymentOptionType } from "apps/landlord/models/Plan";
import { Locale } from "models/Countries";

import usePaymentsHeaderStyles from "./PaymentHeaderStyles";
import Receipts from "../Receipts";

interface Props {
  paymentDate: string | null;
  paymentOption: PaymentOptionType;
  country: Locale;
  propertyLoading: boolean;
}

const PaymentHeader = ({
  paymentDate,
  paymentOption,
  country,
  propertyLoading,
}: Props) => {
  const history = useHistory();
  const classes = usePaymentsHeaderStyles();

  return (
    <Grid container className={classes.header}>
      <Grid item xs={12} sm={6} className={classes.gridItems}>
        <BackNavigation
          className={classes.backButton}
          onClick={() => history.goBack()}
        />
        <Typography className={classes.title}>Detalle de tu pago</Typography>
        <Typography className={classes.dateLabel}>
          Fecha:{" "}
          <Typography component="span" className={classes.date}>
            {paymentDate ?? "No se pudo obtener fecha"}
          </Typography>
        </Typography>
        <PlanTypeTag
          containerClassname={classes.planContainer}
          labelClassname={classes.planLabel}
          isGuaranteed={getIsGuaranteedPlan(paymentOption)}
        />
      </Grid>
      {isDesktop && country === "cl" && (
        <Grid
          item
          container
          xs={12}
          sm={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Loading loading={propertyLoading}>
            <Receipts country={country} />
          </Loading>
        </Grid>
      )}
    </Grid>
  );
};

export default PaymentHeader;
