import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Drawer from "apps/ui/CustomDrawer/Drawer";
import DrawerHeader from "apps/ui/CustomDrawer/DrawerHeader";
import DrawerHeaderStepper from "apps/ui/CustomDrawer/DrawerHeaderStepper";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";

import PropertyDetailsStep from "./components/PropertyDetailsStep";
import WelcomeStep from "./components/WelcomeStep";
import { STEPS } from "./types";

const CreateProperty = () => {
  const { open, currentStep, setCurrentStep } = useCustomDrawer();
  const history = useHistory();

  const returnToSummary = () => history.push("/landlord");

  useEffect(() => {
    setCurrentStep(STEPS.Welcome);
  }, [setCurrentStep]);

  const ComponentsByStep = {
    [STEPS.Welcome]: WelcomeStep,
    [STEPS.PropertyDetails]: PropertyDetailsStep,
  };

  const CurrentStep = ComponentsByStep[currentStep];

  const stepperSteps = [
    {
      isActive: currentStep === STEPS.Welcome,
      onClick: () => setCurrentStep(STEPS.Welcome),
    },
    {
      isActive: currentStep === STEPS.PropertyDetails,
      onClick: () => setCurrentStep(STEPS.PropertyDetails),
    },
  ];

  return (
    <Drawer open={open} onHandleClose={returnToSummary}>
      <DrawerHeader onHandleClose={returnToSummary}>
        <DrawerHeaderStepper steps={stepperSteps} />
      </DrawerHeader>
      <CurrentStep />
    </Drawer>
  );
};

export default CreateProperty;
