import { Dispatch, SetStateAction } from "react";

import { DocumentOption } from "context/loiFormContext/loiFormTypes";
import { FieldProp } from "context/loiFormContext/loiFormFields";
import { CountryUpperCase } from "models/Countries";

export const filterFieldByCountry = (
  fieldInfo: FieldProp,
  country: CountryUpperCase
): boolean => !country || !fieldInfo.countries.includes(country);

export const handleDocumentTypeChange = (
  documentTypes: DocumentOption[],
  type: string,
  setRegex: Dispatch<SetStateAction<RegExp>>
) => {
  if (type) {
    const documentRegex = new RegExp(
      documentTypes.find(
        (doc) => doc.value.toString() === type
      )?.validationRegex
    );
    setRegex(documentRegex);
  }
};
