import currency from "@houm-com/formats/currency";

import { Box, Typography } from "@material-ui/core";

import { CurrencyCodeType } from "apps/landlord/models/Currency";
import usePropertyPriceStyles from "./PropertyPriceStyles";

interface Props {
  propertyPrice: number | null;
  currencyCode: CurrencyCodeType;
  propertyType?: "rent" | "sale";
}

const PropertyPrice = ({
  propertyPrice,
  currencyCode,
  propertyType = "rent",
}: Props) => {
  const type = propertyType === "rent" ? "Arriendo" : "Venta";
  const classes = usePropertyPriceStyles();
  const text = propertyPrice
    ? currency(propertyPrice, currencyCode, { minimumFractionDigits: 0 })
    : "por definir";

  return (
    <Box>
      <Typography className={classes.text}>{`${type} ${text}`}</Typography>
    </Box>
  );
};

export default PropertyPrice;
