import { Grid, Typography } from "@material-ui/core";

import { useGetCurrentUser } from "hooks";

import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji/Emoji";
import { IProperty } from "apps/landlord/models/Property";

import useSummaryHeaderStyles from "./SummaryHeaderStyles";
import PropertySearch from "../PropertySearch";

interface Props {
  onChoseProperty: (e: Partial<IProperty>) => void;
}

const SummaryHeader = ({ onChoseProperty }: Props) => {
  const classes = useSummaryHeaderStyles();
  const { user } = useGetCurrentUser();

  return (
    <Grid container className={classes.headerContainer}>
      <Grid item xs={12} sm={6} className={classes.header}>
        <Typography component="h1" variant="h1" className={classes.title}>
          Hola {`${user?.name} `}
          <Emoji ariaLabel="shake-hand" emojiIcon="👋" />
        </Typography>
        <Typography className={classes.subtitle}>
          Aquí veras toda la información de tus propiedades
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={6} className={classes.searchContainer}>
        <Grid item className={classes.searchContainerItem}>
          <PropertySearch onSelectPropety={(e) => onChoseProperty(e)} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryHeader;
