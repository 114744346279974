import {
  IoHeart as FilledHeartIcon,
  IoHeartOutline as OutlineHeartIcon,
} from "@houm-com/ui-icons";

import useFavoriteButtonStyles from "./FavoriteButtonStyles";

interface Props {
  isFavorite: boolean;
  handleFavoriteToggle: (event: React.MouseEvent) => void;
}

const FavoriteButton = ({ isFavorite, handleFavoriteToggle }: Props) => {
  const classes = useFavoriteButtonStyles();
  return (
    <div
      className={classes.button}
      onClick={handleFavoriteToggle}
      data-testid="favorite-button"
    >
      {isFavorite ? (
        <FilledHeartIcon size="20" className={classes.filledIcon} />
      ) : (
        <OutlineHeartIcon size="20" />
      )}
    </div>
  );
};

export default FavoriteButton;
