import { Theme, makeStyles } from "@material-ui/core";

interface Props {
  warning?: boolean;
}

export default makeStyles<Theme, Props>((theme: Theme) => ({
  wrapper: {
    margin: 0,
    padding: 0,
    width: "100%",
    alignItems: "flex-start",
  },
  label: {
    width: "100%",
    fontWeight: "bold",
    fontSize: "1rem",
    marginBottom: 0,
  },
  textFieldRoot: {
    borderRadius: 20,
    "& p.MuiFormHelperText-root": {
      "&.Mui-error": {
        color: theme.palette.primary.main,
      },
      color: (props) =>
        props.warning ? theme.palette.warning.main : theme.palette.grey[900],
    },
  },
  outline: {
    borderRadius: "50px",
    color: theme.palette.grey["500"],
  },
}));
