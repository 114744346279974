import { Box, Typography } from "@material-ui/core";

import useInfoContainerStyles from "./InfoContainerStyles";

interface InfoContainerProps {
  text: string;
  emoji: string;
  ariaLabel: string;
}

const InfoContainer = ({ text, emoji, ariaLabel }: InfoContainerProps) => {
  const classes = useInfoContainerStyles();
  return (
    <Box className={classes.infoContainer}>
      <Typography className={classes.textInfo}>
        {`${text} `}
        <span role="img" aria-label={ariaLabel}>
          {emoji}
        </span>
      </Typography>
    </Box>
  );
};

export default InfoContainer;
