import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(16),
  },
  textBold: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
  },
  link: {
    marginTop: theme.spacing(3),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    color: `${theme.palette.primary.main} !important`,
    textDecoration: "underline !important",
  },
  iconLink: {
    color: `${theme.palette.primary.main}`,
  },
  divider: {
    backgroundColor: theme.palette.grey[75],
    height: 2,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
    },
  },
}));
