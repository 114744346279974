/* eslint-disable max-lines */
import { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IoCheckmarkCircleOutline } from "@houm-com/ui-icons";

import PropertyLayout from "apps/landlord/Layout/PropertyLayout";
import useBillsInfo from "apps/landlord/hooks/propertyBills/useBillInfo";
import useBillsDetails from "apps/landlord/hooks/propertyBills/useBillsDetails";
import useProperty from "apps/tenant/hooks/useProperty";
import { countryCode } from "utils";
import {
  DetailsType,
  getCompanyTypes,
  snakeToCamel,
  emptyDetail,
} from "apps/landlord/utils/basicServices";

import PropertyRequestLink from "./components/PropertyRequestLink";
import Card from "./components/Card";
import classes from "./BasicServicesStyles";

const BasicServices = () => {
  const { t } = useTranslation("landlord.property.basic.services");
  const { id: propertyId } = useParams<{ id: string }>();
  const history = useHistory();
  const { propertyData: property, propertyLoading } = useProperty(propertyId);
  const country = property?.country && countryCode(property.country);
  const companyTypes = getCompanyTypes(property?.country);
  const {
    data: billsInfo,
    isFetching: isBillsInfoLoading,
    error: infoError,
  } = useBillsInfo(companyTypes);
  const {
    data: billsDetails,
    isFetching: isBillsDetailLoading,
    error: detailsError,
  } = useBillsDetails(property?.country, companyTypes);
  const error = !!infoError || !!detailsError;
  const isLoading =
    isBillsInfoLoading || isBillsDetailLoading || propertyLoading;
  const { waterDetails, gasDetails, energyDetails, commonExpensesDetails } =
    useMemo(
      (): DetailsType =>
        billsInfo && billsDetails && property && country !== "mx"
          ? companyTypes.reduce(
              (details, companyType) => ({
                ...details,
                [`${snakeToCamel(companyType)}Details`]:
                  billsDetails.find(
                    (data) =>
                      data.clientId ===
                      billsInfo?.[companyType]?.clientInfo.externalId
                  ) ?? emptyDetail,
              }),
              {}
            )
          : {},
      [billsInfo, billsDetails, property, country]
    );
  return (
    <PropertyLayout
      titlePage={t("title")}
      titleClassName={classes.title}
      onHandleBack={() => history.push(`/landlord/properties/${propertyId}`)}
    >
      <div>
        <p>{t("update-info")}</p>
        <div className={classes.info}>
          <IoCheckmarkCircleOutline className={classes.infoIcon} />
          <span>{t("billing-updated-info")}</span>
        </div>
        <div className={classes.cardsContainer}>
          {["cl", "co"].includes(country) && (
            <>
              <Card
                defaultTitle={t("water")}
                item={billsInfo?.water}
                detail={waterDetails}
                isLoading={isLoading}
                error={error}
                companyType="water"
              />
              <Card
                defaultTitle={t("energy")}
                item={billsInfo?.energy}
                detail={energyDetails}
                isLoading={isLoading}
                error={error}
                companyType="energy"
              />
              <Card
                defaultTitle={t("gas")}
                item={billsInfo?.gas}
                detail={gasDetails}
                isLoading={isLoading}
                error={error}
                companyType="gas"
              />
            </>
          )}
          {country === "cl" && (
            <Card
              defaultTitle={t("common-expenses")}
              item={billsInfo?.common_expenses}
              detail={commonExpensesDetails}
              isLoading={isLoading}
              error={error}
              companyType="common_expenses"
            />
          )}
        </div>
      </div>
      <PropertyRequestLink propertyId={propertyId} />
    </PropertyLayout>
  );
};

export default BasicServices;
