import { ReactNode } from "react";

import { Box, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import useDrawerHeaderStyles from "./DrawerHeaderStyles";

interface Props {
  children?: ReactNode;
  onHandleClose: () => void;
}
const DrawerHeader = ({ children, onHandleClose }: Props) => {
  const classes = useDrawerHeaderStyles();

  return (
    <Box className={classes.headerWrapper}>
      {children || <div />}
      <div>
        <IconButton
          onClick={() => onHandleClose()}
          className={classes.iconButton}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>
    </Box>
  );
};

export default DrawerHeader;
