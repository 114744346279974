import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

const usePropertyDetailsStyles = makeStyles<Theme>((theme) => ({
  descriptionContainer: {
    display: "flex",
    alignItems: "center",
  },
  descriptionText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.6,
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  container: {
    display: "block",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      display: "auto",
    },
  },
  iconContainer: {
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(0.5),
  },
}));

export default usePropertyDetailsStyles;
