import { useUser } from "context/userContext";
import ProfileUser from "../components/ProfileUser";
import MainPrivateRoutes from "./MainPrivateRoutes";
import MainPublicRoutes from "./MainPublicRoutes";

const MainRouter = () => {
  // here the user is already settled (defined|undefiend) from Authenticate wrapper
  const { user } = useUser();
  return (
    <>
      {user && (
        <ProfileUser>
          <MainPrivateRoutes />
        </ProfileUser>
      )}
      <MainPublicRoutes />
    </>
  );
};

export default MainRouter;
