import { FC, SVGProps } from "react";

const LocationPinIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={12}
    height={18}
    viewBox="0 0 12 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group 18">
      <path
        id="Vector"
        d="M1.01526 6.54259C1.01526 3.92519 3.24806 1.80005 6.00012 1.80005C8.75218 1.80005 10.985 3.92519 10.985 6.54259C10.985 9.5567 7.66174 14.3276 6.44149 15.9754C6.39084 16.045 6.32445 16.1016 6.24775 16.1407C6.17104 16.1797 6.08619 16.2 6.00012 16.2C5.91405 16.2 5.8292 16.1797 5.7525 16.1407C5.67579 16.1016 5.60941 16.045 5.55875 15.9754C4.3385 14.3269 1.01526 9.55428 1.01526 6.54259Z"
        stroke="#9E88D3"
        strokeWidth={1.6875}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        id="Ellipse 4"
        cx={6.29522}
        cy={7.08001}
        r={1.44}
        fill="#9E88D3"
      />
    </g>
  </svg>
);
export default LocationPinIcon;
