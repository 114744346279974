import { useProperty } from "apps/landlord/hooks";
import usePropertySettingUp from "apps/landlord/hooks/propertySettingUp/usePropertySettingUp";
import { useMemo } from "react";
import userService from "services/userService";
import { CardStepStatusByType, SettingUpCards } from "./types";
import TOTAL_STEPS_BY_TYPE from "./constants";

const useSettingUpDashboard = () => {
  const { propertyData } = useProperty();
  const user = userService.me();
  const { missingData, filteredMissingData, isError, isLoading } =
    usePropertySettingUp({
      propertyUuid: propertyData?.uid,
      userCountry: user?.country,
      propertyId: propertyData?.id,
      userId: user?.id,
    });

  const currentBBSSStep = Number(!missingData.missingBasicServicesAccount);
  const currentBankAccountStep = Number(!missingData.missingBankAccount);
  const currentPhotoStep = useMemo(() => {
    if (missingData.pendingPhotoSchedule) return 0;
    if (missingData.pendingPhotosButScheduled) return 1;
    return 2;
  }, [missingData]);
  const currentCharacteristicsStep = Number(
    missingData.pendingBasicCharacteristics
  );

  const settingUpPriceStep = useMemo(() => {
    if (missingData.pendingSettingUpPrice) return 0;
    return 1;
  }, [missingData]);

  const CURRENT_STEPS = useMemo(
    () => ({
      [SettingUpCards.PHOTOS]: currentPhotoStep,
      [SettingUpCards.CHARACTERISTICS]: currentCharacteristicsStep,
      [SettingUpCards.BASIC_SERVICES]: currentBBSSStep,
      [SettingUpCards.BANK_ACCOUNT]: currentBankAccountStep,
      [SettingUpCards.PRICE]: settingUpPriceStep,
    }),
    [
      currentPhotoStep,
      currentCharacteristicsStep,
      currentBBSSStep,
      currentBankAccountStep,
      settingUpPriceStep,
    ]
  );

  const steps = useMemo(() => {
    const types = Object.keys(TOTAL_STEPS_BY_TYPE) as SettingUpCards[];
    const stepsByType = types.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: {
          total: TOTAL_STEPS_BY_TYPE[cur],
          current: CURRENT_STEPS[cur],
          completed: TOTAL_STEPS_BY_TYPE[cur] === CURRENT_STEPS[cur],
        },
      }),
      {} as CardStepStatusByType
    );
    return stepsByType;
  }, [CURRENT_STEPS]);

  return {
    filteredMissingData,
    isError,
    isLoading,
    totalSteps: TOTAL_STEPS_BY_TYPE,
    steps,
  };
};

export default useSettingUpDashboard;
