import { createTheme, Theme } from "@material-ui/core";
import { esES } from "@material-ui/core/locale";

const themeSpacing = 8;
export const primary = {
  main: "#FF452B",
  light: "#FF9D8F",
  dark: "#C21800",
  contrastText: "#fff",
};
const grey = {
  "5": "#F7F8FC",
  "10": "#F2F3F6",
  "50": "#EFF2F3",
  "75": "#DFE5E8",
  "100": "#CFD8DC",
  "200": "#B0BEC5",
  "300": "#90A4AE",
  "400": "#78909C",
  A400: "#7D8488",
  "500": "#607D8B",
  "600": "#546E7A",
  "700": "#455A64",
  "800": "#37474F",
  "900": "#263238",
};
const secondary = {
  main: "#FEE9E8",
  light: "#B0BEC5",
  dark: "#2097F6",
};
const shadows = {
  light: "0px 0px 10px 2px rgba(96,125,139,0.5)",
  lightScrollbar: "0px 0px 4px rgba(38, 50, 56, 0.1)",
  thinLight: "0px 0px 7px rgba(176, 190, 197, 0.5)",
};

const background = {
  default: "#F7F9F9",
};

const success = {
  main: "#64BC26",
};

const error = {
  main: "#EA1601",
  dark: "#8B0300",
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1280,
    xl: 1536,
  },
};

const theme: Theme = createTheme(
  {
    palette: {
      primary,
      secondary,
      grey,
      success,
      error,
      background,
    },
    spacing: themeSpacing,
    typography: {
      fontFamily: "'Nunito', sans-serif",
      allVariants: {
        color: grey[900],
      },
      h1: {
        fontWeight: 700,
      },
    },
    overrides: {
      MuiInputBase: {
        input: {
          padding: "6px 0 6px",
        },
      },
    },
    breakpoints,
  },
  esES
);

const themeShadows = theme.shadows;
themeShadows[1] = shadows.light;
themeShadows[2] = shadows.lightScrollbar;
themeShadows[3] = shadows.thinLight;

export default { ...theme, shadows: themeShadows };
