import * as yup from "yup";

export const requiredText = "Obligatorio";

export const fileSchema = (
  requirements: {
    id: string;
    text: string;
  }[]
) =>
  yup.lazy(() => {
    const DATA_KEYS = requirements.map((req) => `check-${req.id}`);
    const shapes = {};
    DATA_KEYS.forEach((parameter) => {
      shapes[parameter] = yup
        .bool()
        .oneOf([true], "Debe adjuntar al menos un archivo");
    });
    return yup.object().shape(shapes);
  });
