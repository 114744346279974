import { makeStyles, Theme } from "@material-ui/core";

const useRepairFormStyles = makeStyles((theme: Theme) => ({
  textFieldLabel: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  textField: {
    backgroundColor: "#EFF2F3",
    width: "100%",
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
  },
  ctaContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: "#FFF",
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      width: "100%",
      bottom: 0,
    },
  },
}));

export default useRepairFormStyles;
