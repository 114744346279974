import { makeStyles, Theme } from "@material-ui/core/";

const useExitCardStyles = makeStyles<Theme>((theme) => ({
  card: {
    display: "flex",
    height: 120,
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 4,
    backgroundColor: "#FFF",
    overflow: "hidden",
  },
  iconContainer: {
    backgroundColor: "#CE6866",
    width: 80,
    flexGrow: 0,
    flexShrink: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textContainer: {
    padding: theme.spacing(1),
  },
  cardText: {
    color: "#7D8488",
    marginBottom: theme.spacing(1),
  },
  observation: {
    color: "#7D8488",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      "-webkit-line-clamp": 1,
    },
  },
  exitDate: {
    color: "#7D8488",
    "& span": {
      color: theme.palette.grey[900],
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(12),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export default useExitCardStyles;
