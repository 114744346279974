import { add } from "date-fns";
import { useMemo, useState } from "react";
import { EoCLeavingDateForm } from "apps/landlord/hooks/propertyEndOfContract/utils";
import { ContractResponse } from "apps/landlord/services/propertyContractService/types";

interface UseLeavingDataProps {
  activeContract: ContractResponse;
  leavingData: EoCLeavingDateForm;
  hasLimitDateCondition?: boolean;
}

interface UseLeavingDateResponse {
  motive: number | null;
  minSafeDate: Date;
  observations: string | null;
  selectedDate: Date | null;
  minAvailableDate: Date;
  disableSubmit: boolean;
  hasNotSafeDate: boolean;
  setMotive: (motive: number | null) => void;
  setSelectedDate: (date: Date | null) => void;
  setObservations: (observations: string | null) => void;
}

type UseLevingDate = (props: UseLeavingDataProps) => UseLeavingDateResponse;

const useLeavingDate: UseLevingDate = ({
  leavingData,
  activeContract,
  hasLimitDateCondition,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    leavingData.date ??
      add(new Date(), {
        days: 31,
      })
  );
  const [motive, setMotive] = useState<number | null>(
    leavingData.motive ?? null
  );

  const [observations, setObservations] = useState<string | null>(
    leavingData.observations ?? null
  );

  const sanitizedInitDate = useMemo(
    () => new Date(`${activeContract.init_date} 00:00:00`),
    [activeContract.init_date]
  );

  const minAvailableDate = useMemo(
    () =>
      add(sanitizedInitDate, {
        months: 9,
      }),
    [sanitizedInitDate]
  );

  const minSafeDate = useMemo(
    () =>
      add(sanitizedInitDate, {
        years: 1,
      }),
    [sanitizedInitDate]
  );

  const hasNotSafeDate = useMemo(() => {
    const hasValidDate = selectedDate && selectedDate >= minAvailableDate;
    const hasSafeDate = selectedDate && selectedDate >= minSafeDate;
    const hasUnsafeDate = hasValidDate && !hasSafeDate;
    return hasUnsafeDate && hasLimitDateCondition;
  }, [selectedDate, minAvailableDate, minSafeDate, hasLimitDateCondition]);

  const disableSubmit = useMemo(
    () => !observations || !selectedDate || !motive || hasNotSafeDate,
    [selectedDate, motive, observations, hasNotSafeDate]
  );

  return {
    motive,
    minSafeDate,
    observations,
    selectedDate,
    minAvailableDate,
    disableSubmit,
    hasNotSafeDate,
    setMotive,
    setSelectedDate,
    setObservations,
  };
};

export default useLeavingDate;
