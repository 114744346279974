import { SettingUpCards } from "./types";

const TOTAL_STEPS_BY_TYPE = {
  [SettingUpCards.PHOTOS]: 2,
  [SettingUpCards.CHARACTERISTICS]: 1,
  [SettingUpCards.BASIC_SERVICES]: 1,
  [SettingUpCards.BANK_ACCOUNT]: 1,
  [SettingUpCards.PRICE]: 1,
};

export default TOTAL_STEPS_BY_TYPE;
