import { ReactNode } from "react";

import { Box, Typography } from "@material-ui/core";

import useCardStyles from "./CardStyles";

interface Props {
  children: ReactNode;
  title: string;
}
const Card = ({ children, title }: Props) => {
  const classes = useCardStyles();

  return (
    <Box className={classes.card}>
      <Typography className={classes.cardTitle}>{title}</Typography>
      {children}
    </Box>
  );
};

export default Card;
