import { isMobile } from "react-device-detect";

import { Box, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import Spinner from "Components/Shared/partials/Spinner";

import TimeSelection from "../TimeSelection";
import useScheduleFormStyles from "./ScheduleFormStyles";
import DateSelection from "../DateSelection";
import useExitVisitSchedule from "../../hooks/useExitVisitSchedule";

const ScheduleForm = () => {
  const classes = useScheduleFormStyles();
  const {
    availableDates,
    availableHours,
    endDate,
    history,
    selectedDate,
    setSelectedDate,
    handleSubmit,
    showMissingTime,
    scheduling,
    isLoading,
    selectedTime,
    setSelectedTime,
  } = useExitVisitSchedule();

  if (isLoading) return <Spinner />;

  return (
    <Box className={classes.scheduleForm}>
      {!isMobile && (
        <Box className={classes.closeIconWrapper}>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => history.push("/tenant/help")}
          />
        </Box>
      )}
      <Typography className={classes.title}>
        Agenda tu horario de salida
      </Typography>
      <Typography className={classes.info}>
        Define el <strong>horario que más se te acomode</strong> para que
        coordinemos tu visita de salida de la propiedad y entrega de llaves
        programada para el día
        <strong>{endDate}.</strong>
      </Typography>

      <DateSelection
        availableDates={availableDates}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />

      <TimeSelection
        availableHours={availableHours}
        scheduleLoading={isLoading}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
      />
      {showMissingTime && (
        <Typography className={classes.missingTime}>
          Debes seleccionar un horario
        </Typography>
      )}

      <DesignSystemButton
        onClick={handleSubmit}
        buttonClassName={classes.nextButton}
        label="Siguiente"
        size="small"
        variant="primary"
        isLoading={scheduling}
      />
    </Box>
  );
};

export default ScheduleForm;
