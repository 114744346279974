import { motion } from "framer-motion";

import {
  IoWarningOutline as WarningIcon,
  IoAlertCircleOutline as ErrorIcon,
  IoCheckmarkCircleOutline as SuccessIcon,
  IoInformationCircleOutline as InfoIcon,
  IoCloseOutline as CloseIcon,
} from "@houm-com/ui-icons";

import { DESKTOP_BREAKPOINT } from "../../constants";
import { SnackbarPayload } from "../../types";
import "./snackbar.css";

const DEFAULT_HEADS = {
  success: "¡Buenas noticias!",
  warning: "¡Alerta!",
  info: "Hey, sabías que...",
  error: "¡Error!",
};

const IconsDictionary = {
  success: SuccessIcon,
  warning: WarningIcon,
  info: InfoIcon,
  error: ErrorIcon,
};

interface HoumSnackbarProps extends Omit<SnackbarPayload, "duration"> {
  onClose: () => void;
}

const HoumSnackbar = ({
  snackbarKey,
  head,
  body,
  type,
  actions,
  onClose,
}: HoumSnackbarProps) => {
  const hasActions = actions?.length > 0;
  const IconByType = IconsDictionary[type];
  const isDesktop = window?.innerWidth > DESKTOP_BREAKPOINT;
  const exitAnimation = isDesktop
    ? { scale: 0.5, opacity: 0, transition: { duration: 0.2 } }
    : { x: 400, opacity: 0, transition: { duration: 0.4 } };

  return (
    <motion.div
      layout
      key={snackbarKey}
      initial={{ opacity: 0, y: 50, scale: 0.3 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={exitAnimation}
      drag={isDesktop ? false : "x"}
      dragConstraints={{ left: 0, right: 0 }}
      dragElastic={0.1}
      dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
      onDragEnd={(_, info) => {
        if (info.offset.x > 450) {
          onClose();
        }
      }}
    >
      <section className="houm-snackbar" data-type={type}>
        <section className="houm-snackbar__element">
          <section className="houm-snackbar__element-icon-section">
            <IconByType size={20} />
          </section>
          <section className="houm-snackbar__element-content-section">
            <div className="houm-snackbar__element-title">
              {head || DEFAULT_HEADS[type]}
            </div>
            <div className="houm-snackbar__element-body">{body}</div>
            {hasActions && (
              <div className="houm-snackbar__element-actions-section">
                {actions.map(({ label, onClick, type }) => (
                  <button
                    key={label + type}
                    onClick={onClick}
                    className="houm-snackbar__element-action"
                    data-type={type}
                  >
                    {label}
                  </button>
                ))}
              </div>
            )}
          </section>
          <section className="houm-snackbar__element-close-icon-section">
            <CloseIcon
              onClick={onClose}
              className="houm-snackbar__close-icon"
              color="#515B60"
            />
          </section>
        </section>
      </section>
    </motion.div>
  );
};

export default HoumSnackbar;
