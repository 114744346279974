import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}
const InfoContent = ({ children }: Props) => (
  <div className="gap-2 flex flex-col">{children}</div>
);

export default InfoContent;
