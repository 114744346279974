import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    color: theme.palette.grey[500],
  },
  grayColor: { color: theme.palette.grey[500] },
}));
