import { AxiosResponse } from "axios";
import { BACKEND_URL } from "env";
import qs from "query-string";

import { SimilarProperty } from "models";
import { createHttp } from "services/helper";

export default {
  getFavorites: (
    filters: { mode: "rent" | "sale" },
    token: string
  ): Promise<AxiosResponse<SimilarProperty[]>> =>
    createHttp(token, BACKEND_URL).get(
      `/properties/v2/likes/detail/?${qs.stringify({
        ...filters,
      })}`
    ),

  deleteFavorite: (token: string, idProperty: number) =>
    createHttp(token, BACKEND_URL).delete(`/properties/v2/likes/${idProperty}`),

  getRecommendedProperties: (queryMode: string, token: string) =>
    createHttp(token, BACKEND_URL).get(
      `/properties/v2/recommendations/?mode=${queryMode}`
    ),

  getRecommendedPropertiesByHoumer: (
    queryMode: string,
    token: string,
    email: string
  ) =>
    createHttp(token).get(
      `/demand/v2/lead-properties-recommendation/?search=${email}&mode=${queryMode}`
    ),
};
