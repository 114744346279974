import { PropertyBusinessType } from "apps/landlord/models/Property";

export const UNPUBLISH_FROM_RENTAL_OPTIONS = [
  {
    label: "Arrende por mi cuenta",
    value: "RENTED_BY_OWNER",
  },
];

export const UNPUBLISH_FROM_SALES_OPTIONS = [
  {
    label: "Vendi por mi cuenta",
    value: "SOLD_BY_OWNER",
  },
];

export const UNPUBLISH_GENERAL_OPTIONS = [
  {
    label: "Me voy a vivir a la propiedad",
    value: "OWNER_MOVED_IN",
  },
  {
    label: "Quiero hacer reparaciones antes de volver a publicar",
    value: "REPARES_BEFORE_RENT",
  },
  {
    label: "No quiero arrendar con Houm",
    value: "NO_HOUM",
  },
  {
    label: "Otro motivo",
    value: "OTHER",
  },
];

export const defaultUnpublishOptions = (type: PropertyBusinessType) => [
  ...((type === PropertyBusinessType.RENT && UNPUBLISH_FROM_RENTAL_OPTIONS) ||
    []),
  ...((type === PropertyBusinessType.SALE && UNPUBLISH_FROM_SALES_OPTIONS) ||
    []),
  ...UNPUBLISH_GENERAL_OPTIONS,
];
