import { Grid, Typography } from "@material-ui/core";

import { Locale } from "models/Countries";
import { ApplicationCodebtor } from "models/Evaluation";
import applicantInfo from "../../utils";
import useMobileCardApplicantDetailsStyles from "./MobileCardApplicantDetailsStyles";

interface MobileCardApplicantDetailsProps {
  applicant: ApplicationCodebtor;
  countryCode: Locale;
}

const MobileCardApplicantDetails = ({
  applicant,
  countryCode,
}: MobileCardApplicantDetailsProps) => {
  const applicantInfoList = Object.entries(
    applicantInfo(applicant, countryCode)
  );
  const classes = useMobileCardApplicantDetailsStyles();

  const columnA = applicantInfoList.slice(0, 3);
  const columnB = applicantInfoList.slice(3, 5);
  return (
    <Grid container>
      <Grid item xs={6}>
        {columnA.map(([key, value]) => (
          <Typography key={key} className={classes.text}>
            {`${key}: ${value}`}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={6}>
        {columnB.map(([key, value]) => (
          <Typography key={key} className={classes.text}>
            {`${key}: ${value}`}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};

export default MobileCardApplicantDetails;
