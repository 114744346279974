import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  containerProgress: {
    position: "relative",
    marginLeft: theme.spacing(2),
  },
  containerSteps: {
    marginLeft: theme.spacing(1),
  },
  steps: {
    fontSize: "0.75rem",
    lineHeight: "1.5em",
    textAlign: "center",
    position: "absolute",
    top: 11,
    left: 11,
  },
  stepText: {
    fontSize: "0.75rem",
    lineHeight: "1.5em",
  },
  stepTitle: {
    fontSize: "0.875rem",
    fontWeight: "bold",
    lineHeight: "1.5em",
  },
  root: {
    position: "relative",
  },
  bottom: {
    color: theme.palette.grey[500],
  },
  bottomPrimary: {
    color: theme.palette.primary.main,
  },
  top: {
    color: theme.palette.grey[50],
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  topPrimary: {
    color: "#F9E8E5",
  },
}));

export default useStyles;
