import { cn } from "@houm-com/ui/utils";

import classes from "./CardLoadingStyles";

const CardLoading = () => (
  <div>
    <div className={classes.loadingSkeletonHead}>
      <div
        className={cn(
          classes.loadingSkeletonItem,
          classes.loadingSkeletonTitle
        )}
      />
      <div
        className={cn(classes.loadingSkeletonItem, classes.loadingSkeletonTag)}
      />
    </div>
    <span className={classes.loadingSkeletonSubtitle}>Actualizando...</span>
    <div
      className={cn(
        classes.loadingSkeletonContent,
        classes.loadingSkeletonItemHalf
      )}
    >
      <div
        className={cn(
          classes.loadingSkeletonItem,
          classes.loadingSkeletonItemEighth
        )}
      />
      <div
        className={cn(
          classes.loadingSkeletonItem,
          classes.loadingSkeletonItemFourth
        )}
      />
      <div
        className={cn(
          classes.loadingSkeletonItem,
          classes.loadingSkeletonItemHalf
        )}
      />
      <div
        className={cn(
          classes.loadingSkeletonItem,
          classes.loadingSkeletonItemFull
        )}
      />
    </div>
  </div>
);

export default CardLoading;
