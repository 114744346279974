import { View, Text, Image } from "@react-pdf/renderer";

import { ServicesStatus } from "hooks/inspectionReports/types";

import Divider from "../assets/icons/rectangle-divider.png";
import Check from "../assets/icons/check.png";
import Close from "../assets/icons/close.png";
import useInspectionReportDocumentStyles from "../InspectionReportDocumentStyles";

interface InspectionReportSevicesProps {
  servicesStatus: ServicesStatus;
}

const StatusIcon = ({
  status,
  style,
}: {
  status: ServicesStatus["gasStatus"];
  style: Record<string, any>;
}) => <Image style={style} source={status === "does_have" ? Check : Close} />;

const InspectionReportServices = ({
  servicesStatus,
}: InspectionReportSevicesProps) => {
  const styles = useInspectionReportDocumentStyles;
  const { energyStatus, waterStatus, gasStatus } = servicesStatus;

  return (
    <>
      <View style={styles.contentSection}>
        <Text style={styles.sectionTitle}>
          Estados de los servicios de la propiedad:
        </Text>
        <View style={styles.servicesSection}>
          <View style={styles.serviceItem}>
            <StatusIcon status={energyStatus} style={styles.serviceIcon} />
            <Text style={styles.itemValue}>Luz</Text>
          </View>
          <View style={styles.serviceItem}>
            <StatusIcon status={waterStatus} style={styles.serviceIcon} />
            <Text style={styles.itemValue}>Agua</Text>
          </View>
          {gasStatus !== "does_not_apply" && (
            <View style={styles.serviceItem}>
              <StatusIcon status={gasStatus} style={styles.serviceIcon} />
              <Text style={styles.itemValue}>Gas</Text>
            </View>
          )}
          {gasStatus === "does_not_apply" && (
            <View style={styles.serviceItem}>
              <StatusIcon status={gasStatus} style={styles.serviceIcon} />
              <Text style={styles.itemValue}>Gas: No aplica</Text>
            </View>
          )}
        </View>
      </View>
      <Image src={Divider} style={styles.sectionDivider} />
    </>
  );
};

export default InspectionReportServices;
