import { makeStyles, Theme } from "@material-ui/core";

const useVisitsListStyles = makeStyles((theme: Theme) => ({
  visitsListRoot: {
    padding: theme.spacing(0, 0, 15),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 0, 4),
    },
  },
  monthHeader: {
    width: "100%",
    padding: theme.spacing(0.5, 1),
    backgroundColor: "#DAE4E8",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    fontWeight: 700,
  },
}));

export default useVisitsListStyles;
