import { Box } from "@material-ui/core";

import Spinner from "Components/Shared/partials/Spinner";
import { useUser } from "context/userContext";
import useAuthenticateUserStyles from "./AuthenticateUserStyles";

const AuthenticateUser = ({
  children,
}: {
  children: React.ReactElement | null;
}) => {
  const classes = useAuthenticateUserStyles();
  const { isLoading } = useUser();

  if (isLoading) {
    return (
      <Box className={classes.loadingContainer}>
        <Spinner />
      </Box>
    );
  }
  return children;
};

export default AuthenticateUser;
