import IconButton from "@houm-com/ui/IconButton";
import { IoArrowForward as ArrowRight } from "@houm-com/ui-icons";

import { classes } from "apps/lead/pages/EvaluatorForm/components/SelfieDisclaimer/SelfieDisclaimerStyles";
import {
  setActiveStep,
  setPage,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { EvaluatorPages } from "apps/lead/context/evaluatorContext/EvaluatorTypes";

const SelfieDisclaimer = () => {
  const {
    dispatch,
    state: { activeStep },
  } = useEvaluation();

  const onContinue = () => {
    dispatch(setActiveStep(activeStep + 1));
    dispatch(setPage(EvaluatorPages.FORM));
  };

  return (
    <div className={classes.selfieDisclaimerRoot}>
      <div className={classes.selfieDisclaimerInformationRoot}>
        <div className={classes.selfieDisclaimerInformationContainer}>
          <p className={classes.selfieDisclaimerTitle}>
            ¿Cómo debo tomar mi foto?
          </p>
          <div className={classes.selfieDisclaimerTextContainer}>
            <ol className={classes.selfieDisclaimerList}>
              <li>Asegúrate que estés en un espacio bien iluminado.</li>
              <li>Revisa que no te tapes la cara.</li>
              <li>Coloca tu identificación oficial al lado de tu rostro.</li>
              <li>
                Pídele a alguien que te tome una foto o tómate una selfie.
              </li>
            </ol>
          </div>
        </div>
        <div className={classes.selfieDisclaimerButtonContainer}>
          <IconButton
            size="lg"
            variant="primary"
            position="end"
            icon={<ArrowRight />}
            type="button"
            onClick={onContinue}
          >
            Continuar
          </IconButton>
        </div>
      </div>
      <div className={classes.selfieDisclaimerImageContainer}>
        <img
          src="https://houm-production-public.s3.us-east-1.amazonaws.com/cms-content/selfie_disclaimer_bed639eca8.svg"
          alt="selfie-disclaimer"
          aria-label="selfie-disclaimer"
          className={classes.selfieDisclaimerImage}
        />
      </div>
    </div>
  );
};

export default SelfieDisclaimer;
