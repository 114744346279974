import { makeStyles, Theme } from "@material-ui/core";

const useDefaultPhotoStyles = makeStyles<Theme>((theme) => ({
  noPhotoContainer: {
    height: "100%",
    width: "100%",
    backgroundColor: "#F7F9F9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  houmLogo: {
    width: 56,
  },
  container: {
    textAlign: "center",
  },
}));

export default useDefaultPhotoStyles;
