import { FieldProp } from "context/closureFormContext/closureFormFields";
import { CountryUpperCase } from "models/Countries";

export const filter = (
  fieldInfo: FieldProp,
  isAdmin: boolean,
  country: CountryUpperCase | ""
): boolean =>
  (!isAdmin && fieldInfo.admin) ||
  !country ||
  !fieldInfo.countries.includes(country);

export interface Props {
  id: string;
  fieldInfo: FieldProp;
}

export interface OptionTypes {
  value: string | number;
  label: string;
}
