import currency from "@houm-com/formats/currency";

import { Grid } from "@material-ui/core";
import { Currency } from "models/Countries";

import { classes } from "./BreakdownSectionStyles";

interface Props {
  rentValue: number;
  currencyCode: Currency;
  paymentCompany: "PlacetoPay" | "PayU" | "OpenPay";
  amountCommission?: number;
  textValue?: string;
}

const BreakdownSection = ({
  rentValue,
  currencyCode,
  paymentCompany,
  textValue = "Valor renta",
  amountCommission,
}: Props) => (
  <Grid container className={classes.breakdownSectionRoot}>
    <Grid item xs={8}>
      <p className={classes.text}>{textValue}</p>
    </Grid>
    <Grid item xs={4}>
      <p className={classes.number}>
        {currency(rentValue, currencyCode, {
          minimumFractionDigits: 0,
        })}
      </p>
    </Grid>
    {amountCommission !== 0 && (
      <>
        <Grid item xs={8}>
          <p className={classes.text}>Comisión {paymentCompany}</p>
        </Grid>
        <Grid item xs={4}>
          <p className={classes.number}>
            {currency(amountCommission, currencyCode, {
              minimumFractionDigits: 0,
            })}
          </p>
        </Grid>
      </>
    )}
  </Grid>
);

export default BreakdownSection;
