import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

import { Box, Typography } from "@material-ui/core";

import BackButton from "apps/tenant/components/BackButton";
import useContract from "apps/tenant/hooks/useContract";
import useMovements from "apps/tenant/hooks/useMovements";
import { useNavbar } from "apps/ui/Navbar/context/navbarContext";
import Spinner from "Components/Shared/partials/Spinner";

import MobilePaymentsTable from "./components/MobilePaymentsTable";
import usePaymentsListStyles from "./PaymentsListStyles";
import DesktopPaymentsTable from "./components/DesktopPaymentsTable";
import { parseMovements } from "./utils/utils";

const PaymentsList = () => {
  const classes = usePaymentsListStyles();
  const history = useHistory();
  const { isLoading: contractLoading } = useContract();
  const { setBackUrl } = useNavbar();
  const { data: movements, isLoading: movementsLoading } = useMovements();

  useEffect(() => {
    setBackUrl("/tenant");
  }, [setBackUrl]);

  if (contractLoading || movementsLoading)
    return <Spinner className={classes.spinner} />;

  return (
    <Box className={classes.paymentsListRoot}>
      <Box className={classes.paymentsListContent}>
        {!isMobile && (
          <BackButton onClickHandler={() => history.push("/tenant")} />
        )}
        <Typography className={classes.title}>Comprobantes de pago</Typography>
        {!isMobile && (
          <Typography className={classes.subtitle}>
            Encuentra todos los comprobantes de pago y facturas de cada mes y
            pago aquí.
          </Typography>
        )}
        {isMobile && movements && (
          <MobilePaymentsTable movements={parseMovements({ movements })} />
        )}
        {!isMobile && movements && (
          <DesktopPaymentsTable movements={parseMovements({ movements })} />
        )}
      </Box>
    </Box>
  );
};

export default PaymentsList;
