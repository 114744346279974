import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import {
  IoCalendarOutline as CalendarIcon,
  IoList as ListIcon,
} from "@houm-com/ui-icons";

import { Box, Typography } from "@material-ui/core";

import BackButton from "apps/tenant/components/BackButton";

import useVisitsHeaderStyles from "./VisitsHeaderStyles";
import VisitsLinks from "../VisitsLinks";

interface Props {
  viewMode: "calendar" | "list";
  toggleViewMode: () => void;
}

const VisitsHeader = ({ viewMode, toggleViewMode }: Props) => {
  const classes = useVisitsHeaderStyles();
  const history = useHistory();

  const handleBack = () => {
    history.push("/tenant");
  };

  const displayCalendar = isMobile && viewMode === "list";
  const displayList = isMobile && viewMode === "calendar";

  return (
    <Box className={classes.visitsHeaderWrapper}>
      {!isMobile && (
        <BackButton
          className={classes.backButton}
          onClickHandler={handleBack}
        />
      )}
      <Box className={classes.visitsHeaderRoot}>
        <Typography variant="h1" className={classes.visitsTitle}>
          Visitas agendadas
        </Typography>
        {displayCalendar && (
          <CalendarIcon
            data-testid="calendar-icon"
            size={24}
            onClick={toggleViewMode}
          />
        )}
        {displayList && (
          <ListIcon
            data-testid="list-icon"
            size={24}
            onClick={toggleViewMode}
          />
        )}
        {!isMobile && <VisitsLinks />}
      </Box>
    </Box>
  );
};

export default VisitsHeader;
