import { Box, Typography } from "@material-ui/core";

import useMobileHeaderStyles from "./MobileHeaderStyles";

interface Props {
  title: string;
  subtitle: string;
}

const MobileHeader = ({ title, subtitle }: Props) => {
  const classes = useMobileHeaderStyles();

  return (
    <Box className={classes.mobileHeaderRoot}>
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.subtitle}>{subtitle}</Typography>
    </Box>
  );
};

export default MobileHeader;
