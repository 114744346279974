import { makeStyles, Theme } from "@material-ui/core";

const useConfirmationStepStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.spacing(3),
    },
  },
  stepTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.spacing(1.5),
    },
  },
  stepDescription: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  link: {
    fontWeight: 700,
    color: "#2C73DB",
    textDecoration: "underline",
  },
  ctaContainer: {
    marginBottom: theme.spacing(3),
  },
  box: {
    padding: theme.spacing(2),
    backgroundColor: "#EAF1FB",
    borderRadius: 4,
  },
  boxTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: 700,
  },
  boxDescription: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
      fontWeight: 700,
    },
  },
}));

export default useConfirmationStepStyles;
