import { format } from "rut.js";

import { Locale } from "models/Countries";

export const documentFormatter = (country: Locale, document: string) => {
  if (country === "cl") {
    return format(document);
  } else {
    return document;
  }
};

export const translate = (
  country: Locale,
  key: "document" | "document.placeholder"
) => {
  switch (key) {
    case "document":
      return country === "cl" ? "RUT" : "Número de documento";
    case "document.placeholder":
      return country === "cl" ? "1.111.111-1" : "Ingresar";
    default:
      return "";
  }
};
