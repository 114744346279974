import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  color: string;
}

const useDesignSystemSnackbarStyles = makeStyles<Theme, Props>((theme) => ({
  dsSnackbarRoot: {
    marginTop: theme.spacing(8),
    zIndex: theme.zIndex.snackbar,
    borderRadius: 16,
    boxShadow: "3px 4px 25px rgba(38, 50, 56, 0.15)",
    [theme.breakpoints.down("xs")]: {
      borderTop: (props) => `12px solid ${props.color}`,
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(9),
      borderTop: 1,
      borderLeft: (props) => `12px solid ${props.color}`,
    },
  },
  wrapper: {
    width: "100%",
    padding: theme.spacing(1, 1.5),
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: "0px 0px 16px 16px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "0px 16px 16px 0px",
    },
  },
  icon: {
    margin: theme.spacing(0.5, 2, 0, 0),
    height: 15,
    width: 15,
    color: (props) => props.color,
  },
  text: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.7,
  },
  closeIcon: {
    marginLeft: theme.spacing(2),
    display: "flex",
    alignItems: "start",
    cursor: "pointer",
    color: "#7D8488",
  },
}));

export default useDesignSystemSnackbarStyles;
