import { makeStyles, Theme } from "@material-ui/core";

const useNoVisitsStyles = makeStyles((theme: Theme) => ({
  noVisitsRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      gap: theme.spacing(5),
    },
  },
  calendar: {
    fontSize: theme.typography.pxToRem(35),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(45),
    },
  },
  message: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  search: {
    alignSelf: "start",
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
  arrow: {
    transform: "rotate(-45deg)",
  },
}));

export default useNoVisitsStyles;
