import { Box, Typography } from "@material-ui/core";

import { IProperty } from "apps/landlord/models/Property";

import usePropertyAddressStyles from "./PropertyAddressStyles";
import { renderAddress } from "../../utils/utils";

interface Props {
  propertyData: Partial<IProperty>;
}

const PropertyAddress = ({ propertyData }: Props) => {
  const classes = usePropertyAddressStyles();

  return (
    <Box className={classes.addressContainer}>
      <Typography>
        ID: <span>{propertyData.id}</span>
      </Typography>
      <Typography>
        Dirección: <span>{renderAddress(propertyData)}</span>
      </Typography>
      <Typography>
        <span>{`${propertyData.commune}, ${propertyData.region}`}</span>
      </Typography>
    </Box>
  );
};

export default PropertyAddress;
