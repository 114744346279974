import { makeStyles, Theme } from "@material-ui/core";

const useFavoritesStyles = makeStyles((theme: Theme) => ({
  backButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  ctaContainer: {
    position: "fixed",
    padding: theme.spacing(5, 0),
    width: "100%",
    bottom: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
    display: "flex",
    justifyContent: "center",
  },
}));

export default useFavoritesStyles;
