import { makeStyles, Theme } from "@material-ui/core";

const useInitialStepStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.spacing(4),
    },
  },
  confirmCheckLabel: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  confirmCheck: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(4),
    },
  },
  root: {
    display: "flex",
    alignItems: "center",
  },
  link: {
    fontWeight: 700,
    color: "#2C73DB",
    textDecoration: "underline",
  },
}));

export default useInitialStepStyles;
