import Swal from "sweetalert2";

import documentTypeService from "services/documentTypeService";
import {
  Action,
  Dispatch,
  ActionType,
  DocumentOption,
  State,
  ids,
  LandlordData,
  LandlordDocs,
  LandlordBankData,
  PropertyData,
  PropertyDocs,
  BrokerageContract,
} from "./loiFormTypes";
import propertyService from "services/propertyService";
import negotiationService from "services/negotiationService/negotiationService";
import { countryCode } from "utils";
import {
  parseDocumentTypes,
  parseLoiCL,
  parseLoiCO,
  parseLoiMX,
  parsePropertyData,
} from "./utils";
import { CountryUpperCase } from "models/Countries";

export const setIds = (ids: ids): Action => ({
  type: ActionType.SET_IDS,
  ids,
});

export const setLoading = (loading: boolean): Action => ({
  type: ActionType.SET_LOADING,
  loading,
});

export const setAvailableSteps = (steps: string[]): Action => ({
  type: ActionType.SET_AVAILABLE_STEPS,
  steps,
});

export const setStep = (step: string): Action => ({
  type: ActionType.SET_STEP,
  step,
});

export const nextStep = (): Action => ({
  type: ActionType.NEXT_STEP,
});

export const prevStep = (): Action => ({
  type: ActionType.PREV_STEP,
});

export const setReady = (ready: boolean): Action => ({
  type: ActionType.SET_READY,
  ready,
});

export const setSubmitting = (submitting: boolean): Action => ({
  type: ActionType.SET_SUBMITTING,
  submitting,
});

export const setNavText = (navText: string): Action => ({
  type: ActionType.SET_NAV_TEXT,
  navText,
});

export const setDocumentTypes = (documentTypes: DocumentOption[]): Action => ({
  type: ActionType.SET_DOC_TYPES,
  documentTypes,
});

export const setCountry = (country: CountryUpperCase): Action => ({
  type: ActionType.SET_COUNTRY,
  country,
});

export const setLandlordData = (
  landlordData: Partial<LandlordData>
): Action => ({
  type: ActionType.SET_LANDLORD_DATA,
  landlordData,
});

export const setLandlordDocs = (
  landlordDocs: Partial<LandlordDocs>
): Action => ({
  type: ActionType.SET_LANDLORD_DOCS,
  landlordDocs,
});

export const setLandlordBankData = (
  landlordBankData: Partial<LandlordBankData>
): Action => ({
  type: ActionType.SET_LANDLORD_BANK_DATA,
  landlordBankData,
});

export const setPropertyData = (
  propertyData: Partial<PropertyData>
): Action => ({
  type: ActionType.SET_PROPERTY_DATA,
  propertyData,
});

export const setPropertyDocs = (
  propertyDocs: Partial<PropertyDocs>
): Action => ({
  type: ActionType.SET_PROPERTY_DOCS,
  propertyDocs,
});

export const setBrokerageContract = (
  brokerageContract: Partial<BrokerageContract>
): Action => ({
  type: ActionType.SET_BROKERAGE_CONTRACT,
  brokerageContract,
});

export async function fetchData(
  uid: string,
  authToken: string,
  dispatch: Dispatch
) {
  try {
    const fetchedProperty = await propertyService.get(uid, authToken);
    const propertyUid = fetchedProperty.data.uid;
    const propertyId = fetchedProperty.data.id;
    const userId = fetchedProperty.data.user?.id;

    if (fetchedProperty.data) {
      const propertyData = parsePropertyData(fetchedProperty.data);

      if (propertyData) dispatch(setPropertyData(propertyData));

      const fetchedDocumentTypes = await documentTypeService.getList(
        propertyData.country,
        authToken
      );

      if (fetchedDocumentTypes && fetchedDocumentTypes.data) {
        dispatch(
          setDocumentTypes(
            parseDocumentTypes(fetchedDocumentTypes.data.results)
          )
        );
      }

      const service = negotiationService();
      const fetchedNegotations = await service.getNegotiations(
        countryCode(propertyData.country),
        uid,
        authToken
      );

      if (fetchedNegotations.data) {
        const negotiationsData = fetchedNegotations.data;
        const negotiationAcceptedData = negotiationsData?.find(
          (n) => n.status === "Finalizada" && n.last_bid_status === "Aceptada"
        );
        const negotiationId = negotiationAcceptedData.id;

        const fetchedNegotation = await service.get(
          countryCode(propertyData.country),
          negotiationId,
          authToken
        );
        const negotiationData = fetchedNegotation.data;

        if (negotiationData.country)
          dispatch(setCountry(negotiationData.country));
        if (negotiationId)
          dispatch(setIds({ propertyUid, propertyId, userId, negotiationId }));
      }
    }

    dispatch(setLoading(false));
  } catch (err) {
    console.error(err);
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado. Contáctese con su ejecutivo.",
    }).then(() => {
      window.history.back();
    });
  }
}

export async function submitData(
  authToken: string,
  state: State,
  dispatch: Dispatch
) {
  const formData = new FormData();
  dispatch(setSubmitting(true));

  formData.append("first_name", state.landlordData.name);
  formData.append("last_name", state.landlordData.lastName);
  formData.append("second_last_name", state.landlordData.secondLastName);
  formData.append("nationality", state.landlordData.nationality);

  formData.append(
    "files",
    state.landlordDocs.identificationDoc1,
    `frontId-${state.landlordDocs.identificationDoc1.name}`
  );

  try {
    if (state.country === "Chile") parseLoiCL(formData, state);
    else if (state.country === "Colombia") parseLoiCO(formData, state);
    else if (state.country === "Mexico") parseLoiMX(formData, state);

    await negotiationService().uploadLoiDocuments(
      authToken,
      countryCode(state.country),
      state.ids.negotiationId,
      formData
    );

    Swal.fire({
      title: "¡Listo!",
      imageUrl: "",
      text: "Hemos registrado todos los datos para redactar la promesa de compra venta.  Te estaremos avisando si necesitamos más información.",
    }).then(() => {
      // NOTIFY HERE IF IS NECESSARY
      window.history.back();
    });
  } catch (e) {
    console.error(e);
    Swal.fire({
      type: "error",
      text: "No se pudieron subir los datos",
    });
  } finally {
    dispatch(setSubmitting(false));
  }
}

export function stepSubmit(
  dispatch: Dispatch,
  currentStep: string,
  stepList: string[]
) {
  const isLastStep = currentStep === stepList.slice(-1)[0];
  if (isLastStep) dispatch(setReady(true));
  else dispatch(nextStep());
}
