import { useHistory, useRouteMatch } from "react-router-dom";
import { isMobile } from "react-device-detect";

import Spinner from "@houm-com/ui/Spinner";

import useEndHoumContractStatus from "apps/landlord/hooks/useEndHoumContractStatus";
import BackButtonHeader from "apps/landlord/Layout/BackButtonHeader";
import ContentDrawer from "apps/ui/CustomDrawer/ContentDrawer";
import SimpleLayout from "apps/landlord/Layout/SimpleLayout";
import DrawerHeader from "apps/ui/CustomDrawer/DrawerHeader";
import Drawer from "apps/ui/CustomDrawer/Drawer";

import EndContractInformation from "./components/EndContractInformation";
import EndContractPending from "./components/EndContractPending";
import NoCurrentContract from "./components/NoCurrentContract";

const EndHoumContract = () => {
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const rootURL = match.url.replace("/end-houm-contract", "");
  const handleBack = () => history.push(rootURL);
  const { isLoading, activeContract, endContractHoumStatus } =
    useEndHoumContractStatus();

  const flagRequestPending =
    endContractHoumStatus &&
    !["cancelled", "finished"].includes(endContractHoumStatus?.status);

  const renderContent = () => {
    if (isLoading)
      return (
        <Spinner
          classNameContainer="relative flex justify-center"
          size="xl"
          variant="primary"
        />
      );

    if (
      activeContract &&
      activeContract.lastSubscription?.status === "LEASED"
    ) {
      if (flagRequestPending)
        return <EndContractPending requestId={endContractHoumStatus.id} />;

      return (
        <EndContractInformation
          contractId={activeContract.id}
          onHandleClose={handleBack}
          country={activeContract.country}
        />
      );
    }
    return <NoCurrentContract />;
  };

  if (isMobile) {
    return (
      <SimpleLayout rootClassName="bg-white flex flex-col justify-center pt-6 pl-4 pr-4 pb-10">
        <BackButtonHeader handleBack={handleBack} />
        <div className="flex flex-col gap-6 justify-center">
          {renderContent()}
        </div>
      </SimpleLayout>
    );
  }

  return (
    <Drawer onHandleClose={handleBack} open>
      <DrawerHeader onHandleClose={handleBack} />
      <ContentDrawer contentClassName="flex flex-col gap-6 justify-center">
        {renderContent()}
      </ContentDrawer>
    </Drawer>
  );
};

export default EndHoumContract;
