import { useState } from "react";
import { isDesktop } from "react-device-detect";
import { AnimatePresence, motion } from "framer-motion";

import userService from "services/userService";

import HelpWidgetTrigger from "./components/HelpWidgetTrigger";
import HelpWidgetMobileModal from "./components/HelpWidgetMobileModal";
import HelpWidgetDesktopTooltip from "./components/HelpWidgetDesktopTooltip";
import {
  LOCALE_BASED_HELP_CENTER_LINKS,
  LOCALE_BASED_HELP_CHAT_LINKS,
  LOCALE_BASED_HELP_LANDLORD_FORM_LINKS,
  LOCALE_BASED_HELP_TENANT_FORM_LINKS,
} from "./constants";

const HelpWidget = () => {
  const [displayModal, setDisplayModal] = useState(false);
  const user = userService.me();
  const CurrentDialog = isDesktop
    ? HelpWidgetDesktopTooltip
    : HelpWidgetMobileModal;

  const goToHelpCenter = () => {
    window.open(
      LOCALE_BASED_HELP_CENTER_LINKS[user?.country],
      "_blank",
      "noreferrer"
    );
  };

  const goToHelpForm = () => {
    const linkCollectionByRole =
      user.role === "lessor"
        ? LOCALE_BASED_HELP_TENANT_FORM_LINKS
        : LOCALE_BASED_HELP_LANDLORD_FORM_LINKS;
    window.open(linkCollectionByRole[user?.country], "_blank", "noreferrer");
  };

  const goToHelpChat = () => {
    window.open(
      LOCALE_BASED_HELP_CHAT_LINKS[user?.country],
      "_blank",
      "noreferrer"
    );
  };

  return (
    <>
      <HelpWidgetTrigger onClick={() => setDisplayModal(!displayModal)} />
      <AnimatePresence>
        {displayModal && (
          <motion.div
            animate={{ opacity: 1, y: 0, transition: { duration: 0.2 } }}
            exit={{ opacity: 0, y: 5 }}
            initial={{ opacity: 0, y: 5 }}
          >
            <CurrentDialog
              goToHelpCenter={goToHelpCenter}
              goToHelpForm={goToHelpForm}
              goToHelpChat={goToHelpChat}
              onClose={() => setDisplayModal(false)}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default HelpWidget;
