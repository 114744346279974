import { makeStyles, Theme } from "@material-ui/core";

const useLeavingConfirmationStepStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1),
    "& > p:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.spacing(2),
    },
  },
  actionsContainer: {
    marginTop: theme.spacing(3),
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "flex-end",
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: theme.spacing(5),
      justifyContent: "flex-start",
    },
  },
}));

export default useLeavingConfirmationStepStyles;
