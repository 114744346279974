import { object, mixed } from "yup";

export const requiredText = "Obligatorio";

export const independentFilesSchema = object().shape({
  taxReturn: mixed()
    .nullable()
    .test("fileLength", requiredText, (value) => value.length > 0),
  companyIncomeStatement: mixed()
    .nullable()
    .test("fileLength", requiredText, (value) => value.length > 0),
  companyConstitution: mixed()
    .nullable()
    .test("fileLength", requiredText, (value) => value.length > 0),
});
