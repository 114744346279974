import { useHistory } from "react-router-dom";

import { Box } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import useSubmitSectionStyles from "./SubmitSectionStyles";

interface Props {
  loading: boolean;
}

const SubmitSection = ({ loading }: Props) => {
  const classes = useSubmitSectionStyles();
  const history = useHistory();

  return (
    <Box className={classes.submitSectionRoot}>
      <DesignSystemButton
        label="Cancelar"
        size="small"
        variant="tertiaryB"
        onClick={() => history.push("/tenant")}
        disabled={loading}
      />
      <DesignSystemButton
        label="Guardar"
        size="small"
        variant="primary"
        type="submit"
        disabled={loading}
        isLoading={loading}
      />
    </Box>
  );
};

export default SubmitSection;
