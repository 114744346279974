import { isBrowser } from "react-device-detect";

import { Box, Grid } from "@material-ui/core";

import { useApplicant } from "../../context/ApplicantContext";
import useApplicationsRecordStyles from "./ApplicationsRecordStyles";
import HistorialCard from "./components/HistorialCard";
import DetailCard from "./components/DetailCard";
import { Views } from "../../context/ApplicantContext.types";

const ApplicationsRecord = () => {
  const {
    currentView,
    historialApplications,
    selectedApplication,
    handleSelectedApplication,
  } = useApplicant();
  const classes = useApplicationsRecordStyles();

  if (!selectedApplication) {
    handleSelectedApplication(historialApplications[0]);
  }

  if (currentView === Views.MOBILE_APPLICANT_DETAIL)
    return (
      <Box className={classes.historialContainer}>
        <DetailCard application={selectedApplication} />
      </Box>
    );

  return (
    <Box className={classes.historialContainer}>
      <Grid className={classes.historialCardsContainer}>
        {historialApplications?.map((application) => (
          <HistorialCard
            key={application.id}
            application={application}
            selectedApplication={selectedApplication}
            handleSelectedApplication={handleSelectedApplication}
          />
        ))}
      </Grid>
      <Grid>
        {isBrowser && selectedApplication && (
          <DetailCard application={selectedApplication} />
        )}
      </Grid>
    </Box>
  );
};

export default ApplicationsRecord;
