import { format } from "date-fns";
import { es } from "date-fns/locale";

import { DepositStatus } from "../models/Movement";

export const getIsTenantDefaulter = (
  paymentTenantStatus: DepositStatus,
  tenantDebtDays: number
) => {
  const actualDay = format(new Date(), "d", { locale: es });

  return (
    paymentTenantStatus === "UNPAID" &&
    (tenantDebtDays > 5 || Number(actualDay) > 5)
  );
};
