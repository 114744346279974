import { format, add, compareDesc } from "date-fns";
import es from "date-fns/locale/es";

import { BankAccountType } from "apps/tenant/models/BankAccount";
import { Locale } from "models/Countries";
import { accountTypesName } from "utils/accountTypes";
import { localeToCountry } from "utils/countryCode";

export const formatDate = (date: Date, notYear = false) => {
  const formatString = notYear ? "d 'de' MMMM" : "d 'de' MMMM 'del' yyyy";
  return format(date ?? new Date(), formatString, {
    locale: es,
  });
};

export const lessThanXDaysToDate = ({
  date,
  days,
}: {
  date: Date;
  days: number;
}): boolean => compareDesc(add(new Date(), { days }), date) !== 1;

interface Props {
  accountType: BankAccountType;
  country: Locale;
}

export const getAccountType = ({ accountType, country }: Props) => {
  const countryName = localeToCountry[country];
  return accountTypesName[countryName][accountType];
};
