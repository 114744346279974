import { useTranslation } from "react-i18next";
import { useHoumSnackBar } from "libs/houm-snackbar";

import { Typography } from "@material-ui/core";

import { useMarket } from "apps/tenant/context/MarketContext";
import useGetUserBankAccounts from "apps/tenant/hooks/useGetUserBankAccounts";

import BankAccountCard from "./components/BankAccountCard";
import NoBankAccountCard from "./components/NoBankAccountCard";
import useBankAccountStyles from "./BankAccountStyles";
import { getCurrentBankAccount } from "./utils";

interface Props {
  propertyId: number;
}

const BankAccount = ({ propertyId }: Props) => {
  const classes = useBankAccountStyles();
  const { market } = useMarket();
  const {
    isLoading,
    isError,
    data: bankAccounts,
  } = useGetUserBankAccounts({ market });
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const { t } = useTranslation("tenant.bank-account");

  if (isLoading) return null;
  if (isError) {
    enqueueHoumSnackBar({
      head: "Error",
      body: "Hubo un problema cargando tus datos bancarios",
      type: "error",
    });
    return null;
  }
  const currentBankAccount = getCurrentBankAccount(
    bankAccounts,
    String(propertyId)
  );

  return (
    <div className={classes.bankAccountRoot}>
      <Typography className={classes.title}>Cuenta bancaria</Typography>
      <Typography className={classes.subtitle}>
        {t("dashboard-message")}
      </Typography>
      {(!currentBankAccount || !bankAccounts?.length) && <NoBankAccountCard />}
      {currentBankAccount && (
        <BankAccountCard
          key={currentBankAccount?.identifier}
          accountInfo={currentBankAccount}
          country={market}
        />
      )}
    </div>
  );
};

export default BankAccount;
