import RedirectIfUser from "apps/main/components/RedirectIfUser";
import { Route, useRouteMatch } from "react-router-dom";
import AccountCreate from "../pages/AccountCreate";

const AccountPublicRoutes = () => {
  const match = useRouteMatch();

  return (
    <Route exact path={[`${match.path}/create`, `${match.path}/create/:type`]}>
      <RedirectIfUser>
        <AccountCreate />
      </RedirectIfUser>
    </Route>
  );
};

export default AccountPublicRoutes;
