import { SubscriptionPlan } from "models/Subscription";

export interface PropertyProps {
  id: number;
  uid: string;
  address: string;
  streetNumber: number;
  flatNumber: string;
  commune: string;
  country: string;
  photos: { filename: string }[];
  forRent: boolean;
  rentStatus: string;
  rentPrice: number;
  rentCurrency: string;
  forSale: boolean;
  salesStatus: string;
  salesPrice: number;
  salesCurrency: string;
  latitud: number;
  longitud: number;
  typeProperty: string;
  region: string;
  houmOwner: homeOwnerProps;
  bedrooms: number;
  bathrooms: number;
  total_area: number;
  built_area: number;
  parking_spots: number;
  is_furnished: boolean;
  regionRev: string;
  stratus: number;
  locality: string;
  property_age: number;
  municipality: string;
  gc: number;
  disponibility: string;
  pets: boolean;
  orientation: string;
  warehouse: boolean;
  otherDetails: otherDetails;
  amenities: amenitiesProps;
  video: string;
  houmChecker: houmCheckerProp;
  contractId: number | null;
  subscriptionId: string | null;
  account: accountProps;
  landlordId: number;
  propertyDetailsId: number;
  dptoNumber: number;
  schedules;
  plan: SubscriptionPlan | null;
  readjustment: string;
}

export interface homeOwnerProps {
  email: string;
  last_name: string;
  name: string;
}

export interface accountProps {
  holder: string;
  email: string;
  document: string;
  document_type: string;
  bank_code: string;
  account_type: string;
  account_number: string;
}

export interface amenitiesProps {
  piscina: boolean;
  gym: boolean;
  has_roof_garden: boolean;
}
export interface BankListDetailsProps {
  bank_id: string;
  code: number;
  legacy_id: number;
  name: string;
}
export interface BankListProps {
  cl: BankListDetailsProps;
  co: BankListDetailsProps;
  mx: BankListDetailsProps;
}

export interface houmCheckerProp {
  fullName: string;
  email: string;
  phone: string;
  photo: string;
}

export interface otherDetails {
  calefaccion: boolean;
  en_suite: boolean;
  has_balcony: boolean;
  has_houm_cleaning: boolean;
  laundry_capacity: boolean;
  furnishedKitchen: boolean;
  curtains: boolean;
}

export interface contractProps {
  formSubmitted: boolean;
}

export interface PriceSuggestProps {
  forRental: IPriceSuggest;
  forSale: IPriceSuggest;
}
export interface Statistics {
  allVisits: number;
  cancelledByHoum: number;
  cancelledByUser: number;
  cancelledNonConfirmation: number;
  cancelledVisits: number;
  doneVisits: number;
  pageviews: number;
}

export interface IPriceSuggest {
  currency: string;
  maxPrice: number;
  minPrice: number;
  suggestedPrice: number;
}

export type CurrentPage =
  | "menu"
  | "details"
  | "schedule"
  | "applicant"
  | "bank"
  | "contract"
  | "docs"
  | "inspectionReports"
  | "plans"
  | "payments"
  | "services"
  | "schedulePhotos"
  | "schedule"
  | "redactContract"
  | "firmContract"
  | "negotiation"
  | "loi";

export const SET_LOADING = "SET_LOADING";
export const SET_READJUSTMENT = "SET_READJUSTMENT";
export const SET_PROPERTY = "SET_PROPERTY";
export const SET_BANK_LIST = "SET_BANK_LIST";
export const SET_STATISTICS = "SET_STATISTICS";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_PROPERTY_TYPE = "SET_PROPERTY_TYPE";
export const SET_PRICE_SUGGEST = "SET_PRICE_SUGGEST";
export const SET_FORM_SUBMITTED = "SET_FORM_SUBMITTED";
export const SET_LOADING_SCHEDULE = "SET_LOADING_SCHEDULE";
export const SET_SIMILAR_PROPERTIES = "SET_SIMILAR_PROPERTIES";
export const SET_PROPERTY_SCHEDULE_AVAILABILITY =
  "SET_PROPERTY_SCHEDULE_AVAILABILITY";
export const SET_SUBSCRIPTION_PLAN = "SET_SUBSCRIPTION_PLAN";
export const SET_OPEN_NEGOTIATIONS = "SET_OPEN_NEGOTIATIONS";
export const SET_ACCEPTED_NEGOTIATION = "SET_ACCEPTED_NEGOTIATION";

export interface State {
  currentPage: CurrentPage;
  loading: boolean;
  propertyData: PropertyProps;
  bankList: BankListProps;
  priceSuggest: PriceSuggestProps;
  contract: contractProps;
  hasOpenNegotiations: boolean;
  hasAcceptedNegotiation: boolean;
  propertyType: "forRent" | "forSale";
  similarProperties;
  scheduleAvailability;
  loadingSchedule: boolean;
  statistics: Statistics;
}

interface setCurrentPage {
  type: typeof SET_CURRENT_PAGE;
  currentPage: CurrentPage;
}

interface setPropertyStatics {
  type: typeof SET_STATISTICS;
  statistics: Statistics;
}

interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}
interface setScheduleAvailabilityLoading {
  type: typeof SET_LOADING_SCHEDULE;
  loadingSchedule: boolean;
}

interface setReadjustment {
  type: typeof SET_READJUSTMENT;
  readjustment: string;
}

interface setSubscriptionPlan {
  type: typeof SET_SUBSCRIPTION_PLAN;
  plan: SubscriptionPlan;
}

interface setProperty {
  type: typeof SET_PROPERTY;
  data: PropertyProps;
}
interface setBankList {
  type: typeof SET_BANK_LIST;
  bankList: BankListProps;
}
interface setPriceSuggest {
  type: typeof SET_PRICE_SUGGEST;
  priceSuggest: PriceSuggestProps;
}
interface setSimilarProperties {
  type: typeof SET_SIMILAR_PROPERTIES;
  similarProperties;
}
interface setPropertyType {
  type: typeof SET_PROPERTY_TYPE;
  propertyType: "forRent" | "forSale";
}
interface setScheduleAvailability {
  type: typeof SET_PROPERTY_SCHEDULE_AVAILABILITY;
  scheduleAvailability: any;
}

interface setFormSubmitted {
  type: typeof SET_FORM_SUBMITTED;
  formSubmitted: boolean;
}

interface setOpenNegotiations {
  type: typeof SET_OPEN_NEGOTIATIONS;
  hasOpenNegotiations: boolean;
}
interface setAcceptedNegotiation {
  type: typeof SET_ACCEPTED_NEGOTIATION;
  hasAcceptedNegotiation: boolean;
}

export type Action =
  | setLoading
  | setProperty
  | setBankList
  | setCurrentPage
  | setPropertyType
  | setPriceSuggest
  | setFormSubmitted
  | setPropertyStatics
  | setReadjustment
  | setSimilarProperties
  | setScheduleAvailability
  | setScheduleAvailabilityLoading
  | setSubscriptionPlan
  | setOpenNegotiations
  | setAcceptedNegotiation;

export type Dispatch = (action: Action) => void;
