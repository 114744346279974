import moment from "moment-timezone";

import { ParseScheduleBody } from "./types";

const parseScheduleBody: ParseScheduleBody = ({
  hour,
  propertyUid,
  forSale,
  userId,
}) => ({
  begin_hour: hour,
  end_hour: moment(hour).add(2, "hours").format(),
  prop: propertyUid,
  date: hour,
  type: forSale ? "SalesPhoto" : "Photo",
  origin: "Landlord Portal",
  user_id: userId,
});

export default parseScheduleBody;
