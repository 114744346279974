import { useEffect, useMemo } from "react";

import getStepsByCountry from "apps/lead/pages/EvaluatorForm/components/Steps/Steps";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { setSteps } from "apps/lead/context/evaluatorContext/EvaluatorActions";

const UserInformationForm = () => {
  const { state, dispatch } = useEvaluation();
  const { activeStep } = state;

  const formSteps = useMemo(() => getStepsByCountry("co"), []);

  useEffect(() => {
    dispatch(setSteps(formSteps.length));
  }, [formSteps]);

  const CurrentStep = useMemo(() => formSteps[activeStep], [activeStep]);

  return <CurrentStep />;
};

export default UserInformationForm;
