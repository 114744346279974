import { makeStyles, Theme } from "@material-ui/core";

const useDefaulterTagStyles = makeStyles<Theme>((theme) => ({
  tagContainer: {
    backgroundColor: "#900000",
    height: 24,
    width: 64,
    borderRadius: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(0.25, 1),
  },
  label: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    fontWeight: 700,
    color: "white",
    fontStyle: "italic",
  },
}));

export default useDefaulterTagStyles;
