import { useEffect, useState } from "react";

const usePasswordRequirements = ({
  password,
  email,
}: {
  password?: string;
  email?: string;
}) => {
  const parsedEmail = email?.split("@")[0];
  const [caseValidation, setCaseValidation] = useState<boolean | null>(null);
  const [lengthValidation, setLengthValidation] = useState<boolean | null>(
    null
  );
  const [numberValidation, setNumberValidation] = useState<boolean | null>(
    null
  );
  const [emailValidation, setEmailValidation] = useState<boolean | null>(null);

  useEffect(() => {
    const passwordCheck = (condition: boolean) => {
      if (!password) return null;
      return condition;
    };
    setCaseValidation(
      passwordCheck(/[A-Z]/.test(password) && /[a-z]/.test(password))
    );
    setLengthValidation(passwordCheck(password.length >= 8));
    setNumberValidation(passwordCheck(/\d/.test(password)));
    setEmailValidation(passwordCheck(!password?.includes(parsedEmail)));
  }, [password, parsedEmail]);

  return {
    lengthValidation,
    caseValidation,
    numberValidation,
    emailValidation,
  };
};

export default usePasswordRequirements;
