import { Box, Typography } from "@material-ui/core";
import { Locale } from "models/Countries";
import { localeToCountry } from "utils/countryCode";
import useSetUserCountryStyles from "./UserCountryCorrectionDialogStyles";

interface CountryOptionProps {
  country: Locale;
  flagImg: string;
  onClick: () => void;
  selected: boolean;
}

const CountrySelectorOption: React.FC<CountryOptionProps> = ({
  country,
  flagImg,
  onClick,
  selected,
}) => {
  const classes = useSetUserCountryStyles();
  const countryLabel = localeToCountry[country];

  return (
    <Box
      onClick={onClick}
      data-selected={selected}
      className={classes.countryOption}
    >
      <img
        src={flagImg}
        alt={`bandera ${countryLabel}`}
        className={classes.countryFlagButton}
      />
      <Typography className={classes.countryLabel}>{countryLabel}</Typography>
    </Box>
  );
};

export default CountrySelectorOption;
