import clx from "classnames";

import PropertyCard from "Components/Admin/UIComponents/DesignSystem/PropertyCard";
import {
  EvaluationApplicant,
  PropertyApplicationModel,
} from "apps/lead/models/Application";
import usePropertyApplicationCardStyles from "./PropertyApplicationCardStyles";
import PropertyDetails from "./components/PropertyDetails";
import useOptions from "./hooks/useOptions";

interface Props {
  labelButton: string;
  handleClick?: () => void;
  property: PropertyApplicationModel;
  houmerName: string;
  evaluationData?: EvaluationApplicant;
}

const PropertyApplicationCard = ({
  labelButton,
  handleClick,
  property,
  houmerName,
  evaluationData,
}: Props) => {
  const classes = usePropertyApplicationCardStyles();
  const { optionList, status } = useOptions(evaluationData, property.country);

  return (
    <PropertyCard
      labelButton={labelButton}
      onHandleClick={handleClick}
      property={property}
      statusLabel={status}
      chipClassName={clx({
        [classes.chipPending]: status === "Pendiente",
        [classes.chipApproved]: status === "Aprobado",
        [classes.chipRejected]: status === "Rechazado",
        [classes.chipReview]: status === "En revisión",
      })}
      optionList={optionList}
    >
      <PropertyDetails
        property={property}
        houmerName={houmerName}
        evaluationData={evaluationData}
      />
    </PropertyCard>
  );
};

export default PropertyApplicationCard;
