import { ChangeEvent, SyntheticEvent, useCallback, useState } from "react";

import { Files } from "apps/lead/models/Application";

import AlertSnackbar from "apps/lead/components/AlertSnackbar";
import ButtonFullFile from "../ButtonFullFile";
import ButtonEmptyFile from "../ButtonEmptyFile";

interface Props {
  allowedMimeTypes?: string[];
  handleUpload: (file: Files) => void;
  handleDelete: (file: string) => void;
  files: Files[];
  index: number;
}

export type Color = "success" | "info" | "warning" | "error";

interface IReason {
  text: string;
  type: Color;
}

const UploadFileButton = ({
  allowedMimeTypes = ["png", "jpeg", "jpg", "pdf"],
  handleUpload,
  handleDelete,
  files,
  index,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState<IReason | null>(null);

  const handleClick = (text: string, type: Color) => {
    setReason({ text, type });
    setOpen(true);
  };

  const handleClose = useCallback((event?: SyntheticEvent, res?: string) => {
    if (res === "clickaway") {
      return;
    }
    setOpen(false);
  }, []);

  const handleUpdateFile = (e: ChangeEvent<HTMLInputElement>) => {
    const ALLOWED_MB_SIZE = 3;
    const fileSizeMb = e.target.files[0].size / 1024 / 1024;
    if (fileSizeMb > ALLOWED_MB_SIZE) {
      handleClick("El archivo pesa más de los 3MB permitidos", "error");
    } else if (
      allowedMimeTypes.includes(e.target.files[0]?.type.split("/")[1])
    ) {
      handleClick("Documento adjuntado exitosamente", "success");
      handleUpload(e.target.files[0]);
    } else {
      handleClick("El archivo no cuenta con el formato solicitado", "error");
    }
  };

  return (
    <div>
      {files.length === 0 || files[index] === undefined ? (
        <ButtonEmptyFile
          allowedMimeTypes={allowedMimeTypes}
          handleUpdateFile={handleUpdateFile}
        />
      ) : (
        <ButtonFullFile file={files[index]} handleDelete={handleDelete} />
      )}
      <AlertSnackbar
        color={reason?.type === "success" ? "#48AA02" : "#EA1601"}
        reason={reason}
        handleClose={handleClose}
        open={open}
      />
    </div>
  );
};

export default UploadFileButton;
