import { makeStyles, Theme } from "@material-ui/core";

const useHeaderStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(3.5),
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
  },
}));

export default useHeaderStyles;
