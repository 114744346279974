import { makeStyles } from "@material-ui/core";

const useCountryListStyles = makeStyles((theme) => ({
  maxLengthName: {
    visibility: "hidden",
    maxHeight: "20px",
  },
  searchContainer: {
    padding: theme.spacing(0, 4),
  },
}));
export default useCountryListStyles;
