import { useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import PropertyLayout from "apps/landlord/Layout/PropertyLayout";
import useGetContractProperty from "apps/landlord/hooks/useGetContractProperty";
import useGetProperty from "apps/landlord/hooks/useGetProperty";
import { parseProperty } from "context/landlordPropertyContext/utils";
import { SubscriptionPlan } from "models/Subscription";

import Navbar from "Components/Layout/GlobalHeader";
import Spinner from "Components/Shared/partials/Spinner";
import { plansList } from "./constants";
import SubscriptionPlansHeader from "./components/Header";
import SubscriptionPlanOptions from "./components/SubscriptionPlanOptions";
import SubscriptionPlanSelected from "./components/SubscriptionPlanSelected";

const SubscriptionPlans = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const {
    isLoading: propertyLoading,
    data: property,
    error: propertyError,
  } = useGetProperty(params.id);
  const {
    isLoading: contractLoading,
    data: contract,
    error: contractError,
  } = useGetContractProperty(params.id);
  const [selectedPlan, setSelectedPlan] = useState<
    SubscriptionPlan | null | undefined
  >(null);
  const [submitted, setSubmitted] = useState(false);
  const availablePlansId = plansList.map((p) => p.id);

  if (propertyError) {
    Swal.fire(
      "Ha ocurrido un error inesperado",
      "Por favor, vuelva a intentarlo o contacte a su ejecutivo",
      "error"
    );
    history.push(`/landlord/properties/${params.id}`);
  }

  if (contractLoading || propertyLoading) {
    return (
      <>
        <Navbar />
        <Spinner />
      </>
    );
  }

  if (!["cl", "Chile"].includes(property?.country)) {
    history.push(`/landlord/properties/${params.id}`);
  }
  const propertyData = parseProperty(property);
  const hasCustomPlan =
    !(contractError || !contract?.plan) &&
    !availablePlansId.includes(contract?.plan?.id);
  if (contractError || !contract?.plan) {
    if (propertyData.plan && !selectedPlan) {
      setSelectedPlan(plansList.find((p) => p.id === propertyData?.plan?.id));
    }
  } else if (availablePlansId.includes(contract?.plan?.id) && !selectedPlan) {
    setSelectedPlan(plansList.find((p) => p.id === contract?.plan?.id));
  }
  const hasPlanSelected = !!selectedPlan || submitted || hasCustomPlan;

  return (
    <PropertyLayout
      titlePage="Plan de suscripción"
      onHandleBack={() => history.push(`/landlord/properties/${params.id}`)}
    >
      <SubscriptionPlansHeader hasPlan={hasPlanSelected} />
      {!hasPlanSelected ? (
        <SubscriptionPlanOptions
          propertyId={propertyData.id}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          setSubmitted={setSubmitted}
        />
      ) : (
        <SubscriptionPlanSelected
          isCustom={hasCustomPlan}
          contract={contract}
          plan={selectedPlan}
        />
      )}
    </PropertyLayout>
  );
};

export default SubscriptionPlans;
