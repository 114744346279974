import { SET_COUNTRY } from "context/contractPersonContext/contractPersonTypes";
import { CountryUpperCase } from "models/Countries";
import {
  Action,
  Applicant,
  SET_CODEBTOR,
  SET_ACTIVE_STEP,
  SET_STEPS,
  SET_EMAILS,
  SET_EVALUATION,
  SET_LOADING,
  SET_APPLICANT,
} from "./CodebtorTypes";

export const setLoading = (loading: boolean): Action => ({
  type: SET_LOADING,
  loading,
});

export const setSteps = (steps: number): Action => ({
  type: SET_STEPS,
  steps,
});

export const setCountry = (country: CountryUpperCase): Action => ({
  type: SET_COUNTRY,
  country,
});

export const setCodebtor = (codebtor: Partial<Applicant>): Action => ({
  type: SET_CODEBTOR,
  codebtor,
});

export const setActiveStep = (activeStep: number): Action => ({
  type: SET_ACTIVE_STEP,
  activeStep,
});

export const setEmails = (emails: string[]): Action => ({
  type: SET_EMAILS,
  emails,
});

export const setEvaluation = (evaluation: {
  evaluationId: number;
  persons: Partial<Applicant>[];
}): Action => ({
  type: SET_EVALUATION,
  evaluation,
});

export const setApplicant = (applicant: {
  person: Partial<Applicant>;
  role: string;
}): Action => ({
  type: SET_APPLICANT,
  applicant,
});
