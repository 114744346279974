import { makeStyles, Theme } from "@material-ui/core";

const useAuthLogoutButtonStyles = makeStyles<Theme>((theme) => ({
  button: {
    width: "100%",
    marginBottom: theme.spacing(5),
  },
}));

export default useAuthLogoutButtonStyles;
