import { IProperty } from "./types";

const getAddress = (property: IProperty) => {
  const prefixData =
    property.type === "departamento"
      ? `Dpto. ${property?.numeroDepto ?? ""}`
      : "";

  const fullAddress = property?.address
    ? `${property?.address.trim().replace(/^\w/, (c) => c.toUpperCase())} ${
        property?.streetNumber ?? ""
      }`
    : "";
  return {
    address: `${prefixData}${
      property.type === "departamento" && property?.address ? ", " : ""
    }${fullAddress}`,
    city: `${property?.comuna}${property?.city ? `, ${property?.city}` : ""}`,
  };
};

export default getAddress;
