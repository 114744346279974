import Swal from "sweetalert2";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import SelectionOptions, {
  Option,
} from "Components/Admin/UIComponents/ControlledForm/SelectionOptions";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import { useGetToken } from "hooks";
import propertyService from "services/propertyService";
import useReadjustmentDialogStyles from "./ReadjustmentDialogStyles";
import alert from "./alerts";
import PlanInfo from "../../models";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  options: Option[];
  handleChange: (option: string) => void;
  value: string;
  propertyId: number;
  setLoading: (loading: boolean) => void;
  preSelectedPlan?: PlanInfo;
  setSelectedPlan: (plan: PlanInfo) => void;
  setSubmitted: (submitted: boolean) => void;
}

export default function ReadjustmentDialog({
  open,
  setOpen,
  options,
  handleChange,
  value,
  propertyId,
  setLoading,
  preSelectedPlan,
  setSelectedPlan,
  setSubmitted,
}: Props) {
  const classes = useReadjustmentDialogStyles();
  const { getToken } = useGetToken();

  const handleReadjustmentSelected = async () => {
    try {
      setLoading(true);
      const authToken = await getToken();
      await propertyService.updateRentalDetails(
        propertyId,
        { readjust: value, plan_id: preSelectedPlan?.id },
        authToken
      );
    } catch (error) {
      Swal.fire(alert.title, alert.description, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (value) {
      await handleReadjustmentSelected();
      setOpen(false);
      setSelectedPlan(preSelectedPlan);
      setSubmitted(true);
    }
  };

  return (
    <CustomDialog
      open={open}
      title="Selecciona el tipo de reajuste"
      onClose={() => setOpen(false)}
      classes={{
        title: classes.title,
        subtitle: classes.subtitle,
        modalContainer: classes.modalContainer,
        titleContainer: classes.titleContainer,
      }}
    >
      <div className={classes.selectContainer}>
        <SelectionOptions
          id="readjustmentSelection"
          defaultValue="annual"
          options={options}
          onChange={handleChange}
          value={value}
          buttonClassName={classes.button}
        />
      </div>
      <div className={classes.buttonsContainer}>
        <DesignSystemButton
          label="Aplicar"
          variant="primary"
          size="large"
          onClick={handleSubmit}
          disabled={!value}
        />
      </div>
    </CustomDialog>
  );
}
