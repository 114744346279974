import { Box, Typography } from "@material-ui/core";

import { ArrowForward } from "@material-ui/icons";

import useInspectionReportCommentsStyles from "../InspectionReportCommentsStyles";

const RequestTriggerCard = () => {
  const classes = useInspectionReportCommentsStyles();
  return (
    <Box className={classes.highlightedBox}>
      <Typography className={classes.highlightedBoxTitle}>
        ¿Necesitas alguna reparación?
      </Typography>
      <Typography className={classes.highlightedBoxText}>
        Si tienes un problema que te impida disfrutar de tu hogar, puedes
        solicitar una reparación y trataremos de resolverlo lo más pronto
        posible.
      </Typography>
      <Typography className={classes.highlightedBoxLink}>
        Solicitar Reparación
        <ArrowForward />
      </Typography>
    </Box>
  );
};

export default RequestTriggerCard;
