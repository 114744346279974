import { makeStyles, Theme } from "@material-ui/core";

interface ReferPartnerStyleProps {
  img: string;
}

const useReferPartnerStyles = makeStyles<Theme, ReferPartnerStyleProps>(
  (theme: Theme) => ({
    displayContainer: {
      display: "flex",
      width: "100%",
      backgroundImage: (props) => `url(${props.img})`,
      borderRadius: "1rem",
      backgroundPosition: "top",
      backgroundRepeat: "no-repeat",
      // backgroundSize: "contain",
      [theme.breakpoints.down("md")]: {
        backgroundSize: "cover",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "-1rem",
        marginTop: "-4rem",
        width: "100vw",
      },
    },
    formContainer: {
      margin: "48px auto",
      maxWidth: "540px",
      padding: "48px 62px 64px 48px",
      border: "solid",
      borderWidth: 1,
      borderRadius: "1rem",
      borderColor: "#f4f4f4",
      boxShadow: "3px 4px 25px rgba(38, 50, 56, 0.15)",
      backgroundColor: theme.palette.common.white,
      zIndex: 1,
      [theme.breakpoints.down("sm")]: {
        padding: "48px 16px",
        margin: "16px auto",
      },
    },
  })
);

export default useReferPartnerStyles;
