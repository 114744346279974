import EvaluatorLayout from "apps/lead/layout/EvaluatorLayout";
import useCodebtorForm from "./hooks/useCodebtorForm";

const CodebtorForm = () => {
  const { CurrentStep, handleBack } = useCodebtorForm();

  return (
    <EvaluatorLayout handleBack={handleBack}>{CurrentStep}</EvaluatorLayout>
  );
};

export default CodebtorForm;
