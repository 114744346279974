import { AxiosResponse } from "axios";
import { format } from "date-fns";
import qs from "query-string";

import { Paginate } from "models/ResponseWithPaginate";
import { createHttp } from "services/helper";
import { Locale } from "models/Countries";
import { PAYMENTS_URL } from "env";

import {
  CurrentMonthBreakdownResponse,
  IPaymentDetailByMovementId,
  IReceiptsResponse,
  PropertiesSummaryBreakdownModelResponse,
  SummaryBreakdownModelResponse,
  SummaryModelResponse,
} from "./types";

const qsConfig = { skipEmptyString: true, skipNull: true };

export default {
  getSummary: (
    country: Locale,
    userId: number,
    token: string,
    propertyId: number = null,
    date: string = format(new Date(), "yyyy-MM-dd")
  ): Promise<AxiosResponse<SummaryModelResponse>> => {
    const query_filters = {
      date,
      properties_ids: [propertyId],
    };
    return createHttp(token, PAYMENTS_URL).get(
      `/${country}/v3/people/${userId}/payment_summary?${qs.stringify(
        query_filters,
        qsConfig
      )}`
    );
  },
  getSummaryFullBreakdown: (
    country: Locale,
    userId: number,
    token: string,
    params = {}
  ): Promise<AxiosResponse<Paginate<SummaryBreakdownModelResponse>>> =>
    createHttp(token, PAYMENTS_URL).get(
      `/${country}/v3/people/${userId}/full_breakdown?${qs.stringify(
        params,
        qsConfig
      )}`
    ),
  getPropertySummaryFullBreakdown: (
    country: Locale,
    userId: number,
    token: string,
    params = {}
  ): Promise<AxiosResponse<PropertiesSummaryBreakdownModelResponse>> =>
    createHttp(token, PAYMENTS_URL).get(
      `/${country}/v3/people/${userId}/full_breakdown?${qs.stringify(
        params,
        qsConfig
      )}`
    ),
  getCurrentMonthBreakdown: (
    country: Locale,
    userId: number,
    token: string,
    params: {
      month_date?: string;
      movement_type?: "OUTFLOW" | "INCOME";
      page?: string;
      per_page?: string;
      properties_ids?: number[];
    } = {}
  ): Promise<AxiosResponse<CurrentMonthBreakdownResponse>> =>
    createHttp(token, PAYMENTS_URL).get(
      `/${country}/v3/people/${userId}/payment_breakdown?${qs.stringify(
        params,
        qsConfig
      )}`
    ),
  getPaymentDetailByMovementId: (
    country: Locale,
    movementId: string,
    token: string
  ): Promise<AxiosResponse<IPaymentDetailByMovementId>> =>
    createHttp(token, PAYMENTS_URL).get(
      `/${country}/v3/movements/${movementId}`
    ),

  getReceipts: (
    movementId: string,
    token: string,
    country: Locale = "cl"
  ): Promise<AxiosResponse<IReceiptsResponse[]>> =>
    createHttp(token, PAYMENTS_URL).get(
      `${country}/v3/billing/${movementId}/invoices`
    ),
};
