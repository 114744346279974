import { useHistory } from "react-router-dom";

import {
  CalendarTodayOutlined,
  MonetizationOnOutlined,
} from "@material-ui/icons";

import { IoDocumentOutline as DocumentIcon } from "@houm-com/ui-icons";

import { IProperty } from "apps/landlord/models/Property";
import { useURLParamsFlag } from "libs/feature-flags";

const useSupplyLandlordNavigation = (propertyData: IProperty) => {
  const history = useHistory();
  const flag = useURLParamsFlag("alternative_disable");
  const isRented = propertyData.propertyDetails.status === "rented";
  const isPublished = ["republished", "published"].includes(
    propertyData?.propertyDetails?.status
  );

  return [
    {
      onClick: () => {
        history.replace(
          `/landlord/properties/${propertyData.uid}/property-price/${
            flag ? "/?ff=alternative_disable" : ""
          }`
        );
      },
      pageTitle: "Análisis",
      icon: <MonetizationOnOutlined />,
      disabled: flag ? !isPublished : isRented,
    },
    {
      onClick: () =>
        history.push(`/landlord/properties/${propertyData.uid}/schedules`),
      pageTitle: "Agenda",
      icon: <CalendarTodayOutlined />,
      disabled: isRented,
    },
    {
      onClick: () =>
        history.push(
          `/landlord/properties/${propertyData.uid}/property-requests`
        ),
      pageTitle: "Solicitudes",
      icon: <DocumentIcon className="w-6 h-6" />,
    },
  ];
};

export default useSupplyLandlordNavigation;
