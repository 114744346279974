import { makeStyles, Theme } from "@material-ui/core";
import { MOBILE_NAVBAR_HEIGHT } from "apps/tenant/constants";

interface Props {
  hasBackButton: boolean;
}

const useMobileNavbarStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    display: "flex",
    width: "100%",
    alignItems: "center",
    boxShadow: "3px 4px 20px rgba(38, 50, 56, 0.15)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: theme.zIndex.appBar,
    backgroundColor: "white",
    height: MOBILE_NAVBAR_HEIGHT,
  },
  backButton: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    fontsize: theme.typography.pxToRem(14),
    color: "#4C6977",
  },
  menuIcon: {
    cursor: "pointer",
    marginLeft: "auto",
    color: "#4C6977",
  },
  logoContainer: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  navLogo: ({ hasBackButton }) => ({
    height: hasBackButton ? 50 : 35,
  }),
  navbarDrawerPaper: {
    overflow: "hidden",
    width: "80%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  drawerRoot: {
    backgroundColor: "rgba(38, 50, 56, 0.4)",
  },
  drawer: {
    width: "100%",
    height: "100%",
    position: "relative",
    padding: theme.spacing(3, 2),
  },
  closeButton: {
    float: "right",
    padding: 0,
    "& svg path": {
      fill: "#474747",
    },
  },
  icon: {
    "& path": {
      fill: theme.palette.grey[900],
    },
  },

  switchApp: {
    display: "flex",
  },

  switchAppText: {
    fontSize: theme.typography.pxToRem(14),
    marginLeft: theme.spacing(1),
  },

  bottomContainer: {
    position: "absolute",
    bottom: 0,
    width: "90%",
  },
}));

export default useMobileNavbarStyles;
