import { Collapse, TableCell } from "@material-ui/core";

import useStyles from "./LayoutTableStyles";

interface Props {
  open: boolean;
  colSpan?: number;
  children?: JSX.Element;
}

const CollapsibleCell = ({ open, colSpan = 1, children = null }: Props) => {
  const classes = useStyles();
  return (
    <TableCell
      colSpan={colSpan}
      className={!open ? classes.cellCollapsed : classes.cell}
    >
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </TableCell>
  );
};

export default CollapsibleCell;
