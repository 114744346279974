import { useFeatureManager } from "@houm-com/feature-manager";

import Spinner from "Components/Shared/partials/Spinner";

import PaymentMethodCard from "../PaymentMethodCard";

const PaymentMethodsMX = () => {
  const { status, data } = useFeatureManager("open_pay");
  const isOpenPayFlag = status === "success" && data?.enabled;
  const { status: statusPayU, data: dataPayU } = useFeatureManager("pay_u");
  const isPayUFlag = statusPayU === "success" && dataPayU?.enabled;
  const { status: statusFintoc, data: dataFintoc } = useFeatureManager(
    "fintoc",
    { marketCode: "mx" }
  );
  const isFintocFlag = statusFintoc === "success" && dataFintoc?.enabled;

  const isLoading =
    status === "loading" ||
    statusPayU === "loading" ||
    statusFintoc === "loading";

  if (isLoading) return <Spinner />;

  return (
    <>
      {isOpenPayFlag && <PaymentMethodCard data={data.content} />}
      {isPayUFlag && <PaymentMethodCard data={dataPayU.content} />}
      {isFintocFlag && <PaymentMethodCard data={dataFintoc.content} />}
    </>
  );
};

export default PaymentMethodsMX;
