import { makeStyles } from "@material-ui/core";

const useHeaderStepperStyles = makeStyles((theme) => ({
  stepsWrapper: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    gap: theme.spacing(2),
    alignItems: "center",
    justifyContent: "stretch",
  },
  stepItem: {
    display: "flex",
    flexGrow: 1,
    minHeight: 4,
    minWidth: 4,
    backgroundColor: theme.palette.grey[75],
    borderRadius: theme.spacing(2),
    cursor: "pointer",
    transition: "background-color 0.3s ease-in-out",
    "&[data-active='true']": {
      backgroundColor: "#4C6977",
    },
  },
}));

export default useHeaderStepperStyles;
