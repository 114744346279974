import { Box, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { formatDate } from "apps/tenant/utils/utils";
import { ContractResponse } from "apps/landlord/services/propertyContractService/types";
import useInitialStepStyles from "./IntroductionStyles";
import {
  LOCALE_BASED_ONGOING_EOC_INTRO,
  LOCALE_BASED_ONGOING_EOC_LEAVING_DATE_REMINDER,
  LOCALE_BASED_ONGOING_EOC_WIP,
} from "./constants";

interface IntroductionProcessOngoingProps {
  handleNext: () => void;
  estimatedDate: string;
  activeContract: ContractResponse;
}

const IntroductionProcessOngoing = ({
  handleNext,
  estimatedDate,
  activeContract,
}: IntroductionProcessOngoingProps) => {
  const classes = useInitialStepStyles();

  const parsedDate = new Date(estimatedDate);
  const formattedDate = formatDate(parsedDate);

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>
        Has iniciado el proceso de salida
      </Typography>
      <Typography className={classes.description}>
        {LOCALE_BASED_ONGOING_EOC_INTRO[activeContract.country]}
      </Typography>
      <Typography className={classes.description}>
        {LOCALE_BASED_ONGOING_EOC_WIP[activeContract.country]}
      </Typography>
      <Box>
        <ul className={classes.stepsContainer}>
          <li>
            <Typography className={classes.stepTitle}>
              Fecha de entrega
            </Typography>
            <Typography className={classes.stepDescription}>
              El <strong>{formattedDate}</strong>{" "}
              {
                LOCALE_BASED_ONGOING_EOC_LEAVING_DATE_REMINDER[
                  activeContract.country
                ]
              }
            </Typography>
          </li>
        </ul>
      </Box>
      <Box className={classes.ctaContainer}>
        <Box className={classes.btnContainer}>
          <DesignSystemButton
            label="Salir"
            variant="primary"
            size="small"
            onClick={handleNext}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default IntroductionProcessOngoing;
