import { Locale, CountryUpperCase, Country } from "models/Countries";

const countryCode = (country: string): Locale => {
  const code = country.toLowerCase();
  switch (code) {
    case "colombia":
      return "co";
    case "chile":
      return "cl";
    case "méxico":
    case "mexico":
      return "mx";
    case "cl":
      return "cl";
    case "mx":
      return "mx";
    case "co":
      return "co";
    default:
      console.error("Wrong country");
      return "cl";
  }
};

export const localeToCountry: { [locale in Locale]: CountryUpperCase } = {
  cl: "Chile",
  co: "Colombia",
  mx: "Mexico",
};

export const countryIntoCountryUpperCase: {
  [country in Country]: CountryUpperCase;
} = {
  chile: "Chile",
  colombia: "Colombia",
  mexico: "México",
};

export const countryUpperCaseIntoCountry: {
  [country in CountryUpperCase]: Country;
} = {
  Chile: "chile",
  Colombia: "colombia",
  México: "mexico",
  Mexico: "mexico",
};

export const getLocaleToCountry = (locale: Locale): CountryUpperCase =>
  (localeToCountry[locale] as CountryUpperCase) || "Chile";

export default countryCode;
