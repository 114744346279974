import { useQuery } from "react-query";

import { addressDetailsSchema } from "apps/lead/components/AddressSuggester/utils/types";
import addressService from "apps/lead/services/AddressService";
import { Locale } from "models/Countries";

const useAddressDetails = ({
  referenceId,
  countryCode,
}: {
  referenceId: string;
  countryCode: Locale;
}) =>
  useQuery(
    ["locations/address/details", countryCode, { referenceId }],
    async () => {
      const { data } = await addressService.getAddressDetails({
        referenceId,
        countryCode,
      });
      return addressDetailsSchema.parse(data);
    },
    {
      enabled: !!referenceId,
      retry: false,
    }
  );

export default useAddressDetails;
