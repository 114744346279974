/* eslint-disable max-lines */
import { createTheme } from "@material-ui/core/styles";

const defaultBreakpoint = "sm";

const designSystemCalendarTheme = (theme) =>
  createTheme({
    ...theme,
    overrides: {
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          marginBottom: theme.spacing(3),
          overflow: "inherit !important",
          [theme.breakpoints.up(defaultBreakpoint)]: {
            marginBottom: 0,
            borderRadius: 16,
            width: "100%",
          },
        },
      },
      MuiPickersBasePicker: {
        container: {},
        pickerView: {
          width: "100%",
          maxWidth: "unset",
          justifyContent: "start",
          [theme.breakpoints.up(defaultBreakpoint)]: {
            minWidth: 422,
            minHeight: "auto",
          },
        },
      },
      MuiPickersCalendarHeader: {
        iconButton: {
          padding: 0,
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        switchHeader: {
          marginTop: 0,
          marginBottom: 0,
          padding: theme.spacing(2),
          fontFamily: "Nunito",
          color: theme.palette.grey[500],
          textTransform: "capitalize",
          width: "100%",
        },
        dayLabel: {
          color: theme.palette.grey[900],
          fontSize: theme.typography.pxToRem(14),
          fontWeight: 700,
          fontFamily: "Nunito",
          textTransform: "capitalize",
        },
        transitionContainer: {
          "& p": {
            fontSize: theme.typography.pxToRem(18),
            fontWeight: 700,
            fontFamily: "Nunito",
            [theme.breakpoints.up(defaultBreakpoint)]: {
              fontSize: theme.typography.pxToRem(14),
            },
          },
        },
        daysHeader: {
          padding: theme.spacing(1, 2, 0),
          display: "flex",
          justifyContent: "space-around",
        },
      },
      MuiPickersCalendar: {
        transitionContainer: {
          [theme.breakpoints.up(defaultBreakpoint)]: {
            marginTop: 0,
            height: 320,
          },
          paddingBottom: theme.spacing(1),
        },
        week: {
          padding: theme.spacing(0.5, 2),
          display: "flex",
          justifyContent: "space-around",
        },
      },
      MuiPickersDay: {
        day: {
          width: 28,
          height: 28,
          color: theme.palette.grey[900],
          [theme.breakpoints.up(defaultBreakpoint)]: {
            width: 46,
            height: 46,
          },
          "& p": {
            fontWeight: 400,
            fontSize: theme.typography.pxToRem(14),
            fontFamily: "Nunito",
          },
        },
        daySelected: {
          color: theme.palette.grey[900],
          backgroundColor: theme.palette.background.default,
          border: `1px solid #A0B1B9`,
          [theme.breakpoints.up(defaultBreakpoint)]: {
            border: "1.5px solid #A0B1B9",
          },
          "& p": {
            fontWeight: 700,
            [theme.breakpoints.up(defaultBreakpoint)]: {
              fontWeight: 400,
            },
          },
          "&:hover": {
            color: theme.palette.grey[900],
            backgroundColor: theme.palette.background.default,
          },
        },
        current: {
          color: theme.palette.grey[900],
        },
      },
    },
  });

export default designSystemCalendarTheme;
