import { Files, WorkerType } from "apps/lead/models/Application";
import { CountryUpperCase } from "models/Countries";

import { Applicant as ApplicantContext } from "apps/lead/context/codebtorContext/CodebtorTypes";
import { formatPhoneInput } from "utils";

import { Applicant } from "./types";

const parseWorkerType = {
  independent: "Independiente",
  dependent: "Dependiente",
  pensioner: "Pensionado",
};

export const backendCreateCodebtorParser = (
  country: CountryUpperCase,
  user: Partial<Applicant>
) => {
  const RUT_ID = 1;

  return {
    country,
    document: user.document,
    document_type: user.documentType ?? RUT_ID,
    email: user.email,
    last_name: user.lastName,
    name: user.name,
    phone:
      typeof user?.phone === "string" ? user?.phone : user?.phone?.parsedPhone,
    second_last_name: user.secondLastName,
    work_type: parseWorkerType[user.workerType],
  };
};

export const parseApplicantWorkerType = (
  user: Partial<ApplicantContext>,
  workerType: WorkerType
) => ({
  ...user,
  files: {
    identityCard: user.files.identityCard,
  },
  workerType,
});

export const parseUserFiles = (
  user: Partial<ApplicantContext>,
  exactlyFile: { [key: string]: Files[] }
) => ({
  ...user,
  files: {
    ...user.files,
    ...exactlyFile,
  },
});

export const parseUserData = (user) => ({
  id: user.id,
  name: user.name,
  lastName: user.last_name,
  secondLastName: user.second_last_name,
  rut: user.rut,
  phone: formatPhoneInput(user.phone),
  country: user.country,
  email: user.email,
  role: user.role,
});
