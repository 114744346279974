import { makeStyles, Theme } from "@material-ui/core";

const useApplicantDetailsStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    lineHeight: 1.57,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    display: "block",
  },
  applicantDetails: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "fit-content",
    },
  },
  applicantDetailsContent: {
    paddingLeft: theme.spacing(1),
    gap: theme.spacing(0.25),
  },
}));

export default useApplicantDetailsStyles;
