import { Grid, Typography } from "@material-ui/core";

import {
  IoHeartOutline as HeartIcon,
  IoEyeOutline as ViewIcon,
  IoCalendarOutline as CalendarIcon,
  IoKeyOutline as KeyIcon,
} from "@houm-com/ui-icons";

import { IMarketPlaceStats } from "../../hooks/utils/types";
import useStyles from "./StatsInfoCardStyles";

interface Props {
  isMain?: boolean;
  marketplaceStats: IMarketPlaceStats;
}

const StatsInfoCard = ({ isMain = false, marketplaceStats }: Props) => {
  const classes = useStyles();
  const views = marketplaceStats.pageViews;
  const likes = marketplaceStats.likeUsers;
  const scheduledVisits = marketplaceStats.allVisits;
  const finishedVisits = marketplaceStats.doneVisits;

  const mainArray = [
    {
      icon: <ViewIcon className="h-5 w-5" />,
      value: views,
      label: "visualizaciones",
    },
    {
      icon: <HeartIcon className="h-5 w-5" />,
      value: likes,
      label: "interesados",
    },
    {
      icon: <CalendarIcon className="h-5 w-5" />,
      value: scheduledVisits,
      label: "citas agendadas",
    },
    {
      icon: <KeyIcon className="h-5 w-5" />,
      value: finishedVisits,
      label: "citas realizadas",
    },
  ];
  const similarArray = [
    {
      icon: <ViewIcon className="h-5 w-5" />,
      value: views,
      label: "visualizaciones",
    },
    {
      icon: <CalendarIcon className="h-5 w-5" />,
      value: scheduledVisits,
      label: "citas agendadas",
    },
    {
      icon: <HeartIcon className="h-5 w-5" />,
      value: likes,
      label: "interesados",
    },
    {
      icon: <KeyIcon className="h-5 w-5" />,
      value: finishedVisits,
      label: "citas realizadas",
    },
  ];
  const arrayToMap = isMain ? mainArray : similarArray;

  return (
    <Grid container className={classes.container}>
      {arrayToMap.map((item) => (
        <Grid item key={item.label} xs={isMain ? 3 : 6}>
          <Typography className={classes.stat}>
            {item.icon} {item.value} {item.label}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default StatsInfoCard;
