import { makeStyles, Theme } from "@material-ui/core";

const useStatusExplanationStyles = makeStyles<Theme>((theme) => ({
  statusExplanationRoot: {
    margin: theme.spacing(2, 0, 0),
    padding: theme.spacing(2),
    borderRadius: 12,
    backgroundColor: "#F7F8FC",
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 4.5),
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    lineHeight: 1.6,
  },
  collapse: {
    paddingTop: theme.spacing(1),
  },
  explanation: {
    marginBottom: theme.spacing(1),
    fontStyle: "italic",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.6,
  },
  paid: { color: "#548510" },
  review: { color: theme.palette.grey["A400"] },
  overdue: { color: "#AE0400" },
  pending: { color: "#D3AE00" },
}));

export default useStatusExplanationStyles;
