import { useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import { Box, Button, Checkbox, Grid, Typography } from "@material-ui/core";
import { IoHourglassOutline as TimeIcon } from "@houm-com/ui-icons";
import { useLoiForm } from "../context/LoiFormContext";

import CheckIcon from "@material-ui/icons/Check";
import useOwnerStyles from "./OwnersStyles";
import useStepsStyles from "./StepsStyles";
import CallToAction from "Components/Admin/UIComponents/atoms/CallToAction";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

const Entities = () => {
  const [checked, setChecked] = useState(false);
  const rootClasses = useStepsStyles();
  const classes = useOwnerStyles();

  const {
    state: { loiOwners, activeStep },
    actions: { setAddingSecondaryOwner, setActiveStep },
    handlers: { submitLoiToReview, resetFormData, refreshLoiOwners },
  } = useLoiForm();

  const { mainOwner, secondaryOwners } = useMemo(() => {
    const mainOwner = loiOwners.find((bidder) => bidder.is_main_user);
    const secondaryOwners = loiOwners.filter((bidder) => !bidder.is_main_user);
    return { mainOwner, secondaryOwners };
  }, [loiOwners]);

  const handleAddSecondaryOwner = () => {
    setAddingSecondaryOwner(true);
    setActiveStep(activeStep + 1);
    resetFormData();
  };

  useEffect(() => {
    refreshLoiOwners();
  }, []);

  const ownerDataStatusIcon = (completed: boolean) =>
    completed ? (
      <CheckIcon
        className={classnames(
          classes.flagStatusIcon,
          classes.flagStatusValidated
        )}
      />
    ) : (
      <TimeIcon
        className={classnames(
          classes.flagStatusIcon,
          classes.flagStatusPending
        )}
      />
    );

  const ownerDataStatusMessage = (completed: boolean) =>
    completed ? "Información completa" : "Información pendiente";

  const hasSecondaryOwners = secondaryOwners.length > 0;

  return (
    <Grid container className={rootClasses.rootGrid}>
      <Grid item>
        <Typography className={rootClasses.title}>Propietarios</Typography>
        <Typography className={rootClasses.subtitle}>
          Estos son los propietarios registrados para generar la promesa de
          compra. Puedes agregar o eliminar a compradores secundarios si lo
          necesitas.
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.ownerTitle}>
          Propietario principal
        </Typography>
        {mainOwner && (
          <Box className={classes.ownerResume}>
            <Typography className={classes.ownerTitle}>
              {`${mainOwner.name} ${mainOwner.last_name || ""} ${
                mainOwner.second_last_name || ""
              }`}
            </Typography>

            <Typography
              className={classnames(classes.status, {
                [classes.flagStatusPending]: !mainOwner.manually_reviewed,
                [classes.flagStatusValidated]: mainOwner.manually_reviewed,
              })}
            >
              {ownerDataStatusIcon(mainOwner.manually_reviewed)}
              {ownerDataStatusMessage(mainOwner.manually_reviewed)}
            </Typography>
          </Box>
        )}
      </Grid>
      {hasSecondaryOwners && (
        <Grid item>
          <Typography className={classes.ownerTitle}>
            Propietarios secundarios
          </Typography>
          {secondaryOwners.map((owner) => (
            <Box className={classes.ownerResume}>
              <Typography className={classes.ownerTitle}>
                {`${owner.name} ${owner.last_name || ""} ${
                  owner.second_last_name || ""
                }`}
              </Typography>

              <Typography
                className={classnames(classes.status, {
                  [classes.flagStatusPending]: !owner.manually_reviewed,
                  [classes.flagStatusValidated]: owner.manually_reviewed,
                })}
              >
                {ownerDataStatusIcon(owner.manually_reviewed)}
                {ownerDataStatusMessage(owner.manually_reviewed)}
              </Typography>
            </Box>
          ))}
        </Grid>
      )}
      <Grid item>
        <Box display="flex" justifyContent="center">
          <Button
            fullWidth={true}
            variant="outlined"
            onClick={handleAddSecondaryOwner}
            className={classes.nextStepButton}
          >
            Agregar propietario
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <Box>
          <Checkbox
            color="primary"
            checked={checked}
            className={classes.checkbox}
            onChange={(e) => {
              setChecked(e.target.checked);
            }}
          />
          <Typography className={classes.terms}>
            {"Declaro que he leído y acepto los "}
            <Typography className={classes.termsDetails}>
              términos y condiciones.
            </Typography>
            {" y las "}
            <Typography className={classes.termsDetails}>
              políticas de seguridad
            </Typography>
            {" al compartir mi información y documentos."}
          </Typography>
        </Box>
      </Grid>
      <CallToAction>
        <DesignSystemButton
          label="Continuar"
          size="large"
          disabled={!checked}
          variant="primary"
          onClick={submitLoiToReview}
          fullWidth
        />
      </CallToAction>
    </Grid>
  );
};

export default Entities;
