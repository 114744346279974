import { useFeatureManager } from "@houm-com/feature-manager";
import { useQuery, UseQueryResult } from "react-query";

import newPaymentService from "services/newPaymentService";

interface IMethods {
  method: string;
  subtotal: number;
  methodCommission: number;
  total: number;
}

const usePayUMethods = (
  movementId: string,
  savePayUMethods?: (e: IMethods[]) => void
) => {
  const {
    status,
    data: dataHideBankTransfers,
    isLoading: isLoadingFeatureManager,
  } = useFeatureManager("payU_hide_bank_transfers");
  const hideBankTransferFlag =
    status === "success" && dataHideBankTransfers?.enabled;
  const {
    isLoading,
    isError,
    data = [],
  }: UseQueryResult<IMethods[], Error> = useQuery(
    ["pay_u_methods", hideBankTransferFlag],
    async () => {
      const response = await newPaymentService.getPayUMethods(movementId);
      const parsedData = response.data.map((method) => ({
        method: method.method,
        subtotal: method.subtotal,
        methodCommission: method.method_fee,
        total: method.total,
      }));
      return hideBankTransferFlag
        ? parsedData.filter((data) => data.method !== "BANK_ACCOUNT")
        : parsedData;
    },
    {
      enabled: !!movementId,
      onSuccess: (e) => savePayUMethods && savePayUMethods(e),
    }
  );

  return {
    isLoading: isLoadingFeatureManager || isLoading,
    isError,
    data,
  };
};

export default usePayUMethods;
