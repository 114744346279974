import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useGetToken } from "hooks";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { useLoiForm } from "context/loiFormContext/loiFormContext";
import {
  fetchData,
  submitData,
  setNavText,
  setAvailableSteps,
} from "context/loiFormContext/loiFormActions";
import { COUNTRY_STEPS } from "context/loiFormContext/constants";
import LoiFormProgress from "./components/LoiFormProgress";
import LandlordDataStep from "./LandlordDataStep";
import LandlordDocsStep from "./LandlordDocsStep";
import LandlordBankDataStep from "./LandlordBankDataStep";
import PropertyDocsStep from "./PropertyDocsStep";
import BrokerageContractStep from "./BrokerageContractStep";
import SummaryLoiStep from "./SummaryLoiStep";
import useStyles from "./LoiFormStyles";

export default function LoiForm() {
  const { state, dispatch } = useLoiForm();
  const [stepTitle, setStepTitle] = useState<string>("");
  const { id } = useParams<{ id: string }>();
  const { getToken } = useGetToken();

  useEffect(() => {
    getToken().then((authToken) => fetchData(id, authToken, dispatch));
  }, []);

  useEffect(() => {
    if (state.country && COUNTRY_STEPS[state.country]) {
      dispatch(setAvailableSteps(COUNTRY_STEPS[state.country]));
    }
  }, [state.country]);

  const currentStep = () => {
    switch (state.step) {
      case "landlordDocs":
        return <LandlordDocsStep />;
      case "landlordBankData":
        return <LandlordBankDataStep />;
      case "propertyDocs":
        return <PropertyDocsStep />;
      case "brokerageContract":
        return <BrokerageContractStep />;
      case "summaryLoi":
        return <SummaryLoiStep />;
      case "landlordData":
      default:
        return <LandlordDataStep />;
    }
  };

  useEffect(() => {
    // Define step title
    switch (state.step) {
      case "landlordDocs":
        setStepTitle("Personales");
        break;
      case "summaryLoi":
        setStepTitle("Resumen");
        break;
      case "propertyDocs":
        setStepTitle("Propiedad");
        break;
      case "brokerageContract":
        setStepTitle("Propiedad");
        break;
      case "landlordBankData":
        setStepTitle("Datos bancarios");
        break;
      case "landlordData":
      default:
        setStepTitle("Datos personales");
        break;
    }

    // Define navbar button
    const isLastStep =
      state.availableSteps.indexOf(state.step) ===
      state.availableSteps.length - 1;
    if (isLastStep) dispatch(setNavText("Enviar"));
    else dispatch(setNavText("Continuar"));
  }, [state.step]);

  useEffect(() => {
    if (state.readyToSubmit)
      getToken().then((authToken) => submitData(authToken, state, dispatch));
  }, [state.readyToSubmit]);

  const classes = useStyles();

  return (
    <Loading loading={state.loading} className={classes.spinner}>
      <div className={classes.root}>
        <div className={classes.form}>
          <LoiFormProgress
            step={state.availableSteps.indexOf(state.step) + 1}
            totalSteps={state.availableSteps.length}
            stepTitle={stepTitle}
          />
          {currentStep()}
        </div>
      </div>
    </Loading>
  );
}
