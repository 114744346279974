import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const usePaymentDetailsStyles = makeStyles<Theme>((theme) => ({
  paymentContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      maxWidth: 340,
    },
  },
  paymentBox: {
    marginBottom: theme.spacing(1),
    width: "100%",
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(1, 0, 2),
    backgroundColor: theme.palette.grey[75],
    borderRadius: 8,
    padding: theme.spacing(1, 0),
  },
  payment: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    lineHeight: 1.5,
  },
  limitDateContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  limitDateText: {
    color: "#4C6977",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(22),
    },
  },
  date: {
    color: "#4C6977",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(22),
    },
  },
}));

export default usePaymentDetailsStyles;
