import { makeStyles, Theme } from "@material-ui/core";

const useInspectionReportsStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2.5),
    },
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
  },
  observationContainer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2.5),
    },
  },
  disclaimerContainer: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 0),
    },
  },
  observationTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  observationText: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default useInspectionReportsStyles;
