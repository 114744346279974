import { SnackbarProvider } from "notistack";
import CreateView from "./components/CreateView/index";

const AccountCreate = () => (
  <SnackbarProvider>
    <CreateView />
  </SnackbarProvider>
);

export default AccountCreate;
