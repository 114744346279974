import { Button, Grid, Typography } from "@material-ui/core";
import classNames from "classnames";
import useAdsBannerStyles from "./AdsBannerStyles";
import { IoChevronForward as ChevronRightIcon } from "@houm-com/ui-icons";

const HighlightedText = ({ children, className = "" }) => {
  const classes = useAdsBannerStyles({
    img: null,
  });
  return (
    <Typography
      component="span"
      className={classNames(classes.labelTextContent, className)}
    >
      {children}
    </Typography>
  );
};

const Text = ({ children, className = "" }) => {
  const classes = useAdsBannerStyles({
    img: null,
  });
  return (
    <Typography
      component="span"
      className={classNames(classes.mainText, className)}
    >
      {children}
    </Typography>
  );
};

const ButtonLink = ({
  label,
  onClick = () => {},
  isMobile = false,
  className = "",
}) => {
  const classes = useAdsBannerStyles({
    img: null,
  });

  return isMobile ? (
    <div className={classes.smallButton} onClick={onClick}>
      <ChevronRightIcon />
    </div>
  ) : (
    <Button
      className={classNames(classes.adsBannerButton, className)}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

const AdsBanner = ({
  children,
  bgImage,
  isMobile = false,
  onClick = () => {},
  className = "",
  hasImgGradient = false,
}) => {
  const classes = useAdsBannerStyles({ img: bgImage, hasImgGradient });
  const adsBannerClassNames = classNames(classes.cardContent, className, {
    [classes.small]: isMobile,
  });
  return (
    <Grid item className={adsBannerClassNames} onClick={onClick}>
      {children}
    </Grid>
  );
};

AdsBanner.HighlightedText = HighlightedText;
AdsBanner.Button = ButtonLink;
AdsBanner.Text = Text;

export default AdsBanner;
