import Loading from "Components/Admin/UIComponents/molecules/Loading";
import CustomLoiHeader from "./components/CustomHeader";
import { useLoiForm } from "./context/LoiFormContext";
import { getFormStepsCollection } from "./schema/steps";
import FinishedProcess from "./steps/FinishedProcess";
import DepLoiForm from "Components/Admin/landlordAdmin/pages/LoiForm";

export default function LoiForm() {
  const {
    state: {
      activeStep,
      country,
      finishedProcess,
      loiOwners,
      completedFirstForm,
    },
    actions: { setActiveStep },
    handlers: { goToPropertyDetail },
  } = useLoiForm();
  const shouldDisplayOwners = loiOwners.length > 1 || completedFirstForm;
  const formSteps = getFormStepsCollection(shouldDisplayOwners, country);

  const shouldDisplayLoading = !loiOwners.length;

  const CurrentStep = formSteps[activeStep];

  const handleClickBack = () =>
    activeStep === 0 ? goToPropertyDetail() : setActiveStep(activeStep - 1);

  if (country !== "Chile") {
    return <DepLoiForm />;
  }

  return (
    <Loading loading={shouldDisplayLoading}>
      <>
        <CustomLoiHeader handleBackButton={handleClickBack} />
        {finishedProcess && <FinishedProcess />}
        {!finishedProcess && <CurrentStep />}
      </>
    </Loading>
  );
}
