import { makeStyles, Theme } from "@material-ui/core";

import { MOBILE_BOTTOM_NAVBAR_HEIGHT } from "apps/landlord/constants/layout";

const MOBILE_BOTTOM_NAVBAR_HEIGHT_PX = MOBILE_BOTTOM_NAVBAR_HEIGHT * 8;

interface Props {
  navbarHeight: number;
}

const useSummaryStyles = makeStyles<Theme, Props>((theme) => ({
  container: {
    height: "100%",
  },
  summaryRoot: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
      position: "relative",
      padding: theme.spacing(5),
    },
  },
  leftContainer: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      top: ({ navbarHeight }) => `calc(${navbarHeight}px + 40px)`,
      gap: theme.spacing(2),
      height: `100%`,
      flexDirection: "column",
      position: "sticky",
      width: "45%",
    },
  },
  rightContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 2, 2, 2),
    [theme.breakpoints.up("sm")]: {
      width: "55%",
      padding: theme.spacing(0),
    },
  },
  buttonContainer: {
    textAlign: "end",
  },
  loadingContainer: {
    height: ({ navbarHeight }) =>
      `calc(100vh - ${navbarHeight}px - ${MOBILE_BOTTOM_NAVBAR_HEIGHT_PX}px)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      height: ({ navbarHeight }) => `calc(100vh - ${navbarHeight}px)`,
    },
  },
}));

export default useSummaryStyles;
