import { makeStyles, Theme } from "@material-ui/core";

const useDesktopMenuStyles = makeStyles((theme: Theme) => ({
  desktopMenuRoot: {
    display: "flex",
    flexDirection: "row",
  },
}));

export default useDesktopMenuStyles;
