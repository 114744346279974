import { useMutation } from "react-query";

import { useHoumSnackBar } from "libs/houm-snackbar";

import newPaymentService from "services/newPaymentService";

type IMethodOpenPayType = "card" | "bank" | "store";

const useOpenPayLink = (onClose?: () => void) => {
  const { enqueueHoumSnackBar } = useHoumSnackBar();

  const { mutate, isLoading, isError } = useMutation(
    async ({ movementId, option }: { movementId: string; option: string }) => {
      const response = await newPaymentService.getOpenpayLink(
        "mx",
        movementId,
        option as IMethodOpenPayType
      );
      const { payment_link } = response.data;
      window.location.href = payment_link;
    },
    {
      onError: () => {
        enqueueHoumSnackBar({
          head: "",
          body: "No se pudo obtener el link de pago. Por favor intente nuevamente!",
          type: "error",
        });
      },
      onSuccess: () => onClose?.(),
    }
  );

  return {
    isLoading,
    isError,
    mutate,
  };
};

export default useOpenPayLink;
