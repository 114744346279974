import {
  IBillDetails,
  IBillInfo,
} from "apps/landlord/services/propertiesDuesService/type";

import Tag from "../Tag";
import CardSubtitle from "../CardSubtitle";
import classes from "./CardHeadStyles";

type CardHeadProps = {
  billStatus: string;
  item: Partial<IBillInfo>;
  detail: Partial<IBillDetails>;
  defaultTitle: string;
};

const CardHead = ({
  billStatus,
  item,
  detail,
  defaultTitle,
}: CardHeadProps) => (
  <div className={classes.cardHeadContainer}>
    <div>
      <h1 className={classes.cardTitle}>{item?.companyName ?? defaultTitle}</h1>
      <CardSubtitle detail={detail} billStatus={billStatus} />
    </div>
    <Tag billDetails={detail} />
  </div>
);

export default CardHead;
