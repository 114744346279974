import { makeStyles, Theme } from "@material-ui/core";

const useAccountInfoStyles = makeStyles<Theme>((theme) => ({
  accountInfoContainer: {
    gap: theme.spacing(2),
    display: "grid",
    width: "100%",
  },
}));

export default useAccountInfoStyles;
