import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { isMobile } from "react-device-detect";
import { yupResolver } from "@hookform/resolvers/yup";

import Swal from "sweetalert2";
import { cn } from "@houm-com/ui/utils";
import FieldSelect from "@houm-com/ui/FieldSelect";
import Button from "@houm-com/ui/Button";

import useCompanies from "apps/landlord/hooks/propertyBills/useCompanies";
import { CECompanyForm } from "apps/landlord/models/ServicesCompanies";
import useChangeCommonExpenses from "apps/landlord/hooks/propertyBills/useChangeCommonExpenses";
import useBillsInfo from "apps/landlord/hooks/propertyBills/useBillInfo";
import Spinner from "Components/Shared/partials/Spinner";

import classes from "./CommonExpensesFormStyles";
import formSchema from "./validationSchema";
import CompanyFields from "./components/CompanyFields";

interface FormProps {
  onAfterSubmit?: () => void;
  className?: string;
}

export default function CommonExpensesForm({
  onAfterSubmit,
  className = "",
}: FormProps) {
  const { isLoading, error, commonExpensesCompaniesOptions } = useCompanies();
  const {
    data: billsData,
    isLoading: billsLoading,
    error: billsError,
  } = useBillsInfo(["common_expenses"]);
  const { mutate, submitting, isSuccess } = useChangeCommonExpenses();
  const methods = useForm<CECompanyForm>({
    resolver: yupResolver(formSchema),
    reValidateMode: "onSubmit",
  });
  const companyName = methods.watch("companyName");
  useEffect(() => {
    const clientId = billsData?.common_expenses?.clientInfo?.externalId;
    if (clientId || clientId === "") {
      methods.setValue("companyName", billsData?.common_expenses?.company);
    }
  }, [billsData?.common_expenses?.clientInfo?.externalId]);
  if (isLoading || billsLoading) return <Spinner />;
  if (error || billsError) {
    Swal.fire(
      "Ha ocurrido un error",
      `Actualice la página o contacte a su ejecutivo/a. ${error || billsError}`,
      "error"
    );
    return null;
  }
  const onSubmit: SubmitHandler<CECompanyForm> = async (formData) => {
    mutate(formData);
    onAfterSubmit?.();
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={cn(classes.commonExpensesForm, className)}
      >
        <FieldSelect
          id="companyName"
          name="companyName"
          label="Empresa que gestiona tus gastos comunes"
          placeholder="Seleccione una empresa"
          options={commonExpensesCompaniesOptions}
          {...methods.register("companyName")}
        />
        <CompanyFields
          companyName={companyName}
          clientInfo={billsData?.common_expenses?.clientInfo}
          administrationEmail={billsData?.common_expenses?.administrationEmail}
        />
        <Button
          type="submit"
          variant="primary"
          isLoading={submitting}
          disabled={!companyName || isSuccess}
          size={isMobile ? "sm" : "md"}
        >
          Guardar
        </Button>
      </form>
    </FormProvider>
  );
}
