import { makeStyles, Theme } from "@material-ui/core";

const useFormattedTranslationStyles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export default useFormattedTranslationStyles;
