import { Box, Typography } from "@material-ui/core";

import CustomFileDropzone from "apps/components/CustomFileDropzone";
import { IServicesRequest } from "apps/tenant/pages/ServicesRequestPage/hooks/types";

import usePhotosSectionStyles from "./PhotosSectionStyles";

interface Props {
  servicesRequest: IServicesRequest;
  setServicesRequest: (value: IServicesRequest) => void;
}

const PhotosSection = ({ servicesRequest, setServicesRequest }: Props) => {
  const { debtType } = servicesRequest;
  const showGGCCPhotos = debtType?.includes("ggcc");
  const showSSBBPhotos = debtType?.includes("ssbb");
  const showLabel = showGGCCPhotos && showSSBBPhotos;
  const classes = usePhotosSectionStyles();
  return (
    <Box>
      <Typography className={classes.label}>
        Fotos de comprobantes de pagos y boletas
      </Typography>
      <Typography className={classes.labelDescription}>
        Incluye las fotos de cada pago realizado y cada boleta que recibiste.
      </Typography>
      <Box className={classes.inputsContainer}>
        {showSSBBPhotos && (
          <CustomFileDropzone
            id="files"
            onChange={(files) =>
              setServicesRequest({
                ...servicesRequest,
                ggccPhotos: files as File[],
              })
            }
            label={showLabel ? "Servicios Básicos" : null}
            maxFiles={10}
          />
        )}
        {showGGCCPhotos && (
          <CustomFileDropzone
            id="files"
            onChange={(files) =>
              setServicesRequest({
                ...servicesRequest,
                ggccPhotos: files as File[],
              })
            }
            label={showLabel ? "Gastos Comunes" : null}
            maxFiles={10}
          />
        )}
      </Box>
    </Box>
  );
};

export default PhotosSection;
