import { makeStyles, Theme } from "@material-ui/core/styles";

const useFormCreateAccountStyles = makeStyles<Theme>((theme: Theme) => ({
  text: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: "1.25em",
    color: theme.palette.grey[900],
    margin: theme.spacing(3, 0, 4),
    "& span": {
      color: theme.palette.primary.main,
    },
  },
  login: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "0.875rem",
    lineHeight: "1.375em",
    color: theme.palette.grey[900],
    textAlign: "center",
    marginTop: theme.spacing(3),
    "& a": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  label: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: "1.5em",
    color: theme.palette.grey[900],
    marginTop: theme.spacing(1),
    "& span": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  input: {
    background: theme.palette.grey[50],
    borderRadius: 4,
    padding: theme.spacing(0.5, 1),
    "& input": {
      padding: theme.spacing(0),
      color: "#A8ADAF",
      "&::placeholder": {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: "1.5em",
        color: "#A8ADAF",
      },
    },
  },
  phoneInput: {
    "& label div div": {
      background: theme.palette.grey[50],
      borderRadius: 4,
    },
  },
  button: {
    width: "100%",
  },
  error: {
    margin: 0,
    fontSize: "0.75rem",
    marginLeft: theme.spacing(0.7),
    textAlign: "left",
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "1.25rem",
    lineHeight: "1.75em",
    textAlign: "center",
    color: theme.palette.grey[900],
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 0, 0),
    },
  },
  boxCountry: {
    width: 72,
    height: 56,
    border: "1px solid #A0B1B9",
    borderRadius: 8,
    display: "block",
    textAlign: "center",
    paddingTop: theme.spacing(1),
  },
  countryName: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.375em",
    color: theme.palette.grey[900],
  },
  countriesBox: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(0.5, 0, 1, 0),
  },
  countryLabel: {
    marginLeft: theme.spacing(1),
  },
  labelError: {
    fontSize: "0.75rem",
    margin: theme.spacing(0.4, 0, 0, 1),
    textAlign: "left",
    fontWeight: 400,
    lineHeight: "1.66em",
    color: theme.palette.primary.main,
  },
}));

export default useFormCreateAccountStyles;
