import { makeStyles, Theme } from "@material-ui/core/";

import { MOBILE_BOTTOM_NAVBAR_HEIGHT } from "../../constants/layout";

interface Props {
  navbarHeight: number;
}

const useSummaryLayoutStyles = makeStyles<Theme, Props>((theme) => ({
  summaryRoot: {
    maxWidth: 1280,
    minHeight: ({ navbarHeight }) => `calc(100vh - ${navbarHeight}px)`,
    backgroundColor: theme.palette.grey[50],
    margin: ({ navbarHeight }) => theme.spacing(navbarHeight / 8, "auto", 0),
    paddingBottom: theme.spacing(MOBILE_BOTTOM_NAVBAR_HEIGHT),
    [theme.breakpoints.up("sm")]: {
      paddingBottom: theme.spacing(0),
      margin: ({ navbarHeight }) => theme.spacing(navbarHeight / 8, "auto", 0),
      minHeight: ({ navbarHeight }) => `calc(100vh - ${navbarHeight}px)`,
    },
  },
}));

export default useSummaryLayoutStyles;
