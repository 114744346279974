import { Typography } from "@material-ui/core";

import { getMonthDebtText } from "apps/landlord/pages/Property/utils/utils";
import { Locale } from "models/Countries";

import useDefaulterGuaranteedWarningStyles from "./DefaulterGuaranteedWarningStyles";
import DisclaimerRentalInfo from "../DisclaimerRentalInfo";

interface Props {
  country: Locale;
  lesseeMonthSinceDebt: Date;
}

const DefaulterGuaranteedWarning = ({
  country,
  lesseeMonthSinceDebt,
}: Props) => {
  const classes = useDefaulterGuaranteedWarningStyles();

  return (
    <DisclaimerRentalInfo className={classes.container} country={country}>
      <Typography component="span" className={classes.text}>
        Debido a que tu arrendatario no ha pagado el arriendo
        {getMonthDebtText(lesseeMonthSinceDebt)}, hemos comenzado las acciones
        de desalojo ya que este es el último mes en el que tu plan tiene
        cobertura.
      </Typography>
      <Typography component="span" className={classes.textSecondary}>
        Te mantendremos informado sobre el estado de avance de estas gestiones y
        los pasos a seguir
      </Typography>
    </DisclaimerRentalInfo>
  );
};

export default DefaulterGuaranteedWarning;
