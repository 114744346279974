import { useEffect, useState } from "react";
import Fade from "@material-ui/core/Fade";

import PhotoPlaceholder from "assets/images/vacant_image.png";
import useStyles from "./PhotosStyles";

interface Props {
  photos: { filename?: string }[];
}

export default function PropertyPhotos({ photos }: Props) {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const carouselInterval = 4000;
  const fadeTimeout = 200;

  const photoUrl = (photo) => {
    try {
      return `https://s3.amazonaws.com/properties-media/thumbnail/${photo.property.uid}${photo.filename}`;
    } catch {
      return "";
    }
  };

  const changePhoto = (direction?) => {
    setFade(false);
    setTimeout(() => {
      let newIndex;
      switch (direction) {
        case "previous":
          if (photoIndex === 0) {
            newIndex = photos.length - 1;
          } else {
            newIndex = photoIndex - 1;
          }
          break;
        default:
          if (photoIndex + 1 === photos.length) {
            newIndex = 0;
          } else {
            newIndex = photoIndex + 1;
          }
          break;
      }
      setPhotoIndex(newIndex);
      setFade(true);
    }, fadeTimeout);
  };

  useEffect(() => {
    setTimeout(() => changePhoto("next"), carouselInterval);
  }, [photoIndex]);

  const classes = useStyles();
  return (
    <div className={classes.propertyImageContainer}>
      {photos[photoIndex] ? (
        <Fade in={fade} timeout={fadeTimeout} mountOnEnter unmountOnExit>
          <img
            src={photoUrl(photos[photoIndex])}
            // key={photos[photoIndex].url}
            alt="property"
            className={classes.image}
          />
        </Fade>
      ) : (
        <img
          src={PhotoPlaceholder}
          className={classes.placeholderImage}
          alt="placeholder"
        />
      )}
    </div>
  );
}
