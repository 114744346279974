import { Country } from "models/PhoneInput";

import useCountryItemStyles from "./CountryItemStyle";

interface Props {
  country: Country;
  selected?: boolean;
  onClick: (country: Country) => void;
}
const CountryItem = ({ country, selected, onClick }: Props) => {
  const classes = useCountryItemStyles({ selected });
  return (
    <div className={classes.root} onClick={() => onClick(country)}>
      <div className={classes.svgFlagNameContainer}>
        <img
          alt={country?.countryName}
          src={`https://hatscripts.github.io/circle-flags/flags/${country?.countryCode.toLowerCase()}.svg`}
          width="38"
        />
        <div className={classes.nameContainer}>{country?.countryName}</div>
      </div>
      <div className={classes.numberCode}> +{country?.callingCode}</div>
    </div>
  );
};

export default CountryItem;
