import { AxiosError } from "axios";
import { useHoumSnackBar } from "libs/houm-snackbar";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import bankAccountsService from "apps/tenant/services/bankAccountsService";
import { useGetToken } from "hooks";
import { Locale } from "models/Countries";

import { BankAccountFormData, BankAccountFormDataMX } from "../utils/types";
import { parseBankAccount } from "../utils/parsers";

interface MutationProps {
  formData: BankAccountFormData | BankAccountFormDataMX;
  country: Locale;
  userId: number;
  propertyId?: number;
  edit?: boolean;
}

const useCreateBankAccount = () => {
  const { getToken } = useGetToken();
  const history = useHistory();
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const queryClient = useQueryClient();

  const successfulSubmission = () => {
    enqueueHoumSnackBar({
      head: "Actualizamos tu información de forma exitosa",
      body: "",
      type: "success",
    });
    queryClient.invalidateQueries("userBankAccounts");
    history.push("/tenant");
  };

  const failedSubmission = () => {
    enqueueHoumSnackBar({
      head: "No pudimos actualizar tu información bancaria. Por favor intenta de nuevo.",
      body: "",
      type: "error",
    });
  };

  const { isLoading: createLoading, mutate: submitBankAccount } = useMutation(
    async ({ formData, country, userId, propertyId }: MutationProps) => {
      const authToken = await getToken();

      return bankAccountsService.create(
        authToken,
        {
          ...parseBankAccount(formData, country),
          identifier: propertyId.toString(),
        },
        country,
        userId
      );
    },
    {
      onSuccess: successfulSubmission,
      onError: failedSubmission,
    }
  );

  const { isLoading: updateLoading, mutate: updateBankAccount } = useMutation(
    async ({ formData, country, userId, propertyId }: MutationProps) => {
      const authToken = await getToken();
      return bankAccountsService.update(
        authToken,
        parseBankAccount(formData, country),
        country,
        userId,
        propertyId.toString()
      );
    },
    {
      onSuccess: successfulSubmission,
      onError: (e: AxiosError, vars) => {
        if (e.response?.status === 404) {
          submitBankAccount(vars);
        } else {
          failedSubmission();
        }
      },
    }
  );

  return {
    submitBankAccount,
    updateBankAccount,
    isLoading: createLoading || updateLoading,
  };
};

export default useCreateBankAccount;
