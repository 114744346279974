import clx from "classnames";
import { usePDF } from "@react-pdf/renderer";

import { Box, Grid, Typography } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";

import InspectionReportDocument from "Components/Shared/InspectionReportDocument";
import { Inspection } from "hooks/inspectionReports/types";
import { getResizeImageURL } from "Components/Shared/InspectionReportDocument/utils";
import useInspectionReportCardStyles from "./InspectionReportCardStyles";
import { getDate, getRating } from "./utils";

interface Props {
  inspectionReport: Inspection;
  propertyAddress: {
    address: string;
    region: string;
  };
}

export default function InspectionReportCard({
  inspectionReport,
  propertyAddress,
}: Props) {
  const classes = useInspectionReportCardStyles();
  const imageURL = inspectionReport.spaceQualifications[0]?.photos[0];
  const [document] = usePDF({
    document: InspectionReportDocument({
      inspectionReport,
      propertyData: propertyAddress,
    }),
  });

  return (
    <Grid
      onClick={() => {
        if (!document.loading && document.url) {
          window.open(document.url, "_blank");
        }
      }}
      item
      container
      className={clx(classes.inspectionReportCardContainer, {
        [classes.inspectionReportCardContainerLoading]:
          document.loading || !document.url,
      })}
    >
      <img
        className={classes.inspectionReportImage}
        src={getResizeImageURL(imageURL)}
        alt={`foto de la inspección del día ${inspectionReport.createdAt}`}
      />
      <Box className={classes.inspectionReportGradient} />
      <Box className={classes.inspectionReportQualification}>
        <Typography className={classes.inspectionReportQualificationText}>
          <span role="img" aria-label="estrella">
            ⭐
          </span>
          {getRating(inspectionReport)}
        </Typography>
      </Box>
      <Box className={classes.inspectionReportDateContainer}>
        <Typography className={classes.inspectionReportDate}>
          {getDate(inspectionReport)}
        </Typography>
        {!document.loading && (
          <Box className={classes.inspectionReportButton}>
            <ArrowForward className={classes.inspectionReportIcon} />
          </Box>
        )}
      </Box>
    </Grid>
  );
}
