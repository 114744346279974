import { makeStyles, Theme } from "@material-ui/core";

const useInspectionTableStyles = makeStyles<Theme>((theme) => ({
  spaceField: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
  },
  observationField: {
    fontSize: theme.typography.pxToRem(12),
  },
}));

export default useInspectionTableStyles;
