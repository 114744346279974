import { Typography } from "@material-ui/core";
import { TranslationObject } from "apps/ui/Navbar/utils/types";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  label: string | TranslationObject;
  onClick?: () => void;
}

const NavbarLabel = ({ label, ...props }: Props) => {
  if (typeof label === "string")
    return <Typography {...props}>{label}</Typography>;

  const { t } = useTranslation(label.namespace);
  return <Typography {...props}>{t(label.key)}</Typography>;
};

export default NavbarLabel;
