import {
  Action,
  ActionType,
  INITIAL_ID,
  State,
  Steps,
} from "./proprietaryFormTypes";

export const initialState: State = {
  propertyData: {
    additionalInfo: "",
    propertyNumber: "",
    deliveryDate: "",
    district: "",
    propertyType: "flat",
    service: "rent",
  },
  step: Steps.PROPERTY_DETAILS_STEP,
  infoModal: {
    title: "Lamentamos las molestias",
    subtitle: "",
    secondSubtitle: "",
    buttonText: "Salir",
    open: false,
  },
  isOpenHelpModal: false,
  user: {
    name: "",
    country: "Chile",
    id: INITIAL_ID,
  },
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.CLOSE_INFO_MODAL:
      return { ...state, infoModal: { ...state.infoModal, open: false } };
    case ActionType.SET_PROPERTY_DATA:
      return {
        ...state,
        propertyData: { ...state.propertyData, ...action.propertyData },
      };
    case ActionType.SET_STEP:
      return { ...state, step: action.step };
    case ActionType.SET_INFO_MODAL:
      return {
        ...state,
        infoModal: action.infoModal,
      };
    case ActionType.SET_IS_OPEN_HELP_MODAL:
      return { ...state, isOpenHelpModal: action.open };
    case ActionType.SET_USER:
      return { ...state, user: action.user };
    default:
      return state;
  }
};

export default apiReducer;
