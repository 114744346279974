import { format } from "date-fns";
import { IVisit } from "apps/tenant/models/Visit";

export const filterVisits = (visits?: IVisit[], selectedDate?: Date) =>
  selectedDate
    ? visits?.filter(
        (visit) =>
          format(visit.date, "dd/MM/yyyy") ===
          format(selectedDate, "dd/MM/yyyy")
      )
    : visits;
