import usePasswordRequirementsStyles from "./PasswordRequirementsStyles";
import RequirementItem from "../RequirementItem";
import usePasswordRequirements from "./hooks/usePasswordRequirements";

interface Props {
  email: string;
  password?: string;
}

const PasswordRequirements = ({ email, password }: Props) => {
  const classes = usePasswordRequirementsStyles();
  const {
    lengthValidation,
    caseValidation,
    numberValidation,
    emailValidation,
  } = usePasswordRequirements({
    password,
    email,
  });

  return (
    <ul className={classes.passwordRequirementsRoot}>
      <RequirementItem
        requirement="Mínimo 8 caracteres de largo"
        isValid={lengthValidation}
      />
      <RequirementItem
        requirement="No puede contener tu email"
        isValid={emailValidation}
      />
      <RequirementItem
        requirement="Debe incluir mínimo una mayúscula y una minúscula"
        isValid={caseValidation}
      />
      <RequirementItem
        requirement="Debe incluir mínimo un número"
        isValid={numberValidation}
      />
    </ul>
  );
};

export default PasswordRequirements;
