import { numberWithCommas, capitalizeString } from "utils";
import { monthsNames } from "./AppointmentsConstants";
import { IAppointment, IResponse } from "./AppointmentsListItem";

const dateFormat = (dateString: string): string => {
  const date = new Date(dateString);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "pm" : "am";
  const formatedHours = hours % 12 === 0 ? 12 : hours % 12;
  const dateTimeFormated = `${date.getDate()} de ${
    monthsNames[date.getMonth()]
  }, ${formatedHours}:${minutes} ${ampm}`;
  return dateTimeFormated;
};

const getPropertyTitle = (typeProperty: string, commune: string): string =>
  `${capitalizeString(typeProperty)} en ${commune}`;

export const parseVisitData = (visits: IResponse[]): IAppointment[] =>
  visits.map((visit) => ({
    appraisersId: visit.appraisers_id,
    appraisersName: visit.appraisers_name,
    commune: visit.commune,
    index: visit.index,
    city: visit.property_city,
    country: visit.property_country,
    fullUbication: `${visit.property_city}, ${visit.property_country}`,
    propertyId: visit.property_id,
    propertyPhotoTitle: visit.property_photos_filename,
    propertyPhotoURL: visit.property_photos_url,
    propertyRegion: visit.property_region,
    propertyRentalPrice: `$${numberWithCommas(visit.property_rental_price)}`,
    propertySalePrice: `$${numberWithCommas(visit.property_sale_price)}`,
    propertyUID: visit.property_uid,
    scheduleId: visit.schedule_id,
    sharedCost: `$${numberWithCommas(visit.shared_cost)}`,
    visitAddress: visit.visit_address,
    visitDateTime: dateFormat(visit.visit_date_time),
    visitStatus: visit.visit_state,
    propertyType: visit.property_type,
    isRental: visit.property_for_rental,
    isSale: visit.property_for_sale,
    propertyTitle: getPropertyTitle(visit.property_type, visit.commune),
  }));
