import { format } from "date-fns";

import { IPaymentDetailByMovementId } from "apps/landlord/services/paymentService/types";

export const parsePaymentDetail = (data: IPaymentDetailByMovementId) => ({
  id: data.movement_id,
  status: data.status,
  paymentDate: data.date && format(new Date(data.date), "dd/MM/yyyy"),
  totalPrice: data.total_amount,
  rentPrice:
    data?.original_amount_breakdown.find(
      (original) => original.label === "LEASE"
    )?.value ?? data.original_amount,
  planCharges: data.discounts_breakdown?.find(
    (disc) => disc.label === "MANAGEMENT"
  )?.value,
  currency: data.currency,
  discountsBreakdown: data.discounts_breakdown
    ? data.discounts_breakdown?.filter(
        (discount) => discount.label !== "MANAGEMENT"
      )
    : [],
  aditionalsBreakdown: data.additionals_breakdown ?? [],
  promotionsBreakdown:
    data.promotions_breakdown?.filter((promotion) => promotion.value !== 0) ??
    [],
  originalAmountBreakdown: data.original_amount_breakdown
    ? data.original_amount_breakdown.filter(
        (original) => original.label !== "LEASE"
      )
    : [],
  paymentOption: data.plan_type,
  legacyId: data.legacy_id,
  bankAccountInfo: {
    fullName: data.person_data.full_name,
    personId: data.person_data.person_id,
    identifier: data.person_data.identifier,
    bankName: data.person_data.bank_name,
    bankCode: data.person_data.bank_code,
    accountNumber: data.person_data.account_number,
    accountType: data.person_data.account_type,
  },
  readjustment: data.readjustment && {
    totalValue: data.readjustment,
    adjustmentBreakdown: data.readjustments_breakdown?.map((readjustment) => ({
      description: readjustment.description,
      value: readjustment.value,
      label: readjustment.label,
      periods: readjustment.periods?.map((period) => ({
        initialDate: `${period.initial_date}T00:00:00`,
        finalDate: `${period.final_date}T00:00:00`,
        variation: period.variation,
      })),
      identifier: readjustment.identifier,
    })),
  },
});
