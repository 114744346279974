import { Box, Grid, Typography } from "@material-ui/core";

import { IMainPropertyPropertyAnalysis } from "apps/landlord/pages/Price/hooks/useMainProperty/types";
import { ISimilarProperty } from "apps/landlord/pages/Price/hooks/useSimilarProperties/types";
import HomeSvg from "assets/icons/Landlord/home-outline.svg";

import useExternalPhoto from "../../hooks/useExternalPhoto";
import { getPublishedDate } from "../../utils/utils";
import PropertyDetails from "../PropertyDetails";
import useStyles from "./BasicInfoCardStyles";

interface Props {
  isExternal?: boolean;
  property: IMainPropertyPropertyAnalysis | ISimilarProperty;
}

const BasicInfoCard = ({ property, isExternal }: Props) => {
  const classes = useStyles();
  const { getBase64Image, image } = useExternalPhoto();
  const photo0 = property?.photos?.filter((photo) => photo.order === 0)[0];
  const src = photo0?.url;
  const alt = photo0?.filename;
  const rooms = property?.propertyDetails[0]?.bedrooms;
  const bathrooms = property?.propertyDetails[0]?.bathrooms;
  const surface = property?.propertyDetails[0]?.metersSurface;
  const publishDate = getPublishedDate(
    (property as IMainPropertyPropertyAnalysis)?.publicationDate
  );

  if (src) getBase64Image(src);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} className={classes.imageContainer}>
        <Box className={classes.fakeImageContainer}>
          <img src={HomeSvg} alt="HomeSvg" className={classes.fakeImage} />
          {publishDate && (
            <Typography className={classes.publishDate}>
              Publicado: {publishDate}
            </Typography>
          )}
        </Box>
        {!isExternal && (
          <img src={image || src} alt={alt} className={classes.image} />
        )}
      </Grid>
      <Grid item xs={5}>
        <PropertyDetails
          rooms={rooms}
          bathrooms={bathrooms}
          surface={surface}
        />
      </Grid>
    </Grid>
  );
};

export default BasicInfoCard;
