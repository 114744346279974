import { isDesktop } from "react-device-detect";

import { IMovement } from "apps/landlord/models/Movement";

import usePaymentsListStyles from "./PaymentsListStyles";
import DesktopPaymentsTable from "./components/DesktopPaymentsTable";
import parseMovements from "./utils";
import MobilePaymentsTable from "./components/MobilePaymentsTable";

interface Props {
  movements?: IMovement[];
}

const PaymentsList = ({ movements }: Props) => {
  const classes = usePaymentsListStyles();
  const PaymentsTable = isDesktop ? DesktopPaymentsTable : MobilePaymentsTable;

  return (
    <div className={classes.paymentsListRoot}>
      <PaymentsTable movements={parseMovements({ movements })} />
    </div>
  );
};

export default PaymentsList;
