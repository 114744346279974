import { makeStyles, Theme } from "@material-ui/core/";
import {
  DESK_NAVBAR_HEIGHT,
  MOBILE_NAVBAR_HEIGHT,
} from "apps/ui/Navbar/constants";

const useTenantLayoutStyles = makeStyles<Theme>((theme) => ({
  tenantContentRoot: {
    margin: theme.spacing(MOBILE_NAVBAR_HEIGHT / 8, 0, 0),
    width: "100%",
    position: "relative",
    height: `calc(100vh - ${MOBILE_NAVBAR_HEIGHT}px)`,
    [theme.breakpoints.up("sm")]: {
      marginBottom: "unset",
      margin: theme.spacing(DESK_NAVBAR_HEIGHT / 8, 0, 0),
      minHeight: `calc(100vh - ${DESK_NAVBAR_HEIGHT}px)`,
      height: "fit-content",
    },
  },
}));

export default useTenantLayoutStyles;
