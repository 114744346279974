import { makeStyles, Theme } from "@material-ui/core";

interface OptionPropsTypes {
  fullWidth: boolean;
}
const useStyles = makeStyles<Theme, OptionPropsTypes>((theme) => ({
  input: {
    border: "0px",
    width: ({ fullWidth }) => (fullWidth ? "214px" : "auto"),
  },
}));

export default useStyles;
