import { useTranslation } from "react-i18next";

import { Box, Typography } from "@material-ui/core";

import HoumLogo from "assets/images/adminLandlord/default-property.jpg";
import useNavbarHeight from "apps/landlord/hooks/useNavbarHeight";

import useDefaultCardStyles from "./DefaultCardStyles";

const DefaultCard = () => {
  const navbarHeight = useNavbarHeight();
  const classes = useDefaultCardStyles({ navbarHeight });
  const { t } = useTranslation(["landlord.property"]);

  return (
    <Box className={classes.card}>
      <img src={HoumLogo} alt="houm-logo" className={classes.image} />
      <Box className={classes.cardContent}>
        <Typography className={classes.text}>
          {t("defaultImage.text")}
        </Typography>
      </Box>
    </Box>
  );
};

export default DefaultCard;
