import { makeStyles, Theme } from "@material-ui/core";

const useCallToActionDrawerStyles = makeStyles<Theme>((theme) => ({
  callToAction: {
    height: 80,
    width: "100%",
    position: "sticky",
    bottom: 0,
    boxSizing: "border-box",
    boxShadow: "3px 4px 15px 0px rgba(38, 50, 56, 0.15)",
    padding: theme.spacing(3, 2),
    backgroundColor: "#FBFCFE",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3, 5),
    },
  },
}));

export default useCallToActionDrawerStyles;
