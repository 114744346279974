import { useState } from "react";
import clsx from "clsx";

import { Button, ButtonGroup, Typography } from "@material-ui/core";

import useSwitchSelectorStyles from "./SwitchSelectorStyles";

interface Props {
  options: { label: string; key: string }[];
  handleChange: (e: string) => void;
  defaultValue: string;
}

const SwitchSelector = ({ options, defaultValue, handleChange }: Props) => {
  const classes = useSwitchSelectorStyles();
  const [keySelected, setKeySelected] = useState(defaultValue);

  const handleClick = (key: string) => {
    handleChange(key);
    setKeySelected(key);
  };

  return (
    <ButtonGroup variant="outlined" className={classes.group}>
      {options.map((tab) => (
        <Button
          key={tab.key}
          className={clsx(classes.button, {
            [classes.buttonSelected]: keySelected === tab.key,
          })}
          onClick={() => tab.key !== keySelected && handleClick(tab.key)}
          id={tab.key}
        >
          <Typography
            className={clsx(classes.title, {
              [classes.titleSelected]: keySelected === tab.key,
            })}
          >
            {tab.label}
          </Typography>
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default SwitchSelector;
