import { useFeatureManager } from "@houm-com/feature-manager";

import { UseMovementDataProps } from "apps/payments/hooks/useMovementData/useMovementData";
import PropertyInfo from "apps/payments/components/PropertyInfo";
import { Locale } from "models/Countries";

interface Props {
  country: Locale;
  movement: UseMovementDataProps;
  address: string;
}

const LandingInfo = ({ country, movement, address }: Props) => {
  const { data } = useFeatureManager("landing-payments", {
    marketCode: country,
  });

  return (
    <div className="flex flex-col gap-6">
      <p className="text-p16 md:text-p18">
        {data?.content[movement.concept.toLowerCase()].title}
      </p>
      <p className="text-p16 md:text-p18">
        {data?.content[movement.concept.toLowerCase()].text}
      </p>
      <PropertyInfo movement={movement} address={address} />
      {data?.content[movement.concept.toLowerCase()]?.secondaryText && (
        <p className="text-p16 md:text-p18">
          {data?.content[movement.concept.toLowerCase()].secondaryText}
        </p>
      )}
    </div>
  );
};

export default LandingInfo;
