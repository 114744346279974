import { useQuery, UseQueryResult } from "react-query";

import propertyService from "services/propertyService";
import { useGetToken } from "hooks";
import { PropertyDetails } from "models/Property";

const usePropertyDetails = (propertyId: number) => {
  const { getToken } = useGetToken();
  const {
    isLoading: detailsLoading,
    error: detailsError,
    data: detailsData,
  }: UseQueryResult<PropertyDetails, Error> = useQuery<PropertyDetails, Error>(
    ["propertyDetails", propertyId],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.getPropertyDetails(
        propertyId,
        authToken
      );

      return response.data.property_details;
    },
    { enabled: !!propertyId }
  );

  return {
    detailsLoading,
    detailsError,
    detailsData,
  };
};

export default usePropertyDetails;
