import { makeStyles, Theme } from "@material-ui/core";

export const useFormReferPartnerStyles = makeStyles((theme: Theme) => ({
  fromTitle: {
    fontSize: "1.125rem",
    lineHeight: "1.33em",
    fontWeight: 700,
    color: theme.palette.grey[850],
    marginBottom: theme.spacing(3),
  },
  formControlTextField: {
    height: "auto",
    borderRadius: 0,
    padding: 0,
  },
  formControlLabelTextField: {
    margin: 0,
  },
  textField: {
    margin: 0,
    height: "auto",
    borderRadius: 4,
    backgroundColor: "#EFF2F3",
    padding: theme.spacing(0.5, 0.5),
  },
  textFieldLabel: {
    fontSize: "1rem",
    lineHeight: "1.125em",
    color: theme.palette.grey[850],
    fontWeight: 700,
    width: "100%",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      fontSize: "1.125rem",
      lineHeight: "1.1em",
      color: theme.palette.grey[850],
    },
  },
  input: {
    fontSize: "0.875rem",
    lineHeight: "1.45em",
    "& ::placeholder": {
      color: "#7D8488",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
      lineHeight: "1.375em",
    },
  },
  formControlPhoneInput: {
    height: "auto",
    borderRadius: 0,
    marginTop: 0,
  },
  formControlLabelPhoneInput: {
    marginLeft: 0,
    "&:last-of-type": {
      width: "100%",
      marginRight: 0,
    },
  },
  formControlPhoneInputContainer: {
    gap: 0,
    alignItems: "flex-end",
  },
  formControlOptions: {
    marginTop: 0,
    borderRadius: 0,
    height: "auto",
  },
  formControlLabelOptions: {
    marginBottom: 0,
  },
  motiveLabel: {
    fontSize: "1.125rem",
    fontWeight: 700,
    lineHeight: "1.1em",
    color: theme.palette.grey[850],
    marginBottom: theme.spacing(2),
  },
  optionLabel: {
    fontSize: "0.875rem",
    lineHeight: "1.42em",
    color: theme.palette.grey[850],
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
      lineHeight: "1.375em",
    },
  },
}));
