export default {
  checkInfoCardContentContainer:
    "mb-4 p-6 flex flex-col items-center bg-error-20 text-error-100 rounded text-center",
  errorCardContentContainer:
    "mb-4 p-6 flex flex-col items-center bg-warning-20 rounded text-center",
  cardButton:
    "flex items-center bg-transparent hover:bg-transparent !important cursor-pointer font-bold !pl-0 !text-p16 text-primary-cta ",
  cardButtonIcon: "ml-1",
  text: "text-xs",
};
