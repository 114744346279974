import { useHistory, useParams } from "react-router-dom";

import PropertyLayout from "apps/landlord/Layout/PropertyLayout";

import Documents from "./components/Documents";

const index = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const handleBack = () => history.push(`/landlord/properties/${id}`);

  return (
    <PropertyLayout
      titlePage="Mis documentos"
      subtitlePage="Visualiza y descarga todos los documentos asociados a tu propiedad"
      onHandleBack={handleBack}
    >
      <Documents />
    </PropertyLayout>
  );
};

export default index;
