import { UseQueryResult, useQuery } from "react-query";

import useUser from "apps/tenant/hooks/useUser";
import bankAccountsService from "apps/tenant/services/bankAccountsService/bankAccountsService";
import { IUserBankAccount } from "apps/tenant/models/BankAccount";
import { useGetToken } from "hooks";
import { Locale } from "models/Countries";

import { parseUserBankAccounts } from "./parsers";
import useGetBanks from "../useGetBanks";

const useGetUserBankAccounts = ({ market }: { market: Locale }) => {
  const { getToken } = useGetToken();
  const { data: banks } = useGetBanks({ market });
  const { isLoading: userLoading, error: userError, user } = useUser();

  const {
    isLoading,
    isError,
    data,
  }: UseQueryResult<IUserBankAccount[], Error> = useQuery<
    IUserBankAccount[],
    Error
  >(
    ["userBankAccounts", user?.id, market, banks],
    async () => {
      const authToken = await getToken();
      const response = await bankAccountsService.getByUser(
        authToken,
        market,
        user?.id
      );
      return parseUserBankAccounts(response.data?.bank_accounts, banks);
    },
    {
      enabled: !!user?.id && !!market && !!banks,
    }
  );

  return {
    isLoading: isLoading || userLoading,
    isError: isError || userError,
    data,
  };
};

export default useGetUserBankAccounts;
