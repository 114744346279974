import { makeStyles, Theme } from "@material-ui/core";
import {
  DESKTOP_HEADER_SPACING,
  MOBILE_HEADER_SPACING,
} from "apps/landlord/constants/layout";

interface StyleProps {
  adjustContentPadding: boolean;
}

const useLayoutStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  content: {
    width: "100%",
    height: `calc(100vh - ${MOBILE_HEADER_SPACING}px)`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: (props) =>
      !props.adjustContentPadding &&
      `${theme.spacing(MOBILE_HEADER_SPACING)}px 16px 16px 16px`,
    margin: "0 auto",
    [theme.breakpoints.up("md")]: {
      height: `calc(100vh - ${DESKTOP_HEADER_SPACING}px)`,
      padding: (props) =>
        !props.adjustContentPadding &&
        `${theme.spacing(DESKTOP_HEADER_SPACING)}px 0px 16px 0px`,
      maxWidth: 1280,
    },
  },
}));

export default useLayoutStyles;
