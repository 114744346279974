const NoCurrentContract = () => (
  <div className="flex flex-col gap-2">
    <h1 className="!text-h28">No existe un contrato con houm vigente</h1>
    <div className="flex flex-col gap-4">
      <p className="text-p16">
        No puedes realizar una solicitud de fin de contrato ya que no existe un
        contrato vigente con houm para esta propiedad.
      </p>
    </div>
  </div>
);

export default NoCurrentContract;
