import { Theme, makeStyles } from "@material-ui/core";

interface Props {
  error: boolean;
  warning?: boolean;
  isGrey?: boolean;
}

export default makeStyles<Theme, Props>((theme: Theme) => ({
  wrapper: {
    margin: 0,
    width: "100%",
    padding: theme.spacing(0.3),
    "& .MuiFormHelperText-root": {
      color: ({ error, warning }) =>
        error
          ? "red"
          : warning
          ? theme.palette.warning.main
          : theme.palette.grey[900],
    },
  },
  label: {
    width: "100%",
    fontWeight: "bold",
    alignItems: "left",
    fontSize: "1rem",
    marginBottom: "0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  textAreaRoot: {
    width: "100%",
    backgroundColor: ({ isGrey }) => (isGrey ? "#EFF2F3" : "white"),
    color: ({ isGrey }) => (isGrey ? theme.palette.grey[500] : "black"),
    overflowY: "auto",
    borderRadius: ({ isGrey }) => (isGrey ? theme.spacing(1 / 2) : "1rem"),
    border: ({ isGrey }) => (isGrey ? 0 : "1px solid"),
    borderColor: theme.palette.grey[400],
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.25, 0.5, 0.25, 0),
  },
  textArea: {
    display: "block",
    width: "100%",
    backgroundColor: ({ isGrey }) => (isGrey ? "#EFF2F3" : "white"),
    color: ({ isGrey }) => (isGrey ? theme.palette.grey[500] : "black"),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: "none",
    "&::-webkit-scrollbar": {
      width: 6,
      borderRadius: "100rem",
    },

    "&::-webkit-scrollbar-track": {
      boxShadow: theme.shadows[2],
    },

    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.grey[400],
      border: "1px solid white",
      borderRadius: "100rem",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.grey[100],
    },
  },
}));
