import { Controller, useFormContext } from "react-hook-form";
import classNames from "classnames";

import Button from "@material-ui/core/Button";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { FormControl, FormHelperText, Grid } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

import useStyles from "./ControlledFileUploadStyles";

interface Props {
  allowedMimeTypes: string[];
  helperText?: string;
  id: string;
  label: string;
  controllerProps?: React.ComponentProps<typeof Controller>;
  buttonClassName?: string;
  containerFileClasses?: string;
  text?: string;
  deleteFile?: boolean;
}

export default function ControlledFileUpload({
  allowedMimeTypes,
  helperText,
  id,
  label,
  controllerProps,
  buttonClassName,
  containerFileClasses,
  deleteFile = false,
  text = "Añadir archivo",
}: Props) {
  const { control } = useFormContext();
  const classes = useStyles();

  return (
    <Controller
      name={id}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error, invalid },
      }) => (
        <FormControl error={invalid}>
          <span id={`${id}-label`} className={classes.label}>
            {label}
          </span>
          {helperText && <div className={classes.helperText}>{helperText}</div>}
          {!value || !deleteFile ? (
            <label htmlFor={id}>
              <input
                id={id}
                className={classes.hidden}
                name="upload-file"
                type="file"
                onChange={(e) => {
                  onChange(e.target.files[0]);
                }}
                accept={allowedMimeTypes.join()}
              />
              <Button
                color="primary"
                variant="outlined"
                component="span"
                className={classNames(classes.button, buttonClassName)}
              >
                {text}
              </Button>
              {!deleteFile && (
                <span className={classes.helperText}>{value?.name}</span>
              )}
            </label>
          ) : (
            <div
              className={classNames(
                classes.containerFile,
                containerFileClasses
              )}
            >
              <Grid container justify="center">
                <Grid item>
                  <InsertDriveFileOutlinedIcon className={classes.fileIcon} />
                </Grid>
                <Grid item className={classes.fileName}>
                  {value?.name.length > 10
                    ? `${value.name.substring(0, 10)}...`
                    : value?.name}
                </Grid>
              </Grid>
              <CancelIcon
                className={classes.deleteFileUploaded}
                onClick={() => {
                  onChange(null);
                }}
              />
            </div>
          )}
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
      {...controllerProps}
    />
  );
}
