import { PaymentDataProps } from "apps/payments/context/rentPaymentContext/rentPaymentTypes";
import { RentPaymentResponseCL } from "apps/payments/services/paymentService/types";
import { RentPaymentResponse } from "models/Payments";

export const parseRentPaymentCL = (
  data: RentPaymentResponseCL
): Partial<PaymentDataProps> => ({
  charges: data.additionals.toString(),
  chargesDetail: data.additionals_breakdown?.map((bd) => ({
    id: bd.adjustment_id,
    description: bd.description,
  })),
  currency: "CLP",
  concept: "LEASE",
  date: new Date(`${data.date}T20:00:00`),
  discounts: data.discounts,
  discountsDetail: data.discounts_breakdown?.map((bd) => ({
    id: bd.adjustment_id,
    description: bd.description,
  })),
  fines: data.penalty.toString(),
  readjustment: data.readjustment,
  readjustmentDetail: data.readjustment_breakdown?.map((bd) => ({
    id: bd.adjustment_id,
    description: bd.description,
  })),
  originalPrice: data.original_amount.toString(),
  price: data.rent_price.toString(),
  // TODO: change this
  quotationId: data.quotation_id,
});

export const parseRentPayment = (
  data: Partial<RentPaymentResponse>
): Partial<PaymentDataProps> => ({
  charges: data.additionals_breakdown
    ?.reduce(
      (prev, current) =>
        current.label !== "PENALTY" ? prev + current.value : prev,
      0
    )
    .toString(),
  chargesDetail: data.additionals_breakdown
    ?.filter((bd) => bd.label !== "PENALTY")
    ?.map((bd) => ({
      id: bd.adjustment_id,
      description: bd.description,
    })),
  currency: data.currency,
  date: new Date(data.agreed_date),
  discounts: data.discounts,
  discountsDetail: data.discounts_breakdown?.map((bd) => ({
    id: bd.identifier,
    description: bd.description,
  })),
  fines: data.additionals_breakdown
    ?.reduce(
      (prev, current) =>
        current.label === "PENALTY" ? prev + current.value : prev,
      0
    )
    .toString(),
  movementId: data.movement_id,
  originalPrice: data.original_amount?.toString(),
  price: data.total_amount?.toString(),
  concept: data.concept,
});
