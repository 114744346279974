import {
  State,
  Action,
  SET_STEPS,
  SET_LOADING,
  SET_ACTIVE_STEP,
  defaultApplicantValue,
  SET_CODEBTOR,
  SET_COUNTRY,
  SET_EMAILS,
  SET_EVALUATION,
  SET_APPLICANT,
} from "./CodebtorTypes";

export const initialState: State = {
  loading: true,
  codebtor: defaultApplicantValue,
  activeStep: 0,
  steps: 0,
  country: "Chile",
  emails: [],
  evaluation: null,
  applicant: null,
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_LOADING: {
      return { ...state, loading: action.loading };
    }
    case SET_CODEBTOR: {
      return { ...state, codebtor: action.codebtor };
    }
    case SET_ACTIVE_STEP: {
      return { ...state, activeStep: action.activeStep };
    }
    case SET_STEPS: {
      return { ...state, steps: action.steps };
    }
    case SET_COUNTRY: {
      return { ...state, country: action.country };
    }
    case SET_EMAILS: {
      return { ...state, emails: action.emails };
    }
    case SET_EVALUATION: {
      return { ...state, evaluation: action.evaluation };
    }
    case SET_APPLICANT: {
      return { ...state, applicant: action.applicant };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
