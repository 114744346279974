import { Locale } from "models/Countries";

import DefaulterNotGuaranteedWarning from "../DefaulterNotGuaranteedWarning";
import DefaulterGuaranteedWarning from "../DefaulterGuaranteedWarning";
import DefaulterGuaranteedInfo from "../DefaulterGuaranteedInfo";

interface Props {
  country: Locale;
  isPlanGuaranteed: boolean;
  tenantDebtDays: number;
  landlordPaid: boolean;
  lesseeMonthSinceDebt: Date;
}

const FIVE_MONTHS_TO_DAYS = 150;

const PaymentDisclaimer = ({
  country,
  isPlanGuaranteed,
  tenantDebtDays,
  landlordPaid,
  lesseeMonthSinceDebt,
}: Props) => {
  if (country !== "cl") return null;

  if (!isPlanGuaranteed)
    return (
      <DefaulterNotGuaranteedWarning
        country={country}
        lesseeMonthSinceDebt={lesseeMonthSinceDebt}
      />
    );

  if (landlordPaid) {
    if (tenantDebtDays >= FIVE_MONTHS_TO_DAYS)
      return (
        <DefaulterGuaranteedWarning
          country={country}
          lesseeMonthSinceDebt={lesseeMonthSinceDebt}
        />
      );

    return (
      <DefaulterGuaranteedInfo
        country={country}
        lesseeMonthSinceDebt={lesseeMonthSinceDebt}
      />
    );
  }

  return null;
};

export default PaymentDisclaimer;
