import { makeStyles, Theme } from "@material-ui/core/";

const useBackButtonStyles = makeStyles<Theme>((theme) => ({
  button: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    textTransform: "none",
    fontSize: theme.typography.pxToRem(14),
  },
  arrowIcon: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default useBackButtonStyles;
