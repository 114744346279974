import { Box, Typography } from "@material-ui/core";

import { MovementStatus } from "apps/tenant/models/Movement";

import PaymentStatus from "../../../Dashboard/components/PaymentStatus";
import useServiceCardStyles from "./ServiceCardStyles";

interface Props {
  amount: string;
  clientNumber: string;
  expirationDate: string;
  status: MovementStatus;
  type: string;
}

const ServiceCard = ({
  amount,
  clientNumber,
  expirationDate,
  status,
  type,
}: Props) => {
  const classes = useServiceCardStyles();

  return (
    <Box className={classes.serviceCardRoot}>
      <PaymentStatus status={status} className={classes.tag} />
      <Typography className={classes.amount}>{amount}</Typography>
      <Typography className={classes.type}>{type}</Typography>
      <Typography className={classes.info}>
        Vencimiento: {expirationDate}
      </Typography>
      <Typography className={classes.info}>
        Cliente: {clientNumber || "-"}
      </Typography>
    </Box>
  );
};

export default ServiceCard;
