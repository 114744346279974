import { useCallback, useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";

import { useMovements } from "apps/landlord/hooks";

const useRentPayment = () => {
  const [selected, setSelected] = useState<string | null>();
  const [showDrawer, setShowDrawer] = useState(false);

  const { movementsLoading, movementsData, movementsError } = useMovements();

  const handleShow = useCallback((open) => {
    if (open) {
      setShowDrawer(true);
    } else {
      setShowDrawer(false);
      setSelected(null);
    }
  }, []);

  useEffect(() => {
    if (movementsData?.length > 0 && isDesktop && !selected) {
      setSelected(movementsData[0].id);
    }
  }, [movementsLoading, movementsData, isDesktop]);

  return {
    selected,
    setSelected,
    showDrawer,
    setShowDrawer,
    movementsLoading,
    movementsData,
    movementsError,
    handleShow,
  };
};

export default useRentPayment;
