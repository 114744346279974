export const INVALID_TYPE = {
  title: "Lamentamos las molestias",
  subtitle: "En este momento no cubrimos este tipo de propiedad.",
  secondSubtitle:
    "Te estaremos avisando apenas tengamos cobertura. Gracias por tu comprensión.",
  buttonText: "Salir",
  open: true,
};

export const INVALID_LOCATION = {
  title: "Lamentamos las molestias",
  subtitle: "En este momento no tenemos cobertura en tu zona.",
  secondSubtitle:
    "Te estaremos avisando apenas tengamos cobertura. Gracias por tu comprensión.",
  buttonText: "Salir",
  open: true,
};

export const INVALID_DATE = {
  title: "Lamentamos las molestias",
  subtitle: "En este momento no tenemos cobertura para una fecha tan amplia.",
  secondSubtitle: "",
  buttonText: "Salir",
  open: true,
};

// export const redirectFail = {
//   title: "Lamentamos las molestias",
//   subtitle: "Hubo un error al crear la propiedad",
//   secondSubtitle: "Intenta de nuevo mas tarde",
//   buttonText: "Salir",
//   open: true
// };
