import ReactDOM from "react-dom";
import featureManager from "@houm-com/feature-manager";

import MainApp from "apps/main/MainApp";
import {
  SENTRY_DSN,
  HOTJAR_ID,
  ANALITYCS_ID,
  FREATURE_MANAGER_BASE_URL,
  FEATURE_MANAGER_APP_ID,
  DATADOG_APPLICATIONID,
  DATADOG_CLIENTTOKEN,
  DATADOG_SERVICE,
} from "env";
import datadog from "libs/datadog";
import hotjar from "libs/hotjar";
import analytics from "libs/analytics";
import sentry from "libs/sentry";

import "./i18n";

if (SENTRY_DSN) {
  sentry.initialize(SENTRY_DSN);
}

if (HOTJAR_ID) {
  hotjar.initialize(HOTJAR_ID);
}

if (ANALITYCS_ID) {
  analytics.initialize(ANALITYCS_ID);
}

if (DATADOG_APPLICATIONID && DATADOG_CLIENTTOKEN && DATADOG_SERVICE) {
  datadog.init({
    applicationId: DATADOG_APPLICATIONID,
    clientToken: DATADOG_CLIENTTOKEN,
    service: DATADOG_SERVICE,
  });
}

featureManager.init({
  baseURL: FREATURE_MANAGER_BASE_URL,
  appId: FEATURE_MANAGER_APP_ID,
});

ReactDOM.render(<MainApp />, document.getElementById("root"));
