import { makeStyles, Theme } from "@material-ui/core";

const usePaymentSummaryDetailsStyles = makeStyles<Theme>((theme) => ({
  moreDetailsContainer: {
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
    gap: theme.spacing(1.5),
    // marginBottom: theme.spacing(1.75),
    "& p": {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.57,
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 1.5,
      },
    },
    // [theme.breakpoints.up("sm")]: {
    //   marginBottom: theme.spacing(2),
    // },
  },
  paymentDetailContainer: {
    width: "100%",
    "& div:nth-child(odd)": {
      backgroundColor: "#F7F9F9",
    },
    marginTop: theme.spacing(1.75),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
}));

export default usePaymentSummaryDetailsStyles;
