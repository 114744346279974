import { makeStyles, Theme } from "@material-ui/core";

const useMenuCardStyles = makeStyles((theme: Theme) => ({
  MenuCardRoot: {
    marginBottom: theme.spacing(5),
    padding: theme.spacing(2),
    backgroundColor: "white",
    borderRadius: 16,
  },
  title: {
    marginBottom: theme.spacing(3),
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
  },
  cardItemRoot: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
  },
  cardItemIcon: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(0.5),
    borderRadius: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#6B93A3",
    color: "white",
    "& path": {
      stroke: "white",
    },
  },
  cardItemText: {
    width: "100%",
  },
  cardItemTitle: {
    fontSize: theme.typography.pxToRem(14),
  },
  cardItemSubtitle: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey["A400"],
  },
}));

export default useMenuCardStyles;
