import { Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import useCustomLoiHeaderStyles from "./CustomHeaderStyles";
import houmLogo from "assets/images/houmLogo.svg";

interface CustomHeaderProps {
  handleBackButton: () => void;
}

const CustomLoiHeader = ({ handleBackButton }: CustomHeaderProps) => {
  const classes = useCustomLoiHeaderStyles();
  return (
    <header className={classes.root}>
      <Typography className={classes.backButton} onClick={handleBackButton}>
        <ChevronLeftIcon />
        Atrás
      </Typography>
      <img alt="houm-logo" src={houmLogo} />
    </header>
  );
};

export default CustomLoiHeader;
