import { useEffect, useRef, memo, useState } from "react";
import { useTheme } from "@material-ui/core";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { formatNumber } from "utils";
import { MAPBOX_API_TOKEN } from "env";

import useMapStyles from "./MapStyles";

mapboxgl.accessToken = MAPBOX_API_TOKEN;

interface Props {
  properties?: any;
  value: [lng: number, lat: number];
  defaultValue: [lng: number, lat: number];
  hasMarker?: boolean;
  onDrag?: ([lat, lng], marker) => void;
  locale?: string;
  zoom?: number;
}

const Map = ({
  properties = [],
  value,
  defaultValue,
  onDrag,
  hasMarker,
  locale,
  zoom = 12,
}: Props) => {
  const theme = useTheme();
  const classes = useMapStyles();

  const [coordinates, setCoordinates] = useState(value);

  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const mainMarkerRef = useRef(null);

  useEffect(() => {
    if (defaultValue) {
      setCoordinates(defaultValue);
      mapRef.current?.setCenter(coordinates);
      mainMarkerRef.current?.setLngLat(coordinates);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value) {
      setCoordinates(value);
      mapRef.current?.setCenter(coordinates);
      mainMarkerRef.current?.setLngLat(coordinates);
    }
  }, [value]);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: coordinates,
      zoom,
    });

    mapRef.current = map;
    map.addControl(new mapboxgl.NavigationControl(), "top-left");
    const geojsonPoint = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates,
          },
        },
      ],
    };

    map.on("load", () => {
      if (hasMarker) {
        const el = document.createElement("div");
        el.className = "marker";
        const mainMarker = new mapboxgl.Marker(el, { draggable: hasMarker })
          .setLngLat(coordinates)
          .addTo(map);
        // @ts-ignore
        mainMarker.on("dragend", ({ target }) => {
          const { lng, lat }: { lat: number; lng: number } = target.getLngLat();
          onDrag([lng, lat], target);
          setCoordinates([lng, lat]);
          map.setCenter([lng, lat]);
        });

        mainMarkerRef.current = mainMarker;
      }
      try {
        if (!hasMarker) {
          map.addSource("geojsonPointCenter", {
            type: "geojson",
            // @ts-ignore
            data: geojsonPoint,
          });
          map.addLayer({
            id: "geojsonPointCenter",
            type: "circle",
            source: "geojsonPointCenter",
            paint: {
              "circle-radius": 4,
              "circle-color": theme.palette.primary.dark,
              "circle-opacity": 0.9,
            },
          });
          map.addLayer({
            id: "geojsonPointOut",
            type: "circle",
            source: "geojsonPointCenter",
            paint: {
              "circle-radius": 25,
              "circle-color": theme.palette.primary.main,
              "circle-opacity": 0.2,
            },
          });
        }
      } catch (error) {
        // Do nothing
      }
    });

    const parsedProperties = properties
      .filter(
        (item) =>
          (item.latitude !== 0 && item.longitude !== 0) ||
          (item.lat !== 0 && item.lng !== 0)
      )
      .map((item) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [item.longitude || item.lng, item.latitude || item.lat],
        },
        properties: {
          title: `${formatNumber(Math.round(item.price), locale)} ${
            item.currency
          }`,
          link: item.link,
          n_bathrooms: item.bathrooms,
          n_bedrooms: item.bedrooms,
          built_area: item.built_area,
          property_type: item.property_type,
        },
      }));

    const elm = document.createElement("div");

    elm.className = "marker marker-blue";
    parsedProperties.forEach((marker) => {
      const el = document.createElement("div");
      el.className = "marker";
      new mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }).setHTML(
            `<div class="mapbox-popup-prop-wrap">
            <div class="mapbox-prop-card-description">
            ${
              !marker.properties.title.includes(undefined)
                ? `<h6>$${marker.properties.title}</h6>`
                : " "
            }

            <p>${marker.properties.n_bedrooms}d, ${
              marker.properties.n_bathrooms
            }b </p>
              <p>${marker.properties.built_area}m<sup>2</sup></p>
            </div>
          </div>`
          )
        )
        .addTo(map);
    });
  }, [coordinates]);

  return (
    <div className={classes.mapWrapper}>
      <div
        ref={(el) => {
          mapContainer.current = el;
        }}
      />
    </div>
  );
};

export default memo(Map, (prev, next) => prev.value[1] === next.value[1]);
