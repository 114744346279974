export const classes = {
  drawerContainer: "flex flex-col gap-4 md:gap-6",
  drawerTitleContainer: "flex flex-col gap-1",
  title:
    "text-[28px] leading-[1.28] md:text-[32px] md:leading-[1.25] font-black font-['Inter'] text-left",
  subtitle: "!text-left",
  spinner: "flex justify-center",
  informationContainer: "flex flex-col gap-8",
  bankContainer: "flex gap-4",
  bankLogo: "w-6 h-6 max-w-fit",
  bankName: "text-p16 font-['Inter'] font-bold",
  bankDisclaimer: "text-p16",
};
