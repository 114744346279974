import { makeStyles, Theme } from "@material-ui/core";

const useCustomDatePickerStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    gap: theme.spacing(2),
  },
}));

export default useCustomDatePickerStyles;
