import { SignedContracts } from "apps/landlord/services/propertyContractService/types";

export const getDocumentsList = (documents: SignedContracts) => [
  ...(documents?.mandate
    ? [
        {
          id: 1,
          text: "Mandato",
          link: documents.mandate,
          onClick: () => (window.location.href = documents.mandate),
        },
      ]
    : []),
  ...(documents?.contract
    ? [
        {
          id: 2,
          text: "Contrato",
          link: documents.contract,
          onClick: () => (window.location.href = documents.contract),
        },
      ]
    : []),
];
