import { AxiosResponse } from "axios";
import { PAYMENTS_URL } from "env";
import { Locale } from "models/Countries";
import { createHttp } from "services/helper";
import {
  IBankAccountData,
  IBankAccountsResponse,
  IBanksResponse,
  ICreateBankAccountResponse,
} from "./types";

export default {
  getBanks: (
    token: string,
    country: Locale
  ): Promise<AxiosResponse<IBanksResponse>> =>
    createHttp(token, PAYMENTS_URL).get(`/${country}/v3/banks`),
  create: (
    token: string,
    data: Partial<IBankAccountData>,
    country: Locale,
    userId: number
  ): Promise<AxiosResponse<ICreateBankAccountResponse>> =>
    createHttp(token, PAYMENTS_URL).post(
      `${country}/v3/people/${userId}/bank_accounts`,
      data
    ),
  update: (
    token: string,
    data: Partial<IBankAccountData>,
    country: Locale,
    userId: number,
    propertyId: string
  ): Promise<AxiosResponse<ICreateBankAccountResponse>> =>
    createHttp(token, PAYMENTS_URL).put(
      `${country}/v3/people/${userId}/bank_accounts/${propertyId}`,
      data
    ),
  getByUser: (
    token: string,
    country: Locale,
    userId: number
  ): Promise<AxiosResponse<IBankAccountsResponse>> =>
    createHttp(token, PAYMENTS_URL).get(
      `${country}/v3/people/${userId}/bank_accounts`
    ),
};
