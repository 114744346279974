import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useApplicantInfoStyles = makeStyles((theme: Theme) => ({
  applicant: {
    padding: theme.spacing(2),
    rowGap: theme.spacing(2),
    columnGap: theme.spacing(4),
    border: `1px solid ${theme.palette.grey[75]}`,
    boxShadow: "3px 4px 25px rgba(38, 50, 56, 0.15)",
    borderRadius: 16,
    justifyContent: "center",
    width: "fit-content",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(2, 3),
    },
  },
}));

export default useApplicantInfoStyles;
