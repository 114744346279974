import { AxiosResponse } from "axios";
import qs from "query-string";

import { Paginate } from "models/ResponseWithPaginate";
import { createHttp } from "services/helper";

import {
  InspectionReportShowResponse,
  PropertyInspectionReportsTableFilters,
} from "./types";

export default {
  getProperty(id: number | string, token: string) {
    return createHttp(token).get(`/admin/v2/properties/${id}/`);
  },
  getPropertyInspectionReports(
    propertyId: number,
    // eslint-disable-next-line default-param-last
    pageSize: number = 15,
    // eslint-disable-next-line default-param-last
    page: number = 1,
    { ...filters }: PropertyInspectionReportsTableFilters,
    token: string
  ): Promise<AxiosResponse<Paginate<InspectionReportShowResponse>>> {
    return createHttp(token).get(
      `/properties/v2/${propertyId}/inspection_reports/?page_size=${pageSize}&page=${page}&${qs.stringify(
        filters
      )}`
    );
  },
  getInspectionReportById(
    id: number | "last_inspection_report",
    propertyId: number | string,
    token: string
  ): Promise<AxiosResponse<InspectionReportShowResponse>> {
    return createHttp(token).get(
      `/properties/v2/${propertyId}/inspection_reports/${id}/`
    );
  },
};
