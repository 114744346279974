import { useHistory } from "react-router-dom";

import { Box, Typography } from "@material-ui/core";

import useOptionCardStyles from "./OptionCardStyles";
import { IDashboardOption } from "../utils/types";

const OptionCard = ({ icon, label, link }: IDashboardOption) => {
  const classes = useOptionCardStyles();
  const history = useHistory();

  return (
    <Box className={classes.optionCardRoot} onClick={() => history.push(link)}>
      <Box className={classes.iconWrapper}>{icon}</Box>
      <Typography className={classes.label}>{label}</Typography>
    </Box>
  );
};

export default OptionCard;
