import { useParams } from "react-router-dom";
import { isDesktop } from "react-device-detect";

import { Grid } from "@material-ui/core";

import useGetContractProperty from "apps/landlord/hooks/useGetContractProperty";
import Spinner from "Components/Shared/partials/Spinner";
import DocumentsImage from "assets/img/Documents.png";

import useDocuments from "../../hooks/useDocuments";
import useDocumentsStyles from "./DocumentsStyles";
import MenuDocuments from "../MenuDocuments";

const Documents = () => {
  const classes = useDocumentsStyles();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetContractProperty(id);
  const {
    data: documentsLink,
    isLoading: isLoadingDocuments,
    isSuccess,
  } = useDocuments(data?.id);

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={12} sm={6} container direction="column">
        {(isLoading || isLoadingDocuments) && <Spinner />}
        {isSuccess && <MenuDocuments documents={documentsLink} />}
      </Grid>
      {isDesktop && (
        <Grid
          item
          sm={6}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          container
        >
          <img src={DocumentsImage} alt="documents" />
        </Grid>
      )}
    </Grid>
  );
};

export default Documents;
