import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useHoumSnackBar } from "libs/houm-snackbar";

import { PropertyDetailsFormProps } from "apps/landlord/components/FormPropertyDetails/utils/types";
import propertyService from "apps/landlord/services/propertyService";
import { useGetToken } from "hooks";

import parsePropertyDetailsService from "./utils/parsers";

const useEditDetails = (returnToPropertyDetail: () => void) => {
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const queryClient = useQueryClient();
  const { id: propertyId } = useParams<{ id: string }>();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (formData: PropertyDetailsFormProps) => {
      const authToken = await getToken();
      const parsedData = parsePropertyDetailsService(formData);
      const response = await propertyService.editPropertyDetails(
        propertyId,
        parsedData,
        authToken
      );
      return response.data;
    },
    {
      onError: () =>
        enqueueHoumSnackBar({
          body: "Ha ocurrido un error al editar los detalles de la propiedad",
          type: "error",
        }),
      onSuccess: () => {
        queryClient.invalidateQueries("property_details");
        enqueueHoumSnackBar({
          body: "Los detalles de la propiedad se han editado correctamente",
          type: "success",
        });
        returnToPropertyDetail();
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useEditDetails;
