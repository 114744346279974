import { IDetails } from "apps/landlord/hooks/usePropertyDetails/utils/types";

export const parseDefaultValues = (details: IDetails) => ({
  mBuilt: details?.builtArea ?? null,
  mSurface: details?.totalArea ?? null,
  yearBuilt: details?.yearBuilt ?? null,
  bedrooms: details?.rooms ?? 0,
  bathrooms: details?.bathrooms ?? 0,
  parkingQuantity: details?.parkingQuantity ?? 0,
  parkingNumbers: details?.parkingNumbers ?? null,
  warehouse: details?.warehouse ? "yes" : "no",
  warehouseNumber: details?.warehouseNumber ?? null,
});
