import { makeStyles, Theme } from "@material-ui/core";

const useControlledGroupCheckboxStyles = makeStyles((theme: Theme) => ({
  optionContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  label: {
    width: "100%",
    fontWeight: "bold",
    alignItems: "left",
    fontSize: theme.typography.pxToRem(14),
    marginBottom: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
  },
}));

export default useControlledGroupCheckboxStyles;
