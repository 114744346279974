import { makeStyles, Theme } from "@material-ui/core";

const useImageContainerStyles = makeStyles((theme: Theme) => ({
  imageContainer: {
    width: "100%",
    height: 184,
    position: "relative",
    padding: theme.spacing(2),
    zIndex: 1,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "space-between",
    "&::after": {
      content: "''",
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      zIndex: -1,
      background:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 42.65%)",
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1,
  },
  upperOverlayRow: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  typeLabel: {
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(0, 1),
    borderRadius: 80,
    backgroundColor: "#F2F2F2",
    fontWeight: 700,
    lineHeight: "20px",
    display: "inline-block",
  },
}));

export default useImageContainerStyles;
