import { FormProps } from "../../../utils/types";
import { IPropertyDetailsService } from "./types";

const parsePropertyDetailsService = (
  data: FormProps
): IPropertyDetailsService => ({
  year_built: data.yearBuilt,
  property_details: [
    {
      m_construidos: data.mBuilt,
      m_terreno: data.mSurface,
      dormitorios: data.bedrooms,
      banos: data.bathrooms,
      estacionamientos: data.parkingQuantity,
      parking_numbers:
        Number(data.parkingQuantity) > 0 ? data.parkingNumbers : "",
      bodega: data.warehouse === "yes",
      warehouse_number: data.warehouse === "yes" ? data.warehouseNumber : "",
      furnished: data.propertyAmenities.includes("furnished") ? "fully" : "non",
      mascotas: data.propertyAmenities.includes("allowsPets"),
      has_air_conditioning:
        data.propertyAmenities.includes("hasAirConditioning"),
      piscina: data.propertyAmenities.includes("hasPool"),
      has_balcony: data.propertyAmenities.includes("hasBalcony"),
      has_laundry_room: data.propertyAmenities.includes("hasLaundryRoom"),
      laundry_capacity: data.propertyAmenities.includes("hasLaundryCapacity"),
      condominio: data.amenities.includes("condominium"),
      calefaccion: data.propertyAmenities.includes("heating"),
    },
  ],
  association_amenities: {
    has_bbq_area: data.amenities.includes("hasBbqArea"),
    has_elevator: data.amenities.includes("hasElevator"),
    has_gym: data.amenities.includes("hasGym"),
    has_pet_area: data.amenities.includes("hasPetArea"),
    has_concierge: data.amenities.includes("hasConcierge"),
    has_patio: data.amenities.includes("hasPatio"),
    has_sauna: data.amenities.includes("hasSauna"),
    has_laundry: data.amenities.includes("hasLaundry"),
    has_party_room: data.amenities.includes("hasPartyRoom"),
    has_cinema: data.amenities.includes("hasCinema"),
    has_playground: data.amenities.includes("hasPlayground"),
    has_gourmet_space: data.amenities.includes("hasGourmetSpace"),
  },
});

export default parsePropertyDetailsService;
