import { AxiosError } from "axios";
import { useMutation } from "react-query";

import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import paymentService from "apps/payments/services/paymentService";
import {
  setPaymentData,
  setStep,
  setTenantData,
} from "apps/payments/context/rentPaymentContext/rentPaymentActions";
import { useHoumSnackBar } from "libs/houm-snackbar";
import userService from "services/userService";

import { paymentDataFetchError } from "../../utils/utils";
import { parseRentPayment } from "../../utils/parsers";

interface FormProps {
  document?: string;
  documentType?: number;
  email?: string;
}

const useRentPaymentDataByUserId = () => {
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const { state, dispatch } = useRentPayment();

  const { mutate, isLoading, isError, isSuccess, data } = useMutation(
    async (data: FormProps) => {
      const responseUserId = await userService.getUserIdV2({
        document: data.document,
        document_type: data.documentType && String(data.documentType),
        mail: data.email,
      });
      dispatch(
        setTenantData({
          ...state.tenantData,
          document: data.document,
          documentType: data.documentType,
          userId: responseUserId?.data.user_id,
        })
      );
      const response = await paymentService.getRentPaymentDetails(
        state.country,
        String(responseUserId?.data.user_id)
      );
      const parsedData = parseRentPayment(response.data);
      dispatch(setPaymentData(parsedData));
      dispatch(setStep("paymentDetail"));
    },
    {
      onError: (e: AxiosError) => {
        if (e.response?.data?.error === "User not found")
          return dispatch(setStep("mailForm"));
        enqueueHoumSnackBar(paymentDataFetchError(e));
      },
    }
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess,
    mutate,
  };
};

export default useRentPaymentDataByUserId;
