import { useState } from "react";

import { Collapse, Typography, Box } from "@material-ui/core";

import {
  GroupedInspectionReportSpace,
  SpaceSummary,
} from "models/InspectionReport";
import useStyles from "./SpaceMobileCardStyles";
import SpaceMainInfo from "./components/SpaceMainInfo";
import SpaceElementCollapse from "./components/SpaceElementCollapse";
import getElementsWithObservation from "./utils";

interface Props {
  item: SpaceSummary;
  spaces?: GroupedInspectionReportSpace[];
}

const SpaceMobileCard = ({ item: space, spaces = [] }: Props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const spaceElements = getElementsWithObservation(spaces, space.type);
  const isExpandable = spaceElements.length > 0;

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Box className={classes.spaceMobileCardRoot}>
      <SpaceMainInfo
        space={space}
        isOpen={isOpen}
        handleOpen={handleOpen}
        showExpandIcon={isExpandable}
      />
      {space?.overallObservation && (
        <Typography className={classes.observationText}>
          <strong>Comentario: </strong> {space?.overallObservation}
        </Typography>
      )}
      {isExpandable && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Box className={classes.collapsableContainer}>
            {spaceElements.length > 0 && (
              <SpaceElementCollapse
                title="Arreglos sugeridos"
                items={spaceElements}
              />
            )}
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

export default SpaceMobileCard;
