import { Trans } from "react-i18next";

import { Typography } from "@material-ui/core";

import useFormattedTranslationStyles from "./FormattedTranslationStyles";

interface FormattedTranslationProps {
  text: string;
  values?: {
    scheduled_date?: string;
    contract_init_date?: string;
  };
}

const FormattedTranslation = ({ text, values }: FormattedTranslationProps) => {
  const classes = useFormattedTranslationStyles();
  return (
    <Typography className={classes.text}>
      <Trans
        i18nKey={text}
        values={values}
        components={{
          1: <strong />,
          2: <br />,
        }}
      />
    </Typography>
  );
};

export default FormattedTranslation;
