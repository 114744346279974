import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import Spinner from "Components/Shared/partials/Spinner";

import useKhipuOnboarding from "./hooks/useKhipuOnboarding";
import KhipuOnboarding from "./components/KhipuOnboarding";
import useKhipuUrls from "./hooks/useKhipuUrls";
import KhipuBank from "./components/KhipuBank";

const Khipu = () => {
  const { state } = useRentPayment();
  const { data: khipuUrls, isLoading: isLoadingUrls } = useKhipuUrls(
    state.paymentData.quotationId,
    state.tenantData.document
  );
  const { data: khipuOnboarding, isLoading: isLoadingOnboarding } =
    useKhipuOnboarding(
      state.paymentData.quotationId,
      state.tenantData.document
    );

  const isLoadingData = isLoadingUrls || isLoadingOnboarding;

  if (isLoadingData) return <Spinner />;

  return (
    <div>
      {khipuUrls && khipuUrls?.bankAccounts.length > 0 ? (
        <KhipuBank
          paymentLink={khipuUrls.paymentUrl}
          onboardingLink={khipuOnboarding?.paymentUrl}
          bankAccounts={khipuUrls.bankAccounts}
        />
      ) : (
        <KhipuOnboarding onboardingLink={khipuOnboarding?.paymentUrl} />
      )}
    </div>
  );
};

export default Khipu;
