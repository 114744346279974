import { makeStyles, Theme } from "@material-ui/core";

const useNoBankAccountCardStyles = makeStyles((theme: Theme) => ({
  bankAccountInfoRoot: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 1),
    backgroundColor: "white",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  iconWrapper: {
    height: 40,
    width: 40,
    margin: theme.spacing(0, 0, 1),
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DAE4E8",
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 1, 0, 0),
      alignItems: "center",
    },
  },
  noAccountText: {
    fontSize: theme.typography.pxToRem(14),
  },
  addAccountButton: {
    marginBottom: theme.spacing(3),
  },
}));

export default useNoBankAccountCardStyles;
