import { OptionTypes } from "Components/Admin/UIComponents/ControlledForm";
import { CountryUpperCase } from "models/Countries";
import { LoiIdentificationType } from "services/letterOfIntentService/letterOfIntentService.types";

export const INITIAL_LOI_CONTEXT_DATA = {
  state: {
    loiOwners: [],
    country: "Chile" as CountryUpperCase,
    activeStep: 0,
    canAddMoreOwners: false,
    documentTypes: [],
    finishedProcess: false,
    completedFirstForm: false,
    mainOwner: {
      civil_status: null,
      email: "",
      external_user_id: "",
      id: "",
      identifications: [],
      is_main_user: false,
      last_name: "",
      manually_reviewed: false,
      name: "",
      nationality: null,
      occupation: "",
      phone: "",
      second_last_name: "",
      user_detail: {
        city: "",
        commune: "",
        address: "",
        outerNumber: "",
        innerNumber: "",
      },
    },
    personalData: {
      name: "",
      lastName: "",
      secondLastName: "",
      occupation: "",
      documentTypes: [],
      email: "",
      phone: "",
      identificationNumber: "",
      rfc: "",
      civilStatus: "",
    },
    addressData: {
      city: "",
      commune: "",
      address: "",
      outerNumber: "",
      innerNumber: "",
    },
    bankData: {
      clabe: "",
    },
    documents: {},
  },
  actions: {
    setPersonalData: (personalData: LoiPersonalData) => {},
    setAddressData: (addressData: LoiAddressData) => {},
    setBankData: (bankData: LoiBankData) => {},
    setDocuments: (documents: LoiDocuments) => {},
    setActiveStep: (activeStep: number) => {},
    setAddingSecondaryOwner: (addingSecondaryOwner: boolean) => {},
  },
  handlers: {
    submitLoiToReview: () => {},
    updateOrCreateOwner: () => {},
    resetFormData: () => {},
    refreshLoiOwners: () => {},
    goToPropertyDetail: () => {},
  },
};

export interface LoiPersonalData {
  name: string;
  lastName: string;
  secondLastName: string;
  email: string;
  phone: string;
  occupation?: string;
  identificationType?: string | number | boolean;
  identificationNumber?: string;
  rfc?: string;
  civilStatus?: string;
}

export interface LoiAddressData {
  city: string;
  commune: string;
  address: string;
  outerNumber: string;
  innerNumber: string;
}

export interface LoiBankData {
  clabe?: string;
}

export interface LoiDocuments {
  identificationDoc1?: File;
  identificationDoc2?: File;
  appraisalCertificate?: File;
  propertyDeed?: File;
  curp?: File;
  rfc?: File;
  birthCertificate?: File;
  marriageCertificate?: File;
}

export interface Owner {
  civil_status: string | null;
  email: string;
  external_user_id: string;
  id: string;
  identifications: LoiIdentificationType[];
  is_main_user: boolean;
  last_name: string;
  manually_reviewed: boolean;
  name: string;
  nationality: string | null;
  occupation: string | null;
  phone: string | null;
  second_last_name: string | null;
  user_detail: null | LoiAddressData;
}

export interface LoiContextState {
  state: {
    loiOwners: Owner[];
    activeStep: number;
    canAddMoreOwners: boolean;
    country: CountryUpperCase;
    personalData: LoiPersonalData;
    addressData: LoiAddressData;
    bankData: LoiBankData;
    documents: LoiDocuments;
    documentTypes: OptionTypes[];
    finishedProcess: boolean;
    completedFirstForm: boolean;
    mainOwner: Owner;
  };
  actions: {
    setActiveStep: (activeStep?: number) => void;
    setPersonalData: (personalData?: LoiPersonalData) => void;
    setAddressData: (addressData?: LoiAddressData) => void;
    setBankData: (bankData?: LoiBankData) => void;
    setDocuments: (documents?: LoiDocuments) => void;
    setAddingSecondaryOwner: (addingSecondaryOwner?: boolean) => void;
  };
  handlers?: {
    submitLoiToReview: () => void;
    updateOrCreateOwner?: () => void;
    resetFormData: () => void;
    refreshLoiOwners: () => void;
    goToPropertyDetail: () => void;
  };
}
