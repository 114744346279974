import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

import { Box, Typography } from "@material-ui/core";

import BackButton from "apps/tenant/components/BackButton";
import useContractEnd from "apps/tenant/hooks/useContractEnd";
import useContract from "apps/tenant/hooks/useContract";
import { useNavbar } from "apps/ui/Navbar/context/navbarContext";
import Spinner from "Components/Shared/partials/Spinner";

import HelpCard from "./components/HelpCard";
import useHelpPageStyles from "./HelpPageStyles";
import getHelpCardsProps from "./utils";

const HelpPage = () => {
  const classes = useHelpPageStyles();
  const history = useHistory();
  const { setBackUrl } = useNavbar();
  const { data: contractData, isLoading } = useContract();
  const { contractEndData, isLoading: isContractEndLoading } = useContractEnd();

  useEffect(() => {
    setBackUrl("/");
  }, []);

  if (isLoading || isContractEndLoading)
    return (
      <div className={classes.container}>
        <Spinner />
      </div>
    );

  const helpCardsProps = getHelpCardsProps(
    history.push,
    contractEndData,
    contractData?.country,
    contractData?.initDate
  );

  return (
    <Box className={classes.container}>
      {!isMobile && (
        <BackButton onClickHandler={() => history.push("/tenant")} />
      )}
      <Typography className={classes.title}>Tus solicitudes</Typography>
      <Typography className={classes.subtitle}>
        Desde esta sección puedes solicitar reparaciones o la salida de tu
        inmueble.
      </Typography>
      <Box className={classes.cardsContainer}>
        {helpCardsProps.map((helpCardProps) => (
          <HelpCard {...helpCardProps} key={helpCardProps.title} />
        ))}
      </Box>
    </Box>
  );
};

export default HelpPage;
