import React from "react";

import { Typography } from "@material-ui/core";

import useDayWrapperStyles from "./DayWrapperStyles";

interface Props {
  disabled?: boolean;
  children: React.ReactElement | null;
  day: number;
}

const DayWrapper = ({ disabled, children, day }: Props) => {
  const classes = useDayWrapperStyles();
  if (disabled)
    return <Typography className={classes.disabled}>{day}</Typography>;
  return children;
};

export default DayWrapper;
