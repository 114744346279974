import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

export default makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.grey["50"],
    padding: theme.spacing(3.5, 5, 10.5, 5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(6.5, 12),
    },
  },
  divWithBorder: {
    margin: "auto",
    borderLeft: "1.5px solid",
    borderColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(2.75),
    "& p": {
      fontWeight: "bold",
    },
    [theme.breakpoints.up(defaultBreakpoint)]: {
      borderLeft: "3px solid",
      borderColor: theme.palette.primary.main,
    },
  },
  list: {
    marginTop: theme.spacing(1.5),
    "& li": {
      marginLeft: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(3),
    borderRadius: 10,
    "& *": {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
    },
    "& p": {
      marginBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.up(defaultBreakpoint)]: {
      height: "100%",
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(0, 6),
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: "1.375rem",
      marginBottom: theme.spacing(6),
      textAlign: "center",
    },
  },
}));
