import useRequirementItemStyles from "./RequirementItemStyles";
import clsx from "clsx";

interface Props {
  isValid?: boolean | null;
  requirement: string;
}

const RequirementItem = ({ isValid = null, requirement }: Props) => {
  const classes = useRequirementItemStyles();

  return (
    <li
      className={clsx(classes.base, {
        [classes.valid]: isValid,
        [classes.invalid]: isValid === false,
      })}
    >
      {requirement}
    </li>
  );
};

export default RequirementItem;
