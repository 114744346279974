import { useQuery, UseQueryResult } from "react-query";
import { useGetToken } from "hooks";
import { useProperty } from "apps/landlord/hooks";
import {
  CompanyType,
  IBillInfo,
} from "apps/landlord/services/propertiesDuesService/type";
import propertiesDuesService from "apps/landlord/services/propertiesDuesService";
import { parseBillsInfo } from "./utils";

interface BillsInfoByCompanyType {
  water?: IBillInfo;
  energy?: IBillInfo;
  gas?: IBillInfo;
  common_expenses?: IBillInfo;
}

const useBillsInfo = (companyTypes?: CompanyType[]) => {
  const { getToken } = useGetToken();
  const { propertyData, propertyLoading, propertyError } = useProperty();
  const { id, country } = propertyData || {};
  const {
    isFetching,
    isLoading,
    error,
    data,
  }: UseQueryResult<BillsInfoByCompanyType, Error> = useQuery<
    BillsInfoByCompanyType,
    Error
  >(
    ["billsInfo", id, companyTypes],
    async () => {
      const authToken = await getToken();
      const response = await propertiesDuesService.getBills(id, authToken);
      const bills = parseBillsInfo(response.data.bills_info);

      let waterCompanyInfo = null;
      let energyCompanyInfo = null;
      let gasCompanyInfo = null;
      let commonExpensesCompanyInfo = null;
      if (["cl", "co"].includes(country)) {
        waterCompanyInfo = bills.find((elem) => elem.companyType === "water");
        energyCompanyInfo = bills.find((elem) => elem.companyType === "energy");
        gasCompanyInfo = bills.find((elem) => elem.companyType === "gas");
      }
      if (country === "cl") {
        commonExpensesCompanyInfo = bills.find(
          (elem) => elem.companyType === "common_expenses"
        );
      }
      const billsInfo = {
        water: waterCompanyInfo,
        energy: energyCompanyInfo,
        gas: gasCompanyInfo,
        common_expenses: commonExpensesCompanyInfo,
      };

      if (companyTypes) {
        const filteredBillsInfo: BillsInfoByCompanyType = companyTypes.reduce(
          (result, key) => {
            result[key] = billsInfo[key];
            return result;
          },
          {}
        );
        return filteredBillsInfo;
      }
      return billsInfo;
    },
    { enabled: !!id && country !== "mx" }
  );
  return {
    isFetching,
    isLoading: isLoading || propertyLoading,
    error: error || propertyError,
    data,
  };
};

export default useBillsInfo;
