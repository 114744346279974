import { useHistory } from "react-router-dom";

import { Box, Grid, Typography } from "@material-ui/core";
import { IoCashOutline as CashIcon } from "@houm-com/ui-icons";

import CardMenuItem from "apps/landlord/components/CardMenuItem/CardMenuItem";

import usePropertyPriceStyles from "./PropertyPriceStyles";
import IconBox from "../IconBox";

interface Props {
  propertyId: string;
}

const PropertyPrice = ({ propertyId }: Props) => {
  const classes = usePropertyPriceStyles();
  const history = useHistory();

  const onHandleClick = () =>
    history.push(`/landlord/properties/${propertyId}/property-price`);

  return (
    <CardMenuItem
      title="Precio de tu publicación"
      isButtonAvailable
      buttonLabel="Agregar precio"
      buttonVariant="primary"
      onViewInfoClick={onHandleClick}
    >
      <Grid item>
        <Box className={classes.container}>
          <IconBox>
            <CashIcon color="#46788C" />
          </IconBox>
          <Typography className={classes.text}>
            Descubre como te ayudamos a establecer el precio ideal para tu
            propiedad
          </Typography>
        </Box>
      </Grid>
    </CardMenuItem>
  );
};

export default PropertyPrice;
