import { Typography } from "@material-ui/core";
import useUnpublishPropertyStyles from "../PropertyStatusManagerButtonStyles";

const UnpublishDialogDescription = () => {
  const classes = useUnpublishPropertyStyles();

  return (
    <>
      <Typography className={classes.paragraph}>
        Al continuar, tu propiedad será despublicada tanto{" "}
        <strong className={classes.paragraphStrong}>en Houm </strong>
        como en los <strong>principales portales del país.</strong>
      </Typography>
      <Typography className={classes.paragraph}>
        Ayúdanos a mejorar{" "}
        <span role="img" aria-label="emoji de por favor">
          🙏.
        </span>
        Por favor, cuéntanos{" "}
        <strong>por qué quieres despublicar tu propiedad.</strong>
      </Typography>
    </>
  );
};

export default UnpublishDialogDescription;
