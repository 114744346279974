import { useHistory } from "react-router-dom";

import { HomeOutlined } from "@material-ui/icons";

import { IProperty } from "apps/landlord/models/Property";

const useLandlordNavigation = (propertyData: IProperty) => {
  const history = useHistory();

  return [
    {
      onClick: () =>
        history.push(`/landlord/properties/${propertyData.uid}/details`),
      pageTitle: "Detalles",
      icon: <HomeOutlined />,
      disabled: false,
    },
  ];
};

export default useLandlordNavigation;
