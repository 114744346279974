import { format } from "date-fns";

import { ApplicationCodebtor } from "models/Evaluation";
import { numberWithCommas } from "utils";
import { getName, isMainApplicant } from "../../utils";

export const getNameMainApplicant = (applicants: ApplicationCodebtor[]) =>
  getName(applicants.find((applicant) => isMainApplicant(applicant)));

export const getCreatedAt = (createdAt: string) =>
  format(new Date(createdAt), "dd/MM/yyyy");

export const getTotalSalary = (totalSalary: number) =>
  `$${numberWithCommas(totalSalary)}`;
