import { UseQueryResult, useQuery } from "react-query";

import propertyContractServiceV2 from "apps/landlord/services/propertyContractServiceV2";
import { SignedContracts } from "apps/landlord/services/propertyContractServiceV2/types";
import { useGetToken } from "hooks";

const useDocuments = (contractId: number) => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    data,
    error,
    isSuccess,
    isError,
  }: UseQueryResult<SignedContracts, Error> = useQuery<SignedContracts, Error>(
    ["contract_signed_documents", contractId],
    async () => {
      const token = await getToken();
      const response =
        await propertyContractServiceV2.getSignedContractsDocuments(
          contractId,
          token
        );

      return response?.data;
    },
    {
      enabled: !!contractId,
    }
  );
  return { isLoading, data, error, isSuccess, isError };
};

export default useDocuments;
