import { MissingData } from "./types";

const INITIAL_MISSING_DATA: MissingData = {
  missingBasicServicesAccount: false,
  pendingPhotoSchedule: false,
  pendingSettingUpPrice: false,
  missingBankAccount: false,
  pendingPhotosButScheduled: false,
  pendingBasicCharacteristics: false,
};

export default INITIAL_MISSING_DATA;
