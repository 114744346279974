import currency from "@houm-com/formats/currency";
import { format } from "date-fns";

import { SummaryBreakdownModelResponse } from "apps/landlord/services/paymentService/types";

const parseCurrentBreakdown = (data: SummaryBreakdownModelResponse) => ({
  propertyId: data.property_id,
  lease: currency(data.lease ?? 0, data.currency),
  leaseAmount: data.lease ?? 0,
  additionalBreakdown: data.additional_breakdown.map((aditional) => ({
    detailId: aditional.detail_id,
    label: aditional.label,
    description: aditional.description,
    value: currency(aditional.value, data.currency),
  })),
  adjustments: currency(data.adjustments, data.currency),
  agreedDate:
    data.agreed_date &&
    format(new Date(`${data.agreed_date}T00:00:00`), "dd/MM/yyyy"),
  currency: data.currency,
  discountBreakdown: data.discount_breakdown.map((discount) => ({
    detailId: discount.detail_id,
    label: discount.label,
    description: discount.description,
    value: currency(discount.value, data.currency),
  })),
  management: currency(data.management ?? 0, data.currency),
  payment:
    data.deposit_status === "PAID"
      ? currency(data.payment, data.currency)
      : currency(0, data.currency),
  paymentLandlordDate: data.deposit_date
    ? format(new Date(`${data.deposit_date}T00:00:00`), "dd/MM/yyyy")
    : "",
  paymentTenantDate: data.lessee_payment_date
    ? format(new Date(`${data.lessee_payment_date}T00:00:00`), "dd/MM/yyyy")
    : "",
  paymentLandlordStatus: data.deposit_status,
  paymentTenantStatus: data.lessee_payment_status,
  tenantDebtDays: data.lessee_debt_days,
  tenantPayment: data.lessee_payment,
  tenantPaymentId: data.lessee_payment_id,
  landlordPaymentId: data.lessor_payment_id,
  planType: data.plan_type,
  bankAccountData: {
    document: data.deposit_bank_account.document,
    fullName: data.deposit_bank_account.full_name,
    email: data.deposit_bank_account.email,
    personId: data.deposit_bank_account.person_id,
    identifier: data.deposit_bank_account.identifier,
    bankName: data.deposit_bank_account.bank_name,
    bankCode: data.deposit_bank_account.bank_code,
    accountNumber: data.deposit_bank_account.account_number,
    accountType: data.deposit_bank_account.account_type,
  },
  lesseeMonthSinceDebt: new Date(`${data.lessee_month_since_debt}T00:00:00`),
});

export default parseCurrentBreakdown;
