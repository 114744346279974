import { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Grid } from "@material-ui/core";

import { useNegotiations } from "context/negotiationsContext/negotiationsContext";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import PropertyLayout from "apps/landlord/Layout/PropertyLayout";
import { useGetToken } from "hooks";

import useNegotiationIndexStyles from "./NegotiationsIndexStyles";
import NegotiationDetails from "./components/NegotiationDetails";
import TermsAndConditions from "./components/TermsAndConditions";
import NegotiationsList from "./components/NegotiationsList";
import ResumeOffer from "./components/ResumeOffer";
import {
  fetchData,
  setCurrentStep,
} from "context/negotiationsContext/negotiationsActions";
import OfferAnswered from "./components/OfferAnswered";

import RejectForm from "./components/RejectForm";

const Negotiations = () => {
  const { state, dispatch } = useNegotiations();
  const { currentStep, loading } = state;
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { getToken } = useGetToken();
  const classes = useNegotiationIndexStyles();

  useEffect(() => {
    getToken().then((authToken) => {
      fetchData(id, authToken, dispatch);
    });
  }, []);

  const handleBack = () => {
    if (currentStep === "negotiationsList") {
      history.push(`/landlord/properties/${id}?type=forSale`);
    }
    dispatch(setCurrentStep("negotiationsList"));
  };

  const negotiationSteps = {
    negotiationsList: <NegotiationsList />,
    negotiationDetails: <NegotiationDetails />,
    rejectForm: <RejectForm />,
    resumeOffer: <ResumeOffer />,
    offerAnswered: <OfferAnswered />,
    termsAndConditions: <TermsAndConditions />,
  };
  return (
    <PropertyLayout onHandleBack={handleBack}>
      <Grid container className={classes.root}>
        <Loading loading={loading} className={classes.loading}>
          {negotiationSteps[currentStep]}
        </Loading>
      </Grid>
    </PropertyLayout>
  );
};

export default Negotiations;
