import { useTranslation } from "react-i18next";
import { es as localeEs } from "date-fns/locale";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { capitalize } from "lodash";
import { format } from "date-fns";

import Button from "@houm-com/ui/Button";

import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import CallToActionDrawer from "apps/ui/CustomDrawer/CallToActionDrawer";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import ContentDrawer from "apps/ui/CustomDrawer/ContentDrawer";

import validationSchema from "./utils/validationSchema";
import { FormProps } from "./utils/types";
import PaymentHeader from "../PaymentHeader";

interface Props {
  buttonProps: {
    onHandlePayment: (e: string) => void;
    onHandleBack?: () => void;
    disabled?: boolean;
    isLoading?: boolean;
  };
  children?: React.ReactNode;
}

const withPaymentWrapper = (BaseComponent) =>
  function EnhancedComponent(props: Props) {
    const { setCurrentStep } = useCustomDrawer();
    const { state } = useRentPayment();
    const { t } = useTranslation(["payments", "rent-payment"]);
    const monthDate = format(new Date(state.paymentData.date), "MMMM", {
      locale: localeEs,
    });
    const title =
      state.paymentData.concept !== "LEASE"
        ? t("PAYMENT_METHOD_DETAIL_CONCEPT_TITLE", {
            concept: t(state.paymentData.concept),
          })
        : t("PAYMENT_METHOD_DETAIL_TITLE", {
            month: capitalize(monthDate),
            ns: "rent-payment",
          });

    const methods = useForm<FormProps>({
      resolver: yupResolver(validationSchema),
      reValidateMode: "onChange",
    });

    const {
      formState: { isDirty, isValid },
    } = methods;

    const onSubmit = (e: FormProps) =>
      props.buttonProps.onHandlePayment(e.paymentOption);

    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ContentDrawer>
            <PaymentHeader title={title} />
            <BaseComponent
              movementId={state.paymentData.movementId}
              textValue={`Valor ${t(state.paymentData.concept).toLowerCase()}`}
            />
            {props.children}
          </ContentDrawer>
          <CallToActionDrawer>
            <Button
              variant="tertiary"
              size="md"
              type="button"
              onClick={() => {
                if (props?.buttonProps?.onHandleBack) {
                  props.buttonProps.onHandleBack();
                } else {
                  setCurrentStep("paymentPlatforms");
                }
              }}
            >
              Atrás
            </Button>
            <Button
              variant="primary"
              size="md"
              type="submit"
              disabled={!(isValid || isDirty) || props.buttonProps?.disabled}
              isLoading={props.buttonProps?.isLoading}
            >
              Pagar
            </Button>
          </CallToActionDrawer>
        </form>
      </FormProvider>
    );
  };
export default withPaymentWrapper;
