import { useForm, FormProvider } from "react-hook-form";
import { Grid, Typography } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";

import FilteredInput from "../components/FilteredInput";
import CallToAction from "Components/Admin/UIComponents/atoms/CallToAction";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";

import { useLoiForm } from "../context/LoiFormContext";
import { LoiAddressData } from "../context/LoiFormContext.types";
import { addressDataFields } from "../schema/fields";
import { addressDataValidators } from "../schema/validations";
import useStepsStyles from "./StepsStyles";

const PersonalAddress = () => {
  const classes = useStepsStyles();
  const {
    actions: { setActiveStep, setAddressData },
    state: { activeStep, addressData, country, personalData },
  } = useLoiForm();

  const methods = useForm<LoiAddressData>({
    resolver: yupResolver(addressDataValidators),
    reValidateMode: "onBlur",
  });

  const onSubmit = (formData) => {
    setAddressData(formData);
    setActiveStep(activeStep + 1);
  };

  const ownerFullName = `${personalData.name} ${personalData.lastName || ""} ${
    personalData.secondLastName || ""
  }`;

  return (
    <Grid container className={classes.rootGrid}>
      <Grid item>
        <Typography className={classes.ownerNameLead}>
          propietario: {ownerFullName}
        </Typography>
        <Typography className={classes.title}>Dirección actual</Typography>
        <Typography className={classes.subtitle}>
          Necesitamos que nos indiques donde vives actualmente.
        </Typography>
      </Grid>
      <Grid item>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FilteredInput fieldInfo={addressDataFields.city()}>
              <ControlledTextField
                id="city"
                label={addressDataFields.city().label(country)}
                textFieldProps={{ placeholder: "Ingresar" }}
                defaultValue={addressData.city}
              />
            </FilteredInput>

            <FilteredInput fieldInfo={addressDataFields.commune()}>
              <ControlledTextField
                id="commune"
                label={addressDataFields.commune().label(country)}
                textFieldProps={{ placeholder: "Ingresar" }}
                defaultValue={addressData.commune}
              />
            </FilteredInput>

            <FilteredInput fieldInfo={addressDataFields.address()}>
              <ControlledTextField
                id="address"
                label={addressDataFields.address().label(country)}
                textFieldProps={{ placeholder: "Ingresar" }}
                defaultValue={addressData.address}
              />
            </FilteredInput>

            <FilteredInput fieldInfo={addressDataFields.outerNumber()}>
              <ControlledTextField
                id="outerNumber"
                label={addressDataFields.outerNumber().label(country)}
                textFieldProps={{ placeholder: "Ingresar" }}
                defaultValue={addressData.outerNumber}
              />
            </FilteredInput>

            <FilteredInput fieldInfo={addressDataFields.innerNumber()}>
              <ControlledTextField
                id="innerNumber"
                label={addressDataFields.innerNumber().label(country)}
                textFieldProps={{ placeholder: "Ingresar" }}
                defaultValue={addressData.innerNumber}
              />
            </FilteredInput>
            <CallToAction>
              <DesignSystemButton
                label="Continuar"
                type="submit"
                size="large"
                variant="primary"
                fullWidth
              />
            </CallToAction>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default PersonalAddress;
