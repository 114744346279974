import {
  InspectionReportFeaturesNames,
  InspectionReportItemsNames,
  InspectionReportQualifications,
  InspectionReportSpacesType,
} from "services/propertyService/types";

type InspectionReportQualificationTranslation = {
  label: string;
  value: InspectionReportQualifications;
};

export const inspectionReportQualificationOptions: InspectionReportQualificationTranslation[] =
  [
    { label: "Excelente", value: "excellent" },
    { label: "Bueno", value: "good" },
    { label: "Regular", value: "acceptable" },
    { label: "Malo", value: "bad" },
    { label: "No posee", value: "does_not_have" },
    { label: "Sin responder", value: "unanswered" },
  ];

type GeneralSpaceTranslation = {
  label: string;
  value: InspectionReportSpacesType;
};

export const inspectionReportGeneralSpaceTranslation: GeneralSpaceTranslation[] =
  [
    { label: "Living", value: "living_room" },
    { label: "Comedor", value: "dining_room" },
    { label: "Habitación", value: "room" },
    { label: "Baño", value: "bathroom" },
    { label: "Cocina", value: "kitchen" },
    { label: "Logia", value: "laundry_room" },
    { label: "Terraza", value: "terrace" },
    { label: "Otro", value: "other" },
  ];

type FeatureNameTranslation = {
  label: string;
  value: InspectionReportFeaturesNames;
};

export const inspectionReportFeaturesNameTranslation: FeatureNameTranslation[] =
  [
    { label: "Extractor", value: "bathroom_extractor" },
    { label: "Muebles-Gabinetes", value: "cabinets" },
    { label: "Chimenea", value: "chimney" },
    { label: "Closets", value: "closets" },
    { label: "Lavaplatos", value: "dishwasher" },
    { label: "Puertas", value: "doors" },
    { label: "Campana extractora", value: "extractor_hood" },
    { label: "Pisos", value: "floors" },
    { label: "Espejo", value: "mirror" },
    { label: "Horno", value: "oven" },
    { label: "Cielos", value: "roofs" },
    { label: "Ducha", value: "shower" },
    { label: "Shower Door (división de vidrio)", value: "shower_door" },
    { label: "Escaleras", value: "stairs" },
    { label: "Inodoro", value: "toilet" },
    { label: "Bañera / Tina", value: "tub" },
    { label: "Ventanales", value: "windows" },
    { label: "Paredes", value: "walls" },
    { label: "Lavamanos", value: "washbasin" },
    { label: "Presión agua", value: "water_pressure" },
    { label: "Encimera", value: "worktop" },
  ];

type ItemNameTranslation = {
  label: string;
  value: InspectionReportItemsNames;
};

export const inspectionReportItemsNameTranslation: ItemNameTranslation[] = [
  { label: "Alfombras", value: "carpets" },
  { label: "Conector cable coaxial", value: "coaxial_connectors" },
  { label: "Cortinas", value: "curtains" },
  { label: "Lámparas", value: "lamps" },
  { label: "Conectores de red", value: "network_connectors" },
  { label: "Enchufes", value: "plugs" },
  { label: "Interruptores", value: "switches" },
];

export enum InspectionReportQualiBasedRating {
  excellent = 5,
  good = 4,
  acceptable = 3,
  bad = 2,
  does_not_have = 1,
  unanswered = 0,
}

export const keysLocationTranslation = [
  { label: "Houmbox", value: "houmbox" },
  { label: "Propietario", value: "landlord" },
  { label: "Conserjería", value: "concierge" },
  { label: "Houmer", value: "appraiser" },
  { label: "Tercero", value: "third_party" },
];
