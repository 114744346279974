import { Box, Grid, Typography } from "@material-ui/core";

import { ENABLE_UNPUBLISH_PROPERTY } from "env";

import { useProperty } from "apps/landlord/hooks";
import Spinner from "Components/Shared/partials/Spinner";
import useDetailsInformationStyles from "./DetailsInformationStyles";
import DetailsElement from "./components/DetailsElement";
import {
  detailsBooleanElements,
  detailsElements,
} from "./constants/detailsElements";
import UnpublishPropertyButton from "../PropertyStatusManager";
import DetailsBooleanElement from "./components/DetailsBooleanElement";
import detailsAmenities from "./constants/amenitiesElements";
import DetailsPrice from "./components/DetailsPrice";

export default function DetailsInformation() {
  const {
    propertyData: {
      propertyDetails,
      country,
      amenities,
      forRental,
      propertyDetails: { status },
    },
    propertyLoading,
  } = useProperty({ refetchOnMount: "always" });
  const classes = useDetailsInformationStyles();
  if (propertyLoading) return <Spinner />;
  const price = forRental ? propertyDetails.price : propertyDetails.salesPrice;
  const currency = propertyDetails.moneyType;
  return (
    <Box className={classes.detailsRoot}>
      {ENABLE_UNPUBLISH_PROPERTY && <UnpublishPropertyButton />}
      {status !== "rented" && (
        <DetailsPrice price={price} currency={currency} forRental={forRental} />
      )}
      <Typography className={classes.detailsSubtitle}>
        Características
      </Typography>
      <Grid container xs={12} className={classes.characteristicsContainer}>
        {detailsElements(country, classes).map((element) => (
          <DetailsElement
            key={element.detailsKey}
            amount={propertyDetails[element.detailsKey]}
            icon={element.icon}
            text={
              propertyDetails[element.detailsKey]
                ? element.text
                : element.alternativeText
            }
            plural={
              ["totalArea", "builtArea", "age"].includes(element.detailsKey) ||
              propertyDetails[element.detailsKey] > 1
            }
          />
        ))}
        {detailsBooleanElements(classes).map(
          (element) =>
            propertyDetails[element.detailsKey] && (
              <DetailsBooleanElement key={element.detailsKey} {...element} />
            )
        )}
      </Grid>
      {Object.values(amenities).some((value) => value) && ( // Hide when there's no amenities
        <>
          <Typography className={classes.detailsSubtitle}>
            Amenidades
          </Typography>
          <Grid container xs={12} className={classes.characteristicsContainer}>
            {detailsAmenities.map(
              (element) =>
                amenities[element.amenitiesKey] && (
                  <DetailsBooleanElement
                    typographyComponent="li"
                    key={element.amenitiesKey}
                    {...element}
                  />
                )
            )}
          </Grid>
        </>
      )}
    </Box>
  );
}
