import { MouseEvent, ReactElement } from "react";

import { CardContent, Grid, CardActions } from "@material-ui/core";
import Card from "@material-ui/core/Card";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import usePropertyMobileStyles from "./PropertyMobileStyles";
import { Option, IProperty } from "../../utils/types";
import OptionList from "../OptionList/OptionList";
import PropertyAddress from "../PropertyAddress";
import PropertyImage from "../PropertyImage";
import getAddress from "../../utils/utils";

interface Props {
  labelButton?: string;
  handleClick?: () => void;
  property: IProperty;
  optionList?: Option[];
  onHandleOpenProperty?: () => void;
  statusLabel: string;
  chipClassName?: string;
  children?: ReactElement;
}

const PropertyMobile = ({
  labelButton,
  handleClick,
  property,
  optionList = [],
  onHandleOpenProperty,
  statusLabel,
  chipClassName = "",
  children,
}: Props) => {
  const classes = usePropertyMobileStyles();

  const onHandleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleClick();
  };

  return (
    <Card
      aria-label="property-mobile-card"
      className={classes.root}
      onClick={onHandleOpenProperty}
    >
      <CardContent className={classes.cardContainer}>
        <PropertyImage
          photo={property?.photoUrl}
          propertyId={property?.id}
          statusLabel={statusLabel}
          chipClassName={chipClassName}
        />
        <Grid container className={classes.detailsContainer}>
          <Grid item container className={classes.addressContainer}>
            <PropertyAddress property={getAddress(property)} />
          </Grid>
          <Grid item container className={classes.descriptionContainer}>
            {children}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.actionsContainer}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          {optionList.length > 0 && (
            <Grid item>
              <OptionList options={optionList} />
            </Grid>
          )}
          {handleClick && (
            <Grid item>
              <DesignSystemButton
                label={labelButton}
                variant="primary"
                size="small"
                onClick={(e) => onHandleClick(e)}
              />
            </Grid>
          )}
        </Grid>
      </CardActions>
    </Card>
  );
};

export default PropertyMobile;
