import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";

import profileEditService from "apps/account/services/profileEditService/profileEditService";
import { useGetToken } from "hooks";
import { useHoumSnackBar } from "libs/houm-snackbar";

const useChangePhone = () => {
  const history = useHistory();
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const { getToken } = useGetToken();
  const { mutate, isLoading, isError } = useMutation(
    async ({ phone }: { phone: string }) => {
      const authToken = await getToken();
      const response = await profileEditService.startPhoneChange(
        authToken,
        phone
      );
      return response.data;
    },
    {
      onSuccess: (data, vars) => {
        enqueueHoumSnackBar({
          head: "",
          body: "Te hemos enviado un mensaje de texto con un código de verificación",
          type: "success",
        });
        history.push(`/account/phone_verification/${vars.phone}`);
      },
      onError: () => {
        enqueueHoumSnackBar({
          head: "",
          body: "Ha ocurrido un error, por favor intenta de nuevo",
          type: "error",
        });
      },
    }
  );
  return {
    mutate,
    submitting: isLoading,
    error: isError,
  };
};

export default useChangePhone;
