/* eslint-disable max-lines */
import { useHistory } from "react-router-dom";
import { User } from "@auth0/auth0-react";

import {
  DescriptionOutlined,
  EmailOutlined,
  InsertDriveFileOutlined,
  MonetizationOnOutlined,
  PersonOutlineOutlined,
} from "@material-ui/icons";

import useInspectionReports from "hooks/inspectionReports/useInspectionReports";
import useContractPropertyInfo from "apps/landlord/hooks/useContractPropertyInfo";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { IProperty } from "apps/landlord/models/Property";
import { countryCode } from "utils";

import usePropertyStatus from "../../../../../hooks/usePropertyStatus";

const useRentalsLandlordNavigation = (user: User, propertyData: IProperty) => {
  const history = useHistory();
  const { data, isLoading, error } = useContractPropertyInfo();
  const { inspectionReportList, isLoading: isInspectionLoading } =
    useInspectionReports(propertyData?.id);
  const { isForSale, isRented } = usePropertyStatus(propertyData);
  const { uid, id, country, forSale } = propertyData;
  const propUrl = "/landlord/properties";

  return [
    {
      onClick: () => history.push(`${propUrl}/${uid}/payments`),
      pageTitle: "Mis Pagos",
      icon: <MonetizationOnOutlined />,
    },
    {
      onClick: () => {
        history.push(`${propUrl}/${uid}/plans`);
      },
      pageTitle: "Mi Plan",
      icon: isLoading ? <Loading loading /> : <EmailOutlined />,
      disabled: isLoading || !!error || propertyData.country !== "cl",
    },
    {
      onClick: () => {
        history.replace(`${propUrl}/${countryCode(country)}/applicant/${uid}`);
      },
      pageTitle: "Postulante",
      icon: <PersonOutlineOutlined />,
      disabled: propertyData.country === "co",
    },
    {
      onClick: () => {
        history.push(`${propUrl}/${uid}/documents`);
      },
      pageTitle: "Documentos",
      icon: <InsertDriveFileOutlined />,
      disabled: !isRented || propertyData.country === "co",
    },
    {
      onClick: () => history.push(`/landlord/closureForm/${uid}`),
      pageTitle: "Contrato",
      icon:
        isLoading && isRented ? <Loading loading /> : <DescriptionOutlined />,
      disabled: isLoading || !!error || data?.submitted || forSale || isRented,
    },
    {
      onClick: () =>
        history.push(
          `${propUrl}/${uid}/inspections-report?type=actas&propertyId=${id}`
        ),
      pageTitle: "Actas",
      icon: isInspectionLoading ? <Loading loading /> : <DescriptionOutlined />,
      disabled: !inspectionReportList,
    },
    ...(isForSale
      ? [
          {
            onClick: () => history.push(`/landlord/negotiations/${uid}`),
            pageTitle: "Negociación",
            icon: isInspectionLoading ? (
              <Loading loading />
            ) : (
              <DescriptionOutlined />
            ),
          },
        ]
      : []),
  ];
};

export default useRentalsLandlordNavigation;
