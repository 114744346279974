import { add, format, parse } from "date-fns";
import { IVisitsSchedules } from "../components/Forms/DayHoursPicker/utils";

import { ContractEndResponse } from "../services/tenantContractService/types";
import {
  ILeavingForm,
  IRepairRequest,
  ITenantContractEnd,
  ITenantContractEndBody,
} from "./types";

import { IRepairRequestBody } from "../services/tenantRepairsService/types";
import { LOCAL_BASED_MOTIVES_OPTIONS } from "../pages/ContractEndPage/ContractEnd/components/LeavingDateStep/components/LeavingDateForm/constants";
import { Locale } from "models/Countries";

export const parseTenantContractEnd = (
  data: ContractEndResponse
): ITenantContractEnd => ({
  estimatedDate: parse(data.estimated_date, "yyyy-MM-dd", new Date()),
  hasBegun: data.status === "IN_PROCESS",
  status: data.status,
  beginAt: parse(data.begin_at, "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'", new Date()),
  comment: data.comment,
});

export const encodeContractEnd = (
  data: ILeavingForm,
  contractDate: Date,
  country: Locale
): ITenantContractEndBody => {
  const oneYearFromContract = add(contractDate, { years: 1 });
  const type =
    data.date >= oneYearFromContract ? "NO_RENOVATION" : "EARLY_FINISH";
  return {
    type,
    estimated_date: format(data.date, "yyyy-MM-dd"),
    comment: LOCAL_BASED_MOTIVES_OPTIONS[country].find(
      (option) => option.value === data.motive
    ).label,
  };
};

export const encodeVisitSchedules = (data: IVisitsSchedules[]) => {
  const availability = data.reduce((acc, { checked, hours }, index) => {
    if (!checked) return acc;
    hours.forEach(([begin, end]) => {
      acc.push({
        day: index + 1,
        begin_hour: localHourToUtcHour(begin),
        end_hour: localHourToUtcHour(end),
      });
    });
    return acc;
  }, []);
  return { availability };
};

export const encodeRepairRequest = (
  data: IRepairRequest,
  filenames
): IRepairRequestBody => {
  const { repairType, observation, visitHours } = data;
  const availabilities = visitHours.reduce((acc, { checked, hours }, index) => {
    if (!checked) return acc;

    const [start, end] = hours;
    for (let hour = start; hour < end; hour++) {
      acc.push({ day: index + 1, begin_hour: hour, end_hour: hour + 1 });
    }

    return acc;
  }, []);
  const photos = filenames.map((filename) => ({ photo_filename: filename }));
  return {
    repairment_type: repairType,
    observation,
    photos,
    availabilities,
    requestor_role: "tenant",
    origin: "admin_tenant",
    responsible_team: "field_ops_regional",
  };
};

export const localHourToUtcHour = (hour: number): number => {
  const date = new Date();
  date.setHours(hour);
  const utcHour = date.getUTCHours();
  return utcHour;
};
