import ILocalBasedText from "apps/tenant/pages/ContractEndPage/types";

export interface Option {
  label: string;
  value: number;
}

const LEAVING_MOTIVES_OPTIONS = [
  { label: "Encontré otra propiedad mejor dentro de Houm", value: 1 },
  {
    label: "Encontré otra propiedad mejor con otro corredor o particular",
    value: 2,
  },
  { label: "Compré una propiedad", value: 3 },
  {
    label: "Tengo problemas económicos y no puedo continuar con la propiedad",
    value: 4,
  },
  { label: "La propiedad necesita reparaciones", value: 5 },
  { label: "No me gustó el servicio de Houm", value: 6 },
];

const MX_LEAVING_MOTIVES_OPTIONS = [
  { label: "Encontré otro inmueble mejor dentro de Houm", value: 1 },
  {
    label: "Encontré otro inmueble mejor con otro corredor o particular",
    value: 2,
  },
  { label: "Compré un inmueble", value: 3 },
  {
    label: "Tengo problemas económicos y no puedo continuar con el inmueble",
    value: 4,
  },
  { label: "El inmueble necesita reparaciones", value: 5 },
  { label: "No me gustó el servicio de Houm", value: 6 },
];

export const LOCAL_BASED_MOTIVES_OPTIONS = {
  cl: LEAVING_MOTIVES_OPTIONS,
  co: LEAVING_MOTIVES_OPTIONS,
  mx: MX_LEAVING_MOTIVES_OPTIONS,
};

export const LOCAL_BASED_LABEL: ILocalBasedText = {
  cl: "¿Por qué decides finalizar tu contrato de arriendo?",
  co: "¿Por qué decides finalizar tu contrato de arriendo? ",
  mx: "¿Por qué decides finalizar tu contrato de renta?",
};

export const LOCAL_BASED_INITIAL_DAYS = {
  cl: 31,
  co: 31,
  mx: 61,
};
