import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import { useApplicant } from "../../context/ApplicantContext";
import ApplicantEvaluationForm from "./components/ApplicantEvaluationForm";
import useEvaluationDialogStyles from "./EvaluationDialogStyles";

const EvaluationDialog = () => {
  const classes = useEvaluationDialogStyles();
  const { EvaluationModalApplication, handleEvaluationModalApplication } =
    useApplicant();

  return (
    <CustomDialog
      open={Boolean(EvaluationModalApplication)}
      onClose={() => handleEvaluationModalApplication(null)}
      title="Evaluación 🧐"
      classes={{
        title: classes.title,
        content: classes.contentDialog,
      }}
    >
      <ApplicantEvaluationForm />
    </CustomDialog>
  );
};

export default EvaluationDialog;
