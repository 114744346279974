import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import CustomDialog from "apps/lead/components/CustomDialog";

import { useEvaluation } from "apps/lead/context/evaluationContext/EvaluationContext";
import { useAlertMessage } from "apps/lead/hooks/useAlertMessage";
import AlertSnackbar from "apps/lead/components/AlertSnackbar";

import WarningTitle from "./components/WarningNoCodebtor/components/WarningTitle";
import WarningNoCodebtor from "./components/WarningNoCodebtor";
import { useEvaluatorForm } from "./hooks/useEvaluatorForm";
import ResumeForm from "./components/ResumeForm";
import useResumeStyles from "./ResumeStyles";
import Title from "./components/Title";

const Resume = () => {
  const history = useHistory();
  const { state } = useEvaluation();
  const classes = useResumeStyles();
  const [openDialog, setOpenDialog] = useState(false);

  const { doCreateEval, isSubmitting, isSuccess } = useEvaluatorForm();
  const { responseMessage, handleCloseAlert, handleOpenAlert } =
    useAlertMessage(() => history.push("/tenant"));

  const handleDialog = useCallback(() => {
    setOpenDialog((prevState) => !prevState);
  }, []);

  const handleCreateEvaluation = useCallback(() => {
    doCreateEval();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      handleOpenAlert(
        true,
        "success",
        "La evaluación se ha creado exitosamente"
      );
    }
  }, [isSuccess]);

  return (
    <>
      <Header
        title="Resumen"
        subtitle={<Title />}
        activeStep={state.activeStep}
        steps={state.steps}
      />
      <ResumeForm
        openDialog={handleDialog}
        createEvaluation={handleCreateEvaluation}
        isLoading={isSubmitting}
      />
      <CustomDialog
        title={<WarningTitle />}
        open={openDialog}
        onClose={handleDialog}
        customizedButtonClassName={classes.customCloseButton}
      >
        <WarningNoCodebtor
          createEvaluation={handleCreateEvaluation}
          isLoading={isSubmitting}
        />
      </CustomDialog>
      <AlertSnackbar
        color={responseMessage?.type === "success" ? "#48AA02" : "#EA1601"}
        reason={responseMessage?.message}
        handleClose={handleCloseAlert}
        open={responseMessage.open}
      />
    </>
  );
};

export default Resume;
