export const LOCALE_BASED_HELP_LINKS = {
  cl: "https://api.whatsapp.com/send/?app_absent=0&phone=%2B56994767485&text=%C2%A1Hola%21%20Quisiera%20recibir%20ayuda%20de%20Houm.",
  mx: "https://api.whatsapp.com/send/?app_absent=0&phone=%2B525588971173&text=%C2%A1Hola%21%20Quisiera%20recibir%20ayuda%20de%20Houm.",
  co: "https://api.whatsapp.com/send/?app_absent=0&phone=%2B573174330439&text=%C2%A1Hola%21%20Quisiera%20recibir%20ayuda%20de%20Houm.",
};

export const LOCALE_BASED_MOTIVES_TITLE = {
  cl: "Que día quieres que tu arrendatario deje la propiedad",
  mx: "Que día quieres que tu inquilino deje el inmueble",
  co: "Que día quieres que tu arrendatario deje la propiedad",
};

export const LOCALE_BASED_MOTIVES_DESCRIPTION = {
  cl: "Revisaremos por que decides finalizar el contrato de tu arrendatario para poder seguir ayudándote.",
  mx: "Revisaremos por que decides finalizar el contrato de tu renta para poder seguir ayudándote.",
  co: "Revisaremos por que decides finalizar el contrato de tu arrendatario para poder seguir ayudándote.",
};

export const LOCALE_BASED_UNSAFE_DATE_WARNING = {
  cl: "Para solicitar la salida de tu arrendatario, es necesario que se cumpla el plazo establecido en el contrato. Si deseas solicitar la salida con anterioridad a dicho plazo, contáctanos",
  mx: "Para solicitar la salida de tu inquilino, es necesario que se cumpla el plazo establecido en el contrato. Si deseas solicitar la salida con anterioridad a dicho plazo, contáctanos",
  co: "Para solicitar la salida de tu arrendatario, es necesario que se cumpla el plazo establecido en el contrato. Si deseas solicitar la salida con anterioridad a dicho plazo, contáctanos",
};
