import { useQuery, UseQueryResult } from "react-query";
import { format } from "date-fns";

import { SummaryBreakdownModelResponse } from "apps/landlord/services/paymentService/types";
import paymentService from "apps/landlord/services/paymentService";
import { useGetCurrentUser, useGetToken } from "hooks";
import { Locale } from "models/Countries";
import { countryCode } from "utils";

import { ICurrentMonthBreakdownParsed } from "./types";
import parseCurrentBreakdown from "./parsers";

const getFilteredData = (
  data: SummaryBreakdownModelResponse[],
  propertyId: number
) => {
  if (data.length === 0) return null;
  const summaryFilter =
    data.length > 1
      ? data.filter((summary) => summary.deposit_status !== "CANCELED")
      : data;
  const filterByCurrentProperty = summaryFilter.find(
    (summary) => summary.property_id === propertyId
  );
  return filterByCurrentProperty;
};

const useCurrentSummaryBreakdown = (propertyId: number, country: Locale) => {
  const { getToken } = useGetToken();
  const { user, loading } = useGetCurrentUser();

  const {
    isLoading,
    data,
    isError,
    refetch,
  }: UseQueryResult<ICurrentMonthBreakdownParsed, Error> = useQuery<
    ICurrentMonthBreakdownParsed,
    Error
  >(
    ["current_month_breakdown", propertyId, user],
    async () => {
      const authToken = await getToken();
      const response = await paymentService.getPropertySummaryFullBreakdown(
        countryCode(country),
        user?.id,
        authToken,
        {
          date: format(new Date(), "yyyy-MM-dd"),
          properties_ids: [propertyId],
        }
      );
      const summaryData = response?.data.properties;
      if (summaryData.length === 0) return null;
      const filteredData = getFilteredData(summaryData, propertyId);
      const parsedData = parseCurrentBreakdown(filteredData);
      return parsedData;
    },
    {
      enabled: !!user,
      retry: false,
    }
  );

  return {
    isLoading: isLoading || loading,
    isError,
    data,
    refetch,
  };
};

export default useCurrentSummaryBreakdown;
