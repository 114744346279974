import { Theme } from "@material-ui/core";

const sharedFormStyles = (theme: Theme) => ({
  label: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1.5,
    fontWeight: 700,
    color: ({ disabled }: { disabled?: boolean }) =>
      disabled ? "#BFCBD1" : "inherit",
  },
  required: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.primary.main,
    lineHeight: 1.5,
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  message: {
    fontSize: theme.typography.pxToRem(12),
  },
});

export default sharedFormStyles;
