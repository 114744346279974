import { useHistory, useParams } from "react-router-dom";

import { Box, Typography } from "@material-ui/core";

import SingleDigitInput from "apps/ui/formComponents/SingleDigitInput";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import usePhoneVerificationStyles from "./PhoneVerificationStyles";
import useVerifyPhone from "./hooks/useVerifyPhone";

const PhoneVerification = () => {
  const classes = usePhoneVerificationStyles();
  const history = useHistory();
  const { phone } = useParams<{ phone: string }>();
  const { mutate, submitting } = useVerifyPhone();

  return (
    <Box className={classes.phoneVerificationRoot}>
      <Typography variant="h1" className={classes.title}>
        Confirma tu número de celular
      </Typography>
      <Typography className={classes.text}>
        Ingresa el código SMS de 6 dígitos que hemos enviado a tu teléfono
        celular
      </Typography>
      <Typography className={classes.text}>
        <strong>{phone}</strong>
      </Typography>
      <Box className={classes.codeInputSection}>
        <SingleDigitInput
          callback={(code) => mutate({ code })}
          submitting={submitting}
        />
        <Typography className={classes.explanationText}>
          El código puede tardar hasta 3 minutos en llegar. Aún no recibe el
          código, <strong>reenviar aquí</strong>
        </Typography>
      </Box>
      <DesignSystemButton
        variant="tertiaryB"
        label="Editar número"
        size="small"
        onClick={history.goBack}
      />
    </Box>
  );
};

export default PhoneVerification;
