import { makeStyles, Theme } from "@material-ui/core";

const useDisclaimerFilesStyles = makeStyles<Theme>((theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    marginTop: theme.spacing(1),
  },
}));

export default useDisclaimerFilesStyles;
