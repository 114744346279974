import { useQuery, UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";
import { alert } from "@houm-com/ui/Alert";

import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import {
  setCountry,
  setPaymentData,
} from "apps/payments/context/rentPaymentContext/rentPaymentActions";
import { PaymentDataProps } from "apps/payments/context/rentPaymentContext/rentPaymentTypes";
import { IMovement } from "apps/payments/services/paymentService/types";
import paymentService from "apps/payments/services/paymentService";
import { Locale } from "models/Countries";

const movementConcept = {
  contract: "contracts",
  lease: "leases",
  reservation: "reservations",
};

const parseMovement = (
  data: Partial<IMovement>
): Partial<PaymentDataProps> => ({
  currency: data.currency,
  date: data.agreed_date ? new Date(data.agreed_date) : null,
  discounts: data.discounts,
  movementId: data.movement_id,
  originalPrice: data.original_amount?.toString(),
  price: data.total_amount?.toString(),
  concept: data.concept,
  status: data.status,
  propertyId: data.property_id,
});

export interface UseMovementDataProps extends Partial<PaymentDataProps> {
  user: {
    userName: string;
    email: string;
  };
}

const useMovementData = () => {
  const { id, countryCode, movementType } = useParams<{
    id: string;
    countryCode: Locale;
    movementType: "contract" | "reservation" | "lease";
  }>();
  const { dispatch } = useRentPayment();

  const query: UseQueryResult<UseMovementDataProps, Error> = useQuery(
    ["movements", id, movementType],
    async () => {
      const { data } = await paymentService.getPayMovementInfo(
        id,
        movementConcept[movementType],
        countryCode
      );
      const parsedData = parseMovement(data);
      dispatch(setPaymentData(parsedData));
      dispatch(setCountry(countryCode));
      return {
        ...parsedData,
        user: {
          userName: data.person_data.full_name,
          email: data.person_data.email,
        },
      };
    },
    {
      enabled: !!id,
      onError: () =>
        alert.error({
          message: "Error al obtener detalles de pago",
        }),
    }
  );
  return {
    ...query,
  };
};

export default useMovementData;
