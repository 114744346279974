import { useHistory } from "react-router-dom";

import { Box } from "@material-ui/core";

import PropertyLayout from "apps/landlord/Layout/PropertyLayout";
import Spinner from "Components/Shared/partials/Spinner";
import { useProperty } from "apps/landlord/hooks";

import PropertyDetailsTitleBanner from "./components/PropertyDetailsTitleBanner";
import PropertyDetailsContent from "./components/PropertyDetailsContent";
import usePropertyDetailsStyles from "./PropertyDetailsStyles";

export default function PropertyDetails() {
  const classes = usePropertyDetailsStyles();
  const { propertyData, propertyLoading } = useProperty();

  const history = useHistory();

  return (
    <PropertyLayout
      titlePage="Detalles de la propiedad"
      subtitlePage={propertyData?.address}
      onHandleBack={() =>
        history.push(`/landlord/properties/${propertyData?.uid}`)
      }
      rootClassName={classes.root}
    >
      {propertyLoading ? (
        <Spinner />
      ) : (
        <>
          <PropertyDetailsTitleBanner propertyData={propertyData} />
          <Box className={classes.propertyDetailsContent}>
            <PropertyDetailsContent locale={propertyData.country} />
          </Box>
        </>
      )}
    </PropertyLayout>
  );
}
