import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

const useNegotiationIndexStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(2, 13),
    },
  },
  loading: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginTop: theme.spacing(5),
    },
  },
  mobileBack: {
    position: "fixed",
    top: theme.spacing(1.5),
    left: theme.spacing(2.5),
    zIndex: theme.zIndex.appBar,
  },
  navigation: {
    display: "flex",
    color: theme.palette.primary.main,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(2),
      cursor: "pointer",
    },
  },
}));

export default useNegotiationIndexStyles;
