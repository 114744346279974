import TagType from "Components/Admin/UIComponents/DesignSystem/DSTag/types";
import { EvaluationStatus } from "models/Evaluation";

export const getStatusType = (status: EvaluationStatus) => {
  switch (status) {
    case EvaluationStatus.REEVALUATE:
      return TagType.WARNING;
    case EvaluationStatus.REJECTED:
    default:
      return TagType.ERROR;
  }
};

export const getStatusText = (status: EvaluationStatus) => {
  switch (status) {
    case EvaluationStatus.REEVALUATE:
      return "Descartado";
    case EvaluationStatus.REJECTED:
    default:
      return "Rechazado";
  }
};
