import { makeStyles, Theme } from "@material-ui/core/";

const useWhereToFindInfoLinkStyles = makeStyles<Theme>((theme) => ({
  whereToFindInfo: {
    "&[open]": {
      "& > $whereToFindInfoLink": {
        "& > svg": {
          transform: "rotate(180deg)",
        },
      },
    },
  },
  whereToFindInfoLink: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    "&::marker": {
      content: "none",
    },
    "& > svg": {
      transform: "rotate(0deg)",
      transition: "transform 0.1s ease-in-out",
    },
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(1.1)",
    },
  },
  whereToFindInfoIcon: {
    display: "inline",
    transform: "scale(0.7)",
  },
  InfoImage: {
    maxWidth: "100%",
    height: "auto",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "100%",
    },
  },
  comunidadFelizModal: {
    borderRadius: 16,
    padding: theme.spacing(1, 0),
    minWidth: 300,
    display: "flex",
    overflowY: "inherit",
    margin: theme.spacing(0, 2.1),
    [theme.breakpoints.up("md")]: {
      margin: "inherit",
      minWidth: 810,
      padding: theme.spacing(3, 0),
    },
  },
}));

export default useWhereToFindInfoLinkStyles;
