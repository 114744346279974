import React, { useEffect, useState } from "react";

import {
  ClickAwayListener,
  Collapse,
  Grow,
  List,
  ListItem,
  Paper,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { cn } from "@houm-com/ui/utils";
import { IoChevronDownOutline } from "@houm-com/ui-icons";
import LightButton from "@houm-com/ui/LightButton";

import { useUserNavbarMenuStyles } from "./UserNavbarMenuStyles";

interface NavbarMenuProps {
  label: string;
  children: Array<React.ReactNode>;
  isOpenPropietaryRental?: boolean;
}

const NavbarMenu = ({
  label,
  children,
  isOpenPropietaryRental = true,
}: NavbarMenuProps) => {
  const classes = useUserNavbarMenuStyles();
  const [isOpen, setOpen] = useState(false);
  const theme = useTheme();
  const isDesktop: boolean = useMediaQuery(theme.breakpoints.up("sm"));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    handleClose();
  }, [isOpenPropietaryRental]);

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={classes["navbar-item"]}>
        <div className={classes["navbar-button-wrap"]}>
          <LightButton
            onClick={() => (isOpen ? handleClose() : handleOpen())}
            variant="secondary"
            size="md"
            className="!font-normal"
            icon={
              <IoChevronDownOutline
                className={cn({
                  [classes["navbar-menu-arrow-open"]]: isOpen,
                  [classes["navbar-menu-arrow-closed"]]: !isOpen,
                })}
              />
            }
          >
            {label}
          </LightButton>
        </div>

        <div className={classes["navbar-popper-wrap"]}>
          {!isDesktop && (
            <Collapse in={isOpen} unmountOnExit>
              <List component="div" disablePadding>
                {children.map((item, i) => (
                  <ListItem
                    key={`menu-list-${i}`}
                    id={`menu-list-${i}`}
                    className={classes["navbar-menu-list"]}
                    onKeyDown={handleListKeyDown}
                  >
                    {item}
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
          {isDesktop && (
            <Grow in={isOpen} unmountOnExit>
              <Paper className={classes.navbarMenuPaper}>
                <List component="div" disablePadding>
                  {children.map((item, i) => (
                    <ListItem
                      key={`menu-list-${i}`}
                      id={`menu-list-${i}`}
                      className={classes["navbar-menu-list"]}
                      onKeyDown={handleListKeyDown}
                    >
                      {item}
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grow>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default NavbarMenu;
