import TenantLayout from "apps/tenant/layout/TenantLayout";
import VoucherDetails from "./VoucherDetails";

export default function () {
  return (
    <TenantLayout>
      <VoucherDetails />
    </TenantLayout>
  );
}
