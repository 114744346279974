import { makeStyles, Theme } from "@material-ui/core/styles";

const useCustomFileDropzone = makeStyles<Theme>((theme) => ({
  label: {
    fontWeight: 700,
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  requiredLabel: {
    "&::after": {
      content: '"*"',
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(0.5),
    },
  },
  description: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: theme.spacing(0.5),
  },
  wrapper: {
    width: "100%",
  },
}));

export default useCustomFileDropzone;
