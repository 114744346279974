import { createContext, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { useGetToken } from "hooks/useGetToken";
import contractService from "services/contractService";
import newEvaluationService from "services/newEvaluationService";
import { Application } from "models/Evaluation";
import {
  ApplicantContextData,
  ApplicantContextProviderProps,
  INITIAL_DATA,
  IParams,
  Views,
} from "./ApplicantContext.types";
import {
  genericError,
  getError,
  getHistorialApplicantions,
  getLastApplication,
} from "./ApplicantContext.utils";

const ApplicantContext = createContext<ApplicantContextData>(INITIAL_DATA);

export const ApplicantContextProvider = ({
  children,
}: ApplicantContextProviderProps) => {
  const { countryCode, id: propertyUid } = useParams<IParams>();
  const { getToken } = useGetToken();
  const history = useHistory();

  const [applications, setApplications] = useState(INITIAL_DATA.applications);
  const [contract, setContract] = useState(INITIAL_DATA.contract);
  const [isLoading, setIsLoading] = useState(INITIAL_DATA.isLoading);
  const [response, setResponse] = useState(INITIAL_DATA.submitResponse);
  const [currentView, setCurrentView] = useState(INITIAL_DATA.currentView);
  const [EvaluationModalApplication, setEvaluationModalApplication] =
    useState<Application>(INITIAL_DATA.EvaluationModalApplication);
  const [selectedApplication, setSelectedApplication] = useState<Application>();

  const handleSnackbarClose = () =>
    response.title === "Proceso completado"
      ? history.push(`/landlord/closureForm/${contract.property.uid}`)
      : setResponse({ status: null, title: "", message: "" });

  const handleBack = () =>
    currentView === Views.MAIN
      ? history.push(`/landlord/properties/${propertyUid}`)
      : setCurrentView(Views.MAIN);

  const getEvaluation = async (authToken: string) => {
    await newEvaluationService
      .getLandlordEvaluations(propertyUid, countryCode, authToken)
      .then((applicantData) => setApplications(applicantData.data))
      .catch(() => {
        throw new Error(`No se pudo obtener los datos de los postulantes`);
      });
  };

  const getContract = async (authToken: string) => {
    await contractService
      .getContractByProperty(propertyUid, authToken)
      .then((applicantData) => setContract(applicantData.data))
      .catch((error) => {
        if (error.response.status !== 404) {
          throw new Error(`No se pudo obtener los datos de los postulantes`);
        }
      });
  };

  const getApplicants = async (authToken: string) =>
    Promise.all([getEvaluation(authToken), getContract(authToken)])
      .catch((error) => setResponse(getError(error)))
      .finally(() => setIsLoading(false));

  useEffect(() => {
    getToken()
      .then((authToken) => getApplicants(authToken))
      .catch(() => Swal.fire(genericError));
  }, []);

  // FIXME; this is a hack to avoid the error: "Context.Consumer rendered with multiple children"
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const payload = {
    EvaluationModalApplication,
    selectedApplication,
    currentView,
    countryCode,
    lastApplication: getLastApplication(applications),
    historialApplications: getHistorialApplicantions(applications),
    contract,
    isLoading,
    submitResponse: response,
    handleBack,
    handleSnackbarClose,
    handleSubmitResponse: setResponse,
    handleCurrentView: setCurrentView,
    handleEvaluationModalApplication: setEvaluationModalApplication,
    handleSelectedApplication: setSelectedApplication,
  };

  return (
    <ApplicantContext.Provider value={payload}>
      {children}
    </ApplicantContext.Provider>
  );
};

export const useApplicant = () => useContext(ApplicantContext);
