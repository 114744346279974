import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  edit: boolean;
}

const useButtonsSectionStyles = makeStyles<Theme, Props>((theme) => ({
  buttonsSectionRoot: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      justifyContent: ({ edit }) => (edit ? "flex-end" : "flex-start"),
    },
  },

  button: {
    padding: theme.spacing(1, 3),
    [theme.breakpoints.up("sm")]: {
      marginLeft: ({ edit }) => (edit ? theme.spacing(2) : 0),
    },
  },
}));

export default useButtonsSectionStyles;
