import { Theme, makeStyles } from "@material-ui/core";

export default makeStyles<Theme, { justifyContent: string; size: string }>(
  (theme) => ({
    wrapper: {
      display: "flex",
      margin: "0px",
      width: "100%",
      padding: theme.spacing(0.3),
      justifyContent: (props) => props.justifyContent,
    },
    label: {
      marginBottom: "0px",
      fontSize: (props) => (props.size === "small" ? "0.8rem" : "0.875rem"),
      [theme.breakpoints.down("sm")]: {
        fontSize: (props) => (props.size === "small" ? "0.7rem" : "0.875rem"),
      },
    },
    box: {
      marginLeft: (props) =>
        props.size === "small" ? theme.spacing(0) : theme.spacing(6),
      transform: (props) =>
        props.size === "small" ? "scale(0.9)" : "scale(1.2)",
      [theme.breakpoints.down("sm")]: {
        marginLeft: (props) =>
          props.size === "small" ? theme.spacing(0) : theme.spacing(4),
        transform: "scale(0.9)",
      },
    },
    buttonsList: {
      marginTop: theme.spacing(1),
    },
  })
);
