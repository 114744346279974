// eliminar una vez que el endpoint se haga público
export const docTypesCL = [
  {
    id: 1,
    type: "Rut",
    validation_regex: "^\\d{1,2}\\.?\\d{3}\\.?\\d{3}[-]?[0-9kK]{1}$",
  },
];

export const docTypesCO = [
  { id: 2, type: "CC", validation_regex: "^\\d{4,12}|-$" },
  { id: 3, type: "NIT", validation_regex: "^[0-9]+-?[0-9]{1}$" },
  { id: 4, type: "Pasaporte", validation_regex: "^(?!^0+$)[a-zA-Z0-9]{6,9}$" },
  { id: 11, type: "Cédula de extranjería", validation_regex: "^\\d{6,7}$" },
  { id: 12, type: "PEP", validation_regex: "/^[a-zA-Z]{0,9}$/" },
];

export const docTypesMX = [
  { id: 5, type: "INE", validation_regex: "^\\d{13}|-$" },
  {
    id: 7,
    type: "Pasaporte mexicano",
    validation_regex: "^[a-zA-Z]{1}\\d{8}|-$",
  },
  {
    id: 9,
    type: "RFC",
    validation_regex:
      "^([A-ZÑ\\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\\d]{3})|-$",
  },
];
