import { useFormContext } from "react-hook-form";
import FieldInput from "@houm-com/ui/FieldInput";
import { IClientInfo } from "apps/landlord/services/propertiesDuesService/type";
import WhereToFindInfoLink from "./components/WhereToFindInfoLink";

interface Props {
  clientInfo?: IClientInfo | null;
}

export default function ComunidadFelizFields({ clientInfo = null }: Props) {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  return (
    <>
      <FieldInput
        id="communityName"
        label="Nombre de la comunidad"
        defaultValue={clientInfo?.communityName}
        errorMessage={errors.communityName?.message}
        {...register("communityName")}
      />
      <FieldInput
        id="unitNumber"
        label="Número de la unidad"
        defaultValue={clientInfo?.unit}
        errorMessage={errors.unitNumber?.message}
        {...register("unitNumber")}
      />
      <FieldInput
        id="receiptNumber"
        label="Código de la boleta"
        defaultValue={clientInfo?.externalId}
        errorMessage={errors.receiptNumber?.message}
        {...register("receiptNumber")}
      />
      <WhereToFindInfoLink />
    </>
  );
}
