import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@material-ui/core";
import { IoExitOutline } from "@houm-com/ui-icons";

import useAuthLogoutButtonStyles from "./AuthLogoutButtonStyles";

const AuthLogoutButton = () => {
  const { logout } = useAuth0();
  const classes = useAuthLogoutButtonStyles();

  return (
    <div className="flex items-center gap-2" onClick={() => logout()}>
      <IoExitOutline className="h-[20px] w-[20px] text-primary-cta" />
      <Typography className={classes.menuLink}>Cerrar sesión</Typography>
    </div>
  );
};

export default AuthLogoutButton;
