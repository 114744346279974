import { makeStyles, Theme } from "@material-ui/core";

const useMobileHeaderStyles = makeStyles((theme: Theme) => ({
  mobileHeaderRoot: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    marginBottom: theme.spacing(0.5),
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default useMobileHeaderStyles;
