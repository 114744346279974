import { AxiosResponse } from "axios";
import { createHttp, createNoAuthHttp } from "services/helper";
import { Application } from "./types";

export default {
  getApplications(token: string): Promise<AxiosResponse<Application[]>> {
    return createHttp(token).get(`/tenant/v2/applicants/`);
  },
  getApplication(uid: string): Promise<AxiosResponse<Partial<Application>>> {
    return createNoAuthHttp().get(`/tenant/applicants/${uid}/detail`);
  },
};
