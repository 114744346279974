import { SimilarProperty } from "apps/landlord/services/propertyService/type";
import {
  parseMarketPlaceStats,
  parsePhoto,
  parsePropertyDetails,
  parsePropertySalesDetails,
} from "../utils/parsers";

const parseSimilarProperty = (property: SimilarProperty) => ({
  id: property.id,
  address: property.address,
  marketplaceStats: parseMarketPlaceStats(property.marketplace_stats),
  photos: parsePhoto(property.photos),
  ...(property.property_details && {
    propertyDetails: parsePropertyDetails(property.property_details),
  }),
  ...(property.sales_details && {
    salesDetails: parsePropertySalesDetails(property.sales_details),
  }),
  marketplaceUrl: property.marketplace_url,
  portal: property?.portal,
});

export default parseSimilarProperty;
