import qs from "query-string";

import { createHttp } from "services/helper";

export default {
  getAppointments: (filters: Record<any, any>, token: string, email: string) =>
    createHttp(token).get(
      `/demand/v2/get_visits?${qs.stringify({
        ...filters,
        email,
      })}`
    ),
};
