import { useEffect, useRef, useState } from "react";

interface Props {
  disabled?: boolean;
}

const useSelectDropdown = ({ disabled }: Props) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target as HTMLElement)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [buttonRef, setOpen]);

  const handleClick = () => !disabled && setOpen(!open);

  return {
    buttonRef,
    handleClick,
    open,
    setOpen,
  };
};

export default useSelectDropdown;
