import { Grid, Typography } from "@material-ui/core";

import { useHeaderReferPartnerStyles } from "./HeaderReferPartnerStyles";

const HeaderReferPartner = () => {
  const classes = useHeaderReferPartnerStyles();

  return (
    <Grid item lg={12} className={classes.titleContainer}>
      <Typography variant="h3" className={classes.title}>
        Invita, gana y disfruta{" "}
        <span role="img" aria-label="money">
          💸
        </span>
      </Typography>
      <Typography variant="subtitle1" className={classes.subTitle}>
        Refiere a alguien que arriende o venda su propiedad. Lo contactaremos y,
        si hace negocios con nosotros, ¡te compensamos!
      </Typography>
    </Grid>
  );
};

export default HeaderReferPartner;
