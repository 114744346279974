import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      paddingInline: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    marginBottom: 0,
  },

  floatMenuContainer: {
    marginRight: theme.spacing(8),
  },
  selector: {
    marginTop: -16,
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
  listContainer: {
    maxHeight: "100%",
    overflow: "none",
    [theme.breakpoints.up("md")]: {
      maxHeight: "75vh",
      overflow: "auto",
      marginTop: theme.spacing(3),
      "&::-webkit-scrollbar": {
        width: 4,
        marginRight: theme.spacing(2),
        backgroundColor: theme.palette.grey[50],
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#4C6977",
        borderRadius: 50,
      },
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 64,
    },
  },
}));
