import { Grid } from "@material-ui/core";

import { Application } from "models/Evaluation";
import ApplicantsDetails from "../ApplicantsDetails";
import ApplicationCard from "../ApplicationCard";
import useApplicantInfoStyles from "./ApplicantInfoStyles";

interface ApplicantInfoProps {
  application: Application;
}

const ApplicantInfo = ({ application }: ApplicantInfoProps) => {
  const classes = useApplicantInfoStyles();
  return (
    <Grid container className={classes.applicant}>
      <ApplicationCard application={application} />
      <ApplicantsDetails applicants={application.applicants} />
    </Grid>
  );
};

export default ApplicantInfo;
