import { makeStyles, Theme } from "@material-ui/core";

const usePropertyCardStyles = makeStyles((theme: Theme) => ({
  card: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FFF",
    boxShadow: "3px 4px 10px 0px rgba(38, 50, 56, 0.15)",
    borderRadius: 8,
    overflow: "hidden",
    color: theme.palette.grey[900],
    [theme.breakpoints.up("sm")]: {
      width: 343,
    },
  },
}));

export default usePropertyCardStyles;
