import { useGetToken } from "hooks";
import { ContractModel } from "models/Contract";
import { useQuery, UseQueryResult } from "react-query";
import contractService from "services/contractService";

export default function useGetContractProperty(propertyUID: string) {
  const { getToken } = useGetToken();
  const {
    isLoading,
    data,
    error,
    isError,
  }: UseQueryResult<ContractModel, Error> = useQuery<ContractModel, Error>(
    ["contract", propertyUID],
    async () => {
      const authToken = await getToken();
      const contractResponse = await contractService.getContractByProperty(
        propertyUID,
        authToken
      );
      return contractResponse?.data;
    }
  );
  return { isLoading, data, error, isError };
}
