import { ReactNode } from "react";
import { useHistory } from "react-router-dom";

import { Box, Card, CardContent, Typography } from "@material-ui/core";

import { IoChevronForward as ChevronRightIcon } from "@houm-com/ui-icons";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import DefaulterTag from "apps/landlord/components/DefaulterTag";
import {
  IProperty,
  RentStatusType,
  SalesStatusType,
} from "apps/landlord/models/Property";

import usePropertyCardStyles from "./PropertyCardStyles";
import PropertyAddress from "../../../PropertyAddress";
import DefaultPhoto from "../DefaultPhoto";

interface Props {
  propertyData: Partial<IProperty>;
  status: SalesStatusType | RentStatusType;
  children: ReactNode;
  isDefaulter?: boolean;
  isLoading?: boolean;
}

const PropertyCard = ({
  propertyData,
  status,
  children,
  isDefaulter = false,
  isLoading = false,
}: Props) => {
  const history = useHistory();
  const classes = usePropertyCardStyles();

  return (
    <Card
      className={classes.root}
      elevation={0}
      onClick={() => history.push(`/landlord/properties/${propertyData.uid}`)}
    >
      <Box className={classes.cover}>
        {propertyData.photos.length ? (
          <img
            src={propertyData.photos[0].url}
            alt=""
            className={classes.image}
          />
        ) : (
          <DefaultPhoto>
            {status === "photo_scheduled" && (
              <Typography className={classes.text}>Fotos agendadas</Typography>
            )}
          </DefaultPhoto>
        )}
      </Box>
      <Box className={classes.details}>
        <CardContent className={classes.card}>
          <Loading loading={isLoading}>
            <>
              <Box className={classes.content}>
                {children}
                <PropertyAddress propertyData={propertyData} />
              </Box>
              <Box className={classes.rightContainer}>
                {isDefaulter && <DefaulterTag />}
                <Box className={classes.arrowContent}>
                  <ChevronRightIcon />
                </Box>
              </Box>
            </>
          </Loading>
        </CardContent>
      </Box>
    </Card>
  );
};

export default PropertyCard;
