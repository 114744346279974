import { IAmenities } from "apps/landlord/models/Property";

const detailsAmenities: {
  text: string;
  amenitiesKey: keyof IAmenities;
}[] = [
  {
    amenitiesKey: "hasAllDayVigilance",
    text: "Vigilancia las 24 hrs",
  },
  {
    amenitiesKey: "hasBbqArea",
    text: "Asadores / Quincho",
  },
  {
    amenitiesKey: "hasCinema",
    text: "Cine",
  },
  {
    amenitiesKey: "hasConcierge",
    text: "Seguridad / Portería / Conserjería",
  },
  {
    amenitiesKey: "hasElevator",
    text: "Elevador / Ascensor",
  },
  {
    amenitiesKey: "hasGamesRoom",
    text: "Salón de juegos",
  },
  {
    amenitiesKey: "hasGourmetSpace",
    text: "Espacio Gourmet",
  },
  {
    amenitiesKey: "hasGym",
    text: "Gimnasio",
  },
  {
    amenitiesKey: "hasLaundry",
    text: "Área de lavado / Lavandería",
  },
  {
    amenitiesKey: "hasPartyRoom",
    text: "Salón multiuso / Centro de eventos",
  },
  {
    amenitiesKey: "hasPatio",
    text: "Patio / Áreas verdes",
  },
  {
    amenitiesKey: "hasPetArea",
    text: "Área para mascotas",
  },
  {
    amenitiesKey: "hasPlayground",
    text: "Área de juegos infantiles",
  },
  {
    amenitiesKey: "hasPlayroom",
    text: "Ludoteca",
  },
  {
    amenitiesKey: "hasRoofGarden",
    text: "Roof Garden Común",
  },
  {
    amenitiesKey: "hasSauna",
    text: "Sauna",
  },
  {
    amenitiesKey: "hasSteamRoom",
    text: "Vapor",
  },
  {
    amenitiesKey: "hasSwimmingPool",
    text: "Piscina privada",
  },
];

export default detailsAmenities;
