import { makeStyles, Theme } from "@material-ui/core/";

const useCustomSelectStyles = makeStyles<Theme>((theme) => ({
  label: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    transform: "none",
    position: "relative",
    lineHeight: 1.5,
    marginBottom: theme.spacing(0.5),
    color: "inherit !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  select: {
    backgroundColor: "#EFF2F3",
    padding: theme.spacing(0.5, 2),
    lineHeight: 1.5,
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  container: {
    "& .MuiInput-root": {
      margin: 0,
    },
  },
  optionLabel: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  borderRadius: {
    borderRadius: theme.spacing(0.5),
  },
}));

export default useCustomSelectStyles;
