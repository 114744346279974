import { View, Text, Image } from "@react-pdf/renderer";

import Divider from "../assets/icons/rectangle-divider.png";

import useInspectionReportDocumentStyles from "../InspectionReportDocumentStyles";
import { format } from "date-fns";

function InspectionReportInfo({ inspectionReport }) {
  const styles = useInspectionReportDocumentStyles;

  const appRaiserName = inspectionReport?.appraiser?.name ?? "";
  const requireCleaning = inspectionReport?.requiresCleaning
    ? "Requiere"
    : "No requiere";

  return (
    <View wrap style={styles.firstSection}>
      <Text style={styles.sectionTitle}>Detalle del acta</Text>
      <View style={styles.keyValSection}>
        <Text style={styles.itemLabel}>Fecha de realización:&nbsp;</Text>
        <Text style={styles.itemValue}>
          {format(new Date(inspectionReport?.createdAt), "dd/MM/yyyy HH:mm")}
        </Text>
      </View>
      <View style={styles.keyValSection}>
        <Text style={styles.itemLabel}>Inspector:&nbsp;</Text>
        <Text style={styles.itemValue}>{appRaiserName}</Text>
      </View>
      <View style={styles.cleaningInfoSection}>
        <Image src={Divider} style={styles.sectionDivider} />
        <View style={styles.cleaningKeyValSection}>
          <Text style={styles.itemLabel}>Limpieza:&nbsp;</Text>
          <Text style={styles.itemValue}>{requireCleaning}</Text>
        </View>
        <Text style={styles.cleaningObservationDetail}>
          {inspectionReport?.cleaningObservation}
        </Text>
        <Image src={Divider} style={styles.sectionDivider} />
      </View>
    </View>
  );
}

export default InspectionReportInfo;
