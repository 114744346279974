import currency from "@houm-com/formats/currency";

import { Box, Typography } from "@material-ui/core";

import { IMovement } from "apps/tenant/models/Movement";
import useContract from "apps/tenant/hooks/useContract";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import Spinner from "Components/Shared/partials/Spinner";

import usePaymentDetail from "../../hooks/usePaymentDetail";
import Card from "../Card";
import Breakdown from "./components/Breakdown";
import usePaymentBreakdownStyles from "./PaymentBreakdownStyles";

interface Props {
  movement?: IMovement;
}

const PaymentBreakdown = ({ movement }: Props) => {
  const classes = usePaymentBreakdownStyles();
  const { data: contractData, isLoading: contractLoading } = useContract();
  const {
    data,
    isLoading: breakdownLoading,
    breakdownCurrency,
    hasReadjustment,
    originalAmountBreakdown,
    discountsBreakdown,
    aditionalsBreakdown,
    promotionsBreakdown,
  } = usePaymentDetail({
    country: contractData?.country,
    movementId: movement?.id,
  });

  if (breakdownLoading || !data) return <Spinner />;

  return (
    <Card title="Desglose">
      <Loading loading={contractLoading}>
        <>
          <Box className={classes.paymentDetailContainer}>
            <Breakdown
              label="Valor del arriendo mensual"
              value={currency(data.rentPrice, breakdownCurrency)}
            />
            {hasReadjustment && (
              <Breakdown
                label="Reajuste"
                value={currency(
                  data.readjustment.totalValue,
                  breakdownCurrency
                )}
              />
            )}
            {originalAmountBreakdown.map((original) => (
              <Breakdown
                label={original.description}
                value={currency(original.value, breakdownCurrency)}
                key={original.identifier}
              />
            ))}
            {aditionalsBreakdown.map((aditional) => (
              <Breakdown
                label={aditional.description}
                value={currency(aditional.value, breakdownCurrency)}
                key={aditional.identifier}
              />
            ))}
            {discountsBreakdown.map((discount) => (
              <Breakdown
                label={discount.description}
                value={`- ${currency(discount.value, breakdownCurrency)}`}
                key={discount.identifier}
              />
            ))}
            {promotionsBreakdown.map((promotion) => (
              <Breakdown
                label={`Promoción: ${promotion.description}`}
                value={`${promotion.value < 0 ? "- " : ""}${currency(
                  Math.abs(promotion.value),
                  breakdownCurrency
                )}`}
                key={promotion.identifier}
              />
            ))}
          </Box>
          <Box className={classes.totalDetailWrapper}>
            <Typography className={classes.totalDetail}>
              Arriendo depositado
            </Typography>
            <Typography className={classes.totalDetail}>
              {currency(data.totalPrice, breakdownCurrency)}
            </Typography>
          </Box>
        </>
      </Loading>
    </Card>
  );
};

export default PaymentBreakdown;
