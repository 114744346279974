import { makeStyles, Theme } from "@material-ui/core";

const usePropertiesWrapperStyles = makeStyles<Theme>((theme) => ({
  listContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    lineHeight: 1.44,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.4,
    },
  },
  propertiesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

export default usePropertiesWrapperStyles;
