import { useHistory, useParams } from "react-router-dom";

import { Grid, Typography } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";

import { parsePrice } from "utils";
import useDetailsPriceStyles from "./DetailsPriceStyles";

interface Props {
  price: number;
  currency: string;
  forRental?: boolean;
}

export default function DetailsPrice({ price, currency, forRental }: Props) {
  const { id } = useParams<{ id: string }>();
  const classes = useDetailsPriceStyles();
  const history = useHistory();
  const text = forRental ? "Precio de arriendo actual:" : "Precio actual:";
  return (
    <Grid container className={classes.detailsPriceContainer} xs={12} md={5}>
      <Typography className={classes.priceText}>{text}</Typography>
      <Typography className={classes.priceText}>
        <strong>
          {price ? parsePrice(price, currency) : "No se ha registrado"}
        </strong>
      </Typography>
      <Typography
        className={classes.modifyPriceLink}
        onClick={() =>
          history.push(`/landlord/properties/${id}/details/set-price`)
        }
      >
        Actualizar precio
        <ArrowForward className={classes.priceIcon} fontSize="inherit" />
      </Typography>
    </Grid>
  );
}
