import { makeStyles, Theme } from "@material-ui/core";

const useWorkerTypeSelectionStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.66,
    marginBottom: theme.spacing(0.5),
  },
  divider: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.grey[75],
  },
}));

export default useWorkerTypeSelectionStyles;
