import { ReactNode } from "react";

import { Button, Grid, Typography } from "@material-ui/core";
import clx from "classnames";

import usePageButtonStyles from "./PageButtonStyles";

interface Props {
  onClick: () => void;
  icon: ReactNode;
  pageTitle: string;
  disabled?: boolean;
}

export default function PageButton({
  onClick,
  icon,
  pageTitle,
  disabled,
}: Props) {
  const classes = usePageButtonStyles();
  return (
    <Grid item className={classes.pageButtonContainer}>
      <div className={classes.pageButton}>
        <Button
          disabled={disabled}
          onClick={onClick}
          classes={{ root: classes.buttonRoot }}
        >
          {icon}
        </Button>
      </div>
      <Typography
        className={clx({
          [classes.pageTitle]: !disabled,
          [classes.pageTitle_disabled]: disabled,
        })}
      >
        {pageTitle}
      </Typography>
    </Grid>
  );
}
