import { forwardRef, InputHTMLAttributes, ReactNode } from "react";
import { cn } from "@houm-com/ui/utils";
import { classes } from "./SelectableCardStyles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  children: ReactNode;
  cardContainer?: string;
}

const SelectableCard = forwardRef<HTMLInputElement, InputProps>(
  ({ className, cardContainer, children, ...props }, ref) => (
    <div className={cn(classes.container, cardContainer)}>
      <input
        type="radio"
        className="peer hidden"
        id={props.id}
        value={props.id}
        ref={ref}
        {...props}
      />
      <label htmlFor={props.id} className={cn(classes.card, className)}>
        {children}
      </label>
    </div>
  )
);

export default SelectableCard;
