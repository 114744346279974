import { Grid } from "@material-ui/core";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { Inspection } from "hooks/inspectionReports/types";
import { useProperty } from "apps/landlord/hooks";

import useInspectionReports from "hooks/inspectionReports/useInspectionReports";
import useAllInspectionReportsStyles from "./AllInspectionReportsStyles";
import InspectionReportCard from "./InspectionReportCard";
import NoInspectionCard from "./NoInspectionCard";

export default function AllInspectionReports() {
  const { propertyData } = useProperty();
  const { inspectionReportList, isLoading } = useInspectionReports(
    propertyData?.id
  );

  const classes = useAllInspectionReportsStyles();
  if (isLoading) return <Loading loading />;

  return (
    <Grid container>
      <Grid item container className={classes.reportsContainer}>
        {inspectionReportList?.length === 0 && <NoInspectionCard />}
        {inspectionReportList?.map((report: Inspection) => (
          <InspectionReportCard
            key={report.id}
            inspectionReport={report}
            propertyAddress={{
              address: propertyData?.address,
              region: propertyData?.region,
            }}
          />
        ))}
      </Grid>
    </Grid>
  );
}
