import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  floating: boolean;
}

const usePaymentStatusStyles = makeStyles<Theme, Props>((theme) => ({
  paymentStatusRoot: {
    padding: theme.spacing(0, 1),
    fontSize: theme.typography.pxToRem(10),
    lineHeight: 2,
    fontWeight: 700,
    borderRadius: ({ floating }) => (floating ? "2px" : "0px 2px 2px 0px"),
  },
  paidTag: {
    backgroundColor: "#548510",
    color: "#EAF1FB",
  },
  pendingTag: {
    backgroundColor: "#DDB80A",
    color: "#FDF9E6",
  },
  overdueTag: {
    backgroundColor: "#AE0400",
    color: "#FFFFFF",
  },
  reviewTag: {
    backgroundColor: "#E9EAEB",
    color: theme.palette.grey["A400"],
  },
}));

export default usePaymentStatusStyles;
