import { useCallback, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEvaluation } from "apps/lead/context/evaluationContext/EvaluationContext";
import { setActiveStep } from "apps/lead/context/evaluationContext/EvaluationActions";
import textByWorkerType from "apps/lead/utils/requirementsByWorkertype";
import {
  Action,
  Applicant,
  Files,
  WorkerType,
} from "apps/lead/context/evaluationContext/EvaluationTypes";
import { CountryUpperCase } from "models/Countries";
import { useFormNotifications } from "hooks";

import { parseUserFiles, parseWorkerType } from "../utils/parsers";
import { fileSchema, requiredText } from "../validationSchema";

const useUploadFiles = (
  user: Partial<Applicant>,
  workerType: WorkerType,
  country: CountryUpperCase,
  setData: (data: Partial<Applicant>) => Action
) => {
  const { state, dispatch } = useEvaluation();
  const { activeStep } = state;

  const requirements = useMemo(
    () => textByWorkerType(true, workerType, country),
    [workerType]
  );

  const methods = useForm<Files[]>({
    resolver: yupResolver(fileSchema(requirements)),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<Files[]>({ errors, requiredText });

  const onSubmit: SubmitHandler<Files[]> = () => {
    dispatch(setActiveStep(activeStep + 1));
  };

  const handleBackClick = useCallback(() => {
    dispatch(setActiveStep(activeStep - 1));
  }, []);

  const handleChange = useCallback(
    (type: WorkerType) => {
      const parsedUser = parseWorkerType(user, type);
      dispatch(setData(parsedUser));
    },
    [workerType]
  );

  const uploadFile = (file: Files, stateFiles: Files[], fileType: string) => {
    const parsedData = parseUserFiles(user, {
      [`${fileType}`]: [...stateFiles, file],
    });
    dispatch(setData(parsedData));
  };

  const removeFile = (file: string, stateFiles: Files[], fileType: string) => {
    const newFiles = stateFiles.filter((f) => f.name !== file);
    const parsedData = parseUserFiles(user, {
      [`${fileType}`]: newFiles,
    });
    dispatch(setData(parsedData));
  };

  return {
    handleBackClick,
    handleChange,
    uploadFile,
    removeFile,
    onSubmit,
    methods,
    requirements,
  };
};

export default useUploadFiles;
