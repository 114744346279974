export const memberTypeInfo = {
  main: {
    title: "Arrendatario principal",
    information: "Eres la persona que quedará como titular del contrato.",
    disclaimer: undefined,
  },
  codebtor: {
    title: "Complemento",
    information:
      "Eres la persona que, en caso de ser necesario, respalda el pago del arriendo. Debes contar con los ingresos requeridos*. No es necesario que habites la propiedad.",
    disclaimer: "*Ingresos requeridos: 2.5 veces el valor del arriendo",
  },
  rent_complement: {
    title: "Codeudor",
    information:
      "Eres la persona que, en caso de ser necesario, complete los ingresos requeridos* del arrendatario principal. También habitarás la propiedad.",
    disclaimer: "*Ingresos requeridos: 2.5 veces el valor del arriendo",
  },
};
