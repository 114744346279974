import { FormEvent, useState } from "react";

import { Box } from "@material-ui/core";
import { add } from "date-fns";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CustomDatePicker from "apps/tenant/components/Forms/CustomDatePicker";
import CustomRadioButton from "apps/tenant/components/Forms/CustomRadioButton";
import { ILeavingForm } from "apps/tenant/utils/types";
import { Locale } from "models/Countries";

import {
  LOCAL_BASED_INITIAL_DAYS,
  LOCAL_BASED_LABEL,
  LOCAL_BASED_MOTIVES_OPTIONS,
} from "./constants";
import useLeavingDateFormStyles from "./LeavingDateFormStyles";

interface Props {
  submitHandler: (data: ILeavingForm) => void;
  data: ILeavingForm;
  country: Locale;
}

const LeavingDateForm = ({ submitHandler, data, country }: Props) => {
  const classes = useLeavingDateFormStyles();
  const [date, setDate] = useState<Date | null>(
    data.date ??
      add(new Date(), {
        days: LOCAL_BASED_INITIAL_DAYS[country],
      })
  );
  const [motive, setMotive] = useState<number | null>(data.motive ?? null);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submitHandler({ date, motive });
  };

  return (
    <form key="LeavingDateForm" onSubmit={onSubmit}>
      <CustomDatePicker
        value={date}
        onChange={(selectedDate) => setDate(selectedDate)}
        minDate={add(new Date(), {
          days: 1,
        })}
        maxDate={add(new Date(), {
          years: 2,
        })}
      />
      <Box className={classes.motiveContainer}>
        <CustomRadioButton
          label={LOCAL_BASED_LABEL[country]}
          value={motive}
          onChange={(e) => setMotive(parseInt(e.target.value as string))}
          id="motive"
          options={LOCAL_BASED_MOTIVES_OPTIONS[country]}
        />
      </Box>
      <Box className={classes.ctaContainer}>
        <DesignSystemButton
          disabled={!date || !motive}
          label="Siguiente"
          variant="primary"
          size="small"
          type="submit"
        />
      </Box>
    </form>
  );
};

export default LeavingDateForm;
