import { makeStyles, Theme } from "@material-ui/core";

const useStepsStyles = makeStyles((theme: Theme) => ({
  rootGrid: {
    minHeight: "87vh",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: theme.spacing(2.25),
  },
  ownerNameLead: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    color: "#A6B4BB",
    textTransform: "uppercase",
    marginBottom: theme.spacing(0.25),
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(28),
    marginBottom: theme.spacing(0.25),
  },
  subtitle: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
  highlightBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[5],
    borderRadius: 20,
    textAlign: "justify",
  },
}));

export default useStepsStyles;
