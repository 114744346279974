import { makeStyles, Theme } from "@material-ui/core";

import {
  BOTTOM_NAVIGATION_DESKTOP,
  BOTTOM_NAVIGATION_MOBILE,
} from "../../utils/constants";

const defaultBreakpoint = "sm";

interface Props {
  disabled: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme) => ({
  bottomBarRoot: {
    height: BOTTOM_NAVIGATION_MOBILE,
    padding: 0,
    border: 0,
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.up("md")]: {
      height: BOTTOM_NAVIGATION_DESKTOP,
      padding: theme.spacing(0, 10),
      backgroundColor: "white",
      justifyContent: "space-between",
    },
  },
  button: {
    [theme.breakpoints.down(defaultBreakpoint)]: {
      height: "100%",
      width: "100%",
      borderRadius: 0,
      display: "flex",
      justifyContent: "center",
      maxWidth: "none",
    },
  },
}));

export default useStyles;
