import TenantLayout from "apps/tenant/layout/TenantLayout";
import Dashboard from "./Dashboard";

export default function () {
  return (
    <TenantLayout>
      <Dashboard />
    </TenantLayout>
  );
}
