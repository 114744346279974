import { makeStyles, Theme } from "@material-ui/core";

const useMainContentStyles = makeStyles<Theme>((theme) => ({
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0),
    gap: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      gap: theme.spacing(6),
      width: "74%",
      padding: theme.spacing(3),
    },
  },
  generalInfoContainer: {
    gap: theme.spacing(5),
    display: "grid",
    padding: theme.spacing(2),
    alignContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0),
      height: "100%",
    },
  },
  infoContainer: {
    display: "flex",
    gap: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      gap: theme.spacing(0),
    },
  },
}));

export default useMainContentStyles;
