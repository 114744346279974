import { useHistory } from "react-router-dom";

import { Grid } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import useKeepSearchingModalStyles from "./KeepSearchingModalStyles";

interface KeepSearchingModalProps {
  openKeepSearchingModal: boolean;
  propertyUid: string;
}

const KeepSearchingModal = ({
  openKeepSearchingModal,
  propertyUid,
}: KeepSearchingModalProps) => {
  const history = useHistory();
  const classes = useKeepSearchingModalStyles();

  const handleReloadPage = () => history.go(0);

  const handleRedirectToContract = () =>
    history.push(`/propietary/closureForm/${propertyUid}`);

  return (
    <CustomDialog
      open={openKeepSearchingModal}
      onClose={handleReloadPage}
      title="¡Seguiremos buscando! 🧐"
      subtitle="Mientras seguimos buscando al inquilino perfecto para ti, revisa nuestros planes de administración y selecciona el más conveniente para ti. ✨"
      classes={{
        titleContainer: classes.titleContainer,
        title: classes.title,
        subtitle: classes.text,
        content: classes.contentDialog,
      }}
    >
      <Grid container justifyContent="flex-end" alignItems="center">
        <DesignSystemButton
          onClick={handleRedirectToContract}
          variant="primary"
          size="medium"
          label="Actualizar"
        />
      </Grid>
    </CustomDialog>
  );
};

export default KeepSearchingModal;
