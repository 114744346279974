import { makeStyles, Theme } from "@material-ui/core";

const useNavbarMenuItemStyles = makeStyles((theme: Theme) => ({
  navbarMenuItemRoot: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.6,
    borderTop: `1px solid transparent`,
    borderBottom: `1px solid transparent`,
    "&:hover": {
      borderTop: `1px solid ${theme.palette.grey[50]}`,
      borderBottom: `1px solid ${theme.palette.grey[50]}`,
      backgroundColor: "#F7F9F9",
    },
  },
}));

export default useNavbarMenuItemStyles;
