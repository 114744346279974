import { useMemo } from "react";

import { getDate, getMonth, getYear } from "date-fns";

import CustomSelect from "../CustomSelect";
import { getDaysOptions, getMonthsOptions, getYearsOptions } from "./utils";
import useCustomDatePickerStyles from "./CustomDatePickerStyes";

type Props = {
  value?: Date;
  onChange?: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
};

const CustomDatePicker = ({ value, onChange, minDate, maxDate }: Props) => {
  const currentValue = value ?? new Date();
  const day = getDate(currentValue);
  const month = getMonth(currentValue);
  const year = getYear(currentValue);

  const validDate = new Date(year, month);
  const daysOptions = useMemo(
    () => getDaysOptions(validDate, minDate, maxDate),
    [year, month, minDate, maxDate]
  );
  const monthOptions = useMemo(
    () => getMonthsOptions(validDate, minDate, maxDate),
    [year, minDate, maxDate]
  );
  const yearsOptions = useMemo(
    () => getYearsOptions(minDate, maxDate),
    [minDate, maxDate]
  );

  const classes = useCustomDatePickerStyles();
  return (
    <div className={classes.container}>
      <CustomSelect
        id="day"
        label="Día"
        fullWidth
        options={daysOptions}
        value={day}
        onChange={(e) =>
          onChange(new Date(year, month, e.target.value as number))
        }
      />
      <CustomSelect
        id="month"
        label="Mes"
        fullWidth
        options={monthOptions}
        value={month}
        onChange={(e) =>
          onChange(new Date(year, e.target.value as number, day))
        }
      />
      <CustomSelect
        id="year"
        label="Año"
        fullWidth
        options={yearsOptions}
        value={year}
        onChange={(e) =>
          onChange(new Date(e.target.value as number, month, day))
        }
      />
    </div>
  );
};

export default CustomDatePicker;
