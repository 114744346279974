import LayoutWithMenu from "apps/lead/layout/LayoutWithMenu";
import { TabProvider } from "context/tabContext/tabContext";
import MainTenant from "./MainTenant";

export default function () {
  return (
    <LayoutWithMenu>
      <TabProvider>
        <MainTenant />
      </TabProvider>
    </LayoutWithMenu>
  );
}
