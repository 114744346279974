import { useMutation } from "react-query";

import { alert } from "@houm-com/ui/Alert";

import newPaymentService from "services/newPaymentService";
import { Locale } from "models/Countries";

interface RefundProps {
  movementId: string;
  countryCode: Locale;
  refundDeclaration: boolean;
}

const useRefundDeclaration = () => {
  const mutation = useMutation(
    async ({ movementId, countryCode, refundDeclaration }: RefundProps) => {
      const response = await newPaymentService.setReservationRefundDeclaration(
        movementId,
        countryCode,
        refundDeclaration
      );
      return response.data;
    },
    {
      onError: () =>
        alert.error({
          message: "¡Ups! Algo salió mal",
          disclaimer: "Por favor intente nuevamente",
        }),
    }
  );
  return {
    ...mutation,
  };
};

export default useRefundDeclaration;
