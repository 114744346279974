import { useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useCodebtor } from "apps/lead/context/codebtorContext/CodebtorContext";
import { WorkerType } from "apps/lead/context/codebtorContext/CodebtorTypes";
import {
  setEvaluation,
  setSteps,
  setLoading,
  setActiveStep,
} from "apps/lead/context/codebtorContext/CodebtorActions";
import { EvaluationPersons } from "apps/lead/models/Application";

import getUploadFilesFormStepsChile from "../components/Steps";

interface StateType {
  evaluationId: number;
  evaluations: EvaluationPersons[];
}

const useEvaluations = () => {
  const history = useHistory();
  const { state: stateLocation } = useLocation<StateType>();
  const { state, dispatch } = useCodebtor();
  const { activeStep, codebtor } = state;

  const formSteps = useMemo(() => getUploadFilesFormStepsChile(), []);

  const CurrentStep = useMemo(() => formSteps[activeStep], [activeStep]);

  const workerTypeParse = {
    Pensionado: "pensioner",
    Independiente: "independent",
    Dependiente: "dependent",
  };

  useEffect(() => {
    dispatch(setSteps(formSteps.length));
    if (stateLocation) {
      dispatch(setLoading(true));
      const parseData = stateLocation.evaluations.map((person) => ({
        ...codebtor,
        id: person.id,
        country: person.country,
        email: person.email,
        document: person.document,
        documentType: person.documentType,
        lastName: person.lastName,
        name: person.name,
        phone: person.phone,
        workerType: workerTypeParse[person.workerType] as WorkerType,
        status: person.status,
        role: person.role,
      }));
      dispatch(
        setEvaluation({
          evaluationId: stateLocation.evaluationId,
          persons: parseData,
        })
      );
      dispatch(setLoading(false));
    } else {
      history.push("/tenant");
    }
  }, [stateLocation]);

  const handleBack = useCallback(
    () =>
      activeStep === 0
        ? history.push("/tenant")
        : dispatch(setActiveStep(activeStep - 1)),
    [activeStep]
  );

  return {
    CurrentStep,
    handleBack,
  };
};

export default useEvaluations;
