import { useQuery, UseQueryResult } from "react-query";

import propertyService from "apps/landlord/services/propertyService/propertyService";
import { useGetToken } from "hooks";

import { IMainPropertyPropertyAnalysis } from "./types";
import parseMainPropertyData from "./parsers";

const useMainProperty = (propertyId: number) => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    data,
    isError,
    isSuccess,
  }: UseQueryResult<IMainPropertyPropertyAnalysis, Error> = useQuery<
    IMainPropertyPropertyAnalysis,
    Error
  >(
    ["property_by_id_rev_stats", propertyId],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.getLandlordPropertyRev(
        propertyId,
        authToken
      );
      return parseMainPropertyData(response.data);
    },
    {
      enabled: !!propertyId,
      refetchOnMount: true,
    }
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useMainProperty;
