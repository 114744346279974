import { makeStyles, Theme } from "@material-ui/core/";

const usePropertyDetailsStyles = makeStyles<Theme>((theme) => ({
  propertyDetailsContainer: {
    maxWidth: 1480,
    padding: theme.spacing(4, 2, 2, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 5),
    },
  },
  content: {
    display: "grid",
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
    gap: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    },
  },
}));

export default usePropertyDetailsStyles;
