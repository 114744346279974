import { makeStyles, Theme } from "@material-ui/core";

const useSelectionStepStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  container: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  actionsContainer: {
    marginTop: theme.spacing(3),
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: 0,
      right: 0,
      width: "40%", //drawer width
      padding: theme.spacing(5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(5),
    },
  },
}));

export default useSelectionStepStyles;
