import { Box, Link, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { Locale } from "models/Countries";
import HoursIntervals from "apps/tenant/components/Forms/HoursInterval";
import { IVisitHour } from "apps/tenant/hooks/utils/types";

import useRepairVisitStyles from "./RepairVisitStyles";
import INTERVALS_OPTIONS from "./constants";
import KUSTOMER_HELP_LINKS from "../../constants";

interface Props {
  handleNext: () => void;
  visitHours: IVisitHour[];
  setVisitHours: (value: IVisitHour[]) => void;
  country: Locale;
}

const RepairVisit = ({
  handleNext,
  setVisitHours,
  visitHours,
  country,
}: Props) => {
  const classes = useRepairVisitStyles();
  const allDaysHasHours = visitHours
    .filter((day) => day.checked)
    .every((day) => day.hours.every((hour) => hour > 0));
  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Horario de visita</Typography>
      <Typography className={classes.description}>
        Selecciona los días y horarios en los que podrías recibir a un técnico
        para poder realizar la cotización. Recuerda que son días tentativos y un
        ejecutivo te contactará para confirmar.
      </Typography>
      <HoursIntervals
        onChange={setVisitHours}
        value={visitHours}
        intervalOptions={INTERVALS_OPTIONS}
      />
      <Typography className={classes.info}>
        Si tienes dudas de como funciona nuestro proceso de reparaciones{" "}
        <Link
          className={classes.link}
          href={KUSTOMER_HELP_LINKS[country]}
          target="_blank"
        >
          haz click aquí
        </Link>
      </Typography>
      <Box className={classes.ctaContainer}>
        <DesignSystemButton
          disabled={!allDaysHasHours}
          onClick={handleNext}
          variant="primary"
          size="small"
          label="Siguiente"
        />
      </Box>
    </Box>
  );
};

export default RepairVisit;
