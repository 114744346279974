import { makeStyles, Theme } from "@material-ui/core";

const MOBILE_MAX_HEIGHT = 204;
const defaultBreakpoint = "sm";

interface Props {
  navbarHeight: number;
}

const useDefaultCardStyles = makeStyles<Theme, Props>((theme) => ({
  card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: MOBILE_MAX_HEIGHT,
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      height: ({ navbarHeight }) => `calc(100vh - ${navbarHeight}px)`,
    },
  },
  cardContent: {
    width: "100%",
    top: 0,
    zIndex: 1,
    display: "flex",
    alignItems: "flex-end",
    position: "absolute",
    padding: theme.spacing(4, 2),
    gap: theme.spacing(2),
    height: MOBILE_MAX_HEIGHT,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      height: "100%",
      padding: theme.spacing(5),
    },
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    objectPosition: "center center",
  },
  text: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    color: "white",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.57,
    },
  },
}));

export default useDefaultCardStyles;
