import { makeStyles, Theme } from "@material-ui/core";
import { DESKTOP_CONTENT_MAX_WIDTH } from "apps/tenant/constants";

const useHelpPageStyles = makeStyles<Theme>((theme) => ({
  container: {
    maxWidth: DESKTOP_CONTENT_MAX_WIDTH,
    margin: theme.spacing(0, "auto"),
    padding: theme.spacing(4, 2, 5, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5, 5, 6, 5),
    },
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(1),
      fontSize: theme.typography.pxToRem(28),
    },
  },
  cardsContainer: {
    display: "grid",
    gridTemplateRows: "1fr 1fr",
    gridRowGap: theme.spacing(3),
    gridTemplateColumns: "1fr",
    gridColumnGap: theme.spacing(7),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr",
      marginTop: theme.spacing(4),
      gridRowGap: theme.spacing(2),
    },
  },
}));

export default useHelpPageStyles;
