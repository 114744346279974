import { Link } from "react-router-dom";

import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";

import { Locale } from "models/Countries";

import { textsByCountry } from "../../utils/utils";
import useStyles from "./RefundDeclarationStyles";

interface Props {
  value: boolean;
  countryCode: Locale;
  onChange: () => void;
  size?: string;
}

const RefundDeclaration = ({ value, countryCode, onChange }: Props) => {
  const classes = useStyles();
  const renderLabel = () => (
    <Typography className={classes.label}>
      {" Declaro haber leído y estoy de acuerdo con la "}
      <Link
        to={{
          pathname: textsByCountry[countryCode].landing.returnPolicyLink,
        }}
        className={classes.link}
        target="_blank"
      >
        {textsByCountry[countryCode].landing.returnPolicyTextLink}
      </Link>
    </Typography>
  );

  return (
    <FormControlLabel
      control={<Checkbox checked={value} onChange={onChange} color="primary" />}
      label={renderLabel()}
      classes={{ label: classes.boxLabel }}
    />
  );
};

export default RefundDeclaration;
