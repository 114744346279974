export const classes = {
  title:
    "text-[28px] leading-[1.28] md:text-[32px] md:leading-[1.25] font-black font-['Inter'] text-left mb-6",
  khipuOnboarding: "flex gap-10 flex-col",
  khipuOnboardingContainer: "flex gap-2 flex-col",
  khipuOnboardingText: "flex gap-4 flex-col text-p16",
  subtitle: "font-bold text-p16 font-['Inter']",
  strongInfo: "font-bold text-[#6E4CBD]",
  container: "rounded-lg bg-information-20 p-4 flex flex-row gap-2",
  infoContainer: "flex flex-col gap-1",
  infoContainerIcon: "w-6 h-6",
  infoIcon: "w-4 h-full text-information-120",
  infoTitle: "text-p16 text-information-120 font-bold font-['Inter']",
  infoText: "text-p16 text-information-120",
};
