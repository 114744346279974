import { format } from "date-fns";

import { Service, ServiceType } from "apps/tenant/models/PropertyServices";
import { numberWithCommas } from "utils";

const translateType = (type: ServiceType) => {
  switch (type) {
    case "basicServices":
      return "Servicio básico";
    case "commonExpenses":
      return "Gastos comunes";
    case "energy":
      return "Luz";
    case "gas":
      return "Gas";
    case "water":
      return "Agua";
    default:
      return "";
  }
};

export const getServiceData = (data: Service) => ({
  amount: `$${numberWithCommas(data.amount)}`,
  clientNumber: data.clientNumber,
  expirationDate: format(data.expirationDate, "dd/MM/yyyy"),
  status: data.status,
  type: translateType(data.type),
});

export default getServiceData;
