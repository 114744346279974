import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

const useMenuDocumentsStyles = makeStyles<Theme>((theme) => ({
  menuContainer: {
    display: "flex",
    gap: theme.spacing(1),
    flexDirection: "column",
  },
  noDocuments: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export default useMenuDocumentsStyles;
