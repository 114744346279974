import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

export default makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      minHeight: 160,
    },
  },
  hour: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: "bold",
    color: theme.palette.grey[500],
  },
  breakLine: {
    marginTop: 0,
    marginBottom: 0,
    border: 0,
    borderTop: `0.3px solid ${theme.palette.grey[500]}`,
  },
  houmerName: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.grey[900],
    fontWeight: 400,
    marginBottom: 0,
  },
  houmerDetails: {
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.grey[900],
    "& span": {
      fontSize: theme.typography.pxToRem(10),
      color: theme.palette.grey[900],
      fontWeight: "bold",
    },
  },
  chipScheduled: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 400,
    minWidth: 69,
    height: 15,
    marginBottom: theme.spacing(0.75),
    marginTop: theme.spacing(1),
  },
  chipConfirmed: {
    color: "#00982D",
    border: "1px solid #00982D",
    background: "white",
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 400,
    minWidth: 69,
    height: 15,
    marginBottom: theme.spacing(0.75),
    marginTop: theme.spacing(1),
  },
  chipCancelled: {
    color: "white",
    background: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 400,
    minWidth: 69,
    height: 15,
    marginBottom: theme.spacing(0.75),
    marginTop: theme.spacing(1),
  },
  chipDone: {
    color: "#00982D",
    background: "#E9FFDA",
    border: "1px solid #00982D",
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 400,
    minWidth: 69,
    height: 15,
    marginBottom: theme.spacing(0.75),
    marginTop: theme.spacing(1),
  },
}));
