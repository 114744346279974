import { Theme, makeStyles } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    margin: "0px",
    width: "100%",
    padding: theme.spacing(0.3),
    justifyContent: "space-between",
  },
  label: {
    width: "100%",
    fontWeight: "bold",
    alignItems: "left",
    fontSize: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  box: {
    marginLeft: theme.spacing(6),
    transform: "scale(1.2)",
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(4),
      transform: "scale(0.9)",
    },
  },
  selectedItemContainer: {
    width: "100%",
    border: "1px solid",
    borderColor: theme.palette.grey[900],
    borderRadius: "100rem",
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
    "&.Mui-disabled": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderColor: theme.palette.grey[200],
      backgroundColor: theme.palette.grey[50],
    },
  },
  item: {
    width: "100%",
  },
  icon: {
    color: theme.palette.grey[900],
    pointerEvents: "none",
  },
}));
