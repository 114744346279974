import { ReactNode } from "react";
import classNames from "classnames";

import { Typography } from "@material-ui/core";

import useTagStyle from "./TagStyles";

interface TagProps {
  colors: {
    color: string;
    backgroundColor: string;
  };
  className?: {
    wrapperClassName?: string;
    textClassName?: string;
  };
  text: string;
  bordered?: boolean;
  icon?: ReactNode;
}

const Tag = ({ colors, text, className, bordered, icon }: TagProps) => {
  const classes = useTagStyle({ ...colors, bordered });

  return (
    <div className={classNames(classes.TagRoot, className?.wrapperClassName)}>
      {icon}
      <Typography
        className={classNames(classes.label, className?.textClassName)}
      >
        {text}
      </Typography>
    </div>
  );
};

export default Tag;
