import { HTMLAttributes, ReactNode } from "react";
import clsx from "classnames";

import { Grid, Typography } from "@material-ui/core";

import { IoArrowForward as ArrowRight } from "@houm-com/ui-icons";

import useSettingUpCardStyles from "./SettingUpCardStyles";
import CircularSteps from "./CircularSteps";

interface SettingUpCardProps extends HTMLAttributes<HTMLDivElement> {
  currentStep: number;
  totalSteps: number;
  label: string | ReactNode;
  trailLabel?: string | ReactNode;
  actionLabel: string | ReactNode;
  disabled?: boolean;
}

const SettingUpCard = ({
  currentStep,
  totalSteps,
  label,
  trailLabel,
  actionLabel,
  disabled,
  ...htmlProps
}: SettingUpCardProps) => {
  const classes = useSettingUpCardStyles();
  return (
    <Grid
      className={clsx(classes.settingUpCard, {
        [classes.disabled]: disabled,
      })}
      {...htmlProps}
    >
      <CircularSteps currentStep={currentStep} totalSteps={totalSteps} />
      <Typography className={classes.settingUpCardLabel}>{label}</Typography>
      {trailLabel && (
        <Typography className={classes.settingUpCardTrailLabel}>
          {trailLabel}
        </Typography>
      )}
      <button type="button" className={classes.settingUpCardAction}>
        {actionLabel}
        <ArrowRight />
      </button>
    </Grid>
  );
};

export default SettingUpCard;
