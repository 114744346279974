import clx from "classnames";

import { Box, Typography } from "@material-ui/core";

import {
  setCurrentOffer,
  setCurrentStep,
  setResponseToOffer,
} from "context/negotiationsContext/negotiationsActions";
import { useNegotiations } from "context/negotiationsContext/negotiationsContext";
import {
  CurrentStep,
  Offer,
  ResponseAnswer,
} from "context/negotiationsContext/negotiationsTypes";
import { formatPrice } from "context/negotiationsContext/utils";
import { useMemo } from "react";
import useOfferCardStyles from "./OfferCardStyles";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

interface Props {
  offer: Offer;
}

const OfferCard = ({ offer }: Props) => {
  const { dispatch } = useNegotiations();
  const { amount, currency, paymentMethod, createdAt, status } = offer;
  const classes = useOfferCardStyles();

  const handleClick = (response: ResponseAnswer, nextStep: CurrentStep) => {
    dispatch(setCurrentStep(nextStep));
    dispatch(setCurrentOffer(offer));
    dispatch(setResponseToOffer({ response }));
  };

  const statusTittle = (
    <Box
      className={clx(
        classes.status,
        { [classes.accepted]: status === ResponseAnswer.ACCEPTED },
        { [classes.rejected]: status === ResponseAnswer.REJECTED }
      )}
    >
      OFERTA {status.toUpperCase()}
    </Box>
  );

  const formatedCreatedAt = useMemo(
    () => new Date(createdAt).toLocaleDateString(),
    [createdAt]
  );

  return (
    <Box className={classes.offerCard}>
      <Box className={classes.offerDetails}>
        <Typography className={classes.title}>Datos de la oferta</Typography>
        <Box className={classes.epigraph}>
          Precio:
          <span className={classes.value}>{formatPrice(amount, currency)}</span>
        </Box>
        <Box className={classes.epigraph}>
          Forma de pago:
          <span className={classes.value}>{paymentMethod}</span>
        </Box>
        <Box className={classes.epigraph}>
          Fecha oferta:
          <span className={classes.value}>{formatedCreatedAt}</span>
        </Box>
      </Box>
      {status === "Abierta" && (
        <Box>
          <Typography className={classes.responseTittle}>
            ¿Aceptas la oferta?
          </Typography>
          <Box className={classes.buttonsContainer}>
            <DesignSystemButton
              variant="tertiaryA"
              onClick={() => {
                handleClick(ResponseAnswer.REJECTED, "rejectForm");
              }}
              label="Rechazar"
              size="medium"
            />
            <DesignSystemButton
              variant="primary"
              onClick={() => {
                handleClick(ResponseAnswer.ACCEPTED, "resumeOffer");
              }}
              label="Aceptar"
              size="medium"
            />
          </Box>
        </Box>
      )}
      {status !== "Abierta" && (
        <Box className={classes.offerStatus}>{statusTittle}</Box>
      )}
    </Box>
  );
};

export default OfferCard;
