import { ReactElement } from "react";

import { Container } from "@material-ui/core";

import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import TeamWorkImage from "assets/images/adminTenant/team-work.svg";
import useLayoutStyles from "./LayoutStyles";

interface Props {
  title: string;
  subtitle: string | ReactElement;
}

const Layout = ({ title, subtitle }: Props) => {
  const classes = useLayoutStyles();
  const { state } = useEvaluation();
  return (
    <>
      <Header
        title={title}
        subtitle={subtitle}
        activeStep={state.activeStep}
        steps={state.steps}
      />
      <Container className={classes.container}>
        <img src={TeamWorkImage} alt="team-work" className={classes.image} />
      </Container>
    </>
  );
};

export default Layout;
