import { makeStyles, Theme } from "@material-ui/core";

const useSettingUpDashboardStyles = makeStyles((theme: Theme) => ({
  settingUpDashboard: {
    width: "100%",
  },
  settingUpDashboardContainer: {
    display: "grid",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "min-content min-content",
    justifyItems: "center",
    alignItems: "center",
    minWidth: "100%",
    [theme.breakpoints.up("sm")]: {
      gap: theme.spacing(3),
      width: "100%",
      gridTemplateColumns: "repeat(auto-fit, minmax(180px, 1fr))",
      gridAutoRows: "1fr",
      padding: 0,
    },
  },
}));

export default useSettingUpDashboardStyles;
