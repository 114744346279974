import { makeStyles, Theme } from "@material-ui/core";

const useCardInfoStyles = makeStyles<Theme>((theme) => ({
  card: {
    width: "100%",
    backgroundColor: "white",
    padding: theme.spacing(3, 2),
    borderRadius: 0,
    boxShadow: "3px 4px 15px rgba(38, 50, 56, 0.15)",
    [theme.breakpoints.up("sm")]: {
      borderRadius: 8,
      padding: theme.spacing(3),
      boxShadow: "none",
    },
  },
}));

export default useCardInfoStyles;
