import { CountryUpperCase, Locale, Timezones } from "models/Countries";

const getTimezone = (
  country: CountryUpperCase | string,
  commune: string
): Timezones => {
  if (country === "Chile") return "America/Santiago";
  else if (country === "Colombia") return "America/Bogota";
  else if (country === "Mexico") {
    if (commune === "Tijuana") return "America/Tijuana";
    return "America/Mexico_City";
  }
};

export const TIMEZONES_BY_COUNTRY_CODE = {
  cl: "America/Santiago",
  co: "America/Bogota",
  mx: "America/Mexico_City",
};

export const TIMEZONES_BY_COMMUNE = {
  Tijuana: "America/Tijuana",
};

export const timezoneByCountryCode = ({
  countryCode,
  commune,
}: {
  countryCode: Locale;
  commune?: string;
}): Timezones => {
  switch (countryCode) {
    case "co":
      return "America/Bogota";
    case "mx":
      if (commune === "Tijuana") return "America/Tijuana";
      return "America/Mexico_City";
    case "cl":
    default:
      return "America/Santiago";
  }
};

export default getTimezone;
