import { makeStyles, Theme } from "@material-ui/core";

const useBasicServicesStepStyles = makeStyles<Theme>((theme: Theme) => ({
  basicServicesPhotoHeader: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 5),
    },
  },
  basicServicesPhotoContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    flexGrow: 1,
    justifyContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 5),
    },
  },
  basicServicesPhotoContentText: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  basicServicesTitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(28),
  },
  stickyFooter: {
    display: "flex",
    position: "sticky",
    bottom: 0,
    padding: theme.spacing(3, 2),
    justifyContent: "space-between",
    backgroundColor: "#fff",
    borderTop: "1px solid #DFE5E8",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 5),
    },
  },
}));

export default useBasicServicesStepStyles;
