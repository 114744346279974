import { makeStyles, Theme } from "@material-ui/core";

const useExitVisitSummaryStyles = makeStyles((theme: Theme) => ({
  summaryRoot: {
    padding: theme.spacing(4, 2, 5),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5, 4, 12),
    },
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(28),
      lineHeight: 1,
    },
  },
  subtitle: {
    margin: theme.spacing(3, 0, 1),
    padding: theme.spacing(0.5, 1),
    backgroundColor: "#F3F7FD",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(4, 0, 1),
      fontSize: theme.typography.pxToRem(16),
    },
  },
  exitButton: {
    marginTop: theme.spacing(3),
  },
}));

export default useExitVisitSummaryStyles;
