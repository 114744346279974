/* eslint-disable max-lines */
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useHistory, useParams } from "react-router-dom";

import { Box, Drawer } from "@material-ui/core";

import useContract from "apps/tenant/hooks/useContract";
import { useMarket } from "apps/tenant/context/MarketContext";
import useGetUserBankAccounts from "apps/tenant/hooks/useGetUserBankAccounts";
import useGetBanks from "apps/tenant/hooks/useGetBanks";
import useUser from "apps/tenant/hooks/useUser";
import { IUserBankAccount } from "apps/tenant/models/BankAccount";
import { useNavbar } from "apps/ui/Navbar/context/navbarContext";
import Spinner from "Components/Shared/partials/Spinner";

import Dashboard from "../Dashboard";
import useBankAccountFormStyles from "./BankAccountFormStyles";
import Form from "./components/Form";
import Header from "./components/Header";
import useCreateBankAccount from "./hooks/useCreateBankAccount";
import { parseBankOptions } from "./utils/parsers";
import { BankAccountFormData } from "./utils/types";
import { getAccountTypeOptions } from "./utils/utils";

const BankAccountForm = () => {
  const classes = useBankAccountFormStyles();

  const { setBackUrl } = useNavbar();
  const { market } = useMarket();
  const { data: contract, isLoading: contractLoading } = useContract();
  const { isLoading: userLoading, user } = useUser();
  const { isLoading: banksLoading, data: banks } = useGetBanks({
    market,
  });
  const { data: bankAccounts } = useGetUserBankAccounts({
    market,
  });
  const { account_id: id } = useParams<{ account_id: string }>();
  const [bankAccount, setBankAccount] = useState<IUserBankAccount>();
  const history = useHistory();
  const {
    isLoading: isSubmitting,
    submitBankAccount,
    updateBankAccount,
  } = useCreateBankAccount();

  const onSubmit = (data: BankAccountFormData) => {
    const formData = {
      formData: data,
      country: market,
      userId: user?.id,
      propertyId: contract.propertyId,
    };
    if (id) {
      formData.propertyId = parseInt(id);
      return updateBankAccount(formData);
    }
    return submitBankAccount(formData);
  };

  useEffect(() => {
    if (isMobile) setBackUrl("/tenant");
  }, [setBackUrl]);

  useEffect(() => {
    if (id && bankAccounts) {
      const selectedBankAccount = bankAccounts.find(
        (account) => account.identifier === id
      );
      if (selectedBankAccount) setBankAccount(selectedBankAccount);
    }
  }, [id, bankAccounts]);

  if (contractLoading || userLoading || banksLoading) return <Spinner />;

  if (!contract || !user || !banks) {
    history.push("/tenant");
  }

  if (isMobile)
    return (
      <Box className={classes.bankAccountFormRoot}>
        <Header />
        <Form
          bankAccount={bankAccount}
          editing={!!id}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          accountTypeOptions={getAccountTypeOptions(market)}
          bankOptions={parseBankOptions(banks)}
        />
      </Box>
    );

  return (
    <Box className={classes.bankAccountFormRoot}>
      <Dashboard />
      <Drawer
        anchor="right"
        open={true}
        classes={{
          paper: classes.bankAccountFormDrawer,
        }}
        ModalProps={{
          onClose: () => history.push("/tenant"),
        }}
      >
        <Header />
        <Form
          bankAccount={bankAccount}
          editing={!!id}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          accountTypeOptions={getAccountTypeOptions(market)}
          bankOptions={parseBankOptions(banks)}
        />
      </Drawer>
    </Box>
  );
};

export default BankAccountForm;
