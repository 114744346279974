import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const usePropertyPriceStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    gap: 16,
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
}));

export default usePropertyPriceStyles;
