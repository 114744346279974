import { useMutation } from "react-query";

import { useGetToken } from "hooks";
import SummaryReportsService from "services/summaryReportService";
import userService from "services/userService";
import { triggerBlobFileDownload } from "../utils/utils";

interface RequestReportParams {
  month: string;
  year: string;
}

interface RequestReportResponse extends RequestReportParams {
  file: Blob;
}

type RequestReport = (
  args: RequestReportParams
) => Promise<RequestReportResponse>;

const useSummaryReport = () => {
  const { getToken } = useGetToken();
  const { id: userId } = userService.me();

  const requestReport: RequestReport = async ({ month, year }) => {
    const authToken = await getToken();
    const blobFile = await SummaryReportsService(
      authToken
    ).getAllPropertiesReport({
      userId,
      month,
      year,
    });
    return {
      file: blobFile.data,
      month,
      year,
    };
  };

  const handleSuccessfullRequest = ({
    file,
    month,
    year,
  }: RequestReportResponse) => {
    const fileName = `reporte-propiedades-${month}-${year}.xlsx`;
    triggerBlobFileDownload(file, fileName);
  };

  const {
    mutateAsync: handleReportDownload,
    isLoading,
    error,
  } = useMutation(requestReport, { onSuccess: handleSuccessfullRequest });

  return {
    handleReportDownload,
    isLoading,
    error,
  };
};

export default useSummaryReport;
