import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  label: {
    minWidth: "200px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "1rem",
    color: "#263238",
    margin: `0px  0px ${theme.spacing(1)}px 0px`,
  },
}));
