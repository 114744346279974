import { Typography } from "@material-ui/core";

import { getMonthDebtText } from "apps/landlord/pages/Property/utils/utils";
import { Locale } from "models/Countries";

import useDefaulterNotGuaranteedWarningStyles from "./DefaulterNotGuaranteedWarningStyles";
import DisclaimerRentalInfo from "../DisclaimerRentalInfo";

interface Props {
  country: Locale;
  lesseeMonthSinceDebt: Date;
}

const DefaulterNotGuaranteedWarning = ({
  country,
  lesseeMonthSinceDebt,
}: Props) => {
  const classes = useDefaulterNotGuaranteedWarningStyles();

  return (
    <DisclaimerRentalInfo
      className={classes.container}
      country={country}
      link="https://api.whatsapp.com/send/?app_absent=0&phone=%2B56994767485&text=%C2%A1Hola%21%20Quisiera%20recibir%20ayuda%20de%20Houm."
    >
      <Typography component="span" className={classes.text}>
        Tu arrendatario no ha pagado el arriendo
        {getMonthDebtText(lesseeMonthSinceDebt)}. De acuerdo al plan que tienes
        contratado, se transferirá el canon de arriendo 48 hrs después de
        conseguir su recaudación por vías extrajudiciales.
      </Typography>
      <Typography component="span" className={classes.textSecondary}>
        Te sugerimos iniciar las acciones de desalojo de tu propiedad haciendo
      </Typography>
    </DisclaimerRentalInfo>
  );
};

export default DefaulterNotGuaranteedWarning;
