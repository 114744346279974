import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 400,
    [theme.breakpoints.down("sm")]: {
      height: 250,
    },
    borderRadius: 10,
    overflow: "hidden",
    border: "1px solid #DFE5E8",
  },
}));

export default useStyles;
