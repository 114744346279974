import currency from "@houm-com/formats/currency";
import { format } from "date-fns";

import { IMovement, PaymentMethods } from "apps/tenant/models/Movement";

import { ParsedMovement } from "./types";

export const getPaidMovements = ({
  movements,
}: {
  movements: IMovement[];
}): IMovement[] => movements.filter((movement) => movement.status === "paid");

export const translateMethod = ({ method }: { method: PaymentMethods }) => {
  const translation = {
    UNKNOWN: "Método de pago desconocido",
    SERVIPAG: "Pagado en Servipag",
    TRANSFER: "Transferencia bancaria a la cuenta del negocio",
    OTROS_PAGOS: "Otros pagos",
    KHIPU: "Pagado por Khipu",
    ADMIN_LEASE: "Pagado desde la plataforma interna",
    SCRIPT: "Pagado con un script",
    PLACETOPAY: "Pagado por PlaceToPay",
    CARD: "Pagado con tarjeta bancaria vía Open Pay",
    AMEX: "Pagado con Amex",
    PAYCASH: "Pagado en efectivo",
    SPEIFAST: "Pagado vía Speifast",
    SPEI: "Transferencia bancaria con Open Pay",
    STORE: "En tienda con Open Pay",
  };
  if (!Object.keys(translation).includes(method)) return translation["UNKNOWN"];
  return translation[method];
};

export const parseMovements = ({
  movements,
}: {
  movements: IMovement[];
}): ParsedMovement[] =>
  getPaidMovements({ movements }).map((movement) => ({
    amount: currency(movement.totalAmount, movement.currency),
    date: movement.date ? format(movement.date, "dd/MM/yyyy") : "-",
    id: movement.id,
    method: translateMethod({ method: movement.method }),
  }));
