import { makeStyles, Theme } from "@material-ui/core";

const useHourIntervalsStyles = makeStyles((theme: Theme) => ({
  intervalContainer: {
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderBottom: "1px solid #D4D6D7",
  },
}));

export default useHourIntervalsStyles;
