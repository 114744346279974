import { makeStyles, Theme } from "@material-ui/core";
import {
  DESK_NAVBAR_HEIGHT,
  DESKTOP_CONTENT_MAX_WIDTH,
} from "apps/tenant/constants";

const usePaymentsListStyles = makeStyles((theme: Theme) => ({
  paymentsListRoot: {
    minHeight: "100%",
    backgroundColor: theme.palette.grey[50],
    [theme.breakpoints.up("sm")]: {
      height: `calc(100vh - ${DESK_NAVBAR_HEIGHT}px)`,
    },
  },
  paymentsListContent: {
    height: "100%",
    maxWidth: DESKTOP_CONTENT_MAX_WIDTH,
    margin: theme.spacing(0, "auto"),
    padding: theme.spacing(4, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 5),
    },
  },
  spinner: {
    height: "100%",
  },
  title: {
    marginBottom: theme.spacing(4),
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
  subtitle: {
    marginBottom: theme.spacing(4),
  },
}));

export default usePaymentsListStyles;
