import { makeStyles, Theme } from "@material-ui/core";

const useVisitsStyles = makeStyles((theme: Theme) => ({
  visitsRoot: {
    position: "relative",
    padding: theme.spacing(2),
    backgroundColor: "white",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      width: "50%",
      padding: theme.spacing(3),
    },
  },
  visitsTitle: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 700,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(26),
      lineHeight: theme.typography.pxToRem(34),
    },
  },
  visitsSubtitle: {
    margin: theme.spacing(1, 0),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 0, 2),
    },
  },
  visitsList: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  seeAll: {
    marginTop: theme.spacing(3),
    padding: 0,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      right: theme.spacing(3),
      top: 0,
    },
  },
}));

export default useVisitsStyles;
