import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box } from "@material-ui/core";
import IconButton from "@houm-com/ui/IconButton";
import { IoAddOutline } from "@houm-com/ui-icons";

import ErrorCard from "apps/landlord/components/ErrorCard";
import { IProperty } from "apps/landlord/models/Property";
import Spinner from "Components/Shared/partials/Spinner";
import analytics from "libs/analytics";

import PropertiesWrapper from "./components/PropertiesWrapper";
import RentedProperty from "./components/RentedProperty";
import SalesProperty from "./components/SalesProperty";
import useProperties from "../../hooks/useProperties";
import usePropertiesStyles from "./PropertiesStyles";
import RentProperty from "./components/RentProperty";

interface Props {
  property?: Partial<IProperty>;
}

const Properties = ({ property = null }: Props) => {
  const { t } = useTranslation(["landlord.dashboard"]);
  const classes = usePropertiesStyles();
  const history = useHistory();
  const { isLoading, data, isError, refetch } = useProperties(
    "properties_summary",
    property?.id.toString(),
    {
      rental_status_not: "archived",
      page_size: 150,
    }
  );

  return (
    <Box className={classes.rootContainer}>
      <Box className={classes.buttonContainer}>
        <IconButton
          variant="primary"
          size="md"
          onClick={() => {
            analytics.event("create_property", {
              category: "landlord",
              action: "landing_create_property",
            });
            history.push("/landlord/create-property");
          }}
          icon={<IoAddOutline />}
          position="end"
        >
          {t("CREATE_PROPERTY_BUTTON_LABEL")}
        </IconButton>
      </Box>
      {isLoading && (
        <Box className={classes.loadingContainer}>
          <Spinner />
        </Box>
      )}
      {isError && (
        <ErrorCard title={t("ERROR_GET_PROPERTIES")} onRefetchData={refetch} />
      )}
      {!isLoading && !isError && (
        <Box className={classes.propertiesContainer}>
          {data.rentedProperties.length > 0 && (
            <PropertiesWrapper title={t("RENTED_PROPERTIES")}>
              {data.rentedProperties.map((propertyRented) => (
                <RentedProperty
                  key={propertyRented.id}
                  propertyData={propertyRented}
                />
              ))}
            </PropertiesWrapper>
          )}
          {data.forRentalProperties.length > 0 && (
            <PropertiesWrapper title={t("RENT_PROPERTIES")}>
              {data.forRentalProperties.map((propertyForRental) => (
                <RentProperty
                  propertyData={propertyForRental}
                  key={propertyForRental.id}
                />
              ))}
            </PropertiesWrapper>
          )}
          {data.forSaleProperties.length > 0 && (
            <PropertiesWrapper title={t("SALE_PROPERTIES")}>
              {data.forSaleProperties.map((propertyForSale) => (
                <SalesProperty
                  propertyData={propertyForSale}
                  key={propertyForSale.id}
                />
              ))}
            </PropertiesWrapper>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Properties;
