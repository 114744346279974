import { IoDocumentOutline as DocumentIcon } from "@houm-com/ui-icons";
import { useFeatureManager } from "@houm-com/feature-manager";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Locale } from "models/Countries";
import RequestCard from "../RequestCard";

interface Props {
  country: Locale;
  statusRequest: "in_progress" | "cancelled" | "finished";
}

const RequestEndHoumContract = ({ country, statusRequest }: Props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { data: card, status } = useFeatureManager("END_HOUM_MANDATE", {
    marketCode: country,
  });
  const { data: cardPending, status: statusPending } = useFeatureManager(
    "END_HOUM_MANDATE_PENDING",
    {
      marketCode: country,
    }
  );

  const cardDetails = statusRequest === "in_progress" ? cardPending : card;

  return (
    <RequestCard
      ctaText={cardDetails?.content.ctaText}
      description={cardDetails?.content.description}
      onClick={() => history.push(`${match.url}${cardDetails?.content.url}`)}
      title={cardDetails?.content.title}
      CardIcon={DocumentIcon}
      isLoading={status === "loading" || statusPending === "loading"}
    />
  );
};

export default RequestEndHoumContract;
