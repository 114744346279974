import { makeStyles, Theme } from "@material-ui/core";
import { MOBILE_HEADER_HEIGHT } from "apps/landlord/constants/layout";

const useNavbarMobileStyles = makeStyles<Theme>((theme) => ({
  headerRoot: {
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1000,
    boxShadow: "3px 4px 20px rgba(38, 50, 56, 0.15)",
  },
  navigationRoot: {
    height: MOBILE_HEADER_HEIGHT,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
    backgroundColor: "white",
  },
  leftSection: {
    display: "flex",
    alignItems: "center",
    width: 200,
    flexWrap: "nowrap",
  },
  backText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
    fontWeight: 700,
    color: "#4C6977",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  mobileBackContainer: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    height: 32,
  },
}));

export default useNavbarMobileStyles;
