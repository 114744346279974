import { AxiosResponse } from "axios";
import { BACKEND_URL } from "env";
import { createHttp } from "services/helper";

export default {
  startEmailChange: (token: string, email: string): Promise<AxiosResponse> =>
    createHttp(token, BACKEND_URL).post("/v2/users/start-email-change", {
      email,
    }),
  confirmEmailChange: (
    token: string,
    previous_email: string,
    code: string
  ): Promise<AxiosResponse> =>
    createHttp(token, BACKEND_URL).put("/users/change-email", {
      previous_email,
      code,
    }),
  passwordChange: (token: string, password: string): Promise<AxiosResponse> =>
    createHttp(token, BACKEND_URL).post("/v2/users/change-password", {
      password,
    }),
  startPhoneChange: (token: string, phone: string): Promise<AxiosResponse> =>
    createHttp(token, BACKEND_URL).post("/v2/users/start_phone_change", {
      phone,
    }),
  verifyPhoneChange: (token: string, code: string): Promise<AxiosResponse> =>
    createHttp(token, BACKEND_URL).post("/v2/users/change_phone", {
      code,
    }),
};
