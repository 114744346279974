import { add } from "date-fns";

import { IMovement } from "apps/landlord/models/Movement";

import { BreakdownMovement } from "models/Payments";
import { CurrencyCode } from "models/Currencies";

const getMovementDate = (
  date: string | null,
  status: string,
  agreed_date: string
) => {
  if (!date) return null;
  return status === "PAID"
    ? add(new Date(date), { days: 1 })
    : add(new Date(agreed_date), { days: 1 });
};

export const parseMovements = (
  data: Partial<BreakdownMovement>[]
): IMovement[] =>
  data?.map((movement) => ({
    id: movement.movement_id || movement.payment_detail_id,
    status: movement.status === "PAID" ? "paid" : "pending",
    date: getMovementDate(
      movement?.date,
      movement.status,
      movement.agreed_date
    ),
    totalPrice: movement.total_amount,
    currency: movement.currency as CurrencyCode,
    rentPrice: movement.original_amount,
    planCharges: movement.discounts_breakdown?.find(
      (disc) => disc.label === "MANAGEMENT"
    )?.value,
    discountsBreakdown: movement.discounts_breakdown
      ?.filter((discount) => discount.label !== "MANAGEMENT")
      ?.map((discount) => discount),
    chargesBreakdown: movement.additionals_breakdown?.map((charge) => charge),
    readjustment: {
      totalValue: movement.readjustment,
      adjustmentBreakdown: movement.readjustments_breakdown?.map(
        (readjustment) => ({
          description: readjustment.description,
          value: readjustment.value,
          label: readjustment.label,
          periods: readjustment.periods?.map((period) => ({
            initialDate: `${period.initial_date}T00:00:00`,
            finalDate: `${period.final_date}T00:00:00`,
            variation: period.variation,
          })),
          identifier: readjustment.identifier,
        })
      ),
    },
  }));
