import Swal from "sweetalert2";

import { Grid } from "@material-ui/core";

import PropertyApplicationCard from "apps/lead/components/PropertyApplicationCard";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { ApplicationModel } from "apps/lead/models/Application";

import useApplicationActions from "../../../hooks/useApplicationActions";
import useApplicationList from "../../../hooks/useApplicationList";
import useApplicationsListStyles from "./ApplicationsListStyles";

const ApplicationsList = () => {
  const classes = useApplicationsListStyles();
  const { getLabelButton, getOnAction } = useApplicationActions();
  const { isLoading = true, data: applications, error } = useApplicationList();

  if (error) {
    Swal.fire({
      type: "error",
      text: "Hubo un problema al cargar los datos, por favor intente más tarde",
    });
  }

  return (
    <Loading loading={isLoading} className={classes.loading}>
      <div className={classes.wrapper}>
        <Grid item className={classes.container}>
          {applications.map((application: ApplicationModel) => (
            <Grid item key={application.id} className={classes.cardContainer}>
              <PropertyApplicationCard
                labelButton={getLabelButton(application)}
                handleClick={getOnAction(application)}
                property={application.property}
                houmerName={application.houmer?.name}
                evaluationData={application.evaluationData}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </Loading>
  );
};

export default ApplicationsList;
