import { Typography } from "@material-ui/core";

import {
  IoHomeOutline as HouseIcon,
  IoBedOutline as BedIcon,
} from "@houm-com/ui-icons";

import SurfaceIcon from "assets/icons/Landlord/SurfaceIcon";
import BathIcon from "assets/icons/Landlord/BathroomsIcon";

import { PropertyCharacteristics } from "apps/account/models/FavoriteProperty";

import useAmenitiesRowStyles from "./AmenitiesRowStyles";

interface Props {
  characteristics: PropertyCharacteristics;
}

const AmenitiesRow = ({ characteristics }: Props) => {
  const { baths, bedrooms, builtSurface, totalSurface } = characteristics;
  const classes = useAmenitiesRowStyles();
  return (
    <div className={classes.row}>
      <div className={classes.item}>
        <SurfaceIcon className={classes.icon} />
        <Typography className={classes.itemText}>{totalSurface} m²</Typography>
      </div>
      <div className={classes.item}>
        <HouseIcon className={classes.icon} />
        <Typography className={classes.itemText}>{builtSurface} m²</Typography>
      </div>
      <div className={classes.item}>
        <BedIcon className={classes.icon} />
        <Typography className={classes.itemText}>{bedrooms}</Typography>
      </div>
      <div className={classes.item}>
        <BathIcon className={classes.icon} />
        <Typography className={classes.itemText}>{baths}</Typography>
      </div>
    </div>
  );
};
export default AmenitiesRow;
