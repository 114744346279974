import { makeStyles, Theme } from "@material-ui/core/";

const defaultBreakpoint = "md";

const useSubscriptionPlansHeaderStyles = makeStyles<Theme>((theme) => ({
  titleGeneral: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(33),
      marginBottom: theme.spacing(5),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    color: theme.palette.primary.main,
    lineHeight: 1.5,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(28),
      lineHeight: 1,
      marginBottom: theme.spacing(2),
    },
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: theme.palette.grey[900],
    lineHeight: 1.25,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(22),
      lineHeight: 1,
    },
  },
}));

export default useSubscriptionPlansHeaderStyles;
