import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import OpenPayOption from "apps/payments/components/OpenPayOption";

import withPaymentWrapper from "../PaymentsWrapperHoc";
import useOpenPayLink from "./hooks/useOpenPayLink";

const OpenPayDetails = () => {
  const { isLoading, mutate } = useOpenPayLink();
  const { state } = useRentPayment();

  const onHandlePayment = (method: string) => {
    if (isLoading) return;
    mutate({
      movementId: state.paymentData.movementId,
      option: method,
    });
  };

  const OpenPayEnhancement = withPaymentWrapper(OpenPayOption);

  return (
    <OpenPayEnhancement
      buttonProps={{
        onHandlePayment,
      }}
    />
  );
};
export default OpenPayDetails;
