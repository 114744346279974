/* eslint-disable camelcase */

import { PhoneInput } from "models/PhoneInput";

// eslint-disable-next-line no-unused-vars
export type Dispatch = (action: Action) => void;

export const SET_LOADING = "SET_LOADING";
export const SET_IS_PHONE_OPEN = "SET_IS_PHONE_OPEN";
export const SET_PHONE_DISPLAY_STATUS = "SET_PHONE_DISPLAY_STATUS";
export const SET_ACCESS_KEYS = "SET_ACCESS_KEYS";
export const SET_ACCESS_TOKEN_KEY = "SET_ACCESS_TOKEN_KEY";
export const SET_REFRESH_TOKEN_KEY = "SET_REFRESH_TOKEN_KEY";
export const SET_AGENT_DIDS = "SET_AGENT_DIDS";
export const SET_TOKY_CLIENT = "SET_TOKY_CLIENT";
export const SET_TOKY_AUDIO_DEVICES = "SET_TOKY_AUDIO_DEVICES";
export const SET_TOKY_SESSION = "SET_TOKY_SESSION";
export const SET_TOKY_SESSION_STATUS = "SET_TOKY_SESSION_STATUS";
export const SET_DIALED_PHONE_NUMBER = "SET_DIALED_PHONE_NUMBER";
export const SET_ON_CLOSE_CALLBACK_FN = "SET_ON_CLOSE_CALLBACK_FN";
export const SET_DID_CALL_CONNECTED = "SET_DID_CALL_CONNECTED";
export const SET_TOKY_CALL_ID = "SET_TOKY_CALL_ID";
export const SET_CALLBACKS = "SET_CALLBACKS";
export const SET_READY_TO_CALL = "SET_READY_TO_CALL";

export interface State {
  accessToken: string;
  refreshToken: string;
  loading: boolean;
  agentDids: IAgentDid[];
  isPhoneOpen: boolean;
  phoneDisplayStatus: PhoneDisplayStatus;
  TokyClient: any;
  audioDevices: IAudioDevices;
  tokySession: any;
  tokySessionStatus: TokySessionStatus;
  dialiedPhoneNumber: PhoneInput;
  onCloseCallbackFn: (args?: any) => void | Promise<void>;
  didCallConnected: boolean;
  tokyCallId: string;
  callbacks?: ICallbacks;
  readyToCall: boolean;
}

interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}

interface setReadyToCall {
  type: typeof SET_READY_TO_CALL;
  readyToCall: boolean;
}

interface setIsPhoneOpen {
  type: typeof SET_IS_PHONE_OPEN;
  isPhoneOpen: boolean;
}

interface setPhoneDisplayStatus {
  type: typeof SET_PHONE_DISPLAY_STATUS;
  phoneDisplayStatus: PhoneDisplayStatus;
}
interface setAccessKeys {
  type: typeof SET_ACCESS_KEYS;
  tokens: TokenAccessKeys;
}

interface setAccessTokenKey {
  type: typeof SET_ACCESS_TOKEN_KEY;
  accessToken: string;
}

interface setRefreshTokenKey {
  type: typeof SET_REFRESH_TOKEN_KEY;
  refreshToken: string;
}

interface setAgentDids {
  type: typeof SET_AGENT_DIDS;
  agentDids: IAgentDid[];
}

interface setTokyClient {
  type: typeof SET_TOKY_CLIENT;
  TokyClient: any;
}

interface setTokyAudioDevices {
  type: typeof SET_TOKY_AUDIO_DEVICES;
  audioDevices: IAudioDevices;
}

interface setTokySession {
  type: typeof SET_TOKY_SESSION;
  tokySession: any;
}

interface setTokySessionStatus {
  type: typeof SET_TOKY_SESSION_STATUS;
  tokySessionStatus: TokySessionStatus;
}

interface setDialedPhoneNumber {
  type: typeof SET_DIALED_PHONE_NUMBER;
  dialiedPhoneNumber: PhoneInput;
}

interface setOnCloseCallbackFn {
  type: typeof SET_ON_CLOSE_CALLBACK_FN;
  onCloseCallbackFn: (args?: any) => void | Promise<void>;
}

interface setDidCallConnected {
  type: typeof SET_DID_CALL_CONNECTED;
  didCallConnected: boolean;
}

interface setTokyCallId {
  type: typeof SET_TOKY_CALL_ID;
  tokyCallId: string;
}

interface setCallbacks {
  type: typeof SET_CALLBACKS;
  callbacks: ICallbacks;
}

export interface TokenAccessKeys {
  accessToken?: string;
  refreshToken?: string;
}

export interface IAgentDid {
  number: string;
  city: string;
  friendlyName: string;
}

export type ICallbacks = {
  [key in TokySessionStatus]?: (args?: any) => void;
};

export interface IDevice {
  id: string;
  name: string;
  kind: string;
}

export interface IAudioDevices {
  input: IDevice[];
  output: IDevice[];
  currentInput?: IDevice;
  currentOutput?: IDevice;
}

export interface TokyCallInfo {
  id?: string;
  callId?: string;
  duration?: number;
  url?: string;
}

export enum TokySessionStatus {
  OFF = "OFF",
  BYE = "BYE",
  CONNECTING = "CONNECTING",
  RINGING = "RINGING",
  CONNECTED = "CONNECTED",
  REJECTED = "REJECTED",
  SESSION_UPDATED = "SESSION_UPDATED",
  FAILED = "FAILED",
  REGISTERED = "REGISTERED",
  REGISTRATION_FAILED = "REGISTRATION_FAILED",
  UNREGISTERED = "UNREGISTERED",
  READY = "READY",
}

export enum PhoneDisplayStatus {
  CLOSED = "CLOSED",
  OPEN = "OPEN",
  MINIMIZED = "MINIMIZED",
}

export type Action =
  | setLoading
  | setIsPhoneOpen
  | setAccessKeys
  | setAccessTokenKey
  | setRefreshTokenKey
  | setAgentDids
  | setTokyClient
  | setTokyAudioDevices
  | setTokySession
  | setTokySessionStatus
  | setPhoneDisplayStatus
  | setDialedPhoneNumber
  | setOnCloseCallbackFn
  | setDidCallConnected
  | setCallbacks
  | setReadyToCall
  | setTokyCallId;
