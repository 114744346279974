import { makeStyles, Theme } from "@material-ui/core";

const useRepairsRequestPageStyles = makeStyles<Theme>((theme) => ({
  root: {
    backgroundColor: "white",
  },
  container: {
    padding: theme.spacing(3, 2),
  },
  drawer: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
  },
  drawerRoot: {
    backgroundColor: "rgba(38, 50, 56, 0.4)",
  },
  drawerContent: {
    padding: theme.spacing(5),
  },
}));

export default useRepairsRequestPageStyles;
