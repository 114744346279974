import { ComponentProps } from "react";

import Button from "@houm-com/ui/Button";

import BottomBar from "./components/BottomBar";
import Content from "./components/Content";
import Navbar from "./components/Navbar";

interface Props {
  handleBack?: () => void;
  buttonNextText: string;
  buttonProps: Partial<ComponentProps<typeof Button>>;
  loading?: boolean;
}

const Layout: React.FC<Props> = ({
  children,
  handleBack,
  buttonNextText,
  buttonProps,
  loading,
}) => (
  <>
    <Navbar handleBack={handleBack} />
    <Content>{children}</Content>
    <BottomBar
      buttonNextText={buttonNextText}
      buttonProps={buttonProps}
      handleBack={handleBack}
      loading={loading}
    />
  </>
);

export default Layout;
