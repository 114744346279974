import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@material-ui/core";

import FilteredInput from "../components/FilteredInput";
import CallToAction from "Components/Admin/UIComponents/atoms/CallToAction";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import {
  ControlledSelection,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";

import { personalDataFields } from "../schema/fields";
import { personalDataValidators } from "../schema/validations";

import { useLoiForm } from "../context/LoiFormContext";
import { CIVIL_STATUSES } from "context/loiFormContext/constants";
import { LoiPersonalData } from "../context/LoiFormContext.types";

import useStepsStyles from "./StepsStyles";

const PersonalData = () => {
  const classes = useStepsStyles();
  const {
    actions: { setActiveStep, setPersonalData },
    state: { activeStep, personalData, country, documentTypes },
  } = useLoiForm();

  const methods = useForm<LoiPersonalData>({
    resolver: yupResolver(personalDataValidators(/([A-Z0-9])\w+/g)),
    reValidateMode: "onBlur",
  });

  const onSubmit = (formData) => {
    setPersonalData(formData);
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if (country === "Chile" && !personalData.identificationType) {
      setPersonalData({
        ...personalData,
        identificationType: documentTypes[0].value,
      });
    }
  }, [documentTypes, personalData]);

  return (
    <FormProvider {...methods}>
      <Grid container className={classes.rootGrid}>
        <Grid item>
          <Typography className={classes.title}>
            Tu información personal
          </Typography>
          <Typography className={classes.subtitle}>
            Asegúrate que la información sea correcta, ya que see verá reflejada
            de tus documentos de venta.
          </Typography>
        </Grid>
        <Grid item>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FilteredInput fieldInfo={personalDataFields.name()}>
              <ControlledTextField
                id="name"
                label={personalDataFields.name().label(country)}
                textFieldProps={{ placeholder: "Ingresar" }}
                defaultValue={personalData.name}
              />
            </FilteredInput>

            <FilteredInput fieldInfo={personalDataFields.lastName()}>
              <ControlledTextField
                id="lastName"
                label={personalDataFields.lastName().label(country)}
                textFieldProps={{ placeholder: "Ingresar" }}
                defaultValue={personalData.lastName}
              />
            </FilteredInput>

            <FilteredInput fieldInfo={personalDataFields.secondLastName()}>
              <ControlledTextField
                id="secondLastName"
                label={personalDataFields.secondLastName().label(country)}
                textFieldProps={{ placeholder: "Ingresar" }}
                defaultValue={personalData.secondLastName}
              />
            </FilteredInput>

            <FilteredInput fieldInfo={personalDataFields.email()}>
              <ControlledTextField
                id="email"
                label={personalDataFields.email().label(country)}
                textFieldProps={{ placeholder: "correo@houm.com" }}
                defaultValue={personalData.email}
              />
            </FilteredInput>

            <FilteredInput fieldInfo={personalDataFields.phone()}>
              <ControlledTextField
                id="phone"
                label={personalDataFields.phone().label(country)}
                textFieldProps={{ placeholder: "Ingresar" }}
                defaultValue={personalData.phone}
              />
            </FilteredInput>

            <FilteredInput fieldInfo={personalDataFields.occupation()}>
              <ControlledTextField
                id="occupation"
                label={personalDataFields.occupation().label(country)}
                textFieldProps={{ placeholder: "Ingresar" }}
                defaultValue={personalData.occupation}
              />
            </FilteredInput>

            <FilteredInput fieldInfo={personalDataFields.identificationType()}>
              <ControlledSelection
                id="identificationType"
                label={personalDataFields.identificationType().label(country)}
                defaultValue={personalData.identificationType}
                options={documentTypes}
                compressed={true}
                simple={true}
              />
            </FilteredInput>

            <FilteredInput
              fieldInfo={personalDataFields.identificationNumber()}
            >
              <ControlledTextField
                id="identificationNumber"
                label={personalDataFields.identificationNumber().label(country)}
                textFieldProps={{ placeholder: "Ingresar" }}
                defaultValue={personalData.identificationNumber}
              />
            </FilteredInput>

            <FilteredInput fieldInfo={personalDataFields.rfc()}>
              <ControlledTextField
                id="rfc"
                label={personalDataFields.rfc().label(country)}
                textFieldProps={{ placeholder: "Ingresar" }}
                defaultValue="123"
              />
            </FilteredInput>

            <FilteredInput fieldInfo={personalDataFields.civilStatus()}>
              <ControlledSelection
                id="civilStatus"
                label={personalDataFields.civilStatus().label(country)}
                defaultValue="married"
                options={CIVIL_STATUSES}
                compressed={true}
                simple={true}
              />
            </FilteredInput>
            <CallToAction>
              <DesignSystemButton
                label="Continuar"
                type="submit"
                size="large"
                variant="primary"
                fullWidth
              />
            </CallToAction>
          </form>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default PersonalData;
