import { Grid, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import clx from "classnames";
import useStyles from "./CircularStepperStyles";

interface Props {
  title?: string;
  step: number;
  totalSteps: number;
  stepTextHidden?: boolean;
  variant: "primary" | "default";
}

export default function CircularStepper({
  title,
  step,
  totalSteps,
  stepTextHidden,
  variant,
}: Props) {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item className={classes.containerProgress}>
        <Typography component="h3" className={classes.steps}>
          <strong>{step + 1}</strong>/{totalSteps}
        </Typography>
        <div className={classes.root}>
          <CircularProgress
            variant="determinate"
            className={
              classes[variant !== "default" ? `bottom${variant}` : "bottom"]
            }
            value={100}
          />
          <CircularProgress
            variant="determinate"
            className={clx(classes.top, {
              [classes.topPrimary]: variant === "primary",
            })}
            value={((totalSteps - (step + 1)) / totalSteps) * 100}
          />
        </div>
      </Grid>
      <Grid item xs={8} className={classes.containerSteps}>
        {!stepTextHidden && (
          <Typography component="h3" className={classes.stepText}>
            Paso {step + 1}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
