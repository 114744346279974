import { makeStyles, Theme } from "@material-ui/core";

const useVisitDetailsStyles = makeStyles((theme: Theme) => ({
  visitDetailsPaper: {
    width: "100%",
    margin: theme.spacing("auto", 2),
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
      maxWidth: 420,
    },
  },
  visitDetailsContent: {
    position: "relative",
    padding: theme.spacing(3, 4),
  },
  crossIcon: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    transform: "rotate(45deg)",
    color: theme.palette.grey[500],
  },
  calendar: {
    fontSize: theme.typography.pxToRem(32),
    marginBottom: theme.spacing(2.5),
  },
  date: {
    fontSize: theme.typography.pxToRem(26),
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(34),
  },
  unavailableDate: {
    opacity: 0.2,
  },
  info: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
  houmerSection: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(3),
    "& p, strong": {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(22),
    },
  },
  unavailable: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: "#F7E6E5",
    borderRadius: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
  houmerAvatar: {
    width: 56,
    height: 56,
  },
}));

export default useVisitDetailsStyles;
