import { AxiosResponse } from "axios";

export interface InspectionReportAppraiser {
  id: number;
  email: string;
  name: string;
}

export interface InspectionReportPhoto {
  photo_filename: string;
  photo_url: string;
}

export interface InspectionReportTenantProof {
  name: string;
  signature_url: string;
}

export enum KeysInfoLocation {
  HOUMBOX = "houmbox",
  LANDLORD = "landlord",
  CONCIERGE = "concierge",
  APPRAISER = "appraiser",
  THIRD_PARTY = "third_party",
}

export interface InspectionReportKeysInfoSchedule {
  id: number;
  appraiser: InspectionReportAppraiser;
  begin_date: string | null;
  status: string; //tbd
  updated_at: string;
}

export interface InspectionReportKeysInfo {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: null;
  property: number;
  schedule: InspectionReportKeysInfoSchedule;
  location: KeysInfoLocation;
  front_door_copies: number;
  condo_copies: number;
  garage_copies: number;
  warehouse_copies: number;
  other_copies: number;
  other_copies_description: string;
  houmbox_lock_number: number;
  houmbox_lock_code: number;
  receiver_name: string;
  photos: InspectionReportPhoto[];
}

export enum InspectionReportQualification {
  EXCELLENT = "excellent",
  GOOD = "good",
  ACCEPTABLE = "acceptable",
  BAD = "bad",
  DOES_NOT_HAVE = "does_not_have",
  UNANSWERED = "unanswered",
}

export enum InspectionReportSpacesType {
  LIVING_ROOM = "living_room",
  DINING_ROOM = "dining_room",
  ROOM = "room",
  BATHROOM = "bathroom",
  KITCHEN = "kitchen",
  LAUNDRY_ROOM = "laundry_room",
  TERRACE = "terrace",
  OTHER = "other",
}

export enum InspectionReportFeature {
  WALLS = "walls",
  FLOORS = "floors",
  CEILING = "ceiling",
  WINDOWS = "windows",
  DOORS = "doors",
  CHIMNEY = "chimney",
  STAIRS = "stairs",
  CABINETS = "cabinets",
  WORKTOP = "worktop",
  OVEN = "oven",
  EXTRACTOR_HOOD = "extractor_hood",
  DISHWASHER = "dishwasher",
  WATER_PRESSURE = "water_pressure",
  CLOSETS = "closets",
  TOILET = "toilet",
  TUB = "tub",
  SHOWER = "shower",
  SHOWER_DOOR = "shower_door",
  WASHBASIN = "washbasin",
  BATHROOM_EXTRACTOR = "bathroom_extractor",
  MIRRORS = "mirrors",
  LAMPS = "lamps",
  SWITCHES = "switches",
  PLUGS = "plugs",
  NETWORK_CONNECTERS = "network_connectors",
  COAXIAL_CONNECTORS = "coaxial_connectors",
  CURTAINS = "curtains",
  CARPETS = "carpets",
  OTHER = "other",
}

export enum InspectionReportSpaceReportStatus {
  CREATED = "created",
  UPDATED = "updated",
  PRE_QUOTED = "pre_quoted",
  RESOLVED = "resolved",
}

export enum InspectionReportSpaceReportActionType {
  CREATED = "created",
  UPDATED = "updated",
  PRE_QUOTED = "pre_quoted",
}

export interface InspectionReportSpaceReportAction {
  id: number;
  created_at: string;
  updated_at: string;
  observation: string;
  inspection_form: number;
  pre_quotation_value: number | null;
  pre_quotation_currency: string | null;
  photos: InspectionReportPhoto[];
  action_by: InspectionReportAppraiser;
  action: InspectionReportSpaceReportActionType;
}

export interface InspectionReportSpaceReport {
  id: number;
  created_at: string;
  updated_at: string;
  feature: InspectionReportFeature;
  status: InspectionReportSpaceReportStatus;
  report_actions: InspectionReportSpaceReportAction[];
}

export interface InspectionReportSpace {
  id: number;
  created_at: string;
  updated_at: string;
  description: string;
  photo_link?: string;
  type: InspectionReportSpacesType;
  reports: InspectionReportSpaceReport[];
}

export interface InspectionReportSpaceQualification {
  id: number;
  created_at: string;
  updated_at: string;
  observation: string;
  photos: InspectionReportPhoto[];
  qualification: InspectionReportQualification;
  space: InspectionReportSpace;
}

export interface InspectionReport {
  id: number;
  property: number;
  schedule: number;
  created_at: string;
  updated_at: string;
  is_habitable: boolean;
  requires_cleaning: boolean;
  cleaning_observation: string | null;
  general_observation: string | null;
  comments_allowed: boolean;
  schedule_type: string;
  appraiser: InspectionReportAppraiser;
  space_qualifications: InspectionReportSpaceQualification[];
  tenant_proof: InspectionReportTenantProof | null;
  keys_info: InspectionReportKeysInfo | null;
  water_status: "does_have" | "does_not_have";
  energy_status: "does_have" | "does_not_have";
  gas_status: "does_have" | "does_not_have" | "does_not_apply";
}

export interface InspectionReportComment {
  id: number;
  created_at: string;
  comment: string;
  photos: InspectionReportPhoto[];
}

export interface PaginatedInspectionReports {
  count: number;
  next: string | null;
  previous: string | null;
  results: InspectionReport[];
}

export interface PaginatedInspectionReportComments {
  count: number;
  next: string | null;
  previous: string | null;
  results: InspectionReportComment[];
}

export type InspectionReportListViewType = "regular" | "filtered";

export interface GetInspectionReportListArgs {
  propertyId: number;
  pageSize?: number;
  page?: number;
  view?: InspectionReportListViewType;
  filters?: Record<string, string>;
}

export interface GetInspectionReporCommentsArgs {
  nextPageURL?: string;
  propertyId: number;
  inspectionReportId: number;
  page?: number;
  pageSize?: number;
}

export interface NewInspectionReportCommentPayload {
  comment: string;
  photos: {
    photo_filename: string;
  }[];
}

export interface CreateInspectionReporCommentArgs {
  propertyId: number;
  inspectionReportId: number;
  inspectionReportComment: NewInspectionReportCommentPayload;
}

export interface CreateUploadCommentPhotoPresignedURLArgs {
  filenames: string[];
}

export type InspectionReportListApiResponse =
  AxiosResponse<PaginatedInspectionReports>;

export type InspectionReportCommentsApiResponse =
  AxiosResponse<PaginatedInspectionReportComments>;

export type CreateInspectionReportCommentApiResponse = AxiosResponse<{
  status: 200;
}>;

interface PresignedUrl {
  url: string;
  fields: {
    "Content-Type": string;
    key: string;
    policy: string;
    "x-amz-algorithm": string;
    "x-amz-credential": string;
    "x-amz-date": string;
    "x-amz-signature": string;
  };
}
export interface PresignedUrlResponse {
  [key: string]: PresignedUrl;
}

export interface InspectionReportServiceResponse {
  getInspectionsReport: (
    args: GetInspectionReportListArgs
  ) => Promise<InspectionReportListApiResponse>;
  getInspectionReportComments: (
    args: GetInspectionReporCommentsArgs
  ) => Promise<InspectionReportCommentsApiResponse>;
  createInspectionReportComment: (
    args: CreateInspectionReporCommentArgs
  ) => Promise<CreateInspectionReportCommentApiResponse>;
  createUploadCommentPhotoPresignedURL: (
    args: CreateUploadCommentPhotoPresignedURLArgs
  ) => Promise<AxiosResponse<PresignedUrlResponse>>;
}

export type InspectionReportService = (
  token: string
) => InspectionReportServiceResponse;
