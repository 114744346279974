import { Controller, useFormContext } from "react-hook-form";

import { MenuItem, Select, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import { CommonExpensesCompanies } from "apps/landlord/models/ServicesCompanies";
import useCompanySelectorStyles from "./CompanySelectorStyles";

interface Props {
  companyOptions: {
    label: string;
    value: string;
  }[];
  setCompanyName: (x: CommonExpensesCompanies) => void;
  companySelected?: CommonExpensesCompanies | null;
}

export default function CompanySelector({
  companyOptions,
  setCompanyName,
  companySelected = null,
}: Props) {
  const classes = useCompanySelectorStyles();
  const { control } = useFormContext();

  return (
    <>
      <Typography className={classes.ceFormSectionLabel}>
        {"Empresa que gestiona tus gastos comunes "}
        <span className={classes.ceFormSectionRequiredField}>*</span>
      </Typography>
      <Controller
        name="companyName"
        control={control}
        defaultValue={companySelected}
        render={({ field: { value, onChange, ref } }) => (
          <Select
            disabled={!!companySelected}
            className={classes.ceFormSectionTextField}
            IconComponent={ExpandMore}
            classes={{
              select: classes.ceFormSectionSelect,
            }}
            disableUnderline
            onChange={(e) => {
              onChange(e);
              setCompanyName(e.target.value as CommonExpensesCompanies);
            }}
            value={value}
            defaultValue={companySelected}
            ref={ref}
          >
            {companyOptions.map((company) => (
              <MenuItem key={company.value} value={company.value}>
                {company.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </>
  );
}
