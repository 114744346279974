import { makeStyles, Theme } from "@material-ui/core";

const usePlainFieldStyles = makeStyles((theme: Theme) => ({
  plainFieldRoot: {
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2, 0),
    },
  },
  label: {
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
  },
  value: {
    padding: theme.spacing(0.5, 1),
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default usePlainFieldStyles;
