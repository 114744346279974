import React from "react";

import { Dialog, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import useStyles, {
  useButtonStyles,
  useDialogStyles,
} from "./CustomDialogStyles";

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  showNavbar?: boolean;
  paperClassName?: string;
  buttonClassName?: string;
  showLogo?: boolean;
}

const CustomDialog: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  showNavbar = true,
}) => {
  const classes = useStyles();
  const buttonStyles = useButtonStyles();
  const dialogStyles = useDialogStyles();

  return (
    <Dialog
      open={isOpen}
      PaperProps={{ className: dialogStyles.paperRoot }}
      onClose={onClose}
    >
      {showNavbar && (
        <div className={classes.navbar}>
          <IconButton className={buttonStyles.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
      {children}
    </Dialog>
  );
};

export default CustomDialog;
