import { makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme) => ({
  container: {
    height: 120,
    minHeight: 0,
    width: "100% !important",
    display: "block",
  },
  helperText: {
    margin: theme.spacing(0.5, 0, 1, 1),
    fontSize: "0.75rem",
    lineHeight: 1.4,
  },
  icon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    color: theme.palette.primary.main,
    width: "35px",
    height: "35px",
    marginBottom: theme.spacing(1),
    transform: "translateX(-50%) translateY(-50%)",
  },
  label: {
    width: "100%",
    fontWeight: "bold",
    alignItems: "left",
    fontSize: theme.typography.pxToRem(14),
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  subtitle: {
    width: "100%",
    fontWeight: 400,
    alignItems: "left",
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[400],
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  text: {
    fontSize: "0.875rem",
    fontWeight: "bold",
    margin: theme.spacing(1),
    color: "#607D8B",
  },
  wrapper: {
    margin: theme.spacing(2, 0, 0, 0),
    width: "100%",
    padding: theme.spacing(0.3),
  },
}));
