import { makeStyles, Theme } from "@material-ui/core/";

const useNoMovementsStyles = makeStyles<Theme>((theme) => ({
  propertyPaymentsNoMovementsRoot: {
    margin: theme.spacing(3, "auto"),
    borderTop: `2px solid ${theme.palette.grey[75]}`,
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(6.5, "auto"),
      padding: theme.spacing(0, 21.5),
      maxWidth: "80%",
    },
  },
  propertyPaymentsNoMovementsTitle: {
    margin: theme.spacing(3, 0, 2),
    fontWeight: 700,
    textAlign: "center",
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  propertyPaymentsNoMovementsText: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export default useNoMovementsStyles;
