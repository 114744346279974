import { useQuery, UseQueryResult } from "react-query";

import useContract from "apps/tenant/hooks/useContract";
import { useGetToken } from "hooks";
import paymentService from "apps/tenant/services/paymentService";

import { IMovement } from "../../models/Movement";
import useUser from "../useUser";
import { filterByConcept, parseContractMovements } from "./utils";

const useMovements = () => {
  const { getToken } = useGetToken();
  const { user, isLoading: userLoading, error: userError } = useUser();
  const { data: contractData, isLoading: contractLoading } = useContract();

  const {
    data: movementData,
    isLoading: movementLoading,
    isError: movementError,
  }: UseQueryResult<IMovement[], Error> = useQuery<IMovement[], Error>(
    ["subPayment", contractData?.subscriptionId, contractData?.country],
    async () => {
      const authToken = await getToken();
      const response = await paymentService.getContractMovements(
        contractData?.country,
        contractData?.subscriptionId,
        authToken
      );
      const filteredMovements = filterByConcept(response.data);

      return parseContractMovements(filteredMovements);
    },
    {
      enabled:
        !contractLoading &&
        !!contractData?.subscriptionId &&
        !!contractData?.country,
      retry: false,
    }
  );

  return {
    data: movementData,
    isLoading: userLoading || movementLoading || contractLoading,
    isError: userError || movementError || !user?.email,
  };
};

export default useMovements;
