import { notNumberToNull } from "utils/yupValidators";
import * as yup from "yup";

const requiredText = "Este campo es requerido";

const integerNumber = yup
  .number()
  .integer()
  .nullable()
  .transform(notNumberToNull)
  .typeError(requiredText)
  .required(requiredText);

const actualYear = new Date().getFullYear();

export const validationSchema = yup.object().shape({
  mSurface: yup
    .number()
    .nullable()
    .transform(notNumberToNull)
    .typeError(requiredText)
    .min(0, "El número de m2 de superficie debe ser mayor a 0"),
  mBuilt: yup
    .number()
    .nullable()
    .transform(notNumberToNull)
    .typeError(requiredText)
    .min(0, "El número de m2 construidos debe ser mayor a 0"),
  yearBuilt: yup
    .number()
    .integer()
    .nullable()
    .transform(notNumberToNull)
    .typeError(requiredText)
    .min(1492, "El año debe ser mayor a 1492")
    .max(actualYear, `El año debe ser ser menor a ${actualYear}`),
  bedrooms: integerNumber.min(1, "El número de dormitorios debe ser mayor a 0"),
  bathrooms: integerNumber.min(1, "El número de baños debe ser mayor a 0"),
  parkingQuantity: integerNumber,
  parkingNumbers: yup.string().when("parkingQuantity", {
    is: (value: number | null) => !!value && value > 0,
    then: yup.string().required(requiredText),
  }),
  warehouse: yup.string().required(requiredText),
  warehouseNumber: yup.string().when("warehouse", {
    is: "yes",
    then: yup.string().required(requiredText),
  }),
});
