import axios, { AxiosResponse } from "axios";

import { BankAccountModel } from "models/BankAccount";
import { Locale } from "models/Countries";

import { PAYMENTS_URL } from "../env";
import { createHttp, getToken } from "./helper";

export default {
  getBanksCl: async (token) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };

    const payments = axios.create({ baseURL: PAYMENTS_URL, headers });

    return payments.get("cl/v3/banks");
  },

  getBanksCo: async (token) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };

    const paymentsCo = axios.create({ baseURL: PAYMENTS_URL, headers });

    return paymentsCo.get("co/v3/banks");
  },

  getBanksMx: async (token) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };

    const paymentsMx = axios.create({ baseURL: PAYMENTS_URL, headers });

    return paymentsMx.get("mx/v3/banks");
  },

  getByUser: async (country, userId, token) =>
    createHttp(token, PAYMENTS_URL).get(
      `${country}/v3/people/${userId}/bank_accounts`
    ),

  getByUserAndProperty: async (country, userId, propertyId, token) =>
    createHttp(token, PAYMENTS_URL)
      .get(`${country}/v3/people/${userId}/bank_accounts/${propertyId}`)
      .catch((error) => {
        console.error(error);
        return error.response;
      }),

  getByUserAndPropertyV2: async (
    country: Locale,
    userId: number,
    propertyId: number,
    token: string
  ): Promise<AxiosResponse<BankAccountModel>> =>
    createHttp(token, PAYMENTS_URL).get(
      `${country}/v3/people/${userId}/bank_accounts/${propertyId}`
    ),

  create: async (country, userId, data, token) =>
    createHttp(token, PAYMENTS_URL)
      .post(`${country}/v3/people/${userId}/bank_accounts`, data)
      .catch((error) => {
        console.error(error);
        return error.response;
      }),

  update: async (country, userId, propertyId, data, token) =>
    createHttp(token, PAYMENTS_URL)
      .put(`${country}/v3/people/${userId}/bank_accounts/${propertyId}`, data)
      .catch((error) => {
        console.error(error);
        return error.response;
      }),
};
