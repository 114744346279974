import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

const useDecisionCallToActionStyles = makeStyles<Theme>((theme) => ({
  root: {
    position: "fixed",
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.drawer,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    minHeight: 63,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      zIndex: theme.zIndex.appBar,
      padding: theme.spacing(0, 2),
    },
  },
  ctaContent: {
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      alignItems: "center",
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  secondaryButton: {
    border: `1px solid ${theme.palette.grey[900]}`,
    width: "90%",
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginBottom: theme.spacing(0),
      width: "auto",
    },
  },
  button: {
    width: "90%",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      width: "auto",
      marginBottom: "auto",
    },
  },
  buttonBack: {
    border: `1px solid ${theme.palette.grey[900]}`,
    width: 40,
    height: 40,
  },
  icon: {
    fontSize: 20,
    color: theme.palette.grey[900],
  },
}));

export default useDecisionCallToActionStyles;
