export type SnackbarType = "success" | "warning" | "error";

export const getColor = (type: SnackbarType) => {
  switch (type) {
    case "error":
      return "#AE0400";
    case "warning":
      return "#E7C202";
    case "success":
    default:
      return "#48AA02";
  }
};
