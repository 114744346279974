export const SUCCESS_TITLE = "¡Lo lograste!";
export const SUCCESS_DESCRIPTION =
  "Registramos los datos de tu referido y lo contactaremos en máximo un día hábil.";

export const FAILED_TO_CHECK_PARTNER_PROFILE =
  "No se pudo verificar el perfil del usuario, por favor intente de nuevo";
export const FAILED_TO_CREATE_PARTNER_PROFILE =
  "No se pudo asignar un perfil de referidor a este usuario, por favor intente de nuevo";
export const FAILED_TO_SEND_REFER_LEAD =
  "No se pudo iniciar el proceso de referido, por favor intente de nuevo";
export const REFER_LEAD_ALREADY_EXISTS =
  "Ya existe un referido con este correo o telefono";
export const FAILED_TO_SEND_NEW_REFER_EVENT =
  "No se pudo completar el proceso de referido, por favor intente de nuevo";
export const REFER_EVENT_ALREADY_SENT =
  "Ya se ha enviado un evento de referido a ese correo o telefono";

export const USER_REFERRED = "USER_REFERRED";
export const ROLES_USER_DATA_KEY = "https://api.houm.com/roles";
export const LEAD_UTM_SOURCE = "plataforma_referido_adminProp";

export const USER_NOT_FOUND = 406;
export const DEFAULT_INITIAL_PHONE = "999999999";
export const DEFAULT_INITIAL_LAST_NAME = "Apellido";

export const INITIAL_UTM_DATA = {
  utm_source: null,
  utm_medium: null,
  utm_campaign: null,
  utm_content: null,
  utm_term: null,
  referred_url: null,
  gclick_id: null,
};
