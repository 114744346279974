import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IoArrowUpOutline } from "@houm-com/ui-icons";
import IconButton from "@houm-com/ui/IconButton";

import classes from "./PropertyRequestLinkStyles";

interface PropertyRequestLinkProps {
  propertyId: string;
}

const PropertyRequestLink = ({ propertyId }: PropertyRequestLinkProps) => {
  const history = useHistory();
  const { t } = useTranslation("landlord.property.basic.services");
  return (
    <div className={classes.container}>
      <span>{t("pending-payment-request")}</span>
      <IconButton
        onClick={() =>
          history.push(`/landlord/properties/${propertyId}/property-requests`)
        }
        className={classes.button}
        icon={<IoArrowUpOutline className={classes.icon} />}
        position="end"
        variant="tertiary"
        size="md"
      >
        {t("pending-payment-request-link")}
      </IconButton>
    </div>
  );
};

export default PropertyRequestLink;
