import { useMemo, useState } from "react";
import { useMutation } from "react-query";

import { useGetToken } from "hooks";

import { useHoumSnackBar } from "libs/houm-snackbar";
import tenantContractService from "../services/tenantContractService";
import { encodeContractEnd } from "../utils/parsers";
import { ILeavingForm } from "../utils/types";
import {
  CONTRACT_END_ERROR_SNACKBAR,
  VISITS_SCHEDULE_ERROR_SNACKBAR,
  getConfirmationMessage,
} from "./utils/utils";
import useContract from "./useContract";
import useContractEnd from "./useContractEnd";
import { IVisitsSchedules } from "../components/Forms/DayHoursPicker/utils";
import visitSchedulesHandler from "./utils/contractEndActions/utils";

interface IUseContractEndByTenant {
  onSuccessHandler: () => void;
  onErrorHandler: () => void;
}

const useContractEndByTenant = ({
  onSuccessHandler,
  onErrorHandler,
}: IUseContractEndByTenant) => {
  const { data: contractData, isLoading: isContractLoading } = useContract();
  const {
    contractEndData,
    refetchContractEnd,
    isLoading: isContractEndLoading,
  } = useContractEnd();
  const { getToken } = useGetToken();
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const [leavingData, setLeavingData] = useState<ILeavingForm>(
    contractEndData?.hasBegun ? { date: contractEndData.estimatedDate } : {}
  );
  const [visitsSchedules, setVisitsSchedules] = useState<IVisitsSchedules[]>(
    []
  );

  const visitsSchedulesMutation = useMutation(
    () => visitSchedulesHandler(visitsSchedules, getToken),
    {
      onError: () => {
        enqueueHoumSnackBar(VISITS_SCHEDULE_ERROR_SNACKBAR);
      },
    }
  );

  const contractEndMutation = useMutation(
    async (contractEndForm: ILeavingForm) => {
      const authToken = await getToken();
      const endContractResponse = await tenantContractService.endContract(
        authToken,
        contractData?.id,
        encodeContractEnd(
          contractEndForm,
          contractData?.initDate,
          contractData?.country
        )
      );
      if (endContractResponse.data.status === "IN_PROCESS") {
        const mutationResponse = await visitsSchedulesMutation.mutate();
        return mutationResponse;
      }
      return endContractResponse;
    },
    {
      onSuccess: () => {
        refetchContractEnd();
        onSuccessHandler();
      },
      onError: () => {
        enqueueHoumSnackBar(CONTRACT_END_ERROR_SNACKBAR);
        onErrorHandler();
      },
    }
  );

  const confirmationMessage = useMemo(
    () =>
      getConfirmationMessage(
        contractData?.initDate,
        leavingData.date,
        contractData?.country,
        contractData?.endNoticePeriod
      ),
    [leavingData.date]
  );

  return {
    contractEndAction: () => contractEndMutation.mutate(leavingData),
    setVisitsSchedules,
    leavingData,
    onFormSubit: (formData: ILeavingForm) => setLeavingData(formData),
    confirmationMessage,
    isLoading:
      isContractEndLoading ||
      isContractLoading ||
      contractEndMutation.isLoading,
  };
};

export default useContractEndByTenant;
