import { startCase } from "lodash";

import { Box, Typography } from "@material-ui/core";

import { IVisit } from "apps/tenant/models/Visit";

import VisitCard from "./components/VisitCard";
import useVisitsListStyles from "./VisitsListStyles";
import NoVisitsCard from "../NoVisitsCard";
import { addMonthHeaders } from "./utils";

interface Props {
  showMonthHeaders?: boolean;
  visits: IVisit[];
}

const VisitsList = ({ showMonthHeaders, visits }: Props) => {
  const classes = useVisitsListStyles();

  if (!visits?.length) return <NoVisitsCard />;

  const parsedVisits = !showMonthHeaders ? visits : addMonthHeaders(visits);

  return (
    <Box className={classes.visitsListRoot}>
      {parsedVisits.map((visit) =>
        visit.index === "monthHeader" ? (
          <Typography
            key={`monthHeader-${visit.month}`}
            className={classes.monthHeader}
          >
            {startCase(visit.month)}
          </Typography>
        ) : (
          <VisitCard key={visit.index} visit={visit} />
        )
      )}
    </Box>
  );
};

export default VisitsList;
