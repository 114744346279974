import { Box, Link, Typography } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";

import { Locale } from "models/Countries";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji/Emoji";

import { IRequestForm, IVisitHour } from "apps/tenant/hooks/utils/types";
import useRepairVisitStyles from "./RepairConfirmationStyles";
import VisitHourText from "./VisitHourText";
import KUSTOMER_HELP_LINKS from "../../constants";
import { getRepairTypeLabel } from "./utils";

interface Props {
  handleNext: () => void;
  requestForm: IRequestForm;
  visitHours: IVisitHour[];
  country: Locale;
}

const RepairConfirmation = ({
  handleNext,
  requestForm,
  visitHours,
  country,
}: Props) => {
  const classes = useRepairVisitStyles();
  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>
        Creaste tu solicitud con éxito
      </Typography>
      <Typography className={classes.description}>
        Muy pronto uno de nuestros ejecutivos se pondrá en contacto contigo para
        poder terminar de coordinar la visita de nuestro inspector.
      </Typography>
      <Typography className={classes.description}>
        Te dejamos aquí el resumen de tu solicitud{" "}
        <Emoji emojiIcon="👇" ariaLabel="mano apuntando hacia abajo" />
      </Typography>
      <Typography className={classes.stepTitle}>
        1. Tipo de reparación : {getRepairTypeLabel(requestForm.repairType)}
      </Typography>
      <Typography className={classes.stepDescription}>
        {requestForm.observation}
      </Typography>
      <Typography className={classes.stepTitle}>
        2. Rango de días y horas selecionados
      </Typography>
      <Typography className={classes.stepDescription}>
        Indicaste que te es posible recibir a nuestro inspector los días:
        {visitHours.map((visitHour, index) => {
          if (!visitHour.checked) return null;
          return <VisitHourText visitHour={visitHour} index={index} />;
        })}
      </Typography>
      <Box className={classes.ctaContainer}>
        <DesignSystemButton
          label="Salir"
          size="small"
          variant="primary"
          onClick={handleNext}
        />
      </Box>
      <Box className={classes.infoContainer}>
        <Typography className={classes.infoTitle}>
          Recuerda revisar las políticas de arreglos antes de la visita de
          nuestro técnico
        </Typography>
        <Typography className={classes.infoDescription}>
          Conoce los detalles de a quién corresponden cubrir los gastos de las
          reparaciones
        </Typography>
        <Link
          className={classes.infoLink}
          href={KUSTOMER_HELP_LINKS[country]}
          target="_blank"
        >
          Ver politicas
          <ArrowForward className={classes.infoLinkIcon} />
        </Link>
      </Box>
    </Box>
  );
};

export default RepairConfirmation;
