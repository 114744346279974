import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Box, Grid } from "@material-ui/core";

import useContract from "apps/tenant/hooks/useContract";
import useMovement from "apps/tenant/hooks/useMovement";
import { useNavbar } from "apps/ui/Navbar/context/navbarContext";
import Spinner from "Components/Shared/partials/Spinner";
import { useGetQueryParam } from "hooks/useQuery";

import Header from "./components/Header";
import PaymentBreakdown from "./components/PaymentBreakdown";
import usePaymentDetail from "./hooks/usePaymentDetail";
import useVoucherDetailStyles from "./VoucherDetailsStyles";

const VoucherDetails = () => {
  const classes = useVoucherDetailStyles();
  const { data: contract, isLoading: contractLoading } = useContract();
  const { setBackUrl } = useNavbar();
  const { id } = useParams<{ id: string }>();
  const { data: movement, isLoading: movementsLoading } = useMovement({
    movementId: id,
  });
  const { isLoading: detailsLoading } = usePaymentDetail({
    country: contract?.country,
    movementId: movement?.id,
  });
  const origin = useGetQueryParam("origin");

  useEffect(() => {
    const backUrl =
      origin === "dashboard" ? "/tenant" : "/tenant/vouchers-list";
    setBackUrl(backUrl);
  }, [setBackUrl, origin]);

  if (movementsLoading || contractLoading || detailsLoading)
    return (
      <Box className={classes.loadingContainer}>
        <Spinner />
      </Box>
    );

  return (
    <Box className={classes.voucherDetailsRoot}>
      <Header
        country={contract.country}
        date={movement?.date}
        origin={origin}
      />
      <Grid container className={classes.contentGrid}>
        <Grid item xs={12} sm={6}>
          <PaymentBreakdown movement={movement} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default VoucherDetails;
