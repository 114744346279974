import { makeStyles, Theme } from "@material-ui/core";

const useSimilarPropertiesStyles = makeStyles<Theme>((theme) => ({
  multipleCardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(2, 0, 0, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  houm: {
    marginTop: theme.spacing(2),
  },
}));

export default useSimilarPropertiesStyles;
