import {
  getAccountData,
  getPersonalData,
} from "apps/account/pages/Profile/utils/utils";

import ProfileHeader from "../../../ProfileHeader";
import MenuCard from "./components/MenuCard";
import useUser from "apps/account/hooks/useUser";
import { phoneParser } from "apps/account/utils/phoneParser";

const MobileLanding = () => {
  const { user } = useUser();

  const personalItems = getPersonalData(
    `${user?.name} ${user?.lastName} ${user?.secondLastName}`,
    phoneParser(user?.phone?.phone, user?.phone?.country?.countryCode)
  );
  const accountItems = getAccountData(user?.email);

  return (
    <>
      <ProfileHeader />
      <MenuCard title="Personal" items={personalItems} />
      <MenuCard title="Cuenta" items={accountItems} />
    </>
  );
};

export default MobileLanding;
