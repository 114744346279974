import { useQuery, UseQueryResult } from "react-query";
import { useGetToken } from "hooks";
import { useProperty } from "apps/landlord/hooks";
import {
  CompanyType,
  ICompany,
} from "apps/landlord/services/propertiesDuesService/type";
import propertiesDuesService from "apps/landlord/services/propertiesDuesService";
import { filterCompaniesByType, parseCompanies } from "./utils";

const useCompanies = (companyType?: CompanyType) => {
  const { getToken } = useGetToken();
  const { propertyData, propertyLoading, propertyError } = useProperty();
  const { country } = propertyData || {};
  const { isLoading, error, data }: UseQueryResult<ICompany[], Error> =
    useQuery<ICompany[], Error>(
      ["companiesByCountry", country, companyType],
      async () => {
        const authToken = await getToken();
        const response = await propertiesDuesService.getCompaniesByCountry(
          country,
          authToken,
          companyType && { company_type: companyType }
        );
        return parseCompanies(response.data);
      }
    );
  const waterCompaniesOptions = filterCompaniesByType(data, "water");
  const energyCompaniesOptions = filterCompaniesByType(data, "energy");
  const gasCompaniesOptions = filterCompaniesByType(data, "gas");
  const commonExpensesCompaniesOptions = filterCompaniesByType(
    data,
    "common_expenses"
  );

  return {
    isLoading: isLoading || propertyLoading,
    error: error || propertyError,
    data,
    waterCompaniesOptions,
    energyCompaniesOptions,
    gasCompaniesOptions,
    commonExpensesCompaniesOptions,
  };
};

export default useCompanies;
