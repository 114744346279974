import { makeStyles, Theme } from "@material-ui/core";
import {
  DESKTOP_HEADER_HEIGHT,
  DESKTOP_PADDING,
} from "apps/landlord/constants/layout";

const useDeskNavbarStyles = makeStyles<Theme>((theme: Theme) => ({
  navbarDesktopWrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: theme.zIndex.appBar,
    width: "100%",
    height: DESKTOP_HEADER_HEIGHT,
    backgroundColor: "white",
    boxShadow: "3px 4px 20px rgba(38, 50, 56, 0.15)",
    "& > a:first-child": {
      marginRight: theme.spacing(5),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  navbarContentDesktop: {
    position: "relative",
    zIndex: theme.zIndex.appBar,
    margin: theme.spacing(0, "auto"),
    maxWidth: 1280,
    height: "fit-content",
    padding: theme.spacing(0, 2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    backgroundColor: "white",
    [theme.breakpoints.up("sm")]: {
      height: "100%",
      padding: theme.spacing(0, DESKTOP_PADDING),
    },
  },
  leftContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: "1 0 auto",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      height: "100%",
      width: "auto",
      justifyContent: "flex-start",
    },
  },
  logo: {
    marginRight: theme.spacing(1),
    height: 32,
  },
  navbarMenu: {
    height: "100%",
    display: "inline-flex",
    alignItems: "center",
  },
  rightContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

export default useDeskNavbarStyles;
