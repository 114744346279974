import { Chip, Grid, Hidden, Paper, Typography } from "@material-ui/core";

import {
  IoHomeOutline as HomeIcon,
  IoPersonOutline as ProfileIcon,
  IoCalendarOutline as CalendarIcon,
} from "@houm-com/ui-icons";

import useStyles from "./AppointmentsListItemStyles";
import AppointmentsMenuItem, { IPropertyInfo } from "./AppointmentsMenuItem";
import { statusAppointments } from "./AppointmentsConstants";
import { countryCode } from "utils";
import LocationPinIcon from "assets/icons/LocationPinIcon";

export interface IAppointment {
  appraisersId: string;
  appraisersName: string;
  commune: string;
  index: number;
  city: string;
  country: string;
  fullUbication: string;
  propertyId: number;
  propertyPhotoTitle: string;
  propertyPhotoURL: string;
  propertyRegion: string;
  propertyRentalPrice: string;
  propertySalePrice: string;
  propertyUID: string;
  scheduleId: number;
  sharedCost: string;
  visitAddress: string;
  visitDateTime: string;
  visitStatus: string;
  propertyType: string;
  isRental: boolean;
  isSale: boolean;
  propertyTitle: string;
}

export interface IResponse {
  schedule_id: number;
  visit_state: string;
  appraisers_name: string;
  appraisers_id: string;
  visit_date_time: string;
  property_id: number;
  property_uid: string;
  property_region: string;
  property_city: string;
  property_country: string;
  property_sale_price: number;
  property_rental_price: number;
  visit_address: string;
  shared_cost: number;
  commune: string;
  property_photos_filename: string;
  property_type: string;
  property_for_rental: boolean;
  property_for_sale: boolean;
  property_photos_url: string;
  index: number;
}

interface Props {
  appointment: IAppointment;
}

const AppointmentsListItem = ({ appointment }: Props) => {
  const statusData = statusAppointments.find(
    (status) => status.code === appointment.visitStatus
  );
  const classes = useStyles({
    backgroundChip: statusData.color,
    letterChipColor: statusData.letterColor,
  });

  const valueTitle = appointment.isSale
    ? "Valor de venta:"
    : " Valor arriendo:";
  const propertyInfo: IPropertyInfo = {
    countryCode: countryCode(appointment.country),
    propertyType: appointment.propertyType,
    appointmentType: appointment.isSale ? "venta" : "arriendo",
    region: appointment.propertyRegion,
    commune: appointment.commune,
    propertyId: appointment.propertyId,
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={12} md={11}>
        <Paper variant="outlined" className={classes.paper}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            <Grid item xs={12} md={4} className={classes.leftContainer}>
              <div className={classes.imgContainer}>
                <img
                  src={appointment.propertyPhotoURL}
                  alt="property"
                  className={classes.img}
                />
              </div>
              <div className={classes.chipsContainer}>
                <Chip
                  size="small"
                  className={classes.chipID}
                  label={`ID ${appointment.propertyId}`}
                />
                <Chip
                  size="small"
                  label={statusData.label}
                  classes={{
                    root: classes.chip,
                    label: classes.chipText,
                  }}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              className={classes.infoContainer}
            >
              <Grid item xs={12} md={8}>
                <Typography className={classes.title}>
                  {appointment.propertyTitle}
                </Typography>
                <Typography className={classes.subtitle}>
                  {`${appointment.fullUbication.replace("null, ", "")}`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.bodyCard}
              >
                <HomeIcon size={18} className={classes.icon} />
                <Typography className={classes.text}>{valueTitle}</Typography>
                <Typography className={classes.textLight}>
                  {appointment.isSale
                    ? appointment.propertySalePrice
                    : appointment.propertyRentalPrice}
                </Typography>
              </Grid>

              {appointment.isRental && (
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className={classes.subtitleContainer}
                >
                  <Typography className={classes.subtitle}>
                    Gastos comunes:
                  </Typography>
                  <Typography className={classes.subtitleLight}>
                    {appointment.sharedCost}
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <CalendarIcon size={18} className={classes.icon} />
                <Typography className={classes.text}>
                  Visita presencial:
                </Typography>
                <Typography className={classes.textLight}>
                  {appointment.visitDateTime}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <ProfileIcon size={18} className={classes.icon} />
                <Typography className={classes.text}>Houmer:</Typography>{" "}
                <Typography className={classes.textLight}>
                  {appointment.appraisersName}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <LocationPinIcon className={classes.pinIcon} />
                <Typography className={classes.text}>Dirección:</Typography>
                <Typography className={classes.textLight}>
                  {appointment.visitAddress}
                </Typography>
              </Grid>
              <Hidden smUp>
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <AppointmentsMenuItem propertyInfo={propertyInfo} />
                </Grid>
              </Hidden>
            </Grid>
            <Hidden smDown>
              <Grid item className={classes.menuOption}>
                <AppointmentsMenuItem propertyInfo={propertyInfo} />
              </Grid>
            </Hidden>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AppointmentsListItem;
