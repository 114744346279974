import { makeStyles, Theme } from "@material-ui/core";

const useNoDataTableCardStyles = makeStyles((theme: Theme) => ({
  title: {
    margin: theme.spacing(1, 0, 2, 0),
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 700,
  },
  subtitle: {
    margin: theme.spacing(1, 2, 2, 2),
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 2,
  },
  messageContainer: {
    display: "block",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContainer: {
    padding: theme.spacing(10, 0, 10, 0),
    boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.15)",
    borderRadius: 10,
  },
  container: {
    display: "block",
    textAlign: "center",
  },
  containerImage: {
    marginTop: theme.spacing(5),
    display: "block",
    textAlign: "center",
  },
}));

export default useNoDataTableCardStyles;
