import { useState } from "react";

import { Box } from "@material-ui/core";

import { Locale } from "models/Countries";
import { SnackbarProvider } from "notistack";
import PropertyDetailsSelector from "./components/PropertyDetailsSelector";
import {
  PropertyDetailsOptions,
  detailsPageOptionsCL,
  detailsPageOptions,
} from "./constants";
import BasicServicesForm from "./components/BasicServicesForm";
import DetailsInformation from "./components/DetailsInformation";
import CommonExpensesForm from "./components/CommonExpensesForm";

interface Props {
  locale: Locale;
}

export default function PropertyDetailsContent({ locale }: Props) {
  const [selected, setSelected] = useState(
    PropertyDetailsOptions.BASIC_SERVICES
  );
  return (
    <SnackbarProvider>
      <Box>
        <PropertyDetailsSelector
          selected={selected}
          setSelected={setSelected}
          options={locale === "cl" ? detailsPageOptionsCL : detailsPageOptions}
          gridSize={locale === "cl" ? 4 : 6}
        />
        {selected === PropertyDetailsOptions.BASIC_SERVICES && (
          <BasicServicesForm locale={locale} />
        )}
        {selected === PropertyDetailsOptions.INFORMATION && (
          <DetailsInformation />
        )}
        {selected === PropertyDetailsOptions.COMMON_EXPENSES && (
          <CommonExpensesForm />
        )}
      </Box>
    </SnackbarProvider>
  );
}
