import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";

import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import FilesUploadForm from "apps/lead/components/EvaluatorForm/FilesForm";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import { useAlertMessage } from "apps/lead/hooks/useAlertMessage";
import useStorageFiles from "apps/lead/hooks/useStorageFiles";
import AlertSnackbar from "apps/lead/components/AlertSnackbar";

import useUploadFiles from "../../hooks/useUploadFiles";

const UploadFiles = () => {
  const history = useHistory();
  const {
    codebtor,
    activeStep,
    steps,
    requirements,
    loading,
    methods,
    workerType,
    files,
    onSubmit,
    role,
    isSuccess,
  } = useUploadFiles();
  const { handleBackClick, handleChange, uploadFile, removeFile } =
    useStorageFiles();
  const { responseMessage, handleCloseAlert, handleOpenAlert } =
    useAlertMessage(() => history.push("/tenant"));

  useEffect(() => {
    if (isSuccess) {
      handleOpenAlert(
        true,
        "success",
        "Los archivos se han subido correctamente"
      );
    }
  }, [isSuccess]);

  return (
    <>
      <FormProvider {...methods}>
        <Header
          title={`${codebtor.name} ${codebtor.lastName} | ${role}`}
          subtitle="Adjunta los siguientes documentos señalados"
          activeStep={activeStep}
          steps={steps}
        />
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FilesUploadForm
            requirements={requirements}
            files={files}
            uploadFile={uploadFile}
            removeFile={removeFile}
            workerType={workerType}
            handleChange={handleChange}
          />
          <CallToAction
            label="Adjuntar"
            onHandleBackClick={handleBackClick}
            isLoading={loading}
          />
        </form>
      </FormProvider>
      <AlertSnackbar
        color={responseMessage?.type === "success" ? "#48AA02" : "#EA1601"}
        reason={responseMessage?.message}
        handleClose={handleCloseAlert}
        open={responseMessage.open}
      />
    </>
  );
};

export default UploadFiles;
