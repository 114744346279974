import { v4 as uuidv4 } from "uuid";

import { Locale } from "models/Countries";

import getCLConfirmationMessage from "./confirmationMessages/getCLConfirmationMessage";
import getCOConfirmationMessage from "./confirmationMessages/getCOConfirmationMessage";
import getMXConfirmationMessage from "./confirmationMessages/getMXConfirmationMessage";

import { uploadToS3 } from "utils/fileStreaming";
import tenantRepairsService from "apps/tenant/services/tenantRepairsService";
import { SnackbarPayload } from "libs/houm-snackbar/types";

export const getConfirmationMessage = (
  contractDate: Date,
  leavingDate: Date,
  countryCode: Locale,
  endNoticePeriod
) => {
  switch (countryCode) {
    case "co":
      return getCOConfirmationMessage(leavingDate);
    case "mx":
      return getMXConfirmationMessage(contractDate, leavingDate);
    case "cl":
      return getCLConfirmationMessage(
        contractDate,
        leavingDate,
        endNoticePeriod
      );
    default:
      return getCLConfirmationMessage(
        contractDate,
        leavingDate,
        endNoticePeriod
      );
  }
};

export const getPhotoFileName = (name: string) => {
  const fileExtension = name.split(".").pop();
  const uuid = uuidv4();
  const fileName = `repairment_requests_${uuid}.${fileExtension}`;
  return fileName;
};

export const uploadRepairPhotos = async (
  token: string,
  photos: File[]
): Promise<string[]> => {
  try {
    const fileNames = photos.map((file) => getPhotoFileName(file.name));
    const presignedUrls = await tenantRepairsService.getPresignedUrl(token, {
      file_names: fileNames,
    });
    Object.entries(presignedUrls.data).forEach(
      async ([fileName, presignedUrl], index) => {
        await uploadToS3({
          fileContents: photos[index],
          presignedUrl: { ...presignedUrl, filePath: fileName },
        });
      }
    );
    return fileNames;
  } catch (error) {
    return null;
  }
};

export const CONTRACT_END_ERROR_SNACKBAR: SnackbarPayload = {
  head: "¡On no! Tuvimos un problema",
  body: "No pudimos solicitar tu salida. Por favor, vuélvelo a intentar 👌.",
  type: "error",
};

export const VISITS_SCHEDULE_ERROR_SNACKBAR: SnackbarPayload = {
  head: "¡On no! Tuvimos un problema",
  body: "No pudimos guardar los horarios de visita. Por favor, vuélvelo a intentar 👌.",
  type: "error",
};
