import { Box, Grid, Typography } from "@material-ui/core";

import useContract from "apps/tenant/hooks/useContract";
import Spinner from "Components/Shared/partials/Spinner";
import useInspectionReports from "hooks/inspectionReports/useInspectionReports";

import { dashboardOptions } from "./utils/constants";
import useMoreOptionsStyles from "./MoreOptionsStyles";
import OptionCard from "./components/OptionCard";

const MoreOptions = () => {
  const classes = useMoreOptionsStyles();
  const { data: contract, isLoading: contractLoading } = useContract();
  const { inspectionReportList, isLoading: reportsLoading } =
    useInspectionReports(contract?.propertyId);
  const showReports = inspectionReportList?.length !== 0;

  if (reportsLoading || contractLoading) return <Spinner />;

  return (
    <Box className={classes.moreOptionsRoot}>
      <Typography className={classes.title}>Más opciones</Typography>
      <Grid container className={classes.grid}>
        {dashboardOptions({
          propertyId: contract?.propertyId,
          showReports,
        }).map(
          (option) =>
            option.show && (
              <Grid item xs={6} key={option.label}>
                <OptionCard {...option} />
              </Grid>
            )
        )}
      </Grid>
    </Box>
  );
};

export default MoreOptions;
