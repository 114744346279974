import { isMobile } from "react-device-detect";
import NavbarDesktop from "./components/NavbarDesktop";
import NavbarMobile from "./components/NavbarMobile";

const Navbar = () => {
  if (isMobile) return <NavbarMobile />;
  return <NavbarDesktop />;
};

export default Navbar;
