import { DrawerProvider } from "apps/landlord/hooks/useCustomDrawer/context";
import { RentPaymentProvider } from "apps/payments/context/rentPaymentContext/rentPaymentContext";

import RentPayment from "./RentPayment";

const index = () => (
  <DrawerProvider isOpen={false}>
    <RentPaymentProvider>
      <RentPayment />
    </RentPaymentProvider>
  </DrawerProvider>
);

export default index;
