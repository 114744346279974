import { Locale } from "models/Countries";
import {
  Action,
  PaymentDataProps,
  Steps,
  SET_COUNTRY,
  SET_STEP,
  SET_PAYMENT_DATA,
  TenantData,
  SET_TENANT_DATA,
  MethodSelected,
  SET_METHOD_SELECTED,
} from "./rentPaymentTypes";

export const setCountry = (country: Locale): Action => ({
  type: SET_COUNTRY,
  country,
});

export const setPaymentData = (
  paymentData: Partial<PaymentDataProps>
): Action => ({
  type: SET_PAYMENT_DATA,
  paymentData,
});

export const setStep = (step: Steps): Action => ({
  type: SET_STEP,
  step,
});

export const setTenantData = (tenantData: TenantData): Action => ({
  type: SET_TENANT_DATA,
  tenantData,
});

export const setMethodSelected = (methodSelected: MethodSelected): Action => ({
  type: SET_METHOD_SELECTED,
  methodSelected,
});
