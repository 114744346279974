import { useFeatureManager } from "@houm-com/feature-manager";
import { useHoumSnackBar } from "libs/houm-snackbar";
import { useQuery, UseQueryResult } from "react-query";

import newPaymentService from "services/newPaymentService";
import { IMethod } from "apps/payments/models/Payments";

const useOpenPayInfo = (movementId: string) => {
  const {
    status,
    data: dataOnlyBankTransfers,
    isLoading: isLoadingFeatureManager,
  } = useFeatureManager("open_pay_only_bank_transfers");
  const isBankTransferFlag =
    status === "success" && dataOnlyBankTransfers?.enabled;
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const {
    isLoading,
    isError,
    data = [],
  }: UseQueryResult<IMethod[], Error> = useQuery(
    ["open_pay_values", movementId, isBankTransferFlag],
    async () => {
      const response = await newPaymentService.getOpenpayInfo("mx", {
        movement_id: movementId,
      });
      const parsedData = response.data.map((payUMethod) => ({
        method: payUMethod.method,
        subtotal: payUMethod.subtotal,
        methodCommission: payUMethod.method_fee,
        total: payUMethod.total,
      }));
      return isBankTransferFlag
        ? parsedData.filter((data) => data.method === "bank")
        : parsedData;
    },
    {
      enabled: !!movementId && status === "success",
      onError: () => {
        enqueueHoumSnackBar({
          head: "",
          body: "Error al obtener link de pago. Vuelva a intentar!",
          type: "error",
        });
      },
    }
  );

  return {
    isLoading: isLoadingFeatureManager || isLoading,
    isError,
    data,
  };
};

export default useOpenPayInfo;
