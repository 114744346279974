import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { alert } from "@houm-com/ui/Alert";

import propertyService from "apps/landlord/services/propertyService";
import { IProperty } from "apps/landlord/models/Property";
import { PropertyDetails } from "models/Property";
import { useGetToken } from "hooks";

import { parsePricePayload, parseSalesPricePayload } from "./utils";
import { BusinessType, PriceFormData } from "./types";

interface UseSetPriceProps {
  propertyData: IProperty;
  businessType: BusinessType;
  propertyDetails: PropertyDetails;
}

const useSetPrice = ({
  propertyData,
  propertyDetails,
  businessType,
}: UseSetPriceProps) => {
  const { getToken } = useGetToken();
  const { t } = useTranslation(["SET_PRICE"]);
  const queryClient = useQueryClient();
  const history = useHistory();

  const SUCCESS_MESSAGE_BY_BUSINESS_TYPE = {
    SALE: t("SET_PRICE_SALE_SUCCESS_MESSAGE"),
    RENT: t("SET_PRICE_RENT_SUCCESS_MESSAGE"),
  };

  const ERROR_MESSAGE_BY_BUSINESS_TYPE = {
    SALE: t("SET_PRICE_SALE_ERROR_MESSAGE"),
    RENT: t("SET_PRICE_RENT_ERROR_MESSAGE"),
  };

  const handleSuccess = () => {
    alert.success({
      message: "Precio guardado exitosamente",
      disclaimer: SUCCESS_MESSAGE_BY_BUSINESS_TYPE[businessType],
    });
    queryClient.invalidateQueries("property_by_id_rev_stats");
    queryClient.invalidateQueries("property");
    queryClient.invalidateQueries(`SETTING_UP_STATUS_${propertyData.id}`);
    history.push(`/landlord/properties/${propertyData.uid}`);
  };

  const handleError = () =>
    alert.error({
      message: "¡Error!",
      disclaimer: ERROR_MESSAGE_BY_BUSINESS_TYPE[businessType],
    });

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    async (formData: PriceFormData) => {
      const authToken = await getToken();
      const parsedPayload = parsePricePayload({
        formData,
        propertyData,
        propertyDetails,
        businessType,
      });
      // Porque no se envía "includesCommonExpenses"
      const response = await propertyService.updateDetails(
        propertyDetails.id,
        parsedPayload,
        authToken
      );
      if (businessType === "SALE") {
        return propertyService.updateSalesDetails(
          propertyData.id,
          parseSalesPricePayload(formData),
          authToken
        );
      }
      return response;
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useSetPrice;
