import { Box, Collapse, Typography } from "@material-ui/core";

import { IMovement } from "apps/tenant/models/Movement";
import { Locale } from "models/Countries";

import { renderBreakdown } from "./utils/utils";
import useBreakdownStyles from "./BreakdownStyles";
import PaymentSection from "./components/PaymentSection";

interface Props extends IMovement {
  country: Locale;
  showBreakdown: boolean;
}

const Breakdown = ({
  additionalBreakdown,
  country,
  currency,
  discountsBreakdown,
  showBreakdown,
  status,
  totalAmount,
}: Props) => {
  const classes = useBreakdownStyles();

  return (
    <Box className={classes.breakdownRoot}>
      <Collapse in={showBreakdown} className={classes.collapse}>
        {renderBreakdown(additionalBreakdown, "+")}
        {renderBreakdown(discountsBreakdown, "-")}
      </Collapse>

      <PaymentSection
        country={country}
        currencyCode={currency}
        status={status}
        totalAmount={totalAmount}
      />

      <Typography className={classes.disclaimer}>
        Tu pago puede verse reflejado en un rango de 24 a 48 horas hábiles. No
        es necesario que nos envíes el comprobante.
      </Typography>
    </Box>
  );
};

export default Breakdown;
