import { makeStyles, Theme } from "@material-ui/core";

const useAuthenticateUserStyles = makeStyles<Theme>((theme) => ({
  loadingContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useAuthenticateUserStyles;
