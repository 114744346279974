import { useHistory } from "react-router-dom";

import { Box, Typography } from "@material-ui/core";
import {
  IoCardOutline as CardIcon,
  IoChevronForward as ChevronRightIcon,
} from "@houm-com/ui-icons";

import useBankAccountStyles from "./BankAccountStyles";
import CardInfo from "../../../CardInfo";

const BankAccounts = () => {
  const history = useHistory();
  const classes = useBankAccountStyles();

  return (
    <CardInfo
      cardClassName={classes.card}
      onClick={() => history.push("/landlord/bank_accounts")}
    >
      <Box className={classes.container}>
        <Box className={classes.iconContainer}>
          <CardIcon color="#46788C" size={21} />
        </Box>
        <Box className={classes.textContainer}>
          <Typography className={classes.text}>Cuentas bancarias</Typography>
          <ChevronRightIcon color="#46788C" />
        </Box>
      </Box>
    </CardInfo>
  );
};

export default BankAccounts;
