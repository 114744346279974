import { AxiosResponse } from "axios";

import { BACKEND_URL } from "env";
import { createNoAuthHttp } from "services/helper";
import {
  AcceptContractTermsParams,
  GetContractTermsParams,
  GetContractTermsResponse,
} from "./types";

export default {
  acceptContractTerms: ({
    token,
    body,
  }: AcceptContractTermsParams): Promise<AxiosResponse> =>
    createNoAuthHttp(BACKEND_URL).post(
      `/sub_lease_contracts/change_acceptance/accept?token=${token}`,
      body
    ),
  getContractTerms: ({
    token,
  }: GetContractTermsParams): Promise<
    AxiosResponse<GetContractTermsResponse>
  > =>
    createNoAuthHttp(BACKEND_URL).get(
      `/sub_lease_contracts/change_acceptance?token=${token}`
    ),
};
