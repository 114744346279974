import classNames from "classnames";

import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import { useLandlordProperty } from "context/landlordPropertyContext/landlordPropertyContext";
import useScheduledMetricsStyles from "./ScheduledMetricsStyles";

const ScheduledMetrics = () => {
  const { state } = useLandlordProperty();
  const { statistics } = state;
  const classes = useScheduledMetricsStyles();

  return (
    <Grid container item xs={12} className={classes.componentContainer}>
      <h1 className={classes.titleGeneral}>Agenda</h1>
      <Grid container item>
        <Grid container item alignContent="flex-start" xs={12}>
          <div>
            <Typography className={classes.subtitle}>
              Te compartimos las métricas de tu propiedad,
              <b> estas son de los últimos 15 días</b>
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.gridContainer}
        spacing={2}
      >
        <Grid
          item
          container
          className={classes.detailsContainer}
          xs={6}
          md={3}
          justifyContent="center"
          alignContent="center"
        >
          <div>
            <p>{statistics.pageviews}</p>
            <span>Visualizaciones</span>
          </div>
        </Grid>
        <Grid
          item
          container
          className={classNames(
            classes.detailsContainer,
            classes.detailsContainerBorderLeft
          )}
          xs={6}
          md={3}
          justifyContent="center"
          alignContent="center"
        >
          <div>
            <p>{statistics.cancelledVisits}</p>
            <span>Canceladas</span>
          </div>
        </Grid>
        <Grid
          item
          container
          className={classNames(
            classes.detailsContainer,
            classes.detailsContainerBorderLeft,
            classes.detailsContainerBorderRight
          )}
          xs={6}
          md={3}
          justifyContent="center"
          alignContent="center"
        >
          <div>
            <p>{statistics.allVisits}</p>
            <span>Agendadas</span>
          </div>
        </Grid>
        <Grid
          item
          container
          className={classes.detailsContainer}
          xs={6}
          md={3}
          justifyContent="center"
          alignContent="center"
        >
          <div>
            <p>{statistics.doneVisits}</p>
            <span>Finalizadas</span>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScheduledMetrics;
