import { makeStyles, Theme } from "@material-ui/core";

const useDrawerStyles = makeStyles<Theme>((theme) => ({
  drawer: {
    width: "100vw",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      maxWidth: 480,
    },
  },
  drawerRoot: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "rgba(38, 50, 56, 0.4)",
  },
}));

export default useDrawerStyles;
