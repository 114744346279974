import { makeStyles, Theme } from "@material-ui/core";

import { VisitStatus } from "apps/tenant/models/Visit";

interface Props {
  status: VisitStatus;
}

const useStatusBarStyles = makeStyles<Theme, Props>((theme) => ({
  statusBarRoot: {
    margin: theme.spacing(0.5, 0.25),
    marginLeft: 0,
    width: 0,
    borderRadius: 2,
    border: ({ status }) =>
      `2px solid ${status === "ok" ? "#8EC568" : "#DF9B99"}`,
  },
}));

export default useStatusBarStyles;
