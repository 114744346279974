import React, { useState } from "react";

import { IoEllipsisVertical, IoEllipsisHorizontal } from "@houm-com/ui-icons";
import { Hidden, IconButton, Menu, MenuItem } from "@material-ui/core";

import useStyles from "./AppointmentsMenuItemStyles";
import { optionsMenuCard } from "./AppointmentsConstants";
import { Locale } from "models/Countries";

export interface IPropertyInfo {
  countryCode: Locale;
  propertyType: string;
  appointmentType: string;
  region: string;
  commune: string;
  propertyId: number;
}

interface Props {
  propertyInfo: IPropertyInfo;
}

const AppointmentsMenuItem = ({ propertyInfo }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="small"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Hidden smDown>
          <IoEllipsisVertical color="#263238" size={23} />
        </Hidden>
        <Hidden mdUp>
          <IoEllipsisHorizontal
            color="#263238"
            size={23}
            className={classes.icon}
          />
        </Hidden>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {optionsMenuCard.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              option.action(propertyInfo);
              handleClose();
            }}
            classes={{ root: classes.rootMenuItem }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AppointmentsMenuItem;
