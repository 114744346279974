import { format as formatTz, utcToZonedTime } from "date-fns-tz";

import Chip from "@material-ui/core/Chip";

import { getTimezone } from "utils";
import useStyles from "./ScheduleSingleDateStyles";

import { useLandlordProperty } from "context/landlordPropertyContext/landlordPropertyContext";

interface Props {
  schedule: {
    id: number;
    begin_date: string;
    status: string;
    appraiser: {
      name: string;
      phone: string;
    };
  };
}

const ScheduleSingleDate = ({ schedule }: Props) => {
  const classes = useStyles();
  const { state } = useLandlordProperty();
  const { propertyData } = state;

  const statuses = () => ({
    Scheduled: {
      id: 1,
      tag: (
        <Chip label="Agendada" size="small" className={classes.chipScheduled} />
      ),
    },
    Confirmed: {
      id: 2,
      tag: (
        <Chip
          label="Confirmada"
          size="small"
          className={classes.chipConfirmed}
        />
      ),
    },
    Cancelled: {
      id: 3,
      tag: (
        <Chip
          label="Cancelada"
          size="small"
          className={classes.chipCancelled}
        />
      ),
    },
    Done: {
      id: 4,
      tag: <Chip label="Visitada" size="small" className={classes.chipDone} />,
    },
  });

  return (
    <div className={classes.container}>
      <span className={classes.hour}>
        {formatTz(
          utcToZonedTime(
            schedule.begin_date,
            getTimezone(propertyData.country, propertyData.commune)
          ),
          "HH:mm"
        )}
      </span>
      <hr className={classes.breakLine} />
      {statuses()[schedule.status].tag}
      <p className={classes.houmerName}>{schedule.appraiser?.name}</p>
      <p className={classes.houmerDetails}>
        <span>Houmer</span>
      </p>
    </div>
  );
};

export default ScheduleSingleDate;
