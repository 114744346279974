import { useHistory } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { accountTypeMapping } from "apps/landlord/utils/accountTypes";
import { ILandlordBankAccount } from "apps/landlord/models/Movement";

import useBankAccountInfoStyles from "./BankAccountInfoStyles";
import Card from "../Card";

interface Props {
  bankAccountInfo: Partial<ILandlordBankAccount>;
}

const BankAccountInfo = ({ bankAccountInfo }: Props) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useBankAccountInfoStyles();

  return (
    <Grid item xs={12} sm={6}>
      <Card title="Donde recibiste el pago">
        <Box className={classes.container}>
          {isMobile ? (
            <Box>
              <Typography className={classes.value} component="span">
                {bankAccountInfo.fullName}
              </Typography>
              <Box className={classes.mobileContainerInfo}>
                <Typography className={classes.valueMobile} component="span">
                  {bankAccountInfo.bankName}
                </Typography>
                <Typography className={classes.label}>
                  <Typography className={classes.valueMobile} component="span">
                    {accountTypeMapping[bankAccountInfo.accountType]}
                  </Typography>
                  <Typography className={classes.valueMobile} component="span">
                    <b>{` *${bankAccountInfo?.accountNumber?.slice(-4)}`}</b>
                  </Typography>
                </Typography>
              </Box>
            </Box>
          ) : (
            <>
              <Typography className={classes.label}>
                Nombre :{" "}
                <Typography className={classes.value} component="span">
                  {bankAccountInfo.fullName}
                </Typography>
              </Typography>
              <Typography className={classes.label}>
                Banco :{" "}
                <Typography className={classes.value} component="span">
                  {bankAccountInfo.bankName}
                </Typography>
              </Typography>
              <Typography className={classes.label}>
                Tipo de cuenta :{" "}
                <Typography className={classes.value} component="span">
                  {accountTypeMapping[bankAccountInfo.accountType]}
                </Typography>
                <Typography
                  className={classes.valueBankNumber}
                  component="span"
                >
                  <b>{`*${bankAccountInfo?.accountNumber?.slice(-4)}`}</b>
                </Typography>
              </Typography>
            </>
          )}
        </Box>
        <Box
          className={classes.linkArrow}
          onClick={() => history.push("/landlord/bank_accounts")}
        >
          <Typography className={classes.linkText}>
            Editar información
          </Typography>
          <ArrowForwardIcon className={classes.arrow} />
        </Box>
      </Card>
    </Grid>
  );
};

export default BankAccountInfo;
