import { Subscription } from "models/Subscription";
import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";
import subscriptionService from "services/subscriptionService";

const useSubscription = (propertyId: number) => {
  const { getToken } = useGetToken();
  const {
    isLoading: subscriptionLoading,
    error: subscriptionError,
    data: subscriptionData,
  }: UseQueryResult<Subscription, Error> = useQuery<Subscription, Error>(
    ["subscription", propertyId],
    async () => {
      const authToken = await getToken();
      const response = await subscriptionService.getByPropertyId(
        propertyId,
        authToken
      );
      return response.data;
    },
    { enabled: !!propertyId }
  );

  return {
    subscriptionLoading,
    subscriptionError,
    subscriptionData,
  };
};

export default useSubscription;
