import { format } from "date-fns";

import { IBillDetails } from "apps/landlord/services/propertiesDuesService/type";
import statuses from "../../hooks/useBillStatus/utils";
import classes from "./CardSubtitleStyles";

type CardSubtitleProps = {
  billStatus: string;
  detail: Partial<IBillDetails>;
};

const CardSubtitle = ({ billStatus, detail }: CardSubtitleProps) => {
  const getSubtitle = () => {
    if (
      detail.updatedAt &&
      ![
        statuses.validating_code,
        statuses.in_process,
        statuses.pending,
      ].includes(billStatus)
    ) {
      return `Actualizado: ${format(detail.updatedAt, "dd/MM/yyyy hh:mm")}`;
    }
    switch (billStatus) {
      case statuses.validating_code:
      case statuses.in_process:
      case statuses.pending:
        return "";
      case statuses.failure:
        return "Actualizado: -";
      case statuses.invalid_code:
      case statuses.no_data:
        return "Información faltante";
      default:
        return "Actualizado: Sin actualización";
    }
  };
  return <p className={classes.subtitle}>{getSubtitle()}</p>;
};

export default CardSubtitle;
