import { CountryUpperCase } from "models/Countries";
import { OldCurrencyType } from "../../models/Currency";
import { PhoneInput, Country } from "models/PhoneInput";

export const SET_RELATED_APPLICANT = "SET_RELATED_APPLICANT";
export const SET_CODEBTOR_ANSWER = "SET_CODEBTOR_ANSWER";
export const SET_SECOND_CODEBTOR = "SET_SECOND_CODEBTOR";
export const SET_FIRST_CODEBTOR = "SET_FIRST_CODEBTOR";
export const SET_MAIN_APPLICANT = "SET_MAIN_APPLICANT";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_FILES_MAIN = "SET_FILES_MAIN";
export const SET_EXECUTIVE = "SET_EXECUTIVE";
export const SET_PROPERTY = "SET_PROPERTY";
export const SET_LOADING = "SET_LOADING";
export const SET_STEPS = "SET_STEPS";

export interface Files {
  lastModified: number;
  lastModifiedDate?: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath?: string;
}

export type WorkerType = "dependent" | "independent" | "pensioner";

export interface CodebtorAnswer {
  firstCodebtorAnswer: boolean;
  secondCodebtorAnswer: boolean;
}

export interface Property {
  photo: string;
  promotionalPrice: number;
  price: number;
  currency: OldCurrencyType;
  uid: string;
  id: number;
  address: string;
  comuna: string;
  region: string;
  type: string;
  numero_depto: string;
  street_number: string;
  city: string;
  country: CountryUpperCase;
  photo_url: string | null;
}

export interface Applicant {
  country: string;
  email: string;
  document: string;
  documentType: number;
  lastName: string;
  name: string;
  phone: string | PhoneInput;
  secondLastName: string;
  workerType: WorkerType;
  files: {
    identityCard: Files[];
    contract?: Files[];
    salarySettlements?: Files[];
    quotes?: Files[];
    f22?: Files[];
    pensionSettlements?: Files[];
  };
  occupation?: string;
  codebtorAnswer: CodebtorAnswer;
}

export type ApplicantType =
  | "mainApplicant"
  | "firstCodebtor"
  | "secondCodebtor";

export const defaultApplicantValue = {
  country: "",
  email: "",
  document: "",
  documentType: null,
  lastName: "",
  name: "",
  phone: "",
  secondLastName: "",
  workerType: "dependent" as WorkerType,
  files: {
    identityCard: [],
  },
};

export interface State {
  loading: boolean;
  mainApplicant: Partial<Applicant>;
  firstCodebtor?: Partial<Applicant>;
  secondCodebtor?: Partial<Applicant>;
  activeStep: number;
  steps: number;
  codebtorAnswer: CodebtorAnswer;
  property: Partial<Property>;
  executive: string;
  relatedApplicantId: string;
}

interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}

interface setProperty {
  type: typeof SET_PROPERTY;
  property: Partial<Property>;
}

interface setMainApplicant {
  type: typeof SET_MAIN_APPLICANT;
  mainApplicant: Partial<Applicant>;
}

interface setFirstCodebtor {
  type: typeof SET_FIRST_CODEBTOR;
  firstCodebtor: Partial<Applicant>;
}

interface setSecondCodebtor {
  type: typeof SET_SECOND_CODEBTOR;
  secondCodebtor: Partial<Applicant>;
}

interface setActiveStep {
  type: typeof SET_ACTIVE_STEP;
  activeStep: number;
}

interface setSteps {
  type: typeof SET_STEPS;
  steps: number;
}

export interface ApplicantFormatted {
  email: string;
  document: string;
  documentType: number;
  lastName: string;
  name: string;
  phone: { country: string | Country; phone: string };
  secondLastName: string;
  nationality: string;
}

export interface ApplicantFormated {
  email: string;
  document: string;
  documentType: number;
  lastName: string;
  name: string;
  phone: string;
  secondLastName: string;
}

interface setCodebtorsAnswer {
  type: typeof SET_CODEBTOR_ANSWER;
  codebtorAnswer: CodebtorAnswer;
}

interface setExecutive {
  type: typeof SET_EXECUTIVE;
  executive: string;
}

interface setRelatedApplicantId {
  type: typeof SET_RELATED_APPLICANT;
  relatedApplicantId: string;
}

export type Action =
  | setLoading
  | setMainApplicant
  | setActiveStep
  | setFirstCodebtor
  | setSecondCodebtor
  | setSteps
  | setCodebtorsAnswer
  | setProperty
  | setExecutive
  | setRelatedApplicantId;

export type Dispatch = (action: Action) => void;
