import { makeStyles, Theme } from "@material-ui/core";

const useButtonEmptyFileStyles = makeStyles<Theme>((theme) => ({
  uploadButton: {
    border: `1px dashed ${theme.palette.grey[200]}`,
    borderRadius: 8,
    width: 112,
    height: 43,
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    textTransform: "none",
    marginTop: theme.spacing(0.5),
    pointerEvents: "auto",
  },
  fileName: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[500],
  },
  containerText: {
    display: "flex",
    alignItems: "center",
  },
  inputFileHiden: {
    display: "none",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export default useButtonEmptyFileStyles;
