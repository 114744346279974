import { AnimatePresence, motion } from "framer-motion";

import { produceKey } from "apps/ui/Navbar/utils/utils";
import { INavbarItem, TranslationObject } from "apps/ui/Navbar/utils/types";

import NavbarMenuItem from "../NavbarMenuItem";
import MobileSubmenuHeader from "../MobileSubmenuHeader";
import useMobileSubmenuStyles from "./MobileSubmenuStyles";

interface Props {
  items: INavbarItem[];
  label: string | TranslationObject;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const MobileSubmenu = ({ items, label, open, setOpen }: Props) => {
  const classes = useMobileSubmenuStyles();

  return (
    <AnimatePresence>
      <motion.div
        className={classes.mobileNested}
        animate={{
          x: open ? -16 : 320,
        }}
        transition={{ type: "tween" }}
        initial={false}
      >
        <MobileSubmenuHeader handleClose={() => setOpen(false)} label={label} />
        {items.map((menuItem) => (
          <NavbarMenuItem key={produceKey(menuItem)} {...menuItem} />
        ))}
      </motion.div>
    </AnimatePresence>
  );
};

export default MobileSubmenu;
