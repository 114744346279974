import { Container, Grid } from "@material-ui/core";

import { LandlordVipProvider } from "context/landlordVipContext/landlordVipContext";
import Buildings from "assets/images/adminLandlord/buildings.jpg";
import SimpleLayout from "apps/landlord/Layout/SimpleLayout";
import Navbar from "Components/Layout/GlobalHeader";

import HeaderReferPartner from "./components/HeaderReferPartner";
import FormReferPartner from "./components/FormReferPartner";
import useReferPartnerStyles from "./ReferPartnerStyles";

const ReferPartner = () => {
  const classes = useReferPartnerStyles({
    img: Buildings,
  });

  return (
    <LandlordVipProvider>
      <Navbar />
      <SimpleLayout>
        <div className={classes.displayContainer}>
          <Container maxWidth="md" className={classes.formContainer}>
            <Grid container spacing={4}>
              <HeaderReferPartner />
              <FormReferPartner />
            </Grid>
          </Container>
        </div>
      </SimpleLayout>
    </LandlordVipProvider>
  );
};

export default ReferPartner;
