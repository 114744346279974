import { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";

import {
  parseCodebtorData,
  parseDataUploadFiles,
} from "apps/lead/utils/parseEvaluatorFormData";
import { useCodebtor } from "apps/lead/context/codebtorContext/CodebtorContext";
import textByWorkerType from "apps/lead/utils/requirementsByWorkertype";
import { Files } from "apps/lead/context/codebtorContext/CodebtorTypes";
import { useCreateCodebtor } from "apps/lead/hooks/useCreateCodebtor";
import { useUploadFiles } from "apps/lead/hooks/useUploadFiles";
import { useFormNotifications } from "hooks";

import { fileSchema, requiredText } from "../utils/validationSchema";

const useCodebtorCreate = () => {
  const params = useParams<{ evaluationId: string }>();
  const { evaluationId } = params;
  const { state } = useCodebtor();
  const { codebtor, country } = state;
  const { workerType } = codebtor;
  const [isSuccess, setIsSuccess] = useState(false);
  const { mutate: uploadFiles, isLoading: isLoadingFiles } = useUploadFiles();
  const { mutate: createCodebtor, isLoading } = useCreateCodebtor();

  const requirements = useMemo(
    () => textByWorkerType(true, workerType, country),
    [workerType]
  );

  const methods = useForm<Files[]>({
    resolver: yupResolver(fileSchema(requirements)),
    reValidateMode: "onBlur",
  });

  const { errors } = methods.formState;
  useFormNotifications<Files[]>({ errors, requiredText });

  const renderFailureAlert = (error: string, onAction: () => void) =>
    Swal.fire({
      title: "Error",
      text: error,
      type: "error",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Intentar nuevamente",
    }).then((result) => {
      if (result.value) {
        onAction();
      }
    });

  const updateDocuments = (id: number, codebtorData) => {
    const parsedData = parseDataUploadFiles(
      id,
      Number(evaluationId),
      codebtorData,
      country
    );
    uploadFiles(parsedData, {
      onSuccess: () => setIsSuccess(true),
      onError: () =>
        renderFailureAlert(
          "No se pudieron subir los archivos correctamente",
          () => updateDocuments(id, codebtorData)
        ),
    });
  };

  const createNewCodebtor = () => {
    const codebtorData = parseCodebtorData(
      codebtor,
      country,
      Number(evaluationId)
    );
    createCodebtor(codebtorData, {
      onSuccess: (data) => {
        updateDocuments(data.id, codebtorData.user);
      },
      onError: () => {
        renderFailureAlert(
          "No se pudo crear codeudor, por favor intente nuevamente",
          () => createNewCodebtor()
        );
      },
    });
  };

  const onSubmit: SubmitHandler<Files[]> = () => createNewCodebtor();

  return {
    methods,
    requirements,
    onSubmit,
    loading: isLoadingFiles || isLoading,
    isSuccess,
  };
};

export default useCodebtorCreate;
