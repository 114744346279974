import { Grid } from "@material-ui/core";

import Spinner from "Components/Shared/partials/Spinner";

import TimeOption from "./components/TimeOption";
import useTimeSelectionStyles from "./TimeSelectionStyles";
import { ITimeOption } from "../../utils/types";

interface Props {
  availableHours: null | ITimeOption[];
  scheduleLoading: boolean;
  selectedTime: number;
  setSelectedTime: (n: number) => void;
}

const TimeSelection = ({
  availableHours,
  scheduleLoading,
  selectedTime,
  setSelectedTime,
}: Props) => {
  const classes = useTimeSelectionStyles();

  if (scheduleLoading) return <Spinner />;

  if (availableHours?.length === 0) return null;

  return (
    <Grid container className={classes.timeSelectionRoot} spacing={2}>
      {availableHours?.map((time) => (
        <Grid key={time.id} item xs={3}>
          <TimeOption
            label={time.label}
            onClick={() => setSelectedTime(time.id)}
            selected={selectedTime === time.id}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TimeSelection;
