import { format } from "date-fns";
import { round } from "lodash";

import statusRatingStars from "apps/landlord/utils/inspectionStatusRating";
import { Inspection } from "hooks/inspectionReports/types";

export function getDate(inspectionReport: Inspection) {
  return format(new Date(inspectionReport.createdAt), "dd/MM/yyyy");
}

export function getRating(inspectionReport: Inspection) {
  const filteredRatings = inspectionReport.spaceQualifications.filter(
    (summary) => summary.qualification !== "does_not_have"
  );
  const rating =
    filteredRatings.reduce(
      (summaryA, summaryB) =>
        summaryA + statusRatingStars[summaryB.qualification],
      0
    ) / filteredRatings.length;
  return round(rating, 1);
}
