export const workerTypeOptions = [
  {
    label: "Dependiente",
    value: "dependent",
  },
  {
    label: "Independiente",
    value: "independent",
  },
  {
    label: "Pensionado",
    value: "retired",
  },
];
