import { Box, CircularProgress, Typography } from "@material-ui/core";

import useStyles from "./CircularProgressWithLabelStyles";

interface Props {
  step: number;
  totalSteps: number;
}

const CircularProgressWithLabel = ({ step, totalSteps }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <CircularProgress
        variant="determinate"
        value={(100 * step) / totalSteps}
      />
      <Box className={classes.subRoot}>
        <Typography> {`${step}/${totalSteps}`} </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
