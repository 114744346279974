import { AxiosResponse } from "axios";

import httpClient from "@houm-com/http-client";

import { clientConfig, createHttp } from "services/helper";
import { BACKEND_URL } from "env";

import {
  ActiveContractResponse,
  EndHoumContractResponse,
  SignedContracts,
} from "./types";

export default {
  getSignedContractsDocuments: (
    contractId: number,
    token: string
  ): Promise<AxiosResponse<SignedContracts>> =>
    createHttp(token, BACKEND_URL).get(
      `/v2/contracts/landlord/${contractId}/signed_documents/`
    ),

  getActiveContract: (
    propertyId: number,
    token: string
  ): Promise<AxiosResponse<ActiveContractResponse>> =>
    createHttp(token, BACKEND_URL).get(
      `/v2/landlord/contract_by_property/${propertyId}`
    ),

  endContractHoum: (
    contractId: number,
    token: string
  ): Promise<AxiosResponse<EndHoumContractResponse>> => {
    const client = httpClient(clientConfig(token));
    return client.post(`/v2/contracts/landlord/${contractId}/desertion/begin/`);
  },

  getEndContractHoum: (
    contractId: number,
    token: string
  ): Promise<AxiosResponse<EndHoumContractResponse>> =>
    createHttp(token).get(`/v2/contracts/landlord/${contractId}/desertion/`),
};
