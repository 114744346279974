import { useTranslation } from "react-i18next";
import PasswordForm from "../../../PasswordForm/PasswordForm";
import MobileHeader from "../MobileHeader/MobileHeader";

const PasswordMobile = () => {
  const { t } = useTranslation("account.profile");
  return (
    <>
      <MobileHeader title="Contraseña" subtitle={t("password_text")} />
      <PasswordForm />
    </>
  );
};

export default PasswordMobile;
