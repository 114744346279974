import { makeStyles, Theme } from "@material-ui/core";

const useBankAccountStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#DAE4E8",
    borderRadius: 8,
    padding: theme.spacing(1.25),
    "& path": {
      strokeWidth: 50,
    },
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  card: {
    cursor: "pointer",
  },
  text: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
  },
}));

export default useBankAccountStyles;
