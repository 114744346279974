import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useHoumSnackBar } from "libs/houm-snackbar";

import { IProperty } from "apps/landlord/models/Property";
import propertyService from "apps/landlord/services/propertyService";
import { useGetToken } from "hooks";

import { parseProperty } from "./utils";

const useProperty = (
  options: Omit<
    UseQueryOptions<IProperty, Error, IProperty, QueryKey>,
    "queryKey" | "queryFn"
  > = {}
) => {
  const history = useHistory();
  const { getToken } = useGetToken();
  const { id: propertyId } = useParams<{ id: string }>();
  const { enqueueHoumSnackBar } = useHoumSnackBar();

  const {
    isLoading,
    error,
    data,
    isError,
    refetch,
  }: UseQueryResult<IProperty, Error> = useQuery<IProperty, Error>(
    ["property", propertyId],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.get(propertyId, authToken);
      return parseProperty(response.data);
    },
    {
      enabled: !!propertyId,
      ...options,
      onError: () => {
        enqueueHoumSnackBar({
          body: "Ha ocurrido un error",
          type: "error",
        });
        history.push("/landlord");
      },
    }
  );

  return {
    propertyLoading: isLoading,
    propertyError: error,
    isPropertyError: isError,
    propertyData: data,
    refetchProperty: refetch,
  };
};

export default useProperty;
