import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

import { Box, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import useHeaderStyles from "./HeaderStyles";

const Header = () => {
  const classes = useHeaderStyles();
  const history = useHistory();

  return (
    <>
      {!isMobile && (
        <Box className={classes.closeIconWrapper}>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => history.push("/tenant")}
          />
        </Box>
      )}
      <Typography className={classes.title}>Cuenta bancaria</Typography>
      <Typography className={classes.text}>
        Añade o edita tu cuenta a la que se realizará la devolución de la
        garantía. Recuerda que dicha devolución procederá de acuerdo a lo
        establecido en tu contrato.
      </Typography>
    </>
  );
};

export default Header;
