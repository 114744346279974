import { useMutation } from "react-query";
import { AxiosError } from "axios";

import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import paymentService from "apps/payments/services/paymentService";
import {
  setPaymentData,
  setStep,
  setTenantData,
} from "apps/payments/context/rentPaymentContext/rentPaymentActions";
import { useHoumSnackBar } from "libs/houm-snackbar";

import { parseRentPaymentCL } from "../../utils/parsers";
import { paymentDataFetchError } from "../../utils/utils";

interface FormProps {
  document: string;
  documentType: number;
}

const useRentPaymentData = () => {
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const { dispatch, state } = useRentPayment();

  const { mutate, isLoading, isError, isSuccess, data } = useMutation(
    async ({ document, documentType }: FormProps) => {
      const paymentResponse = await paymentService.getRentPaymentCL(document);
      const parsedData = parseRentPaymentCL(paymentResponse.data);
      dispatch(setPaymentData(parsedData));
      dispatch(
        setTenantData({
          ...state.tenantData,
          document,
          documentType,
        })
      );
      dispatch(setStep("paymentDetail"));
    },
    {
      onError: (e: AxiosError) => enqueueHoumSnackBar(paymentDataFetchError(e)),
    }
  );

  return {
    data,
    mutate,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useRentPaymentData;
