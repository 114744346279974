import { Application, EvaluationStatus } from "models/Evaluation";

export const labelEvaluateButton = (lastApplication: Application) =>
  lastApplication.status === EvaluationStatus.APPROVED ? "Aprobado" : "Evaluar";

export const getInfoContainerTexts = (status: EvaluationStatus) => {
  switch (status) {
    case EvaluationStatus.APPROVED:
      return [
        {
          text: "Postulante aprobado",
          emoji: "🥳",
          "aria-label": "partying face",
        },
        {
          text: "Datos del contrato",
          emoji: "📄",
          "aria-label": "page facing up",
        },
        {
          text: "Tu arrendatario",
          emoji: "👇🏼",
          "aria-label": "pointing down",
        },
      ];
    default:
      return [
        {
          text: "Revisa los datos del contrato",
          emoji: "🧐",
          ariaLabel: "face-with-monocle",
        },
        {
          text: "Conoce quienes son los postulantes",
          emoji: "👇🏼",
          ariaLabel: "point-down",
        },
        {
          text: "Tienes 24 horas para evaluar",
          emoji: "⏰",
          ariaLabel: "alarm-clock",
        },
      ];
  }
};

export const getTitle = (status: EvaluationStatus) =>
  status === EvaluationStatus.APPROVED
    ? "¡Este es el arrendatario perfecto para ti!"
    : `¡Queremos encontrar al arrendatario perfecto para ti!`;
