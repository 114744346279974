import { validate } from "rut.js";

import { DocumentTypes } from "apps/landlord/hooks/useDocumentTypeList/useDocumentTypeList";
import { Locale } from "models/Countries";
import * as yup from "yup";

const requiredText = "Este campo es requerido";
const nameRegex =
  /^[A-Za-zÀ-ÖØ-öø-ÿ.]+\s[A-Za-zÀ-ÖØ-öø-ÿ.]+(\s[A-Za-zÀ-ÖØ-öø-ÿ.]+)*$/;

const generalValidations = {
  fullName: yup
    .string()
    .required(requiredText)
    .test("fullName", null, (obj) => {
      const regex = new RegExp(nameRegex);
      if (regex.test(obj.trim())) return true;
      return new yup.ValidationError(
        "Por favor ingresar nombre y apellido",
        null,
        "fullName",
        "typeError"
      );
    }),
  bankCode: yup.string().required(requiredText),
  accountNumber: yup.string().required(requiredText),
  numberValidation: yup
    .string()
    .required(requiredText)
    .oneOf(
      [yup.ref("accountNumber"), null],
      "Los números de cuenta no coinciden"
    ),
};

const clValidations = {
  documentType: yup.string().required(requiredText),
  document: yup
    .string()
    .required(requiredText)
    .test("document", "Por favor ingrese un RUT válido", (val) =>
      validate(val)
    ),
  accountType: yup.string().required(requiredText),
};

const coValidations = {
  accountType: yup.string().required(requiredText),
};

const mxValidations = {
  clabeNumber: yup
    .string()
    .required(requiredText)
    .test(
      "len",
      "La cuenta clabe debe tener 18 dígitos",
      (val) => String(val).length === 18
    ),
};

const documentValidations = (documentTypeList: DocumentTypes[]) => ({
  documentType: yup.string().required(requiredText),
  document: yup
    .string()
    .required(requiredText)
    .test("validationDocument", null, (value, { parent: { documentType } }) => {
      if (!documentType) return true;
      const docType = documentTypeList.find(
        (doc) => doc.identifier === documentType
      );
      const regex = new RegExp(docType.regex);
      if (regex.test(value.toUpperCase())) return true;
      return new yup.ValidationError(
        `${docType.label} no válido`,
        null,
        "document",
        "typeError"
      );
    }),
});

export const validationSchema = (
  documentTypeList: DocumentTypes[],
  country: Locale
) =>
  yup.object().shape({
    ...generalValidations,
    ...(country === "cl" && { ...clValidations }),
    ...(country !== "cl" && { ...documentValidations(documentTypeList) }),
    ...(country === "co" && { ...coValidations }),
    ...(country === "mx" && { ...mxValidations }),
  });
