import { Box } from "@material-ui/core";
import Spinner from "Components/Shared/partials/Spinner";
import useInitialStepStyles from "./Introduction/IntroductionStyles";

const Loader = () => {
  const classes = useInitialStepStyles();
  return (
    <Box className={classes.root}>
      <Spinner />
    </Box>
  );
};

export default Loader;
