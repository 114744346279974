import { phoneObject } from "utils/yupValidators/phone";
import { object, string } from "yup";

export const requiredText = "Obligatorio";

export const employmentSchema = object().shape({
  occupation: string().nullable().required(requiredText),
  employer: string().nullable().required(requiredText),
  phone: phoneObject
    .typeError("Número de celular inválido")
    .required(requiredText),
});
