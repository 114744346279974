import { IServicesRequest } from "../../../hooks/types";

const isButtonDisabled = (ServicesRequest: IServicesRequest) => {
  const { debtType, regularizationType, ggccPhotos, ssbbPhotos, description } =
    ServicesRequest;

  const needGGCCPhotos = debtType?.includes("ggcc");
  const needSSBBPhotos = debtType?.includes("ssbb");

  const notGGCCPhotos = needGGCCPhotos && ggccPhotos?.length === 0;
  const notSSBBPhotos = needSSBBPhotos && ssbbPhotos?.length === 0;

  return (
    !debtType ||
    !regularizationType ||
    notGGCCPhotos ||
    notSSBBPhotos ||
    !description
  );
};

export default isButtonDisabled;
