import { makeStyles, Theme } from "@material-ui/core/styles";

const defaultBreakpoint = "md";

const useCallToActionStyles = makeStyles((theme: Theme) => ({
  title: {
    margin: theme.spacing(1, 0),
    color: theme.palette.grey[900],
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(20),
    lineHeight: "1.5em",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      margin: theme.spacing(2, 0, 1),
    },
  },
  subtitle: {
    margin: theme.spacing(1, 0),
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "1.5em",
  },
  description: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text[400],
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "1.25em",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

export default useCallToActionStyles;
