import { makeStyles, Theme } from "@material-ui/core";

const useBankAccountFormStyles = makeStyles((theme: Theme) => ({
  bankAccountFormRoot: {
    padding: theme.spacing(3, 2),
    [theme.breakpoints.up("sm")]: {
      padding: 0,
    },
  },
  bankAccountFormDrawer: {
    padding: theme.spacing(3, 5),
    width: "40%",
    maxWidth: 480,
  },
}));

export default useBankAccountFormStyles;
