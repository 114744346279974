import { makeStyles } from "@material-ui/core";

export const useHeaderReferPartnerStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "grid",
    gap: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: "1.25rem",
    lineHeight: "1.25rem",
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  subTitle: {
    fontSize: "1rem",
    lineHeight: "1.25em",
    fontWeight: 400,
    color: theme.palette.grey[850],
  },
}));
