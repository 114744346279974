import { Suspense } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import Alert from "@houm-com/ui/Alert";
import { UserProvider } from "context/userContext";
import {
  AUTH0_DOMAIN,
  AUTH0_CLIENTID,
  REACT_QUERY_DEV_TOOLS,
  AUTH0_AUDIENCE,
} from "env";
import TokyHandler from "libs/toky/TokyHandler";
import { HoumSnackbarProvider } from "libs/houm-snackbar";
import Theme from "Components/Layout/Theme";
import AuthorizeUser from "./components/AuthorizeUser";
import MainRouter from "./routes/MainRouter";
import AuthenticateUser from "./components/AuthenticateUser";
import ForceLoginControl from "./components/ForceLoginControl";
import Spinner from "Components/Shared/partials/Spinner";
import { UserRoleProvider } from "./context/userRoleContext";

const MainApp = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    },
  });
  return (
    <HoumSnackbarProvider>
      <Alert />
      <Suspense fallback={<Spinner />}>
        <QueryClientProvider client={queryClient}>
          <Auth0Provider
            domain={AUTH0_DOMAIN}
            clientId={AUTH0_CLIENTID}
            redirectUri={window.location.origin}
            audience={AUTH0_AUDIENCE}
          >
            <Theme>
              <UserProvider>
                <Router>
                  <TokyHandler>
                    <AuthenticateUser>
                      <ForceLoginControl>
                        <AuthorizeUser>
                          <UserRoleProvider>
                            <MainRouter />
                          </UserRoleProvider>
                        </AuthorizeUser>
                      </ForceLoginControl>
                    </AuthenticateUser>
                  </TokyHandler>
                </Router>
              </UserProvider>
            </Theme>
          </Auth0Provider>
          {REACT_QUERY_DEV_TOOLS && (
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          )}
        </QueryClientProvider>
      </Suspense>
    </HoumSnackbarProvider>
  );
};

export default MainApp;
