import { createContext, useCallback, useContext, useState } from "react";

import { HoumSnackbarContainer, HoumSnackbar } from "../components";
import { DEFAULT_DURATION } from "../constants";
import {
  IHoumSnackbarContext,
  SnackbarPayload,
  Append,
  RemoveFirst,
} from "../types";

export const HoumSnackbarContext = createContext<IHoumSnackbarContext>(null);

const append: Append = (payload) => (prev) => [...prev, payload];
const removeFirst: RemoveFirst = (prev) => prev.slice(1);

export const HoumSnackbarProvider = ({
  children,
  duration = DEFAULT_DURATION,
}) => {
  const [snackBarList, setSnackBarList] = useState<SnackbarPayload[]>([]);

  const enqueueHoumSnackBar = useCallback(
    (payload: SnackbarPayload) => {
      const timeoutId = setTimeout(() => {
        /**
         * We need to remove the first element if the device is a desktop
         * and the last element if the device is a mobile.
         */
        const updateSnackbarListOnDelete = removeFirst;
        setSnackBarList(updateSnackbarListOnDelete);
      }, payload.duration || duration);

      const payloadWithKey = {
        ...payload,
        snackbarKey: Number(timeoutId),
      };
      /**
       * If the device is a desktop, we need to add the snackbar to the end of the list
       * and if the device is a mobile, we need to add the snackbar to the beginning of the list.
       */
      const updateSnackbarListOnCreate = append;
      /**
       * finally we update the snackbar list
       * and pass the updated list to the snackbar container
       */
      setSnackBarList(updateSnackbarListOnCreate(payloadWithKey));
    },
    [duration]
  );

  const removeHoumSnackBar = useCallback(
    (key: number) => {
      const index = snackBarList.findIndex((item) => item.snackbarKey === key);
      if (index !== -1) {
        const removeAtIndex = (prev) => prev.filter((_, i) => i !== index);
        setSnackBarList(removeAtIndex);
        /**
         * We need to clear the timeout of the snackbar
         * If we don't clear the timeout, the snackbar will throw an error
         */
        clearTimeout(key);
      }
    },
    [snackBarList]
  );

  const hasSnackbar = snackBarList.length > 0;

  return (
    <HoumSnackbarContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        enqueueHoumSnackBar,
        removeHoumSnackBar,
      }}
    >
      {children}
      {hasSnackbar && (
        <HoumSnackbarContainer>
          {snackBarList.map(({ snackbarKey, head, body, type, actions }) => (
            <HoumSnackbar
              key={snackbarKey}
              snackbarKey={snackbarKey}
              head={head}
              body={body}
              type={type}
              actions={actions}
              onClose={() => removeHoumSnackBar(snackbarKey)}
            />
          ))}
        </HoumSnackbarContainer>
      )}
    </HoumSnackbarContext.Provider>
  );
};

export const useHoumSnackBar = () => useContext(HoumSnackbarContext);
