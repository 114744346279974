import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

import { Box, Grid } from "@material-ui/core";

import BackButton from "apps/tenant/components/BackButton";
import useContract from "apps/tenant/hooks/useContract";
import useServices from "apps/tenant/hooks/useServices";
import { useNavbar } from "apps/ui/Navbar/context/navbarContext";
import Spinner from "Components/Shared/partials/Spinner";

import { getServiceData } from "./utils/utils";
import ServiceCard from "./components/ServiceCard";
import ServicesHeader from "./components/ServicesHeader";
import StatusExplanation from "./components/StatusExplanation";
import useServicesStyles from "./ServicesStyles";

const Services = () => {
  const classes = useServicesStyles();
  const { isLoading: contractLoading, data: contractData } = useContract();
  const { data, isLoading: servicesLoading } = useServices(
    contractData?.propertyId
  );
  const { setBackUrl } = useNavbar();
  const history = useHistory();

  useEffect(() => {
    setBackUrl("/tenant");
  }, [setBackUrl]);

  if (contractLoading || servicesLoading)
    return <Spinner className={classes.spinner} />;

  return (
    <Box className={classes.servicesRoot}>
      <Box className={classes.servicesInnerContainer}>
        {!isMobile && (
          <BackButton onClickHandler={() => history.push("/tenant")} />
        )}
        <ServicesHeader noServices={data?.length === 0} />
        <Box className={classes.servicesContent}>
          <Grid container spacing={2}>
            {data.map((service) => (
              <Grid key={service.id} item xs={12} sm={6}>
                <ServiceCard {...getServiceData(service)} />
              </Grid>
            ))}
          </Grid>
          <StatusExplanation />
        </Box>
      </Box>
    </Box>
  );
};

export default Services;
