import clx from "classnames";

import { Box } from "@material-ui/core";

import useStepperStyles from "./StepperStyles";

interface Props {
  steps: number;
  currentStep: number;
}

const Stepper = ({ steps, currentStep }: Props) => {
  const classes = useStepperStyles();
  const stepsArray = Array.from(Array(steps).keys());
  return (
    <Box className={classes.container}>
      {stepsArray.map((step) => (
        <Box
          key={step}
          className={clx(classes.step, {
            [classes.filled]: step <= currentStep,
          })}
          data-testid="step"
        ></Box>
      ))}
    </Box>
  );
};

export default Stepper;
