import { useTranslation } from "react-i18next";

import { useFeatureManager } from "@houm-com/feature-manager";

import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";

import { Subtitle } from "../../../typography";
import PaymentsDisclaimer, {
  PaymentsDisclaimerProps,
} from "../PaymentsDisclaimer";
import { classes } from "./HeaderStyles";

const Header = () => {
  const { t } = useTranslation("rent-payment");
  const { state } = useRentPayment();
  const { data: disclaimerFlag, status: disclaimerFlagStatus } =
    useFeatureManager("payments_disclaimer", { marketCode: state.country });
  const disclaimerData = disclaimerFlag?.content as PaymentsDisclaimerProps;
  const isDisclaimerEnabled =
    disclaimerFlagStatus === "success" && disclaimerFlag?.enabled;

  return (
    <div className={classes.headerContainer}>
      <div className={classes.headerContainer}>
        <h1 className={classes.title}>{t("PAYMENT_METHOD_TITLE")}</h1>
        <Subtitle className={classes.subtitle}>
          {t("PAYMENT_METHOD_SUBTITLE")}
        </Subtitle>
      </div>
      {isDisclaimerEnabled && (
        <div className={classes.disclaimer}>
          <PaymentsDisclaimer {...disclaimerData} />
        </div>
      )}
    </div>
  );
};

export default Header;
