import { useForm, FormProvider } from "react-hook-form";

import { useClosureForm } from "context/closureFormContext/closureFormContext";
import {
  setAdminData,
  setReady,
} from "context/closureFormContext/closureFormActions";
import fields from "context/closureFormContext/closureFormFields";
import { AdminDataProps } from "context/closureFormContext/closureFormTypes";

import CallToAction from "./CustomCallToAction";
import { FilteredOptions, FilteredTextField } from "./FilteredComponents";

export default function AdminDataStep() {
  const { state, dispatch } = useClosureForm();
  const stepState = state.adminData;
  const stepFields = fields.adminstration;

  const methods = useForm<AdminDataProps>({
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    // falta validación de datos
    data.hasHoumCleaning = data.hasHoumCleaning === "si";
    dispatch(setAdminData(data));
    dispatch(setReady("adminData"));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FilteredOptions
          id="hasHoumCleaning"
          fieldInfo={stepFields.hasHoumCleaning()}
          defaultValue={stepState.hasHoumCleaning}
          options={[
            { value: "1", label: "Si" },
            { value: "0", label: "No" },
          ]}
          optionsProps={{
            helperText:
              "¿Desea que Houm se encargue de la limpieza en el momento que se arriende? El costo por el servicio de aseo es de $90.000 que será descontado del segundo canon de arrendamiento por parte de HOUM al MANDANTE. Conforme la Ley 820 de 2003, el inmueble debe ser entregado en condiciones habitables, incluyendo la limpieza del mismo, por lo cual, en caso de no tomar el servicio y HOUM evidencie que no ha sido debidamente aseado el Inmueble al momento del arriendo, este tiene la facultad de limpiarlo y descontarlo del segundo canon de arriendo, conforme el numeral 1.9. del presente contrato. ",
          }}
        />

        <FilteredTextField
          id="cleaningAmount"
          fieldInfo={stepFields.cleaningAmount()}
          defaultValue={stepState.cleaningAmount}
        />

        <CallToAction />
      </form>
    </FormProvider>
  );
}
