import { makeStyles, Theme } from "@material-ui/core";

const useBreakdownStyles = makeStyles<Theme>((theme) => ({
  detail: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 2,
    "& p": {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.57,
    },
    padding: theme.spacing(0.25, 0.5),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1),
      "& p": {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 1.5,
      },
    },
  },
  detailName: {
    maxWidth: "70%",
  },
  detailValue: {
    minWidth: "30%",
    textAlign: "end",
  },
}));

export default useBreakdownStyles;
