import TenantLayout from "apps/tenant/layout/TenantLayout";
import Services from "./Services";

export default function () {
  return (
    <TenantLayout>
      <Services />
    </TenantLayout>
  );
}
