import { useQuery, UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";

import paymentService from "apps/landlord/services/paymentService";
import { useProperty } from "apps/landlord/hooks";
import { useGetToken } from "hooks";
import { countryCode } from "utils";

import { parsePaymentDetail } from "./utils/parsers";
import { IPaymentDetail } from "./utils/types";

const usePaymentDetails = () => {
  const { movementId } = useParams<{ movementId: string }>();
  const { propertyLoading, propertyData, isPropertyError } = useProperty();
  const { getToken } = useGetToken();

  const {
    isLoading,
    data,
    isError,
    refetch,
  }: UseQueryResult<IPaymentDetail, Error> = useQuery<IPaymentDetail, Error>(
    ["payment_detail_by_id", propertyData, movementId],
    async () => {
      const authToken = await getToken();
      const response = await paymentService.getPaymentDetailByMovementId(
        countryCode(propertyData?.country),
        movementId,
        authToken
      );
      return parsePaymentDetail(response.data);
    },
    {
      enabled: !!movementId && !!propertyData,
    }
  );

  return {
    isLoading: isLoading || propertyLoading,
    isError: isPropertyError || isError,
    data,
    refetch,
  };
};

export default usePaymentDetails;
