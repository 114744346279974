import { FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import FieldInput from "@houm-com/ui/FieldInput";
import Button from "@houm-com/ui/Button";

import { IBillInfo } from "apps/landlord/services/propertiesDuesService/type";
import useChangeBillExternalId from "apps/landlord/hooks/propertyBills/useChangeBillExternalId";

import { validationSchemaClientCode } from "../../utils/validationSchema";
import classes from "./ClientCodeFormStyles";

interface ClientCodeProps {
  item: Partial<IBillInfo>;
  afterSubmit: () => void;
}

type FormProps = {
  externalId: number;
};

const ClientCodeForm = ({ item, afterSubmit }: ClientCodeProps) => {
  const { mutate, submitting } = useChangeBillExternalId();
  const methods = useForm<FormProps>({
    defaultValues: {
      externalId: Number(item.clientInfo.externalId),
    },
    resolver: yupResolver(validationSchemaClientCode),
  });

  const onSubmit = (data: FormProps) => {
    mutate({ externalId: String(data.externalId), company: item.company });
    afterSubmit();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FieldInput
          id="externalId"
          name="externalId"
          label="Número de cliente"
          placeholder="000000000"
          type="number"
          className={classes.formInput}
          classNameContainer={classes.formInputContainer}
          errorMessage={methods.formState.errors.externalId?.message}
          {...methods.register("externalId")}
        />
        <Button
          type="submit"
          variant="primary"
          size="lg"
          className={classes.formButton}
          isLoading={submitting}
        >
          Guardar
        </Button>
      </form>
    </FormProvider>
  );
};

export default ClientCodeForm;
