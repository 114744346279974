import { FormProvider } from "react-hook-form";

import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper/FormWrapper";
import ApplicantFields from "apps/lead/components/EvaluatorForm/ApplicantFields";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";

import useUserForm from "../../hooks/useUserForm";

const UserForm = () => {
  const { methods, activeStep, steps, onSubmit, handleBackClick } =
    useUserForm();

  return (
    <FormProvider {...methods}>
      <Header
        title="Datos codeudor"
        subtitle="Asegúrate de que estén correctos"
        activeStep={activeStep}
        steps={steps}
      />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper>
          <ApplicantFields country="co" documentList={[]} />
        </FormWrapper>
        <CallToAction onHandleBackClick={handleBackClick} />
      </form>
    </FormProvider>
  );
};

export default UserForm;
