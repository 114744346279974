import { makeStyles, Theme } from "@material-ui/core";

const useConfirmationStepStyles = makeStyles<Theme>((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      padding: "unset",
      marginTop: "unset",
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(5),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.spacing(3),
    },
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  ctaContainer: {
    display: "flex",
    gap: theme.spacing(2),
    width: "100%",
    justifyContent: "flex-end",
    [theme.breakpoints.up("sm")]: {
      width: "unset",
      position: "absolute",
      bottom: theme.spacing(5),
    },
  },
}));

export default useConfirmationStepStyles;
