import { NegotiationsProvider } from "context/negotiationsContext/negotiationsContext";

import NegotiationsIndex from "./NegotiationsIndex";

export default function Negotiations() {
  return (
    <NegotiationsProvider>
      <NegotiationsIndex />
    </NegotiationsProvider>
  );
}
