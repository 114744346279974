import { useState } from "react";

import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

import ScheduleSingleDate from "./ScheduleSingleDate";
import CustomCalendar from "./CustomCalendar";
import useStyles from "./ScheduleDatesStyles";

interface SchedulesDay {
  id: number;
  begin_date: string;
  status: string;
  appraiser: {
    name: string;
    phone: string;
  };
}

const ScheduleDates = () => {
  const classes = useStyles();
  const [allSchedulesDay, setAllSchedulesDay] = useState<SchedulesDay[]>([]);

  const getGridSize = () => {
    if (allSchedulesDay.length >= 3) {
      return 4;
    } else if (allSchedulesDay.length === 2) {
      return 6;
    } else if (allSchedulesDay.length === 1) {
      return 12;
    }
  };

  return (
    <Grid container item xs={12} className={classes.componentContainer}>
      <Grid container item>
        <Grid container item alignContent="flex-start" xs={12}>
          <Grid item container xs={8}>
            <p className={classes.title}>Calendario visitas</p>
          </Grid>
          <Grid item container xs={12}>
            <p className={classes.subtitle}>
              Entérate de las visitas que tu propiedad tiene agendadas
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justifyContent="space-between"
        className={classes.gridContainer}
        spacing={2}
      >
        <Grid item xs={12} lg={6}>
          <Card className={classes.cardContainer}>
            <CustomCalendar setAllSchedulesDay={setAllSchedulesDay} />
          </Card>
        </Grid>
        <Grid item container xs={12} lg={6}>
          <Card className={classes.cardContainerDate}>
            {allSchedulesDay.length ? (
              <Grid
                item
                container
                xs={12}
                justifyContent="center"
                alignContent="center"
                alignItems="center"
              >
                <Grid item container xs={12}>
                  <span className={classes.titleday}>
                    {moment
                      .utc(allSchedulesDay[0].begin_date)
                      .clone()
                      .format("dddd DD")
                      .toUpperCase()}
                  </span>
                </Grid>
                {allSchedulesDay.map((sc) => (
                  <Grid
                    item
                    container
                    xs={12}
                    md={getGridSize()}
                    key={sc.id}
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                  >
                    <ScheduleSingleDate schedule={sc} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid
                item
                container
                xs={12}
                md={12}
                justifyContent="center"
                alignContent="center"
                alignItems="center"
              >
                <h1 className={classes.titleday}>
                  No hay ningúna visita para este día.
                </h1>
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScheduleDates;
