import { FC, SVGProps } from "react";

const PhotoSpecsIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.75682 12.2808H7.71867M7.71867 12.2808H12.2808M7.71867 12.2808V7.67865M12.2808 12.2808V16.2427M12.2808 12.2808V7.67865M12.2808 7.67865H16.2427M12.2808 7.67865H7.71867M7.71867 3.7168V7.67865M5.31755 16.2827H14.6819C15.566 16.2827 16.2827 15.566 16.2827 14.6819V5.31755C16.2827 4.43348 15.566 3.7168 14.6819 3.7168H5.31755C4.43348 3.7168 3.7168 4.43348 3.7168 5.31755V14.6819C3.7168 15.566 4.43348 16.2827 5.31755 16.2827Z"
      stroke="#263238"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
  </svg>
);
export default PhotoSpecsIcon;
