import { Grid, Typography } from "@material-ui/core";

import ControlledPhoneInput from "Components/Admin/UIComponents/ControlledForm/ControlledPhoneInput";
import ControlledTextField from "Components/Admin/UIComponents/ControlledForm/ControlledTextField";
import { formatPhoneInput } from "utils";

import { useFormReferPartnerStyles } from "./FormReferPartnerStyles";
import ButtonReferPartner from "./ButtonReferPartner";

interface Props {
  defaultData: any;
  loading: boolean;
}

const FormReferContent = ({ defaultData, loading }: Props) => {
  const classes = useFormReferPartnerStyles();

  const nullishPhone = formatPhoneInput("");
  const defaultControlledPhoneValue = {
    country: nullishPhone.country,
    phone: nullishPhone.phone,
  };

  return (
    <>
      <Typography className={classes.fromTitle}>
        Cuéntanos quién es esa persona
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ControlledTextField
            label="Nombre"
            id="name"
            textFieldProps={{
              placeholder: "Ej: Juan",
              classes: { root: classes.textField },
              InputProps: { classes: { root: classes.input } },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label="Apellido"
            id="last_name"
            textFieldProps={{
              placeholder: "Ej: Carter",
              classes: { root: classes.textField },
              InputProps: { classes: { root: classes.input } },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label="Correo electrónico"
            id="email"
            textFieldProps={{
              placeholder: "Ej: juancarter@example.com",
              classes: { root: classes.textField },
              InputProps: { classes: { root: classes.input } },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledPhoneInput
            countryLabel="Teléfono"
            phoneLabel=" "
            id="phone"
            countryInputProps={{
              placeholder: "+56",
              classes: { root: classes.textField },
              InputProps: {
                classes: { root: classes.input },
                disableUnderline: true,
              },
            }}
            numberInputProps={{
              placeholder: "Ingresar",
              classes: { root: classes.textField },
              InputProps: {
                classes: { root: classes.input },
                disableUnderline: true,
              },
            }}
            defaultValue={defaultControlledPhoneValue}
            classNameInputContainer={classes.formControlPhoneInputContainer}
          />
        </Grid>
        <ButtonReferPartner loading={loading} />
      </Grid>
    </>
  );
};

export default FormReferContent;
