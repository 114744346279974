import { ActiveContractResponse } from "apps/landlord/services/propertyContractServiceV2/types";

const parsedActiveContract = (data: ActiveContractResponse) => ({
  activeSubscriptionId: data.active_subscription_id,
  country: data.country,
  id: data.id,
  initDate: data.init_date,
  endDate: data.end_date,
  endNoticePeriod: data.end_notice_period,
  propertyId: data.property_id,
  lastSubscription: data.last_subscription,
});

export default parsedActiveContract;
