import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import useContract from "apps/tenant/hooks/useContract";
import useContractEnd from "apps/tenant/hooks/useContractEnd";

import { ITimeOption } from "../utils/types";
import useScheduleUser from "./useScheduleUser";
import useAvailableHours from "./useAvailableHours";
import { formatDate, getAvailableDates } from "./utils/utils";

const useScheduling = () => {
  const [availableDates, setAvailableDates] = useState<null | Date[]>(null);
  const [availableHours, setAvailableHours] = useState<null | ITimeOption[]>(
    null
  );
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState<null | number>(null);
  const [missingTime, setMissingTime] = useState(false);
  const { data: contractData } = useContract();
  const { contractEndData } = useContractEnd();
  const history = useHistory();

  const { mutate, isLoading: scheduling } = useScheduleUser();
  const { data: timeAvailability, isLoading: timeAvailabilityLoading } =
    useAvailableHours({
      propertyId: contractData?.propertyId,
      countryCode: contractData?.country,
    });

  const getSelectedTime = (): `${string}:${string}` =>
    availableHours?.find((time) => time.id === selectedTime)?.label;

  const handleSubmit = () => {
    if (selectedTime !== 0 && !selectedTime) setMissingTime(true);
    else {
      setMissingTime(false);
      mutate(
        {
          ...formatDate({ date: selectedDate, time: getSelectedTime() }),
          propertyId: contractData?.propertyId,
          contractId: contractData?.id,
        },
        {
          onSuccess: () => {
            history.push("/tenant/exit-visit-summary");
          },
          onError: () => {
            Swal.fire({
              type: "error",
              text: "No se pudo agendar la visita.",
            });
          },
        }
      );
    }
  };

  useEffect(() => {
    if (timeAvailability && contractEndData) {
      setAvailableDates(
        getAvailableDates({
          availableDates: timeAvailability,
          endDate: contractEndData.estimatedDate,
        })
      );
    }
  }, [timeAvailability, contractEndData]);

  useEffect(() => {
    if (availableDates?.length > 0) {
      setSelectedDate(availableDates[0]);
    }
  }, [availableDates]);

  useEffect(() => {
    setSelectedTime(null);
    const formattedDate = format(selectedDate, "yyyy-MM-dd");
    if (timeAvailability && timeAvailability[formattedDate]) {
      setAvailableHours(timeAvailability[formattedDate]);
    } else {
      setAvailableHours([]);
    }
  }, [selectedDate, timeAvailability]);

  return {
    availableDates,
    availableHours,
    selectedDate,
    setSelectedDate,
    handleSubmit,
    showMissingTime: missingTime,
    scheduling,
    scheduleLoading: timeAvailabilityLoading,
    selectedTime,
    setSelectedTime,
  };
};

export default useScheduling;
