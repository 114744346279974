import { makeStyles, Theme } from "@material-ui/core";

const useKastorFieldsStyles = makeStyles<Theme>((theme) => ({
  kastorFieldsHelper: {
    backgroundColor: "#D5E3F8",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  kastorFieldsHelperText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    lineHeight: 1.6,
    color: "#2C73DB",
  },
}));

export default useKastorFieldsStyles;
