import { useHistory } from "react-router-dom";

import PropertyLayout from "apps/landlord/Layout/PropertyLayout";
import InspectionReports from "./components/InspectionReports";

const DocumentsIndex = () => {
  const history = useHistory();

  return (
    <PropertyLayout
      onHandleBack={() => history.goBack()}
      titlePage="Estas inspecciones muestran el estado tu propiedad y te dejamos
    comentarios de posibles mejoras 😉"
    >
      <InspectionReports />
    </PropertyLayout>
  );
};

export default DocumentsIndex;
