import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Box, Drawer } from "@material-ui/core";

import { useNavbar } from "apps/ui/Navbar/context/navbarContext";
import { isMobile } from "react-device-detect";
import useRepairsRequestPageStyles from "./RepairsRequestPageStyles";
import RepairsRequest from "./RepairsRequest";

export default function () {
  const classes = useRepairsRequestPageStyles();
  const history = useHistory();
  const { setBackUrl } = useNavbar();

  const handleBack = () => history.push("/tenant/help");

  useEffect(() => {
    setBackUrl("/tenant/help");
  }, [setBackUrl]);

  if (isMobile) {
    return (
      <Box className={classes.container}>
        <RepairsRequest closeDrawer={handleBack} />
      </Box>
    );
  }
  return (
    <Drawer
      anchor="right"
      open
      onClose={handleBack}
      classes={{ paper: classes.drawer, root: classes.drawerRoot }}
    >
      <Box className={classes.drawerContent}>
        <RepairsRequest closeDrawer={handleBack} />
      </Box>
    </Drawer>
  );
}
