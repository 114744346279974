import { useEffect } from "react";
import { useParams } from "react-router-dom";

import useBankAccountByPropertyId from "apps/landlord/hooks/useBankAccountByPropertyId";
import useDocumentTypeList from "apps/landlord/hooks/useDocumentTypeList";
import FormBankAccount from "apps/landlord/components/FormBankAccount";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import DrawerHeader from "apps/ui/CustomDrawer/DrawerHeader";
import Spinner from "Components/Shared/partials/Spinner";
import useGetBanks from "apps/landlord/hooks/useBanks";
import { useProperty } from "apps/landlord/hooks";
import Drawer from "apps/ui/CustomDrawer/Drawer";

interface Props {
  parentUrl?: string;
}

const DrawerBankAccount = ({ parentUrl }: Props) => {
  const { id: propertyId } = useParams<{ id: string }>();
  const { propertyData, propertyLoading, isPropertyError } = useProperty();
  const PARENT_URL = parentUrl ?? `/landlord/properties/${propertyId}`;
  const { open, returnToMainPage, saveParentUrl } = useCustomDrawer();
  const { bankData, bankLoading, bankError } = useBankAccountByPropertyId(
    propertyData?.country,
    propertyData?.id
  );
  const {
    isLoading: bankListLoading,
    data: bankList,
    isError: isErrorBankList,
  } = useGetBanks({
    country: propertyData?.country,
  });
  const {
    data: documentTypeList,
    isLoading: isLoadingDocumentTypeList,
    isError: isErrorDocumentTypeList,
  } = useDocumentTypeList(propertyData?.country);

  const isErrored =
    (bankError && bankError?.response?.status !== 404) ||
    isErrorDocumentTypeList ||
    isErrorBankList ||
    isPropertyError;

  if (isErrored) returnToMainPage();

  const loadingData =
    bankLoading ||
    propertyLoading ||
    bankListLoading ||
    isLoadingDocumentTypeList;

  useEffect(() => {
    saveParentUrl(PARENT_URL);
  }, [PARENT_URL]);

  return (
    <Drawer onHandleClose={returnToMainPage} open={open}>
      <DrawerHeader onHandleClose={returnToMainPage} />
      {loadingData && <Spinner />}
      {!isErrored && !loadingData && (
        <FormBankAccount
          bankAccountData={bankData}
          country={propertyData?.country}
          bankList={bankList}
          documentTypeList={documentTypeList}
          user={propertyData?.user}
          propertyId={propertyData?.id}
        />
      )}
    </Drawer>
  );
};

export default DrawerBankAccount;
