import { ReactNode } from "react";
import clx from "classnames";

import { Box } from "@material-ui/core";

import useContentDrawerStyles from "./ContentDrawerStyles";

interface Props {
  children: ReactNode;
  contentClassName?: string;
}
const ContentDrawer = ({ children, contentClassName }: Props) => {
  const classes = useContentDrawerStyles();

  return (
    <Box
      data-testid="content-test"
      className={clx(classes.contentDrawer, contentClassName)}
    >
      {children}
    </Box>
  );
};

export default ContentDrawer;
