import { Box, SvgIcon, Typography } from "@material-ui/core";
import { format } from "date-fns";
import clx from "classnames";
import { IoConstructOutline as RepairmentIcon } from "@houm-com/ui-icons";

import { IRepairsRequest } from "apps/tenant/hooks/utils/types";

import REPAIRMENT_TYPE_LABEL from "./constants";
import useRepairRequestCardStyles from "./RepairRequestCardStyles";

type Props = {
  repairRequest: IRepairsRequest;
};

const RequestCard = ({ repairRequest }: Props) => {
  const classes = useRepairRequestCardStyles();
  const repairmentTypeLabel =
    REPAIRMENT_TYPE_LABEL[repairRequest.repairmentType];
  return (
    <Box className={classes.card}>
      <Box className={classes.iconContainer}>
        <SvgIcon className={classes.icon}>
          <RepairmentIcon />
        </SvgIcon>
      </Box>
      <Box className={classes.textContainer}>
        <Typography className={classes.text}>
          <strong>{repairmentTypeLabel}</strong>
        </Typography>
        <Typography className={clx(classes.cardTex, classes.text)}>
          Creado: {format(repairRequest.createdAt, "dd/MM/yyyy")}
        </Typography>
        <Typography className={clx(classes.observation, classes.text)}>
          {repairRequest.observation}
        </Typography>
      </Box>
    </Box>
  );
};

export default RequestCard;
