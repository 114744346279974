import Header from "Components/Layout/GlobalHeader";

import DefaultTenantLayout from "apps/lead/layout/DefaultTenantLayout";
import StartEvaluationLanding from "./StartEvaluationLanding";

export default function () {
  return (
    <DefaultTenantLayout>
      <Header />
      <StartEvaluationLanding />
    </DefaultTenantLayout>
  );
}
