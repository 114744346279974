import { makeStyles, Theme } from "@material-ui/core";

const useCustomLoiHeaderStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.appBar,
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 1rem",
    height: "60px",
  },
  backButton: {
    color: "#4C6977",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
}));

export default useCustomLoiHeaderStyles;
