import { makeStyles, Theme } from "@material-ui/core";

const usePrintButtonStyles = makeStyles<Theme>((theme) => ({
  downloadButton: {
    display: "flex",
    gap: theme.spacing(1.5),
    color: theme.palette.primary.main,
    cursor: "pointer",
    textTransform: "none",
    height: "fit-content",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonLabel: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    fontWeight: 700,
  },
}));

export default usePrintButtonStyles;
