/* eslint-disable max-lines */
import { useEffect, useRef, memo, useState } from "react";
import currency from "@houm-com/formats/currency";
import mapboxgl from "mapbox-gl";

import { MAPBOX_API_TOKEN } from "env";

import "mapbox-gl/dist/mapbox-gl.css";
import useMapStyles from "./MapStyles";

mapboxgl.accessToken = MAPBOX_API_TOKEN;

interface Props {
  properties?: any;
  value: [lng: number, lat: number];
  defaultValue: [lng: number, lat: number];
  hasMarker?: boolean;
  zoom?: number;
}

const Map = ({ properties = [], value, defaultValue, zoom = 12 }: Props) => {
  const classes = useMapStyles();
  const [coordinates, setCoordinates] = useState(value);
  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const mainMarkerRef = useRef(null);

  useEffect(() => {
    if (defaultValue) {
      setCoordinates(defaultValue);
      mapRef.current?.setCenter(coordinates);
      mainMarkerRef.current?.setLngLat(coordinates);
    }
  }, [coordinates, defaultValue]);

  useEffect(() => {
    if (value) {
      setCoordinates(value);
      mapRef.current?.setCenter(coordinates);
      mainMarkerRef.current?.setLngLat(coordinates);
    }
  }, [value, coordinates]);

  useEffect(() => {
    //Creates a new map instance with the given options
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style:
        "mapbox://styles/equipoarriendoasegurado/cko1fvdq50vag17pf5emgaj86",
      center: coordinates,
      zoom,
      preserveDrawingBuffer: true,
    });

    //Adds a control to the map to allow the user to zoom the map
    mapRef.current = map;
    map.addControl(new mapboxgl.NavigationControl(), "top-left");

    map.on("load", () => {
      //On load

      //Parse the data to add the markers to the map
      const parsedProperties = properties
        .filter(
          (item) =>
            (item.latitude !== 0 && item.longitude !== 0) ||
            (item.lat !== 0 && item.lng !== 0)
        )
        .map((item, index) => ({
          coordinates: [item.longitude || item.lng, item.latitude || item.lat],
          price: item.price,
          cc: item.cc === "CLF" ? "UF" : item.cc,
          index: index + 1,
        }));

      //Create a html element each item with className otherPropsPin to be used as a custom marker (Other properties)
      parsedProperties.forEach((marker) => {
        const otherPropsPin = document.createElement("div");
        const idContainer = document.createElement("div");
        idContainer.appendChild(document.createTextNode(`${marker.index}`));
        otherPropsPin.appendChild(idContainer);
        otherPropsPin.appendChild(
          document.createTextNode(currency(marker.price, marker.cc))
        );
        otherPropsPin.className = "otherPropsPin";
        idContainer.className = "idNumber";
        new mapboxgl.Marker(otherPropsPin)
          .setLngLat(marker.coordinates)
          .addTo(map);
      });

      //add a marker to the map at the given coordinates
      const mainPin = document.createElement("div");
      mainPin.className = "mainPin";
      const mainMarker = new mapboxgl.Marker(mainPin)
        .setLngLat(coordinates)
        .addTo(map);
      mainMarkerRef.current = mainMarker;
    });
  }, [coordinates, properties, zoom]);

  return (
    <div className={classes.mapWrapper} id="map">
      <div
        ref={(el) => {
          mapContainer.current = el;
        }}
      />
    </div>
  );
};

export default memo(Map, (prev, next) => prev.value[1] !== next.value[1]);
