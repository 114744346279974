import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles<Theme>((theme) => ({
  boxCountry: {
    width: 72,
    height: 56,
    border: "1px solid #A0B1B9",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    paddingTop: theme.spacing(1),
    background: "#fff",
  },
  selected: {
    background: "#d7e1e5",
  },
  countryName: {
    marginTop: theme.spacing(0.5),
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.375em",
    color: theme.palette.grey[900],
  },
  inputCountry: {
    visibility: "hidden",
  },
  label: {
    display: "flex",
  },
}));

export default useStyles;
