import { Box, Typography } from "@material-ui/core";

import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji";

import useNoVisitsCardStyles from "./NoVisitsCardStyles";

const NoVisitsCard = () => {
  const classes = useNoVisitsCardStyles();

  return (
    <Box className={classes.noVisitsCardRoot}>
      <Emoji
        className={classes.icon}
        emojiIcon="📅"
        ariaLabel="calendar icon"
      />
      <Typography className={classes.title}>
        No tienes visitas agendadas hoy
      </Typography>
      <Typography className={classes.text}>
        Revisa las fechas marcadas con un punto rojo para ver las que visitas
        que tienes agendadas
      </Typography>
    </Box>
  );
};

export default NoVisitsCard;
