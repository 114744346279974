import {
  MainPropertyPropertyAnalysis,
  Schedules,
} from "apps/landlord/services/propertyService/type";
import {
  parseHoumOwner,
  parseMarketPlaceStats,
  parsePhoto,
  parsePropertyDetails,
  parsePropertySalesDetails,
} from "../utils/parsers";

const parseSchedules = (schedules: Schedules[]) =>
  schedules.map((schedule) => ({
    id: schedule.id,
    createdAt: schedule.created_at,
    updatedAt: schedule.updated_at,
    completed: schedule.completed,
    date: schedule.date,
    beginDate: schedule.begin_date,
    endDate: schedule.end_date,
    comment: schedule.comment,
    status: schedule.status,
    uid: schedule.uid,
    cancelReason: schedule.cancel_reason,
    stateProgress: schedule.state_progress,
    actionSource: schedule.action_source,
    appraiser: {
      id: schedule?.appraiser.id,
      email: schedule?.appraiser.email,
      name: schedule?.appraiser.name,
    },
  }));

const parseMainPropertyData = (property: MainPropertyPropertyAnalysis) => ({
  id: property.id,
  address: property.address,
  marketplaceStats: parseMarketPlaceStats(property.marketplace_stats),
  photos: parsePhoto(property.photos),
  propertyDetails: parsePropertyDetails(property.property_details),
  salesDetails: parsePropertySalesDetails(property.sales_details),
  forRental: property.for_rental,
  forSale: property.for_sale,
  houmOwner: property?.houm_owner && parseHoumOwner(property.houm_owner),
  vitruvioSuggest: property.vitruvio_suggest,
  vitruvioSuggestClf: property.vitruvio_suggest_clf,
  marketplaceUrl: property.marketplace_url,
  publicationDate: property.publication_date,
  portal: property.portal,
  uid: property.uid,
  country: property.country,
  schedules: parseSchedules(property.schedules),
});

export default parseMainPropertyData;
