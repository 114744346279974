import { Box, Typography } from "@material-ui/core";

import usePlainFieldStyles from "./PlainFieldStyles";

interface Props {
  label: string;
  value: string;
}

const PlainField = ({ label, value }: Props) => {
  const classes = usePlainFieldStyles();

  return (
    <Box className={classes.plainFieldRoot}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </Box>
  );
};

export default PlainField;
