export const MOBILE_NAVBAR_HEIGHT = 52;
export const DESK_NAVBAR_HEIGHT = 72;
export const MOBILE_HELP_CENTER_HEIGHT = 132;
export const DESKTOP_CONTENT_MAX_WIDTH = 1280;
export const ERROR_HANDLER_MESSAGES = {
  contracts: {
    NO_CONTRACT_FOUND:
      "No hemos encontrado un contrato activo para este usuario",
  },
  UNHANDLED_ERROR: "Ha ocurrido un error inesperado",
};
