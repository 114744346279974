import { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";

import { Box } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

import useRepairsRequestSteps, {
  STEPS,
} from "apps/tenant/hooks/useRepairsRequestSteps";
import useRepairRequestAction from "apps/tenant/hooks/useRepairRequestAction";
import useContract from "apps/tenant/hooks/useContract";
import Stepper from "apps/tenant/components/Stepper";
import Spinner from "Components/Shared/partials/Spinner";
import RepairVisit from "./components/RepairVisit";

import useRepairsRequestStyles from "./RepairsRequestStyles";
import RepairConfirmation from "./components/RepairConfirmation";
import RepairSelection from "./components/RepairSelection";

interface Props {
  closeDrawer?: () => void;
}

const RepairsRequest = ({ closeDrawer }: Props) => {
  const { data: contractData } = useContract();
  const {
    currentStep,
    handleNextStep,
    showStepper,
    currentStepperIndex,
    handleError,
  } = useRepairsRequestSteps(closeDrawer);
  const {
    setVisitHours,
    visitHours,
    requestForm,
    setRequestForm,
    submitData,
    isLoading,
  } = useRepairRequestAction({
    onSuccessHandler: handleNextStep,
    onErrorHandler: handleError,
  });

  //Scroll to top when step changes
  const containerRef = useRef(null);
  useEffect(() => {
    containerRef.current.scrollIntoView();
  }, [currentStep]);

  const classes = useRepairsRequestStyles();
  const stepComponent = () => {
    if (isLoading) return <Spinner />;
    switch (currentStep) {
      case STEPS.Selection:
        return (
          <RepairSelection
            handleNext={handleNextStep}
            setRepairForm={setRequestForm}
            repairForm={requestForm}
          />
        );
      case STEPS.Visit:
        return (
          <RepairVisit
            handleNext={submitData}
            setVisitHours={setVisitHours}
            visitHours={visitHours}
            country={contractData?.country}
          />
        );
      case STEPS.Confirmation:
        return (
          <RepairConfirmation
            handleNext={handleNextStep}
            requestForm={requestForm}
            visitHours={visitHours}
            country={contractData?.country}
          />
        );

      default:
        return (
          <RepairSelection
            handleNext={handleNextStep}
            setRepairForm={setRequestForm}
            repairForm={requestForm}
          />
        );
    }
  };
  return (
    <div className={classes.scrollContainer} ref={containerRef}>
      <Box className={classes.drawerHeader}>
        {showStepper && <Stepper steps={2} currentStep={currentStepperIndex} />}
        {!isMobile && (
          <Box className={classes.iconContainer} onClick={closeDrawer}>
            <Close />
          </Box>
        )}
      </Box>
      {stepComponent()}
    </div>
  );
};

export default RepairsRequest;
