import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import { ButtonGroup, Typography } from "@material-ui/core";

import { capitalizeString } from "utils";

import useStyles from "./TypeSelectorStyles";

const MODES = ["rent", "sale"];

const FavoritesTypeSelector = () => {
  const classes = useStyles();
  const [indexSelected, setIndexSelected] = useState(0);
  const history = useHistory();
  const { t, ready } = useTranslation("shared");

  const changeParams = useCallback(
    (index) => {
      const currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set("mode", MODES[index]);
      history.push(
        `${window.location.pathname}?${currentUrlParams.toString()}`
      );
    },
    [history]
  );

  const handleClick = (index) => {
    changeParams(index);
    setIndexSelected(index);
  };

  useEffect(() => {
    if (ready) changeParams(indexSelected);
  }, [changeParams, indexSelected, ready]);

  return (
    <ButtonGroup
      variant="outlined"
      className={classes.group}
      classes={{
        groupedHorizontal: classes.groupedHorizontal,
      }}
    >
      {MODES?.map((tab, index) => (
        <span
          key={tab}
          className={clsx({
            [classes.button]: true,
            [classes.buttonSelected]: indexSelected === index,
          })}
          onClick={() => handleClick(index)}
        >
          <Typography
            className={clsx({
              [classes.labelSelected]: indexSelected === index,
              [classes.label]: true,
            })}
          >
            {capitalizeString(t(tab))}
          </Typography>
        </span>
      ))}
    </ButtonGroup>
  );
};

export default FavoritesTypeSelector;
