import { makeStyles, Theme } from "@material-ui/core";

const usePaymentHeaderStyles = makeStyles<Theme>((theme) => ({
  header: {
    width: "100%",
    padding: theme.spacing(4, 2),
    boxShadow: "3px 4px 15px rgba(38, 50, 56, 0.15)",
    backgroundColor: "white",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5),
    },
  },
  gridItems: {
    gap: 8,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.4,
  },
  dateLabel: {
    fontSize: theme.typography.pxToRem(14),
    color: "#7D8488",
    lineHeight: 1.57,
  },
  date: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[900],
    lineHeight: 1.57,
  },
  planContainer: {
    backgroundColor: "#F7F9F9",
    padding: theme.spacing(0.75, 1),
  },
  planLabel: {
    color: "#7D8488",
  },
  backButton: {
    margin: 0,
    marginBottom: theme.spacing(0.5),
    "& > p, path": {
      fontWeight: 700,
    },
  },
}));

export default usePaymentHeaderStyles;
