import { makeStyles, Theme } from "@material-ui/core/styles";

const useInfoContainerStyles = makeStyles((theme: Theme) => ({
  textInfo: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "1.57em",
  },
  infoContainer: {
    borderRadius: 40,
    border: `1px solid ${theme.palette.grey[75]}`,
    padding: theme.spacing(0.5, 1),
    width: "fit-content",
  },
}));

export default useInfoContainerStyles;
