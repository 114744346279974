import DefaultTenantLayout from "apps/lead/layout/DefaultTenantLayout";
import EvaluatorForm from "./EvaluatorForm";

export default function () {
  return (
    <DefaultTenantLayout>
      <EvaluatorForm />
    </DefaultTenantLayout>
  );
}
