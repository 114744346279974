import { makeStyles, Theme } from "@material-ui/core";

const useMapSelectStyles = makeStyles<Theme>((theme) => ({
  mapContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: 280,
    flex: 1,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      flex: "auto",
    },
  },
  roundedMap: {
    borderRadius: 100,
  },
  helpOverlay: {
    position: "absolute",
    zIndex: 2,
    margin: "auto",
    bottom: 32,
    left: 16,
    width: "calc(100% - 32px)",
    height: "auto",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: "white",
    border: "1px solid #DFE5E8",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export default useMapSelectStyles;
