import { makeStyles, Theme } from "@material-ui/core";

const useMainMenuStyles = makeStyles<Theme>((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey[75]}`,
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      borderTop: "none",
      paddingTop: 0,
    },
  },
}));

export default useMainMenuStyles;
