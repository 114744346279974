import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  isMainPlan?: boolean;
  isHorizontal?: boolean;
}

const useSubscriptionPlanCardStyles = makeStyles<Theme, Props>((theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(14),
    textAlign: "left",
    color: theme.palette.grey[900],
    marginBottom: theme.spacing(1),
  },
  benefitsContainer: {
    marginBottom: ({ isHorizontal }) => (isHorizontal ? 0 : theme.spacing(1.5)),
    padding: theme.spacing(1.5, 2),
    flexWrap: ({ isHorizontal }) => (isHorizontal ? "nowrap" : "wrap"),
    display: "flex",
    flex: 1,
    flexDirection: ({ isHorizontal }) => (isHorizontal ? "row" : "column"),
  },
  benefitsChunk: {
    flexWrap: "nowrap",
    display: "flex",
    flexDirection: "column",
    padding: ({ isHorizontal }) => (isHorizontal ? theme.spacing(2, 0.5) : 0),
    [theme.breakpoints.up("md")]: {
      flex: 1,
    },
  },
  benefitItem: {
    padding: theme.spacing(1.5, 0.5),
  },
  listItemText: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[900],
    lineHeight: 1.3,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  price: {
    fontSize: "0.875rem",
    fontWeight: 700,
    whiteSpace: "pre-wrap",
  },
  listIcon: {
    fontSize: theme.typography.pxToRem(20),
    marginRight: theme.spacing(1),
  },
  listIcon_check: {
    color: theme.palette.primary.main,
  },
  cardTitle: {
    color: theme.palette.grey[900],
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    paddingBottom: theme.spacing(1),
    minWidth: 280,
  },
  cardTitlePremium: {
    color: theme.palette.primary.main,
  },
  cardSubtitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    color: theme.palette.grey[500],
    paddingRight: ({ isMainPlan }) => (isMainPlan ? theme.spacing(7) : 0),
  },
  seeMore: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  seeMore_ancor: {
    "&:hover": {
      color: theme.palette.primary.dark,
    },
    cursor: "pointer",
  },
  cardHeader: {
    backgroundColor: "#F7F8FC",
    padding: theme.spacing(1.75, 2),
    marginBottom: ({ isHorizontal }) => (isHorizontal ? 0 : theme.spacing(1.5)),
    minHeight: 108,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      maxWidth: ({ isHorizontal }) => (isHorizontal ? 250 : "100%"),
    },
  },
  cardFooter: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1),
    display: "flex",
    flex: 1,
    justifyContent: "center",
    gap: 6,
    flexDirection: "column",
    maxWidth: "100%",
    minWidth: 250,
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      maxWidth: ({ isHorizontal }) => (isHorizontal ? "20%" : "100%"),
    },
  },
  footerText: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  cardContainer: {
    background: "white",
    maxWidth: ({ isHorizontal }) => (isHorizontal ? 1420 : 346),
    borderRadius: "8px",
    border: `1px solid ${theme.palette.grey[50]}`,
    padding: ({ isHorizontal }) =>
      isHorizontal ? 0 : theme.spacing(0, 0, 1.5),
    position: "relative",
    margin: theme.spacing(2, "auto"),
    width: "100%",
    flexBasis: "auto",
    boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.1)",
    flexDirection: ({ isHorizontal }) => (isHorizontal ? "row" : "column"),
    [theme.breakpoints.up("md")]: {
      margin: ({ isHorizontal }) =>
        isHorizontal ? theme.spacing(2, "auto") : 0,
      boxShadow: "unset",
    },
  },
  divider: {
    backgroundColor: theme.palette.grey[50],
  },
  priceDescription: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[900],
    textAlign: "left",
    marginBottom: theme.spacing(2),
    width: "90%",
    [theme.breakpoints.up("md")]: {
      width: "unset",
      marginBottom: ({ isHorizontal }) => (isHorizontal ? theme.spacing(2) : 0),
    },
  },
  wrap: {
    width: "100%",
    display: "flex",
    justifyContent: "left",
  },
  diagonalTag_text: {
    color: "white",
    fontSize: theme.typography.pxToRem(14),
    textAlign: "center",
    paddingTop: theme.spacing(0.25),
    fontWeight: 800,
  },
  diagonalTag: {
    transform: "translate(20%) rotate(45deg)",
    borderBottom: `24px solid ${theme.palette.primary.main}`,
    borderLeft: "24px solid transparent",
    borderRight: "24px solid transparent",
    position: "absolute",
    width: 180,
    right: 0,
    top: 42,
    height: 0,
  },
  buttonContainer: {
    margin: theme.spacing(2, "auto", 0.5),
  },
  contactUsContainer: {
    margin: theme.spacing(3, "auto"),
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > p, a": {
      fontSize: theme.typography.pxToRem(14),
      margin: theme.spacing(0, 1),
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      margin: theme.spacing(3, 0),
      textAlign: "left",
      "& > p, a": {
        fontSize: theme.typography.pxToRem(18),
        maxWidth: 400,
      },
    },
  },
  email: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  houmLogo: {
    margin: theme.spacing(0, 3, 5),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 10, 0, 20),
    },
  },
}));

export default useSubscriptionPlanCardStyles;
