const statuses = {
  paid: "Pagado",
  unpaid: "Por pagar",
  past_due: "Mora",
  failure: "Error",
  invalid_code: "Revisa tu información",
  no_data: "Carga tu información",
  in_process: "En proceso",
  pending: "Pendiente de consulta",
  validating_code: "Validando el código",
};

export default statuses;
