import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
  },
  boxesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  revContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
      gap: theme.spacing(5),
    },
  },
}));

export default useStyles;
