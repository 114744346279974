import { compareDesc } from "date-fns";

import { IMovement, MovementStatus } from "apps/tenant/models/Movement";
import {
  IContractMovements,
  IMovementResponse,
} from "apps/tenant/services/paymentService/types";

export const getStatus = (data: IMovementResponse): MovementStatus => {
  if (data.status === "PAID") return "paid";
  if (compareDesc(new Date(data.agreed_date), new Date()) === 1)
    return "overdue";
  return "pending";
};

export const parseContractMovements = (
  data: IMovementResponse[]
): IMovement[] =>
  data.map((mv) => ({
    additionalAmount: mv.additionals,
    agreedDate: new Date(mv.agreed_date),
    currency: mv.currency,
    date: mv.date ? new Date(mv.date) : undefined,
    id: mv.movement_id,
    method: mv.method,
    originalAmount: mv.original_amount,
    paymentLink: mv.payment_link,
    status: getStatus(mv),
    totalAmount: mv.total_amount,
  }));

export const filterByConcept = (
  data: IContractMovements
): IMovementResponse[] => data.movements.filter((mv) => mv.concept === "LEASE");
