import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  idAndPrice: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(1),
  },
  id: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    textDecoration: "underline",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 1),
      fontSize: theme.typography.pxToRem(12),
    },
  },
}));

export default useStyles;
