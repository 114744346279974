import Button from "@houm-com/ui/Button";
import { useFeatureManager } from "@houm-com/feature-manager";
import Spinner from "@houm-com/ui/Spinner";

import { setStep } from "apps/payments/context/rentPaymentContext/rentPaymentActions";
import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import CallToActionDrawer from "apps/ui/CustomDrawer/CallToActionDrawer";
import useWidgetFintoc from "apps/payments/hooks/useWidgetFintoc";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import ContentDrawer from "apps/ui/CustomDrawer/ContentDrawer";

import { classes } from "./FintocDrawerStyles";
import { Subtitle } from "../typography";

const FintocDrawer = () => {
  const { onHandleDrawer, setCurrentStep } = useCustomDrawer();
  const { dispatch, state } = useRentPayment();
  const { status: statusFintocInformation, data: dataFintocInformation } =
    useFeatureManager("fintoc_information", { marketCode: state.country });
  const { refetch: getFintocWidget, isFetching } = useWidgetFintoc({
    country: state.country,
    movementId: state.paymentData?.movementId,
    onHandleDrawer,
    onHandleSuccess: () => dispatch(setStep("landing")),
  });

  const isFintocInformationFlag =
    statusFintocInformation === "success" && dataFintocInformation?.enabled;

  return (
    <>
      <ContentDrawer contentClassName={classes.drawerContainer}>
        <div className={classes.drawerTitleContainer}>
          <h1 className={classes.title}>Información importante</h1>
          <Subtitle className={classes.subtitle}>
            Ten en cuenta la siguiente información para hacer transacciones con
            Fintoc
          </Subtitle>
        </div>
        {statusFintocInformation === "loading" && (
          <Spinner
            variant="primary"
            size="xl"
            classNameContainer={classes.spinner}
          />
        )}
        <div className={classes.informationContainer}>
          {isFintocInformationFlag &&
            dataFintocInformation?.content.map((item) => (
              <div key={item.bankName} className={classes.bankContainer}>
                <img
                  src={item.bankLogo}
                  alt={item.bankName}
                  className={classes.bankLogo}
                />
                <div>
                  <p className={classes.bankName}>{item.bankName}</p>
                  <p className={classes.bankDisclaimer}>{item.disclaimer}</p>
                </div>
              </div>
            ))}
        </div>
      </ContentDrawer>
      <CallToActionDrawer>
        <Button
          variant="tertiary"
          size="md"
          onClick={() => setCurrentStep("paymentPlatforms")}
        >
          Atrás
        </Button>
        <Button
          variant="primary"
          size="md"
          type="button"
          onClick={() => getFintocWidget()}
          isLoading={isFetching}
        >
          Pagar
        </Button>
      </CallToActionDrawer>
    </>
  );
};

export default FintocDrawer;
