import { BaseOption } from "apps/ui/formComponents/Select/Select";
import { Locale } from "models/Countries";
import { accountTypes } from "utils";
import { localeToCountry } from "utils/countryCode";

export const getAccountTypeOptions = (country: Locale): BaseOption[] =>
  accountTypes[localeToCountry[country]].map((accountType) => ({
    label: accountType.name,
    value: accountType.code,
  }));
