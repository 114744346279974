import { AxiosResponse } from "axios";
import { SALES_URL } from "env";
import { Locale } from "models/Countries";
import { createHttp } from "../helper";
import {
  BidderDetail,
  GetNegotiationAdmin,
  LetterOfIntentDetail,
  LOIList,
  OwnerDetail,
  ReplyOffer,
  BidFees,
  NegotiationProperty,
} from "./models";
import { HasAcceptedNegotiationResponse } from "./types";

export interface NegotiationService {
  getNegotiations: (
    country: Locale,
    propertyId: string,
    token: string
  ) => Promise<AxiosResponse<any>>;
  get: (
    country: Locale,
    negotiationId: string,
    token: string
  ) => Promise<AxiosResponse<any>>;
  uploadLoiDocuments: (
    token: string,
    country: Locale,
    negotiationId: string,
    data: any
  ) => Promise<AxiosResponse<any>>;
  hasOpenNegotiations: (
    country: Locale,
    propertyId: string,
    token: string
  ) => Promise<AxiosResponse<any>>;
  hasAcceptedNegotiation: (
    country: Locale,
    propertyId: string,
    token: string
  ) => Promise<AxiosResponse<HasAcceptedNegotiationResponse>>;
  replyOffer: (
    data: ReplyOffer,
    country: Locale,
    offerId: string,
    token: string
  ) => Promise<AxiosResponse<any>>;
  changeFileStatus: (
    token: string,
    country: Locale,
    loiId: string,
    status: string
  ) => Promise<AxiosResponse<any>>;
  getAllLOIs: (
    country: Locale,
    page: number,
    limit: number,
    token: string
  ) => Promise<AxiosResponse<LOIList>>;
  uploadLoiFile: (
    token: string,
    country: Locale,
    loiId: string,
    data: FormData
  ) => Promise<AxiosResponse<any>>;
  editOwner: (
    data: OwnerDetail,
    country: Locale,
    userId: string,
    token: string
  ) => Promise<AxiosResponse<any>>;
  getLOI: (
    country: Locale,
    loiId: string,
    token: string
  ) => Promise<AxiosResponse<LetterOfIntentDetail>>;
  getNegotiationsBidders: (
    country: Locale,
    loiId: string,
    token: string
  ) => Promise<AxiosResponse<BidderDetail[]>>;
  getNegotiationsOwners: (
    country: Locale,
    loiId: string,
    token: string
  ) => Promise<AxiosResponse<OwnerDetail[]>>;
  getNegotiationsAdmin: (
    country: Locale,
    propertyId: string,
    token: string
  ) => Promise<AxiosResponse<GetNegotiationAdmin[]>>;
  rejectOfferAdmin: (
    country: Locale,
    offerId: string,
    data: ReplyOffer,
    token: string
  ) => Promise<AxiosResponse<any>>;
  acceptOfferAdmin: (
    country: Locale,
    offerId: string,
    data: FormData,
    token: string
  ) => Promise<AxiosResponse<any>>;
  editBidFees: (
    data: BidFees,
    country: Locale,
    bidId: string,
    token: string
  ) => Promise<AxiosResponse<any>>;
  getNegotiationProperty: (
    country: Locale,
    negotiationId: string,
    token: string
  ) => Promise<AxiosResponse<NegotiationProperty>>;
  editNegotiationProperty: (
    data: NegotiationProperty,
    country: Locale,
    negotiationId: string,
    token: string
  ) => Promise<AxiosResponse<void>>;
}

const negotiationService = (): NegotiationService => {
  const getNegotiations: NegotiationService["getNegotiations"] = (
    country,
    propertyId,
    token
  ) =>
    createHttp(token, SALES_URL).get(
      `/bidding/${country}/properties/${propertyId}/negotiations`
    );
  const getNegotiationsAdmin: NegotiationService["getNegotiationsAdmin"] = (
    country,
    propertyId,
    token
  ) =>
    createHttp(token, SALES_URL).get(
      `/bidding/${country}/properties/${propertyId}/executive_negotiations`
    );
  const get: NegotiationService["get"] = (country, negotiationId, token) =>
    createHttp(token, SALES_URL).get(
      `/bidding/${country}/negotiations/${negotiationId}`
    );
  const uploadLoiDocuments: NegotiationService["uploadLoiDocuments"] = (
    token,
    country,
    negotiationId,
    data
  ) =>
    createHttp(token, SALES_URL).post(
      `/loi/${country}/negotiations/${negotiationId}/owner`,
      data
    );
  const hasOpenNegotiations: NegotiationService["hasOpenNegotiations"] = (
    country,
    propertyId,
    token
  ) =>
    createHttp(token, SALES_URL).get(
      `/bidding/${country}/properties/${propertyId}/has_open_negotiations`
    );
  const hasAcceptedNegotiation: NegotiationService["hasAcceptedNegotiation"] = (
    country,
    propertyId,
    authToken
  ) =>
    createHttp(authToken, SALES_URL).get(
      `/loi/${country}/properties/${propertyId}/can_complete_loi`
    );
  const replyOffer: NegotiationService["replyOffer"] = (
    data,
    country,
    offerId,
    token
  ) =>
    createHttp(token, SALES_URL).patch(
      `/bidding/${country}/bids/${offerId}`,
      data
    );
  const getLOI: NegotiationService["getLOI"] = (country, loiId, token) =>
    createHttp(token, SALES_URL).get(`loi/${country ?? "cl"}/${loiId}`);
  const getNegotiationsBidders: NegotiationService["getNegotiationsBidders"] = (
    country,
    loiId,
    token
  ) =>
    createHttp(token, SALES_URL).get(
      `bidding/${country ?? "cl"}/negotiations/${loiId}/bidders`
    );
  const getNegotiationsOwners: NegotiationService["getNegotiationsOwners"] = (
    country,
    loiId,
    token
  ) =>
    createHttp(token, SALES_URL).get(
      `bidding/${country ?? "cl"}/negotiations/${loiId}/owners`
    );
  const getAllLOIs: NegotiationService["getAllLOIs"] = (
    country,
    page,
    pageSize,
    token
  ) =>
    createHttp(token, SALES_URL).get(
      `/loi/${country}/all?page=${page}&page_size=${pageSize}`
    );
  const editOwner = (data, country, userId, token) =>
    createHttp(token, SALES_URL).patch(`/owner/${country}/${userId}`, data);
  const uploadLoiFile = (token, country, loiId, file) =>
    createHttp(token, SALES_URL).post(
      `/loi/${country}/${loiId}/upload_file`,
      file
    );
  const changeFileStatus = (token, country, documentId, status) =>
    createHttp(token, SALES_URL).patch(
      `/loi/${country}/document/${documentId}/status?status=${status}`
    );
  const editBidFees = (data, country, bidId, token) =>
    createHttp(token, SALES_URL).patch(
      `/bidding/${country}/bids/${bidId}/fees`,
      data
    );
  const getNegotiationProperty: NegotiationService["getNegotiationProperty"] = (
    country,
    negotiationId,
    token
  ) =>
    createHttp(token, SALES_URL).get(
      `bidding/${country ?? "cl"}/negotiations/${negotiationId}/property`
    );
  const editNegotiationProperty = (data, country, negotiationId, token) =>
    createHttp(token, SALES_URL).patch(
      `/bidding/${country ?? "cl"}/negotiations/${negotiationId}/property`,
      data
    );
  const rejectOfferAdmin = (country, offerId, data, token) =>
    createHttp(token, SALES_URL).patch(
      `/bidding/${country}/bids/${offerId}/reject`,
      data
    );

  const acceptOfferAdmin = (country, offerId, data, token) =>
    createHttp(token, SALES_URL).patch(
      `/bidding/${country}/bids/${offerId}/accept`,
      data
    );

  return {
    getNegotiations,
    getNegotiationsAdmin,
    get,
    uploadLoiDocuments,
    hasOpenNegotiations,
    hasAcceptedNegotiation,
    replyOffer,
    getLOI,
    getNegotiationsBidders,
    getNegotiationsOwners,
    getAllLOIs,
    editOwner,
    uploadLoiFile,
    changeFileStatus,
    editBidFees,
    getNegotiationProperty,
    editNegotiationProperty,
    rejectOfferAdmin,
    acceptOfferAdmin,
  };
};

export default negotiationService;
