import { makeStyles, Theme } from "@material-ui/core";

const useServicesRequestFormStyles = makeStyles((theme: Theme) => ({
  form: {
    marginTop: theme.spacing(2),
    color: theme.palette.grey[900],
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  label: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
    "&::after": {
      content: '"*"',
      color: "#FF452B",
      marginLeft: theme.spacing(0.5),
    },
  },
  textField: {
    backgroundColor: "#EFF2F3",
    width: "100%",
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
  },
}));

export default useServicesRequestFormStyles;
