import * as yup from "yup";
import _ from "lodash";

import { PROPERTY_TYPES } from "apps/landlord/pages/CreateProperty/context/constants";

const REQUIRED_TEXT = "Este campo es obligatorio";

export default yup.object({
  propertyType: yup.string().nullable().required(REQUIRED_TEXT),
  address: yup.object().nullable().required(REQUIRED_TEXT),
  date: yup.string().required(REQUIRED_TEXT),
  service: yup.string().nullable().required(REQUIRED_TEXT),
  propertyStatus: yup
    .string()
    .nullable()
    .when("address", {
      is: (val: {
        address_components: {
          long_name: string;
          short_name: string;
          types: string[];
        }[];
      }) => {
        if (!val) return false;
        return (
          val.address_components.find((c) => c.types.includes("country"))
            ?.short_name === "CL"
        );
      },
      then: yup.string().nullable().required(REQUIRED_TEXT),
    }),
  propertyNumber: yup.string().when("propertyType", {
    is: (val: { value: string; label: string }) =>
      _.isEqual(val, PROPERTY_TYPES.flat.value),
    then: yup.string().required(REQUIRED_TEXT),
  }),
});
