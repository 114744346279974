import { FC, SVGProps } from "react";

const BalconyIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.88179 6.97988C8.80642 5.84923 8.33908 3.67837 7.07275 4.04018M9.33407 6.75374C9.25869 6.2261 9.28884 5.17082 10.0125 5.17082M10.0124 10.1457L10.7523 7.55638C10.8348 7.26746 10.6179 6.97987 10.3174 6.97987H7.85832C7.57039 6.97987 7.35577 7.24535 7.4161 7.52689L7.97727 10.1457M20.03 18.7387C20.5494 17.9095 21.3212 15.7538 20.2526 13.7638C19.958 13.1654 19.6342 11.6167 20.6737 10.1787C20.6895 10.1569 20.7039 10.1338 20.7151 10.1093C20.9289 9.64205 21.0708 8.68904 20.03 8.33666M4.9698 18.7387C4.45035 17.9095 3.6786 15.7538 4.74718 13.7638C5.04175 13.1654 5.36555 11.6167 4.32605 10.1787C4.31027 10.1569 4.29586 10.1338 4.28465 10.1093C4.07087 9.64205 3.92901 8.68904 4.9698 8.33666M4.5853 10.1457H20.6406M12.4999 10.1457V18.7387M17.4748 10.1457C17.0225 10.9749 16.2989 12.9045 17.0225 13.9899C17.5502 14.8945 18.1984 17.1105 16.5703 18.7387M7.43057 10.1457C7.88283 10.9749 8.60645 12.9045 7.88283 13.9899C7.35521 14.8945 6.70698 17.1105 8.33513 18.7387M3.68078 21H21.319C21.5688 21 21.7713 20.7975 21.7713 20.5477V19.191C21.7713 18.9412 21.5688 18.7387 21.319 18.7387H3.68078C3.431 18.7387 3.22852 18.9412 3.22852 19.191V20.5477C3.22852 20.7975 3.431 21 3.68078 21Z"
      stroke="#263238"
      strokeWidth={1.6}
      strokeLinecap="round"
    />
  </svg>
);
export default BalconyIcon;
