import clx from "classnames";
import { useHistory, useRouteMatch } from "react-router-dom";

import { IoWarningOutline as WarningIcon } from "@houm-com/ui-icons";
import { ArrowForward } from "@material-ui/icons";
import { Box, Grid, Typography } from "@material-ui/core";

import { Inspection } from "hooks/inspectionReports/types";
import { getResizeImageURL } from "Components/Shared/InspectionReportDocument/utils";
import { getDate, getRating } from "./utils";
import useInspectionReportCardStyles from "./InspectionReportCardStyles";

interface Props {
  inspectionReport: Inspection;
  cardClassname?: string;
  imageClassname?: string;
  dateClassname?: string;
}

export default function InspectionReportCard({
  inspectionReport,
  cardClassname,
  imageClassname,
  dateClassname,
}: Props) {
  const classes = useInspectionReportCardStyles();
  const imageURL = inspectionReport.spaceQualifications[0]?.photos[0];
  const history = useHistory();
  const match = useRouteMatch();
  const rating = getRating(inspectionReport);
  const isAbleToComment = inspectionReport.commentsAllowed;

  return (
    <Grid
      onClick={() => {
        history.push(`${match.url}/${inspectionReport.id}`);
      }}
      item
      container
      className={clx(cardClassname, classes.inspectionReportCardContainer)}
    >
      <img
        className={clx(imageClassname, classes.inspectionReportImage)}
        src={getResizeImageURL(imageURL)}
        alt={`foto de la inspección del día ${inspectionReport.createdAt}`}
      />
      <Box className={classes.inspectionReportGradient} />
      {isAbleToComment && (
        <Box className={classes.inspectionReportCommentsReminder}>
          <Typography className={classes.inspectionReportCommentsReminderText}>
            <WarningIcon
              size={15}
              color="#900000"
              className={classes.inspectionReportCommentsReminderIcon}
            />
            Es importante que dejes tus comentarios aquí
          </Typography>
        </Box>
      )}
      {!!rating && (
        <Box className={classes.inspectionReportQualification}>
          <Typography className={classes.inspectionReportQualificationText}>
            <span role="img" aria-label="estrella" className={classes.starIcon}>
              ⭐
            </span>
            {getRating(inspectionReport)}
          </Typography>
        </Box>
      )}
      <Box className={classes.inspectionReportDateContainer}>
        <Typography
          className={clx(dateClassname, classes.inspectionReportDate)}
        >
          {getDate(inspectionReport)}
        </Typography>
        <Box className={classes.inspectionReportButton}>
          <ArrowForward className={classes.inspectionReportIcon} />
        </Box>
      </Box>
    </Grid>
  );
}
