import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  showBreakdown: boolean;
}

const useGeneralInfoStyles = makeStyles<Theme, Props>((theme) => ({
  generalInfoRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paymentStatus: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  rent: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey["A400"],
  },
  totalAmount: {
    margin: theme.spacing(3, 0, 0),
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
    fontWeight: 700,
    color: theme.palette.grey[900],
  },
  limitDate: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
    fontWeight: 400,
    color: theme.palette.grey["A400"],
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
  },
  seeBreakdown: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
    fontWeight: 700,
    color: "#263238",
  },
  arrow: {
    height: 16,
    transform: ({ showBreakdown }) =>
      showBreakdown ? "rotate(180deg)" : "rotate(0)",
    transitionDuration: "0.5s",
  },
}));

export default useGeneralInfoStyles;
