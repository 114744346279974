import Swal from "sweetalert2";

import {
  Action,
  Dispatch,
  PropertyProps,
  BankListProps,
  PriceSuggestProps,
  CurrentPage,
  SET_CURRENT_PAGE,
  SET_LOADING,
  SET_PROPERTY,
  SET_BANK_LIST,
  SET_PRICE_SUGGEST,
  SET_PROPERTY_TYPE,
  SET_SIMILAR_PROPERTIES,
  SET_PROPERTY_SCHEDULE_AVAILABILITY,
  SET_LOADING_SCHEDULE,
  SET_FORM_SUBMITTED,
  SET_STATISTICS,
  Statistics,
  SET_READJUSTMENT,
  SET_OPEN_NEGOTIATIONS,
  SET_ACCEPTED_NEGOTIATION,
} from "./landlordPropertyTypes";
import { parseProperty, parseStatistics } from "./utils";
import propertyService from "services/propertyService";
import { countryCode } from "utils";
import bankAccountService from "services/bankAccountService";
import contractService from "services/contractService";
import schedulesService from "services/schedulesService";
import negotiationService from "services/negotiationService/negotiationService";
import { PropertyData } from "models/Property";

export const setCurrentPage = (currentPage: CurrentPage): Action => ({
  type: SET_CURRENT_PAGE,
  currentPage,
});

export const setLoading = (loading: boolean): Action => ({
  type: SET_LOADING,
  loading,
});

export const setLoadingSchedule = (loadingSchedule: boolean): Action => ({
  type: SET_LOADING_SCHEDULE,
  loadingSchedule,
});

export const setReadjustment = (readjustment: string): Action => ({
  type: SET_READJUSTMENT,
  readjustment,
});

export const setProperty = (data: PropertyProps): Action => ({
  type: SET_PROPERTY,
  data,
});

export const setBankList = (bankList: BankListProps): Action => ({
  type: SET_BANK_LIST,
  bankList,
});

export const setPriceSuggest = (priceSuggest: PriceSuggestProps): Action => ({
  type: SET_PRICE_SUGGEST,
  priceSuggest,
});

export const setFormSubmitted = (formSubmitted: boolean): Action => ({
  type: SET_FORM_SUBMITTED,
  formSubmitted,
});

export const setSimilarProperties = (
  similarProperties: PropertyProps
): Action => ({
  type: SET_SIMILAR_PROPERTIES,
  similarProperties,
});

export const setTypeProperty = (
  propertyType: "forRent" | "forSale"
): Action => ({
  type: SET_PROPERTY_TYPE,
  propertyType,
});

export const setAvailableHours = (scheduleAvailability): Action => ({
  type: SET_PROPERTY_SCHEDULE_AVAILABILITY,
  scheduleAvailability,
});

export const setStatistics = (statistics: Statistics): Action => ({
  type: SET_STATISTICS,
  statistics,
});

export const setOpenNegotiations = (hasOpenNegotiations: boolean): Action => ({
  type: SET_OPEN_NEGOTIATIONS,
  hasOpenNegotiations,
});

export const setAcceptedNegotiation = (
  hasAcceptedNegotiation: boolean
): Action => ({
  type: SET_ACCEPTED_NEGOTIATION,
  hasAcceptedNegotiation,
});

export async function fetchData(
  id: string,
  authToken: string,
  dispatch: Dispatch
) {
  const propertyRes = await propertyService.get(id, authToken);
  if (typeof propertyRes !== "boolean") {
    dispatch(setProperty(parseProperty(propertyRes.data)));
    const fetchedContractInfo = await propertyService.getContractPropertyInfo(
      propertyRes.data.id,
      authToken
    );
    if (typeof fetchedContractInfo !== "boolean") {
      dispatch(setFormSubmitted(fetchedContractInfo.data.submitted));
    }
    if (propertyRes.data.for_sale) {
      const code = countryCode(propertyRes.data.country.toLowerCase());
      const service = negotiationService();

      try {
        const hasOpenNegotiationsRes = await service.hasOpenNegotiations(
          code,
          id,
          authToken
        );
        dispatch(
          setOpenNegotiations(hasOpenNegotiationsRes.data.has_open_negotiations)
        );
      } catch {
        dispatch(setOpenNegotiations(false));
      }

      try {
        const hasAcceptedNegotiationRes = await service.hasAcceptedNegotiation(
          code,
          id,
          authToken
        );
        dispatch(
          setAcceptedNegotiation(
            hasAcceptedNegotiationRes.data.has_accepted_negotiation
          )
        );
      } catch {
        dispatch(setAcceptedNegotiation(false));
      }
    }
  }

  dispatch(setLoading(false));
  return propertyRes.data as PropertyData;
}

export const getPropertyList = async (
  lang,
  lat,
  lng,
  mode,
  type,
  pageSize,
  bounds,
  token,
  dispatch: Dispatch
) => {
  try {
    const res = await propertyService.listProperties({
      lang,
      lat,
      lng,
      mode,
      type,
      pageSize,
      bounds,
      token,
    });
    if (res?.data) dispatch(setSimilarProperties(res.data.results));
    else return [];
  } catch {
    return [];
  }
};

export const setPropertyType = (type, dispatch: Dispatch) => {
  dispatch(setTypeProperty(type));
};

export const getBankList = async (authToken: string, dispatch: Dispatch) => {
  const fetchedBanksCo = await bankAccountService.getBanksCo(authToken);
  const fetchedBanksCl = await bankAccountService.getBanksCl(authToken);
  const fetchedBanksMx = await bankAccountService.getBanksMx(authToken);
  dispatch(
    setBankList({
      cl: fetchedBanksCl.data.banks,
      co: fetchedBanksCo.data.banks,
      mx: fetchedBanksMx.data.banks,
    })
  );
};

export const getPropertySuggestedPrice = async (info, dispatch: Dispatch) => {
  try {
    const res = await propertyService.valuate(info);
    const { data } = res;
    const suggested = {
      forRental: {
        currency: data.for_rental?.currency,
        maxPrice: data.for_rental?.max_price,
        minPrice: data.for_rental?.min_price,
        suggestedPrice: data.for_rental?.suggested_price,
      },
      forSale: {
        currency: data.for_sale?.currency,
        maxPrice: data.for_sale?.max_price,
        minPrice: data.for_sale?.min_price,
        suggestedPrice: data.for_sale?.suggested_price,
      },
    };
    dispatch(setPriceSuggest(suggested));
  } catch {
    dispatch(setPriceSuggest(null));
  }
};

export const getPricePrefix = (currency) => {
  switch (currency) {
    case "Pesos":
      return "$";
    case "U.F.":
    case "UF":
    case "CLF":
      return "UF ";
    default:
      return "$";
  }
};

export const fetchSchedule = async (data, token) => {
  if (!data.houmChecker) {
    const schedule = await propertyService.getScheduledPhotos(data.uid, token);
    if (!schedule) return null;
    return {
      id: schedule.data[0].id,
      fullname: schedule.data[0].appraiser.name,
      email: schedule.data[0].appraiser.email,
      phone: schedule.data[0].appraiser.phone,
      date: schedule.data[0].begin_date,
      photo: schedule.data[0].appraiser.photo,
    };
  } else {
    return {
      id: 1,
      fullname: data.houmChecker.fullName,
      email: data.houmChecker.email,
      phone: data.houmChecker.phone,
      date: "",
      photo: data.houmChecker.photo,
    };
  }
};

export const setBankAccountData = async (
  bankAccount,
  country,
  landlordId,
  propertyId,
  data,
  token
) => {
  const res = bankAccount
    ? await bankAccountService.update(
        country,
        landlordId,
        propertyId,
        data,
        token
      )
    : await bankAccountService.create(
        country,
        landlordId,
        { ...data, identifier: propertyId.toString() },
        token
      );
  return res;
};

export const getBankAccount = async (
  country,
  landlordId,
  propertyId,
  token
) => {
  const dataAccount = await bankAccountService.getByUserAndProperty(
    country,
    landlordId,
    propertyId,
    token
  );
  const { data } = dataAccount;
  return data;
};

export const fetchLinks = async (contractId, token) => {
  const res = await contractService.retrieveSignedContracts(contractId, token);
  return res;
};

export const getStatistics = async (
  propertyUid: string,
  token: string,
  dispatch: Dispatch
) => {
  try {
    const res = await propertyService.newMarketplaceStatistics(
      propertyUid,
      token
    );
    dispatch(setStatistics(parseStatistics(res.data)));
  } catch (e) {
    dispatch(setStatistics(parseStatistics()));
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado, no se pudo obtener las estadisticas de esta propiedad.",
    });
  }
};

export const getAvailableHours = async (
  propertyId: number,
  authToken: string,
  dispatch: Dispatch
) => {
  const available = await schedulesService.getAvailableHours(
    "Photo",
    propertyId,
    authToken
  );
  if (!available) {
    dispatch(setLoadingSchedule(false));
    return;
  }
  dispatch(setAvailableHours(available["data"]));
};
