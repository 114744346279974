import { capitalize } from "lodash";

import { Button, Grid } from "@material-ui/core";

import { accountTypes, renderAddress } from "utils";

import useBankAccountCardStyles from "./BankAccountCardStyles";

interface Props {
  item?: {
    account: {
      account_number: number;
      bank_name: string;
      account_type: string;
    };
    address: string;
    comuna: string;
    country: string;
    id: number;
    numero_depto: string;
    street_number: number;
    type: string;
    uid: string;
  };
  onClick: (e: string) => void;
}

const BankAccountCard = ({ item, onClick }: Props) => {
  const classes = useBankAccountCardStyles();

  return (
    <div className={classes.bankCard}>
      <div className={classes.cardInfo}>
        <div className={classes.cardInfoTitle}>
          <span>{renderAddress(item)}</span>
          <span>{item?.id}</span>
        </div>
        <div className={classes.gridContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className={classes.infoBankAccount}>
                <p>
                  Tipo de cuenta:{" "}
                  <span>
                    {item?.account
                      ? accountTypes[item?.country].map(
                          (type) =>
                            type.code === item?.account.account_type &&
                            type.name
                        )
                      : "-"}
                  </span>
                </p>
                <p>
                  Número de cuenta:{" "}
                  <span>
                    {item?.account ? item?.account.account_number : "-"}
                  </span>
                </p>
                <p>
                  Banco:{" "}
                  <span>
                    {item?.account ? capitalize(item?.account.bank_name) : "-"}
                  </span>
                </p>
              </div>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            {item?.account ? (
              <Button
                className={classes.editAccount}
                onClick={() => onClick(item?.uid)}
              >
                Editar
              </Button>
            ) : (
              <Button
                className={classes.addAccount}
                onClick={() => onClick(item?.uid)}
              >
                Agregar cuenta
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankAccountCard;
