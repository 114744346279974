import { Locale } from "models/Countries";
import useMapSelectStyles from "./MapSelectStyles";
import Map from "../Map";

interface Props {
  lat: number;
  lng: number;
  locale: Locale;
}
const MapSelect = ({ lat, lng, locale }: Props) => {
  const classes = useMapSelectStyles();
  return (
    <section className={classes.mapContainer}>
      <section className={classes.helpOverlay}>
        ¿El punto rojo no coincide con la dirección? Arrastra el mapa para poder
        editarla.
      </section>
      <div className={classes.roundedMap}>
        <Map defaultLat={lat} defaultLng={lng} locale={locale} />
      </div>
    </section>
  );
};

export default MapSelect;
