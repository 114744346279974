import { useCallback } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";

import { ApplicantFormated } from "apps/lead/context/codebtorContext/CodebtorTypes";
import { useCodebtor } from "apps/lead/context/codebtorContext/CodebtorContext";
import {
  setActiveStep,
  setCodebtor,
} from "apps/lead/context/codebtorContext/CodebtorActions";
import { useFormNotifications } from "hooks";

import {
  userFormSchema,
  requiredText,
} from "../components/UserForm/utils/validationSchema";
import { parseCodebtorData } from "../components/UserForm/utils/parsers";

const useUserForm = () => {
  const history = useHistory();
  const { state, dispatch } = useCodebtor();
  const { activeStep, codebtor, emails, country, steps } = state;

  const methods = useForm<ApplicantFormated>({
    resolver: yupResolver(userFormSchema(country, emails)),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<ApplicantFormated>({ errors, requiredText });

  const onSubmit: SubmitHandler<ApplicantFormated> = (data) => {
    dispatch(setCodebtor(parseCodebtorData(codebtor, data)));
    dispatch(setActiveStep(activeStep + 1));
  };

  const handleBackClick = useCallback(() => {
    history.goBack();
  }, []);

  return {
    onSubmit,
    handleBackClick,
    methods,
    activeStep,
    steps,
    country,
    codebtor,
  };
};

export default useUserForm;
