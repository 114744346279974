import { CommonExpensesCompanies } from "apps/landlord/models/ServicesCompanies";
import * as yup from "yup";

const requiredText = "Este campo es obligatorio";

const formSchema = yup.object().shape({
  companyName: yup.string().required(requiredText),
  kastorClientNumber: yup.string().when("companyName", {
    is: CommonExpensesCompanies.KASTOR,
    then: yup.string().nullable(true).required(requiredText),
  }),
  communityName: yup
    .string()
    .nullable(true)
    .when("companyName", {
      is: CommonExpensesCompanies.COMUNIDAD_FELIZ,
      then: yup.string().nullable(true).required(requiredText),
    }),
  unitNumber: yup
    .string()
    .nullable(true)
    .when("companyName", {
      is: CommonExpensesCompanies.COMUNIDAD_FELIZ,
      then: yup.string().nullable(true).required(requiredText),
    }),
  receiptNumber: yup
    .string()
    .nullable(true)
    .when("companyName", {
      is: CommonExpensesCompanies.COMUNIDAD_FELIZ,
      then: yup.string().nullable(true).required(requiredText),
    }),
  administrationEmail: yup
    .string()
    .nullable(true)
    .email("Correo inválido")
    .when("companyName", {
      is: CommonExpensesCompanies.OTHER,
      then: yup.string().nullable(true).required(requiredText),
    }),
});

export default formSchema;
