import { startCase } from "lodash";

import {
  ICompany,
  CompanyResponse,
  BillResponse,
  IBillInfo,
  CompanyType,
  Bill,
} from "apps/landlord/services/propertiesDuesService/type";
import { CECompanyForm } from "apps/landlord/models/ServicesCompanies";

export const parseCompanies = (companies: CompanyResponse[]): ICompany[] =>
  companies.map((company) => ({
    name: company.name,
    type: company.type,
    country: company.country,
    externalId: company.external_id,
    createdAt: company.created_at,
    updatedAt: company.updated_at,
    active: company.active,
    isManual: company.is_manual,
  }));

export const parseBillsInfo = (bills: BillResponse[]): IBillInfo[] =>
  bills.map((bill) => ({
    company: bill.company,
    companyName: bill.pretty_name,
    propertyId: bill.property_id,
    clientInfo: {
      communityName: bill.client_info?.community_name,
      unit: bill.client_info?.unit,
      externalId: bill.client_info.external_id,
    },
    administrationEmail: bill.administration_email,
    isActive: bill.is_active,
    companyType: bill.company_type,
    id: bill._id,
  }));

export const getUpperCaseName = (name: string) =>
  startCase(name.replace(/_/g, " "));

export const getCompaniesOptions = (companies: ICompany[]) =>
  companies.map((company) => ({
    label: getUpperCaseName(company.name),
    value: company.name,
  }));

export const filterCompaniesByType = (data: ICompany[], type: CompanyType) =>
  data
    ? getCompaniesOptions(data.filter((company) => company.type === type))
    : [];

export const parseBillsInfoPayload = ({
  formData,
  billsInfo,
  propertyId,
}: {
  billsInfo: IBillInfo;
  formData: CECompanyForm;
  propertyId: number;
}): Bill => {
  const externalId =
    formData?.receiptNumber ||
    formData?.kastorClientNumber ||
    billsInfo?.clientInfo?.externalId ||
    "";

  const communityName =
    formData?.communityName || billsInfo?.clientInfo?.communityName || "";

  const unit = formData?.unitNumber || billsInfo?.clientInfo?.unit || "";

  return {
    company: formData.companyName || billsInfo?.company,
    property_id: propertyId || billsInfo?.propertyId,
    client_info: {
      community_name: communityName.trim(),
      external_id: externalId.trim(),
      unit: unit.trim(),
    },
    administration_email: formData?.administrationEmail,
    is_active: true,
    ...(billsInfo?.id && { id: billsInfo?.id }),
  };
};
