import { FavoriteProperty } from "apps/account/models/FavoriteProperty";
import { SimilarProperty } from "models";

export const parseFavorites = (data: SimilarProperty[]): FavoriteProperty[] =>
  data.map((property) => ({
    id: property.id,
    type: property.type === "departamento" ? "apartment" : "house",
    region: property.region,
    address: property.address,
    commune: property.comuna,
    photos: property.photos,
    isGGCCincluded: property.property_details[0].is_common_expenses_included,
    price: {
      currencyCode: property.price[0].currency,
      value: property.price[0].value,
    },
    characteristics: {
      baths: property.property_details[0].banos,
      bedrooms: property.property_details[0].dormitorios,
      builtSurface: property.property_details[0].m_construidos,
      totalSurface: property.property_details[0].m_terreno,
    },
  }));
