import { ReactElement } from "react";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

import IconButton from "@houm-com/ui/IconButton";
import { IoCreateOutline, IoRefreshOutline } from "@houm-com/ui-icons";

import statuses from "../../hooks/useBillStatus/utils";
import classes from "./ErrorCardContentStyles";

interface ErrorCardContentProps {
  billStatus: string;
  onEditMode: () => void;
  onFillInfoMode: () => void;
}

interface ContentProps {
  title: string;
  subtitle: string;
  className: string;
  button: ReactElement;
}

const Content = ({ title, subtitle, className, button }: ContentProps) => (
  <div className={className}>
    <p className={classes.text}>{title}</p>
    <p className={classes.text}>{subtitle}</p>
    {button}
  </div>
);

const ErrorCardContent = ({
  billStatus,
  onEditMode,
  onFillInfoMode,
}: ErrorCardContentProps) => {
  const { t } = useTranslation("landlord.property.basic.services");
  const queryClient = useQueryClient();
  const handleRefresh = () => {
    queryClient.invalidateQueries("billsInfo");
    queryClient.invalidateQueries("propertyBillsDetails");
  };
  switch (billStatus) {
    case statuses.invalid_code:
      return (
        <Content
          title={t("info-error-title")}
          subtitle={t("info-error-subtitle")}
          className={classes.checkInfoCardContentContainer}
          button={
            <IconButton
              variant="primary"
              size="md"
              className={classes.cardButton}
              icon={<IoCreateOutline className={classes.cardButtonIcon} />}
              position="end"
              onClick={onEditMode}
            >
              Editar
            </IconButton>
          }
        />
      );
    case statuses.no_data:
      return (
        <Content
          title={t("no-data-error-title")}
          subtitle={t("no-data-error-subtitle")}
          className={classes.checkInfoCardContentContainer}
          button={
            <IconButton
              variant="primary"
              size="md"
              className={classes.cardButton}
              icon={<IoCreateOutline className={classes.cardButtonIcon} />}
              position="end"
              onClick={onFillInfoMode}
            >
              Cargar información
            </IconButton>
          }
        />
      );
    default:
      return (
        <Content
          title={t("update-error-title")}
          subtitle={t("update-error-subtitle")}
          className={classes.errorCardContentContainer}
          button={
            <IconButton
              variant="primary"
              size="md"
              className={classes.cardButton}
              icon={<IoRefreshOutline className={classes.cardButtonIcon} />}
              position="end"
              onClick={handleRefresh}
            >
              Actualizar
            </IconButton>
          }
        />
      );
  }
};

export default ErrorCardContent;
