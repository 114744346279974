import {
  IProperty,
  RentStatusType,
  SalesStatusType,
} from "apps/landlord/models/Property";

export const rentalStatus: { [rentType in RentStatusType]: string } = {
  incomplete: "Por publicar",
  photo_scheduled: "Por publicar",
  photo_cancelled: "Por publicar",
  photo_done: "Por publicar",
  complete: "Por publicar",
  published: "Publicada",
  reserved: "Reservada",
  unpublished: "Despublicada",
  lost: "Despublicada",
  unpublished_by_import: "Despublicada",
  archived: "Archivada",
  republished: "Republicada",
  rented: "Arrendada",
  out_of_coverage: "Fuera de cobertura",
};

export const salesStatus: { [rentType in SalesStatusType]: string } = {
  incomplete: "Por publicar",
  photo_scheduled: "Por publicar",
  photo_cancelled: "Por publicar",
  photo_done: "Por publicar",
  complete: "Por publicar",
  published: "Publicada",
  sold: "Vendida",
  unpublished: "Despublicada",
  lost: "Despublicada",
  unpublished_by_import: "Despublicada",
  archived: "Archivada",
  out_of_coverage: "Fuera de cobertura",
};

export const triggerBlobFileDownload = (blobFile: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blobFile);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  return url;
};

export const renderAddress = (data: Partial<IProperty>) => {
  if (data?.type === "casa") {
    return `${
      data?.address
        ? data?.address.trim().replace(/^\w/, (c) => c.toUpperCase())
        : ""
    }`;
  }
  return `Dpto. ${data?.apartmentNumber ?? ""}, ${data?.address ?? ""}`;
};
