import { AxiosResponse } from "axios";

import { createHttp } from "services/helper";

import { IPhotosBody, IPresignedUrlResponse } from "../types";
import { IServicesRequestBody, IServicesRequestsResponse } from "./types";

export default {
  createServicesRequest(
    token: string,
    propertyId: number,
    data: IServicesRequestBody
  ): Promise<AxiosResponse<IServicesRequestsResponse>> {
    return createHttp(token).post(
      `/properties/v2/${propertyId}/regularization/request`,
      data
    );
  },

  getPresignedUrl(
    token: string,
    data: IPhotosBody
  ): Promise<AxiosResponse<IPresignedUrlResponse>> {
    return createHttp(token).post(
      "properties/v2/regularization/upload-url",
      data
    );
  },

  getRepairsRequests(
    token: string
  ): Promise<AxiosResponse<IServicesRequestsResponse[]>> {
    return createHttp(token).get(`/properties/v2/regularization/request/list`);
  },
};
