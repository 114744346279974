import { CurrencyCodeType } from "apps/landlord/models/Currency";
import { CurrencyCode } from "models/Currencies";

const parseCountryCurrencyCode = (
  country: string,
  currency?: string
): CurrencyCode => {
  if (currency === "U.F.") return "CLF";
  switch (country.toLocaleLowerCase()) {
    case "cl":
      return "CLP";
    case "mx":
      return "MXN";
    case "co":
      return "COP";
    default:
      return null;
  }
};

export const parseCurrencyCode = (currency: CurrencyCodeType): string => {
  if (currency === "CLF") return "U.F.";
  return "Pesos";
};

export default parseCountryCurrencyCode;
