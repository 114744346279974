import { FormProvider, SubmitHandler } from "react-hook-form";

import FieldInputFile from "@houm-com/ui/FieldInputFile";

import { retiredFilesSchema } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/RetiredForm/utils/validationSchema";
import { IRetiredFilesForm } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/RetiredForm/utils/types";
import { allowedMimeTypes } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/utils/constants";
import { resetEmploymentData } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/utils/utils";
import { parserFileList } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/utils/parsers";
import {
  setActiveStep,
  setUserInformation,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper";
import useReactHookForm from "hooks/useReactHookForm";

const RetiredForm = () => {
  const {
    state: { user: userInformation, activeStep },
    dispatch,
  } = useEvaluation();
  const { methods, errors } = useReactHookForm<IRetiredFilesForm>({
    formSchema: retiredFilesSchema,
    formOptions: {
      defaultValues: {
        pensionCertificate: userInformation.employmentFiles?.pensionCertificate,
      },
    },
  });

  const onSubmit: SubmitHandler<IRetiredFilesForm> = (data) => {
    const parsedData = parserFileList<IRetiredFilesForm>(data);
    dispatch(
      setUserInformation({
        ...userInformation,
        employmentFiles: {
          ...resetEmploymentData(),
          pensionCertificate: parsedData.pensionCertificate[0],
        },
      })
    );
    dispatch(setActiveStep(activeStep + 1));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper classNameFormWrapper="flex flex-col">
          <FieldInputFile
            id="laborCertificate"
            label="Certificado de pensión"
            allowedMimeTypes={allowedMimeTypes}
            classNameInputButton="!w-full"
            splitLength={40}
            required
            form="novalidateform"
            file={userInformation.employmentFiles?.pensionCertificate}
            errorMessage={errors.pensionCertificate?.message}
            {...methods.register("pensionCertificate")}
          />
        </FormWrapper>
        <CallToAction label="Continuar" />
      </form>
    </FormProvider>
  );
};

export default RetiredForm;
