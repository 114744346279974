import { makeStyles, Theme } from "@material-ui/core/";

const useDetailsPriceStyles = makeStyles<Theme>((theme) => ({
  detailsPriceContainer: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: 8,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  priceText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.44,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  modifyPriceLink: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.44,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    width: "fit-content",
    fontWeight: 700,
    marginTop: theme.spacing(2.25),
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.dark,
    },
  },
  priceIcon: {
    marginLeft: theme.spacing(0.75),
  },
}));

export default useDetailsPriceStyles;
