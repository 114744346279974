import { makeStyles, Theme } from "@material-ui/core/";

const useStepperStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    gap: 16,
    width: "100%",
  },
  step: {
    backgroundColor: "#DFE5E8",
    borderRadius: 20,
    width: "100%",
    height: 4,
  },
  filled: {
    backgroundColor: "#4C6977",
  },
}));

export default useStepperStyles;
