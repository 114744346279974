export const FAILED_SNACKBAR_OPTIONS = {
  body: "Ha ocurrido un error al cerrar el contrato",
  type: "error",
};

export const SUCCESS_SNACKBAR_OPTIONS = {
  body: "El proceso se ha iniciado correctamente",
  type: "success",
};

export const CL_LEAVING_MOTIVES_OPTIONS = [
  { label: "Incumplimiento por parte del arrendatario", value: 1 },
  {
    label: "Me voy a ir a vivir a la propiedad",
    value: 2,
  },
  { label: "Vendí la propiedad", value: 3 },
  {
    label: "Voy a arrendar por mi cuenta",
    value: 4,
  },
  { label: "Voy a arrendar con la competencia", value: 5 },
  { label: "Otro", value: 9999 },
];

export const CO_LEAVING_MOTIVES_OPTIONS = CL_LEAVING_MOTIVES_OPTIONS;

export const MX_LEAVING_MOTIVES_OPTIONS = [
  { label: "Incumplimiento por parte del inquilino", value: 1 },
  {
    label: "Me voy a ir a vivir al inmueble",
    value: 2,
  },
  { label: "Vendí el inmueble", value: 3 },
  {
    label: "Voy a rentar por mi cuenta",
    value: 4,
  },
  { label: "Voy a rentar con la competencia", value: 5 },
  { label: "Otro", value: 9999 },
];

export const LOCALE_BASED_LEAVING_MOTIVES_OPTIONS = {
  cl: CL_LEAVING_MOTIVES_OPTIONS,
  co: CO_LEAVING_MOTIVES_OPTIONS,
  mx: MX_LEAVING_MOTIVES_OPTIONS,
};
