import { FC, forwardRef } from "react";
import Input, { InputProps } from "apps/ui/formComponents/Input/Input";
import Spinner from "Components/Shared/partials/Spinner";

import useSingleDigitInputStyles from "./SingleDigitInputStyles";
import useSingleDigitInput from "./hooks/useSingleDigitInput";
import useForwardRef from "../hooks/useForwardRef";

interface SingleDigitInputProps extends Omit<InputProps, "onChange"> {
  callback?: (code: string) => void | null;
  digitsAmount?: number;
  onChange?: (e: string) => void;
  prefix?: string;
  submitting?: boolean;
}

const SingleDigitInput: FC<SingleDigitInputProps> = forwardRef<
  HTMLInputElement,
  SingleDigitInputProps
>(
  (
    {
      callback,
      digitsAmount = 6,
      id,
      onChange,
      prefix,
      submitting = false,
      ...props
    },
    ref
  ) => {
    const classes = useSingleDigitInputStyles();
    const inputRef = useForwardRef<HTMLInputElement>(ref);
    const { getInputProps } = useSingleDigitInput({
      callback: () => (callback ? callback(inputRef.current.value) : null),
      digitsAmount,
      inputRef,
      onChange,
      prefix,
    });

    if (submitting) return <Spinner />;

    return (
      <div className={classes.codeInputRoot}>
        <input
          id={id}
          type="text"
          ref={inputRef}
          className={classes.hidden}
          {...props}
        />
        {[...Array(digitsAmount).keys()].map((_, i) => (
          <Input
            key={`code-input-${i}`}
            {...getInputProps(i)}
            classNames={{
              root: classes.digitInputRoot,
              input: classes.digitInput,
            }}
          />
        ))}
      </div>
    );
  }
);

export default SingleDigitInput;
