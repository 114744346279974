import { ReactNode } from "react";

import { cn } from "@houm-com/ui/utils";

import { classes } from "./FormWrapperStyles";

interface Props {
  children: ReactNode;
  classNameFormWrapper?: string;
}

const FormWrapper = ({ children, classNameFormWrapper }: Props) => (
  <div className={classes.formWrapperRoot}>
    <div className={cn(classes.formWrapperContent, classNameFormWrapper)}>
      {children}
    </div>
  </div>
);

export default FormWrapper;
