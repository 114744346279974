import { ComponentProps, FC } from "react";

import Tooltip from "@material-ui/core/Tooltip";

import useHelpTooltipInfoStyles from "./HelpTooltipInfoStyles";

type TooltipProps = ComponentProps<typeof Tooltip>;

const HelpTooltipInfo: FC<TooltipProps> = (props) => {
  const classes = useHelpTooltipInfoStyles();

  return <Tooltip arrow classes={classes} {...props} />;
};

export default HelpTooltipInfo;
