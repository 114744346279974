import {
  State,
  Action,
  SET_CURRENT_STEP,
  SET_LOADING,
  SET_PROPERTY,
  SET_NEGOTIATIONS,
  SET_RESPONSE_TO_OFFER,
  SET_CURRENT_NEGOTIATION,
  SET_CURRENT_OFFER,
  SET_COUNTRY_CODE,
  SET_NEGOTIATIONS_ADMIN,
} from "./negotiationsTypes";

export const initialState: State = {
  loading: true,
  countryCode: null,
  currentStep: "negotiationsList",
  currentOffer: null,
  currentNegotiation: null,
  property: {
    uid: null,
    country: "",
    address: "",
    streetNumber: null,
    flatNumber: "",
    commune: "",
    photos: [],
    salesPrice: null,
    salesCurrency: "",
    region: "",
    type: "",
  },
  negotiations: [
    {
      id: null,
      status: "Abierta",
      activeOfferAmount: 0,
      activeOfferPaymentMethod: "",
      activeOfferCurrency: "",
      activeOfferStatus: "",
      offers: null,
    },
  ],
  negotiationsAdmin: [],
  responseToOffer: {
    response: null,
    reason: null,
    lastAmount: null,
    currency: null,
  },
};

const apiReducer = (state: State, action: Action) => {
  switch (action.type) {
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.currentStep,
      };
    case SET_LOADING:
      return { ...state, loading: action.loading };
    case SET_PROPERTY:
      return { ...state, property: action.data };
    case SET_NEGOTIATIONS:
      return { ...state, negotiations: action.negotiations };
    case SET_NEGOTIATIONS_ADMIN:
      return { ...state, negotiationsAdmin: action.negotiationsAdmin };
    case SET_CURRENT_NEGOTIATION:
      return { ...state, currentNegotiation: action.currentNegotiation };
    case SET_CURRENT_OFFER:
      return { ...state, currentOffer: action.currentOffer };
    case SET_COUNTRY_CODE:
      return { ...state, countryCode: action.countryCode };
    case SET_RESPONSE_TO_OFFER:
      return {
        ...state,
        responseToOffer: {
          ...state.responseToOffer,
          ...action.responseToOffer,
        },
      };
    default:
      return state;
  }
};

export default apiReducer;
