import InspectionReportLayout from "apps/tenant/layout/InspectionReportLayout";

import AllInspectionReports from "./AllInspectionReports";

export default function AllInspectionReportsIndex() {
  return (
    <InspectionReportLayout>
      <AllInspectionReports />
    </InspectionReportLayout>
  );
}
