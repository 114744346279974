import { FC, InputHTMLAttributes, forwardRef, useState } from "react";
import clsx from "clsx";

import {
  IoEyeOutline as VisibleIcon,
  IoEyeOffOutline as HideIcon,
} from "@houm-com/ui-icons";

import useInputStyles from "./InputStyles";

export interface ClassNamesProps {
  root?: string;
  label?: string;
  input?: string;
  message?: string;
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  subLabel?: string;
  message?: string;
  error?: boolean;
  classNames?: ClassNamesProps;
  disabled?: boolean;
}

const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      label,
      subLabel,
      classNames,
      message,
      error,
      required,
      disabled,
      type,
      ...props
    },
    ref
  ) => {
    const classes = useInputStyles({ disabled, error });
    const [showPassword, setShowPassword] = useState(false);
    const toggleHide = () => {
      setShowPassword(!showPassword);
    };

    const handleType = (type: string): string => {
      if (type === "password") {
        if (showPassword) return "text";
        return "password";
      } else {
        return type;
      }
    };

    return (
      <div className={clsx(classes.inputRoot, classNames?.root)}>
        {label && (
          <label
            htmlFor={id}
            className={clsx(classes.label, classNames?.label)}
          >
            {label}
            {required && <span className={classes.required}>*</span>}
          </label>
        )}
        {subLabel && <p className={classes.subLabel}>{subLabel}</p>}
        <input
          id={id}
          ref={ref}
          className={clsx(classes.inputBase, classNames?.input)}
          type={handleType(type)}
          disabled={disabled}
          {...props}
        />
        {type === "password" && !showPassword && (
          <VisibleIcon
            role="img"
            className={classes.eyeIcon}
            onClick={toggleHide}
            size={20}
          />
        )}
        {type === "password" && showPassword && (
          <HideIcon
            role="img"
            className={classes.eyeIcon}
            onClick={toggleHide}
            size={20}
            aria-label="Hide password"
          />
        )}
        {message && (
          <p
            className={clsx(
              classes.message,
              error && classes.errorMessage,
              classNames?.message
            )}
          >
            {message}
          </p>
        )}
      </div>
    );
  }
);

export default Input;
