import { useEffect } from "react";

import Drawer from "apps/ui/CustomDrawer/Drawer";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import { useProperty } from "apps/landlord/hooks";
import DrawerHeader from "apps/ui/CustomDrawer/DrawerHeader";
import DrawerSpinner from "apps/ui/CustomDrawer/DrawerSpinner";

import usePhotoScheduled from "../../../Property/components/PropertyPhotoSchedule/hooks/usePhotoScheduled";
import { STEPS } from "../../hooks/types";
import ScheduleStep from "../ScheduleStep";
import SummaryStep from "../SummaryStep";

const ScheduleDrawer = () => {
  const { propertyData } = useProperty();
  const { data: scheduleData, isLoading } = usePhotoScheduled(
    propertyData?.uid
  );
  const { open, returnToMainPage, saveParentUrl, setCurrentStep, currentStep } =
    useCustomDrawer();

  useEffect(() => {
    setCurrentStep(STEPS.Schedule);
  }, [setCurrentStep]);

  useEffect(() => {
    const PROPERTY_URL = `/landlord/properties/${propertyData?.uid}`;
    saveParentUrl(PROPERTY_URL);
  }, [propertyData?.uid, saveParentUrl]);

  useEffect(() => {
    if (scheduleData) setCurrentStep(STEPS.Summary);
  }, [scheduleData, setCurrentStep]);

  const ComponentsByStep = {
    [STEPS.Schedule]: ScheduleStep,
    [STEPS.Summary]: SummaryStep,
  };

  const CurrentStep = ComponentsByStep[currentStep];

  return (
    <Drawer open={open} onHandleClose={returnToMainPage}>
      <DrawerHeader onHandleClose={returnToMainPage} />
      {isLoading && <DrawerSpinner />}
      {!isLoading && <CurrentStep />}
    </Drawer>
  );
};

export default ScheduleDrawer;
