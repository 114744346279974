import { makeStyles, Theme } from "@material-ui/core";

const useMobilePaymentsTableStyles = makeStyles((theme: Theme) => ({
  mobilePaymentsTableRoot: {},
  movementCard: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(2),
    borderRadius: 4,
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  cardText: {
    fontSize: theme.typography.pxToRem(14),
  },
  valueBox: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      height: 20,
      width: 20,
      marginLeft: theme.spacing(2),
    },
  },
}));

export default useMobilePaymentsTableStyles;
