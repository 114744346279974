import { useState } from "react";

import { useHistory } from "react-router-dom";
import { AppBar, Tab, Tabs } from "@material-ui/core";

import useStyles from "./AppointmentsStatusSelectorStyles";
import { appointmentsOptionsStatus } from "./AppointmentsConstants";

const AppointmentsStatusSelector = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const history = useHistory();

  const handleChange = (event, index) => {
    setValue(index);
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set(
      "completed",
      appointmentsOptionsStatus[index].completed.toString()
    );
    history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
  };

  return (
    <AppBar position="static" className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        className={classes.tabsContainer}
        classes={{ indicator: classes.tabBar }}
      >
        {appointmentsOptionsStatus.map((tab) => (
          <Tab
            label={tab.label}
            key={tab.label}
            classes={{
              selected: classes.selected,
              root: classes.tabRoot,
            }}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};

export default AppointmentsStatusSelector;
