import { STEPS } from "./types";

const INITIAL_STATE_VIEW = {
  open: false,
  returnToPropertyDetail: () => {},
  currentStep: STEPS.BasicServices,
  setCurrentStep: (step: STEPS) => {},
  goToBasicServicesStep: () => {},
  returnFromBasicServices: () => {},
};

export default INITIAL_STATE_VIEW;
