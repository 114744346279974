import { add, format } from "date-fns";
import { es } from "date-fns/locale";
import { startCase, toLower } from "lodash";

import { VisitStatus } from "apps/tenant/models/Visit";

export const getVisitTime = (
  date: Date,
  status: VisitStatus,
  reason?: string
): string => {
  switch (status) {
    case "ok":
      return `${format(date, "kk:mm")} - ${format(
        add(date, { minutes: 30 }),
        "kk:mm"
      )}`;
    case "reserved":
      return "Cancelada: propiedad ya reservada";
    case "cancelled":
      if (reason) return `Cancelada: ${reason}`;
      return "Cambio de horario";
    default:
      return "";
  }
};

export const getVisitDate = (date: Date): string =>
  startCase(toLower(format(date, "EEEE d MMMM", { locale: es })));
