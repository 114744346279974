import { makeStyles, Theme } from "@material-ui/core";

const useDefaulterGuaranteedWarningStyles = makeStyles<Theme>((theme) => ({
  container: {
    backgroundColor: "#F9E8E5",
    border: "1px solid #900000",
  },
  text: {
    display: "block",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    color: "#900000",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.57,
    },
  },
  textSecondary: {
    fontSize: theme.typography.pxToRem(12),
    color: "#900000",
    lineHeight: 1.66,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.57,
    },
  },
}));

export default useDefaulterGuaranteedWarningStyles;
