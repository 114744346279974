import { MovementStatus } from "apps/tenant/models/Movement";

export const getStatusText = (status: MovementStatus) => {
  switch (status) {
    case "pending":
      return "Por pagar";
    case "overdue":
      return "Atrasado";
    case "paid":
      return "Pagado";
    case "review":
      return "Sin información";
    default:
      return "";
  }
};

export default getStatusText;
