import { makeStyles, Theme } from "@material-ui/core";

import sharedFormStyles from "../sharedStyles";

interface ISelectStylesProps {
  disabled?: boolean;
}

const useSelectStyles = makeStyles<Theme, ISelectStylesProps>((theme) => ({
  selectRoot: {
    margin: theme.spacing(2, 0),
  },
  ...sharedFormStyles(theme),
  selectBase: {
    width: "100%",
    padding: theme.spacing(0.5, 1),
    border: "none",
    borderRadius: 4,
    backgroundColor: "#EFF2F3",
  },
}));

export default useSelectStyles;
