import { usePDF } from "@react-pdf/renderer";

import { IoDownloadOutline as DownloadIcon } from "@houm-com/ui-icons";
import IconButton from "@houm-com/ui/IconButton";
import { Box, Typography } from "@material-ui/core";

import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji/Emoji";

import InspectionReportDocument from "Components/Shared/InspectionReportDocument";
import { InspectionReportDocumentProps } from "Components/Shared/InspectionReportDocument/InspectionReportDocument";
import useInspectionReportCommentsStyles from "../InspectionReportCommentsStyles";

const InspectionReportCommentsHeader = ({
  inspectionReport,
  propertyData,
}: InspectionReportDocumentProps) => {
  const classes = useInspectionReportCommentsStyles();
  const [document] = usePDF({
    document: InspectionReportDocument({
      inspectionReport,
      propertyData,
    }),
  });

  const reportCreationYear = new Date(inspectionReport.createdAt).getFullYear();
  const reportCreationMonth = new Date(
    inspectionReport.createdAt
  ).toLocaleString("es-ES", { month: "long" });

  const inspectionTitle = `Acta de ${reportCreationMonth} ${reportCreationYear}`;
  const downloadButtonLabel = document.loading
    ? "Descargando..."
    : "Descargar acta";

  const handleDownloadReport = () => {
    if (!document.loading && document.url) {
      window.open(document.url, "_blank");
    }
  };

  return (
    <Box className={classes.headerSection}>
      <Typography className={classes.title}>{inspectionTitle}</Typography>
      <Typography>
        Descarga el acta para ver todos los detalles del estado de la propiedad
        y realiza tus observaciones aquí{" "}
        <Emoji emojiIcon="👇" ariaLabel="mano apuntando hacia abajo" />.
      </Typography>
      <IconButton
        size="md"
        variant="primary"
        disabled={document.loading}
        onClick={handleDownloadReport}
        icon={<DownloadIcon />}
        position="end"
      >
        {downloadButtonLabel}
      </IconButton>
    </Box>
  );
};

export default InspectionReportCommentsHeader;
