import { useHistory } from "react-router-dom";

import { INavbarItem } from "../../../../utils/types";
import NavbarLabel from "../../../NavbarLabel/NavbarLabel";
import useNavbarMenuItemStyles from "./NavbarMenuItemStyles";

const NavbarMenuItem = ({ label, link, target, domain = "" }: INavbarItem) => {
  const classes = useNavbarMenuItemStyles();
  const history = useHistory();
  const formattedLink = `${domain}${link}`;

  const handleClick = () => {
    if (target === "blank") return window.open(formattedLink);
    if (domain) return window.location.assign(formattedLink);
    history.push(link);
  };

  return (
    <NavbarLabel
      className={classes.navbarMenuItemRoot}
      onClick={handleClick}
      label={label}
    />
  );
};

export default NavbarMenuItem;
