import { Box, Typography } from "@material-ui/core";

import { IoDownloadOutline as DownloadIcon } from "@houm-com/ui-icons";

import usePrintPage from "apps/landlord/pages/Price/hooks/usePrintPage";

import usePrintButtonStyles from "./PrintButtonStyles";

const PrintButton = () => {
  const classes = usePrintButtonStyles();
  const { printPage } = usePrintPage();

  return (
    <Box onClick={printPage} className={classes.downloadButton}>
      <Typography className={classes.buttonLabel}>Descargar</Typography>
      <DownloadIcon width={18} height={20} />
    </Box>
  );
};

export default PrintButton;
