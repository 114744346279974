import { useFormContext } from "react-hook-form";

import LightButton from "@houm-com/ui/LightButton";

import { classes } from "./PaymentMethodCardStyles";
import SelectableCard from "../SelectableCard";
import InfoTag from "../InfoTag";

interface Props {
  data: PaymentMethod;
}
interface PaymentMethod {
  id: string;
  cardOptions?: {
    title: string;
    subtitle: string;
    image: string;
    hasFee: boolean;
  };
  buttonProps?: {
    buttonLabel: string;
    link: string;
  };
}

const PaymentMethodCard = ({ data }: Props) => {
  const { register } = useFormContext();

  const { id, buttonProps, cardOptions } = data;

  return (
    <div className={classes.container}>
      <SelectableCard
        id={id}
        className={classes.selectableCard}
        {...register("paymentMethod")}
      >
        <div className={classes.infoContainer}>
          <p className={classes.title}>{cardOptions?.title}</p>
          <p className={classes.subtitle}>{cardOptions?.subtitle}</p>
        </div>
        <img src={cardOptions?.image} alt="method" className={classes.image} />
        {cardOptions?.hasFee && (
          <InfoTag
            information="Aplican comisiones"
            className={classes.infoTag}
          />
        )}
      </SelectableCard>
      {buttonProps && (
        <LightButton
          onClick={() => window.open(buttonProps.link, "_blank")}
          size="sm"
          type="button"
          variant="primary"
          className={classes.buttonHelp}
        >
          {buttonProps.buttonLabel}
        </LightButton>
      )}
    </div>
  );
};

export default PaymentMethodCard;
