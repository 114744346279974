import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

export default makeStyles((theme: Theme) => ({
  componentContainer: {
    padding: theme.spacing(0, 5, 4.75, 5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(0, 13, 7, 13),
    },
  },
  gridContainer: {
    paddingTop: theme.spacing(3.75),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      paddingTop: theme.spacing(7),
    },
  },
  titleGeneral: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    color: theme.palette.grey[900],
    marginTop: theme.spacing(6.5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(33),
      marginTop: theme.spacing(0),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    color: theme.palette.primary.main,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(28),
    },
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: theme.palette.grey[900],
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  contentMap: {
    position: "relative",
    width: "100%",
    height: "100%",
    marginTop: theme.spacing(3.8),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginTop: theme.spacing(0),
    },
  },
  detailsContainer: {
    "& p": {
      textAlign: "center",
      color: theme.palette.primary.main,
      fontSize: theme.typography.pxToRem(20),
      fontWeight: "bold",
      [theme.breakpoints.up(defaultBreakpoint)]: {
        fontSize: theme.typography.pxToRem(33),
      },
    },
    "& span": {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.grey[900],
    },
  },
  detailsContainerBorderLeft: {
    [theme.breakpoints.up(defaultBreakpoint)]: {
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  detailsContainerBorderRight: {
    [theme.breakpoints.up(defaultBreakpoint)]: {
      borderRight: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  homeOwner: {
    color: theme.palette.primary.main,
  },
}));
