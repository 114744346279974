import { makeStyles, Theme } from "@material-ui/core";
import {
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
} from "../utils/constants";

const useContentDrawerStyles = makeStyles<Theme>((theme) => ({
  contentDrawer: {
    padding: theme.spacing(2),
    minHeight: `calc(100vh - ${HEADER_HEIGHT_MOBILE}px - ${FOOTER_HEIGHT}px)`,
    [theme.breakpoints.up("md")]: {
      minHeight: `calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
      padding: theme.spacing(2, 5),
    },
  },
}));

export default useContentDrawerStyles;
