import TenantLayout from "apps/tenant/layout/TenantLayout";
import InspectionReportComments from "./InspectionReportComments";

export default function () {
  return (
    <TenantLayout>
      <InspectionReportComments />
    </TenantLayout>
  );
}
