import { makeStyles, Theme } from "@material-ui/core/";

const useRentalPaymentsInfoStyles = makeStyles<Theme>((theme) => ({
  infoColumnRental: {
    marginTop: theme.spacing(1),
    borderRadius: 8,
    backgroundColor: "white",
    padding: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(3),
      gap: 24,
    },
  },
  infoContainer: {
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
      gap: 24,
    },
  },
  rentedInfoText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.58,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
}));

export default useRentalPaymentsInfoStyles;
