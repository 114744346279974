import { useGetCurrentUser, useGetToken } from "hooks";
import { useQuery, UseQueryResult } from "react-query";

import { SummaryModelResponse } from "apps/landlord/services/paymentService/types";
import paymentService from "apps/landlord/services/paymentService";
import { IProperty } from "apps/landlord/models/Property";
import { countryCode } from "utils";

import { Summary } from "../utils/types";

const useSummary = (property: Partial<IProperty> = null) => {
  const { user, loading } = useGetCurrentUser();
  const { getToken } = useGetToken();

  const parseData = (data: SummaryModelResponse) => ({
    currency: data.currency,
    depositedPayments: {
      collection: data?.total_money_collected ?? 0,
      costs: data?.total_money_discounted ?? 0,
      pays: data?.total_money_paid ?? 0,
    },
    rentCollectedOnTime: {
      value: data?.total_money_paid ?? 0,
      percentage: data?.total_paid_percentage ?? 0,
    },
    totalAdjustment: {
      totalValue: data?.total_adjustments ?? 0,
      discountValue: data?.total_discounts ?? 0,
      additionalValue: data?.total_additionals ?? 0,
    },
    rentCollectedLate: {
      value: data?.total_pending ?? 0,
      percentage: data?.total_pending_percentage ?? 0,
    },
    totalManagement: {
      value: data?.total_management ?? 0,
    },
  });

  const { isLoading, data, isError, refetch }: UseQueryResult<Summary, Error> =
    useQuery<Summary, Error>(
      ["summary", user, property],
      async () => {
        const authToken = await getToken();
        const response = await paymentService.getSummary(
          countryCode(user?.country),
          user.id,
          authToken,
          property?.id
        );
        return parseData(response.data);
      },
      {
        enabled: !!user,
      }
    );

  return {
    isLoading: isLoading || loading,
    data,
    isError,
    refetch,
  };
};

export default useSummary;
