import capitalize from "lodash/capitalize";
import moment from "moment";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

import useStyles from "./FeedbackCardStyles";

interface Props {
  feedback: {
    id: number;
    comment: string;
    date: string;
    houmer: string;
  };
}

const FeedbackCard = ({ feedback }: Props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      xs={true}
      key={`card-${feedback.id}`}
      justifyContent="center"
    >
      <Card className={classes.cardFeedback}>
        <div>
          <Typography className={classes.houmerText}>
            {feedback.houmer}
          </Typography>
          <Typography className={classes.houmerSubText}>Houmer</Typography>
        </div>
        <Typography className={classes.feedbackText}>
          {capitalize(feedback.comment)}
        </Typography>
        <Typography className={classes.dateText}>
          {capitalize(moment(feedback.date).format("MMMM Do YYYY, h:mm a"))}
        </Typography>
      </Card>
    </Grid>
  );
};

export default FeedbackCard;
