import { useCallback, useState } from "react";
import Box from "@material-ui/core/Box";

import Label from "Components/Admin/UIComponents/ControlledForm/Label";
import SelectionOptions from "Components/Admin/UIComponents/ControlledForm/SelectionOptions";
import { useToky } from "context/tokyContext/tokyContext";
import { IDevice } from "context/tokyContext/tokyTypes";
import useStyles from "./TokyPhoneFormStyles";

const TokyAudioSettings = () => {
  const classes = useStyles({ showcallStatusMessage: false });
  const { state } = useToky();
  const [currentInputDevice, setCurrentInputDevice] = useState(
    state.audioDevices?.currentInput?.id
  );
  const [currentOutputDevice, setCurrentOutputDevice] = useState(
    state.audioDevices?.currentOutput?.id
  );

  const mappedAudioDevicesOptions = useCallback(
    (audioDevices: IDevice[]) =>
      audioDevices?.length > 0
        ? audioDevices.map((audioDevice) => ({
            label: audioDevice.name,
            value: audioDevice.id,
          }))
        : [],
    [state.audioDevices]
  );

  return (
    <Box className="py-3">
      <Box className={classes.selectorContainer}>
        <Label id="tokyInputDevice-label" className={classes.selectorLabel}>
          Input de audio
        </Label>
        <SelectionOptions
          id="tokyInputDevice"
          simple={true}
          value={currentInputDevice}
          onChange={(inputDeviceId) => {
            setCurrentInputDevice(inputDeviceId);
          }}
          options={mappedAudioDevicesOptions(state.audioDevices?.input)}
          fullWidth
        />
      </Box>
      <Box className={classes.selectorContainer}>
        <Label id="tokyOutputDevice-label" className={classes.selectorLabel}>
          Output de audio
        </Label>
        <SelectionOptions
          id="tokyOutputDevice"
          simple={true}
          value={currentOutputDevice}
          onChange={(outputDevice) => {
            setCurrentOutputDevice(outputDevice);
          }}
          options={mappedAudioDevicesOptions(state.audioDevices?.output)}
          fullWidth
        />
      </Box>
    </Box>
  );
};

export default TokyAudioSettings;
