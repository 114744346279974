import clsx from "clsx";
import { useHistory } from "react-router-dom";

import { Box, Typography } from "@material-ui/core";

import { Submenus } from "apps/account/pages/Profile/utils/types";

import useSideMenuStyles from "./SideMenuStyles";

interface Props {
  selected: Submenus;
}

const SideMenu = ({ selected }: Props) => {
  const classes = useSideMenuStyles();
  const history = useHistory();
  const handleClick = (submenu: Submenus) => {
    if (submenu === selected) return;
    history.push(`/account/profile/${submenu}`);
  };

  return (
    <Box className={classes.sideMenuRoot}>
      <Typography
        onClick={() => handleClick("personal_info")}
        className={clsx(
          classes.sideMenuItem,
          selected === "personal_info" && classes.selectedSideMenuItem
        )}
      >
        Información personal
      </Typography>
      <Typography
        onClick={() => handleClick("phone")}
        className={clsx(
          classes.sideMenuItem,
          selected === "phone" && classes.selectedSideMenuItem
        )}
      >
        Teléfono
      </Typography>
      <Typography
        onClick={() => handleClick("email")}
        className={clsx(
          classes.sideMenuItem,
          selected === "email" && classes.selectedSideMenuItem
        )}
      >
        Correo electrónico
      </Typography>
      <Typography
        onClick={() => handleClick("password")}
        className={clsx(
          classes.sideMenuItem,
          selected === "password" && classes.selectedSideMenuItem
        )}
      >
        Contraseña
      </Typography>
    </Box>
  );
};

export default SideMenu;
