import { AxiosResponse } from "axios";

import { BACKEND_URL } from "env";
import { createHttp, createNoAuthHttp } from "services/helper";

import { ICreateScheduleResponse } from "./types";

export default {
  scheduleExit(
    token: string,
    params: {
      begin_hour: Date;
      end_hour: Date;
      prop: string;
      contract: number;
    }
  ): Promise<AxiosResponse<ICreateScheduleResponse>> {
    return createHttp(token).post("schedules/v2/create_schedule_user/", {
      ...params,
      type: "Exit",
      origin: "Tenant Portal",
    });
  },
  availableHours(
    propertyId: number
  ): Promise<AxiosResponse<Record<string, string[]>>> {
    return createNoAuthHttp(BACKEND_URL).get(
      "/schedules/available_hours_by_macrozone/",
      {
        params: {
          schedule_type: "Exit",
          prop_id: propertyId,
        },
      }
    );
  },
};
