import { format } from "date-fns";
import { es } from "date-fns/locale";

import { SweetAlertType } from "sweetalert2";

import { IProperty } from "apps/landlord/models/Property";
import { incompletePhotoStatuses } from "./constants";

export const errorParams = (error: Error) => ({
  type: "error" as SweetAlertType,
  text: `Ha ocurrido algo inesperado. Actualice la página y si el error persiste, contáctese con su ejecutivo/a. ${error}`,
});

export const pageSubtitle = (propertyData: IProperty) => {
  if (propertyData.region && propertyData.commune) {
    return `${propertyData.commune}, ${propertyData.region}`;
  }
  if (propertyData.region) {
    return propertyData.region;
  }
  if (propertyData.commune) {
    return propertyData.commune;
  }
};

export const collectionLink = {
  cl: "https://houm.kustomer.help/es/proceso-de-cobranza-Hkt2eQ5tj",
  mx: "https://houm.kustomer.help/es/proceso-de-cobranza-BJdohnCii",
  co: "https://houm.kustomer.help/es/proceso-de-cobranza-BJXeihCss",
};

export const getMonthDebtText = (lesseeMonthSinceDebt: Date) => {
  const monthSinceDebt = format(lesseeMonthSinceDebt, "MMMM", { locale: es });
  const yearSinceDebt = format(lesseeMonthSinceDebt, "yyyy", { locale: es });

  const actualMonth = format(new Date(), "MMMM", { locale: es });
  const actualYear = format(new Date(), "yyyy", { locale: es });

  if (monthSinceDebt === actualMonth && yearSinceDebt === actualYear) return "";

  return ` desde el mes de ${monthSinceDebt}`;
};

export const showEmptyPriceCard = (propertyData: IProperty) => {
  const isEmptyPriceFlag = (type: "rent" | "sale") => {
    if (type === "rent") return !!propertyData.propertyDetails.price;
    if (type === "sale") return !!propertyData.salesDetails.price;
  };

  let flag: boolean;
  if (propertyData.forRental) {
    if (propertyData?.propertyDetails.status === "rented") flag = false;
    else flag = !isEmptyPriceFlag("rent");
  }
  if (propertyData.forSale) {
    flag = !isEmptyPriceFlag("sale");
  }
  return flag;
};

export const showSupplyPropertyInfo = (propertyData: IProperty) => {
  let flag: boolean;
  if (propertyData.forRental) {
    if (propertyData?.propertyDetails.status === "rented") flag = false;
    else flag = true;
  }
  if (propertyData.forSale) flag = true;
  return flag && !showEmptyPriceCard(propertyData);
};

export const showPhotoScheduleCard = (propertyData: IProperty) => {
  const isForRental = propertyData?.forRental;
  const isForSale = propertyData?.forSale;
  const photos = propertyData?.photos ?? [];
  const propertyStatusForRental = isForRental
    ? propertyData?.propertyDetails.status
    : null;
  const propertyStatusForSale = isForSale
    ? propertyData?.salesDetails.status
    : null;
  const propertyStatus = propertyStatusForRental ?? propertyStatusForSale;

  if (
    photos.length > 0 ||
    (!incompletePhotoStatuses.includes(propertyStatus) &&
      propertyStatus !== "photo_scheduled")
  )
    return false;
  else return true;
};
