import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { Link, Typography } from "@material-ui/core";
import { HOUM_COM_URL } from "env";
import {
  countriesSelector,
  countryToCode,
  defaultPhoneCountry,
  IUser,
  parseUser,
  SuccessForm,
} from "./formUtils";
import leadService from "services/leadService";

import useFormCreateAccountStyles from "./FormCreateAccountStyles";
import { formSchema } from "./validationSchema";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import TermsAndConditions from "Components/Admin/UIComponents/TermsAndConditions";
import PrivacyPolicies from "Components/Admin/UIComponents/PrivacyPolicies";
import {
  ControlledPhoneInput,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import RadioCountry from "./components/RadioCountry";

interface Props {
  setSuccessForm: Dispatch<SetStateAction<SuccessForm>>;
}

const FormCreateAccount = ({ setSuccessForm }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useFormCreateAccountStyles();
  const methods = useForm<IUser>({
    resolver: yupResolver(formSchema),
    reValidateMode: "onSubmit",
  });
  const defaultPhone = defaultPhoneCountry("mx");
  const country = methods.watch("selectCountry");
  const phone = methods.watch("phone");

  useEffect(() => {
    if (country) {
      methods.setValue("phone", {
        country: defaultPhoneCountry(countryToCode(country)),
        phone: phone.phone ?? "",
      });
    }
  }, [country]);

  const textFieldProps = useMemo(
    () => ({
      InputProps: {
        disableUnderline: true,
        classes: {
          underline: "",
        },
      },
    }),
    []
  );
  const onSubmit = async (val: IUser) => {
    try {
      const lead = await leadService.createUser(parseUser(val));
      if (lead.status === 200) {
        alert("Este email se encuentra registrado", "error");
      } else {
        setSuccessForm({ success: true, user: val });
      }
    } catch (error) {
      alert("Ups... Ha ocurrido un error", "error");
    }
  };
  const alert = (message: string, type) => {
    enqueueSnackbar(message, {
      autoHideDuration: 2000,
      variant: type,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };
  const { errors } = methods.formState;

  const placeholderTextFields = {
    placeholder: "Ingresar",
    InputProps: {
      classes: {
        root: classes.input,
      },
    },
  };

  return (
    <>
      <Typography className={classes.title}>Crea tu cuenta</Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          <ControlledTextField
            label={
              <Typography className={classes.label}>
                Nombre <span>*</span>
              </Typography>
            }
            id="firstName"
            defaultValue=""
            textFieldProps={placeholderTextFields}
          />
          <ControlledTextField
            label={
              <Typography className={classes.label}>
                Apellido <span>*</span>
              </Typography>
            }
            id="lastName"
            defaultValue=""
            textFieldProps={placeholderTextFields}
          />
          <ControlledTextField
            label={
              <Typography className={classes.label}>
                Correo electrónico <span>*</span>
              </Typography>
            }
            id="email"
            defaultValue=""
            textFieldProps={placeholderTextFields}
          />
          <Typography className={clsx(classes.label, classes.countryLabel)}>
            Selecciona en que país quieres usar Houm <span>*</span>
          </Typography>

          <div className={classes.countriesBox}>
            {countriesSelector.map((item) => (
              <RadioCountry
                flag={item.flag}
                id={item.id}
                value={item.value}
                tag={item.tag}
              />
            ))}
          </div>
          {errors.selectCountry && (
            <Typography className={classes.labelError}>
              {errors.selectCountry.message}
            </Typography>
          )}
          <ControlledPhoneInput
            id="phone"
            phoneLabel="Número de celular"
            defaultValue={{
              country: defaultPhone,
              phone: "",
            }}
            countryInputProps={textFieldProps}
            numberInputProps={textFieldProps}
            classNameInputContainer={classes.phoneInput}
          />
          <Typography className={classes.error}>
            {errors?.phone?.phone?.message}
          </Typography>
          <Typography className={classes.text}>
            Al continuar estás aceptando los{" "}
            <TermsAndConditions locale={country ?? "mx"} /> y la{" "}
            <PrivacyPolicies locale={country ?? "mx"} />
          </Typography>
          <DesignSystemButton
            label="Continuar"
            variant="primary"
            size="medium"
            type="submit"
            buttonClassName={classes.button}
          />
          <Typography className={classes.login}>
            ¿Ya tienes cuenta?{" "}
            <Link href={`${HOUM_COM_URL}/api/auth/login`}>Entra aquí</Link>
          </Typography>
        </form>
      </FormProvider>
    </>
  );
};

export default FormCreateAccount;
