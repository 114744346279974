import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme, { column: boolean }>((theme) => ({
  container: {
    marginTop: theme.spacing(1),
  },
  label: {
    fontSize: "1rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  helperText: {
    marginBottom: theme.spacing(1),
    fontSize: "0.75rem",
    lineHeight: 1.4,
    textAlign: "justify",
  },
  rootRadio: {
    display: ({ column }) => (column ? "flex" : "inline"),
    paddingLeft: ({ column }) => (column ? theme.spacing(1) : 0),
  },
}));
