import { makeStyles, Theme } from "@material-ui/core";

const useBreakdownItemStyles = makeStyles((theme: Theme) => ({
  breakdownItemRoot: {
    margin: theme.spacing(1, 0),
    display: "flex",
    justifyContent: "space-between",
  },
  breakdownText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
  },
}));

export default useBreakdownItemStyles;
