import { SnackbarProvider } from "notistack";
import { isMobile } from "react-device-detect";
import { Route, useRouteMatch } from "react-router-dom";

import { ENABLE_REPAIRS_REQUEST } from "env";

import AllInspectionReports from "../pages/AllInspectionReports";
import BankAccountForm from "../pages/BankAccountForm";
import ContractEndPage from "../pages/ContractEndPage";
import Dashboard from "../pages/Dashboard";
import ExitVisitSchedule from "../pages/ExitVisitSchedule";
import ExitVisitSummary from "../pages/ExitVisitSummary";
import HelpPage from "../pages/HelpPage";
import PaymentsList from "../pages/PaymentsList";
import RepairsRequestPage from "../pages/RepairsRequestPage";
import Services from "../pages/Services";
import VoucherDetails from "../pages/VoucherDetails";
import InspectionReportComments from "../pages/InspectionReportComments";
import RequestsPage from "../pages/RequestsPage";
import { MarketProvider } from "../context/MarketContext/MarketContext";
import ServicesRequestPage from "../pages/ServicesRequestPage";
import Visits from "../pages/Visits";

const TenantPrivateRoutes = () => {
  const match = useRouteMatch();

  return (
    <SnackbarProvider>
      <MarketProvider>
        <Route exact path={match.path}>
          <Dashboard />
        </Route>
        <Route
          exact
          path={[
            `${match.path}/bank-account-form`,
            `${match.path}/bank-account-form/:account_id`,
          ]}
        >
          <BankAccountForm />
        </Route>
        <Route exact={isMobile} path={`${match.path}/help`}>
          <HelpPage />
        </Route>
        <Route exact path={`${match.path}/help/schedule-exit-visit`}>
          <ExitVisitSchedule />
        </Route>
        <Route exact path={`${match.path}/exit-visit-summary`}>
          <ExitVisitSummary />
        </Route>
        <Route exact path={`${match.path}/help/contract-end`}>
          <ContractEndPage />
        </Route>
        <Route exact path={`${match.path}/services`}>
          <Services />
        </Route>
        {ENABLE_REPAIRS_REQUEST && (
          <Route exact path={`${match.path}/help/repairs-request`}>
            <RepairsRequestPage />
          </Route>
        )}
        <Route exact path={`${match.path}/help/services-request`}>
          <ServicesRequestPage />
        </Route>
        <Route exact path={`${match.path}/requests`}>
          <RequestsPage />
        </Route>
        <Route
          exact
          path={`${match.path}/property/:propertyId/inspection-report`}
        >
          <AllInspectionReports />
        </Route>
        <Route
          exact
          path={`${match.path}/property/:propertyId/inspection-report/:reportId`}
        >
          <InspectionReportComments />
        </Route>
        <Route exact path={`${match.path}/voucher-details/:id`}>
          <VoucherDetails />
        </Route>
        <Route exact path={`${match.path}/vouchers-list`}>
          <PaymentsList />
        </Route>
        <Route exact path={`${match.path}/visits`}>
          <Visits />
        </Route>
      </MarketProvider>
    </SnackbarProvider>
  );
};

export default TenantPrivateRoutes;
