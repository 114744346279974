export const pendingText = {
  contract: {
    cl: {
      title:
        "Tu contrato se hará efectivo una vez que se haya validado el pago.",
      text: "Luego uno de nuestros ejecutivos se pondrá en contacto contigo para brindarte una atención personalizada en los siguientes pasos.",
    },
    co: {
      title:
        "Tu contrato se hará efectivo una vez que se haya validado el pago.",
      text: "Luego uno de nuestros ejecutivos se pondrá en contacto contigo para brindarte una atención personalizada en los siguientes pasos.",
    },
    mx: {
      title:
        "Tu contrato se hará efectivo una vez que se haya validado el pago.",
      text: "Luego uno de nuestros ejecutivos se pondrá en contacto contigo para brindarte una atención personalizada en los siguientes pasos.",
    },
  },
  reservation: {
    cl: {
      title:
        "Tu reserva se hará efectiva una vez que se haya validado el pago.",
      text: "Luego uno de nuestros ejecutivos se pondrá en contacto contigo para brindarte una atención personalizada en los siguientes pasos.",
    },
    co: {
      title:
        "Tu reserva se hará efectiva una vez que se haya validado el pago.",
      text: "Luego uno de nuestros ejecutivos se pondrá en contacto contigo para brindarte una atención personalizada en los siguientes pasos.",
    },
    mx: {
      title:
        "Tu reserva se hará efectiva una vez que se haya validado el pago.",
      text: "Luego uno de nuestros ejecutivos se pondrá en contacto contigo para brindarte una atención personalizada en los siguientes pasos.",
    },
  },
  lease: {
    cl: {
      title: "Tu pago se hará efectivo una vez que se haya validado.",
      text: null,
    },
    co: {
      title: "Tu pago se hará efectivo una vez que se haya validado.",
      text: null,
    },
    mx: {
      title: "Tu pago se hará efectivo una vez que se haya validado.",
      text: null,
    },
  },
};
