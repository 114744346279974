import { Typography } from "@material-ui/core";
import useStyles from "./LoiFormStepHeaderStyles";

export default function ({
  title = null,
  description = null,
  subtitle = null,
}) {
  const classes = useStyles();

  return (
    <>
      {title && <Typography className={classes.title}> {title} </Typography>}
      {description && (
        <Typography className={classes.description}> {description} </Typography>
      )}
      {subtitle && (
        <Typography className={classes.subtitle}> {subtitle} </Typography>
      )}
    </>
  );
}
