export type CompanyOptionsKeyType =
  | "waterCompaniesOptions"
  | "energyCompaniesOptions"
  | "gasCompaniesOptions"
  | "commonExpensesCompaniesOptions";

export const companyOptionsKeys: Record<string, CompanyOptionsKeyType> = {
  water: "waterCompaniesOptions",
  energy: "energyCompaniesOptions",
  gas: "gasCompaniesOptions",
  common_expenses: "commonExpensesCompaniesOptions",
};
