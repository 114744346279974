import { Box, Typography } from "@material-ui/core";
import { ReportProblemOutlined } from "@material-ui/icons";

import { IProperty } from "apps/landlord/models/Property";
import usePropertyDetailsTitleBannerStyles from "./PropertyDetailsTitleBannerStyles";

interface Props {
  propertyData: IProperty;
}

export default function PropertyDetailsTitleBanner({ propertyData }: Props) {
  const classes = usePropertyDetailsTitleBannerStyles();

  return (
    <Box className={classes.WarningBannerContainer}>
      <ReportProblemOutlined className={classes.WarningBannerIcon} />
      <Typography className={classes.WarningBannerText}>
        Para poder hacerle seguimiento a los pagos de tus gastos comunes y
        servicios básicos es muy importante que completes esta información.
      </Typography>
    </Box>
  );
}
