import { View, Text, Image } from "@react-pdf/renderer";

import { getObjectLabel } from "utils";

import InspectionReportObservation from "../components/InspectionReportObservation";
import InspectionReportInfo from "../components/InspectionReportInfo";
import InspectionReportServices from "../components/InspectionReportServices";

import useInspectionReportDocumentStyles from "../InspectionReportDocumentStyles";
import { keysLocationTranslation } from "../translations";
import { Inspection } from "hooks/inspectionReports/types";
import { getResizeImageURL } from "../utils";

interface IntroductionPageProps {
  inspectionReport: Inspection;
}
const IntroductionPage = ({ inspectionReport }: IntroductionPageProps) => {
  const styles = useInspectionReportDocumentStyles;
  const hasKeysLocationSection = !!inspectionReport?.keysInfo?.id;

  const {
    garageCopies = 0,
    condoCopies = 0,
    warehouseCopies = 0,
    frontDoorCopies = 0,
    photos = [],
  } = inspectionReport.keysInfo || {};
  const locationLabel =
    hasKeysLocationSection &&
    getObjectLabel(
      keysLocationTranslation,
      inspectionReport.keysInfo?.location
    );
  const showServicesSection =
    !!inspectionReport?.servicesStatus?.energyStatus &&
    !!inspectionReport?.servicesStatus?.waterStatus &&
    !!inspectionReport?.servicesStatus?.gasStatus;

  return (
    <View style={styles.pageContent}>
      <InspectionReportInfo inspectionReport={inspectionReport} />
      {inspectionReport.generalObservation && (
        <InspectionReportObservation
          generalObservation={inspectionReport.generalObservation}
        />
      )}
      {showServicesSection && (
        <InspectionReportServices
          servicesStatus={inspectionReport?.servicesStatus}
        />
      )}
      {hasKeysLocationSection && (
        <View style={styles.contentSection}>
          <Text style={styles.sectionTitle}>
            Ubicación de llaves: {locationLabel}
          </Text>
          <View style={styles.keyValSection}>
            <Text style={styles.itemLabel}>Copias puerta de entrada: </Text>
            <Text style={styles.itemValue}>{frontDoorCopies}</Text>
          </View>
          <View style={styles.keyValSection}>
            <Text style={styles.itemLabel}>Copias condominio: </Text>
            <Text style={styles.itemValue}>{condoCopies}</Text>
          </View>
          <View style={styles.keyValSection}>
            <Text style={styles.itemLabel}>Copias bodega: </Text>
            <Text style={styles.itemValue}>{warehouseCopies}</Text>
          </View>
          <View style={styles.keyValSection}>
            <Text style={styles.itemLabel}>Copias de estacionamiento: </Text>
            <Text style={styles.itemValue}>{garageCopies}</Text>
          </View>
          <View wrap style={styles.suggestedChangesItemsDetailsImagesSection}>
            {photos.map((photoLink, __idx) => {
              const isOdd = __idx % 2 !== 0;
              const isNewPageIdx = __idx === 2 || __idx === 3;

              return (
                <Image
                  style={{
                    ...styles.spaceImage,
                    ...(isOdd && styles.oddSpaceItem),
                    ...(isNewPageIdx && styles.newPageImage),
                  }}
                  source={getResizeImageURL(photoLink)}
                />
              );
            })}
          </View>
        </View>
      )}
    </View>
  );
};

export default IntroductionPage;
