import {
  CloseContractStatusResponse,
  ContractResponse,
} from "apps/landlord/services/propertyContractService/types";

import IntroductionNewEoC from "./IntroductionNewEoC";
import IntroductionProcessOngoing from "./IntroductionOngoing";

type Props = {
  handleNext: () => void;
  endOfContractStatus: CloseContractStatusResponse;
  activeContract: ContractResponse;
};

const Introduction = ({
  handleNext,
  endOfContractStatus,
  activeContract,
}: Props) => {
  const hasProcessOngoing = endOfContractStatus.status === "IN_PROCESS";

  return hasProcessOngoing ? (
    <IntroductionProcessOngoing
      activeContract={activeContract}
      handleNext={handleNext}
      estimatedDate={endOfContractStatus.estimated_date}
    />
  ) : (
    <IntroductionNewEoC
      handleNext={handleNext}
      activeContract={activeContract}
    />
  );
};

export default Introduction;
