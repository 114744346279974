import { useCallback, useEffect, useState } from "react";

import { IMainPropertyPropertyAnalysis } from "../useMainProperty/types";
import { AnalysisTypeProperty } from "../../utils/types";

const getDefaultType = (property: IMainPropertyPropertyAnalysis) => {
  if (property?.forSale && property?.forRental) return "rent";
  if (property?.forSale) return "sale";
  if (property?.forRental) return "rent";
  else return "rent";
};

const useSwitchAnalysisType = (property: IMainPropertyPropertyAnalysis) => {
  const [type, setType] = useState<AnalysisTypeProperty | null>(
    getDefaultType(property)
  );

  useEffect(() => {
    const propertyType = getDefaultType(property);
    setType(propertyType);
  }, [property]);

  const handleChangeType = useCallback((typeSelected: AnalysisTypeProperty) => {
    setType(typeSelected);
  }, []);

  return {
    handleChangeType,
    type,
    isForRentalAndSale: property?.forSale && property?.forRental,
  };
};

export default useSwitchAnalysisType;
