import { isMobile } from "react-device-detect";

import { Box, Typography } from "@material-ui/core";

import { Locale } from "models/Countries";
import { ApplicationCodebtor } from "models/Evaluation";
import { getName, isMainApplicant } from "apps/landlord/pages/Applicant/utils";
import useApplicantDetailsStyles from "./ApplicantDetailsStyles";
import MobileCardApplicantDetails from "./components/MobileCardApplicantDetails";
import DesktopCardApplicantDetails from "./components/DesktopCardApplicantDetails";

interface ApplicantDetailsProps {
  applicant: ApplicationCodebtor;
  countryCode: Locale;
}

const ApplicantDetails = ({
  applicant,
  countryCode,
}: ApplicantDetailsProps) => {
  const classes = useApplicantDetailsStyles();

  return (
    <Box key={applicant.id} className={classes.applicantDetails}>
      <Typography className={classes.title}>
        {`| ${getName(applicant)}`}
      </Typography>
      <Box className={classes.applicantDetailsContent}>
        <Typography className={classes.subtitle}>
          {isMainApplicant(applicant) ? "Arrendatario" : "Codeudor"}
        </Typography>
        {isMobile ? (
          <MobileCardApplicantDetails
            applicant={applicant}
            countryCode={countryCode}
          />
        ) : (
          <DesktopCardApplicantDetails
            applicant={applicant}
            countryCode={countryCode}
          />
        )}
      </Box>
    </Box>
  );
};

export default ApplicantDetails;
