import { HTMLAttributes } from "react";
import useHeaderStepperStyles from "./DrawerHeaderStepperStyles";

interface StepItem extends HTMLAttributes<HTMLSpanElement> {
  isActive?: boolean;
}

interface HeaderStepperProps {
  steps: StepItem[];
}

const DrawerHeaderStepper = ({ steps }: HeaderStepperProps) => {
  const classes = useHeaderStepperStyles();
  return (
    <section className={classes.stepsWrapper}>
      {!!steps?.length &&
        steps.map((step, idx) => (
          <span
            key={`header_step_${idx}`}
            className={classes.stepItem}
            data-active={step.isActive}
            {...step}
          />
        ))}
    </section>
  );
};

export default DrawerHeaderStepper;
