import { useCallback, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, UseQueryResult } from "react-query";

import { Container } from "@material-ui/core";

import PropertyApplicationCard from "apps/lead/components/PropertyApplicationCard";
import applicationsService from "apps/lead/services/ApplicationsService";
import { ApplicationModel } from "apps/lead/models/Application";
import CustomDialog from "apps/lead/components/CustomDialog";
import useStartEvaluationLandingStyles from "./StartEvaluationLandingStyles";
import CompleteEvaluationDialog from "./components/CompleteEvaluationDialog";
import frontendApplicationParser from "./utils/parsers";

const StartEvaluationLanding = () => {
  const history = useHistory();
  const params = useParams<{ applicantUid: string }>();
  const { applicantUid } = params;
  const classes = useStartEvaluationLandingStyles();
  const [openDialog, setOpenDialog] = useState(false);

  const {
    data: application,
  }: UseQueryResult<Partial<ApplicationModel>, Error> = useQuery<
    Partial<ApplicationModel>,
    Error
  >(["application"], async () => {
    const response = await applicationsService.getApplication(applicantUid);
    return frontendApplicationParser(response?.data);
  });

  const handleDialog = useCallback(() => {
    setOpenDialog((prevState) => !prevState);
  }, []);

  const startEvaluation = useCallback(() => {
    handleDialog();
  }, []);

  const handleLogin = useCallback(() => {
    history.push("/tenant/evaluations");
  }, []);

  const openGuestSite = useCallback(() => {
    handleDialog();
    window.open(
      `https://evaluations.houm.com/cl/form?property=${application.property.uid}&brokerage=false`,
      "_blank"
    );
  }, [application?.property]);

  return (
    <>
      <Container className={classes.container}>
        {application && (
          <PropertyApplicationCard
            labelButton="Empezar evaluación"
            handleClick={startEvaluation}
            property={application?.property}
            houmerName={application.houmer?.name}
          />
        )}
      </Container>
      <CustomDialog
        title="¡Completa tu evaluación!"
        open={openDialog}
        onClose={handleDialog}
        customizedButtonClassName={classes.customCloseButton}
      >
        <CompleteEvaluationDialog
          login={handleLogin}
          keepAsInvite={openGuestSite}
        />
      </CustomDialog>
    </>
  );
};

export default StartEvaluationLanding;
