import { normalizeCommuneName, normalizeRegionName } from "utils/normalizer";

export const urlHoum = "https://www.houm.com";

export const statusAppointments = [
  {
    code: "Confirmed",
    label: "Confirmada",
    color: "#DAEECC",
    letterColor: "#439F03",
  },
  {
    code: "Waiting",
    label: "Por confirmar",
    color: "#FAF3CC",
    letterColor: "#A07800",
  },
  {
    code: "Cancelled",
    label: "Cancelada",
    color: "#EFCDCC",
    letterColor: "#900000",
  },
  {
    code: "Scheduled",
    label: "Confirmada",
    color: "#DAEECC",
    letterColor: "#439F03",
  },
  {
    code: "Done",
    label: "Realizada",
    color: "#DAEECC",
    letterColor: "#439F03",
  },
];

export const countrys = [
  { name: "Colombia", nameShort: "co" },
  { name: "Chile", nameShort: "cl" },
  { name: "México", nameShort: "mx" },
];

export const optionsMenuCard = [
  {
    label: "Ver propiedad",
    action: (propertyInfo) => redirectToViewProperty(propertyInfo),
  },
  {
    label: "Cancelar o reagendar visita",
    action: (propertyInfo) => redirectToCancelAppointment(propertyInfo),
  },
];

const redirectToCancelAppointment = (propertyInfo) => {
  const strictScheduleViewQueryParams =
    "?isSuggested=false&origin=Marketplace&streaming=&selectedDate=";
  const url = `${urlHoum}/${propertyInfo.countryCode}/propiedades/${
    propertyInfo.appointmentType
  }/${propertyInfo.propertyType}/${normalizeRegionName(
    propertyInfo.region
  )}/${normalizeCommuneName(propertyInfo.commune)}/${
    propertyInfo.propertyId
  }/agendar${strictScheduleViewQueryParams}`;

  window.open(url);
};

const redirectToViewProperty = (propertyInfo) => {
  const url = `${urlHoum}/${propertyInfo.countryCode}/propiedades/${
    propertyInfo.appointmentType
  }/${propertyInfo.propertyType}/${normalizeRegionName(
    propertyInfo.region
  )}/${normalizeCommuneName(propertyInfo.commune)}/${propertyInfo.propertyId}`;
  window.open(url);
};

export const appointmentsOptionsTypes = [
  { label: "Arriendo", scheduleType: "Visit" },
  { label: "Compra", scheduleType: "SalesVisit" },
];

export const appointmentsOptionsStatus = [
  { label: "Agendadas", completed: false },
  { label: "Realizadas", completed: true },
];

export const monthsNames = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];
