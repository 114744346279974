import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";

import PropertyLayout from "apps/landlord/Layout/PropertyLayout";

import PropertyLandlordRequests from "./PropertyLandlordRequests";

const Page = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { t } = useTranslation(["landlord.property.requests"]);
  const propertyDetailURL = match.url.replace("/property-requests", "");
  const goToPropertyDetail = () => history.push(propertyDetailURL);

  return (
    <PropertyLayout
      titlePage={t("PAGE_PROPERTY_REQUESTS_TITLE")}
      subtitlePage={t("PAGE_PROPERTY_REQUESTS_SUBTITLE")}
      onHandleBack={goToPropertyDetail}
      rootClassName="!bg-white"
    >
      <PropertyLandlordRequests />
    </PropertyLayout>
  );
};

export default Page;
