const accountTypes = {
  Chile: [
    {
      code: "CURRENT",
      name: "Cuenta Corriente",
    },
    {
      code: "SIGHT",
      name: "Cuenta Vista",
    },
    {
      code: "SAVING",
      name: "Ahorro",
    },
  ],
  Colombia: [
    {
      code: "CURRENT",
      name: "Cuenta Corriente",
    },
    {
      code: "SAVING",
      name: "Ahorro",
    },
  ],
  Mexico: [
    {
      code: "CURRENT",
      name: "Cuenta Corriente",
    },
    {
      code: "SAVING",
      name: "Ahorro",
    },
    {
      code: "BANK",
      name: "Cuenta bancaria",
    },
  ],
};

export default accountTypes;

export const accountTypesName = {
  Chile: {
    CURRENT: "Cuenta Corriente",
    SIGHT: "Cuenta Vista",
    SAVING: "Cuenta Ahorro",
  },
  Colombia: {
    CURRENT: "Cuenta Corriente",
    SAVING: "Cuenta Ahorro",
  },
  Mexico: {
    CURRENT: "Cuenta Corriente",
    SAVING: "Cuenta Ahorro",
    BANK: "Cuenta Bancaria",
  },
};

export const documentTypes = [
  { code: "CC", name: "Cédula de identidad" },
  { code: "CE", name: "Cédula de Extranjería" },
  { code: "NIT", name: "NIT" },
  { code: "PASSPORT", name: "Pasaporte" },
  { code: "SE", name: "Sociedad Extranjera" },
];
