import { BanksResponse } from "apps/landlord/services/bankAccountService/types";
import { IBank } from "apps/landlord/models/BankAccount";

export const parseBankAccounts = (bankAccounts: BanksResponse): IBank[] =>
  bankAccounts.banks.map((bank) => ({
    name: bank.name,
    bankId: bank.bank_id,
    code: bank.code,
    deleted: bank.deleted,
  }));
