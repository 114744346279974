import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useApplicantEvaluationFormStyles = makeStyles((theme: Theme) => ({
  formContent: {
    width: "100%",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      width: 500,
    },
  },
  select: {
    border: "none",
    padding: 0,
    width: "100%",
    color: "#7D8488",
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  container: {
    padding: theme.spacing(0.3),
  },
  textArea: {
    padding: theme.spacing(1, 1.5),
    marginTop: theme.spacing(1),
    borderRadius: 4,
    backgroundColor: "#EFF2F3",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.9,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.6,
    },
  },
  button: {
    marginTop: theme.spacing(5.5),
    "&:hover": {
      backgroundColor: "#FFC9C2",
      color: theme.palette.primary.main,
    },
  },
  selectRoot: {
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderRadius: 4,
    backgroundColor: "#EFF2F3",
    minHeight: 30,
    display: "flex",
    alignItems: "center",
    "&:focus": {
      borderRadius: 4,
    },
  },
  selectIcon: {
    marginTop: "auto",
    paddingRight: theme.spacing(1),
    minWidth: 36,
    backgroundColor: "#EFF2F3",
    minHeight: 42,
    borderRadius: 4,
    position: "absolute",
    top: 8,
    right: 0,
  },
  remindMeLink: {
    textDecoration: "underline",
    color: theme.palette.grey[900],
    "&:hover": {
      color: theme.palette.grey[500],
    },
  },
}));

export default useApplicantEvaluationFormStyles;
