import { add, compareDesc, format, setHours } from "date-fns";
import { ITimeOption } from "../../utils/types";

export const formatDate = ({
  date,
  time,
}: {
  date: Date;
  time: `${string}:${string}`;
}): { beginDate: Date; endDate: Date } => {
  const modifier = time.includes("pm") && time !== "12:00 pm" ? 12 : 0;
  const hours = parseInt(time.split(":")[0]) + modifier;
  const minutes = parseInt(time.split(":")[1]);

  date.setHours(hours);
  date.setMinutes(minutes);

  return {
    beginDate: date,
    endDate: add(date, { minutes: 30 }),
  };
};

const isEqualDay = (first_date: Date, second_date: Date): boolean => {
  const equalYear = first_date.getFullYear() === second_date.getFullYear();
  const equalMonth = first_date.getMonth() === second_date.getMonth();
  const equalDay = first_date.getDate() === second_date.getDate();
  return equalYear && equalMonth && equalDay;
};

export const getAvailableDates = ({
  availableDates,
  endDate,
}: {
  availableDates: Record<string, ITimeOption[]>;
  endDate: Date;
}): Date[] => {
  const filteredDates = Object.keys(availableDates)
    .map((date) => setHours(add(new Date(date), { days: 1 }), 0))
    .filter(
      (date) =>
        compareDesc(date, endDate) !== -1 &&
        !isEqualDay(new Date(), new Date(date))
    );

  if (availableDates[format(endDate, "yyyy-MM-dd")]?.length === 0) {
    filteredDates.push(add(endDate, { days: 1 }));
  }

  return filteredDates;
};
