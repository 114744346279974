import currency from "@houm-com/formats/currency";

import { Currency } from "models/Countries";

interface Props {
  paymentMethodIcon?: string;
  total: number;
  currencyCode: Currency;
}

const PaymentSummary = ({ paymentMethodIcon, total, currencyCode }: Props) => (
  <>
    <div className="flex justify-between items-center mt-8">
      <p className="text-p16 !font-bold md:text-p20">Total a pagar</p>
      {paymentMethodIcon && (
        <div className="flex items-center">
          <p className="text-p10 mr-1">Pago realizado por</p>
          <img
            src={paymentMethodIcon}
            aria-label="payment-method"
            className="w-[50px] h-auto"
          />
        </div>
      )}
    </div>
    <div className="text-p16 !font-bold rounded-lg bg-secondary-20 w-[100%] text-center py-1 mt-2 mb-4">
      {currency(total, currencyCode, {
        minimumFractionDigits: 0,
      })}
    </div>
  </>
);

export default PaymentSummary;
