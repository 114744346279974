interface IObjectArray {
  value: string;
  label: string;
}

const getObjectLabel = (objectArr: IObjectArray[], val: string): string => {
  if (!val) return "";
  const foundObjectInfo = objectArr.find((object) => object.value === val);
  return foundObjectInfo?.label;
};

export default getObjectLabel;
