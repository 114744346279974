import { makeStyles, Theme } from "@material-ui/core";

const useFormPropertyDetailsStyles = makeStyles<Theme>((theme) => ({
  containerRoot: {
    display: "grid",
    gap: theme.spacing(2),
  },
  fieldContainer: {
    gap: theme.spacing(2),
    display: "grid",
  },
}));

export default useFormPropertyDetailsStyles;
