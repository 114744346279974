import * as yup from "yup";

const requiredText = "Este campo es requerido";
const clabeValidation = "CLABE debe tener exactamente 18 dígitos";
const nameRegex =
  /^[A-Za-zÀ-ÖØ-öø-ÿ]+\s[A-Za-zÀ-ÖØ-öø-ÿ]+(\s[A-Za-zÀ-ÖØ-öø-ÿ]+\s*)*$/;
const rfcRegex =
  /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})$/;

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required(requiredText)
    .matches(nameRegex, "Por favor ingresar nombre y apellido"),
  email: yup.string().email().required(requiredText),
  document: yup.string().required(requiredText),
  bankId: yup.string().required(requiredText),
  accountType: yup.string().required(requiredText),
  accountNumber: yup.string().required(requiredText),
  numberValidation: yup
    .string()
    .required(requiredText)
    .oneOf(
      [yup.ref("accountNumber"), null],
      "Los números de cuenta no coinciden"
    ),
});

export const validationSchemaMX = yup.object().shape({
  name: yup
    .string()
    .required(requiredText)
    .matches(nameRegex, "Por favor ingresar nombre y apellido"),
  email: yup.string().email().required(requiredText),
  document: yup
    .string()
    .required(requiredText)
    .matches(rfcRegex, "RFC inválido"),
  bankId: yup.string().required(requiredText),
  clabe: yup
    .string()
    .min(18, clabeValidation)
    .max(18, clabeValidation)
    .required(requiredText),
  accountType: yup.string().required(requiredText).oneOf(["BANK"]),
  accountNumber: yup.string().required(requiredText),
  numberValidation: yup
    .string()
    .required(requiredText)
    .oneOf(
      [yup.ref("accountNumber"), null],
      "Los números de cuenta no coinciden"
    ),
});

export default validationSchema;
