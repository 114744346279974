import { makeStyles, Theme } from "@material-ui/core";
import { DESKTOP_CONTENT_MAX_WIDTH } from "apps/account/constants";

const useContentWrapperStyles = makeStyles((theme: Theme) => ({
  contentWrapperRoot: {
    margin: "0 auto",
    padding: theme.spacing(4, 2),
    maxWidth: DESKTOP_CONTENT_MAX_WIDTH,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
  },
}));

export default useContentWrapperStyles;
