import { makeStyles, Theme } from "@material-ui/core";

export const useUserNavbarLinkStyles = makeStyles<Theme>((theme) => ({
  "navbar-link-button-paper": {
    width: "100%",
    transition: "all ease-out .1s",
    padding: `${theme.spacing(0.5, 2)} !important`,
    "&:hover": {
      color: `${theme.palette.primary.main} !important`,
      fontWeight: "700 !important",
    },
    "& span": {
      textTransform: "initial",
    },
    [theme.breakpoints.up("sm")]: {
      padding: `${theme.spacing(1.25, 2)} !important`,
    },
  },
  "navbar-link-button": {
    padding: theme.spacing(1),
    fontSize: "0.875rem",
    height: "100%",
    width: "100%",
    minWidth: "unset",
    justifyContent: "flex-start",
    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
  },
}));
