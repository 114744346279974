import { ReactNode } from "react";

import { Drawer as MaterialDrawer } from "@material-ui/core";

import useDrawerStyles from "./DrawerStyles";

interface Props {
  children: ReactNode;
  open: boolean;
  onHandleClose: () => void;
}

const Drawer = ({ children, open, onHandleClose }: Props) => {
  const classes = useDrawerStyles();

  return (
    <MaterialDrawer
      anchor="right"
      open={open}
      classes={{ paper: classes.drawer, root: classes.drawerRoot }}
      ModalProps={{
        onClose: () => onHandleClose(),
      }}
    >
      {children}
    </MaterialDrawer>
  );
};

export default Drawer;
