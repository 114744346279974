import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

import { SubmitHandler, useForm } from "react-hook-form";

import { useGetToken } from "hooks/useGetToken";
import useFormNotifications from "hooks/useFormNotifications";
import contractService from "services/contractService";
import newEvaluationService from "services/newEvaluationService";
import { useApplicant } from "apps/landlord/pages/Applicant/context/ApplicantContext";
import { evaluationSchema, requiredText } from "./validationSchema";
import {
  IData,
  FormData,
  errorEvaluatingProfile,
  errorUpdatingContract,
  successEvaluatingProfile,
} from "./utils";

const useEvaluateProfile = (mainApplicantId: number) => {
  const { contract, countryCode, handleSubmitResponse } = useApplicant();

  const { getToken } = useGetToken();
  const [isLoading, setIsLoading] = useState(false);
  const [decisionFlag, setDecisionFlag] = useState(false);

  const formMethods = useForm<IData>({
    resolver: yupResolver(evaluationSchema(decisionFlag)),
    reValidateMode: "onBlur",
  });
  const { errors } = formMethods.formState;
  useFormNotifications<IData>({ errors, requiredText });

  const [decision] = formMethods.watch(["decision"]);
  const [openKeepSearchingModal, setOpenKeepSearchingModal] = useState(false);

  const modifyContract = async (comment: string, authToken: string) => {
    try {
      const newStatusContract = contract;
      newStatusContract.discard_comment = comment;
      newStatusContract.status = decisionFlag
        ? "Perfil aprobado"
        : "Re-evaluacion";
      await contractService.update(
        newStatusContract.id,
        newStatusContract,
        authToken
      );
      if (decisionFlag) {
        handleSubmitResponse(successEvaluatingProfile);
      } else {
        setOpenKeepSearchingModal(true);
      }
    } catch {
      handleSubmitResponse(errorUpdatingContract);
    }
  };

  const evaluateProfile = async (data: FormData) => {
    const authToken = await getToken();
    await newEvaluationService
      .evaluateApplicantProfile(mainApplicantId, countryCode, data, authToken)
      .then(() => modifyContract(data.message, authToken))
      .catch(() => handleSubmitResponse(errorEvaluatingProfile))
      .finally(() => setIsLoading(false));
  };

  const handleEvaluateProfile: SubmitHandler<IData> = (data) => {
    setIsLoading(true);
    const formData: FormData = {
      status: data.decision,
    };
    if (!decisionFlag) {
      formData.message = data.comment;
      setOpenKeepSearchingModal(true);
    }
    evaluateProfile(formData);
  };

  const handleFormSubmit = formMethods.handleSubmit(handleEvaluateProfile);

  useEffect(() => setDecisionFlag(decision === "approved"), [decision]);

  return {
    isLoading,
    decision,
    formMethods,
    handleFormSubmit,
    openKeepSearchingModal,
  };
};

export default useEvaluateProfile;
