import ILocalBasedText from "apps/tenant/pages/ContractEndPage/types";

const LOCAL_BASED_LEAVING_DATE_CONFIRMATION: ILocalBasedText = {
  cl: `Nos vemos el <1>{{formattedDate}}</1> para que realices la
  entrega de la propiedad y las llaves. Te contactaremos una semana antes
  para coordinar el horario.`,
  co: `Nos vemos el <1>{{formattedDate}}</1> para que realices la
  entrega de la propiedad y las llaves. Te contactaremos una semana antes
  para coordinar el horario.`,
  mx: `Nos vemos el <1>{{formattedDate}}</1> para que realices la
  entrega del inmueble y las llaves. Te contactaremos unos días antes
  para coordinar el horario.`,
};

export default LOCAL_BASED_LEAVING_DATE_CONFIRMATION;
