import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: 90,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.grey[5],
    overflow: "hidden",
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: 90,
    objectFit: "cover",
  },
  fakeImageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    height: "100%",
  },
  fakeImage: {
    width: "fit-content",
    height: 45,
  },
  number: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    height: "100%",
  },
  publishDate: {
    fontSize: theme.typography.pxToRem(10),
  },
}));

export default useStyles;
