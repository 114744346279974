import { StyleSheet } from "@react-pdf/renderer";

// Create styles
const useInspectionReportDocumentStyles = StyleSheet.create({
  pageRoot: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    flexWrap: "wrap",
  },
  header: {
    width: "100%",
    padding: 16,
    fontSize: 14,
    fontFamily: "Nunito",
    fontWeight: 700,
    color: "#FFFFFF",
    backgroundColor: "#263238",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerTitleWrap: {
    flexDirection: "column",
  },
  headerLogo: {
    paddingTop: 10,
  },
  pageContent: {
    marginLeft: 63,
    marginRight: 63,
    marginTop: 20,
    marginBottom: 20,
  },
  firstSection: {
    paddingTop: 20,
  },
  contentSection: {
    paddingBottom: 12,
    paddingTop: 12,
  },
  sectionTitle: {
    fontSize: 14,
    fontFamily: "Nunito",
    fontWeight: 700,
    marginBottom: 8,
  },
  itemLabel: {
    fontSize: 12,
    fontFamily: "Nunito",
    fontWeight: 700,
  },
  itemValue: {
    fontSize: 12,
    fontFamily: "Nunito",
    fontWeight: 400,
  },
  sectionDivider: {
    height: 4,
    width: 24,
  },
  cleaningKeyValSection: {
    paddingTop: 12,
    flexDirection: "row",
  },
  cleaningInfoSection: {
    paddingTop: 12,
  },
  cleaningObservationDetail: {
    fontSize: 12,
    fontFamily: "Nunito",
    fontWeight: 400,
    marginBottom: 12,
  },
  keyValSection: {
    flexDirection: "row",
  },

  /**Spaces */
  spacesSection: {
    maxWidth: "100%",
    flexShrink: 0,
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  spaceItem: {
    width: 220,
    maxHeight: 308,
    paddingBottom: 16,
    flexShrink: 0,
    marginRight: 80,
  },
  oddSpaceItem: {
    marginRight: 0,
  },
  postPageBreakSpaceItem: {
    marginTop: 67,
  },
  spaceImage: {
    minWidth: 220,
    minHeight: 220,
    maxWidth: 220,
    maxHeight: 220,
    borderWidth: 1,
    borderColor: "#263238",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    marginRight: 40,
    marginBottom: 8,
  },
  spaceRating: {
    height: 16,
    width: "auto",
    marginTop: 4,
  },
  logo: {
    width: 25,
    height: "auto",
  },
  pageBackground: {
    position: "absolute",
    width: 457,
    height: 380,
    left: 80,
    top: 200,
  },
  qualificationWrapper: {
    height: 16,
    flexDirection: "row",
    width: "auto",
  },
  starIcon: {
    marginRight: 9,
    width: 12,
    height: 12,
  },

  /**Services status */
  servicesSection: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  serviceItem: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    marginRight: 10,
  },
  serviceIcon: {
    width: 16,
    height: 16,
    marginRight: 8,
  },

  /**Suggested changes */
  suggestedChanges: {
    maxWidth: "100%",
    flexShrink: 0,
    flexWrap: "wrap",
    flexDirection: "column",
  },
  suggestedChangesItem: {
    maxWidth: "100%",
    flexShrink: 0,
    flexDirection: "column",
  },
  suggestedChangesSummary: {
    flexDirection: "row",
  },
  suggestedChangesSummaryDetails: {
    flexDirection: "column",
  },
  spaceImageSmall: {
    width: 50,
    height: 50,
    borderWidth: 1,
    borderColor: "#263238",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    marginRight: 8,
  },
  suggestedChangesItemsDetails: {
    flexDirection: "column",
    paddingBottom: 24,
    paddingTop: 24,
    paddingLeft: 32,
    paddingRight: 0,
  },
  suggestedChangesItemsDetailsItem: {
    flexDirection: "column",
    marginBottom: 8,
  },
  suggestedChangesItemsDetailsImagesSection: {
    marginTop: 8,
    maxWidth: "100%",
    flexShrink: 0,
    flexWrap: "wrap",
    flexDirection: "row",
  },
  suggestedChangesItemsDetailsItemImage: {
    paddingTop: 8,
    marginRight: 0,
  },
  suggestedChangesItemsDetailsItemOdd: {
    marginBottom: 56,
  },
  /**Is not Habitable Disclaimer */
  disclaimerContainer: {
    backgroundColor: "#DF9B99",
    display: "flex",
    alignItems: "center",
    borderRadius: 4,
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 4,
    paddingBottom: 4,
    marginTop: 12,
  },
  disclaimerText: {
    color: "#900000",
    fontSize: 12,
    fontFamily: "Nunito",
    fontWeight: 700,
    marginLeft: 8,
  },
  actionsImagesSection: {
    marginTop: 8,
    maxWidth: "100%",
    flexShrink: 0,
    flexWrap: "wrap",
    flexDirection: "row",
  },
  actionsImage: {
    minWidth: 220,
    minHeight: 220,
    maxWidth: 220,
    maxHeight: 220,
    borderWidth: 1,
    borderColor: "#263238",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    marginRight: 40,
    marginTop: 8,
  },

  actionLastUpdate: {
    fontSize: 12,
    fontFamily: "Nunito",
    fontWeight: 400,
  },
  newPageImage: {
    marginTop: 67,
  },
  tenantProofSection: {
    paddingTop: 12,
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  tenantProofImage: {
    margin: "auto",
    width: 220,
    height: 220,
    borderWidth: 1,
    borderColor: "#263238",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
  tenantProofCaption: {
    fontSize: 12,
    fontFamily: "Nunito",
    fontWeight: 700,
    marginTop: 8,
    margin: "auto",
  },
});

export default useInspectionReportDocumentStyles;
