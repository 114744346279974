import { useHistory } from "react-router-dom";

import { Box, Typography } from "@material-ui/core";
import { GridAlignment, GridCellValue, GridColDef } from "@material-ui/x-grid";

import CustomDataGrid from "apps/tenant/components/CustomDataGrid";

import useDesktopPaymentsTableStyles from "./DesktopPaymentsTableStyles";
import { ParsedMovement } from "../../utils/types";

interface Props {
  movements: ParsedMovement[];
}

const DesktopPaymentsTable = ({ movements }: Props) => {
  const classes = useDesktopPaymentsTableStyles(movements.length)();
  const history = useHistory();

  const columnsConfig: GridColDef = {
    field: "id",
    resizable: true,
    filterable: false,
    cellClassName: classes.centerCell,
    sortable: false,
    disableColumnMenu: true,
    disableReorder: true,
    editable: false,
  };

  const columns = [
    {
      ...columnsConfig,
      field: "date",
      headerName: "Fecha de pago",
      type: "string",
      resizable: false,
      headerClassName: classes.firstHeaderCell,
      width: 130,
    },
    {
      ...columnsConfig,
      field: "method",
      headerName: "Método de pago",
      type: "string",
      flex: 2,
    },
    {
      ...columnsConfig,
      field: "amount",
      headerName: "Pago",
      type: "string",
      align: "right" as GridAlignment,
      width: 180,
    },
    {
      ...columnsConfig,
      field: "id",
      headerName: "Link",
      width: 200,
      headerClassName: classes.lastRenderedHeaderCell,
      cellClassName: classes.lastRenderedRowCell,
      align: "center" as GridAlignment,
      renderHeader: () => <span className={classes.emptyHeaderName}>-</span>,
      renderCell: ({ value }: { value: GridCellValue }) => (
        <Box className={classes.linkContainer}>
          <Typography
            className={classes.link}
            onClick={() => history.push(`/tenant/voucher-details/${value}`)}
          >
            Ver detalles
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      {movements.length === 0 && (
        <Typography className={classes.emptyData}>
          No hay ningún pago registrado.
        </Typography>
      )}
      {movements.length > 0 && (
        <CustomDataGrid
          autoHeight={false}
          hideFooter
          rowHeight={48}
          headerHeight={40}
          rowCount={movements.length}
          columns={columns}
          rows={movements}
          loading={false}
          disableSelectionOnClick={true}
          classes={{
            root: classes.root,
            columnHeader: classes.headerWrapper,
          }}
        />
      )}
    </Box>
  );
};

export default DesktopPaymentsTable;
