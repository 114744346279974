import { makeStyles, Theme } from "@material-ui/core";

const usePaymentBreakdownStyles = makeStyles((theme: Theme) => ({
  paymentDetailContainer: {
    width: "100%",
    "& div:nth-child(odd)": {
      backgroundColor: "#F7F9F9",
    },
  },
  totalDetailWrapper: {
    width: "100%",
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#DFE5E8",
    borderRadius: 2,
    padding: theme.spacing(0.25, 0.5),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1),
    },
  },
  totalDetail: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
}));

export default usePaymentBreakdownStyles;
