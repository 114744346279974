import { makeStyles, Theme } from "@material-ui/core";

const useBreakdownStyles = makeStyles<Theme>((theme) => ({
  detail: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 2,
    padding: theme.spacing(0.25, 0.5),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0.5),
    },
  },
  detailLabel: {
    color: theme.palette.grey["A400"],
    maxWidth: "70%",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
  detailValue: {
    color: theme.palette.grey["A400"],
    minWidth: "30%",
    textAlign: "end",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
}));

export default useBreakdownStyles;
