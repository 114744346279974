import { makeStyles } from "@material-ui/core";

const useHelpWidgetDesktopTooltipStyles = makeStyles((theme) => ({
  desktopTooltip: {
    position: "fixed",
    bottom: 80,
    right: 35,
    zIndex: 9999,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    width: 215,
    height: 144,
    background: theme.typography.button.color,
    borderRadius: 4,
    "&::after": {
      content: "''",
      position: "absolute",
      bottom: -7,
      right: 4,
      width: 0,
      height: 0,
      borderLeft: "6px solid transparent",
      borderRight: "6px solid transparent",
      borderTop: `7px solid ${theme.typography.button.color}`,
    },
  },
  desktopTooltipLinkItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    transition: "filter 0.2s ease-in-out",
    color: "#E9EAEB",
    "&:hover": {
      cursor: "pointer",
      filter: "brightness(0.8)",
    },
  },
  destopTooltipTitle: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    color: "#E9EAEB",
  },
  link: {
    cursor: "pointer",
    width: "100%",
    justifyContent: "space-between",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    color: "#E9EAEB",
  },
  linkIcon: {
    transform: "rotate(-45deg)",
    display: "inline-block",
  },
}));

export default useHelpWidgetDesktopTooltipStyles;
