import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import PlaceToPayDetails from "apps/payments/components/PlaceToPayDetails";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import { SERVICES_URL } from "env";

import withPaymentWrapper from "../PaymentsWrapperHoc";

const PlaceToPay = () => {
  const { onHandleDrawer } = useCustomDrawer();
  const { state } = useRentPayment();

  const getPlaceToPayLink = (movementId: string, method: string) =>
    `${SERVICES_URL}/payments/co/v3/movements/${movementId}/placetopay/start_payment/${method}`;

  const onHandlePayment = (method: string) =>
    window.open(
      getPlaceToPayLink(state.paymentData.movementId, method),
      "_blank"
    );

  const OpenPayEnhancement = withPaymentWrapper(PlaceToPayDetails);

  return (
    <OpenPayEnhancement
      buttonProps={{
        onHandlePayment,
        onHandleBack: onHandleDrawer,
      }}
    />
  );
};

export default PlaceToPay;
