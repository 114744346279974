import { useHistory } from "react-router-dom";

import { Box, Typography } from "@material-ui/core";

import {
  IoHomeOutline,
  IoChevronForward as ChevronRightIcon,
} from "@houm-com/ui-icons";

import usePropertiesCard from "./PropertiesCardStyles";
import CardInfo from "../CardInfo";

const PropertiesCard = () => {
  const history = useHistory();
  const classes = usePropertiesCard();

  return (
    <CardInfo
      cardClassName={classes.card}
      onClick={() => history.push("/landlord")}
    >
      <Box className={classes.container}>
        <Box className={classes.iconContainer}>
          <IoHomeOutline color="#46788C" />
        </Box>
        <Box className={classes.textContainer}>
          <Typography className={classes.text}>Propiedades</Typography>
          <ChevronRightIcon color="#46788C" />
        </Box>
      </Box>
    </CardInfo>
  );
};

export default PropertiesCard;
