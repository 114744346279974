import statuses from "./utils";

const useBillStatus = (status: string, error: boolean) => {
  if (error || !statuses[status]) {
    return statuses.failure;
  }
  return statuses[status];
};

export default useBillStatus;
