import currency from "@houm-com/formats/currency";

import { CurrencyCodeType } from "apps/landlord/models/Currency";
import { RentStatusType } from "apps/landlord/models/Property";
import { Locale } from "models/Countries";

import { IMainPropertyPropertyAnalysis } from "../../../hooks/useMainProperty/types";
import { AnalysisTypeProperty } from "../../../utils/types";

export const getPrice = (
  price: number,
  currencyCode: CurrencyCodeType
): string =>
  currency(price, currencyCode, {
    minimumFractionDigits: 0,
  });

export const getCurrentPrice = (
  property: IMainPropertyPropertyAnalysis,
  locale: Locale,
  type: AnalysisTypeProperty
) => {
  const currencyCodeRent =
    property.forRental && property?.propertyDetails[0]?.currencyCode;
  const currencyCodeSale =
    property.forSale && property?.salesDetails?.currencyCode;

  // CURRENT PRICE RENTAL
  const actualRentPrice = property?.propertyDetails[0]?.value;
  const actualRentPriceCLF =
    locale === "cl" && property?.propertyDetails[0]?.valueClf;

  // CURRENT PRICE SALE
  const actualSalePrice = property?.salesDetails?.price;
  const actualSalePriceCLF =
    locale === "cl" && property?.salesDetails?.priceClf;

  if (type === "rent")
    return {
      primaryPrice: getPrice(
        actualRentPrice,
        locale === "cl" ? "CLP" : (currencyCodeRent as CurrencyCodeType)
      ),
      secondaryPrice: locale === "cl" && getPrice(actualRentPriceCLF, "CLF"),
    };
  if (type === "sale")
    return {
      primaryPrice: getPrice(
        actualSalePrice,
        currencyCodeSale as CurrencyCodeType
      ),
      secondaryPrice: actualSalePriceCLF && getPrice(actualSalePriceCLF, "CLF"),
    };
};

export const getRecommendedPrice = (
  property: IMainPropertyPropertyAnalysis,
  locale: Locale,
  type: AnalysisTypeProperty
) => {
  const currencyCodeRent =
    property.forRental && property?.propertyDetails[0]?.currencyCode;
  const currencyCodeSale =
    property.forSale && property?.salesDetails?.currencyCode;

  // CURRENT PRICE RENTAL
  const suggestedRentValue = property?.vitruvioSuggest;
  const suggestedRentValueInUF = property?.vitruvioSuggestClf;

  // CURRENT PRICE SALE
  const suggestedSaleValueInCLP = property?.salesDetails?.recommendedPriceClp;
  const suggestedSaleValue = property?.salesDetails?.recommendedPrice;

  if (type === "rent")
    return {
      primaryPrice: getPrice(
        suggestedRentValue,
        locale === "cl" ? "CLP" : (currencyCodeRent as CurrencyCodeType)
      ),
      secondaryPrice:
        locale === "cl" && getPrice(suggestedRentValueInUF, "CLF"),
    };
  if (type === "sale")
    return {
      primaryPrice: getPrice(
        suggestedSaleValue,
        locale === "cl" ? "CLF" : (currencyCodeSale as CurrencyCodeType)
      ),
      secondaryPrice:
        locale === "cl" && getPrice(suggestedSaleValueInCLP, "CLP"),
    };
};

export const isButtonAvailable = (
  type: AnalysisTypeProperty,
  status: RentStatusType
) => {
  if (type === "rent") return status !== "rented";
  return true;
};
