import { ReactNode } from "react";
import clx from "classnames";
import useStyles from "./CallToActionStyles";

interface Props {
  children: ReactNode;
  className?: string;
}

const CallToAction = ({ children, className }: Props) => {
  const classes = useStyles();
  return <div className={clx(classes.root, className)}>{children}</div>;
};

export default CallToAction;
