import { makeStyles, Theme } from "@material-ui/core";

const useDesktopViewStyles = makeStyles((theme: Theme) => ({
  desktopViewRoot: {
    display: "flex",
  },
  calendarWrapper: {
    flex: 1,
  },
  listWrapper: {
    flex: 1,
    marginLeft: theme.spacing(3),
  },
}));

export default useDesktopViewStyles;
