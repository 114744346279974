import { AxiosResponse } from "axios";
import { Locale } from "models/Countries";
import { IContractMovements, IMovementResponse } from "./types";
import { createHttp } from "services/helper";
import { PAYMENTS_URL } from "env";

export default {
  getPaymentDetailByMovementId: (
    country: Locale,
    movementId: string,
    token: string
  ): Promise<AxiosResponse<IMovementResponse>> =>
    createHttp(token, PAYMENTS_URL).get(
      `/${country}/v3/movements/${movementId}`
    ),
  getContractMovements: (
    country: Locale,
    subId: number,
    token: string
  ): Promise<AxiosResponse<IContractMovements>> =>
    createHttp(token).get(
      `/v2/movements/${country}/subscriptions/${subId}?type=income`
    ),
};
