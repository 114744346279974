import { ReactNode } from "react";

import { Typography } from "@material-ui/core";
import ArrowForward from "@material-ui/icons/ArrowForward";

import useHelpCardStyles from "./HelpCardStyles";

export interface HelpCardProps {
  icon: ReactNode;
  title: string;
  description: string | ReactNode;
  ctaText: string;
  clickHandler?: () => void;
}

const HelpCard = ({
  icon,
  title,
  description,
  ctaText,
  clickHandler,
}: HelpCardProps) => {
  const classes = useHelpCardStyles();
  return (
    <div className={classes.card}>
      <div className={classes.titleContainer}>
        {icon}
        <Typography component="h2" className={classes.title}>
          {title}
        </Typography>
      </div>
      <Typography className={classes.description}>{description}</Typography>
      <Typography onClick={clickHandler} className={classes.cta}>
        {ctaText}
        <ArrowForward />
      </Typography>
    </div>
  );
};

export default HelpCard;
