import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

import { useGetToken } from "hooks";

import {
  ChangePropertyStatusArgs,
  ChangePropertyStatusOperationType,
} from "apps/landlord/services/propertyService/type";
import { PropertyBusinessType } from "apps/landlord/models/Property";
import propertyService from "apps/landlord/services/propertyService";
import {
  handleFailedReasonsRequests,
  handleFailedUnpublishRequest,
  handleSuccessfullUnpublishRequest,
} from "./utils";
import { defaultUnpublishOptions } from "./constants";

interface UsePropertyVisibilityProps {
  mode?: PropertyBusinessType;
  propertyId: number;
}

type UnpublishPropertyArgs = Pick<
  ChangePropertyStatusArgs,
  "observation" | "reasonId"
>;

const useChangePropertyVisibility = ({
  mode,
  propertyId,
}: UsePropertyVisibilityProps) => {
  const { getToken } = useGetToken();

  const handleGetOperationReasons = async () => {
    const authToken = await getToken();
    const { data: reasons } = await propertyService.getOperationReasons({
      businessType: mode,
      token: authToken,
      operationType: ChangePropertyStatusOperationType.UNPUBLISH,
    });
    if (!reasons.length) {
      return defaultUnpublishOptions(mode);
    }
    return reasons.map((reason) => ({
      label: reason.label,
      value: String(reason.value),
    }));
  };

  const handleUnpublishProperty = async ({
    observation,
    reasonId,
  }: UnpublishPropertyArgs) => {
    const authToken = await getToken();
    const { data: unpublishPropertyResponse } =
      await propertyService.unpublishPropertyById({
        observation,
        token: authToken,
        propertyId,
        reasonId,
      });
    return unpublishPropertyResponse;
  };

  const { data: operationReasons = [], isLoading: isLoadingOperationReasons } =
    useQuery([mode, "operationReasons"], handleGetOperationReasons, {
      onError: handleFailedReasonsRequests,
    });

  const {
    mutateAsync: handleUnpublishPropertyAction,
    isLoading: isLoadingUnpublishPropertyAction,
    isError: isErrorUnpublishPropertyAction,
    isSuccess: isSuccessUnpublishPropertyAction,
  } = useMutation(handleUnpublishProperty, {
    onSuccess: handleSuccessfullUnpublishRequest,
    onError: handleFailedUnpublishRequest,
  });

  const formMethods = useForm<UnpublishPropertyArgs>();

  const [reasonId] = formMethods.watch(["reasonId"]);

  const onSubmitForm = formMethods.handleSubmit(
    (formData: UnpublishPropertyArgs) =>
      handleUnpublishPropertyAction({
        reasonId: formData.reasonId,
        observation: formData.observation,
      })
  );

  return {
    reasonId,
    operationReasons,
    formMethods,
    onSubmitForm,
    handleUnpublishPropertyAction,
    isLoadingOperationReasons,
    isErrorUnpublishPropertyAction,
    isLoadingUnpublishPropertyAction,
    isSuccessUnpublishPropertyAction,
  };
};

export default useChangePropertyVisibility;
