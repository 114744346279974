import { CountryUpperCase, Currency, Locale } from "models/Countries";

const countryCurrency = (country: CountryUpperCase): CurrencyAndCode => {
  switch (country) {
    case "Chile":
      return {
        currency: "CLP",
        code: "cl",
      };
    case "Colombia":
      return { currency: "COP", code: "co" };
    case "Mexico":
      return { currency: "MXN", code: "mx" };
    default:
      return { currency: "MXN", code: "mx" };
  }
};

export default countryCurrency;

interface CurrencyAndCode {
  currency: Currency;
  code: Locale;
}
