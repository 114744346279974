import { ComponentProps, useEffect, useState } from "react";

import FieldInput from "@houm-com/ui/FieldInput";

import { AddressSearch } from "apps/lead/components/AddressSuggester/utils/types";
import SearchDropDown from "apps/lead/components/AddressSuggester/components/SearchDropDown";
import useAddressDetails from "apps/lead/components/AddressSuggester/hooks/useAddressDetails";
import useDebounceState from "apps/lead/components/AddressSuggester/hooks/useDebounceState";
import { Locale } from "models/Countries";

interface Props {
  onChange?: (value: string) => void;
  onCheck?: (value: boolean) => void;
  textFieldProps?: ComponentProps<typeof FieldInput>;
  countryCode: Locale;
  defaultValue?: string;
}

const AddressSuggester = ({
  textFieldProps,
  countryCode,
  onChange,
  onCheck,
  defaultValue = "",
}: Props) => {
  const [query, setQuery] = useState<string>("");
  const [selected, setSelected] = useState<AddressSearch | null>(null);

  const { data: details, status: detailsStatus } = useAddressDetails({
    referenceId: selected?.reference_id,
    countryCode,
  });

  useEffect(() => {
    if (detailsStatus === "success") onChange?.(details.full_address);
  }, [details, detailsStatus, onChange]);

  const handleSearch = (value: AddressSearch | null) => {
    onCheck?.(true);
    setSelected(value);
  };

  const handleClear = () => {
    setSelected(null);
    setQuery("");
  };

  const debouncedQuery = useDebounceState(query, 500);

  return (
    <div>
      <FieldInput
        {...textFieldProps}
        onChange={(e) => {
          if (selected || e.target.value === "") handleClear();
          setQuery(e.target.value);
        }}
      />
      <SearchDropDown
        countryCode={countryCode}
        handleSearch={handleSearch}
        queryAddressSuggestions={debouncedQuery}
        defaultAddress={defaultValue}
        selectedAddress={selected}
        handleCheck={onCheck}
      />
    </div>
  );
};

export default AddressSuggester;
