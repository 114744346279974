import { makeStyles, Theme } from "@material-ui/core";
import {
  MOBILE_BOTTOMNAVBAR_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
} from "apps/lead/constants/layout";

const defaultBreakpoint = "sm";

const useEvaluatorLayoutStyles = makeStyles<Theme>((theme) => ({
  evaluatorFormRoot: {
    backgroundColor: "white",
  },
  navBarRoot: {
    position: "fixed",
    zIndex: theme.zIndex.appBar,
    top: 0,
    left: 0,
    height: MOBILE_HEADER_HEIGHT,
    width: "100%",
    padding: theme.spacing(1, 2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    filter: "drop-shadow(0px 4px 4px rgba(96, 108, 129, 0.1))",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      flexDirection: "row",
      padding: theme.spacing(2, 5),
      height: DESKTOP_HEADER_HEIGHT,
    },
  },
  navbarImg: {
    height: 32,
    width: 68,
  },
  backStep: {
    display: "flex",
    alignItems: "center",
  },
  containerEvaluatorLayout: {
    padding: theme.spacing(0, 2),
    paddingBottom: MOBILE_BOTTOMNAVBAR_HEIGHT,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(0),
      paddingBottom: theme.spacing(11.25),
    },
  },
}));

export default useEvaluatorLayoutStyles;
