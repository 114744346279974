import { ReactNode, createContext, useContext } from "react";

import { Locale } from "models/Countries";
import useContract from "apps/tenant/hooks/useContract";
import useGetCurrentUser from "hooks/useGetCurrentUser";
import { countryCode } from "utils";

export interface MarketContextProps {
  market: Locale | null;
}

const MarketContext = createContext<MarketContextProps>({
  market: null,
});

const MarketProvider = ({ children }: { children: ReactNode }) => {
  const { data: contract } = useContract();
  const { user } = useGetCurrentUser();
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const initialState = {
    market: contract?.country || (user && countryCode(user?.country)) || null,
  };
  return (
    <MarketContext.Provider value={initialState}>
      {children}
    </MarketContext.Provider>
  );
};

const useMarket = () => {
  const context = useContext(MarketContext);
  if (context === undefined) {
    throw new Error("useMarket must be used within a MarketProvider");
  }
  return context;
};

export { MarketProvider, useMarket };
