import { v4 as uuidv4 } from "uuid";

import { uploadToS3 } from "utils/fileStreaming";

import { IRepairRequestBody } from "apps/landlord/services/repairRequestService/types";
import repairRequestService from "../../services/repairRequestService";
import { IRepairRequest } from "./types";

export const getPhotoFileName = (name: string) => {
  const fileExtension = name.split(".").pop();
  const uuid = uuidv4();
  const fileName = `repairment_requests_${uuid}.${fileExtension}`;
  return fileName;
};

export const uploadRepairPhotos = async (
  token: string,
  photos: File[]
): Promise<string[]> => {
  try {
    const fileNames = photos.map((file) => getPhotoFileName(file.name));
    const presignedUrls = await repairRequestService.getPresignedUrl(token, {
      file_names: fileNames,
    });
    Object.entries(presignedUrls.data).forEach(
      async ([fileName, presignedUrl], index) => {
        await uploadToS3({
          fileContents: photos[index],
          presignedUrl: { ...presignedUrl, filePath: fileName },
        });
      }
    );
    return fileNames;
  } catch (error) {
    return null;
  }
};

export const encodeRepairRequest = (
  data: Pick<IRepairRequest, "observation" | "repairType">,
  filenames
): IRepairRequestBody => {
  const { repairType, observation } = data;
  const photos = filenames.map((filename) => ({
    photo_filename: filename,
  }));
  return {
    repairment_type: repairType,
    observation,
    photos,
    requestor_role: "landlord",
    origin: "admin_landlord",
  };
};
