import { add, parse } from "date-fns";
import { useMutation, useQuery } from "react-query";

import { useGetToken } from "hooks";
import { useHoumSnackBar } from "libs/houm-snackbar";
import PropertyContractService from "apps/landlord/services/propertyContractService";
import { SnackbarPayload } from "libs/houm-snackbar/types";
import { FAILED_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from "./constants";
import { encodeEndOfContractData, EoCLeavingDateForm } from "./utils";

const usePropertyEndOfContract = ({ propertyId }: { propertyId: number }) => {
  const { getToken } = useGetToken();
  const { enqueueHoumSnackBar } = useHoumSnackBar();

  const handleGetEoCTarget = async () => {
    const token = await getToken();
    const propertyContractService = PropertyContractService({ token });
    const { data: contractData } =
      await propertyContractService.getActiveContract({
        propertyId,
      });
    return contractData;
  };

  const {
    data: endOfContractTarget,
    isLoading: isLoadingEndOfContractTarget,
    isError: isErrorEndOfContractTarget,
  } = useQuery(["endOfContractTarget", propertyId], handleGetEoCTarget, {
    enabled: !!propertyId,
  });

  const handleGetEoCStatus = async () => {
    const token = await getToken();
    const propertyContractService = PropertyContractService({ token });
    const { data: processStatusData } =
      await propertyContractService.getCloseContractProcessStatus({
        contractId: endOfContractTarget?.id,
      });
    return processStatusData;
  };

  const {
    data: endOfContractStatus,
    isLoading: isLoadingEndOfContractStatus,
    isError: isErrorEndOfContractStatus,
    refetch: refetchProcessStatus,
  } = useQuery(["end_of_contract_status", propertyId], handleGetEoCStatus, {
    enabled: Boolean(endOfContractTarget?.id),
  });

  const handleSubmitEndOfContract = async ({
    date,
    motive,
    observations,
  }: EoCLeavingDateForm) => {
    const token = await getToken();
    const propertyContractService = PropertyContractService({ token });
    const parsedPayload = encodeEndOfContractData({
      endOfContractDate: date,
      endOfContractTarget,
      reason: motive,
      observations,
      hasLimitDateCondition: false,
    });

    const { data: endOfContractData } =
      await propertyContractService.closePropertyContract(parsedPayload);
    return endOfContractData;
  };

  const {
    mutate: submitEndOfContract,
    isLoading: isLoadingSubmitEndOfContract,
    isSuccess: isSuccessSubmitEndOfContract,
    isError: isErrorSubmitEndOfContract,
  } = useMutation(handleSubmitEndOfContract, {
    onSuccess: () => {
      enqueueHoumSnackBar(SUCCESS_SNACKBAR_OPTIONS as SnackbarPayload);
      refetchProcessStatus();
    },
    onError: () => {
      enqueueHoumSnackBar(FAILED_SNACKBAR_OPTIONS as SnackbarPayload);
    },
  });

  const activeContractInitialDate = parse(
    endOfContractTarget?.init_date || new Date().toString(),
    "yyyy-MM-dd",
    new Date()
  );

  const yearPostContractInitialDate = add(activeContractInitialDate, {
    years: 1,
  });

  return {
    endOfContractTarget,
    endOfContractStatus,
    submitEndOfContract,
    isDateAfterYear: new Date() > yearPostContractInitialDate,
    isSuccessSubmitEndOfContract,
    isLoadingSubmitEndOfContract,
    isLoadingEndOfContractStatus,
    isLoadingEndOfContractTarget,
    isErrorSubmitEndOfContract,
    isErrorEndOfContractStatus,
    isErrorEndOfContractTarget,
  };
};

export default usePropertyEndOfContract;
