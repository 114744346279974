import { IAuthMenuItem, TranslationObject } from "apps/ui/Navbar/utils/types";

export const generateKey = (label: string | TranslationObject): string => {
  if (typeof label === "string") return label;
  return label.key;
};

export const getAuthMenuContent = (
  usingAs: "tenant" | "landlord" | null
): IAuthMenuItem[] => [
  {
    label: "Tipo de cuenta",
  },
  {
    label: {
      key: usingAs === "landlord" ? "landlord-using" : "switch-landlord",
      namespace: "shared.navbar",
    },
    link: "/landlord",
    nested: true,
    selected: usingAs === "landlord",
  },
  {
    label: {
      key: usingAs === "tenant" ? "tenant-using" : "switch-tenant",
      namespace: "shared.navbar",
    },
    link: "/tenant",
    nested: true,
    selected: usingAs === "tenant",
  },
  {
    label: { key: "visits", namespace: "shared.navbar" },
    link: "/tenant/visits",
  },
  {
    label: { key: "profile", namespace: "shared.navbar" },
    link: "/account",
  },
  {
    label: { key: "logout", namespace: "shared.navbar" },
    link: "/logout",
    isLogout: true,
  },
];
