import { useParams } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";
import Swal from "sweetalert2";

import { Box, Grid, Typography } from "@material-ui/core";

import Loading from "Components/Admin/UIComponents/molecules/Loading";

import useInspectionReportsStyles from "./InspectionReportsStyles";
import HabitableDisclaimer from "./components/HabitableDisclaimer";
import useInspectionsById from "../../hooks/useInspectionById";
import InspectionMobile from "./components/InspectionMobile";
import InspectionTable from "./components/InspectionTable";

const InspectionReports = () => {
  const classes = useInspectionReportsStyles();
  const { reportId } = useParams<{ reportId: string }>();
  const { data, isLoading, isError } = useInspectionsById(reportId);
  if (isLoading) return <Loading loading />;
  if (isError) {
    Swal.fire(
      "Ha ocurrido un error",
      "No pudimos obtener tu acta de inspección. Vuelve a intentarlo más tarde.",
      "error"
    );
  }

  return (
    <>
      <Grid item xs={12} className={classes.container}>
        {data?.overallObservation && (
          <Box className={classes.observationContainer}>
            <Typography className={classes.observationTitle}>
              Observación general
            </Typography>
            <Typography className={classes.observationText}>
              {data.overallObservation}
            </Typography>
          </Box>
        )}
        <HabitableDisclaimer />
        {!data?.is_habitable && (
          <Box className={classes.disclaimerContainer}>
            <HabitableDisclaimer />
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        {isBrowser && (
          <InspectionTable inspectionReport={data} isLoading={isLoading} />
        )}
        {isMobile && <InspectionMobile inspectionReport={data} />}
      </Grid>
    </>
  );
};

export default InspectionReports;
