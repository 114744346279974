import clsx from "clsx";
import { getCountry } from "country-list-spanish";
import { getCountries, getCountryCallingCode } from "libphonenumber-js";
import {
  FC,
  forwardRef,
  HTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from "react";

import { IoChevronDown as DownArrow } from "@houm-com/ui-icons";

import usePhoneInputStyles from "./PhoneInputStyles";
import { Country } from "./types";
import Flag from "./components/Flag/Flag";
import CountryOptions from "./components/CountryOptions";
import useSelectDropdown from "./hooks/useSelectDropdown";

interface ClassNamesProps {
  root?: string;
}

interface PhoneInputProps extends HTMLAttributes<HTMLSelectElement> {
  classNames?: ClassNamesProps;
  disabled?: boolean;
  id: string;
  setValue: (key: string, value: string) => void;
}

const PhoneInput: FC<PhoneInputProps> = forwardRef<
  HTMLSelectElement,
  PhoneInputProps
>(({ classNames, disabled, defaultValue, id, setValue, ...props }, ref) => {
  const { buttonRef, handleClick, open, setOpen } = useSelectDropdown({
    disabled,
  });
  const [selected, setSelected] = useState<Country | null>();
  const classes = usePhoneInputStyles({ disabled });

  const countries: Country[] = useMemo(
    () =>
      getCountries()
        .map((country) => ({
          name: getCountry(country),
          code: country.toLocaleLowerCase(),
          callingCode: `+${getCountryCallingCode(country)}`,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    []
  );

  useEffect(() => {
    const defaultCountry = countries?.find(
      (country) => country.code === defaultValue
    );
    setSelected(defaultCountry);
    setValue(id, defaultCountry?.callingCode);
  }, [defaultValue, countries, id, setValue]);

  useEffect(() => {
    const selectElement = document.getElementById(id);
    if (selectElement && selected?.callingCode) {
      setValue(id, selected?.callingCode);
    }
  }, [selected, id, setValue]);

  return (
    <>
      <select
        className={classes.hidden}
        id={id}
        ref={ref}
        disabled={disabled}
        {...props}
      >
        {countries.map((country) => (
          <option
            key={country.code}
            id={country.code}
            value={country.callingCode}
          >
            {country.name}
          </option>
        ))}
      </select>
      <span
        className={clsx(classes.phoneInputRoot, classNames?.root)}
        onClick={handleClick}
        ref={buttonRef}
        data-testid="phone-input"
      >
        <CountryOptions
          data-testid="country-options"
          countries={countries}
          open={open}
          setOpen={setOpen}
          setSelected={setSelected}
        />
        <Flag country={selected} size={12} />
        <span className={classes.code}>{selected?.callingCode}</span>
        <DownArrow className={classes.arrow} />
      </span>
    </>
  );
});

export default PhoneInput;
