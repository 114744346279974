import { Divider, Typography } from "@material-ui/core";

import SwitchSelector from "apps/lead/components/SwitchSelector";
import { WorkerType } from "apps/lead/models/Application";

import useWorkerTypeSelectionStyles from "./WorkerTypeSelectionStyles";

interface Props {
  onHandleChange: (e: WorkerType) => void;
  workerType: WorkerType;
}

const WorkerTypeSelection = ({ onHandleChange, workerType }: Props) => {
  const classes = useWorkerTypeSelectionStyles();

  const OPTIONS = [
    { label: "Dependiente", key: "dependent" },
    { label: "Independiente", key: "independent" },
    { label: "Pensionado", key: "pensioner" },
  ];

  return (
    <>
      <Typography component="h1" className={classes.title}>
        Tipo de trabajador
      </Typography>
      <SwitchSelector
        options={OPTIONS}
        defaultValue={workerType}
        handleChange={(e) => onHandleChange(e as WorkerType)}
      />
      <Divider className={classes.divider} />
    </>
  );
};

export default WorkerTypeSelection;
