import { Theme, makeStyles } from "@material-ui/core";

interface Props {
  useFormLabel: boolean;
}

export default makeStyles<Theme, Props>((theme) => ({
  wrapper: {
    display: "flex",
    margin: "0px",
    width: "100%",
    padding: theme.spacing(0.3),
    justifyContent: "space-between",
  },
  formLabel: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  label: {
    marginBottom: "0px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  box: {
    marginLeft: theme.spacing(6),
    transform: "scale(1.2)",
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(4),
      transform: "scale(0.9)",
    },
  },
  buttonsList: {
    marginTop: ({ useFormLabel }) => (!useFormLabel ? theme.spacing(2) : 0),
  },
  container: {
    marginTop: theme.spacing(2),
  },
}));
