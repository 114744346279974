import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useState } from "react";
import { isMobile } from "react-device-detect";

import { Box } from "@material-ui/core";

import useContract from "apps/tenant/hooks/useContract";
import useCurrentMovement from "apps/tenant/hooks/useCurrentMovement";
import useUser from "apps/tenant/hooks/useUser";
import Spinner from "Components/Shared/partials/Spinner";
import { countryCode, numberWithCommas } from "utils";

import Breakdown from "./components/Breakdown";
import GeneralInfo from "./components/GeneralInfo";
import { hasBreakdown } from "./utils/utils";
import usePaymentDataStyles from "./PaymentDataStyles";

const PaymentData = () => {
  const { data: contract } = useContract();
  const classes = usePaymentDataStyles();
  const [showBreakdown, setShowBreakdown] = useState(!isMobile);
  const {
    user: { country },
  } = useUser();
  const { data, isLoading, isError } = useCurrentMovement();

  if (isLoading) return <Spinner />;

  if (isError || !data || !contract) return null;

  return (
    <Box className={classes.paymentDataRoot}>
      <GeneralInfo
        dueDate={format(data.agreedDate, "dd 'de' MMMM", { locale: es })}
        hasBreakdown={hasBreakdown(data)}
        totalAmount={`$${numberWithCommas(data.totalAmount)} ${
          data.currency || ""
        }`}
        showBreakdown={showBreakdown}
        status={data.status}
        toggleBreakdown={() => setShowBreakdown(!showBreakdown)}
      />
      <Breakdown
        showBreakdown={showBreakdown}
        country={countryCode(country)}
        {...data}
      />
    </Box>
  );
};

export default PaymentData;
