import { useQuery, UseQueryResult } from "react-query";

import { IReceiptsResponse } from "apps/landlord/services/paymentService/types";
import paymentService from "apps/landlord/services/paymentService";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

const useGetReceipts = (country: Locale, movementId: string) => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    isError,
    data = [],
  }: UseQueryResult<IReceiptsResponse[], Error> = useQuery<
    IReceiptsResponse[],
    Error
  >(["receipts", country, movementId], async () => {
    const authToken = await getToken();
    const receiptsResponse = await paymentService.getReceipts(
      movementId,
      authToken
    );
    return receiptsResponse.data;
  });

  return {
    isLoading,
    isError,
    data,
  };
};

export default useGetReceipts;
