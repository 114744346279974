import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";
const extraSmallBreakpoint = "xs";

const useStartEvaluationLandingStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginTop: theme.spacing(12.5),
    },
  },
  customCloseButton: {
    left: "95%",
    top: "-10%",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[900],
    },
    [theme.breakpoints.down(extraSmallBreakpoint)]: {
      left: "90%",
      top: "-8%",
    },
    [theme.breakpoints.up(defaultBreakpoint)]: {
      left: "97%",
      top: "-10%",
    },
  },
}));

export default useStartEvaluationLandingStyles;
