import { makeStyles, Theme } from "@material-ui/core/";
import {
  MOBILE_BOTTOMNAVBAR_HEIGHT,
  MOBILE_HEADER_SPACING,
} from "apps/lead/constants/layout";

const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    margin: theme.spacing(MOBILE_HEADER_SPACING / 8, "auto", 4),
    width: "100%",
    padding: theme.spacing(0, 2, 8),
    paddingBottom: MOBILE_BOTTOMNAVBAR_HEIGHT,
    height: `calc((${window.innerHeight} * 0.01) * 100)`,
  },
}));

export default useStyles;
