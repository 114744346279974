import { FormEvent, useCallback, useEffect } from "react";

import { useHistory } from "react-router-dom";

import {
  setActiveStep,
  setApplicant,
  setCodebtor,
} from "apps/lead/context/codebtorContext/CodebtorActions";
import { useCodebtor } from "apps/lead/context/codebtorContext/CodebtorContext";
import { Applicant } from "apps/lead/context/codebtorContext/CodebtorTypes";
import { PersonsRole } from "apps/lead/models/Application";

import { getRole } from "../utils/utils";

const useSelectApplicant = () => {
  const history = useHistory();
  const { state, dispatch } = useCodebtor();
  const { evaluation, loading, activeStep, steps, applicant } = state;

  const handleBackClick = () => history.push("/tenant");

  const handleNextClick = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setActiveStep(activeStep + 1));
  }, []);

  const compareRole = (a: Partial<Applicant>, b: Partial<Applicant>) => {
    if (a.role < b.role) return 1;
    if (a.role > b.role) return -1;
    return 0;
  };

  const sortEvaluation = () => {
    const filterRejectedEvaluations = {
      ...evaluation,
      persons: evaluation?.persons.filter(
        (person) =>
          person.status !== "Desistido" && person.status !== "Rechazado"
      ),
    };
    return filterRejectedEvaluations?.persons?.sort(compareRole);
  };

  useEffect(() => {
    const newEvaluation = sortEvaluation();
    if (newEvaluation?.length === 1) {
      const applicantPreSelected = newEvaluation[0];
      const role = applicantPreSelected.role as PersonsRole;
      dispatch(
        setApplicant({
          person: applicantPreSelected,
          role: getRole(role),
        })
      );
      dispatch(setCodebtor(applicantPreSelected));
    }
  }, [evaluation]);

  return {
    evaluation: sortEvaluation(),
    handleBackClick,
    handleNextClick,
    loading,
    activeStep,
    steps,
    applicant,
  };
};

export default useSelectApplicant;
