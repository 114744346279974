import { useState } from "react";
import { useQuery, UseQueryResult } from "react-query";

import propertyService from "apps/landlord/services/propertyService";
import { IProperty } from "apps/landlord/models/Property";
import { useGetToken } from "hooks";

import { parseSearchProperty } from "../utils/parsers";

const useProperties = (
  type: string = "search",
  valueSearch: string = "",
  params: Object = { page_size: 10 },
  handleResultData: (properties: Partial<IProperty>[]) => void = null
) => {
  const [pageNumber, setPageNumber] = useState(1);
  const { getToken } = useGetToken();

  const changePage = (num: number) => setPageNumber(num);

  interface IResponse {
    properties: Partial<IProperty>[];
    pageCount: number;
    forRentalProperties: Partial<IProperty>[];
    forSaleProperties: Partial<IProperty>[];
    rentedProperties: Partial<IProperty>[];
  }

  const {
    isLoading = true,
    isError,
    data = {
      properties: [],
      pageCount: 0,
      forRentalProperties: [],
      forSaleProperties: [],
      rentedProperties: [],
    },
    refetch,
  }: UseQueryResult<IResponse, Error> = useQuery<IResponse, Error>(
    [type, valueSearch, pageNumber, params],
    async () => {
      const authToken = await getToken();
      const result = await propertyService.searchProperty(
        valueSearch,
        authToken,
        { ...params, page: pageNumber }
      );
      const value = result.data.results;
      const parsedProperties = parseSearchProperty(value);
      return {
        properties: parsedProperties,
        pageCount: Math.ceil(result.data.count / params["page_size"]),
        forRentalProperties: parsedProperties.filter(
          (property) =>
            property.forRental && property.propertyDetails.status !== "rented"
        ),
        forSaleProperties: parsedProperties.filter(
          (property) => property.forSale
        ),
        rentedProperties: parsedProperties.filter(
          (property) =>
            property.forRental && property.propertyDetails.status === "rented"
        ),
      };
    },
    {
      onSuccess: (result: { properties: Partial<IProperty>[] }) =>
        handleResultData && handleResultData(result?.properties),
    }
  );

  return {
    isLoading,
    isError,
    data,
    changePage,
    refetch,
  };
};

export default useProperties;
