import { makeStyles, Theme } from "@material-ui/core/";

const useDetailsElementStyles = makeStyles<Theme>((theme) => ({
  informationElement: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  informationText: {
    margin: theme.spacing("auto", 1),
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export default useDetailsElementStyles;
