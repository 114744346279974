import clx from "classnames";

import { Card, Grid, Typography } from "@material-ui/core";

import {
  setApplicant,
  setCodebtor,
} from "apps/lead/context/codebtorContext/CodebtorActions";
import { useCodebtor } from "apps/lead/context/codebtorContext/CodebtorContext";
import { Applicant } from "apps/lead/context/codebtorContext/CodebtorTypes";
import useCardApplicantStyles from "./CardApplicantStyles";
import StatusTag from "./components/StatusTag";

interface Props {
  applicant: Partial<Applicant>;
  role: string;
}

const CardApplicant = ({ applicant, role }: Props) => {
  const { status, name, lastName, id } = applicant;
  const classes = useCardApplicantStyles();
  const { state, dispatch } = useCodebtor();
  const { applicant: applicantSelected, codebtor } = state;

  const selectApplicant = (userId: number) => {
    if (codebtor?.id === userId) return;
    dispatch(
      setApplicant({
        person: applicant,
        role,
      })
    );
    dispatch(setCodebtor(applicant));
  };

  return (
    <Card
      elevation={0}
      className={clx(classes.card, {
        [classes.cardSelected]: applicantSelected?.person?.id === id,
      })}
      onClick={() => selectApplicant(id)}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={6}>
          <Typography
            className={clx(classes.text, classes.textBold)}
          >{`${name} ${lastName}`}</Typography>
          <Typography className={classes.text}>{`| ${role}`}</Typography>
        </Grid>
        <StatusTag status={status} />
      </Grid>
    </Card>
  );
};

export default CardApplicant;
