import { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { useProperty } from "apps/landlord/hooks";
import { BSCompanyForm } from "apps/landlord/models/ServicesCompanies";
import Spinner from "Components/Shared/partials/Spinner";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import useBillsInfo from "apps/landlord/hooks/propertyBills/useBillInfo";
import useCompanies from "apps/landlord/hooks/propertyBills/useCompanies";
import useChangeBasicServices from "apps/landlord/hooks/propertyBills/useChangeBasicServices";
import useBasicServicesStepStyles from "./BasicServicesStepStyles";

import formSchema from "../../PropertyDetails/components/PropertyDetailsContent/components/BasicServicesForm/validationSchema";
import useBasicServicesFormView from "../hooks/useBasicServicesFormView";
import BasicServicesFields from "./BasicServicesFields";

const BasicServicesStep = () => {
  const classes = useBasicServicesStepStyles();
  const { propertyData } = useProperty();
  const { isLoading: companiesLoading } = useCompanies();
  const { isLoading: billsLoading } = useBillsInfo();
  const { mutate, submitting, isSuccess } = useChangeBasicServices();
  const queryClient = useQueryClient();
  const methods = useForm<BSCompanyForm>({
    resolver: yupResolver(formSchema),
    reValidateMode: "onSubmit",
  });
  const { returnToPropertyDetail, returnFromBasicServices } =
    useBasicServicesFormView();

  useEffect(() => {
    if (isSuccess) {
      returnToPropertyDetail();
      queryClient.invalidateQueries("property");
      queryClient.invalidateQueries(`SETTING_UP_STATUS_${propertyData?.id}`);
    }
  }, [isSuccess, propertyData?.id, queryClient, returnToPropertyDetail]);

  const isLoading = billsLoading || companiesLoading;

  return (
    <>
      {isLoading && <Spinner />}
      <Box className={classes.basicServicesPhotoContent}>
        <Box className={classes.basicServicesPhotoContentText}>
          <Typography className={classes.basicServicesTitle}>
            Servicios básicos
          </Typography>
          <Typography className={classes.shedulePhotoContentText}>
            Agrega esta información, la necesitaremos para hacer seguimiento a
            los pagos de tu arrendatario cuando arrendemos tu propiedad
          </Typography>

          <FormProvider {...methods}>
            <form>
              <BasicServicesFields />
            </form>
          </FormProvider>
        </Box>
      </Box>
      <Box className={classes.stickyFooter}>
        <DesignSystemButton
          onClick={returnFromBasicServices}
          variant="tertiaryB"
          size="small"
          label="Volver"
        />
        <DesignSystemButton
          onClick={methods.handleSubmit(mutate as SubmitHandler<BSCompanyForm>)}
          variant="primary"
          size="small"
          disabled={submitting}
          label="Siguiente"
        />
      </Box>
    </>
  );
};

export default BasicServicesStep;
