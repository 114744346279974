import { useQuery, UseQueryResult } from "react-query";

import { Service } from "apps/tenant/models/PropertyServices";
import propertiesDuesService from "apps/tenant/services/propertyBillsService";
import { useGetToken } from "hooks";

import { parseServices } from "./utils/parsers";

const useServices = (propId: number) => {
  const { getToken } = useGetToken();

  const { data, isLoading, isError }: UseQueryResult<Service[], Error> =
    useQuery<Service[], Error>(
      ["tenantServices", propId],
      async () => {
        const authToken = await getToken();
        const response = await propertiesDuesService.getPropertyBills(
          propId,
          authToken
        );
        return parseServices(response.data);
      },
      {
        enabled: !!propId,
      }
    );

  return { data, isLoading, isError };
};

export default useServices;
