import { Typography } from "@material-ui/core";

import { IoChevronForwardOutline as ArrowRight } from "@houm-com/ui-icons";

import { TranslationObject } from "apps/ui/Navbar/utils/types";

import useMobileSubmenuHeaderStyles from "./MobileSubmenuHeaderStyles";
import NavbarLabel from "../../../NavbarLabel/NavbarLabel";

interface Props {
  handleClose: () => void;
  label: string | TranslationObject;
}

const MobileSubmenuHeader = ({ handleClose, label }: Props) => {
  const classes = useMobileSubmenuHeaderStyles();

  return (
    <Typography className={classes.mobileSubmenuHeaderRoot}>
      <span onClick={handleClose}>Menú</span>
      <ArrowRight />
      <strong>
        <NavbarLabel label={label} />
      </strong>
    </Typography>
  );
};

export default MobileSubmenuHeader;
