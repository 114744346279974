import { makeStyles, Theme } from "@material-ui/core";

export const useUserNavbarMenuStyles = makeStyles<Theme>((theme) => ({
  "navbar-menu-wrap": {
    height: "100%",
  },
  "navbar-button-wrap": {
    display: "flex",
    alignItems: "center",
    margin: 0,
    "& button": {
      textTransform: "initial",
      padding: theme.spacing(1),
    },
  },
  "navbar-menu-button": {
    fontWeight: "700 !important" as "bold",
  },
  "navbar-menu-arrow-open": {
    transform: "rotate(180deg) translate(-5px,0)",
    transition: ".2s all linear",
  },
  "navbar-menu-arrow-closed": {
    transform: "none",
    transition: ".2s all linear",
  },
  "navbar-menu-paper": {
    zIndex: theme.zIndex.tooltip,
  },
  "navbar-menu-list": {
    padding: `${theme.spacing(0)} !important`,
    flex: "1 0 auto",
  },
  navbarMenuPaper: {
    borderRadius: 8,
    boxShadow: "0px 4px 15px rgba(38,50,56,0.15)",
    position: "absolute",
  },
}));
