import { Grid } from "@material-ui/core";

import { IMarketPlaceStats } from "apps/landlord/pages/Price/hooks/utils/types";

import InfoRow from "../InfoRow/InfoRow";

interface Props {
  stats: IMarketPlaceStats;
}

const MarketPlaceStats = ({ stats }: Props) => (
  <>
    <Grid item xs={4} md={3}>
      <InfoRow title="Vistas en la web" value={stats?.pageViews} />
    </Grid>
    <Grid item xs={4} md={3}>
      <InfoRow title="Visitas agendadas" value={stats?.allVisits} />
    </Grid>
    <Grid item xs={4} md={3}>
      <InfoRow title="Visitas realizadas" value={stats?.doneVisits} />
    </Grid>
  </>
);

export default MarketPlaceStats;
