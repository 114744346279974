import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useNegotiatonsCardStyles = makeStyles((theme: Theme) => ({
  negotiationCard: {
    width: "100%",
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1),
    minHeight: 90,
    backgroundColor: "white",
    boxShadow: "4px 4px 30px rgba(0, 43, 41, 0.15)",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  amountContainer: {
    height: "100%",
    display: "flex",
    width: 140,
    flexShrink: 0,
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2, 0),
    borderRight: `1px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      width: 200,
    },
    "& p": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontSize: "1.5rem",
      [theme.breakpoints.up(defaultBreakpoint)]: {
        fontSize: "1.75rem",
        padding: theme.spacing(2),
      },
    },
  },
  detailsContainer: {
    width: "100%",
    padding: theme.spacing(0, 1),
  },
  buyerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  buyerNumber: {
    color: theme.palette.grey[500],
    fontSize: "0.75rem",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: "0.875rem",
      marginBottom: theme.spacing(2),
    },
  },
  newOffer: {
    display: "flex",
    background: "#FF705C",
    borderRadius: 10,
    color: "white",
    fontSize: "0.75rem",
    padding: theme.spacing(0, 1),
    alignItems: "center",
    height: "fit-content",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: "1rem",
    },
  },
  payment: {
    display: "flex",
    flexDirection: "column",
  },
  paymentMethod: {
    fontSize: "1rem",
    fontWeight: "bold",
    lineHeight: 1.25,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: "1.25rem",
    },
  },
  paymentMethodText: {
    color: theme.palette.grey[500],
    fontSize: "0.75rem",
    lineHeight: 1.125,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: "1rem",
      marginBottom: 0,
    },
  },
  priceContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      justifyContent: "flex-start",
    },
  },
  price: {
    fontSize: "0.75rem",
    fontWeight: "bold",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: "1rem",
    },
  },
  priceText: {
    fontSize: "0.875rem",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginRight: theme.spacing(2),
      fontSize: "1.125rem",
    },
  },
  offer: {
    [theme.breakpoints.up(defaultBreakpoint)]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
  },
}));

export default useNegotiatonsCardStyles;
