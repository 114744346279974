import { Fragment } from "react";

import { Box, Divider, Typography } from "@material-ui/core";

import { ISimilarProperty } from "apps/landlord/pages/Price/hooks/useSimilarProperties/types";
import { AnalysisTypeProperty } from "apps/landlord/pages/Price/utils/types";
import { Locale } from "models/Countries";

import SimilarPropertyCard from "./components/SimilarPropertyCard";
import useSimilarPropertiesStyles from "./SimilarPropertiesStyles";

interface Props {
  locale: Locale;
  similarProperties: ISimilarProperty[];
  type: AnalysisTypeProperty;
}

const SimilarProperties = ({ locale, similarProperties, type }: Props) => {
  const classes = useSimilarPropertiesStyles();

  return (
    <Box>
      <Typography variant="subtitle1">
        <strong>Propiedades con características similares</strong>
      </Typography>
      <Typography variant="subtitle1">
        Sabemos lo difícil que es encontrar el precio ideal para arrendar una
        propiedad así que nos hicimos cargo de encontrar propiedades similares
        cercanas a la tuya en Houm y en otros portales
      </Typography>
      <Typography variant="subtitle1" className={classes.houm}>
        <strong>En Houm</strong>
      </Typography>
      <Box className={classes.multipleCardContainer}>
        {similarProperties.length > 0 ? (
          similarProperties.map((property) => (
            <Fragment key={property.id}>
              <SimilarPropertyCard
                locale={locale}
                property={property}
                type={type}
              />
              <Divider />
            </Fragment>
          ))
        ) : (
          <Typography component="span">
            Lamentamos las molestias, aún no tenemos está información. Estamos
            trabajando para tenerla, por favor intenta más tarde.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SimilarProperties;
