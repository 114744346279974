import { HOUM_COM_URL } from "env";

export interface ILink {
  to: string;
  label: string;
  hrefTitle: string;
  onClick?: () => void;
  id: string;
}

export interface INavMenu {
  menuLabel: string;
  isOpenPropietaryRental: boolean;
  navLink: INavLink[];
  key: string;
  id: string;
}

export interface INavLink {
  label: string;
  link: string;
  key: string;
  isOnPaper: boolean;
}

type GetMenuItems = (userLocale: string) => (INavMenu | ILink)[];

export const getLocaleBasedHeaderItems: GetMenuItems = (userLocale) => [
  {
    id: "proprietary",
    key: "proprietary",
    menuLabel: "Propietarios",
    isOpenPropietaryRental: true,
    navLink: [
      {
        label: "Vender mi propiedad",
        link: `${HOUM_COM_URL}/${userLocale}/propietario/venta`,
        key: "sales-landing",
        isOnPaper: true,
      },
      {
        label: "Arrendar mi propiedad",
        link: `${HOUM_COM_URL}/${userLocale}/propietario/arriendo`,
        key: "rentals-landing",
        isOnPaper: true,
      },
    ],
  },
  {
    id: "invest",
    to: "/invierte",
    label: "Invierte",
    hrefTitle: "",
    onClick: () => {
      window.location.replace(`${HOUM_COM_URL}/${userLocale}/inversionistas`);
    },
  },
  {
    id: "simulate",
    key: "simulate",
    menuLabel: "Simula y calcula",
    isOpenPropietaryRental: true,
    navLink: [
      {
        label: "Simulador de crédito hipotecario",
        link: `${HOUM_COM_URL}/${userLocale}/simular-credito`,
        key: "simulate-credit",
        isOnPaper: true,
      },
      {
        label: "Calculadora de arriendo o venta",
        link: `${HOUM_COM_URL}/${userLocale}/algoritmo-precio`,
        key: "rental-calculator",
        isOnPaper: true,
      },
    ],
  },
  {
    id: "houm",
    key: "houm",
    menuLabel: "Más de Houm",
    isOpenPropietaryRental: true,
    navLink: [
      {
        label: "¿Quiénes somos?",
        link: `${HOUM_COM_URL}/${userLocale}/quienes-somos`,
        key: "about us",
        isOnPaper: true,
      },
      {
        label: "Nuestro blog",
        link: "https://blog.houm.com/",
        key: "blog",
        isOnPaper: true,
      },
      {
        label: "Refiere y gana",
        link: `${HOUM_COM_URL}/${userLocale}/partner`,
        key: "partner",
        isOnPaper: true,
      },
    ],
  },
];
