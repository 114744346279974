import currency from "@houm-com/formats/currency";

import { PaymentDataProps } from "apps/payments/context/rentPaymentContext/rentPaymentTypes";
import { CurrencyCode } from "apps/payments/models/Payments";

interface Props {
  movement: Partial<PaymentDataProps>;
  address: string;
}

const getPrice = (amount: string, currencyCode: CurrencyCode) =>
  currency(Number(amount), currencyCode, {
    minimumFractionDigits: 0,
  });

const conceptLabel = {
  RESERVATION: "Valor de la reserva",
  CONTRACT: "Valor primer pago",
  LEASE: "Valor mensualidad",
  OTHER: "Valor de la deuda",
};

const PropertyInfo = ({ movement, address }: Props) => (
  <div>
    <p className="text-p16 md:text-p18 !font-bold">
      Ubicación del inmueble: {address}
    </p>
    <p className="text-p16 md:text-p18 !font-bold ">
      {`${conceptLabel[movement.concept]}: ${getPrice(
        movement.price,
        movement.currency
      )}`}
    </p>
  </div>
);

export default PropertyInfo;
