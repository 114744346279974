import { useQuery, UseQueryResult } from "react-query";
import { compareDesc } from "date-fns";

import newPaymentService from "services/newPaymentService";
import { IMovement } from "apps/landlord/models/Movement";
import { useGetToken } from "hooks";

import useSubscription from "./useSubscription";
import { parseMovements } from "./utils";
import useProperty from "./useProperty";

const useMovementsData = (propertyData) => {
  const { getToken } = useGetToken();
  const { subscriptionData, subscriptionLoading } = useSubscription(
    propertyData?.id
  );

  const {
    data = [],
    error,
    isLoading,
  }: UseQueryResult<IMovement[], Error> = useQuery<IMovement[], Error>(
    ["movements", subscriptionData],
    async () => {
      const authToken = await getToken();
      const response = await newPaymentService.getAllSubscriptionMovements(
        propertyData?.country,
        subscriptionData?.id,
        "outflow",
        authToken
      );
      const responseData = parseMovements(response.data.movements);
      return responseData
        ?.filter((movement) => movement.status === "paid")
        .sort((movA, movB) => compareDesc(movA.date, movB.date));
    },
    { enabled: !!subscriptionData && !!propertyData }
  );

  return {
    movementsData: data,
    movementsError: error,
    movementsLoading: isLoading || subscriptionLoading,
  };
};

const useMovements = () => {
  const { propertyData, propertyLoading } = useProperty();
  const { movementsData, movementsError, movementsLoading } =
    useMovementsData(propertyData);

  return {
    movementsLoading: propertyLoading || movementsLoading,
    movementsData,
    movementsError,
  };
};

export default useMovements;
