import { ReactNode } from "react";
import { isMobile } from "react-device-detect";

import BottomNavbar from "Components/Admin/UIComponents/BottomNavbar";
import useNavbarHeight from "apps/landlord/hooks/useNavbarHeight";
import { menuItems } from "apps/landlord/constants/layout";
import Navbar from "Components/Layout/GlobalHeader";

import useSummaryLayoutStyles from "./SummaryLayoutStyles";

interface Props {
  children: ReactNode;
}

const SummaryLayout = ({ children }: Props) => {
  const navbarHeight = useNavbarHeight();
  const classes = useSummaryLayoutStyles({ navbarHeight });

  return (
    <>
      <Navbar />
      <div className={classes.summaryRoot}>{children}</div>
      {isMobile && <BottomNavbar menuList={menuItems} />}
    </>
  );
};

export default SummaryLayout;
