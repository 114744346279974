import { makeStyles, Theme } from "@material-ui/core/";

const usePropertyDetailsStyles = makeStyles<Theme>((theme) => ({
  root: {
    backgroundColor: "white",
  },
  propertyDetailsContent: {
    margin: theme.spacing(2, 2),
    rowGap: 16,
    display: "grid",
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(2, 0),
    },
  },
}));

export default usePropertyDetailsStyles;
