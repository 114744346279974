import { Box } from "@material-ui/core";

import { IVisit } from "apps/tenant/models/Visit";

import { filterVisits } from "../../utils/utils";
import VisitsCalendar from "../VisitsCalendar";
import VisitsList from "../VisitsList";
import useDesktopViewStyles from "./DesktopViewStyles";

interface Props {
  selectedDate: Date;
  setSelectedDate: (e: Date) => void;
  visits: IVisit[];
}

const DesktopView = ({ selectedDate, setSelectedDate, visits }: Props) => {
  const classes = useDesktopViewStyles();
  const filteredVisits = filterVisits(visits, selectedDate);

  return (
    <Box className={classes.desktopViewRoot}>
      <Box className={classes.calendarWrapper}>
        <VisitsCalendar
          value={selectedDate}
          setSelectedDate={setSelectedDate}
          visits={visits}
        />
      </Box>
      <Box className={classes.listWrapper}>
        <VisitsList visits={filteredVisits} />
      </Box>
    </Box>
  );
};

export default DesktopView;
