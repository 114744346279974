import makeStyles from "@material-ui/styles/makeStyles";
import { Theme } from "@material-ui/core/styles/createTheme";

const HEADER_HEIGHT = 72;

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    zIndex: 2,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: "3px 4px 25px rgba(38, 50, 56, 0.15)",
    gap: theme.spacing(1),
    width: 244,
    position: "sticky",
    top: `calc(${HEADER_HEIGHT}px + 40px)`,
    display: "block",
    [theme.breakpoints.down("md")]: {
      top: `calc(${HEADER_HEIGHT}px + 24px)`,
    },
  },
  titleLink: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
    marginLeft: theme.spacing(0.2),
  },
  linkButton: {
    padding: theme.spacing(1.05, 1, 1.05, 1),
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    borderRadius: 8,
    justifyContent: "flex-start",
    width: "100%",
    "&:focus": {
      "& p": {
        color: theme.palette.grey[900],
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.grey[10],
    },
  },
  linkActive: {
    backgroundColor: theme.palette.grey[50],
    "& p": {
      fontWeight: 700,
    },
  },
  titleActive: {
    backgroundColor: theme.palette.grey[50],
    fontWeight: 700,
  },
  divider: {
    margin: theme.spacing(1, 0),
    backgroundColor: theme.palette.grey[75],
    borderRadius: 8,
    alignSelf: "stretch",
    flexGrow: 0,
  },
}));

export default useStyles;
