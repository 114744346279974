import { useQuery, UseQueryResult } from "react-query";

import khipuService from "services/khipuService";
import { KhipuData } from "../../utils/types";

const useKhipuOnboarding = (quotationId: number, document: string) => {
  const { data, isLoading, isError }: UseQueryResult<KhipuData, Error> =
    useQuery<KhipuData, Error>(
      "khipu_onboarding_urls",
      async () => {
        const response = await khipuService.getKhipuOnboarding(
          String(quotationId),
          document
        );
        const parsedData = {
          paymentUrl: response.data.payment_url,
          appUrl: response.data.app_url,
        };
        return parsedData;
      },
      {
        enabled: !!document && !!quotationId,
      }
    );
  return {
    data,
    isLoading,
    isError,
  };
};

export default useKhipuOnboarding;
