import { IMainMenuItem } from "apps/ui/Navbar/utils/types";
import { HOUM_COM_URL } from "env";
import { Locale } from "models/Countries";

const propertiesCL: IMainMenuItem[] = [
  {
    label: "Arrendar en Santiago",
    link: "/cl/arriendo-departamentos-region-metropolitana",
    domain: HOUM_COM_URL,
  },
  {
    label: "Comprar en Santiago",
    link: "/cl/venta-departamentos-region-metropolitana",
    domain: HOUM_COM_URL,
  },
  {
    label: "Arrendar en Viña del Mar",
    link: "/cl/arriendo-departamentos-valparaiso/viña-del-mar",
    domain: HOUM_COM_URL,
  },
  {
    label: "Comprar en Viña del Mar",
    link: "/cl/venta-departamentos-valparaiso/viña-del-mar",
    domain: HOUM_COM_URL,
  },
  {
    label: "Arrendar en Concepción",
    link: "/cl/arriendo-departamentos-bio-bio/concepcion",
    domain: HOUM_COM_URL,
  },
  {
    label: "Comprar en Concepción",
    link: "/cl/venta-departamentos-bio-bio/concepcion",
    domain: HOUM_COM_URL,
  },
];

const propertiesCO: IMainMenuItem[] = [
  {
    label: "Arrendar de apartamento en Bogotá",
    link: "/co/arriendo-apartamentos-bogota",
    domain: HOUM_COM_URL,
  },
  {
    label: "Arriendo de casa en Bogotá",
    link: "/co/arriendo-casa-bogota",
    domain: HOUM_COM_URL,
  },
  {
    label: "Compra de apartamento en Bogotá",
    link: "/co/venta-apartamentos-bogota",
    domain: HOUM_COM_URL,
  },
  {
    label: "Compra de casa en Bogotá",
    link: "/co/venta-casa-bogota",
    domain: HOUM_COM_URL,
  },
];

const propertiesMX: IMainMenuItem[] = [
  {
    label: "Renta de departamento en CDMX",
    link: "/mx/renta-departamentos-ciudad-de-mexico",
    domain: HOUM_COM_URL,
  },
  {
    label: "Renta de casa en CDMX",
    link: "/mx/renta-casa-ciudad-de-mexico",
    domain: HOUM_COM_URL,
  },
  {
    label: "Compra de departamento en CDMX",
    link: "/mx/venta-departamentos-ciudad-de-mexico",
    domain: HOUM_COM_URL,
  },
  {
    label: "Compra de casa en CDMX",
    link: "/mx/venta-casa-ciudad-de-mexico",
    domain: HOUM_COM_URL,
  },
];

const getProperties = (country: Locale): IMainMenuItem[] => {
  switch (country) {
    case "cl":
      return propertiesCL;
    case "co":
      return propertiesCO;
    case "mx":
      return propertiesMX;
    default:
      return [];
  }
};

export const getMainMenuContent = (country: Locale): IMainMenuItem[] => [
  {
    label: { key: "property.search", namespace: "shared.navbar" },
    nested: getProperties(country),
  },
  {
    label: "Propietarios",
    nested: [
      {
        label: { key: "property.sell", namespace: "shared.navbar" },
        link: `/${country}/propietario/venta`,
        domain: HOUM_COM_URL,
      },
      {
        label: { key: "property.rent", namespace: "shared.navbar" },
        link: `/${country}/propietario/arriendo`,
        domain: HOUM_COM_URL,
      },
    ],
  },
  {
    label: "Simula y calcula",
    nested: [
      {
        label: { key: "mortgage.simulator", namespace: "shared.navbar" },
        link: `/${country}/simular-credito`,
        domain: HOUM_COM_URL,
      },
      {
        label: { key: "mortgage.calculator", namespace: "shared.navbar" },
        link: `/${country}/algoritmo-precio`,
        domain: HOUM_COM_URL,
      },
    ],
  },
  {
    label: "Más de Houm",
    nested: [
      {
        label: { key: "about", namespace: "shared.navbar" },
        link: `/${country}/quienes-somos`,
        domain: HOUM_COM_URL,
      },
      {
        label: { key: "blog", namespace: "shared.navbar" },
        link: "",
        domain: "https://blog.houm.com/",
      },
      {
        label: { key: "referrals", namespace: "shared.navbar" },
        link: `/${country}/partner`,
        domain: HOUM_COM_URL,
      },
    ],
  },
];
