import clx from "classnames";
import { chunk } from "lodash";
import { useParams } from "react-router-dom";

import { Grid, Typography } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";

import HoumLogo from "assets/img/OUI).svg";
import useGetProperty from "apps/landlord/hooks/useGetProperty";
import { parseProperty } from "context/landlordPropertyContext/utils";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import usePlanCardBenefitsStyles from "./PlanCardBenefitsStyles";

interface Props {
  isHorizontal?: boolean;
  isMainPlan?: boolean;
  benefitsList: string[];
  isCustom?: boolean;
}

export default function PlanCardBenefits({
  isHorizontal,
  isMainPlan,
  benefitsList,
  isCustom,
}: Props) {
  const BENEFITS_CHUCK_SIZE = isMainPlan ? 2 : 3;
  const classes = usePlanCardBenefitsStyles({ isHorizontal, isMainPlan });
  const params = useParams<{ id: string }>();
  const { data, isLoading } = useGetProperty(params.id);
  const propertyData = parseProperty(data);

  if (isLoading) {
    return <Loading loading />;
  } else {
    const renderBenefits = (list: string[]) => {
      const chunkedList = chunk(list, BENEFITS_CHUCK_SIZE);
      return chunkedList.map((chunked) => (
        <div key={`chunk-${chunked[0]}`} className={classes.benefitsChunk}>
          {chunked.map((listItemText) => (
            <Grid
              key={`listItem-${listItemText}`}
              item
              className={classes.benefitItem}
            >
              <Typography className={classes.listItemText}>
                <CheckCircle
                  className={clx(classes.listIcon, classes.listIcon_check)}
                />
                {listItemText}
              </Typography>
            </Grid>
          ))}
        </div>
      ));
    };

    const renderCustom = () => (
      <div className={classes.contactUsContainer}>
        <div className={classes.houmLogo}>
          <img src={HoumLogo} alt="houm-logo" className="max-w-[74px]" />
        </div>
        <Typography>
          Dado que tu plan es especial para tí, para más información o cambios
          de plan, contáctate con tu ejecutiva a{" "}
          <strong className={classes.email}>
            {propertyData?.houmOwner?.email ?? "-"}
          </strong>
        </Typography>
      </div>
    );
    return (
      <Grid spacing={1} container className={classes.benefitsContainer}>
        {isCustom ? renderCustom() : renderBenefits(benefitsList)}
      </Grid>
    );
  }
}
