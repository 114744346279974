import { makeStyles } from "@material-ui/core";

const useTermsAndConditionsStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      gap: theme.spacing(2),
      padding: theme.spacing(0, 2),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 700,
    lineHeight: "30px",
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(26),
      lineHeight: "34px",
    },
  },
  description: {
    lineHeight: "22px",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: "24px",
    },
  },
  iframe: {
    width: "100%",
    height: 500,
    flex: 1,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
}));

export default useTermsAndConditionsStyles;
