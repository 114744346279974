import { Box, Typography } from "@material-ui/core";

import { ReactNode } from "react";

import useRentRequirementItemStyles from "./RentRequirementItemStyles";

interface Props {
  icon: ReactNode;
  translation: { show: boolean; text: string };
}

const RentRequirementItem = ({ icon, translation }: Props) => {
  const classes = useRentRequirementItemStyles();

  if (!translation.show) return null;

  return (
    <Box className={classes.rentRequirementsItem}>
      <div className={classes.iconWrapper}>{icon}</div>
      <Typography className={classes.text}>{translation.text}</Typography>
    </Box>
  );
};

export default RentRequirementItem;
