import { Link } from "react-router-dom";

import { Typography } from "@material-ui/core";

import { CountryUpperCase } from "models/Countries";
import { countryCode } from "utils";

import useTermsAndConditionsStyles from "./TermsAndConditionsStyles";
import urlsByCountry from "./utils";

interface Props {
  country: CountryUpperCase;
}

const TermsAndConditions = ({ country }: Props) => {
  const classes = useTermsAndConditionsStyles();

  return (
    <Typography>
      {`Declaro que he leído y acepto los `}
      <Link
        className={classes.link}
        to={{
          pathname: urlsByCountry[countryCode(country)].tyc,
        }}
        target="_blank"
      >
        términos y condiciones
      </Link>
      {` y las `}
      <Link
        className={classes.link}
        to={{
          pathname: urlsByCountry[countryCode(country)].privacyPolitics,
        }}
        target="_blank"
      >
        políticas de privacidad
      </Link>
      {` al compartir mi información y documentos.`}
    </Typography>
  );
};

export default TermsAndConditions;
