import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CompanyType,
  IBillDetails,
  IBillInfo,
} from "apps/landlord/services/propertiesDuesService/type";

import statuses from "../../hooks/useBillStatus/utils";
import InfoCardContent from "../InfoCardContent";
import SuccessCardContent from "../SuccessCardContent";
import ErrorCardContent from "../ErrorCardContent";
import ClientCodeForm from "../ClientCodeForm";
import BillForm from "../BillForm";

type CardContentContainerProps = {
  billStatus: string;
  detail: Partial<IBillDetails>;
  item: Partial<IBillInfo>;
  companyType: CompanyType;
};

const CardContent = ({
  billStatus,
  item,
  detail,
  companyType,
}: CardContentContainerProps) => {
  const { t } = useTranslation("landlord.property.basic.services");
  const [editMode, setEditMode] = useState(false);
  const [fillInfoMode, setFillInfoMode] = useState(false);
  const onCancelEditMode = () => setEditMode(false);
  const onEditMode = () => setEditMode(true);
  const onCancelFillInfoMode = () => setFillInfoMode(false);
  const onFillInfoMode = () => setFillInfoMode(true);
  if (editMode) {
    return <ClientCodeForm afterSubmit={onCancelEditMode} item={item} />;
  }
  if (fillInfoMode) {
    return (
      <BillForm afterSubmit={onCancelFillInfoMode} companyType={companyType} />
    );
  }
  switch (billStatus) {
    case statuses.validating_code:
      return <InfoCardContent content={t("validating-code-info")} />;
    case statuses.in_process:
      return <InfoCardContent content={t("in-process-info")} />;
    case statuses.pending:
      return <InfoCardContent content={t("pending-info")} />;
    case statuses.paid:
    case statuses.unpaid:
    case statuses.past_due:
      return (
        <SuccessCardContent
          item={item}
          detail={detail}
          billStatus={billStatus}
          onEditMode={onEditMode}
        />
      );
    case statuses.failure:
    case statuses.invalid_code:
    case statuses.no_data:
    default:
      return (
        <ErrorCardContent
          billStatus={billStatus}
          onEditMode={onEditMode}
          onFillInfoMode={onFillInfoMode}
        />
      );
  }
};

export default CardContent;
