import SuccessCheckIcon from "assets/icons/SuccessCheckIcon";

import useCircularStepsStyles from "./CircularStepsStyles";

interface CircularStepsProps {
  currentStep: number;
  totalSteps: number;
}

const CircularSteps = ({ currentStep, totalSteps }: CircularStepsProps) => {
  const classes = useCircularStepsStyles();
  const isCompleted = currentStep === totalSteps;

  if (isCompleted)
    return (
      <SuccessCheckIcon
        className="w-[32px] h-[32px]"
        data-testid="success-icon"
      />
    );

  const percentage = (currentStep / totalSteps) * 100;

  /**
   * Stroke dash offset value must be between 400 and 120 considering 400 as 0% and 120 as 100%
   * the given value is a calculation of the percentage of the current step over the total steps
   * so, that percentage (value between 0 and 100) must be transformed to match the stroke dash offset value
   */
  const offset = 400 - (percentage * 280) / 100;

  return (
    <section className={classes.circularStepWrap}>
      <svg className="w-full h-full" viewBox="0 0 100 100">
        <circle
          className={classes.emptyRingCircle}
          strokeWidth="8"
          cx="50"
          cy="50"
          r="46"
          fill="transparent"
        />
        <circle
          className={classes.strokeRingCircle}
          strokeWidth="8"
          strokeLinecap="round"
          cx="50"
          cy="50"
          r="46"
          fill="transparent"
          strokeDashoffset={offset}
        />
        <text
          x="38"
          y="50"
          fontSize="30"
          textAnchor="middle"
          alignmentBaseline="middle"
          fontWeight="bold"
        >
          {currentStep}
        </text>
        <text
          x="61"
          y="50"
          fontSize="30"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          /{totalSteps}
        </text>
      </svg>
    </section>
  );
};

export default CircularSteps;
