import { ReactNode, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { Divider } from "@material-ui/core";

import ControlledCheckedFile from "apps/lead/components/ControlledForm/ControlledCheckedFile";

import useFileSelectionStyles from "./FileSelectionStyles";
import LabelFile from "./components/LabelFile";

type Files = {
  lastModified: number;
  lastModifiedDate?: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath?: string;
};

interface Props {
  stateFiles: Files[];
  label: string;
  fileType: string;
  helpTooltip?: ReactNode;
  files: {
    identityCard: Files[];
    contract?: Files[];
    salarySettlements?: Files[];
    quotes?: Files[];
    f22?: Files[];
    pensionSettlements?: Files[];
  };
  uploadFile: (file: Files, stateFiles: Files[], fileType: string) => void;
  removeFile: (file: string, stateFiles: Files[], fileType: string) => void;
}

const FileSelection = ({
  stateFiles,
  label,
  fileType,
  helpTooltip = null,
  files,
  uploadFile,
  removeFile,
}: Props) => {
  const { setValue } = useFormContext();
  const classes = useFileSelectionStyles();

  useEffect(() => {
    if (stateFiles?.length > 0) setValue(`check-${fileType}`, true);
    else setValue(`check-${fileType}`, false);
  }, [files[fileType]]);

  return (
    <>
      <div className={classes.container}>
        <ControlledCheckedFile
          id={`check-${fileType}`}
          justifyContent="flex-start"
          defaultChecked={stateFiles?.length > 0}
          checkboxProps={{
            disabled: true,
          }}
          label={
            <LabelFile
              helpTooltip={helpTooltip}
              stateFiles={stateFiles}
              fileType={fileType}
              label={label}
              uploadFile={uploadFile}
              removeFile={removeFile}
            />
          }
        />
      </div>
      <Divider className={classes.divider} />
    </>
  );
};

export default FileSelection;
