import {
  KeysOfAmenities,
  KeysOfPropertyAmenities,
  IPropertyAmenities,
  IAmenities,
} from "./types";

export const parseDefaultValues = (
  propertyAmenities: IPropertyAmenities,
  amenities: IAmenities
) => ({
  propertyAmenities: Object.keys(propertyAmenities).filter(
    (key) => propertyAmenities[key]
  ) as KeysOfPropertyAmenities[],
  amenities: Object.keys(amenities).filter(
    (key) => amenities[key]
  ) as KeysOfAmenities[],
});
