import { FC, SVGProps } from "react";

const SuccessCheckIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={32} height={32} rx={16} fill="#DAEECC" />
    <path
      d="M21 11.832L14 20.1658L11 16.8323"
      stroke="#439F03"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SuccessCheckIcon;
