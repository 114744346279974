import { ISimilarExternalProperty } from "apps/landlord/pages/Price/hooks/useExternalProperties/types";
import { IMainPropertyPropertyAnalysis } from "apps/landlord/pages/Price/hooks/useMainProperty/types";
import { ISimilarProperty } from "apps/landlord/pages/Price/hooks/useSimilarProperties/types";
import { getCCbyLocale } from "apps/landlord/pages/Price/utils/utils";
import { countryCode } from "utils";

import { AnalysisTypeProperty } from "../../utils/types";
import useStyles from "./SimilarPropsMapStyle";
import Map from "./Map";

interface Props {
  mainProperty: IMainPropertyPropertyAnalysis;
  similarProperties: ISimilarProperty[];
  externalProperties: ISimilarExternalProperty[];
  type: AnalysisTypeProperty;
}

const SimilarPropsMap = ({
  mainProperty,
  similarProperties,
  externalProperties,
  type,
}: Props) => {
  const classes = useStyles();
  const locale = countryCode(mainProperty?.country);

  const coords: [lng: number, lat: number] = [
    mainProperty.propertyDetails[0]?.longitude || 0,
    mainProperty.propertyDetails[0]?.latitude || 0,
  ];

  const similarPropsCoords = similarProperties.map((prop) => ({
    longitude: prop?.propertyDetails[0]?.longitude || 0,
    latitude: prop?.propertyDetails[0]?.latitude || 0,
    price:
      type === "rent"
        ? prop?.propertyDetails[0]?.value
        : prop?.propertyDetails[0]?.valueSales,
    cc: getCCbyLocale(locale),
  }));

  const similarExternalPropsCoords = externalProperties.map((prop) => ({
    longitude: prop.longitude || 0,
    latitude: prop.latitude || 0,
    price: prop.price,
    cc: getCCbyLocale(locale),
  }));

  const similarProps = [...similarPropsCoords, ...similarExternalPropsCoords];

  return (
    <div className={classes.container}>
      <Map
        properties={similarProps}
        value={coords}
        defaultValue={coords}
        hasMarker
        zoom={11}
      />
    </div>
  );
};

export default SimilarPropsMap;
