import { ComponentProps, ReactNode } from "react";

import { Controller, useFormContext } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";

import useControlledCheckedFileStyles from "./ControlledCheckedFileStyles";
import { ControlledCheckedFilePlacement } from "./utils";

interface Props {
  label: string | ReactNode;
  id: string;
  defaultChecked?: boolean;
  defaultValue?: string;
  controllerProps?: ComponentProps<typeof Controller>;
  checkboxProps?: ComponentProps<typeof Checkbox>;
  placement?: ControlledCheckedFilePlacement;
  justifyContent?: string;
  size?: string;
  labelProps?: string;
  wrapperProps?: string;
}

const ControlledCheckedFile = ({
  id,
  label,
  defaultChecked,
  defaultValue,
  checkboxProps,
  controllerProps,
}: Props) => {
  const { control } = useFormContext();
  const classes = useControlledCheckedFileStyles();

  return (
    <Controller
      name={id}
      defaultValue={defaultValue ?? defaultChecked}
      control={control}
      render={({
        field: { value, onChange },
        fieldState: { error, invalid },
      }) => (
        <FormControl error={invalid}>
          <FormControlLabel
            className={classes.formControlLabelContainer}
            control={
              <Checkbox
                checked={value}
                onChange={onChange}
                defaultValue={defaultValue}
                className={classes.backgroundColorOnWholeIcon}
                color="primary"
                {...checkboxProps}
              />
            }
            label={label}
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      )}
      {...controllerProps}
    />
  );
};

export default ControlledCheckedFile;
