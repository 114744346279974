import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

export default makeStyles((theme: Theme) => ({
  propertyImageContainer: {
    padding: 0,
    height: "100%",
    width: "100%",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    objectFit: "scale-down",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      position: "relative",
      display: "flex",
      alignItems: "center",
    },
  },
  image: {
    top: 0,
    height: "100%",
    width: "auto",
    borderRadius: 10,
  },
  placeholderImage: {
    width: "50%",
  },
}));
