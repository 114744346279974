import { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import {
  parseCodebtorData,
  parseDataUploadFiles,
} from "apps/lead/utils/parseEvaluatorFormData";
import { useUploadFiles as useFetchUploadFiles } from "apps/lead/hooks/useUploadFiles";
import { useCodebtor } from "apps/lead/context/codebtorContext/CodebtorContext";
import textByWorkerType from "apps/lead/utils/requirementsByWorkertype";
import { Files } from "apps/lead/context/codebtorContext/CodebtorTypes";
import { useFormNotifications } from "hooks";

import { fileSchema, requiredText } from "../utils/validationSchema";

const useUploadFiles = () => {
  const history = useHistory();
  const { state } = useCodebtor();
  const { country, evaluation, codebtor, activeStep, steps, applicant } = state;
  const { workerType, files } = codebtor;
  const { mutate: uploadFiles, isLoading: isLoadingFiles } =
    useFetchUploadFiles();
  const [isSuccess, setIsSuccess] = useState(false);

  const requirements = useMemo(
    () => textByWorkerType(true, workerType, country),
    [workerType]
  );

  const getFilesLength = () => {
    const data = Object.values(files);
    const newArray = Array.prototype.concat.apply([], data);
    return newArray.length;
  };

  const methods = useForm<Files[]>({
    resolver: yupResolver(fileSchema(requirements, getFilesLength())),
    reValidateMode: "onBlur",
  });

  const { errors } = methods.formState;
  useFormNotifications<Files[]>({ errors, requiredText });
  const updateDocuments = () => {
    const codebtorData = parseCodebtorData(
      codebtor,
      country,
      evaluation.evaluationId
    );
    const parsedData = parseDataUploadFiles(
      codebtor.id,
      evaluation.evaluationId,
      codebtorData.user,
      country
    );
    uploadFiles(parsedData, {
      onSuccess: () => setIsSuccess(true),
      onError: () =>
        Swal.fire({
          title: "Error",
          text: "No se pudieron subir los archivos correctamente",
          type: "error",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Intentar nuevamente",
        }).then((result) => {
          if (result.value) {
            updateDocuments();
          } else {
            history.push("/tenant");
          }
        }),
    });
  };

  const onSubmit: SubmitHandler<Files[]> = () => updateDocuments();

  return {
    methods,
    requirements,
    onSubmit,
    loading: isLoadingFiles,
    activeStep,
    steps,
    codebtor,
    workerType,
    files,
    role: applicant.role,
    isSuccess,
  };
};

export default useUploadFiles;
