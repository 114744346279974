/* eslint-disable max-lines */
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import FieldSwitchSelector from "@houm-com/ui/FieldSwitchSelector";
import FieldRadioButton from "@houm-com/ui/FieldRadioButton";
import FieldInput from "@houm-com/ui/FieldInput";

import ContentDrawer from "apps/ui/CustomDrawer/ContentDrawer";
import { IProperty } from "apps/landlord/models/Property";

import INCLUDES_COMMON_EXPENSES_OPTIONS from "./constants";
import usePriceFormStyles from "./PriceFormStyles";
import priceFormatter from "./utils";

interface PriceFormProps {
  propertyData: IProperty;
}

const PriceForm = ({ propertyData }: PriceFormProps) => {
  const classes = usePriceFormStyles();
  const { t } = useTranslation(["SET_PRICE"]);
  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const currency = watch("currency");
  const businessType = watch("businessType");

  const isForBoth = propertyData?.forSale && propertyData?.forRental;

  useEffect(() => {
    const rentPrice = propertyData?.propertyDetails?.price ?? 0;
    const salesPrice = propertyData?.salesDetails?.price ?? 0;
    setValue(
      "price",
      priceFormatter({
        price:
          businessType === "RENT"
            ? rentPrice.toString()
            : salesPrice.toString(),
        currency,
      })
    );
  }, [businessType, currency, propertyData]);

  const formTitle = useMemo(() => {
    if (isForBoth) return t("SET_PRICE_FOR_BOTH_TITLE");
    if (propertyData?.forSale) return t("SET_PRICE_FOR_SALE_TITLE");
    return t("SET_PRICE_FOR_RENT_TITLE");
  }, [isForBoth, propertyData?.forSale, t]);

  const isReserved = propertyData?.propertyDetails?.status === "reserved";

  return (
    <ContentDrawer contentClassName={classes.root}>
      <p className={classes.formTitle}>{formTitle}</p>
      {isForBoth && (
        <FieldSwitchSelector
          id="businessType"
          options={[
            {
              label: t("SET_PRICE_RENT_LABEL"),
              value: "RENT",
            },
            {
              label: t("SET_PRICE_SALE_LABEL"),
              value: "SALE",
            },
          ]}
          labelPosition="top"
          required
          errorMessage={errors.businessType?.message}
          classNameSwitch="max-w-[100%]"
          classNameButton="w-[50%]"
          {...register("businessType")}
        />
      )}
      <FieldInput
        id="price"
        name="price"
        label={
          businessType === "RENT"
            ? t("SET_PRICE_RENT_MONTHLY_LABEL")
            : t("SET_PRICE_SALE_VALUE_LABEL")
        }
        labelPosition="top"
        autoComplete="off"
        required
        type="text"
        errorMessage={errors.price?.message}
        classNameInput={classes.priceInput}
        {...register("price")}
        value={priceFormatter({
          price: watch("price"),
          currency,
        })}
      />

      {propertyData?.country === "cl" && (
        <FieldSwitchSelector
          id="currency"
          options={[
            {
              label: "CLP",
              value: "CLP",
            },
            {
              label: "UF",
              value: "CLF",
            },
          ]}
          labelPosition="top"
          required
          errorMessage={errors.currency?.message}
          classNameSwitch="max-w-[100%]"
          classNameButton="w-[50%]"
          {...register("currency")}
        />
      )}
      {businessType === "RENT" && (
        <FieldRadioButton
          id="includesCommonExpenses"
          label="¿El precio de arriendo incluye gasto común?"
          options={INCLUDES_COMMON_EXPENSES_OPTIONS}
          required
          errorMessage={errors.includesCommonExpenses?.message}
          {...register("includesCommonExpenses")}
        />
      )}
      {isReserved && (
        <section className={classes.reservedPropWarn}>
          <strong>Tu propiedad se encuentra reservada</strong>, por lo que no
          podrás cambiar el precio. Si necesitas hacerlo, por favor contacta a
          nuestros ejecutivos.
        </section>
      )}
    </ContentDrawer>
  );
};

export default PriceForm;
