import { Link } from "react-router-dom";

import { Box, Typography } from "@material-ui/core";
import { IoChevronBack } from "@houm-com/ui-icons";

import logo from "assets/images/houmLogo.svg";

import useNavbarMobileStyles from "./NavbarMobileStyles";

interface Props {
  handleBack: () => void;
  backText: string;
}

const NavbarMobile = ({ handleBack, backText }: Props) => {
  const classes = useNavbarMobileStyles();

  return (
    /** this navbar-root element is tracked by our layout resize observer */
    <div id="navbar-root" className={classes.headerRoot}>
      <div className={classes.navigationRoot}>
        <Box className={classes.leftSection} onClick={handleBack}>
          <div className={classes.mobileBackContainer}>
            <IoChevronBack color="#4C6977" />
          </div>
          <Typography className={classes.backText}>{backText}</Typography>
        </Box>
        <Link to="/" title="Houm">
          <img src={logo} alt="Houm" className={classes.logo} />
        </Link>
      </div>
      {/** DON'T REMOVE!Horizontal banner portal root */}
      <div id="horizontal-banner-root" />
    </div>
  );
};

export default NavbarMobile;
