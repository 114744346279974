import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { parseDefaultValues as parseDefaultValuesAmenities } from "apps/landlord/components/FormAmenities/utils/parsers";
import { validationSchema } from "apps/landlord/components/FormPropertyDetails/utils/validationSchema";
import { parseDefaultValues } from "apps/landlord/components/FormPropertyDetails/utils/parsers";
import { IPropertyDetails } from "apps/landlord/hooks/usePropertyDetails/utils/types";
import FormPropertyDetails from "apps/landlord/components/FormPropertyDetails";
import FormAmenities from "apps/landlord/components/FormAmenities";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";

import useEditPropertyDetails from "../../hooks/useEditPropertyDetails";
import { FormProps } from "../../utils/types";

interface Props {
  data: IPropertyDetails;
}

const Form = ({ data }: Props) => {
  const { returnToMainPage } = useCustomDrawer();
  const { mutate, isLoading } = useEditPropertyDetails(returnToMainPage);
  const [step, setStep] = useState(1);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      ...parseDefaultValues(data.details),
      ...parseDefaultValuesAmenities(data.propertyAmenities, data.amenities),
    },
  });

  const onSubmit: SubmitHandler<FormProps> = (e) => {
    if (isLoading) return;
    if (step === 1) setStep(2);
    else mutate(e);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {step === 1 && (
          <FormPropertyDetails onHandleCloseModal={returnToMainPage} />
        )}
        {step === 2 && (
          <FormAmenities
            onHandleBack={() => setStep(1)}
            isLoading={isLoading}
          />
        )}
      </form>
    </FormProvider>
  );
};

export default Form;
