import * as yup from "yup";

export const validationSchema = yup.object().shape({
  condominium: yup.boolean(),
  hasElevator: yup.boolean(),
  hasGym: yup.boolean(),
  hasPetArea: yup.boolean(),
  hasConcierge: yup.boolean(),
  hasPatio: yup.boolean(),
  hasBbqArea: yup.boolean(),
  hasSauna: yup.boolean(),
  hasLaundry: yup.boolean(),
  hasPartyRoom: yup.boolean(),
  hasCinema: yup.boolean(),
  hasGourmetSpace: yup.boolean(),
  hasPlayground: yup.boolean(),
  hasAllDayVigilance: yup.boolean(),
  hasGamesRoom: yup.boolean(),
  hasPlayroom: yup.boolean(),
  hasRoofGarden: yup.boolean(),
  hasSteamRoom: yup.boolean(),
  hasSwimmingPool: yup.boolean(),
  furnished: yup.boolean(),
  allowsPets: yup.boolean(),
  hasAirConditioning: yup.boolean(),
  heating: yup.boolean(),
  hasPool: yup.boolean(),
  hasBalcony: yup.boolean(),
  hasLaundryRoom: yup.boolean(),
  hasLaundryCapacity: yup.boolean(),
});
