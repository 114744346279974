import { makeStyles, Theme } from "@material-ui/core";

const useRejectFormStyles = makeStyles((theme: Theme) => ({
  titleGeneral: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: theme.palette.grey[900],
    paddingBottom: theme.spacing(3),
  },
  text: {
    fontSize: "1rem",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  divider: {
    margin: theme.spacing(0, 0, 3, 0),
  },
  selectButton: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  label: {
    width: "100%",
    fontWeight: "bold",
    alignItems: "left",
    fontSize: "1.125rem",
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  option: {
    fontSize: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(4, 0),
  },
  ctaContent: {
    width: "100%",
    padding: theme.spacing(0, 8),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(0, 2),
    },
  },
}));

export default useRejectFormStyles;
