import { PhoneInput } from "models/PhoneInput";
import { formatPhoneInput } from "utils";

const getDefaultPhone = (defaultPhone: PhoneInput, phone: string = null) => {
  const thirdPartyCoordinationPhone = phone;
  return thirdPartyCoordinationPhone
    ? formatPhoneInput(thirdPartyCoordinationPhone)
    : defaultPhone;
};

export const getFormattedPhone = (
  defaultValue: PhoneInput,
  phone: string = null
) => {
  const defaultPhone = getDefaultPhone(defaultValue, phone);
  return {
    country: defaultPhone.country,
    phone: defaultPhone.phone,
  };
};
