import { ReactNode } from "react";

import { Box, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import useLeavingConfirmationStepStyles from "./LeavingConfirmationStepStyles";

type Props = {
  handleNext: () => void;
  handleBack: () => void;
  confirmationMessage: ReactNode;
};

const LeavingConfirmationStep = ({
  handleNext,
  handleBack,
  confirmationMessage,
}: Props) => {
  const classes = useLeavingConfirmationStepStyles();

  return (
    <>
      <Typography className={classes.title}>
        Estás por solicitar tu salida
      </Typography>
      <Box className={classes.description}>{confirmationMessage}</Box>
      <Box className={classes.actionsContainer}>
        <DesignSystemButton
          label="Cancelar"
          variant="tertiaryB"
          size="small"
          onClick={handleBack}
        />
        <DesignSystemButton
          label="Continuar"
          variant="primary"
          size="small"
          onClick={handleNext}
        />
      </Box>
    </>
  );
};

export default LeavingConfirmationStep;
