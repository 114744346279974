import { makeStyles, Theme } from "@material-ui/core";

const useRepairsRequestStyles = makeStyles((theme: Theme) => ({
  scrollContainer: {
    "scroll-margin": "90px",
  },
  drawerHeader: {
    width: "100%",
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
  },
  iconContainer: {
    float: "right",
    width: 40,
    height: 40,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
}));

export default useRepairsRequestStyles;
