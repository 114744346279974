import { Box, Typography } from "@material-ui/core";
import { format } from "date-fns";
import clx from "classnames";

import { IoExitOutline as ExitIcon } from "@houm-com/ui-icons";

import { ITenantContractEnd } from "apps/tenant/utils/types";

import useExitCardStyles from "./ExitCardStyles";

type Props = {
  contractEnd: ITenantContractEnd;
};

const ExitCard = ({ contractEnd }: Props) => {
  const classes = useExitCardStyles();
  return (
    <Box className={classes.card}>
      <Box className={classes.iconContainer}>
        <ExitIcon color="#EFCDCC" size={22} />
      </Box>
      <Box className={classes.textContainer}>
        <Typography className={classes.text}>
          <strong>Término de contrato</strong>
        </Typography>
        <Typography className={clx(classes.cardText, classes.text)}>
          Creado: {format(contractEnd.beginAt, "dd/MM/yyyy")}
        </Typography>
        <Typography className={clx(classes.observation, classes.text)}>
          {contractEnd.comment}
        </Typography>
        <Typography className={clx(classes.exitDate, classes.text)}>
          Fecha de salida:{" "}
          <span>{format(contractEnd.estimatedDate, "dd/MM/yyyy")}</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default ExitCard;
