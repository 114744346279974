import { useMutation, useQueryClient } from "react-query";

import { alert } from "@houm-com/ui/Alert";

import { useGetToken } from "hooks";
import propertiesDuesService from "apps/landlord/services/propertiesDuesService";
import { useProperty } from "apps/landlord/hooks";
import { CommonExpensesCompanies } from "apps/landlord/models/ServicesCompanies";

type ChangeExternalIdProps = {
  externalId: string;
  company: CommonExpensesCompanies;
};

const useChangeBillExternalId = () => {
  const queryClient = useQueryClient();
  const { propertyData } = useProperty();
  const { getToken } = useGetToken();
  const {
    mutate,
    isLoading: submitting,
    isSuccess,
  } = useMutation(
    async ({ externalId, company }: ChangeExternalIdProps) => {
      const authToken = await getToken();
      const data = {
        property_id: propertyData.id,
        company,
        client_info: {
          external_id: externalId,
        },
      };
      return propertiesDuesService.patchBill(propertyData.id, authToken, data);
    },
    {
      onSuccess: () => {
        alert.success({
          message: "Solicitud enviada con éxito",
          disclaimer:
            "Se ha creado una solicitud para cambiar el número de cliente",
        });
        queryClient.invalidateQueries("billsInfo");
        queryClient.invalidateQueries("propertyBillsDetails");
      },
      onError: () => {
        alert.success({
          message: "No se pudo enviar la solicitud",
          disclaimer:
            "No se pudo enviar tu peticion de cambio de número de cliente",
        });
        queryClient.invalidateQueries("billsInfo");
        queryClient.invalidateQueries("propertyBillsDetails");
      },
    }
  );
  return { mutate, submitting, isSuccess };
};

export default useChangeBillExternalId;
