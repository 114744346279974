import { Fragment } from "react";
import { Link } from "react-router-dom";

import logo from "assets/images/houmLogo.svg";
import LightButton from "@houm-com/ui/LightButton";
import {
  CountryUpperCaseModelToLocale,
  LocaleCountries,
} from "models/Countries";
import userService from "services/userService";

import NavbarMenu from "./NavbarMenu/NavbarMenu";
import NavbarLink from "./NavbarLink/NavbarLink";
import useStyles from "./NavbarDesktopStyles";
import AuthDropdown from "./AuthDropdownMenu";
import { getLocaleBasedHeaderItems } from "../HeaderItems";

export default function NavbarDesktop() {
  const classes = useStyles();
  const user = userService.me();
  const userLocale =
    CountryUpperCaseModelToLocale[user?.country] || LocaleCountries.CL;

  const menuItems = getLocaleBasedHeaderItems(userLocale);

  return (
    /** this navbar-root element is tracked by our layout resize observer */
    <div id="navbar-root" className={classes.navbarDesktopWrapper}>
      <div className={classes.linksContentDesktop}>
        <div className={classes.wrap}>
          <Link to="/" title="Ir a Houm">
            <img src={logo} alt="Houm" />
          </Link>
          <div className={classes.navbarMenuLeft}>
            {menuItems.map((menuItem) =>
              "menuLabel" in menuItem ? (
                <NavbarMenu
                  isOpenPropietaryRental={true}
                  label={menuItem.menuLabel}
                  key={menuItem.key}
                >
                  {menuItem.navLink.map((navLink) => (
                    <NavbarLink
                      label={navLink.label}
                      link={navLink.link}
                      key={navLink.key}
                      isOnPaper={navLink.isOnPaper}
                      className="w-full"
                    />
                  ))}
                </NavbarMenu>
              ) : (
                <Fragment key={menuItem.label}>
                  <LightButton
                    onClick={menuItem.onClick}
                    variant="secondary"
                    size="md"
                    className="!font-normal"
                  >
                    {menuItem.label}
                  </LightButton>
                </Fragment>
              )
            )}
          </div>
        </div>
        <div className={classes.rightContainer}>
          <AuthDropdown />
        </div>
      </div>
      {/** DON'T REMOVE!Horizontal banner portal root */}
      <div id="horizontal-banner-root" />
    </div>
  );
}
