import { useTranslation } from "react-i18next";
import Button from "@houm-com/ui/Button";

import {
  IPropertyAmenities,
  IAmenities,
} from "apps/landlord/hooks/usePropertyDetails/utils/types";
import DrawerHeader from "apps/ui/CustomDrawer/DrawerHeader";
import Drawer from "apps/ui/CustomDrawer/Drawer";

import { useState } from "react";
import CardContent from "../CardContent";
import InfoContent from "../InfoContent";
import Form from "./components/Form";
import Label from "../Label";
import Title from "../Title";
import Card from "../Card";

interface Props {
  amenities: IAmenities;
  propertyAmenities: IPropertyAmenities;
  isEditable: boolean;
}

const Amenities = ({ amenities, propertyAmenities, isEditable }: Props) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("landlord.property.settingUp");
  const allAmenities = { ...amenities, ...propertyAmenities };
  const availableAmenities = Object.keys(allAmenities).filter(
    (key) => allAmenities[key]
  );

  const amenitiesTranslation = t("FULL_AMENITIES", { returnObjects: true });
  const onHandleDrawer = () => setOpen((prev) => !prev);

  return (
    <>
      <Card>
        <CardContent>
          <Title title="Amenidades" />
          <InfoContent>
            <div className="grid grid-cols-3 gap-2">
              {availableAmenities.map(
                (amenity) =>
                  t(amenitiesTranslation[amenity]) && (
                    <Label
                      key={amenity}
                      label={t(amenitiesTranslation[amenity])}
                    />
                  )
              )}
            </div>
          </InfoContent>
        </CardContent>
        {isEditable && (
          <Button onClick={onHandleDrawer} size="md" variant="primary">
            Editar
          </Button>
        )}
      </Card>

      <Drawer onHandleClose={onHandleDrawer} open={open}>
        <DrawerHeader onHandleClose={onHandleDrawer} />
        <Form
          amenities={amenities}
          propertyAmenities={propertyAmenities}
          onHandleDrawer={onHandleDrawer}
        />
      </Drawer>
    </>
  );
};

export default Amenities;
