import { FC, SVGProps } from "react";

const BathroomsIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.5708 11.4422L18.528 5.96418C18.5246 5.51851 18.3796 5.06409 18.0018 4.82776C17.11 4.26995 15.7252 4.22108 15.2946 6.33493M18.3781 8.55122H17.8963M5.66381 13.562C5.59956 15.425 6.08779 19.1991 8.55464 19.3918H16.5525C17.5643 19.3918 19.5108 18.4186 19.3952 13.562M7.44114 19.1509L6.38116 20.5H4.98393M17.5109 19.1509L18.5708 20.5H19.9681M5.36937 13.562H19.6308C20.163 13.562 20.5944 13.1306 20.5944 12.5984V12.4057C20.5944 11.8735 20.163 11.4421 19.6308 11.4421H5.36937C4.83718 11.4421 4.40576 11.8735 4.40576 12.4057V12.5984C4.40576 13.1306 4.83719 13.562 5.36937 13.562Z"
      stroke="#263238"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);
export default BathroomsIcon;
