import ILocalBasedText from "apps/tenant/pages/ContractEndPage/types";

export const LOCAL_BASED_TITLE: ILocalBasedText = {
  cl: "Indícanos que día planeas dejar la propiedad",
  co: "Indícanos que día dejarás la propiedad ",
  mx: "Indícanos que día dejarás el inmueble",
};

export const LOCAL_BASED_DESCRIPTION: ILocalBasedText = {
  cl: "Este día te visitaremos para que nos entregues las llaves de la propiedad.",
  co: "Este es el día que realizaremos la visita de salida y deberás de entregar las llaves de la propiedad",
  mx: "Este es el día que realizaremos la visita de salida y deberás de entregar las llaves del inmueble",
};
