import { useFormContext } from "react-hook-form";

import FieldInput from "@houm-com/ui/FieldInput";
import FieldSelect from "@houm-com/ui/FieldSelect";

import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import { DocumentTypes } from "hooks/useDocumentTypeListPublic/utils/types";

import { documentFormatter, translate } from "./utils/utils";
import useStyles from "./DocumentInputStyles";

interface Props {
  documentTypeList: DocumentTypes[];
}

const DocumentInput = ({ documentTypeList }: Props) => {
  const { state } = useRentPayment();
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {state.country !== "cl" && (
        <FieldSelect
          id="documentType"
          label="Tipo de documento"
          required
          errorMessage={errors.documentType?.message}
          placeholder="Selecciona un documento"
          options={documentTypeList.map((document) => ({
            label: document.label,
            value: document.value,
          }))}
          form="novalidateform"
          {...register("documentType")}
        />
      )}
      <FieldInput
        id="document"
        label={translate(state.country, "document")}
        required
        errorMessage={errors.document?.message}
        form="novalidateform"
        {...register("document")}
        onChange={(e) =>
          setValue("document", documentFormatter(state.country, e.target.value))
        }
        placeholder={state.country === "cl" ? "1.111.111-1" : "Ingresar"}
      />
    </div>
  );
};

export default DocumentInput;
