import { FormProvider, SubmitHandler } from "react-hook-form";

import FieldInputFile from "@houm-com/ui/FieldInputFile";

import { independentFilesSchema } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/IndependentForm/utils/validationSchema";
import { IIndependentFilesForm } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/IndependentForm/utils/types";
import { allowedMimeTypes } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/utils/constants";
import { resetEmploymentData } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/utils/utils";
import { parserFileList } from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/utils/parsers";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper";
import useReactHookForm from "hooks/useReactHookForm";
import {
  setActiveStep,
  setUserInformation,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";

const IndependentForm = () => {
  const {
    state: { user: userInformation, activeStep },
    dispatch,
  } = useEvaluation();
  const { methods, errors } = useReactHookForm<IIndependentFilesForm>({
    formSchema: independentFilesSchema,
    formOptions: {
      defaultValues: {
        companyConstitution:
          userInformation.employmentFiles?.companyConstitution,
        companyIncomeStatement:
          userInformation.employmentFiles?.companyIncomeStatement,
        taxReturn: userInformation.employmentFiles?.taxReturn,
      },
    },
  });

  const onSubmit: SubmitHandler<IIndependentFilesForm> = (data) => {
    const parsedData = parserFileList<IIndependentFilesForm>(data);
    dispatch(
      setUserInformation({
        ...userInformation,
        employmentFiles: {
          ...resetEmploymentData(),
          companyConstitution: parsedData.companyConstitution[0],
          companyIncomeStatement: parsedData.companyIncomeStatement[0],
          taxReturn: parsedData.taxReturn[0],
        },
      })
    );
    dispatch(setActiveStep(activeStep + 1));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper classNameFormWrapper="flex flex-col">
          <FieldInputFile
            id="companyConstitution"
            label="Acta constitutiva de  la empresa (Documento de la cámara de comercio)"
            subLabel="Debe ser empresa vigente y más de 6 meses de antigüedad"
            allowedMimeTypes={allowedMimeTypes}
            classNameInputButton="!w-full"
            splitLength={40}
            required
            form="novalidateform"
            file={userInformation.employmentFiles?.companyConstitution}
            errorMessage={errors.companyConstitution?.message}
            {...methods.register("companyConstitution")}
          />
          <FieldInputFile
            id="companyIncomeStatement"
            label="Declaración de renta de la empresa"
            subLabel="Los ingresos deben ser 3 veces mayor al monto del arriendo"
            allowedMimeTypes={allowedMimeTypes}
            classNameInputButton="!w-full"
            splitLength={40}
            required
            form="novalidateform"
            file={userInformation.employmentFiles?.companyIncomeStatement}
            errorMessage={errors.companyIncomeStatement?.message}
            {...methods.register("companyIncomeStatement")}
          />
          <FieldInputFile
            id="taxReturn"
            label="Declaración IVA"
            subLabel="Los últimos 3 meses deben validar que los ingresos declarados sean reales."
            allowedMimeTypes={allowedMimeTypes}
            classNameInputButton="!w-full"
            splitLength={40}
            required
            form="novalidateform"
            file={userInformation.employmentFiles?.taxReturn}
            errorMessage={errors.taxReturn?.message}
            {...methods.register("taxReturn")}
          />
        </FormWrapper>
        <CallToAction label="Continuar" />
      </form>
    </FormProvider>
  );
};

export default IndependentForm;
