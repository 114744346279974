import { UseMovementDataProps } from "apps/payments/hooks/useMovementData/useMovementData";
import Layout from "Components/Admin/UIComponents/paymentLayout/Layout";
import CreditCardPerson from "assets/images/creditCardPerson.png";
import { Locale } from "models/Countries";

import { pendingText } from "./utils/constants";
import PropertyInfo from "../PropertyInfo";

interface Props {
  country: Locale;
  movementData: UseMovementDataProps;
  address: string;
}

const PendingPayment = ({ country, movementData, address }: Props) => (
  <Layout
    title="Tu pago está pendiente de confirmación"
    label="Pago pendiente"
    image={CreditCardPerson}
  >
    <div className="flex flex-col gap-6">
      <p className="text-p16 md:text-p18">
        {pendingText[movementData.concept.toLowerCase()][country].title}
      </p>
      <PropertyInfo movement={movementData} address={address} />
      {pendingText[movementData.concept.toLowerCase()][country].text && (
        <p className="text-p16 md:text-p18">
          {pendingText[movementData.concept.toLowerCase()][country].text}
        </p>
      )}
    </div>
  </Layout>
);

export default PendingPayment;
