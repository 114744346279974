import { UseQueryResult, useQuery } from "react-query";

import { IPaymentDetail } from "apps/tenant/models/Movement";
import paymentService from "apps/tenant/services/paymentService";
import { useGetToken } from "hooks";
import { Locale } from "models/Countries";
import { countryCode } from "utils";

import { parsePaymentDetail } from "./utils/parsers";

interface Props {
  country: Locale;
  movementId: string;
}

const usePaymentDetail = ({ country, movementId }: Props) => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    data,
    isError,
    refetch,
  }: UseQueryResult<IPaymentDetail, Error> = useQuery<IPaymentDetail, Error>(
    ["tenant_payment_detail_by_id", country, movementId],
    async () => {
      const authToken = await getToken();
      const response = await paymentService.getPaymentDetailByMovementId(
        countryCode(country),
        movementId,
        authToken
      );
      return parsePaymentDetail(response.data);
    },
    {
      enabled: !!movementId && !!country,
    }
  );

  const hasReadjustment =
    !!data?.readjustment && data.readjustment?.totalValue !== 0;

  const currency = data?.currency;
  const originalAmountBreakdown = data?.originalAmountBreakdown || [];
  const discountsBreakdown = data?.discountsBreakdown || [];
  const aditionalsBreakdown = data?.aditionalsBreakdown || [];
  const promotionsBreakdown = data?.promotionsBreakdown || [];

  return {
    isLoading,
    isError,
    data,
    hasReadjustment,
    breakdownCurrency: currency,
    originalAmountBreakdown,
    discountsBreakdown,
    aditionalsBreakdown,
    promotionsBreakdown,
    refetch,
  };
};

export default usePaymentDetail;
