import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  noFeedback: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[500],
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
}));
