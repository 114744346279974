import { CountryUpperCase } from "models/Countries";

import {
  BankData,
  Documents,
  PersonalAddress,
  PersonalData,
  Resume,
  Welcome,
  Owners,
} from "../steps";

export const getFormStepsCollection = (
  shouldDisplayOwners: boolean,
  country: CountryUpperCase
) => {
  const FirstStep = shouldDisplayOwners ? Owners : Welcome;

  const CORE_STEPS = [FirstStep, PersonalData, PersonalAddress];

  const LAST_STEPS = [Documents, Resume];

  if (country === "Mexico") {
    return [...CORE_STEPS, BankData, ...LAST_STEPS];
  }

  return [...CORE_STEPS, ...LAST_STEPS];
};
