import Tag from "apps/landlord/pages/BasicServices/components/Tag";
import {
  IBillDetails,
  IBillInfo,
} from "apps/landlord/services/propertiesDuesService/type";

import classes from "./BasicServicesInfoItemStyles";

type ItemProps = {
  billInfo: IBillInfo;
  billDetails: Partial<IBillDetails>;
  error: boolean;
  defaultTitle: string;
};

const Item = ({ billInfo, billDetails, error, defaultTitle }: ItemProps) => (
  <div className={classes.item}>
    <p className={classes.itemTitle}>{billInfo?.companyName ?? defaultTitle}</p>
    <Tag className={classes.tag} billDetails={billDetails} error={error} />
  </div>
);

export default Item;
