import { FormattedAddress, RawAddress } from "models/Address";
import { CountryUpperCase } from "models/Countries";
import { PROPERTY_TYPES, TYPE_OF_SERVICE } from "./constants";

export enum ActionType {
  CLOSE_INFO_MODAL,
  SET_IS_OPEN_HELP_MODAL,
  SET_INFO_MODAL,
  SET_PROPERTY_DATA,
  SET_STEP,
  SET_USER,
  UPDATE_PROPERTY_DATA,
}

export const INITIAL_ID = -1;

type Service = keyof typeof TYPE_OF_SERVICE;
type PropertyType = keyof typeof PROPERTY_TYPES;

export enum Steps {
  PROPERTY_DETAILS_STEP = 0,
  CONFIRMATION_STEP = 1,
  FINISHED_STEP = 2,
}

export interface User {
  country: CountryUpperCase;
  name: string;
  id: number;
}

export interface AutoProfileProperty {
  propertyType: PropertyType;
  date: string;
  district?: { label: string; value: string };
  map: number[];
  propertyNumber: string;
  service: Service;
  moreInfo: string;
  address: RawAddress;
  propertyStatus?: string;
}

export interface PropertyData {
  additionalInfo: string;
  deliveryDate: string;
  district: string;
  formattedAddress?: FormattedAddress;
  propertyNumber: string;
  propertyType: PropertyType;
  service: Service;
  id?: number;
  propertyStatus?: string;
}

export interface InfoModal {
  title: string;
  subtitle: string;
  secondSubtitle: string;
  buttonText: string;
  open: boolean;
  advisorContacted?: boolean;
}

export interface State {
  propertyData: PropertyData;
  step: Steps;
  infoModal: InfoModal;
  isOpenHelpModal: boolean;
  user: User;
}

export interface setPropertyData {
  type: typeof ActionType.SET_PROPERTY_DATA;
  propertyData: Partial<PropertyData>;
}

export interface setStep {
  type: typeof ActionType.SET_STEP;
  step: number;
}

export interface setUser {
  type: typeof ActionType.SET_USER;
  user: User;
}

export interface updatePropertyData {
  type: typeof ActionType.UPDATE_PROPERTY_DATA;
  propertyData: Partial<PropertyData>;
}

export interface setInfoModal {
  type: typeof ActionType.SET_INFO_MODAL;
  infoModal: InfoModal;
}

export interface closeNotValidPropertyModal {
  type: ActionType.CLOSE_INFO_MODAL;
}

export interface setIsOpenHelpModal {
  type: typeof ActionType.SET_IS_OPEN_HELP_MODAL;
  open: boolean;
}

export type Action =
  | closeNotValidPropertyModal
  | setPropertyData
  | setStep
  | setIsOpenHelpModal
  | setInfoModal
  | setUser
  | updatePropertyData;

export type Dispatch = (action: Action) => void;
