import { Locale } from "models/Countries";
import { ApplicationCodebtor } from "models/Evaluation";
import { numberWithCommas } from "utils";

const applicantInfo = (applicant: ApplicationCodebtor, country: Locale) => {
  const documentName = country === "mx" ? "RFC" : "Rut";
  return {
    [documentName]: applicant.document,
    Nacionalidad: applicant.nationality,
    Empleador: applicant.employer,
    Cargo: applicant.position,
    Sueldo: `$${numberWithCommas(applicant.salary)}`,
  };
};

export default applicantInfo;
