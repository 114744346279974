import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";

import profileEditService from "apps/account/services/profileEditService/profileEditService";
import { useGetToken } from "hooks";
import { useHoumSnackBar } from "libs/houm-snackbar";

interface TError {
  response: {
    data: {
      message: string;
    };
  };
}

const usePasswordChange = () => {
  const history = useHistory();
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const { getToken } = useGetToken();
  const { mutate, isLoading } = useMutation(
    async ({ password }: { password: string }) => {
      const authToken = await getToken();
      const response = await profileEditService.passwordChange(
        authToken,
        password
      );
      return response;
    },
    {
      onSuccess: () => {
        enqueueHoumSnackBar({
          head: "Contraseña cambiada con éxito",
          body: "",
          type: "success",
        });
        history.push("/account/profile");
      },
      onError: (error: TError) => {
        let message;
        const parsedError = error.response?.data?.message;
        if (parsedError === "PasswordDictionaryError: Password is too common") {
          message =
            "La contraseña es muy común. Intenta con una contraseña más segura";
        } else if (
          parsedError ===
          "PasswordHistoryError: Password has previously been used"
        ) {
          message = "No puedes usar una contraseña que ya hayas usado antes";
        } else {
          message = "Ha ocurrido un error, por favor intenta de nuevo";
        }
        enqueueHoumSnackBar({
          head: message,
          body: "",
          type: "error",
        });
      },
    }
  );

  return {
    mutate,
    submitting: isLoading,
  };
};

export default usePasswordChange;
