import { Box } from "@material-ui/core";

import { Locale } from "models/Countries";

import useCurrentSummaryBreakdown from "apps/landlord/hooks/useCurrentSummaryBreakdown/useCurrentSummaryBreakdown";
import ErrorCard from "apps/landlord/components/ErrorCard";
import Spinner from "Components/Shared/partials/Spinner";
import useRentedPropertyInfoStyles from "./RentedPropertyInfoStyles";
import RentalPaymentsInfo from "./components/RentalPaymentsInfo";

interface Props {
  country: Locale;
  propertyId: number;
  isPlanGuaranteed: boolean;
}

export default function RentedPropertyInfo({
  country,
  propertyId,
  isPlanGuaranteed,
}: Props) {
  const classes = useRentedPropertyInfoStyles();
  const {
    data: summaryData,
    isError,
    isLoading,
    refetch,
  } = useCurrentSummaryBreakdown(propertyId, country);
  const failedToLoad = isError || (!isLoading && !summaryData);

  if (!summaryData) {
    return null;
  }

  return (
    <Box className={classes.rentedInfoCardRoot}>
      {isLoading && <Spinner />}
      {failedToLoad && (
        <ErrorCard
          title="Hubo un problema al obtener la información de tu pago"
          onRefetchData={refetch}
        />
      )}
      <RentalPaymentsInfo
        country={country}
        isPlanGuaranteed={isPlanGuaranteed}
        summaryData={summaryData}
      />
    </Box>
  );
}
