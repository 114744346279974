import { makeStyles, Theme } from "@material-ui/core";

const usePropertyAddressStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "block",
  },
  address: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    lineHeight: 1.5,
  },
  region: {
    color: theme.palette.grey[500],
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
  },
}));

export default usePropertyAddressStyles;
