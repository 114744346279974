import { PARTNERS_URL } from "env";
import { createHttp } from "services/helper";
import { ReferPartnerService } from "./partnersReferralService.types";

const SEND_REFERAL_URL = "v2/users_referred";
const THROW_REFER_EVENT_URL = "v2/events";
const USER_PARTNER_PROFILE = "v2/me";
const SEND_PARTNER_LEAD = "v2/partners_leads";

const referPartnerService: ReferPartnerService = (token: string) => {
  /** Instance based httpClient */
  const httpClient = createHttp(token, PARTNERS_URL);

  /** Brings partner profile info if session matches */
  const getUserPartnerProfile = () => httpClient.get(USER_PARTNER_PROFILE);

  /** creates partner lead if user session does not match */
  const createPartnerLead = (partner, utmData) =>
    httpClient.post(SEND_PARTNER_LEAD, { ...partner, ...utmData });

  /** Send partner referral to partners-services  */
  const sendReferralToPartners = (newPartnerData) =>
    httpClient.post(SEND_REFERAL_URL, {
      ...newPartnerData,
      reason_to_refer: "has_a_property_and_wants_to_rent_it",
    });

  /** Throws event that complete and notify the referal creation flow */
  const throwReferEvent = (eventReferalData) =>
    httpClient.post(THROW_REFER_EVENT_URL, eventReferalData);

  return {
    getUserPartnerProfile,
    sendReferralToPartners,
    createPartnerLead,
    throwReferEvent,
  };
};

export default referPartnerService;
