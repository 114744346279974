import { getDefaultPhone } from "apps/lead/pages/EvaluatorForm/utils/utils";
import { User } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { Locale } from "models/Countries";

export const getDefaultValues = (user: User, country: Locale) => ({
  name: user?.name,
  lastName: user?.lastName,
  secondLastName: user?.secondLastName,
  email: user?.email,
  phone: getDefaultPhone(user?.phone, country),
  documentType: user?.documentType,
  document: user?.document,
  nationality: user?.nationality,
});
