import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  const tableBorder = `1px solid ${theme.palette.grey[50]}`;
  return {
    table: {
      borderCollapse: "separate",
      borderSpacing: "0",
    },
    header: {
      "& $cell": {
        padding: theme.spacing(2),
        fontWeight: "bold",
        color: theme.palette.grey[500],
        position: "relative",
        backgroundColor: theme.palette.grey[50],
        "&:first-child": {
          borderRadius: "8px 0 0 0",
        },
        "&:nth-last-child(2)": {
          borderRadius: "0 8px 0 0",
        },
        "&:not(:first-child):not(:last-child):after": {
          content: '""',
          display: "block",
          height: "50%",
          width: 2,
          position: "absolute",
          left: 0,
          top: "50%",
          transform: "translateY(-50%)",
          backgroundColor: theme.palette.grey[500],
        },
      },
    },
    cellCollapsed: {
      padding: 0,
      border: "none",
    },
    cell: {
      padding: theme.spacing(1, 2),
      borderLeft: tableBorder,
      borderBottom: tableBorder,
      "&:nth-last-child(2)": {
        borderRight: tableBorder,
      },
      "&:only-child": {
        borderRight: tableBorder,
      },
      "&:last-child:not(:only-child)": {
        border: "none",
        backgroundColor: "transparent",
        padding: 0,
      },
    },
    customTable: {
      marginTop: theme.spacing(2),
    },
    icon: {
      fontSize: theme.typography.pxToRem(24),
      display: "inline",
      marginLeft: theme.spacing(2),
      fill: theme.palette.primary.main,
    },
    editIcon: {
      stroke: theme.palette.primary.main,
    },
    trashIcon: {
      borderLeft: `1px solid ${theme.palette.grey[100]}`,
      marginLeft: theme.spacing(2),
    },
    iconDisabled: {
      "& $icon": {
        filter: "grayscale(100%) opacity(40%)",
      },
    },
    iconActions: {
      display: "flex",
    },
    defaultCell: {
      width: "1%",
      padding: theme.spacing(1, 2),
      borderLeft: tableBorder,
      borderBottom: tableBorder,
      "&:nth-last-child(2)": {
        borderRight: tableBorder,
      },
      "&:only-child": {
        borderRight: tableBorder,
      },
      "&:last-child:not(:only-child)": {
        border: "none",
        backgroundColor: "transparent",
        padding: 0,
      },
    },
  };
});

export default useStyles;
