import { makeStyles, Theme } from "@material-ui/core";

const useHelpTooltipStyles = makeStyles<Theme>((theme) => ({
  iconHelp: {
    marginLeft: theme.spacing(0.62),
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export default useHelpTooltipStyles;
