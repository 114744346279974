import { AxiosResponse } from "axios";

import {
  AddressDetailsResponse,
  AddressResponse,
} from "apps/lead/services/AddressService/types";
import { createNoAuthHttp } from "services/helper";
import { Locale } from "models/Countries";

export default {
  searchAddress({
    countryCode,
    addressQuery,
  }: {
    countryCode: Locale;
    addressQuery: string;
  }): Promise<AxiosResponse<AddressResponse>> {
    return createNoAuthHttp().get(`locations/address/${countryCode}/search`, {
      params: {
        q: addressQuery,
      },
    });
  },
  getAddressDetails({
    referenceId,
    countryCode,
  }: {
    referenceId: string;
    countryCode: Locale;
  }): Promise<AxiosResponse<AddressDetailsResponse>> {
    return createNoAuthHttp().get(`/locations/address/${countryCode}/details`, {
      params: { reference_id: referenceId },
    });
  },
};
