import { makeStyles, Theme } from "@material-ui/core/";

const useBasicServicesSectionStyles = makeStyles<Theme>((theme) => ({
  formSectionTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    lineHeight: 1.5,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: theme.palette.grey[900],
  },
  formSectionFieldContainer: {
    display: "flex",
    maxWidth: "unset",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
}));

export default useBasicServicesSectionStyles;
