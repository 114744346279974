import {
  State,
  Action,
  SET_COUNTRY,
  SET_STEP,
  SET_PAYMENT_DATA,
  SET_TENANT_DATA,
  SET_METHOD_SELECTED,
} from "./rentPaymentTypes";

export const initialState: State = {
  country: null,
  paymentData: null,
  step: "landing",
  tenantData: null,
  methodSelected: null,
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_COUNTRY:
      return {
        ...state,
        country: action.country,
      };
    case SET_PAYMENT_DATA: {
      return {
        ...state,
        paymentData: action.paymentData,
      };
    }
    case SET_TENANT_DATA: {
      return {
        ...state,
        tenantData: action.tenantData,
      };
    }
    case SET_STEP:
      return {
        ...state,
        step: action.step,
      };
    case SET_METHOD_SELECTED:
      return {
        ...state,
        methodSelected: action.methodSelected,
      };
    default:
      return state;
  }
};

export default apiReducer;
