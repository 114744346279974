import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";

import { Locale } from "models/Countries";

import { DocumentTypes } from "./utils/types";
import { docTypes } from "./utils/constants";

const useDocumentTypeListPublic = (country: Locale) => {
  const {
    isLoading,
    error,
    data = [],
    isError,
    isSuccess,
  }: UseQueryResult<DocumentTypes[], AxiosError> = useQuery<
    DocumentTypes[],
    AxiosError
  >(
    ["document_type_list_public", country],
    async () => {
      const parseDocumentsType = docTypes[country].map((doc) => ({
        value: doc.id,
        label: doc.type,
        identifier: doc.identifier,
        regex: doc.validation_regex,
      }));
      return parseDocumentsType;
    },
    {
      enabled: !!country,
    }
  );

  return {
    isLoading,
    error,
    data,
    isError,
    isSuccess,
  };
};

export default useDocumentTypeListPublic;
