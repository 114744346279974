import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useOwnerStyles = makeStyles((theme: Theme) => ({
  ownerTitle: {
    color: theme.palette.grey[900],
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
  ownerResume: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2.5, 2),
    backgroundColor: theme.palette.grey[5],
    borderRadius: 12,
  },
  status: {
    borderRadius: theme.typography.pxToRem(15),
    backgroundColor: theme.palette.grey[300],
    width: "fit-content",
    padding: theme.spacing(0, 1),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    marginTop: theme.spacing(1),
    alignItems: "center",
    display: "flex",
  },
  terms: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    display: "inline",
  },
  termsDetails: {
    textDecoration: "underline",
    display: "inline",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    color: theme.palette.primary.main,
    fontWeight: 700,
    "&:hover": {
      cursor: "pointer",
    },
  },
  checkbox: {
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 1),
  },
  nextStepButton: {
    borderRadius: 12,
    textAlign: "center",
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 700,
    textTransform: "none",
    padding: theme.spacing(2, 0),
    borderColor: theme.palette.grey[500],
    marginTop: theme.spacing(1),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      width: "300px",
    },
  },
  flagStatusIcon: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(1),
  },
  flagStatusPending: {
    fontWeight: 700,
    backgroundColor: "#FAF3CC",
    color: "#A07800",
    fill: "#A07800",
  },
  flagStatusValidated: {
    fontWeight: 700,
    backgroundColor: "#D5E3F8",
    color: "#2663BC",
  },
}));

export default useOwnerStyles;
