import { useMutation } from "react-query";

import profileEditService from "apps/account/services/profileEditService/profileEditService";
import { useGetToken } from "hooks";

interface VerificationProps {
  code: string;
  previousEmail: string;
}

const useEmailVerification = () => {
  const { getToken } = useGetToken();
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async ({ code, previousEmail }: VerificationProps) => {
      const authToken = await getToken();
      const response = await profileEditService.confirmEmailChange(
        authToken,
        previousEmail,
        code
      );
      return response.data;
    }
  );
  return {
    mutate,
    submitting: isLoading,
    error: isError,
    success: isSuccess,
  };
};

export default useEmailVerification;
