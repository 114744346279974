import { makeStyles, Theme } from "@material-ui/core";

const useDisclaimerStyles = makeStyles((theme: Theme) => ({
  disclaimerRoot: {
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    borderRadius: 8,
    backgroundColor: "#D5E3F8",
    display: "flex",
  },
  text: {
    color: "#124FA8",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    textAlign: "justify",
  },
  icon: {
    marginRight: theme.spacing(1),
    minWidth: 20,
  },
}));

export default useDisclaimerStyles;
