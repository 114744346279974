import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  contrast?: boolean;
}

const useVisitCardStyles = makeStyles<Theme, Props>((theme) => ({
  visitCardRoot: {
    padding: theme.spacing(1),
    display: "flex",
    borderRadius: 8,
    backgroundColor: ({ contrast }) =>
      contrast ? theme.palette.background.default : "#ffffff",
    cursor: "pointer",
  },
  infoBox: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
  },
  date: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
  info: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
  },
  cancelled: {
    color: theme.palette.error.dark,
  },
}));

export default useVisitCardStyles;
