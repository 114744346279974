import { RepairmentType } from "apps/tenant/services/tenantRepairsService/types";

const getRepairTypeLabel = (type: RepairmentType) => {
  const labels = {
    plumbing: "Plomería",
    locksmith: "Cerrajería",
    heating: "Calefacción",
    structural: "Estructurales",
    cosmetic_repairs: "Arreglos estéticos",
    electrical: "Eléctrico",
    electronic: "Electrónico",
    cleaning: "Limpieza",
  };
  return labels[type];
};
const convertHourToAmPmformat = (hour: number) => {
  const amPm = hour >= 12 ? "pm" : "am";
  const hourFormat = hour % 12 || 12;
  return `${hourFormat}:00 ${amPm}`;
};

export { getRepairTypeLabel, convertHourToAmPmformat };
