const priceFormatter = ({
  price,
  currency,
}: {
  price: string;
  currency: string;
}): string => {
  if (!price) return "";
  const sanitizedPrice = price.replaceAll(/[^0-9,.]/g, "");
  const deletedDots = sanitizedPrice.replaceAll(".", "");
  const replacedCommas = deletedDots.replaceAll(",", ".");
  const parsedPrice = Number(replacedCommas);
  const currencyString = new Intl.NumberFormat("es-CL", {
    style: "currency",
    // we're using CLP format universally by now, with "$" as the currency symbol
    currency: "CLP",
  }).format(parsedPrice);
  if (currency === "CLF") {
    return currencyString.replace("$", "UF ");
  }
  return currencyString;
};

export default priceFormatter;
