import { useMutation } from "react-query";

import { useHoumSnackBar } from "libs/houm-snackbar";
import changeAcceptanceService from "apps/landlord/services/changeAcceptanceService";

interface IUseAcceptTerms {
  document: string;
  token: string;
}

const useAcceptTerms = ({ document, token }: IUseAcceptTerms) => {
  const { enqueueHoumSnackBar } = useHoumSnackBar();

  const repairRequestMutation = useMutation(
    async () =>
      changeAcceptanceService.acceptContractTerms({
        token,
        body: {
          document,
          document_type: 1,
        },
      }),
    {
      onSuccess: () => {
        enqueueHoumSnackBar({
          body: "Se han aceptado los terminos y condiciones",
          type: "success",
        });
      },
      onError: () => {
        enqueueHoumSnackBar({
          body: "Ocurrió un problema al aceptar los terminos y condiciones",
          type: "error",
        });
      },
    }
  );

  return {
    submitData: repairRequestMutation.mutate,
    isLoading: repairRequestMutation.isLoading,
    isSuccess: repairRequestMutation.isSuccess,
  };
};

export default useAcceptTerms;
