import { ReactElement } from "react";

interface Props {
  title: string;
  icon?: ReactElement;
}

const Title = ({ title, icon = null }: Props) => (
  <div className="flex gap-2 items-center">
    {icon && icon}
    <h1 className="text-p18 font-bold">{title}</h1>
  </div>
);

export default Title;
