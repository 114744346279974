import { ReactNode } from "react";

import { Box } from "@material-ui/core";

import useNavbarHeight from "apps/landlord/hooks/useNavbarHeight";

import usePropertyLayoutStyles from "./PropertyLayoutStyles";
import HeaderLayout from "../HeaderLayout";
import SimpleLayout from "../SimpleLayout";

interface Props {
  children: ReactNode;
  titlePage?: string;
  subtitlePage?: string;
  onHandleBack: () => void;
  rootClassName?: string;
  titleClassName?: string;
}

const PropertyLayout = ({
  children,
  onHandleBack,
  titlePage = null,
  subtitlePage = null,
  rootClassName = null,
  titleClassName = null,
}: Props) => {
  const navbarHeight = useNavbarHeight();
  const classes = usePropertyLayoutStyles({ navbarHeight });

  return (
    <SimpleLayout onHandleBack={onHandleBack} rootClassName={rootClassName}>
      <Box className={classes.pageContainer}>
        <HeaderLayout
          handleBack={onHandleBack}
          title={titlePage}
          subtext={subtitlePage}
          titleClassName={titleClassName}
        />
        {children}
      </Box>
    </SimpleLayout>
  );
};

export default PropertyLayout;
