import Spinner from "Components/Shared/partials/Spinner";

import usePlaceToPayMethods from "./hooks/usePlaceToPayMethods";
import PaymentMethods from "../PaymentMethods";

interface Props {
  movementId: string;
  textValue?: string;
}

const PlaceToPayDetails = ({
  movementId,
  textValue = "Valor arriendo",
}: Props) => {
  const { isLoading, data } = usePlaceToPayMethods(movementId);

  if (isLoading) return <Spinner />;

  return (
    <PaymentMethods
      country="co"
      methods={data}
      paymentCompany="PlacetoPay"
      currencyCode="COP"
      textValue={textValue}
      paymentMethodIcon="https://houm-staging-public.s3.us-east-1.amazonaws.com/cms-content/logo_Place_To_Pay_b37b849b6f.svg"
    />
  );
};

export default PlaceToPayDetails;
