/* eslint-disable import/no-cycle */
import TokySDK from "toky-phone-js-sdk";
import {
  IAgentDid,
  ICallbacks,
  TokenAccessKeys,
  TokySessionStatus,
} from "./tokyTypes";
import {
  setTokySession,
  setTokySessionStatus,
  setDidCallConnected,
  setTokyCallId,
  setReadyToCall,
} from "./tokyActions";

const { SessionStatus } = TokySDK;

const handleCallback = (callback: () => void) => {
  if (callback) {
    callback();
  }
};

export const parseGetAccessKeysResponse = (response): TokenAccessKeys => ({
  accessToken: response.data?.data?.access_token,
  refreshToken: response.data?.data?.refresh_token,
});

export const parseGetDidsForAgentResponse = (response): IAgentDid[] =>
  response.data.dids.map((agentDid) => ({
    number: agentDid.number,
    city: agentDid.city,
    friendlyName: agentDid.friendly_name,
  }));

function setupTokySessionEventListeners(
  currentSession,
  tokyDispatch,
  callbacks: ICallbacks = {}
) {
  tokyDispatch(setTokySession(currentSession));

  currentSession.on(SessionStatus.RINGING, () => {
    tokyDispatch(setTokySessionStatus(TokySessionStatus.RINGING));
    handleCallback(callbacks[TokySessionStatus.RINGING]);
  });

  currentSession.on(SessionStatus.FAILED, () => {
    tokyDispatch(setTokySessionStatus(TokySessionStatus.OFF));
    handleCallback(callbacks[TokySessionStatus.FAILED]);
  });

  currentSession.on(SessionStatus.CONNECTED, () => {
    tokyDispatch(setDidCallConnected(true));
    tokyDispatch(setTokyCallId(currentSession._callId));
    tokyDispatch(setTokySessionStatus(TokySessionStatus.CONNECTED));
    handleCallback(callbacks[TokySessionStatus.CONNECTED]);
  });

  currentSession.on(SessionStatus.BYE, () => {
    tokyDispatch(setTokySession(null));
    tokyDispatch(setTokySessionStatus(TokySessionStatus.OFF));
    handleCallback(callbacks[TokySessionStatus.BYE]);
  });

  currentSession.on(SessionStatus.REJECTED, () => {
    tokyDispatch(setTokySession(null));
    tokyDispatch(setTokySessionStatus(TokySessionStatus.OFF));
    handleCallback(callbacks[TokySessionStatus.REJECTED]);
  });
}

export const setupTokyClientEventListeners = (
  Client,
  tokyDispatch,
  callbacks: ICallbacks = {}
) => {
  const { ClientStatus } = TokySDK;

  Client.on(ClientStatus.REGISTERED, () => {
    tokyDispatch(setReadyToCall(true));
    handleCallback(callbacks.REGISTERED);
  });

  Client.on(ClientStatus.CONNECTING, () => {
    tokyDispatch(setTokySessionStatus(TokySessionStatus.CONNECTING));
    handleCallback(callbacks.CONNECTING);
  });

  Client.on(ClientStatus.SESSION_UPDATED, (data) => {
    setupTokySessionEventListeners(data.session, tokyDispatch, callbacks);
  });
};
