import { CountryUpperCase } from "models/Countries";
import { PhoneInput } from "models/PhoneInput";
import { formatPhoneInput } from "utils";

export function parseUserData(user) {
  return {
    id: user.id,
    name: user.name,
    lastName: user.last_name,
    secondLastName: user.second_last_name,
    rut: user.rut,
    phone: formatPhoneInput(user.phone),
    country: user.country,
    email: user.email,
    role: user.role,
  };
}

export interface IUser {
  id: number;
  name: string;
  lastName: string;
  secondLastName: string;
  rut: string;
  phone: PhoneInput;
  email: string;
  country: CountryUpperCase;
  role: string;
}
