import { useGetToken } from "hooks";
import { useMutation } from "react-query";

import evaluationService from "apps/lead/services/EvaluationService";
import { countryCode } from "utils";

import { backendCreateCodebtorParser } from "./utils/parsers";
import { CodebtorDataType } from "./utils/types";

export const useCreateCodebtor = () => {
  const { getToken } = useGetToken();
  return useMutation(async (data: CodebtorDataType) => {
    const authToken = await getToken();
    const parsedData = backendCreateCodebtorParser(data.country, data.user);
    const response = await evaluationService.createCodebtor(
      parsedData,
      countryCode(data.country),
      data.evaluationId,
      authToken
    );
    return response?.data;
  });
};

export default useCreateCodebtor;
