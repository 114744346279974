import { makeStyles, Theme } from "@material-ui/core";
import { MOBILE_HEADER_SPACING } from "apps/lead/constants/layout";

const useMobileViewStyles = makeStyles<Theme>((theme) => ({
  textContainer: {
    margin: theme.spacing(MOBILE_HEADER_SPACING / 8, 2, 2.5, 2),
  },
  image: {
    width: "100%",
    height: 350,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    objectFit: "cover",
    objectPosition: "center center",
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
}));

export default useMobileViewStyles;
