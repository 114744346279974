import { useState } from "react";
import { useMutation } from "react-query";

import { useGetToken } from "hooks";

import { useHoumSnackBar } from "libs/houm-snackbar";
import repairRequestService from "../../services/repairRequestService";
import useProperty from "../useProperty";
import { IRequestForm, IUseRepairRequestAction } from "./types";
import { encodeRepairRequest, uploadRepairPhotos } from "./utils";

const useRepairRequestAction = ({
  onSuccessHandler,
  onErrorHandler,
}: IUseRepairRequestAction) => {
  const { propertyData } = useProperty();
  const { getToken } = useGetToken();
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const [requestForm, setRequestForm] = useState<IRequestForm>({});

  const repairRequestMutation = useMutation(
    async () => {
      const authToken = await getToken();
      const fileNames = await uploadRepairPhotos(authToken, requestForm.photos);
      if (!fileNames) throw new Error("No pudimos subir las fotos");
      return repairRequestService.createRepairRequest(
        authToken,
        propertyData?.id,
        encodeRepairRequest(requestForm, fileNames)
      );
    },
    {
      onSuccess: () => {
        onSuccessHandler();
      },
      onError: () => {
        enqueueHoumSnackBar({
          head: "Ocurrió un problema con tu solicitud",
          body: "No pudimos crear tu solicitud de reparación, por favor intenta de nuevo.",
          type: "error",
        });
        onErrorHandler();
      },
    }
  );

  return {
    requestForm,
    setRequestForm,
    submitData: repairRequestMutation.mutate,
    isLoading: repairRequestMutation.isLoading,
  };
};

export default useRepairRequestAction;
