import { object, string, boolean } from "yup";

export const requiredText = "Obligatorio";

export const fullAddressSchema = object().shape({
  fullAddress: string()
    .required(requiredText)
    .test(
      "isAddressSelected",
      "Debes seleccionar una dirección válida",
      (_, { parent: { isAddressSelected } }) => isAddressSelected
    ),
  isAddressSelected: boolean().required(requiredText),
});
