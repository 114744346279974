import { BillResponse, CompanyType, IBillDetails } from "./type";

export const parseBillDetails = (
  data: BillResponse[],
  companyTypes?: CompanyType[]
): IBillDetails[] =>
  data
    .filter(
      (bill) =>
        companyTypes?.length && companyTypes?.includes(bill.company_type)
    )
    .map((bill) => ({
      clientId: bill.client_info.external_id,
      name: bill.pretty_name,
      totalDebt: bill.total_debt,
      updatedAt: new Date(bill.updated_at),
      status: bill.bill_status,
      isValid: bill.client_info?.is_valid,
      expiredDebt: bill.expired_debt,
      dueDate: bill.due_date ? new Date(bill.due_date) : null,
    }));

export default parseBillDetails;
