import * as yup from "yup";

import { DocumentTypes } from "hooks/useDocumentTypeListPublic/utils/types";
import { phoneObject } from "utils/yupValidators/phone";

export const requiredText = "Obligatorio";

const yupDocument = new yup.ValidationError(
  "Documento no válido",
  null,
  "document",
  "typeError"
);

const yupDocumentType = new yup.ValidationError(
  requiredText,
  null,
  "documentType",
  "typeError"
);

export const userFormSchema = (
  documentList: DocumentTypes[],
  emailList: string[] = []
) =>
  yup
    .object({
      email: yup
        .string()
        .email("Ingrese un email válido")
        .nullable()
        .required(requiredText)
        .test("email", null, (obj) => {
          const isRepeatEmail = emailList.includes(obj);
          if (emailList.length === 0 || !isRepeatEmail) return true;
          return new yup.ValidationError(
            "Cada codeudor y arrendatario principal deben tener un correo distinto.",
            null,
            "email",
            "typeError"
          );
        }),
      phone: phoneObject
        .typeError("Número de celular inválido")
        .required(requiredText),
      name: yup.string().nullable().required(requiredText),
      lastName: yup.string().nullable().required(requiredText),
      secondLastName: yup.string().nullable().required(requiredText),
      document: yup.string().nullable().required(requiredText),
      documentType: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .required(requiredText),
      nationality: yup.string().nullable().required(requiredText),
    })
    .test("validationDocument", null, (obj) => {
      if (!obj.documentType) return yupDocumentType;
      if (!obj.document) return yupDocument;
      const regexValue = documentList.find(
        (doc) => doc.value === Number(obj.documentType)
      );
      const regex = new RegExp(regexValue.regex);
      if (regex.test(obj.document)) return true;
      return yupDocument;
    });
