import { makeStyles, Theme } from "@material-ui/core";

const useInputStyles = makeStyles<Theme>((theme) => ({
  calendarLabel: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export default useInputStyles;
