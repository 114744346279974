import { CountryUpperCase } from "models/Countries";
import { PhoneInput } from "models/PhoneInput";
import { Files, WorkerType } from "../models/Application";

interface Applicant {
  country: string;
  email: string;
  document: string;
  documentType: number;
  lastName: string;
  name: string;
  phone: string | PhoneInput;
  secondLastName: string;
  workerType: WorkerType;
  files: {
    identityCard: Files[];
    contract?: Files[];
    salarySettlements?: Files[];
    quotes?: Files[];
    f22?: Files[];
    pensionSettlements?: Files[];
  };
  occupation?: string;
}

const parseFiles = (user: Partial<Applicant>) => {
  const formData = new FormData();
  const data = Object.values(user.files);
  const newArray = Array.prototype.concat.apply([], data);
  newArray.forEach((file) => {
    formData.append("files", file);
  });
  formData.append("is_new", "true");
  return formData;
};

export const parseDataUploadFiles = (
  userId: number,
  evaluationId: number,
  user: Partial<Applicant>,
  country: CountryUpperCase
) => ({
  userId,
  evaluationId,
  files: parseFiles(user),
  country,
});

export const parseCodebtorData = (
  user: Partial<Applicant>,
  country: CountryUpperCase,
  evaluationId: number
) => ({
  user,
  evaluationId,
  country,
});
