import { useEffect } from "react";

import { FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";

import FilesUploadForm from "apps/lead/components/EvaluatorForm/FilesForm/FilesUploadForm";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import AlertSnackbar from "apps/lead/components/AlertSnackbar";
import useStorageFiles from "apps/lead/hooks/useStorageFiles";

import { useAlertMessage } from "apps/lead/hooks/useAlertMessage";
import useCodebtorCreate from "../../hooks/useCodebtorCreate";

const FilesForm = () => {
  const history = useHistory();
  const { methods, onSubmit, loading, requirements, isSuccess } =
    useCodebtorCreate();
  const {
    handleBackClick,
    handleChange,
    uploadFile,
    removeFile,
    workerType,
    activeStep,
    steps,
    files,
  } = useStorageFiles();
  const { responseMessage, handleCloseAlert, handleOpenAlert } =
    useAlertMessage(() => history.push("/tenant"));

  useEffect(() => {
    if (isSuccess) {
      handleOpenAlert(
        true,
        "success",
        "La evaluación se ha creado exitosamente"
      );
    }
  }, [isSuccess]);

  return (
    <>
      <FormProvider {...methods}>
        <Header
          title="Documentación codeudor"
          subtitle="Adjunta los siguientes documentos señalados"
          activeStep={activeStep}
          steps={steps}
        />
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FilesUploadForm
            requirements={requirements}
            files={files}
            uploadFile={uploadFile}
            removeFile={removeFile}
            workerType={workerType}
            handleChange={handleChange}
          />
          <CallToAction
            label="Adjuntar"
            onHandleBackClick={handleBackClick}
            isLoading={loading}
          />
        </form>
      </FormProvider>
      <AlertSnackbar
        color={responseMessage?.type === "success" ? "#48AA02" : "#EA1601"}
        reason={responseMessage?.message}
        handleClose={handleCloseAlert}
        open={responseMessage.open}
      />
    </>
  );
};

export default FilesForm;
