import { ReactElement } from "react";

import Box from "@material-ui/core/Box";

import useIconBoxStyles from "./IconBoxStyles";

interface Props {
  children: ReactElement;
}

const IconBox = ({ children }: Props) => {
  const classes = useIconBoxStyles();
  return <Box className={classes.iconContainer}>{children}</Box>;
};

export default IconBox;
