import { IVisit } from "apps/tenant/models/Visit";
import { compareDesc } from "date-fns";

export const getPreviewVisits = (
  isMobile: boolean,
  visits?: IVisit[]
): IVisit[] => {
  const filteredVisits =
    visits?.filter((visit) => compareDesc(new Date(), visit.date) === 1) || [];

  if (filteredVisits.length === 0) return [];

  if (isMobile) {
    return [filteredVisits[0]];
  }
  if (filteredVisits.length > 3) return filteredVisits.slice(0, 3);
  return filteredVisits;
};

export const getCurrentMonthVisits = (visits?: IVisit[]): number => {
  if (!visits) return 0;
  const currentMonth = new Date().getMonth();
  return visits?.filter((visit) => visit.date.getMonth() === currentMonth)
    .length;
};

export default getPreviewVisits;
