import { useProperty } from "apps/landlord/hooks";
import { useHistory } from "react-router-dom";

import userService from "services/userService";

import usePropertySettingUp from "apps/landlord/hooks/propertySettingUp/usePropertySettingUp";
import { MissingData } from "apps/landlord/hooks/propertySettingUp/types";
import {
  UnfinishedSettingUpModalAction,
  UseUnfinishedSettingUpBanner,
} from "./types";
import COUNTRY_BASED_TRANSLATIONS from "./constants";

const useUnfinishedSettingUpBanner: UseUnfinishedSettingUpBanner = () => {
  const { propertyData } = useProperty();
  const history = useHistory();
  const user = userService.me();
  const { hasMissingData, filteredMissingData, isError, isLoading } =
    usePropertySettingUp({
      propertyUuid: propertyData?.uid,
      userCountry: user?.country,
      propertyId: propertyData?.id,
      userId: user?.id,
    });

  const countryBasedTranslations =
    COUNTRY_BASED_TRANSLATIONS[user?.country || "Chile"];

  const actions_dict: Record<
    keyof Pick<
      MissingData,
      | "missingBankAccount"
      | "missingBasicServicesAccount"
      | "pendingPhotoSchedule"
      | "pendingSettingUpPrice"
    >,
    UnfinishedSettingUpModalAction
  > = {
    missingBankAccount: {
      title: countryBasedTranslations.missingBankAccount.title,
      description: countryBasedTranslations.missingBankAccount.description,
      onClick: () =>
        history.push(`/landlord/properties/${propertyData.uid}/bank-account`),
    },
    missingBasicServicesAccount: {
      title: countryBasedTranslations.missingBasicServicesAccount.title,
      description:
        countryBasedTranslations.missingBasicServicesAccount.description,
      onClick: () =>
        history.push(`/landlord/properties/${propertyData?.uid}/details`),
    },
    pendingPhotoSchedule: {
      title: countryBasedTranslations.pendingPhotoSchedule.title,
      description: countryBasedTranslations.pendingPhotoSchedule.description,
      onClick: () =>
        history.push(
          `/landlord/properties/${propertyData?.uid}/schedule-photo`
        ),
    },
    pendingSettingUpPrice: {
      title: countryBasedTranslations.pendingSettingUpPrice.title,
      description: countryBasedTranslations.pendingSettingUpPrice.description,
      onClick: () =>
        history.push(`/landlord/property-price/${propertyData?.id}`),
    },
  };

  const missingDataActions = filteredMissingData
    .map((missingDatakey) => actions_dict[missingDatakey])
    .filter(Boolean);

  return {
    missingDataActions,
    hasMissingData,
    isLoading,
    isError,
  };
};

export default useUnfinishedSettingUpBanner;
