import { getCountryPhone } from "utils/verifyPhoneNumber";
import { getFormattedPhone } from "apps/lead/utils/phone";
import { Country, PhoneInput } from "models/PhoneInput";
import { formatPhoneInput } from "utils";
import { Locale } from "models/Countries";

export const getDefaultPhone = (phone: string | null, country: Locale) => {
  const defaultCountry: Country = getCountryPhone(country);
  const defaultPhone: PhoneInput = { country: defaultCountry, phone: "" };
  if (typeof phone === "string") return formatPhoneInput(phone);
  return getFormattedPhone(defaultPhone, phone);
};
