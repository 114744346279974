import { InspectionReport } from "models/InspectionReport";

import CollapsibleTable from "Components/Admin/UIComponents/CollapsibleTable/CollapsibleTable";
import parseInspectionReportForDeskTable from "./utils/parsers";
import getTableCols from "./utils/columns";
import useInspectionTableStyles from "./InspectionTableStyles";

interface Props {
  inspectionReport: InspectionReport;
  isLoading: boolean;
}

const InspectionTable = ({ inspectionReport, isLoading }: Props) => {
  const rows = parseInspectionReportForDeskTable(inspectionReport);
  const classes = useInspectionTableStyles();

  return (
    <CollapsibleTable
      rows={rows}
      headers={getTableCols(classes)}
      hasExpandIcon={false}
      loading={isLoading}
    />
  );
};

export default InspectionTable;
