import { IProperty } from "apps/landlord/models/Property";

import PropertyPrice from "../PropertyPrice";
import PropertyCard from "../PropertyCard";

interface Props {
  propertyData: Partial<IProperty>;
}

const SalesProperty = ({ propertyData }: Props) => (
  <PropertyCard
    propertyData={propertyData}
    status={propertyData.propertyDetails.status}
  >
    <PropertyPrice
      currencyCode={propertyData.propertyDetails.currencyCode}
      propertyPrice={propertyData.propertyDetails.price}
    />
  </PropertyCard>
);

export default SalesProperty;
