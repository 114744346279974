import { UserModel } from "./User";
import { DocumentType } from "./Document";

export interface Codebtor {
  id?: number;
  created_at?: string;
  updated_at?: string;
  name?: string;
  rut?: string;
  document?: string;
  email?: string;
  phone?: string;
  address?: string;
  city?: string;
  tributary_type?: string;
  nonce?: string;
  country?: string;
  ws_signatory_id?: number | string;
  civil_status?: string;
  marital_regime?: string;
  document_type?: DocumentType;
  nationality?: string;
  occupation?: string;
  rfcNumber?: string;
  averageSalary?: string | number;
  sign?: {
    id?: number;
    created_at?: string;
    updated_at?: string;
    s3_ci_front_key?: string;
    s3_ci_back_key?: string;
    s3_ci_signature_key?: string;
  };
}

export interface IDocumentFile {
  content_type?: string;
  evaluation_id?: number;
  filename?: string;
  id?: number;
  path?: string;
}

export interface EvaluationModel {
  brokerage?: boolean;
  codebtor?: number;
  contracts?: number[];
  created_at?: string;
  documents?: IDocumentFile[];
  global_status?: string;
  is_belvo?: null;
  id?: number;
  is_verified?: boolean;
  main_applicant?: UserModel;
  nip_token?: string;
  property_id?: number;
  property_uid?: string;
  related_applicant_id?: string;
  status?: string;
  updated_at?: string;
  user?: UserModel;
}

export interface ApplicationCodebtor {
  id: number;
  nationality?: string;
  name: string;
  document: string;
  document_type: number;
  employer: string;
  last_name: string;
  occupation: string;
  position: string;
  salary: string;
  role: string;
}

export enum EvaluationStatus {
  APPROVED = "approved",
  REEVALUATE = "reevaluation",
  REJECTED = "rejected",
  PENDING = "pending",
}

export interface Application {
  contract_init_date: string;
  contract_habitants_description: string;
  applicants: ApplicationCodebtor[];
  id: number;
  created_at: string;
  status: EvaluationStatus;
  quantity_applicants: number;
  total_salary: number;
  has_pets: boolean;
}

export interface CreateLandlordEvaluationResponse {
  contract_habitants_description: string;
  contract_init_date: string;
  has_pets: boolean;
  id: number;
  main_applicant_id: number;
  property_id: number;
  status: string;
}
