import { ClipboardEvent, useState } from "react";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "apps/ui/formComponents/Input";

import ProfileCard from "../ProfileCard";
import ButtonsSection from "../ButtonsSection";
import SentLink from "./components/SentLink";
import useStartEmailChange from "./hooks/useStartEmailChange";
import { validationSchema } from "./validationSchema";
import useEmailFormStyles from "./EmailFormStyles";
import { IEmailForm } from "../../utils/types";

interface Props {
  email: string;
}

const EmailForm = ({ email }: Props) => {
  const [edit, setEdit] = useState(false);
  const classes = useEmailFormStyles();
  const { sent } = useParams<{ sent?: string }>();
  const { t } = useTranslation("account.profile");
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
  });
  const { mutate, submitting } = useStartEmailChange();
  const watchEmail = watch("email");
  const watchEmailVerification = watch("emailVerification");

  const preventCopyPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const onSubmit = (data: IEmailForm) => mutate(data);

  return (
    <ProfileCard text={!isMobile && t("email_text")}>
      {sent && <SentLink email={email} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          id="email"
          label={edit && "Tu nuevo correo electrónico"}
          defaultValue={!edit ? email : null}
          type="email"
          disabled={!edit}
          required={edit}
          error={!!errors.email}
          message={errors.email?.message as string}
          onCopy={preventCopyPaste}
          onPaste={preventCopyPaste}
          onCut={preventCopyPaste}
          classNames={{ root: classes.emailInput }}
          {...register("email")}
        />
        {edit && (
          <Input
            id="emailVerification"
            label={edit && "Ingresa de nuevo tu correo electrónico"}
            subLabel="No se puede copiar y pegar"
            type="email"
            disabled={!edit}
            required={edit}
            error={!!errors.emailVerification}
            message={errors.emailVerification?.message as string}
            onCopy={preventCopyPaste}
            onPaste={preventCopyPaste}
            onCut={preventCopyPaste}
            {...register("emailVerification")}
          />
        )}
        <ButtonsSection
          edit={edit}
          setEdit={setEdit}
          submitting={submitting}
          disabled={!watchEmail && !watchEmailVerification}
        />
      </form>
    </ProfileCard>
  );
};

export default EmailForm;
