import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, Theme, useMediaQuery } from "@material-ui/core";
import useTheme from "@material-ui/styles/useTheme";

import { ResponseToOffer } from "context/negotiationsContext/negotiationsTypes";
import useRejectFormStyles from "./RejectFormStyles";
import { ControlledOptions } from "Components/Admin/UIComponents/ControlledForm";
import { rejectedReasons } from "context/negotiationsContext/constants";
import { reasonValidationSchema } from "./validationSchema";
import { setResponseToOffer } from "context/negotiationsContext/negotiationsActions";
import { useNegotiations } from "context/negotiationsContext/negotiationsContext";
import CallToAction from "Components/Admin/UIComponents/atoms/CallToAction";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

interface RejectFormReasonStepProps {
  handleStep: (number) => void;
}

const RejectFormReasonStep = ({ handleStep }: RejectFormReasonStepProps) => {
  const { dispatch } = useNegotiations();
  const classes = useRejectFormStyles();
  const theme: Theme = useTheme();
  const isDesktop: boolean = useMediaQuery(theme.breakpoints.up("md"));

  const methods = useForm<Partial<ResponseToOffer>>({
    reValidateMode: "onBlur",
    resolver: yupResolver(reasonValidationSchema),
  });

  const onSubmit: SubmitHandler<ResponseToOffer> = (data) => {
    dispatch(setResponseToOffer(data));
    handleStep(data.reason);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ControlledOptions
          label="Motivo del rechazo"
          id="reason"
          options={rejectedReasons}
          optionClassName={classes.option}
        />
        {isDesktop ? (
          <CallToAction>
            <Box className={classes.ctaContent}>
              <DesignSystemButton
                variant="primary"
                label="Continuar"
                type="submit"
                size="medium"
              />
            </Box>
          </CallToAction>
        ) : (
          <Box className={classes.ctaContent}>
            <DesignSystemButton
              variant="primary"
              label="Continuar"
              type="submit"
              size="medium"
            />
          </Box>
        )}
      </form>
    </FormProvider>
  );
};

export default RejectFormReasonStep;
