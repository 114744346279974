import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { INavbarItem } from "../../../utils/types";

const useNavbarItem = (
  link: string,
  nested: INavbarItem[],
  target: "self" | "blank"
) => {
  const [open, setOpen] = useState(false);
  const [renderSubitems, setRenderSubitems] = useState(false);
  const history = useHistory();
  const buttonRef = useRef(null);

  // TO DO: make this a utilitarian function
  useEffect(() => {
    const handleClose = () => setOpen(false);
    const handleClickOutside = (event: MouseEvent) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target as HTMLElement)
      ) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [buttonRef]);

  useEffect(() => {
    setTimeout(() => {
      if (nested?.length > 0) setRenderSubitems(true);
    }, 500);
  }, [nested]);

  const handleClick = () => {
    if (nested?.length > 0) return setOpen(!open);
    if (target === "blank") return window.open(link);
    history.push(link);
  };

  return {
    buttonRef,
    handleClick,
    open,
    setOpen,
    renderSubitems,
  };
};

export default useNavbarItem;
