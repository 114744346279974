import { isMobile } from "react-device-detect";

import {
  IoChevronDownOutline as ArrowDown,
  IoChevronForwardOutline as ArrowRight,
} from "@houm-com/ui-icons";

import { IMainMenuItem } from "../../utils/types";
import useNavbarItemStyles from "./NavbarItemStyles";
import MobileSubmenu from "./components/MobileSubmenu";
import useNavbarItem from "./hooks/useNavbarItem";
import DesktopSubmenu from "./components/DesktopSubmenu";
import NavbarLabel from "../NavbarLabel/NavbarLabel";

const NavbarItem = ({ label, link, nested, target }: IMainMenuItem) => {
  const { buttonRef, handleClick, open, setOpen, renderSubitems } =
    useNavbarItem(link, nested, target);
  const classes = useNavbarItemStyles({ open });

  return (
    <div className={classes.navbarItemRoot}>
      <div className={classes.button} onClick={handleClick} ref={buttonRef}>
        <NavbarLabel label={label} className={classes.label} />
        {nested?.length > 0 && isMobile ? (
          <ArrowRight className={classes.arrowMobile} />
        ) : (
          <ArrowDown className={classes.arrow} />
        )}
      </div>
      {renderSubitems && !isMobile && (
        <DesktopSubmenu items={nested} open={open} />
      )}
      {renderSubitems && isMobile && (
        <MobileSubmenu
          items={nested}
          label={label}
          open={open}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};

export default NavbarItem;
