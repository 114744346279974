import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

export default makeStyles((theme: Theme) => ({
  cardFeedback: {
    padding: theme.spacing(2.75),
    borderRadius: 10,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: 270,
    height: "100%",
    minHeight: 200,
    boxShadow: "4px 4px 30px rgba(0, 43, 41, 0.15)",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(2.75),
    },
  },
  houmerText: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.grey[900],
    textAlign: "left",
  },
  feedbackText: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[900],
    textAlign: "left",
  },
  dateText: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[500],
    textAlign: "left",
    marginTop: theme.spacing(2.5),
  },
  houmerSubText: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.primary.main,
    textAlign: "left",
    marginBottom: theme.spacing(2.5),
  },
}));
