import RentPayment from "apps/payments/pages/RentPayment";
import { Route, useRouteMatch } from "react-router-dom";

const PaymentsPublicRoutes = () => {
  const match = useRouteMatch();
  return (
    <Route exact path={[match.path, `${match.path}/:locale`]}>
      <RentPayment />
    </Route>
  );
};

export default PaymentsPublicRoutes;
