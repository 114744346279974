import { Grid, Modal, Typography } from "@material-ui/core";
import { PropertyBusinessType } from "apps/landlord/models/Property";
import useUnpublishPropertyStyles from "../PropertyStatusManagerButtonStyles";
import PropertyStatusManagerForm from "./PropertyStatusManagerForm";
import UnpublishDialogDescription from "./UnpublishDialogDescription";

interface PropertyStatusManagerDialogProps {
  open: boolean;
  mode: PropertyBusinessType;
  onClose: () => void;
}

const PropertyStatusManagerDialog = ({
  open,
  mode,
  onClose,
}: PropertyStatusManagerDialogProps) => {
  const classes = useUnpublishPropertyStyles();

  const dialogTitle = `Estás por despublicar tu propiedad en ${
    mode === PropertyBusinessType.RENT ? "arriendo" : "venta"
  }`;

  return (
    <Modal open={open} className={classes.modalRoot}>
      <Grid container className={classes.dialogWrapper}>
        <Typography className={classes.dialogTitle}>{dialogTitle}</Typography>
        <UnpublishDialogDescription />
        <PropertyStatusManagerForm mode={mode} onClose={onClose} />
      </Grid>
    </Modal>
  );
};

export default PropertyStatusManagerDialog;
