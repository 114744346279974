export const BUSINESS_TYPES = [
  {
    label: "Arriendo",
    value: "rent",
  },
  {
    label: "Venta",
    value: "sale",
  },
  {
    label: "Ambas",
    value: "both",
  },
];

export const PROPERTY_TYPES = [
  {
    label: "Departamento",
    value: "flat",
  },
  {
    label: "Casa",
    value: "house",
  },
];

export const PROPERTY_STATUS = [
  {
    label: "Nueva",
    value: "new",
  },
  {
    label: "Usada",
    value: "used",
  },
];
