import { UseQueryResult, useQuery } from "react-query";

import scheduleService from "apps/tenant/services/scheduleService/scheduleService";
import { Locale } from "models/Countries";
import { parseAvailableHours } from "./utils/parsers";
import { ITimeOption } from "../utils/types";

const useAvailableHours = ({
  propertyId,
  countryCode,
}: {
  propertyId: number;
  countryCode: Locale;
}) => {
  const {
    isLoading,
    error,
    data,
  }: UseQueryResult<Record<string, ITimeOption[]>, Error> = useQuery<
    Record<string, ITimeOption[]>,
    Error
  >(
    ["availableHours", propertyId],
    async () => {
      const response = await scheduleService.availableHours(propertyId);
      return parseAvailableHours({ rawDates: response.data, countryCode });
    },
    {
      enabled: !!propertyId,
    }
  );

  return { isLoading, error, data };
};

export default useAvailableHours;
