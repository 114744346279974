import { useFormContext } from "react-hook-form";

import FieldInput from "@houm-com/ui/FieldInput";
import FieldRadioButton from "@houm-com/ui/FieldRadioButton";
import FieldTextArea from "@houm-com/ui/FieldTextArea";

import DesignSystemCalendar from "Components/Admin/UIComponents/Calendar/DesignSystemCalendar";
import { ControlledAddressText } from "Components/Admin/UIComponents/ControlledForm";
import { CountryUpperCase } from "models/Countries";
import { getDefaultLocation } from "utils/map";
import { countryCode } from "utils";

import { BUSINESS_TYPES, PROPERTY_STATUS, PROPERTY_TYPES } from "./constants";
import MapSelect from "./components/MapSelect";
import useInputStyles from "./InputsStyles";

interface Props {
  userCountry: CountryUpperCase;
}

const Inputs = ({ userCountry }: Props) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const classes = useInputStyles();

  const locale = countryCode(userCountry);
  const { lat, lng } = getDefaultLocation(locale);

  return (
    <>
      <ControlledAddressText
        id="address"
        label="Dirección de tu propiedad"
        placeholder="Ej: Av. Providencia 1234, Providencia, Santiago"
        country={userCountry}
        {...register("address")}
      />
      <MapSelect lat={lat} lng={lng} locale={locale} />
      <FieldRadioButton
        id="propertyType"
        label="¿Qué tipo de propiedad es?"
        options={PROPERTY_TYPES}
        required
        errorMessage={errors.propertyType?.message}
        {...register("propertyType")}
      />
      <FieldInput
        id="propertyNumber"
        name="propertyNumber"
        label="Número de departamento o casa"
        labelPosition="top"
        placeholder="Ej: 1234"
        type="text"
        errorMessage={errors.propertyNumber?.message}
        required
        {...register("propertyNumber")}
      />
      <FieldRadioButton
        id="service"
        label="¿Qué tipo de publicación quieres hacer?"
        options={BUSINESS_TYPES}
        errorMessage={errors.propertyNumber?.message}
        required
        {...register("service")}
      />
      <FieldRadioButton
        id="propertyStatus"
        label="Estado de la propiedad"
        subLabel="Es considerada una propiedad nueva si no ha sido habitada antes, es decir, recién ha sido entregada por la inmobiliaria."
        options={PROPERTY_STATUS}
        required={locale === "cl"}
        errorMessage={errors.propertyStatus?.message}
        {...register("propertyStatus")}
      />
      <section>
        <p className={classes.calendarLabel}>¿Desde cuándo esta disponible?</p>
        <DesignSystemCalendar
          onChange={(e: Date) => setValue("date", e)}
          value={watch("date")}
        />
        {errors.date && (
          <p className="text-p12 text-alert">{errors.date?.message}</p>
        )}
      </section>
      <FieldTextArea
        id="moreInfo"
        label="Mas información"
        placeholder="Ampliado o remodelado recientemente, etc."
        {...register("moreInfo")}
      />
    </>
  );
};

export default Inputs;
