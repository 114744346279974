import { Box, Typography } from "@material-ui/core";

import { IoChatboxOutline as ChatDialog } from "@houm-com/ui-icons";
import useInspectionReportCommentsStyles from "../InspectionReportCommentsStyles";

const MoCommentsGuidelines = () => {
  const classes = useInspectionReportCommentsStyles();
  return (
    <Box className={classes.noCommentsGuideline}>
      <Box className={classes.noCommentsGuidelineIcon}>
        <ChatDialog size={20} />
      </Box>
      <Typography className={classes.noCommentsGuidelinesText}>
        Sabemos que puede haber detalles que salen incluso después de que el
        acta se realizó. Agrega cualquier detalle que hayas notado para tener
        referencia de ello al momento que entregues la propiedad.
      </Typography>
      <Box className={classes.noCommentsGuidelinesWarning}>
        <Typography className={classes.noCommentsGuidelinesWarningText}>
          Es importante que completes esta información ya que será tu respaldo
          al momento en que decidas salir de la propiedad ya que se revisará
          durante el proceso de devolución de la garantía.
          <strong>
            Recuerda que esto solo lo podrás hacer en los próximos 30 días.
          </strong>
        </Typography>
      </Box>
    </Box>
  );
};

export default MoCommentsGuidelines;
