import useBillsInfo from "apps/landlord/hooks/propertyBills/useBillInfo";
import useCompanies from "apps/landlord/hooks/propertyBills/useCompanies";
import Spinner from "Components/Shared/partials/Spinner";
import BasicServicesSection from "../../PropertyDetails/components/PropertyDetailsContent/components/BasicServicesForm/components/BasicServicesSection";

const BasicServicesFields = () => {
  const {
    isLoading: companiesLoading,
    waterCompaniesOptions,
    energyCompaniesOptions,
    gasCompaniesOptions,
  } = useCompanies();
  const { data: billsData, isLoading: billsLoading } = useBillsInfo();
  const waterCompanySelectedName = billsData?.water?.company;
  const energyCompanySelectedName = billsData?.energy?.company;
  const gasCompanySelectedName = billsData?.gas?.company;

  const waterCompanySelectedNumber = billsData?.water?.clientInfo.externalId;
  const energyCompanySelectedNumber = billsData?.energy?.clientInfo.externalId;
  const gasCompanySelectedNumber = billsData?.gas?.clientInfo.externalId;

  if (companiesLoading || billsLoading) return <Spinner />;

  return (
    <>
      <BasicServicesSection
        idBase="water"
        title="Agua"
        options={waterCompaniesOptions}
        defaultSelectValue={waterCompanySelectedName}
        defaultFieldValue={waterCompanySelectedNumber}
      />
      <BasicServicesSection
        idBase="energy"
        title="Luz"
        options={energyCompaniesOptions}
        defaultSelectValue={energyCompanySelectedName}
        defaultFieldValue={energyCompanySelectedNumber}
      />
      <BasicServicesSection
        idBase="gas"
        title="Gas"
        options={gasCompaniesOptions}
        required={false}
        defaultSelectValue={gasCompanySelectedName}
        defaultFieldValue={gasCompanySelectedNumber}
      />
    </>
  );
};

export default BasicServicesFields;
