import { useEffect } from "react";
import { isMobile } from "react-device-detect";

import { Box } from "@material-ui/core";

import useGetVisits from "apps/tenant/hooks/useGetVisits";
import useUser from "apps/tenant/hooks/useUser";
import { useNavbar } from "apps/ui/Navbar/context/navbarContext";
import Spinner from "Components/Shared/partials/Spinner";

import DesktopView from "./components/DesktopView";
import MobileView from "./components/MobileView";
import VisitsHeader from "./components/VisitsHeader";
import useVisits from "./hooks/useVisits";
import useVisitsStyles from "./VisitsStyles";

const Visits = () => {
  const classes = useVisitsStyles();
  const { viewMode, toggleViewMode, selectedDate, setSelectedDate } =
    useVisits();
  const { isLoading: userLoading, user } = useUser();
  const { isLoading, data } = useGetVisits(user?.email);
  const { setBackUrl } = useNavbar();

  useEffect(() => {
    if (isMobile) setBackUrl("default");
  }, [setBackUrl]);

  if (userLoading || isLoading) {
    return <Spinner className={classes.spinner} />;
  }

  return (
    <Box className={classes.visitsRoot}>
      <Box className={classes.visitsContent}>
        <VisitsHeader viewMode={viewMode} toggleViewMode={toggleViewMode} />
        {isMobile && (
          <MobileView
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            viewMode={viewMode}
            visits={data}
          />
        )}
        {!isMobile && (
          <DesktopView
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            visits={data}
          />
        )}
      </Box>
    </Box>
  );
};

export default Visits;
