import CustomRadioButton from "apps/tenant/components/Forms/CustomRadioButton/CustomRadioButton";
import CustomTextField from "Components/Admin/UIComponents/CustomTextField";
import { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import CustomFileDropzone from "apps/components/CustomFileDropzone";
import { IRequestForm } from "apps/tenant/hooks/utils/types";
import { RepairmentType } from "apps/tenant/services/tenantRepairsService/types";
import REPAIRS_TYPES_OPTIONS from "./constants";
import useRepairFormStyles from "./RepairFormStyles";

interface Props {
  handleNext: () => void;
  setRequestForm: (value: IRequestForm) => void;
  requestForm: IRequestForm;
}

const RepairForm = ({ handleNext, setRequestForm, requestForm }: Props) => {
  const [motive, setMotive] = useState<string | null>(
    requestForm?.repairType || null
  );
  const [observations, setObservations] = useState<string>(
    requestForm?.observation || null
  );
  const [files, setFiles] = useState<File[]>(requestForm.photos || []);
  const classes = useRepairFormStyles();

  const onFilesChange = (f: File[]) => {
    setFiles(f);
  };

  return (
    <>
      <CustomRadioButton
        label="Tipo de reparación"
        value={motive}
        onChange={(e) => {
          setMotive(e.target.value);
          setRequestForm({
            ...requestForm,
            repairType: e.target.value as RepairmentType,
          });
        }}
        id="repairType"
        options={REPAIRS_TYPES_OPTIONS}
      />
      <CustomFileDropzone
        id="files"
        onChange={(f) => {
          onFilesChange(f as File[]);
          setRequestForm({ ...requestForm, photos: f as File[] });
        }}
        label="Foto"
        maxFiles={10}
      />
      <Typography className={classes.textFieldLabel}>
        Descripción del problema
      </Typography>
      <CustomTextField
        className={classes.textField}
        minRows={3}
        multiline
        wrapperPadding={false}
        required
        onChange={(e) => {
          setObservations(e.target.value);
          setRequestForm({ ...requestForm, observation: e.target.value });
        }}
        value={observations}
        placeholder="Escribe aquí tu texto"
        maxLength={1024}
      />
      <Box className={classes.ctaContainer}>
        <DesignSystemButton
          disabled={!motive || !observations || files.length === 0}
          variant="primary"
          size="small"
          label="Siguiente"
          onClick={handleNext}
        />
      </Box>
    </>
  );
};

export default RepairForm;
