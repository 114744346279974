import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji/Emoji";

import useServicesHeaderStyles from "./ServicesHeaderStyles";

interface Props {
  noServices?: boolean;
}

const ServicesHeader = ({ noServices = false }: Props) => {
  const classes = useServicesHeaderStyles();

  return (
    <Box className={classes.servicesHeaderRoot}>
      <Typography className={classes.servicesTitle}>
        Servicios básicos y gastos comunes
      </Typography>
      {noServices ? (
        <Typography className={clsx(classes.message, classes.noInfo)}>
          Todavía no tenemos información relacionada a los servicios básicos o
          gastos comunes de tu propiedad.
        </Typography>
      ) : (
        <Typography className={classes.message}>
          {"Esta información se actualizará todos los meses. "}
          <Emoji emojiIcon="😁" ariaLabel="relax" />
        </Typography>
      )}
    </Box>
  );
};

export default ServicesHeader;
