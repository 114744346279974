import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useEvaluationDialogStyles = makeStyles((theme: Theme) => ({
  contentDialog: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    paddingBottom: 0,
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      paddingTop: theme.spacing(3),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.4,
  },
}));

export default useEvaluationDialogStyles;
