export const ROLES_USER_API_DATA_KEY = "https://api.houm.com/roles";

export const ADMIN = "admin";
export const SUPER = "super_admin";
export const CT_ADMIN = "control_tower_admin";
export const SALES_ADMIN = "sales_admin";
export const CONTRACT_ADMIN = "contract_admin";
export const EVALUATION_ADMIN = "evaluation_admin";
export const MANAGEMENT_ADMIN = "management_admin";

export const CONTROL_TOWER = "control_tower";
export const CONTROL_TOWER_AGENT = "control_tower_agent";
export const CONTROL_TOWER_ANALYST = "control_tower_analyst";

export const CONTRACT = "contract";
export const MANAGEMENT = "management";
export const EVALUATION = "evaluation";
export const ACCOUNTANT = "accountant";

export const LANDLORD = "propietary";
export const REAL_STATE_DEV = "real_estate_developer";
export const BROKER = "property_broker";

export const SALES = "sales";
export const SALES_GUIDE = "sales_guide";
export const SALES_GUIDE_HIRED = "sales_guide_hired";
export const SALES_PHOTOGRAPHER = "sales_photographer";

export const RENT_PHOTOGRAPHER = "rent_photographer";

export const TENANT = "tenant";

export const ADMIN_ROLES = [
  ADMIN,
  SUPER,
  CT_ADMIN,
  SALES_ADMIN,
  CONTRACT_ADMIN,
  EVALUATION_ADMIN,
  MANAGEMENT_ADMIN,
] as const;

export const CT_ROLES = [
  CONTROL_TOWER,
  CONTROL_TOWER_AGENT,
  CONTROL_TOWER_ANALYST,
] as const;

export const LANDLORD_ROLES = [LANDLORD, REAL_STATE_DEV, BROKER] as const;

export const SALES_ROLES = [
  SALES,
  SALES_GUIDE,
  SALES_GUIDE_HIRED,
  SALES_PHOTOGRAPHER,
] as const;

export const OTHER_ROLES = [
  CONTRACT,
  MANAGEMENT,
  EVALUATION,
  ACCOUNTANT,
] as const;

export const ALL_ROLES = [
  ...OTHER_ROLES,
  ...ADMIN_ROLES,
  ...SALES_ROLES,
  ...LANDLORD_ROLES,
  ...CT_ROLES,
  RENT_PHOTOGRAPHER,
  TENANT,
] as const;

export type Role = (typeof ALL_ROLES)[number];

export const dashboard: Role[] = [
  ...CT_ROLES,
  ...ADMIN_ROLES,
  ...SALES_ROLES,
  CONTRACT,
  RENT_PHOTOGRAPHER,
  EVALUATION,
  MANAGEMENT,
];

export const landlord: Role[] = [...LANDLORD_ROLES, SUPER];

export const management: Role[] = [MANAGEMENT, MANAGEMENT_ADMIN, SUPER];

export const contract: Role[] = [CONTRACT, CONTRACT_ADMIN, SUPER];

export const realStateTag: Role[] = [SALES_ADMIN, SALES, SUPER];

//admin/applicants/:id
export const applicantById: Role[] = [
  CONTRACT,
  CONTRACT_ADMIN,
  CONTROL_TOWER,
  CT_ADMIN,
  EVALUATION,
  EVALUATION_ADMIN,
  SALES,
  SALES_ADMIN,
  SUPER,
];

//admin/contract-person/:id/force_signature
export const forceSignature: Role[] = [
  CONTRACT,
  CONTRACT_ADMIN,
  EVALUATION,
  EVALUATION_ADMIN,
  MANAGEMENT,
  MANAGEMENT_ADMIN,
  SUPER,
];

export const contractsClients: Role[] = [
  ...CT_ROLES,
  CONTRACT,
  CONTRACT_ADMIN,
  EVALUATION,
  EVALUATION_ADMIN,
  MANAGEMENT,
  MANAGEMENT_ADMIN,
  SALES,
  SALES_ADMIN,
  CT_ADMIN,
  SUPER,
];

export const adjustId: Role[] = [
  CONTRACT,
  CONTRACT_ADMIN,
  MANAGEMENT,
  MANAGEMENT_ADMIN,
  SUPER,
];

export const clientsUsers: Role[] = [
  CT_ADMIN,
  CONTROL_TOWER,
  CONTRACT_ADMIN,
  CONTRACT,
  EVALUATION,
  EVALUATION_ADMIN,
  MANAGEMENT,
  MANAGEMENT_ADMIN,
  SALES,
  SALES_ADMIN,
  SUPER,
];

export const controltowerContractEvaluationManagementSales: Role[] = [
  ...CT_ROLES,
  CT_ADMIN,
  CONTRACT,
  CONTRACT_ADMIN,
  EVALUATION,
  EVALUATION_ADMIN,
  MANAGEMENT,
  MANAGEMENT_ADMIN,
  SALES,
  SALES_ADMIN,
  SUPER,
  RENT_PHOTOGRAPHER,
  SALES_PHOTOGRAPHER,
];

export const controltowerContractEvaluationManagementSalesPhotographer: Role[] =
  [
    ...CT_ROLES,
    CT_ADMIN,
    CONTRACT,
    CONTRACT_ADMIN,
    EVALUATION,
    EVALUATION_ADMIN,
    MANAGEMENT,
    MANAGEMENT_ADMIN,
    SALES,
    SALES_ADMIN,
    SUPER,
    RENT_PHOTOGRAPHER,
    SALES_PHOTOGRAPHER,
  ];

export const withSalesHired: Role[] = [
  ...controltowerContractEvaluationManagementSalesPhotographer,
  SALES_GUIDE_HIRED,
];

export const controltowerEvaluationManagement: Role[] = [
  ...CT_ROLES,
  CT_ADMIN,
  CONTRACT,
  EVALUATION,
  EVALUATION_ADMIN,
  MANAGEMENT,
  MANAGEMENT_ADMIN,
  SUPER,
];

export const controltowerEvaluationManagementSales: Role[] = [
  CONTROL_TOWER,
  CT_ADMIN,
  EVALUATION,
  EVALUATION_ADMIN,
  MANAGEMENT,
  MANAGEMENT_ADMIN,
  SUPER,
  SALES_ADMIN,
];

export const controlTowerTab: Role[] = [
  ...CT_ROLES,
  CT_ADMIN,
  SUPER,
  SALES,
  SALES_ADMIN,
  SALES_GUIDE_HIRED,
];

export const controlTowerDashboard: Role[] = controlTowerTab;

export const controlTowerPropertyHour: Role[] = [
  SALES,
  SUPER,
  SALES_ADMIN,
  CT_ADMIN,
  CONTROL_TOWER_ANALYST,
];

export const controlTowerHoumerCreation: Role[] = [
  SUPER,
  SALES_ADMIN,
  CT_ADMIN,
];

export const controlTowerSales: Role[] = [
  ...CT_ROLES,
  CT_ADMIN,
  SALES,
  SALES_ADMIN,
  SUPER,
];

export const evaluationManagementSales: Role[] = [
  ...CT_ROLES,
  CT_ADMIN,
  EVALUATION,
  EVALUATION_ADMIN,
  MANAGEMENT,
  MANAGEMENT_ADMIN,
  SALES,
  SALES_ADMIN,
  SUPER,
];

export const internalCollaborators: Role[] = [
  ...ADMIN_ROLES,
  ...OTHER_ROLES,
  ...CT_ROLES,
  SALES,
  RENT_PHOTOGRAPHER,
  SALES_PHOTOGRAPHER,
];

export const contractTemplates = [
  ...CT_ROLES,
  SUPER,
  EVALUATION_ADMIN,
  MANAGEMENT_ADMIN,
  CONTRACT_ADMIN,
  CT_ADMIN,
  CONTROL_TOWER_ANALYST,
];

export const paymentsContract = [SUPER, MANAGEMENT_ADMIN];

export const ALLOWED_ROLES = {
  all: ALL_ROLES,
  dashboard,
  management,
  contract,
  realStateTag,
  applicantById,
  forceSignature,
  contractsClients,
  adjustId,
  clientsUsers,
  controltowerContractEvaluationManagementSales,
  controltowerContractEvaluationManagementSalesPhotographer,
  controltowerEvaluationManagement,
  controltowerEvaluationManagementSales,
  controlTowerDashboard,
  controlTowerTab,
  controlTowerPropertyHour,
  controlTowerHoumerCreation,
  controlTowerSales,
  evaluationManagementSales,
  internalCollaborators,
  none: [SUPER],
  contractTemplates,
  paymentsContract,
  withSalesHired,
};

export type RoleDict = keyof typeof ALLOWED_ROLES;
