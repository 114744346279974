import DogsImage from "assets/images/adminTenant/dogs-error.jpg";
import Header from "Components/Layout/GlobalHeader";

import useMobileViewStyles from "./MobileViewStyles";
import ErrorText from "../ErrorText";

interface Props {
  onHandleClick: () => void;
}

const MobileView = ({ onHandleClick }: Props) => {
  const classes = useMobileViewStyles();

  return (
    <>
      <Header />
      <div className={classes.textContainer}>
        <ErrorText />
      </div>
      <img src={DogsImage} alt="error" className={classes.image} />
    </>
  );
};

export default MobileView;
