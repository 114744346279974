import { ReactEventHandler, useRef, useState } from "react";
import { debounce } from "lodash";

import { IProperty } from "apps/landlord/models/Property";
import { renderAddress } from "utils";
import useSearch from "./useSearch";

const useSuggestion = (onSelectPropety: (prop: Partial<IProperty>) => void) => {
  const [searchValue, setSearchValue] = useState<string>(null);
  const { makeSearch, suggestions, resetSuggestion, isLoading, isError } =
    useSearch();

  const getSuggestion = (value: string) => makeSearch(value);

  const debouncedLoadSuggestions = useRef(debounce(getSuggestion, 1000));

  const onSuggestionsFetchRequested = ({ value = "" }) => {
    debouncedLoadSuggestions.current(value);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => resetSuggestion();

  const onChange = (event: ReactEventHandler, { newValue }) => {
    if (newValue.length === 0) {
      debouncedLoadSuggestions.current(newValue);
      onSelectPropety(null);
      setSearchValue(null);
    }
    setSearchValue(newValue);
  };

  const inputProps = {
    placeholder: "Buscar dirección o por ID de propiedad",
    value: searchValue ?? "",
    onChange,
  };

  const getSuggestionValue = (suggestion: IProperty) => {
    onSelectPropety(suggestion);
    return renderAddress({
      address: suggestion?.address,
      comuna: suggestion?.commune,
      numero_depto: suggestion?.apartmentNumber,
      street_number: suggestion?.streetNumber,
      type: suggestion?.type,
    });
  };

  return {
    isLoading,
    suggestions,
    isError,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    inputProps,
    searchValue,
    getSuggestionValue,
  };
};

export default useSuggestion;
