import React from "react";
import Highlighter from "react-highlight-words";

import { Typography } from "@material-ui/core";

import {
  rentalStatus,
  salesStatus,
} from "apps/landlord/pages/Summary/utils/utils";
import { IProperty } from "apps/landlord/models/Property";
import { renderAddress } from "utils";

import useSuggestionStyles from "./SuggestionStyles";

interface Props {
  suggestion: Partial<IProperty>;
  value: string;
}

const Suggestion = ({ suggestion, value }: Props) => {
  const classes = useSuggestionStyles();

  return (
    <div className={classes.suggestionContainerComponent}>
      <Highlighter
        className={classes.title}
        searchWords={[value["query"]]}
        autoEscape
        textToHighlight={`ID ${suggestion.id}: ${renderAddress({
          address: suggestion?.address,
          comuna: suggestion?.commune,
          numero_depto: suggestion?.apartmentNumber,
          street_number: suggestion?.streetNumber,
          type: suggestion?.type,
        })}`}
      />
      {suggestion.forRental && (
        <Typography className={classes.suggestionName}>
          Estado renta:
          <strong>{` ${
            rentalStatus[suggestion.propertyDetails.status] ?? ""
          }`}</strong>
        </Typography>
      )}
      {suggestion.forSale && (
        <Typography className={classes.suggestionName}>
          Estado venta:
          <strong>{` ${
            salesStatus[suggestion.salesDetails?.status] ?? ""
          }`}</strong>
        </Typography>
      )}
    </div>
  );
};

export default React.memo(Suggestion);
