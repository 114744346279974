import { makeStyles, Theme } from "@material-ui/core";

const useRequestsCarrouselStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(5),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  seeMore: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
  },
  cards: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr 0",
    overflow: "hidden",
    gridGap: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  emptyRequestsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: "#FFF",
    borderRadius: 8,
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  emptyRequestsText: {
    fontSize: theme.typography.pxToRem(14),
  },
  iconContainer: {
    backgroundColor: "#DAE4E8",
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
  },
}));

export default useRequestsCarrouselStyles;
