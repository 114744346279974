import { ReactNode } from "react";
import { Box, Typography } from "@material-ui/core";

import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji/Emoji";
import useInfoStepStyles from "./InfoStepStyles";

export interface InfoStepProps {
  title: string;
  content: ReactNode;
  emoji: { icon: string; label: string };
}

const InfoStep = ({ title, emoji, content }: InfoStepProps) => {
  const classes = useInfoStepStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>
        {title} <Emoji emojiIcon={emoji.icon} ariaLabel={emoji.label} />
      </Typography>
      <Typography className={classes.content}>{content}</Typography>
    </Box>
  );
};

export default InfoStep;
