import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  disabled?: boolean;
}

const usePhoneFormStyles = makeStyles<Theme, Props>((theme) => ({
  inputsWrapper: {
    marginBottom: theme.spacing(3),
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  input: {
    width: "100%",
  },
  inputRoot: {
    margin: 0,
    marginRight: theme.spacing(1),
  },
}));

export default usePhoneFormStyles;
