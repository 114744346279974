import { makeStyles, Theme } from "@material-ui/core";

const useBoldMenuStyles = makeStyles((theme: Theme) => ({
  boldMenuRoot: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      borderLeft: `1px solid ${theme.palette.grey[50]}`,
      flexDirection: "row",
      alignItems: "center",
    },
  },
  boldMenuItem: {
    width: "100%",
    padding: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 1),
      width: "auto",
      border: "1px solid transparent",
      borderRadius: 800,
      padding: theme.spacing(0.5, 2),
      cursor: "pointer",
    },
  },
}));

export default useBoldMenuStyles;
