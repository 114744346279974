import { UseQueryResult, useQuery } from "react-query";

import favoritesService from "services/favouritesService";
import { useGetToken } from "hooks";
import { useHoumSnackBar } from "libs/houm-snackbar";
import { FavoriteProperty } from "apps/account/models/FavoriteProperty";

import { FavoriteMode } from "../../utils/types";
import { parseFavorites } from "./parsers";

const useGetFavorites = (mode: FavoriteMode) => {
  const { getToken } = useGetToken();
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const {
    data,
    isLoading,
    isError,
    refetch,
  }: UseQueryResult<FavoriteProperty[], Error> = useQuery<
    FavoriteProperty[],
    Error
  >(
    ["favouritesList", mode],
    async () => {
      const authToken = await getToken();
      const response = await favoritesService.getFavorites({ mode }, authToken);
      return parseFavorites(response.data);
    },
    {
      onError: () => {
        enqueueHoumSnackBar({
          head: "",
          body: "Ocurrió un problema al cargar tus favoritos",
          type: "error",
        });
      },
    }
  );

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};

export default useGetFavorites;
