/* eslint-disable max-lines */
import { useState, useMemo } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { chunk } from "lodash";

import { NavigateNext, NavigateBefore } from "@material-ui/icons";
import { Box, Theme, useMediaQuery } from "@material-ui/core";

import useFeedbackCarouselStyles from "./FeedbackCarouselStyles";
import { ISchedulesQuery } from "../../utils/types";
import FeedbackCard from "../FeedbackCard";

/**
 * Number of media items shown in carousel
 */
const desktopSize = 3;
const mobileSize = 1;

interface Props {
  schedules: ISchedulesQuery;
}
const FeedbackCarousel = ({ schedules }: Props) => {
  const classes = useFeedbackCarouselStyles();
  const [currentPage, setCurrentPage] = useState(0);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const cardsShown: number = isDesktop ? desktopSize : mobileSize;

  const cardsList = useMemo(
    () => chunk(schedules, cardsShown),
    [schedules, cardsShown]
  );

  return (
    <Carousel
      showArrows={isDesktop}
      centerMode={!isDesktop}
      showIndicators={false}
      showThumbs={false}
      showStatus={false}
      selectedItem={currentPage}
      onClickItem={setCurrentPage}
      onChange={setCurrentPage}
      renderArrowNext={(onClickHandler) =>
        cardsList.length > 1 &&
        isDesktop && (
          <button
            onClick={onClickHandler}
            className={`${classes.carouselNavButton} ${classes.carouselNextNavButton}`}
            type="button"
          >
            <NavigateNext className={classes.icon} />
          </button>
        )
      }
      renderArrowPrev={(onClickHandler) =>
        cardsList.length > 1 &&
        isDesktop && (
          <button
            onClick={onClickHandler}
            className={`${classes.carouselNavButton} ${classes.carouselPrevNavButton}`}
            type="button"
          >
            <NavigateBefore className={classes.icon} />
          </button>
        )
      }
    >
      {cardsList.map((list, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={i} className={classes.contentCardWrap}>
          {list.map((feedback) => (
            <FeedbackCard key={feedback.data.id} feedback={feedback.data} />
          ))}
        </Box>
      ))}
    </Carousel>
  );
};

export default FeedbackCarousel;
