import { makeStyles, Theme } from "@material-ui/core";

const useOfferAnsweredStyles = makeStyles((theme: Theme) => ({
  text: {
    textAlign: "center",
    maxWidth: 463,
    "& h1": {
      fontSize: theme.typography.pxToRem(30),
      fontWeight: "bold",
    },
    "& p": {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  iconCheck: {
    color: "#00982D",
    fontSize: theme.typography.pxToRem(40),
    margin: theme.spacing(2, 0),
  },
  grid: {
    justifyContent: "space-around",
    paddingTop: theme.spacing(10),
  },
}));

export default useOfferAnsweredStyles;
