import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useEvaluationFormTitleStyles = makeStyles((theme: Theme) => ({
  text: {
    fontWeight: 400,
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      marginBottom: theme.spacing(1),
    },
  },
  boldText: {
    fontWeight: 700,
    display: "inline",
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

export default useEvaluationFormTitleStyles;
