import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { ApplicationModel } from "apps/lead/models/Application";

const useApplicationActions = () => {
  const history = useHistory();

  const startEvaluation = useCallback((application: ApplicationModel) => {
    history.push({
      pathname: "/tenant/evaluator-form",
      state: { applicants: application },
    });
  }, []);

  const getOnAction = (application: ApplicationModel) => {
    const { evaluationData } = application;
    if (!evaluationData)
      return () => {
        startEvaluation(application);
      };
    if (
      evaluationData.status === "Pendiente" ||
      evaluationData.status === "En revisión" ||
      evaluationData.status === null
    ) {
      return () => {
        history.push({
          pathname: "/tenant/document-form",
          state: {
            evaluationId: evaluationData.id,
            evaluations: evaluationData.persons,
          },
        });
      };
    }
    return null;
  };

  const getLabelButton = (application: ApplicationModel) =>
    application.evaluationData ? "Subir documentación" : "Empezar evaluación";

  return { getLabelButton, getOnAction };
};

export default useApplicationActions;
