import { makeStyles, Theme } from "@material-ui/core";

const useProfileCardStyles = makeStyles((theme: Theme) => ({
  profileCardRoot: {
    padding: theme.spacing(2),
    borderRadius: 16,
    backgroundColor: "white",
    [theme.breakpoints.up("sm")]: {
      width: 666,
      padding: theme.spacing(3),
    },
  },
  text: {
    marginBottom: theme.spacing(3),
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default useProfileCardStyles;
