import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const usePrintPage = () => {
  const printPage = () => {
    const toPrint = document.getElementById("toPrint");
    const getSize = async (image) => {
      const img = new Image();
      img.src = image;
      await img.decode();
      return [img.width, img.height];
    };
    html2canvas(toPrint).then(async (canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const sizes = await getSize(imgData);
      const width = sizes[0] / 7;
      const height = sizes[1] / 7;
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF("p", "mm", [width, height]);
      const heightOfPage = pdf.internal.pageSize.getHeight();
      const widthOfPage = pdf.internal.pageSize.getWidth();
      pdf.addImage(imgData, "png", 0, 0, widthOfPage, heightOfPage);
      pdf.save("property-price-comparison.pdf");
    });
  };

  return {
    printPage,
  };
};

export default usePrintPage;
