import { Grid, Typography } from "@material-ui/core";

import useSubscriptionPlanCardStyles from "./SubscriptionPlanCardStyles";
import PlanCardTitle from "./components/PlanCardTitle";
import PlanCardBenefits from "./components/PlanCardBenefits";
import DefaultPlanCardContent from "./components/DefaultPlanCardContent";
import PlanInfo from "../../models";

export interface IPlanCardProps {
  plan?: PlanInfo;
  handleClick?: () => void;
  isHorizontal?: boolean;
  isCustom?: boolean;
}

export default function SubscriptionPlanCard({
  plan = null,
  handleClick = undefined,
  isHorizontal = false,
  isCustom = false,
}: IPlanCardProps) {
  const classes = useSubscriptionPlanCardStyles({
    isMainPlan: plan?.isMainPlan ?? false,
    isHorizontal,
  });

  return (
    <Grid md={3} sm={12} item container className={classes.cardContainer}>
      {plan?.isMainPlan && (
        <div className={classes.diagonalTag}>
          <Typography className={classes.diagonalTag_text}>
            RECOMENDADO
          </Typography>
        </div>
      )}
      <PlanCardTitle
        isHorizontal={isHorizontal}
        isMainPlan={plan?.isMainPlan}
        title={plan?.name}
        subtitle={plan?.description}
        isCustom={isCustom}
      />
      <PlanCardBenefits
        isHorizontal={isHorizontal}
        isMainPlan={plan?.isMainPlan}
        benefitsList={plan?.benefits}
        isCustom={isCustom}
      />
      {!isCustom && (
        <DefaultPlanCardContent
          brokerageCommissionText={plan?.brokerageCommissionText}
          administrationCommissionText={plan?.administrationCommissionText}
          operationalCost={plan?.operationalCost}
          handleClick={handleClick}
          isHorizontal={isHorizontal}
        />
      )}
    </Grid>
  );
}
