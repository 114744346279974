import { makeStyles, Theme } from "@material-ui/core";

const usePropertyAddressStyles = makeStyles<Theme>((theme) => ({
  addressContainer: {
    "& p, span": {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.57,
    },
    "& span": {
      color: theme.palette.grey[900],
    },
    "& p ": {
      color: "#7D8488",
    },
    [theme.breakpoints.up("sm")]: {
      "& p, span": {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 1.5,
      },
    },
  },
}));

export default usePropertyAddressStyles;
