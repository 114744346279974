import { isMobile } from "react-device-detect";

import { Box } from "@material-ui/core";

import DesignSystemTabs from "Components/Admin/UIComponents/DesignSystem/DesignSystemTab/DesignSystemTabs";
import DesignSystemSnackbar from "Components/Admin/UIComponents/DesignSystem/DesignSystemSnackbar";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import PropertyLayout from "apps/landlord/Layout/PropertyLayout";
import useProperty from "apps/landlord/hooks/useProperty";
import { useTab } from "context/tabContext/tabContext";

import ApplicantEvaluation from "./components/ApplicantEvaluation";
import ApplicantHistorial from "./components/ApplicationsRecord";
import { useApplicant } from "./context/ApplicantContext";
import { Views } from "./context/ApplicantContext.types";
import useApplicantStyles from "./ApplicantStyles";
import { getTabsOptions } from "./utils";

const Applicant = () => {
  const classes = useApplicantStyles();
  const {
    historialApplications,
    currentView,
    isLoading,
    submitResponse,
    handleBack,
    handleSnackbarClose,
  } = useApplicant();

  const hasHistorial = Boolean(historialApplications.length);
  const { propertyData } = useProperty();
  const {
    state: { currentTabValue },
  } = useTab();
  const address = propertyData?.address;
  const subtext = isMobile ? null : address;

  return (
    <PropertyLayout
      onHandleBack={handleBack}
      titlePage="Postulante"
      subtitlePage={subtext}
    >
      <Loading loading={isLoading}>
        <>
          {currentView === Views.MAIN && (
            <>
              <DesignSystemTabs
                expanded
                options={getTabsOptions(hasHistorial)}
                appBarClassName={classes.appBar}
              />
              <Box
                hidden={currentTabValue !== "evaluation"}
                className={classes.wrapper}
              >
                <ApplicantEvaluation />
              </Box>
              <Box
                hidden={currentTabValue !== "historial"}
                className={classes.wrapper}
              >
                <ApplicantHistorial />
              </Box>
            </>
          )}
          {currentView === Views.MOBILE_APPLICANT_DETAIL && (
            <ApplicantHistorial />
          )}
          <DesignSystemSnackbar
            type={submitResponse.status}
            open={submitResponse.status !== null}
            onClose={handleSnackbarClose}
            head={submitResponse.title}
            body={submitResponse.message}
          />
        </>
      </Loading>
    </PropertyLayout>
  );
};

export default Applicant;
