import SwipeableViews from "react-swipeable-views";
import { isDesktop } from "react-device-detect";

import { Drawer, IconButton } from "@material-ui/core";
import { GridCloseIcon } from "@material-ui/x-grid";

import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import NavbarMobile from "apps/landlord/Layout/BackButtonHeader/components/NavbarMobile";
import LeavingDate from "../LeavingDate/LeavingDate";
import Confirmation from "../Confirmation/Confirmation";
import Introduction from "../Introduction/Introduction";
import Loader from "../Loader";
import useCloseContractStyles from "./EndOfContractStyles";
import { EndOfContractUIProps } from "../../types";

const EndOfContractUI = ({
  leavingData,
  isLoading,
  openConfirmation,
  goToPrevStep,
  goToPropertyHelp,
  handleSubmitEoC,
  setOpenConfirmation,
  handleNextFirstStep,
  endOfContractStatus,
  endOfContractTarget,
  endOfContractInProcess,
  handleSubmitDate,
  currentIndex,
  setCurrentIndex,
  isLoadingSubmitEndOfContract,
}: EndOfContractUIProps) => {
  const classes = useCloseContractStyles();

  return (
    <Drawer
      anchor="right"
      open={true}
      onClose={goToPropertyHelp}
      classes={{
        paper: classes.drawer,
        root: classes.drawerRoot,
      }}
    >
      {isDesktop && (
        <IconButton className={classes.closeButton} onClick={goToPropertyHelp}>
          <GridCloseIcon />
        </IconButton>
      )}
      {!isDesktop && (
        <NavbarMobile handleBack={goToPrevStep} backText="volver" />
      )}
      {isLoading && <Loader />}
      {!isLoading && (
        <SwipeableViews
          className={classes.swipable}
          disabled={true}
          index={currentIndex}
          handleChangeIndex={setCurrentIndex}
        >
          <Introduction
            activeContract={endOfContractTarget}
            handleNext={handleNextFirstStep}
            endOfContractStatus={endOfContractStatus}
          />
          {!endOfContractInProcess && (
            <LeavingDate
              onSubmitHadler={handleSubmitDate}
              leavingData={leavingData}
              activeContract={endOfContractTarget}
            />
          )}
          {!endOfContractInProcess && isDesktop && (
            <Confirmation
              activeContract={endOfContractTarget}
              handleBack={goToPrevStep}
              isLoading={isLoadingSubmitEndOfContract}
              handleSubmit={handleSubmitEoC}
              leavingData={leavingData}
            />
          )}
        </SwipeableViews>
      )}
      {!isDesktop && (
        <CustomDialog
          onClose={() => setOpenConfirmation(false)}
          open={openConfirmation}
          classes={{
            modalContainer: classes.modal,
          }}
        >
          <Confirmation
            activeContract={endOfContractTarget}
            handleBack={() => setOpenConfirmation(false)}
            isLoading={isLoadingSubmitEndOfContract}
            handleSubmit={handleSubmitEoC}
            leavingData={leavingData}
          />
        </CustomDialog>
      )}
    </Drawer>
  );
};

export default EndOfContractUI;
