import { makeStyles, Theme } from "@material-ui/core";

const usePropertySearchStyles = makeStyles<Theme>((theme) => ({
  propertySearchContainer: {
    gap: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      gap: 0,
    },
  },
  textField: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    display: "block",
    background: "none",
    padding: theme.spacing(0.5, 1),
    paddingLeft: theme.spacing(1),
    outline: "none",
    border: "none",
    width: "100%",
    "&::placeholder": {
      color: "#BFCBD1",
      fontWeight: 400,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  textFieldContainer: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: 4,
    border: "none",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 343,
    },
  },
  suggestionContainer: {
    display: "block",
    position: "absolute",
    width: "100%",
    boxShadow: "3px 4px 25px rgb(38 50 56 / 15%);",
    backgroundColor: "white",
    fontWeight: 300,
    fontSize: "1rem",
    borderRadius: 8,
    borderTopWidth: 0,
    maxHeight: 400,
    zIndex: 2000000,
    overflowY: "auto",
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      maxWidth: 343,
    },
  },
  searchIcon: {
    marginLeft: theme.spacing(1.4),
  },
  inputContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

export default usePropertySearchStyles;
