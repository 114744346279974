import { Box } from "@material-ui/core";

import getWeekDays from "apps/tenant/components/Forms/DayHoursPicker/utils";
import { IVisitHour } from "apps/tenant/hooks/utils/types";
import HourInterval from "./HourInterval";

interface Props {
  value: IVisitHour[];
  onChange: (value: IVisitHour[]) => void;
  intervalOptions: { label: string; value: string }[];
}

const HoursIntervals = ({
  value: visitHours,
  onChange,
  intervalOptions,
}: Props) => {
  const numberDaysChecked = visitHours.filter((day) => day.checked).length;
  const onCheckChange = (value: boolean, index: number) => {
    const newVisitHours = [...visitHours];
    newVisitHours[index].checked = value;
    onChange(newVisitHours);
  };

  const onHourChange = (value: [number, number], index: number) => {
    const newVisitHours = [...visitHours];
    newVisitHours[index].hours = value;
    onChange(newVisitHours);
  };
  return (
    <Box>
      {getWeekDays().map((label, index) => (
        <HourInterval
          label={label}
          disabled={numberDaysChecked === 1 && visitHours[index].checked}
          value={visitHours[index]}
          onCheckChange={(value) => {
            onCheckChange(value, index);
          }}
          onHourChange={(value) => {
            onHourChange(value, index);
          }}
          intervalOptions={intervalOptions}
        />
      ))}
    </Box>
  );
};

export default HoursIntervals;
