import { FormProvider } from "react-hook-form";

import Button from "@houm-com/ui/Button";

import CallToActionDrawer from "apps/ui/CustomDrawer/CallToActionDrawer";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import DrawerSpinner from "apps/ui/CustomDrawer/DrawerSpinner";
import FormHeader from "apps/landlord/components/FormHeader";

import Inputs from "./components/Inputs";
import usePropertyDetailsStep from "./hooks/usePropertyDetailsStep";
import { STEPS } from "../../types";

const PropertyDetailsStep = () => {
  const { setCurrentStep } = useCustomDrawer();
  const { user, isLoading, methods, handleSubmitNewProperty } =
    usePropertyDetailsStep();

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-col gap-4 p-4 md:p-8"
        onSubmit={methods.handleSubmit(handleSubmitNewProperty)}
      >
        {isLoading && <DrawerSpinner />}
        <FormHeader
          title="Información básica de tu propiedad"
          subTitle="Para empezar, cuéntanos un poco de tu propiedad para que podamos ayudarte a crearla"
        />
        {!isLoading && <Inputs userCountry={user.country} />}
      </form>
      <CallToActionDrawer>
        <Button
          variant="tertiary"
          size="md"
          onClick={() => setCurrentStep(STEPS.Welcome)}
        >
          Atrás
        </Button>
        <Button
          onClick={methods.handleSubmit(handleSubmitNewProperty)}
          type="button"
          variant="primary"
          size="md"
        >
          Siguiente
        </Button>
      </CallToActionDrawer>
    </FormProvider>
  );
};

export default PropertyDetailsStep;
