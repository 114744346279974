import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import useContract from "apps/tenant/hooks/useContract";
import useContractEnd from "apps/tenant/hooks/useContractEnd";

import useScheduling from "./useScheduling";

const useExitVisitSchedule = () => {
  const history = useHistory();
  const [endDate, setEndDate] = useState<null | string>(null);

  const { isLoading: contractLoading } = useContract();

  const {
    isLoading: contractEndLoading,
    contractEndData,
    isError: contractEndError,
  } = useContractEnd();

  const {
    availableDates,
    availableHours,
    selectedDate,
    setSelectedDate,
    handleSubmit,
    showMissingTime,
    scheduling,
    scheduleLoading,
    selectedTime,
    setSelectedTime,
  } = useScheduling();

  useEffect(() => {
    if (!contractEndLoading && !contractEndError) {
      if (contractEndData?.status === "NOT_STARTED") {
        history.push("/tenant");
      } else if (contractEndData?.estimatedDate) {
        setEndDate(
          format(contractEndData?.estimatedDate, " d 'de' MMMM", {
            locale: es,
          })
        );
      }
    }
  }, [contractEndData, contractEndLoading]);

  return {
    availableDates,
    availableHours,
    endDate,
    history,
    selectedDate,
    setSelectedDate,
    handleSubmit,
    showMissingTime,
    scheduling,
    isLoading: contractLoading || scheduleLoading || contractEndLoading,
    selectedTime,
    setSelectedTime,
  };
};

export default useExitVisitSchedule;
