import { SALES_URL, BACKEND_URL } from "env";
import { Locale } from "models/Countries";
import { createHttp } from "services/helper";
import { OwnerPayload } from "./letterOfIntentService.types";

const LoiServices = (authToken) => {
  const httpClient = createHttp(authToken, SALES_URL);

  const getNegotiations = (countryCode: Locale, propertyId: string) =>
    httpClient.get(
      `/bidding/${countryCode}/properties/${propertyId}/negotiations`
    );

  const getLoiOwners = (negotiationId: string, countryCode: Locale) =>
    httpClient.get(
      `/bidding/${countryCode}/negotiations/${negotiationId}/owners`
    );

  const updateMainOwner = (
    negotiationId: string,
    countryCode: Locale,
    owner: OwnerPayload
  ) =>
    httpClient.patch(
      `/loi/${countryCode}/negotiations/${negotiationId}/owner`,
      owner
    );

  const createNewOwner = (
    negotiationId: string,
    countryCode: Locale,
    owner: OwnerPayload
  ) =>
    httpClient.post(
      `/bidding/${countryCode}/negotiations/${negotiationId}/owners`,
      owner
    );

  const sendLoiToReview = (negotiationId: string, countryCode: Locale) =>
    httpClient.patch(
      `/bidding/${countryCode}/negotiations/${negotiationId}/loi/status`,
      {
        role: "main_owner",
      }
    );

  const uploadOwnerDocuments = (
    negotiationId: string,
    countryCode: Locale,
    files: FormData
  ) =>
    httpClient.post(
      `/loi/${countryCode}/negotiations/${negotiationId}/owner/upload_docs`,
      files
    );

  const getPropertyDetails = (propertyUuid: string) =>
    httpClient.get(`${BACKEND_URL}/propietary/v2/properties/${propertyUuid}`);

  const getDocumentTypes = (countryCode: string) =>
    httpClient.get(`/bidding/${countryCode}/identification_types`);

  return {
    uploadOwnerDocuments,
    getPropertyDetails,
    getDocumentTypes,
    updateMainOwner,
    createNewOwner,
    sendLoiToReview,
    getNegotiations,
    getLoiOwners,
  };
};

export default LoiServices;
