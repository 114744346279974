import { makeStyles, Theme } from "@material-ui/core";

const useInspectionDetailsExpandStyles = makeStyles((theme: Theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    paddingRight: theme.spacing(2),
    "&:focus, &:hover, &:visited": {
      "& p": {
        color: theme.palette.primary.main,
      },
    },
    cursor: "pointer",
  },
  actionField: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    marginLeft: theme.spacing(1),
  },
}));

export default useInspectionDetailsExpandStyles;
