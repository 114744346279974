/* eslint-disable max-lines */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Input from "apps/ui/formComponents/Input";
import Select from "apps/ui/formComponents/Select";

import SubmitSection from "../SubmitSection";
import { parseUserBankAccountMX } from "../../utils/utils";
import { validationSchemaMX } from "../../utils/validationSchema";
import { EMPTY_BANK_ACCOUNT_MX } from "../../utils/constants";
import { FormProps } from "../../utils/types";

const Form = ({
  bankAccount,
  bankOptions,
  editing,
  isSubmitting,
  onSubmit,
}: FormProps) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchemaMX),
    reValidateMode: "onBlur",
    defaultValues: editing
      ? parseUserBankAccountMX(bankAccount)
      : EMPTY_BANK_ACCOUNT_MX,
  });
  const { t } = useTranslation(["tenant.rentPayment"]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        id="name"
        label="Titular"
        required
        error={!!errors.name}
        message={errors.name?.message}
        {...register("name")}
      />

      <Input
        id="email"
        label="Email"
        required
        error={!!errors.email}
        message={errors.email?.message}
        {...register("email")}
      />

      <Input
        id="document"
        label={t("document")}
        placeholder={t("document.placeholder")}
        error={!!errors.document}
        message={errors.document?.message}
        required
        {...register("document")}
      />

      <Select
        id="bankId"
        label="Banco"
        options={bankOptions}
        error={!!errors.bankId}
        message={errors.bankId?.message}
        required
        {...register("bankId")}
      />

      <Input
        id="clabe"
        label="CLABE"
        error={!!errors.clabe}
        message={errors.clabe?.message}
        required
        {...register("clabe")}
      />

      <Input
        id="accountNumber"
        label="Número de cuenta"
        error={!!errors.accountNumber}
        message={errors.accountNumber?.message}
        required
        {...register("accountNumber")}
      />

      <Input
        id="numberValidation"
        label="Validar número de cuenta"
        error={!!errors.numberValidation}
        message={errors.numberValidation?.message}
        required
        autoComplete="off"
        {...register("numberValidation")}
      />

      <SubmitSection loading={isSubmitting} />
    </form>
  );
};

export default Form;
