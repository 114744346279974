import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  CECompanyForm,
  CommonExpensesCompanies as Companies,
} from "apps/landlord/models/ServicesCompanies";
import Spinner from "Components/Shared/partials/Spinner";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import useBillsInfo from "apps/landlord/hooks/propertyBills/useBillInfo";
import useCompanies from "apps/landlord/hooks/propertyBills/useCompanies";
import useChangeCommonExpenses from "apps/landlord/hooks/propertyBills/useChangeCommonExpenses";
import useCommonExpensesStepStyles from "./CommonExpensesStepStyles";

import CompanySelector from "../../PropertyDetails/components/PropertyDetailsContent/components/CommonExpensesForm/components/CompanySelector";
import CompanyFields from "../../PropertyDetails/components/PropertyDetailsContent/components/CommonExpensesForm/components/CompanyFields";
import formSchema from "../../PropertyDetails/components/PropertyDetailsContent/components/CommonExpensesForm/validationSchema";
import useBasicServicesFormView from "../hooks/useBasicServicesFormView";

const CommonExpensesStep = () => {
  const { t } = useTranslation("landlord.property.basic.services");
  const { returnToPropertyDetail, goToBasicServicesStep } =
    useBasicServicesFormView();
  const classes = useCommonExpensesStepStyles();
  const [selectedCompany, setCompanySelected] = useState<Companies>();
  const [companyName, setCompanyName] = useState<Companies>();

  const { mutate, submitting, isSuccess } = useChangeCommonExpenses();
  const methods = useForm<CECompanyForm>({
    resolver: yupResolver(formSchema),
    reValidateMode: "onSubmit",
  });
  const { commonExpensesCompaniesOptions } = useCompanies();
  const { data: billsData, isLoading: billsLoading } = useBillsInfo([
    "common_expenses",
  ]);

  const clientId = billsData?.common_expenses?.clientInfo?.externalId;
  const company = billsData?.common_expenses?.company;

  useEffect(() => {
    if (clientId || clientId === "") {
      setCompanySelected(company);
      methods.setValue("companyName", company);
    }
  }, [clientId, company, methods]);

  useEffect(() => {
    if (isSuccess) return goToBasicServicesStep();
  }, [isSuccess, goToBasicServicesStep]);

  const onSubmit: SubmitHandler<CECompanyForm> = async (formData) => {
    mutate(formData);
  };

  const { isValid } = methods.formState;

  return (
    <>
      {(billsLoading || submitting) && <Spinner />}
      <Box className={classes.basicServicesPhotoContent}>
        <Box className={classes.basicServicesPhotoContentText}>
          <Typography className={classes.basicServicesTitle}>
            {t("common-expenses")}
          </Typography>
          <Typography className={classes.basicServicesPhotoContentText}>
            {t("common-expenses-add-your-info")}
          </Typography>

          <FormProvider {...methods}>
            <form>
              <CompanySelector
                companyOptions={commonExpensesCompaniesOptions}
                setCompanyName={setCompanyName}
                companySelected={selectedCompany}
              />
              <CompanyFields
                companyName={companyName || selectedCompany}
                clientInfo={billsData?.common_expenses?.clientInfo}
                administrationEmail={
                  billsData?.common_expenses?.administrationEmail
                }
              />
            </form>
          </FormProvider>
        </Box>
      </Box>
      <Box className={classes.stickyFooter}>
        <DesignSystemButton
          onClick={returnToPropertyDetail}
          variant="tertiaryB"
          size="small"
          label="Volver"
        />
        <DesignSystemButton
          onClick={methods.handleSubmit(onSubmit)}
          variant="primary"
          size="small"
          disabled={submitting || billsLoading || !isValid}
          label="Siguiente"
        />
      </Box>
    </>
  );
};

export default CommonExpensesStep;
