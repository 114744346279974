import { Box, Typography } from "@material-ui/core";
import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji/Emoji";
import useNoMovementsStyles from "./NoMovementsStyles";

export default function NoMovements() {
  const classes = useNoMovementsStyles();
  return (
    <Box className={classes.propertyPaymentsNoMovementsRoot}>
      <Typography className={classes.propertyPaymentsNoMovementsTitle}>
        {"Aún no tienes pagos registrados "}
        <Emoji emojiIcon="💰️" ariaLabel="money emoji" />
      </Typography>
      <Typography className={classes.propertyPaymentsNoMovementsText}>
        Tu propiedad <b>todavía no cuenta pagos registrados</b>
        {`, estos los podrás ve reflejados después de tu primer pago de arriendo `}
        <Emoji emojiIcon="👌" ariaLabel="ok hand emoji" />.
      </Typography>
    </Box>
  );
}
