import {
  State,
  Action,
  SET_PAGE,
  SET_STEPS,
  SET_LOADING,
  SET_ACTIVE_STEP,
  SET_APPLICANT,
  SET_USER_INFO,
  SET_COUNTRY,
  EvaluatorPages,
} from "apps/lead/context/evaluatorContext/EvaluatorTypes";

export const initialState: State = {
  loading: true,
  activeStep: 0,
  steps: 0,
  user: undefined,
  applicants: [],
  page: EvaluatorPages.WELCOME,
  country: undefined,
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_LOADING: {
      return { ...state, loading: action.loading };
    }
    case SET_ACTIVE_STEP: {
      return { ...state, activeStep: action.activeStep };
    }
    case SET_STEPS: {
      return { ...state, steps: action.steps };
    }
    case SET_APPLICANT: {
      return { ...state, applicants: [...state.applicants, action.applicant] };
    }
    case SET_USER_INFO: {
      return { ...state, user: action.userInfo };
    }
    case SET_PAGE: {
      return { ...state, page: action.page };
    }
    case SET_COUNTRY: {
      return { ...state, country: action.country };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
