import { Box, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import useHeaderStyles from "./HeaderStyles";
import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji/Emoji";

interface Props {
  handleClose: () => void;
  name?: string;
}

const Header = ({ handleClose, name }: Props) => {
  const classes = useHeaderStyles();

  return (
    <Box className={classes.headerRoot}>
      <Typography className={classes.username}>
        {name} <Emoji emojiIcon="👋 " ariaLabel="waving hand" />
      </Typography>
      <Box className={classes.closeIcon} onClick={handleClose}>
        <CloseIcon />
      </Box>
    </Box>
  );
};

export default Header;
