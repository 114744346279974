import { useState } from "react";

import { Typography } from "@material-ui/core";

import { IoArrowForward } from "@houm-com/ui-icons";

import HorizontalBanner from "apps/ui/HorizontalBanner";
import useUnfinishedSettingUpBannerStyles from "./UnfinishedSettingUpBannerStyles";
import useUnfinishedSettingUpBanner from "../hooks/useUnfinishedSettingUpBanner";
import UnfinishedSettingUpModal from "../UnfinishedSettingUpModal";

const UnfinishedSettingUpBanner = () => {
  const classes = useUnfinishedSettingUpBannerStyles();
  const [showModal, setShowModal] = useState(false);
  const { missingDataActions } = useUnfinishedSettingUpBanner();

  if (!missingDataActions.length) return null;

  return (
    <>
      <HorizontalBanner onClick={() => setShowModal(true)}>
        <span className={classes.bannerAnimatedDot} />
        <Typography className={classes.bannerText}>
          <strong>Existen datos pendientes.</strong> Entra aquí para completar
          la información faltante
          <IoArrowForward size="14" className={classes.bannerLinkIcon} />
        </Typography>
      </HorizontalBanner>
      <UnfinishedSettingUpModal
        open={showModal}
        onClose={() => setShowModal(false)}
        missingDataActions={missingDataActions}
      />
    </>
  );
};

export default UnfinishedSettingUpBanner;
