import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

export default makeStyles((theme: Theme) => ({
  componentContainer: {
    padding: theme.spacing(4.75, 5, 4.75, 5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(7, 13),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    color: theme.palette.grey[900],
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(28),
    },
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: theme.palette.grey[900],
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  gridContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginTop: theme.spacing(6),
    },
  },
  carousel: {
    width: "100%",
    "& li": {
      background: "transparent !important",
      maxWidth: "100%",
    },
  },
  carouselNavButton: {
    background: "white",
    borderRadius: "50%",
    padding: theme.spacing(1),
    lineHeight: "normal",
    position: "absolute",
    top: "40%",
    zIndex: theme.zIndex.mobileStepper,
    margin: theme.spacing(0, "6%"),
    height: 45,
    width: 45,
    opacity: 0.6,
    transition: "0.3s",
    "&:hover": {
      opacity: 1,
    },
    border: "2px solid #EFEFEF",
    [theme.breakpoints.up("md")]: {
      height: 60,
      width: 60,
    },
  },
  icon: {
    fontSize: 20,
    [theme.breakpoints.up("md")]: {
      fontSize: "2.125rem",
    },
  },
  carouselNextNavButton: {
    right: 0,
    marginRight: 0,
    cursor: "pointer",
  },
  carouselPrevNavButton: {
    left: 0,
    marginLeft: 0,
    cursor: "pointer",
  },
  contentCard_wrap: {
    width: "85%",
    margin: "auto",
    padding: theme.spacing(2.5, 0),
    [theme.breakpoints.up("md")]: {
      width: "90%",
    },
  },
  noFeedback: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[500],
    marginTop: theme.spacing(-2.5),
  },
}));
