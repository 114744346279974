import { makeStyles, Theme } from "@material-ui/core";

const useSwitchSelectorStyles = makeStyles<Theme>((theme) => ({
  group: {
    borderRadius: 100,
    height: 32,
    backgroundColor: theme.palette.grey[50],
  },
  titleSelected: {
    fontWeight: 700,
    color: "white !important",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
  },
  button: {
    border: "none",
    textTransform: "unset",
    borderRadius: "100px !important",
  },
  buttonSelected: {
    backgroundColor: `${theme.palette.grey[900]} !important`,
  },
}));

export default useSwitchSelectorStyles;
