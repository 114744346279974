import { cn } from "@houm-com/ui/utils";

import { classes } from "./InfoTagStyles";

interface Props {
  information: string;
  className?: string;
}

const InfoTag = ({ information, className }: Props) => (
  <div className={cn(classes.tagContainer, className)}>
    <p className={classes.text}>{information}</p>
  </div>
);

export default InfoTag;
