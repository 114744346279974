import { makeStyles, Theme } from "@material-ui/core";

const useRepairVisitStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  info: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    fontWeight: 700,
  },
  ctaContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: "#FFF",
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      width: "100%",
      bottom: 0,
    },
  },
}));

export default useRepairVisitStyles;
