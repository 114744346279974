import { useGetToken } from "hooks";
import { PropertyData } from "models/Property";
import { useQuery, UseQueryResult } from "react-query";
import propertyService from "services/propertyService";

export default function useGetProperty(propertyUID: string) {
  const { getToken } = useGetToken();
  const { isLoading, data, error }: UseQueryResult<PropertyData, Error> =
    useQuery<PropertyData, Error>(["getProperty", propertyUID], async () => {
      const authToken = await getToken();
      const propertyResponse = await propertyService.get(
        propertyUID,
        authToken
      );
      return propertyResponse?.data;
    });
  return { isLoading, data, error };
}
