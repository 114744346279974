import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { Locale } from "models/Countries";

import { ITimeOption } from "../../utils/types";
import { timezoneByCountryCode } from "utils/getTimezone";

export const parseAvailableHours = ({
  rawDates,
  countryCode,
}: {
  rawDates: Record<string, string[]>;
  countryCode: Locale;
}): Record<string, ITimeOption[]> => {
  const tz = timezoneByCountryCode({ countryCode });
  const processedDates = {};

  Object.keys(rawDates).forEach((dateKey) => {
    processedDates[dateKey] = rawDates[dateKey]?.map((hour, i) => {
      const date = new Date(`${hour}Z`);
      return {
        id: i,
        label: format(utcToZonedTime(date, tz), "h:mm aaa"),
      } as ITimeOption;
    });
  });

  return processedDates;
};
