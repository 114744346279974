import { IUserBankAccount } from "apps/tenant/models/BankAccount";
import { IPaymentDetail } from "apps/tenant/models/Movement";
import {
  IMovementResponse,
  IUserBankAccountResponse,
} from "apps/tenant/services/paymentService/types";
import { format } from "date-fns";

const parseBankAccountInfo = (
  data: IUserBankAccountResponse
): Partial<IUserBankAccount> => ({
  fullName: data.full_name,
  personId: data.person_id,
  identifier: data.identifier,
  bankName: data.bank_name,
  bankCode: data.bank_code,
  accountNumber: data.account_number,
  accountType: data.account_type,
});

const parseReadjustment = (data: IMovementResponse) =>
  data.readjustment && {
    totalValue: data.readjustment,
    adjustmentBreakdown: data.readjustments_breakdown?.map((readjustment) => ({
      description: readjustment.description,
      value: readjustment.value,
      label: readjustment.label,
      periods: readjustment.periods?.map((period) => ({
        initialDate: `${period.initial_date}T00:00:00`,
        finalDate: `${period.final_date}T00:00:00`,
        variation: period.variation,
      })),
      identifier: readjustment.identifier,
    })),
  };

export const parsePaymentDetail = (
  data: IMovementResponse
): IPaymentDetail => ({
  id: data.movement_id,
  status: data.status,
  paymentDate: format(new Date(data.date), "dd/MM/yyyy"),
  totalPrice: data.total_amount,
  rentPrice:
    data?.original_amount_breakdown.find(
      (original) => original.label === "LEASE"
    )?.value ?? data.original_amount,
  planCharges: data.discounts_breakdown?.find(
    (disc) => disc.label === "MANAGEMENT"
  )?.value,
  currency: data.currency,
  discountsBreakdown: data.discounts_breakdown
    ? data.discounts_breakdown?.filter(
        (discount) => discount.label !== "MANAGEMENT"
      )
    : [],
  aditionalsBreakdown: data.additionals_breakdown ?? [],
  originalAmountBreakdown: data.original_amount_breakdown
    ? data.original_amount_breakdown.filter(
        (original) => original.label !== "LEASE"
      )
    : [],
  promotionsBreakdown:
    data.promotions_breakdown?.filter((promotion) => promotion.value !== 0) ??
    [],
  paymentOption: data.plan_type,
  legacyId: data.legacy_id,
  bankAccountInfo: parseBankAccountInfo(data.person_data),
  readjustment: parseReadjustment(data),
});
