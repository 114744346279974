import { SnackbarProvider } from "notistack";
import LoiFormProvider from "./context/LoiFormContext";
import LoiForm from "./LoiFormIndex";

export default function NewLoiForm() {
  return (
    <LoiFormProvider>
      <SnackbarProvider>
        <LoiForm />
      </SnackbarProvider>
    </LoiFormProvider>
  );
}
