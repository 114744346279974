export default {
  cardRoot:
    "bg-information-10 w-full rounded-lg py-6 px-4 md:py-8 md:px-6 flex flex-col justify-between",
  spinner: "flex h-full justify-center items-center",
  cardTitle: "flex gap-2 mb-2",
  cardIcon: "text-secondary-cta w-[20px] h-[20px]",
  title: "!font-bold text-p18 md:text-p20",
  description: "text-p14",
  button: "mt-4 md:mt-8",
};
