import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

const useOptionListStyles = makeStyles<Theme>((theme) => ({
  paper: {
    maxHeight: 100,
    width: 175,
    marginLeft: theme.spacing(2),
    borderRadius: 8,
    boxShadow: "3px 4px 20px rgba(38, 50, 56, 0.15)",
    padding: theme.spacing(1.25),
    "& ul": {
      maxHeight: 100,
      padding: 0,
    },
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginLeft: "auto",
      width: 223,
    },
    "&::-webkit-scrollbar": {
      width: 25,
    },
    "&::-webkit-scrollbar-track": {
      background: theme.palette.grey[50],
      border: "10px solid white",
      borderRadius: 40,
    },
    "&::-webkit-scrollbar-thumb": {
      position: "relative",
      border: "10px solid transparent",
      borderRadius: 40,
      background: "#4C6977",
      backgroundClip: "padding-box",
      "&::after": {
        position: "absolute",
        content: "",
        zIndex: -1,
        borderRadius: 40,
      },
    },
  },
  iconButton: {
    padding: theme.spacing(1.25),
  },
  mobileIcon: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(35),
  },
  browserIcon: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(25),
  },
  menuItem: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    borderRadius: 2,
    "&:hover": {
      backgroundColor: theme.palette.grey[900],
      color: "white",
      fontWeight: 700,
    },
  },
}));

export default useOptionListStyles;
