export enum SettingUpCards {
  PHOTOS = "photos",
  CHARACTERISTICS = "characteristics",
  BASIC_SERVICES = "basicServices",
  BANK_ACCOUNT = "bankAccount",
  PRICE = "price",
}

export type CardStepStatus = {
  total: number;
  current: number;
  completed: boolean;
};

export type CardStepStatusByType = {
  [key in SettingUpCards]: CardStepStatus;
};
