import { View, Text } from "@react-pdf/renderer";

import useInspectionReportDocumentStyles from "../InspectionReportDocumentStyles";

interface InspectionReportObservationProps {
  generalObservation: string;
}

const InspectionReportObservation = ({
  generalObservation,
}: InspectionReportObservationProps) => {
  const styles = useInspectionReportDocumentStyles;

  return (
    <View style={styles.contentSection}>
      <Text style={styles.sectionTitle}>Observaciones Generales</Text>
      <Text style={styles.itemValue}>{generalObservation}</Text>
    </View>
  );
};

export default InspectionReportObservation;
