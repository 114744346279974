import { CountryCallingCode } from "libphonenumber-js/mobile";

import { Country, PhoneInput } from "models/PhoneInput";

export const countryToCode = (country: string) => {
  const code: Record<string, string> = {
    Mexico: "mx",
    Colombia: "co",
    Chile: "cl",
  };
  return code[country];
};

export const defaultPhoneCountry = (selectCountry: string): Country => {
  const country: Record<string, Country> = {
    mx: {
      callingCode: "52" as CountryCallingCode,
      countryCode: "MX",
      countryName: "Mexico",
    },
    co: {
      callingCode: "57" as CountryCallingCode,
      countryCode: "CO",
      countryName: "Colombia",
    },
    cl: {
      callingCode: "56" as CountryCallingCode,
      countryCode: "CL",
      countryName: "Chile",
    },
  };
  return country[selectCountry];
};

export const parseUser = (data: IUser): IUserParse => ({
  name: data.firstName,
  last_name: data.lastName,
  email: data.email,
  phone: `+${data.phone.country.callingCode}${data.phone.phone}`,
  role: "prospect_user",
  country: data.selectCountry,
});

export interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  phone: PhoneInput;
  country?: string;
  selectCountry: string;
  role?: string;
}

export interface IUserParse {
  name: string;
  last_name: string;
  email: string;
  phone: string;
  country?: string;
  role?: string;
}

export interface UserData {
  firstName: string;
  lastName: string;
  phone: Partial<PhoneInput>;
  email: string;
  referralCode?: string;
}
export interface SuccessForm {
  success: boolean;
  user?: IUser;
}
export interface IUserResponse {
  token?: string;
  id: number;
  name: number;
  last_name: string;
  role: string;
  phone: number | string;
  email: number | string;
  rut: string;
  document?: string;
  document_type?: string;
  first_time_user: boolean;
  referral_id?: number | string;
  second_last_name?: string;
  gclick_id?: number | string;
  fbclid_id?: number | string;
  hashed_id: string;
  country: string;
  created_at: string;
  utm_source?: number | string;
  utm_medium?: number | string;
  utm_campaign?: number | string;
  utm_content?: number | string;
  utm_term?: number | string;
  referred_url?: string;
  manager?: string;
  company?: string;
}

export interface CountrySelector {
  flag: string;
  id: string;
  value: string;
  tag: string;
}

export const countriesSelector: CountrySelector[] = [
  {
    flag: "cl",
    id: "input-chile",
    value: "Chile",
    tag: "Chile",
  },
  {
    flag: "co",
    id: "input-colombia",
    value: "Colombia",
    tag: "Colombia",
  },
  {
    flag: "mx",
    id: "input-mexico",
    value: "Mexico",
    tag: "México",
  },
];

export type CountryCode =
  | "AC"
  | "AD"
  | "AE"
  | "AF"
  | "AG"
  | "AI"
  | "AL"
  | "AM"
  | "AO"
  | "AR"
  | "AS"
  | "AT"
  | "AU"
  | "AW"
  | "AX"
  | "AZ"
  | "BA"
  | "BB"
  | "BD"
  | "BE"
  | "BF"
  | "BG"
  | "BH"
  | "BI"
  | "BJ"
  | "BL"
  | "BM"
  | "BN"
  | "BO"
  | "BQ"
  | "BR"
  | "BS"
  | "BT"
  | "BW"
  | "BY"
  | "BZ"
  | "CA"
  | "CC"
  | "CD"
  | "CF"
  | "CG"
  | "CH"
  | "CI"
  | "CK"
  | "CL"
  | "CM"
  | "CN"
  | "CO"
  | "CR"
  | "CU"
  | "CV"
  | "CW"
  | "CX"
  | "CY"
  | "CZ"
  | "DE"
  | "DJ"
  | "DK"
  | "DM"
  | "DO"
  | "DZ"
  | "EC"
  | "EE"
  | "EG"
  | "EH"
  | "ER"
  | "ES"
  | "ET"
  | "FI"
  | "FJ"
  | "FK"
  | "FM"
  | "FO"
  | "FR"
  | "GA"
  | "GB"
  | "GD"
  | "GE"
  | "GF"
  | "GG"
  | "GH"
  | "GI"
  | "GL"
  | "GM"
  | "GN"
  | "GP"
  | "GQ"
  | "GR"
  | "GT"
  | "GU"
  | "GW"
  | "GY"
  | "HK"
  | "HN"
  | "HR"
  | "HT"
  | "HU"
  | "ID"
  | "IE"
  | "IL"
  | "IM"
  | "IN"
  | "IO"
  | "IQ"
  | "IR"
  | "IS"
  | "IT"
  | "JE"
  | "JM"
  | "JO"
  | "JP"
  | "KE"
  | "KG"
  | "KH"
  | "KI"
  | "KM"
  | "KN"
  | "KP"
  | "KR"
  | "KW"
  | "KY"
  | "KZ"
  | "LA"
  | "LB"
  | "LC"
  | "LI"
  | "LK"
  | "LR"
  | "LS"
  | "LT"
  | "LU"
  | "LV"
  | "LY"
  | "MA"
  | "MC"
  | "MD"
  | "ME"
  | "MF"
  | "MG"
  | "MH"
  | "MK"
  | "ML"
  | "MM"
  | "MN"
  | "MO"
  | "MP"
  | "MQ"
  | "MR"
  | "MS"
  | "MT"
  | "MU"
  | "MV"
  | "MW"
  | "MX"
  | "MY"
  | "MZ"
  | "NA"
  | "NC"
  | "NE"
  | "NF"
  | "NG"
  | "NI"
  | "NL"
  | "NO"
  | "NP"
  | "NR"
  | "NU"
  | "NZ"
  | "OM"
  | "PA"
  | "PE"
  | "PF"
  | "PG"
  | "PH"
  | "PK"
  | "PL"
  | "PM"
  | "PR"
  | "PS"
  | "PT"
  | "PW"
  | "PY"
  | "QA"
  | "RE"
  | "RO"
  | "RS"
  | "RU"
  | "RW"
  | "SA"
  | "SB"
  | "SC"
  | "SD"
  | "SE"
  | "SG"
  | "SH"
  | "SI"
  | "SJ"
  | "SK"
  | "SL"
  | "SM"
  | "SN"
  | "SO"
  | "SR"
  | "SS"
  | "ST"
  | "SV"
  | "SX"
  | "SY"
  | "SZ"
  | "TA"
  | "TC"
  | "TD"
  | "TG"
  | "TH"
  | "TJ"
  | "TK"
  | "TL"
  | "TM"
  | "TN"
  | "TO"
  | "TR"
  | "TT"
  | "TV"
  | "TW"
  | "TZ"
  | "UA"
  | "UG"
  | "US"
  | "UY"
  | "UZ"
  | "VA"
  | "VC"
  | "VE"
  | "VG"
  | "VI"
  | "VN"
  | "VU"
  | "WF"
  | "WS"
  | "XK"
  | "YE"
  | "YT"
  | "ZA"
  | "ZM"
  | "ZW";
