import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";
import userService from "services/userService";

import { IUser } from "../../models/User";
import { parseUserData } from "./parsers";

const useUser = () => {
  const { getToken } = useGetToken();
  const { data, isLoading, isError }: UseQueryResult<IUser, Error> = useQuery<
    IUser,
    Error
  >("user", async () => {
    const authToken = await getToken();
    const response = await userService.getV2(authToken);
    return parseUserData(response?.data);
  });
  return {
    user: data,
    isLoading,
    error: isError,
  };
};

export default useUser;
