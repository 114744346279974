import { makeStyles, Theme } from "@material-ui/core";

const useResumeApplicantsStyles = makeStyles<Theme>((theme) => ({
  container: {
    marginBottom: theme.spacing(4),
  },
  applicantsResume: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    textAlign: "center",
  },
}));

export default useResumeApplicantsStyles;
