import { makeStyles, Theme } from "@material-ui/core";

const usePropertyMobileStyles = makeStyles<Theme>((theme) => ({
  root: {
    borderRadius: 8,
    width: "100%",
    boxShadow: "3px 4px 20px rgba(38, 50, 56, 0.15)",
    padding: 0,
  },
  descriptionContainer: {
    marginTop: theme.spacing(1),
  },
  actionsContainer: {
    padding: theme.spacing(2),
  },
  addressContainer: {
    marginTop: theme.spacing(2),
  },
  detailsContainer: {
    padding: theme.spacing(0, 2),
  },
  cardContainer: {
    padding: 0,
  },
}));

export default usePropertyMobileStyles;
