import { PropertyData } from "models/Property";
import {
  GetNegotiation,
  GetNegotiationAdmin,
} from "services/negotiationService/models";
import { numberWithCommas } from "utils";
import {
  Negotiation,
  NegotiationAdmin,
  Property,
  ResponseToOffer,
} from "./negotiationsTypes";

export function parseProperty(propertyData: PropertyData): Property {
  return {
    uid: propertyData.uid,
    country: propertyData.country,
    address: propertyData.address,
    streetNumber: propertyData.street_number,
    flatNumber: propertyData.numero_depto,
    commune: propertyData.comuna,
    photos: propertyData.photos,
    salesPrice: propertyData.sales_details.price,
    salesCurrency: propertyData.sales_details.currency,
    region: propertyData.region,
    type: propertyData.type,
  };
}

const PAYMENTS_OPTIONS = {
  own_resources: "Recursos propios",
  mortgage_credit: "Crédito hipotecario",
};

export function parseNegotiations(data: GetNegotiation[]): Negotiation[] {
  return data?.map((negotiation) => ({
    id: negotiation.id,
    status: negotiation.status,
    activeOfferAmount: negotiation.last_bid_amount,
    activeOfferPaymentMethod:
      PAYMENTS_OPTIONS[negotiation.last_bid_payment_method],
    activeOfferCurrency: negotiation.last_bid_currency,
    activeOfferStatus: negotiation.last_bid_status,
    offers: negotiation.bids?.map((offer) => ({
      id: offer.id,
      amount: offer.amount,
      currency: offer.currency,
      status: offer.status,
      paymentMethod: PAYMENTS_OPTIONS[offer.payment_method],
      createdAt: offer.created_at,
    })),
  }));
}

export function parseNegotiationsAdmin(
  data: GetNegotiationAdmin[]
): NegotiationAdmin[] {
  const negotiationsWithBids = data?.filter(
    (negotiation) => negotiation.last_bid
  );
  return negotiationsWithBids?.map((negotiation) => ({
    id: negotiation.id,
    status: negotiation.status,
    bidder: {
      name: negotiation.bidder.name,
      lastName: negotiation.bidder.last_name,
      secondLastName: negotiation.bidder.second_last_name,
    },
    lastBid: {
      id: negotiation.last_bid.id,
      amount: negotiation.last_bid.amount,
      currency: negotiation.last_bid.currency,
      status: negotiation.last_bid.status,
      paymentMethod: PAYMENTS_OPTIONS[negotiation.last_bid.payment_method],
      createdAt: negotiation.last_bid.created_at,
    },
    startDate: negotiation.start_date,
  }));
}

export function unParseResponseToOffer(data: ResponseToOffer) {
  return {
    response: data.response,
    reason: data.reason,
    last_amount: data.lastAmount,
    currency: data.currency,
  };
}

export function addressProperty(propertyData: Partial<Property>) {
  return {
    type: propertyData.type,
    address: propertyData.address,
    street_number: propertyData.streetNumber,
    numero_depto: propertyData.flatNumber,
    comuna: propertyData.commune,
  };
}

export function formatPrice(amount: number, currency: string) {
  return `${numberWithCommas(amount)} ${currency}`;
}
