import SummaryLayout from "apps/landlord/Layout/SummaryLayout";

import Summary from "./Summary";

const index = () => (
  <SummaryLayout>
    <Summary />
  </SummaryLayout>
);

export default index;
