import {
  ADMIN_ROLES,
  CT_ROLES,
  LANDLORD,
  OTHER_ROLES,
  RENT_PHOTOGRAPHER,
  Role,
  SALES_ROLES,
  TENANT,
} from "helpers/auth/roleAccess";

const OPS_VALID_ROLES: Role[] = [
  ...ADMIN_ROLES,
  ...SALES_ROLES,
  ...CT_ROLES,
  ...OTHER_ROLES,
  RENT_PHOTOGRAPHER,
];

/**
 * if at least one of the user roles is a ops roles
 * we should flag user as OPS user.
 */
const detectOps = (userRoles: Role[]) =>
  userRoles.some((role) => OPS_VALID_ROLES.includes(role));

export default detectOps;

export const detectLandlord = (userRoles: Role[]) =>
  userRoles.includes(LANDLORD);

export const detectTenant = (userRoles: Role[]) => userRoles.includes(TENANT);
