import { createContext, useContext, ReactNode, useState } from "react";
import { INavbarUser } from "../utils/types";
import { Locale } from "models/Countries";

const NavbarContext = createContext<
  | {
      country: Locale;
      setCountry: (value: Locale) => void;
      backUrl: string;
      setBackUrl: (value: string) => void;
      user: INavbarUser | null;
      setUser: (value: INavbarUser | null) => void;
      usingAs: "tenant" | "landlord" | null;
      setUsingAs: (value: "tenant" | "landlord") => void;
    }
  | undefined
>(undefined);

const NavbarProvider = ({ children }: { children: ReactNode }) => {
  const [backUrl, setBackUrl] = useState<string | null>(null);
  const [user, setUser] = useState<INavbarUser | null>(null);
  const [usingAs, setUsingAs] = useState<"tenant" | "landlord" | null>(null);
  const [country, setCountry] = useState<Locale>("cl");

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const initialValue = {
    backUrl,
    setBackUrl,
    country,
    setCountry,
    user,
    setUser,
    usingAs,
    setUsingAs,
  };

  return (
    <NavbarContext.Provider value={initialValue}>
      {children}
    </NavbarContext.Provider>
  );
};

const useNavbar = () => {
  const context = useContext(NavbarContext);
  if (context === undefined) {
    throw new Error("useNavbar must be used within a NavbarProvider");
  }
  return context;
};

export { NavbarProvider, useNavbar };
