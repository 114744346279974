import Swal from "sweetalert2";

import {
  Action,
  Dispatch,
  DocumentOptionProps,
  State,
  idsProps,
  PropertyDataProps,
  LandlordDataProps,
  ThirdPartyProps,
  PublicServicesProps,
  AdminDataProps,
  FilesProps,
  FileLinksProps,
  SET_IDS,
  SET_IS_ADMIN,
  SET_LOADING,
  SET_AVAILABLE_STEPS,
  SET_STEP,
  NEXT_STEP,
  PREV_STEP,
  SET_READY,
  SET_ALREADY_SUBMITTED,
  SET_PROPERTY_DATA,
  SET_LANDLORD_DATA,
  SET_THIRD_PARTY_DATA,
  SET_PUBLIC_SERVICES,
  SET_ADMIN_DATA,
  SET_FILES,
  SET_SUBMITTING,
  SET_NAV_TEXT,
  SET_STEP_MODE,
  SET_DOC_TYPES,
  SET_FILE_LINKS,
  Step,
} from "./closureFormTypes";
import contractService from "services/contractService";
import documentTypeService from "services/documentTypeService";
import propertyService from "services/propertyService";
import {
  parseIds,
  parseDocumentTypes,
  parseNeighborhoods,
  parsePropertyData,
  parseLandlordData,
  parseThirdPartyData,
  parseService,
  parseServices,
  parseAdminData,
  parseFileLinks,
} from "./utils/frontendParsers";
import {
  backParseProperty,
  backParsePropertyDetails,
  backParseContractProperty,
  backParseService,
  backParseContractLandlord,
  backParsePropertyLandlord,
  backParseContractThirdParty,
  backParseFiles,
  backParsePropertyDetailsService,
  backParseContractService,
  backParsePropertyAdmin,
  backParsePropertyDetailsAdmin,
  backParseContractAdmin,
} from "./utils/backendParsers";

export const setIds = (ids: idsProps): Action => ({
  type: SET_IDS,
  ids,
});

export const setIsAdmin = (isAdmin: boolean): Action => ({
  type: SET_IS_ADMIN,
  isAdmin,
});

export const setLoading = (loading: boolean): Action => ({
  type: SET_LOADING,
  loading,
});

export const setAvailableSteps = (steps: Step[]): Action => ({
  type: SET_AVAILABLE_STEPS,
  steps,
});

export const setStep = (step: Step): Action => ({
  type: SET_STEP,
  step,
});

export const nextStep = (): Action => ({
  type: NEXT_STEP,
});

export const prevStep = (): Action => ({
  type: PREV_STEP,
});

export const setReady = (ready: Step | ""): Action => ({
  type: SET_READY,
  ready,
});

export const setAlreadySubmitted = (alreadySubmitted: boolean): Action => ({
  type: SET_ALREADY_SUBMITTED,
  alreadySubmitted,
});

export const setSubmitting = (submitting: boolean): Action => ({
  type: SET_SUBMITTING,
  submitting,
});

export const setNavText = (navText: string): Action => ({
  type: SET_NAV_TEXT,
  navText,
});

export const setStepMode = (stepMode: boolean): Action => ({
  type: SET_STEP_MODE,
  stepMode,
});
export const setDocumentTypes = (
  documentTypes: DocumentOptionProps[]
): Action => ({
  type: SET_DOC_TYPES,
  documentTypes,
});

export const setPropertyData = (
  propertyData: Partial<PropertyDataProps>
): Action => ({
  type: SET_PROPERTY_DATA,
  propertyData,
});

export const setLandlordData = (
  landlordData: Partial<LandlordDataProps>
): Action => ({
  type: SET_LANDLORD_DATA,
  landlordData,
});

export const setThirdPartyData = (thirdPartyData: ThirdPartyProps): Action => ({
  type: SET_THIRD_PARTY_DATA,
  thirdPartyData,
});

export const setPublicServices = (
  publicServices: PublicServicesProps
): Action => ({
  type: SET_PUBLIC_SERVICES,
  publicServices,
});

export const setAdminData = (adminData: AdminDataProps): Action => ({
  type: SET_ADMIN_DATA,
  adminData,
});

export const setFiles = (files: FilesProps): Action => ({
  type: SET_FILES,
  files,
});

export const setFileLinks = (fileLinks: FileLinksProps): Action => ({
  type: SET_FILE_LINKS,
  fileLinks,
});

const fetchAvailableServices = async (authToken: string, country: string) => {
  if (country == "Mexico") return;
  const fetchedServices = await propertyService.getBasicServices(
    authToken,
    country
  );
  if (fetchedServices) {
    return {
      energy: parseService(fetchedServices.data.results, "energy"),
      gas: parseService(fetchedServices.data.results, "gas"),
      water: parseService(fetchedServices.data.results, "water"),
    };
  }
};

export async function fetchData(
  uid: string,
  authToken: string,
  dispatch: Dispatch
) {
  try {
    const fetchedProperty = await propertyService.get(uid, authToken);
    if (typeof fetchedProperty !== "boolean") {
      const propertyData = fetchedProperty.data;
      const fetchedContractInfo = await propertyService.getContractPropertyInfo(
        propertyData.id,
        authToken
      );
      if (typeof fetchedContractInfo !== "boolean") {
        const contractInfo = fetchedContractInfo.data;
        dispatch(setAlreadySubmitted(contractInfo.submitted));
        dispatch(setIds(parseIds(propertyData)));
        dispatch(
          setPropertyData(parsePropertyData(propertyData, contractInfo))
        );
        dispatch(
          setLandlordData(parseLandlordData(propertyData, contractInfo))
        );
        dispatch(
          setThirdPartyData(parseThirdPartyData(contractInfo.third_party))
        );
        dispatch(setAdminData(parseAdminData(propertyData, contractInfo)));

        const fetchedNeighborhoods =
          await propertyService.getAvailableNeighborhoods(
            propertyData.country,
            authToken
          );
        if (typeof fetchedNeighborhoods !== "boolean") {
          dispatch(
            setPropertyData(
              parseNeighborhoods(fetchedNeighborhoods.data.results)
            )
          );
        }

        const fetchedDocumentTypes = await documentTypeService.getList(
          propertyData.country,
          authToken
        );
        if (fetchedDocumentTypes) {
          dispatch(
            setDocumentTypes(
              parseDocumentTypes(fetchedDocumentTypes.data.results)
            )
          );
        }

        const fetchedServices = await propertyService.getPropertyBasicServices(
          propertyData.id,
          authToken
        );
        const fetchedAvailableServices = await fetchAvailableServices(
          authToken,
          propertyData.country
        );
        if (fetchedServices && fetchedAvailableServices) {
          dispatch(
            setPublicServices(
              parseServices(
                fetchedServices,
                fetchedAvailableServices,
                contractInfo
              )
            )
          );
        }

        const fetchedDocuments = await propertyService.getDocuments(
          propertyData.id,
          authToken
        );
        if (fetchedDocuments) {
          dispatch(setFileLinks(parseFileLinks(fetchedDocuments.data)));
        }
        dispatch(setLoading(false));
      }
    }
  } catch (err) {
    console.error(err);
    Swal.fire({
      type: "error",
      text: "Ha ocurrido algo inesperado. Contáctese con su ejecutivo.",
    }).then(() => {
      window.history.back();
    });
  }
}

export async function submitProperty(authToken: string, state: State) {
  await propertyService.update(
    state.ids.propertyUid,
    backParseProperty(state),
    authToken
  );

  await propertyService.updateDetails(
    state.ids.propertyDetailsId,
    backParsePropertyDetails(state),
    authToken
  );

  await propertyService.updateContractPropertyInfo(
    state.ids.propertyId,
    backParseContractProperty(state),
    authToken
  );
}

export async function submitLandlord(authToken: string, state: State) {
  await propertyService.update(
    state.ids.propertyUid,
    backParsePropertyLandlord(state),
    authToken
  );

  await propertyService.updateContractPropertyInfo(
    state.ids.propertyId,
    backParseContractLandlord(state),
    authToken
  );
}

export async function submitThirdParty(authToken: string, state: State) {
  await propertyService.updateContractPropertyInfo(
    state.ids.propertyId,
    backParseContractThirdParty(state),
    authToken
  );
}

export async function submitPublicServices(authToken: string, state: State) {
  if (state.publicServices.waterService && state.publicServices.waterClient) {
    await propertyService.attachService(
      backParseService(state, "water"),
      authToken
    );
  }
  if (state.publicServices.energyService && state.publicServices.energyClient) {
    await propertyService.attachService(
      backParseService(state, "energy"),
      authToken
    );
  }
  if (state.publicServices.gasService && state.publicServices.gasClient) {
    await propertyService.attachService(
      backParseService(state, "gas"),
      authToken
    );
  }
  await propertyService.updateDetails(
    state.ids.propertyDetailsId,
    backParsePropertyDetailsService(state),
    authToken
  );

  await propertyService.updateContractPropertyInfo(
    state.ids.propertyId,
    backParseContractService(state),
    authToken
  );
}

export async function submitAdminData(authToken: string, state: State) {
  const { cleaningAmount, email, phoneNumber } = state.adminData;

  if (email || phoneNumber)
    await propertyService.update(
      state.ids.propertyUid,
      backParsePropertyAdmin(state),
      authToken
    );

  await propertyService.updateDetails(
    state.ids.propertyDetailsId,
    backParsePropertyDetailsAdmin(state),
    authToken
  );

  if (cleaningAmount) {
    await propertyService.updateContractPropertyInfo(
      state.ids.propertyId,
      backParseContractAdmin(state),
      authToken
    );
  }
}

export async function submitFiles(authToken: string, state: State) {
  const data = backParseFiles(state);
  if (Array.from(data).length !== 0) {
    await propertyService.updateDocuments(
      state.ids.propertyId,
      authToken,
      data
    );
  }

  if (!state.alreadySubmitted) {
    await contractService.notifyFilledForm(state.ids.propertyId, authToken);
  }
}

export async function submitData(
  authToken: string,
  state: State,
  dispatch: Dispatch
) {
  dispatch(setSubmitting(true));
  let submitHandler;
  switch (state.step) {
    case "propertyData":
      submitHandler = () => submitProperty(authToken, state);
      break;
    case "landlordData":
      submitHandler = () => submitLandlord(authToken, state);
      break;
    case "thirdPartyData":
      submitHandler = () => submitThirdParty(authToken, state);
      break;
    case "publicServices":
      submitHandler = () => submitPublicServices(authToken, state);
      break;
    case "adminData":
      submitHandler = () => submitAdminData(authToken, state);
      break;
    case "fileLinks":
      submitHandler = () => submitFiles(authToken, state);
      break;
    default:
      break;
  }

  try {
    await submitHandler();
  } catch (e) {
    console.error(e);
  } finally {
    if (state.stepMode && state.step !== state.availableSteps.slice(-1)[0]) {
      dispatch(nextStep());
    }
    dispatch(setSubmitting(false));
  }
}
