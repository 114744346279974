import { makeStyles, Theme } from "@material-ui/core";

const useIconBoxStyles = makeStyles<Theme>((theme) => ({
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DAE4E8",
    borderRadius: 8,
    minWidth: 41,
    height: 41,
  },
}));

export default useIconBoxStyles;
