import { RumInitConfiguration } from "@datadog/browser-rum-core";
import { datadogRum } from "@datadog/browser-rum";
import { BACKEND_URL } from "env";

export default {
  init: ({
    applicationId,
    clientToken,
    service,
    ...rest
  }: RumInitConfiguration) => {
    if (window) {
      datadogRum.init({
        applicationId,
        clientToken,
        service,
        site: "datadoghq.com",
        env: "prd",
        version: "0.1.1",
        sampleRate: 5,
        replaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackInteractions: true,
        defaultPrivacyLevel: "allow",
        silentMultipleInit: true,
        allowedTracingUrls: [BACKEND_URL],
        ...rest,
      });
      datadogRum.startSessionReplayRecording();
    }
  },
};
