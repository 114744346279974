import { ReactNode, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import { AppBar } from "@material-ui/core";

import useBottomNavbarStyles from "./BottomNavbarStyles";
import { HOUM_COM_URL } from "env";

interface Props {
  menuList: {
    icon: ReactNode;
    title: string;
    path?: string;
  }[];
}

const BottomNavbar = ({ menuList }: Props) => {
  const history = useHistory();
  const classes = useBottomNavbarStyles();
  const location = useLocation();
  const { pathname } = location;
  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    menuList.forEach((menu, i) => {
      if (menu.path === pathname) setValue(i);
    });
  }, []);

  const handleGoTo = (url: string) => {
    if (url.includes(HOUM_COM_URL)) {
      window.location.href = url;
    } else {
      history.push(url);
    }
  };

  return (
    <AppBar position="absolute" className={classes.appBar} component="div">
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
        {menuList.map((menu) => (
          <BottomNavigationAction
            classes={{
              root: classes.rootAction,
              selected: classes.selected,
            }}
            label={menu.title}
            icon={menu.icon}
            key={menu.title}
            onClick={() => handleGoTo(menu.path)}
          />
        ))}
      </BottomNavigation>
    </AppBar>
  );
};

export default BottomNavbar;
