import { makeStyles, Theme } from "@material-ui/core";

import { DESKTOP_CONTENT_MAX_WIDTH } from "apps/tenant/constants";
import { DESK_NAVBAR_HEIGHT } from "apps/ui/Navbar/constants";

const useVisitsStyles = makeStyles((theme: Theme) => ({
  visitsRoot: {
    height: "auto",
    minHeight: "100%",
    backgroundColor: theme.palette.grey[50],
    [theme.breakpoints.up("sm")]: {
      height: `calc(100vh - ${DESK_NAVBAR_HEIGHT}px)`,
    },
  },
  visitsContent: {
    padding: theme.spacing(3, 2),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, "auto"),
      maxWidth: DESKTOP_CONTENT_MAX_WIDTH,
      padding: theme.spacing(5),
    },
  },
  spinner: {
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(5),
    },
  },
}));

export default useVisitsStyles;
