import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  open: boolean;
}

const useAuthDropdownMenuStyles = makeStyles<Theme, Props>((theme) => ({
  authDropdownMenuRoot: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textTransform: "none",
    borderRadius: 800,
    padding: theme.spacing(0.5, 2),
    "&:hover": {
      backgroundColor: "rgba(249, 232, 229, 0.75)",
    },
  },
  label: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
  },
  profileIcon: {
    width: 20,
    height: 20,
  },
  arrow: {
    transform: ({ open }) => (open ? "rotate(180deg)" : "rotate(0)"),
    transitionDuration: "0.5s",
    "& path": {
      stroke: theme.palette.primary.main,
    },
  },
  collapse: {
    position: "absolute",
    top: 44,
    right: -34,
    width: 263,
    padding: theme.spacing(1, 0),
    backgroundColor: "white",
    borderRadius: 8,
    boxShadow: "0px 4px 15px rgb(38, 50, 56, 0.15)",
    whiteSpace: "nowrap",
  },
}));

export default useAuthDropdownMenuStyles;
