import { Typography } from "@material-ui/core";

import TenantLayout from "apps/tenant/layout/TenantLayout";
import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji/Emoji";

import useErrorViewStyles from "./ErrorViewStyles";

const ErrorView = () => {
  const classes = useErrorViewStyles();

  return (
    <TenantLayout>
      <Typography className={classes.errorViewRoot}>
        Error cargando datos <Emoji emojiIcon="😕" ariaLabel="confused face" />
      </Typography>
    </TenantLayout>
  );
};

export default ErrorView;
