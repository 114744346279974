import { useLocation } from "react-router-dom";

export default function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const useGetQueryParam = (search: string) => {
  const queryParams = new URLSearchParams(useLocation().search);
  return queryParams.get(search);
};
