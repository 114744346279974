import clsx from "clsx";
import { compareDesc } from "date-fns";
import { IoEllipse as DotIcon } from "@houm-com/ui-icons";
import { ReactElement } from "react";
import { isMobile } from "react-device-detect";

import { Box } from "@material-ui/core";

import useCalendarDayStyles from "./CalendarDayStyles";

interface Props {
  children?: ReactElement;
  date: Date;
  hasVisit?: boolean;
}

const CalendarDay = ({ children, date, hasVisit }: Props) => {
  const classes = useCalendarDayStyles();
  const currentDate = new Date();

  return (
    <Box className={classes.calendarDayRoot}>
      {children}
      {hasVisit && (
        <DotIcon
          data-testid="dot-icon"
          size={isMobile ? 6 : 9.8}
          className={clsx(classes.dot, {
            [classes.pastVisit]: compareDesc(currentDate, date) === -1,
            [classes.upcomingVisit]: compareDesc(currentDate, date) !== -1,
          })}
        />
      )}
    </Box>
  );
};

export default CalendarDay;
