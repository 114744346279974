import { Container, Grid, Typography } from "@material-ui/core";

import { useEvaluation } from "apps/lead/context/evaluationContext/EvaluationContext";
import useResumeApplicantsStyles from "./ResumeApplicantsStyles";

const ResumeApplicants = () => {
  const classes = useResumeApplicantsStyles();
  const { state } = useEvaluation();
  const { mainApplicant, firstCodebtor, secondCodebtor, codebtorAnswer } =
    state;

  const APPLICANTS = [
    { ...mainApplicant, role: "Arrendatario", flag: true },
    {
      ...firstCodebtor,
      role: "Codeudor 1",
      flag: codebtorAnswer.firstCodebtorAnswer,
    },
    {
      ...secondCodebtor,
      role: "Codeudor 2",
      flag: codebtorAnswer.secondCodebtorAnswer,
    },
  ];

  return (
    <Container className={classes.container}>
      <Grid container direction="column" justifyContent="center">
        {APPLICANTS.map(
          (applicant) =>
            applicant.document &&
            applicant.flag && (
              <Grid
                item
                container
                key={applicant.document}
                justifyContent="center"
              >
                <Typography
                  component="span"
                  className={classes.applicantsResume}
                >
                  <strong>
                    {`${applicant?.name} ${applicant?.lastName} | `}
                  </strong>
                  {applicant.role}
                </Typography>
              </Grid>
            )
        )}
      </Grid>
    </Container>
  );
};

export default ResumeApplicants;
