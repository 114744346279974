import { makeStyles, Theme } from "@material-ui/core";

const useSettingUpCardStyles = makeStyles((theme: Theme) => ({
  settingUpCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    cursor: "pointer",
    border: `1px solid ${theme.palette.grey[50]}`,
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      border: `1px solid ${theme.palette.grey[75]}`,
      backgroundColor: "#F7F9F9",
    },
  },
  settingUpCardLabel: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(24),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(26),
    },
  },
  settingUpCardTrailLabel: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(20),
    opacity: 0.5,
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(-1.5),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(22),
      flexGrow: 1,
      marginTop: theme.spacing(-1.5),
      marginBottom: theme.spacing(-2.5),
    },
  },
  settingUpCardAction: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    fontWeight: 700,
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    border: "none",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  disabled: {
    opacity: 0.5,
    cursor: "not-allowed",
    pointerEvents: "none",
  },
}));

export default useSettingUpCardStyles;
