import { ApplicationCodebtor } from "models/Evaluation";

export const getName = (applicant: ApplicationCodebtor) =>
  `${applicant.name} ${applicant.last_name}`;

export const isMainApplicant = (applicant: ApplicationCodebtor) =>
  applicant.role === "main_tenant";

export const getTabsOptions = (hasHistorial: boolean) => {
  const tabsOptions = [
    {
      value: "evaluation",
      label: "Evaluación",
    },
  ];
  if (hasHistorial)
    tabsOptions.push({
      value: "historial",
      label: "Historial",
    });
  return tabsOptions;
};
