import { AxiosResponse } from "axios";
import qs from "query-string";

import { createHttp } from "services/helper";
import { BILLS_URL } from "env";
import { localeToCountry } from "utils/countryCode";
import { Bill, BillResponse, CompanyResponse } from "./type";

export default {
  getCompaniesByCountry(
    country: string,
    token: string,
    params = {}
  ): Promise<AxiosResponse<CompanyResponse[]>> {
    return createHttp(token, BILLS_URL).get(
      `company/?country=${localeToCountry[country]}&${qs.stringify(params)}`
    );
  },
  postBill(
    propertyId: number,
    token: string,
    formData: Bill
  ): Promise<AxiosResponse<BillResponse[]>> {
    return createHttp(token, BILLS_URL).post(
      `bill/${propertyId}/info`,
      formData
    );
  },
  patchBill(
    propertyId: number,
    token: string,
    formData: Partial<Bill>
  ): Promise<AxiosResponse<BillResponse[]>> {
    return createHttp(token, BILLS_URL).patch(
      `bill/${propertyId}/info`,
      formData
    );
  },
  getBills(
    propertyId: number,
    token: string
  ): Promise<AxiosResponse<{ bills_info: BillResponse[] }>> {
    return createHttp(token, BILLS_URL).get(`bill/${propertyId}/info`);
  },
  getBillsDetails(
    id: number,
    token: string
  ): Promise<AxiosResponse<{ bill_details: BillResponse[] }>> {
    return createHttp(token, BILLS_URL).get(`/bill/${id}/detail`);
  },
};
