import {
  IoHomeOutline,
  IoCalendarClearOutline,
  IoImageOutline,
  IoDesktopOutline,
} from "@houm-com/ui-icons";

const CREATE_PROPERTY_GUIDELINES = [
  {
    icon: <IoHomeOutline />,
    title: "Información básica de tu propiedad",
    description:
      "Agrega la dirección, tipo de propiedad y disponibilidad para habitar.",
  },
  {
    icon: <IoCalendarClearOutline />,
    title: "Agenda tus fotos gratuitas",
    description:
      "Selecciona el día y horario que para que podamos ir a realizar las fotos",
  },
  {
    icon: <IoImageOutline />,
    title: "Fotos realizadas",
    description:
      "Destacamos tu espacio con nuestro servicio de fotografías profesionales.",
  },
  {
    icon: <IoDesktopOutline />,
    title: "Verás tu propiedad en los portales",
    description:
      "Una vez que tengamos las fotos, tu propiedad estará publicada en los portales de arriendo más importantes de Chile.",
  },
];

export default CREATE_PROPERTY_GUIDELINES;
