import { object, mixed } from "yup";

export const requiredText = "Obligatorio";

export const identificationFilesSchema = object().shape({
  frontIdentification: mixed()
    .nullable()
    .test("fileLength", requiredText, (value) => value.length > 0),
  backIdentification: mixed()
    .nullable()
    .test("fileLength", requiredText, (value) => value.length > 0),
  frontIdentificationSelfie: mixed()
    .nullable()
    .test("fileLength", requiredText, (value) => value.length > 0),
});
