/* eslint-disable max-lines */
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IoArrowForwardOutline } from "@houm-com/ui-icons";

import useBillsInfo from "apps/landlord/hooks/propertyBills/useBillInfo";
import useBillsDetails from "apps/landlord/hooks/propertyBills/useBillsDetails";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { Locale } from "models/Countries";
import {
  getCompanyTypes,
  emptyDetail,
} from "apps/landlord/utils/basicServices";

import classes from "./BasicServicesInfoStyles";
import Item from "../BasicServicesInfoItem";

type BasicServicesInfoProps = {
  country: Locale;
};

const BasicServicesInfo = ({ country }: BasicServicesInfoProps) => {
  const { t } = useTranslation([
    "landlord.property.basic.services",
    "landlord.property",
  ]);
  const history = useHistory();
  const companyTypes = getCompanyTypes(country);
  const {
    data: billsInfo,
    isFetching: isBillsInfoLoading,
    error: infoError,
  } = useBillsInfo(companyTypes);
  const {
    data: billsDetails,
    isFetching: isBillsDetailLoading,
    error: detailsError,
  } = useBillsDetails(country, companyTypes);
  const isLoading = isBillsDetailLoading || isBillsInfoLoading;
  const [waterDetails, gasDetails, energyDetails, commonExpensesDetails] =
    useMemo(
      () =>
        billsInfo && billsDetails
          ? companyTypes.map(
              (key) =>
                billsDetails.find(
                  (data) =>
                    data.clientId === billsInfo?.[key]?.clientInfo.externalId
                ) ?? emptyDetail
            )
          : [],
      [billsInfo, billsDetails]
    );
  const handleRedirect = () => {
    history.push(`${window.location.pathname}/basic-services`);
  };
  const error = !!infoError || !!detailsError;
  return (
    <div
      className={classes.container}
      onClick={handleRedirect}
      role="presentation"
    >
      <Loading loading={isLoading}>
        <>
          <h2 className={classes.title}>
            {t("basic-services", { ns: "landlord.property" })}
          </h2>
          <div className={classes.itemsContainer}>
            <Item
              defaultTitle={t("water")}
              billInfo={billsInfo?.water}
              billDetails={waterDetails}
              error={error}
            />
            <Item
              defaultTitle={t("energy")}
              billInfo={billsInfo?.energy}
              billDetails={energyDetails}
              error={error}
            />
            <Item
              defaultTitle={t("gas")}
              billInfo={billsInfo?.gas}
              billDetails={gasDetails}
              error={error}
            />
            <Item
              defaultTitle={t("common-expenses")}
              billInfo={billsInfo?.common_expenses}
              billDetails={commonExpensesDetails}
              error={error}
            />
          </div>
          <div className={classes.detailLink}>
            <span>Ver detalles</span>
            <span className={classes.detailLinkIcon}>
              <IoArrowForwardOutline />
            </span>
          </div>
        </>
      </Loading>
    </div>
  );
};

export default BasicServicesInfo;
