/* eslint-disable max-lines */
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldCheckboxCustom from "@houm-com/ui/FieldCheckboxCustom";
import Button from "@houm-com/ui/Button";

import { Box } from "@material-ui/core";

import CallToActionDrawer from "apps/ui/CustomDrawer/CallToActionDrawer";
import ContentDrawer from "apps/ui/CustomDrawer/ContentDrawer";
import FormHeader from "apps/landlord/components/FormHeader";

import useFormAmenitiesStyles from "./FormAmenitiesStyles";

interface Props {
  onHandleBack?: () => void;
  isLoading?: boolean;
}

const FormAmenities = ({ onHandleBack, isLoading = false }: Props) => {
  const { t } = useTranslation(["landlord.property.settingUp"]);
  const { register } = useFormContext();
  const classes = useFormAmenitiesStyles();

  return (
    <>
      <ContentDrawer>
        <Box className={classes.containerRoot}>
          <Box className={classes.amenitiesContainer}>
            <FormHeader
              title={t("AMENITIES_FORM_TITLE")}
              subTitle={t("AMENITIES_FORM_SUBTITLE")}
            />
            <FieldCheckboxCustom
              label={t("LABEL_PROPERTY_AMENITIES")}
              labelPosition="top"
              options={[
                {
                  label: t("LABEL_FURNISHED"),
                  value: "furnished",
                  icon: <>🛋</>,
                },
                {
                  label: t("LABEL_PET_FRIENDLY"),
                  value: "allowsPets",
                  icon: <>🐶</>,
                },
                {
                  label: t("LABEL_AIR_CONDITIONING"),
                  value: "hasAirConditioning",
                  icon: <>❄️</>,
                },
                {
                  label: t("LABEL_HEATING"),
                  value: "heating",
                  icon: <>☀️</>,
                },
                {
                  label: t("LABEL_POOL"),
                  value: "hasPool",
                  icon: <>🌊</>,
                },
                {
                  label: t("LABEL_TERRACE"),
                  value: "hasBalcony",
                  icon: <>🪴</>,
                },
                {
                  label: t("LABEL_LAUNDRY_ROOM"),
                  value: "hasLaundryRoom",
                  icon: <>🧺</>,
                },
                {
                  label: t("LABEL_LAUNDRY_CAPACITY"),
                  value: "hasLaundryCapacity",
                  icon: <>🔌</>,
                },
              ]}
              {...register("propertyAmenities")}
            />
          </Box>
          <Box className={classes.amenitiesContainer}>
            <FieldCheckboxCustom
              label={t("LABEL_BUILDING_AMENITIES")}
              labelPosition="top"
              options={[
                {
                  label: t("LABEL_CONDOMINIUM"),
                  value: "condominium",
                  icon: <>🏢</>,
                },
                {
                  label: t("LABEL_ELEVATOR"),
                  value: "hasElevator",
                  icon: <>🛗</>,
                },
                {
                  label: t("LABEL_GYM"),
                  value: "hasGym",
                  icon: <>🏋️</>,
                },
                {
                  label: t("LABEL_PET_AREA"),
                  value: "hasPetArea",
                  icon: <>🦮</>,
                },
                {
                  label: t("LABEL_CONCIERGE"),
                  value: "hasConcierge",
                  icon: <>👮</>,
                },
                {
                  label: t("LABEL_GREEN_AREAS"),
                  value: "hasPatio",
                  icon: <>🌳</>,
                },
                {
                  label: t("LABEL_BBQ_AREA"),
                  value: "hasBbqArea",
                  icon: <>🌆</>,
                },
                {
                  label: t("LABEL_SAUNA"),
                  value: "hasSauna",
                  icon: <>🧖</>,
                },
                {
                  label: t("LABEL_BUILDING_LAUNDRY_ROOM"),
                  value: "hasLaundry",
                  icon: <>🧺</>,
                },
                {
                  label: t("LABEL_PARTY_ROOM"),
                  value: "hasPartyRoom",
                  icon: <>🎈</>,
                },
                {
                  label: t("LABEL_CINEMA"),
                  value: "hasCinema",
                  icon: <>🎬</>,
                },
                {
                  label: t("LABEL_PLAYGROUND"),
                  value: "hasPlayground",
                  icon: <>🛝</>,
                },
                {
                  label: t("LABEL_GOURMET_SPACE"),
                  value: "hasGourmetSpace",
                  icon: <>🍱</>,
                },
              ]}
              {...register("amenities")}
            />
          </Box>
        </Box>
      </ContentDrawer>
      <CallToActionDrawer>
        {onHandleBack && (
          <Button variant="tertiary" size="md" onClick={onHandleBack}>
            Atrás
          </Button>
        )}
        <Button isLoading={isLoading} variant="primary" size="md" type="submit">
          Siguiente
        </Button>
      </CallToActionDrawer>
    </>
  );
};

export default FormAmenities;
