import { makeStyles, Theme } from "@material-ui/core";

const usePaymentsListStyles = makeStyles<Theme>((theme) => ({
  paymentsListRoot: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      minWidth: 450,
    },
  },
}));

export default usePaymentsListStyles;
