import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  label: {
    maxInlineSize: theme.typography.pxToRem(400),
    marginBottom: theme.typography.pxToRem(0),
    fontSize: "0.875rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  boxLabel: {
    marginTop: theme.spacing(1),
  },
}));
