import capitalize from "lodash/capitalize";
import { es as localeEs } from "date-fns/locale";
import { format } from "date-fns";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { Box } from "@material-ui/core";

import useStyles from "./FeedbackCardStyles";
import { ISchedules } from "../../utils/types";

interface Props {
  feedback: ISchedules;
}

const FeedbackCard = ({ feedback }: Props) => {
  const classes = useStyles();

  return (
    <Card className={classes.cardFeedback}>
      <Box>
        <Typography className={classes.houmerText}>
          {feedback.houmer}
        </Typography>
        <Typography className={classes.houmerSubText}>Houmer</Typography>
      </Box>
      <Typography className={classes.feedbackText}>
        {capitalize(feedback.comment)}
      </Typography>
      <Typography className={classes.dateText}>
        {format(new Date(feedback.date), "d 'de' MMMM yyyy, HH:mm", {
          locale: localeEs,
        })}
      </Typography>
    </Card>
  );
};

export default FeedbackCard;
