import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";

import {
  setLandlordData,
  setReady,
} from "context/closureFormContext/closureFormActions";
import { useClosureForm } from "context/closureFormContext/closureFormContext";
import fields from "context/closureFormContext/closureFormFields";
import { LandlordDataProps } from "context/closureFormContext/closureFormTypes";
import { CIVIL_STATUSES } from "context/closureFormContext/constants";
import { useFormNotifications, useFocusError } from "hooks";
import { requiredText } from "utils/yupValidators";

import MaritalRegimeSelector from "./CollapsibleComponents/maritalRegime";
import CallToAction from "./CustomCallToAction";
import {
  FilteredFileUpload,
  FilteredOptions,
  FilteredTextField,
  FilteredSelection,
} from "./FilteredComponents";
import { landlordValidationSchema } from "./validationSchema";
import UserDocuments from "./UserDocuments";
import { handleDocumentTypeChange } from "./utils";

export default function LandlordDataStep() {
  const { state, dispatch } = useClosureForm();
  const [documentRegex, setDocumentRegex] = useState<RegExp>();
  const stepState = state.landlordData;
  const stepFields = fields.landlord;

  const methods = useForm<LandlordDataProps>({
    resolver: yupResolver(
      landlordValidationSchema(documentRegex, state.propertyData.country)
    ),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<LandlordDataProps>({ errors, requiredText });
  useFocusError(errors, methods.setFocus);

  const onSubmit: SubmitHandler<LandlordDataProps> = (data) => {
    dispatch(
      setLandlordData({
        ...data,
        country: state.propertyData.country,
      })
    );
    dispatch(setReady("landlordData"));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FilteredOptions
          id="userType"
          fieldInfo={stepFields.userType()}
          defaultValue={stepState.userType}
          options={[
            { value: "natural", label: "Persona natural" },
            { value: "corporative", label: "Persona jurídica (empresa)" },
          ]}
        />

        <FilteredTextField
          id="name"
          fieldInfo={stepFields.name()}
          defaultValue={stepState.name}
        />

        <FilteredTextField
          id="rfc"
          fieldInfo={stepFields.rfc()}
          defaultValue={stepState.rfc}
        />

        <UserDocuments
          documentTypeField={stepFields.documentType()}
          documentTypeState={stepState.documentType}
          documentTypes={state.documentTypes}
          documentField={stepFields.document()}
          documentState={stepState.document}
          handleDocumentTypeChange={handleDocumentTypeChange(
            state.documentTypes,
            setDocumentRegex
          )}
        />

        <FilteredTextField
          id="phoneNumber"
          fieldInfo={stepFields.phoneNumber()}
          defaultValue={stepState.phoneNumber}
        />

        <FilteredTextField
          id="email"
          fieldInfo={stepFields.email()}
          defaultValue={stepState.email}
        />

        <FilteredTextField
          id="address"
          fieldInfo={stepFields.address()}
          defaultValue={stepState.address}
        />

        <FilteredTextField
          id="city"
          fieldInfo={stepFields.city()}
          defaultValue={stepState.city}
        />

        <FilteredSelection
          id="civilStatus"
          fieldInfo={stepFields.civilStatus()}
          defaultValue={stepState.civilStatus}
          options={CIVIL_STATUSES}
          selectionProps={{
            compressed: true,
            simple: true,
          }}
        />

        <MaritalRegimeSelector />

        <FilteredTextField
          id="occupation"
          fieldInfo={stepFields.occupation()}
          defaultValue={stepState.occupation}
        />

        <FilteredOptions
          id="ownWarehouse"
          fieldInfo={stepFields.ownWarehouse()}
          defaultValue={stepState.ownWarehouse}
          options={[
            { value: "1", label: "Propio" },
            { value: "0", label: "Común" },
          ]}
        />

        <FilteredOptions
          id="isIvaResponsible"
          fieldInfo={stepFields.isIvaResponsible()}
          defaultValue={stepState.isIvaResponsible}
          options={[
            { value: "1", label: "Responsable de IVA (común)" },
            {
              value: "0",
              label: "No responsable de IVA (simplificado)",
            },
          ]}
        />

        <FilteredTextField
          id="specialCondition"
          fieldInfo={stepFields.specialCondition()}
          defaultValue={stepState.specialCondition}
        />

        <FilteredFileUpload
          id="rutPhoto"
          fieldInfo={stepFields.rutPhoto()}
          helperText="En caso de no poder conseguir esta información por motivos de fuerza mayor, contacta a tu ejecutivo"
          allowedMimeTypes={[
            "image/jpeg",
            "image/pjpeg",
            "application/pdf",
            "image/png",
          ]}
        />

        <FilteredOptions
          id="isNew"
          fieldInfo={stepFields.isNew()}
          defaultValue={stepState.isNew}
          options={[
            { value: "1", label: "Si" },
            { value: "0", label: "No" },
          ]}
        />

        <FilteredTextField
          id="landlordBrokerageCommission"
          fieldInfo={stepFields.landlordBrokerageCommission()}
          defaultValue={stepState.landlordBrokerageCommission}
        />

        <FilteredTextField
          id="tenantBrokerageCommission"
          fieldInfo={stepFields.tenantBrokerageCommission()}
          defaultValue={stepState.tenantBrokerageCommission}
        />

        <CallToAction />
      </form>
    </FormProvider>
  );
}
