import { classes } from "./OpenPayDisclaimerStyles";

const OpenPayDisclaimer = () => (
  <div className={classes.container}>
    <h1 className={classes.title}>Referencia válida por 24 hrs</h1>
    <p className={classes.text}>
      El número de referencia generado tendrá una validez única de 24 horas.
      Posterior a esto, deberás repetir este paso y generar una nueva.
    </p>
  </div>
);

export default OpenPayDisclaimer;
