import { ReactNode } from "react";

import { Box, Typography } from "@material-ui/core";
import usePropertiesWrapperStyles from "./PropertiesWrapperStyles";

interface Props {
  title: string;
  children: ReactNode;
}

const PropertiesWrapper = ({ title, children }: Props) => {
  const classes = usePropertiesWrapperStyles();

  return (
    <Box className={classes.listContainer}>
      <Typography className={classes.title}>{title}</Typography>
      <Box className={classes.propertiesContainer}>{children}</Box>
    </Box>
  );
};

export default PropertiesWrapper;
