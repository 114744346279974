import { makeStyles, Theme } from "@material-ui/core";

const useHelpCardStyles = makeStyles<Theme>((theme) => ({
  card: {
    backgroundColor: "#EAF1FB",
    width: "fit-content",
    borderRadius: 8,
    padding: theme.spacing(3, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 3),
    },
  },
  titleContainer: {
    display: "flex",
    gap: theme.spacing(1),
    "& svg": {
      color: theme.palette.grey[900],
    },
    marginBottom: theme.spacing(1),
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  description: {
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(14),
    },
    "& a": {
      color: "#2C73DB",
      fontWeight: 700,
      textDecoration: "underline",
    },
  },
  cta: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    color: theme.palette.primary.main,
    cursor: "pointer",
    "& svg": {
      marginLeft: theme.spacing(1),
      fontSize: theme.typography.pxToRem(14),
    },
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(4),
    },
  },
}));

export default useHelpCardStyles;
