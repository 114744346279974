import { AxiosResponse } from "axios";

import { BILLS_URL } from "env";
import { createHttp } from "services/helper";

import { PropertyBillsResponse } from "./types";

export default {
  getPropertyBills(
    id: number,
    token: string
  ): Promise<AxiosResponse<PropertyBillsResponse>> {
    return createHttp(token, BILLS_URL).get(`/bill/${id}/detail`);
  },
};
