import { useParams } from "react-router-dom";

import useUser from "apps/account/hooks/useUser";
import Spinner from "Components/Shared/partials/Spinner";

import { Submenus } from "../../utils/types";
import EmailMobile from "./components/EmailMobile";
import PersonalInfoMobile from "./components/PersonalInfoMobile";
import MobileLanding from "./components/MobileLanding";
import PhoneMobile from "./components/PhoneMobile";
import PasswordMobile from "./components/PasswordMobile";

const MobileMenu = () => {
  const { submenu } = useParams<{ submenu: Submenus }>();
  const { user, isLoading } = useUser();

  if (isLoading) return <Spinner />;

  if (!submenu) return <MobileLanding />;

  if (submenu === "personal_info")
    return (
      <PersonalInfoMobile
        name={user.name}
        lastName={user.lastName}
        secondLastName={user.secondLastName}
      />
    );

  if (submenu === "phone")
    return (
      <PhoneMobile
        country={user.phone.country.countryCode}
        phone={user.phone.phone}
      />
    );

  if (submenu === "email") return <EmailMobile email={user.email} />;

  if (submenu === "password") return <PasswordMobile />;
};

export default MobileMenu;
