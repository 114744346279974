export const CIVIL_STATUSES = [
  { value: "unmarried", label: "Soltero/a" },
  { value: "married", label: "Casado/a" },
  { value: "divorced", label: "Divorciado/a" },
  { value: "widowed", label: "Viudo/a" },
  { value: "free_union", label: "Unión libre" },
  { value: "other", label: "Otro" },
];

export const MARITAL_REGIMES = [
  { value: "conjugal_society", label: "Sociedad conyugal" },
  { value: "separated_goods", label: "Con separación de bienes" },
  { value: "not_apply", label: "No aplica" },
  { value: "other", label: "Otro" },
];

export const COUNTRY_STEPS = {
  Chile: ["landlordData", "landlordDocs", "summaryLoi"],
  Mexico: [
    "landlordData",
    "landlordDocs",
    "landlordBankData",
    "propertyDocs",
    "summaryLoi",
  ],
  Colombia: [
    "landlordData",
    "landlordDocs",
    "propertyDocs",
    "brokerageContract",
    "summaryLoi",
  ],
};

export const ALLOWED_MIME_TYPES = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
];
