import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  container: {
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  oli: {
    height: "173px",
    width: "150px",
  },
}));
