import { makeStyles } from "@material-ui/core";
import {
  DESKTOP_NAVBAR_BANNER_HEIGHT,
  MOBILE_NAVBAR_BANNER_HEIGHT,
} from "apps/landlord/constants/layout";

const useHorizontalBennerStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: theme.spacing(1.5),
    padding: theme.spacing(2),
    backgroundColor: "#124FA8",
    height: MOBILE_NAVBAR_BANNER_HEIGHT,
    justifyContent: "center",
    color: "#FFFFFF",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    [theme.breakpoints.up("sm")]: {
      height: DESKTOP_NAVBAR_BANNER_HEIGHT,
    },
    "&:hover": {
      backgroundColor: "#144C9D",
    },
  },
}));

export default useHorizontalBennerStyles;
