import { makeStyles, Theme } from "@material-ui/core";

const useCircularStepsStyles = makeStyles((theme: Theme) => ({
  circularStepWrap: {
    position: "relative",
    width: 32,
    height: 32,
  },
  emptyRingCircle: {
    stroke: "#DFE5E8",
  },
  strokeRingCircle: {
    stroke: "#607D8B",
    strokeDasharray: "400, 400",
    transition: "stroke-dashoffset 0.35s",
    transform: "rotate(-90deg)",
    transformOrigin: "50% 50%",
  },
}));

export default useCircularStepsStyles;
