import { Grid, Typography } from "@material-ui/core";

import { Application } from "models/Evaluation";
import { useApplicant } from "apps/landlord/pages/Applicant/context/ApplicantContext";
import applicationInfo from "../../utils";
import useMobileApplicationCardStyles from "./MobileApplicationCardStyles";

interface MobileApplicationCardProps {
  application: Application;
}

const MobileApplicationCard = ({ application }: MobileApplicationCardProps) => {
  const { countryCode } = useApplicant();
  const classes = useMobileApplicationCardStyles();

  const applicantionFields = applicationInfo(application, countryCode);
  const applicantionFieldsList = Object.entries(applicantionFields);

  const columnA = applicantionFieldsList.slice(0, 3);
  const columnB = applicantionFieldsList.slice(3, 6);

  return (
    <Grid container className={classes.infoContainer}>
      <Grid item xs={6}>
        {columnA.map(([key, value]) => (
          <Typography key={key} className={classes.text}>
            {`${key}: ${value}`}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={6}>
        {columnB.map(([key, value]) => (
          <Typography key={key} className={classes.text}>
            {`${key}: ${value}`}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};

export default MobileApplicationCard;
