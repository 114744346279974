import { makeStyles, Theme } from "@material-ui/core";
import { DESKTOP_CONTENT_MAX_WIDTH } from "apps/tenant/constants";

const useRequestsPageStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: DESKTOP_CONTENT_MAX_WIDTH,
    margin: theme.spacing(0, "auto"),
    padding: theme.spacing(4, 2, 5, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5, 5, 6, 5),
    },
    position: "relative",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(28),
    },
  },
  cards: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  ctaContainer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: "#FFF",
    padding: theme.spacing(2),
    boxShadow: "3px 4px 20px rgba(38, 50, 56, 0.15);",
  },
}));

export default useRequestsPageStyles;
