import { Locale } from "models/Countries";
import { FormProps } from "../../../utils/types";

export const parseBankAccountData = (
  isNew: boolean,
  data: FormProps,
  propertyId: number,
  email: string,
  country: Locale
) => ({
  account_type: data.accountType,
  bank_id: data.bankCode.toString(),
  account_number: data.accountNumber,
  document: data.document.trim(),
  email,
  holder: data.fullName,
  ...(country === "co" && { document_type: data.documentType }),
  ...(isNew && { identifier: propertyId.toString() }),
  ...(country === "mx" && {
    clabe_number: data.clabeNumber.toString(),
    city: "Mexico",
  }),
});
