import { makeStyles, Theme } from "@material-ui/core";

import { HEADER_HEIGHT, HEADER_HEIGHT_MOBILE } from "../utils/constants";

const useDrawerHeaderStyles = makeStyles<Theme>((theme) => ({
  headerWrapper: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    position: "sticky",
    top: "0vh",
    zIndex: theme.zIndex.appBar,
    backgroundColor: "#fff",
    height: HEADER_HEIGHT_MOBILE,
    "&:only-child": {
      justifyContent: "flex-end",
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3, 5),
      height: HEADER_HEIGHT,
    },
  },
  iconButton: {
    padding: 0,
  },
  closeIcon: {
    width: 24,
    height: 24,
    color: theme.palette.grey[900],
  },
}));

export default useDrawerHeaderStyles;
