import { IVisit } from "apps/tenant/models/Visit";
import { format } from "date-fns";
import { es } from "date-fns/locale";

export const addMonthHeaders = (visits: IVisit[]) => {
  const visitsWithMonthHeaders = [];
  let currentMonth = visits[0]?.date.getMonth();
  visitsWithMonthHeaders.push({
    month: format(visits[0]?.date, "MMMM", { locale: es }),
    index: "monthHeader",
  });

  visits.forEach((visit) => {
    if (visit.date.getMonth() !== currentMonth) {
      visitsWithMonthHeaders.push({
        month: format(visit.date, "MMMM", { locale: es }),
        index: "monthHeader",
      });
      currentMonth = visit.date.getMonth();
    }
    visitsWithMonthHeaders.push(visit);
  });
  return visitsWithMonthHeaders;
};

export default addMonthHeaders;
