import useCurrentSummaryBreakdown from "apps/landlord/hooks/useCurrentSummaryBreakdown/useCurrentSummaryBreakdown";
import { getIsTenantDefaulter } from "apps/landlord/utils/getIsTenantDefaulter";
import { getIsGuaranteedPlan } from "apps/landlord/utils/getIsGuaranteedPlan";
import PlanTypeTag from "apps/landlord/components/PlanTypeTag";
import { IProperty } from "apps/landlord/models/Property";

import { useContract } from "apps/landlord/hooks";
import useRentedPropertyStyles from "./RentedPropertyStyles";
import PropertyPrice from "../PropertyPrice";
import PropertyCard from "../PropertyCard";

interface Props {
  propertyData: Partial<IProperty>;
}

const RentedProperty = ({ propertyData }: Props) => {
  const classes = useRentedPropertyStyles();
  const { data, isLoading } = useCurrentSummaryBreakdown(
    propertyData.id,
    propertyData.country
  );
  const { contractData, contractLoading } = useContract(propertyData?.uid);
  const currencyCode = () =>
    data?.currency ?? propertyData.propertyDetails.currencyCode;
  const propertyPrice = () =>
    data?.leaseAmount ?? propertyData.propertyDetails.price;

  return (
    <PropertyCard
      propertyData={propertyData}
      status={propertyData.propertyDetails.status}
      key={propertyData.id}
      isDefaulter={getIsTenantDefaulter(
        data?.paymentTenantStatus,
        data?.tenantDebtDays
      )}
      isLoading={isLoading || contractLoading}
    >
      <>
        <PropertyPrice
          currencyCode={currencyCode()}
          propertyPrice={propertyPrice()}
        />
        {(data || contractData) && (
          <PlanTypeTag
            isGuaranteed={getIsGuaranteedPlan(
              data?.planType ?? contractData.plan?.payment_option?.option
            )}
            labelClassname={classes.tagText}
          />
        )}
      </>
    </PropertyCard>
  );
};

export default RentedProperty;
