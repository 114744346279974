import { useState } from "react";

import { ViewModes } from "../utils/types";

const useVisits = () => {
  const [viewMode, setViewMode] = useState<ViewModes>("calendar");
  const [selectedDate, setSelectedDate] = useState(new Date());

  const toggleViewMode = () =>
    setViewMode(viewMode === "calendar" ? "list" : "calendar");

  return {
    viewMode,
    toggleViewMode,
    selectedDate,
    setSelectedDate,
  };
};

export default useVisits;
