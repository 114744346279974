import { useTranslation } from "react-i18next";

import { Box } from "@material-ui/core";

import MobileHeader from "../MobileHeader";
import PersonalInfo from "../../../PersonalInfo";

interface Props {
  name: string;
  lastName: string;
  secondLastName: string;
}

const PersonalInfoMobile = ({ name, lastName, secondLastName }: Props) => {
  const { t } = useTranslation("account.profile");
  return (
    <Box>
      <MobileHeader
        title="Información personal"
        subtitle={t("personal_info_text")}
      />
      <PersonalInfo
        name={name}
        lastName={lastName}
        secondLastName={secondLastName}
      />
    </Box>
  );
};

export default PersonalInfoMobile;
