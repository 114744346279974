import { useFormContext } from "react-hook-form";

import Spinner from "Components/Shared/partials/Spinner";

import OpenPayDisclaimer from "../OpenPayDetails/components/OpenPayDisclaimer";
import useOpenPayInfo from "./hooks/useOpenPayInfo";
import PaymentMethods from "../PaymentMethods";

interface Props {
  movementId: string;
  textValue?: string;
}

const OpenPayOption = ({ movementId, textValue = "Valor Renta" }: Props) => {
  const { data, isLoading } = useOpenPayInfo(movementId);
  const { watch } = useFormContext();
  const method = watch("paymentOption");

  if (isLoading) return <Spinner />;

  return (
    <PaymentMethods
      country="mx"
      methods={data}
      paymentCompany="OpenPay"
      currencyCode="MXN"
      textValue={textValue}
      paymentMethodIcon="https://houm-staging-public.s3.us-east-1.amazonaws.com/cms-content/logo_Open_Pay_9e410dae0c.svg"
    >
      {method === "bank" && <OpenPayDisclaimer />}
    </PaymentMethods>
  );
};

export default OpenPayOption;
