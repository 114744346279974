import { Link } from "react-router-dom";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Divider, Grid, Hidden, Typography } from "@material-ui/core";

import usePublishPropertyReminderStyles from "./PublishPropertyReminderStyles";

const PublishPropertyReminder = () => {
  const classes = usePublishPropertyReminderStyles();
  return (
    <Grid container justifyContent="center">
      <Hidden mdDown>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Hidden>
      <Grid item container className={classes.reminderContainer}>
        <Grid item xs={12}>
          <Typography className={classes.reminderText}>
            ¿Todavía no tienes propiedades publicadas?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.reminderText}>
            <b>Empieza hoy a arrendar o vender tu propiedad</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Link to="/landlord/create-property">
          <Typography className={classes.linkText}>
            Quiero publicar mi propiedad
            <ArrowForwardIcon className={classes.icon} />
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default PublishPropertyReminder;
