import { makeStyles, Theme } from "@material-ui/core/";
import { DESKTOP_HEADER_SPACING } from "apps/lead/constants/layout";

const useStyles = makeStyles<Theme>((theme) => ({
  content: {
    width: "100%",
    maxWidth: 1280,
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(DESKTOP_HEADER_SPACING / 8, "auto", 0),
  },
  leftContainer: {
    position: "sticky",
    top: DESKTOP_HEADER_SPACING,
    height: "100%",
  },
  rightContainer: {
    width: "100%",
    paddingLeft: theme.spacing(7.5),
  },
}));

export default useStyles;
