import { PostPropertyBody } from "services/propertyService/types";
import {
  TYPE_OF_SERVICE,
  PROPERTY_TYPES,
} from "apps/landlord/pages/CreateProperty/context/constants";
import { PropertyData } from "apps/landlord/pages/CreateProperty/context/proprietaryFormTypes";
import { countryCurrency } from "utils";
import { CountryUpperCase } from "models/Countries";
import { format } from "date-fns";

const CONSTANTS = {
  code: "lardlord_flow",
  stagename: "COMPLETAR_INFO_2",
  pipedrivestage: 193,
  mode: "propietary",
};

const normalizeText = (text: string): string =>
  text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

const parsePropertyData = (
  data: PropertyData,
  userId: number,
  constants = CONSTANTS
): PostPropertyBody => {
  const service = TYPE_OF_SERVICE[data.service].value;
  const isForSale = ["both", "sale"].includes(service);
  const isForRent = ["both", "rent"].includes(service);
  const address = data.formattedAddress;
  const deliveryDate = format(
    new Date(data.deliveryDate),
    "yyyy-MM-dd'T'hh:mm"
  );
  const { currency } = countryCurrency(address.country as CountryUpperCase);

  return {
    region: address.region,
    type: PROPERTY_TYPES[data.propertyType].label.toLowerCase(),
    address: address.street,
    street_number: address.streetNumber,
    comuna: address.commune ? address.commune : data.district,
    country: normalizeText(address.country),
    numero_depto: data.propertyNumber ? data.propertyNumber : null,
    propietary_comment: data.additionalInfo ?? "",
    for_sale: isForSale,
    for_rental: isForRent,
    sales_details: {
      first_available_handover_date: isForSale ? deliveryDate : null,
      currency_code: currency,
    },
    code: constants.code,
    stagename: constants.stagename,
    pipedrivestage: constants.pipedrivestage,
    mode: constants.mode,
    property_details: {
      first_available_handover_date: isForRent ? deliveryDate : null,
      currency_code: currency,
      latitud: address.lat,
      longitud: address.lng,
    },
    user: userId,
    ...(data?.propertyStatus && { is_new: data.propertyStatus === "new" }),
  };
};

export default parsePropertyData;
