import { Box } from "@material-ui/core";
import { InspectionReport } from "models/InspectionReport";
import SpaceMobileCard from "./components/SpaceMobileCard/SpaceMobileCard";
import useInspectionMobileStyles from "./InspectionMobileStyles";

interface Props {
  inspectionReport: InspectionReport;
}

const InspectionMobile = ({ inspectionReport }: Props) => {
  const classes = useInspectionMobileStyles();
  return (
    <Box className={classes.inspectionMobileContainer}>
      {inspectionReport?.generalSpacesSummary.map((space) => (
        <SpaceMobileCard
          key={`space-${space.id}`}
          item={space}
          spaces={inspectionReport.spaces}
        />
      ))}
    </Box>
  );
};

export default InspectionMobile;
