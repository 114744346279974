import { useParams } from "react-router-dom";

import { Grid } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import Spinner from "Components/Shared/partials/Spinner";
import { Locale } from "models/Countries";

import useGetReceipts from "../../hooks/useGetReceipts";
import useReceiptsStyles from "./ReceiptsStyles";

interface Props {
  country: Locale;
}

const Receipts = ({ country }: Props) => {
  const classes = useReceiptsStyles();
  const { movementId } = useParams<{ movementId: string }>();
  const { data, isLoading } = useGetReceipts(country, movementId);

  if (isLoading) return <Spinner />;

  return (
    <Grid container className={classes.buttonContainer}>
      {data.map((receipt) => (
        <Grid item key={receipt.id}>
          <DesignSystemButton
            variant={receipt.type === "INVOICE" ? "primary" : "tertiaryB"}
            label={
              receipt.type === "INVOICE"
                ? "Descargar boleta"
                : "Descargar factura"
            }
            size="small"
            href={receipt.url}
            target="_blank"
            buttonClassName={classes.button}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Receipts;
