import { Box, Typography } from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Application } from "models/Evaluation";
import useApplicationCardStyles from "./ApplicationCardStyles";
import DesktopApplicationCard from "./components/DesktopApplicationCard";
import MobileApplicationCard from "./components/MobileApplicationCard";

interface ApplicationCardProps {
  application: Application;
}

const ApplicationCard = ({ application }: ApplicationCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useApplicationCardStyles();

  return (
    <Box className={classes.cardContainer}>
      <Typography className={classes.title}>| Datos Generales</Typography>
      {isMobile ? (
        <MobileApplicationCard application={application} />
      ) : (
        <DesktopApplicationCard application={application} />
      )}
    </Box>
  );
};

export default ApplicationCard;
