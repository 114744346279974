import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

import { ISchedulesQuery } from "../../utils/types";
import FeedbackCarousel from "../FeedbackCarousel";
import useStyles from "./FeedbackStyles";

interface Props {
  schedules: ISchedulesQuery;
}

const Feedback = ({ schedules }: Props) => {
  const classes = useStyles();

  return (
    <Box>
      {schedules.length === 0 ? (
        <Typography className={classes.noFeedback}>
          Aún no hay comentarios de las visitas del houmer para esta propiedad.
        </Typography>
      ) : (
        <FeedbackCarousel schedules={schedules} />
      )}
    </Box>
  );
};

export default Feedback;
