/* eslint-disable max-lines */
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth0 } from "@auth0/auth0-react";
import capitalize from "lodash/capitalize";
import Swal from "sweetalert2";

import { useGetToken } from "hooks";
import { formatPhoneInput } from "utils";
import { Country } from "models/Countries";
import referPartnerService from "services/partnersReferralService";
import { useLandlordVip } from "context/landlordVipContext/landlordVipContext";
import { ReferData } from "services/partnersReferralService/partnersReferralService.types";
import {
  DEFAULT_INITIAL_LAST_NAME,
  DEFAULT_INITIAL_PHONE,
  FAILED_TO_CHECK_PARTNER_PROFILE,
  FAILED_TO_CREATE_PARTNER_PROFILE,
  FAILED_TO_SEND_NEW_REFER_EVENT,
  FAILED_TO_SEND_REFER_LEAD,
  INITIAL_UTM_DATA,
  REFER_LEAD_ALREADY_EXISTS,
  ROLES_USER_DATA_KEY,
  SUCCESS_DESCRIPTION,
  SUCCESS_TITLE,
  USER_NOT_FOUND,
  USER_REFERRED,
  REFER_EVENT_ALREADY_SENT,
  LEAD_UTM_SOURCE,
} from "./constants";
import partnerDataSchema from "./validationSchema";

/**
 * Flow:
 * To send the refers to the partners service
 * - Check if the user exists on partners
 * - If the user exists on partners, send refer lead and throw refer event
 * - If the user does not exist on partners, create it, send refer lead and throw refer event
 */
const useReferPartners = () => {
  /** Dynamic data */
  const [isLoading, setIsLoading] = useState(false);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [processCompleted, setProcessCompleted] = useState<boolean>(false);

  const nullishPhone = formatPhoneInput("");
  const defaultControlledPhoneValue = {
    country: nullishPhone.country,
    phone: nullishPhone.phone,
  };
  const formInitialValues = {
    name: "",
    last_name: "",
    email: "",
    phone: defaultControlledPhoneValue,
  };

  /** Form library implementation */
  const formMethods = useForm<ReferData>({
    mode: "all",
    resolver: yupResolver(partnerDataSchema),
    defaultValues: formInitialValues,
  });

  /** Services init */
  const {
    getUserPartnerProfile,
    createPartnerLead,
    sendReferralToPartners,
    throwReferEvent,
  } = referPartnerService(authToken);

  /** Hooks related data */
  const {
    state: { country },
  } = useLandlordVip();
  const { getToken } = useGetToken();
  const { user: sessionUserData } = useAuth0();
  const { [ROLES_USER_DATA_KEY]: roles } = sessionUserData;

  const hasRoles = !!roles.length;
  const countryName = capitalize(country);

  /** Handlers */
  /** Submit user lead */
  const sendUserLead = (referred: ReferData) => {
    const { email, phone, name, last_name, referral_id } = referred;
    const userLeadData = {
      ...referred,
      email: email.toLocaleLowerCase(),
      phone: phone.parsedPhone,
      country: countryName as Country,
      event: USER_REFERRED,
      utm_source: LEAD_UTM_SOURCE,
    };

    const handleThrowReferEvent = ({ data: registeredNewLead }) =>
      throwReferEvent({
        event: USER_REFERRED,
        user: {
          legacy_id: registeredNewLead.user.id,
          pipedrive_deal_id: registeredNewLead.lead.deal_id,
          email: email.toLocaleLowerCase(),
          name: `${name} ${last_name}`,
        },
        promo_code: referral_id,
        generated_through_platform: true,
      })
        .then(() => setProcessCompleted(true))
        .catch((e) =>
          e?.response?.status === 409
            ? setErrorMessage(REFER_EVENT_ALREADY_SENT)
            : setErrorMessage(FAILED_TO_SEND_NEW_REFER_EVENT)
        );

    return sendReferralToPartners(userLeadData).then(
      handleThrowReferEvent,
      (e) =>
        e.response.status === 409
          ? setErrorMessage(REFER_LEAD_ALREADY_EXISTS)
          : setErrorMessage(FAILED_TO_SEND_REFER_LEAD)
    );
  };

  /** create new referrer if not found */
  const handlePartnerServiceProfileFallback =
    (newReferedData) => (err: any) => {
      if (err?.response?.status === USER_NOT_FOUND) {
        const { name, email } = sessionUserData;
        const names = name.split(" ");
        const [frist_name, last_name] = names;
        const createPartnerPayload = {
          name: frist_name,
          last_name: last_name || DEFAULT_INITIAL_LAST_NAME,
          email,
          phone: DEFAULT_INITIAL_PHONE,
          country: countryName as Country,
        };
        return createPartnerLead(createPartnerPayload, INITIAL_UTM_DATA).then(
          /** User already set as partner */
          (foundUserData) =>
            sendUserLead({
              ...newReferedData,
              referral_id: foundUserData.data.promo_code,
            }),
          /** User doesn't have partner profile setted */
          () => setErrorMessage(FAILED_TO_CREATE_PARTNER_PROFILE)
        );
      }
      return setErrorMessage(FAILED_TO_CHECK_PARTNER_PROFILE);
    };

  /** main form submit handler */
  const handleReferProcess: SubmitHandler<ReferData> = (formData) => {
    if (hasRoles) {
      setProcessCompleted(false);
      setIsLoading(true);
      /** ask backend to check user session partner profile */
      getUserPartnerProfile()
        .then((foundUserData) =>
          sendUserLead({
            ...formData,
            referral_id: foundUserData.data.promo_code,
          })
        )
        .catch(handlePartnerServiceProfileFallback(formData))
        .finally(() => setIsLoading(false));
    }
  };

  const handleFormSubmit = formMethods.handleSubmit(handleReferProcess);

  useEffect(() => {
    getToken().then((token) => setAuthToken(token));
  }, []);

  /** Error dialogs */
  useEffect(() => {
    if (errorMessage) {
      Swal.fire({
        title: "¡Ha ocurrido algo!",
        text: errorMessage,
        type: "error",
        showConfirmButton: true,
        onClose: () => setErrorMessage(null),
      });
    }
  }, [errorMessage]);

  /** Success dialog */
  useEffect(() => {
    if (processCompleted) {
      formMethods.reset();
      Swal.fire({
        title: SUCCESS_TITLE,
        text: SUCCESS_DESCRIPTION,
        type: "success",
        showConfirmButton: true,
        onClose: () => setProcessCompleted(false),
      });
    }
  }, [processCompleted]);

  return {
    handleReferProcess,
    handleFormSubmit,
    isLoading,
    errorMessage,
    formMethods,
  };
};

export default useReferPartners;
