import clx from "classnames";
import { FormControl, Typography } from "@material-ui/core";

import DropArea from "Components/Admin/UIComponents/ControlledForm/DropArea";

import useCustomFileDropzone from "./CustomFileDropzoneStyles";

export interface IFile {
  preview: string;
  [x: string]: any;
}

interface Props {
  allowedMimeTypes?: string;
  maxFiles?: number;
  id: string;
  label?: string;
  description?: string;
  onChange: (files: (File | IFile)[]) => void;
  required?: boolean;
}

const CustomFileDropzone = ({
  allowedMimeTypes = "image/*",
  id,
  label = "",
  description = "",
  maxFiles = 1,
  onChange,
  required = false,
}: Props) => {
  const classes = useCustomFileDropzone();

  return (
    <FormControl className={classes.wrapper}>
      {label && (
        <Typography
          id={id}
          className={clx(classes.label, { [classes.requiredLabel]: required })}
        >
          {label}
        </Typography>
      )}
      {description && (
        <Typography id={id} className={classes.description}>
          {description}
        </Typography>
      )}
      <DropArea
        id={id}
        onChange={onChange}
        accepted={allowedMimeTypes}
        maxFiles={maxFiles}
      />
    </FormControl>
  );
};

export default CustomFileDropzone;
