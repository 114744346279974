import { GridOverlay } from "@material-ui/x-grid";

import Spinner from "Components/Shared/partials/Spinner";

import useCustomLoadingOverlayStyles from "./CustomLoadingOverlayStyles";

const CustomLoadingOverlay = () => {
  const classes = useCustomLoadingOverlayStyles();
  return (
    <GridOverlay>
      <div className={classes.loading}>
        <Spinner />
      </div>
    </GridOverlay>
  );
};

export default CustomLoadingOverlay;
