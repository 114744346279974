import axios from "axios";
import { BACKEND_URL } from "../env";
import { getToken } from "./helper";

export default {
  async getFeedback(scheduleId, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({
      baseURL: BACKEND_URL,
      headers,
    });
    return http.get(`/schedules/v2/visit-feedback/?schedule_id=${scheduleId}`);
  },
};
