import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Spinner from "Components/Shared/partials/Spinner";
import userService from "services/userService";
import useGetToken from "hooks/useGetToken";
import hotjar from "libs/hotjar";

import useProfileUserStyles from "./ProfileUserStyles";
import { Box } from "@material-ui/core";
import analytics from "libs/analytics";

const ProfileUser = ({ children }: { children: React.ReactElement | null }) => {
  const classes = useProfileUserStyles();
  const [isLoading, setIsloading] = useState(true);
  const { getToken } = useGetToken();
  const history = useHistory();

  useEffect(() => {
    const setUserInLocalStorage = async () => {
      try {
        const authToken = await getToken();
        const { data } = await userService.getUser(authToken);
        //TODO: refactor this. we shouldn't store user data on localstorage
        localStorage.setItem("user", JSON.stringify(data));
        // analytics tracking
        analytics.identify(`${data.id}`);
        hotjar.identify(`${data.id}`);
        setIsloading(false);
      } catch (e) {
        history.push("/");
      }
    };
    setUserInLocalStorage();
  }, []);
  if (isLoading)
    return (
      <Box className={classes.loadingContainer}>
        <Spinner />
      </Box>
    );
  return children;
};

export default ProfileUser;
