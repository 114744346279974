import { makeStyles, Theme } from "@material-ui/core";

const useReceiptsStyles = makeStyles<Theme>((theme) => ({
  button: {
    fontSize: theme.typography.pxToRem(14),
    height: 40,
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  buttonContainer: {
    justifyContent: "space-around",
    alignItems: "center",
    gap: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      gap: theme.spacing(2),
    },
  },
}));

export default useReceiptsStyles;
