import { useQuery, UseQueryResult } from "react-query";

import propertyService from "apps/landlord/services/propertyService/propertyService";
import { useGetToken } from "hooks";

import { ISimilarExternalProperty } from "./types";
import parseExternalProperty from "./parsers";

const useExternalProperties = (propertyId: number, type: "rent" | "sale") => {
  const { getToken } = useGetToken();

  const {
    isLoading,
    data = [],
    isError,
    isSuccess,
  }: UseQueryResult<ISimilarExternalProperty[], Error> = useQuery<
    ISimilarExternalProperty[],
    Error
  >(
    ["similar_external_properties_by_id_rev", propertyId, type],
    async () => {
      const authToken = await getToken();
      const response =
        await propertyService.getLandlordSimilarExternalProperties(
          propertyId,
          type,
          authToken
        );
      return response.data.map(parseExternalProperty);
    },
    {
      enabled: !!propertyId && !!type,
      retry: false,
    }
  );

  return {
    data,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useExternalProperties;
