import Grid from "@material-ui/core/Grid";
import { useLoiForm } from "../context/LoiFormContext";
import Typography from "@material-ui/core/Typography";

import CallToAction from "Components/Admin/UIComponents/atoms/CallToAction";
import useWelcomeStyles from "./WelcomeStyles";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

const Welcome = () => {
  const classes = useWelcomeStyles();
  const {
    state: { activeStep },
    actions: { setActiveStep },
  } = useLoiForm();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      className={classes.rootGrid}
    >
      <Grid item>
        <Typography className={classes.textTitle}>
          Vamos por esa promesa de compra venta
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.text}>
          Para poder generar la promesa de compra venta te pediremos que nos
          confirmes algunos datos personales y además que tengas al alcance una
          copia digital o foto de tu identificación.
        </Typography>
        <Typography className={classes.text}>
          Si hay más personas como propietarios de la propiedad, necesitaremos
          que completes sus datos.
        </Typography>
      </Grid>
      <CallToAction>
        <DesignSystemButton
          label="Comenzar"
          onClick={() => setActiveStep(activeStep + 1)}
          size="large"
          variant="primary"
          fullWidth
        />
      </CallToAction>
    </Grid>
  );
};

export default Welcome;
