import { SnackbarProvider } from "notistack";

import { LoiFormProvider } from "context/loiFormContext/loiFormContext";
import PropertyLayout from "apps/landlord/Layout/PropertyLayout";

import LoiForm from "./LoiFormIndex";
import { useHistory } from "react-router-dom";

export default function () {
  const history = useHistory();

  const onHandleBack = () => history.push("/landlord");

  return (
    <LoiFormProvider>
      <SnackbarProvider>
        <PropertyLayout onHandleBack={onHandleBack}>
          <LoiForm />
        </PropertyLayout>
      </SnackbarProvider>
    </LoiFormProvider>
  );
}
