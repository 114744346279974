import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

const useMenuItemStyles = makeStyles<Theme>((theme) => ({
  menuItem: {
    padding: theme.spacing(2),
    width: "100%",
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    backgroundColor: "white",
    borderRadius: theme.spacing(1),
  },
  container: {
    flex: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(1.5),
  },
  text: {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export default useMenuItemStyles;
