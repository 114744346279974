import { View, Image, Text } from "@react-pdf/renderer";

import Star from "../assets/icons/star-fulfilled.png";

import { getResizeImageURL } from "../utils";
import useInspectionReportDocumentStyles from "../InspectionReportDocumentStyles";

const SpacesQualificationPage = ({
  firstSpace,
  photos,
  qualification,
  qualificationStars,
  spaceName,
  observation,
}) => {
  const styles = useInspectionReportDocumentStyles;
  return (
    <View style={styles.pageContent}>
      <View wrap style={styles.suggestedChanges}>
        <View wrap style={styles.suggestedChangesItem}>
          {firstSpace && <Text style={styles.sectionTitle}>Ammbientes</Text>}
          <View style={styles.suggestedChangesSummary}>
            <View style={styles.suggestedChangesSummaryDetails}>
              <View style={styles.keyValSection}>
                <Text style={styles.itemLabel}>{spaceName}</Text>
              </View>
              {qualificationStars.length > 0 && (
                <>
                  <Text style={styles.itemValue}>{qualification}</Text>
                  <View style={styles.qualificationWrapper}>
                    {qualificationStars.map((_, i) => (
                      <Image
                        key={`icon_quali_${spaceName}_${i}`}
                        style={styles.starIcon}
                        source={Star}
                      />
                    ))}
                  </View>
                </>
              )}
              <Text style={styles.itemValue}>{observation}</Text>
            </View>
          </View>
          <View wrap style={styles.suggestedChangesItemsDetailsImagesSection}>
            {photos.map((photoLink, __idx) => {
              const isOdd = __idx % 2 !== 0;
              const isNewPageIdx = __idx === 4 || __idx === 5;

              return (
                <Image
                  style={{
                    ...styles.spaceImage,
                    ...(isOdd && styles.oddSpaceItem),
                    ...(isNewPageIdx && styles.newPageImage),
                  }}
                  source={getResizeImageURL(photoLink)}
                />
              );
            })}
          </View>
        </View>
      </View>
    </View>
  );
};

export default SpacesQualificationPage;
