import _ from "lodash";

import { BooleanString as BS } from "../constants";
import { State } from "../closureFormTypes";

// PROPERTY DATA
export const backParseProperty = (state: State) => {
  const base = {
    address: state.propertyData.address,
  };
  switch (state.propertyData.country) {
    case "Chile":
      base["street_number"] = state.propertyData.addressNumber;
      base["commune"] = state.propertyData.commune;
      base["numero_depto"] = state.propertyData.departmentNumber;
      break;
    case "Colombia":
      base["pin"] = state.propertyData.propertyRegistrationNumber;

      break;
    case "Mexico":
      base["neighborhood_id"] = state.propertyData.neighborhood.value;
      base["commune"] = state.propertyData.commune;
      base["region"] = state.propertyData.region;
      base["type"] =
        state.propertyData.isFlat === BS.NO ? "casa" : "departamento";
      base["pin"] = state.propertyData.propertyRegistrationNumber;
      break;
    default:
      break;
  }
  return base;
};

export const backParsePropertyDetails = (state: State) => {
  const base = {
    id: state.ids.propertyDetailsId,
    valor: state.propertyData.rentValue,
  };
  if (state.propertyData.commonExpenses) {
    base["gc"] = state.propertyData.commonExpenses;
  }
  switch (state.propertyData.country) {
    case "Chile":
      base["parking_numbers"] = state.propertyData.parkingNumbers;
      base["warehouse_number"] = state.propertyData.warehouseNumber;
      break;
    case "Colombia":
      base["parking_numbers"] = state.propertyData.parkingNumbers;
      base["warehouse_number"] = state.propertyData.warehouseNumber;
      base["furnished"] = state.propertyData.isFurnished;
      break;
    case "Mexico":
    default:
      break;
  }
  return base;
};

const backParseContractPropertyNoAdmin = (state: State) => {
  switch (state.propertyData.country) {
    case "Chile":
      return {
        max_people_quantity: state.propertyData.maxPeopleQuantity,
      };
    case "Mexico":
      // eslint-disable-next-line no-case-declarations
      const base = {
        available_date: state.propertyData.availableDate,
        exterior_number: state.propertyData.outerNumber,
        inner_number: state.propertyData.innerNumber,
        postal_code: state.propertyData.postalCode,
        condominium: state.propertyData.inCondo === BS.YES,
        public_deed: state.propertyData.publicDeed === BS.YES,
        notary_name: state.propertyData.notary,
        notary_number: state.propertyData.notaryNumber,
        predial_number: state.propertyData.predialNumber,
        include_common_expenses:
          state.propertyData.hasCommonExpenses === BS.YES,
        warranty_month_amount: state.propertyData.warranty,
      };
      if (state.propertyData.publicDeed === BS.YES) {
        base["public_deed_number"] = state.propertyData.publicDeedNumber;
        base["public_deed_date"] = state.propertyData.publicDeedDate;
      }
      return base;
    default:
      return {};
  }
};

export const backParseContractProperty = (state: State) => {
  const base = backParseContractPropertyNoAdmin(state);
  if (!state.isAdmin) {
    return base;
  }
  base["is_urban"] = state.propertyData.isUrban === BS.YES;
  switch (state.propertyData.country) {
    case "Colombia":
      base["special_pay_condition"] = state.propertyData.paymentCondition;
      base["special_charge_condition"] = state.propertyData.chargeCondition;
      base["mortgage_code"] = state.propertyData.mortgage;
      base["domain_restriction"] = state.propertyData.domainRestriction;
      base["policy"] = state.propertyData.policy;
      base["part_of_horizontal"] = state.propertyData.isHorizontal === BS.YES;
      break;
    default:
      break;
  }
  return base;
};

// LANDLORD DATA
export const backParsePropertyLandlord = (state: State) => {
  switch (state.propertyData.country) {
    case "Colombia":
      return {
        is_iva_responsible: state.landlordData.isIvaResponsible === BS.YES,
      };
    default:
      return {};
  }
};

const backParseContractLandlordNoAdmin = (state: State) => {
  const base = {
    owner: {
      name: state.landlordData.name,
      rut: state.landlordData.document,
      document: state.landlordData.document,
      document_type: state.landlordData.documentType,
      phone: state.landlordData.phoneNumber,
      email: state.landlordData.email,
      address: state.landlordData.address,
    },
  };

  switch (state.propertyData.country) {
    case "Colombia":
      base["own_warehouse"] = state.landlordData.ownWarehouse === BS.YES;
      base.owner["city"] = state.landlordData.city;
      base["special_owner_condition"] = state.landlordData.specialCondition;
      break;
    case "Mexico":
    case "México":
      base.owner["rfc_number"] = state.landlordData.rfc;
      base.owner["civil_status"] = _.snakeCase(state.landlordData.civilStatus);
      base.owner["marital_regime"] = _.snakeCase(
        state.landlordData.maritalRegime
      );
      base.owner["occupation"] = state.landlordData.occupation;
      base["lessor_brokerage_commmission"] =
        state.landlordData.landlordBrokerageCommission;
      base["lessee_brokerage_commission"] =
        state.landlordData.tenantBrokerageCommission;
      break;
    default:
      break;
  }
  return base;
};

export const backParseContractLandlord = (state: State) => {
  const base = backParseContractLandlordNoAdmin(state);
  if (!state.isAdmin) {
    return base;
  }
  switch (state.propertyData.country) {
    case "Colombia":
      base.owner["country"] = state.landlordData.country;
      break;
    case "Mexico":
    case "México":
      base.owner["tributary_type"] = state.landlordData.userType;
      break;
    default:
      break;
  }
  return base;
};

// THIRD PARTY DATA
export const backParseContractThirdParty = (state: State) => ({
  third_party: {
    name: state.thirdPartyData.name,
    rut: state.thirdPartyData.document,
    document: state.thirdPartyData.document,
    document_type: state.thirdPartyData.documentType,
    email: state.thirdPartyData.email,
    phone: state.thirdPartyData.phone,
    address: state.thirdPartyData.address,
    country: state.thirdPartyData.country,
  },
});

// SERVICES DATA
export const backParseService = (state: State, serviceType: string) => {
  switch (state.propertyData.country) {
    case "Colombia":
      return {
        service_id: state.publicServices[`${serviceType}Service`],
        client_number: state.publicServices[`${serviceType}Client`],
        prop_id: state.ids.propertyId,
      };
    default:
      return {};
  }
};

export const backParseContractService = (state: State) => {
  switch (state.propertyData.country) {
    case "Colombia":
      return {
        additional_services: state.publicServices.additionalServices,
        included_services: state.publicServices.includedServices,
      };
    default:
      return {};
  }
};

export const backParsePropertyDetailsService = (state: State) => ({
  cleaning: state.publicServices.cleaningClient,
});

// ADMIN DATA
export const backParsePropertyAdmin = (state: State) => ({
  administration_email: state.adminData.email,
  administration_phonenumber: state.adminData.phoneNumber,
});

export const backParsePropertyDetailsAdmin = (state: State) => {
  switch (state.propertyData.country) {
    case "Colombia":
      return { has_houm_cleaning: state.adminData.hasHoumCleaning };
    case "Mexico":
      return { has_houm_cleaning: state.adminData.hasHoumCleaning };
    default:
      return {};
  }
};

export const backParseContractAdmin = (state: State) => {
  switch (state.propertyData.country) {
    case "Mexico":
    case "México":
      return {
        cleaning_service_amount: state.adminData.cleaningAmount,
      };
    default:
      return {};
  }
};

// FILES
export const backParseFiles = (state: State) => {
  const formData = new FormData();
  if (state.files.ccFile) {
    formData.append("cc", state.files.ccFile);
  }
  if (state.files.predialFile) {
    formData.append("predial", state.files.predialFile);
  }
  if (state.files.traditionLibertyFile) {
    formData.append("liberty_tradition", state.files.traditionLibertyFile);
  }
  if (state.files.waterReceipt) {
    formData.append("water_service_receipt", state.files.waterReceipt);
  }
  if (state.files.gasReceipt) {
    formData.append("gas_service_receipt", state.files.gasReceipt);
  }
  if (state.files.energyReceipt) {
    formData.append("energy_service_receipt", state.files.energyReceipt);
  }
  if (state.files.cleaningReceipt) {
    formData.append("cleaning_receipt", state.files.cleaningReceipt);
  }
  if (state.files.administrationReceipt) {
    formData.append(
      "administration_receipt",
      state.files.administrationReceipt
    );
  }
  if (state.files.publicWriting) {
    formData.append("public_writing", state.files.publicWriting);
  }
  if (state.files.predialVoucher) {
    formData.append("predial_voucher", state.files.predialVoucher);
  }
  return formData;
};
