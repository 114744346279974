import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  onClickFlag: boolean;
}

const defaultBreakpoint = "md";

const usePropertyDesktopStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(2),
    borderRadius: 16,
    width: "100%",
    border: `1px solid ${theme.palette.grey[75]}`,
    cursor: ({ onClickFlag }) => onClickFlag && "pointer",
    "&:hover": {
      boxShadow: "3px 4px 20px rgba(38, 50, 56, 0.15)",
    },
  },
  imageContainer: {
    width: "100%",
    minHeight: 144,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      width: 250,
      minHeight: 100,
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 0",
    padding: 0,
  },
  descriptionContainer: {
    marginTop: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginTop: 0,
    },
  },
}));

export default usePropertyDesktopStyles;
