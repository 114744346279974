import classNames from "classnames";
import Radio, { RadioProps } from "@material-ui/core/Radio";

import useStyles from "./CustomStyleRadioStyles";

export default function CustomStyleRadio(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={classNames(classes.icon, classes.checkedIcon)} />
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
