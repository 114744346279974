import { isMobile } from "react-device-detect";

import { Box } from "@material-ui/core";

import { IProperty } from "apps/landlord/models/Property";
import PaymentSummary from "./components/PaymentSummary";
import useAccountInfoStyles from "./AccountInfoStyles";
import BankAccounts from "./components/BankAccounts";

interface Props {
  property?: Partial<IProperty>;
  rentedFlag: boolean;
}

const AccountInfo = ({ property = null, rentedFlag }: Props) => {
  const classes = useAccountInfoStyles();

  return (
    <Box className={classes.accountInfoContainer}>
      {rentedFlag && <PaymentSummary property={property} />}
      {!isMobile && <BankAccounts />}
    </Box>
  );
};

export default AccountInfo;
