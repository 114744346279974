import { round } from "lodash";

import { PropertyData } from "models/Property";
import { IPropertyDetails } from "./types";

const parsePropertyDetails = (data: PropertyData): IPropertyDetails => {
  const propertyDetails = data.property_details[0];
  const amenities = data?.association_amenities;
  return {
    rentalStatus: data.sales_details?.status,
    saleStatus: propertyDetails.status,
    propertyDetailsId: propertyDetails.id,
    details: {
      totalArea: round(Number(propertyDetails.m_terreno)),
      builtArea: round(Number(propertyDetails.m_construidos)),
      bathrooms: propertyDetails.banos,
      rooms: propertyDetails.dormitorios,
      parkingQuantity: propertyDetails.estacionamientos,
      parkingNumbers: propertyDetails.parking_numbers as unknown as string,
      warehouse: propertyDetails.bodega,
      warehouseNumber: propertyDetails.warehouse_number as unknown as string,
      yearBuilt: data.year_built ? data.year_built : data.antiquity,
    },
    propertyAmenities: {
      furnished:
        propertyDetails.furnished === "partial" ||
        propertyDetails.furnished === "fully",
      allowsPets: propertyDetails.mascotas,
      hasAirConditioning: propertyDetails.has_air_conditioning,
      heating: propertyDetails.calefaccion,
      hasPool: propertyDetails.piscina,
      hasBalcony: propertyDetails.has_balcony,
      hasLaundryRoom: propertyDetails.has_laundry_room,
      hasLaundryCapacity: propertyDetails.laundry_capacity,
    },
    amenities: {
      condominium: propertyDetails.condominio,
      hasElevator: amenities?.has_elevator,
      hasGym: amenities?.has_gym,
      hasPetArea: amenities?.has_pet_area,
      hasConcierge: amenities?.has_concierge,
      hasPatio: amenities?.has_patio,
      hasBbqArea: amenities?.has_bbq_area,
      hasSauna: amenities?.has_sauna,
      hasLaundry: amenities?.has_laundry,
      hasPartyRoom: amenities?.has_party_room,
      hasCinema: amenities?.has_cinema,
      hasGourmetSpace: amenities?.has_gourmet_space,
      hasPlayground: amenities?.has_playground,
      hasAllDayVigilance: amenities?.has_all_day_vigilance,
      hasGamesRoom: amenities?.has_games_room,
      hasPlayroom: amenities?.has_playroom,
      hasRoofGarden: amenities?.has_roof_garden,
      hasSteamRoom: amenities?.has_steam_room,
      hasSwimmingPool: amenities?.has_swimming_pool,
    },
  };
};

export default parsePropertyDetails;
