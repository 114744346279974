import { makeStyles, Theme } from "@material-ui/core";

const useFeedbackCarouselStyles = makeStyles<Theme>((theme) => ({
  contentCardWrap: {
    width: "100%",
    padding: theme.spacing(3, 2.5),
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(3, 0),
      gap: 32,
    },
  },
  icon: {
    fontSize: 20,
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.125rem",
    },
  },
  carouselNavButton: {
    background: "white",
    borderRadius: "50%",
    padding: theme.spacing(1),
    lineHeight: "normal",
    position: "absolute",
    top: "40%",
    zIndex: theme.zIndex.mobileStepper,
    margin: theme.spacing(0, "6%"),
    height: 45,
    width: 45,
    opacity: 0.6,
    transition: "0.3s",
    "&:hover": {
      opacity: 1,
    },
    border: "2px solid #EFEFEF",
    [theme.breakpoints.up("sm")]: {
      height: 60,
      width: 60,
    },
  },
  carouselNextNavButton: {
    right: 0,
    marginRight: 0,
    cursor: "pointer",
  },
  carouselPrevNavButton: {
    left: 0,
    marginLeft: 0,
    cursor: "pointer",
  },
}));

export default useFeedbackCarouselStyles;
