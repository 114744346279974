import { makeStyles, Theme } from "@material-ui/core";

const useRentRequirementsStyles = makeStyles((theme: Theme) => ({
  rentRequirementsRoot: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    borderRadius: 16,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
  },
  rentRequirementsTitle: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(18),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(26),
      lineHeight: theme.typography.pxToRem(34),
    },
  },
  more: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  arrow: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
    transform: "rotate(-45deg)",
    display: "inline-block",
  },
}));

export default useRentRequirementsStyles;
