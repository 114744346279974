import { ReactNode } from "react";
import { isMobile } from "react-device-detect";

import {
  IoArrowBack as ArrowLeft,
  IoChevronBackOutline as MobileArrowLeft,
} from "@houm-com/ui-icons";
import LightButton from "@houm-com/ui/LightButton";

import useEvaluatorLayoutStyles from "apps/lead/layout/EvaluatorLayout/EvaluatorLayoutStyles";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { EvaluatorPages } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import houmLogo from "assets/images/houmLogo.png";

interface Props {
  children: ReactNode;
  handleBack: () => void;
}

const EvaluatorLayout = ({ children, handleBack }: Props) => {
  const { state } = useEvaluation();
  const { page } = state;
  const classes = useEvaluatorLayoutStyles();
  const showBackButton = page === EvaluatorPages.FORM;

  return (
    <div className={classes.evaluatorFormRoot}>
      <div className={classes.navBarRoot}>
        {showBackButton && (
          <LightButton
            size="lg"
            variant="secondary"
            position="start"
            icon={
              isMobile ? <MobileArrowLeft size={20} /> : <ArrowLeft size={29} />
            }
            type="button"
            onClick={handleBack}
          />
        )}
        <img src={houmLogo} alt="houm-logo" className={classes.navbarImg} />
      </div>
      <div className="w-full h-full">{children}</div>
    </div>
  );
};

export default EvaluatorLayout;
