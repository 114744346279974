import { useState } from "react";

import { Grid, Typography } from "@material-ui/core";

import useBasicServicesSectionStyles from "./BasicServicesSectionStyles";
import BasicServicesField from "./components/BasicServicesField";
import BasicServicesSelect from "./components/BasicServicesSelect";

interface Props {
  title: string;
  options: { label: string; value: string }[];
  idBase: "water" | "energy" | "gas";
  required?: boolean;
  defaultSelectValue?: string | null;
  defaultFieldValue?: string | null;
}

export default function BasicServicesSection({
  title,
  options,
  idBase,
  required = true,
  defaultSelectValue = null,
  defaultFieldValue = null,
}: Props) {
  const classes = useBasicServicesSectionStyles();
  const [companyNameSelected, setCompanyNameSelected] = useState(
    !!defaultSelectValue
  );
  const companyNameField = `${idBase}CompanyName`;
  const clientNumberField = `${idBase}ClientNumber`;
  return (
    <>
      <Typography className={classes.formSectionTitle}>{title}</Typography>
      <Grid
        container
        spacing={2}
        xs={12}
        className={classes.formSectionFieldContainer}
      >
        <Grid item xs={12}>
          <BasicServicesSelect
            required={required}
            options={options}
            id={companyNameField}
            defaultValue={defaultSelectValue}
            setCompanyNameSelected={setCompanyNameSelected}
          />
        </Grid>
        <Grid item xs={12}>
          <BasicServicesField
            required={required}
            id={clientNumberField}
            defaultValue={defaultFieldValue}
            disabled={!companyNameSelected}
          />
        </Grid>
      </Grid>
    </>
  );
}
