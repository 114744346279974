import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";

import { useFormNotifications } from "hooks";
import { ControlledTextField } from "Components/Admin/UIComponents/ControlledForm";
import {
  setLandlordBankData,
  stepSubmit,
} from "context/loiFormContext/loiFormActions";
import { useLoiForm } from "context/loiFormContext/loiFormContext";
import fields from "context/loiFormContext/loiFormFields";
import { LandlordBankData } from "context/loiFormContext/loiFormTypes";
import CallToAction from "./components/CustomCallToAction";
import FilteredInput from "./components/FilteredInput";
import {
  landlordBankDataValidationSchema,
  requiredText,
} from "./validationSchema";
import LoiFormStepHeader from "./components/LoiFormStepHeader";

export default function LandlordBankDataStep() {
  const { state, dispatch } = useLoiForm();
  const stepState = state.landlordBankData;
  const stepFields = fields.landlordBankData;

  const methods = useForm<LandlordBankData>({
    resolver: yupResolver(landlordBankDataValidationSchema),
    reValidateMode: "onBlur",
  });

  const { errors } = methods.formState;
  useFormNotifications<LandlordBankData>({ errors, requiredText });

  const onSubmit: SubmitHandler<LandlordBankData> = (data) => {
    dispatch(setLandlordBankData({ ...data }));
    stepSubmit(dispatch, state.step, state.availableSteps);
  };

  return (
    <FormProvider {...methods}>
      <LoiFormStepHeader
        title="Datos bancarios"
        description="Necesitamos que nos indiques donde se va a realizar el depósito del enganche."
      />

      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FilteredInput fieldInfo={stepFields.clabe()}>
          <ControlledTextField
            id="clabe"
            label={stepFields.clabe().label(state.country)}
            textFieldProps={{ placeholder: "Ingresar" }}
            defaultValue={stepState.clabe}
          />
        </FilteredInput>

        <CallToAction />
      </form>
    </FormProvider>
  );
}
