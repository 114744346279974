import { Grid } from "@material-ui/core";

import { ISimilarProperty } from "apps/landlord/pages/Price/hooks/useSimilarProperties/types";
import { AnalysisTypeProperty } from "apps/landlord/pages/Price/utils/types";
import { Locale } from "models/Countries";

import BasicInfoCard from "../../../BasicInfoCard";
import StatsInfoCard from "../../../StatsInfoCard";
import DetailsInfoCard from "../DetailsInfoCard";

interface Props {
  locale: Locale;
  isMain?: boolean;
  isExternal?: boolean;
  property: ISimilarProperty;
  type: AnalysisTypeProperty;
}

const SimilarPropertyCard = ({
  isExternal = false,
  locale,
  property,
  type = "rent",
}: Props) => (
  <Grid container spacing={1}>
    <Grid item xs={6} md={isExternal ? 6 : 3}>
      <BasicInfoCard property={property} isExternal={isExternal} />
    </Grid>
    <Grid item xs={6} md={isExternal ? 6 : 3}>
      <DetailsInfoCard
        property={property}
        locale={locale}
        isExternal={isExternal}
        type={type}
      />
    </Grid>
    {!isExternal && (
      <Grid item xs={12} md={6}>
        <StatsInfoCard marketplaceStats={property.marketplaceStats} />
      </Grid>
    )}
  </Grid>
);

export default SimilarPropertyCard;
