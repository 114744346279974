import { MenuItem } from "@material-ui/core";

import { useUserRole } from "apps/main/context/userRoleContext";
import {
  IoHeartOutline,
  IoPersonOutline,
  IoGiftOutline,
  IoKeyOutline,
} from "@houm-com/ui-icons";

import AuthLogoutButton from "../AuthLogoutButton";
import ItemMenu from "./components/ItemMenu";

interface Props {
  handleClose?: () => void;
  isDropdown?: boolean;
}

const AuthItemsMenu = ({ handleClose, isDropdown = false }: Props) => {
  const { isTenant } = useUserRole();

  const handleClick = () => {
    handleClose?.();
  };

  return (
    <>
      <MenuItem onClick={() => handleClick()}>
        <ItemMenu
          url="/account/favorites/likes"
          text="Mis favoritos"
          icon={IoHeartOutline}
        />
      </MenuItem>
      <MenuItem onClick={() => handleClick()}>
        <ItemMenu url="/account" text="Mi perfil" icon={IoPersonOutline} />
      </MenuItem>
      {isTenant && (
        <MenuItem onClick={() => handleClick()}>
          <ItemMenu
            url="/tenant"
            text="Usar como arrendatario"
            icon={IoKeyOutline}
          />
        </MenuItem>
      )}
      <MenuItem onClick={handleClose}>
        <ItemMenu
          url="/propietary/refer-partner"
          text="Refiere y gana"
          icon={IoGiftOutline}
        />
      </MenuItem>
      {isDropdown && (
        <MenuItem onClick={handleClose}>
          <AuthLogoutButton />
        </MenuItem>
      )}
    </>
  );
};

export default AuthItemsMenu;
