import * as yup from "yup";
import { phoneObject } from "utils/yupValidators/phone";
import { CountryUpperCase } from "models/Countries";
import { countryCode } from "utils";

export const requiredText = "Obligatorio";

const regexByCountry = {
  cl: "^\\d{1,2}\\.?\\d{3}\\.?\\d{3}[-]?[0-9kK]{1}$",
  mx: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{3})$/,
};

export const userFormSchema = (
  country: CountryUpperCase,
  emailList: string[] = []
) =>
  yup
    .object({
      email: yup
        .string()
        .email("Ingrese un email válido")
        .nullable()
        .required(requiredText)
        .test("email", null, (obj) => {
          const isRepeatEmail = emailList.includes(obj);
          if (emailList.length === 0 || !isRepeatEmail) {
            return true;
          }
          return new yup.ValidationError(
            "Cada codeudor y arrendatario principal deben tener un correo distinto.",
            null,
            "email",
            "typeError"
          );
        }),
      phone: phoneObject
        .typeError("Número de celular inválido")
        .required(requiredText),
      name: yup.string().nullable().required(requiredText),
      lastName: yup.string().nullable().required(requiredText),
      secondLastName: yup.string().nullable().required(requiredText),
      document: yup.string().nullable().required(requiredText),
    })
    .test("validationDocument", null, (obj) => {
      const regexValue = regexByCountry[countryCode(country)];
      const regex = new RegExp(regexValue);
      if (regex.test(obj.document.toUpperCase())) {
        return true;
      }
      return new yup.ValidationError(
        "Documento no válido",
        null,
        "document",
        "typeError"
      );
    });
