import GlobalLayout from "Components/Layout/GlobalLayout";
import TermsAndConditions from "./TermsAndConditions";

const TermsAndConditionsIndex = () => (
  <GlobalLayout>
    <TermsAndConditions />
  </GlobalLayout>
);

export default TermsAndConditionsIndex;
