enum TagType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  GREY = "grey",
  LIGHT_GREY = "light grey",
}

export default TagType;
