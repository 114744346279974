import { isMobile } from "react-device-detect";

import { IoChevronDown as DownArrow } from "@houm-com/ui-icons";

import deskImage from "assets/images/adminLandlord/com-feliz-info-desk.png";
import mobileImage from "assets/images/adminLandlord/com-feliz-info-mobile.png";
import useWhereToFindInfoLinkStyles from "./WhereToFindInfoLinkStyles";

export default function WhereToFindInfoLink() {
  const classes = useWhereToFindInfoLinkStyles();
  return (
    <details className={classes.whereToFindInfo}>
      <summary className={classes.whereToFindInfoLink}>
        ¿Dónde encuentro esta información?{" "}
        <DownArrow className={classes.whereToFindInfoIcon} />
      </summary>
      <img
        className={classes.InfoImage}
        src={isMobile ? mobileImage : deskImage}
        alt="información de comunidad feliz"
      />
    </details>
  );
}
