import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CustomDialog from "Components/Admin/UIComponents/CustomDialog";
import useSelectPlanDialogStyles from "./SelectPlanDialogStyles";

interface Props {
  open: boolean;
  subtitle: string;
  setOpen: (open: boolean) => void;
  handleSubmit: () => void;
  loading: boolean;
}

export default function SelectPlanDialog({
  open,
  setOpen,
  subtitle,
  handleSubmit,
  loading,
}: Props) {
  const classes = useSelectPlanDialogStyles();

  return (
    <CustomDialog
      open={open}
      title="¿Estás seguro de que quieres elegir este plan?"
      subtitle={subtitle}
      onClose={() => setOpen(false)}
      classes={{
        title: classes.title,
        subtitle: classes.subtitle,
        modalContainer: classes.modalContainer,
        titleContainer: classes.titleContainer,
      }}
    >
      <div className={classes.buttonsContainer}>
        <DesignSystemButton
          label="Cancelar"
          variant="tertiaryA"
          size="small"
          onClick={() => setOpen(false)}
        />
        <DesignSystemButton
          label="Sí, elegir"
          variant="primary"
          size="small"
          onClick={handleSubmit}
          isLoading={loading}
        />
      </div>
    </CustomDialog>
  );
}
