import * as yup from "yup";

const requiredText = "Obligatorio";
const mustBeNumber = "Debe ser un número";
const mustBePositiveValue = "El número debe ser positivo";
const mustBeIntegerValue = "El número debe ser entero";

export const validationSchemaClientCode = yup.object().shape({
  externalId: yup
    .number()
    .required(requiredText)
    .typeError(mustBeNumber)
    .integer(mustBeIntegerValue)
    .min(0, mustBePositiveValue),
});

export const validationSchemaBill = yup.object().shape({
  externalId: yup
    .number()
    .required(requiredText)
    .typeError(mustBeNumber)
    .integer(mustBeIntegerValue)
    .min(0, mustBePositiveValue),
  companyName: yup.string().required(requiredText),
});
