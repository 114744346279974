import { CommonExpensesCompanies } from "apps/landlord/models/ServicesCompanies";
import { IClientInfo } from "apps/landlord/services/propertiesDuesService/type";
import ComunidadFelizFields from "./components/ComunidadFelizFields";
import KastorFields from "./components/KastorFields";
import OtherFields from "./components/OtherFields";

interface Props {
  companyName: CommonExpensesCompanies;
  clientInfo?: IClientInfo | null;
  administrationEmail?: string | null;
}

export default function CompanyFields({
  companyName,
  clientInfo = null,
  administrationEmail = null,
}: Props) {
  return (
    <>
      {companyName === CommonExpensesCompanies.COMUNIDAD_FELIZ && (
        <ComunidadFelizFields clientInfo={clientInfo} />
      )}
      {companyName === CommonExpensesCompanies.KASTOR && (
        <KastorFields clientInfo={clientInfo} />
      )}
      {companyName && companyName === CommonExpensesCompanies.OTHER && (
        <OtherFields defaultValue={administrationEmail} />
      )}
    </>
  );
}
