import { UseQueryResult, useQuery } from "react-query";

import bankAccountService from "apps/landlord/services/bankAccountService";
import { IBank } from "apps/landlord/models/BankAccount";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

import { parseBankAccounts } from "./utils/parsers";

const useGetBanks = ({ country }: { country: Locale }) => {
  const { getToken } = useGetToken();

  const {
    data = [],
    isLoading,
    isError,
  }: UseQueryResult<IBank[], Error> = useQuery<IBank[], Error>(
    ["banksList", country],
    async () => {
      const authToken = await getToken();
      const response = await bankAccountService.getBanks(authToken, country);
      const orderedBanks = parseBankAccounts(response.data).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      return orderedBanks;
    },
    {
      enabled: !!country,
    }
  );

  return {
    data,
    isLoading,
    isError,
  };
};

export default useGetBanks;
