import { createStyles, makeStyles, Theme } from "@material-ui/core";

const DesktopPaymentsTableStyles = (nbRows: number = 0) =>
  makeStyles((theme: Theme) => {
    const ROW_HEIGHT = 48;
    const ROW_MARGIN = 8;
    const containerHeight = (nbRows + 1) * (ROW_HEIGHT + ROW_MARGIN);
    const defaultBorderRadius = "8px";

    return createStyles({
      root: {
        minHeight: containerHeight,
        "&.MuiDataGrid-root .MuiDataGrid-autoHeight": {
          height: "none !important",
          maxHeight: `${containerHeight}px !important`,
        },
        "&.MuiDataGrid-virtualScrollerContent": {
          paddingBottom: nbRows * 8, // to compensate space between rows
          boxSizing: "content-box",
        },
        "&.MuiDataGrid-root .MuiDataGrid-viewport": {
          maxHeight: `${containerHeight}px !important`,
        },
        "&.MuiDataGrid-root .MuiDataGrid-renderingZone": {
          maxHeight: `${containerHeight}px !important`,
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
          marginBottom: theme.spacing(1),
          border: 0,
          backgroundColor: "rgba(255, 0, 255, 0.20)",
        },
        "& .MuiDataGrid-columnsContainer": {
          borderWidth: 0,
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
        },
        "&.MuiDataGrid-root .MuiDataGrid-row": {
          border: `1px solid ${theme.palette.grey[50]}`,
          borderRadius: 8,
          marginTop: theme.spacing(1),
          backgroundColor: "white",
          width: "100%",
          height: 38,
        },
        "& .MuiDataGrid-cell": {
          paddingLeft: 12,
          borderBottomWidth: 0,
          borderTopWidth: 0,
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer": {
          padding: "0px !important",
        },
        "&.MuiDataGrid-root .MuiDataGrid-iconSeparator": {
          color: "#BFCBD1",
          strokeWidth: "1px",
        },
      },
      headerWrapper: {
        backgroundColor: "#DAE4E8",
        fontSize: theme.typography.pxToRem(14),
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
          color: theme.palette.grey[500],
          borderBottomWidth: 0,
        },
      },
      firstHeaderCell: {
        borderTopLeftRadius: defaultBorderRadius,
        borderBottomLeftRadius: defaultBorderRadius,
      },
      commentsHeaderCell: {
        "& > .MuiDataGrid-columnSeparator": {
          display: "none",
        },
      },
      lastRenderedHeaderCell: {
        borderTopRightRadius: defaultBorderRadius,
        borderBottomRightRadius: defaultBorderRadius,
        "& > .MuiDataGrid-columnSeparator": {
          display: "none",
        },
      },
      centerCell: {
        fontSize: theme.typography.pxToRem(14),
        borderLeft: "0 !important",
        position: "relative",
        "&::after": {
          content: '""',
          position: "absolute",
          top: 8,
          right: 0,
          width: "1px",
          height: "60%",
          borderRight: "1px solid #DFE5E8",
          boxSizing: "border-box",
        },
      },
      lastRenderedRowCell: {
        borderRight: "nonce",
      },
      lastHeaderCell: {
        display: "none !important",
        maxWidth: "20px !important",
      },
      emptyHeaderName: {
        color: "#DAE4E8",
        height: "100%",
      },
      linkContainer: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1.75),
        height: 20,
      },
      link: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 700,
        lineHeight: 1.6,
        textDecoration: "underline",
        cursor: "pointer",
      },
      emptyData: {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: 1.57,
        marginBottom: theme.spacing(2),
      },
    });
  });

export default DesktopPaymentsTableStyles;
