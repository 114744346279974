import * as yup from "yup";

const requiredText = "Campo requerido";
const numberInvalid = "Número de celular inválido";
const emailInvalid = "Correo electrónico invalido";
export const phoneObject = yup.object().shape({
  phone: yup
    .string()
    .min(8, numberInvalid)
    .max(10, numberInvalid)
    .required(requiredText),
  country: yup.object({
    countryName: yup.string().required(requiredText),
    countryCode: yup.string().required(requiredText),
    callingCode: yup.string().required(requiredText),
  }),
});

export const formSchema = yup.object().shape({
  firstName: yup.string().required(requiredText),
  lastName: yup.string().required(requiredText),
  email: yup.string().email(emailInvalid).required(requiredText),
  selectCountry: yup
    .string()
    .required(requiredText)
    .typeError("Campo requerido"),
  phone: phoneObject.typeError(numberInvalid),
});
