import { makeStyles, Theme } from "@material-ui/core";
import { DESKTOP_CONTENT_MAX_WIDTH } from "apps/tenant/constants";

const useServicesStyles = makeStyles((theme: Theme) => ({
  spinner: {
    height: "100%",
  },
  servicesRoot: {
    padding: theme.spacing(2.5, 2, 3),
    [theme.breakpoints.up("sm")]: {
      padding: 0,
      width: "100%",
      marginBottom: 0,
      height: "100%",
    },
  },
  servicesInnerContainer: {
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, "auto"),
      padding: theme.spacing(4.5, 4),
      maxWidth: DESKTOP_CONTENT_MAX_WIDTH,
    },
  },
  servicesContent: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
  },
}));

export default useServicesStyles;
