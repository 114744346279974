/* eslint-disable max-lines */
import { format } from "rut.js";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import FieldSelect from "@houm-com/ui/FieldSelect";
import FieldInput from "@houm-com/ui/FieldInput";
import Button from "@houm-com/ui/Button";

import { DocumentTypes } from "apps/landlord/hooks/useDocumentTypeList/useDocumentTypeList";
import { BankAccount } from "apps/landlord/hooks/useBankAccountByPropertyId/utils/types";
import CallToActionDrawer from "apps/ui/CustomDrawer/CallToActionDrawer";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import ContentDrawer from "apps/ui/CustomDrawer/ContentDrawer";
import { IBank } from "apps/landlord/models/BankAccount";
import { Locale } from "models/Countries";
import { UserModel } from "models/User";

import { getAccountTypeOptions, parseDefaultValues } from "./utils/utils";
import { validationSchema } from "./utils/validationSchema";
import useBankAccount from "./hooks/useBankAccount";
import { FormProps } from "./utils/types";
import FormHeader from "../FormHeader";

interface Props {
  bankAccountData: BankAccount;
  bankList: IBank[];
  country: Locale;
  documentTypeList: DocumentTypes[];
  user: UserModel;
  propertyId: number;
}

const Form = ({
  bankAccountData,
  country,
  bankList,
  documentTypeList,
  user,
  propertyId,
}: Props) => {
  const { t } = useTranslation(["landlord.bank-account", "landlord"]);
  const { returnToMainPage } = useCustomDrawer();
  const { mutate, isLoading } = useBankAccount(
    !bankAccountData,
    country,
    user.id,
    propertyId,
    user.email,
    returnToMainPage
  );

  const methods = useForm({
    resolver: yupResolver(validationSchema(documentTypeList, country)),
    reValidateMode: "onBlur",
    defaultValues: parseDefaultValues(bankAccountData, country, bankList),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
    getValues,
  } = methods;

  const onSubmit: SubmitHandler<FormProps> = (data) => {
    if (isLoading) return;
    mutate(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentDrawer contentClassName="flex flex-col gap-4">
          <FormHeader
            title={t("BANK_ACCOUNT_FORM_TITLE")}
            subTitle={t("BANK_ACCOUNT_FORM_SUBTITLE")}
          />
          <FieldInput
            id="fullName"
            label={t("LABEL_FULL_NAME")}
            required
            errorMessage={errors.fullName?.message}
            form="novalidateform"
            {...register("fullName")}
          />
          {country === "co" && (
            <FieldSelect
              id="documentType"
              label={t("LABEL_DOCUMENT_TYPE")}
              required
              errorMessage={errors.documentType?.message}
              placeholder="Selecciona un documento"
              options={documentTypeList.map((document) => ({
                label: document.label,
                value: document.identifier,
              }))}
              form="novalidateform"
              {...register("documentType")}
            />
          )}
          {getValues("documentType") && (
            <FieldInput
              id="document"
              label={
                t("DOCUMENT_TYPE_LIST", {
                  returnObjects: true,
                  ns: "landlord",
                })[country][watch("documentType")]
              }
              required
              errorMessage={errors.document?.message}
              form="novalidateform"
              {...register("document")}
              onChange={(e) => {
                const formatDocument = e.target.value.toUpperCase();
                if (country === "cl")
                  setValue("document", format(e.target.value));
                if (country === "mx") setValue("document", formatDocument);
              }}
            />
          )}
          <FieldSelect
            id="bankCode"
            label={t("LABEL_BANK")}
            required
            errorMessage={errors.bankCode?.message}
            placeholder="Selecciona un banco"
            options={bankList.map((bank) => ({
              label: bank.name,
              value: bank.bankId,
            }))}
            {...register("bankCode")}
            form="novalidateform"
          />
          {country !== "mx" && (
            <FieldSelect
              id="accountType"
              label={t("LABEL_ACCOUNT_TYPE")}
              required
              errorMessage={errors.accountType?.message}
              placeholder="Selecciona el tipo de cuenta"
              options={getAccountTypeOptions(country)}
              form="novalidateform"
              {...register("accountType")}
            />
          )}
          {country === "mx" && (
            <FieldInput
              id="clabeNumber"
              label={t("LABEL_CLABE_NUMBER")}
              required
              type="number"
              min="18"
              max="18"
              step="1"
              errorMessage={errors.clabeNumber?.message}
              form="novalidateform"
              {...register("clabeNumber")}
            />
          )}
          <FieldInput
            id="accountNumber"
            label={t("LABEL_ACCOUNT_NUMBER")}
            required
            type="number"
            min="0"
            step="1"
            onCopy={(e) => e.preventDefault()}
            onPaste={(e) => e.preventDefault()}
            errorMessage={errors.accountNumber?.message}
            form="novalidateform"
            {...register("accountNumber")}
          />
          <FieldInput
            id="numberValidation"
            label={t("LABEL_VALIDATE_ACCOUNT")}
            required
            type="number"
            min="0"
            step="1"
            onPaste={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
            errorMessage={errors.numberValidation?.message}
            form="novalidateform"
            {...register("numberValidation")}
          />
        </ContentDrawer>
        <CallToActionDrawer>
          <Button
            isLoading={isLoading}
            variant="primary"
            size="md"
            type="submit"
          >
            Guardar
          </Button>
        </CallToActionDrawer>
      </form>
    </FormProvider>
  );
};

export default Form;
