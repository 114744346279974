import { ITenantContractEndBody } from "apps/tenant/utils/types";
import { AxiosResponse } from "axios";
import { BACKEND_URL } from "env";
import { createHttp } from "services/helper";
import {
  ContractEndActionResponse,
  ContractEndResponse,
  ContractResponse,
  ContractsResponse,
} from "./types";

export default {
  getContract(
    token: string
  ): Promise<AxiosResponse<ContractResponse | ContractResponse[]>> {
    return createHttp(token).get("/v2/contracts/tenant_contract/");
  },
  getAllContracts(token: string): Promise<AxiosResponse<ContractsResponse[]>> {
    return createHttp(token).get("/v2/contracts/user_contracts/?role=tenant");
  },
  endContract(
    token: string,
    id: number,
    data: ITenantContractEndBody
  ): Promise<AxiosResponse<ContractEndActionResponse>> {
    return createHttp(token).put(
      `/v2/contracts/tenant/${id}/exit_process/begin`,
      data
    );
  },
  getContractEndInfo(
    token: string,
    id: number
  ): Promise<AxiosResponse<ContractEndResponse>> {
    return createHttp(token).get(`/v2/contracts/${id}/exit_process/`);
  },
  setVisitsSchedules(
    token: string,
    data: any
  ): Promise<AxiosResponse<{ status: 200 }>> {
    return createHttp(token, BACKEND_URL).put(
      `/schedules/v2/property-availability/tenant-set`,
      data
    );
  },
};
