import {
  BankAccountFormData,
  BankAccountFormDataMX,
} from "../../../utils/types";

export const EMPTY_BANK_ACCOUNT: BankAccountFormData = {
  accountNumber: "",
  accountType: "",
  bankId: null,
  document: "",
  email: "",
  name: "",
  numberValidation: "",
};

export const EMPTY_BANK_ACCOUNT_MX: BankAccountFormDataMX = {
  accountNumber: "",
  accountType: "BANK",
  bankId: null,
  clabe: "",
  document: "",
  email: "",
  name: "",
  numberValidation: "",
};

export default EMPTY_BANK_ACCOUNT;
