import { phoneObject } from "utils/yupValidators/phone";
import * as yup from "yup";

const requiredText = "Campo obligatorio";

const partnerDataSchema = yup.object().shape({
  name: yup.string().min(2, "Mínimo dos caracteres").required(requiredText),
  last_name: yup.string().nullable().notRequired(),
  phone: phoneObject
    .typeError("Número de celular inválido")
    .required(requiredText),
  email: yup.string().email("Mail inválido").required(requiredText),
});

export default partnerDataSchema;
