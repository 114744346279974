import { useQuery, UseQueryResult } from "react-query";

import { useGetToken } from "hooks";
import { useHoumSnackBar } from "libs/houm-snackbar";

import { parseServicesRequest } from "./utils/parsers";
import ServicesRequest from "../models/Requests";
import servicesRequestsService from "../services/servicesRequestsService";

const useServicesRequests = () => {
  const { getToken } = useGetToken();
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const {
    data,
    isLoading,
    isError,
    refetch,
  }: UseQueryResult<ServicesRequest[], Error> = useQuery<
    ServicesRequest[],
    Error
  >(
    "servicesRequest",
    async () => {
      const authToken = await getToken();
      const response = await servicesRequestsService.getRepairsRequests(
        authToken
      );
      return parseServicesRequest(response.data);
    },
    {
      onError: () => {
        enqueueHoumSnackBar({
          body: "Ha ocurrido un error obteniendo las solicitudes de regularización de tu propiedad",
          type: "error",
        });
      },
    }
  );

  return { data, isLoading, isError, refetch };
};

export default useServicesRequests;
