import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { FieldProp } from "context/closureFormContext/closureFormFields";
import { DocumentOptionProps } from "context/closureFormContext/closureFormTypes";
import { FilteredSelection, FilteredTextField } from "./FilteredComponents";

interface Props {
  documentTypeField: FieldProp;
  documentTypeState: number | "0";
  documentTypes: DocumentOptionProps[];
  documentField: FieldProp;
  documentState: string;
  handleDocumentTypeChange: (type: number) => void;
}

export default function LandlordDocuments({
  documentTypeField,
  documentTypeState,
  documentTypes,
  documentField,
  documentState,
  handleDocumentTypeChange,
}: Props) {
  const { control } = useFormContext();

  const type = useWatch({
    control,
    name: "documentType",
    defaultValue: documentTypeState,
  });

  useEffect(() => handleDocumentTypeChange(parseInt(type)), [type]);

  return (
    <>
      <FilteredSelection
        id="documentType"
        fieldInfo={documentTypeField}
        defaultValue={documentTypeState}
        options={documentTypes}
        selectionProps={{
          compressed: true,
          simple: true,
        }}
      />
      <FilteredTextField
        id="document"
        fieldInfo={documentField}
        defaultValue={documentState}
      />
    </>
  );
}
