import { useParams } from "react-router-dom";

import { Box } from "@material-ui/core";

import useUser from "apps/account/hooks/useUser";
import Spinner from "Components/Shared/partials/Spinner";

import { Submenus } from "../../utils/types";
import EmailForm from "../EmailForm";
import PersonalInfo from "../PersonalInfo";
import PhoneForm from "../PhoneForm";
import PasswordForm from "../PasswordForm";
import SideMenu from "./components/SideMenu";
import useDesktopMenuStyles from "./DesktopMenuStyles";

const DesktopMenu = () => {
  const classes = useDesktopMenuStyles();
  const { submenu } = useParams<{ submenu: Submenus }>();
  const { user, isLoading } = useUser();

  if (isLoading) return <Spinner />;

  return (
    <Box className={classes.desktopMenuRoot}>
      <SideMenu selected={submenu || "personal_info"} />
      {(!submenu || submenu === "personal_info") && (
        <PersonalInfo
          name={user.name}
          lastName={user.lastName}
          secondLastName={user.secondLastName}
        />
      )}
      {submenu === "phone" && (
        <PhoneForm
          country={user.phone.country.countryCode}
          phone={user.phone.phone}
        />
      )}
      {submenu === "email" && <EmailForm email={user.email} />}
      {submenu === "password" && <PasswordForm />}
    </Box>
  );
};

export default DesktopMenu;
