import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  propertyDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(0.5),
    flexWrap: "wrap",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
      flexDirection: "row",
      gap: theme.spacing(1, 2),
    },
  },
  propertyDetailsText: {
    fontSize: theme.typography.pxToRem(12),
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  roomsGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconAndDetail: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    fontSize: "1rem",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0),
      width: "auto",
    },
  },
}));

export default useStyles;
