import NavbarDesktop from "Components/Layout/GlobalHeader/components/NavbarDesktop";
import { isMobile } from "react-device-detect";
import NavbarMobile from "./components/NavbarMobile";

interface Props {
  handleBack: () => void;
  backText?: string;
}

const BackButtonHeader = ({ handleBack, backText = "Volver" }: Props) => {
  if (isMobile)
    return <NavbarMobile handleBack={handleBack} backText={backText} />;
  return <NavbarDesktop />;
};

export default BackButtonHeader;
