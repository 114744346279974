import { makeStyles } from "@material-ui/core";

const useDialogContrySelectStyles = makeStyles((theme) => ({
  container: {
    overflowY: "scroll",
    height: "700px",
  },
  header: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: theme.spacing(4, 6, 2, 6),
  },
  closeButton: {
    padding: 0,
  },
}));
export default useDialogContrySelectStyles;
