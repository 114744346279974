import FieldInput from "@houm-com/ui/FieldInput";
import Button from "@houm-com/ui/Button";

import Spinner from "Components/Shared/partials/Spinner";
import useTermsAndConditionsStyles from "./TermsAndConditionsStyles";
import useTermsAndConditions from "./feats/useTermsAndConditions";

const TermsAndConditions = () => {
  const classes = useTermsAndConditionsStyles();
  const {
    termsLink,
    document,
    inputError,
    onInputChange,
    onSubmit,
    isAcceptLoading,
    isDataLoading,
    isAccepted,
  } = useTermsAndConditions();
  const isButtonDisabled =
    !document || isAccepted || isAcceptLoading || isDataLoading;
  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Actualización de cláusulas</h1>
      <p className={classes.description}>
        Estas nuevas cláusulas fueron implementadas para resguardar de mejor
        manera los intereses de nuestros clientes propietarios en lo que dice
        relación con siniestralidad, mora y cobranza a sus inquilinos.
      </p>
      <FieldInput
        id="document"
        label="Ingresa tu RUT para indicar que aceptas los cambios"
        required
        placeholder="RUT"
        value={document}
        errorMessage={inputError}
        form="novalidateform"
        onChange={onInputChange}
      />
      {isDataLoading ? (
        <Spinner />
      ) : (
        <iframe
          className={classes.iframe}
          title="Terminos y condiciones"
          src={termsLink}
        ></iframe>
      )}
      <div className={classes.buttonContainer}>
        <Button
          isLoading={isAcceptLoading}
          variant="primary"
          size="md"
          disabled={isButtonDisabled}
          onClick={() => onSubmit()}
          data-testid="accept-button"
        >
          Aceptar
        </Button>
      </div>
    </div>
  );
};

export default TermsAndConditions;
