import { makeStyles, Theme } from "@material-ui/core/";
import {
  MOBILE_HEADER_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
} from "../../constants/layout";

const useStyles = makeStyles<Theme>((theme) => ({
  contentRoot: {
    maxWidth: 1280,
    margin: theme.spacing(MOBILE_HEADER_HEIGHT / 8, "auto", 0),
    padding: theme.spacing(5, 2),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      minHeight: `calc(100vh - ${DESKTOP_HEADER_HEIGHT}px)`,
      margin: theme.spacing(DESKTOP_HEADER_HEIGHT / 8, "auto", 0),
      padding: theme.spacing(5),
    },
  },
}));

export default useStyles;
