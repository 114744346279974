import { Divider, Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import useDefaultPlanCardContentStyles from "./DefaultPlanCardContentStyles";

interface Props {
  isHorizontal?: boolean;
  brokerageCommissionText: string;
  administrationCommissionText: string;
  operationalCost?: string;
  handleClick?: () => void;
  isMainPlan?: boolean;
}

export default function DefaultPlanCardContent({
  isHorizontal,
  brokerageCommissionText,
  administrationCommissionText,
  operationalCost,
  handleClick,
  isMainPlan,
}: Props) {
  const classes = useDefaultPlanCardContentStyles({ isHorizontal });
  // eslint-disable-next-line react/no-unstable-nested-components
  const RenderFooterInfo = ({
    text,
    priceText,
  }: {
    text: string;
    priceText: string;
  }) => (
    <div className={classes.footerText}>
      <Typography className={classes.text}>{text}</Typography>
      <Typography className={classes.priceDescription}>
        <strong className={classes.price}>{priceText}</strong>
      </Typography>
    </div>
  );

  return (
    <>
      <Divider
        orientation={isHorizontal ? "vertical" : "horizontal"}
        className={classes.divider}
      />
      <Grid item className={classes.cardFooter}>
        <RenderFooterInfo
          text="Comisión corretaje"
          priceText={brokerageCommissionText}
        />
        {administrationCommissionText && (
          <RenderFooterInfo
            text="Comisión administración"
            priceText={administrationCommissionText}
          />
        )}
        {operationalCost && (
          <RenderFooterInfo
            text="Gastos operacionales"
            priceText={operationalCost}
          />
        )}
        {handleClick && (
          <>
            <Divider className={classes.divider} />
            <div className={classes.buttonContainer}>
              <DesignSystemButton
                variant={isMainPlan ? "primary" : "tertiaryA"}
                label="Seleccionar Plan"
                size="large"
                onClick={handleClick}
              />
            </div>
          </>
        )}
      </Grid>
    </>
  );
}
