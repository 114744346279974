import { ApplicationModel } from "apps/lead/models/Application";
import { Application } from "apps/lead/services/ApplicationsService/types";

const frontendApplicationParser = (
  applicationData: Partial<Application>
): Partial<ApplicationModel> => ({
  id: applicationData?.id,
  createdAt: applicationData?.created_at,
  property: {
    uid: applicationData.property?.uid,
    id: applicationData.property?.id,
    address: applicationData.property?.address,
    comuna: applicationData.property?.comuna,
    region: applicationData.property?.region,
    type: applicationData.property?.type,
    city: applicationData.property?.city,
    country: applicationData.property?.country,
    numeroDepto: applicationData.property?.numero_depto,
    streetNumber: applicationData.property?.street_number,
    photoUrl: applicationData.property?.photo_url,
    propertyDetails: applicationData.property.property_details.map(
      (detail) => ({
        valor: detail?.valor,
        typeMoney: detail?.type_money,
        promotionalPrice: detail?.promotional_price,
      })
    ),
  },
  houmer: {
    name: applicationData?.houmer ? applicationData?.houmer?.name : null,
  },
});

export default frontendApplicationParser;
