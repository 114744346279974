import { FC, SVGProps } from "react";

const ArmchairIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.58551 13.8324V18.7438C5.58551 18.8593 5.67888 18.9528 5.79406 18.9528H19.1411C19.2563 18.9528 19.3496 18.8593 19.3496 18.7438V13.8324M17.4727 18.9528V11.6379M7.56671 18.9528V11.6379M17.2642 15.8701H7.67098M17.2642 13.8846H7.67098M17.4727 11.4812C17.8183 10.7282 18.8292 9.582 20.3637 10.1772C20.8962 10.3838 21.3218 10.8348 21.4463 11.3932C21.645 12.2846 21.3614 13.385 19.4018 13.7279M7.5273 11.4812C7.18168 10.7282 6.17083 9.582 4.6363 10.1772C4.10379 10.3838 3.67816 10.8348 3.55366 11.3932C3.35495 12.2846 3.63857 13.385 5.59824 13.7279M6.73252 10.3317V6.25618C6.73252 6.14075 6.82589 6.04718 6.94107 6.04718H18.0462C18.1614 6.04718 18.2548 6.14075 18.2548 6.25618V10.3317"
      stroke="#263238"
      strokeWidth={1.6}
      strokeLinecap="round"
    />
  </svg>
);
export default ArmchairIcon;
