import { makeStyles, Theme } from "@material-ui/core";

const usePublishPropertyReminderStyles = makeStyles<Theme>((theme) => ({
  reminderText: {
    fontSize: theme.typography.pxToRem(16),
    textAlign: "center",
  },
  reminderContainer: {
    margin: theme.spacing(3, 0, 2.5, 0),
  },
  linkText: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.5,
    color: theme.palette.primary.main,
    textDecoration: "underline",
    textUnderlinePosition: "under",
    "&:active": {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    fontSize: theme.typography.pxToRem(18),
  },
}));

export default usePublishPropertyReminderStyles;
