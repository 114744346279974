import {
  State,
  Action,
  SET_LOADING,
  CHANGE_COUNTRY,
  SET_LANDLORD_VIP,
  SET_LANDLORD_VIP_FILTER,
} from "./landlordVipTypes";

export const initialState: State = {
  loading: true,
  landlordVipList: [],
  country: "chile",
  numberOfLandlordVip: null,
  filters: {
    executiveEmail: null,
    size: 0,
    page: 0,
  },
};

const apiReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_LOADING: {
      return { ...state, loading: action.loading };
    }
    case CHANGE_COUNTRY: {
      return { ...state, country: action.country };
    }
    case SET_LANDLORD_VIP: {
      return {
        ...state,
        loading: false,
        landlordVipList: action.payload.landlordVipList,
        numberOfLandlordVip: action.payload.count,
      };
    }
    case SET_LANDLORD_VIP_FILTER: {
      return {
        ...state,
        filters: action.filters,
      };
    }
    default: {
      return state;
    }
  }
};

export default apiReducer;
