import { format } from "date-fns";
import { View, Image, Text } from "@react-pdf/renderer";

import useInspectionReportDocumentStyles from "../InspectionReportDocumentStyles";
import { getResizeImageURL } from "../utils";

import { ReportActionPage as ReportActionPageProps } from "../types";

const ReportActionPage = ({
  feature,
  observation,
  space,
  photos,
  reportStatus,
  createdAt,
  reportId,
}: ReportActionPageProps) => {
  const styles = useInspectionReportDocumentStyles;
  return (
    <View style={styles.pageContent}>
      <View wrap style={styles.suggestedChanges}>
        <View wrap style={styles.suggestedChangesItem}>
          <Text style={styles.sectionTitle}>Daños reportados en {space}</Text>
        </View>
      </View>
      <View wrap style={styles.suggestedChanges}>
        <View style={styles.suggestedChangesSummaryDetails}>
          <Text style={styles.sectionTitle}>{feature}</Text>
        </View>
        <View wrap style={styles.suggestedChangesSummaryDetails}>
          <View style={styles.keyValSection}>
            <Text style={styles.itemLabel}>ID de reporte: </Text>
            <Text style={styles.itemValue}>{reportId}</Text>
          </View>
          <View style={styles.keyValSection}>
            <Text style={styles.itemLabel}>Estado del reporte: </Text>
            <Text style={styles.itemValue}>{reportStatus}</Text>
          </View>
          <View style={styles.keyValSection}>
            <Text style={styles.itemLabel}>Cambios registrados: </Text>
            <Text style={styles.itemValue}>
              {format(new Date(createdAt), "dd/MM/yyyy HH:mm")}
            </Text>
          </View>
          <Text style={styles.itemValue}>{observation}</Text>
          <View wrap style={styles.actionsImagesSection}>
            {photos.map((photoLink, index) => {
              const isOdd = index % 2 !== 0;
              const isNewPageIdx = index === 4 || index === 5;
              return (
                <Image
                  style={{
                    ...styles.actionsImage,
                    ...(isOdd && styles.oddSpaceItem),
                    ...(isNewPageIdx && styles.newPageImage),
                  }}
                  source={getResizeImageURL(photoLink)}
                />
              );
            })}
          </View>
        </View>
      </View>
    </View>
  );
};

export default ReportActionPage;
