import { makeStyles, Theme } from "@material-ui/core";

const useErrorViewStyles = makeStyles((theme: Theme) => ({
  errorViewRoot: {
    width: "100%",
    padding: theme.spacing(4),
    textAlign: "center",
  },
}));

export default useErrorViewStyles;
