import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(26),
    lineHeight: theme.typography.pxToRem(34),
    fontWeight: 700,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 400,
  },
  selectorContainer: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  ctaButton: {
    fontSize: theme.typography.pxToRem(14),
  },
  icon: {
    transform: "rotate(-45deg)",
  },
}));
