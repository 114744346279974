import { FC, SVGProps } from "react";

const MoneyIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <circle cx={12.5} cy={12.5} r={8} stroke="#263238" strokeWidth={1.7} />
    <path
      fill="#263238"
      d="M12.55 17.15a.443.443 0 0 1-.339-.14.447.447 0 0 1-.132-.33v-.64a4.414 4.414 0 0 1-1.026-.179 4.63 4.63 0 0 1-.762-.33.538.538 0 0 1-.273-.329.652.652 0 0 1 .028-.395.457.457 0 0 1 .255-.264c.119-.05.26-.028.423.066.176.1.405.198.687.292.289.088.634.132 1.035.132.47 0 .81-.082 1.017-.245.207-.17.31-.38.31-.63a.657.657 0 0 0-.216-.509c-.144-.131-.402-.238-.772-.32l-.998-.216c-1.135-.245-1.703-.84-1.703-1.788 0-.527.179-.97.537-1.327.363-.364.85-.584 1.458-.66v-.667c0-.132.044-.242.132-.33a.443.443 0 0 1 .339-.141c.132 0 .241.047.33.141a.432.432 0 0 1 .14.33v.658c.251.026.515.085.791.18.282.093.527.222.734.385a.497.497 0 0 1 .141.668.443.443 0 0 1-.273.207c-.119.032-.26 0-.423-.094a2.79 2.79 0 0 0-1.421-.358c-.402 0-.719.092-.95.274a.84.84 0 0 0-.34.696c0 .213.07.386.207.518.138.125.374.226.706.3l1.026.227c.602.131 1.045.342 1.327.63.283.283.424.656.424 1.12 0 .527-.182.96-.546 1.3-.358.332-.825.542-1.402.63v.668a.447.447 0 0 1-.132.33.443.443 0 0 1-.34.14Z"
    />
  </svg>
);
export default MoneyIcon;
