import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  multipleCardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  downloadContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  downloadButton: {
    display: "flex",
    gap: theme.spacing(0.5),
    fontWeight: 700,
    padding: theme.spacing(1, 4),
    cursor: "pointer",
    border: "1px solid black",
    borderRadius: theme.spacing(10),
    textTransform: "none",
    height: "fit-content",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      alignItems: "center",
      margin: theme.spacing(1, 0),
    },
  },
  padding: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  changePriceContainer: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 0),
    },
  },
  changePriceText: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
  },
  changePriceLink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.dark,
    },
  },
  propertyPriceContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  infoContainer: {
    marginTop: theme.spacing(1),
    borderRadius: 8,
    backgroundColor: "transparent",
    padding: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(0),
      gap: 24,
    },
  },
  statsContainer: {
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
      gap: 24,
    },
  },
  card: {
    height: "auto",
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.58,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
}));

export default useStyles;
