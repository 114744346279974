import { useEffect } from "react";
import { FormProvider } from "react-hook-form";

import { Box, Theme, useMediaQuery } from "@material-ui/core";

import {
  ControlledOptions,
  ControlledTextField,
} from "Components/Admin/UIComponents/ControlledForm";
import Spinner from "Components/Shared/partials/Spinner";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import { useProperty } from "apps/landlord/hooks";
import { PropertyBusinessType } from "apps/landlord/models/Property";
import useChangePropertyVisibility from "apps/landlord/hooks/propertyVisibilityStatus/useChangePropertyVisibility";
import useUnpublishPropertyStyles from "../PropertyStatusManagerButtonStyles";

interface PropertyStatusManagerFormProps {
  mode: PropertyBusinessType;
  onClose: () => void;
}

const PropertyStatusManagerForm = ({
  mode,
  onClose,
}: PropertyStatusManagerFormProps) => {
  const {
    propertyData: { id: propertyId },
  } = useProperty();
  const classes = useUnpublishPropertyStyles();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const {
    reasonId,
    formMethods,
    onSubmitForm,
    operationReasons,
    isLoadingOperationReasons,
    isLoadingUnpublishPropertyAction,
    isSuccessUnpublishPropertyAction,
  } = useChangePropertyVisibility({
    mode,
    propertyId,
  });

  //TODO: update these values on a enum once backend updates the values
  const selectedOtherReason = reasonId === "1007" || reasonId === "1001";

  useEffect(() => {
    if (isSuccessUnpublishPropertyAction) {
      onClose();
    }
  }, [isSuccessUnpublishPropertyAction]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmitForm}>
        {isLoadingOperationReasons && <Spinner />}
        {!!operationReasons.length && (
          <ControlledOptions
            id="reasonId"
            options={operationReasons}
            optionClassName={classes.dialogOption}
          />
        )}
        {selectedOtherReason && (
          <ControlledTextField
            label="Cuéntanos cuál es el motivo"
            id="observation"
            textFieldProps={{
              multiline: true,
              minRows: 1,
              InputProps: {
                className: classes.textArea,
              },
            }}
          />
        )}
        <Box className={classes.formButtonsWrapper}>
          <DesignSystemButton
            label="Cancelar"
            variant="tertiaryB"
            size={isMobile ? "small" : "medium"}
            onClick={onClose}
          />
          <DesignSystemButton
            label="Continuar"
            variant="primary"
            size={isMobile ? "small" : "medium"}
            type="submit"
            disabled={!reasonId}
            isLoading={isLoadingUnpublishPropertyAction}
          />
        </Box>
      </form>
    </FormProvider>
  );
};

export default PropertyStatusManagerForm;
