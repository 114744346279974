import { format } from "date-fns";
import { es } from "date-fns/locale";

export const actualMonth = format(new Date(), "MMMM", { locale: es });
export const actualDay = format(new Date(), "d", { locale: es });

export const getSubtitle = (paymentLandlordStatus, paymentLandlordDate) => {
  if (paymentLandlordStatus === "PAID")
    return `Fecha de pago ${paymentLandlordDate}`;
  if (Number(actualDay) <= 5)
    return "Tu arrendatario tiene hasta el día 5 para hacer el pago";
  return null;
};
