import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>((theme) => ({
  group: {
    border: `1px solid #515B60`,
    display: "flex",
    width: "100%",
    borderRadius: 4,
    [theme.breakpoints.up("sm")]: {
      width: 280,
    },
  },
  groupedHorizontal: {
    "&:not(:last-child)": {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    "&:not(:first-child)": {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
  },
  labelSelected: {
    color: "#FFF",
    fontWeight: 700,
  },
  button: {
    width: "100%",
    cursor: "pointer",
    border: "none",
    textTransform: "unset",
    padding: theme.spacing(0.5, 2),
    textAlign: "center",
  },
  buttonSelected: {
    backgroundColor: "#263238",
    borderRadius: "4px",
  },
}));
