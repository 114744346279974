import { useMemo } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from "@material-ui/core/Link";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useClosureForm } from "context/closureFormContext/closureFormContext";
import { prevStep } from "context/closureFormContext/closureFormActions";
import CallToAction from "Components/Admin/UIComponents/atoms/CallToAction";

import useStyles from "./CustomCallToActionStyles";
import { Hidden } from "@material-ui/core";

export default function CustomCallToAction() {
  const { state, dispatch } = useClosureForm();
  const handleBack = () => {
    dispatch(prevStep());
  };

  const stepIndex = useMemo(
    () => state.availableSteps.indexOf(state.step),
    [state.step]
  );

  const classes = useStyles();
  return (
    <CallToAction>
      <div className={classes.ctaContent}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.ctaButton}
        >
          {state.submitting ? (
            <CircularProgress size={14} color="secondary" />
          ) : (
            state.navText
          )}
        </Button>
        {stepIndex !== 0 && state.stepMode && (
          <Hidden smUp>
            <Link onClick={handleBack}>
              <span>
                <ArrowBackIosIcon fontSize="inherit" />
                Volver
              </span>
            </Link>
          </Hidden>
        )}
      </div>
    </CallToAction>
  );
}
