import { UseMovementDataProps } from "apps/payments/hooks/useMovementData/useMovementData";
import Layout from "Components/Admin/UIComponents/paymentLayout/Layout";
import OkPerson from "assets/images/okPerson.png";
import { Locale } from "models/Countries";

import { successText } from "./utils/constants";
import PropertyInfo from "../PropertyInfo";

interface Props {
  country: Locale;
  movementData: UseMovementDataProps;
  address: string;
}

const Success = ({ country, movementData, address }: Props) => (
  <Layout
    title={successText[movementData.concept.toLowerCase()][country].layoutTitle}
    label={successText[movementData.concept.toLowerCase()][country].layoutLabel}
    image={OkPerson}
  >
    <div className="flex flex-col gap-6">
      <p className="text-p16 md:text-p18">
        Felicitaciones, {movementData.user.userName},{" "}
        {successText[movementData.concept.toLowerCase()][country].title}
      </p>
      <PropertyInfo movement={movementData} address={address} />
      {successText[movementData.concept.toLowerCase()][country].text && (
        <p className="text-p16 md:text-p18">
          {successText[movementData.concept.toLowerCase()][country].text}
        </p>
      )}
    </div>
  </Layout>
);

export default Success;
