import { format, parse } from "date-fns";
import { camelCase, round } from "lodash";

import { Service, ServiceType } from "apps/tenant/models/PropertyServices";
import { MovementStatus } from "apps/tenant/models/Movement";
import {
  BillsResponse,
  PropertyBillsResponse,
} from "apps/tenant/services/propertyBillsService/types";
import { IRepairsRequestResponse } from "apps/tenant/services/requestsService/types";
import {
  IProperty,
  IPropertyDetails,
  ISalesDetails,
  PropertyType,
} from "apps/tenant/models/Property";
import { IServicesRequestsResponse } from "apps/tenant/services/servicesRequestsService/types";
import ServicesRequest from "apps/tenant/models/Requests";
import { PropertyData } from "models/Property";

import { IRepairsRequest } from "./types";
import { formatPhoneInput, countryCode } from "utils";

export const parseUserData = (user) => ({
  id: user.id,
  name: user.name,
  lastName: user.last_name,
  secondLastName: user.second_last_name,
  rut: user.rut,
  phone: formatPhoneInput(user.phone),
  country: user.country,
  email: user.email,
  role: user.role,
});

const getBillStatus = (data: BillsResponse): MovementStatus => {
  if (
    data.status === null ||
    data.status === "pending" ||
    data.status === "in_process"
  )
    return "review";
  if (data.expired_debt !== 0) return "overdue";
  if (data.total_debt !== 0) return "pending";
  return "paid";
};

export const parseServices = (data: PropertyBillsResponse): Service[] =>
  data.bill_details.map((service) => ({
    amount: service.total_debt,
    clientNumber: service.client_info.external_id,
    expirationDate: new Date(service.due_date),
    id: service._id,
    status: getBillStatus(service),
    type: camelCase(service.company_type) as ServiceType,
  }));
export const parseProperty = (data: PropertyData): IProperty => {
  const dpto = data.numero_depto ? `Dpto ${data.numero_depto}, ` : "";
  const propertyDetails = data.property_details[0];
  const amenities = data?.association_amenities;
  return {
    id: data.id,
    uid: data.uid,
    address: `${dpto}${data.address} ${data.street_number}`,
    country: countryCode(data.country),
    stratus: data?.stratus,
    photos: data.photos,
    apartmentNumber: data.numero_depto,
    streetNumber: data.street_number,
    region: data.region,
    commune: data.comuna,
    forSale: data.for_sale,
    forRental: data.for_rental,
    userRole: data.user?.role,
    type: data.type as PropertyType,
    managementExecutive: data.houm_owner,
    propertyDetails: {
      currencyCode: propertyDetails.currency_code,
      moneyType: propertyDetails.tipo_moneda,
      salesMoneyType: propertyDetails.tipo_moneda_venta,
      status: propertyDetails.status,
      price: propertyDetails.valor,
      salesPrice: propertyDetails.valor_venta,
      firstHandoverDate: propertyDetails.first_available_handover_date,
      totalArea: round(Number(propertyDetails.m_terreno)),
      builtArea: round(Number(propertyDetails.m_construidos)),
      bathrooms: propertyDetails.banos,
      rooms: propertyDetails.dormitorios,
      parkingSlots: propertyDetails.estacionamientos,
      laundryRoom: propertyDetails.has_laundry_room,
      allowsPets: propertyDetails.mascotas,
      furnished: !!propertyDetails.amoblado,
      balcony: propertyDetails.has_balcony,
      age: data.year_built
        ? Number(format(new Date(), "yyyy")) - data.year_built
        : data.antiquity,
      latitude: propertyDetails.latitud,
      longitude: propertyDetails.longitud,
      commonExpenses: propertyDetails?.gc,
    } as IPropertyDetails,
    amenities: {
      hasAllDayVigilance: amenities?.has_all_day_vigilance,
      hasBbqArea: amenities?.has_bbq_area,
      hasCinema: amenities?.has_cinema,
      hasConcierge: amenities?.has_concierge,
      hasElevator: amenities?.has_elevator,
      hasGamesRoom: amenities?.has_games_room,
      hasGourmetSpace: amenities?.has_gourmet_space,
      hasGym: amenities?.has_gym,
      hasLaundry: amenities?.has_laundry,
      hasPartyRoom: amenities?.has_party_room,
      hasPatio: amenities?.has_patio,
      hasPetArea: amenities?.has_pet_area,
      hasPlayground: amenities?.has_playground,
      hasPlayroom: amenities?.has_playroom,
      hasRoofGarden: amenities?.has_roof_garden,
      hasSauna: amenities?.has_sauna,
      hasSteamRoom: amenities?.has_steam_room,
      hasSwimmingPool: amenities?.has_swimming_pool,
    },
    salesDetails: {
      status: data.sales_details.status,
      currencyCode: data.sales_details.currency_code,
      price: data.sales_details.price,
    } as ISalesDetails,
  };
};

const sortByDate = (data) =>
  data.sort(({ createdAt: a }, { createdAt: b }) =>
    a > b ? -1 : a < b ? 1 : 0
  );

export const parseRepairsRequests = (
  data: IRepairsRequestResponse[]
): IRepairsRequest[] =>
  sortByDate(
    data.map((request) => ({
      id: request.pk,
      observation: request.observation,
      repairmentType: request.repairment_type,
      createdAt: parse(
        request.created_at,
        "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'",
        new Date()
      ),
    }))
  );

export const parseServicesRequest = (
  data: IServicesRequestsResponse[]
): ServicesRequest[] =>
  sortByDate(
    data.map((request) => ({
      id: request.pk,
      description: request.description,
      createdAt: parse(
        request.created_at,
        "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'",
        new Date()
      ),
    }))
  );
