import { makeStyles, Theme } from "@material-ui/core";

const useRenderDetailsStyles = makeStyles<Theme>((theme) => ({
  detailsContainer: {
    marginBottom: theme.spacing(2),
  },
  sectionSubtitle: {
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
    fontWeight: 700,
  },
  detailLabel: {
    paddingLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
  },
}));

export default useRenderDetailsStyles;
