import { makeStyles, Theme } from "@material-ui/core";

const useOptionCardStyles = makeStyles((theme: Theme) => ({
  optionCardRoot: {
    padding: theme.spacing(2),
    height: 154,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: "white",
    [theme.breakpoints.up("sm")]: {
      cursor: "pointer",
    },
  },
  iconWrapper: {
    height: 40,
    width: 40,
    marginBottom: theme.spacing(1),
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DAE4E8",
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    textAlign: "center",
  },
}));

export default useOptionCardStyles;
