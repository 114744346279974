import { useEffect, useState } from "react";
import classNames from "classnames";

import { useUser } from "context/userContext";
import useStyles from "./GlobalLayoutStyles";
import GlobalHeader from "../GlobalHeader";
import { detectOps } from "apps/main/utils";
import { AUTH0_ROLES } from "env";
// import { Footer } from "Components/Admin/UIComponents";

const GlobalLayout = ({ children, contentClassName = "" }) => {
  const [isOps, setIsOps] = useState(false);
  const classes = useStyles({ adjustContentPadding: isOps });
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      if (detectOps(user[AUTH0_ROLES])) {
        setIsOps(true);
      }
    }
  }, [user]);

  return (
    <>
      {!isOps && <GlobalHeader />}
      <div className={classNames(classes.content, contentClassName)}>
        {children}
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default GlobalLayout;
