import { IProperty } from "apps/landlord/models/Property";
import { PropertyDetails } from "models/Property";
import {
  BusinessType,
  PriceFormData,
  PropertyDetailsProps,
  SalesPricePayload,
} from "./types";

export const parsePricePayload = ({
  formData,
  propertyDetails,
  propertyData,
  businessType,
}: {
  formData: PriceFormData;
  propertyDetails: PropertyDetails;
  propertyData: IProperty;
  businessType: BusinessType;
}): PropertyDetailsProps => {
  const priceWithoutDots = formData.price.replace(/[^0-9,]/g, "");
  const sanitizedPrice = Number(priceWithoutDots.replace(",", "."));
  const parsedCurrency = formData.currency === "CLF" ? "U.F." : "Pesos";
  const rootPayload = {
    id: propertyDetails.id,
    uid: propertyData.uid,
  };
  if (businessType === "SALE") {
    return {
      ...rootPayload,
      valor_venta: Number(sanitizedPrice),
      tipo_moneda_venta: parsedCurrency,
    };
  }
  return {
    ...rootPayload,
    valor: Number(sanitizedPrice),
    currency_code: formData.currency,
    tipo_moneda: parsedCurrency,
  };
};

export const parseSalesPricePayload = (
  formData: PriceFormData
): SalesPricePayload => {
  const priceWithoutDots = formData.price.replace(/[^0-9,]/g, "");
  const sanitizedPrice = Number(priceWithoutDots.replace(",", "."));
  return {
    price: sanitizedPrice,
    currency_code: formData.currency,
  };
};
