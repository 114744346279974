import { Box, Typography } from "@material-ui/core";

import useDefaulterTagStyles from "./DefaulterTagStyles";

const DefaulterTag = () => {
  const classes = useDefaulterTagStyles();

  return (
    <Box className={classes.tagContainer}>
      <Typography className={classes.label}>Atrasado</Typography>
    </Box>
  );
};

export default DefaulterTag;
