import { EvaluationApplicant } from "apps/lead/models/Application";
import { CountryUpperCase } from "models/Countries";
import { useHistory } from "react-router-dom";

type OptionListType = {
  text: string;
  handleClick: () => void;
};

const useOptions = (
  evaluationData: EvaluationApplicant,
  country: CountryUpperCase
) => {
  const status = evaluationData?.status ?? "Pendiente";
  let optionList: OptionListType[] = [];
  const history = useHistory();

  if (
    evaluationData &&
    evaluationData.persons.length < 3 &&
    (evaluationData.status === "Pendiente" ||
      evaluationData.status === "En revisión")
  ) {
    const emails = evaluationData.persons.map((person) => person.email);
    optionList = [
      {
        text: "Agregar codeudor",
        handleClick: () => {
          history.push({
            pathname: `/tenant/codebtor-form/${evaluationData.id}`,
            state: {
              data: {
                country,
                emails,
              },
            },
          });
        },
      },
    ];
  }

  return {
    optionList,
    status,
  };
};

export default useOptions;
