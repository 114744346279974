import { Box, Typography } from "@material-ui/core";

import useBoldMenuStyles from "./BoldMenuStyles";
import { useHistory } from "react-router-dom";
import { useNavbar } from "../../context/navbarContext";

const BoldMenu = () => {
  const classes = useBoldMenuStyles();
  const history = useHistory();
  const { country } = useNavbar();

  return (
    <Box className={classes.boldMenuRoot}>
      <Typography
        onClick={() =>
          window.open(`https://help.houm.com/${country}`, "_blank")
        }
        className={classes.boldMenuItem}
      >
        Ayuda
      </Typography>
      <Typography
        onClick={() => history.push("/pagar")}
        className={classes.boldMenuItem}
      >
        Pagar arriendo
      </Typography>
    </Box>
  );
};

export default BoldMenu;
