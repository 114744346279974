import { Box } from "@material-ui/core";

import useContentWrapperStyles from "./ContentWrapperStyles";
import clsx from "clsx";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const ContentWrapper = ({ children, className }: Props) => {
  const classes = useContentWrapperStyles();

  return (
    <Box className={clsx(classes.contentWrapperRoot, className)}>
      {children}
    </Box>
  );
};

export default ContentWrapper;
