import { makeStyles, Theme } from "@material-ui/core";

const useApplicantsStyles = makeStyles((theme: Theme) => ({
  applicantsDetails: {
    display: "flex",
    width: "fit-content",
    columnGap: theme.spacing(4),
    rowGap: theme.spacing(1),
  },
}));

export default useApplicantsStyles;
