import EvaluatorLayout from "apps/lead/layout/EvaluatorLayout";
import useEvaluations from "./hooks/useEvaluations";

const UploadFilesForm = () => {
  const { CurrentStep, handleBack } = useEvaluations();

  return (
    <EvaluatorLayout handleBack={handleBack}>{CurrentStep}</EvaluatorLayout>
  );
};

export default UploadFilesForm;
