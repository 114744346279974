import { FormattedAddress } from "models";
import moment from "moment";
import {
  AutoProfileProperty,
  InfoModal,
} from "apps/landlord/pages/CreateProperty/context/proprietaryFormTypes";
import propertyService from "services/propertyService";

import { formatAddress } from "utils/createProperty";
import { INVALID_TYPE, INVALID_LOCATION, INVALID_DATE } from "../constants";

const EXACT_DIRECTION_ERROR =
  "No se pudo encontrar la dirección exacta, por favor prueba con otra.";
const NO_COMMUNE_ERROR =
  "No se pudo encontrar la dirección exacta, por favor ingrese una alcaldía.";

export const validateAddress = (
  address: FormattedAddress,
  district: { label: string; value: string } | null
): { error: true; message: string } | { error: false } => {
  if (
    ["México", "Mexico"].includes(address.country) &&
    !address.commune &&
    !district?.value
  ) {
    return { message: NO_COMMUNE_ERROR, error: true };
  }
  if (!address.streetNumber) {
    return { message: EXACT_DIRECTION_ERROR, error: true };
  }
  return { error: false };
};

const isValidPropertyType = (type: string): boolean => {
  const validTypes = ["flat", "house"];
  return validTypes.includes(type);
};

const isWithinArea = async (
  latitude: number,
  longitude: number,
  authToken: string
): Promise<boolean> => {
  const response = await propertyService.isWithinCoverage(
    latitude,
    longitude,
    authToken
  );
  return response.data.result;
};

const isWithinValidDate = (date: string): boolean => {
  const validMonths = 2;
  const lastDayToBeValid = moment().add(validMonths, "M");
  const selectedDay = moment(date);
  return moment(selectedDay).isBefore(lastDayToBeValid);
};

export const validateData = async (
  data: AutoProfileProperty,
  authToken: string
): Promise<{ error: true; modalInfo: InfoModal } | { error: false }> => {
  if (!isValidPropertyType(data.propertyType)) {
    return { error: true, modalInfo: INVALID_TYPE };
  }
  const fa = formatAddress(data.address);
  if (!isWithinArea(fa.lat, fa.lng, authToken)) {
    return { error: true, modalInfo: INVALID_LOCATION };
  }
  if (!isWithinValidDate(data.date)) {
    return { error: true, modalInfo: INVALID_DATE };
  }
  return { error: false };
};
