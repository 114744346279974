import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  navbar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: theme.spacing(2, 2, 0, 2),
  },
}));

export const useDialogStyles = makeStyles(() => ({
  paperRoot: {
    borderRadius: 20,
    overflow: "hidden",
    zIndex: 20,
  },
}));

export const useButtonStyles = makeStyles(() => ({
  closeButton: {
    zIndex: 1,
    color: "black",
  },
}));

export default useStyles;
