import { makeStyles, Theme } from "@material-ui/core/";

interface Props {
  navbarHeight: number;
}

const useSimpleLayoutStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.grey[50],
    minHeight: ({ navbarHeight }) => `calc(100vh - ${navbarHeight}px)`,
    marginTop: ({ navbarHeight }) => navbarHeight,
    marginX: "auto",
    [theme.breakpoints.up("sm")]: {
      margin: ({ navbarHeight }) => theme.spacing(navbarHeight / 8, "auto", 0),
      minHeight: ({ navbarHeight }) => `calc(100vh - ${navbarHeight}px)`,
    },
    transition: "margin-top 0.1s ease-in-out",
  },
  contentRoot: {
    maxWidth: 1280,
    height: "100%",
    width: "100%",
    margin: theme.spacing(0, "auto"),
  },
}));

export default useSimpleLayoutStyles;
