import axios, { AxiosResponse } from "axios";

import {
  IUserParse,
  IUserResponse,
} from "apps/account/pages/AccountCreate/components/FormCreateAccount/formUtils";

import { BACKEND_URL } from "env";

const http = axios.create({
  baseURL: BACKEND_URL,
});
export default {
  sendEmailVerificationEmail(
    data
  ): Promise<AxiosResponse<{ success: string }>> {
    return http.post("/resend-verification-email", data);
  },

  createUser(data: IUserParse): Promise<AxiosResponse<IUserResponse>> {
    return http.post("/users/", data);
  },
};
