import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import DrawerHeader from "apps/ui/CustomDrawer/DrawerHeader";
import Drawer from "apps/ui/CustomDrawer/Drawer";

import PaymentSelectedOption from "../PaymentSelectedOption";
import PaymentPlatforms from "../PaymentPlatforms";

interface Props {
  open: boolean;
  onHandleDrawer: () => void;
}

const PaymentGateway = ({ open, onHandleDrawer }: Props) => {
  const { currentStep } = useCustomDrawer();

  return (
    <Drawer onHandleClose={onHandleDrawer} open={open}>
      <DrawerHeader onHandleClose={onHandleDrawer} />
      {currentStep === "paymentPlatforms" && <PaymentPlatforms />}
      {currentStep === "paymentSelected" && <PaymentSelectedOption />}
    </Drawer>
  );
};

export default PaymentGateway;
