import { useFeatureManager } from "@houm-com/feature-manager";
import Button from "@houm-com/ui/Button";
import Spinner from "@houm-com/ui/Spinner";
import { Locale } from "models/Countries";

import useEndHoumContract from "../../hooks/useEndHoumContract";

interface Props {
  onHandleClose: () => void;
  contractId: number;
  country: Locale;
}

const EndContractInformation = ({
  onHandleClose,
  contractId,
  country,
}: Props) => {
  const { mutate, isLoading } = useEndHoumContract({
    contractId,
    onCloseDrawer: onHandleClose,
  });
  const { data, status } = useFeatureManager("END_HOUM_MANDATE_INFO", {
    marketCode: country,
  });

  if (status === "loading")
    return (
      <Spinner
        variant="primary"
        size="xl"
        classNameContainer="flex justify-center"
      />
    );

  return (
    <>
      <div className="flex flex-col gap-2">
        <h1 className="!text-h28">{data?.content.title}</h1>
        <div className="flex flex-col gap-4">
          <p className="text-p16">{data?.content.text}</p>
          <p className="text-p16 text-information-100">
            {data?.content.disclaimer}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Button
          size="md"
          variant="primary"
          className="max-w-full md:max-w-fit justify-center"
          onClick={() => mutate()}
          isLoading={isLoading}
        >
          {data?.content.submitButton}
        </Button>
        <Button
          size="md"
          variant="tertiary"
          className="max-w-full md:max-w-fit justify-center"
          onClick={onHandleClose}
        >
          {data?.content.cancelButton}
        </Button>
      </div>
    </>
  );
};

export default EndContractInformation;
