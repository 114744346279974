import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Collapse from "@material-ui/core/Collapse";

import { useClosureForm } from "context/closureFormContext/closureFormContext";
import fields from "context/closureFormContext/closureFormFields";
import { MARITAL_REGIMES } from "context/closureFormContext/constants";

import { FilteredSelection } from "../FilteredComponents";

const MaritalRegimeSelector = () => {
  const { state } = useClosureForm();
  const { watch, setValue } = useFormContext();

  const stepState = state.landlordData;
  const stepFields = fields.landlord;

  useEffect(() => {
    if (watch("civilStatus") !== "married") {
      setValue("maritalRegime", "not_apply");
    } else {
      setValue("maritalRegime", "other");
    }
  }, [watch("civilStatus")]);

  return (
    <Collapse in={watch("civilStatus") === "married"}>
      <FilteredSelection
        id="maritalRegime"
        fieldInfo={stepFields.maritalRegime()}
        defaultValue={stepState.maritalRegime}
        options={MARITAL_REGIMES}
        selectionProps={{
          compressed: true,
          simple: true,
        }}
      />
    </Collapse>
  );
};

export default MaritalRegimeSelector;
