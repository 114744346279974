import usePrivacyPoliciesStyles from "./PrivacyPoliciesStyles";

interface Props {
  locale: string;
}

const PrivacyPolicies = ({ locale }: Props) => {
  const classes = usePrivacyPoliciesStyles();

  const getUrl = (locale: string) => {
    const url = {
      mx: () =>
        "https://ayuda.houm.com/hc/es-419/articles/360060530972-Pol%C3%ADtica-de-Privacidad",
      co: () => "https://ayuda.houm.com/hc/es-419/articles/360049464512",
      cl: () => "https://ayuda.houm.com/hc/es-419/articles/360047723631",
    };
    return url[locale];
  };
  const url = getUrl(locale);

  return (
    <a target="_blank" href={url} className={classes.link}>
      política de privacidad
    </a>
  );
};

export default PrivacyPolicies;
