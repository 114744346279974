import { makeStyles, Theme } from "@material-ui/core";

const useAmenitiesRowStyles = makeStyles((theme: Theme) => ({
  row: {
    display: "flex",
    gap: theme.spacing(2),
  },
  item: {
    display: "flex",
    gap: theme.spacing(0.5),
    alignItems: "center",
  },
  itemText: {
    fontSize: theme.typography.pxToRem(14),
    color: "#FFF",
  },
  icon: {
    color: "#FFF",
    width: 18,
    height: 18,
    "& path": {
      stroke: "#FFF",
    },
  },
}));

export default useAmenitiesRowStyles;
