import React from "react";
import { useQuery, UseQueryResult } from "react-query";

import { Menu } from "@material-ui/core";
import { IoChevronDownOutline, IoChevronUpOutline } from "@houm-com/ui-icons";
import LightButton from "@houm-com/ui/LightButton";
import Button from "@houm-com/ui/Button";

import { IUser, parseUserData } from "apps/account/components/utils";
import { useUser } from "context/userContext";

import useAuthDropdownMenuStyles from "./AuthDropdownMenuStyles";
import { useGetToken } from "hooks/useGetToken";
import userService from "services/userService";
import ItemsMenu from "../AuthItemsMenu";

const AuthDropdownMenu = () => {
  const classes = useAuthDropdownMenuStyles();
  const { user, loginWithRedirect } = useUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { getToken } = useGetToken();

  const { data }: UseQueryResult<IUser, Error> = useQuery<IUser, Error>(
    "user",
    async () => {
      const authToken = await getToken();
      const response = await userService.getV2(authToken);
      return parseUserData(response?.data);
    }
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  if (!user) {
    return (
      <Button variant="primary" size="sm" onClick={() => loginWithRedirect()}>
        Iniciar sesión
      </Button>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <div className={classes.content}>
        <LightButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          variant="secondary"
          size="md"
          icon={
            open ? (
              <IoChevronUpOutline className="w-[16px] h-[16px]" />
            ) : (
              <IoChevronDownOutline className="w-[16px] h-[16px]" />
            )
          }
        >
          {`${data.name} ${data.lastName}`}
        </LightButton>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        className={classes.menuCustom}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <ItemsMenu handleClose={handleClose} isDropdown />
      </Menu>
    </>
  );
};

export default AuthDropdownMenu;
