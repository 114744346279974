import { Locale } from "models/Countries";
import { Photos } from "models/Property";

export enum ResponseAnswer {
  ACCEPTED = "Aceptada",
  REJECTED = "Rechazada",
}

export enum NegotiationStatus {
  OPEN = "Abierta",
  FINALIZED = "Finalizada",
}

export enum OfferStatus {
  OPEN = "Abierta",
  ACCEPTED = "Aceptada",
  REJECTED = "Rechazada",
}

export enum ReasonsForRejection {
  SOLD = "Propiedad ya se vendió",
  PRICE = "Valor de la oferta no se ajusta a lo requerido",
  ONLY_CASH = "Busco solo ofertas con pago al contado",
  NOT_LONGER_IN_SELL = "Ya no se quiere vender la propiedad",
  OTHER = "Otro",
}
export interface Negotiation {
  id: string;
  status: string;
  activeOfferAmount: number;
  activeOfferPaymentMethod: string;
  activeOfferCurrency: string;
  activeOfferStatus: string;
  offers: Offer[];
}

export interface NegotiationAdmin {
  id: string;
  status: string;
  bidder: Bidder;
  lastBid: LastBid;
  startDate: Date;
}

export interface Bidder {
  name: string;
  lastName: string;
  secondLastName: string;
}

export interface LastBid {
  id: string;
  amount: number;
  paymentMethod: string;
  currency: string;
  status: string;
  createdAt: Date;
}

export interface Property {
  uid: string;
  country: string;
  address: string;
  streetNumber: number;
  flatNumber: string;
  commune: string;
  photos: Photos[];
  salesPrice: number;
  salesCurrency: string;
  region: string;
  type: string;
}

export interface Offer {
  id: string;
  amount: number;
  currency: string;
  status: string;
  paymentMethod: string;
  createdAt: Date;
}

export interface ResponseToOffer {
  response: ResponseAnswer;
  reason?: string;
  lastAmount?: number;
  currency?: string;
}

export type CurrentStep =
  | "negotiationsList"
  | "negotiationDetails"
  | "resumeOffer"
  | "rejectForm"
  | "offerAnswered"
  | "termsAndConditions";

export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const SET_CURRENT_NEGOTIATION = "SET_CURRENT_NEGOTIATION";
export const SET_CURRENT_OFFER = "SET_CURRENT_OFFER";
export const SET_NEGOTIATIONS = "SET_NEGOTIATIONS";
export const SET_LOADING = "SET_LOADING";
export const SET_PROPERTY = "SET_PROPERTY";
export const SET_RESPONSE_TO_OFFER = "SET_RESPONSE_TO_OFFER";
export const SET_COUNTRY_CODE = "SET_COUNTRY_CODE";
export const SET_NEGOTIATIONS_ADMIN = "SET_NEGOTIATIONS_ADMIN";

export interface State {
  loading: boolean;
  countryCode: Locale;
  currentNegotiation: Negotiation;
  currentOffer: Offer;
  property: Property;
  currentStep: CurrentStep;
  negotiations: Negotiation[];
  negotiationsAdmin: NegotiationAdmin[];
  responseToOffer: ResponseToOffer;
}

interface setCurrentStep {
  type: typeof SET_CURRENT_STEP;
  currentStep: CurrentStep;
}

interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}

interface setProperty {
  type: typeof SET_PROPERTY;
  data: Property;
}

interface setNegotiations {
  type: typeof SET_NEGOTIATIONS;
  negotiations: Negotiation[];
}

interface setNegotiationsAdmin {
  type: typeof SET_NEGOTIATIONS_ADMIN;
  negotiationsAdmin: NegotiationAdmin[];
}

interface setResponseToOffer {
  type: typeof SET_RESPONSE_TO_OFFER;
  responseToOffer: ResponseToOffer;
}

interface setCurrentNegotiation {
  type: typeof SET_CURRENT_NEGOTIATION;
  currentNegotiation: Negotiation;
}

interface setCurrentOffer {
  type: typeof SET_CURRENT_OFFER;
  currentOffer: Offer;
}

interface setCountryCode {
  type: typeof SET_COUNTRY_CODE;
  countryCode: Locale;
}

export type Action =
  | setCurrentStep
  | setLoading
  | setProperty
  | setNegotiations
  | setResponseToOffer
  | setCurrentNegotiation
  | setCurrentOffer
  | setCountryCode
  | setNegotiationsAdmin;

export type Dispatch = (action: Action) => void;
