import { Trans } from "react-i18next";
import { Box, Link, Typography } from "@material-ui/core";

import { Locale } from "models/Countries";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji/Emoji";
import { formatDate } from "apps/tenant/utils/utils";

import useConfirmationStepStyles from "./ConfirmationStepStyles";
import LOCAL_BASED_LEAVING_DATE_CONFIRMATION from "./constants";

type Props = {
  handleBack: () => void;
  leavingDate: Date;
  country: Locale;
};

const ConfirmationStep = ({ handleBack, leavingDate, country }: Props) => {
  const classes = useConfirmationStepStyles();
  const formattedDate = formatDate(leavingDate);
  return (
    <>
      <Typography className={classes.title}>
        Has iniciado el proceso de salida
      </Typography>
      <Typography className={classes.subtitle}>
        Te enviamos la confirmación de salida a tu correo registrado.
      </Typography>
      <Typography className={classes.stepTitle}>1. Fecha de entrega</Typography>
      <Typography className={classes.stepDescription}>
        <Trans
          i18nKey={LOCAL_BASED_LEAVING_DATE_CONFIRMATION[country]}
          values={{
            formattedDate,
          }}
          components={{
            1: <strong />,
          }}
        />
      </Typography>
      {country === "co" && (
        <>
          <Typography className={classes.stepTitle}>
            2. Requisitos de entrega y servicios públicos
          </Typography>
          <Typography className={classes.stepDescription}>
            Puedes consultar{" "}
            <Link
              href="https://houm.kustomer.help/es/-HkPXSUAJ2"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              aquí
            </Link>{" "}
            los requisitos para poder entregar tu propiedad así como el estimado
            de servicios públicos que deben estar cubiertos al momento de
            entregar la propiedad.
          </Typography>
        </>
      )}

      <div className={classes.ctaContainer}>
        <DesignSystemButton
          label="Salir"
          variant="primary"
          size="small"
          onClick={handleBack}
        />
      </div>
      <Box className={classes.box}>
        <Typography className={classes.boxTitle}>
          Tenemos muchas más opciones para ti{" "}
          <Emoji emojiIcon="🏡" ariaLabel="casa con jardín" />.
        </Typography>
        <Typography className={classes.boxDescription}>
          Si aún estás en búsqueda de tu nuevo hogar, recuerda que podrás
          encontrarlo en <a href="https://www.houm.com">Houm.com</a>
        </Typography>
      </Box>
    </>
  );
};

export default ConfirmationStep;
