import { Typography } from "@material-ui/core";

import { IoAlertCircleOutline } from "@houm-com/ui-icons";

import useWarningTitleStyles from "./WarningTitleStyles";

const WarningTitle = () => {
  const classes = useWarningTitleStyles();

  return (
    <div className={classes.container}>
      <IoAlertCircleOutline color="#E7C202" role="img" name="warning" />
      <Typography component="h1" className={classes.text}>
        ¡Importante!
      </Typography>
    </div>
  );
};

export default WarningTitle;
