// TO BE DEPRECATED BY useAuthHook.jsx
import axios from "axios";
import { BACKEND_URL } from "../env";

export const getToken = () => console.error("Tried to fetch old token.");

export const createHttp = (
  token,
  baseURL = BACKEND_URL,
  contentType = "application/json",
  cancelToken = undefined
) => {
  const headers = {
    "Content-Type": contentType,
    Authorization: `Bearer ${token || getToken()}`,
  };
  return axios.create({
    baseURL,
    headers,
    cancelToken,
  });
};

export const clientConfig = (
  token: string,
  baseURL = BACKEND_URL,
  contentType = "application/json"
) => ({
  headers: {
    "Content-Type": contentType,
    Authorization: `Bearer ${token || getToken()}`,
  },
  baseURL,
});

export const createNoAuthHttp = (
  baseURL = BACKEND_URL,
  contentType = "application/json"
) => {
  const headers = { "Content-Type": contentType };

  return axios.create({
    baseURL,
    headers,
  });
};
