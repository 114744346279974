import { makeStyles, Theme } from "@material-ui/core";

const extraSmallBreakpoint = "xs";
const defaultBreakpoint = "md";

const useResumeStyles = makeStyles<Theme>((theme) => ({
  customCloseButton: {
    left: "94%",
    top: "-10%",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[900],
    },
    [theme.breakpoints.down(extraSmallBreakpoint)]: {
      left: "90%",
      top: "-8%",
    },
    [theme.breakpoints.up(defaultBreakpoint)]: {
      left: "97%",
      top: "-10%",
    },
  },
}));

export default useResumeStyles;
