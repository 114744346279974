export default (amount: number, locale: string) => {
  /**
   * US locale is used in MX
   */
  if (locale === "mx") {
    return amount.toLocaleString("en-US", { minimumFractionDigits: 2 });
  } else {
    return amount.toLocaleString(`es-${locale.toUpperCase()}`);
  }
};
