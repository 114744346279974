import { Locale } from "models/Countries";
import { SERVICES_URL } from "env";

export const getDefaultByCountry = (country: Locale) => {
  if (country === "mx") return "openPay";
  if (country === "cl") return "otrosPagos";
  return "placeToPay";
};

export const conceptMovement = {
  CONTRACT: "contract",
  OTHER: "other",
  SPORT: "spot",
  RESERVATION: "reservation",
  LEASE: "rental",
};

export const getOtrosPagosLink = (qid: number | string, concept: string) =>
  `${SERVICES_URL}/payments/cl/v3/movements/pay/link?concept=${conceptMovement[concept]}&identifier=${qid}`;
