import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import Spinner from "Components/Shared/partials/Spinner";
import BackButton from "apps/tenant/components/BackButton";
import useRepairsRequests from "apps/tenant/hooks/useRepairsRequests";
import { useNavbar } from "apps/ui/Navbar/context/navbarContext";
import useContractEnd from "apps/tenant/hooks/useContractEnd";
import ExitCard from "apps/tenant/components/ExitCard";
import RequestCard from "apps/tenant/components/RepairRequestCard";
import ServicesRequestCard from "apps/tenant/components/ServicesRequestCard";
import useServicesRequests from "apps/tenant/hooks/useServicesRequests";

import useRequestsPageStyles from "./RequestsPageStyles";

const RequestsPage = () => {
  const classes = useRequestsPageStyles();
  const history = useHistory();
  const { data: repairsRequests, isLoading: isRepairsRequestsLoading } =
    useRepairsRequests();
  const { data: servicesRequests, isLoading: isServicesRequestsLoading } =
    useServicesRequests();
  const { contractEndData, isLoading: isContractEndLoading } = useContractEnd();
  const { setBackUrl } = useNavbar();
  const createRedirect = () => history.push("/tenant/help");

  useEffect(() => {
    setBackUrl("/");
  }, []);

  if (
    isRepairsRequestsLoading ||
    isContractEndLoading ||
    isServicesRequestsLoading
  )
    return <Spinner />;
  return (
    <Box className={classes.container}>
      {!isMobile && (
        <BackButton onClickHandler={() => history.push("/tenant")} />
      )}
      <Box className={classes.header}>
        <Typography className={classes.title}>Solicitudes</Typography>
        {!isMobile && (
          <DesignSystemButton
            label="Crear solicitud"
            size="small"
            variant="primary"
            onClick={createRedirect}
          />
        )}
      </Box>
      <Box className={classes.cards}>
        {contractEndData.hasBegun && <ExitCard contractEnd={contractEndData} />}
        {servicesRequests.map((request) => (
          <ServicesRequestCard servicesRequest={request} key={request.id} />
        ))}
        {repairsRequests.map((request) => (
          <RequestCard repairRequest={request} key={request.id} />
        ))}
      </Box>
      {isMobile && (
        <Box className={classes.ctaContainer}>
          <DesignSystemButton
            label="Crear solicitud"
            size="small"
            variant="primary"
            onClick={createRedirect}
            fullWidth
          />
        </Box>
      )}
    </Box>
  );
};

export default RequestsPage;
