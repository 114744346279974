import { useEffect, useState, useCallback, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { Typography } from "@material-ui/core";
import Step from "@material-ui/core/Step";

import BackNavigation from "apps/landlord/components/BackNavigation";
import { useClosureForm } from "context/closureFormContext/closureFormContext";
import { COUNTRY_STEPS } from "context/closureFormContext/constants";
import {
  fetchData,
  setIsAdmin,
  setStep,
  submitData,
  setSubmitting,
  setNavText,
  setStepMode,
  setAvailableSteps,
  prevStep,
} from "context/closureFormContext/closureFormActions";
import {
  Step as StepType,
  step,
} from "context/closureFormContext/closureFormTypes";
import CustomNavbar from "Components/Admin/UIComponents/atoms/CustomNavbar";
import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { useGetToken, useQuery } from "hooks";

import useStyles, { Connector } from "./ClosureFormStyles";
import PublicServicesStep from "./StepPublicServices";
import PropertyDataStep from "./StepPropertyData";
import LandlordDataStep from "./StepLandlordData";
import ThirdPartyStep from "./StepThirdParty";
import AdminDataStep from "./StepAdminData";
import FilesStep from "./StepFiles";
import SuccessModal from "./SuccessModal";

export default function ClosureForm() {
  const { state, dispatch } = useClosureForm();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const path = useLocation().pathname;
  const [stepTitle, setStepTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const query = useQuery();
  const queryStep = query.get("step");
  const { getToken } = useGetToken();
  const titleRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    getToken().then((authToken) => {
      dispatch(setIsAdmin(path.includes("admin")));
      fetchData(id, authToken, dispatch);
    });
  }, []);

  useEffect(() => {
    if (queryStep) {
      dispatch(setStepMode(false));

      if (step.includes(queryStep)) {
        dispatch(setStep(queryStep as StepType));
      }
    } else {
      dispatch(setStepMode(true));
    }
  }, [queryStep]);

  useEffect(() => {
    if (
      state.propertyData.country &&
      COUNTRY_STEPS[state.propertyData.country]
    ) {
      dispatch(setAvailableSteps(COUNTRY_STEPS[state.propertyData.country]));
    }
  }, [state.propertyData.country]);

  const handleBack = () => {
    const currentStepIndex = step.indexOf(state.step);
    if (currentStepIndex === 0) {
      history.goBack();
    } else {
      dispatch(prevStep());
    }
  };

  const currentStep = useCallback(() => {
    switch (state.step) {
      case "landlordData":
        return <LandlordDataStep />;
      case "thirdPartyData":
        return <ThirdPartyStep />;
      case "publicServices":
        return <PublicServicesStep />;
      case "adminData":
        return <AdminDataStep />;
      case "fileLinks":
        return <FilesStep />;
      case "propertyData":
      default:
        return <PropertyDataStep />;
    }
  }, [state.step]);

  useEffect(() => {
    // Define step title
    switch (state.step) {
      case "landlordData":
        setStepTitle("Datos propietario");
        break;
      case "thirdPartyData":
        setStepTitle("Datos tercero autorizado para restituir el inmueble");
        break;
      case "publicServices":
        setStepTitle("Datos servicios públicos del inmueble a arrendar");
        break;
      case "adminData":
        setStepTitle("Datos administración");
        break;
      case "fileLinks":
        setStepTitle("Documentos a adjuntar");
        break;
      case "propertyData":
      default:
        setStepTitle("Datos propiedad");
        break;
    }

    // Define navbar button
    if (state.stepMode) {
      if (
        state.availableSteps.indexOf(state.step) ===
        state.availableSteps.length - 1
      ) {
        dispatch(setNavText("Enviar"));
      } else {
        dispatch(setNavText("Continuar"));
      }
    } else {
      dispatch(setNavText("Enviar"));
    }

    // scroll to top
    if (titleRef.current) {
      titleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [state.step]);

  useEffect(() => {
    if (state.readyToSubmit === state.step) {
      dispatch(setSubmitting(true));
      (async () => {
        try {
          const authToken = await getToken();
          await submitData(authToken, state, dispatch);
          if (
            state.availableSteps.indexOf(state.step) ===
              state.availableSteps.length - 1 ||
            !state.stepMode
          ) {
            setShowModal(true);
          }
        } catch (e) {
          console.error(e);
          Swal.fire({
            type: "error",
            text: `No se pudieron subir los ${stepTitle.toLowerCase()}`,
          });
        } finally {
          dispatch(setSubmitting(false));
        }
      })();
    }
  }, [state.readyToSubmit]);

  const classes = useStyles();
  return (
    <Loading loading={state.loading} className={classes.spinner}>
      <div className={classes.closureFormRoot}>
        {!state.isAdmin && (
          <CustomNavbar onClose={() => window.history.back()} />
        )}
        <div className={classes.closureFormContainer}>
          {state.stepMode && (
            <BackNavigation
              onClick={handleBack}
              propertyId={id}
              className={classes.navigation}
            />
          )}
          {state.stepMode && (
            <Stepper
              activeStep={state.availableSteps.indexOf(state.step)}
              connector={<Connector />}
            >
              {state.availableSteps?.map((stepKey) => (
                <Step key={stepKey}>
                  <StepLabel />
                </Step>
              ))}
            </Stepper>
          )}
          <Typography className={classes.title} ref={titleRef}>
            Formulario documentos
          </Typography>
          <Typography className={classes.subtitle}>
            Asegúrate de que todos los datos sean correctos, ya que se
            utilizarán para la redacción del contrato y del mandato. Todos los
            campos son obligatorios.
          </Typography>
          <div className={classes.form}>
            <Typography className={classes.stepTitle}>{stepTitle}</Typography>
            {currentStep()}
          </div>
        </div>
        <SuccessModal open={showModal} />
      </div>
    </Loading>
  );
}
