import { Grid, Typography } from "@material-ui/core";

import { prevStep } from "context/loiFormContext/loiFormActions";
import { useLoiForm } from "context/loiFormContext/loiFormContext";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import useStyles from "./LoiFormProgressStyles";

export default function LoiFormProgres({ step, totalSteps, stepTitle }) {
  const { dispatch } = useLoiForm();
  const onClick = () => dispatch(prevStep());
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <CircularProgressWithLabel step={step} totalSteps={totalSteps} />
      </Grid>
      <Grid item xs={8}>
        <Typography className={classes.stepName}> Paso {step} </Typography>
        <Typography className={classes.stepTitle}> {stepTitle} </Typography>
      </Grid>
      {step > 1 && (
        <Grid item xs={2}>
          <button
            className={classes.backButton}
            type="button"
            onClick={onClick}
          >
            {" "}
            volver{" "}
          </button>
        </Grid>
      )}
    </Grid>
  );
}
