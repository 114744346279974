import { Typography } from "@material-ui/core";

import { Header } from "Components/Admin/UIComponents/CollapsibleTable/CollapsibleTableTypes";
import TableRowImagePreviewer from "apps/landlord/components/TableRowImagePreviewer";
import RatingStatus from "apps/landlord/components/RatingStatus";
import { getObjectLabel } from "utils";

import InspectionDetailsExpand from "../../InspectionDetailsExpand";
import {
  inspectionReportFeaturesNameTranslation,
  inspectionReportItemsNameTranslation,
} from "./types";

const inspectionReportNameTranslations = [
  ...inspectionReportItemsNameTranslation,
  ...inspectionReportFeaturesNameTranslation,
];

const getTableCols = (classes: { [key: string]: string }): Header[] => [
  {
    id: "photos",
    name: "Imágenes",
    width: "10%",
    renderCell: (value, row) => (
      <TableRowImagePreviewer rowName={row?.name} imagesArr={[row?.photo]} />
    ),
    renderSubCell: (value, item, row) => (
      <TableRowImagePreviewer
        rowName={`${row?.name} | ${getObjectLabel(
          inspectionReportNameTranslations,
          item?.name
        )}`}
        imagesArr={item?.photo_links?.map((photo) => photo.link)}
      />
    ),
  },
  {
    id: "name",
    name: "Ambiente",
    width: "10%",
    renderCell: (value, row) => (
      <Typography className={classes.spaceField}>{row?.name}</Typography>
    ),
    renderSubCell: (value, item) => (
      <Typography className={classes.spaceField}>
        {getObjectLabel(inspectionReportNameTranslations, item?.name)}
      </Typography>
    ),
  },
  {
    id: "overallQualification",
    name: "Estado de evaluación",
    width: "15%",
    renderCell: (value, row) => (
      <RatingStatus qualification={row?.overallQualification} />
    ),
    renderSubCell: (value, item) => (
      <RatingStatus qualification={item?.qualification} />
    ),
  },
  {
    id: "overallObservation",
    name: "Comentarios",
    width: "50%",
    renderCell: (value, row) => (
      <Typography className={classes.observationField}>
        {row?.overallObservation ?? "No se dejaron comentario de este ambiente"}
      </Typography>
    ),
    renderSubCell: (value, item) => (
      <Typography className={classes.observationField}>
        {item?.observation ?? "No se dejaron comentario de este ambiente"}
      </Typography>
    ),
  },
  {
    id: "action",
    name: "Visualizar",
    width: "15%",
    renderCell: (value, row, isOpen, expand) =>
      row?.subitems?.length > 0 && (
        <InspectionDetailsExpand isOpen={isOpen} onClick={expand} />
      ),
  },
];

export default getTableCols;
