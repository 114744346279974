import { makeStyles, Theme } from "@material-ui/core";

const usePaymentBreakdownStyles = makeStyles<Theme>((theme) => ({
  paymentDetailContainer: {
    width: "100%",
    "& div:nth-child(odd)": {
      backgroundColor: "#F7F9F9",
    },
  },
  totalDetail: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#DFE5E8",
    borderRadius: 2,
    "& p": {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.57,
    },
    padding: theme.spacing(0.25, 0.5),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1),
      "& p": {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 1.5,
      },
    },
  },
}));

export default usePaymentBreakdownStyles;
