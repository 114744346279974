import { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";

import { ITenantContract } from "apps/tenant/models/Contract";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import InfoStep from "./components/InfoStep";
import useInitialStepStyles from "./InitialStepStyles";
import getInfoSteps from "./utils/utils";

type Props = {
  handleNext: () => void;
  contractData: ITenantContract;
};

const InitialStep = ({ handleNext, contractData }: Props) => {
  const classes = useInitialStepStyles();
  const [checked, setChecked] = useState(false);
  const [infoSteps, setInfoSteps] = useState([]);
  const infoStepsLength = infoSteps.length;
  useEffect(() => {
    const getData = async () => {
      const data = await getInfoSteps(contractData, classes);
      setInfoSteps(data);
    };
    getData();
  }, []);

  return (
    <>
      <Typography className={classes.title}>Proceso de salida</Typography>
      <Typography className={classes.description}>
        Para continuar con tu salida, es necesario que tengas en cuenta los
        siguientes <strong>{infoStepsLength} puntos importantes</strong>:
      </Typography>
      {infoSteps.map(({ title, ...props }, index) => (
        <InfoStep key={title} title={`${index + 1}. ${title}`} {...props} />
      ))}
      <Box className={classes.confirmCheck}>
        <FormControlLabel
          label="He leído y acepto continuar con el término del contrato."
          classes={{ label: classes.confirmCheckLabel, root: classes.root }}
          control={
            <Checkbox
              checked={checked}
              color="primary"
              onChange={() => setChecked(!checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />
      </Box>
      <DesignSystemButton
        label="Comenzar salida"
        variant="primary"
        size="small"
        disabled={!checked}
        onClick={handleNext}
      />
    </>
  );
};

export default InitialStep;
