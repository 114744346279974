import useTermsAndConditionsStyles from "./TermsAndConditionsStyles";

interface Props {
  locale: string;
}

const TermsAndConditions = ({ locale }: Props) => {
  const classes = useTermsAndConditionsStyles();
  const getUrl = (locale: string) => {
    const url = {
      mx: () =>
        "https://ayuda.houm.com/hc/es-419/articles/360060530732-T%C3%A9rminos-y-Condiciones-Houm",
      co: () => "https://ayuda.houm.com/hc/es-419/articles/360048644532",
      cl: () => "https://ayuda.houm.com/hc/es-419/articles/360047538291",
    };
    return url[locale];
  };
  const url = getUrl(locale);

  return (
    <a target="_blank" href={url} className={classes.link}>
      términos y condiciones
    </a>
  );
};

export default TermsAndConditions;
