import { ReactNode } from "react";

import { Box } from "@material-ui/core";
import useCallToActionDrawerStyles from "./CallToActionDrawerStyles";

interface Props {
  children: ReactNode;
}
const CallToActionDrawer = ({ children }: Props) => {
  const classes = useCallToActionDrawerStyles();

  return <Box className={classes.callToAction}>{children}</Box>;
};

export default CallToActionDrawer;
