import {
  CompanyType,
  IBillDetails,
  IBillInfo,
} from "apps/landlord/services/propertiesDuesService/type";

import useBillStatus from "../../hooks/useBillStatus";
import CardContent from "../CardContent";
import CardHead from "../CardHead";
import CardLoading from "../CardLoading";
import classes from "./CardStyles";

type CardProps = {
  item: Partial<IBillInfo>;
  detail: Partial<IBillDetails>;
  isLoading: boolean;
  defaultTitle: string;
  error: boolean;
  companyType: CompanyType;
};

const Card = ({
  item,
  detail,
  isLoading,
  defaultTitle,
  error,
  companyType,
}: CardProps) => {
  const billStatus = useBillStatus(detail?.status, error);
  return (
    <div className={classes.cardContainer}>
      {isLoading ? (
        <CardLoading />
      ) : (
        <>
          <CardHead
            item={item}
            detail={detail}
            billStatus={billStatus}
            defaultTitle={defaultTitle}
          />
          <CardContent
            detail={detail}
            item={item}
            billStatus={billStatus}
            companyType={companyType}
          />
        </>
      )}
    </div>
  );
};

export default Card;
