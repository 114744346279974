import { isMobile } from "react-device-detect";
import { useEffect } from "react";

import DeskNavbar from "./components/DeskNavbar";
import MobileNavbar from "./components/MobileNavbar";
import { INavbarUser } from "./utils/types";
import { useNavbar } from "./context/navbarContext";
import { Locale } from "models/Countries";

interface NavbarProps {
  user: INavbarUser;
  usingAs?: "tenant" | "landlord";
  market: Locale;
}

const Navbar = ({ market, user, usingAs }: NavbarProps) => {
  const { setUser, setUsingAs, setCountry } = useNavbar();

  useEffect(() => {
    if (user) setUser(user);
  }, [user, setUser]);

  useEffect(() => {
    if (market) setCountry(market);
  }, [market, setCountry]);

  useEffect(() => {
    if (usingAs) setUsingAs(usingAs);
  }, [usingAs, setUsingAs]);

  if (isMobile) return <MobileNavbar />;
  return <DeskNavbar />;
};

export default Navbar;
