import axios from "axios";

import { BACKEND_URL } from "../env";
import { createHttp, getToken } from "./helper";

export default {
  sendProfileV2(contractId: number, token: string) {
    return createHttp(token).post(
      `/v2/contracts/${contractId}/new_send_profile/`
    );
  },

  update(id, data, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({ baseURL: BACKEND_URL, headers });

    return http.put(`/v2/contracts/${id}/`, data);
  },

  retrieveSignedContracts(contractId, token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token || getToken()}`,
    };
    const http = axios.create({
      baseURL: `${BACKEND_URL}/v2`,
      headers,
    });
    return http.get(`/contracts/${contractId}/signed_contracts`);
  },

  notifyFilledForm(propertyId, token) {
    return createHttp(token).post(
      `/properties/v2/${propertyId}/send_contract_form_fill_notification`
    );
  },

  getContractByProperty(propertyId: string | number, token: string) {
    return createHttp(token).get(`/v2/contract_by_property/${propertyId}`);
  },
};
