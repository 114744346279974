import clsx from "clsx";

import useFlagStyles from "./FlagStyles";
import { Country } from "../../types";

interface Props {
  className?: string;
  country: Country | null;
  size?: number;
}

const Flag = ({ className, country, size = 20 }: Props) => {
  const classes = useFlagStyles({ size });

  if (!country) return null;

  return (
    <img
      alt={country.code}
      src={`https://hatscripts.github.io/circle-flags/flags/${country.code}.svg`}
      className={clsx(classes.flagRoot, className)}
    />
  );
};

export default Flag;
