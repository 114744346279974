import { Typography } from "@material-ui/core";

import {
  collectionLink,
  getMonthDebtText,
} from "apps/landlord/pages/Property/utils/utils";
import { Locale } from "models/Countries";

import useDefaulterGuaranteedInfoStyles from "./DefaulterGuaranteedInfoStyles";
import DisclaimerRentalInfo from "../DisclaimerRentalInfo";

interface Props {
  country: Locale;
  lesseeMonthSinceDebt: Date;
}

const DefaulterGuaranteedInfo = ({ country, lesseeMonthSinceDebt }: Props) => {
  const classes = useDefaulterGuaranteedInfoStyles();

  return (
    <DisclaimerRentalInfo
      className={classes.container}
      country={country}
      link={collectionLink[country]}
    >
      <Typography component="span" className={classes.text}>
        Tu arrendatario no ha pagado el arriendo
        {getMonthDebtText(lesseeMonthSinceDebt)}, por lo que se ha iniciado el
        proceso de cobranza judicial.
      </Typography>
      <Typography component="span" className={classes.textSecondary}>
        Recuerda que el plan que tienes contratado te asegura una cobertura de 6
        meses, así que seguirás recibiendo el pago de tu arriendo hasta la
        mensualidad del sexto mes o hasta la fecha en que tu arrendatario deje
        la propiedad, lo que ocurra primero. Conoce más del proceso haciendo
      </Typography>
    </DisclaimerRentalInfo>
  );
};

export default DefaulterGuaranteedInfo;
