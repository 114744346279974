import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  mapWrapper: {
    width: "100% !important",
    height: "100% !important",
    "& .mapboxgl-canvas": {
      width: "100% !important",
    },
    "& .mapboxgl-map": {
      width: "100% !important",
      height: "100% !important",
    },
    "& .mainPin": {
      width: 90,
      height: 90,
      borderRadius: "50%",
      backgroundSize: "cover",
      backgroundImage: "url('/static/images/YourHousePin.svg')",
    },
    "& .otherPropsPin": {
      width: "fit-content",
      height: 30,
      border: "1px solid #DFE5E8",
      padding: theme.spacing(2),
      display: "flex",
      boxShadow: "4px 4px 30px rgba(0, 43, 41, 0.15)",
      alignItems: "center",
      borderRadius: 10,
      justifyContent: "center",
      backgroundColor: "white",
    },
    "& .idNumber": {
      position: "absolute",
      top: 2,
      left: 6,
      width: 30,
      height: 30,
      borderRadius: "50%",
      backgroundSize: "cover",
      fontSize: theme.typography.pxToRem(9),
    },
    "& .mapboxgl-popup": {
      padding: 5,
    },
    "& .mapboxgl-popup-content": {
      padding: 15,
      fontSize: 15,
      textAlign: "center",
      fontFamily: "'Open Sans', sans-serif",
    },
  },
}));
