import { AxiosResponse } from "axios";

import { createNoAuthHttp } from "services/helper";
import { Locale } from "models/Countries";
import { PAYMENTS_URL } from "env";

import {
  FintocWidgetResponse,
  IMovement,
  RentPaymentResponse,
  RentPaymentResponseCL,
} from "./types";

const http = createNoAuthHttp(
  PAYMENTS_URL,
  "application/x-www-form-urlencoded"
);

export default {
  getFintoc: (
    country: Locale,
    movementId: string
  ): Promise<AxiosResponse<FintocWidgetResponse>> =>
    createNoAuthHttp(PAYMENTS_URL).get(
      `${country}/v3/fintoc/${movementId}/get-token`
    ),

  getRentPaymentCL(id: string): Promise<AxiosResponse<RentPaymentResponseCL>> {
    const cleanedId = id.split(".").join("").split("-").join("");
    return http.get(`cl/v3/movements/income/breakdown/simple`, {
      params: { rut: cleanedId },
    });
  },

  getPayMovementInfo: (
    movementId: string,
    concept: string,
    country = "mx"
  ): Promise<AxiosResponse<IMovement>> =>
    createNoAuthHttp(PAYMENTS_URL).get(
      `${country}/v3/movements/income/${concept}/${movementId}`
    ),

  getRentPaymentDetails(
    country: Locale,
    userId: string
  ): Promise<AxiosResponse<RentPaymentResponse>> {
    return createNoAuthHttp(PAYMENTS_URL).get(
      `${country}/v3/people/${userId}/unpaid-movement`
    );
  },
};
