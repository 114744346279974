import { makeStyles, Theme } from "@material-ui/core/styles";

const defaultBreakpoint = "sm";

const useCustomNavbarStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    zIndex: theme.zIndex.appBar,
    top: 0,
    left: 0,
    height: 80,
    width: "100%",
    padding: theme.spacing(0, 2),
    borderBottom: "2px solid rgb(233,233,233)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    filter: "drop-shadow(0px 4px 4px rgba(96, 108, 129, 0.1))",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      height: 104,
      padding: theme.spacing(0, 6),
    },
  },
  navbarImg: {
    height: 48,
    marginLeft: theme.spacing(5),
  },
}));

export default useCustomNavbarStyles;
