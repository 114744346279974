// TODO create endpoint to get this data
export const docTypes = {
  cl: [
    {
      id: 1,
      type: "Rut",
      validation_regex: "^\\d{1,2}\\.?\\d{3}\\.?\\d{3}[-]?[0-9kK]{1}$",
      identifier: "RUT",
    },
  ],
  co: [
    {
      id: 2,
      type: "Cedula",
      validation_regex: "^\\d{4,12}|-$",
      identifier: "CC",
    },
    {
      id: 11,
      type: "Cedula de extranjeria",
      validation_regex: "^\\d{6,7}$",
      identifier: "CE",
    },
    {
      id: 3,
      type: "NIT",
      validation_regex: "^[0-9]+-?[0-9]{1}$",
      identifier: "NIT",
    },
    {
      id: 4,
      type: "Pasaporte",
      validation_regex: "^(?!^0+$)[a-zA-Z0-9]{6,9}$",
      identifier: "PASSPORT",
    },
    {
      id: 12,
      type: "PEP",
      validation_regex: "/^[a-zA-Z]{0,9}$/",
      identifier: "PEP",
    },
  ],
  mx: [
    { id: 5, type: "INE", validation_regex: "^\\d{13}|-$", identifier: "INE" },
    {
      id: 7,
      type: "Pasaporte mexicano",
      validation_regex: "^[a-zA-Z]{1}\\d{8}|-$",
      identifier: "PASSPORT_MX",
    },
    {
      id: 9,
      type: "RFC",
      validation_regex:
        "^([A-ZÑ\\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\\d]{3})|-$",
      identifier: "RFC",
    },
    {
      id: 13,
      type: "CURP",
      validation_regex:
        "/^([A-Z][AEIOUX][A-Z]{2}d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Zd])(d)$/",
      identifier: "CURP",
    },
    {
      id: 14,
      type: "Pasaporte extranjero",
      validation_regex: "^[A-Z0-9]{6,12}$",
      identifier: "FOREIGN_PASSPORT_MX",
    },
  ],
};
