import axios, { AxiosError } from "axios";
import { SnackbarTypes } from "libs/houm-snackbar/types";

export const paymentDataFetchError = (error: AxiosError) => {
  if (
    axios.isAxiosError(error) &&
    error.response?.data?.message === "The tenant has not debt."
  ) {
    return {
      head: "¡Ups!",
      body: "No hay deudas asociadas a este arrendatario",
      type: "warning" as SnackbarTypes,
    };
  }
  return {
    head: "¡Oh no!",
    body: "No se ha podido obtener la información del pago",
    type: "error" as SnackbarTypes,
  };
};
