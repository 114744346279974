import { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";

import { Box } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

import useRepairsRequestSteps, {
  STEPS,
} from "apps/landlord/hooks/useRepairsRequestSteps";
import Spinner from "Components/Shared/partials/Spinner";
import { useContract, useProperty } from "apps/landlord/hooks";
import { Locale } from "models/Countries";
import useRepairRequestAction from "apps/landlord/hooks/propertyRepairRequests";

import useRepairsRequestStyles from "./RepairsRequestStyles";
import RepairConfirmation from "./components/RepairConfirmation";
import RepairSelection from "./components/RepairSelection";

interface Props {
  closeDrawer?: () => void;
}

const RepairsRequest = ({ closeDrawer }: Props) => {
  const { propertyData } = useProperty();
  const { uid: propertyUuid } = propertyData || {};
  const { contractData } = useContract(propertyUuid);
  const { currentStep, handleNextStep, handleError } =
    useRepairsRequestSteps(closeDrawer);
  const { requestForm, setRequestForm, submitData, isLoading } =
    useRepairRequestAction({
      onSuccessHandler: handleNextStep,
      onErrorHandler: handleError,
    });

  const containerRef = useRef(null);
  useEffect(() => {
    containerRef.current.scrollIntoView();
  }, [currentStep]);

  const sanitizedCountry = {
    Chile: "cl" as Locale,
    Colombia: "co" as Locale,
    Mexico: "mx" as Locale,
    México: "mx" as Locale,
  }[contractData?.property?.country];

  const classes = useRepairsRequestStyles();
  const stepComponent = () => {
    if (isLoading) return <Spinner />;
    switch (currentStep) {
      case STEPS.Selection:
        return (
          <RepairSelection
            handleNext={submitData}
            setRepairForm={setRequestForm}
            repairForm={requestForm}
          />
        );
      case STEPS.Confirmation:
        return (
          <RepairConfirmation
            handleNext={handleNextStep}
            requestForm={requestForm}
            country={sanitizedCountry}
          />
        );

      default:
        return (
          <RepairSelection
            handleNext={handleNextStep}
            setRepairForm={setRequestForm}
            repairForm={requestForm}
          />
        );
    }
  };
  return (
    <div className={classes.scrollContainer} ref={containerRef}>
      <Box className={classes.drawerHeader}>
        {!isMobile && (
          <Box className={classes.iconContainer} onClick={closeDrawer}>
            <Close />
          </Box>
        )}
      </Box>
      {stepComponent()}
    </div>
  );
};

export default RepairsRequest;
