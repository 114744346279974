import { ReactElement, useState } from "react";

import { IoAlertCircleOutline } from "@houm-com/ui-icons";
import { ClickAwayListener } from "@material-ui/core";

import useHelpTooltipStyles from "./HelpTooltipStyles";
import { HelpTooltipPlacements } from "./utils";
import HelpTooltipInfo from "../Tooltip";

interface Props {
  valueText: string | ReactElement;
  disableFocus?: boolean;
  disableHover?: boolean;
  disableTouch?: boolean;
  openClick?: boolean;
  placement?: HelpTooltipPlacements;
}

const HelpTooltip = ({
  valueText,
  disableFocus = true,
  disableHover = false,
  disableTouch = true,
  openClick = false,
  placement = "right",
}: Props) => {
  const [open, setOpen] = useState(false);

  const classes = useHelpTooltipStyles();
  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);

  const renderTooltip = () => (
    <HelpTooltipInfo
      title={valueText}
      placement={placement}
      onClose={handleTooltipClose}
      open={openClick ? open : undefined}
      disableFocusListener={disableFocus}
      disableHoverListener={disableHover}
      disableTouchListener={disableTouch}
    >
      <IoAlertCircleOutline
        className={classes.iconHelp}
        onClick={openClick ? handleTooltipOpen : undefined}
      />
    </HelpTooltipInfo>
  );

  return openClick ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>{renderTooltip()}</div>
    </ClickAwayListener>
  ) : (
    <>{renderTooltip()}</>
  );
};

export default HelpTooltip;
