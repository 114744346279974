import { makeStyles, Theme } from "@material-ui/core";

const useUnpublishPropertyStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "flex-end",
    },
  },
  modalRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    maxWidth: 343,
    padding: theme.spacing(4, 3),
    borderRadius: "1rem",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "532px",
      padding: theme.spacing(4, 6),
    },
  },
  textArea: {
    padding: theme.spacing(1, 1.5),
    marginTop: theme.spacing(1),
    borderRadius: 4,
    backgroundColor: "#EFF2F3",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.9,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.6,
    },
  },
  dialogTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(28),
  },
  paragraph: {
    fontFamily: "Nunito",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  paragraphStrong: {
    fontWeight: 700,
  },
  dialogOption: {
    fontFamily: "Nunito",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    padding: 0,
    margin: 0,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  multiOptionsAvailable: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      alignItems: "flex-end",
      gap: theme.spacing(1.5),
    },
  },
  formButtonsWrapper: {
    width: "100%",
    paddingTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      gap: theme.spacing(2),
    },
  },
}));

export default useUnpublishPropertyStyles;
