import { useMutation } from "react-query";

import { useHoumSnackBar } from "libs/houm-snackbar";
import { useGetToken } from "hooks";
import favoritesService from "services/favouritesService";

interface IUseDeleteFavorite {
  onSuccessHandler: () => void;
}

const useDeleteFavorite = ({ onSuccessHandler }: IUseDeleteFavorite) => {
  const { getToken } = useGetToken();
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const { mutate, isLoading, isError } = useMutation(
    async (propertyId: number) => {
      const authToken = await getToken();
      return favoritesService.deleteFavorite(authToken, propertyId);
    },
    {
      onSuccess: () => {
        enqueueHoumSnackBar({
          body: "Se ha quitado la propiedad de tus favoritos",
          type: "success",
          head: "",
        });
        onSuccessHandler();
      },
      onError: () => {
        enqueueHoumSnackBar({
          head: "",
          body: "Ocurrió un problema al quitar la propiedad de tus favoritos",
          type: "error",
        });
      },
    }
  );

  return {
    mutate,
    isLoading,
    isError,
  };
};

export default useDeleteFavorite;
