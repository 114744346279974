import { ReactNode } from "react";

import Navbar from "apps/ui/Navbar";
import { NavbarProvider } from "apps/ui/Navbar/context/navbarContext";
import Spinner from "Components/Shared/partials/Spinner";
import { useHoumSnackBar } from "libs/houm-snackbar";
import { countryCode } from "utils";

import useUser from "../hooks/useUser";
import useStyles from "./AccountLayoutStyles";

interface Props {
  children: ReactNode;
}

const AccountLayout = ({ children }: Props) => {
  const classes = useStyles();
  const { user, isLoading, error } = useUser();
  const { enqueueHoumSnackBar } = useHoumSnackBar();

  if (isLoading) return <Spinner />;

  if (error)
    enqueueHoumSnackBar({
      body: "Ha ocurrido un error cargando el usuario",
      type: "error",
    });

  return (
    <NavbarProvider>
      <Navbar user={user} market={countryCode(user.country)} />
      <div className={classes.accountContentRoot}>{children}</div>
    </NavbarProvider>
  );
};

export default AccountLayout;
