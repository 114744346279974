import { makeStyles, Theme } from "@material-ui/core";

const useBankAccountStyles = makeStyles((theme: Theme) => ({
  bankAccountRoot: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.6,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
}));

export default useBankAccountStyles;
