import { InspectionReportQualifications } from "apps/landlord/services/propertyService/type";

export const statusRatingLabel: {
  [status in InspectionReportQualifications]: string;
} = {
  excellent: "¡Excelente!",
  good: "Bueno",
  acceptable: "Aceptable",
  bad: "Malo",
  does_not_have: "Sin evaluar",
  unanswered: "Sin responder",
};
