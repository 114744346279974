export const classes = {
  welcomeRoot:
    "w-full h-full flex flex-col-reverse md:flex-row gap-8 md:gap-20 md:px-20",
  welcomeInformationRoot: "w-full md:w-[50%] flex flex-col gap-10 md:py-10",
  welcomeInformationContainer: "flex flex-col gap-4 text-center md:text-start ",
  welcomeTitle:
    "text-[36px] leading-[42px] md:text-[52px] md:leading-[60px] font-extrabold text-[#515B60] font-['Inter']",
  welcomeTextContainer:
    "text-p20 flex flex-col gap-8 text-[#515B60] [&_p]:font-['Inter']",
  welcomeList: "list-decimal list-inside [&_li]:font-['Inter']",
  welcomeButtonContainer: "flex justify-center md:justify-start",
  welcomeImageContainer: "w-full md:w-[50%] flex items-center justify-center",
  welcomeImage: "max-w-[300px] max-h-[300px] md:max-w-[385px] md:max-h-[385px]",
};
