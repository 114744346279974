import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box } from "@material-ui/core";

import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import useDocumentTypeList from "hooks/useDocumentTypeListPublic";
import Layout from "apps/payments/layout/RentPaymentLayout";
import Spinner from "Components/Shared/partials/Spinner";

import useRentPaymentDataByUserId from "../../hooks/useRentPaymentDataByUserId";
import { Title, Subtitle } from "../../../../components/typography";
import useRentPaymentData from "../../hooks/useRentPaymentData";
import validationSchema from "./utils/validationSchema";
import DocumentInput from "./components/DocumentInput";
import useLandingStyles from "./LandingStyles";
import { getDefaultData } from "./utils/utils";

export interface FormProps {
  document: string;
  documentType: number;
}

const Landing = () => {
  const { t } = useTranslation("rent-payment");
  const classes = useLandingStyles();
  const { state } = useRentPayment();
  const { mutate, isLoading } = useRentPaymentData();
  const { mutate: mutateByUserId, isLoading: isLoadingByUserId } =
    useRentPaymentDataByUserId();

  const { data: documentTypeList, isLoading: isLoadingDocumentTypeList } =
    useDocumentTypeList(state.country);

  const methods = useForm<FormProps>({
    resolver: yupResolver(validationSchema(documentTypeList)),
    reValidateMode: "onBlur",
    defaultValues: getDefaultData(state.country, state.tenantData),
  });

  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    if (isLoading) return;
    if (state.country === "cl") mutate(data);
    else mutateByUserId(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Layout
          buttonNextText="Ingresar"
          buttonProps={{
            type: "submit",
          }}
          loading={isLoading || isLoadingByUserId}
        >
          {isLoadingDocumentTypeList && <Spinner />}
          {!isLoadingDocumentTypeList && (
            <Box className={classes.landingContainer}>
              <Box className={classes.landingTextContainer}>
                <Title>{t("LANDING_TITLE")}</Title>
                <Subtitle>
                  <Trans
                    i18nKey={t("LANDING_SUBTITLE")}
                    components={{ 1: <strong /> }}
                  />
                </Subtitle>
              </Box>
              <DocumentInput documentTypeList={documentTypeList} />
            </Box>
          )}
        </Layout>
      </form>
    </FormProvider>
  );
};

export default Landing;
