import Swal from "sweetalert2";

import { Box } from "@material-ui/core";

import useNavbarHeight from "apps/landlord/hooks/useNavbarHeight";
import { useContract, useProperty } from "apps/landlord/hooks";
import Spinner from "Components/Shared/partials/Spinner";

import { errorParams, pageSubtitle } from "./utils/utils";
import usePropertyStatus from "./hooks/usePropertyStatus";
import useStyles from "./PropertyStyles";
import Photo from "./components/Photo";
import MainContent from "./components/MainContent";

export default function Property() {
  const navbarHeight = useNavbarHeight();
  const classes = useStyles({
    navbarHeight,
  });
  const { propertyLoading, propertyData, propertyError } = useProperty();
  const { isRented } = usePropertyStatus(propertyData);
  const { contractData, contractError, contractLoading } = useContract(
    propertyData?.uid,
    isRented
  );

  if (contractError || propertyError) {
    if (contractError) Swal.fire({ ...errorParams(contractError) });
    return null;
  }

  if (propertyLoading || contractLoading) {
    return (
      <Box className={classes.loadingContainer}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box className={classes.propertyRoot}>
      <Box className={classes.leftContainer}>
        <Photo
          photos={propertyData.photos}
          address={propertyData.address}
          subAddress={pageSubtitle(propertyData)}
          propertyId={propertyData.id}
          paymentOptionType={contractData?.plan?.payment_option?.option}
        />
      </Box>
      <MainContent contractData={contractData} propertyData={propertyData} />
    </Box>
  );
}
