export interface PaymentsDisclaimerProps {
  title: string;
  content: string;
}

const PaymentsDisclaimer = ({ title, content }: PaymentsDisclaimerProps) => (
  <div className="bg-[#FAF3CC] p-6 md:p-4">
    <p className="text-p14 font-bold mb-1 md:mb-2">{title}</p>
    <p className="text-p14">{content}</p>
  </div>
);

export default PaymentsDisclaimer;
