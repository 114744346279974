import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

const useCompleteEvaluationDialogStyles = makeStyles<Theme>((theme) => ({
  textContainer: {
    marginBottom: theme.spacing(3),
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
  textButton: {
    textDecoration: "underline",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    cursor: "pointer",
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(16),
      marginBottom: "auto",
    },
  },
}));

export default useCompleteEvaluationDialogStyles;
