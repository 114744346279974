import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";

import {
  setPropertyData,
  setReady,
} from "context/closureFormContext/closureFormActions";
import { useClosureForm } from "context/closureFormContext/closureFormContext";
import fields from "context/closureFormContext/closureFormFields";
import { PropertyDataProps } from "context/closureFormContext/closureFormTypes";
import { useFocusError, useFormNotifications } from "hooks";
import { propertyFurnishedOptions } from "utils/createProperty";
import { requiredText } from "utils/yupValidators";

import PublicDeedFields from "./CollapsibleComponents/publicDeedFields";
import CommonExpensesValue from "./CollapsibleComponents/commonExpensesValue";
import CallToAction from "./CustomCallToAction";
import {
  FilteredAutocomplete,
  FilteredOptions,
  FilteredTextField,
} from "./FilteredComponents";
import { propertyValidationSchema } from "./validationSchema";

export default function PropertyDataStep() {
  const { state, dispatch } = useClosureForm();
  const stepState = state.propertyData;
  const stepFields = fields.property;

  const methods = useForm<PropertyDataProps>({
    resolver: yupResolver(propertyValidationSchema(state.propertyData.country)),
    reValidateMode: "onBlur",
  });
  const { errors } = methods.formState;
  useFormNotifications<PropertyDataProps>({ errors, requiredText });
  useFocusError(errors, methods.setFocus);

  const onSubmit: SubmitHandler<PropertyDataProps> = (data) => {
    dispatch(setPropertyData({ ...stepState, ...data }));
    dispatch(setReady("propertyData"));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FilteredTextField
          id="address"
          fieldInfo={stepFields.address()}
          defaultValue={stepState.address}
        />

        <FilteredTextField
          id="addressNumber"
          fieldInfo={stepFields.addressNumber()}
          defaultValue={stepState.addressNumber}
        />

        <FilteredTextField
          id="outerNumber"
          fieldInfo={stepFields.outerNumber()}
          defaultValue={stepState.outerNumber}
        />

        <FilteredTextField
          id="innerNumber"
          fieldInfo={stepFields.innerNumber()}
          defaultValue={stepState.innerNumber}
        />

        <FilteredAutocomplete
          id="neighborhood"
          fieldInfo={stepFields.neighborhood()}
          defaultValue={stepState.neighborhood}
          options={stepState.availableNeighborhoods}
        />

        <FilteredTextField
          id="commune"
          fieldInfo={stepFields.commune()}
          defaultValue={stepState.commune}
        />

        <FilteredTextField
          id="region"
          fieldInfo={stepFields.region()}
          defaultValue={stepState.region}
        />

        <FilteredTextField
          id="postalCode"
          fieldInfo={stepFields.postalCode()}
          defaultValue={stepState.postalCode}
        />

        <FilteredTextField
          id="rentValue"
          fieldInfo={stepFields.rentValue()}
          defaultValue={stepState.rentValue}
        />

        <FilteredTextField
          id="availableDate"
          fieldInfo={stepFields.availableDate()}
          defaultValue={stepState.availableDate}
          textFieldProps={{
            textFieldProps: { type: "date" },
          }}
        />

        <FilteredOptions
          id="isFlat"
          fieldInfo={stepFields.isFlat()}
          defaultValue={stepState.isFlat}
          options={[
            { value: "1", label: "Departamento" },
            { value: "0", label: "Casa" },
          ]}
        />

        <FilteredOptions
          id="isHorizontal"
          fieldInfo={stepFields.isHorizontal()}
          defaultValue={stepState.isHorizontal}
          options={[
            { value: "1", label: "Si" },
            { value: "0", label: "No" },
          ]}
        />

        <FilteredTextField
          id="departmentNumber"
          fieldInfo={stepFields.departmentNumber()}
          defaultValue={stepState.departmentNumber}
        />

        <FilteredOptions
          id="isFurnished"
          fieldInfo={stepFields.isFurnished()}
          defaultValue={stepState.isFurnished}
          options={propertyFurnishedOptions}
        />

        <FilteredTextField
          id="parkingNumbers"
          fieldInfo={stepFields.parkingNumbers()}
          defaultValue={stepState.parkingNumbers}
          hint="Si tiene más de uno, indique los números separados por comas."
        />

        <FilteredOptions
          id="isUrban"
          fieldInfo={stepFields.isUrban()}
          defaultValue={stepState.isUrban}
          options={[
            { value: "1", label: "Urbano" },
            { value: "0", label: "Rural" },
          ]}
        />

        <FilteredOptions
          id="inCondo"
          fieldInfo={stepFields.inCondo()}
          defaultValue={stepState.inCondo}
          options={[
            { value: "1", label: "Si" },
            { value: "0", label: "No" },
          ]}
        />

        <FilteredOptions
          id="publicDeed"
          fieldInfo={stepFields.publicDeed()}
          defaultValue={stepState.publicDeed}
          options={[
            { value: "1", label: "Si" },
            { value: "0", label: "No" },
          ]}
        />

        <PublicDeedFields />

        <FilteredTextField
          id="notary"
          fieldInfo={stepFields.notary()}
          defaultValue={stepState.notary}
        />

        <FilteredTextField
          id="notaryNumber"
          fieldInfo={stepFields.notaryNumber()}
          defaultValue={stepState.notaryNumber}
        />

        <FilteredTextField
          id="predialNumber"
          fieldInfo={stepFields.predialNumber()}
          defaultValue={stepState.predialNumber}
        />

        <FilteredTextField
          id="propertyRegistrationNumber"
          fieldInfo={stepFields.propertyRegistrationNumber()}
          defaultValue={stepState.propertyRegistrationNumber}
        />

        <FilteredOptions
          id="hasCommonExpenses"
          fieldInfo={stepFields.hasCommonExpenses()}
          defaultValue={stepState.hasCommonExpenses}
          options={[
            { value: "1", label: "Si" },
            { value: "0", label: "No" },
          ]}
        />

        <CommonExpensesValue />

        <FilteredTextField
          id="mortgage"
          fieldInfo={stepFields.mortgage()}
          defaultValue={stepState.mortgage}
        />

        <FilteredTextField
          id="domainRestriction"
          fieldInfo={stepFields.domainRestriction()}
          defaultValue={stepState.domainRestriction}
        />

        <FilteredTextField
          id="paymentCondition"
          fieldInfo={stepFields.paymentCondition()}
          defaultValue={stepState.paymentCondition}
        />

        <FilteredTextField
          id="chargeCondition"
          fieldInfo={stepFields.chargeCondition()}
          defaultValue={stepState.chargeCondition}
        />

        <FilteredOptions
          id="policy"
          fieldInfo={stepFields.policy()}
          defaultValue={stepState.policy}
          options={[
            { value: "Digital", label: "Digital" },
            { value: "Mecanismo tradicional", label: "Mecanismo tradicional" },
          ]}
        />

        <FilteredTextField
          id="warranty"
          fieldInfo={stepFields.warranty()}
          defaultValue={stepState.warranty}
        />

        <FilteredTextField
          id="warehouseNumber"
          fieldInfo={stepFields.warehouseNumber()}
          defaultValue={stepState.warehouseNumber}
          hint="Si tiene más de uno, indique los números separados por comas."
        />

        <FilteredTextField
          id="maxPeopleQuantity"
          fieldInfo={stepFields.maxPeopleQuantity()}
          defaultValue={stepState.maxPeopleQuantity}
        />

        <CallToAction />
      </form>
    </FormProvider>
  );
}
