import { useMutation, useQueryClient } from "react-query";

import bankAccountService from "apps/landlord/services/bankAccountService";
import { useHoumSnackBar } from "libs/houm-snackbar";
import { Locale } from "models/Countries";
import { useGetToken } from "hooks";

import { parseBankAccountData } from "./utils/parsers";
import { FormProps } from "../../utils/types";

const useBankAccount = (
  isNew: boolean,
  country: Locale,
  userId: number,
  propertyId: number,
  userEmail: string,
  closeModal: () => void
) => {
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const queryClient = useQueryClient();
  const { getToken } = useGetToken();

  const { mutate, isLoading, isSuccess } = useMutation(
    async (data: FormProps) => {
      const authToken = await getToken();
      const parsedBankAccount = parseBankAccountData(
        isNew,
        data,
        propertyId,
        userEmail,
        country
      );
      if (isNew)
        await bankAccountService.create(
          country,
          userId,
          parsedBankAccount,
          authToken
        );
      else
        await bankAccountService.update(
          country,
          userId,
          propertyId,
          parsedBankAccount,
          authToken
        );
    },
    {
      onError: () =>
        enqueueHoumSnackBar({
          body: `La cuenta bancaria no se ha podido ${
            isNew ? "crear" : "modificar"
          }.`,
          type: "error",
        }),
      onSuccess: () => {
        enqueueHoumSnackBar({
          body: `La cuenta bancaria se ha ${
            isNew ? "creado" : "modificado"
          } con exito.`,
          type: "success",
        });
        queryClient.invalidateQueries(`SETTING_UP_STATUS_${propertyId}`);
        queryClient.invalidateQueries(`PROPERTY_BANK_ACCOUNT_${propertyId}`);
        closeModal();
      },
    }
  );

  return { mutate, isLoading, isSuccess };
};

export default useBankAccount;
