import { makeStyles, Theme } from "@material-ui/core";

import {
  DESK_NAVBAR_HEIGHT,
  DESKTOP_CONTENT_MAX_WIDTH,
  MOBILE_NAVBAR_HEIGHT,
} from "apps/tenant/constants";

const useVoucherDetailsStyles = makeStyles<Theme>((theme) => ({
  loadingContainer: {
    display: "flex",
    minHeight: `calc(100vh - ${MOBILE_NAVBAR_HEIGHT}px)`,
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      minHeight: `calc(100vh - ${DESK_NAVBAR_HEIGHT}px)`,
    },
  },
  voucherDetailsRoot: {
    position: "relative",
    width: "100vw",
    minHeight: `calc(100vh - ${MOBILE_NAVBAR_HEIGHT}px)`,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F7F9F9",
    [theme.breakpoints.up("sm")]: {
      minHeight: `calc(100vh - ${DESK_NAVBAR_HEIGHT}px)`,
      paddingBottom: theme.spacing(0),
    },
  },
  contentGrid: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: DESKTOP_CONTENT_MAX_WIDTH,
      margin: theme.spacing(0, "auto"),
      padding: theme.spacing(0, 5),
    },
  },
  appBar: {
    top: "auto",
    bottom: 0,
    backgroundColor: "#fff",
    padding: theme.spacing(2),
  },
}));

export default useVoucherDetailsStyles;
