import { AxiosResponse } from "axios";

import { CurrencyCodeType } from "apps/landlord/models/Currency";
import {
  MoneyType,
  PropertyBusinessType,
  PropertyType,
  RentStatusType,
  SalesStatusType,
  Photos,
} from "apps/landlord/models/Property";
import { CountryUpperCase } from "models/Countries";
import { PropertyData } from "models/Property";

export interface PropertyDetails {
  currency_code: CurrencyCodeType;
  tipo_moneda: MoneyType;
  tipo_moneda_venta: MoneyType;
  status: RentStatusType;
  valor: number;
  valor_venta: number;
  first_available_handover_date: string;
  m_terreno: number;
  m_construidos: number;
  banos: number;
  dormitorios: number;
  estacionamientos: number;
  has_laundry_room: boolean;
  mascotas: boolean;
  amoblado: boolean;
  has_balcony: boolean;
  latitud: number;
  longitud: number;
  gc: number;
}

export interface SalesDetails {
  status: SalesStatusType;
  currency_code: CurrencyCodeType;
  price: number;
}

export interface IProperty {
  id: number;
  uid: string;
  address: string;
  comuna: string;
  country: CountryUpperCase;
  for_rental: boolean;
  for_sale: boolean;
  numero_depto: string;
  property_details: PropertyDetails[];
  photos: Photos[];
  region: string;
  sales_details: SalesDetails;
  street_number: string;
  type: PropertyType;
  year_built: number;
  antiquity: number;
}

export interface QuantityResponse {
  rented: {
    count: number;
    percentage: number;
  };
  not_rented: {
    count: number;
    percentage: number;
  };
  total: number;
}

export type InspectionReportQualifications =
  | "excellent"
  | "good"
  | "acceptable"
  | "bad"
  | "does_not_have"
  | "unanswered";

export type InspectionReportItemsNames =
  | "carpets"
  | "coaxial_connectors"
  | "curtains"
  | "lamps"
  | "network_connectors"
  | "plugs"
  | "switches";

export type InspectionReportFeaturesNames =
  | "bathroom_extractor"
  | "cabinets"
  | "chimney"
  | "closets"
  | "dishwasher"
  | "doors"
  | "extractor_hood"
  | "floors"
  | "mirror"
  | "oven"
  | "roofs"
  | "shower"
  | "shower_door"
  | "stairs"
  | "toilet"
  | "tub"
  | "windows"
  | "walls"
  | "washbasin"
  | "water_pressure"
  | "worktop";

export type InspectionReportSpacesType =
  | "living_room"
  | "dining_room"
  | "room"
  | "bathroom"
  | "kitchen"
  | "laundry_room"
  | "terrace"
  | "other";

interface InspectionReportPhotoLink {
  link: string;
}

export interface InspectionReportFeature {
  name?: InspectionReportFeaturesNames;
  observation: string;
  photo_links: InspectionReportPhotoLink[];
  qualification: InspectionReportQualifications;
}

export interface InspectionReportItem {
  name?: InspectionReportItemsNames;
  observation: string;
  photo_links: InspectionReportPhotoLink[];
  qualification: InspectionReportQualifications;
  quantity: number;
}

export interface InspectionReportSpace {
  id: number;
  name: string;
  general_photo: string;
  photo_link: string;
  type: InspectionReportSpacesType;
  overall_qualification: InspectionReportQualifications;
  overall_observation: string;
  bathroom_extractor?: InspectionReportFeature;
  cabinets?: InspectionReportFeature;
  carpets?: InspectionReportItem;
  chimney?: InspectionReportFeature;
  closets?: InspectionReportFeature;
  coaxial_connectors?: InspectionReportItem;
  curtains?: InspectionReportItem;
  dishwasher?: InspectionReportFeature;
  doors?: InspectionReportFeature;
  extractor_hood?: InspectionReportFeature;
  floors?: InspectionReportFeature;
  lamps?: InspectionReportItem;
  mirror?: InspectionReportFeature;
  network_connectors?: InspectionReportItem;
  oven?: InspectionReportFeature;
  plugs?: InspectionReportItem;
  roofs?: InspectionReportFeature;
  shower?: InspectionReportFeature;
  shower_door?: InspectionReportFeature;
  stairs?: InspectionReportFeature;
  switches?: InspectionReportItem;
  toilet?: InspectionReportFeature;
  tub?: InspectionReportFeature;
  windows?: InspectionReportFeature;
  walls?: InspectionReportFeature;
  washbasin?: InspectionReportFeature;
  water_pressure?: InspectionReportFeature;
  worktop?: InspectionReportFeature;
}

type InspectionReportsTypes =
  | "first_visit"
  | "applicant_visit"
  | "handover"
  | "half_contract"
  | "return";

export interface InspectionReportShowResponse {
  id: number;
  created_at: string;
  appraiser?: {
    id: string;
    email: string;
    name: string;
  };
  property: number;
  schedule: number;
  spaces: InspectionReportSpace[];
  type: InspectionReportsTypes;
  general_observation: string;
  is_habitable: boolean;
}

export interface PropertyInspectionReportsTableFilters extends DefaultFilters {
  type__in?: string;
  date_after?: string;
  date_before?: string;
}

export interface DefaultFilters {
  ordering?: string;
  page?: number;
  page_size?: number;
}

export type PropertyListResponse = PropertyData;

export interface ChangePropertyStatusArgs {
  propertyId: number;
  reasonId?: string;
  observation?: string;
  token: string;
}

export enum ChangePropertyStatusOperationType {
  PUBLISH = "publish",
  UNPUBLISH = "unpublish",
}

export interface GetChangePropertyStatusReasonsArgs {
  token: string;
  businessType: PropertyBusinessType;
  operationType?: ChangePropertyStatusOperationType;
}

export interface ChangePropertyStatusReason {
  value: string;
  label: string;
}

export type ChangePropertyStatusReasonsResponse = Promise<
  AxiosResponse<ChangePropertyStatusReason[]>
>;

export interface MarketplaceStats {
  pageviews?: number;
  all_visits?: number;
  like_users?: number;
  done_visits?: number;
}

export interface PropertyUrlPropertyAnalysis {
  rent?: string;
  sale?: string;
}

export interface HoumOwnerPropertyAnalysis {
  id?: number;
  name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  second_last_name?: string;
}

export interface PropertyDetailPropertyAnalysis {
  banos?: number;
  currency_code?: string;
  dormitorios?: number;
  latitud?: number;
  longitud?: number;
  m_construidos?: number;
  m_terreno?: number;
  tipo_moneda?: string;
  valor?: number;
  valor_clf?: number;
  tipo_moneda_venta?: string;
  valor_venta?: number;
  valor_venta_clf?: number;
  scraped_at?: string;
  status: RentStatusType;
}

export interface SalesDetailsPropertyAnalysis {
  currency_code?: string;
  price?: number;
  price_clf?: number;
  recommended_price?: number;
  recommended_price_clp?: number;
  status: SalesStatusType;
}

export interface Schedules {
  id: number;
  created_at: string;
  updated_at: string;
  completed: boolean;
  date: string;
  begin_date: string;
  end_date: string;
  comment: string;
  status: string;
  uid: string;
  cancel_reason: string;
  state_progress: string;
  action_source: string;
  appraiser: {
    id: string;
    email: string;
    name: string;
  };
}

export interface MainPropertyPropertyAnalysis {
  id: number;
  address?: string;
  marketplace_stats?: MarketplaceStats;
  photos?: Photos[];
  property_details?: PropertyDetailPropertyAnalysis[];
  sales_details?: SalesDetailsPropertyAnalysis;
  for_rental?: boolean;
  for_sale?: boolean;
  houm_owner?: HoumOwnerPropertyAnalysis;
  vitruvio_suggest?: number;
  vitruvio_suggest_clf?: number;
  marketplace_url?: PropertyUrlPropertyAnalysis;
  publication_date?: string;
  portal?: string;
  uid: string;
  schedules: Schedules[];
  country: CountryUpperCase;
}

export interface SimilarProperty {
  id: number;
  address?: string;
  marketplace_stats?: MarketplaceStats;
  photos?: Photos[];
  property_details?: PropertyDetailPropertyAnalysis[];
  sales_details?: SalesDetailsPropertyAnalysis;
  marketplace_url?: PropertyUrlPropertyAnalysis;
  portal: string;
}

export interface SimilarExternalProperty {
  link: string;
  portal: string;
  price: number;
  bedrooms: number;
  bathrooms: number;
  built_surface: number;
  total_surface: number;
  parking_spots: number;
  level_1_area: string;
  level_2_area: string;
  latitude: number;
  longitude: number;
  scraped_at: string;
  publication_date: string;
  price_clf: number;
}

export interface PropertySettingUpMissingData {
  basic_services: boolean;
  for_rental: boolean;
  for_sale: boolean;
  photos: boolean;
  price_rental: boolean;
  price_sale: boolean;
  scheduled_photos_rent: boolean;
  scheduled_photos_sale: boolean;
  basic_characteristics: boolean;
}

export interface EditPropertyDetails {
  year_built?: string | number;
  property_details: {
    m_construidos?: number;
    m_terreno?: number;
    dormitorios?: number;
    banos?: number;
    estacionamientos?: number;
    parking_numbers?: string;
    bodega?: boolean;
    warehouse_number?: string;
    furnished?: "non" | "fully";
    mascotas?: boolean;
    has_air_conditioning?: boolean;
    piscina?: boolean;
    has_balcony?: boolean;
    has_laundry_room?: boolean;
    laundry_capacity?: boolean;
    condominio?: boolean;
    calefaccion?: boolean;
  }[];
  association_amenities?: {
    has_bbq_area: boolean;
    has_elevator: boolean;
    has_gym: boolean;
    has_pet_area: boolean;
    has_concierge: boolean;
    has_patio: boolean;
    has_sauna: boolean;
    has_laundry: boolean;
    has_party_room: boolean;
    has_cinema: boolean;
    has_playground: boolean;
    has_gourmet_space: boolean;
  };
}
