declare global {
  interface Window {
    dataLayer?: any[];
    gtag: (...args: any[]) => void;
  }
}

type EventDetail = {
  category: string;
  action: string;
};

const analytics = {
  initialize: (analyticsId: string) => {
    const analyticsScript = document.createElement("script");
    analyticsScript.async = true;
    analyticsScript.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
    document.head.appendChild(analyticsScript);
    const loadAnalytics = document.createElement("script");
    loadAnalytics.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${analyticsId}');`;
    document.head.appendChild(loadAnalytics);
  },

  identify(userId: string) {
    if (window.gtag) {
      // update gtag config with user id
      window.gtag("set", { user_id: userId });
    }
  },
  event(name: string, data: EventDetail = {} as EventDetail) {
    if (window.gtag) {
      //console.log(`Event ${name} with data ${JSON.stringify(data)}`);
      window.gtag("event", name, data);
    }
  },
};

export default analytics;
