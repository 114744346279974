import { makeStyles, Theme } from "@material-ui/core";

const useSubtitleStyles = makeStyles<Theme>((theme) => ({
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.6,
  },
}));

export default useSubtitleStyles;
