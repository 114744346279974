import { useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { formatDate } from "apps/tenant/utils/utils";
import { useGetCurrentUser } from "hooks";

import usePropertyEndOfContract from "../propertyEndOfContract";
import {
  ACTIVE_CONTRACT_REQUEST_OPTIONS,
  ACTIVE_END_PROCESS_REQUEST_BY_LANDLORD_OPTIONS,
  ACTIVE_END_PROCESS_REQUEST_BY_TENANT_OPTIONS,
  CREATE_REPAIR_REQUEST_OPTIONS,
  LANDLORD_REQUESTS_OPTIONS_KEYS,
  OTHER_REQUEST_OPTIONS,
} from "./constants";

interface Props {
  propertyId: number;
}

const usePropertyLandlordRequests = ({ propertyId }: Props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { loading: loadingUserData } = useGetCurrentUser();
  const { isLoadingEndOfContractTarget, endOfContractStatus } =
    usePropertyEndOfContract({
      propertyId,
    });

  const requestsCardsCallbacks = useMemo(
    () => ({
      [LANDLORD_REQUESTS_OPTIONS_KEYS.INITIALIZE_CLOSE_CONTRACT]: () =>
        history.push(`${match.url}/end-contract`),
      [LANDLORD_REQUESTS_OPTIONS_KEYS.END_OF_CONTRACT_SUMMARY]: () =>
        history.push(`${match.url}/end-contract`),
      [LANDLORD_REQUESTS_OPTIONS_KEYS.CREATE_REPAIR_REQUEST]: () =>
        history.push(`${match.url}/repairs-request`),
      [LANDLORD_REQUESTS_OPTIONS_KEYS.OTHER_REQUEST]: () =>
        window.open(
          "https://houm.kustomer.help/es/contact/otras-solicitudes-pp-r1fy_UYHh"
        ),
    }),
    [history, match.url]
  );

  const assignableRequestsCards = useMemo(() => {
    if (endOfContractStatus?.contract_id) {
      return [
        ...OTHER_REQUEST_OPTIONS,
        ...CREATE_REPAIR_REQUEST_OPTIONS,
        ...(endOfContractStatus?.status === "NOT_STARTED"
          ? ACTIVE_CONTRACT_REQUEST_OPTIONS
          : []),
        ...(endOfContractStatus?.status === "IN_PROCESS" &&
        endOfContractStatus.requested_by_user === "LANDLORD"
          ? ACTIVE_END_PROCESS_REQUEST_BY_LANDLORD_OPTIONS
          : []),
        ...(endOfContractStatus?.status === "IN_PROCESS" &&
        endOfContractStatus.requested_by_user === "TENANT"
          ? ACTIVE_END_PROCESS_REQUEST_BY_TENANT_OPTIONS
          : []),
      ];
    }
    return [...OTHER_REQUEST_OPTIONS];
  }, [endOfContractStatus]);

  const requestsCardsCollection = useMemo(() => {
    const hasAvailableCards = assignableRequestsCards.length;
    const cardsFormatter = (cardItem) => {
      const payload = {
        ...cardItem,
        onClick: requestsCardsCallbacks[cardItem.key],
      };
      if (
        cardItem.key === LANDLORD_REQUESTS_OPTIONS_KEYS.END_OF_CONTRACT_SUMMARY
      ) {
        const endOfContractDate = new Date(endOfContractStatus?.estimated_date);
        return {
          ...payload,
          description: cardItem.description({
            date: formatDate(endOfContractDate),
          }),
        };
      }
      return payload;
    };
    return hasAvailableCards
      ? assignableRequestsCards.map(cardsFormatter)
      : assignableRequestsCards;
  }, [
    assignableRequestsCards,
    endOfContractStatus?.estimated_date,
    requestsCardsCallbacks,
  ]);

  return {
    loadingUserData,
    hasRequestsCards: !!requestsCardsCollection.length,
    requestsCardsCollection,
    isLoadingEndOfContractTarget,
    isLoadingData: loadingUserData || isLoadingEndOfContractTarget,
  };
};

export default usePropertyLandlordRequests;
