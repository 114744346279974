import { compareDesc } from "date-fns";

import { Box } from "@material-ui/core";

import { IVisit } from "apps/tenant/models/Visit";

import { ViewModes } from "../../utils/types";
import CalendarView from "./components/CalendarView";
import VisitsList from "../VisitsList";
import VisitsLinks from "../VisitsLinks";

interface Props {
  selectedDate: Date;
  setSelectedDate: (e: Date) => void;
  viewMode: ViewModes;
  visits: IVisit[];
}

const MobileView = ({
  selectedDate,
  setSelectedDate,
  viewMode,
  visits,
}: Props) => (
  <Box>
    {viewMode === "calendar" && (
      <CalendarView
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        visits={visits}
      />
    )}
    {viewMode === "list" && (
      <VisitsList
        visits={visits.filter(
          (visit) => compareDesc(new Date(), visit.date) === 1
        )}
        showMonthHeaders
      />
    )}
    <VisitsLinks />
  </Box>
);

export default MobileView;
