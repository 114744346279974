import { ReactElement } from "react";

import { EvaluatorPages } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import { classes } from "apps/lead/components/EvaluatorForm/HeaderForm/HeaderStyles";

interface Props {
  title: string;
  stepTitle?: string;
  subtitle?: string | ReactElement;
  activeStep?: number;
  steps?: number;
}

const Header = ({ title, subtitle, stepTitle }: Props) => {
  const { state } = useEvaluation();
  const { activeStep: currentStep, page, steps: totalSteps } = state;

  return (
    <div className={classes.headerContainer}>
      <p className={classes.headerSteps}>
        {page === EvaluatorPages.FORM &&
          `Paso ${currentStep + 1} de ${totalSteps}: `}
        {stepTitle ?? title}
      </p>
      <div className={classes.headerTitleContainer}>
        <h1 className={classes.headerTitle}>{title}</h1>
        {subtitle && <p className={classes.headerSubtitle}>{subtitle}</p>}
      </div>
    </div>
  );
};

export default Header;
