import { makeStyles, Theme } from "@material-ui/core/styles";

const CreateViewStyles = makeStyles((theme: Theme) => ({
  formContent: {
    position: "relative",
    maxWidth: 480,
    border: `1px solid ${theme.palette.grey[75]}`,
    borderRadius: 8,
    margin: theme.spacing(8, "auto"),
    padding: theme.spacing(5, 8.5, 3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2),
      border: "unset",
    },
  },
  logo: {
    maxWidth: 88,
    display: "block",
    margin: theme.spacing(1, "auto"),
  },
  stepperPosition: {
    position: "absolute",
    right: 12,
    top: 20,
  },
}));

export default CreateViewStyles;
