import { CountryUpperCase, Currency } from "models/Countries";
import { OldCurrencyType } from "../models/Currency";

export const countryCurrency = (
  country: CountryUpperCase,
  currency: OldCurrencyType | Currency
) => {
  switch (country) {
    case "Colombia":
      return "COP";
    case "Mexico":
      return "MXN";
    case "Chile":
    default:
      return currency === "pesos" || currency === "CLP" ? "CLP" : "CLF";
  }
};

export const IsoLenguaje = {
  Chile: "es-CL",
  Colombia: "es-CO",
  Mexico: "es-MX",
};

export const parsePrice = (
  value: number,
  currency: Currency,
  country: CountryUpperCase,
  showZero = false
) => {
  const formatter = new Intl.NumberFormat(IsoLenguaje[country], {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  if (value || showZero) {
    return currency === "CLF"
      ? `${formatter.format(value).replace("CLF", "")} UF`
      : formatter.format(value);
  }
  return "N/A";
};
