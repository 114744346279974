import { Theme, makeStyles } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useSupplyPropertyInfoStyles = makeStyles<Theme>((theme) => ({
  supplyContainerRoot: {
    width: "100%",
    padding: theme.spacing(3, 2),
    boxShadow: "3px 4px 15px rgba(38, 50, 56, 0.15)",
    backgroundColor: "white",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      backgroundColor: "transparent",
      margin: theme.spacing(0),
      padding: theme.spacing(0),
      boxShadow: "none",
      clipPath: "none",
    },
  },
  infoContainer: {
    marginTop: theme.spacing(0),
    borderRadius: 8,
    backgroundColor: "white",
    gap: 24,
    padding: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
    },
  },
  statsContainer: {
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
      gap: 24,
    },
  },
  bankTextContainer: {
    display: "contents",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    width: "100%",
  },
  bankText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
}));

export default useSupplyPropertyInfoStyles;
