import { Typography } from "@material-ui/core";
import useDisclaimerFilesStyles from "./DisclaimerFilesStyles";

const DisclaimerFiles = () => {
  const classes = useDisclaimerFilesStyles();
  return (
    <Typography className={classes.text}>
      Sube el documento solicitado en formato{" "}
      <strong>pdf. png. jpg. o jpeg.</strong> con un{" "}
      <strong>peso máximo de 3MB</strong> cada uno.
    </Typography>
  );
};

export default DisclaimerFiles;
