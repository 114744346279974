import { useMemo } from "react";
import clx from "classnames";

import { Box } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import { useNegotiations } from "context/negotiationsContext/negotiationsContext";
import { formatPrice } from "context/negotiationsContext/utils";
import useResumeOfferCardStyles from "./ResumeOfferCardStyles";
import { priceReason } from "context/negotiationsContext/constants";

const ResumeOfferCard = () => {
  const { state } = useNegotiations();
  const { currentOffer, responseToOffer } = state;
  const { amount, currency, paymentMethod, createdAt } = currentOffer;
  const {
    response,
    reason,
    lastAmount,
    currency: currencyResponse,
  } = responseToOffer;
  const classes = useResumeOfferCardStyles();

  const formatedCreatedAt = useMemo(
    () => new Date(createdAt).toLocaleDateString(),
    [createdAt]
  );

  const displayIcon =
    response === "Aceptada" ? (
      <CheckIcon className={classes.icon} />
    ) : (
      <CloseIcon className={classes.icon} />
    );

  const statusTittle = (
    <Box
      className={clx(
        classes.status,
        { [classes.accepted]: response === "Aceptada" },
        { [classes.rejected]: response === "Rechazada" }
      )}
    >
      {displayIcon}
      Oferta {response.charAt(0).toUpperCase() + response.slice(1)}
    </Box>
  );

  return (
    <Box className={classes.resumeOfferCard}>
      <Box className={classes.offerStatus}>{statusTittle}</Box>
      <Box className={classes.offerDetails}>
        {response === "Rechazada" && (
          <Box className={classes.rejectDetails}>
            <Box className={classes.resumeTittle}>Motivo del rechazo</Box>
            <Box className={classes.epigraph}>{reason}</Box>
            {priceReason === reason && (
              <Box className={classes.epigraph}>
                Monto mínimo a negociar:
                <span className={classes.value}>
                  {formatPrice(lastAmount, currencyResponse)}
                </span>
              </Box>
            )}
          </Box>
        )}
        {response === "Aceptada" && (
          <Box className={classes.resumeTittle}>Resumen de la respuesta</Box>
        )}
        <Box className={classes.epigraph}>
          Precio Ofertado:
          <span className={classes.value}>{formatPrice(amount, currency)}</span>
        </Box>
        <Box className={classes.epigraph}>
          Forma de pago:
          <span className={classes.value}>{paymentMethod}</span>
        </Box>
        <Box className={classes.epigraph}>
          Fecha oferta:
          <span className={classes.value}>{formatedCreatedAt}</span>
        </Box>
      </Box>
    </Box>
  );
};

export default ResumeOfferCard;
