import { EndHoumContractResponse } from "apps/landlord/services/propertyContractServiceV2/types";

const parseEndHoumContractRequest = (data: EndHoumContractResponse) => ({
  id: data.id,
  contractId: data.contract_id,
  createdAt: data.created_at,
  status: data.status,
});

export default parseEndHoumContractRequest;
