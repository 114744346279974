import { useGetToken } from "hooks";
import { useQuery, UseQueryResult } from "react-query";
import { useHoumSnackBar } from "libs/houm-snackbar";

import { ITenantContract } from "apps/tenant/models/Contract";

import tenantContractService from "../../services/tenantContractService";
import { parseTenantContract } from "./parsers";
import { ERROR_HANDLER_MESSAGES } from "../../constants";

const useContract = (isSilent = true) => {
  const { getToken } = useGetToken();
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const { data, isLoading, isError }: UseQueryResult<ITenantContract, Error> =
    useQuery<ITenantContract, Error>(
      "tenantContract",
      async () => {
        const authToken = await getToken();
        const response = await tenantContractService.getContract(authToken);
        return parseTenantContract(response.data);
      },
      {
        retry: false,
        onError: (e) => {
          if (!isSilent) {
            enqueueHoumSnackBar({
              body:
                ERROR_HANDLER_MESSAGES.contracts[e.message] ||
                ERROR_HANDLER_MESSAGES.UNHANDLED_ERROR,
              type: "error",
            });
          }
        },
      }
    );

  return { data, isLoading, isError };
};

export default useContract;
