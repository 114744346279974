import DefaultTenantLayout from "apps/lead/layout/DefaultTenantLayout";
import CodebtorForm from "./CodebtorForm";

export default function () {
  return (
    <DefaultTenantLayout>
      <CodebtorForm />
    </DefaultTenantLayout>
  );
}
