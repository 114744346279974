import { useHistory } from "react-router-dom";

import { IoHeartOutline as OutlineHeartIcon } from "@houm-com/ui-icons";
import { Box, Typography } from "@material-ui/core";
import { ArrowForwardIos as ArrowIcon } from "@material-ui/icons";

import useGetFavorites from "apps/account/pages/Favorites/hooks/useGetFavorites";
import Spinner from "Components/Shared/partials/Spinner";

import useFavoritesStyles from "./FavoritesStyles";

const Favorites = () => {
  const classes = useFavoritesStyles();
  const history = useHistory();
  const { isLoading, data } = useGetFavorites("rent");
  if (isLoading) return <Spinner />;

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Propiedades favoritas</Typography>
      <div className={classes.itemContainer}>
        <Box
          className={classes.item}
          onClick={() => history.push("/account/favorites")}
        >
          <div className={classes.iconContainer}>
            <OutlineHeartIcon size="30" className={classes.icon} />
          </div>
          <div className={classes.textContainer}>
            <div>
              <Typography className={classes.itemTitle}>Favoritas</Typography>
              <Typography className={classes.itemText}>
                Guardadas: {data?.length}
              </Typography>
            </div>
            <ArrowIcon className={classes.arrowIcon} />
          </div>
        </Box>
      </div>
    </div>
  );
};

export default Favorites;
