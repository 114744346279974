import { useState } from "react";
import { useHistory } from "react-router-dom";

import { EvaluationCreatedResponse } from "apps/lead/services/EvaluationService/types";
import { useEvaluation } from "apps/lead/context/evaluationContext/EvaluationContext";
import { parseDataUploadFiles } from "apps/lead/utils/parseEvaluatorFormData";
import { useCreateCodebtor } from "apps/lead/hooks/useCreateCodebtor";
import { useUploadFiles } from "apps/lead/hooks/useUploadFiles";

import { useCreateEvaluation } from "./useCreateEvaluation";
import { renderSwalAlertOnError } from "../utils/utils";
import { parseCodebtorData } from "../utils/parsers";
import { UploadFilesDataType } from "../utils/types";

export const useEvaluatorForm = () => {
  const history = useHistory();
  const { state } = useEvaluation();
  const {
    mainApplicant,
    firstCodebtor,
    secondCodebtor,
    codebtorAnswer,
    property: { country },
  } = state;
  const { mutate: createEval, isLoading } = useCreateEvaluation();
  const { mutate: uploadFiles, isLoading: isLoadingFiles } = useUploadFiles();
  const { mutate: createCodebtor, isLoading: isLoadingCodebtor } =
    useCreateCodebtor();
  const [isSuccess, setIsSuccess] = useState(false);

  const doUpdateFiles = (dataFiles: UploadFilesDataType, fnSuccess = null) => {
    uploadFiles(dataFiles, {
      onSuccess: () => (fnSuccess ? fnSuccess() : setIsSuccess(true)),
      onError: () => {
        renderSwalAlertOnError(
          "Error en la carga de archivos",
          () => doUpdateFiles(dataFiles, fnSuccess),
          history
        );
      },
    });
  };

  const newDoCreateCodebtor = (
    isFirstCodebtor: boolean,
    evaluation: EvaluationCreatedResponse
  ) => {
    const newUser = isFirstCodebtor ? firstCodebtor : secondCodebtor;
    const codebtor = parseCodebtorData(newUser, country, evaluation.id);
    createCodebtor(codebtor, {
      onSuccess: (e) => {
        doUpdateFiles(
          parseDataUploadFiles(e.id, evaluation.id, newUser, country),
          isFirstCodebtor && codebtorAnswer.secondCodebtorAnswer
            ? () => newDoCreateCodebtor(false, evaluation)
            : null
        );
      },
      onError: () => {
        renderSwalAlertOnError(
          "Error en la creación del codeudor",
          () => newDoCreateCodebtor(isFirstCodebtor, evaluation),
          history
        );
      },
    });
  };

  const doCreateEval = () => {
    createEval(state, {
      onSuccess: (e) => {
        doUpdateFiles(
          parseDataUploadFiles(e.person.id, e.id, mainApplicant, country),
          codebtorAnswer.firstCodebtorAnswer
            ? () => newDoCreateCodebtor(true, e)
            : null
        );
      },
      onError: () => {
        renderSwalAlertOnError(
          "Error en la creación de la evaluación",
          doCreateEval,
          history
        );
      },
    });
  };

  return {
    doCreateEval,
    isSubmitting: isLoading || isLoadingFiles || isLoadingCodebtor,
    isSuccess,
  };
};

export default useEvaluatorForm;
