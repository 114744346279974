import { makeStyles, Theme } from "@material-ui/core";
import {
  DESKTOP_HEADER_HEIGHT,
  DESKTOP_PADDING,
  MOBILE_HEADER_HEIGHT,
} from "apps/landlord/constants/layout";

export default makeStyles((theme: Theme) => ({
  navbarDesktopWrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: theme.zIndex.appBar,
    width: "100%",
    height: "auto",
    backgroundColor: "white",
    boxShadow: "3px 4px 20px rgba(38, 50, 56, 0.15)",
    "& > a:first-child": {
      marginRight: 40,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  linksContentDesktop: {
    maxWidth: 1280,
    margin: theme.spacing(0, "auto"),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    zIndex: theme.zIndex.appBar,
    backgroundColor: "white",
    height: MOBILE_HEADER_HEIGHT,
    [theme.breakpoints.up("sm")]: {
      height: DESKTOP_HEADER_HEIGHT,
      padding: theme.spacing(0, DESKTOP_PADDING),
    },
    [theme.breakpoints.up("lg")]: {
      padding: 0,
    },
  },
  wrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      flexGrow: 1,
      justifyContent: "flex-start",
    },
  },
  logo: {
    marginLeft: 10,
    height: 40,
  },
  navbarMenuLeft: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  navbarMenuItem: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.5em",
    color: theme.palette.grey[900],
    padding: theme.spacing(1.2),
    borderRadius: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
      color: theme.palette.grey[900],
    },
  },
  rightContainer: {
    display: "flex",
    gap: theme.spacing(2.5),
    alignItems: "center",
  },
}));
