import { useState } from "react";

export enum STEPS {
  Selection = 0,
  Confirmation = 1,
}

const useRepairsRequestSteps = (closeDrawer: () => void) => {
  const [currentStep, setCurrentStep] = useState(STEPS.Selection);

  const handleNextStep = async () => {
    switch (currentStep) {
      case STEPS.Selection:
        setCurrentStep(STEPS.Confirmation);
        return null;
      case STEPS.Confirmation:
        closeDrawer();
        return null;
      default:
        return null;
    }
  };

  const handleError = () => {
    setCurrentStep(STEPS.Selection);
  };

  return {
    currentStep,
    handleNextStep,
    handleError,
  };
};

export default useRepairsRequestSteps;
