import classes from "./InfoCardContentStyles";

interface Props {
  content: string;
}

const InfoCardContent = ({ content }: Props) => (
  <div className={classes.container}>{content}</div>
);

export default InfoCardContent;
