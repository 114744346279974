import { BankAccountResponse } from "apps/landlord/services/bankAccountService/types";
import { CountryUpperCase } from "models/Countries";
import { accountTypes } from "utils";

import { BankAccount } from "./types";

const parseBank = (
  data: BankAccountResponse,
  country: CountryUpperCase
): BankAccount => ({
  id: data?.id ?? null,
  bankCode: data?.bank_code ?? null,
  accountNumber: data?.account_number ?? null,
  accountType: data?.account_type
    ? accountTypes[country].find(
        (accountType) => accountType.code === data.account_type
      )?.name
    : null,
  holder: data?.holder ?? null,
  createdAt: data?.created_at ?? null,
  updatedAt: data?.updated_at ?? null,
  deleted: data?.deleted ?? null,
  identifier: data?.identifier ?? null,
  bankName: data?.bank_name ?? null,
  document: data?.document ?? null,
  email: data?.email ?? null,
  personId: data?.person_id ?? null,
  accountTypeLegacy: data?.account_type ?? null,
  documentType: data?.document_type ?? null,
  registered: data?.registered ?? null,
  modifierEmail: data?.modifier_email ?? null,
  clabeNumber: data?.clabe_number ?? null,
  city: data?.city ?? null,
});

export default parseBank;
