import { compareAsc } from "date-fns";

import { IVisit, VisitStatus } from "apps/tenant/models/Visit";
import {
  IVisitResponse,
  VisitState,
} from "apps/tenant/services/visitsService/types";

const getVisitStatus = (state: VisitState, reason: string): VisitStatus => {
  switch (state) {
    case "Cancelled":
      return reason === "Reserved" ? "reserved" : "cancelled";
    default:
      return "ok";
  }
};

export const parseVisitData = (data: IVisitResponse[]): IVisit[] =>
  data
    .map((visit) => ({
      cancelReason: visit.cancel_comment,
      city: visit.property_city,
      commune: visit.commune,
      country: visit.property_country,
      date: new Date(visit.visit_date_time),
      fullLocation: `${visit.commune}, ${visit.property_city}, ${visit.property_country}`,
      houmerName: visit.appraisers_name,
      index: visit.index,
      propertyId: visit.property_id,
      propertyRegion: visit.property_region,
      visitAddress: visit.visit_address,
      status: getVisitStatus(visit.visit_state, visit.cancel_reason_type),
    }))
    .sort((a, b) => compareAsc(a.date, b.date));

export default parseVisitData;
