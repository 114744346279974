import {
  ChangeEvent,
  InputHTMLAttributes,
  KeyboardEventHandler,
  MutableRefObject,
  KeyboardEvent,
} from "react";

interface SingleDigitInputProps {
  callback?: () => void | null;
  digitsAmount: number;
  prefix?: string;
  inputRef: MutableRefObject<HTMLInputElement>;
  onChange?: (e: string) => void;
}

const useSingleDigitInput = ({
  callback,
  digitsAmount,
  prefix = "code-",
  inputRef,
  onChange,
}: SingleDigitInputProps) => {
  const prefixRegex = new RegExp(`^${prefix}`);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { maxLength, value, id } = e.target;
    const fieldIndex = parseInt(id.replace(prefixRegex, ""), 10);

    if (value.length >= maxLength) {
      // Update the input field
      if (inputRef.current) {
        const refValue = inputRef.current.value;
        inputRef.current.value =
          refValue.slice(0, fieldIndex) + value + refValue.slice(fieldIndex);
        if (onChange) {
          console.log(inputRef.current.value);

          onChange(inputRef.current.value);
        }
      }
      // Check if it's not the last input field
      if (fieldIndex < digitsAmount) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[id=${prefix}${fieldIndex + 1}]`
        ) as HTMLInputElement;
        // If not last field, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
        // If last field and callback, call it
        if (inputRef.current.value.length === digitsAmount && callback) {
          callback();
        }
      }
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (
    e: KeyboardEvent<HTMLInputElement>
  ) => {
    const { key } = e;
    if (key === "Backspace") {
      const { id } = e.target as HTMLInputElement;
      const fieldIndex = id.replace(prefixRegex, "");

      // Check if it's not the first input field
      if (parseInt(fieldIndex, 10) > 0) {
        // Update the input field
        const value = inputRef.current?.value;
        if (value?.length > 0) {
          if (value?.length === Number(fieldIndex)) {
            inputRef.current.value = value.slice(0, -1);
          } else if (fieldIndex !== "0") {
            inputRef.current.value =
              value.slice(0, Number(fieldIndex) - 1) +
              value.slice(Number(fieldIndex));
          }
        }
        // Get the previous input field
        const previousSibling = document.querySelector(
          `input[id=${prefix}${parseInt(fieldIndex, 10) - 1}]`
        ) as HTMLInputElement;
        // If found, focus the previous field
        if (previousSibling !== null) {
          previousSibling.focus();
        }
      }
    }
  };

  const getInputProps = (index): InputHTMLAttributes<HTMLInputElement> => ({
    placeholder: "-",
    id: `${prefix}${index}`,
    onKeyDown: handleKeyDown,
    onChange: handleChange,
    maxLength: 1,
    autoComplete: "off",
  });

  return {
    getInputProps,
  };
};

export default useSingleDigitInput;
