import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  spaceMobileCardRoot: {
    "&:first-child": {
      marginTop: theme.spacing(3),
    },
    borderRadius: 8,
    boxShadow: "4px 4px 30px rgba(0, 0, 0, 0.1)",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  collapsableContainer: {
    padding: theme.spacing(1),
  },
  observationText: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));
