import { makeStyles, Theme } from "@material-ui/core";

const useDetailsContainerStyles = makeStyles((theme: Theme) => ({
  detailsContainer: {
    width: "100%",
    height: 174,
    padding: theme.spacing(1, 2, 2, 2),
  },
  priceText: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
  },
  ggccText: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: "20px",
    color: "#515B60",
  },
  addressText: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "22px",
  },
  regionText: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: "20px",
    color: "#515B60",
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
  ctaButton: {
    padding: 0,
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default useDetailsContainerStyles;
