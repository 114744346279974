import { makeStyles, Theme } from "@material-ui/core";

const useRepairConfirmationStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  stepTitle: {
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 700,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      marginTop: theme.spacing(3),
    },
  },
  stepDescription: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
    "& span": {
      display: "block",
      marginLeft: theme.spacing(0.5),
      fontSize: theme.typography.pxToRem(14),
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
  },
  ctaContainer: {
    marginTop: theme.spacing(3),
  },
  infoContainer: {
    marginTop: theme.spacing(3),
    backgroundColor: "#EAF1FB",
    padding: theme.spacing(2),
  },
  infoTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  infoDescription: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  infoLink: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
  },
  infoLinkIcon: {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
  },
}));

export default useRepairConfirmationStyles;
