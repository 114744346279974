import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: 280,
    flex: 1,
    position: "relative",
  },
  markerText: {
    fontSize: "0.75rem",
    fontWeight: "bold",
    marginLeft: theme.spacing(0.6),
    textAlign: "left",
    width: "100%",
  },
  mapMainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    height: "100%",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      height: "auto",
      position: "sticky",
      top: "15%",
      maxHeight: "70vh",
      minHeight: 0,
    },
  },
  mapContainer: {
    width: "100%",
    flex: 1,
    borderRadius: 10,
    overflow: "hidden",
    boxShadow: "1px 1px 5px #ccc",
    "& .marker": {
      width: 25,
      height: 25,
      cursor: "pointer",
    },
    [theme.breakpoints.up("md")]: {
      "& .marker": {
        width: 40,
        height: 40,
      },
    },
  },
}));

export default useStyles;
