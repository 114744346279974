import useMovements from "./useMovements";

interface Props {
  movementId: string;
}

const useMovement = ({ movementId }: Props) => {
  const { isLoading, isError, data } = useMovements();

  const selected = data?.find((movement) => movement.id === movementId);

  return {
    isLoading,
    isError,
    data: selected,
  };
};

export default useMovement;
