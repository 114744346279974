import { makeStyles, Theme } from "@material-ui/core";

export const useAuthDropdownMenuStyles = makeStyles<Theme>((theme) => ({
  picture: {
    borderRadius: 100,
  },
  user: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: "1.5em",
    color: "#263238",
    textTransform: "capitalize",
  },
  content: {
    display: "flex",
    alignItems: "baseline",
  },
  transparent: {
    color: "transparent",
  },
  menuCustom: {
    "& div": {
      top: "55px !important",
      borderRadius: 16,
    },
    "& div:nth-child(3)": {
      width: 256,
      boxShadow: "0px 4px 15px rgb(38 50 56 / 25%)",
    },
  },
  menuLink: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.5em",
    color: "#263238",
  },
  border: {
    borderTop: `1px solid ${theme.palette.grey[75]}`,
    borderBottom: `1px solid ${theme.palette.grey[75]}`,
  },
  exitLink: {
    "& p": {
      color: theme.palette.primary.main,
    },
  },
  menuItem: {
    "& a": {
      position: "relative",
      top: -3,
    },
    paddingTop: theme.spacing(2),
  },
}));

export default useAuthDropdownMenuStyles;
