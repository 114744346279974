import { useState } from "react";

import { Grid, Typography } from "@material-ui/core";

import NegotiationCard from "./NegotiationCard";
import { useNegotiations } from "context/negotiationsContext/negotiationsContext";
import NegotiationTabs from "./NegotiationsTabs";
import useNegotiationsListStyles from "./NegotiationsListStyles";

const NegotiationsList = () => {
  const { state } = useNegotiations();
  const { property, negotiations } = state;
  const classes = useNegotiationsListStyles();
  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (_, newValue: number) => {
    setValueTab(newValue);
  };

  const tabsNames = ["En proceso", "Cerradas"];
  const statusNegotiation = ["Abierta", "Finalizada"];

  return (
    <Grid container>
      <Grid container item xs={12}>
        <Typography className={classes.titleGeneral}>Negociaciones</Typography>
        <Grid container item>
          <NegotiationTabs
            tabsNames={tabsNames}
            handleChangeTab={handleChangeTab}
            value={valueTab}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        {negotiations
          ?.filter(
            (negotiation) => negotiation.status === statusNegotiation[valueTab]
          )
          .map((negotiation, index) => (
            <NegotiationCard
              key={negotiation.id}
              index={index}
              property={property}
              negotiation={negotiation}
            />
          ))}
      </Grid>
    </Grid>
  );
};

export default NegotiationsList;
