import { makeStyles } from "@material-ui/core";

const useFinishedProcessStyles = makeStyles((theme) => ({
  rootGrid: {
    minHeight: "60vh",
    flexDirection: "column",
    justifyContent: "center",
    gap: "20px",
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(28),
    textAlign: "center",
  },
  description: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(26),
    display: "flex",
    alignItems: "center",
  },
  iconContainer: {
    textAlign: "center",
  },
  iconCheck: {
    width: "55px",
    height: "55px",
    color: "#00982D",
  },
}));

export default useFinishedProcessStyles;
