import { ReactNode } from "react";

import {
  Grid,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import useHeaderStyles from "./HeaderStyles";

interface Props {
  title: string;
  children?: ReactNode;
}

const Header = ({ title, children }: Props) => {
  const classes = useHeaderStyles();
  const theme: Theme = useTheme();
  const isDesktop: boolean = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container className={classes.container} alignItems="center">
      <Grid item md={6} xs={12}>
        <Typography component="h1" className={classes.title}>
          {title}
        </Typography>
      </Grid>
      {children && (
        <Grid
          item
          md={6}
          xs={12}
          container
          justifyContent={isDesktop ? "flex-end" : "flex-start"}
        >
          <Grid item>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
