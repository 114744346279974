import { SweetAlertType } from "sweetalert2";

import { Application, EvaluationStatus } from "models/Evaluation";

export const getLastApplication = (applications: Application[]) => {
  const notRejectedApplications = applications.filter((application) =>
    [EvaluationStatus.APPROVED, EvaluationStatus.PENDING].includes(
      application.status
    )
  );

  const lastApplication =
    Boolean(notRejectedApplications.length) &&
    notRejectedApplications.reduce((r, a) =>
      new Date(r.created_at) > new Date(a.created_at) ? r : a
    );

  return lastApplication;
};

export const getHistorialApplicantions = (applications: Application[]) => {
  const historialApplications = applications.filter((application) =>
    [EvaluationStatus.REJECTED, EvaluationStatus.REEVALUATE].includes(
      application.status
    )
  );
  return historialApplications;
};

interface IError {
  type: SweetAlertType;
  text: string;
}

export const genericError: IError = {
  type: "error",
  text: "Ha ocurrido algo inesperado. Contáctese con su ejecutivo.",
};

export const getError = (error) => ({
  status: "error",
  title: "Error",
  message: error.message,
});
