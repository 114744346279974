import { ReactNode } from "react";
import { isDesktop } from "react-device-detect";

import { Grid, Typography } from "@material-ui/core";
import { cn } from "@houm-com/ui/utils";

import BackNavigation from "apps/landlord/components/BackNavigation";

import useHeaderLayoutStyles from "./HeaderLayoutStyles";

interface Props {
  handleBack: () => void;
  title?: ReactNode;
  subtext?: string;
  titleClassName?: string;
}

const HeaderLayout = ({
  handleBack,
  title,
  subtext,
  titleClassName = null,
}: Props) => {
  const classes = useHeaderLayoutStyles();
  return (
    <Grid container spacing={1}>
      {isDesktop && (
        <Grid item xs={12}>
          <BackNavigation onClick={handleBack} />
        </Grid>
      )}
      {(Boolean(title) || Boolean(subtext)) && (
        <Grid item container>
          <Grid item xs={12}>
            {Boolean(title) && (
              <Typography className={cn(titleClassName, classes.title)}>
                {title}
              </Typography>
            )}
          </Grid>
          {Boolean(subtext) && (
            <Grid item xs={12}>
              <Typography className={classes.subtitle}>{subtext}</Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default HeaderLayout;
