import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";

import { useFormNotifications } from "hooks";
import { ControlledFileUpload } from "Components/Admin/UIComponents/ControlledForm";
import {
  setBrokerageContract,
  stepSubmit,
} from "context/loiFormContext/loiFormActions";
import { useLoiForm } from "context/loiFormContext/loiFormContext";
import fields from "context/loiFormContext/loiFormFields";
import { BrokerageContract } from "context/loiFormContext/loiFormTypes";
import { ALLOWED_MIME_TYPES } from "context/loiFormContext/constants";
import CallToAction from "./components/CustomCallToAction";
import FilteredInput from "./components/FilteredInput";
import {
  brokerageContractValidationSchema,
  requiredText,
} from "./validationSchema";
import LoiFormStepHeader from "./components/LoiFormStepHeader";

export default function BrokerageContractStep() {
  const { state, dispatch } = useLoiForm();
  const stepFields = fields.brokerageContract;

  const methods = useForm<BrokerageContract>({
    resolver: yupResolver(brokerageContractValidationSchema),
    reValidateMode: "onBlur",
  });

  const { errors } = methods.formState;
  useFormNotifications<BrokerageContract>({ errors, requiredText });

  const onSubmit: SubmitHandler<BrokerageContract> = (data) => {
    dispatch(setBrokerageContract({ ...data }));
    stepSubmit(dispatch, state.step, state.availableSteps);
  };

  return (
    <FormProvider {...methods}>
      <LoiFormStepHeader
        title="Contrato de corretaje"
        description="Carga los documentos e ingresa la información necesaria para hacer la venta."
        subtitle="Documentos"
      />

      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FilteredInput fieldInfo={stepFields.signedContract()}>
          <ControlledFileUpload
            id="signedContract"
            label={stepFields.signedContract().label(state.country)}
            allowedMimeTypes={ALLOWED_MIME_TYPES}
            helperText="Lo puedes descargar desde acá"
          />
        </FilteredInput>

        <CallToAction />
      </form>
    </FormProvider>
  );
}
