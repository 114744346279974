import { makeStyles, Theme } from "@material-ui/core";

const iconWidth = 25;

export default makeStyles((theme: Theme) => ({
  spaceMainInfoRoot: {
    display: "grid",
    gridTemplateColumns: `80px 1fr ${iconWidth}px`,
    alignItems: "center",
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: 60,
    borderRadius: 8,
    overflow: "hidden",
  },
  image: {
    width: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  spaceInfoMain: {
    padding: theme.spacing(0, 1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  spaceInfoState: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1),
  },
  spaceInfoStateTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
  },
  text: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.spacing(2),
  },
  icon: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(iconWidth),
  },
  iconButton: {
    padding: 0,
  },
}));
