import { Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";

import { InspectionReportQualifications } from "apps/landlord/services/propertyService/type";
import statusRatingStars from "apps/landlord/utils/inspectionStatusRating";
import { statusRatingLabel } from "apps/landlord/pages/InspectionReport/utils/utils";
import useRatingStatusStyles from "./RatingStatusStyles";

interface Props {
  qualification: InspectionReportQualifications;
}

const RatingStatus = ({ qualification }: Props) => {
  const classes = useRatingStatusStyles();

  return (
    <div className={classes.ratingContainer}>
      <Typography className={classes.label}>
        {statusRatingLabel[qualification]}
      </Typography>
      <Rating
        name="read-only"
        value={statusRatingStars[qualification]}
        readOnly
        size="small"
      />
    </div>
  );
};

export default RatingStatus;
