import { makeStyles, Theme } from "@material-ui/core/";

const useSubscriptionPlanSelectedStyles = makeStyles<Theme>((theme) => ({
  componentContainer: {
    width: "100%",
  },
  cardsContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
    gap: 16,
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
      flexDirection: "row",
      justifyContent: "center",
    },
  },
  contactUsContainer: {
    maxWidth: 263,
    margin: theme.spacing(3, "auto", 2),
    textAlign: "center",
    "& > p, a": {
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 345,
      margin: theme.spacing(10, 0.5, 0),
      textAlign: "left",
      "& > p, a": {
        fontSize: theme.typography.pxToRem(18),
      },
    },
  },
  email: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
}));

export default useSubscriptionPlanSelectedStyles;
