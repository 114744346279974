import { IoDocumentOutline as DocumentIcon } from "@houm-com/ui-icons";

export enum LANDLORD_REQUESTS_OPTIONS_KEYS {
  INITIALIZE_CLOSE_CONTRACT = "INITIALIZE_CLOSE_CONTRACT",
  END_OF_CONTRACT_SUMMARY = "END_OF_CONTRACT_SUMMARY",
  CREATE_REPAIR_REQUEST = "CREATE_REPAIR_REQUEST",
  OTHER_REQUEST = "OTHER_REQUEST",
  END_OF_HOUM_CONTRACT = "END_OF_HOUM_CONTRACT",
}
const REPAIR_REQUEST_HELP_CENTER_LINK =
  "https://help.houm.com/cl/articles/134/a-quien-le-corresponde-los-arreglos-en-la-propiedad-1";

const RepairRequestCardDescription = (
  <>
    Si tu propiedad necesita una reparación podrás solicitarla aquí. Si tienes
    duda de cómo funciona este proceso haz{" "}
    <a href={REPAIR_REQUEST_HELP_CENTER_LINK} target="_blank" rel="noreferrer">
      click aquí
    </a>
    .
  </>
);

export const ACTIVE_CONTRACT_REQUEST_OPTIONS = [
  {
    key: LANDLORD_REQUESTS_OPTIONS_KEYS.INITIALIZE_CLOSE_CONTRACT,
    title: "Solicitar salida de arrendatario",
    description:
      "Para dar termino a tu contrato de arriendo contestando las siguientes preguntas y así realizar la salida a tu propiedad.",
    ctaText: "Solicitar salida",
    icon: DocumentIcon,
  },
];

export const END_HOUM_CONTRACT_REQUEST = [
  {
    key: LANDLORD_REQUESTS_OPTIONS_KEYS.END_OF_HOUM_CONTRACT,
    title: "Finalizar mi contrato con Houm",
    description:
      "Un ejecutivo se pondrá en contacto contigo. Recuerda que al terminar tu contrato con nosotros, nos deslindamos de responsabilidades relacionadas con tu arrendatario.",
    ctaText: "Finalizar contrato",
    icon: DocumentIcon,
  },
];

export const ACTIVE_END_HOUM_CONTRACT_REQUEST = [
  {
    key: LANDLORD_REQUESTS_OPTIONS_KEYS.END_OF_HOUM_CONTRACT,
    title: "Fin de contracto con Houm solicitada",
    description: ({ date }: { date: string }) => (
      <>
        Solicitaste el fin de contrato con houm para el día {date}, te invitamos
        a que veas el resumen de tu solicitud.
      </>
    ),
    ctaText: "Ver resumen",
    icon: DocumentIcon,
  },
];

export const ACTIVE_END_PROCESS_REQUEST_BY_LANDLORD_OPTIONS = [
  {
    key: LANDLORD_REQUESTS_OPTIONS_KEYS.END_OF_CONTRACT_SUMMARY,
    title: "Salida solicitada",
    description: ({ date }: { date: string }) => (
      <>
        Solicitaste la salida de tu arrendatario para el día {date}, te
        invitamos a que veas el resumen de tu solicitud.
      </>
    ),
    ctaText: "Ver resumen",
    icon: DocumentIcon,
  },
];

export const ACTIVE_END_PROCESS_REQUEST_BY_TENANT_OPTIONS = [
  {
    key: LANDLORD_REQUESTS_OPTIONS_KEYS.END_OF_CONTRACT_SUMMARY,
    title: "Salida solicitada",
    description: ({ date }: { date: string }) =>
      `Tu arrendatario solicitó la salida de tu propiedad para el día ${date}, te invitamos a que veas el resumen de su solicitud.`,
    ctaText: "Ver resumen",
    icon: DocumentIcon,
  },
];

export const CREATE_REPAIR_REQUEST_OPTIONS = [
  {
    key: LANDLORD_REQUESTS_OPTIONS_KEYS.CREATE_REPAIR_REQUEST,
    title: "Reparaciones",
    description: RepairRequestCardDescription,
    ctaText: "Solicitar reparación",
    icon: DocumentIcon,
  },
];

export const OTHER_REQUEST_OPTIONS = [
  {
    key: LANDLORD_REQUESTS_OPTIONS_KEYS.OTHER_REQUEST,
    title: "Otro requerimiento",
    description:
      "Si no encuentras la solicitud que estas buscando, completa nuestro formulario para que podamos ayudarte.",
    ctaText: "Completar formulario",
    icon: DocumentIcon,
  },
];
