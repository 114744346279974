export const LOCALE_BASED_MARKETPLACE_URLS = {
  mx: "https://houm.com/mx/renta-departamentos-ciudad-de-mexico",
  co: "https://houm.com/co/arriendo-apartamentos-bogota",
  cl: "https://houm.com/cl/arriendo-departamentos-region-metropolitana",
};

export const favoritesOptionsTypes = {
  mx: [
    { label: "Renta", scheduleType: "rent" },
    { label: "Venta", scheduleType: "sale" },
  ],
  co: [
    { label: "Arriendo", scheduleType: "rent" },
    { label: "Venta", scheduleType: "sale" },
  ],
  cl: [
    { label: "Arriendo", scheduleType: "rent" },
    { label: "Venta", scheduleType: "sale" },
  ],
};
