/* eslint-disable react/prop-types */
import * as React from "react";
import AddressSuggester from "Components/Admin/UIComponents/AddressSuggester";
import { Controller, useFormContext } from "react-hook-form";

interface Props {
  label: string;
  id: string;
  country?: string;
  defaultValue?: string;
  placeholder?: string;
  controllerProps?: React.ComponentProps<typeof Controller>;
  addressSuggesterProps?: React.ComponentProps<typeof AddressSuggester>;
  customClassname?: string;
}

const ControlledAddressText: React.FC<Props> = ({
  label,
  id,
  country,
  defaultValue,
  placeholder,
  addressSuggesterProps,
  customClassname,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={id}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <AddressSuggester
          value={value}
          defaultValue={defaultValue}
          locale={country}
          onChange={onChange}
          id={id}
          label={label}
          placeholder={placeholder}
          textFieldProps={
            customClassname
              ? { helperText: error?.message, className: customClassname }
              : { helperText: error?.message }
          }
          {...addressSuggesterProps}
        />
      )}
    />
  );
};

export default ControlledAddressText;
