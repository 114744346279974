/* eslint-disable max-lines */
/* Keep adding abstractions on this file is not necessary */
import { noop } from "lodash/fp";
import {
  QueryFunctionContext,
  useInfiniteQuery,
  useMutation,
} from "react-query";

import { useGetToken } from "hooks";

import inspectionReportService from "services/inspectionReportService";
import { useHoumSnackBar } from "libs/houm-snackbar";
import { uploadToS3 } from "utils/fileStreaming";
import { InspectionReportCommentFormData } from "./types";
import {
  encodeInspectionReportComment,
  getPhotoFileName,
  parseInspectionReportComments,
  parsePagesToFlatList,
  sanitizePageParam,
} from "./utils";
import {
  FAILED_TO_FETCH_INSPECTION_REPORT_COMMENTS,
  SUCCESS_SUBMIT_INSPECTION_REPORT_COMMENT,
} from "./constants";

const useInspectionReportComments = ({ propertyId, inspectionReportId }) => {
  const { getToken } = useGetToken();
  const { enqueueHoumSnackBar } = useHoumSnackBar();

  const handleRequestInspectionReportComments = async ({
    pageParam,
  }: QueryFunctionContext) => {
    const authToken = await getToken();
    const { getInspectionReportComments } = inspectionReportService(authToken);
    const { data } = await getInspectionReportComments({
      nextPageURL: sanitizePageParam(pageParam),
      propertyId,
      inspectionReportId,
    });
    const parsedInspectionComments = data?.results?.map(
      parseInspectionReportComments
    );
    return {
      ...data,
      results: parsedInspectionComments,
    };
  };

  const handleCreateNewInspectionReportComment = async (
    inspectionReportComment: InspectionReportCommentFormData
  ) => {
    const authToken = await getToken();
    const {
      createInspectionReportComment,
      createUploadCommentPhotoPresignedURL,
    } = inspectionReportService(authToken);
    const filenames = inspectionReportComment.photos.map(getPhotoFileName);
    const { data: presignedURLs } = await createUploadCommentPhotoPresignedURL({
      filenames,
    });
    Object.entries(presignedURLs).forEach(([fileName, presignedUrl], index) => {
      uploadToS3({
        fileContents: inspectionReportComment.photos[index],
        presignedUrl: { ...presignedUrl, filePath: fileName },
      }).catch(noop);
    });
    return createInspectionReportComment({
      propertyId,
      inspectionReportId,
      inspectionReportComment: encodeInspectionReportComment({
        inspectionReportComment,
        filenames,
      }),
    });
  };

  const {
    data: inspectionReportCommentPages = { pages: [] },
    isLoading: isLoadingInspectionReportComments,
    refetch: refetchInspectionReportComments,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["inspectionReportComments", propertyId, inspectionReportId],
    handleRequestInspectionReportComments,
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.next,
      enabled: !!propertyId && !!inspectionReportId,
      onError: () => {
        enqueueHoumSnackBar(FAILED_TO_FETCH_INSPECTION_REPORT_COMMENTS);
      },
    }
  );

  const { mutate: submitNewInspectionReportComment } = useMutation(
    ["newInspectionReportComments", propertyId, inspectionReportId],
    handleCreateNewInspectionReportComment,
    {
      onError: () => {
        enqueueHoumSnackBar(FAILED_TO_FETCH_INSPECTION_REPORT_COMMENTS);
      },
      onSuccess: () => {
        refetchInspectionReportComments();
        enqueueHoumSnackBar(SUCCESS_SUBMIT_INSPECTION_REPORT_COMMENT);
      },
    }
  );

  return {
    hasMoreInspectionReportComments: hasNextPage,
    getMoreInspectionReportComments: fetchNextPage,
    inspectionReportComments: parsePagesToFlatList(
      inspectionReportCommentPages.pages
    ),
    isLoadingInspectionReportComments,
    isLoadingMoreInspectionReportComments: isFetchingNextPage,
    submitNewInspectionReportComment,
  };
};

export default useInspectionReportComments;
