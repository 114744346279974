import TenantLayout from "apps/tenant/layout/TenantLayout";
import Visits from "./Visits";

const index = () => (
  <TenantLayout>
    <Visits />
  </TenantLayout>
);

export default index;
