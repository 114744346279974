import {
  KeysOfPropertyAmenities,
  KeysOfAmenities,
} from "apps/landlord/components/FormAmenities/utils/types";
import { IPropertyDetailsService } from "./types";

const parsePropertyDetailsService = (
  amenities: KeysOfAmenities[],
  propertyAmenities: KeysOfPropertyAmenities[]
): IPropertyDetailsService => ({
  property_details: [
    {
      furnished: propertyAmenities.includes("furnished") ? "fully" : "non",
      mascotas: propertyAmenities.includes("allowsPets"),
      has_air_conditioning: propertyAmenities.includes("hasAirConditioning"),
      piscina: propertyAmenities.includes("hasPool"),
      has_balcony: propertyAmenities.includes("hasBalcony"),
      has_laundry_room: propertyAmenities.includes("hasLaundryRoom"),
      laundry_capacity: propertyAmenities.includes("hasLaundryCapacity"),
      condominio: amenities.includes("condominium"),
      calefaccion: propertyAmenities.includes("heating"),
    },
  ],
  association_amenities: {
    has_bbq_area: amenities.includes("hasBbqArea"),
    has_elevator: amenities.includes("hasElevator"),
    has_gym: amenities.includes("hasGym"),
    has_pet_area: amenities.includes("hasPetArea"),
    has_concierge: amenities.includes("hasConcierge"),
    has_patio: amenities.includes("hasPatio"),
    has_sauna: amenities.includes("hasSauna"),
    has_laundry: amenities.includes("hasLaundry"),
    has_party_room: amenities.includes("hasPartyRoom"),
    has_cinema: amenities.includes("hasCinema"),
    has_playground: amenities.includes("hasPlayground"),
    has_gourmet_space: amenities.includes("hasGourmetSpace"),
  },
});

export default parsePropertyDetailsService;
