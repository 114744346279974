import { IProperty } from "apps/landlord/models/Property";
import { useUser } from "context/userContext";
import enabledFirst from "../../utils";
import PageButton from "../PageButton";
import useLandlordNavigation from "./hooks/useLandlordNavigation";
import useRentalsLandlordNavigation from "./hooks/useRentalsLandlordNavigation";
import useSupplyLandlordNavigation from "./hooks/useSupplyLandlordNavigation";

interface Props {
  propertyData: IProperty;
}

export default function pagesNavigation({ propertyData }: Props) {
  const { user } = useUser();
  const navigation = useRentalsLandlordNavigation(user, propertyData).concat(
    useLandlordNavigation(propertyData),
    useSupplyLandlordNavigation(propertyData)
  );
  const orderedNavigation = navigation.sort(enabledFirst);
  return (
    <>
      {orderedNavigation.map((menuItem) => (
        <PageButton key={menuItem.pageTitle} {...menuItem} />
      ))}
    </>
  );
}
