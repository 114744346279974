import { makeStyles, Theme } from "@material-ui/core";

const useServiceCardStyles = makeStyles((theme: Theme) => ({
  serviceCardRoot: {
    position: "relative",
    padding: theme.spacing(5, 1),
    minWidth: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: 4,
    backgroundColor: "white",
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0.5),
      backgroundColor: "#F7F8FC",
      border: "none",
    },
  },
  tag: {
    position: "absolute",
    top: theme.spacing(1),
    left: 0,
  },
  amount: {
    fontWeight: 700,
  },
  type: {
    fontSize: theme.typography.pxToRem(14),
  },
  info: {
    color: "#A8ADAF",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
  },
}));

export default useServiceCardStyles;
