import { Photos } from "apps/landlord/models/Property";
import {
  HoumOwnerPropertyAnalysis,
  MarketplaceStats,
  PropertyDetailPropertyAnalysis,
  SalesDetailsPropertyAnalysis,
} from "apps/landlord/services/propertyService/type";

export const parseMarketPlaceStats = (stats: MarketplaceStats) => ({
  pageViews: stats.pageviews,
  allVisits: stats.all_visits,
  likeUsers: stats.like_users,
  doneVisits: stats.done_visits,
});

export const parsePhoto = (photos: Photos[]) =>
  photos.map((photo) => ({
    id: photo.id,
    createdAt: photo.created_at,
    updatedAt: photo.updated_at,
    filename: photo.filename,
    featured: photo.featured,
    order: photo.order,
    url: photo.url,
  }));

export const parsePropertyDetails = (
  details: PropertyDetailPropertyAnalysis[]
) =>
  details.map((detail) => ({
    bathrooms: detail.banos,
    currencyCode: detail.currency_code,
    bedrooms: detail.dormitorios,
    latitude: detail.latitud,
    longitude: detail.longitud,
    metersBuilt: detail.m_construidos,
    metersSurface: detail.m_terreno,
    moneyType: detail.tipo_moneda,
    value: detail.valor,
    valueClf: detail.valor_clf,
    moneyTypeSales: detail.tipo_moneda_venta,
    valueSales: detail.valor_venta,
    valueSalesClf: detail.valor_venta_clf,
    scrapedAt: detail.scraped_at,
    status: detail.status,
  }));

export const parsePropertySalesDetails = (
  salesDetail: SalesDetailsPropertyAnalysis
) => ({
  currencyCode: salesDetail.currency_code,
  price: salesDetail.price,
  priceClf: salesDetail.price_clf,
  recommendedPrice: salesDetail.recommended_price,
  recommendedPriceClp: salesDetail.recommended_price_clp,
  status: salesDetail.status,
});

export const parseHoumOwner = (houmOwner: HoumOwnerPropertyAnalysis) => ({
  id: houmOwner.id,
  name: houmOwner.name,
  lastName: houmOwner.last_name,
  phone: houmOwner.phone,
  email: houmOwner.email,
  secondLastName: houmOwner.second_last_name,
});
