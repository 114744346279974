import { useQuery, UseQueryResult } from "react-query";

import newPaymentService from "services/newPaymentService";

interface IMethods {
  method: string;
  subtotal: number;
  methodCommission: number;
  total: number;
}

const usePlaceToPayMethods = (
  movementId: string,
  savePlaceToPayMethods?: (e: IMethods[]) => void
) => {
  const {
    isLoading,
    isError,
    data = [],
  }: UseQueryResult<IMethods[], Error> = useQuery(
    ["place_to_pay_methods"],
    async () => {
      const response = await newPaymentService.getPlaceToPayMethods(movementId);
      const parsedData = response.data.map((method) => ({
        method: method.method,
        subtotal: method.subtotal,
        methodCommission: method.method_fee,
        total: method.total,
      }));
      return parsedData;
    },
    {
      enabled: !!movementId,
      onSuccess: (e) => savePlaceToPayMethods?.(e),
    }
  );

  return {
    isLoading,
    isError,
    data,
  };
};

export default usePlaceToPayMethods;
