import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";

import { IMethod } from "apps/payments/models/Payments";
import { CurrencyCode } from "models/Currencies";
import { Locale } from "models/Countries";

import BreakdownSection from "./components/BreakdownSection";
import PaymentSelector from "./components/PaymentSelector";
import PaymentSummary from "./components/PaymentSummary";

interface Props {
  textValue: string;
  country: Locale;
  methods: IMethod[];
  paymentCompany: "PlacetoPay" | "PayU" | "OpenPay";
  currencyCode: CurrencyCode;
  paymentMethodIcon?: string | null;
  children?: ReactNode;
}

const PaymentMethods = ({
  textValue,
  country,
  methods,
  paymentCompany,
  currencyCode,
  paymentMethodIcon = null,
  children,
}: Props) => {
  const { watch } = useFormContext();
  const method = watch("paymentOption");
  const methodSelected = methods.find((m) => m.method === method);

  return (
    <div>
      <PaymentSelector paymentMethods={methods} country={country} />
      {methodSelected && (
        <>
          <PaymentSummary
            total={methodSelected.total}
            currencyCode={currencyCode}
            paymentMethodIcon={paymentMethodIcon}
          />
          <BreakdownSection
            rentValue={methodSelected.subtotal}
            amountCommission={methodSelected.methodCommission}
            textValue={textValue}
            paymentCompany={paymentCompany}
            currencyCode={currencyCode}
          />
        </>
      )}
      {children}
    </div>
  );
};

export default PaymentMethods;
