import { IBanksResponse } from "apps/tenant/services/bankAccountsService/types";
import { IBank } from "apps/tenant/models/BankAccount";

export const parseBankAccounts = (bankAccounts: IBanksResponse): IBank[] =>
  bankAccounts.banks.map((bank) => ({
    name: bank.name,
    bankId: bank.bank_id,
    code: bank.code,
    deleted: bank.deleted,
  }));

export default parseBankAccounts;
