import { Box, Typography } from "@material-ui/core";

import { IoHelpCircleOutline } from "@houm-com/ui-icons";

import useStatusExplanationStyles from "./StatusExplanationStyles";

const StatusExplanation = () => {
  const classes = useStatusExplanationStyles();

  return (
    <Box className={classes.statusExplanationRoot}>
      <Typography className={classes.title}>
        <IoHelpCircleOutline size={23} className={classes.icon} />
        {"¿Qué significa cada estado? "}
      </Typography>
      <Box className={classes.collapse}>
        <Typography className={classes.explanation}>
          <strong className={classes.paid}>Pagado:</strong>
          {" Tu pago ya fue realizado y corroborado."}
        </Typography>
        <Typography className={classes.explanation}>
          <strong className={classes.review}>Sin información:</strong>
          {" No pudimos obtener esta información."}
        </Typography>
        <Typography className={classes.explanation}>
          <strong className={classes.overdue}>Atrasado:</strong>
          {" Tienes pagos anteriores pendientes."}
        </Typography>
        <Typography className={classes.explanation}>
          <strong className={classes.pending}>Por pagar:</strong>
          {" Saldo pendiente actual."}
        </Typography>
      </Box>
    </Box>
  );
};

export default StatusExplanation;
