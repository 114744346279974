import { useState, useMemo } from "react";
import classNames from "classnames";

import { IoDownloadOutline as DownloadIcon } from "@houm-com/ui-icons";

import { Button, Grid, MenuItem, Select } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import useSummaryReport from "../../hooks/useSummaryReport";
import useSummaryReportDownloaderStyles from "./SummaryReportDownloaderStyles";

const NULL_REPORT_OPTION = {
  value: "hardcoded_placeholder",
  name: "Seleccionar informe",
};

const MIN_DATE_LIMIT = new Date(2022, 11, 31);

const SummaryReportDownloader = () => {
  const classes = useSummaryReportDownloaderStyles();
  const [selectedMonth, setSelectedMonth] = useState(NULL_REPORT_OPTION.value);

  const { handleReportDownload, isLoading } = useSummaryReport();

  const lastTwelveMonthsOptions = useMemo(
    () =>
      Array.from({ length: 12 }, (_, i) => {
        const date = new Date();
        date.setMonth(date.getMonth() - i);
        if (date < MIN_DATE_LIMIT) return null;
        const monthNumber = date.getMonth() + 1;
        const monthName = date.toLocaleString("ES", { month: "long" });
        const year = date.getFullYear();
        return {
          value: `${monthNumber}-${year}`,
          name: `${monthName} ${year}`,
        };
      }).filter(Boolean),
    []
  );

  const options = useMemo(
    () => [NULL_REPORT_OPTION, ...lastTwelveMonthsOptions],
    [lastTwelveMonthsOptions]
  );

  const handleChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleDownload = (currentSelection: string) => {
    const [month, year] = currentSelection.split("-");
    handleReportDownload({ month, year });
  };

  return (
    <Grid container className={classes.summaryReportContainer}>
      <Select
        onChange={handleChange}
        className={classNames(classes.summaryReportField, {
          [classes.summaryReportFieldPlaceholder]:
            selectedMonth === NULL_REPORT_OPTION.value,
        })}
        value={selectedMonth}
        disableUnderline
        IconComponent={ExpandMore}
        classes={{
          select: classes.summaryReportSelectField,
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className={classes.summaryReportSelectOption}
            disabled={option.value === NULL_REPORT_OPTION.value}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
      <Button
        disabled={selectedMonth === NULL_REPORT_OPTION.value || isLoading}
        onClick={() => handleDownload(selectedMonth)}
        className={classes.summaryReportDownloadButton}
      >
        Descargar
        <DownloadIcon className={classes.summaryReportDownloadButtonIcon} />
      </Button>
    </Grid>
  );
};

export default SummaryReportDownloader;
