import { useUser } from "context/userContext";
import { useHistory } from "react-router-dom";

const RedirectIfUser = ({ children, to = "/" }) => {
  const { user } = useUser();
  const history = useHistory();
  if (user) {
    history.replace(to);
    return null;
  }

  return children;
};

export default RedirectIfUser;
