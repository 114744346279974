import { makeStyles, Theme } from "@material-ui/core";

const useLeavingDateStepStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
    [theme.breakpoints.up("sm")]: {
      padding: "unset",
      marginTop: "unset",
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(5),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  motiveContainer: {
    marginTop: theme.spacing(3),
  },
  ctaContainer: {
    marginTop: theme.spacing(2),
  },
  textField: {
    backgroundColor: "#EFF2F3",
    width: "100%",
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
  },
  textFieldLabel: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  unsafeDateWarningBlock: {
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    backgroundColor: "#EFCDCC",
    padding: theme.spacing(1),
    color: "#C22E2A",
  },
  unsafeDateWarningText: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(18),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
  },
  unsafeDateWarningLink: {
    fontWeight: 700,
    color: "#C22E2A",
    textDecoration: "underline",
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(20),
    },
  },
}));

export default useLeavingDateStepStyles;
