import { cn } from "@houm-com/ui/utils";

import { IBillDetails } from "apps/landlord/services/propertiesDuesService/type";

import useBillStatus from "../../hooks/useBillStatus/useBillStatus";
import statuses from "../../hooks/useBillStatus/utils";
import classes from "./TagStyles";

type TagProps = {
  billDetails: Partial<IBillDetails>;
  className?: string;
  error?: boolean;
};

const getTagClasses = (status: string, error: boolean) => {
  if (error) {
    return classes.tagYellow;
  }
  switch (status) {
    case statuses.paid:
      return classes.tagGreen;
    case statuses.unpaid:
      return classes.tagBlue;
    case statuses.failure:
      return classes.tagYellow;
    case statuses.invalid_code:
    case statuses.no_data:
      return classes.tagRedLongText;
    case statuses.past_due:
    default:
      return classes.tagRed;
  }
};

const Tag = ({ billDetails, className = "", error }: TagProps) => {
  const status = useBillStatus(billDetails?.status, error);
  if (
    [statuses.validating_code, statuses.in_process, statuses.pending].includes(
      status
    )
  ) {
    return null;
  }
  return (
    <div className={cn(classes.tag, getTagClasses(status, error), className)}>
      {status}
    </div>
  );
};

export default Tag;
