import { Box, Typography } from "@material-ui/core";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji/Emoji";
import { ContractResponse } from "apps/landlord/services/propertyContractService/types";
import useInitialStepStyles from "./IntroductionStyles";
import {
  LOCALE_BASED_NEW_EOC_CONCERNS,
  LOCALE_BASED_NEW_EOC_INTRO,
} from "./constants";

interface IntroductionNewEoCProps {
  activeContract: ContractResponse;
  handleNext: () => void;
}

const IntroductionNewEoC = ({
  handleNext,
  activeContract,
}: IntroductionNewEoCProps) => {
  const classes = useInitialStepStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>Proceso de salida</Typography>
      <Typography className={classes.description}>
        A continuación podrás <strong>comenzar</strong> con el proceso de
        salida, en este pediremos <strong>la siguiente información</strong>{" "}
        <Emoji emojiIcon="👇" ariaLabel="abajo" />.
      </Typography>
      <Box>
        <ul className={classes.stepsContainer}>
          <li>
            <Typography className={classes.stepTitle}>
              Fecha de entrega
            </Typography>
            <Typography className={classes.stepDescription}>
              {LOCALE_BASED_NEW_EOC_INTRO[activeContract.country]}
            </Typography>
            <Typography className={classes.stepDescriptionConcerns}>
              {LOCALE_BASED_NEW_EOC_CONCERNS[activeContract.country]}
            </Typography>
          </li>
        </ul>
      </Box>
      <Box className={classes.ctaContainer}>
        <Typography className={classes.confirmText}>
          ¿Quieres comenzar el proceso de salida?
        </Typography>
        <Box className={classes.btnContainer}>
          <DesignSystemButton
            label="Empezar"
            variant="primary"
            size="small"
            onClick={handleNext}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default IntroductionNewEoC;
