import { makeStyles } from "@material-ui/core";

const usePriceFormStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  formTitle: {
    fontSize: theme.typography.pxToRem(26),
    lineHeight: theme.typography.pxToRem(34),
    fontWeight: 700,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  priceInput: {
    width: "100%",
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  inputWrapperLabel: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  reservedPropWarn: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    border: "1px solid #DFE5E8",
    backgroundColor: "#ECEFF1",
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export default usePriceFormStyles;
