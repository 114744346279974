interface dataProps {
  address: string;
  comuna: string;
  numero_depto: string;
  street_number: number | string;
  type: string;
}

const renderAddress = (data: Partial<dataProps>) => {
  if (data?.type === "casa") {
    return `${
      data?.address
        ? data?.address.trim().replace(/^\w/, (c) => c.toUpperCase())
        : ""
    } ${data?.street_number ?? ""} ${data?.numero_depto ?? " "},
       ${data?.comuna ?? ""}`;
  }
  return `Dpto. ${data?.numero_depto ?? ""}, ${data?.address ?? ""} ${
    data?.street_number ?? ""
  }, ${data?.comuna ?? ""}`;
};

export default renderAddress;
