import { makeStyles, Theme } from "@material-ui/core";
import { DESKTOP_CONTENT_MAX_WIDTH } from "apps/tenant/constants";

const useHeaderStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    width: "100%",
    marginBottom: theme.spacing(4),
    backgroundColor: "white",
    boxShadow: "3px 4px 15px rgba(38, 50, 56, 0.15)",
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(6.5),
    },
  },
  header: {
    left: 0,
    margin: theme.spacing(0, "auto"),
    padding: theme.spacing(4, 2, 3),
    [theme.breakpoints.up("sm")]: {
      maxWidth: DESKTOP_CONTENT_MAX_WIDTH,
      padding: theme.spacing(4, 5, 3),
    },
  },
  gridItems: {
    gap: 8,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.4,
  },
  dateLabel: {
    fontSize: theme.typography.pxToRem(14),
    color: "#7D8488",
    lineHeight: 1.57,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  date: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[900],
    lineHeight: 1.57,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  planContainer: {
    backgroundColor: "#F7F9F9",
    padding: theme.spacing(0.75, 1),
  },
  planLabel: {
    color: "#7D8488",
  },
}));

export default useHeaderStyles;
