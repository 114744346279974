import { Fragment } from "react";
import { match, NavLink } from "react-router-dom";

import { Divider, Grid, Paper, Typography } from "@material-ui/core";

import useStyles from "./PageDesktopSidebarStyles";

interface MenuItemProps {
  title: string;
  icon: JSX.Element;
  handleClick?: () => void;
  path: string | Object;
  isExternal?: boolean;
  isActiveRoutes?: string[];
  exact?: boolean;
}

interface Props {
  menuItems: MenuItemProps[];
}

const PageDesktopSidebar = ({ menuItems }: Props) => {
  const classes = useStyles();

  const getFlagActiveRoute = (
    isActiveRoutes: string[],
    location,
    match: match,
    path: string
  ) => {
    const routesFilter = isActiveRoutes.filter(
      (route) => route === location.pathname
    );
    if (match?.url === path || routesFilter.length > 0) return true;
    return false;
  };

  const isLastItem = (i: number) => i < menuItems.length - 1;

  return (
    <Paper elevation={4} className={classes.root}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        {menuItems.map(
          (
            {
              title,
              icon,
              handleClick,
              path,
              exact = true,
              isExternal = false,
              isActiveRoutes = [],
            },
            i
          ) => (
            <Fragment key={title}>
              <Grid item xs={12}>
                <NavLink
                  exact={exact}
                  to={path}
                  className={classes.linkButton}
                  activeClassName={classes.linkActive}
                  onClick={handleClick}
                  target={isExternal ? "_blank" : "_self"}
                  isActive={(match, location) =>
                    getFlagActiveRoute(
                      isActiveRoutes,
                      location,
                      match,
                      path as string
                    )
                  }
                >
                  {icon}
                  <Typography className={classes.titleLink}>{title}</Typography>
                </NavLink>
              </Grid>
              {isLastItem(i) && <Divider className={classes.divider} />}
            </Fragment>
          )
        )}
      </Grid>
    </Paper>
  );
};
export default PageDesktopSidebar;
