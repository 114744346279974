import { useMutation } from "react-query";

import { useGetToken } from "hooks";

import exitService from "apps/tenant/services/scheduleService/scheduleService";

interface ScheduleProps {
  beginDate: Date;
  endDate: Date;
  propertyId: number;
  contractId: number;
}

const useScheduleUser = () => {
  const { getToken } = useGetToken();
  const { mutate, isLoading, isSuccess } = useMutation(
    async ({ beginDate, endDate, propertyId, contractId }: ScheduleProps) => {
      const authToken = await getToken();
      return exitService.scheduleExit(authToken, {
        begin_hour: beginDate,
        end_hour: endDate,
        prop: propertyId.toString(),
        contract: contractId,
      });
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
  };
};

export default useScheduleUser;
