import TenantLayout from "apps/tenant/layout/TenantLayout";
import PaymentsList from "./PaymentsList";

export default function () {
  return (
    <TenantLayout>
      <PaymentsList />
    </TenantLayout>
  );
}
