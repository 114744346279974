type HeightInterceptor = (height: number) => any;

export const observeNavbarHeight = (interceptor: HeightInterceptor) => {
  const resizeObserver = new ResizeObserver((entries) => {
    const entry = entries[0];
    const { height } = entry.contentRect;
    interceptor(height);
  });
  return resizeObserver;
};
