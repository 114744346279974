import { makeStyles, Theme } from "@material-ui/core";

const useCloseContractStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  ctaContainer: {
    marginTop: theme.spacing(20),
  },
  modal: {
    padding: theme.spacing(2, 2),
    borderRadius: 16,
  },
  drawer: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      maxWidth: 480,
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(4),
    padding: 0,
    zIndex: 1,
    color: "black",
  },
  container: {
    padding: theme.spacing(3, 2),
  },
  swipable: {},
}));

export default useCloseContractStyles;
