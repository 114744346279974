import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useLandingStyles = makeStyles((theme: Theme) => ({
  landingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      gap: theme.spacing(3),
    },
  },
  landingTextContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    alignItems: "center",
  },
}));

export default useLandingStyles;
