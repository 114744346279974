import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import PayUDetails from "apps/payments/components/PayUDetails";

import withPaymentWrapper from "../PaymentsWrapperHoc";
import { getPayULink } from "./utils/utils";

const PayU = () => {
  const { state } = useRentPayment();

  const onHandlePayment = (e: string) =>
    window.open(getPayULink(state.paymentData.movementId, e), "_blank");

  const OpenPayEnhancement = withPaymentWrapper(PayUDetails);

  return (
    <OpenPayEnhancement
      buttonProps={{
        onHandlePayment,
      }}
    />
  );
};

export default PayU;
