import { Typography } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";

import CardMenuItem from "apps/landlord/components/CardMenuItem";

import useErrorCardStyles from "./ErrorCardStyles";

interface Props {
  onRefetchData: () => void;
  title: string;
}

const ErrorCard = ({ onRefetchData, title }: Props) => {
  const classes = useErrorCardStyles();

  return (
    <CardMenuItem
      title={title}
      infoContainerClassName={classes.infoColumnRental}
      buttonVariant="primary"
      isButtonAvailable
      buttonLabel="Intentar otra vez"
      onViewInfoClick={() => onRefetchData()}
      icon={<ReplayIcon className={classes.iconflip} />}
    >
      <Typography className={classes.text}>
        Al igual que tu, sabemos lo molesto que esto puede ser. Por favor
        intenta recargando la pagina{" "}
        <span role="img" aria-label="hand">
          👇
        </span>
      </Typography>
      <Typography className={classes.text}>
        Si recargaste la pagina y el problema sigue, vuelve a intentarlo más
        tarde.
      </Typography>
    </CardMenuItem>
  );
};

export default ErrorCard;
