import Spinner from "Components/Shared/partials/Spinner";
import useDrawerSpinnerStyles from "./DrawerSpinnerStyles";

const DrawerSpinner = () => {
  const classes = useDrawerSpinnerStyles();
  return (
    <section className={classes.fullSizeSpinner}>
      <Spinner />
    </section>
  );
};

export default DrawerSpinner;
