import { Box, Typography } from "@material-ui/core";

import { ISimilarProperty } from "apps/landlord/pages/Price/hooks/useSimilarProperties/types";
import { AnalysisTypeProperty } from "apps/landlord/pages/Price/utils/types";
import { Locale } from "models/Countries";

import { countryCurrencyByLocale } from "apps/landlord/utils/countryCode";
import { getCurrencyPrice } from "apps/landlord/pages/Price/utils/utils";
import useStyles from "./DetailsInfoCardStyles";
import PriceInfo from "../../../PriceInfo";

interface Props {
  locale: Locale;
  isExternal?: boolean;
  property: ISimilarProperty;
  type?: AnalysisTypeProperty;
}

const DetailsInfoCard = ({
  property,
  locale,
  isExternal = false,
  type = "rent",
}: Props) => {
  const classes = useStyles();
  const { id } = property;
  const propertyAddress = property?.address;
  const external = isExternal && property?.portal;
  const link = property?.marketplaceUrl[type];
  const primaryPrice =
    type === "rent"
      ? property.propertyDetails[0].value
      : property.propertyDetails[0].valueSales;
  const secondaryPrice =
    locale === "cl" && type === "rent"
      ? property.propertyDetails[0].valueClf
      : property.propertyDetails[0]?.valueSalesClf;

  return (
    <Box className={classes.idAndPrice}>
      <Typography className={classes.title}>{propertyAddress}</Typography>
      <a href={link} target="_blank" rel="noreferrer" className={classes.id}>
        {isExternal ? `Publicado por ${external}` : `ID ${id}`}
      </a>
      <PriceInfo
        primaryPrice={getCurrencyPrice(
          primaryPrice,
          countryCurrencyByLocale[locale]
        )}
        secondaryPrice={
          locale === "cl" && getCurrencyPrice(secondaryPrice, "CLF")
        }
      />
    </Box>
  );
};

export default DetailsInfoCard;
