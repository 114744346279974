export const classes = {
  container: "flex flex-col justify-center w-full items-center gap-6",
  buttonHelp: "p-0 underline",
  selectableCard: "!min-h-[124px]",
  infoContainer: "flex items-start gap-1 flex-col text-left",
  title: "text-p16 font-bold font-['Inter']",
  subtitle: "text-p16",
  image: "h-[30px]",
  infoTag: "mt-[-124px]",
};
