import { FC, HTMLAttributes, forwardRef } from "react";

import clsx from "clsx";
import useSelectStyles from "./SelectStyles";

export interface BaseOption {
  label: string;
  value: any;
}

export interface ClassNamesProps {
  root?: string;
  label?: string;
  select?: string;
  message?: string;
}

interface SelectProps
  extends HTMLAttributes<HTMLSelectElement | HTMLInputElement> {
  label: string;
  subLabel?: string;
  classNames?: ClassNamesProps;
  options: BaseOption[];
  disabled?: boolean;
  message?: string;
  error?: boolean;
  required?: boolean;
  placeholder?: string;
}

const Select: FC<SelectProps> = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      id,
      label,
      subLabel,
      classNames,
      options,
      disabled = false,
      message,
      error,
      required = false,
      placeholder = "Seleccione",
      ...props
    },
    ref
  ) => {
    const classes = useSelectStyles({ disabled });

    return (
      <div className={clsx(classes.selectRoot, classNames?.root)}>
        {label && (
          <label
            htmlFor={id}
            className={clsx(classes.label, classNames?.label)}
          >
            {label}
            {required && <span className={classes.required}>*</span>}
          </label>
        )}
        {subLabel && <p>{subLabel}</p>}
        <select
          name={id}
          ref={ref}
          className={clsx(classes.selectBase, classNames?.select)}
          {...props}
          disabled={disabled}
        >
          <option key="unselected">{placeholder}</option>
          {options?.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
        {message && (
          <p
            className={clsx(
              classes.message,
              error && classes.errorMessage,
              classNames?.message
            )}
          >
            {message}
          </p>
        )}
      </div>
    );
  }
);

export default Select;
