import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  backgroundSnackbar: string;
}

const useAlertSnackbarStyles = makeStyles<Theme, Props>((theme) => ({
  rootAlert: {
    backgroundColor: ({ backgroundSnackbar }) => backgroundSnackbar,
    background: ({ backgroundSnackbar }) =>
      `linear-gradient(to right, ${backgroundSnackbar} 0%, white 100%)`,
    borderRadius: 16,
    paddingLeft: theme.spacing(1.25),
    boxShadow: "3px 4px 10px rgba(38, 50, 56, 0.15)",
    height: 45,
    marginBottom: theme.spacing(8),
  },
  alert: {
    backgroundColor: "white",
    borderRadius: 0,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    height: 45,
    display: "flex",
    alignItems: "center",
  },
  icon: {
    pointerEvents: "auto",
  },
}));

export default useAlertSnackbarStyles;
