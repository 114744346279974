import { makeStyles, Theme } from "@material-ui/core";

const useSummaryHeaderStyles = makeStyles<Theme>((theme) => ({
  headerContainer: {
    width: "100%",
    padding: theme.spacing(3, 2),
    backgroundColor: "#33505E",
    alignItems: "center",
    gap: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      gap: 0,
      padding: theme.spacing(4, 5),
    },
  },
  header: {
    gap: theme.spacing(2),
    display: "grid",
  },
  title: {
    color: "white",
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(28),
      lineHeight: 1,
    },
  },
  subtitle: {
    color: "white",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
  searchContainer: {
    justifyContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
  },
  searchContainerItem: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export default useSummaryHeaderStyles;
