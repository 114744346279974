import { IUser } from "apps/account/models/User";
import { UserResponse } from "models/User";
import { formatPhoneInput } from "utils";

export const parseUserData = (user: UserResponse): IUser => ({
  id: user.id,
  name: user.name,
  lastName: user.last_name,
  secondLastName: user.second_last_name,
  rut: user.rut,
  phone: formatPhoneInput(user.phone),
  country: user.country,
  email: user.email,
  role: user.role,
});
