import { useEffect } from "react";
import { useUser } from "context/userContext";
import hotjar from "libs/hotjar";

const Logout = () => {
  const { logout } = useUser();

  useEffect(() => {
    //reset tracking ids;
    hotjar.identify();
    logout({
      returnTo: window.location.origin,
      federated: true,
    });
  }, []);

  return null;
};

export default Logout;
