import { makeStyles } from "@material-ui/core";

export const useButtonReferPartnerStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 100,
    color: "#FFFFFF",
    padding: theme.spacing(1, 4),
    minWidth: 220,
    "&:disabled": {
      color: "#FFFFFF",
    },
  },
  buttonLabel: {
    textTransform: "none",
    fontSize: "1rem",
    lineHeight: "1.5em",
    gap: 8,
  },
}));
