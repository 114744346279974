import { makeStyles, Theme } from "@material-ui/core";

const useWarningTitleStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(0.5),
  },
  text: {
    marginLeft: theme.spacing(2.5),
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1.4,
  },
}));

export default useWarningTitleStyles;
