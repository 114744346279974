import { ReactNode } from "react";

import { Grid, Typography } from "@material-ui/core";

import { Files } from "apps/lead/models/Application";

import UploadFileButton from "../UploadFileButton";
import useLabelFileStyles from "./LabelFileStyles";

interface Props {
  helpTooltip: ReactNode;
  stateFiles: Files[];
  fileType: string;
  label: string;
  uploadFile: (file: Files, stateFiles: Files[], fileType: string) => void;
  removeFile: (file: string, stateFiles: Files[], fileType: string) => void;
}
const LabelFile = ({
  helpTooltip,
  stateFiles,
  fileType,
  label,
  uploadFile,
  removeFile,
}: Props) => {
  const classes = useLabelFileStyles();

  const getLengthFiles = (defectNumber: number) => {
    if (stateFiles.length < defectNumber) {
      return defectNumber;
    }
    return stateFiles.length + 1;
  };

  const handleUploadFile = (file: Files) =>
    uploadFile(file, stateFiles, fileType);

  const handleRemoveFile = (file: string) =>
    removeFile(file, stateFiles, fileType);

  return (
    <>
      <div className={classes.textContainer}>
        <Typography className={classes.label}>{label}</Typography>
        {helpTooltip}
      </div>
      <Grid container spacing={1}>
        {Array.from({ length: getLengthFiles(1) }, (_, index) => (
          <Grid item key={index}>
            <UploadFileButton
              handleUpload={handleUploadFile}
              handleDelete={handleRemoveFile}
              files={stateFiles}
              index={index}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default LabelFile;
