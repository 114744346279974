import { ReactNode } from "react";
import { isBrowser, isMobile } from "react-device-detect";

import Header from "Components/Layout/GlobalHeader";

import BrowserView from "./components/BrowserView";
import MobileView from "./components/MobileView";

interface Props {
  children: ReactNode;
}

const LayoutWithMenu = ({ children }: Props) => (
  <>
    <Header />
    {isBrowser && <BrowserView>{children}</BrowserView>}
    {isMobile && <MobileView>{children}</MobileView>}
  </>
);

export default LayoutWithMenu;
