import { useMutation } from "react-query";

import profileEditService from "apps/account/services/profileEditService/profileEditService";
import { useGetToken } from "hooks";
import { useHoumSnackBar } from "libs/houm-snackbar";

interface IFormData {
  email: string;
}

const useStartEmailChange = () => {
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const { getToken } = useGetToken();
  const { mutate, isLoading, isError } = useMutation(
    async (formData: IFormData) => {
      const authToken = await getToken();
      const response = await profileEditService.startEmailChange(
        authToken,
        formData.email
      );
      return response.data;
    },
    {
      onSuccess: () => {
        enqueueHoumSnackBar({
          body: "",
          head: "Se ha enviado un correo electrónico a tu nueva dirección de correo electrónico. Por favor, sigue las instrucciones para confirmar",
          type: "success",
        });
      },
      onError: () => {
        enqueueHoumSnackBar({
          body: "",
          head: "Ha ocurrido un error, por favor intenta de nuevo",
          type: "error",
        });
      },
    }
  );
  return {
    mutate,
    submitting: isLoading,
    error: isError,
  };
};

export default useStartEmailChange;
