import { Button, CircularProgress, Grid } from "@material-ui/core";

import { useButtonReferPartnerStyles } from "./ButtonReferPartnerStyles";

const ButtonReferPartner = ({ loading }: { loading: boolean }) => {
  const classes = useButtonReferPartnerStyles();

  return (
    <Grid item xs={12} className={classes.buttonContainer}>
      <Button
        type="submit"
        className={classes.button}
        classes={{ label: classes.buttonLabel }}
        disabled={loading}
      >
        Invitar {loading && <CircularProgress size="1rem" color="inherit" />}
      </Button>
    </Grid>
  );
};

export default ButtonReferPartner;
