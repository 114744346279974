import { BACKEND_URL } from "env";
import { createHttp } from "services/helper";

const SummaryReportsService = (token: string) => {
  const httpClient = createHttp(token, BACKEND_URL);
  const getAllPropertiesReport = ({ userId, month, year }) => {
    const API_URL = `/propietary/v2/${userId}/full_breakdown_excel?month=${month}&year=${year}`;
    return httpClient.get(API_URL, {
      responseType: "blob",
    });
  };
  return {
    getAllPropertiesReport,
  };
};

export default SummaryReportsService;
