import { ControlledOptions } from "Components/Admin/UIComponents/ControlledForm";
import { useClosureForm } from "context/closureFormContext/closureFormContext";

import { Props, filter, OptionTypes } from "./utils";

interface OptionsProps extends Props {
  defaultValue: string | number;
  options: OptionTypes[];
  optionsProps?: Partial<React.ComponentProps<typeof ControlledOptions>>;
}

export const FilteredOptions: React.FC<OptionsProps> = ({
  id,
  fieldInfo,
  defaultValue,
  options,
  optionsProps,
}) => {
  const { state } = useClosureForm();
  if (filter(fieldInfo, state.isAdmin, state.propertyData.country)) return null;
  return (
    <>
      <ControlledOptions
        label={fieldInfo.label(state.propertyData.country)}
        id={id}
        options={options}
        defaultValue={defaultValue}
        {...optionsProps}
      />
      <br />
    </>
  );
};

export default FilteredOptions;
