import { useEffect } from "react";

import UserInformationForm from "apps/lead/pages/EvaluatorForm/components/Steps/UserInformationForm";
import SelfieDisclaimer from "apps/lead/pages/EvaluatorForm/components/SelfieDisclaimer";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import {
  setActiveStep,
  setPage,
  setUserInformation,
  setCountry,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";
import { EvaluatorPages } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import Welcome from "apps/lead/pages/EvaluatorForm/components/Steps/Welcome";
import Resume from "apps/lead/pages/EvaluatorForm/components/Steps/Resume";
import Finish from "apps/lead/pages/EvaluatorForm/components/Finish";
import EvaluatorLayout from "apps/lead/layout/EvaluatorLayout";

const EvaluatorForm = () => {
  const {
    state: { activeStep, page },
    dispatch,
  } = useEvaluation();

  useEffect(() => {
    dispatch(setCountry("co"));
  }, []);

  const handleBack = () => {
    if (page === EvaluatorPages.RESUME) {
      dispatch(setPage(EvaluatorPages.WELCOME));
    }
    if (page === EvaluatorPages.FORM) {
      if (activeStep === 0) {
        dispatch(setPage(EvaluatorPages.RESUME));
        dispatch(setActiveStep(0));
        dispatch(setUserInformation(undefined));
      } else dispatch(setActiveStep(activeStep - 1));
    }
  };

  return (
    <EvaluatorLayout handleBack={handleBack}>
      {page === EvaluatorPages.WELCOME && <Welcome />}
      {page === EvaluatorPages.RESUME && <Resume />}
      {page === EvaluatorPages.FORM && <UserInformationForm />}
      {page === EvaluatorPages.SELFIE_DISCLAIMER && <SelfieDisclaimer />}
      {page === EvaluatorPages.FINISH && <Finish />}
    </EvaluatorLayout>
  );
};

export default EvaluatorForm;
