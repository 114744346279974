import { useEffect, useState } from "react";
import { observeNavbarHeight } from "../utils/observers";

const useNavbarHeight = () => {
  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    const navbarElement = document.getElementById("navbar-root");
    const observer = observeNavbarHeight(setNavbarHeight);

    if (navbarElement) observer.observe(navbarElement);

    return () => {
      if (navbarElement) observer.disconnect();
    };
  }, []);

  return navbarHeight;
};

export default useNavbarHeight;
