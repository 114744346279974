import { TFunction } from "i18next";

import { FavoriteProperty } from "apps/account/models/FavoriteProperty";
import { HOUM_COM_URL } from "env";
import { Locale } from "models/Countries";
import { normalizeCommuneName, normalizeRegionName } from "utils/normalizer";

interface IGetPropertyListingUrl {
  property: FavoriteProperty;
  locale: Locale;
  mode: "rent" | "sale";
  translate: TFunction;
}

export const getPropertyListingUrl = ({
  property,
  locale,
  mode,
  translate,
}: IGetPropertyListingUrl) => {
  const { type, region, commune, id } = property;

  const normalizedRegion = normalizeRegionName(region);
  const normalizedCommune = normalizeCommuneName(commune);
  const normalizedType = `${translate(type)}s`;
  const normalizedMode = translate(mode);
  return `${HOUM_COM_URL}/${locale}/${normalizedMode}-${normalizedType}-${normalizedRegion}/${normalizedCommune}/${id}`;
};
