import { InputLabel, Typography } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import useStyles from "./RadioCountryStyles";
import clx from "classnames";

interface Props {
  flag: string;
  id: string;
  value: string;
  tag: string;
}

const RadioCountry = ({ flag, id, value, tag }: Props) => {
  const classes = useStyles();
  const { watch, register } = useFormContext();
  const country = watch("selectCountry");

  return (
    <InputLabel htmlFor={id} className={classes.label}>
      <input
        className={classes.inputCountry}
        {...register("selectCountry")}
        type="radio"
        name="selectCountry"
        value={value}
        id={id}
      />
      <div
        className={clx(classes.boxCountry, {
          [classes.selected]: country === value,
        })}
      >
        <img
          alt={value}
          src={`https://hatscripts.github.io/circle-flags/flags/${flag}.svg`}
          width="20"
        />
        <Typography className={classes.countryName}>{tag}</Typography>
      </div>
    </InputLabel>
  );
};

export default RadioCountry;
