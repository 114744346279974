import { makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles<Theme>((theme: Theme) => ({
  hidden: {
    display: "none",
  },
  button: {
    margin: theme.spacing(1.5),
    textTransform: "none",
  },
  label: {
    fontSize: "0.875rem",
    fontWeight: "bold",
    margin: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
  helperText: {
    margin: theme.spacing(0.5, 0, 1, 1),
    fontSize: "0.75rem",
    lineHeight: 1.4,
  },
  containerFile: {
    margin: theme.spacing(1.5),
    border: `2px solid ${theme.palette.grey[200]}`,
    borderRadius: 9,
    display: "flex",
    alignItems: "center",
  },
  fileIcon: {
    color: theme.palette.grey[200],
  },
  fileName: {
    color: theme.palette.grey[200],
    fontSize: theme.typography.pxToRem(9.24),
    textAlign: "center",
    width: "100%",
  },
  deleteFileUploaded: {
    color: "rgba(176, 190, 197, 0.8)",
    float: "right",
    marginLeft: theme.spacing(7.6),
    cursor: "pointer",
    marginTop: -theme.spacing(7.5),
    position: "absolute",
    fontSize: theme.typography.pxToRem(30),
  },
}));
