import { format } from "date-fns";
import { es } from "date-fns/locale";
import currency from "@houm-com/formats/currency";

import { Box, Typography } from "@material-ui/core";

import useSummary from "apps/landlord/pages/Summary/hooks/useSummary";
import ErrorCard from "apps/landlord/components/ErrorCard";
import { IProperty } from "apps/landlord/models/Property";
import Spinner from "Components/Shared/partials/Spinner";

import SummaryReportDownloader from "../../../SummaryReportDownloader";
import usePaymentSummaryStyles from "./PaymentSummaryStyles";
import PaymentSummaryDetails from "../PaymentSummaryDetails";
import CardInfo from "../../../CardInfo";

interface Props {
  property?: Partial<IProperty>;
}

const PaymentSummary = ({ property = null }: Props) => {
  const actualMonth = format(new Date(), "MMMM", { locale: es });
  const { data, isError, isLoading, refetch } = useSummary(property);
  const classes = usePaymentSummaryStyles();

  return (
    <CardInfo>
      {isLoading && <Spinner />}
      {isError && (
        <ErrorCard
          title="Hubo un problema al obtener el resumen de tus pagos"
          onRefetchData={refetch}
        />
      )}
      {!isError && !isLoading && (
        <Box className={classes.summaryContainer}>
          <SummaryReportDownloader />
          <Box className={classes.paymentContainer}>
            <Box>
              <Typography className={classes.title}>
                Ingresos por tus arriendos de {actualMonth}
              </Typography>
              <Typography className={classes.paymentTotalValue}>
                {currency(data.depositedPayments.pays, data.currency)}
              </Typography>
            </Box>
            <Box className={classes.detailContainer}>
              {data.depositedPayments.pays > 0 && (
                <PaymentSummaryDetails metrics={data} />
              )}
              <Typography className={classes.disclaimer}>
                El detalle específico de cada arriendo que recibas lo podrás
                encontrar dentro de cada propiedad{" "}
                <span role="img" aria-label="house">
                  🏡
                </span>
                .
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </CardInfo>
  );
};

export default PaymentSummary;
