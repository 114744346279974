import { makeStyles, Theme } from "@material-ui/core";

const useBreakdownStyles = makeStyles((theme: Theme) => ({
  breakdownRoot: {
    padding: theme.spacing(3, 2, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  disclaimer: {
    marginTop: theme.spacing(2),
    color: theme.palette.grey["A400"],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.8,
    fontWeight: 400,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },
  strong: {
    color: theme.palette.primary.main,
  },
  collapse: {
    width: "100%",
    "& :first-child": {
      marginTop: 0,
    },
  },
}));

export default useBreakdownStyles;
