import {
  InspectionReportFeaturesNames,
  InspectionReportItemsNames,
} from "services/propertyService/types";

type ItemNameTranslation = {
  label: string;
  value: InspectionReportItemsNames;
};

export const inspectionReportItemsNameTranslation: ItemNameTranslation[] = [
  { label: "Alfombras", value: "carpets" },
  { label: "Conector cable coaxial", value: "coaxial_connectors" },
  { label: "Cortinas", value: "curtains" },
  { label: "Lámparas", value: "lamps" },
  { label: "Conectores de red", value: "network_connectors" },
  { label: "Enchufes", value: "plugs" },
  { label: "Interruptores", value: "switches" },
];

type FeatureNameTranslation = {
  label: string;
  value: InspectionReportFeaturesNames;
};

export const inspectionReportFeaturesNameTranslation: FeatureNameTranslation[] =
  [
    { label: "Extractor", value: "bathroom_extractor" },
    { label: "Muebles-Gabinetes", value: "cabinets" },
    { label: "Chimenea", value: "chimney" },
    { label: "Closets", value: "closets" },
    { label: "Lavaplatos", value: "dishwasher" },
    { label: "Puertas", value: "doors" },
    { label: "Campana extractora", value: "extractor_hood" },
    { label: "Pisos", value: "floors" },
    { label: "Espejo", value: "mirror" },
    { label: "Horno", value: "oven" },
    { label: "Cielos", value: "roofs" },
    { label: "Ducha", value: "shower" },
    { label: "Shower Door (división de vidrio)", value: "shower_door" },
    { label: "Escaleras", value: "stairs" },
    { label: "Inodoro", value: "toilet" },
    { label: "Bañera / Tina", value: "tub" },
    { label: "Ventanales", value: "windows" },
    { label: "Paredes", value: "walls" },
    { label: "Lavamanos", value: "washbasin" },
    { label: "Presión agua", value: "water_pressure" },
    { label: "Encimera", value: "worktop" },
  ];
