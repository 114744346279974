import { makeStyles, Theme } from "@material-ui/core";

const useMobileSubmenuStyles = makeStyles((theme: Theme) => ({
  mobileNested: {
    position: "absolute",
    top: 0,
    zIndex: theme.zIndex.snackbar + 1,
    width: "100%",
    height: "100%",
    padding: theme.spacing(3, 2),
    backgroundColor: "white",
  },
}));

export default useMobileSubmenuStyles;
