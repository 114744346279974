interface EmojiProps {
  ariaLabel: string;
  className?: string;
  emojiIcon: string;
}

export default function Emoji({ emojiIcon, ariaLabel, className }: EmojiProps) {
  return (
    <span className={className} role="img" aria-label={ariaLabel}>
      {emojiIcon}
    </span>
  );
}
