import { makeStyles, Theme } from "@material-ui/core";

const usePhotosSectionStyles = makeStyles((theme: Theme) => ({
  label: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
    "&::after": {
      content: '"*"',
      color: "#FF452B",
      marginLeft: theme.spacing(0.5),
    },
  },
  labelDescription: {
    fontSize: theme.typography.pxToRem(10),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

export default usePhotosSectionStyles;
