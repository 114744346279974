import { useHistory, useRouteMatch } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { Box, Drawer } from "@material-ui/core";

import BackButtonHeader from "apps/landlord/Layout/BackButtonHeader";
import SimpleLayout from "apps/landlord/Layout/SimpleLayout";

import useRepairsRequestPageStyles from "./RepairsRequestPageStyles";
import RepairsRequest from "./RepairsRequest";

export default function () {
  const classes = useRepairsRequestPageStyles();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const rootURL = match.url.replace("/repairs-request", "");
  const handleBack = () => history.push(rootURL);

  if (isMobile) {
    return (
      <SimpleLayout rootClassName={classes.root}>
        <BackButtonHeader handleBack={handleBack} />
        <Box className={classes.container}>
          <RepairsRequest closeDrawer={handleBack} />
        </Box>
      </SimpleLayout>
    );
  }

  return (
    <Drawer
      anchor="right"
      open
      onClose={handleBack}
      classes={{ paper: classes.drawer, root: classes.drawerRoot }}
    >
      <Box className={classes.drawerContent}>
        <RepairsRequest closeDrawer={handleBack} />
      </Box>
    </Drawer>
  );
}
