import { ButtonBase, Grid, SvgIcon } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import fileImage from "assets/images/fileImage.png";
import useStyles from "./FilePreviewStyles";

interface Props {
  src: string;
  onDelete?: (item: string) => void;
  type?: string;
}

const FilePreview = ({ src, onDelete = null, type = "image" }: Props) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.thumb}>
      <div className={classes.thumbInner}>
        {onDelete && (
          <ButtonBase onClick={() => onDelete(src)} className={classes.close}>
            <SvgIcon
              component={Close}
              fontSize="small"
              className={classes.icon}
            />
          </ButtonBase>
        )}
        <img
          className={classes.image}
          src={type.includes("image") ? src : fileImage}
          alt="preview"
        />
      </div>
    </Grid>
  );
};

export default FilePreview;
