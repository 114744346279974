import TenantLayout from "apps/tenant/layout/TenantLayout";
import HelpPage from "./HelpPage";

export default function () {
  return (
    <TenantLayout>
      <HelpPage />
    </TenantLayout>
  );
}
