import { ReactNode } from "react";

import useWelcomeStepStyles from "./WelcomeStepStyles";

interface WelcomeGuidelineProps {
  icon: ReactNode;
  title: string;
  description: string;
}

const WelcomeGuideline = ({
  icon,
  title,
  description,
}: WelcomeGuidelineProps) => {
  const classes = useWelcomeStepStyles();
  return (
    <article className={classes.informationStepGuidelineBox}>
      <section className={classes.informationStepGuidelineIcon}>{icon}</section>
      <section className={classes.informationSetpGuidelineDetails}>
        <p className={classes.informationStepGuidelineHead}>{title}</p>
        <p className={classes.informationStepGuidelineDescription}>
          {description}
        </p>
      </section>
    </article>
  );
};

export default WelcomeGuideline;
