import { ReactNode, useEffect } from "react";
import clx from "classnames";

import useNavbarHeight from "apps/landlord/hooks/useNavbarHeight";
import useSimpleLayoutStyles from "./SimpleLayoutStyles";
import BackButtonHeader from "../BackButtonHeader";

interface Props {
  children: ReactNode;
  onHandleBack?: () => void;
  rootClassName?: string;
}

const SimpleLayout = ({
  children,
  onHandleBack,
  rootClassName = null,
}: Props) => {
  const navbarHeight = useNavbarHeight();
  const classes = useSimpleLayoutStyles({ navbarHeight });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={clx(classes.root, rootClassName)}>
      {onHandleBack && (
        <BackButtonHeader handleBack={onHandleBack} backText="Volver" />
      )}
      <div className={classes.contentRoot}>{children}</div>
    </div>
  );
};

export default SimpleLayout;
