import { AxiosResponse } from "axios";
import qs from "query-string";

import { Locale } from "models/Countries";

import { createHttp, createNoAuthHttp } from "../helper";
import { PAYMENTS_URL, BACKEND_URL } from "../../env";

import { RentPaymentResponse } from "models/Payments";
import {
  IOpenPayInfoResponse,
  IPaymentLinkResponse,
  IPaymentMethodsResponse,
  ISubscriptionMovementsResponse,
  OpenPayPaymentMethodType,
  PayUPaymentMethodType,
} from "./types";

const qsConfig = { skipEmptyString: true, skipNull: true };

export default {
  getPayMovementInfo: (movementId, concept, country = "mx") =>
    createNoAuthHttp(PAYMENTS_URL).get(
      `${country}/v3/movements/income/${concept}/${movementId}`
    ),

  getReceiptsByQuotation: async (quotationId) =>
    createNoAuthHttp(PAYMENTS_URL)
      .get(`v1/quotations/${quotationId}/receipts`)
      .catch(),

  async getSubscriptionMovements(
    country,
    subId,
    mType,
    concept,
    token
  ): Promise<AxiosResponse<ISubscriptionMovementsResponse>> {
    const http = createHttp(token, PAYMENTS_URL);
    // TO DO: waiting for payments feature to correct the "to" date
    return http.get(
      `/${country}/v3/subscriptions/${subId}/movements?type=${mType}&concept=${concept}&from=2020-04-10&to=3000-01-01`
    );
  },

  getAllSubscriptionMovements(country, subId, mType, token) {
    const http = createHttp(token, BACKEND_URL);
    return http.get(
      `/v2/movements/${country}/subscriptions/${subId}?type=${mType}`
    );
  },

  getOpenpayLink: (
    country: Locale,
    movementId: string,
    method: OpenPayPaymentMethodType
  ): Promise<AxiosResponse<IPaymentLinkResponse>> =>
    createNoAuthHttp(PAYMENTS_URL).get(
      `${country}/v3/openpay/get_link?movement_id=${movementId}&method=${method}`
    ),

  getOpenpayInfo: (
    country: Locale,
    params: {
      method?: OpenPayPaymentMethodType;
      movement_id: string;
    }
  ): Promise<AxiosResponse<IOpenPayInfoResponse[]>> =>
    createNoAuthHttp(PAYMENTS_URL).get(
      `${country}/v3/openpay/info?${qs.stringify(params, qsConfig)}`
    ),

  setReservationRefundDeclaration: (
    movementId: string,
    // eslint-disable-next-line default-param-last
    country: Locale = "cl",
    refund: boolean
  ) =>
    createNoAuthHttp(PAYMENTS_URL).patch(
      `${country}/v3/movements/income/reservations/${movementId}`,
      {
        refund_terms: refund,
      }
    ),

  getPlaceToPayMethods: (
    movementId: string
  ): Promise<AxiosResponse<IPaymentMethodsResponse[]>> =>
    createNoAuthHttp(PAYMENTS_URL).get(
      `co/v3/movements/${movementId}/placetopay/get_methods`
    ),

  getPayUMethods: (
    movementId: string
  ): Promise<AxiosResponse<IPaymentMethodsResponse[]>> =>
    createNoAuthHttp(PAYMENTS_URL).get(`mx/v3/payu/${movementId}/get-amounts`),

  getPayULink: (
    country: Locale,
    movementId: string,
    method: PayUPaymentMethodType
  ): Promise<AxiosResponse<IPaymentLinkResponse>> =>
    createNoAuthHttp(PAYMENTS_URL).get(
      `${country}/v3/payu/${movementId}/get-link/${method}`
    ),

  getRentPaymentDetails(
    country: Locale,
    userId: string
  ): Promise<AxiosResponse<RentPaymentResponse>> {
    return createNoAuthHttp(PAYMENTS_URL).get(
      `${country}/v3/people/${userId}/unpaid-movement`
    );
  },
};
