import { makeStyles, Theme } from "@material-ui/core/styles";

const useCallToActionStyles = makeStyles<Theme>((theme) => ({
  root: {
    position: "relative",
    display: "inline-flex",
  },
  subRoot: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useCallToActionStyles;
