import { makeStyles, Theme } from "@material-ui/core";

const usePropertyCardStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: "flex",
    minHeight: 146,
    cursor: "pointer",
    [theme.breakpoints.between("sm", "md")]: {
      height: 270,
    },
    [theme.breakpoints.up("md")]: {
      height: 176,
    },
  },
  cover: {
    width: 96,
    [theme.breakpoints.up("sm")]: {
      height: "100%",
      width: "30%",
    },
  },
  image: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    objectFit: "cover",
    objectPosition: "center center",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: 343,
    [theme.breakpoints.up("sm")]: {
      height: "100%",
      width: "70%",
    },
  },
  card: {
    display: "flex",
    height: "100%",
    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
    justifyContent: "space-between",
    padding: theme.spacing(1, 1.75, 1, 1),
    gap: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      gap: theme.spacing(2),
      "&:last-child": {
        paddingBottom: theme.spacing(2),
      },
      padding: theme.spacing(2, 4, 2, 3),
    },
  },
  content: {
    flex: "1 0 auto",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    gap: theme.spacing(1),
    width: "92%",
  },
  text: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
  rightContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexDirection: "column",
    height: "calc(50% + 6px)",
    width: "8%",
  },
  arrowContent: {
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
  },
}));

export default usePropertyCardStyles;
