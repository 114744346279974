import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useStyles = makeStyles<Theme>((theme) => ({
  detailsContainer: {
    width: "100%",
  },
  sectionTitle: {
    width: "100%",
    textAlign: "left",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.57,
    },
  },
  breakdownContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 0),
    borderTop: `1px solid ${theme.palette.grey[50]}`,
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
  },
  detailLabel: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
  },
  detailNumber: {
    textAlign: "end",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
    fontWeight: 700,
  },
  green: {
    color: theme.palette.success.main,
  },
  red: {
    color: theme.palette.error.main,
  },
}));

export default useStyles;
