import { makeStyles, Theme } from "@material-ui/core";

const useCountryOptionsStyles = makeStyles((theme: Theme) => ({
  countryOptionsRoot: {
    position: "absolute",
    top: 40,
    left: 0,
    zIndex: theme.zIndex.modal,
    width: "fit-content",
    maxWidth: 330,
    maxHeight: 400,
    borderRadius: 4,
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    overflow: "scroll",
    boxShadow: "3px 4px 20px 0px rgba(38, 50, 56, 0.15)",
  },
  countryOption: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 4,
    padding: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:last-child": {
      marginBottom: 0,
    },
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
      "& span": {
        fontWeight: 700,
      },
    },
  },
  flag: {
    margin: 0,
  },
  text: {
    margin: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(16),
    maxWidth: 190,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

export default useCountryOptionsStyles;
