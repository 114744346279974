import useMovements from "./useMovements";

const useCurrentMovement = () => {
  const { isLoading, isError, data } = useMovements();

  const current =
    data?.length > 0 &&
    data?.find((mv) => {
      const agreed = new Date(mv.agreedDate);
      const today = new Date();
      return (
        agreed.getMonth() === today.getMonth() &&
        agreed.getFullYear() === today.getFullYear()
      );
    });

  return {
    isLoading,
    isError: isError || (data && !current),
    data: current,
  };
};

export default useCurrentMovement;
