import { useCallback, useState, useEffect } from "react";

import { useProperty } from "apps/landlord/hooks";
import useSchedulePhotoVisit from "apps/landlord/hooks/scheduler/useSchedulePhotos";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import useSchedulePhotoAvailability from "apps/landlord/hooks/scheduler/useSchedulePhotoAvailability";
import { STEPS } from "./types";
import { getHourOptions } from "./utils";

const useScheduleStep = () => {
  const { propertyData } = useProperty();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<null | number>(null);
  const { setCurrentStep } = useCustomDrawer();

  const { availability, isLoading: isAvailabilityLoading } =
    useSchedulePhotoAvailability(propertyData);

  const {
    handleSubmitSchedulePhotoVisit,
    isLoading: isSubmitting,
    isSuccess: successSubmit,
  } = useSchedulePhotoVisit(propertyData);

  const handleChangeDate = useCallback((date: Date) => {
    setSelectedDate(date);
    setSelectedTime(0);
  }, []);

  const availableHours = getHourOptions(selectedDate, availability);

  useEffect(() => {
    if (successSubmit) {
      setCurrentStep(STEPS.Summary);
    }
  }, [setCurrentStep, successSubmit]);

  const isLoading = isAvailabilityLoading || isSubmitting;

  return {
    availability,
    isLoading,
    handleChangeDate,
    selectedDate,
    setSelectedTime,
    selectedTime,
    availableHours,
    handleSubmitSchedulePhotoVisit,
  };
};

export default useScheduleStep;
