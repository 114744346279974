import { Box } from "@material-ui/core";

import DesignSystemCalendar from "Components/Admin/UIComponents/Calendar/DesignSystemCalendar";

import { isEqual } from "date-fns";
import DayWrapper from "./components/DayWrapper";

interface Props {
  availableDates: null | Date[];
  selectedDate: Date;
  setSelectedDate: (e: Date) => void;
}

const DateSelection = ({
  availableDates,
  selectedDate,
  setSelectedDate,
}: Props) => (
  <Box>
    <DesignSystemCalendar
      value={selectedDate}
      onChange={(e) => setSelectedDate(e)}
      renderDay={(day, _, isInCurrentMonth, dayComponent) => {
        const currentDay = new Date(day.toString());
        return (
          <DayWrapper
            day={currentDay.getDate()}
            disabled={
              !availableDates?.find((availableDate) =>
                isEqual(availableDate, currentDay)
              )
            }
          >
            {dayComponent}
          </DayWrapper>
        );
      }}
    />
  </Box>
);

export default DateSelection;
