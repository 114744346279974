import {
  add,
  eachDayOfInterval,
  endOfWeek,
  format,
  startOfToday,
  startOfWeek,
} from "date-fns";
import es from "date-fns/locale/es";
import { capitalize } from "lodash";

const getWeekDays = () => {
  const today = new Date();
  const week = eachDayOfInterval({
    start: startOfWeek(today, { weekStartsOn: 1 }),
    end: endOfWeek(today, { weekStartsOn: 1 }),
  });
  const weekDays = week.map((day) =>
    capitalize(format(day, "EEEE", { locale: es }))
  );
  return weekDays;
};

export const formatHour = (hour: number) => {
  const datetime = add(startOfToday(), { hours: hour });
  return format(datetime, "HH:mm", { locale: es });
};

export interface IVisitsSchedules {
  hours: [number, number][];
  checked: boolean;
}

export default getWeekDays;
