import { makeStyles, Theme } from "@material-ui/core";

const useRatingStatusStyles = makeStyles((theme: Theme) => ({
  ratingContainer: {
    display: "block",
  },
  label: {
    fontSize: theme.typography.pxToRem(12),
  },
}));

export default useRatingStatusStyles;
