import { Box, Typography } from "@material-ui/core";

import useTimeOptionStyles from "./TimeOptionStyles";

interface Props {
  label: string;
  onClick: () => void;
  selected?: boolean;
}

const TimeOption = ({ label, onClick, selected }: Props) => {
  const classes = useTimeOptionStyles({ selected });

  return (
    <Box className={classes.timeOptionRoot} onClick={onClick}>
      <Typography className={classes.label}>{label}</Typography>
    </Box>
  );
};

export default TimeOption;
