import { makeStyles, Theme } from "@material-ui/core";

const useLeavingDateFormStyles = makeStyles<Theme>((theme) => ({
  motiveContainer: {
    marginTop: theme.spacing(3),
  },
  ctaContainer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: theme.spacing(5),
    },
  },
}));

export default useLeavingDateFormStyles;
