import { ReactNode } from "react";

import { ClassNameMap } from "@material-ui/styles";
import {
  IoHomeOutline as Home,
  IoBedOutline as Bedrooms,
  IoCarOutline as Car,
  IoCalendarOutline as Calendar,
} from "@houm-com/ui-icons";

import SurfaceIcon from "assets/icons/Landlord/SurfaceIcon";
import BathroomsIcon from "assets/icons/Landlord/BathroomsIcon";
import LaundryIcon from "assets/icons/Landlord/LaundryIcon";
import PetsIcon from "assets/icons/Landlord/PetsIcon";
import ArmchairIcon from "assets/icons/Landlord/ArmchairIcon";
import BalconyIcon from "assets/icons/Landlord/BalconyIcon";

import { IPropertyDetails } from "apps/landlord/models/Property";
import { Locale } from "models/Countries";

export const detailsElements = (
  country: Locale,
  classes: ClassNameMap<string>
): {
  text: string;
  detailsKey: keyof IPropertyDetails;
  icon: ReactNode;
  alternativeText?: string;
}[] => [
  {
    text: "m² totales",
    detailsKey: "totalArea",
    icon: <SurfaceIcon className={classes.informationIcon} />,
  },
  {
    text: "m² construidos",
    detailsKey: "builtArea",
    icon: <Home className={classes.informationIcon} />,
  },
  {
    text: "Baños",
    detailsKey: "bathrooms",
    icon: <BathroomsIcon className={classes.informationIcon} />,
  },
  {
    text: country === "cl" ? "Piezas" : "Recámaras",
    detailsKey: "rooms",
    icon: <Bedrooms className={classes.informationIcon} />,
  },
  {
    text: "Estacionamientos",
    detailsKey: "parkingSlots",
    icon: <Car className={classes.informationIcon} />,
  },
  {
    text: "Años de antiguedad",
    alternativeText: "Nuevo",
    detailsKey: "age",
    icon: <Calendar className={classes.informationIcon} />,
  },
];

export const detailsBooleanElements = (
  classes: ClassNameMap<string>
): { text: string; detailsKey: keyof IPropertyDetails; icon: ReactNode }[] => [
  {
    detailsKey: "laundryRoom",
    text: "Cuarto de lavado",
    icon: <LaundryIcon className={classes.informationIcon} />,
  },
  {
    detailsKey: "allowsPets",
    text: "Admite mascotas",
    icon: <PetsIcon className={classes.informationIcon} />,
  },
  {
    detailsKey: "furnished",
    text: "Amoblado",
    icon: <ArmchairIcon className={classes.informationIcon} />,
  },
  {
    detailsKey: "balcony",
    text: "Balcón",
    icon: <BalconyIcon className={classes.informationIcon} />,
  },
];
