import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "@houm-com/ui/Button";

import { Box } from "@material-ui/core";

import CallToActionDrawer from "apps/ui/CustomDrawer/CallToActionDrawer";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import ContentDrawer from "apps/ui/CustomDrawer/ContentDrawer";

import validationSchema from "./utils/validationSchema";
import { classes } from "./KhipuBankStyles";
import { FormProps } from "./utils/types";
import BankCard from "../BankCard";

interface Props {
  onboardingLink: string;
  paymentLink: string;
  bankAccounts: {
    bankName: string;
    accountNumber: string;
  }[];
}

const KhipuBank = ({ onboardingLink, paymentLink, bankAccounts }: Props) => {
  const { setCurrentStep } = useCustomDrawer();
  const methods = useForm<FormProps>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onBlur",
    defaultValues: {
      bank: bankAccounts[0].bankName,
    },
  });

  const onSubmit: SubmitHandler<FormProps> = async (data) => {
    if (data.bank !== "other") return window.open(paymentLink, "_blank");
    window.open(onboardingLink, "_blank");
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ContentDrawer>
          <Box className={classes.container}>
            <h1 className={classes.title}>Selecciona tu cuenta</h1>
            <Box className={classes.cardContainer}>
              {bankAccounts.map((account) => (
                <BankCard
                  id={account.accountNumber}
                  name={account.bankName}
                  lastDigits={account?.accountNumber}
                  key={`${account.bankName}${account?.accountNumber}Card`}
                />
              ))}
              <BankCard id="other" name="Pagar con otra" />
            </Box>
          </Box>
        </ContentDrawer>
        <CallToActionDrawer>
          <Button
            variant="tertiary"
            size="md"
            type="button"
            onClick={() => setCurrentStep("paymentPlatforms")}
          >
            Atrás
          </Button>
          <Button variant="primary" size="md" type="submit">
            Siguiente
          </Button>
        </CallToActionDrawer>
      </form>
    </FormProvider>
  );
};

export default KhipuBank;
