import { Grid } from "@material-ui/core";

import { AnalysisTypeProperty } from "apps/landlord/pages/Price/utils/types";
import SwitchSelector from "apps/landlord/components/SwitchSelector";

import useCallToActionStyles from "./CallToActionStyles";
import PrintButton from "../../../PrintButton";

interface Props {
  type: AnalysisTypeProperty;
  handleChangeType: (e: AnalysisTypeProperty) => void;
  isForRentalAndSale: boolean;
}

const CallToAction = ({
  isForRentalAndSale,
  handleChangeType,
  type,
}: Props) => {
  const classes = useCallToActionStyles();

  return (
    <Grid
      container
      justifyContent={isForRentalAndSale ? "space-between" : "flex-end"}
      alignItems="center"
      className={classes.buttonContainer}
    >
      {isForRentalAndSale && (
        <Grid item>
          <SwitchSelector
            handleChange={(e) => handleChangeType(e as AnalysisTypeProperty)}
            options={[
              { label: "Arriendo", key: "rent" },
              { label: "Venta", key: "sale" },
            ]}
            defaultValue={type}
          />
        </Grid>
      )}
      <Grid item>
        <PrintButton />
      </Grid>
    </Grid>
  );
};

export default CallToAction;
