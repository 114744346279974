import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";

const defaultBreakpoint = "sm";

const useCallToActionStyles = makeStyles((theme: Theme) => ({
  closureFormRoot: {
    backgroundColor: "white",
  },
  closureFormContainer: {
    margin: theme.spacing(10, 2),
    padding: theme.spacing(7, 0),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      margin: "auto",
      marginTop: theme.spacing(13),
      width: 512,
      padding: theme.spacing(7, 0),
    },
  },
  title: {
    margin: theme.spacing(4, 9, 2, 9),
    color: theme.palette.grey[900],
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "2rem",
    lineHeight: 1.125,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      margin: theme.spacing(8, 9, 2, 9),
    },
  },
  subtitle: {
    margin: theme.spacing(0, 5),
    color: theme.palette.grey[300],
    textAlign: "center",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.25,
  },
  form: {
    padding: theme.spacing(4, 5, 14, 5),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(7, 5, 14, 5),
    },
  },
  stepTitle: {
    fontWeight: "bold",
    fontSize: "1.25rem",
    lineHeight: 1.25,
    color: theme.palette.primary.main,
  },
  inputLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: 1.5,
    marginBottom: 0,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: "1rem",
    },
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  spinner: {
    marginTop: theme.spacing(5),
  },
  hint: {
    color: theme.palette.grey[300],
    marginTop: theme.spacing(-3),
    marginBottom: theme.spacing(3),
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: 1.25,
  },
  navigation: {
    marginLeft: theme.spacing(4),
  },
}));

export const Connector = withStyles({
  active: {
    "& $line": {
      borderColor: "#FF452B",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#FF452B",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderWidth: "thin",
  },
})(StepConnector);

export default useCallToActionStyles;
