import { CountryUpperCase } from "models/Countries";

export interface SelectionOption {
  value: number;
  label: string;
}

export interface ids {
  propertyUid: string;
  propertyId: number;
  negotiationId: string;
  userId: number;
}

export interface PropertyData {
  uid: string;
  id: number;
  address: string;
  commune: string;
  country: string;
}

export type CivilStatus =
  | "unmarried"
  | "married"
  | "divorced"
  | "widowed"
  | "freeUnion"
  | "other";

export type MaritalRegime =
  | "conjugalSociety"
  | "separatedGoods"
  | "notApply"
  | "other";

export interface LandlordData {
  name: string;
  lastName: string;
  secondLastName: string;
  identificationType: string | "0";
  identificationNumber: string;
  civilStatus: CivilStatus;
  maritalRegime: MaritalRegime;
  occupation: string;
  rfc: string;
  nationality: string;
  // me podria servir
  phoneNumber: string;
}

export interface LandlordDocs {
  identificationDoc1: File;
  identificationDoc2: File;
  appraisalCertificate: File;
  propertyDeed: File;
  curp: File;
  rfc: File;
  birthCertificate: File;
  marriageCertificate: File;
}
export interface LandlordBankData {
  clabe: string;
}

export interface PropertyDocs {
  propertyDeed: File;
  propertyBill: File;
  waterBill: File;
  traditionCertificate: File;
}
export interface BrokerageContract {
  signedContract: File;
}
export interface LoiFile {
  nameFile: string;
  frontID: File;
  backID: File;
}

export interface DocumentOption extends SelectionOption {
  label: string;
  validationRegex: string;
  value: number;
}

export interface State {
  availableSteps: string[];
  step: string;
  loading: boolean;
  submitting: boolean;
  navText: string;
  country: CountryUpperCase;
  ids: ids;
  readyToSubmit: boolean;
  documentTypes: DocumentOption[];
  landlordData: LandlordData;
  landlordDocs: LandlordDocs;
  landlordBankData: LandlordBankData;
  propertyData: PropertyData;
  propertyDocs: PropertyDocs;
  brokerageContract: BrokerageContract;
}

export enum ActionType {
  SET_LOADING,
  SET_AVAILABLE_STEPS,
  SET_STEP,
  NEXT_STEP,
  PREV_STEP,
  SET_READY,
  SET_SUBMITTING,
  SET_NAV_TEXT,
  SET_COUNTRY,
  SET_DOC_TYPES,
  SET_IDS,
  SET_LANDLORD_DATA,
  SET_LANDLORD_DOCS,
  SET_LANDLORD_BANK_DATA,
  SET_PROPERTY_DATA,
  SET_PROPERTY_DOCS,
  SET_BROKERAGE_CONTRACT,
}

interface setLoading {
  type: typeof ActionType.SET_LOADING;
  loading: boolean;
}

interface setAvailableSteps {
  type: typeof ActionType.SET_AVAILABLE_STEPS;
  steps: string[];
}

interface setStep {
  type: typeof ActionType.SET_STEP;
  step: string;
}

interface nextStep {
  type: typeof ActionType.NEXT_STEP;
}

interface prevStep {
  type: typeof ActionType.PREV_STEP;
}

interface setReady {
  type: typeof ActionType.SET_READY;
  ready: boolean;
}

interface setSubmitting {
  type: typeof ActionType.SET_SUBMITTING;
  submitting: boolean;
}

interface setNavText {
  type: typeof ActionType.SET_NAV_TEXT;
  navText: string;
}

interface setCountry {
  type: typeof ActionType.SET_COUNTRY;
  country: CountryUpperCase;
}

interface setDocumentTypes {
  type: typeof ActionType.SET_DOC_TYPES;
  documentTypes: DocumentOption[];
}

interface setIds {
  type: typeof ActionType.SET_IDS;
  ids: ids;
}

interface setLandlordData {
  type: typeof ActionType.SET_LANDLORD_DATA;
  landlordData: Partial<LandlordData>;
}

interface setLandlordDocs {
  type: typeof ActionType.SET_LANDLORD_DOCS;
  landlordDocs: Partial<LandlordDocs>;
}

interface setLandlordBankData {
  type: typeof ActionType.SET_LANDLORD_BANK_DATA;
  landlordBankData: Partial<LandlordBankData>;
}

interface setPropertyData {
  type: typeof ActionType.SET_PROPERTY_DATA;
  propertyData: Partial<PropertyData>;
}
interface setPropertyDocs {
  type: typeof ActionType.SET_PROPERTY_DOCS;
  propertyDocs: Partial<PropertyDocs>;
}

interface setBrokerageContract {
  type: typeof ActionType.SET_BROKERAGE_CONTRACT;
  brokerageContract: Partial<BrokerageContract>;
}

export type Action =
  | setLoading
  | setAvailableSteps
  | setStep
  | nextStep
  | prevStep
  | setReady
  | setSubmitting
  | setNavText
  | setCountry
  | setDocumentTypes
  | setIds
  | setLandlordData
  | setLandlordDocs
  | setLandlordBankData
  | setPropertyData
  | setPropertyDocs
  | setBrokerageContract;

export type Dispatch = (action: Action) => void;
