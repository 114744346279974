import { format } from "date-fns";
import { es } from "date-fns/locale";
import { startCase } from "lodash";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

import { Box, Grid, Typography } from "@material-ui/core";

import BackButton from "apps/tenant/components/BackButton";
import { Locale } from "models/Countries";

import useHeaderStyles from "./HeaderStyles";

interface Props {
  country: Locale;
  date?: Date;
  origin?: string;
}

const Header = ({ country, date, origin }: Props) => {
  const classes = useHeaderStyles();
  const history = useHistory();

  const handleBack = () => {
    const backUrl =
      origin === "dashboard" ? "/tenant" : "/tenant/vouchers-list";
    history.push(backUrl);
  };

  return (
    <Box className={classes.headerWrapper}>
      <Grid container className={classes.header}>
        <Grid item xs={12} sm={6} className={classes.gridItems}>
          {!isMobile && <BackButton onClickHandler={handleBack} />}
          <Typography className={classes.title}>
            {`Detalle ${
              date ? startCase(format(date, "MMMM yyyy", { locale: es })) : ""
            }`}
          </Typography>
          {date && (
            <Typography className={classes.dateLabel}>
              Fecha:{" "}
              <Typography component="span" className={classes.date}>
                {format(date, "dd/MM/yyyy")}
              </Typography>
            </Typography>
          )}
        </Grid>
        {!isMobile && country === "cl" && (
          <Grid
            item
            container
            xs={12}
            sm={6}
            justifyContent="flex-end"
            alignItems="center"
          ></Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Header;
