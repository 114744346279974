import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  backgroundChip: string;
  letterChipColor: string;
}

export default makeStyles<Theme, Props>((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },
  paper: {
    borderRadius: 16,
    "&:hover": {
      boxShadow: "3px 4px 20px rgba(38, 50, 56, 0.15)",
    },
    [theme.breakpoints.down("md")]: {
      boxShadow: "3px 4px 20px rgba(38, 50, 56, 0.15)",
      borderRadius: 8,
    },
  },
  infoContainer: {
    paddingBlock: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingInline: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
  },
  subtitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[500],
  },
  subtitleLight: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[500],
    marginLeft: "0.25rem",
  },
  text: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    marginRight: "0.25rem",
    paddingTop: 2,
  },
  textLight: {
    fontSize: theme.typography.pxToRem(14),
    paddingTop: 2,
  },
  subtitleContainer: {
    paddingLeft: theme.spacing(3),
  },
  leftContainer: {
    position: "relative",
    margin: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    height: "100%",
    borderRadius: 8,
    [theme.breakpoints.down("md")]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  img: {
    height: 182,
    width: "auto",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: 182,
    },
  },
  chipsContainer: {
    float: "left",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1000,
    padding: 5,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  chipID: {
    backgroundColor: theme.palette.grey[50],
    fontWeight: 700,
    marginRight: 8,
  },
  bodyCard: {
    marginTop: theme.spacing(1),
  },
  icon: {
    marginRight: 4,
  },
  pinIcon: {
    marginRight: 4,
    width: 20,
    height: 20,
    "& path": {
      stroke: theme.palette.grey[900],
    },
    "& circle": {
      fill: theme.palette.grey[900],
    },
  },
  menuOption: {
    marginLeft: theme.spacing(-1),
    paddingBlock: theme.spacing(2),
  },
  chip: {
    backgroundColor: ({ backgroundChip }) => backgroundChip,
    fontWeight: 700,
  },
  chipText: {
    color: ({ letterChipColor }) => letterChipColor,
  },
}));
