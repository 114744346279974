import { Grid } from "@material-ui/core";

import useStyles from "./AppointmentsStyles";
import AppointmentsStatusSelector from "./components/AppointmentsStatusSelector";
import AppointmentsTypeSelector from "./components/AppointmentsTypeSelector";
import AppointmentsList from "./components/AppointmentsList";
import ContentWrapper from "../../layout/components/ContentWrapper";

const AccountAppointments = () => {
  const classes = useStyles();
  return (
    <ContentWrapper>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.root}
      >
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={8}>
            <h1 className={classes.title}>Mis visitas</h1>
          </Grid>
          <Grid item xs={12} md={4} className={classes.selector}>
            <AppointmentsStatusSelector />
          </Grid>
          <Grid item xs={12}>
            <Grid xs={4}>
              <AppointmentsTypeSelector />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.listContainer}>
            <AppointmentsList />
          </Grid>
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

export default AccountAppointments;
