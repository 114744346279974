import { useMutation, useQueryClient } from "react-query";

import { useHoumSnackBar } from "libs/houm-snackbar";

import { useGetToken } from "hooks";
import userService from "services/userService";
import schedulerService from "services/schedulesService";

import { IProperty } from "apps/landlord/models/Property";
import {
  SCHEDULE_PHOTO_VISIT_ERROR,
  SCHEDULE_PHOTO_VISIT_SUCCESS,
} from "./constants";
import parseScheduleBody from "./parsers";

const useSchedulePhotoVisit = (propertyData: IProperty) => {
  const { id: userId } = userService.me();
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const queryClient = useQueryClient();

  const { getToken } = useGetToken();

  const submitSchedulePhotoVisit = async ({ selectedTime, availableHours }) => {
    const authToken = await getToken();
    const selectedTimeStamp = availableHours.find(
      (hour) => hour.id === selectedTime
    );
    const parsedBody = parseScheduleBody({
      hour: selectedTimeStamp.fullDate,
      propertyUid: propertyData.uid,
      forSale: propertyData.forSale,
      userId,
    });
    const { data } = await schedulerService.createPhotoSchedule(
      parsedBody,
      authToken
    );
    return data;
  };

  const {
    mutate: handleSubmitSchedulePhotoVisit,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(submitSchedulePhotoVisit, {
    onSuccess: () => {
      queryClient.invalidateQueries("property");
      queryClient.invalidateQueries(`SETTING_UP_STATUS_${propertyData?.id}`);
      queryClient.invalidateQueries(["AVAILABILITY", propertyData?.id]);
      enqueueHoumSnackBar(SCHEDULE_PHOTO_VISIT_SUCCESS);
    },
    onError: () => {
      enqueueHoumSnackBar(SCHEDULE_PHOTO_VISIT_ERROR);
    },
  });

  return {
    handleSubmitSchedulePhotoVisit,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useSchedulePhotoVisit;
