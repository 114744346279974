import { AxiosResponse } from "axios";

import { createHttp } from "services/helper";

import { IVisitsResponse } from "./types";

export default {
  getVisits: (
    token: string,
    email: string
  ): Promise<AxiosResponse<IVisitsResponse>> =>
    createHttp(token).get(`/demand/v2/get_visits`, { params: { email } }),
};
