/**
 *  Add your app public routes provider here.
 */
import { Route } from "react-router-dom";
import PaymentsPublicRoutes from "apps/payments/routes/PaymentsPublicRoutes";
import AccountPublicRoutes from "apps/account/routes/AccountPublicRoutes";
import TenantPublicRoutes from "apps/lead/routes/LeadPublicRoutes";
import LandlordPublicRoutes from "apps/landlord/routes/LandlorPublicRoutes";
import ErrorBoundary from "apps/lead/components/ErrorBoundary";
import RedirectIfUser from "../components/RedirectIfUser";
import Login from "../Pages/Login";
import Logout from "../Pages/Logout";

const MainPublicRoutes = () => (
  <>
    <Route exact path="/login">
      <RedirectIfUser to="/">
        <Login />
      </RedirectIfUser>
    </Route>
    <Route exact path="/logout">
      <Logout />
    </Route>
    <Route path="/account">
      <AccountPublicRoutes />
    </Route>
    <Route path={["/payments", "/pagar"]}>
      <PaymentsPublicRoutes />
    </Route>
    <Route path={["/tenant", "/reservation", "/contract", "/lease"]}>
      <ErrorBoundary>
        <TenantPublicRoutes />
      </ErrorBoundary>
    </Route>
    <Route path="/landlord">
      <ErrorBoundary>
        <LandlordPublicRoutes />
      </ErrorBoundary>
    </Route>
  </>
);

export default MainPublicRoutes;
