import { IProperty } from "apps/landlord/models/Property";

const usePropertyStatus = (propertyData: IProperty) => {
  const isForRental = propertyData?.forRental;
  const isForSale = propertyData?.forSale;
  const propertyStatusForRental = isForRental
    ? propertyData?.propertyDetails.status
    : null;
  const propertyStatusForSale = isForSale
    ? propertyData?.salesDetails.status
    : null;
  const isRented = propertyStatusForRental === "rented";

  return {
    isForRental,
    isForSale,
    propertyStatusForRental,
    propertyStatusForSale,
    isRented,
  };
};

export default usePropertyStatus;
