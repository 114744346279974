import { makeStyles } from "@material-ui/core";

const useHelpWdgetMobileModalStyles = makeStyles((theme) => ({
  modalPaper: {
    margin: theme.spacing(2),
  },
  modalRoot: {
    width: "100%",
    margin: theme.spacing(2),
    maxWidth: 624,
    borderRadius: 16,
    overflowY: "inherit",
    padding: theme.spacing(4, 2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  mobileModalCloseIcon: {
    position: "absolute",
    top: 16,
    right: 16,
    cursor: "pointer",
    color: "#A0B1B9",
  },
  mobileModalTitle: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(28),
  },
  mobileModalActions: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
  },
  mobileModalAction: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  mobileModalActionTitleWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  mobileModalActionTitle: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
  mobileModalActionDescription: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
  mobileModalActionLink: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    color: "#FF452B",
  },
  mobileModalActionLinkContainer: {
    color: "#FF452B",
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    transition: "filter 0.2s ease-in-out",
    alignItems: "center",
    "&:hover": {
      filter: "brightness(0.8)",
    },
  },
  linkIcon: {
    transform: "rotate(-45deg)",
    display: "inline-block",
  },
}));

export default useHelpWdgetMobileModalStyles;
