import Resume from "./ResumeStep/Resume";

import EmploymentValidationForm from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm";
import IncomeValidationFiles from "apps/lead/pages/EvaluatorForm/components/IncomeValidationFiles";
import IdentificationFiles from "apps/lead/pages/EvaluatorForm/components/IdentificationFiles";
import {
  FirstCodebtorUploadFiles,
  MainUploadFiles,
  SecondCodebtorUploadFiles,
} from "./UploadFilesStep";
import {
  AddFirstCodebtorDecision,
  AddSecondCodebtorDecision,
} from "./CodebtorDecision";
import WorkerTypeForm from "apps/lead/pages/EvaluatorForm/components/WorkerTypeForm";
import ApplicantForm from "apps/lead/pages/EvaluatorForm/components/ApplicantForm";
import EmployerForm from "apps/lead/pages/EvaluatorForm/components/EmployerForm";
import AddressForm from "apps/lead/pages/EvaluatorForm/components/AddressForm";
import Welcome from "apps/lead/pages/EvaluatorForm/components/Steps/Welcome";
import ResumeV2 from "apps/lead/pages/EvaluatorForm/components/Steps/Resume";
import { Locale } from "models/Countries";

const UNIVERSAL_STEPS = {
  WELCOME: Welcome,
  RESUME: ResumeV2,
};

const APPLICANT_INFORMATION_STEPS = {
  INCOME_VALIDATION: IncomeValidationFiles,
  EMPLOYMENT_VALIDATION: EmploymentValidationForm,
  EMPLOYMENT: EmployerForm,
  WORKER_TYPE: WorkerTypeForm,
  ADDRESS: AddressForm,
  USER_FORM: ApplicantForm,
  IDENTIFICATION: IdentificationFiles,
};
const FORM_STEPS = [
  APPLICANT_INFORMATION_STEPS.USER_FORM,
  APPLICANT_INFORMATION_STEPS.IDENTIFICATION,
  APPLICANT_INFORMATION_STEPS.ADDRESS,
  APPLICANT_INFORMATION_STEPS.WORKER_TYPE,
  APPLICANT_INFORMATION_STEPS.EMPLOYMENT,
  APPLICANT_INFORMATION_STEPS.EMPLOYMENT_VALIDATION,
  APPLICANT_INFORMATION_STEPS.INCOME_VALIDATION,
];

const getEvaluationFormStepsColombia = () => FORM_STEPS;

const getEvaluationFormStepsChile = () => {
  const INITIAL_STEPS = [MainUploadFiles, AddFirstCodebtorDecision];

  const CODEBTOR_STEPS = [
    FirstCodebtorUploadFiles,
    AddSecondCodebtorDecision,
    SecondCodebtorUploadFiles,
  ];

  const FINAL_STEPS = [Resume];

  return [
    UNIVERSAL_STEPS.WELCOME,
    ...INITIAL_STEPS,
    ...CODEBTOR_STEPS,
    ...FINAL_STEPS,
  ];
};

const getStepsByCountry = (country: Locale) => {
  if (country === "cl") return getEvaluationFormStepsChile();
  if (country === "co") return getEvaluationFormStepsColombia();
};
export default getStepsByCountry;
