import { makeStyles, Theme } from "@material-ui/core";

const useBankAccountCardStyles = makeStyles((theme: Theme) => ({
  bankCard: {
    margin: theme.spacing(1, 1),
    padding: theme.spacing(1.25, 1.5, 0),
    minHeight: 150,
    backgroundColor: "white",
    boxShadow: "4px 4px 30px rgba(0, 43, 41, 0.15)",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    color: theme.palette.grey[900],
    "& p": {
      marginBottom: theme.spacing(0.75),
    },
  },
  infoBankAccount: {
    "& p": {
      fontSize: theme.typography.pxToRem(12),
      margin: 0,
    },
    "& span": {
      fontSize: theme.typography.pxToRem(13),
      fontWeight: 600,
    },
  },
  borderLine: {
    padding: 0,
    margin: theme.spacing(1.25, 0),
    background: theme.palette.primary.main,
  },
  cardInfoTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0, 2.5),
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(15),
  },
  cardInfo: {
    flex: "80%",
    display: "flex",
    flexDirection: "column",
  },
  gridContainer: {
    margin: theme.spacing(1.25),
  },
  editAccount: {
    width: 126,
    height: 30,
    background: "#FEE9E8",
    borderRadius: 17,
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "none",
    fontWeight: "bold",
  },
  addAccount: {
    width: 126,
    height: 30,
    background: theme.palette.primary.main,
    borderRadius: 17,
    color: "white",
    fontSize: theme.typography.pxToRem(14),
    textTransform: "none",
    fontWeight: "bold",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
}));

export default useBankAccountCardStyles;
