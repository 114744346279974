import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";
const mediumBreakpoint = "md";

export interface StyleProps {
  size: number;
  properties: boolean;
}

export default makeStyles<Theme, StyleProps>((theme: Theme) => ({
  desktopTable: {
    [theme.breakpoints.down(mediumBreakpoint)]: {
      display: "none",
    },
  },
  title: {
    paddingLeft: theme.spacing(2.5),
    [theme.breakpoints.up(mediumBreakpoint)]: {
      paddingLeft: 0,
    },
  },
  containerTable: {
    height: (props) => props.size,
    width: "100%",
    [theme.breakpoints.down(defaultBreakpoint)]: {
      display: "none",
    },
  },
  table: {
    border: 0,
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
      paddingBottom: theme.spacing(2),
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
      display: "flex",
      alignItems: "center",
      borderBottomWidth: 0,
      paddingLeft: theme.spacing(2),
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
      width: "100%",
      marginTop: theme.spacing(1),
      borderRadius: 10,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
      color: theme.palette.grey[900],
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 400,
      backgroundColor: "#fff",
      [theme.breakpoints.up(mediumBreakpoint)]: {
        fontSize: theme.typography.pxToRem(18),
      },
      cursor: "pointer",
      transition: "0.3s",
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#fff",
      borderRadius: 6,
      marginBottomWidth: theme.typography.pxToRem(1),
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: theme.palette.grey[500],
      fontSize: theme.typography.pxToRem(13),
      fontWeight: 700,
      [theme.breakpoints.down(mediumBreakpoint)]: {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    "& .hideColumnLine": {
      "& .MuiDataGrid-columnSeparator": {
        display: "none",
      },
    },
  },
  /*============
   * Mobile Table
   * ============ */
  mobileTable: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  spinner: {
    margin: theme.spacing(3, 0),
  },
  loading: { position: "absolute", top: 0, width: "100%" },
  pagination: {
    [theme.breakpoints.between("sm", mediumBreakpoint)]: {
      paddingBottom: theme.spacing(3),
    },
  },
}));
