import * as yup from "yup";

const requiredText = "Este campo es requerido";

const currencyRegEx =
  /^[$]|[U.F.]?[0-9]+([,.][0-9]+)?([,.][0-9]+)?([,.][0-9]+)?$/;

const validationSchema = yup.object().shape({
  price: yup
    .string()
    .required(requiredText)
    .matches(currencyRegEx, "El monto ingresado no es válido"),
  currency: yup.string().required(requiredText),
  includesCommonExpenses: yup.string().required(requiredText),
  businessType: yup.string().required(requiredText),
});

export default validationSchema;
