import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { useProperty } from "apps/landlord/hooks";
import INITIAL_STATE_VIEW from "./constants";
import { STEPS } from "./types";

export const BasicServicesFormContext = createContext(INITIAL_STATE_VIEW);

const COUNTRY_BASED_STEPS = {
  cl: [STEPS.CommonExpenses, STEPS.BasicServices],
  co: [STEPS.BasicServices],
  mx: [STEPS.BasicServices],
};

export const BasicServicesFormContextProvider: React.FC = ({ children }) => {
  const { propertyData } = useProperty();
  const { country } = propertyData || {};
  const countrySteps = COUNTRY_BASED_STEPS[country] || COUNTRY_BASED_STEPS.cl;
  const [currentStep, setCurrentStep] = useState(countrySteps[0]);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const PROPERTY_URL = `/landlord/properties/${propertyData?.uid}`;

  useEffect(() => {
    setOpen(true);
  }, []);

  const returnToPropertyDetail = useCallback(() => {
    if (!isMobile) {
      setOpen(false);
      return setTimeout(() => history.push(PROPERTY_URL), 300);
    }
    return history.push(PROPERTY_URL);
  }, [history, PROPERTY_URL]);

  const returnFromBasicServices = useMemo(() => {
    if (country === "cl") {
      return () => setCurrentStep(STEPS.CommonExpenses);
    }
    return returnToPropertyDetail;
  }, [country, returnToPropertyDetail]);

  const goToBasicServicesStep = () => setCurrentStep(STEPS.BasicServices);

  return (
    <BasicServicesFormContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        open,
        returnToPropertyDetail,
        currentStep,
        setCurrentStep,
        goToBasicServicesStep,
        returnFromBasicServices,
      }}
    >
      {children}
    </BasicServicesFormContext.Provider>
  );
};
