import { ProprietaryFormProvider } from "./context/proprietaryFormContext";
import CreateProperty from "./CreateProperty";

const CreatePropertyIndex = () => (
  <ProprietaryFormProvider>
    <CreateProperty />
  </ProprietaryFormProvider>
);

export default CreatePropertyIndex;
