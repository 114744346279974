import IconButton from "@houm-com/ui/IconButton";
import { IoArrowForward as ArrowIcon } from "@houm-com/ui-icons";

import { Locale } from "models/Countries";

import useMarketplaceLinkStyles from "./MarketplaceLinkStyles";
import { LOCALE_BASED_MARKETPLACE_URLS } from "../../utils/constants";

interface Props {
  countryCode: Locale;
}

const MarketplaceLink = ({ countryCode }: Props) => {
  const classes = useMarketplaceLinkStyles();

  const onCtaClick = () =>
    window.open(LOCALE_BASED_MARKETPLACE_URLS[countryCode], "_blank");
  return (
    <IconButton
      variant="primary"
      size="md"
      className={classes.ctaButton}
      icon={<ArrowIcon size="20" className={classes.icon} />}
      position="end"
      onClick={onCtaClick}
    >
      Buscar más propiedades
    </IconButton>
  );
};

export default MarketplaceLink;
