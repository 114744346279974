import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  root: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  tabsContainer: {
    backgroundColor: "white",
  },
  selected: {
    color: `${theme.palette.primary.main}!important`,
    fontWeight: 700,
  },
  tabRoot: {
    fontSize: theme.typography.pxToRem(16),
    textTransform: "unset",
    color: "black",
    minWidth: "auto",
    paddingInline: 0,
    marginLeft: theme.spacing(3),
    paddingBottom: 0,

    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: theme.spacing(2),
    },
  },
  tabBar: {
    background: theme.palette.primary.main,
    height: theme.spacing(0.5),
    borderRadius: 20,
  },
}));
