import { useState } from "react";

import { Button, ButtonGroup, Typography } from "@material-ui/core";

import useStyles from "./AppointmentsTypeSelectorStyles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { appointmentsOptionsTypes } from "./AppointmentsConstants";

const AppointmentsTypeSelector = () => {
  const classes = useStyles();
  const [indexSelected, setIndexSelected] = useState(0);
  const history = useHistory();

  const handleClick = (index) => {
    setIndexSelected(index);
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set(
      "schedule_type",
      appointmentsOptionsTypes[index].scheduleType
    );
    history.push(`${window.location.pathname}?${currentUrlParams.toString()}`);
  };

  return (
    <ButtonGroup variant="outlined" className={classes.group}>
      {appointmentsOptionsTypes.map((tab, index) => (
        <Button
          key={tab.label}
          className={clsx({
            [classes.button]: true,
            [classes.buttonSelected]: indexSelected === index,
          })}
          onClick={() => handleClick(index)}
        >
          <Typography className={indexSelected === index && classes.title}>
            {tab.label}
          </Typography>
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default AppointmentsTypeSelector;
