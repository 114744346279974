import { useFormContext } from "react-hook-form";

import { IoCheckmark } from "@houm-com/ui-icons";

import SelectableCard from "../../../SelectableCard";
import { classes } from "./BankCardStyles";

interface Props {
  name: string;
  lastDigits?: string;
  id: string;
}

const BankCard = ({ id, name, lastDigits = "" }: Props) => {
  const { register, watch } = useFormContext();
  const selectedMethod = watch("bank");

  return (
    <SelectableCard
      id={id}
      {...register("bank")}
      className="!p-4 !min-h-fit !h-auto"
    >
      <div className={classes.infoContainer}>
        <p className={classes.title}>{name}</p>
        {lastDigits && (
          <p className={classes.subtitle}>{`**** **** **** ${lastDigits}`}</p>
        )}
      </div>
      {selectedMethod === id && (
        <div className={classes.checkIconContainer}>
          <IoCheckmark size={24} />
        </div>
      )}
    </SelectableCard>
  );
};

export default BankCard;
