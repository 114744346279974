import { parsePhoneNumber } from "libphonenumber-js/mobile";
import { Country, PhoneInput } from "models/PhoneInput";
import { getCountryPhone } from "utils/verifyPhoneNumber";

const codes = {
  56: true,
  57: true,
  52: true,
};

// phoneWithCode: +56920984495 example
export default (phoneWithCode: string): PhoneInput => {
  const defaultCountry: Country = getCountryPhone("cl");
  let formattedAdministrationPhone = { country: defaultCountry, phone: "" };
  if (!phoneWithCode) return formattedAdministrationPhone;

  try {
    // + symbol,
    const phoneWithSymbol = codes[phoneWithCode.substring(0, 2)]
      ? `+${phoneWithCode}`
      : phoneWithCode;

    const phoneParsedNumber = parsePhoneNumber(phoneWithSymbol);
    const administrationPhoneCountry: Country = {
      callingCode: phoneParsedNumber.countryCallingCode,
      countryCode: phoneParsedNumber.country,
      countryName: phoneParsedNumber.country,
    };
    formattedAdministrationPhone = {
      country: administrationPhoneCountry,
      phone: phoneParsedNumber.nationalNumber as string,
    };
  } catch (error) {
    return {
      country: defaultCountry,
      phone: phoneWithCode,
    };
  }
  return formattedAdministrationPhone;
};
