import { AxiosResponse } from "axios";

import { createHttp } from "services/helper";
import { IRepairsRequestResponse } from "./types";

export default {
  getRepairsRequests(
    token: string,
    propId: number
  ): Promise<AxiosResponse<IRepairsRequestResponse[]>> {
    return createHttp(token).get(
      `/properties/v2/${propId}/maintenance/request/list`
    );
  },
};
