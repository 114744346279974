import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid, Typography } from "@material-ui/core";

import FilteredInput from "../components/FilteredInput";
import CallToAction from "Components/Admin/UIComponents/atoms/CallToAction";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { ControlledFileUpload } from "Components/Admin/UIComponents/ControlledForm";

import { useLoiForm } from "../context/LoiFormContext";
import { LoiDocuments } from "../context/LoiFormContext.types";
import { landlordDocsFields } from "../schema/fields";
import { landlordDocsValidationSchema } from "../schema/validations";
import useStepsStyles from "./StepsStyles";

const ALLOWED_MIME_TYPES = ["application/pdf", "image/jpeg", "image/png"];

const Documents = () => {
  const classes = useStepsStyles();

  const {
    actions: { setActiveStep, setDocuments },
    state: { activeStep, country, personalData },
  } = useLoiForm();

  const methods = useForm<LoiDocuments>({
    resolver: yupResolver(landlordDocsValidationSchema(country)),
    reValidateMode: "onBlur",
  });

  const onSubmit = (formData) => {
    setDocuments(formData);
    setActiveStep(activeStep + 1);
  };

  const ownerFullName = `${personalData.name} ${personalData.lastName || ""} ${
    personalData.secondLastName || ""
  }`;

  return (
    <Grid container className={classes.rootGrid}>
      <Grid item>
        <Typography className={classes.ownerNameLead}>
          propietario: {ownerFullName}
        </Typography>
        <Typography className={classes.title}>Tus documentos</Typography>
        <Typography className={classes.subtitle}>
          Necesitamos que subas los siguientes documentos para poder corroborar
          tu identidad. Sube el documento solicitado en formato
          <b>pdf. png. jpg. o jpeg</b>. con un peso máximo de <b>3MB</b> cada
          uno.
        </Typography>
      </Grid>
      <Grid item>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FilteredInput fieldInfo={landlordDocsFields.identificationDoc1()}>
              <ControlledFileUpload
                id="identificationDoc1"
                label={landlordDocsFields.identificationDoc1().label(country)}
                allowedMimeTypes={ALLOWED_MIME_TYPES}
                helperText=""
              />
            </FilteredInput>

            <FilteredInput fieldInfo={landlordDocsFields.identificationDoc2()}>
              <ControlledFileUpload
                id="identificationDoc2"
                label={landlordDocsFields.identificationDoc2().label(country)}
                allowedMimeTypes={ALLOWED_MIME_TYPES}
                helperText=""
              />
            </FilteredInput>

            {personalData.identificationType === "ine" && (
              <FilteredInput fieldInfo={landlordDocsFields.ine()}>
                <ControlledFileUpload
                  id="ine"
                  label={landlordDocsFields.ine().label(country)}
                  allowedMimeTypes={ALLOWED_MIME_TYPES}
                  helperText=""
                />
              </FilteredInput>
            )}

            {personalData.identificationType === "passport" && (
              <FilteredInput fieldInfo={landlordDocsFields.passport()}>
                <ControlledFileUpload
                  id="passport"
                  label={landlordDocsFields.passport().label(country)}
                  allowedMimeTypes={ALLOWED_MIME_TYPES}
                  helperText=""
                />
              </FilteredInput>
            )}

            <FilteredInput fieldInfo={landlordDocsFields.rfc()}>
              <ControlledFileUpload
                id="rfc"
                label={landlordDocsFields.rfc().label(country)}
                allowedMimeTypes={ALLOWED_MIME_TYPES}
                helperText=""
              />
            </FilteredInput>

            <FilteredInput
              fieldInfo={landlordDocsFields.phyisicalCurrentSituation()}
            >
              <ControlledFileUpload
                id="phyisicalCurrentSituation"
                label={landlordDocsFields
                  .phyisicalCurrentSituation()
                  .label(country)}
                allowedMimeTypes={ALLOWED_MIME_TYPES}
                helperText=""
              />
            </FilteredInput>

            <FilteredInput fieldInfo={landlordDocsFields.addressCertificate()}>
              <ControlledFileUpload
                id="addressCertificate"
                label={landlordDocsFields.addressCertificate().label(country)}
                allowedMimeTypes={ALLOWED_MIME_TYPES}
                helperText=""
              />
            </FilteredInput>

            {personalData.identificationType === "passport" && (
              <FilteredInput fieldInfo={landlordDocsFields.birthCertificate()}>
                <ControlledFileUpload
                  id="birthCertificate"
                  label={landlordDocsFields.birthCertificate().label(country)}
                  allowedMimeTypes={ALLOWED_MIME_TYPES}
                  helperText=""
                />
              </FilteredInput>
            )}

            {personalData.civilStatus === "married" && (
              <FilteredInput
                fieldInfo={landlordDocsFields.marriageCertificate()}
              >
                <ControlledFileUpload
                  id="marriageCertificate"
                  label={landlordDocsFields
                    .marriageCertificate()
                    .label(country)}
                  allowedMimeTypes={ALLOWED_MIME_TYPES}
                  helperText=""
                />
              </FilteredInput>
            )}
            <CallToAction>
              <DesignSystemButton
                label="Continuar"
                type="submit"
                size="large"
                variant="primary"
                fullWidth
              />
            </CallToAction>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default Documents;
