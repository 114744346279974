/* eslint-disable max-lines */
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import FieldSwitchSelector from "@houm-com/ui/FieldSwitchSelector";
import FieldCounter from "@houm-com/ui/FieldCounter";
import FieldInput from "@houm-com/ui/FieldInput";
import Button from "@houm-com/ui/Button";

import Box from "@material-ui/core/Box";

import CallToActionDrawer from "apps/ui/CustomDrawer/CallToActionDrawer";
import ContentDrawer from "apps/ui/CustomDrawer/ContentDrawer";
import FormHeader from "apps/landlord/components/FormHeader";

import useFormPropertyDetailsStyles from "./FormPropertyDetailsStyles";

interface Props {
  isLoading?: boolean;
  onHandleCloseModal: () => void;
}

const FormPropertyDetails = ({
  isLoading = false,
  onHandleCloseModal,
}: Props) => {
  const { t } = useTranslation(["landlord.property.settingUp"]);
  const classes = useFormPropertyDetailsStyles();
  const {
    register,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();
  const [parkingQuantity, warehouse] = watch(["parkingQuantity", "warehouse"]);

  return (
    <>
      <ContentDrawer>
        <Box className={classes.containerRoot}>
          <FormHeader
            title={t("PROPERTY_DETAILS_FORM_TITLE")}
            subTitle={t("PROPERTY_DETAILS_FORM_SUBTITLE")}
          />
          <Box className={classes.fieldContainer}>
            <FieldInput
              id="yearBuilt"
              label={t("LABEL_BUILT_YEAR")}
              labelPosition="start"
              classNameInput="w-[72px] mr-[20px] ml-[20px] text-center"
              optionalLabel="(opcional)"
              type="number"
              min="0"
              step="1"
              {...register("yearBuilt")}
              errorMessage={errors.yearBuilt?.message}
            />
            <FieldInput
              id="mBuilt"
              label={t("LABEL_BUILT_AREA")}
              labelPosition="start"
              classNameInput="w-[72px] mr-[20px] ml-[20px] text-center"
              optionalLabel="(opcional)"
              type="number"
              min="0"
              {...register("mBuilt")}
              errorMessage={errors.mBuilt?.message}
            />
            <FieldInput
              id="mSurface"
              label={t("LABEL_TOTAL_SURFACE")}
              labelPosition="start"
              classNameInput="w-[72px] mr-[20px] ml-[20px] text-center"
              optionalLabel="(opcional)"
              type="number"
              min="0"
              {...register("mSurface")}
              errorMessage={errors.mSurface?.message}
            />
            <FieldCounter
              id="bedrooms"
              minValue={0}
              maxValue={20}
              label={t("LABEL_BEDROOMS")}
              labelPosition="start"
              required
              errorMessage={errors.bedrooms?.message}
              form="novalidateform"
              {...register("bedrooms")}
              onChange={(e) => setValue("bedrooms", Number(e))}
            />
            <FieldCounter
              id="bathrooms"
              minValue={0}
              maxValue={20}
              label={t("LABEL_BATHROOMS")}
              labelPosition="start"
              required
              errorMessage={errors.bathrooms?.message}
              {...register("bathrooms")}
              onChange={(e) => setValue("bathrooms", Number(e))}
              form="novalidateform"
            />
            <FieldCounter
              id="parkingQuantity"
              minValue={0}
              maxValue={20}
              label={t("LABEL_PARKINGS")}
              labelPosition="start"
              required
              errorMessage={errors.parkingQuantity?.message}
              form="novalidateform"
              {...register("parkingQuantity")}
              onChange={(e) => setValue("parkingQuantity", Number(e))}
            />
            {Number(parkingQuantity) > 0 && (
              <FieldInput
                id="parkingNumbers"
                label={t("LABEL_PARKING_SLOTS")}
                required
                errorMessage={errors.parkingNumbers?.message}
                form="novalidateform"
                {...register("parkingNumbers")}
              />
            )}
            <FieldSwitchSelector
              id="warehouse"
              options={[
                {
                  label: "Si",
                  value: "yes",
                },
                {
                  label: "No",
                  value: "no",
                },
              ]}
              labelPosition="start"
              label={t("LABEL_WAREHOUSE")}
              required
              errorMessage={errors.warehouse?.message}
              classNameSwitch="mr-[19px]"
              {...register("warehouse")}
            />
            {warehouse === "yes" && (
              <FieldInput
                id="warehouseNumber"
                label={t("LABEL_WAREHOUSE_SLOT")}
                required
                errorMessage={errors.warehouseNumber?.message}
                form="novalidateform"
                {...register("warehouseNumber")}
              />
            )}
          </Box>
        </Box>
      </ContentDrawer>
      <CallToActionDrawer>
        <Button
          type="button"
          variant="tertiary"
          size="md"
          onClick={onHandleCloseModal}
        >
          Atrás
        </Button>
        <Button isLoading={isLoading} variant="primary" size="md" type="submit">
          Siguiente
        </Button>
      </CallToActionDrawer>
    </>
  );
};

export default FormPropertyDetails;
