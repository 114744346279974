import { useHistory } from "react-router-dom";

import { IoCardOutline as CardIcon } from "@houm-com/ui-icons";

import { Box, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import useNoBankAccountCardStyles from "./NoBankAccountCardStyles";

const NoBankAccountCard = () => {
  const classes = useNoBankAccountCardStyles();
  const history = useHistory();

  return (
    <>
      <Box className={classes.bankAccountInfoRoot}>
        <Box className={classes.iconWrapper}>
          <CardIcon color="#46788C" size={21} />
        </Box>
        <Typography className={classes.noAccountText}>
          Agrega tu información bancaria aquí.
        </Typography>
      </Box>
      <DesignSystemButton
        label="Agregar cuenta +"
        size="small"
        variant="primary"
        buttonClassName={classes.addAccountButton}
        onClick={() => history.push("tenant/bank-account-form")}
      />
    </>
  );
};

export default NoBankAccountCard;
