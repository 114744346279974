import clx from "classnames";
import { Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import SuccessCheckIcon from "assets/icons/SuccessCheckIcon";
import { HOUM_COM_URL } from "env";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import leadService from "services/leadService";
import useStyle from "./SuccessFormCreateAccountStyles";
import { SuccessForm } from "../FormCreateAccount/formUtils";

interface Props {
  successForm: SuccessForm;
}

const SuccessFormCreateAccount = ({ successForm }: Props) => {
  const classes = useStyle();
  const { enqueueSnackbar } = useSnackbar();

  const handleSendEmail = async () => {
    try {
      await leadService.sendEmailVerificationEmail({
        email: successForm.user.email,
      });
      alert(
        `El correo electrónico de verificación se ha reenviado a ${successForm.user.email}`,
        "success"
      );
    } catch (err) {
      alert("Ups... Ha ocurrido un error", "error");
    }
  };
  const alert = (message: string, type) => {
    enqueueSnackbar(message, {
      autoHideDuration: 2000,
      variant: type,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  };

  return (
    <div className={classes.container}>
      <SuccessCheckIcon className={classes.icon} />
      <Typography component="h1" className={classes.title}>
        ¡Listo! Solo falta crear tu contraseña
      </Typography>
      <Typography className={classes.body}>
        Sigue las instrucciones que enviamos al correo{" "}
        <strong>{successForm.user.email}</strong> para poder acceder a tu cuenta
        en Houm.com
      </Typography>
      <div className={classes.paragraphContainer}>
        <Typography className={classes.paragraph}>
          Asegúrate de revisar tu bandeja de entrada y la carpeta de correo no
          deseado
        </Typography>
        <Typography className={clx(classes.paragraph, classes.bold)}>
          Este correo puede tardar hasta 5 minutos en llegar
        </Typography>
        <Typography className={classes.paragraph}>
          ¿No te llegó el correo?{" "}
          <DesignSystemButton
            label="Reenvíalo aquí"
            variant="tertiaryB"
            size="medium"
            type="submit"
            buttonClassName={classes.button}
            onClick={handleSendEmail}
          />
        </Typography>

        <a href={HOUM_COM_URL} className={classes.paragraph}>
          <span>Regresar a Houm.com</span>
        </a>
      </div>
    </div>
  );
};

export default SuccessFormCreateAccount;
