import { BankAccount } from "apps/landlord/hooks/useBankAccountByPropertyId/utils/types";
import { IBank } from "apps/landlord/models/BankAccount";
import { localeToCountry } from "utils/countryCode";
import { Locale } from "models/Countries";
import { accountTypes } from "utils";

const getDefaultDocumentType = (country: Locale) => {
  if (country === "cl") return "RUT";
  if (country === "mx") return "RFC";
  return "";
};

const getDefaultAccountType = (country: Locale) => {
  if (country === "mx") return "BANK";
  return "";
};

const getBankCodeId = (bankCode: number, bankList: IBank[]) =>
  bankList.find((bank) => bank.code === bankCode).bankId;

export const parseDefaultValues = (
  bankAccount: BankAccount,
  country: Locale,
  bankList: IBank[]
) => ({
  document: bankAccount?.document ?? "",
  fullName: bankAccount?.holder ?? "",
  bankCode: bankAccount?.bankCode
    ? getBankCodeId(bankAccount?.bankCode, bankList)
    : "",
  accountNumber: bankAccount?.accountNumber ?? null,
  accountType: bankAccount?.accountTypeLegacy ?? getDefaultAccountType(country),
  documentType: bankAccount?.documentType ?? getDefaultDocumentType(country),
  numberValidation: "",
  clabeNumber: bankAccount?.clabeNumber ?? null,
});

export const getAccountTypeOptions = (
  country: Locale
): { label: string; value: string }[] =>
  accountTypes[localeToCountry[country]].map((accountType) => ({
    label: accountType.name,
    value: accountType.code,
  }));
