import Button from "@houm-com/ui/Button";

import { classes } from "apps/lead/components/EvaluatorForm/CallToAction/CallToActionStyles";

interface Props {
  label?: string;
  onHandleBackClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}

const CallToAction = ({
  label = "Continuar",
  isLoading = false,
  disabled = false,
}: Props) => (
  <div className={classes.callToActionRoot}>
    <Button
      size="md"
      variant="primary"
      type="submit"
      disabled={disabled}
      isLoading={isLoading}
      className={classes.callToActionButton}
    >
      {label}
    </Button>
  </div>
);

export default CallToAction;
