import { makeStyles, Theme } from "@material-ui/core";

const useTimeSelectionStyles = makeStyles((theme: Theme) => ({
  timeSelectionRoot: {
    position: "relative",
    left: -theme.spacing(1),
    minWidth: "100vw",
    padding: theme.spacing(1),
    display: "flex",
    whiteSpace: "pre",
    background: "white",
    border: `1px solid ${theme.palette.grey[75]}`,
    [theme.breakpoints.up("sm")]: {
      left: theme.spacing(1),
      minWidth: 0,
      width: "100%",
    },
  },
}));

export default useTimeSelectionStyles;
