import * as yup from "yup";

const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required("Este campo es requerido")
    .notOneOf(
      [yup.ref("oldPassword")],
      "La nueva contraseña no puede ser igual a la anterior"
    )
    .matches(
      PASSWORD_REGEX,
      "La contraseña no cumple todos los requerimientos"
    ),
  oldPassword: yup.string().required("Este campo es requerido"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password")], "Las contraseñas no coinciden"),
});

export default validationSchema;
