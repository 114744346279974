import {
  State,
  Action,
  SET_CURRENT_PAGE,
  SET_LOADING,
  SET_PROPERTY,
  SET_BANK_LIST,
  SET_PRICE_SUGGEST,
  SET_PROPERTY_TYPE,
  SET_SIMILAR_PROPERTIES,
  SET_PROPERTY_SCHEDULE_AVAILABILITY,
  SET_FORM_SUBMITTED,
  SET_STATISTICS,
  SET_SUBSCRIPTION_PLAN,
  SET_READJUSTMENT,
  SET_OPEN_NEGOTIATIONS,
  SET_ACCEPTED_NEGOTIATION,
} from "./landlordPropertyTypes";

export const initialState: State = {
  currentPage: "menu",
  loading: true,
  propertyData: {
    id: 0,
    uid: "",
    address: "",
    streetNumber: 0,
    flatNumber: "",
    commune: "",
    country: "",
    photos: [],
    forRent: false,
    rentStatus: "",
    rentPrice: 0,
    rentCurrency: "",
    forSale: false,
    salesStatus: "",
    salesPrice: 0,
    salesCurrency: "",
    latitud: -32,
    longitud: 4,
    typeProperty: "",
    region: "",
    houmOwner: {
      email: "",
      last_name: "",
      name: "",
    },
    bedrooms: 0,
    bathrooms: 0,
    total_area: 0,
    built_area: 0,
    parking_spots: 0,
    is_furnished: false,
    regionRev: "",
    stratus: 0,
    locality: "",
    property_age: 0,
    municipality: "",
    gc: 0,
    disponibility: "",
    pets: false,
    orientation: "",
    warehouse: false,
    otherDetails: {
      calefaccion: false,
      en_suite: false,
      has_balcony: false,
      has_houm_cleaning: false,
      laundry_capacity: false,
      furnishedKitchen: false,
      curtains: false,
    },
    amenities: {
      piscina: false,
      gym: false,
      has_roof_garden: false,
    },
    video: "",
    houmChecker: null,
    contractId: null,
    subscriptionId: null,
    account: null,
    landlordId: null,
    propertyDetailsId: null,
    dptoNumber: null,
    schedules: null,
    plan: null,
    readjustment: null,
  },
  bankList: null,
  priceSuggest: null,
  contract: {
    formSubmitted: false,
  },
  propertyType: null,
  similarProperties: null,
  scheduleAvailability: [],
  loadingSchedule: true,
  hasOpenNegotiations: false,
  hasAcceptedNegotiation: false,
  statistics: {
    allVisits: 0,
    cancelledByHoum: 0,
    cancelledByUser: 0,
    cancelledNonConfirmation: 0,
    cancelledVisits: 0,
    doneVisits: 0,
    pageviews: 0,
  },
};

const apiReducer = (state: State, action: Action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.currentPage };
    case SET_LOADING:
      return { ...state, loading: action.loading };
    case SET_PROPERTY:
      return { ...state, propertyData: action.data };
    case SET_BANK_LIST:
      return { ...state, bankList: action.bankList };
    case SET_PRICE_SUGGEST:
      return { ...state, priceSuggest: action.priceSuggest };
    case SET_PROPERTY_TYPE:
      return { ...state, propertyType: action.propertyType };
    case SET_SIMILAR_PROPERTIES:
      return { ...state, similarProperties: action.similarProperties };
    case SET_PROPERTY_SCHEDULE_AVAILABILITY:
      return {
        ...state,
        loadingSchedule: false,
        scheduleAvailability: action.scheduleAvailability,
      };
    case SET_STATISTICS:
      return { ...state, statistics: action.statistics };
    case SET_FORM_SUBMITTED:
      return {
        ...state,
        contract: { ...state.contract, formSubmitted: action.formSubmitted },
      };
    case SET_SUBSCRIPTION_PLAN:
      return {
        ...state,
        propertyData: { ...state.propertyData, plan: action.plan },
      };
    case SET_READJUSTMENT:
      return {
        ...state,
        propertyData: {
          ...state.propertyData,
          readjustment: action.readjustment,
        },
      };
    case SET_OPEN_NEGOTIATIONS:
      return { ...state, hasOpenNegotiations: action.hasOpenNegotiations };
    case SET_ACCEPTED_NEGOTIATION:
      return {
        ...state,
        hasAcceptedNegotiation: action.hasAcceptedNegotiation,
      };
    default:
      return state;
  }
};

export default apiReducer;
