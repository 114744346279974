import { makeStyles, Theme } from "@material-ui/core";

const useKeepSearchingModalStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    maxWidth: 437,
  },
  title: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.4,
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
  },
  checkIcon: {
    height: 22,
    stroke: "#439F03",
    strokeWidth: 3,
    overflow: "visible !important",
    verticalAlign: "middle",
    marginTop: theme.spacing(-0.5),
    marginRight: theme.spacing(1),
  },
  contentDialog: {
    paddingBottom: 0,
  },
}));

export default useKeepSearchingModalStyles;
