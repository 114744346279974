import { Typography } from "@material-ui/core";

import getWeekDays from "apps/tenant/components/Forms/DayHoursPicker/utils";
import { IVisitHour } from "apps/tenant/hooks/utils/types";
import { convertHourToAmPmformat } from "../utils";

interface Props {
  visitHour: IVisitHour;
  index: number;
}

const VisitHourText = ({ visitHour, index }: Props) => {
  const weekDays = getWeekDays();
  const day = weekDays[index];
  const startHour = convertHourToAmPmformat(visitHour.hours[0]);
  const endHour = convertHourToAmPmformat(visitHour.hours[1]);
  return (
    <Typography component="span">
      {index + 1}. <strong>{day}</strong> en un horario de{" "}
      <strong>
        {startHour} a {endHour}
      </strong>
    </Typography>
  );
};

export default VisitHourText;
