import { makeStyles, Theme } from "@material-ui/core";

const usePropertyApplicationCardStyles = makeStyles<Theme>((theme) => ({
  chipPending: {
    backgroundColor: "#FAF3CC",
    color: "#A07800",
  },
  chipApproved: {
    backgroundColor: "#DAEECC",
    color: "#439F03",
  },
  chipRejected: {
    backgroundColor: "#EFCDCC",
    color: "#900000",
  },
  chipReview: {
    backgroundColor: "#D5E3F8",
    color: "#2663BC",
  },
}));

export default usePropertyApplicationCardStyles;
