import { Trans } from "react-i18next";

import { ClassNameMap } from "@material-ui/styles";

import { ITenantContract } from "apps/tenant/models/Contract";
import { formatDate } from "apps/tenant/utils/utils";

import { InfoStepProps } from "../components/InfoStep/InfoStep";
import { StepData } from "./models";

const getInfoSteps = async (
  contract: ITenantContract,
  classes: ClassNameMap<string>
): Promise<InfoStepProps[]> => {
  const { data: countryContent }: StepData = await import(
    `./${contract.country}-steps.json`
  );

  const Interpolation = ({ text }: { text: string }) => (
    <Trans
      i18nKey={text}
      values={{
        contract_init_date: formatDate(contract.initDate),
        notice_period: contract.endNoticePeriod,
      }}
      components={{
        1: <strong />,
        2: <br />,
        link1: (
          // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label
          <a
            href="https://houm.kustomer.help/es/-HkPXSUAJ2"
            target="_blank"
            className={classes.link}
          />
        ),
      }}
    />
  );

  const infoSteps = countryContent.map((step) => ({
    ...step,
    content: <Interpolation text={step.content} />,
  }));

  return infoSteps;
};

export default getInfoSteps;
