import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useCardMenuItemStyles = makeStyles<Theme>((theme) => ({
  cardRoot: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  infoTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.44,
    marginBottom: theme.spacing(0),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.4,
    },
  },
  infoColumn: {
    marginTop: theme.spacing(1),
    borderRadius: 8,
    backgroundColor: "white",
    padding: theme.spacing(2, 1),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(3),
    },
  },
  buttonContainer: {
    marginTop: theme.spacing(3),
  },
  subtitleText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.58,
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default useCardMenuItemStyles;
