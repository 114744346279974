import { Controller, useFormContext } from "react-hook-form";

import { MenuItem, Select, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import useBasicServicesSelectStyles from "./BasicServicesSelectStyles";

interface Props {
  id: string;
  options: { label: string; value: string }[];
  required?: boolean;
  defaultValue?: string | null;
  setCompanyNameSelected: (x: boolean) => void;
}

export default function BasicServicesSelect({
  options,
  id,
  required = true,
  defaultValue,
  setCompanyNameSelected,
}: Props) {
  const classes = useBasicServicesSelectStyles();
  const { control } = useFormContext();
  return (
    <>
      <Typography className={classes.formSectionLabel}>
        {"Selecciona una empresa "}
        {required && (
          <span className={classes.formSectionRequiredField}>*</span>
        )}
      </Typography>
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue}
        render={({
          field: { value, onChange, ref },
          fieldState: { invalid, error },
        }) => (
          <>
            <Select
              disabled={!!defaultValue}
              className={classes.formSectionTextField}
              IconComponent={ExpandMore}
              classes={{
                select: classes.formSectionSelect,
              }}
              disableUnderline
              onChange={(e) => {
                onChange(e);
                setCompanyNameSelected(true);
              }}
              value={value}
              ref={ref}
              defaultValue={defaultValue}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {invalid && (
              <Typography className={classes.errorMessage}>
                {error?.message}
              </Typography>
            )}
          </>
        )}
      />
    </>
  );
}
