import { useTranslation } from "react-i18next";

import { Locale } from "models/Countries";
import { FavoriteProperty } from "apps/account/models/FavoriteProperty";

import { FavoriteMode } from "../../utils/types";
import ImageContainer from "./components/ImageContainer";
import usePropertyCardStyles from "./usePropertyCardStyles";
import { getPropertyListingUrl } from "./utils";
import DetailsContainer from "./components/DetailsContainer";

interface Props {
  property: FavoriteProperty;
  mode: FavoriteMode;
  handleFavoriteToggle?: () => void;
  locale: Locale;
  isFavorite?: boolean;
}

const PropertyCard = ({
  property,
  mode,
  handleFavoriteToggle,
  locale,
  isFavorite = false,
}: Props) => {
  const classes = usePropertyCardStyles();
  const { t: translate } = useTranslation("shared");
  const propertyUrl = getPropertyListingUrl({
    property,
    mode,
    locale,
    translate,
  });

  return (
    <div
      className={classes.card}
      onClick={() => window.open(propertyUrl, "_blank")}
    >
      <ImageContainer
        handleFavoriteToggle={handleFavoriteToggle}
        isFavorite={isFavorite}
        mode={mode}
        property={property}
      />
      <DetailsContainer
        property={property}
        toScheduleUrl={`${propertyUrl}?toSchedule=true`}
      />
    </div>
  );
};
export default PropertyCard;
