import { makeStyles, Theme } from "@material-ui/core";

const useHabitableDisclaimerStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: "#DF9B99",
    width: "100%",
    borderRadius: 4,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    color: " #900000",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: "1rem",
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.pxToRem(10),
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export default useHabitableDisclaimerStyles;
