import CancelIcon from "@material-ui/icons/Cancel";
import { Container, Typography } from "@material-ui/core";

import { IoDocumentOutline } from "@houm-com/ui-icons";

import { Files } from "apps/lead/models/Application";

import useButtonFullFileStyles from "./ButtonFullFileStyles";

interface IProps {
  file: Files;
  handleDelete: (file: string) => void;
}

const ButtonFullFile = ({ file, handleDelete }: IProps) => {
  const classes = useButtonFullFileStyles();

  return (
    <Container
      aria-label="file-uploaded"
      component="label"
      className={classes.containerFile}
    >
      <div className={classes.containerText}>
        <IoDocumentOutline color="#46788C" className={classes.icon} />
        <Typography className={classes.fileName}>
          {file?.name.length > 8
            ? `${file.name.substring(0, 8)}...`
            : file.name}
        </Typography>
      </div>
      <div className={classes.iconContainer}>
        <CancelIcon
          aria-label="Eliminar"
          className={classes.deleteFileUploaded}
          onClick={(e) => {
            e.preventDefault();
            handleDelete(file?.name);
          }}
        />
      </div>
    </Container>
  );
};

export default ButtonFullFile;
