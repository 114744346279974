interface Props {
  title: string;
  subTitle?: string;
}

const FormHeader = ({ title, subTitle }: Props) => (
  <div className="grid gap-2">
    <h1 className="text-p20 font-bold">{title}</h1>
    {subTitle && <p className="text-p16">{subTitle}</p>}
  </div>
);

export default FormHeader;
