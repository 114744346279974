import { Theme, makeStyles } from "@material-ui/core";

const useScheduleSummaryStep = makeStyles<Theme>((theme) => ({
  shedulePhotoContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    justifyContent: "flex-start",
    minHeight: "unset",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 5),
    },
  },
  shedulePhotoContentText: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  schedulePhotoTitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(28),
  },
  shedulePhotoPhotographerCard: {
    display: "flex",
    gap: theme.spacing(1),
    flexDirection: "column",
    padding: theme.spacing(2),
    alignItems: "center",
    border: `1px solid ${theme.palette.grey[75]}`,
    borderRadius: theme.spacing(1),
    boxShadow: "3px 4px 15px 0px rgba(38, 50, 56, 0.15)",
    textAlign: "center",
  },
  schedulePhotoPhotographerPhoto: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    borderRadius: "50%",
    border: `1px solid ${theme.palette.grey[75]}`,
  },
  shedulePhotoDateDetail: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
  shedulePhotoGuides: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[75]}`,
    borderRadius: theme.spacing(1),
  },
  shedulePhotoHoumBoxGuide: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[75],
    borderRadius: theme.spacing(1),
  },
  schedulePhotoHoumboxGudeTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
  schedulePhotoGuideDetail: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(0.5),
    alignItems: "flex-start",
  },
  schedulePhotoGuideIcon: {
    paddingTop: theme.spacing(0.2),
  },
  schedulePhotoGuideText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  schedulePhotoHoumboxGudeDescription: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    "& a": {
      fontWeight: 700,
      color: "#2C73DB",
      textDecoration: "underline",
    },
  },
}));

export default useScheduleSummaryStep;
