import { Grid } from "@material-ui/core";

import TabMenuEvaluation from "./components/TabMenuEvaluation";
import EvaluationsTabs from "./components/EvaluationsTabs";
import Header from "./components/Header";

const MainTenant = () => (
  <Grid container justifyContent="flex-end">
    <Grid item xs={12}>
      <Header title="Mi Houm">
        <TabMenuEvaluation />
      </Header>
      <EvaluationsTabs />
    </Grid>
  </Grid>
);

export default MainTenant;
