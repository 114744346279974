import { useMemo } from "react";

interface LocationProps {
  pathname: string;
}

export const inUrl = (substring: string, location: LocationProps) =>
  useMemo(() => {
    if (location.pathname.indexOf(substring) !== -1) {
      return true;
    } else {
      return false;
    }
  }, [location.pathname]);
