import { useTranslation } from "react-i18next";

import { Box, Typography } from "@material-ui/core";

import { IoInformationCircleOutline } from "@houm-com/ui-icons";

import useDisclaimerStyles from "./DisclaimerStyles";

const Disclaimer = () => {
  const { t } = useTranslation("rent-payment");
  const classes = useDisclaimerStyles();

  return (
    <Box className={classes.disclaimerRoot}>
      <IoInformationCircleOutline color="#124FA8" className={classes.icon} />
      <Typography className={classes.text}>
        {t("RENT_PAYMENT_DISCLAIMER")}
      </Typography>
    </Box>
  );
};

export default Disclaimer;
