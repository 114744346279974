import { Link } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import { Locale } from "models/Countries";

import { textsByCountry } from "../../utils/utils";
import useStyles from "./RefundInfoStyles";

interface Props {
  countryCode: Locale;
}

const Landing = ({ countryCode = "mx" }: Props) => {
  const classes = useStyles();

  const rulesPerCountry = () => {
    switch (countryCode) {
      case "cl":
        return (
          <>
            <Typography>
              {textsByCountry[countryCode].landing.returnPolicy}
              <br />
              <Link
                to={{
                  pathname:
                    textsByCountry[countryCode].landing.returnPolicyLink,
                }}
                target="_blank"
              >
                {textsByCountry[countryCode].landing.returnPolicyTextLink}
              </Link>
            </Typography>
            <Typography>
              {textsByCountry[countryCode].landing.otherPolicies}
              <br />
              <Link
                to={{
                  pathname:
                    textsByCountry[countryCode].landing.cleaningPolicyLink,
                }}
                target="_blank"
              >
                {textsByCountry[countryCode].landing.cleaningPolicy}
              </Link>
              <br />
              <Link
                to={{
                  pathname:
                    textsByCountry[countryCode].landing.repairPolicyLink,
                }}
                target="_blank"
              >
                {textsByCountry[countryCode].landing.repairPolicy}
              </Link>
            </Typography>
          </>
        );
      case "co":
        return (
          <>
            <Typography>
              Se te <b>hará la devolución total</b> de la reserva en los
              siguientes casos:
            </Typography>
            <ul className={classes.list}>
              <li>
                Si la propiedad ya no se encuentra disponible para ser rentada
                por medio de Houm, sin importar el motivo.
              </li>
              <li>
                Si tu perfil no es aprobado comercialmente por Houm o por el
                propietario, de acuerdo a los estudios de viabilidad e historial
                crediticio.
              </li>
              <li>
                En caso de que decidas no seguir con el proceso por un error en
                la publicación o cambios en las condiciones de arrendamiento.
              </li>
            </ul>
            <Typography>
              De lo contrario, <b>no se te devolverá la reserva</b> si decides
              no seguir con el proceso después de ser aprobado por el
              propietario.
            </Typography>
          </>
        );
      case "mx":
      default:
        return (
          <>
            <Typography>
              Se te devuelve el 100% de tu reserva antes de buró de crédito en
              el caso de que aplique cualquiera de los siguientes casos:
            </Typography>
            <Typography>
              <strong>Antes de buró de crédito:</strong>
            </Typography>
            <ul className={classes.list}>
              <li>El perfil del interesado es rechazado por el propietario.</li>
              <li>
                El propietario desiste del servicio, renta a un familiar u otro
                directo o por medio de corredora.
              </li>
              <li>
                Suspensión de contrato de renta o mandato por parte de Houm o
                propietario.
              </li>
            </ul>
          </>
        );
    }
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        {textsByCountry[countryCode].landing.policiesAndConsiderations}
      </Typography>
      <Grid container spacing={5}>
        <Grid item xs={12} md={12}>
          <Paper elevation={0} className={classes.paper}>
            {rulesPerCountry()}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Landing;
