import { Box, Typography } from "@material-ui/core";
import { isMobile } from "react-device-detect";

import { useApplicant } from "apps/landlord/pages/Applicant/context/ApplicantContext";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { Application, EvaluationStatus } from "models/Evaluation";
import ApplicantInfo from "../../../ApplicantInfo";
import EvaluationDialog from "../../../EvaluationDialog";
import { getCreatedAt, getNameMainApplicant } from "../../utils";
import useDetailCardStyles from "./DetailCardStyles";

interface DetailCardProps {
  application: Application;
}

const DetailCard = ({ application }: DetailCardProps) => {
  const classes = useDetailCardStyles();
  const { lastApplication, handleEvaluationModalApplication } = useApplicant();

  return (
    <Box className={classes.detailContainer}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>
          {getNameMainApplicant(application.applicants)}
        </Typography>
        <Typography className={classes.subTitle}>
          {`Postulación descartada el ${getCreatedAt(application.created_at)} `}
          <span role="img" aria-label="crying-face">
            😢
          </span>
        </Typography>
        <Typography className={classes.text}>
          {`Esta postulación fue declinada por ti. Si te arrepientes, `}
          <span className={classes.boldText}>
            puedes volver a evaluar al postulante:
          </span>
        </Typography>
      </Box>
      <ApplicantInfo application={application} />
      <Box className={classes.buttonContainer}>
        <DesignSystemButton
          variant="primary"
          size="medium"
          label="Volver a evaluar"
          id="re-evaluate-button"
          fullWidth={isMobile}
          disabled={
            Boolean(lastApplication) ||
            application.status === EvaluationStatus.REJECTED
          }
          buttonClassName={classes.button}
          onClick={() => handleEvaluationModalApplication(application)}
        />
      </Box>
      <EvaluationDialog />
    </Box>
  );
};

export default DetailCard;
