import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { isMobile } from "react-device-detect";

import { Box } from "@material-ui/core";
import Swal from "sweetalert2";
import { yupResolver } from "@hookform/resolvers/yup";

import { BSCompanyForm } from "apps/landlord/models/ServicesCompanies";
import useCompanies from "apps/landlord/hooks/propertyBills/useCompanies";
import useChangeBasicServices from "apps/landlord/hooks/propertyBills/useChangeBasicServices";
import useBillsInfo from "apps/landlord/hooks/propertyBills/useBillInfo";
import Spinner from "Components/Shared/partials/Spinner";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { Locale } from "models/Countries";

import BasicServicesSection from "./components/BasicServicesSection";
import formSchema from "./validationSchema";
import classes from "./BasicServicesFormStyles";

interface Props {
  locale: Locale;
}

export default function BasicServicesForm({ locale }: Props) {
  const {
    isLoading,
    error,
    waterCompaniesOptions,
    energyCompaniesOptions,
    gasCompaniesOptions,
  } = useCompanies();
  const {
    data: billsData,
    isLoading: billsLoading,
    error: billsError,
  } = useBillsInfo();
  const { mutate, submitting, isSuccess } = useChangeBasicServices();
  const methods = useForm<BSCompanyForm>({
    resolver: yupResolver(formSchema),
    reValidateMode: "onSubmit",
  });
  if (isLoading || billsLoading) return <Spinner />;
  if (error || billsError) {
    Swal.fire(
      "Ha ocurrido un error",
      `Actualice la página o contacte a su ejecutivo/a. ${error || billsError}`,
      "error"
    );
    return null;
  }
  const onSubmit: SubmitHandler<BSCompanyForm> = async (formData) => {
    mutate(formData);
  };

  const waterCompanySelectedName = billsData?.water?.company;
  const energyCompanySelectedName = billsData?.energy?.company;
  const gasCompanySelectedName = billsData?.gas?.company;

  const waterCompanySelectedNumber = billsData?.water?.clientInfo.externalId;
  const energyCompanySelectedNumber = billsData?.energy?.clientInfo.externalId;
  const gasCompanySelectedNumber = billsData?.gas?.clientInfo.externalId;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={classes.basicServicesForm}
      >
        <Box className={classes.basicServicesFormContainer}>
          <BasicServicesSection
            idBase="water"
            title="Agua"
            options={waterCompaniesOptions}
            defaultSelectValue={waterCompanySelectedName}
            defaultFieldValue={waterCompanySelectedNumber}
          />
          <BasicServicesSection
            idBase="energy"
            title="Luz"
            options={energyCompaniesOptions}
            defaultSelectValue={energyCompanySelectedName}
            defaultFieldValue={energyCompanySelectedNumber}
          />
          {locale !== "mx" && (
            <BasicServicesSection
              idBase="gas"
              title="Gas"
              options={gasCompaniesOptions}
              required={false}
              defaultSelectValue={gasCompanySelectedName}
              defaultFieldValue={gasCompanySelectedNumber}
            />
          )}
        </Box>
        <DesignSystemButton
          label="Guardar"
          size={isMobile ? "small" : "medium"}
          variant="primary"
          fullWidth={false}
          type="submit"
          isLoading={submitting}
          disabled={isSuccess}
        />
      </form>
    </FormProvider>
  );
}
