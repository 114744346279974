import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

export default makeStyles((theme: Theme) => ({
  componentContainer: {
    background: theme.palette.grey[10],
    padding: theme.spacing(4.75, 5, 4.75, 5),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(7, 13),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    color: theme.palette.grey[900],
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(28),
    },
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: theme.palette.grey[900],
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  gridContainer: {
    marginTop: theme.spacing(3.8),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginTop: theme.spacing(7),
    },
  },
  containerTitle: {
    marginBottom: theme.spacing(9),
    "& p": {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
  },
  detailsContainer: {
    "& p": {
      lineHeight: 0.5,
      fontSize: theme.typography.pxToRem(16),
    },
    "& span": {
      fontWeight: 700,
    },
  },
  cardContainer: {
    padding: theme.spacing(3, 0),
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(6.75, 11.5),
    },
  },
  cardContainerDate: {
    padding: theme.spacing(2.5),
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(3, 11.5),
    },
  },
  titleday: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: "bold",
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(2.8),
  },
}));
