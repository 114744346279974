import { capitalize } from "lodash";

import {
  Applicant,
  ApplicantFormated,
} from "apps/lead/context/codebtorContext/CodebtorTypes";

export const parseCodebtorData = (
  applicant: Partial<Applicant>,
  data: ApplicantFormated
) => ({
  ...applicant,
  ...data,
  name: capitalize(data.name),
  lastName: capitalize(data.lastName),
  secondLastName: capitalize(data.secondLastName),
});

export default parseCodebtorData;
