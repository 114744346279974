import { SyntheticEvent } from "react";
import { isBrowser } from "react-device-detect";

import { IconButton, Slide, SlideProps, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";

import useAlertSnackbarStyles from "./AlertSnackbarStyles";

type TransitionProps = Omit<SlideProps, "direction">;

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

type Color = "success" | "info" | "warning" | "error";

interface Props {
  color: string;
  handleClose?: (event?: SyntheticEvent, reason?: string) => void;
  open: boolean;
  reason: {
    type: Color;
    text: string;
  };
}

const AlertSnackbar = ({ reason, color, handleClose, open }: Props) => {
  const classes = useAlertSnackbarStyles({ backgroundSnackbar: color });

  return (
    <Snackbar
      TransitionComponent={isBrowser ? TransitionDown : TransitionUp}
      key={TransitionUp.name}
      autoHideDuration={1500}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: isBrowser ? "top" : "bottom",
        horizontal: isBrowser ? "right" : "center",
      }}
    >
      <div className={classes.rootAlert}>
        <Alert
          severity={reason?.type}
          className={classes.alert}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
              className={classes.icon}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <div>{reason?.text}</div>
        </Alert>
      </div>
    </Snackbar>
  );
};

export default AlertSnackbar;
