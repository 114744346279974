import { makeStyles, Theme } from "@material-ui/core";

const useSubscriptionPlanOptionsStyles = makeStyles<Theme>((theme) => ({
  componentContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
    gap: 12,
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
      flexDirection: "row",
    },
  },
}));

export default useSubscriptionPlanOptionsStyles;
