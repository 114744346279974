import { makeStyles, Theme } from "@material-ui/core";

const useSpinnerStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
  },
  icon: {
    fontSize: theme.typography.pxToRem(30),
  },
}));

export default useSpinnerStyles;
