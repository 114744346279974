import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useApplicationsListStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      paddingBottom: theme.spacing(0),
      position: "relative",
      height: "calc(100vh - 220px)",
    },
  },
  cardContainer: {
    marginBottom: theme.spacing(1),
  },
  container: {
    [theme.breakpoints.up(defaultBreakpoint)]: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "100%",
      overflowY: "auto",
      width: "100%",
      padding: theme.spacing(0.5, 1.5, 1, 1.5),
      "&::-webkit-scrollbar": {
        width: 30,
        display: "block",
      },
      "&::-webkit-scrollbar-track": {
        background: "white",
      },
      "&::-webkit-scrollbar-track-piece:start": {
        border: "10px solid white",
        borderRadius: 40,
        marginTop: -10,
        background: theme.palette.grey[50],
      },
      "&::-webkit-scrollbar-track-piece:end": {
        border: "10px solid white",
        borderRadius: 40,
        marginTop: -10,
        background: theme.palette.grey[50],
      },
      "&::-webkit-scrollbar-thumb": {
        position: "relative",
        border: "10px solid transparent",
        borderRadius: 40,
        background: "#4C6977",
        backgroundClip: "padding-box",
        height: 20,
        "&::after": {
          position: "absolute",
          content: "",
          zIndex: -1,
          borderRadius: 40,
          height: 20,
        },
      },
    },
  },
  loading: {
    height: "auto",
  },
}));

export default useApplicationsListStyles;
