import {
  Applicant,
  Files,
  WorkerType,
} from "apps/lead/context/evaluationContext/EvaluationTypes";

export const parseWorkerType = (
  user: Partial<Applicant>,
  workerType: WorkerType
) => ({
  ...user,
  files: {
    identityCard: user.files.identityCard,
  },
  workerType,
});

export const parseUserFiles = (
  user: Partial<Applicant>,
  exactlyFile: { [key: string]: Files[] }
) => ({
  ...user,
  files: {
    ...user.files,
    ...exactlyFile,
  },
});
