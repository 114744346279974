import { makeStyles, Theme } from "@material-ui/core";

interface HistorialCardStylesProps {
  isSelected: boolean;
}

const useHistorialCardStyles = makeStyles<Theme, HistorialCardStylesProps>(
  (theme) => ({
    title: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 700,
      lineHeight: 1.5,
      marginBottom: theme.spacing(1),
    },
    text: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.66,
    },
    boldText: {
      fontWeight: 700,
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    applicantHistorialCard: {
      display: "flex",
      flexDirection: "column",
      border: (props) =>
        `1px solid ${props.isSelected ? "#4C6977" : theme.palette.grey[75]}`,
      borderRadius: 16,
      padding: theme.spacing(2),
      boxShadow: "none",
      [theme.breakpoints.up("sm")]: {
        width: 343,
        "&:hover": {
          backgroundColor: theme.palette.grey[5],
        },
      },
    },
    reevaluate: {
      color: theme.palette.primary.main,
      marginTop: theme.spacing(0.5),
      fontWeight: 700,
      textDecoration: "underline",
    },
  })
);

export default useHistorialCardStyles;
