import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  selected: boolean;
}

const useTimeOptionStyles = makeStyles<Theme, Props>((theme) => ({
  timeOptionRoot: {
    padding: theme.spacing(0.5, 1.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 40,
    backgroundColor: ({ selected }) =>
      selected ? theme.palette.grey[50] : "white",
    border: ({ selected }) => `1px solid ${selected ? "#4C6977" : "#A0B1B9"}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: ({ selected }) => (selected ? 700 : 400),
  },
}));

export default useTimeOptionStyles;
