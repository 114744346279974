import { useMemo } from "react";

import { Box, Typography } from "@material-ui/core";
import Button from "@houm-com/ui/Button";
import { IoSearchOutline as SearchIcon } from "@houm-com/ui-icons";

import usePhotoScheduled from "apps/landlord/pages/Property/components/PropertyPhotoSchedule/hooks/usePhotoScheduled";
import photographerAvatarImage from "assets/images/photographer-placeholder.png";
import CallToActionDrawer from "apps/ui/CustomDrawer/CallToActionDrawer";
import PhotoSpecsIcon from "assets/icons/Landlord/PhotoSpecsIcon";
import useCustomDrawer from "apps/landlord/hooks/useCustomDrawer";
import CleaningIcon from "assets/icons/Landlord/CleaningIcon";
import DrawerSpinner from "apps/ui/CustomDrawer/DrawerSpinner";
import ContentDrawer from "apps/ui/CustomDrawer/ContentDrawer";
import { useProperty } from "apps/landlord/hooks";

import { getReadableDate } from "../../hooks/utils";
import useScheduleSummaryStep from "./SummaryStepStyles";
import { STEPS } from "../../hooks/types";

const SummaryStep = () => {
  const classes = useScheduleSummaryStep();
  const { propertyData } = useProperty();
  const { returnToMainPage, setCurrentStep } = useCustomDrawer();
  const { data: scheduleData, isLoading } = usePhotoScheduled(
    propertyData?.uid
  );

  const goToScheduleStep = () => setCurrentStep(STEPS.Schedule);

  const photographerName = scheduleData?.appraiser?.name || "Fotógrafo Houm";

  const fullReadableDate = useMemo(
    () => getReadableDate(scheduleData?.begin_date),
    [scheduleData]
  );

  return (
    <>
      {isLoading && <DrawerSpinner />}
      <ContentDrawer contentClassName={classes.shedulePhotoContent}>
        <Box className={classes.shedulePhotoContentText}>
          <Typography className={classes.schedulePhotoTitle}>
            ¡Fotografías agendadas con éxito!
          </Typography>
        </Box>
        <Box className={classes.shedulePhotoPhotographerCard}>
          <img
            className={classes.schedulePhotoPhotographerPhoto}
            src={photographerAvatarImage}
            alt="foto de fotografo asignado"
          />
          <Typography>
            <strong>Fotógrafo profesional</strong> {photographerName}
            <Typography className={classes.shedulePhotoDateDetail}>
              {fullReadableDate}
            </Typography>
          </Typography>
        </Box>
        <Box className={classes.shedulePhotoGuides}>
          <Typography>
            <strong>Prepárate para tus fotografías gratuitas</strong>
          </Typography>
          <Box className={classes.schedulePhotoGuideDetail}>
            <PhotoSpecsIcon className={classes.schedulePhotoGuideIcon} />
            <Typography className={classes.schedulePhotoGuideText}>
              Tomaremos fotos de todos los espacios
            </Typography>
          </Box>
          <Box className={classes.schedulePhotoGuideDetail}>
            <CleaningIcon className={classes.schedulePhotoGuideIcon} />
            <Typography className={classes.schedulePhotoGuideText}>
              Asegúrate que cada espacio este limpio y ordenado
            </Typography>
          </Box>
          <Box className={classes.schedulePhotoGuideDetail}>
            <SearchIcon className={classes.schedulePhotoGuideIcon} />
            <Typography className={classes.schedulePhotoGuideText}>
              Haremos una breve inspección de cada espacio
            </Typography>
          </Box>
        </Box>
        <Box className={classes.shedulePhotoHoumBoxGuide}>
          <Typography className={classes.schedulePhotoHoumboxGudeTitle}>
            ¡Olvídate de coordinar tus visitas!
          </Typography>
          <Typography className={classes.schedulePhotoHoumboxGudeDescription}>
            Solicita tu Houmbox, nuestras cajas de seguridad donde quedan tus
            llaves. Si quieres saber más haz{" "}
            <a href="https://help.houm.com/cl/articles/16">click aquí</a>
          </Typography>
        </Box>
      </ContentDrawer>
      <CallToActionDrawer>
        <Button onClick={goToScheduleStep} variant="tertiary" size="md">
          Reagendar
        </Button>
        <Button onClick={returnToMainPage} variant="primary" size="md">
          Ver mi propiedad
        </Button>
      </CallToActionDrawer>
    </>
  );
};

export default SummaryStep;
