import currency from "@houm-com/formats/currency";
import { useParams } from "react-router-dom";

import { Box, Grid, Typography } from "@material-ui/core";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import { useContract } from "apps/landlord/hooks";

import usePaymentBreakdownStyles from "./PaymentBreakdownStyles";
import { IPaymentDetail } from "../../hooks/utils/types";
import Breakdown from "./components/Breakdown";
import Card from "../Card";

interface Props {
  data: IPaymentDetail;
}

const PaymentBreakdown = ({ data }: Props) => {
  const classes = usePaymentBreakdownStyles();
  const { id: propertyId } = useParams<{ id: string }>();
  const { contractData, contractLoading } = useContract(propertyId);

  return (
    <Grid item xs={12} sm={6}>
      <Card title="Desglose">
        <Loading loading={contractLoading}>
          <>
            <Box className={classes.paymentDetailContainer}>
              <Breakdown
                label="Valor del arriendo mensual"
                value={currency(data.rentPrice, data.currency)}
              />
              {data.planCharges && (
                <Breakdown
                  label={`${contractData?.plan?.name} 
              (${contractData?.plan?.admin_commission}% + IVA)`}
                  value={`- ${currency(data.planCharges, data.currency)}`}
                />
              )}
              {!!data.readjustment && data.readjustment?.totalValue !== 0 && (
                <Breakdown
                  label="Reajuste"
                  value={currency(data.readjustment.totalValue, data.currency)}
                />
              )}
              {data.originalAmountBreakdown.map((original) => (
                <Breakdown
                  label={original.description}
                  value={currency(original.value, data.currency)}
                  key={original.identifier}
                />
              ))}
              {data.aditionalsBreakdown.map((aditional) => (
                <Breakdown
                  label={aditional.description}
                  value={currency(aditional.value, data.currency)}
                  key={aditional.identifier}
                />
              ))}
              {data.discountsBreakdown.map((discount) => (
                <Breakdown
                  label={discount.description}
                  value={`- ${currency(discount.value, data.currency)}`}
                  key={discount.identifier}
                />
              ))}
              {data.promotionsBreakdown.map((promotion) => (
                <Breakdown
                  label={`Promoción: ${promotion.description}`}
                  value={`${promotion.value < 0 ? "- " : ""}${currency(
                    Math.abs(promotion.value),
                    data.currency
                  )}`}
                  key={promotion.identifier}
                />
              ))}
            </Box>
            <Box className={classes.totalDetail}>
              <Typography>Arriendo depositado</Typography>
              <Typography>
                {currency(data.totalPrice, data.currency)}
              </Typography>
            </Box>
          </>
        </Loading>
      </Card>
    </Grid>
  );
};

export default PaymentBreakdown;
