import { Grid } from "@material-ui/core";
import CustomRadioButton from "apps/tenant/components/Forms/CustomRadioButton";
import CustomSlider from "apps/tenant/components/Forms/CustomSlider";

import { IVisitHour } from "apps/tenant/hooks/utils/types";
import useHourIntervalsStyles from "./HourIntervalStyles";

interface Props {
  label: string;
  value: IVisitHour;
  onCheckChange: (value: boolean) => void;
  onHourChange: (value: [number, number]) => void;
  disabled?: boolean;
  intervalOptions: { label: string; value: string }[];
}

const HourInterval = ({
  label,
  value,
  onCheckChange,
  onHourChange,
  disabled = false,
  intervalOptions,
}: Props) => {
  const interval = value.hours.join("-");
  const parseToTuple = (optionValue: string): [number, number] => {
    const [start, end] = optionValue.split("-").map((hour) => parseInt(hour));
    return [start, end];
  };
  const classes = useHourIntervalsStyles();
  return (
    <Grid container xs={12} className={classes.intervalContainer}>
      <Grid item xs={5}>
        <CustomSlider
          disabled={disabled}
          label={label}
          value={value.checked}
          onChange={(checkValue) => onCheckChange(checkValue)}
        />
      </Grid>
      <Grid item xs={7}>
        <CustomRadioButton
          disabled={!value.checked}
          value={interval}
          onChange={(e) => onHourChange(parseToTuple(e.target.value))}
          id="repairType"
          options={intervalOptions}
        />
      </Grid>
    </Grid>
  );
};

export default HourInterval;
