import { makeStyles, Theme } from "@material-ui/core";

const useFavoritesStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    width: "100%",
    backgroundColor: "#FFF",
    borderRadius: 8,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3),
      width: "50%",
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(26),
    fontWeight: 700,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(26),
      lineHeight: theme.typography.pxToRem(34),
    },
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  item: {
    display: "flex",
    width: "100%",
    padding: theme.spacing(1, 2),
    cursor: "pointer",
  },
  iconContainer: {
    width: 100,
    height: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DAE4E8",
    borderRadius: 16,
    flexShrink: 0,
  },
  icon: {
    color: "#6B93A3",
  },
  textContainer: {
    marginLeft: theme.spacing(1),
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  itemTitle: {
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(22),
    fontSize: theme.typography.pxToRem(14),
  },
  itemText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
  arrowIcon: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

export default useFavoritesStyles;
