import { makeStyles, Theme } from "@material-ui/core";

const useFileSelectionStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 0),
  },
  labelWrapper: {
    display: "flex",
    margin: 0,
    width: "auto",
  },
  label: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
  },
  divider: {
    backgroundColor: theme.palette.grey[75],
  },
  textContainer: {
    display: "flex",
    pointerEvents: "auto",
    alignItems: "center",
  },
}));

export default useFileSelectionStyles;
