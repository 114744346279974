/* eslint-disable max-lines */
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { es } from "date-fns/locale";

import { Grid } from "@material-ui/core";

import { IProperty } from "apps/landlord/models/Property";

import useSettingUpDashboardStyles from "./SettingUpDashboardStyles";
import SettingUpCard from "./components/SettingUpCard";
import useSettingUpDashboard from "./hooks/useSettingUpDashboard";
import usePhotoScheduled from "../PropertyPhotoSchedule/hooks/usePhotoScheduled";

interface SettingUpDashboardProps {
  propertyData: IProperty;
}

const SettingUpDashboard = ({ propertyData }: SettingUpDashboardProps) => {
  const classes = useSettingUpDashboardStyles();
  const { t } = useTranslation(["SETTING_UP_DASHBOARD"]);
  const { steps, isLoading: isLoadingDashboard } = useSettingUpDashboard();
  const { data: scheduleData } = usePhotoScheduled(propertyData?.uid);
  const history = useHistory();

  const SETTING_UP_ROUTES = {
    PHOTOS: `/landlord/properties/${propertyData?.uid}/schedule-photo`,
    CHARACTERISTICS: `/landlord/properties/${propertyData?.uid}/edit-details`,
    CHARACTERISTICS_COMPLETED: `/landlord/properties/${propertyData?.uid}/property-details`,
    BASIC_SERVICES: `/landlord/properties/${propertyData?.uid}/details`,
    BANK_ACCOUNT: `/landlord/properties/${propertyData?.uid}/bank-account`,
    PRICE: `/landlord/properties/${propertyData?.uid}/set-price`,
  };

  const scheduledPhotoVisitLabel = useMemo(() => {
    const { begin_date } = scheduleData || {};
    if (!begin_date) return "";
    const localeConfig = { locale: es };
    const day = format(new Date(begin_date), "dd 'de' MMMM", localeConfig);
    const hour = format(new Date(begin_date), "HH:mm", localeConfig);
    return `${day} a las ${hour}`;
  }, [scheduleData]);

  const schedulePhotosLabel = useMemo(() => {
    if (steps.photos.completed)
      return t("DASHBOARD_PROPERTY_PHOTOS_COMPLETED_LABEL");
    if (steps.photos.current === 1)
      return t("DASHBOARD_PROPERTY_PHOTOS_SCHEDULED_LABEL");
    if (steps.photos.current === 0) return t("DASHBOARD_PROPERTY_PHOTOS_LABEL");
  }, [steps.photos, t]);

  const schedulePhotosActionLabel = useMemo(() => {
    if (steps.photos.completed)
      return t("DASHBOARD_PROPERTY_SCHEDULED_ACTION_LABEL");
    if (!scheduleData) return t("DASHBOARD_PROPERTY_SCHEDULE_ACTION_LABEL");
    if (scheduleData) return t("DASHBOARD_PROPERTY_RESCHEDULE_ACTION_LABEL");
    return t("DASHBOARD_PROPERTY_SCHEDULE_ACTION_LABEL");
  }, [scheduleData, steps.photos.completed, t]);

  const priceActionLabel = useMemo(() => {
    if (steps.price.completed)
      return t("DASHBOARD_PROPERTY_EDIT_PRICE_ACTION_LABEL");
    return t("DASHBOARD_PROPERTY_ADD_PRICE_ACTION_LABEL");
  }, [steps.price.completed, t]);

  const isForSale = propertyData?.forSale && !propertyData?.forRental;

  if (isLoadingDashboard) return null;

  return (
    <section className={classes.settingUpDashboard}>
      <Grid className={classes.settingUpDashboardContainer}>
        <SettingUpCard
          onClick={() => history.push(SETTING_UP_ROUTES.PHOTOS)}
          label={schedulePhotosLabel}
          trailLabel={scheduledPhotoVisitLabel}
          actionLabel={schedulePhotosActionLabel}
          currentStep={steps.photos.current}
          totalSteps={steps.photos.total}
        />
        <SettingUpCard
          onClick={() =>
            history.push(
              steps.characteristics.completed
                ? SETTING_UP_ROUTES.CHARACTERISTICS_COMPLETED
                : SETTING_UP_ROUTES.CHARACTERISTICS
            )
          }
          label={t("DASHBOARD_PROPERTY_CHARACTERISTICS_LABEL")}
          actionLabel={
            steps.characteristics.completed
              ? "Editar"
              : t("DASHBOARD_PROPERTY_CHARACTERISTICS_ACTION_LABEL")
          }
          currentStep={steps.characteristics.current}
          totalSteps={steps.characteristics.total}
        />
        <SettingUpCard
          onClick={() => history.push(SETTING_UP_ROUTES.BASIC_SERVICES)}
          label={t("DASHBOARD_PROPERTY_BASIC_SERVICES_LABEL")}
          actionLabel={t("DASHBOARD_PROPERTY_BASIC_SERVICES_ACTION_LABEL")}
          currentStep={steps.basicServices.current}
          totalSteps={steps.basicServices.total}
        />
        <SettingUpCard
          onClick={() => history.push(SETTING_UP_ROUTES.BANK_ACCOUNT)}
          label={t("DASHBOARD_PROPERTY_BANK_ACCOUNT_LABEL")}
          actionLabel={
            steps.bankAccount.completed
              ? "Editar"
              : t("DASHBOARD_PROPERTY_BANK_ACCOUNT_ACTION_LABEL")
          }
          disabled={isForSale}
          currentStep={steps.bankAccount.current}
          totalSteps={steps.bankAccount.total}
        />
        <SettingUpCard
          onClick={() => history.push(SETTING_UP_ROUTES.PRICE)}
          label={t("DASHBOARD_PROPERTY_ADD_PRICE_LABEL")}
          actionLabel={priceActionLabel}
          currentStep={steps.price.current}
          totalSteps={steps.price.total}
        />
      </Grid>
    </section>
  );
};

export default SettingUpDashboard;
