/* eslint-disable max-lines */
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";

import { SubmitHandler, useForm } from "react-hook-form";
import { useHoumSnackBar } from "libs/houm-snackbar";

import { useGetToken } from "hooks/useGetToken";
import { setInfoModal } from "apps/landlord/pages/CreateProperty/context/proprietaryFormActions";
import { AutoProfileProperty } from "apps/landlord/pages/CreateProperty/context/proprietaryFormTypes";
import propertyService from "services/propertyService";
import { formatAddress } from "utils/createProperty";
import analytics from "libs/analytics";

import { validateAddress, validateData } from "../utils/propertyValidations";
import { useProprietaryForm } from "../../../context/proprietaryFormContext";
import parsePropertyData from "../../ConfirmationStep/utils";
import validationSchema from "../utils/validationSchema";
import {
  FAILED_SUBMIT_MESSAGE,
  FAILED_VALIDATION_MESSAGE,
  SUCCESS_SUBMIT_MESSAGE,
} from "./constants";
import parseData from "../utils/parser";

const usePropertyDetailsStep = () => {
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const history = useHistory();
  const { getToken } = useGetToken();
  const { state, dispatch } = useProprietaryForm();

  const methods = useForm<AutoProfileProperty>({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onSubmit",
  });

  const verifyAddressPreSubmit = async (data: AutoProfileProperty) => {
    const authToken = await getToken();
    /** Validate address */
    const formattedAddress = formatAddress(data.address);
    const addressValidation = validateAddress(formattedAddress, data.district);
    if (addressValidation.error) {
      throw new Error(addressValidation.message);
    }
    /** Validate location availability/type */
    const validation = await validateData(data, authToken);
    if (validation.error) {
      dispatch(setInfoModal(validation.modalInfo));
      await propertyService.createInvalidProperty(
        parseData(data, formattedAddress, state.user.id),
        authToken
      );
      throw new Error(FAILED_VALIDATION_MESSAGE.body);
    }
    return data;
  };

  const submitLandlordProperty = async (data: AutoProfileProperty) => {
    const newLandlordPropertyPayload = {
      formattedAddress: formatAddress(data.address),
      additionalInfo: data.moreInfo,
      propertyNumber: data.propertyNumber,
      deliveryDate: data.date,
      district: data.district?.value,
      propertyType: data.propertyType,
      service: data.service,
      ...(data?.propertyStatus && { propertyStatus: data.propertyStatus }),
    };
    const authToken = await getToken();
    const parsedPropertyData = parsePropertyData(
      { ...newLandlordPropertyPayload },
      state.user.id
    );
    return propertyService.createLandlordProperty(
      parsedPropertyData,
      authToken
    );
  };

  const handleCreateNewProperty = async (data: AutoProfileProperty) =>
    verifyAddressPreSubmit(data).then(submitLandlordProperty);

  const { mutate: submitNewLandlordProperty, isLoading } = useMutation(
    handleCreateNewProperty,
    {
      onSuccess: ({ data }) => {
        enqueueHoumSnackBar(SUCCESS_SUBMIT_MESSAGE);
        analytics.event("create_property", {
          category: "landlord",
          action: "success_create_property",
        });
        history.push(`/landlord/properties/${data.uid}/schedule-photo`);
      },
      onError: (error: { message: string }) => {
        enqueueHoumSnackBar({
          body: error.message || FAILED_SUBMIT_MESSAGE.body,
          type: "error",
        });
        analytics.event("create_property", {
          category: "landlord",
          action: "fail_create_property",
        });
      },
    }
  );

  const handleBackButton = () => history.push("/landlord");

  const handleSubmitNewProperty: SubmitHandler<AutoProfileProperty> = async (
    data
  ) => {
    analytics.event("create_property", {
      category: "landlord",
      action: "submit_create_property",
    });
    submitNewLandlordProperty(data);
  };

  return {
    user: state.user,
    methods,
    isLoading,
    handleBackButton,
    handleSubmitNewProperty,
  };
};

export default usePropertyDetailsStep;
