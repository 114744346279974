import { makeStyles, Theme } from "@material-ui/core";
import { isBrowser } from "react-device-detect";

const usePropertyImageStyles = makeStyles<Theme>(() => ({
  media: {
    width: "100%",
    height: isBrowser ? "100%" : 144,
    borderRadius: isBrowser ? 8 : "none",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    objectFit: "cover",
    objectPosition: "center center",
  },
  cardMediaContainer: {
    position: "relative",
    padding: 0,
    height: isBrowser ? "100%" : "auto",
  },
  chipContainer: {
    position: "absolute",
    color: "red",
    top: 8,
    left: 8,
    display: "flex",
    padding: 0,
  },
  chip: {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 1.6,
    height: 20,
    marginRight: 8,
  },
  chipId: {
    backgroundColor: "#EFF2F3",
  },
}));

export default usePropertyImageStyles;
