import useContract from "apps/tenant/hooks/useContract";
import useContractEndByTenant from "apps/tenant/hooks/useContractEndByTenant";
import useContractEndSteps, {
  STEPS,
} from "apps/tenant/hooks/useContractEndSteps";
import { ILeavingForm } from "apps/tenant/utils/types";
import Spinner from "Components/Shared/partials/Spinner";
import ConfirmationStep from "./components/ConfirmationStep";
import InitialStep from "./components/InitialStep";
import LeavingDateStep from "./components/LeavingDateStep";
import LeavingConfirmationStep from "./components/LeavingConfirmationStep";
import ConfirmationDialog from "./ConfirmationDialog";

const useStepComponent = (handleClose: () => void) => {
  const { data: contractData } = useContract();
  const {
    currentStep,
    handleNextStep: handleNext,
    handleBack,
    showConfirmationModal,
    handleError,
  } = useContractEndSteps(handleClose);
  const {
    leavingData,
    onFormSubit,
    contractEndAction,
    confirmationMessage,
    isLoading: isContractEndLoading,
  } = useContractEndByTenant({
    onSuccessHandler: handleNext,
    onErrorHandler: handleError,
  });

  if (isContractEndLoading)
    return { currentStepComponent: <Spinner />, dialogComponent: null };

  const onSubmitHadler = (data: ILeavingForm) => {
    onFormSubit(data);
    handleNext();
  };

  const getCurrentStep = () => {
    switch (currentStep) {
      case STEPS.Initial:
        return (
          <InitialStep handleNext={handleNext} contractData={contractData} />
        );
      case STEPS.LeavingDate:
        return (
          <LeavingDateStep
            onSubmitHadler={onSubmitHadler}
            leavingData={leavingData}
            country={contractData.country}
          />
        );
      case STEPS.LeavingConfirmation:
        return (
          <LeavingConfirmationStep
            handleNext={contractEndAction}
            handleBack={handleBack}
            confirmationMessage={confirmationMessage}
          />
        );
      case STEPS.Confirmation:
        return (
          <ConfirmationStep
            handleBack={handleBack}
            leavingDate={leavingData.date}
            country={contractData.country}
          />
        );
      default:
        return (
          <InitialStep handleNext={handleNext} contractData={contractData} />
        );
    }
  };

  const Dialog = (
    <ConfirmationDialog
      handleNext={contractEndAction}
      handleBack={handleBack}
      confirmationMessage={confirmationMessage}
      isOpen={showConfirmationModal}
    />
  );
  return { currentStepComponent: getCurrentStep(), dialogComponent: Dialog };
};

export default useStepComponent;
