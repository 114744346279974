import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import esLocale from "date-fns/locale/es";

import {
  DatePicker,
  DatePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

import { IVisit } from "apps/tenant/models/Visit";

import CalendarDay from "./components/CalendarDay";
import VisitsCalendarTheme from "./VisitsCalendarTheme";

interface Props extends Omit<DatePickerProps, "onChange"> {
  value: Date;
  setSelectedDate: (e: Date) => void;
  visits: IVisit[];
}

const VisitsCalendar = ({
  value,
  setSelectedDate,
  visits,
  ...datePickerProps
}: Props) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
    <ThemeProvider theme={VisitsCalendarTheme}>
      <DatePicker
        label=""
        variant="static"
        disablePast={true}
        readOnly={true}
        disableToolbar
        {...datePickerProps}
        value={value}
        onChange={(e) => {
          const date = e as unknown as Date;
          setSelectedDate(date);
        }}
        renderDay={(day, _, isInCurrentMonth, dayComponent) => {
          const currentDay = new Date(day.toString());
          return (
            <CalendarDay
              date={currentDay}
              hasVisit={visits.some(
                (visit) =>
                  format(visit.date, "dd/MM/yy") ===
                  format(currentDay, "dd/MM/yy")
              )}
            >
              {dayComponent}
            </CalendarDay>
          );
        }}
      />
    </ThemeProvider>
  </MuiPickersUtilsProvider>
);

export default VisitsCalendar;
