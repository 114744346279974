import { FormProvider, SubmitHandler } from "react-hook-form";

import FieldInputFile from "@houm-com/ui/FieldInputFile";

import { identificationFilesSchema } from "apps/lead/pages/EvaluatorForm/components/IdentificationFiles/utils/validationSchema";
import { classes } from "apps/lead/pages/EvaluatorForm/components/IdentificationFiles/IdentificationFilesStyles";
import { allowedMimeTypes } from "apps/lead/pages/EvaluatorForm/components/IdentificationFiles/utils/constants";
import { IIdentificationForm } from "apps/lead/pages/EvaluatorForm/components/IdentificationFiles/utils/types";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper/FormWrapper";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import {
  setActiveStep,
  setUserInformation,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";
import useReactHookForm from "hooks/useReactHookForm";

const IdentificationFiles = () => {
  const {
    state: { user: userInformation, activeStep },
    dispatch,
  } = useEvaluation();

  const { methods, errors } = useReactHookForm<IIdentificationForm>({
    formSchema: identificationFilesSchema,
    formOptions: {
      defaultValues: {
        frontIdentification: userInformation.identityFiles?.frontIdentification,
        backIdentification: userInformation.identityFiles?.backIdentification,
        frontIdentificationSelfie:
          userInformation.identityFiles?.frontIdentificationSelfie,
      },
    },
  });

  const onSubmit: SubmitHandler<IIdentificationForm> = (data) => {
    dispatch(
      setUserInformation({
        ...userInformation,
        identityFiles: {
          frontIdentification: data.frontIdentification,
          backIdentification: data.backIdentification,
          frontIdentificationSelfie: data.frontIdentificationSelfie,
        },
      })
    );
    dispatch(setActiveStep(activeStep + 1));
  };

  return (
    <FormProvider {...methods}>
      <Header
        title="Validación de identificación"
        subtitle="Estos documentos pueden estar en formato PDF o como imagen (JPEG, JPG o PNG)"
        stepTitle="Identificación"
      />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormWrapper classNameFormWrapper={classes.identificationFilesWrapper}>
          <FieldInputFile
            id="frontIdentification"
            label="Foto de tu identificación (Frente)"
            allowedMimeTypes={allowedMimeTypes}
            classNameInputButton={classes.inputField}
            splitLength={40}
            required
            form="novalidateform"
            file={userInformation.identityFiles?.frontIdentification}
            errorMessage={errors.frontIdentification?.message}
            {...methods.register("frontIdentification")}
          />
          <FieldInputFile
            id="backIdentification"
            label="Foto de tu identificación (Detrás)"
            allowedMimeTypes={allowedMimeTypes}
            classNameInputButton={classes.inputField}
            splitLength={40}
            required
            form="novalidateform"
            file={userInformation.identityFiles?.backIdentification}
            errorMessage={errors.backIdentification?.message}
            {...methods.register("backIdentification")}
          />
          <FieldInputFile
            id="frontIdentificationSelfie"
            label="Foto de tu cara y de tu identificación donde se vea tu foto"
            classNameInputButton={classes.inputField}
            allowedMimeTypes={allowedMimeTypes}
            splitLength={40}
            required
            form="novalidateform"
            file={userInformation.identityFiles?.frontIdentificationSelfie}
            errorMessage={errors.frontIdentificationSelfie?.message}
            {...methods.register("frontIdentificationSelfie")}
          />
        </FormWrapper>
        <CallToAction label="Continuar" />
      </form>
    </FormProvider>
  );
};

export default IdentificationFiles;
