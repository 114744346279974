import { useMemo } from "react";
import { useQuery } from "react-query";

import { useGetToken } from "hooks/useGetToken";
import { countryCode } from "utils";
import propertyService from "apps/landlord/services/propertyService/propertyService";
import bankAccountService from "services/bankAccountService";

import { MissingData, UsePropertySettingUp } from "./types";
import INITIAL_MISSING_DATA from "./constants";
import parseMissingSettingUpData from "./parsers";

const usePropertySettingUp: UsePropertySettingUp = ({
  propertyUuid,
  userCountry,
  propertyId,
  userId,
}) => {
  const { getToken } = useGetToken();

  const {
    data: missingBankAccount = false,
    isLoading: isLoadingBankAccount,
    isError: isErrorBankAccount,
  } = useQuery(
    `PROPERTY_BANK_ACCOUNT_${propertyId}`,
    async () => {
      const authToken = await getToken();
      const { status } = await bankAccountService.getByUserAndProperty(
        countryCode(userCountry),
        userId,
        propertyId,
        authToken
      );
      return status === 404;
    },
    {
      enabled: !!propertyId && !!userId,
    }
  );

  const {
    data: missingSettingUpData = INITIAL_MISSING_DATA,
    isLoading: isLoadingMissingSettingUpData,
    isError: isErrorMissingSettingUpData,
  } = useQuery(
    `SETTING_UP_STATUS_${propertyId}`,
    async () => {
      const authToken = await getToken();
      const { data } = await propertyService.getSettingUpMissingData(
        propertyUuid,
        authToken
      );
      const parsedSettingUpData = parseMissingSettingUpData(data);
      return parsedSettingUpData;
    },
    {
      enabled: !!propertyId,
    }
  );

  const missingData: MissingData = useMemo(
    () => ({
      ...missingSettingUpData,
      missingBankAccount,
    }),
    [missingSettingUpData, missingBankAccount]
  );

  const filteredMissingData: (keyof MissingData)[] = useMemo(
    () =>
      Object.entries(missingData).reduce(
        (acc, [key, value]) => (value ? [...acc, key] : acc),
        []
      ),
    [missingData]
  );

  const hasMissingData = !!filteredMissingData.length;

  return {
    filteredMissingData,
    hasMissingData,
    missingData,
    isLoading: isLoadingBankAccount || isLoadingMissingSettingUpData,
    isError: isErrorBankAccount || isErrorMissingSettingUpData,
  };
};

export default usePropertySettingUp;
