import { Trans, useTranslation } from "react-i18next";

import { Box, Typography } from "@material-ui/core";

import useUser from "apps/tenant/hooks/useUser";
import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji";
import Spinner from "Components/Shared/partials/Spinner";

import BankAccount from "./components/BankAccount";
import ErrorView from "./components/ErrorView";
import Favorites from "./components/Favorites";
import MoreOptions from "./components/MoreOptions";
import PaymentData from "./components/PaymentData";
import RequestsCarrousel from "./components/RequestsCarrousel";
import RentRequirements from "./components/RentRequirements";
import Visits from "./components/Visits";
import useDashboard from "./hooks/useDashboard";
import useDashboardStyles from "./DashboardStyles";

const Dashboard = () => {
  const { isLoading: userLoading, error: userError, user } = useUser();
  const {
    isLoading,
    showBankAccounts,
    showFavorites,
    showMoreOptions,
    showPaymentData,
    showRentRequirements,
    showRequestsCarrousel,
    showVisits,
    showWelcome,
    visits,
    contract,
  } = useDashboard({ email: user?.email });
  const classes = useDashboardStyles({ showBanner: false });
  const { t } = useTranslation("tenant.dashboard");

  if (userLoading || isLoading) {
    return <Spinner className={classes.spinner} />;
  }

  if (userError) return <ErrorView />;

  return (
    <Box className={classes.dashboardContent}>
      <Box className={classes.colorBox} />
      <Box className={classes.leftContainer}>
        <Typography className={classes.title}>
          Hola {user?.name} <Emoji emojiIcon="👋" ariaLabel="waving hand" />
        </Typography>
        {showWelcome && (
          <Typography className={classes.welcome}>
            <Trans i18nKey={t("welcome")} components={{ 1: <br /> }} />
          </Typography>
        )}
        {showPaymentData && <PaymentData />}
      </Box>
      <Box className={classes.rightContainer}>
        {showRentRequirements && <RentRequirements />}
        {showRequestsCarrousel && <RequestsCarrousel />}
        {showBankAccounts && <BankAccount propertyId={contract?.propertyId} />}
        <Box className={classes.visitsFavoritesWrapper}>
          {showVisits && <Visits visits={visits} />}
          {showFavorites && <Favorites />}
        </Box>
        {showMoreOptions && <MoreOptions />}
      </Box>
    </Box>
  );
};

export default Dashboard;
