import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useEmptyEvaluationStyles = makeStyles((theme: Theme) => ({
  noApplicants: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.44,
    },
  },
  button: {
    width: "fit-content",
    marginTop: theme.spacing(5),
  },
  personSearchIcon: {
    height: 157,
    width: 250,
    [theme.breakpoints.up(defaultBreakpoint)]: {
      height: 232,
      width: 369,
    },
  },
}));

export default useEmptyEvaluationStyles;
