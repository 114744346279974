import { useFeatureManager } from "@houm-com/feature-manager";

import { useMarket } from "apps/tenant/context/MarketContext";

import FormCL from "./components/cl";
import FormMX from "./components/mx";
import { FormProps } from "./utils/types";

const Form = ({
  bankAccount,
  editing,
  isSubmitting,
  onSubmit,
  bankOptions,
  accountTypeOptions,
}: FormProps) => {
  const { market } = useMarket();
  const { data: bankAccountFormFlag, status: bankAccountFormFlagStatus } =
    useFeatureManager("tenant.bank_account_form");

  if (bankAccountFormFlagStatus === "success" && !bankAccountFormFlag?.enabled)
    return null;

  const getMarketForm = () => {
    switch (market) {
      case "cl":
        return FormCL;
      case "mx":
        return FormMX;
      default:
        return null;
    }
  };

  const MarketBasedForm = getMarketForm();

  if (!MarketBasedForm) return null;

  return (
    <MarketBasedForm
      bankAccount={bankAccount}
      editing={editing}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      bankOptions={bankOptions}
      accountTypeOptions={accountTypeOptions}
    />
  );
};

export default Form;
