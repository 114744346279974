import {
  IoBusinessOutline as BuildingsIcon,
  IoSearchOutline as SearchIcon,
  IoReceiptOutline as VoucherIcon,
} from "@houm-com/ui-icons";

import { IDashboardOption } from "./types";

interface Props {
  propertyId?: number;
  showReports?: boolean;
}

export const dashboardOptions = ({
  propertyId,
  showReports = false,
}: Props): IDashboardOption[] => {
  const options = [
    {
      label: "Servicios básicos y gastos comunes",
      icon: <BuildingsIcon color="#46788C" />,
      link: "/tenant/services",
      show: true,
    },
    {
      label: "Actas de inspección",
      icon: <SearchIcon color="#46788C" />,
      link: `/tenant/property/${propertyId}/inspection-report`,
      show: showReports,
    },
    {
      label: "Comprobantes de pagos",
      icon: <VoucherIcon color="#46788C" />,
      link: "/tenant/vouchers-list",
      show: true,
    },
  ];
  return options;
};
