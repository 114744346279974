import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "md";

const useResumeOfferStyles = makeStyles((theme: Theme) => ({
  titleGeneral: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: theme.palette.grey[900],
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: "2rem",
      paddingBottom: theme.spacing(3),
    },
  },
  warning: {
    margin: theme.spacing(3, 1),
    display: "flex",
    alignItems: "flex-start",
    "& p": {
      textAlign: "justify",
    },
  },
  terms: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
    display: "inline",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  termsDetails: {
    textDecoration: "underline",
    display: "inline",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
    color: theme.palette.primary.main,
    fontWeight: 700,
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  warningReject: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5,
    textAlign: "justify",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  warningRejectRemember: {
    fontWeight: 700,
    display: "inline",
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4),
  },
  button: {
    borderRadius: 100,
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      width: "fit-content",
    },
  },
  checkbox: {
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 1),
  },
  warningIcon: {
    width: "2rem",
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginTop: theme.spacing(0.75),
      width: "2rem",
      marginRight: 0,
    },
  },
}));

export default useResumeOfferStyles;
