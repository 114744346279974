import { ReactElement, ReactNode } from "react";
import clx from "classnames";

import { Box, Grid, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import useCardMenuItemStyles from "./CardMenuItemStyles";
import ButtonLink from "../ButtonLink";

interface Props {
  title: string;
  subtitle?: string;
  children: ReactNode;
  isButtonAvailable?: boolean;
  buttonVariant?: "primary" | "secondary" | "tertiary";
  buttonLabel?: string;
  onViewInfoClick?: () => void;
  cardClassName?: string;
  infoContainerClassName?: string;
  icon?: ReactElement;
}

const CardMenuItem = ({
  title,
  subtitle,
  children,
  isButtonAvailable = false,
  buttonVariant = "secondary",
  buttonLabel,
  cardClassName,
  infoContainerClassName,
  onViewInfoClick,
  icon = null,
}: Props) => {
  const classes = useCardMenuItemStyles();

  return (
    <Box className={clx(cardClassName, classes.cardRoot)}>
      <Box>
        <Box className={classes.titleContainer}>
          <Typography component="h2" className={classes.infoTitle}>
            {title}
          </Typography>
          {isButtonAvailable && buttonVariant === "tertiary" && (
            <ButtonLink
              buttonLabel={buttonLabel}
              onViewInfoClick={onViewInfoClick}
            />
          )}
        </Box>
        {subtitle && (
          <Typography className={classes.subtitleText}>{subtitle}</Typography>
        )}
        <Grid
          container
          className={clx(infoContainerClassName, classes.infoColumn)}
        >
          {children}
        </Grid>
      </Box>
      {isButtonAvailable && buttonVariant !== "tertiary" && (
        <Box className={classes.buttonContainer}>
          {buttonVariant === "secondary" && (
            <ButtonLink
              buttonLabel={buttonLabel}
              onViewInfoClick={onViewInfoClick}
            />
          )}
          {buttonVariant === "primary" && (
            <DesignSystemButton
              variant="primary"
              label={buttonLabel}
              size="small"
              onClick={() => onViewInfoClick()}
              Icon={icon}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default CardMenuItem;
