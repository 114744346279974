import { makeStyles, Theme } from "@material-ui/core";

const useDrawerSpinnerStyles = makeStyles((theme: Theme) => ({
  fullSizeSpinner: {
    position: "absolute",
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    backgroundColor: "rgba(255,255,255,0.8)",
    zIndex: 9999,
  },
}));

export default useDrawerSpinnerStyles;
