import { Box, Typography } from "@material-ui/core";

import { useApplicant } from "apps/landlord/pages/Applicant/context/ApplicantContext";
import { Application } from "models/Evaluation";
import applicationInfo from "../../utils";
import useDesktopApplicationCardStyles from "./DesktopApplicationCardStyles";

interface DesktopApplicationCardProps {
  application: Application;
}

const DesktopApplicationCard = ({
  application,
}: DesktopApplicationCardProps) => {
  const { countryCode } = useApplicant();
  const classes = useDesktopApplicationCardStyles();

  const applicantionFields = applicationInfo(application, countryCode);

  const applicantionFieldsList = Object.entries(applicantionFields);
  return (
    <Box className={classes.infoContainer}>
      {applicantionFieldsList.map(([key, value]) => (
        <Typography key={key} className={classes.text}>
          {`${key}: ${value}`}
        </Typography>
      ))}
    </Box>
  );
};

export default DesktopApplicationCard;
