const urlsByCountry = {
  mx: {
    tyc: "https://ayuda.houm.com/hc/es-419/articles/360060530732-T%C3%A9rminos-y-Condiciones-Houm",
    privacyPolitics:
      "https://ayuda.houm.com/hc/es-419/articles/360060530972-Aviso-de-Privacidad",
  },
  co: {
    tyc: "https://ayuda.houm.com/hc/es-419/articles/360048644532",
    privacyPolitics:
      "https://ayuda.houm.com/hc/es-419/articles/360049464512-Pol%C3%ADtica-de-Privacidad",
  },
  cl: {
    tyc: "https://ayuda.houm.com/hc/es-419/articles/360047538291",
    privacyPolitics:
      "https://ayuda.houm.com/hc/es-419/articles/360047723631-Pol%C3%ADtica-de-Privacidad",
  },
};

export default urlsByCountry;
