import { Box, Typography } from "@material-ui/core";

import useBreakdownStyles from "./BreakdownStyles";

interface Props {
  label: string;
  value: string;
}

const Breakdown = ({ label, value }: Props) => {
  const classes = useBreakdownStyles();
  return (
    <Box className={classes.detail}>
      <Typography className={classes.detailName}>{label}</Typography>
      <Typography className={classes.detailValue}>{value}</Typography>
    </Box>
  );
};

export default Breakdown;
