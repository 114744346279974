import { ClassNameMap } from "@material-ui/styles";

import { IoPersonOutline } from "@houm-com/ui-icons";

export const DESKTOP_HEADER_SPACING = 112;
export const DESKTOP_HEADER_HEIGHT = 68;
export const MOBILE_HEADER_SPACING = 76;
export const MOBILE_HEADER_HEIGHT = 48;
export const MOBILE_BOTTOMNAVBAR_HEIGHT = 63;

export const menuItems = (classes: ClassNameMap<string>) => [
  {
    title: "Mi Houm",
    icon: <IoPersonOutline />,
    path: "/tenant",
    isActiveRoutes: ["/tenant/", "/tenant/evaluations"],
    handleClick: () => {},
  },
];
