import {
  PaymentMethodsCL,
  PaymentMethodsCO,
  PaymentMethodsMX,
  MovementStatus,
  CurrencyCode,
} from "apps/payments/models/Payments";
import { Locale } from "models/Countries";

export type Steps =
  | "landing"
  | "paymentDetail"
  | "paymentMethod"
  | "mailForm"
  | "paymentSelected";

export interface PaymentDetail {
  id: string;
  description: string;
}

export interface PaymentDataProps {
  charges: string;
  chargesDetail: PaymentDetail[];
  currency: CurrencyCode;
  date: Date;
  discounts: number;
  discountsDetail: PaymentDetail[];
  fines: string;
  readjustment?: number;
  readjustmentDetail?: PaymentDetail[];
  originalPrice: string;
  price: string;
  quotationId?: number;
  movementId?: string;
  concept: "LEASE" | "RESERVATION" | "CONTRACT" | "OTHER";
  status?: MovementStatus;
  propertyId?: number;
}

export interface TenantData {
  userId: number;
  document: string;
  documentType: number;
  email: string;
}

export type MethodSelected =
  | PaymentMethodsMX
  | PaymentMethodsCO
  | PaymentMethodsCL;

export interface State {
  country: Locale;
  paymentData: Partial<PaymentDataProps>;
  step: Steps;
  tenantData: TenantData;
  methodSelected: MethodSelected;
}

export const SET_COUNTRY = "SET_COUNTRY";
export const SET_PAYMENT_DATA = "SET_PAYMENT_DATA";
export const SET_STEP = "SET_STEP";
export const SET_TENANT_DATA = "SET_TENANT_DATA";
export const SET_METHOD_SELECTED = "SET_METHOD_SELECTED";

interface setCountry {
  type: typeof SET_COUNTRY;
  country: Locale;
}

interface setPaymentData {
  type: typeof SET_PAYMENT_DATA;
  paymentData: Partial<PaymentDataProps>;
}

interface setTenantData {
  type: typeof SET_TENANT_DATA;
  tenantData: TenantData;
}

interface setStep {
  type: typeof SET_STEP;
  step: Steps;
}

interface setMethodSelected {
  type: typeof SET_METHOD_SELECTED;
  methodSelected: MethodSelected;
}

export type Action =
  | setCountry
  | setPaymentData
  | setStep
  | setTenantData
  | setMethodSelected;

export type Dispatch = (action: Action) => void;
