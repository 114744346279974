export enum PropertyDetailsOptions {
  INFORMATION = "info",
  COMMON_EXPENSES = "common_expenses",
  BASIC_SERVICES = "basic_services",
}

export const detailsPageOptionsCL = [
  { label: "Información", value: PropertyDetailsOptions.INFORMATION },
  {
    label: "Gastos comunes",
    value: PropertyDetailsOptions.COMMON_EXPENSES,
  },
  {
    label: "Servicios básicos",
    value: PropertyDetailsOptions.BASIC_SERVICES,
  },
];

export const detailsPageOptions = [
  { label: "Información", value: PropertyDetailsOptions.INFORMATION },
  {
    label: "Servicios básicos",
    value: PropertyDetailsOptions.BASIC_SERVICES,
  },
];
