import { useHistory, useParams } from "react-router-dom";

import SimpleLayout from "apps/landlord/Layout/SimpleLayout";

import PaymentDetail from "./PaymentDetail";

const PropertyIndex = () => {
  const history = useHistory();
  const { id: propertyUid } = useParams<{ id: string }>();

  return (
    <SimpleLayout
      onHandleBack={() => history.push(`/landlord/properties/${propertyUid}`)}
    >
      <PaymentDetail />
    </SimpleLayout>
  );
};

export default PropertyIndex;
