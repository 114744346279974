import { useHistory } from "react-router-dom";

import { Box, Typography } from "@material-ui/core";
import { IoChevronForward as ChevronRightIcon } from "@houm-com/ui-icons";

import useMenuCardStyles from "./MenuCardStyles";
import { ICardItem } from "../../../../../../utils/types";

interface Props {
  title: string;
  items: ICardItem[];
}

const MenuCard = ({ title, items }: Props) => {
  const classes = useMenuCardStyles();
  const history = useHistory();

  const handleClick = (url: string) => {
    history.push(url);
  };

  return (
    <Box className={classes.MenuCardRoot}>
      <Typography component="h2" className={classes.title}>
        {title}
      </Typography>
      {items.map((item) => (
        <Box
          className={classes.cardItemRoot}
          onClick={() => handleClick(item.url)}
          key={item.title}
        >
          <Box className={classes.cardItemIcon}>{item.icon}</Box>
          <Box className={classes.cardItemText}>
            <Typography className={classes.cardItemTitle}>
              {item.title}
            </Typography>
            <Typography className={classes.cardItemSubtitle}>
              {item.subtitle}
            </Typography>
          </Box>
          <ChevronRightIcon />
        </Box>
      ))}
    </Box>
  );
};

export default MenuCard;
