import { useState } from "react";
import clsx from "clsx";

import { Box, Typography } from "@material-ui/core";
import { IoEyeOutline as EyeIcon } from "@houm-com/ui-icons";

import { IVisit } from "apps/tenant/models/Visit";

import StatusBar from "../StatusBar";
import useVisitCardStyles from "./VisitCardStyles";
import { getVisitDate, getVisitTime } from "./utils";
import VisitDetails from "../VisitDetails";

interface Props {
  contrast?: boolean;
  visit: IVisit;
}

const VisitCard = ({ contrast = false, visit }: Props) => {
  const classes = useVisitCardStyles({ contrast });
  const [openDetails, setOpenDetails] = useState(false);

  return (
    <Box className={classes.visitCardRoot}>
      <StatusBar status={visit.status} />
      <Box className={classes.infoBox} onClick={() => setOpenDetails(true)}>
        <Box>
          <Typography className={classes.date}>
            {getVisitDate(visit.date)}
          </Typography>
          <Typography className={classes.info}>{visit.fullLocation}</Typography>
          <Typography
            className={clsx(classes.info, {
              [classes.cancelled]: visit.status !== "ok",
            })}
          >
            {getVisitTime(visit.date, visit.status, visit.cancelReason)}
          </Typography>
        </Box>
        <EyeIcon size={18} />
      </Box>
      <VisitDetails open={openDetails} setOpen={setOpenDetails} visit={visit} />
    </Box>
  );
};

export default VisitCard;
