import { Grid } from "@material-ui/core";
import { FormProvider } from "react-hook-form";

import FormReferContent from "./FormReferContent";
import useReferPartners from "../hooks/useReferPartners";

const FormReferPartner = () => {
  const { isLoading, formMethods, handleFormSubmit } = useReferPartners();

  return (
    <Grid item lg={12}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleFormSubmit}>
          <FormReferContent defaultData={{}} loading={isLoading} />
        </form>
      </FormProvider>
    </Grid>
  );
};

export default FormReferPartner;
