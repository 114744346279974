import { SnackbarProvider } from "notistack";
import { Route, useRouteMatch } from "react-router-dom";

import { EvaluationProvider } from "../context/evaluationContext/EvaluationContext";
import { CodebtorProvider } from "../context/codebtorContext/CodebtorContext";
import UploadFilesForm from "../pages/UploadFilesForm";
import EvaluatorForm from "../pages/EvaluatorForm";
import CodebtorForm from "../pages/CodebtorForm";
import MainTenant from "../pages/MainTenant";

const TenantPrivateRoutes = () => {
  const match = useRouteMatch();

  return (
    <SnackbarProvider>
      <Route exact path={[match.path, `${match.path}/evaluations`]}>
        <MainTenant />
      </Route>
      <Route exact path={`${match.path}/evaluator-form`}>
        <EvaluationProvider>
          <EvaluatorForm />
        </EvaluationProvider>
      </Route>
      <Route exact path={`${match.path}/codebtor-form/:evaluationId`}>
        <CodebtorProvider>
          <CodebtorForm />
        </CodebtorProvider>
      </Route>
      <Route exact path={`${match.path}/document-form`}>
        <CodebtorProvider>
          <UploadFilesForm />
        </CodebtorProvider>
      </Route>
    </SnackbarProvider>
  );
};

export default TenantPrivateRoutes;
