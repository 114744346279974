import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme, { selected: boolean }>((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100vw",
    boxShadow: "3px 4px 20px rgba(38, 50, 56, 0.15)",
    position: "fixed",
    zIndex: 1000,
    top: 0,
    left: 0,
  },
  rootContent: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: theme.zIndex.appBar,
    backgroundColor: "white",
    padding: theme.spacing(1, 2),
    height: 52,
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    alignContent: "flex-start",
    "& img": {
      height: 32,
    },
  },
  menuContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  menuIcon: {
    cursor: "pointer",
  },
  navbarDrawerPaper: {
    alignItems: "flex-start",
    padding: theme.spacing(2),
    width: "80%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      padding: theme.spacing(3),
    },
  },
  drawer: {
    width: "100%",
    height: "100%",
  },
  transparent: {
    color: "transparent",
  },
  pictureContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  picture: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    marginBottom: theme.spacing(1.5),
  },
  username: {
    marginBottom: theme.spacing(4),
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: "bold",
  },
  account: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: (props) => (props.selected ? "bold" : "normal"),
    lineHeight: 1.7,
    color: (props) =>
      props.selected ? theme.palette.primary.main : "rgba(0, 0, 0, 0.87)",
  },
  logoutContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    padding: theme.spacing(5),
    display: "flex",
    justifyContent: "flex-end",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.7,
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  logoSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
  },
  navbarMenuItem: {
    padding: theme.spacing(1),
    fontSize: "0.875rem",
    height: "100%",
    width: "100%",
    minWidth: "unset",
    justifyContent: "flex-start",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1.5em",
    color: "#263238",
    "& hover": {
      color: "#263238 !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
  },
  navbarMenuItemActive: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    "& hover": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  contentUser: {
    margin: theme.spacing(0, 0, 1),
  },
  user: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "1rem",
    lineHeight: "1.5em",
    color: "#263238",
    textTransform: "capitalize",
    marginLeft: theme.spacing(1),
  },
  userItem: {
    display: "flex",
    marginTop: theme.spacing(2),
    alignItems: "baseline",
  },
  logoutButton: {
    padding: theme.spacing(1, 0, 0, 0.5),
  },
}));
