import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import ProfileCard from "../ProfileCard";
import PlainField from "./components/PlainField";

interface Props {
  name: string;
  lastName: string;
  secondLastName: string;
}

const PersonalInfo = ({ name, lastName, secondLastName }: Props) => {
  const { t } = useTranslation("account.profile");
  return (
    <ProfileCard text={!isMobile && t("personal_info_text")}>
      <PlainField label="Nombre(s)" value={name} />
      <PlainField label="Apellido paterno" value={lastName} />
      <PlainField label="Apellido materno" value={secondLastName} />
    </ProfileCard>
  );
};

export default PersonalInfo;
