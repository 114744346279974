import { AxiosResponse } from "axios";

import { createHttp } from "services/helper";
import { Locale } from "models/Countries";
import { PAYMENTS_URL } from "env";

import { BankAccountResponse, BanksResponse, IBankAccountData } from "./types";

export default {
  getByUserAndProperty: async (
    country: Locale,
    userId: number,
    propertyId: number,
    token: string
  ): Promise<AxiosResponse<BankAccountResponse>> =>
    createHttp(token, PAYMENTS_URL).get(
      `${country}/v3/people/${userId}/bank_accounts/${propertyId}`
    ),

  getBanks: (
    token: string,
    country: Locale
  ): Promise<AxiosResponse<BanksResponse>> =>
    createHttp(token, PAYMENTS_URL).get(`/${country}/v3/banks`),

  create: async (
    country: Locale,
    userId: number,
    data: Partial<IBankAccountData>,
    token: string
  ) =>
    createHttp(token, PAYMENTS_URL).post(
      `${country}/v3/people/${userId}/bank_accounts`,
      data
    ),

  update: async (
    country: Locale,
    userId: number,
    propertyId: number,
    data: Partial<IBankAccountData>,
    token: string
  ) =>
    createHttp(token, PAYMENTS_URL).put(
      `${country}/v3/people/${userId}/bank_accounts/${propertyId}`,
      data
    ),
};
