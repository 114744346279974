import { useEffect, useState } from "react";

import { TextField, TextFieldProps } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import CountryItem from "./CountryItem";
import { getCountry } from "country-list-spanish";
import { getCountryCallingCode, getCountries } from "libphonenumber-js";
import { Country } from "models/PhoneInput";
import { strNormalize } from "utils/verifyPhoneNumber";

import useCountryListStyles from "./CountryListStyle";

interface Props {
  onSelect?: (country: Country) => void;
  selected?: Country;
  searchInputProps?: TextFieldProps;
}
const CountryList = ({ onSelect, selected, searchInputProps }: Props) => {
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [filtered, setFiltered] = useState<Country[]>([]);
  const [longestNameIndex, setLongestNameIndex] = useState<number>(0);
  const classes = useCountryListStyles();
  useEffect(() => {
    const countries = getCountries();
    const mapped: Country[] = countries
      .map((country) => ({
        countryName: getCountry(country),
        countryCode: country,
        callingCode: getCountryCallingCode(country),
      }))
      .filter((country) =>
        ["MX", "CO", "CL", "US"].includes(country.countryCode)
      )
      .sort((a, b) => a.countryCode.localeCompare(b.countryCode));

    const lenghts: number[] = mapped.map(
      (country) => country.countryName.length
    );
    const longestIndex = lenghts.indexOf(Math.max(...lenghts));
    setLongestNameIndex(longestIndex);
    setCountryList(mapped);
    setFiltered(mapped);
  }, []);
  const handleChangeInput = (e) => {
    const value = strNormalize(e.target.value.toLowerCase());
    const filtered = countryList.filter((country) =>
      strNormalize(country.countryName.toLowerCase()).includes(value)
    );
    setFiltered(filtered);
  };

  return (
    <div>
      <div className={classes.searchContainer}>
        <TextField
          {...searchInputProps}
          onChange={handleChangeInput}
          fullWidth
          placeholder="Buscar país"
          autoFocus
          InputProps={{
            startAdornment: <SearchOutlined />,
            ...searchInputProps?.InputProps,
          }}
        />
      </div>
      <div className={classes.maxLengthName}>
        <CountryItem
          onClick={() => null}
          country={countryList[longestNameIndex]}
        />
      </div>
      {filtered.map((item) => (
        <CountryItem
          key={item.countryName}
          country={item}
          onClick={onSelect}
          selected={selected?.countryCode === item?.countryCode}
        />
      ))}
    </div>
  );
};

export default CountryList;
