import { makeStyles, Theme } from "@material-ui/core";

import { MOBILE_BOTTOM_NAVBAR_HEIGHT } from "apps/landlord/constants/layout";

const MOBILE_BOTTOM_NAVBAR_HEIGHT_PX = MOBILE_BOTTOM_NAVBAR_HEIGHT * 8;

interface Props {
  navbarHeight: number;
}

export default makeStyles<Theme, Props>((theme: Theme) => ({
  propertyRoot: {
    position: "relative",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(0),
      display: "flex",
      flexDirection: "row",
      position: "relative",
    },
  },
  leftContainer: {
    top: ({ navbarHeight }) => navbarHeight,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      position: "sticky",
      height: ({ navbarHeight }) => `calc(100vh - ${navbarHeight}px)`,
      width: "26%",
    },
  },
  loadingContainer: {
    height: ({ navbarHeight }) =>
      `calc(100vh - ${navbarHeight}px - ${MOBILE_BOTTOM_NAVBAR_HEIGHT_PX}px)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      height: ({ navbarHeight }) => `calc(100vh - ${navbarHeight}px)`,
    },
  },
}));
