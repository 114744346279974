import { Box, Dialog, IconButton, Typography } from "@material-ui/core";

import {
  IoInformationCircleOutline as InformationIcon,
  IoChevronForward as ChevronRight,
} from "@houm-com/ui-icons";

import { GridCloseIcon } from "@material-ui/x-grid";

import useUnfinishedSettingUpModalStyles from "./UnfinishedSettingUpModalStyles";
import { UnfinishedSettingUpModalAction } from "../hooks/types";

interface UnfinishedSettingUpModalProps {
  open: boolean;
  onClose: () => void;
  missingDataActions: UnfinishedSettingUpModalAction[];
}

const UnfinishedSettingUpModal = ({
  open,
  onClose,
  missingDataActions,
}: UnfinishedSettingUpModalProps) => {
  const classes = useUnfinishedSettingUpModalStyles();

  return (
    <Dialog
      PaperProps={{
        className: classes.materialUiBorder,
      }}
      open={open}
      onClose={onClose}
      className={classes.root}
    >
      <Box className={classes.modal}>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <GridCloseIcon />
        </IconButton>
        <Box className={classes.unfinishedSettingUpModalHeader}>
          <InformationIcon
            className={classes.unfinishedSettingUpModalHeaderIcon}
          />
          <Typography className={classes.unfinishedSettingUpModalHeaderTitle}>
            Información pendiente
          </Typography>
        </Box>
        <Typography className={classes.unfinishedSettingUpModalText}>
          Es necesario que completes estos datos para que podamos hacer una
          gestión correcta de tu propiedad
        </Typography>
        <Box className={classes.unfinishedSettingUpRoutes}>
          {missingDataActions.map(({ title, description, onClick }) => (
            <Box key={title} className={classes.unfinishedSettingUpRoute}>
              <Box
                onClick={onClick}
                className={classes.unfinishedSettingUpRouteContent}
              >
                <Typography className={classes.unfinishedSettingUpRouteTitle}>
                  {title}
                </Typography>
                <Typography
                  className={classes.unfinishedSettingUpRouteDescription}
                >
                  {description}
                </Typography>
              </Box>
              <ChevronRight className={classes.unfinishedSettingUpRouteIcon} />
            </Box>
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};

export default UnfinishedSettingUpModal;
