import LightButton from "@houm-com/ui/LightButton";

import { IoArrowForward } from "@houm-com/ui-icons";
import Spinner from "@houm-com/ui/Spinner";
import classes from "./RequestCardStyles";

interface Props {
  CardIcon?: any;
  title: string;
  description: string;
  onClick: () => void;
  ctaText: string;
  isLoading?: boolean;
}

const RequestCard = ({
  CardIcon,
  title,
  description,
  onClick,
  ctaText,
  isLoading = false,
}: Props) => (
  <div className={classes.cardRoot}>
    {isLoading ? (
      <Spinner
        variant="primary"
        size="xl"
        classNameContainer={classes.spinner}
      />
    ) : (
      <>
        <div>
          <div className={classes.cardTitle}>
            {CardIcon && (
              <CardIcon data-testid="icon" className={classes.cardIcon} />
            )}
            <h2 className={classes.cardTitle}>{title}</h2>
          </div>
          <p className={classes.description}>{description}</p>
        </div>
        <LightButton
          variant="primary"
          size="md"
          onClick={onClick}
          icon={<IoArrowForward />}
          className={classes.button}
        >
          {ctaText}
        </LightButton>
      </>
    )}
  </div>
);

export default RequestCard;
