import { makeStyles, Theme } from "@material-ui/core";

const useRentedPropertyStyles = makeStyles<Theme>((theme) => ({
  tagText: {
    color: theme.palette.grey["A400"],
  },
  spinner: {
    margin: theme.spacing(1),
  },
}));

export default useRentedPropertyStyles;
