import { makeStyles, Theme } from "@material-ui/core/";

const usePropertyDetailsSelectorStyles = makeStyles<Theme>((theme) => ({
  selectorRoot: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 8,
    height: "fit-content",
    border: "0.5px solid #A0B1B9",
  },
  selectorItem: {
    padding: theme.spacing(0.75),
    borderRadius: 7,
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0.75, 2),
    },
  },
  selectorItem_selected: {
    backgroundColor: theme.palette.grey[900],
  },
  selectorItem_unselected: {
    backgroundColor: "white",
  },
  selectorText: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  selectorText_selected: {
    color: "white",
  },
  selectorText_unselected: {
    color: "#BFCBD1",
  },
}));

export default usePropertyDetailsSelectorStyles;
