import { makeStyles, Theme } from "@material-ui/core";

const useVisitsHeaderStyles = makeStyles((theme: Theme) => ({
  visitsHeaderWrapper: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      alignItems: "start",
    },
  },
  backButton: {
    padding: 0,
    marginBottom: theme.spacing(2),
  },
  visitsHeaderRoot: {
    marginBottom: theme.spacing(3),
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(5),
    },
  },
  visitsTitle: {
    fontSize: theme.typography.pxToRem(20),
  },
}));

export default useVisitsHeaderStyles;
