import { ReactNode } from "react";

import BottomNavbar from "Components/Admin/UIComponents/BottomNavbar";
import { menuItems } from "apps/lead/constants/layout";
import useStyles from "./MobileViewStyles";

interface Props {
  children: ReactNode;
}

export default function BrowserView({ children }: Props) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.content}>{children}</div>;
      <BottomNavbar menuList={menuItems(classes)} />
    </>
  );
}
