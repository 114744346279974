import {
  Applicant,
  State,
} from "apps/lead/context/evaluationContext/EvaluationTypes";
import { CountryUpperCase } from "models/Countries";

const parseWorkerType = {
  independent: "Independiente",
  dependent: "Dependiente",
  pensioner: "Pensionado",
};

export const backendCreateEvaluationParser = (data: State) => ({
  brokerage: true,
  country: data?.property?.country,
  document: data?.mainApplicant?.document,
  document_type:
    data?.property?.country === "Chile" ? 1 : data?.mainApplicant?.documentType,
  executive: data?.executive ?? "",
  last_name: data?.mainApplicant?.lastName,
  name: data?.mainApplicant?.name,
  phone:
    typeof data?.mainApplicant?.phone === "string"
      ? data?.mainApplicant?.phone
      : data?.mainApplicant?.phone?.parsedPhone,
  property_id: data?.property.id,
  property_uid: data?.property.uid,
  related_applicant_id: data?.relatedApplicantId,
  second_last_name: data?.mainApplicant?.secondLastName,
  work_type: parseWorkerType[data?.mainApplicant.workerType],
});

export const parseCodebtorData = (
  user: Partial<Applicant>,
  country: CountryUpperCase,
  evaluationId: number
) => ({
  user,
  evaluationId,
  country,
});
