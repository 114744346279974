import {
  IoPersonOutline as PersonalInfoIcon,
  IoCallOutline as PhoneIcon,
  IoMailOutline as EmailIcon,
  IoLockClosedOutline as PasswordIcon,
} from "@houm-com/ui-icons";

import { ICardItem } from "./types";

export const getPersonalData = (name: string, phone: string): ICardItem[] => [
  {
    icon: <PersonalInfoIcon size={25} />,
    title: "Información personal",
    subtitle: name,
    url: "/account/profile/personal_info",
  },
  {
    icon: <PhoneIcon size={25} />,
    title: "Teléfono",
    subtitle: phone,
    url: "/account/profile/phone",
  },
];

export const getAccountData = (email: string): ICardItem[] => [
  {
    icon: <EmailIcon size={25} />,
    title: "Correo electrónico",
    subtitle: email,
    url: "/account/profile/email",
  },
  {
    icon: <PasswordIcon size={25} />,
    title: "Contraseña",
    subtitle: "",
    url: "/account/profile/password",
  },
];
