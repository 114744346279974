import { Link as RouterLink } from "react-router-dom";
import { Link, Typography } from "@material-ui/core";

import useItemMenuStyles from "./ItemMenuStyles";

interface Props {
  icon: React.FunctionComponent<{ className?: string }>;
  url: string;
  text: string;
}

const ItemMenu = ({ icon: Icon, url, text }: Props) => {
  const classes = useItemMenuStyles();

  return (
    <div className="flex items-center gap-2">
      <Icon className="h-[20px] w-[20px]" />
      <Link to={url} underline="none" component={RouterLink}>
        <Typography className={classes.menuLink}>{text}</Typography>
      </Link>
    </div>
  );
};

export default ItemMenu;
