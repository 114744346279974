import { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { isDesktop } from "react-device-detect";

import { Container } from "@material-ui/core";

import BackButton from "apps/tenant/components/BackButton";

import useInspectionReportLayoutStyles from "./InspectionReportLayoutStyles";
import TenantLayout from "../TenantLayout";

interface Props {
  children: ReactNode;
}

function InspectionReportLayout({ children }: Props) {
  const history = useHistory();
  const classes = useInspectionReportLayoutStyles();

  return (
    <TenantLayout>
      <Container className={classes.container}>
        {isDesktop && <BackButton onClickHandler={() => history.goBack()} />}
        <Container className={classes.childrenContainer}>{children}</Container>
      </Container>
    </TenantLayout>
  );
}

export default InspectionReportLayout;
