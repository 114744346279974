import { Typography, Box } from "@material-ui/core";

import { EoCLeavingDateForm } from "apps/landlord/hooks/propertyEndOfContract/utils";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CustomRadioButton from "apps/tenant/components/Forms/CustomRadioButton";
import DesignSystemCalendar from "Components/Admin/UIComponents/Calendar/DesignSystemCalendar";
import CustomTextField from "Components/Admin/UIComponents/CustomTextField";
import { LOCALE_BASED_LEAVING_MOTIVES_OPTIONS } from "apps/landlord/hooks/propertyEndOfContract/constants";
import { ContractResponse } from "apps/landlord/services/propertyContractService/types";
import useLeavingDateStepStyles from "./LeavingDateStyles";
import useLeavingDate from "./hooks/useLeavingDate";
import {
  LOCALE_BASED_MOTIVES_DESCRIPTION,
  LOCALE_BASED_MOTIVES_TITLE,
} from "./constants";
import WarningUnsafeDate from "./WarningUnsafeDate";

type Props = {
  onSubmitHadler: (data: EoCLeavingDateForm) => void;
  activeContract: ContractResponse;
  leavingData: EoCLeavingDateForm;
};

const LeavingDateStep = ({
  onSubmitHadler,
  leavingData,
  activeContract,
}: Props) => {
  const classes = useLeavingDateStepStyles();
  const {
    selectedDate,
    setSelectedDate,
    motive,
    setMotive,
    observations,
    setObservations,
    disableSubmit,
    hasNotSafeDate,
    minAvailableDate,
  } = useLeavingDate({
    activeContract,
    leavingData,
    hasLimitDateCondition: false,
  });

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>
        {LOCALE_BASED_MOTIVES_TITLE[activeContract.country]}
      </Typography>
      <Typography className={classes.description}>
        {LOCALE_BASED_MOTIVES_DESCRIPTION[activeContract.country]}
      </Typography>
      {hasNotSafeDate && <WarningUnsafeDate activeContract={activeContract} />}
      <DesignSystemCalendar
        disablePast
        value={selectedDate}
        minDate={minAvailableDate}
        onChange={(date) => {
          setSelectedDate(date as unknown as Date);
        }}
      />
      <Box className={classes.motiveContainer}>
        <CustomRadioButton
          label="¿Cuál es el motivo?"
          value={motive}
          onChange={(e) => setMotive(parseInt(e.target.value as string))}
          id="motive"
          options={LOCALE_BASED_LEAVING_MOTIVES_OPTIONS[activeContract.country]}
        />
      </Box>
      <>
        <Typography className={classes.textFieldLabel}>
          <strong>Cuéntanos</strong> ¿Qué fue lo que paso?
        </Typography>
        <CustomTextField
          className={classes.textField}
          minRows={3}
          multiline
          required
          onChange={(e) => setObservations(e.target.value)}
          value={observations}
        />
      </>
      <Box className={classes.ctaContainer}>
        <DesignSystemButton
          disabled={disableSubmit}
          label="Siguiente"
          variant="primary"
          size="small"
          onClick={() =>
            onSubmitHadler({ date: selectedDate, motive, observations })
          }
        />
      </Box>
    </Box>
  );
};

export default LeavingDateStep;
