import { makeStyles, Theme } from "@material-ui/core";

const useLabelFileStyles = makeStyles<Theme>((theme) => ({
  label: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
  },
  textContainer: {
    display: "flex",
    pointerEvents: "auto",
    alignItems: "center",
  },
}));

export default useLabelFileStyles;
