import { makeStyles, Theme } from "@material-ui/core/";

const useAllInspectionReportsStyles = makeStyles<Theme>((theme) => ({
  reportsContainer: {
    gap: 24,
    marginBottom: theme.spacing(12),
  },
  reportsTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    color: theme.palette.grey[900],
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  reportsSubtitle: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[900],
    margin: theme.spacing(2, 0, 3),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export default useAllInspectionReportsStyles;
