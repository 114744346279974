import { useFeatureManager } from "@houm-com/feature-manager";

import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import Spinner from "Components/Shared/partials/Spinner";

import PaymentMethodCard from "../PaymentMethodCard";

const PaymentMethodsCL = () => {
  const { state } = useRentPayment();
  const { status: statusFintoc, data: dataFintoc } = useFeatureManager(
    "fintoc",
    { marketCode: "cl" }
  );
  const { status: statusOtrosPagos, data: dataOtrosPagos } =
    useFeatureManager("otros_pagos");
  const { status: statusKhipu, data: dataKhipu } = useFeatureManager("khipu");
  const isFintocFlag = statusFintoc === "success" && dataFintoc?.enabled;
  const isOtrosPagosFlag =
    statusOtrosPagos === "success" && dataOtrosPagos?.enabled;
  const isKhipuFlag = statusKhipu === "success" && dataKhipu?.enabled;

  const isLoading =
    statusFintoc === "loading" ||
    statusOtrosPagos === "loading" ||
    statusKhipu === "loading";

  if (isLoading) return <Spinner />;

  return (
    <>
      {state.paymentData.concept !== "RESERVATION" && (
        <>
          {isFintocFlag && <PaymentMethodCard data={dataFintoc.content} />}
          {isKhipuFlag && <PaymentMethodCard data={dataKhipu.content} />}
        </>
      )}
      {isOtrosPagosFlag && <PaymentMethodCard data={dataOtrosPagos.content} />}
    </>
  );
};

export default PaymentMethodsCL;
