import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  size: number;
}

const useFlagStyles = makeStyles<Theme, Props>((theme) => ({
  flagRoot: {
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    width: ({ size }) => size + 8,
    height: ({ size }) => size + 8,
  },
}));

export default useFlagStyles;
