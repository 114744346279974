import { makeStyles, Theme } from "@material-ui/core";

const usePasswordRequirementsStyles = makeStyles((theme: Theme) => ({
  passwordRequirementsRoot: {
    marginTop: theme.spacing(-1),
    paddingLeft: theme.spacing(0.5),
    "& > li": {
      listStylePosition: "inside",
    },
  },
}));

export default usePasswordRequirementsStyles;
