import { Fragment, useState } from "react";
import { useLocation, Link, NavLink } from "react-router-dom";
import { useQuery, UseQueryResult } from "react-query";
import classNames from "classnames";

import { IoMenuOutline, IoCloseCircleOutline } from "@houm-com/ui-icons";
import Drawer from "@material-ui/core/Drawer";
import { Divider, MenuItem, Typography } from "@material-ui/core";

import {
  CountryUpperCaseModelToLocale,
  LocaleCountries,
} from "models/Countries";
import { IUser, parseUserData } from "apps/account/components/utils";
import userService from "services/userService";
import logo from "assets/images/houmLogo.svg";
import { useGetToken } from "hooks";

import NavbarMenu from "./NavbarMenu/NavbarMenu";
import NavbarLink from "./NavbarLink/NavbarLink";
import AuthMenuMobile from "./AuthMobileMenu";
import useStyles from "./NavbarMobileStyles";
import { getLocaleBasedHeaderItems } from "../HeaderItems";
import { inUrl } from "../utils";
import AuthLogoutButton from "./AuthLogoutButton";

export default function NavbarMobile() {
  const { getToken } = useGetToken();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const user = userService.me();
  const userLocale =
    CountryUpperCaseModelToLocale[user?.country] || LocaleCountries.CL;
  const location = useLocation();
  const selected = inUrl("/landlord/ajustes", location);
  const classes = useStyles({ selected });
  const handleMenuStyles = (isActive: boolean) =>
    isActive
      ? classNames(classes.navbarMenuItem, classes.navbarMenuItemActive)
      : classNames(classes.navbarMenuItem);

  const { data }: UseQueryResult<IUser, Error> = useQuery<IUser, Error>(
    "user",
    async () => {
      const authToken = await getToken();
      const response = await userService.getV2(authToken);
      return parseUserData(response?.data);
    }
  );

  const handleCloseDrawer = () => setOpenDrawer(false);

  const menuItems = getLocaleBasedHeaderItems(userLocale);

  return (
    <>
      {/** this navbar-root element is tracked by our layout resize observer */}
      <div id="navbar-root" className={classes.root}>
        <div className={classes.rootContent}>
          <div>
            <Link to="/" title="Houm">
              <img src={logo} alt="Houm" />
            </Link>
          </div>
          <button onClick={() => setOpenDrawer(true)}>
            <IoMenuOutline className="cursor-pointer h-6 w-6" />
          </button>
        </div>
        <div id="horizontal-banner-root" />
      </div>

      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleCloseDrawer}
        classes={{ paper: classes.navbarDrawerPaper }}
      >
        <div className={classes.drawer}>
          <div className={classes["navbar-mobile-logo-container"]}>
            <div className={classes.logoSection}>
              <Link to="/" title="Houm">
                <img src={logo} alt="Houm" />
              </Link>
              <button className="rounded-full" onClick={handleCloseDrawer}>
                <IoCloseCircleOutline className="h-8 w-8 text-primary-cta" />
              </button>
            </div>
            <Divider className={classes["navbar-divider"]} />
            {data && (
              <div className={classes.userItem}>
                <Typography
                  className={classes.user}
                >{`${data.name} ${data.lastName}`}</Typography>
              </div>
            )}
            <div className={classes.contentUser}>
              <AuthMenuMobile />
            </div>
            {menuItems.map((menuItem) => (
              <Fragment key={menuItem.id}>
                {"menuLabel" in menuItem ? (
                  <NavbarMenu
                    isOpenPropietaryRental={true}
                    label={menuItem.menuLabel}
                    key="prop"
                  >
                    {menuItem.navLink.map((navLink) => (
                      <NavbarLink
                        label={navLink.label}
                        link={navLink.link}
                        key={navLink.key}
                        isOnPaper={navLink.isOnPaper}
                      />
                    ))}
                  </NavbarMenu>
                ) : (
                  <NavLink
                    to={menuItem.to}
                    title={menuItem.hrefTitle}
                    onClick={menuItem.onClick}
                    className={handleMenuStyles}
                  >
                    {menuItem.label}
                  </NavLink>
                )}
              </Fragment>
            ))}
            <MenuItem className={classes.logoutButton}>
              <AuthLogoutButton />
            </MenuItem>
          </div>
        </div>
      </Drawer>
    </>
  );
}
