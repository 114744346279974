import { useState } from "react";

const useExternalPhoto = () => {
  const [image, setImage] = useState<string | null>(null);

  const getBase64Image = (url: string) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/jpg");
      setImage(dataURL);
    };
    img.src = url;
  };

  return {
    image,
    getBase64Image,
  };
};

export default useExternalPhoto;
