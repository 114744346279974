import { Hidden } from "@material-ui/core";

import { IoChevronBack } from "@houm-com/ui-icons";

import HoumLogo from "assets/images/houmLogo.png";
import useStyles from "./NavbarStyles";

interface Props {
  handleBack?: () => void;
}

const Navbar = ({ handleBack }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.navbarRoot}>
      {handleBack && (
        <Hidden mdUp>
          <IoChevronBack className={classes.arrow} onClick={handleBack} />
        </Hidden>
      )}
      <img src={HoumLogo} alt="logo" className={classes.logo} />
    </div>
  );
};

export default Navbar;
