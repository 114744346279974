import { useHistory } from "react-router-dom";

import { Box, Typography } from "@material-ui/core";
import { IoChevronForwardOutline as ArrowRight } from "@houm-com/ui-icons";

import useMobilePaymentsTableStyles from "./MobilePaymentsTableStyles";
import { ParsedMovement } from "../../utils/types";

interface Props {
  movements: ParsedMovement[];
}

const MobilePaymentsTable = ({ movements }: Props) => {
  const classes = useMobilePaymentsTableStyles();
  const history = useHistory();

  return (
    <Box className={classes.mobilePaymentsTableRoot}>
      {movements &&
        movements.map((movement) => (
          <Box
            key={movement.id}
            className={classes.movementCard}
            onClick={() =>
              history.push(`/tenant/voucher-details/${movement.id}`)
            }
          >
            <Typography className={classes.cardText}>
              {movement.date}
            </Typography>
            <Box className={classes.valueBox}>
              <Typography className={classes.cardText}>
                {movement.amount}
              </Typography>
              <ArrowRight />
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default MobilePaymentsTable;
