import * as yup from "yup";

const requiredText = "Este campo es obligatorio";

export const validationSchema = yup.object().shape({
  email: yup.string().email().required(requiredText),
  emailVerification: yup
    .string()
    .oneOf([yup.ref("email"), null], "Los correos no coinciden")
    .required(requiredText),
});
