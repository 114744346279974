import { makeStyles, Theme } from "@material-ui/core";

const useRequirementItemStyles = makeStyles<Theme>((theme) => ({
  base: {
    listStyleType: "disc",
    fontSize: theme.typography.pxToRem(10),
    lineHeight: 1.8,
  },
  valid: {
    color: theme.palette.success.main,
    fontWeight: 700,
  },
  invalid: {
    color: theme.palette.error.main,
  },
}));

export default useRequirementItemStyles;
