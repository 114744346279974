import { Grid, Link, Typography, Divider } from "@material-ui/core";

import { IoArrowForwardOutline } from "@houm-com/ui-icons";

import useStyles from "./AppointmentsNoResultsStyles";
import { urlHoum } from "./AppointmentsConstants";
import { useLocation } from "react-router-dom";

const AppointmentsNoResults = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const scheduleType = new URLSearchParams(search).get("schedule_type");
  const marketplaceUrl =
    scheduleType !== "SalesVisit"
      ? `${urlHoum}/propiedades/arriendo`
      : `${urlHoum}/propiedades/venta`;
  return (
    <>
      <Divider className={classes.divider} />
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography className={classes.text}>
          Todavía no tienes visitas agendadas
        </Typography>
        <Typography className={classes.textBold}>
          ¡Empieza hoy a buscar tu siguiente hogar!
        </Typography>
        <Link
          className={classes.link}
          rel="noreferrer"
          href={marketplaceUrl}
          target="_blank"
        >
          Buscar mi siguiente hogar
          <IoArrowForwardOutline className={classes.iconLink} />
        </Link>
      </Grid>
    </>
  );
};

export default AppointmentsNoResults;
