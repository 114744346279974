import { makeStyles, Theme } from "@material-ui/core";

interface TagStyleProps {
  color: string;
  backgroundColor: string;
  bordered: boolean;
}

const useTagStyle = makeStyles<Theme, TagStyleProps>((theme) => ({
  TagRoot: {
    height: "fit-content",
    width: "fit-content",
    padding: theme.spacing(0, 1),
    borderRadius: 20,
    border: (props) => (props.bordered ? `1px solid ${props.color}` : "none"),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: (props) => props.backgroundColor,
  },
  label: {
    margin: "0px !important",
    padding: 0,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.66,
    color: (props) => props.color,
  },
}));

export default useTagStyle;
