import { Box, Typography } from "@material-ui/core";

import { IoChatboxOutline } from "@houm-com/ui-icons";
import IconButton from "@houm-com/ui/IconButton";

import Spinner from "Components/Shared/partials/Spinner";

import useInspectionReportCommentsStyles from "../InspectionReportCommentsStyles";
import InspectionReportCommentItem from "./InspectionReportCommentItem";
import NoCommentsGuidelines from "./NoCommentsGuidelines";

interface ReportCommentsListProps {
  reports: any[];
  onCreateNewComment: () => void;
  isLoadingMoreItems: boolean;
  canCreateNewComments: boolean;
}

const ReportCommentsList = ({
  reports,
  onCreateNewComment,
  isLoadingMoreItems,
  canCreateNewComments,
}: ReportCommentsListProps) => {
  const classes = useInspectionReportCommentsStyles();
  return (
    <Box className={classes.highlightedBox}>
      <Typography className={classes.highlightedBoxTitle}>
        Tus comentarios sobre el estado de la propiedad
      </Typography>
      <Typography className={classes.highlightedBoxText}>
        Recuerda que esta información es importante como respaldo para el
        momento en que decidas salir de esta propiedad.
      </Typography>
      {reports.map(({ createdAt, comment, photos }) => (
        <InspectionReportCommentItem
          key={`${createdAt}-${comment}`}
          createdAt={createdAt}
          comment={comment}
          photos={photos}
        />
      ))}
      {isLoadingMoreItems && <Spinner />}
      {!reports.length && <NoCommentsGuidelines />}
      <IconButton
        size="md"
        variant="tertiary"
        disabled={!canCreateNewComments}
        onClick={onCreateNewComment}
        icon={<IoChatboxOutline />}
        position="end"
      >
        Agregar comentario
      </IconButton>
    </Box>
  );
};

export default ReportCommentsList;
