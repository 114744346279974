import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(15),
    [theme.breakpoints.up("sm")]: {
      padding: 0,
      paddingTop: theme.spacing(7),
    },
  },
  icon: {
    fontSize: theme.typography.pxToRem(60),
  },
  text: {
    textAlign: "center",
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(20),
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
  },
}));
