import { useQuery } from "react-query";

import { useHoumSnackBar } from "libs/houm-snackbar";

import schedulerService from "services/schedulesService";
import {
  filterLandlordHomecheckerHours,
  parseScheduleAvailability,
} from "utils/datetime";
import {
  TIMEZONES_BY_COMMUNE,
  TIMEZONES_BY_COUNTRY_CODE,
} from "utils/getTimezone";
import { IProperty } from "apps/landlord/models/Property";

import { GET_AVAILABILITY_ERROR } from "./constants";

const useSchedulePhotoAvailability = (propertyData: IProperty) => {
  const { latitude, longitude } = propertyData?.propertyDetails || {};
  const { enqueueHoumSnackBar } = useHoumSnackBar();

  const handleGetAvailability = async () => {
    const { data: availability } =
      await schedulerService.getAvailabilityByLocation({
        lat: latitude,
        lng: longitude,
        type: "Photo",
      });

    const timezone =
      TIMEZONES_BY_COUNTRY_CODE[propertyData?.country] ||
      TIMEZONES_BY_COMMUNE[propertyData?.commune] ||
      TIMEZONES_BY_COUNTRY_CODE.cl;

    const parsedAvailability = parseScheduleAvailability(
      availability,
      timezone,
      filterLandlordHomecheckerHours
    );
    return {
      availability,
      parsedAvailability,
    };
  };

  const {
    data: availability,
    isError,
    isLoading,
    isSuccess,
  } = useQuery(["AVAILABILITY", propertyData?.id], handleGetAvailability, {
    enabled: Boolean(longitude && latitude),
    onError: () => {
      enqueueHoumSnackBar(GET_AVAILABILITY_ERROR);
    },
  });

  return {
    availability,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useSchedulePhotoAvailability;
