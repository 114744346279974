import Divider from "@material-ui/core/Divider";

import { ControlledFileUpload } from "Components/Admin/UIComponents/ControlledForm";
import { useClosureForm } from "context/closureFormContext/closureFormContext";

import { Props, filter } from "./utils";
import useStyles from "../ClosureFormStyles";

interface fileUploadProps extends Props {
  helperText?: string;
  allowedMimeTypes: string[];
}

export const FilteredFileUpload: React.FC<fileUploadProps> = ({
  id,
  fieldInfo,
  allowedMimeTypes,
  helperText = "",
}) => {
  const { state } = useClosureForm();
  if (filter(fieldInfo, state.isAdmin, state.propertyData.country)) return null;
  const classes = useStyles();
  return (
    <>
      <ControlledFileUpload
        id={id}
        label={fieldInfo.label(state.propertyData.country)}
        allowedMimeTypes={allowedMimeTypes}
        helperText={helperText}
      />
      <br />
      <Divider className={classes.divider} />
    </>
  );
};

export default FilteredFileUpload;
