import { useGetToken } from "hooks";
import { useQuery, UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";
import propertyService from "services/propertyService";

interface ContractInfo {
  submitted: boolean;
}

export default function useContractPropertyInfo() {
  const { getToken } = useGetToken();
  const { id: propertyUid } = useParams<{ id: string }>();
  const { isLoading, data, error }: UseQueryResult<ContractInfo, Error> =
    useQuery<ContractInfo, Error>(["contractInfo", propertyUid], async () => {
      const authToken = await getToken();
      const contractResponse = await propertyService.getContractPropertyInfo(
        propertyUid,
        authToken
      );
      return contractResponse?.data;
    });
  return { isLoading, data, error };
}
