import { useParams, useHistory } from "react-router-dom";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Grid, Typography } from "@material-ui/core";

import useOfferAnsweredStyles from "./OfferAnsweredStyles";
import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import CallToAction from "Components/Admin/UIComponents/atoms/CallToAction";

const OfferAnswered = () => {
  const classes = useOfferAnsweredStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const handleOnClick = () => {
    history.push(`/propietary/properties/${id}?type=forSale`);
  };

  return (
    <Grid container className={classes.grid}>
      <Grid item xs={11} sm={8} className={classes.text}>
        <Grid item className={classes.text}>
          <Typography component="h1">¡Listo!</Typography>
        </Grid>
        <Grid item className={classes.text}>
          <CheckCircleIcon className={classes.iconCheck} />
        </Grid>
        <Grid item className={classes.text}>
          <Typography component="p">
            Tu respuesta fue enviada. Te avisaremos cuando tengamos una
            respuesta.
          </Typography>
        </Grid>
        <CallToAction>
          <DesignSystemButton
            variant="primary"
            onClick={handleOnClick}
            label="Salir"
            size="medium"
          />
        </CallToAction>
      </Grid>
    </Grid>
  );
};

export default OfferAnswered;
