import { useUser } from "context/userContext";
import { useGetToken } from "hooks";
import { useQuery, UseQueryResult } from "react-query";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import appointmentsService from "services/appointmentsService";
import Spinner from "Components/Shared/partials/Spinner";
import AppointmentsListItem, { IAppointment } from "./AppointmentsListItem";
import AppointmentsNoResults from "./AppointmentsNoResults";
import { parseVisitData } from "./utils";

const AppointmentsList = () => {
  const { getToken } = useGetToken();
  const { user } = useUser();
  const location = useLocation();
  const dataLink = queryString.parse(location.search);
  const { isLoading, data, error }: UseQueryResult<IAppointment[], Error> =
    useQuery<IAppointment[], Error>(
      ["appointmentsList", dataLink],
      async () => {
        const authToken = await getToken();
        const response = await appointmentsService.getAppointments(
          { ...dataLink },
          authToken,
          user.email
        );
        return parseVisitData(response?.data.visits);
      }
    );

  const dataList = data;

  if (isLoading) return <Spinner />;

  if (error) return <AppointmentsNoResults />;

  if (!dataList.length) return <AppointmentsNoResults />;

  return (
    <div>
      {dataList.map((item, index) => (
        <AppointmentsListItem key={index} appointment={item} />
      ))}
    </div>
  );
};

export default AppointmentsList;
