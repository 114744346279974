import { makeStyles, Theme } from "@material-ui/core";

const defaultBreakpoint = "sm";

const useDetailCardStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    lineHeight: 1.4,
  },
  boldText: {
    fontWeight: 700,
  },
  subTitle: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      marginBottom: 0,
    },
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.57,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(defaultBreakpoint)]: {
      rowGap: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
    },
  },
  detailContainer: {
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(0, 4),
      borderLeft: `1px solid ${theme.palette.grey[75]}`,
      marginLeft: theme.spacing(4),
    },
  },
  buttonContainer: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: 0,
      position: "static",
      justifyContent: "center",
    },
  },
  button: {
    "&:hover": {
      backgroundColor: "#FFC9C2",
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up(defaultBreakpoint)]: {
      alignSelf: "center",
      width: "fit-content",
      marginTop: theme.spacing(4),
    },
  },
}));

export default useDetailCardStyles;
