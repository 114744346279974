import { Locale } from "models/Countries";
import {
  CompanyType,
  IBillDetails,
} from "apps/landlord/services/propertiesDuesService/type";

export type DetailsType = {
  waterDetails?: IBillDetails;
  energyDetails?: IBillDetails;
  gasDetails?: IBillDetails;
  commonExpensesDetails?: IBillDetails;
};

export const getCompanyTypes = (country: Locale): CompanyType[] => {
  switch (country) {
    case "cl":
      return ["water", "gas", "energy", "common_expenses"];
    case "co":
      return ["water", "gas", "energy"];
    case "mx":
    default:
      return [];
  }
};

export const snakeToCamel = (str: string) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", "")
    );

export const emptyDetail = {
  status: "no_data",
};
