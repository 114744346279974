import { View, Text, Image } from "@react-pdf/renderer";

import HoumLogo from "../assets/images/isotipo.png";

import useInspectionReportDocumentStyles from "../InspectionReportDocumentStyles";

interface InspectionReportHeaderProps {
  address: string;
  propertyId: number;
}

function InspectionReportHeader({
  address,
  propertyId,
}: InspectionReportHeaderProps) {
  const styles = useInspectionReportDocumentStyles;

  return (
    <View fixed style={styles.header}>
      <View style={styles.headerTitleWrap}>
        <Text>{address}</Text>
        <Text>ID Propiedad: {propertyId}</Text>
      </View>
      <View style={styles.headerLogo}>
        <Image style={styles.logo} src={HoumLogo} />
      </View>
    </View>
  );
}

export default InspectionReportHeader;
