import clx from "classnames";

import { Box, Typography } from "@material-ui/core";

import { CurrencyCode } from "models/Currencies";
import usePriceInfoStyles from "./PriceInfoStyles";

interface Props {
  isSmallCard?: boolean;
  currencySecondaryPrice?: CurrencyCode;
  secondaryPrice?: string;
  primaryPrice: string;
  isRecommendedPrice?: boolean;
}

const PriceInfo = ({
  isSmallCard = false,
  primaryPrice,
  secondaryPrice = null,
  isRecommendedPrice = false,
}: Props) => {
  const classes = usePriceInfoStyles();
  return (
    <Box className={isSmallCard ? classes.noBgPrice : classes.price}>
      <Typography
        className={clx(classes.bold, {
          [classes.greenPrice]: isRecommendedPrice,
        })}
      >
        {primaryPrice}
      </Typography>
      {secondaryPrice && (
        <>
          <strong className={classes.divisor}>{` / `}</strong>
          <Typography
            className={clx(classes.default, {
              [classes.greenPrice]: isRecommendedPrice,
            })}
          >
            {secondaryPrice}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default PriceInfo;
