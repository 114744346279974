import { format } from "date-fns";
import { es } from "date-fns/locale";
import { startCase } from "lodash";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import LightButton from "@houm-com/ui/LightButton";
import { IoArrowForward as ArrowIcon } from "@houm-com/ui-icons";

import { Box, Typography } from "@material-ui/core";

import { useMarket } from "apps/tenant/context/MarketContext";
import { IVisit } from "apps/tenant/models/Visit";
import VisitCard from "apps/tenant/pages/Visits/components/VisitsList/components/VisitCard";

import NoVisits from "./components/NoVisits";
import useVisitsStyles from "./VisitsStyles";
import { getCurrentMonthVisits, getPreviewVisits } from "./utils";

interface Props {
  visits: IVisit[];
}

const Visits = ({ visits }: Props) => {
  const classes = useVisitsStyles();
  const history = useHistory();
  const { market } = useMarket();
  const previewVisits = getPreviewVisits(isMobile, visits);
  const currentMonthVisits = getCurrentMonthVisits(visits);
  const currentMonth = startCase(format(new Date(), "MMMM", { locale: es }));

  if (!previewVisits.length)
    return (
      <Box className={classes.visitsRoot}>
        <Typography className={classes.visitsTitle}>Visitas</Typography>
        <NoVisits market={market} />
      </Box>
    );

  return (
    <Box className={classes.visitsRoot}>
      <Typography className={classes.visitsTitle}>Visitas</Typography>
      <Typography
        className={classes.visitsSubtitle}
      >{`Agendadas en ${currentMonth}: ${currentMonthVisits}`}</Typography>
      <Box className={classes.visitsList}>
        {previewVisits?.map((visit) => (
          <VisitCard key={visit?.index} visit={visit} contrast />
        ))}
      </Box>
      <LightButton
        className={classes.seeAll}
        variant="primary"
        size="sm"
        icon={<ArrowIcon />}
        onClick={() => history.push("tenant/visits")}
      >
        Ver todas
      </LightButton>
    </Box>
  );
};

export default Visits;
