import { format } from "date-fns";

import IconButton from "@houm-com/ui/IconButton";
import { IoCreateOutline } from "@houm-com/ui-icons";
import { cn } from "@houm-com/ui/utils";
import currency from "@houm-com/formats/currency";

import {
  IBillDetails,
  IBillInfo,
} from "apps/landlord/services/propertiesDuesService/type";
import useProperty from "apps/tenant/hooks/useProperty";

import statuses from "../../hooks/useBillStatus/utils";
import classes from "./SuccessCardContentStyles";

type SuccessCardContentProps = {
  detail: Partial<IBillDetails>;
  item: Partial<IBillInfo>;
  billStatus: string;
  onEditMode: () => void;
};

const SuccessCardContent = ({
  detail,
  item,
  billStatus,
  onEditMode,
}: SuccessCardContentProps) => {
  const { propertyData: property, propertyLoading: isLoading } = useProperty();
  if (isLoading) return;
  const isDebt = billStatus === statuses.past_due;
  const debt = currency(
    isDebt ? detail.expiredDebt : detail.totalDebt,
    property?.country === "cl" ? "CLP" : "COP",
    { locale: property?.country }
  );
  return (
    <>
      <div className={classes.successCardContentContainer}>
        <p
          className={cn({
            [classes.debtText]: isDebt,
            [classes.debtTextSuccess]: !isDebt,
          })}
        >
          Deuda: {debt}
        </p>
        <p className={classes.cardText}>
          Cliente: {item.clientInfo?.externalId}
        </p>
        {detail.dueDate && (
          <p className={classes.cardText}>
            Fecha límite de pago:{" "}
            {detail.dueDate && format(detail.dueDate, "dd/MM/yyyy")}
          </p>
        )}
      </div>
      <IconButton
        variant="primary"
        size="md"
        className={classes.cardButton}
        icon={<IoCreateOutline className={classes.cardButtonIcon} />}
        position="end"
        onClick={onEditMode}
      >
        Editar
      </IconButton>
    </>
  );
};

export default SuccessCardContent;
