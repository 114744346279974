import { AnimatePresence } from "framer-motion";
import "./container.css";

interface HoumSnackbarContainerProps {
  children: React.ReactNode;
}

const HoumSnackbarContainer = ({ children }: HoumSnackbarContainerProps) => (
  <div className="snackbar-outlet">
    <AnimatePresence initial={true}>{children}</AnimatePresence>
  </div>
);

export default HoumSnackbarContainer;
