import { useRentPayment } from "apps/payments/context/rentPaymentContext/rentPaymentContext";

import OpenPayDetails from "../OpenPayDetails";
import FintocDrawer from "../FintocDrawer";
import PlaceToPay from "../PlaceToPay";
import Khipu from "../Khipu";
import PayU from "../PayU";

const PaymentSelectedOption = () => {
  const { state } = useRentPayment();

  return (
    <div>
      {state.methodSelected === "payU" && <PayU />}
      {state.methodSelected === "openPay" && <OpenPayDetails />}
      {state.methodSelected === "placeToPay" && <PlaceToPay />}
      {state.methodSelected === "khipu" && <Khipu />}
      {state.methodSelected === "fintoc" && <FintocDrawer />}
    </div>
  );
};

export default PaymentSelectedOption;
