import { makeStyles, Theme } from "@material-ui/core";

const usePropertyEvaluationDetailsStyles = makeStyles<Theme>((theme) => ({
  personContainer: {
    marginLeft: theme.spacing(3),
  },
  personDescription: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[500],
    lineHeight: 1.6,
  },
  statusDefault: {
    color: theme.palette.grey[500],
  },
  statusPending: {
    color: "#A07800",
  },
  statusRejected: {
    color: "#900000",
  },
  descriptionContainer: {
    display: "flex",
    alignItems: "center",
  },
  descriptionText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.6,
  },
  iconContainer: {
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(0.5),
  },
}));

export default usePropertyEvaluationDetailsStyles;
