import { makeStyles, Theme } from "@material-ui/core/";

const useDetailsInformationStyles = makeStyles<Theme>((theme) => ({
  detailsRoot: {
    marginTop: theme.spacing(3),
  },
  detailsSubtitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  informationIcon: {
    transform: "scale(0.8)",
    [theme.breakpoints.up("sm")]: {
      transform: "scale(1)",
    },
  },
  characteristicsContainer: {
    marginBottom: theme.spacing(2),
  },
}));

export default useDetailsInformationStyles;
