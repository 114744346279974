import PropertyLayout from "apps/landlord/Layout/PropertyLayout";
import { useHistory, useParams } from "react-router-dom";
import AllInspectionReports from "./AllInspectionReports";

export default function AllInspectionReportsIndex() {
  const history = useHistory();
  const { id: propertyUuid } = useParams<{ id: string }>();
  const backURL = `/landlord/properties/${propertyUuid}`;

  return (
    <PropertyLayout
      onHandleBack={() => history.push(backURL)}
      titlePage="Actas de inspección"
      subtitlePage="Estas inspecciones muestran el estado tu propiedad y te dejamos observaciones de posibles mejoras 😉"
    >
      <AllInspectionReports />
    </PropertyLayout>
  );
}
