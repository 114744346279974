import * as yup from "yup";

const requiredText = "Este campo es obligatorio";

const formSchema = yup.object().shape({
  waterCompanyName: yup.string().nullable(true).required(requiredText),
  energyCompanyName: yup.string().nullable(true).required(requiredText),
  waterClientNumber: yup.string().nullable(true).required(requiredText),
  energyClientNumber: yup.string().nullable(true).required(requiredText),
});

export default formSchema;
