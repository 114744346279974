import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

import { IProperty } from "apps/tenant/models/Property";

import { useGetToken } from "hooks";

import propertyService from "../services/propertyService";
import { parseProperty } from "./utils/parsers";

const useProperty = (
  propertyId: string = null,
  options: Omit<
    UseQueryOptions<IProperty, Error, IProperty, QueryKey>,
    "queryKey" | "queryFn"
  > = {}
) => {
  const { getToken } = useGetToken();
  const { isLoading, error, data }: UseQueryResult<IProperty, Error> = useQuery<
    IProperty,
    Error
  >(
    ["property", propertyId],
    async () => {
      const authToken = await getToken();
      const response = await propertyService.getProperty(propertyId, authToken);
      return parseProperty(response.data);
    },
    {
      ...options,
      enabled: !!propertyId,
    }
  );
  return {
    propertyLoading: isLoading,
    propertyError: error,
    propertyData: data,
  };
};

export default useProperty;
