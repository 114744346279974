import { useFormContext } from "react-hook-form";
import FieldInput from "@houm-com/ui/FieldInput";

interface Props {
  defaultValue?: string | null;
}

export default function OtherFields({ defaultValue }: Props) {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  return (
    <FieldInput
      id="administrationEmail"
      label="Correo de la administración"
      defaultValue={defaultValue}
      errorMessage={errors.administrationEmail?.message}
      {...register("administrationEmail")}
    />
  );
}
