export const PROPERTY_TYPES = {
  house: { value: "house", label: "Casa" },
  flat: { value: "flat", label: "Departamento" },
  office: { value: "office", label: "Oficina" },
  land: { value: "land", label: "Terreno" },
  warehouse: { value: "warehouse", label: "Almacen" },
  other: { value: "other", label: "Otro" },
};

export const TYPE_OF_SERVICE = {
  rent: { label: "Arriendo", value: "rent" },
  sale: { label: "Venta", value: "sale" },
  both: { label: "Ambos", value: "both" },
};
