import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import { useAuth0 } from "@auth0/auth0-react";

import { Box } from "@material-ui/core";

import Loading from "Components/Admin/UIComponents/molecules/Loading";
import useNavbarHeight from "apps/landlord/hooks/useNavbarHeight";
import SummaryLayout from "apps/landlord/Layout/SummaryLayout";
import bankAccountService from "services/bankAccountService";
import propertyService from "services/propertyService";
import userService from "services/userService";
import { useGetToken } from "hooks";
import { countryCode } from "utils";

import BankAccountList from "./components/BankAccountList";
import PropertiesCard from "./components/PropertiesCard";
import useBankAccountsStyles from "./BankAccountsStyles";

export interface BankListsProps {
  cl: BankListProps[];
  co: BankListProps[];
  mx: BankListProps[];
}

export interface BankListProps {
  bank_id: string;
  code: string;
  institution_number: string;
  name: string;
}

const BankAccounts = () => {
  const navbarHeight = useNavbarHeight();
  const { getToken } = useGetToken();
  const { logout } = useAuth0();
  const classes = useBankAccountsStyles({ navbarHeight });
  const [loading, setLoading] = useState<boolean>(true);
  const [properties, setProperties] = useState(null);

  useEffect(() => {
    (async () => {
      setLoading(true);

      // fetch bankLists
      const authToken = await getToken();

      // fetch properties
      const user = userService.me();
      if (!user) {
        logout({ returnTo: `${window.location.origin}/login` });
      }
      if (user) {
        const propertiesRes = await propertyService.index(authToken);
        const data = await bankAccountService.getByUser(
          countryCode(user.country),
          user.id,
          authToken
        );
        if (propertiesRes && data) {
          const filteredProperties = propertiesRes.data.results;
          const fullProperties = await Promise.all(
            filteredProperties.map(async (item) => {
              const response = await bankAccountService.getByUserAndProperty(
                countryCode(item.country),
                user.id,
                item.id,
                authToken
              );
              const { data: dataAccount, status } = response;
              if (status === 404) return item;
              return {
                ...item,
                account: dataAccount,
              };
            })
          );
          setProperties(fullProperties);
        }
      }
      setLoading(false);
    })();
  }, []);

  return (
    <SummaryLayout>
      <Box className={classes.bankAccountsRoot}>
        {!isMobile && (
          <Box className={classes.leftContainer}>
            <PropertiesCard />
          </Box>
        )}
        <Box className={classes.rightContainer}>
          <Loading loading={loading} className={classes.spinnerContainer}>
            <BankAccountList properties={properties} />
          </Loading>
        </Box>
      </Box>
    </SummaryLayout>
  );
};

export default BankAccounts;
