import { RentPaymentProvider } from "apps/payments/context/rentPaymentContext/rentPaymentContext";
import { DrawerProvider } from "apps/landlord/hooks/useCustomDrawer/context";
import LandingPayment from "./LandingPayment";

const index = () => (
  <DrawerProvider isOpen={false}>
    <RentPaymentProvider>
      <LandingPayment />
    </RentPaymentProvider>
  </DrawerProvider>
);

export default index;
