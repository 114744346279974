import { add } from "date-fns";

import {
  IoConstructOutline as RepairsIcon,
  IoDocumentOutline as DocumentIcon,
  IoDocumentTextOutline as PaperIcon,
  IoCashOutline as CashIcon,
} from "@houm-com/ui-icons";

import { ITenantContractEnd } from "apps/tenant/utils/types";
import { formatDate } from "apps/tenant/utils/utils";
import { Locale } from "models/Countries";

import { HelpCardProps } from "./components/HelpCard/HelpCard";
import {
  LOCAL_BASED_CONTRACT_END_CARD_TEXTS,
  LOCAL_BASED_KUSTOMER_FORMS,
  LOCAL_BASED_REPAIRS_CARD_TEXTS,
} from "./constants";

const getHelpCardsProps = (
  historyPush: (string) => void,
  contractEndData: ITenantContractEnd,
  country: Locale,
  contractInitDate: Date
): HelpCardProps[] => {
  const isContractEnd = contractEndData?.hasBegun;
  const formattedDate = isContractEnd
    ? formatDate(contractEndData.estimatedDate)
    : "";
  const limitDateForServicesRequest = add(contractInitDate, { days: 90 });
  const showServicesRequestCard =
    country === "cl" && limitDateForServicesRequest > new Date();
  const CONTRACT_END_CARD_TEXTS = LOCAL_BASED_CONTRACT_END_CARD_TEXTS[country];
  const REPAIRS_CARD_TEXTS = LOCAL_BASED_REPAIRS_CARD_TEXTS[country];

  const cardProps = [
    {
      title: isContractEnd
        ? "Salida solicitada"
        : CONTRACT_END_CARD_TEXTS.title,
      description: isContractEnd
        ? `Solicitaste tu salida para el día ${formattedDate}, te invitamos a que veas el resumen de tu salida.`
        : CONTRACT_END_CARD_TEXTS.description,
      icon: <DocumentIcon size={22} />,
      clickHandler: () => historyPush("/tenant/help/contract-end"),
      ctaText: isContractEnd ? "Ver resumen" : CONTRACT_END_CARD_TEXTS.ctaText,
    },
    {
      title: REPAIRS_CARD_TEXTS.title,
      description: REPAIRS_CARD_TEXTS.description,
      icon: <RepairsIcon size={22} />,
      clickHandler: () => historyPush("/tenant/help/repairs-request"),
      ctaText: REPAIRS_CARD_TEXTS.ctaText,
    },
    ...(showServicesRequestCard
      ? [
          {
            title: "Aclaración de cuentas de luz, agua, gas o gastos comunes",
            description: (
              <>
                Solicita una aclaración si realizaste pagos que no te
                corresponden o necesitas un reembolso durante tus primeros
                meses. Para saber cómo funciona el proceso haz{" "}
                <a
                  target="_blank"
                  href="https://help.houm.com/cl/articles/34/es-mi-primer-mes-y-no-me-corresponde-pagar-la-totalidad-de-boletas"
                  rel="noreferrer"
                >
                  click aquí
                </a>
              </>
            ),
            icon: <CashIcon size={22} />,
            clickHandler: () => historyPush("/tenant/help/services-request"),
            ctaText: "Solicitar aclaración",
          },
        ]
      : []),

    {
      title: "Otro requerimiento",
      description:
        "Si no encuentras la solicitud que estas buscando, completa nuestro formulario para que podamos ayudarte",
      icon: <PaperIcon size={22} />,
      clickHandler: () =>
        window.open(
          LOCAL_BASED_KUSTOMER_FORMS[country],
          "_blank",
          "noreferrer"
        ),
      ctaText: "Completar formulario",
    },
  ];

  return cardProps;
};

export default getHelpCardsProps;
