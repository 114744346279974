import { IUserBankAccount } from "apps/tenant/models/BankAccount";

import {
  BankAccountFormData,
  BankAccountFormDataMX,
} from "../../../utils/types";

export const parseUserBankAccount = (
  bankAccount: IUserBankAccount
): BankAccountFormData => ({
  accountNumber: bankAccount.accountNumber,
  accountType: bankAccount.accountType,
  bankId: bankAccount.bankId,
  document: bankAccount.document,
  email: bankAccount.email,
  name: bankAccount.fullName,
  numberValidation: "",
});

export const parseUserBankAccountMX = (
  bankAccount: IUserBankAccount
): BankAccountFormDataMX => ({
  accountNumber: bankAccount.accountNumber,
  accountType: bankAccount.accountType,
  bankId: bankAccount.bankId,
  document: bankAccount.document,
  email: bankAccount.email,
  name: bankAccount.fullName,
  numberValidation: "",
  clabe: bankAccount.clabe,
});
