import { makeStyles, Theme } from "@material-ui/core/styles";

const useSuccessFormCreateAccountStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    textAlign: "center",
  },
  icon: {
    marginTop: theme.spacing(3),
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "1.25rem",
    lineHeight: "1.75em",
    color: "#263238",
    padding: theme.spacing(3, 0, 1),
  },
  body: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.5em",
    color: "#263238",
    paddingBottom: theme.spacing(3),
  },
  paragraphContainer: {
    "&>:nth-child(1)": {
      paddingBottom: theme.spacing(1),
    },
    "&>:nth-child(2)": {
      paddingBottom: theme.spacing(4),
    },
    "&>:nth-child(3)": {
      paddingBottom: theme.spacing(2.6),
    },
    "&>:nth-child(4)": {
      paddingBottom: theme.spacing(2),
    },
  },
  paragraph: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.375em",
    color: "#263238",
    "& span": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  },
  button: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.375em",
    padding: theme.spacing(0),
    backgroundColor: "#fff !important",
    border: "unset !important",
  },
  bold: {
    fontWeight: 700,
  },
}));

export default useSuccessFormCreateAccountStyles;
