import DependentForm from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/DependentForm";
import IndependentForm from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/IndependentForm";
import RetiredForm from "apps/lead/pages/EvaluatorForm/components/EmploymentValidationForm/components/co/RetiredForm";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";

const EmploymentValidationForm = () => {
  const {
    state: {
      user: { employment },
    },
  } = useEvaluation();

  return (
    <>
      <Header
        title="Validación laboral"
        subtitle="Estos documentos pueden estar en formato PDF o como imagen (JPEG, JPG o PNG)"
        stepTitle="Validación laboral e ingresos"
      />
      <FormWrapper classNameFormWrapper="flex flex-col gap-4">
        {employment?.workerType === "independent" && <IndependentForm />}
        {employment?.workerType === "dependent" && <DependentForm />}
        {employment?.workerType === "retired" && <RetiredForm />}
      </FormWrapper>
    </>
  );
};

export default EmploymentValidationForm;
