import { Locale } from "models/Countries";

export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_LOADING = "SET_LOADING";
export const SET_STEPS = "SET_STEPS";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_APPLICANT = "SET_APPLICANT";
export const SET_PAGE = "SET_PAGE";
export const SET_COUNTRY = "SET_COUNTRY";

export type WorkerTypeV2 = "dependent" | "independent" | "retired";
export type ApplicantRole = "main" | "codebtor" | "rent_complement";
export enum EvaluatorPages {
  WELCOME = "welcome",
  RESUME = "resume",
  FORM = "form",
  SELFIE_DISCLAIMER = "selfie_disclaimer",
  FINISH = "finish",
}

export interface User {
  id?: number;
  name: string;
  lastName: string;
  secondLastName: string;
  email: string;
  phone: string;
  documentType: number;
  document: string;
  nationality: string;
}

export interface IEmployment {
  workerType: WorkerTypeV2;
  occupation: string;
  employer: string;
  employerPhone: string;
}

export interface UserApplicant {
  user: User;
  identityFiles: {
    frontIdentification: FileList;
    backIdentification: FileList;
    frontIdentificationSelfie: FileList;
  };
  employmentFiles: {
    laborCertificate?: FileList;
    taxReturn?: FileList;
    companyIncomeStatement?: FileList;
    companyConstitution?: FileList;
    pensionCertificate?: FileList;
  };
  incomeValidationFiles: {
    pensionSalarySettlements?: { [key: string]: FileList };
    salarySettlements?: { [key: string]: FileList };
    bankStatements: { [key: string]: FileList };
  };
  employment: IEmployment;
  extraInformation: {
    fullAddress?: string;
    role: ApplicantRole;
  };
  isCompleted: boolean;
}

interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}

interface setActiveStep {
  type: typeof SET_ACTIVE_STEP;
  activeStep: number;
}

interface setSteps {
  type: typeof SET_STEPS;
  steps: number;
}

interface setUserInformation {
  type: typeof SET_USER_INFO;
  userInfo: Partial<UserApplicant>;
}

interface setApplicant {
  type: typeof SET_APPLICANT;
  applicant: Partial<UserApplicant>;
}

interface setPage {
  type: typeof SET_PAGE;
  page: EvaluatorPages;
}

interface setCountry {
  type: typeof SET_COUNTRY;
  country: Locale;
}

export interface State {
  loading: boolean;
  activeStep: number;
  steps: number;
  applicants: Partial<UserApplicant>[];
  user: Partial<UserApplicant>;
  page: EvaluatorPages;
  country: Locale;
}

export type Action =
  | setLoading
  | setActiveStep
  | setSteps
  | setApplicant
  | setUserInformation
  | setPage
  | setCountry;

export type Dispatch = (action: Action) => void;
