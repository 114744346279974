import { useCallback } from "react";

import {
  setActiveStep,
  setCodebtor,
} from "apps/lead/context/codebtorContext/CodebtorActions";
import { useCodebtor } from "apps/lead/context/codebtorContext/CodebtorContext";

import { parseUserFiles, parseApplicantWorkerType } from "./utils/parsers";
import { Files, WorkerType } from "../models/Application";

const useUploadFiles = () => {
  const { state, dispatch } = useCodebtor();
  const { activeStep, codebtor, steps } = state;
  const { workerType, files } = codebtor;

  const handleBackClick = useCallback(() => {
    dispatch(setActiveStep(activeStep - 1));
  }, []);

  const handleChange = useCallback(
    (type: WorkerType) => {
      const parsedUser = parseApplicantWorkerType(codebtor, type);
      dispatch(setCodebtor(parsedUser));
    },
    [workerType]
  );

  const uploadFile = (file: Files, stateFiles: Files[], fileType: string) => {
    const parsedData = parseUserFiles(codebtor, {
      [`${fileType}`]: [...stateFiles, file],
    });
    dispatch(setCodebtor(parsedData));
  };

  const removeFile = (file: string, stateFiles: Files[], fileType: string) => {
    const newFiles = stateFiles.filter((f) => f.name !== file);
    const parsedData = parseUserFiles(codebtor, {
      [`${fileType}`]: newFiles,
    });
    dispatch(setCodebtor(parsedData));
  };

  return {
    handleBackClick,
    handleChange,
    uploadFile,
    removeFile,
    workerType,
    activeStep,
    steps,
    files,
  };
};

export default useUploadFiles;
