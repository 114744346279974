import { useState } from "react";
import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";

import { useHoumSnackBar } from "libs/houm-snackbar";
import changeAcceptanceService from "apps/landlord/services/changeAcceptanceService";

interface IUseTermsData {
  token: string;
}

interface TermsData {
  category: "premium" | "premium_plus" | "basic" | "only_brokerage";
}

const useTermsData = ({ token }: IUseTermsData) => {
  const { enqueueHoumSnackBar } = useHoumSnackBar();
  const [isAccepted, setIsAccepted] = useState(false);
  const { data, isLoading }: UseQueryResult<TermsData, Error> = useQuery<
    TermsData,
    Error
  >(
    "newTermsData",
    async () => {
      const response = await changeAcceptanceService.getContractTerms({
        token,
      });
      return response.data;
    },
    {
      enabled: !!token,
      retry: 1,
      onError: (error: Error) => {
        if (
          (error as AxiosError).response?.data?.detail === "Nothing to accept"
        ) {
          setIsAccepted(true);
          enqueueHoumSnackBar({
            head: "Ya aceptaste las nuevas cláusulas",
            body: "",
            type: "success",
          });
          return;
        }
        enqueueHoumSnackBar({
          body: "Ocurrió un problema al obtener tus datos",
          type: "error",
        });
      },
    }
  );

  return {
    isLoading,
    data,
    isAccepted,
  };
};

export default useTermsData;
