import TenantLayout from "apps/tenant/layout/TenantLayout";

import RepairsRequestPage from "./RepairsRequestPage";

export default function () {
  return (
    <TenantLayout>
      <RepairsRequestPage />
    </TenantLayout>
  );
}
