import * as yup from "yup";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";

import { Country } from "models/PhoneInput";
import { getCountryPhone } from "utils/verifyPhoneNumber";

export const phoneToString = (
  _,
  value: { country: Country; phone: string }
) => {
  if (value) {
    const phoneWithCode = `+${value?.country?.callingCode}${value?.phone}`;
    if (!isValidPhoneNumber(phoneWithCode, value?.country?.countryCode)) {
      return new Error();
    }
    return {
      country: value?.country,
      phone: value?.phone,
      parsedPhone: phoneWithCode,
    };
  }
};

export const phoneObject = yup
  .object({
    phone: yup.string().required(),
    parsedPhone: yup.string().required(),
    country: yup.object({
      countryName: yup.string(),
      countryCode: yup.string().required(),
      callingCode: yup.string().required(),
    }),
  })
  .transform(phoneToString);

export const optionalPhoneToString = (
  _,
  value: { country: Country; phone: string }
) => {
  if (!value?.phone) {
    const defaultCountry: Country = getCountryPhone("cl");

    return {
      country: defaultCountry,
      phone: "",
      parsedPhone: "",
    };
  }
  const phoneWithCode = `+${value?.country?.callingCode}${value?.phone}`;
  if (!isValidPhoneNumber(phoneWithCode, value?.country?.countryCode)) {
    return new Error();
  }
  return {
    country: value?.country,
    phone: value?.phone,
    parsedPhone: phoneWithCode,
  };
};

export const optionalPhoneObject = yup
  .object({
    phone: yup.string().nullable(),
    parsedPhone: yup.string().nullable(),
    country: yup.object({
      countryName: yup.string().nullable(),
      countryCode: yup.string().nullable(),
      callingCode: yup.string().nullable(),
    }),
  })
  .transform(optionalPhoneToString);
