import { QuotationModel } from "models/Subscription";
import { notToday, notTomorrowIfAlreadyPastMidday } from "utils/datetime";
import { PropertyProps, Statistics } from "./landlordPropertyTypes";

const getLastActiveContract = (quotation: QuotationModel[]) => {
  if (quotation.length > 1) {
    const lastQuotationsBeforeTodayFilter = quotation.filter(
      (q) => Date.parse(q.contract.init_date) < Date.now()
    );
    if (lastQuotationsBeforeTodayFilter.length) {
      const latestQuotation = lastQuotationsBeforeTodayFilter.reduce((r, a) =>
        Date.parse(r.contract.created_at) > Date.parse(a.contract.created_at)
          ? r
          : a
      );
      return latestQuotation.contract.id;
    }
    return null;
  }
  return quotation[0]?.contract?.id;
};

export function parseProperty(propertyData: any): PropertyProps {
  return {
    id: propertyData.id,
    uid: propertyData.uid,
    address: propertyData.address,
    streetNumber: propertyData.street_number,
    flatNumber: propertyData.numero_depto,
    commune: propertyData.comuna,
    country: propertyData.country,
    photos: propertyData.photos,
    forRent: propertyData.for_rental,
    rentStatus: propertyData.property_details[0].status,
    rentPrice: propertyData.property_details[0].valor,
    rentCurrency: propertyData.property_details[0].tipo_moneda,
    forSale: propertyData.for_sale,
    salesStatus: propertyData.sales_details.status,
    salesPrice: propertyData.sales_details.price,
    salesCurrency: propertyData.sales_details.currency,
    latitud: propertyData.property_details[0].latitud,
    longitud: propertyData.property_details[0].longitud,
    typeProperty: propertyData.type,
    region: propertyData.region,
    houmOwner: propertyData.houm_owner,
    bedrooms: propertyData.property_details[0].dormitorios,
    bathrooms: propertyData.property_details[0].banos,
    total_area: propertyData.property_details[0].m_terreno,
    built_area: propertyData.property_details[0].m_construidos,
    parking_spots: propertyData.property_details[0].estacionamientos,
    is_furnished: !!propertyData.property_details[0].amoblado,
    regionRev:
      propertyData.country === "Chile"
        ? propertyData.region === "Región Metropolitana"
          ? "metropolitana"
          : propertyData.region === "Bío Bío"
          ? "biobio"
          : "valparaiso"
        : propertyData.region,
    stratus:
      propertyData.country === "Colombia"
        ? propertyData.stratus || 4
        : undefined,
    locality:
      propertyData.country === "Colombia"
        ? propertyData.neighborhood.commune
        : undefined,
    property_age:
      propertyData.country === "Colombia" ? propertyData.antiquity : undefined,
    municipality:
      propertyData.country === "Mexico" ? propertyData.address : undefined,
    gc: propertyData.property_details[0].gc,
    disponibility:
      propertyData.property_details[0].first_available_handover_date,
    pets: propertyData.property_details[0].mascotas,
    orientation: propertyData.property_details[0].orientacion,
    warehouse: propertyData.property_details[0].bodega,
    otherDetails: {
      calefaccion: propertyData.property_details[0].calefaccion,
      en_suite: propertyData.property_details[0].en_suite,
      has_balcony: propertyData.property_details[0].has_balcony,
      has_houm_cleaning: propertyData.property_details[0].has_houm_cleaning,
      laundry_capacity: propertyData.property_details[0].laundry_capacity,
      furnishedKitchen: propertyData.property_details[0].kitchen_is_furnished,
      curtains: propertyData.property_details[0].curtain_hanger,
    },
    amenities: {
      piscina: propertyData.property_details[0].piscina,
      gym: propertyData.property_details[0].gym,
      has_roof_garden: propertyData.property_details[0].has_roof_garden,
    },
    video: propertyData.video_url,
    houmChecker: propertyData.homechecker
      ? {
          fullName: propertyData.homechecker?.name,
          email: propertyData.homechecker?.email,
          phone: propertyData.homechecker?.phone,
          photo: propertyData.homechecker?.photo,
        }
      : null,
    contractId:
      propertyData.property_details[0].status === "rented"
        ? getLastActiveContract(propertyData.quotations)
        : null,
    subscriptionId:
      propertyData.property_details[0].status === "rented"
        ? propertyData.quotations[0]?.id
        : null,
    account: propertyData.bank_account.length
      ? propertyData.bank_account
      : null,
    landlordId: propertyData.user?.id,
    propertyDetailsId: propertyData?.property_details[0]?.id,
    dptoNumber: propertyData?.numero_depto,
    schedules: propertyData?.schedules,
    plan: propertyData?.rental_details?.plan,
    readjustment: propertyData?.rental_details?.readjustment,
  };
}

interface IStatistics {
  pageviews: number;
  all_visits: number;
  cancelled_visits: number;
  cancelled_non_confirmation: number;
  cancelled_by_user: number;
  cancelled_by_houm: number;
  done_visits: number;
}

export function parseStatistics(data?: IStatistics): Statistics {
  return {
    allVisits: data?.all_visits ?? 0,
    cancelledByHoum: data?.cancelled_by_houm ?? 0,
    cancelledByUser: data?.cancelled_by_user ?? 0,
    cancelledNonConfirmation: data?.cancelled_non_confirmation ?? 0,
    cancelledVisits: data?.cancelled_visits ?? 0,
    doneVisits: data?.done_visits ?? 0,
    pageviews: data?.pageviews ?? 0,
  };
}

export const filterDays = (days) => {
  const filteredData = Object.entries(days || {})
    .filter(
      ([key, value]) => notToday(value) && notTomorrowIfAlreadyPastMidday(value)
    )
    .map(([key, value]) => key);
  return filteredData;
};
