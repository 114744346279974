import { Typography } from "@material-ui/core";
import { formatDate } from "apps/tenant/utils/utils";

const getMXConfirmationMessage = (contractDate: Date, leavingDate: Date) => {
  const formattedLeavingDate = formatDate(leavingDate);
  const formattedContractDate = formatDate(contractDate, true);

  return (
    <>
      <Typography component="p">
        Estas solicitando tu <strong>fecha de salida</strong> para el día{" "}
        <strong>{formattedLeavingDate}</strong> y la fecha de inicio de contrato
        y renovación es el día {formattedContractDate}.
      </Typography>
      <Typography component="p">
        Recuerda que se generará una multa equivalente de al menos 3 meses de
        renta en los siguientes casos:
      </Typography>
      <Typography component="ul">
        <li>
          Si tu fecha de salida es menor a la fecha de término de contrato
          original.
        </li>
      </Typography>
    </>
  );
};
export default getMXConfirmationMessage;
