export const classes = {
  memberCardRoot:
    "flex flex-col gap-4 self-stretch items-center max-w-full md:max-w-[300px] w-full h-[400px] p-6 rounded-3xl bg-[#E8ECEE]/50 hover:bg-[#001631] hover:text-white group",
  memberCardTitle: "text-h24 font-['Inter'] text-center",
  memberFillInfo: "flex flex-col self-stretch h-full",
  memberCardInfoContainer:
    "flex flex-col self-stretch justify-between h-full text-center",
  memberCardInfoText: "text-p16",
  memberCardDisclaimerText: "text-p12 text-[#515B60] group-hover:text-white",
  memberCardButton:
    "group-hover:text-black-100 group-hover:bg-white group-hover:rounded-full group-hover:px-6",
};
