import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Box, Typography } from "@material-ui/core";

import useInspectionDetailsExpandStyles from "./InspectionDetailsExpandStyles";

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

const InspectionDetailsLink = ({ isOpen, onClick }: Props) => {
  const classes = useInspectionDetailsExpandStyles();

  return (
    <Box
      className={classes.link}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <Typography className={classes.actionField}>
        {isOpen ? "Ver menos" : "Ver más"}
      </Typography>
      {isOpen ? (
        <ArrowUpwardIcon className={classes.icon} />
      ) : (
        <ArrowDownwardIcon className={classes.icon} />
      )}
    </Box>
  );
};

export default InspectionDetailsLink;
