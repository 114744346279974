import { makeStyles, Theme } from "@material-ui/core";

const useLeavingDateStepStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export default useLeavingDateStepStyles;
