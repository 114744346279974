import { useFormContext } from "react-hook-form";

import { IoCheckmark } from "@houm-com/ui-icons";

import SelectableCard from "apps/payments/components/SelectableCard";
import InfoTag from "apps/payments/components/InfoTag";
import { IMethod } from "apps/payments/models/Payments";
import { Locale } from "models/Countries";

import { classes } from "./PaymentSelectorStyles";
import { parsePaymentMethods } from "../../utils/parsers";

interface Props {
  paymentMethods: IMethod[];
  country: Locale;
}

const PaymentSelector = ({ paymentMethods, country }: Props) => {
  const { register, watch } = useFormContext();

  const getMethodData = (method: string) => {
    const data = parsePaymentMethods[country][method.toUpperCase()];
    return data;
  };

  const selectedMethod = watch("paymentOption");

  return (
    <div className={classes.container}>
      {paymentMethods.map((method) => (
        <SelectableCard
          key={method.method}
          id={method.method}
          className={classes.selectableCard}
          {...register("paymentOption")}
        >
          <div className={classes.infoContainer}>
            <div className={classes.iconContainer}>
              {getMethodData(method.method).icon}
            </div>
            <div className={classes.textContainer}>
              <p className={classes.title}>
                {getMethodData(method.method).title}
              </p>
              {getMethodData(method.method)?.subtitle && (
                <p className={classes.subtitle}>
                  {getMethodData(method.method).subtitle}
                </p>
              )}
            </div>
          </div>
          {selectedMethod === method.method && (
            <div className={classes.checkIconContainer}>
              <IoCheckmark size={24} />
            </div>
          )}
          {method.methodCommission !== 0 && (
            <InfoTag
              information="Aplican comisiones"
              className={classes.infoTag}
            />
          )}
        </SelectableCard>
      ))}
    </div>
  );
};

export default PaymentSelector;
