import axios from "axios";
import { TOKY_API_URL, TOKY_APP_KEY, TOKY_APP_ID } from "../env";

export default {
  createHttp() {
    const tokyApiUrl = TOKY_API_URL;
    const headers = {
      "X-App-Key": TOKY_APP_KEY,
    };

    return axios.create({ baseURL: tokyApiUrl, headers });
  },

  getAccessTokens(agentId: string) {
    const sendData = JSON.stringify({
      scope: "dialer",
      agent_id: agentId,
      app_id: TOKY_APP_ID,
      grant_type: "app_id",
    });

    return this.createHttp().post(`/v1/access_token`, sendData);
  },

  refreshTokens(refreshToken: string) {
    const sendData = new URLSearchParams({
      token: refreshToken,
      grant_type: "refresh_token",
    });

    return this.createHttp().post(`/v1/access_token/refresh`, sendData);
  },

  getDidsForAgent(agentId: string, accessToken: string) {
    const tokyApiUrl = "https://api.toky.co";
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    return axios
      .create({ baseURL: tokyApiUrl, headers })
      .get(`/v1/sdk/agents/dids?agent_id=${agentId}`);
  },
};
