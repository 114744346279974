import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import useUser from "apps/tenant/hooks/useUser";
import { useUser as useContextUser } from "context/userContext";

const useAuthDropdownMenu = () => {
  const { user, loginWithRedirect } = useContextUser();
  const { user: userData, isLoading } = useUser();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);
  const handleClose = () => setOpen(false);

  // TO DO: make this a utilitarian function
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target as HTMLElement)
      ) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [buttonRef, handleClose]);

  const handleClick = () => setOpen(!open);

  return {
    buttonRef,
    handleClick,
    loading: isLoading,
    loginWithRedirect,
    open,
    pathname: location.pathname,
    user,
    userName: `${userData?.name} ${userData?.lastName}`,
  };
};

export default useAuthDropdownMenu;
