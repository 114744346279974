import { useTranslation } from "react-i18next";

import {
  IoDocumentOutline as DocumentIcon,
  IoPersonOutline as PersonIcon,
  IoCashOutline as BillIcon,
  IoSpeedometerOutline as ScoreIcon,
  IoArrowForward as UpRightArrowIcon,
} from "@houm-com/ui-icons";

import { Box, Typography } from "@material-ui/core";

import RentRequirementItem from "./components/RentRequirementItem";
import useRentRequirementsStyles from "./RentRequirementsStyles";

const RentRequirements = () => {
  const classes = useRentRequirementsStyles();
  const { t } = useTranslation("tenant.dashboard");

  const handleMoreInfoClick = () =>
    window.open(t("requirements.more-info-link"));

  return (
    <Box className={classes.rentRequirementsRoot}>
      <Typography variant="h1" className={classes.rentRequirementsTitle}>
        Requisitos para arrendar
      </Typography>
      <RentRequirementItem
        icon={<BillIcon />}
        translation={t("income-requirement", { returnObjects: true })}
      />
      <RentRequirementItem
        icon={<PersonIcon />}
        translation={t("identification-requirement", { returnObjects: true })}
      />
      <RentRequirementItem
        icon={<DocumentIcon />}
        translation={t("work-requirement", { returnObjects: true })}
      />
      <RentRequirementItem
        icon={<ScoreIcon />}
        translation={t("buro-requirement", { returnObjects: true })}
      />
      <Typography onClick={handleMoreInfoClick} className={classes.more}>
        Conocer más detalles
        <UpRightArrowIcon className={classes.arrow} width={20} height={20} />
      </Typography>
    </Box>
  );
};

export default RentRequirements;
