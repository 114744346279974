export enum CommonExpensesCompanies {
  KASTOR = "kastor",
  COMUNIDAD_FELIZ = "comunidad_feliz",
  OTHER = "otro",
}

export interface BSCompanyForm {
  waterCompanyName: string;
  energyCompanyName: string;
  gasCompanyName: string;
  waterClientNumber: string;
  energyClientNumber: string;
  gasClientNumber: string;
}

export interface CECompanyForm {
  companyName: CommonExpensesCompanies;
  kastorClientNumber: string;
  communityName: string;
  unitNumber: string;
  receiptNumber: string;
  administrationEmail: string;
}
