import { ReactNode } from "react";

import Spinner from "Components/Shared/partials/Spinner";
import Navbar from "apps/ui/Navbar";
import { NavbarProvider } from "apps/ui/Navbar/context/navbarContext";

import useUser from "../hooks/useUser";
import useStyles from "./TenantLayoutStyles";
import { useMarket } from "../context/MarketContext";

interface Props {
  children: ReactNode;
}

const TenantLayout = ({ children }: Props) => {
  const classes = useStyles();
  const { user } = useUser();
  const { market } = useMarket();

  if (!user) return <Spinner />;

  return (
    <NavbarProvider>
      <Navbar usingAs="tenant" user={user} market={market} />
      <div className={classes.tenantContentRoot}>{children}</div>
    </NavbarProvider>
  );
};

export default TenantLayout;
