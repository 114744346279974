import { Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";

import Spinner from "Components/Shared/partials/Spinner";
import useServicesRequests from "apps/tenant/hooks/useServicesRequests";
import { useTranslation } from "react-i18next";
import useSelectionStepStyles from "./SelectionStepStyles";
import ServicesRequestForm from "../ServicesRequestForm";
import useServicesRequestAction from "../../../hooks/useServicesRequestAction";
import isButtonDisabled from "./utils";

interface Props {
  handleBack: () => void;
}

const SelectionStep = ({ handleBack }: Props) => {
  const classes = useSelectionStepStyles();
  const history = useHistory();
  const { refetch } = useServicesRequests();
  const onSuccessHandler = () => {
    refetch();
    history.push("/tenant/requests");
  };
  const { servicesRequest, setServicesRequest, submitData, isLoading } =
    useServicesRequestAction({
      onSuccessHandler,
    });
  const { t } = useTranslation(["tenant.regularization.info"]);

  if (isLoading) return <Spinner />;

  return (
    <>
      <Typography className={classes.title}>{t("title")}</Typography>
      <Typography className={classes.description}>
        {t("description.first")}
      </Typography>
      <Typography className={classes.description}>
        {t("description.second")}
      </Typography>
      <ServicesRequestForm
        servicesRequest={servicesRequest}
        setServicesRequest={setServicesRequest}
      />

      <Box className={classes.actionsContainer}>
        <DesignSystemButton
          variant="tertiaryB"
          size="small"
          label="Cancelar"
          onClick={handleBack}
        />
        <DesignSystemButton
          disabled={isButtonDisabled(servicesRequest)}
          variant="primary"
          size="small"
          label="Siguiente"
          onClick={() => submitData()}
        />
      </Box>
    </>
  );
};

export default SelectionStep;
