import { makeStyles, Theme } from "@material-ui/core";

const useInitialStepStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      padding: "unset",
      marginTop: "unset",
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(5),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },

  stepsContainer: {
    marginLeft: theme.spacing(2.5),
  },
  stepTitle: {
    fontWeight: 700,
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1.5),
      fontSize: theme.typography.pxToRem(16),
    },
  },
  stepDescription: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  stepDescriptionConcerns: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  confirmText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  ctaContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    gap: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export default useInitialStepStyles;
