import { INavbarItem } from "apps/ui/Navbar/utils/types";
import { produceKey } from "apps/ui/Navbar/utils/utils";

import useDesktopSubmenuStyles from "./DesktopSubmenuStyles";
import NavbarMenuItem from "../NavbarMenuItem/NavbarMenuItem";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  items: INavbarItem[];
  open: boolean;
}

const DesktopSubmenu = ({ items, open }: Props) => {
  const classes = useDesktopSubmenuStyles();

  return (
    <AnimatePresence>
      <motion.div
        layout
        className={classes.collapse}
        animate={{
          opacity: open ? 1 : 0,
          height: open ? "auto" : 0,
        }}
        transition={{
          opacity: { duration: 0.05 },
          height: { duration: 0.15, ease: "easeInOut", type: "tween" },
        }}
      >
        {items.map((menuItem) => (
          <NavbarMenuItem key={produceKey(menuItem)} {...menuItem} />
        ))}
      </motion.div>
    </AnimatePresence>
  );
};

export default DesktopSubmenu;
