import { Button, Typography } from "@material-ui/core";
import { IoArrowBack, IoArrowForward } from "@houm-com/ui-icons";

import useButtonLinkStyles from "./ButtonLinkStyles";

interface Props {
  buttonLabel: string;
  onViewInfoClick: () => void;
  iconPosition?: "start" | "end";
}

const ButtonLink = ({
  buttonLabel,
  onViewInfoClick,
  iconPosition = "end",
}: Props) => {
  const classes = useButtonLinkStyles();

  return (
    <Button
      classes={{ root: classes.button, label: classes.buttonLabel }}
      onClick={() => onViewInfoClick()}
    >
      {iconPosition === "start" && (
        <IoArrowBack width={20} height={30} className={classes.arrowIcon} />
      )}
      <Typography className={classes.linkText}>{buttonLabel}</Typography>
      {iconPosition === "end" && (
        <IoArrowForward width={20} height={30} className={classes.arrowIcon} />
      )}
    </Button>
  );
};

export default ButtonLink;
