import { CountryCode, parsePhoneNumber } from "libphonenumber-js";

export const phoneParser = (phone: string, country?: CountryCode): string => {
  let parsedPhoneNumber;
  if (!phone) return "";
  if (country) {
    parsedPhoneNumber = parsePhoneNumber(phone, country);
  } else if (phone[0] === "+") {
    parsedPhoneNumber = parsePhoneNumber(phone);
  } else {
    return phone;
  }
  return parsedPhoneNumber.formatInternational();
};
