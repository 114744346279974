import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  navbarHeight: number;
}

const APP_BAR_HEIGHT = 104 / 8;

const usePaymentDetailStyles = makeStyles<Theme, Props>((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginBottom: theme.spacing(APP_BAR_HEIGHT),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
  },
  content: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5),
    },
  },
  gridContainer: {
    [theme.breakpoints.down("xs")]: {
      gap: theme.spacing(4),
    },
  },
  loadingContainer: {
    display: "flex",
    minHeight: ({ navbarHeight }) => `calc(100vh - ${navbarHeight}px)`,
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      minHeight: ({ navbarHeight }) => `calc(100vh - ${navbarHeight}px)`,
    },
  },
  appBar: {
    top: "auto",
    bottom: 0,
    backgroundColor: "#fff",
    padding: theme.spacing(2),
  },
}));

export default usePaymentDetailStyles;
