import { Typography } from "@material-ui/core";

import useErrorTextStyles from "./ErrorTextStyles";

const ErrorText = () => {
  const classes = useErrorTextStyles();
  return (
    <>
      <Typography className={classes.title}>
        ¡Oh no! Al parecer ocurrió un error inesperado
      </Typography>
      <Typography className={classes.subtitle}>
        Recarga la página para volver a intentarlo
      </Typography>
    </>
  );
};

export default ErrorText;
