import { Trans, useTranslation } from "react-i18next";

import { Box, Typography } from "@material-ui/core";

import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji";

import useSentLinkStyles from "./SentLinkStyles";

interface Props {
  email: string;
}

const SentLink = ({ email }: Props) => {
  const classes = useSentLinkStyles();
  const { t } = useTranslation("account.profile");
  const handleClick = () => {};

  return (
    <Box className={classes.sentLinkRoot}>
      <Box className={classes.titleContainer}>
        <Emoji emojiIcon="✉️" ariaLabel="mail icon" />
        <Typography className={classes.title}>Link enviado</Typography>
      </Box>
      <Trans
        i18nKey={t("link_sent")}
        values={{ email }}
        components={{
          1: <strong />,
        }}
      />
      <span className={classes.link} onClick={handleClick}>
        reenviar el link
      </span>
    </Box>
  );
};

export default SentLink;
