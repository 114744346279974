import { makeStyles, Theme } from "@material-ui/core";

const useMarketplaceLinkStyles = makeStyles((theme: Theme) => ({
  ctaButton: {
    fontSize: theme.typography.pxToRem(14),
  },
  icon: {
    transform: "rotate(-45deg)",
  },
}));

export default useMarketplaceLinkStyles;
