import { makeStyles, Theme } from "@material-ui/core/styles";

interface AdsBannerStyles {
  img: string;
  hasImgGradient?: boolean;
}

const imgGradient =
  "linear-gradient(180deg, rgba(50,50,50,1) 0%, rgba(255,255,255,0) 50%)";

const useAdsBannerStyles = makeStyles<Theme, AdsBannerStyles>(
  (theme: Theme) => ({
    cardContent: {
      position: "relative",
      height: "324px",
      width: "244px",
      padding: theme.spacing(2, 1.5),
      color: "#FFFFFF",
      borderRadius: "1rem",
      overflow: "hidden",
      zIndex: 1,
      textAlign: "left",
      cursor: "pointer",
      "&&&:before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage: (props) =>
          props.hasImgGradient
            ? `${imgGradient}, url(${props?.img})`
            : `url(${props?.img})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        filter: "brightness(100%)",
        zIndex: -1,
      },
      [theme.breakpoints.down("md")]: {
        borderRadius: "0.5rem",
      },
    },
    small: {
      height: "80px",
      width: "100%",
    },
    labelTextContent: {
      color: "white",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "26px",
      letterSpacing: "0em",
      backgroundColor: theme.palette.primary.main,
      borderRadius: 8,
      padding: theme.spacing(0.3, 1),
    },
    mainText: {
      color: "white",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "26px",
      letterSpacing: "0em",
    },
    adsBannerButton: {
      position: "absolute",
      bottom: "1rem",
      right: "1rem",

      width: "fit-content",
      padding: theme.spacing(0.6, 1.6),
      borderRadius: theme.spacing(2),
      textTransform: "none",

      backgroundColor: "#FFFFFF",
      color: "#263238",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "22px",

      "&:hover": {
        backgroundColor: "#EFF2F3",
      },
    },
    smallButton: {
      position: "absolute",
      bottom: "13px",
      right: "14px",
      padding: theme.spacing(0.38),

      width: "32px",
      height: "32px",
      borderRadius: theme.spacing(2),
      backgroundColor: "#FFFFFF",
    },
  })
);

export default useAdsBannerStyles;
