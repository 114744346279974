import { Typography } from "@material-ui/core";

import useErrorTextStyles from "./ErrorTextStyles";
import { useTranslation } from "react-i18next";

const ErrorText = () => {
  const classes = useErrorTextStyles();
  const { t } = useTranslation(["shared"]);

  return (
    <>
      <Typography className={classes.title}>
        {t("no-roles-error-title")}
      </Typography>
      <Typography className={classes.subtitle}>
        {t("no-roles-error-subtitle")}
      </Typography>
    </>
  );
};

export default ErrorText;
