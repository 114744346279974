import clsx from "clsx";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { Box, Drawer, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import { IoChevronBackOutline as ArrowLeft } from "@houm-com/ui-icons";

import useUser from "apps/tenant/hooks/useUser";
import logo from "assets/images/houmLogo.svg";
import basicLogo from "assets/images/logo.png";
import Spinner from "Components/Shared/partials/Spinner";

import AuthMobileMenu from "./components/AuthMobileMenu";
import MainMenu from "../MainMenu";
import AuthLogoutButton from "./components/AuthLogoutButton";
import useMobileNavbarStyles from "./MobileNavbarStyles";
import { useNavbar } from "../../context/navbarContext";
import Header from "./components/Header";

const MobileNavbar = () => {
  const { backUrl } = useNavbar();
  const history = useHistory();
  const classes = useMobileNavbarStyles({ hasBackButton: !!backUrl });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { user, isLoading } = useUser();
  const handleBack = () => {
    if (backUrl === "default") history.goBack();
    else history.push(backUrl);
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      <Box className={classes.root}>
        {!!backUrl && (
          <Typography onClick={handleBack} className={classes.backButton}>
            <ArrowLeft />
            Volver
          </Typography>
        )}
        <Link
          to="/"
          title="Houm"
          className={clsx({ [classes.logoContainer]: !!backUrl })}
        >
          <img
            className={classes.navLogo}
            src={backUrl ? basicLogo : logo}
            alt="Houm"
          />
        </Link>

        <MenuIcon
          className={classes.menuIcon}
          onClick={() => setIsDrawerOpen(true)}
        />
      </Box>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        classes={{ paper: classes.navbarDrawerPaper, root: classes.drawerRoot }}
      >
        <Box className={classes.drawer}>
          <Header
            name={`${user?.name} ${user?.lastName}`}
            handleClose={() => setIsDrawerOpen(false)}
          />

          <Box className={classes.contentUser}>
            <AuthMobileMenu />
          </Box>

          <MainMenu />

          <Box className={classes.bottomContainer}>
            <AuthLogoutButton />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default MobileNavbar;
