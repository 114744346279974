import { useState } from "react";
import { useForm } from "react-hook-form";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "apps/ui/formComponents/Input";
import { useUser } from "context/userContext";
import Spinner from "Components/Shared/partials/Spinner";

import { IPasswordForm } from "../../utils/types";
import ProfileCard from "../ProfileCard";
import ButtonsSection from "../ButtonsSection";
import usePasswordChange from "./hooks/usePasswordChange";
import validationSchema from "./utils/validationSchema";
import PasswordRequirements from "./components/PasswordRequirements";
import usePasswordFormStyles from "./PasswordFormStyles";

const PasswordForm = () => {
  const { user, isLoading } = useUser();
  const [edit, setEdit] = useState(true);
  const classes = usePasswordFormStyles();
  const { t } = useTranslation("account.profile");

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      oldPassword: "",
      password: "",
      passwordConfirmation: "",
    },
  });
  const watchPassword = watch("password", "");
  const watchPasswordConfirmation = watch("passwordConfirmation", "");
  const { mutate, submitting } = usePasswordChange();
  const onSubmit = (data: IPasswordForm) => mutate(data);

  if (isLoading) return <Spinner />;

  return (
    <ProfileCard text={!isMobile && t("password_text")}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          id="oldPassword"
          label="Contraseña anterior"
          required
          type="password"
          error={!!errors.oldPassword}
          message={errors.oldPassword?.message}
          classNames={{ root: classes.firstInput }}
          {...register("oldPassword")}
        />
        <Input
          id="password"
          label="Contraseña nueva"
          required
          type="password"
          error={!!errors.password}
          message={errors.password?.message}
          {...register("password")}
        />
        <PasswordRequirements password={watchPassword} email={user?.email} />
        <Input
          id="passwordConfirmation"
          label="Escribe de nuevo la contraseña nueva"
          required
          type="password"
          error={!!errors.passwordConfirmation}
          message={errors.passwordConfirmation?.message}
          {...register("passwordConfirmation")}
        />
        <ButtonsSection
          edit={edit}
          setEdit={setEdit}
          submitting={submitting}
          disabled={
            !watchPassword ||
            !watchPasswordConfirmation ||
            watchPassword !== watchPasswordConfirmation
          }
        />
      </form>
    </ProfileCard>
  );
};

export default PasswordForm;
