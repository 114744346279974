import { isBrowser } from "react-device-detect";

import { ContractModel } from "models/Contract";

import useSubscriptionPlanSelectedStyles from "./SubscriptionPlanSelectedStyles";
import SubscriptionPlanCard from "../SubscriptionPlanCard";
import PlanInfo from "../../models";

interface Props {
  isCustom?: boolean;
  plan?: PlanInfo;
  contract?: ContractModel;
}

export default function SubscriptionPlanSelected({
  isCustom = false,
  plan = null,
}: Props) {
  const classes = useSubscriptionPlanSelectedStyles();

  return (
    <div className={classes.componentContainer}>
      <div className={classes.cardsContainer}>
        <SubscriptionPlanCard
          key={plan?.id}
          plan={plan}
          isHorizontal={isBrowser}
          isCustom={isCustom}
        />
      </div>
    </div>
  );
}
