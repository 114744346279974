import { PersonsRole, PersonStatus } from "apps/lead/models/Application";
import { CountryUpperCase } from "models/Countries";
import { PhoneInput } from "models/PhoneInput";

export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_EVALUATION = "SET_EVALUATION";
export const SET_APPLICANT = "SET_APPLICANT";
export const SET_CODEBTOR = "SET_CODEBTOR";
export const SET_LOADING = "SET_LOADING";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_EMAILS = "SET_EMAILS";
export const SET_STEPS = "SET_STEPS";

export interface Files {
  lastModified: number;
  lastModifiedDate?: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath?: string;
}

export type WorkerType = "dependent" | "independent" | "pensioner";

export interface Applicant {
  id?: number;
  country: CountryUpperCase;
  email: string;
  document: string;
  documentType: number;
  lastName: string;
  name: string;
  phone: string | PhoneInput;
  secondLastName: string;
  workerType: WorkerType;
  status?: PersonStatus;
  role?: PersonsRole;
  files: {
    identityCard: Files[];
    contract?: Files[];
    salarySettlements?: Files[];
    quotes?: Files[];
    f22?: Files[];
    pensionSettlements?: Files[];
  };
  occupation?: string;
}

export const defaultApplicantValue = {
  id: null,
  country: null,
  email: "",
  document: "",
  documentType: null,
  lastName: "",
  name: "",
  phone: "",
  secondLastName: "",
  workerType: "dependent" as WorkerType,
  files: {
    identityCard: [],
  },
};

export interface State {
  loading: boolean;
  codebtor?: Partial<Applicant>;
  activeStep: number;
  steps: number;
  country?: CountryUpperCase;
  emails: string[];
  evaluation?: {
    evaluationId: number;
    persons: Partial<Applicant>[];
  };
  applicant?: {
    person: Partial<Applicant>;
    role: string;
  };
}

interface setLoading {
  type: typeof SET_LOADING;
  loading: boolean;
}

interface setCountry {
  type: typeof SET_COUNTRY;
  country: CountryUpperCase;
}

interface setCodebtor {
  type: typeof SET_CODEBTOR;
  codebtor: Partial<Applicant>;
}

interface setActiveStep {
  type: typeof SET_ACTIVE_STEP;
  activeStep: number;
}

interface setSteps {
  type: typeof SET_STEPS;
  steps: number;
}

interface setEmails {
  type: typeof SET_EMAILS;
  emails: string[];
}

interface setEvaluation {
  type: typeof SET_EVALUATION;
  evaluation: {
    evaluationId: number;
    persons: Partial<Applicant>[];
  };
}

interface setEvaluationSelected {
  type: typeof SET_APPLICANT;
  applicant: {
    person: Partial<Applicant>;
    role: string;
  };
}

export interface ApplicantFormated {
  email: string;
  document: string;
  documentType: number;
  lastName: string;
  name: string;
  phone: string;
  secondLastName: string;
}

export type Action =
  | setLoading
  | setCodebtor
  | setActiveStep
  | setSteps
  | setCountry
  | setEmails
  | setEvaluation
  | setEvaluationSelected;

export type Dispatch = (action: Action) => void;
