import { useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useCodebtor } from "apps/lead/context/codebtorContext/CodebtorContext";
import {
  setSteps,
  setCountry,
  setEmails,
  setActiveStep,
} from "apps/lead/context/codebtorContext/CodebtorActions";
import { CountryUpperCase } from "models/Countries";

import getCodebtorFormStepsChile from "../components/Steps";

interface StateType {
  data: {
    country: CountryUpperCase;
    emails: string[];
  };
}

const useCodebtorForm = () => {
  const history = useHistory();
  const { state: stateLocation } = useLocation<StateType>();
  const { state, dispatch } = useCodebtor();
  const { activeStep } = state;

  useEffect(() => {
    if (stateLocation?.data) {
      dispatch(setCountry(stateLocation.data.country));
      dispatch(setEmails(stateLocation.data.emails));
    } else {
      history.push("/tenant");
    }
  }, [stateLocation]);

  const formSteps = useMemo(() => getCodebtorFormStepsChile(), []);

  const CurrentStep = useMemo(() => formSteps[activeStep], [activeStep]);

  useEffect(() => {
    dispatch(setSteps(formSteps.length));
  }, []);

  const handleBack = useCallback(
    () =>
      activeStep === 0
        ? history.push("/tenant")
        : dispatch(setActiveStep(activeStep - 1)),
    [activeStep]
  );

  return {
    CurrentStep,
    handleBack,
  };
};

export default useCodebtorForm;
