import { makeStyles } from "@material-ui/core";

const useInspectionReportCommentsStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    padding: theme.spacing(4, 2, 5, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5, 5, 6, 5),
    },
  },
  headerSection: {
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "column",
  },
  title: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(1),
      fontSize: theme.typography.pxToRem(28),
    },
  },
  downloadReportButton: {
    width: "fit-content",
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(5),
    padding: theme.spacing(0.5, 3),
    transition: "background-color 0.3s ease-in",
    "&:hover": {
      backgroundColor: "#FF6349",
    },
  },
  downloadReportButtonText: {
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    "& svg": {
      marginLeft: theme.spacing(0.5),
    },
  },
  highlightedBox: {
    width: "100%",
    display: "flex",
    gap: theme.spacing(1),
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    backgroundColor: "#F3F7F9",
    borderRadius: theme.spacing(2),
  },
  highlightedBoxTitle: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
  highlightedBoxText: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
  highlightedBoxLink: {
    color: "#FF452B",
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    transition: "filter 0.3s ease-in",
    "& svg": {
      marginLeft: theme.spacing(1),
      fontSize: theme.typography.pxToRem(14),
    },
    "&:hover": {
      filter: "brightness(0.8)",
    },
  },
  addCommentButton: {
    textTransform: "none",
    backgroundColor: "#FFFFFF",
    width: "fit-content",
    border: "1px solid #263238",
    borderRadius: theme.spacing(5),
    padding: theme.spacing(0.5, 3),
    transition: "filter 0.3s ease-in",
    "&:hover": {
      filter: "brightness(0.8)",
    },
    "&:disabled": {
      pointerEvents: "hover",
      cursor: "not-allowed",
      opacity: 0.5,
    },
  },
  addCommentButtonText: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    "& svg": {
      marginLeft: theme.spacing(0.5),
    },
  },
  noCommentsGuideline: {
    width: "100%",
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    backgroundColor: "#FFFFFF",
    border: "1px solid #EFF2F3",
    borderRadius: theme.spacing(1),
  },
  noCommentsGuidelineIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#DAE4E8",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    color: "#46788C",
    width: 41,
    height: 41,
  },
  noCommentsGuidelinesText: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
  noCommentsGuidelinesWarning: {
    width: "100%",
    padding: theme.spacing(1),
    backgroundColor: "#F9E8E5",
    border: "1px solid #900000",
    borderRadius: theme.spacing(1),
  },
  noCommentsGuidelinesWarningText: {
    color: "#900000",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
  },
  addCommentBox: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(2, 0),
  },
  addCommentTextField: {
    backgroundColor: "#EFF2F3",
    width: "100%",
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
  },
  addCommentBoxActions: {
    padding: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: theme.spacing(2),
  },
  commentContainer: {
    width: "100%",
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    backgroundColor: "#FFFFFF",
    border: "1px solid #EFF2F3",
    borderRadius: theme.spacing(1),
    overflow: "hidden",
  },
  commentDate: {
    fontStyle: "normal",
    fontWeight: 400,
    color: "#7D8488",
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
  commentText: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
  commentPhotosContainerLabel: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
  commentPhotosContainer: {
    width: "100%",
    overflow: "scroll",
    scrollBehavior: "smooth",
    animationDuration: "1s",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  commentPhotosContainerScrollWrapper: {
    width: "fit-content",
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "row",
    overflowX: "auto",
    overflowY: "hidden",
  },
  commentPhotoThumbnail: {
    height: 80,
    width: 80,
    objectFit: "cover",
    backgroundColor: "#EFF2F3",
    borderRadius: theme.spacing(1),
  },
}));

export default useInspectionReportCommentsStyles;
