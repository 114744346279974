import { useEffect, useState } from "react";

import Map from "Components/Admin/UIComponents/atoms/Map";
import { isNil } from "lodash";
import { FormattedAddress } from "models/Address";
import { useFormContext } from "react-hook-form";

import { formatAddress } from "utils/createProperty";
import Geocoder from "utils/geoCoder";

import { Locale } from "models/Countries";
import useStyles from "./MapStyles";

interface Props {
  defaultLat: number;
  defaultLng: number;
  locale: Locale;
}

const MapContainer = ({ defaultLat, defaultLng, locale }: Props) => {
  const classes = useStyles();
  const { getValues, reset, watch } = useFormContext();
  const [localFormattedAddress, setLocalFormattedAddress] =
    useState<FormattedAddress | null>(null);
  const lat = localFormattedAddress?.lat ?? defaultLat;
  const lng = localFormattedAddress?.lng ?? defaultLng;

  const address = watch("address");

  const handleAddressChange = async () => {
    if (!(typeof address === "object") || isNil(address)) return;
    const parsedAddress = formatAddress(address);
    setLocalFormattedAddress(parsedAddress);
  };

  useEffect(() => {
    handleAddressChange();
  }, [address]);

  // Get geodata of market position
  const onChangeMarker = async (latitude: number, longitude: number) => {
    const geodata = await Geocoder.fromLatLng(locale, latitude, longitude);
    if (geodata) {
      const fa = formatAddress(geodata);
      setLocalFormattedAddress(fa);
      reset({ ...getValues(), address: geodata });
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.mapMainContainer}>
        <div className={classes.mapContainer}>
          <Map
            value={[lng, lat]}
            defaultValue={[lng, lat]}
            zoom={16}
            hasMarker
            onDrag={([latitude, longitude]) =>
              onChangeMarker(latitude, longitude)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MapContainer;
