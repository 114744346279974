import { PropertyDetailsFormProps } from "apps/landlord/components/FormPropertyDetails/utils/types";

import { IPropertyDetailsService } from "./types";

const parsePropertyDetailsService = (
  data: PropertyDetailsFormProps
): IPropertyDetailsService => ({
  year_built: data.yearBuilt,
  property_details: [
    {
      m_construidos: data.mBuilt,
      m_terreno: data.mSurface,
      dormitorios: data.bedrooms,
      banos: data.bathrooms,
      estacionamientos: data.parkingQuantity,
      parking_numbers:
        Number(data.parkingQuantity) > 0 ? data.parkingNumbers : "",
      bodega: data.warehouse === "yes",
      warehouse_number: data.warehouse === "yes" ? data.warehouseNumber : "",
    },
  ],
});

export default parsePropertyDetailsService;
