import {
  IoCardOutline as CardIcon,
  IoSwapHorizontalOutline as ArrowsIcon,
  IoCashOutline as CashIcon,
} from "@houm-com/ui-icons";
import AmexIcon from "assets/icons/AmexIcon";
import LocationPinIcon from "assets/icons/LocationPinIcon";
import MasterCardIcon from "assets/icons/MasterCardIcon";
import VisaIcon from "assets/icons/VisaIcon";

export const parsePaymentMethods = {
  co: {
    VISA: {
      title: "Tarjeta de crédito Visa",
      icon: <VisaIcon />,
    },
    AMEX: {
      title: "Tarjeta de crédito Amex",
      icon: <AmexIcon />,
    },
    MASTERCARD: {
      title: "Tarjeta de crédito Mastecard",
      icon: <MasterCardIcon />,
    },
    EFECTIVO: {
      title: "Pago en efectivo",
      icon: <CashIcon className="w-4 h-4 text-[#9E88D3]" />,
    },
    DEBITO: {
      title: "Pago con cuentas débito ahorro y corriente (PSE)",
      icon: <ArrowsIcon className="w-4 h-4 text-[#9E88D3]" />,
    },
  },
  mx: {
    CARD: {
      title: "Tarjetas bancarias",
      subtitle: "Mastercard, Visa y Amex",
      icon: <CardIcon className="w-4 h-4 text-[#9E88D3]" />,
    },
    BANK_ACCOUNT: {
      title: "Pago con transferencia bancaria",
      subtitle: "Transferencia bancaria",
      icon: <CardIcon className="w-4 h-4 text-[#9E88D3]" />,
    },
    BANK: {
      title: "Pago con transferencia bancaria",
      subtitle: "Transferencia bancaria",
      icon: <CardIcon className="w-4 h-4 text-[#9E88D3]" />,
    },
    STORE: {
      title: "Pago en tienda",
      subtitle: "Puedes pagar en OXXO, Farmacias del Ahorro, Walmart, etc.",
      icon: <LocationPinIcon className="w-4 h-4 text-[#9E88D3]" />,
    },
  },
};
