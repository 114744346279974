import { makeStyles, Theme } from "@material-ui/core/styles";

const useFilePreviewStyles = makeStyles((theme: Theme) => ({
  close: {
    position: "absolute",
    top: -10,
    right: -10,
    zIndex: 100,
    borderRadius: "50%",
    cursor: "pointer",
    padding: theme.spacing(0.8),
    backgroundColor: theme.palette.grey[900],
  },
  thumb: {
    display: "inline-flex",
    width: 80,
    height: 80,
    boxSizing: "border-box",
    borderRadius: 10,
  },
  thumbInner: {
    display: "flex",
    position: "relative",
  },
  icon: {
    fill: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(16),
  },
  image: {
    width: 80,
    height: 80,
    objectFit: "cover",
    borderRadius: 10,
  },
}));

export default useFilePreviewStyles;
