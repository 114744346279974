import { useEffect } from "react";
import { isMobile } from "react-device-detect";

import { useNavbar } from "apps/ui/Navbar/context/navbarContext";

import ContentWrapper from "../../layout/components/ContentWrapper";
import ProfileHeader from "./components/ProfileHeader";
import MobileMenu from "./components/MobileMenu";
import DesktopMenu from "./components/DesktopMenu";
import useProfileStyles from "./ProfileStyles";

const Profile = () => {
  const classes = useProfileStyles();
  const { setBackUrl } = useNavbar();

  useEffect(() => {
    if (isMobile) setBackUrl("default");
  }, [setBackUrl]);

  return (
    <ContentWrapper className={classes.profileContentWrapper}>
      {isMobile && <MobileMenu />}
      {!isMobile && (
        <>
          <ProfileHeader />
          <DesktopMenu />
        </>
      )}
    </ContentWrapper>
  );
};

export default Profile;
