import { State, PropertyData, DocumentOption } from "./loiFormTypes";

// PARSERS fetch
export function parsePropertyData(propertyData: any): Partial<PropertyData> {
  return {
    uid: propertyData.uid,
    id: propertyData.id,
    address: propertyData.address,
    commune: propertyData.comuna,
    country: propertyData.country,
  };
}

export function parseDocumentTypes(fetchedDocuments: any): DocumentOption[] {
  return fetchedDocuments
    .filter((doc) => doc.identifier !== "RFC")
    .map((doc) => ({
      value: doc.id,
      label: doc.identifier,
      validationRegex: doc.validation_regex,
    }));
}

// PARSERS submit
export function parseLoiCL(formData: FormData, state: State) {
  formData.append("occupation", state.landlordData.occupation);
  formData.append("civil_status", state.landlordData.civilStatus);
  formData.append("conjugal_regime", state.landlordData.maritalRegime);

  formData.append(
    "files",
    state.landlordDocs.appraisalCertificate,
    `appraisalCertificate-${state.landlordDocs.appraisalCertificate.name}`
  );
  formData.append(
    "files",
    state.landlordDocs.propertyDeed,
    `propertyDeed-${state.landlordDocs.propertyDeed.name}`
  );
}

export function parseLoiCO(formData: FormData, state: State) {
  formData.append("id_type", state.landlordData.identificationType);
  formData.append("id_number", state.landlordData.identificationNumber);

  formData.append(
    "files",
    state.landlordDocs.identificationDoc2,
    `backId-${state.landlordDocs.identificationDoc2.name}`
  );
  formData.append(
    "files",
    state.landlordDocs.marriageCertificate,
    `marriageCertificate-${state.landlordDocs.marriageCertificate.name}`
  );
  formData.append(
    "files",
    state.propertyDocs.traditionCertificate,
    `traditionCertificate-${state.propertyDocs.traditionCertificate.name}`
  );
  formData.append(
    "files",
    state.brokerageContract.signedContract,
    `signedContract-${state.brokerageContract.signedContract.name}`
  );
}

export function parseLoiMX(formData: FormData, state: State) {
  formData.append("id_type", state.landlordData.identificationType);
  formData.append("id_number", state.landlordData.identificationNumber);
  formData.append("rfc", state.landlordData.rfc);
  formData.append("clabe", state.landlordBankData.clabe);

  formData.append(
    "files",
    state.landlordDocs.curp,
    `curp-${state.landlordDocs.curp.name}`
  );
  formData.append(
    "files",
    state.landlordDocs.rfc,
    `rfc-${state.landlordDocs.rfc.name}`
  );
  formData.append(
    "files",
    state.landlordDocs.birthCertificate,
    `birthCertificate-${state.landlordDocs.birthCertificate.name}`
  );
  formData.append(
    "files",
    state.landlordDocs.marriageCertificate,
    `marriageCertificate-${state.landlordDocs.marriageCertificate.name}`
  );
  formData.append(
    "files",
    state.propertyDocs.propertyDeed,
    `propertyDeed-${state.propertyDocs.propertyDeed.name}`
  );
  formData.append(
    "files",
    state.propertyDocs.propertyBill,
    `propertyBill-${state.propertyDocs.propertyBill.name}`
  );
  formData.append(
    "files",
    state.propertyDocs.waterBill,
    `waterBill-${state.propertyDocs.waterBill.name}`
  );
}
