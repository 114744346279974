import { useQuery, UseQueryResult } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";

import { InspectionReportShowResponse } from "apps/landlord/services/propertyService/type";
import propertyService from "apps/landlord/services/propertyService";
import { useGetToken } from "hooks";

import parsePropertyInspectionReport from "../utils/parsers";

const useInspectionsById = (reportId: string) => {
  const { search } = useLocation<{ propertyId: string }>();
  const { id } = useParams<{ id: string }>();
  const { getToken } = useGetToken();
  const queryParams = queryString.parse(search);
  const propertyId = queryParams?.propertyId
    ? Number(queryParams?.propertyId)
    : id;

  const {
    isLoading,
    data,
    isError,
  }: UseQueryResult<InspectionReportShowResponse, Error> = useQuery<
    InspectionReportShowResponse,
    Error
  >(["inspection_report_by_id", queryParams.propertyId, reportId], async () => {
    const authToken = await getToken();
    const response = await propertyService.getInspectionReportById(
      Number(reportId),
      propertyId,
      authToken
    );
    return response.data;
  });
  return {
    isLoading,
    isError,
    data: data ? parsePropertyInspectionReport(data) : null,
  };
};

export default useInspectionsById;
