import FormWrapper from "apps/lead/components/EvaluatorForm/FormWrapper/FormWrapper";
import CallToAction from "apps/lead/components/EvaluatorForm/CallToAction";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";
import Loading from "Components/Admin/UIComponents/molecules/Loading";

import useSelectApplicant from "../../hooks/useSelectApplicant";
import CardApplicant from "../CardApplicant";
import { getRole } from "../../utils/utils";

const SelectApplicant = () => {
  const {
    evaluation,
    handleBackClick,
    handleNextClick,
    loading,
    activeStep,
    steps,
    applicant,
  } = useSelectApplicant();

  return (
    <>
      <Header
        title="Subir documentos"
        subtitle="A continuación selecciona el postulante al que le quieres adjuntar documentos para continuar con el proceso de evaluación"
        activeStep={activeStep}
        steps={steps}
      />
      <Loading loading={loading}>
        {evaluation && (
          <form onSubmit={handleNextClick}>
            <FormWrapper>
              {evaluation.map((person, i) => (
                <CardApplicant
                  key={person.id}
                  applicant={person}
                  role={getRole(person.role, i)}
                />
              ))}
            </FormWrapper>
            <CallToAction
              disabled={!applicant}
              onHandleBackClick={handleBackClick}
            />
          </form>
        )}
      </Loading>
    </>
  );
};

export default SelectApplicant;
