import countryList from "i18n-iso-countries/langs/es.json";

const useCountryList = () => {
  const countries = Object.entries(countryList.countries)
    .map(([key, value]) => ({
      value: typeof value === "string" ? value : value[0],
      label: typeof value === "string" ? value : value[0],
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return {
    countries,
  };
};

export default useCountryList;
