import { makeStyles, Theme } from "@material-ui/core/styles";

const defaultBreakpoint = "sm";

const useCallToActionStyles = makeStyles((theme: Theme) => ({
  ctaContent: {
    width: "100%",
    padding: theme.spacing(0, 2),
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(0, 8),
    },
  },
  ctaButton: {
    padding: theme.spacing(1, 6),
    borderRadius: 10,
    fontSize: "1rem",
    lineHeight: 1.25,
    textTransform: "none",
  },
}));

export default useCallToActionStyles;
