/* eslint-disable react/no-unstable-nested-components */
import { useHistory, useParams } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import {
  GridAlignment,
  GridCellValue,
  GridColDef,
  GridOverlay,
} from "@material-ui/x-grid";

import CustomDataGrid from "apps/tenant/components/CustomDataGrid";

import useDesktopPaymentsTableStyles from "./DesktopPaymentsTableStyles";
import { ParsedMovement } from "../../utils";

interface Props {
  movements: ParsedMovement[];
}

const DesktopPaymentsTable = ({ movements }: Props) => {
  const classes = useDesktopPaymentsTableStyles(movements.length)();
  const history = useHistory();
  const { id: propertyId } = useParams<{ id: string }>();

  const columnsConfig: GridColDef = {
    field: "id",
    resizable: true,
    filterable: false,
    cellClassName: classes.centerCell,
    sortable: false,
    disableColumnMenu: true,
    disableReorder: true,
    editable: false,
  };

  const columns = [
    {
      ...columnsConfig,
      field: "date",
      headerName: "Fecha de pago",
      type: "string",
      resizable: false,
      headerClassName: classes.firstHeaderCell,
      flex: 1,
    },
    {
      ...columnsConfig,
      field: "amount",
      headerName: "Pago",
      type: "string",
      align: "right" as GridAlignment,
      flex: 1,
    },
    {
      ...columnsConfig,
      field: "id",
      headerName: "Link",
      flex: 1,
      headerClassName: classes.lastRenderedHeaderCell,
      cellClassName: classes.lastRenderedRowCell,
      align: "center" as GridAlignment,
      renderHeader: () => <span className={classes.emptyHeaderName}>-</span>,
      renderCell: ({ value }: { value: GridCellValue }) => (
        <Box className={classes.linkContainer}>
          <Typography
            className={classes.link}
            onClick={() =>
              history.push(
                `/landlord/properties/${propertyId}/payment_detail/${value}`
              )
            }
          >
            Ver detalles
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <CustomDataGrid
      autoHeight={false}
      hideFooter
      rowHeight={48}
      headerHeight={40}
      rowCount={movements.length}
      columns={columns}
      rows={movements}
      loading={false}
      disableSelectionOnClick={true}
      classes={{
        root: classes.root,
        columnHeader: classes.headerWrapper,
      }}
      components={{
        NoRowsOverlay: () => (
          <GridOverlay>
            <p>Aún no se registran pagos</p>
          </GridOverlay>
        ),
      }}
    />
  );
};

export default DesktopPaymentsTable;
