import { makeStyles, Theme } from "@material-ui/core";

const ProfileStyles = makeStyles<Theme>((theme) => ({
  profileContentWrapper: {
    paddingTop: theme.spacing(3),
    height: "100%",
  },
}));

export default ProfileStyles;
