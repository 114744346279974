import {
  IoPersonAddOutline,
  IoAddOutline,
  IoArrowForwardOutline,
} from "@houm-com/ui-icons";
import LightButton from "@houm-com/ui/LightButton";

import { classes } from "apps/lead/pages/EvaluatorForm/components/Steps/Resume/components/MemberCard/MemberCardStyles";
import { memberTypeInfo } from "apps/lead/pages/EvaluatorForm/components/Steps/Resume/components/utils/constants";
import {
  ApplicantRole,
  EvaluatorPages,
} from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { useEvaluation } from "apps/lead/context/evaluatorContext/EvaluatorContext";
import {
  setPage,
  setUserInformation,
} from "apps/lead/context/evaluatorContext/EvaluatorActions";

interface Props {
  memberType: ApplicantRole;
}

const MemberCard = ({ memberType }: Props) => {
  const {
    dispatch,
    state: { applicants },
  } = useEvaluation();
  const member = applicants.find(
    (applicant) => applicant.extraInformation.role === memberType
  );

  const onAddMember = () => {
    dispatch(setPage(EvaluatorPages.FORM));
    const userInfo = member || {
      extraInformation: {
        role: memberType,
      },
      isCompleted: false,
    };
    dispatch(setUserInformation(userInfo));
  };

  return (
    <div className={classes.memberCardRoot}>
      <div>
        <IoPersonAddOutline size={24} />
      </div>
      <h1 className={classes.memberCardTitle}>
        {memberTypeInfo[memberType].title}
      </h1>
      {member ? (
        <div className={classes.memberFillInfo}>
          <p
            className={classes.memberCardInfoText}
          >{`${member.user.name} ${member.user.lastName} ${member.user.secondLastName}`}</p>
          <p>{member.user.email}</p>
          <p>{member.user.phone}</p>
          <p>{member.user.nationality}</p>
          <p>{member.user.document}</p>
        </div>
      ) : (
        <div className={classes.memberCardInfoContainer}>
          <p className={classes.memberCardInfoText}>
            {memberTypeInfo[memberType].information}
          </p>
          {memberTypeInfo[memberType].disclaimer && (
            <span className={classes.memberCardDisclaimerText}>
              {memberTypeInfo[memberType].disclaimer}
            </span>
          )}
        </div>
      )}
      <LightButton
        size="lg"
        variant="primary"
        icon={member ? <IoArrowForwardOutline /> : <IoAddOutline />}
        className={classes.memberCardButton}
        onClick={onAddMember}
      >
        {member ? "Editar" : "Agregar persona"}
      </LightButton>
    </div>
  );
};

export default MemberCard;
