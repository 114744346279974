import { Box, Grid } from "@material-ui/core";

import LandlordPagesMenu from "apps/landlord/pages/Property/components/PropertyPagesMenu";
import { getIsGuaranteedPlan } from "apps/landlord/utils/getIsGuaranteedPlan";

import { IProperty } from "apps/landlord/models/Property";
import { ContractModel } from "models/Contract";

import useUnfinishedSettingUpBanner from "apps/landlord/pages/PropertyUnfinishedSettingUp/hooks/useUnfinishedSettingUpBanner";
import { showEmptyPriceCard, showSupplyPropertyInfo } from "../../utils/utils";
import usePropertyStatus from "../../hooks/usePropertyStatus";
import useMainContentStyles from "./MainContentStyles";
import RentedPropertyInfo from "../RentedPropertyInfo";
import SupplyPropertyInfo from "../SupplyPropertyInfo";
import PropertyPrice from "../PropertyPrice";
import SettingUpDashboard from "../SettingUpDashboard";
import BasicServicesInfo from "../BasicServicesInfo";

interface Props {
  propertyData: IProperty | null;
  contractData: ContractModel | null;
}

const MainContent = ({ propertyData, contractData }: Props) => {
  const classes = useMainContentStyles();
  const { hasMissingData } = useUnfinishedSettingUpBanner();
  const { propertyStatusForRental, isForRental } =
    usePropertyStatus(propertyData);

  return (
    <Box className={classes.rightContainer}>
      {hasMissingData && propertyStatusForRental !== "rented" && (
        <SettingUpDashboard propertyData={propertyData} />
      )}
      {isForRental && propertyStatusForRental === "rented" && (
        <>
          <RentedPropertyInfo
            country={propertyData?.country}
            propertyId={propertyData?.id}
            isPlanGuaranteed={getIsGuaranteedPlan(
              contractData?.plan?.payment_option?.option
            )}
          />
          <BasicServicesInfo country={propertyData.country} />
        </>
      )}
      {showSupplyPropertyInfo(propertyData) && (
        <SupplyPropertyInfo property={propertyData} />
      )}
      <Box className={classes.generalInfoContainer}>
        {showEmptyPriceCard(propertyData) && (
          <Grid container item xs={12} sm={6}>
            <PropertyPrice propertyId={propertyData?.uid} />
          </Grid>
        )}
        <LandlordPagesMenu propertyData={propertyData} />
      </Box>
    </Box>
  );
};

export default MainContent;
