import { ReactNode } from "react";

import { menuItems } from "apps/lead/constants/layout";
import ReferPartnerButton from "Components/Admin/UIComponents/ReferPartnerButton";
import PageDesktopSidebar from "Components/Layout/PageDesktopSidebar";
import useStyles from "./BrowserViewStyles";

interface Props {
  children: ReactNode;
}

export default function BrowserView({ children }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <div className={classes.leftContainer}>
        <PageDesktopSidebar menuItems={menuItems(classes)} />
        <ReferPartnerButton />
      </div>
      <div className={classes.rightContainer}>{children}</div>
    </div>
  );
}
