import { ComponentProps } from "react";
import { useHistory } from "react-router-dom";
import clx from "classnames";

import { IoArrowBack } from "@houm-com/ui-icons";
import LightButton from "@houm-com/ui/LightButton";

import { Hidden } from "@material-ui/core";

import useBackNavigationStyles from "./BackNavigationStyles";

interface Props extends Partial<ComponentProps<typeof LightButton>> {
  propertyId?: string;
  className?: string;
  iconClassName?: string;
  backText?: string;
}

const BackNavigation = ({
  backText,
  propertyId,
  className,
  iconClassName,
  ...props
}: Props) => {
  const classes = useBackNavigationStyles();
  const history = useHistory();

  return (
    <Hidden smDown>
      <LightButton
        variant="primary"
        size="sm"
        onClick={() =>
          propertyId
            ? history.push(`/landlord/properties/${propertyId}`)
            : history.goBack()
        }
        icon={
          <IoArrowBack
            size="14"
            className={clx(classes.arrow, iconClassName)}
          />
        }
        position="start"
        {...props}
        className={className}
      >
        {backText || "Volver"}
      </LightButton>
    </Hidden>
  );
};

export default BackNavigation;
