import { Typography } from "@material-ui/core";

import { useEvaluation } from "apps/lead/context/evaluationContext/EvaluationContext";
import { countryCurrency, parsePrice } from "apps/lead/utils/parsePrice";
import useSubtitleStyles from "./SubtitleStyles";

const Subtitle = () => {
  const classes = useSubtitleStyles();
  const {
    state: {
      property: { promotionalPrice, price, currency, country },
    },
  } = useEvaluation();

  const propertyPrice = promotionalPrice ?? price;

  return (
    <Typography component="span" className={classes.subtitle}>
      Recuerda que para arrendar, la suma de las rentas del arrendatario y los
      codeudores, debe ser al menos <strong>3 veces el precio</strong> del
      arriendo, lo que equivale a{" "}
      {parsePrice(
        propertyPrice * 3,
        countryCurrency(country, currency),
        country
      )}
      .
    </Typography>
  );
};

export default Subtitle;
