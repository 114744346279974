import { useHistory, useParams } from "react-router-dom";

import PropertyLayout from "apps/landlord/Layout/PropertyLayout";

import RentPayments from "./components/RentPayments";

const PropertyPayments = () => {
  const history = useHistory();
  const { id: propertyId } = useParams<{ id: string }>();

  const handleBack = () => {
    history.push(`/landlord/properties/${propertyId}`);
  };

  return (
    <PropertyLayout
      titlePage="Comprobantes de pago"
      subtitlePage="Encuentra todos tus comprobantes de pago con sus detalles aquí."
      onHandleBack={handleBack}
    >
      <RentPayments />
    </PropertyLayout>
  );
};

export default PropertyPayments;
