import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  list: {
    color: "#263238",
    fontSize: theme.spacing(1.7),
    fontWeight: 600,
    padding: theme.spacing(1),
    "&:hover, &:focus": {
      color: theme.palette.primary.main,
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      marginLeft: "3px",
      marginRight: "3px",
    },
  },
  textFieldRoot: {
    width: "100%",
    "& input.MuiInputBase-input": {
      color: theme.palette.grey[500],
    },
  },
}));
