import { Close } from "@material-ui/icons";
import { Box, Dialog, Grid, Typography } from "@material-ui/core";

import {
  IoChatbubblesOutline as ChatIcon,
  IoDocumentTextOutline as FormIcon,
  IoHelpCircleOutline as HelpIcon,
  IoArrowForward as LinkIcon,
} from "@houm-com/ui-icons";

import useHelpWdgetMobileModalStyles from "./HelpWidgetModalStyles";

const HelpWidgetModal = ({
  onClose,
  goToHelpCenter,
  goToHelpForm,
  goToHelpChat,
}) => {
  const classes = useHelpWdgetMobileModalStyles();
  return (
    <Dialog
      open={true}
      PaperProps={{
        classes: {
          root: classes.modalRoot,
        },
      }}
    >
      <Close className={classes.mobileModalCloseIcon} onClick={onClose} />
      <Typography className={classes.mobileModalTitle}>
        Resuelve tus dudas aquí
      </Typography>
      <Grid className={classes.mobileModalActions}>
        <Grid className={classes.mobileModalAction}>
          <Box className={classes.mobileModalActionTitleWrapper}>
            <HelpIcon />
            <Typography className={classes.mobileModalActionTitle}>
              Centro de ayuda
            </Typography>
          </Box>
          <Typography className={classes.mobileModalActionDescription}>
            Obtén respuestas al instante. Hemos recopilado las principales dudas
            de nuestros clientes.
          </Typography>
          <Box
            data-testid="btn__help-center"
            onClick={goToHelpCenter}
            className={classes.mobileModalActionLinkContainer}
          >
            <Typography className={classes.mobileModalActionLink}>
              Ir al centro
            </Typography>
            <LinkIcon size="14" className={classes.linkIcon} />
          </Box>
        </Grid>
        <Grid className={classes.mobileModalAction}>
          <Box className={classes.mobileModalActionTitleWrapper}>
            <FormIcon />
            <Typography className={classes.mobileModalActionTitle}>
              Formulario
            </Typography>
          </Box>
          <Typography className={classes.mobileModalActionDescription}>
            Completa nuestro formulario para que podamos ayudarte a la brevedad
            posible.
          </Typography>
          <Box
            data-testid="btn__help-form"
            onClick={goToHelpForm}
            className={classes.mobileModalActionLinkContainer}
          >
            <Typography className={classes.mobileModalActionLink}>
              Ir al formulario
            </Typography>
            <LinkIcon size="14" className={classes.linkIcon} />
          </Box>
        </Grid>
        <Grid className={classes.mobileModalAction}>
          <Box className={classes.mobileModalActionTitleWrapper}>
            <ChatIcon />
            <Typography className={classes.mobileModalActionTitle}>
              Chat
            </Typography>
          </Box>
          <Typography className={classes.mobileModalActionDescription}>
            Entra a nuestro Whatsapp y resuelve todas las dudas que tengas.
          </Typography>
          <Box
            data-testid="btn__help-chat"
            onClick={goToHelpChat}
            className={classes.mobileModalActionLinkContainer}
          >
            <Typography className={classes.mobileModalActionLink}>
              Ir al chat
            </Typography>
            <LinkIcon size="14" className={classes.linkIcon} />
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default HelpWidgetModal;
