import { GroupedInspectionReportSpace } from "models/InspectionReport";

const getCurrentSpace = (
  spaces: GroupedInspectionReportSpace[],
  currentSpaceName: string
): GroupedInspectionReportSpace | null =>
  spaces ? spaces.find((space) => space?.type === currentSpaceName) : null;

const getElementsWithObservation = (
  spaces: GroupedInspectionReportSpace[],
  spaceType: string
) => {
  if (!spaces.length) return [];
  const currentSpace = getCurrentSpace(spaces, spaceType);

  const spaceItemsWithObservations = currentSpace?.items.filter(
    (item) => item.observation
  );
  const spaceFeaturessWithObservations = currentSpace?.features.filter(
    (feature) => feature.observation
  );

  return [...spaceItemsWithObservations, ...spaceFeaturessWithObservations];
};

export default getElementsWithObservation;
