import { Theme, makeStyles } from "@material-ui/core";

const useUnfinishedSettingUpModalStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "rgba(38, 50, 56, 0.88)",
    justifyContent: "space-between",
  },
  materialUiBorder: {
    borderRadius: theme.spacing(2),
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(3, 2),
    width: `calc(100vw - ${theme.spacing(4)}px)`,
    minHeight: theme.spacing(26),
    [theme.breakpoints.up("sm")]: {
      maxWidth: 532,
    },
  },
  unfinishedSettingUpModalHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  unfinishedSettingUpModalHeaderIcon: {
    flexShrink: 0,
    color: "#2C73DB",
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(24),
  },
  unfinishedSettingUpModalHeaderTitle: {
    fontWeight: 700,
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(28),
  },
  unfinishedSettingUpModalText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
  unfinishedSettingUpRoutes: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  unfinishedSettingUpRoute: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F1F5FB",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    gap: theme.typography.pxToRem(10),
    border: "1.4px solid #124FA8",
    borderRadius: theme.spacing(1),
    alignItems: "center",
    transition: "all 0.3s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EBF1FB",
      transform: "translateY(-1px)",
    },
    "&:active": {
      backgroundColor: "#DAE2EB",
    },
  },
  unfinishedSettingUpRouteContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  unfinishedSettingUpRouteTitle: {
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
  unfinishedSettingUpRouteDescription: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
  },
  unfinishedSettingUpRouteIcon: {
    flexShrink: 0,
    width: theme.typography.pxToRem(16),
    height: theme.typography.pxToRem(16),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    padding: 0,
    zIndex: 1,
    color: "black",
  },
}));

export default useUnfinishedSettingUpModalStyles;
