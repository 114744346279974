import { useEffect, useState } from "react";

import { Grid, Modal, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { useGetCurrentUser, useGetToken } from "hooks";
import { localeToCountry } from "utils/countryCode";
import userService from "services/userService";
import { Locale } from "models/Countries";

import useSetUserCountryStyles from "./UserCountryCorrectionDialogStyles";
import CountrySelectorOption from "./CountrySelectorOption";

import CLFlag from "assets/images/flags/cl-flag.svg";
import COFlag from "assets/images/flags/co-flag.svg";
import MXFlag from "assets/images/flags/mx-flag.svg";

const UserCountryCorrectionDialog = () => {
  const classes = useSetUserCountryStyles();
  const { getToken } = useGetToken();
  const [selectedCountry, setSelectedCountry] = useState<Locale | undefined>();
  const [errorMesage, setErrorMessage] = useState<string | undefined>();
  const [displayModal, setDisplayModal] = useState(false);
  const { user } = useGetCurrentUser();

  useEffect(() => {
    if (user && user.country === "Unknown") {
      setDisplayModal(true);
    }
  }, [user]);

  const handleCountrySelection = (country: Locale) => {
    setSelectedCountry(country);
  };

  const handleSubmitUserCountry = () => {
    setErrorMessage(undefined);
    getToken()
      .then((token) => {
        userService
          .setUserCountry(token, localeToCountry[selectedCountry])
          .then((res) => {
            setDisplayModal(false);
          })
          .catch((err) => {
            setErrorMessage(
              "Ha ocurrido un error al asignar país, por favor intente nuevamente"
            );
          });
      })
      .catch((err) => {
        setErrorMessage(
          "Ha ocurrido un error de autenticación, reintente más tarde"
        );
      });
  };

  return (
    <Modal open={displayModal} className={classes.modalRoot}>
      <Grid container className={classes.dialogWrapper}>
        <Typography className={classes.dialogTitle}>
          ¿Donde quieres publicar tu propiedad?
          <span role="img" aria-label="emoji casa">
            🏡
          </span>
        </Typography>
        <Typography className={classes.dialogDisclaimer}>
          Para continuar es&nbsp;
          <b>necesario que elijas uno de estos países</b>&nbsp; donde podemos
          publicar tu propiedad&nbsp;
          <span role="img" aria-label="emoji de aqui">
            👇
          </span>
        </Typography>
        <Grid className={classes.countriesSelector}>
          <CountrySelectorOption
            country="cl"
            flagImg={CLFlag}
            selected={selectedCountry === "cl"}
            onClick={() => handleCountrySelection("cl")}
          />
          <CountrySelectorOption
            country="co"
            flagImg={COFlag}
            selected={selectedCountry === "co"}
            onClick={() => handleCountrySelection("co")}
          />
          <CountrySelectorOption
            country="mx"
            flagImg={MXFlag}
            selected={selectedCountry === "mx"}
            onClick={() => handleCountrySelection("mx")}
          />
        </Grid>
        <Grid>
          <DesignSystemButton
            onClick={handleSubmitUserCountry}
            buttonClassName={classes.nextButton}
            label="Continuar"
            size="medium"
            variant="primary"
          />
        </Grid>
        {errorMesage && (
          <Typography className={classes.errorMessage}>
            {errorMesage}
          </Typography>
        )}
      </Grid>
    </Modal>
  );
};

export default UserCountryCorrectionDialog;
