import { Typography } from "@material-ui/core";
import { formatDate } from "apps/tenant/utils/utils";

const getCOConfirmationMessage = (leavingDate: Date) => {
  const formattedLeavingDate = formatDate(leavingDate);
  return (
    <>
      <Typography component="p">
        Estas solicitando tu <strong>fecha tentativa de salida</strong> para el{" "}
        <strong>{formattedLeavingDate}</strong>. Te contactaremos cuando
        validemos la información, indicándote el proceso a seguir para llevar a
        cabo tu solicitud.
      </Typography>
      <Typography component="p">
        Recuerda que en caso de que tú intención de terminación de contrato sea
        de manera anticipada, estás sujeto a las condiciones expuestas en la
        Cláusula Número 10 de tu contrato de arrendamiento.
      </Typography>
      <Typography component="p">¿Deseas continuar con la solicitud?</Typography>
    </>
  );
};
export default getCOConfirmationMessage;
