import { Box, Typography } from "@material-ui/core";

import { accountTypeMapping } from "apps/landlord/utils/accountTypes";
import { ILandlordBankAccount } from "apps/landlord/models/Movement";

import useBankAccountInfoStyles from "./BankAccountInfoStyles";

interface Props {
  bankData: ILandlordBankAccount;
}

const BankAccountInfo = ({ bankData }: Props) => {
  const classes = useBankAccountInfoStyles();

  return (
    <Box className={classes.infoRowContainer}>
      <Typography className={classes.infoTextTitle}>
        Donde recibirás el pago
      </Typography>
      <Box>
        <Typography className={classes.infoTextTitle}>
          Nombre:{" "}
          <Typography component="span" className={classes.infoTextValue}>
            {bankData.fullName}
          </Typography>
        </Typography>
        <Typography className={classes.infoTextTitle}>
          Banco:{" "}
          <Typography component="span" className={classes.infoTextValue}>
            {bankData.bankName}
          </Typography>
        </Typography>
        <Typography className={classes.infoTextTitle}>
          Tipo de cuenta:{" "}
          <Typography component="span" className={classes.infoTextValue}>
            {accountTypeMapping[bankData.accountType]}
          </Typography>
          <Typography component="span" className={classes.infoTextNumber}>
            {`*${bankData.accountNumber.slice(-4)}`}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default BankAccountInfo;
