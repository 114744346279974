import { Typography } from "@material-ui/core";

import Emoji from "Components/Admin/UIComponents/DesignSystem/Emoji";
import { Locale } from "models/Countries";

import useStyles from "./NoResultsStyles";
import MarketplaceLink from "../MarketplaceLink";

interface Props {
  countryCode: Locale;
}

const NoResults = ({ countryCode }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.icon}>
        <Emoji emojiIcon="🧡" ariaLabel="corazon naranjo" />
      </Typography>
      <Typography className={classes.text}>
        Aún no tienes propiedades favoritas guardadas
      </Typography>
      <MarketplaceLink countryCode={countryCode} />
    </div>
  );
};

export default NoResults;
