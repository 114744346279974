import { isMobile } from "react-device-detect";

import { Typography, Box, Grid } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { EvaluationStatus } from "models/Evaluation";
import { getInfoContainerTexts, getTitle, labelEvaluateButton } from "./utils";
import EmptyEvaluation from "../EmptyEvaluation";
import useApplicantEvaluationStyles from "./ApplicantEvaluationStyles";
import { useApplicant } from "../../context/ApplicantContext";
import InfoContainer from "./components/InfoContainer";
import ApplicantInfo from "../ApplicantInfo";
import EvaluationDialog from "../EvaluationDialog";

const ApplicantEvaluation = () => {
  const classes = useApplicantEvaluationStyles();
  const { lastApplication, handleEvaluationModalApplication } = useApplicant();

  return (
    <>
      {!lastApplication && <EmptyEvaluation />}
      {Boolean(lastApplication) && (
        <Box className={classes.applicantEvaluationContainer}>
          <Grid container className={classes.titleContainer}>
            <Typography className={classes.title}>
              {getTitle(lastApplication.status)}
            </Typography>
            {getInfoContainerTexts(lastApplication.status).map(
              (infoContainerText) => (
                <InfoContainer
                  key={infoContainerText.text}
                  {...infoContainerText}
                />
              )
            )}
          </Grid>
          <Box className={classes.contentContainer}>
            <ApplicantInfo application={lastApplication} />
          </Box>
          <Box className={classes.buttonContainer}>
            <DesignSystemButton
              variant="primary"
              size="medium"
              label={labelEvaluateButton(lastApplication)}
              id="evaluate-button"
              fullWidth={isMobile}
              disabled={lastApplication.status === EvaluationStatus.APPROVED}
              buttonClassName={classes.button}
              onClick={() => handleEvaluationModalApplication(lastApplication)}
            />
          </Box>
          <EvaluationDialog />
        </Box>
      )}
    </>
  );
};

export default ApplicantEvaluation;
