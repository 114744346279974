import { MouseEvent, useState } from "react";
import { isBrowser } from "react-device-detect";

import { Fade, IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import useOptionListStyles from "./OptionListStyles";
import { Option } from "../../utils/types";

interface Props {
  options: Option[];
}

const OptionList = ({ options }: Props) => {
  const classes = useOptionListStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOption = (option: Option) => {
    handleClose();
    option.handleClick();
  };

  return (
    <>
      <IconButton
        aria-label="Opciones"
        aria-controls="long-menu"
        aria-haspopup="true"
        title="Opciones"
        onClick={handleClick}
        className={classes.iconButton}
      >
        {isBrowser ? (
          <MoreVertIcon aria-label="vertical" className={classes.browserIcon} />
        ) : (
          <MoreHorizIcon
            aria-label="horizontal"
            className={classes.mobileIcon}
          />
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        anchorOrigin={{
          vertical: "top",
          horizontal: isBrowser ? "left" : "right",
        }}
        transformOrigin={{
          vertical: isBrowser ? "top" : "bottom",
          horizontal: isBrowser ? "right" : "center",
        }}
        TransitionComponent={Fade}
        onClose={handleClose}
        PaperProps={{ className: classes.paper }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.text}
            className={classes.menuItem}
            onClick={() => handleClickOption(option)}
          >
            {option.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default OptionList;
