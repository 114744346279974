import { makeStyles, Theme } from "@material-ui/core";

const useCallToActionStyles = makeStyles<Theme>((theme) => ({
  buttonContainer: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0),
    },
  },
}));

export default useCallToActionStyles;
