export const CIVIL_STATUSES = [
  { value: "unmarried", label: "Soltero/a" },
  { value: "married", label: "Casado/a" },
  { value: "divorced", label: "Divorciado/a" },
  { value: "widowed", label: "Viudo/a" },
  { value: "freeUnion", label: "Unión libre" },
  { value: "other", label: "Otro" },
];

export const MARITAL_REGIMES = [
  { value: "conjugalSociety", label: "Sociedad conyugal" },
  { value: "separatedGoods", label: "Con separación de bienes" },
  { value: "not_apply", label: "No aplica" },
  { value: "other", label: "Otro" },
];

export const COUNTRY_ADDRESS = {
  Mexico: "Dirección (sin número)",
  Chile: "Calle",
  Colombia: "Dirección",
};

export const COUNTRY_STEPS = {
  Chile: ["propertyData", "landlordData"],
  Colombia: [
    "propertyData",
    "landlordData",
    "thirdPartyData",
    "publicServices",
    "adminData",
    "fileLinks",
  ],
  Mexico: ["propertyData", "landlordData", "adminData", "fileLinks"],
};

export enum BooleanString {
  YES = "1",
  NO = "0",
}
