import { createHttp } from "services/helper";
import qs from "query-string";
import {
  InspectionReportService,
  InspectionReportServiceResponse,
} from "./types";
import { BACKEND_URL } from "env";

const inspectionReportService: InspectionReportService = (token) => {
  const httpClient = createHttp(token);
  const getInspectionsReport: InspectionReportServiceResponse["getInspectionsReport"] =
    ({
      propertyId,
      pageSize = 15,
      page = 1,
      view = "filtered",
      filters = {},
    }) =>
      httpClient.get(
        `/properties/v2/${propertyId}/inspection_forms/?page_size=${pageSize}&page=${page}&view=${view}&${qs.stringify(
          filters
        )}`
      );

  const commentsHTTPClient = createHttp(token, BACKEND_URL);

  const getInspectionReportComments: InspectionReportServiceResponse["getInspectionReportComments"] =
    ({
      nextPageURL,
      propertyId,
      inspectionReportId,
      page = 1,
      pageSize = 15,
    }) =>
      commentsHTTPClient.get(
        nextPageURL ??
          `/properties/v2/${propertyId}/inspection_forms/${inspectionReportId}/comments/?page_size=${pageSize}&page=${page}`
      );

  const createInspectionReportComment: InspectionReportServiceResponse["createInspectionReportComment"] =
    ({ propertyId, inspectionReportId, inspectionReportComment }) =>
      commentsHTTPClient.post(
        `/properties/v2/${propertyId}/inspection_forms/${inspectionReportId}/comment/`,
        inspectionReportComment
      );

  const createUploadCommentPhotoPresignedURL: InspectionReportServiceResponse["createUploadCommentPhotoPresignedURL"] =
    ({ filenames }) =>
      commentsHTTPClient.post(
        `/properties/v2/inspection-form-comments/upload-url`,
        {
          file_names: filenames,
        }
      );

  return {
    getInspectionsReport,
    getInspectionReportComments,
    createInspectionReportComment,
    createUploadCommentPhotoPresignedURL,
  };
};

export default inspectionReportService;
