import Swal from "sweetalert2";

export const handleFailedReasonsRequests = () => {
  Swal.fire({
    title: "Error",
    text: "No se pudieron obtener los motivos de despublicación",
  });
};

export const handleFailedUnpublishRequest = () => {
  Swal.fire({
    title: "Error",
    text: "No se pudo despublicar la propiedad",
  });
};

export const handleSuccessfullUnpublishRequest = () => {
  Swal.fire({
    title: "Éxito",
    text: "La propiedad se despublicó correctamente",
  });
};
