import { Box, Typography } from "@material-ui/core";

import DefaulterTag from "apps/landlord/components/DefaulterTag";
import useInfoRowStyles from "./InfoRowStyles";

interface Props {
  title: string;
  value: string;
  isDefaulter?: boolean;
}

export default function InfoRow({ title, value, isDefaulter = false }: Props) {
  const classes = useInfoRowStyles();

  return (
    <Box className={classes.infoRowContainer}>
      <Typography className={classes.infoTextTitle}>{title}</Typography>
      <Typography className={classes.infoTextValue}>{value}</Typography>
      {isDefaulter && <DefaulterTag />}
    </Box>
  );
}
