import { Box, Typography } from "@material-ui/core";

import DesignSystemButton from "Components/Admin/UIComponents/Buttons/DesignSystemButton";
import { formatDate } from "apps/tenant/utils/utils";
import { ILeavingForm } from "apps/tenant/utils/types";
import { ContractResponse } from "apps/landlord/services/propertyContractService/types";
import useConfirmationStepStyles from "./ConfirmationStyles";
import { LOCALE_BASED_CONFIRMATION_TITLE } from "./constants";

type Props = {
  activeContract: ContractResponse;
  handleBack: () => void;
  handleSubmit: (data: ILeavingForm) => void;
  leavingData: ILeavingForm;
  isLoading: boolean;
};

const ConfirmationStep = ({
  activeContract,
  handleSubmit,
  handleBack,
  leavingData,
  isLoading,
}: Props) => {
  const classes = useConfirmationStepStyles();
  const formattedDate = formatDate(leavingData.date);
  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>
        {LOCALE_BASED_CONFIRMATION_TITLE[activeContract.country]}
      </Typography>
      <Typography className={classes.subtitle}>
        La fecha de <strong>salida solicitada</strong> es el día{" "}
        <strong>{formattedDate}.</strong>
      </Typography>
      <Typography className={classes.description}>
        ¿Deseas continuar con este proceso?
      </Typography>

      <div className={classes.ctaContainer}>
        <DesignSystemButton
          label="Cancelar"
          variant="tertiaryB"
          size="small"
          onClick={handleBack}
        />
        <DesignSystemButton
          label="Continuar"
          variant="primary"
          disabled={!leavingData.date || !leavingData.motive}
          isLoading={isLoading}
          size="small"
          onClick={() => handleSubmit(leavingData)}
        />
      </div>
    </Box>
  );
};

export default ConfirmationStep;
