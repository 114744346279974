import { useEffect } from "react";
import { useUser } from "context/userContext";

const Login = () => {
  const { loginWithRedirect } = useUser();

  useEffect(() => {
    loginWithRedirect();
  }, []);

  return null;
};

export default Login;
