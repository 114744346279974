import { makeStyles, Theme } from "@material-ui/core/styles";

const defaultBreakpoint = "md";

const useCallToActionStyles = makeStyles((theme: Theme) => ({
  ctaRoot: {
    position: "fixed",
    right: 0,
    bottom: 0,
    padding: theme.spacing(0),
    zIndex: theme.zIndex.snackbar,
    height: "75px",
    width: "100%",
    boxShadow: "4px 4px 30px rgba(0, 43, 41, 0.15)",
  },
  ctaContent: {
    width: "100%",
    height: "75px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(defaultBreakpoint)]: {
      padding: theme.spacing(0, 8),
    },
  },
  ctaButton: {
    width: "100%",
    height: "75px",
    fontSize: theme.typography.pxToRem(18),
    lineHeight: "1.5em",
    borderRadius: 0,
    textTransform: "none",
  },
}));

export default useCallToActionStyles;
