import Swal from "sweetalert2";
import { Dispatch, SetStateAction } from "react";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Cancel, Settings, Minimize } from "@material-ui/icons";

import {
  setPhoneDisplayStatus,
  closePhone,
} from "context/tokyContext/tokyActions";
import { useToky } from "context/tokyContext/tokyContext";

import useStyles from "./TokyPhoneHeaderStyles";
import {
  PhoneDisplayStatus,
  TokySessionStatus,
} from "context/tokyContext/tokyTypes";

interface Props {
  setShowModal: Dispatch<SetStateAction<boolean>>;
}

const TokyPhoneHeader = ({ setShowModal }: Props) => {
  const classes = useStyles();
  const { state, dispatch } = useToky();

  const handleOpenSettingsModal = () => setShowModal(true);

  const handleClosePhone = () => {
    closePhone(dispatch);
  };

  const handleCloseClick = async () => {
    if (state.tokySessionStatus === TokySessionStatus.OFF) {
      return handleClosePhone();
    }
    const confirm = await Swal.fire({
      type: "warning",
      title: `Hay una llamada en curso. 
      ¿Seguro deseas cerrar el teléfono?
      La llamada será cortada`,
      showCancelButton: true,
      confirmButtonText: "Si, estoy seguro",
      cancelButtonText: "Cancelar",
    });

    if (confirm?.value) {
      handleClosePhone();
      if (!state.tokySession) return;
      state.tokySession.endCall();
    }
  };

  const handleToggleMinimize = () => {
    const toggleState =
      state.phoneDisplayStatus === PhoneDisplayStatus.OPEN
        ? PhoneDisplayStatus.MINIMIZED
        : PhoneDisplayStatus.OPEN;
    dispatch(setPhoneDisplayStatus(toggleState));
  };

  const isSettingEnabled =
    state.audioDevices?.input?.length && state.audioDevices.output?.length;

  return (
    <Box className={classes.phoneHeader}>
      <Typography className={classes.phoneHeaderText}>Teléfono</Typography>
      <Box>
        <IconButton size="small" onClick={handleToggleMinimize}>
          <Minimize className={classes.headerIcon} />
        </IconButton>
        <IconButton
          size="small"
          onClick={handleOpenSettingsModal}
          disabled={!isSettingEnabled}
        >
          <Settings className={classes.headerIcon} />
        </IconButton>

        <IconButton
          size="small"
          onClick={handleCloseClick}
          id="closeTokyButton"
        >
          <Cancel className={classes.headerIcon} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TokyPhoneHeader;
