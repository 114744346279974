import MemberCard from "apps/lead/pages/EvaluatorForm/components/Steps/Resume/components/MemberCard";
import { classes } from "apps/lead/pages/EvaluatorForm/components/Steps/Resume/ResumeStyles";
import Header from "apps/lead/components/EvaluatorForm/HeaderForm/Header";

const Resume = () => (
  <div className={classes.resumeRoot}>
    <Header title="Elige el perfil que quieres agregar" stepTitle="Resumen" />
    <div className={classes.resumeMemberContainer}>
      <MemberCard memberType="main" />
      <MemberCard memberType="rent_complement" />
      <MemberCard memberType="codebtor" />
    </div>
  </div>
);

export default Resume;
