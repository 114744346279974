import { useEffect, useState } from "react";

import { detectLandlord, detectTenant } from "apps/main/utils/detectUserRole";
import { AUTH0_ROLES, ADMIN_HOUM_URL } from "env";
import { useUser } from "context/userContext";
import { detectOps } from "apps/main/utils";

const AuthorizeUser = ({
  children,
}: {
  children: React.ReactElement | null;
}) => {
  const [isOps, setIsOps] = useState(false);
  const [isLandlord, setIsLandlord] = useState(false);
  const [isTenant, setIsTenant] = useState(false);
  const { user } = useUser();
  useEffect(() => {
    if (user) {
      // here we can handle access for admin use;
      if (detectOps(user[AUTH0_ROLES])) {
        setIsOps(true);
      }

      if (detectLandlord(user[AUTH0_ROLES])) {
        setIsLandlord(true);
      }

      if (detectTenant(user[AUTH0_ROLES])) {
        setIsTenant(true);
      }
    }
  }, [user]);

  // this is going to be depreacted when the admin is migrated to admin.houm.com
  // TODO: here we'll trigger mirror mode
  if (isOps) {
    window.location.replace(`${ADMIN_HOUM_URL}${window.location.pathname}`);
    return null;
  }

  if (isLandlord) {
    if (isTenant) {
      return children;
    } else if (
      !["propietary", "landlord", "account"].includes(
        window.location.pathname.split("/")[1]
      )
    ) {
      window.location.pathname = "landlord";
      return null;
    }
  }

  return children;
};

export default AuthorizeUser;
