import { useQuery, UseQueryResult } from "react-query";

import propertyService from "apps/landlord/services/propertyService";
import { useGetToken } from "hooks";

interface Data {
  rentedFlag: boolean;
  enabledPropertiesFlag: boolean;
  propertiesFlag: boolean;
}

const usePropertiesFlag = () => {
  const { getToken } = useGetToken();

  const { isLoading, data, error }: UseQueryResult<Data, Error> = useQuery<
    Data,
    Error
  >(["properties"], async () => {
    const authToken = await getToken();
    const response = await propertyService.getLandlordPropertiesByToken(
      authToken
    );
    const rentedProperties = response.data.results.filter(
      (property) => property.property_details[0].status === "rented"
    );
    const enabledProperties = response.data.results.filter(
      (property) =>
        property.property_details[0].status !== "rented" &&
        property.property_details[0].status !== "archived"
    );
    const properties = response.data.results.filter(
      (property) => property.property_details[0].status !== "archived"
    );
    return {
      rentedFlag: rentedProperties.length > 0,
      enabledPropertiesFlag: enabledProperties.length > 0,
      propertiesFlag: properties.length > 0,
    };
  });

  return {
    isLoading,
    rentedFlag: data?.rentedFlag ?? false,
    enabledPropertiesFlag: data?.enabledPropertiesFlag ?? false,
    propertiesFlag: data?.propertiesFlag ?? false,
    error,
  };
};

export default usePropertiesFlag;
