import { useCallback } from "react";
import Autosuggest from "react-autosuggest";

import { Box, Grid } from "@material-ui/core";

import { IoSearchOutline as SearchIcon } from "@houm-com/ui-icons";

import { IProperty } from "apps/landlord/models/Property";

import usePropertySearchStyles from "./PropertySearchStyles";
import useSuggestion from "../../hooks/useSuggestion";
import Suggestion from "./components/Suggestion";

interface Props {
  onSelectPropety: (prop: Partial<IProperty>) => void;
}

const PropertySearch = ({ onSelectPropety }: Props) => {
  const classes = usePropertySearchStyles();

  const {
    suggestions,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    inputProps,
    searchValue,
    getSuggestionValue,
  } = useSuggestion(onSelectPropety);

  const renderSuggestion = useCallback(
    (suggestion: Partial<IProperty>) => (
      <Suggestion suggestion={suggestion} value={searchValue} />
    ),
    [searchValue]
  );

  const renderInputComponent = (inputPropss) => (
    <Box className={classes.inputContainer}>
      <SearchIcon className={classes.searchIcon} />
      <input {...inputPropss} />
    </Box>
  );

  return (
    <Grid container className={classes.propertySearchContainer}>
      <Grid item xs={12}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          renderInputComponent={renderInputComponent}
          theme={{
            input: classes.textField,
            container: classes.textFieldContainer,
            suggestionsContainerOpen: classes.suggestionContainer,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PropertySearch;
