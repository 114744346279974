import { HOUM_COM_URL } from "env";
import { useMemo } from "react";
import useProperty from "../useProperty";
import usePropertyVisibility from "../propertyVisibilityStatus/usePropertyVisibility";

const usePropertyPublicationLinks = () => {
  const { isNotPublished, isPublishedInSaleMode, isPublishedInRentalMode } =
    usePropertyVisibility();

  const { propertyData } = useProperty();

  const salePublicationLink = useMemo(() => {
    if (!isPublishedInSaleMode) return null;
    const sanitizedRegion = propertyData.region.toLowerCase().replace(" ", "-");
    const countryCode = propertyData.country;
    const rootURL = `/${countryCode}/${propertyData.type}-venta-${sanitizedRegion}/${propertyData.id}`;
    return HOUM_COM_URL + rootURL;
  }, [
    isPublishedInSaleMode,
    propertyData.country,
    propertyData.id,
    propertyData.region,
    propertyData.type,
  ]);

  const rentalPublicationLink = useMemo(() => {
    if (!isPublishedInRentalMode) return null;
    const sanitizedRegion = propertyData.region.toLowerCase().replace(" ", "-");
    const countryCode = propertyData.country;
    const rootURL = `/${countryCode}/${propertyData.type}-arriendo-${sanitizedRegion}/${propertyData.id}`;
    return HOUM_COM_URL + rootURL;
  }, [
    isPublishedInRentalMode,
    propertyData.country,
    propertyData.id,
    propertyData.region,
    propertyData.type,
  ]);

  const goToSalePublication = () => {
    window.open(salePublicationLink, "_blank", "noreferrer");
  };

  const goToRentalPublication = () => {
    window.open(rentalPublicationLink, "_blank", "noreferrer");
  };

  return {
    isPublished: !isNotPublished,
    salePublicationLink,
    rentalPublicationLink,
    goToSalePublication,
    goToRentalPublication,
  };
};

export default usePropertyPublicationLinks;
