import { TextField, Typography } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import useBasicServicesFieldStyles from "./BasicServicesFieldStyles";

interface Props {
  id: string;
  required?: boolean;
  defaultValue?: string | null;
  disabled?: boolean;
}

export default function BasicServicesField({
  id,
  required = true,
  defaultValue,
  disabled,
}: Props) {
  const classes = useBasicServicesFieldStyles();
  const { control } = useFormContext();
  return (
    <>
      <Typography className={classes.formSectionLabel}>
        {"Número de cliente "}
        {required && (
          <span className={classes.formSectionRequiredField}>*</span>
        )}
      </Typography>
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValue}
        render={({
          field: { value, onChange, ref },
          fieldState: { invalid, error },
        }) => (
          <>
            <TextField
              disabled={disabled}
              InputProps={{ disableUnderline: true }}
              className={classes.formSectionTextField}
              classes={{
                root: classes.formSectionInput,
              }}
              onChange={onChange}
              value={value}
              defaultValue={defaultValue}
              ref={ref}
            />
            {invalid && (
              <Typography className={classes.errorMessage}>
                {error?.message}
              </Typography>
            )}
          </>
        )}
      />
    </>
  );
}
