export const successText = {
  contract: {
    cl: {
      layoutTitle: "Has pagado el contrato de tu próximo hogar",
      layoutLabel: "Pago exitoso",
      title: "has pagado el contrato del siguiente propiedad: ",
      text: "Ahora uno de nuestros ejecutivos se pondrá en contacto contigo para brindarte una atención personalizada en los siguientes pasos.",
    },
    co: {
      layoutTitle: "Has pagado el contrato de tu próximo hogar",
      layoutLabel: "Pago exitoso",
      title: "has pagado el contrato del siguiente propiedad ",
      text: "Ahora uno de nuestros ejecutivos se pondrá en contacto contigo para brindarte una atención personalizada en los siguientes pasos.",
    },
    mx: {
      layoutTitle: "Has pagado el contrato de tu próximo hogar",
      layoutLabel: "Pago exitoso",
      title: "has pagado el contrato del siguiente inmueble: ",
      text: "Ahora uno de nuestros ejecutivos se pondrá en contacto contigo para brindarte una atención personalizada en los siguientes pasos.",
    },
  },
  reservation: {
    cl: {
      layoutTitle: "Has reservado tu próximo hogar",
      layoutLabel: "Pago exitoso",
      title: "has reservado la siguiente propiedad: ",
      text: "Si aún no lo has hecho, debes subir tu documentación al link enviado a tu correo para continuar con el proceso.",
    },
    co: {
      layoutTitle: "Has reservado tu próximo hogar",
      layoutLabel: "Pago exitoso",
      title: "has reservado la siguiente propiedad: ",
      text: null,
    },
    mx: {
      layoutTitle: "Has reservado tu próximo hogar",
      layoutLabel: "Pago exitoso",
      title: "has reservado el siguiente inmueble: ",
      text: "Si aún no lo has hecho, debes subir tu documentación al link enviado a tu correo para continuar con el proceso.",
    },
  },
  lease: {
    cl: {
      layoutTitle: "Pago existoso",
      layoutLabel: "Mensualidad pagada",
      title: "has pagado el arriendo de la siguiente propiedad: ",
      text: null,
    },
    co: {
      layoutTitle: "Pago existoso",
      layoutLabel: "Mensualidad pagada",
      title: "has pagado el arriendo de la siguiente propiedad: ",
      text: null,
    },
    mx: {
      layoutTitle: "Pago existoso",
      layoutLabel: "Mensualidad pagada",
      title: "has pagado la mensualidad del siguiente inmueble: ",
      text: null,
    },
  },
};
