import TenantLayout from "apps/tenant/layout/TenantLayout";
import RequestPage from "./RequestsPage";

export default function () {
  return (
    <TenantLayout>
      <RequestPage />
    </TenantLayout>
  );
}
