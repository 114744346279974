import { Box, Grid, Typography } from "@material-ui/core";

import { IProperty } from "apps/landlord/models/Property";
import PagesNavigation from "./components/PagesNavigation";
import usePropertyPagesMenuStyles from "./PropertyPagesMenuStyles";

interface Props {
  propertyData: IProperty;
}

export default function PropertyPagesMenu({ propertyData }: Props) {
  const classes = usePropertyPagesMenuStyles();

  return (
    <Box>
      <Typography component="h3" className={classes.menuTitle}>
        Más opciones
      </Typography>
      <Grid container item xs={12} className={classes.menuButtonsContainer}>
        <PagesNavigation propertyData={propertyData} />
      </Grid>
    </Box>
  );
}
