import { IEmployment } from "apps/lead/context/evaluatorContext/EvaluatorTypes";
import { getDefaultPhone } from "apps/lead/pages/EvaluatorForm/utils/utils";
import { Locale } from "models/Countries";

export const getDefaultData = (
  employmentInformation: IEmployment,
  country: Locale
) => ({
  employer: employmentInformation?.employer,
  occupation: employmentInformation?.occupation,
  phone: getDefaultPhone(employmentInformation?.employerPhone, country),
});
